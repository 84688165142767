import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, styled, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

import {
  Button,
  Drawer,
  LoadingIndicator,
  Table,
} from '@forethought-technologies/forethought-elements';
import DiscoverLastUpdatedAt from '../common/discover-last-updated-at';
import { formatBooleanValue } from './utils';
import BackIcon from 'src/assets/images/actions-back-icon.svg';
import {
  Card,
  InlineData,
  TextDivider,
} from 'src/pages/intent-conversation-analytics/ConversationDrawer';
import { useGetIrReportQuery } from 'src/services/ir-report/irReportApi';
import {
  InformationRetrievalAggregateMetrics,
  InformationRetrievalBreakdownMetrics,
} from 'src/types/irReportApiTypes';

const MAYBE = 'Maybe';

const IRreport = () => {
  const { palette } = useTheme();
  const { data: irReportData, isLoading: loadingIrReportData } =
    useGetIrReportQuery();

  if (!irReportData || loadingIrReportData) {
    return <LoadingIndicator color={palette.colors.purple[500]} size='large' />;
  }

  return (
    <Box bgcolor='white' height='100%'>
      <Navbar link={irReportData?.download_link} />
      <Box bgcolor='white' mt='40px' px={4}>
        <Typography variant='font16Medium'>
          {`This report simulates our knowledge retrieval algorithm on ${irReportData.aggregate.user_queries} queries. The queries come from a combination of live widget queries as well as queries we generated based on your knowledge base content.`}
        </Typography>
        <IRHeader aggregateData={irReportData?.aggregate} />
        <TheTable breakdownData={irReportData?.breakdown} />
      </Box>
    </Box>
  );
};

const Navbar = ({ link }: { link?: string }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  return (
    <StyledNav>
      <BackButton onClick={() => navigate(-1)} src={BackIcon} />
      <StyledTabContainer>
        <Typography noWrap variant='font24'>
          Information Retrieval Report
        </Typography>
      </StyledTabContainer>
      {link && (
        <Box ml='40px'>
          <Button variant='ghost'>
            <Typography
              color={palette.colors.purple[500]}
              variant={'font14Medium'}
            >
              <a download href={link} rel='noopener noreferrer' target='_blank'>
                Download Report
              </a>
            </Typography>
          </Button>
        </Box>
      )}
      <DiscoverLastUpdatedAt lastUpdateAt={Date.now() / 1000} />
    </StyledNav>
  );
};

const IRHeader = ({
  aggregateData,
}: {
  aggregateData?: InformationRetrievalAggregateMetrics;
}) => {
  const { palette } = useTheme();
  if (!aggregateData) {
    return null;
  }

  return (
    <Box display='flex' gap={2} mb='46px' mt={3}>
      <IRCard
        description='We run incoming queries through our knowledge base question-answering algorithm, and only generate responses when we are confident enough in our answer.'
        isLeftValuePercentage={false}
        leftDescription='User queries'
        leftValue={aggregateData.user_queries}
        rightDescription='Attempted'
        rightValue={aggregateData.attempt_rate}
        title='User query'
      />
      {/* Hide for now */}
      {/* <IRCard
        description='We offer the accuracy of IR in selecting the correct paragraphs and documents for user queries.'
        leftDescription='Paragraph'
        leftValue={aggregateData.paragraph_precision}
        rightDescription='Document'
        rightValue={aggregateData.document_precision}
        title='Precision'
      /> */}
      <IRCard
        description='We assess the percentage of user queries for which our responses are firmly rooted in facts contained in your articles.'
        leftDescription='Yes'
        leftValue={aggregateData.factual_rate}
        leftValueColor={
          aggregateData.factual_rate >= 0.9
            ? palette.colors.green[500]
            : palette.colors.black
        }
        rightDescription='Maybe'
        rightValue={1 - aggregateData.factual_rate}
        title='Factual'
      />
      <IRCard
        description='We assess the percentage of user queries for which our responses might help fully resolve the customer’s issue.'
        leftDescription='Yes'
        leftValue={aggregateData.helpful_rate}
        leftValueColor={
          aggregateData.factual_rate >= 0.9
            ? palette.colors.green[500]
            : palette.colors.black
        }
        rightDescription='No'
        rightValue={1 - aggregateData.helpful_rate}
        title='Helpful'
      />
    </Box>
  );
};

const IRCard = ({
  description,
  isLeftValuePercentage = true,
  leftDescription,
  leftValue,
  leftValueColor,
  rightDescription,
  rightValue,
  title,
}: {
  description: string;
  isLeftValuePercentage?: boolean;
  leftDescription: string;
  leftValue: number;
  leftValueColor?: string;
  rightDescription: string;
  rightValue: number;
  title: string;
}) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        border: '1px solid #BD00FF',
        borderRadius: '8px',
        display: 'flex',
        flex: 1,
        p: 2,
      }}
    >
      <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='font24'>{title}</Typography>
        <Typography color={palette.colors.grey[600]} variant='font14'>
          {description}
        </Typography>
        <Box display='flex' flex={1} my={1}>
          <Box alignSelf='end' display='flex' flex={1} flexDirection='column'>
            <Typography
              color={leftValueColor ?? palette.colors.black}
              variant='font32'
            >
              {leftValue.toLocaleString('en-us', {
                minimumFractionDigits: isLeftValuePercentage ? 2 : undefined,
                style: isLeftValuePercentage ? 'percent' : undefined,
              })}
            </Typography>
            <Typography variant='font14Medium'>{leftDescription}</Typography>
          </Box>
          <Box alignSelf='end' display='flex' flex={1} flexDirection='column'>
            <Typography variant='font32'>
              {rightValue.toLocaleString('en-us', {
                minimumFractionDigits: 2,
                style: 'percent',
              })}
            </Typography>
            <Typography variant='font14Medium'>{rightDescription}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const TheTable = ({
  breakdownData,
}: {
  breakdownData?: InformationRetrievalBreakdownMetrics[];
}) => {
  const { palette } = useTheme();
  const [theIndex, setTheIndex] = useState(-1);
  const [page, setPage] = useState(1);

  const filteredColumns = [
    {
      cellRenderer: (data: InformationRetrievalBreakdownMetrics) => {
        const sources = data.sources;
        return (
          <Box>
            <Typography variant='font14'>{data.response ?? '-'}</Typography>
            {sources.length > 0 && (
              <Box mt={1}>
                <Typography color={palette.colors.grey[600]} variant='font14'>
                  {'Source: '}
                </Typography>
                {sources.map((source, idx) => (
                  <Typography key={source} variant='font14'>
                    {source}
                    {idx !== sources.length - 1 ? ', ' : ''}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
        );
      },
      hideSortIcon: true,
      id: 'response',
      label: 'Response',
      width: '300px',
    },
    {
      cellRenderer: (data: InformationRetrievalBreakdownMetrics) => {
        return (
          <Typography variant='font14'>
            {formatBooleanValue(data.attempted)}
          </Typography>
        );
      },
      comparatorFunction: (
        a: InformationRetrievalBreakdownMetrics,
        b: InformationRetrievalBreakdownMetrics,
      ) => {
        const valueA = a.attempted?.toString() ?? '';
        const valueB = b.attempted?.toString() ?? '';
        return valueA.localeCompare(valueB);
      },
      id: 'attempted',
      label: 'Attempted',
      width: '50px',
    },
    {
      cellRenderer: (data: InformationRetrievalBreakdownMetrics) => {
        return (
          <ItemWithReasoning
            bool={data.factual}
            falseLabel={MAYBE}
            reason={data.factual_reasoning}
          />
        );
      },
      comparatorFunction: (
        a: InformationRetrievalBreakdownMetrics,
        b: InformationRetrievalBreakdownMetrics,
      ) => {
        const valueA = a.factual?.toString() ?? '';
        const valueB = b.factual?.toString() ?? '';
        return valueA.localeCompare(valueB);
      },
      id: 'answer_if_relevant_answer_Factual',
      label: 'Factual',
      width: '300px',
    },
    {
      cellRenderer: (data: InformationRetrievalBreakdownMetrics) => {
        return (
          <ItemWithReasoning
            bool={data.helpful}
            reason={data.helpful_reasoning}
          />
        );
      },
      comparatorFunction: (
        a: InformationRetrievalBreakdownMetrics,
        b: InformationRetrievalBreakdownMetrics,
      ) => {
        const valueA = a.helpful?.toString() ?? '';
        const valueB = b.helpful?.toString() ?? '';
        return valueA.localeCompare(valueB);
      },
      id: 'helpful',
      label: 'Helpful',
      width: '300px',
    },
  ];

  return (
    <Box
      sx={{
        '.MuiPagination-root': {
          paddingBottom: '16px',
        },
        td: { verticalAlign: 'top' },
      }}
    >
      <TheDrawer
        onClose={() => setTheIndex(-1)}
        selectedIR={breakdownData?.[theIndex]}
      />
      <Table
        columns={[
          {
            cellRenderer: data => {
              return (
                <Box display='flex'>
                  <Typography color={palette.colors.blue[600]} variant='font14'>
                    <i>{data.user_query}</i>
                  </Typography>
                </Box>
              );
            },
            hideSortIcon: true,
            id: 'query',
            label: 'User query',
            width: '200px',
          },
          ...filteredColumns,
        ]}
        defaultPaginationValue={1}
        onClickRow={(_, idx) => setTheIndex(idx)}
        onSetPageCallback={page => setPage(page)}
        onSortCallback={() => setPage(1)}
        paginationValue={page}
        rows={breakdownData}
        rowsPerPage={25}
      />
    </Box>
  );
};

const ItemWithReasoning = ({
  bool,
  falseLabel,
  reason,
}: {
  bool: boolean | null;
  falseLabel?: string;
  reason: string | null;
}) => {
  const { palette } = useTheme();
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box
        color={bool === false ? palette.colors.red[500] : undefined}
        sx={{ textTransform: 'capitalize' }}
      >
        {formatBooleanValue(bool, falseLabel)}
      </Box>
      <Box>
        <Typography color={palette.colors.grey[500]} variant='font12'>
          Reason
        </Typography>
      </Box>
      <Box>{reason ?? '-'}</Box>
    </Box>
  );
};

export const BackButton = styled('img')`
  margin-right: 20px;
  cursor: pointer;
`;

const StyledTabContainer = styled('h1')`
  margin: 0;
  text-transform: capitalize;
`;

export const StyledNav = styled('nav')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 16px 40px;
  isolation: isolate;
  top: 0;
  z-index: 1200;
  transition: ${props =>
    props.theme.transitions.create(['background-color'], { duration: 100 })};
`;

export default IRreport;

const TheDrawer = ({
  onClose,
  selectedIR,
}: {
  onClose: () => void;
  selectedIR?: InformationRetrievalBreakdownMetrics;
}) => {
  const { palette } = useTheme();
  const isOpen = Boolean(selectedIR);

  const sources = selectedIR?.sources ?? [];

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <Box display='flex' flexDirection='column' gap='30px' p='40px'>
        <Typography variant='font24'>IR report conversation details</Typography>
        <Box display='flex' flexDirection='column' gap={0.5}>
          <Typography variant='font11'>User query</Typography>
          <Typography color={palette.colors.blue[600]} variant='font14'>
            <i>{selectedIR?.user_query}</i>
          </Typography>
        </Box>
        {sources.length > 0 && (
          <Box display='flex' flexDirection='column' gap={0.5}>
            <Typography variant='font11'>Source</Typography>
            {sources.map((source, idx) => (
              <Typography key={source} variant='font12Medium'>
                {source}
                {idx !== sources.length - 1 ? ', ' : ''}
              </Typography>
            ))}
          </Box>
        )}
        <Card>
          <InlineData
            color={
              selectedIR?.attempted === false
                ? palette.colors.red[500]
                : undefined
            }
            label='Attempted'
            value={formatBooleanValue(selectedIR?.attempted)}
          />
          <InlineData
            color={
              selectedIR?.factual === false
                ? palette.colors.red[500]
                : undefined
            }
            label='Factual'
            value={formatBooleanValue(selectedIR?.factual, MAYBE)}
          />
          <InlineData
            color={
              selectedIR?.helpful === false
                ? palette.colors.red[500]
                : undefined
            }
            label='Helpful'
            value={formatBooleanValue(selectedIR?.helpful)}
          />
          <TextDivider size='small' text='Response' />
          <Typography variant='font14'>
            {selectedIR?.response ?? '-'}
          </Typography>
          <TextDivider size='small' text='Factual' />
          <Box>
            <Typography
              color={
                selectedIR?.factual === false
                  ? palette.colors.red[500]
                  : undefined
              }
              variant='font14'
            >
              {formatBooleanValue(selectedIR?.factual, MAYBE)}
            </Typography>
            <br />
            <Typography color={palette.colors.grey[500]} variant='font12Medium'>
              Reason
            </Typography>
            <Box>
              <Typography variant='font14'>
                {selectedIR?.factual_reasoning ?? '-'}
              </Typography>
            </Box>
          </Box>
          <TextDivider size='small' text='Helpful' />
          <Box>
            <Typography
              color={
                selectedIR?.helpful === false
                  ? palette.colors.red[500]
                  : undefined
              }
              variant='font14'
            >
              {formatBooleanValue(selectedIR?.helpful)}
            </Typography>
            <br />
            <Typography color={palette.colors.grey[500]} variant='font12Medium'>
              Reason
            </Typography>
            <Box>
              <Typography variant='font14'>
                {selectedIR?.helpful_reasoning ?? '-'}
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
    </Drawer>
  );
};
