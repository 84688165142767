import LogRocket from 'logrocket';
import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import {
  APPS_AVAILABLE,
  SET_CURRENT_APPLICATION,
  SET_DASHBOARD_APPLICATIONS_BY_ROLE,
  SET_ORG_CODE,
  UPDATE_ROLE_INFO,
  UPDATE_USER_INFO,
} from './actionTypes';
import { avatarColors } from 'src/components/app/navbar/user-profile/avatarColors';
import { Permission } from 'src/utils/enums';
import { iconColor } from 'src/utils/iconColorForInitials';

const setUser = (user: any, avatarColor: string) => ({
  avatarColor,
  type: UPDATE_USER_INFO,
  user,
});

export const setRole = (role: any) => ({
  role,
  type: UPDATE_ROLE_INFO,
});

export const appsAvailable = (applications: [], insightsTabs?: []) => ({
  applications,
  insightsTabs,
  type: APPS_AVAILABLE,
});

export const setCurrentApplication = (
  currentApplication: string,
  currentTab?: string,
) => ({
  currentApplication,
  currentTab,
  type: SET_CURRENT_APPLICATION,
});

export const saveUserData: Function = (user: any) => (dispatch: Dispatch) => {
  LogRocket.identify(user.user.sub, { email: user.user.email });
  dispatch(setUser(user, iconColor(user.user.email, avatarColors)));
};

export const saveRoleData: Function = (role: any) => (dispatch: Dispatch) => {
  dispatch(setRole(role));
};

export const setOrganizationCode = createAction<string>(SET_ORG_CODE);

export const setDashboardApplicationsByRole = createAction<
  Record<Permission, string>
>(SET_DASHBOARD_APPLICATIONS_BY_ROLE);
