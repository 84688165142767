import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material';

import {
  Button,
  ColorInput,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  CHAT_HEADER_PLACEHOLDER,
  GREETING_LINE_PLACEHOLDER,
} from '../constants';
import { useDebouncedSubmitConfigForRTE } from '../hooks/useDebouncedSubmitConfig';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import { Field, RTEContainer, TabContentContainer } from '../styledComponents';
import { ImageUploadError } from './ImageUploadError';
import IntentOptionsForm from './IntentOptionsForm';
import debounce from 'lodash/fp/debounce';
import agentChatImageDefaultIcon from 'src/assets/images/agent-chat-image-default.svg';
import logoPlaceholderIcon from 'src/assets/images/logo-placeholder.svg';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { useSolveConfigTrackingEventAction } from 'src/hooks/hooks';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { isImageASquare } from 'src/services/workflow-builder/utils';
import {
  confirmAssetUploadComplete,
  createAssetUploadLinkAPI,
} from 'src/services/workflow-builder/workflowBuilderApi';
import { updateOnboardingFlags } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import {
  selectWidgetConfiguration,
  setGreetingLine,
  setStartUploadingImage,
  setTabName,
  setThemeColor,
  updateWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';
import {
  SolveConfigTrackingEventTypes,
  SolveConfigurationTypes,
} from 'src/utils/enums';

const ThemeTabContent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const editorRef = useRef<EditorRef>(null);
  const logoInputRef = useRef<HTMLInputElement>(null);
  const avatarImageInputRef = useRef<HTMLInputElement>(null);
  const errorMessageRef = useRef<HTMLDivElement>(null);

  // selectors
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const remirrorContextVariables = useGetRemirrorContextVariables();
  const {
    agent_chat_image: agentChatImage,
    greeting_line: greetingLine,
    header_image: headerImage,
    tab_name: tabName,
    theme_color: themeColor,
  } = widgetConfiguration;
  const isGreetingEmpty = greetingLine === '';

  // states
  const [isLogoValid, setIsLogoValid] = useState(true);
  const [isAvatarImageValid, setIsAvatarImageValid] = useState(true);

  const debouncedSubmitConfig = useDebouncedSubmitConfigForRTE();

  const debouncedSetThemeColor = useMemo(
    () => debounce(250, (value: string) => dispatch(setThemeColor(value))),
    [dispatch],
  );

  const handleGreetingLineChange = (html: string) => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      greeting_line: html,
    };
    dispatch(setGreetingLine(html));
    debouncedSubmitConfig(updatedConfig, 'greeting_line', html);
  };

  const handleChatHeaderChange = (value: string) => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      tab_name: value,
    };
    dispatch(setTabName(value));
    debouncedSubmitConfig(updatedConfig, 'tab_name', value);
  };

  const handleThemeColorChange = (value: string) => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      theme_color: value,
    };
    debouncedSetThemeColor(value);
    debouncedSubmitConfig(updatedConfig, 'theme_color', value);
  };

  const handleLogoChange = async () => {
    const file = logoInputRef.current?.files?.[0];

    if (file) {
      const fileExtension = file.name.split('.').slice(-1).pop();
      const isValidImage = await isImageASquare(file);
      if (!isValidImage) {
        setIsLogoValid(false);
        return;
      }

      // show loading state in header properly
      dispatch(setStartUploadingImage());

      setIsLogoValid(true);
      const asset = await createAssetUploadLinkAPI({
        content_type: file.type,
        file_extension: fileExtension ?? '',
      });

      fetch(asset.url, {
        body: file,
        headers: {
          'Content-Type': file.type,
        },
        method: 'PUT',
      })
        .then(async () => {
          await confirmAssetUploadComplete(asset.asset_id);
          const updatedWidgetConfiguration = {
            ...widgetConfiguration,
            header_image: asset.file_name,
          };

          dispatch(updateWidgetConfiguration(updatedWidgetConfiguration));
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: 'header_image',
              value: asset.file_name,
            },
          );
        })
        .catch(console.error);
    }
  };

  const handleAvatarImageChange = async () => {
    const file = avatarImageInputRef.current?.files?.[0];

    if (file) {
      const fileExtension = file.name.split('.').slice(-1).pop();

      const isValidImage = await isImageASquare(file);
      if (!isValidImage) {
        setIsAvatarImageValid(false);
        return;
      }

      // show loading state in header properly
      dispatch(setStartUploadingImage());

      setIsAvatarImageValid(true);
      const asset = await createAssetUploadLinkAPI({
        content_type: file.type,
        file_extension: fileExtension ?? '',
      });

      fetch(asset.url, {
        body: file,
        headers: {
          'Content-Type': file.type,
        },
        method: 'PUT',
      })
        .then(async () => {
          await confirmAssetUploadComplete(asset.asset_id);
          const updatedWidgetConfiguration = {
            ...widgetConfiguration,
            agent_chat_image: asset.file_name,
          };

          dispatch(updateWidgetConfiguration(updatedWidgetConfiguration));
          dispatchTrackingAction(
            SolveConfigTrackingEventTypes.CONFIGURATION_UPDATE,
            {
              configuration_type: SolveConfigurationTypes.WIDGET,
              type: 'agent_chat_image',
              value: asset.file_name,
            },
          );
        })
        .catch(console.error);
    }
  };

  const scrollToErrorMessage = () => {
    errorMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (isGreetingEmpty) {
      // needed the setTimeout so we can scroll into the error message after it is rendered
      setTimeout(scrollToErrorMessage, 50);
    }
  }, [isGreetingEmpty]);

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const isOnboardingExperienceEnabled = featureFlags.includes(
    'onboarding_experience',
  );

  useEffect(() => {
    if (isOnboardingExperienceEnabled) {
      dispatch(updateOnboardingFlags({ is_theme_visited: true }));
    }
  }, [dispatch, isOnboardingExperienceEnabled]);

  return (
    <TabContentContainer>
      <Field>
        <Typography variant='font16Bold'>Logo</Typography>
        {isLogoValid ? (
          <ImageContainer>
            <IconImage
              alt=''
              src={headerImage ? headerImage : logoPlaceholderIcon}
            />
          </ImageContainer>
        ) : (
          <ImageUploadError />
        )}
        <Button onClick={() => logoInputRef.current?.click()} variant='ghost'>
          Change image
        </Button>
        <input
          accept='image/*'
          hidden
          onChange={handleLogoChange}
          ref={logoInputRef}
          type='file'
        />
      </Field>
      <Field>
        <Typography variant='font16Bold'>Chat header</Typography>
        <TextField
          aria-label='Chat header'
          onChange={e => {
            handleChatHeaderChange(e.target.value);
          }}
          placeholder={CHAT_HEADER_PLACEHOLDER}
          value={tabName}
        />
      </Field>
      <Field>
        <Typography variant='font16Bold'>Color</Typography>
        <ColorInput
          color={themeColor}
          onChange={e => {
            handleThemeColorChange(e.target.value);
          }}
          subLabel='Theme color'
        />
      </Field>
      <Field>
        <Typography variant='font16Bold'>Avatar image</Typography>
        {isAvatarImageValid ? (
          <ImageContainer>
            <IconImage
              alt=''
              src={agentChatImage ? agentChatImage : agentChatImageDefaultIcon}
            />
          </ImageContainer>
        ) : (
          <ImageUploadError />
        )}
        <Button
          onClick={() => avatarImageInputRef.current?.click()}
          variant='ghost'
        >
          Change image
        </Button>
        <input
          accept='image/*'
          hidden
          onChange={handleAvatarImageChange}
          ref={avatarImageInputRef}
          type='file'
        />
      </Field>
      <Field>
        <Typography variant='font16Bold'>Greeting</Typography>
        <div>
          <RTEContainer hasError={isGreetingEmpty}>
            <RichTextEditor
              contextVariables={remirrorContextVariables}
              editorRef={editorRef}
              initialContent={greetingLine}
              label='Greeting'
              onChange={handleGreetingLineChange}
              placeholder={GREETING_LINE_PLACEHOLDER}
            />
          </RTEContainer>
          <div ref={errorMessageRef}>
            {isGreetingEmpty && (
              <Typography
                color={theme.palette.colors.red[500]}
                variant='font14Medium'
              >
                Enter a greeting message. Greeting cannot be empty.
              </Typography>
            )}
          </div>
        </div>
      </Field>
      <IntentOptionsForm />
    </TabContentContainer>
  );
};

const ImageContainer = styled('div')`
  height: 96px;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled('img')`
  height: 60px;
  width: 60px;
`;

export default ThemeTabContent;
