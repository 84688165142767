import store from 'src/store/store';

const macroNameHeader = {
  heading: 'MACRO NAME',
  headingClass: 'heading-type',
  productSorted: 'macro_controls_admin_table',
  propertyName: 'name',
};
const macroIdHeader = {
  heading: 'MACRO ID',
  headingClass: 'heading-type',
  productSorted: 'macro_controls_admin_table',
  propertyName: 'macro_id',
  tooltipText: 'ID of the macro in your helpdesk',
  tooltipTitle: 'Macro ID',
};

export const trainedMacroHeaderDict = [
  macroNameHeader,
  macroIdHeader,
  {
    heading: 'RESOLUTIONS',
    headingClass: 'heading-type',
    productSorted: 'macro_controls_admin_table',
    propertyName: 'resolutions',
    tooltipText:
      'Number of tickets that did not need any agent interaction after Agatha’s response',
    tooltipTitle: 'Instant Resolutions',
  },
  {
    heading: 'ACTIVE',
    headingClass: 'heading-type',
    productSorted: 'macro_controls_admin_table',
    propertyName: 'is_active',
    tooltipText:
      'Indicates whether this macro is used by Agatha to reply to customers',
    tooltipTitle: 'Active',
  },
];

export const untrainedMacroHeaderDict = () => {
  const userState = store.getState().user;
  // @ts-expect-error legacy code with untyped state
  const { helpdesk } = userState;
  const usageName = helpdesk === 'zendesk' ? '30 Day Usage' : 'Usage';

  return [
    macroNameHeader,
    macroIdHeader,
    {
      heading: usageName,
      headingClass: 'heading-type',
      productSorted: 'macro_controls_admin_table',
      propertyName: usageName,
      tooltipText: 'Number of times this macro was used by a support agent',
      tooltipTitle: 'Agent Usage',
    },
  ];
};
