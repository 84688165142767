import { GetAllReportsDict, Report } from 'src/services/apiInterfaces';
const selectNextReport = (
  reportsData: GetAllReportsDict | undefined,
  selectedReport: Report | undefined,
): Report | undefined => {
  let newSelectedReport: number | undefined;

  const bookmarkedReports = reportsData?.user_bookmarked_report_ids || [];
  const selectedReportId = selectedReport?.report_id as number;

  const currentIndex = Number(bookmarkedReports.indexOf(selectedReportId));

  const index =
    currentIndex === -1
      ? bookmarkedReports[bookmarkedReports.length - 1]
      : currentIndex;

  if (reportsData?.user_bookmarked_report_ids.includes(selectedReportId)) {
    if (bookmarkedReports[currentIndex + 1]) {
      newSelectedReport = bookmarkedReports[currentIndex - 1];
    } else {
      newSelectedReport = bookmarkedReports[currentIndex - 1];
    }
  } else {
    newSelectedReport = index;
  }

  const report =
    reportsData?.reports?.find(
      (report: Report) => report.report_id === newSelectedReport,
    ) || reportsData?.reports?.[0];

  return report;
};

export default selectNextReport;
