import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import iconOnboardingCompleted from 'src/assets/images/onboarding-completed.svg';

export default function OnboardingCompleted(): JSX.Element {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '225px',
        justifyContent: 'flex-start',
        marginBottom: '24px',
        textAlign: 'center',
      }}
    >
      <img alt='' height='100px' src={iconOnboardingCompleted} />
      <Box
        sx={{
          '.MuiTypography-root': {
            background: 'linear-gradient(270deg, #BD00FF 0%, #3866F5 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
          marginBottom: '8px',
          marginTop: '8px',
        }}
      >
        <Typography variant='font24'>Great start!</Typography>
      </Box>
      <Box>
        <Typography variant='font14'>
          The Bot setup is complete and it’s time to create workflows.
          <br />
          Analytics will be available once traffic is detected.
        </Typography>
      </Box>
    </Box>
  );
}
