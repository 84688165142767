import { FilterOption } from './types';
import { UNFORMATTED_LABEL_SEPARATOR } from 'src/constants/discover';
import { Field, Filters } from 'src/services/apiInterfaces';

export const processValuesToFilters = (
  values: string[],
  options: FilterOption[],
): Filters[] => {
  // Takes a list of options like: ['agent{}priority{}high']
  // and the set of options and creates the FTQuery
  // object that can be converted to ES queries
  const fieldToValuesMap: Record<string, string[]> = {};
  values.forEach(filter => {
    const splitFilter = filter.split(UNFORMATTED_LABEL_SEPARATOR);
    const category = splitFilter[0];
    const field = splitFilter[1];
    const value = splitFilter[2];
    const itemKey = `${category}${UNFORMATTED_LABEL_SEPARATOR}${field}`;
    fieldToValuesMap[itemKey] = fieldToValuesMap[itemKey]
      ? [...fieldToValuesMap[itemKey], value]
      : [value];
  });
  const fieldMap: Record<string, Field> = {};
  options.forEach(option => {
    if (option.field) {
      fieldMap[
        `${option.category}${UNFORMATTED_LABEL_SEPARATOR}${option.value}`
      ] = {
        ...option.field,
        field_category: option.category,
      };
    }
  });
  const processedFilters: Filters[] = [];
  Object.entries(fieldToValuesMap).forEach(entry => {
    const [key, value] = entry;
    if (!fieldMap[key]) {
      return;
    }
    processedFilters.push({
      field: fieldMap[key],
      negate: false,
      operator: '=',
      values: value,
    });
  });
  return processedFilters;
};

export const createOption = (
  category: string,
  field: Field,
  values: string[],
): FilterOption => ({
  category,
  field,
  label: field.field_name,
  options: values.map(fieldValue => {
    return {
      category,
      field: field,
      label: String(fieldValue),
      value: `${category}${UNFORMATTED_LABEL_SEPARATOR}${field.field_id}${UNFORMATTED_LABEL_SEPARATOR}${fieldValue}`,
    };
  }),
  value: field.field_id,
});
