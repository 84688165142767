import { CreateModelFieldItem, CreateModelFormValue } from './types';

export const createModelFormValue: CreateModelFormValue = {
  description: '',
  displayName: '',
};

export const createModelFields: CreateModelFieldItem[] = [
  {
    description:
      'This is the info intended only for you to better view the model in analytics.',
    key: 'displayName',
    label: 'Model name',
  },
  {
    description:
      'This is the info intended only for you to better view the model in analytics.',
    info: 'Keep the description under 100 characters',
    key: 'description',
    label: 'Model description',
    placeholder: 'Describe this model’s intention ',
  },
];

export const TRIAGE_SUPPORTED_HELPDESKS = ['salesforce', 'zendesk'];
