import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { darkModeGreen, darkModeGrey } from '../constants';
import { PANELS } from '../types';
import { CollapseButton } from './StyledComponents';
import _omit from 'lodash/fp/omit';
import {
  isForethoughtError,
  isHistoricalSolveApiRequest,
  isSolveApiStreamingResponse,
  SolveApiTransaction,
} from 'src/slices/workflow-preview/types';
import { selectApiPreviewState } from 'src/slices/workflow-preview/workflowPreviewSlice';

const useTrafficPanelHistory = () => {
  const { history } = useSelector(selectApiPreviewState);
  const joinRequestsByRequestId = (history: SolveApiTransaction[]) => {
    const joinedHistory: (SolveApiTransaction | string)[] = [];
    const requestIds: number[] = [];

    history.forEach(transaction => {
      if (!isSolveApiStreamingResponse(transaction)) {
        joinedHistory.push(transaction);
        return;
      }

      if (requestIds.includes(transaction.requestId)) {
        return;
      }

      const requestsWithSameId = history.filter(
        t =>
          isSolveApiStreamingResponse(t) &&
          t.requestId === transaction.requestId,
      );
      const joinedRequest = requestsWithSameId
        .map(t => JSON.stringify(_omit('requestId', t), null, 2))
        .join('\n');
      joinedHistory.push(joinedRequest);
      requestIds.push(transaction.requestId);
    });

    return joinedHistory;
  };

  return joinRequestsByRequestId(history);
};

export const ApiTrafficPanel = ({
  expandedPanel,
  setExpandedPanel,
}: {
  expandedPanel: PANELS;
  setExpandedPanel: (panel: PANELS) => void;
}) => {
  const theme = useTheme();
  const endRef = useRef<HTMLDivElement>(null);
  const history = useTrafficPanelHistory();

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [history]);

  const isCollapsed = expandedPanel === 'info-log';

  return (
    <CollapsibleSection collapsed={isCollapsed}>
      <Typography color={theme.palette.colors.white} variant='font16Bold'>
        API request logs
      </Typography>
      <CollapseButton
        onClick={() => {
          setExpandedPanel(
            expandedPanel === 'api-traffic' ? 'none' : 'api-traffic',
          );
        }}
      >
        {expandedPanel === 'api-traffic' ? (
          <IconChevronDown color={theme.palette.colors.white} size={20} />
        ) : (
          <IconChevronUp color={theme.palette.colors.white} size={20} />
        )}
      </CollapseButton>
      {!isCollapsed && (
        <TrafficPanel>
          {history.map((transaction, index) => (
            <Transaction index={index} key={index} transaction={transaction} />
          ))}
          <div ref={endRef} />
        </TrafficPanel>
      )}
    </CollapsibleSection>
  );
};

const Transaction = ({
  index,
  transaction,
}: {
  index: number;
  transaction: SolveApiTransaction | string;
}) => {
  return (
    <Box display='flex' flexDirection='column'>
      <Header index={index} transaction={transaction} />
      <pre style={{ whiteSpace: 'pre-wrap' }}>
        {typeof transaction === 'string'
          ? transaction
          : JSON.stringify(transaction, null, 2)}
      </pre>
    </Box>
  );
};

const Header = ({
  index,
  transaction,
}: {
  index: number;
  transaction: SolveApiTransaction | string;
}) => {
  const theme = useTheme();
  const { conversation_id: conversationId } = useSelector(
    selectApiPreviewState,
  );

  const method = index === 0 ? 'post' : 'put';
  const type = isHistoricalSolveApiRequest(transaction)
    ? 'request'
    : 'response';

  if (type === 'request') {
    return (
      <>
        <span style={{ color: theme.palette.colors.white }}>
          {'// Request'}
        </span>
        <span>
          <span style={{ color: darkModeGreen }}>{method.toUpperCase()} </span>
          <span style={{ color: darkModeGrey }}>
            /api/v1/conversation{method === 'put' && `/${conversationId}`}
          </span>
        </span>
      </>
    );
  }

  if (type === 'response') {
    return (
      <>
        <span style={{ color: theme.palette.colors.white }}>
          {'// Response'}
        </span>
        {isForethoughtError(transaction) ? (
          <span style={{ color: theme.palette.colors.red[400] }}>
            {`${transaction.status} ${transaction.message}`}
          </span>
        ) : (
          <span style={{ color: darkModeGreen }}>200 OK</span>
        )}
      </>
    );
  }
};

const CollapsibleSection = styled('div')<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.collapsed ? 0.02 : 0.975)};
  gap: 16px;
  padding: 24px 40px;
  overflow-y: hidden;
  position: relative;
  border-top: 1px solid ${props => props.theme.palette.colors.grey[700]};
  transition: 0.3s;
`;

const TrafficPanel = styled('div')`
  background-color: ${props => props.theme.palette.colors.black};
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  overflow-y: scroll;
  flex: 1;
  border-radius: 8px;
  font-family: 'Fira Code';
  color: white;
  font-size: 14px;

  pre::selection {
    color: ${props => props.theme.palette.colors.black};
  }

  span::selection {
    color: ${props => props.theme.palette.colors.black};
  }
`;
