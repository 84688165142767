import Box from '@mui/material/Box';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

const PercentChangeTooltipContent = () => (
  <Box padding={2}>
    <Typography variant='font12'>
      <Box mb={2}>
        <strong>Percent change</strong> is the comparison of a topic between the
        current selected time period versus the previous selected time period.{' '}
        <strong>Positive (+)</strong> percent change indicates the topic has
        increased, while <strong>negative (-)</strong> percent change indicates
        the topic has decreased.
      </Box>
      <Box mb={0.5}>
        <Typography color={theme.palette.colors.grey[800]} variant='font11'>
          Example
        </Typography>
      </Box>
      If a topic’s volume percent change is <strong>+2.3%</strong>, its volume
      has <strong>increased 2.3%</strong> compared to the previous time frame.
    </Typography>
  </Box>
);

export default PercentChangeTooltipContent;
