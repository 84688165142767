import React from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { IconCheck } from '@tabler/icons-react';

import { Button } from '@forethought-technologies/forethought-elements';
import { GenerativeResponseModelFooterButtonAction } from '../email-builder/types';
import ExtendIcon from 'src/assets/images/extend.svg';
import ShortenIcon from 'src/assets/images/shorten.svg';

interface ResponseGenerationModalFooterProps {
  /** Determines if the request to get the response is in progress */
  isLoadingResponse: boolean;
  /** Function to call when a button is clicked */
  onClick: (action: GenerativeResponseModelFooterButtonAction) => void;
}

const ResponseGenerationModalFooter: React.FC<
  React.PropsWithChildren<ResponseGenerationModalFooterProps>
> = ({ isLoadingResponse, onClick }: ResponseGenerationModalFooterProps) => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.slate[100]}
      borderRadius='0 0 7px 7px'
      display='flex'
      gap='5px'
      height='56px'
      minHeight='56px'
      px='16px'
      py='12px'
      width='100%'
    >
      {!isLoadingResponse && (
        <>
          <Button
            onClick={() => onClick('apply')}
            startIcon={
              <IconCheck
                color={palette.colors.white[100]}
                height='20px'
                width='20px'
              />
            }
            variant='main'
          >
            Apply
          </Button>
          <CustomActionButton
            onClick={() => onClick('shorten')}
            startIcon={<img src={ShortenIcon} />}
            variant='ghost'
          >
            Shorten
          </CustomActionButton>
          <CustomActionButton
            onClick={() => onClick('extend')}
            startIcon={<img src={ExtendIcon} />}
            variant='ghost'
          >
            Extend
          </CustomActionButton>
        </>
      )}
    </Box>
  );
};

export default ResponseGenerationModalFooter;

const CustomActionButton = styled(Button)`
  &:hover {
    background: ${({ theme }) => theme.palette.colors.purple[200]};
  }
`;
