import React, { Children } from 'react';
import ReactSelect, { components, Props, StylesConfig } from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import dropdownClearDark from '../../../assets/images/dropdown-clear-dark.svg';
import dropdownIndicator from '../../../assets/images/dropdown-indicator.svg';
import dropdownIndicatorDark from '../../../assets/images/dropdown-indicator-dark.svg';

const MenuList: typeof components.MenuList = props => {
  // looks like react-select didn't add children to their prop types
  // eslint-disable-next-line react/prop-types
  const arrayChildren = Children.toArray(props.children);
  return (
    <components.MenuList {...props}>
      {arrayChildren.map((child, ind) => (
        <div
          aria-label={`select option ${ind + 1}`}
          key={ind}
          role='contentinfo'
        >
          {child}
        </div>
      ))}
    </components.MenuList>
  );
};

const DropdownIndicator: typeof components.DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img alt='dropdown indicator' src={dropdownIndicator} />
    </components.DropdownIndicator>
  );
};

const DropdownIndicatorDark: typeof components.DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img alt='dropdown indicator' src={dropdownIndicatorDark} />
    </components.DropdownIndicator>
  );
};

const ClearIndicatorDark: typeof components.ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <img src={dropdownClearDark} />
    </components.ClearIndicator>
  );
};

interface SelectProps extends Props<{ label: string; value: string }, false> {
  backgroundColor?: string;
  color?: string;
  containerWidth?: string;
  customFocusedBorderColor?: string;
  errorMessage?: string;
  fontSize?: string;
  hoverColor?: string;
  isDisabled?: boolean;
  isValueInvalid?: boolean;
  justifyContent?: string;
  margin?: string;
  maxMenuHeight?: number;
  optionBorderRadius?: string;
  optionColor?: string;
  padding?: string;
  shouldShowFocusStyle?: boolean;
  width?: string;
}

export const Select: React.FC<React.PropsWithChildren<SelectProps>> = ({
  backgroundColor,
  border,
  color,
  containerWidth,
  customFocusedBorderColor,
  errorMessage = '',
  fontSize,
  hoverColor,
  isDisabled,
  isValueInvalid = Boolean(errorMessage),
  justifyContent,
  margin,
  maxMenuHeight,
  optionBorderRadius,
  optionColor,
  padding,
  shouldShowFocusStyle,
  width,
  ...props
}) => {
  const stylesConfig: StylesConfig<
    {
      label: string;
      value: string;
    },
    false
  > = {
    container: styles => ({
      ...styles,
      margin: margin ? margin : undefined,
      width: containerWidth ? containerWidth : 'auto',
    }),
    control: (styles, state) => {
      let controlBorder;
      if (isValueInvalid) {
        controlBorder = `1px solid ${Colors.ui.semantic.destructive.default}`;
      } else if (state.isFocused && customFocusedBorderColor) {
        controlBorder = `1px solid ${customFocusedBorderColor}`;
      } else if (state.isFocused && shouldShowFocusStyle) {
        controlBorder = `1px solid ${theme.palette.colors.purple[500]}`;
      } else if (border) {
        controlBorder = border;
      } else {
        controlBorder = `1px solid ${theme.palette.colors.grey[300]}`;
      }
      let bgColor;
      if (props.isDisabled) {
        bgColor = Colors.ui.backgrounds.slate;
      } else if (backgroundColor) {
        bgColor = backgroundColor;
      } else {
        bgColor = theme.palette.colors.grey[100];
      }
      return {
        ...styles,
        '&:hover': {
          borderColor: isValueInvalid
            ? Colors.ui.semantic.destructive.default
            : undefined,
        },
        backgroundColor: bgColor,
        border: controlBorder,
        boxShadow: 'none',
        cursor: isDisabled ? 'default' : 'pointer',
        fontSize: fontSize ? fontSize : '15px',
        fontWeight: 500,
        height: '42px',
        justifyContent: justifyContent ? justifyContent : 'center',
        padding: padding ? padding : '0',
        pointerEvents: 'auto',
        width: width || '90px',
      };
    },
    indicatorsContainer: () => ({
      alignItems: 'flex-start',
      color: theme.palette.colors.white,
      display: 'flex',
      marginRight: '-8px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: styles => ({
      ...styles,
      fontSize: '15px',
      fontWeight: 500,
      zIndex: 5,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      '&:active': {
        background: hoverColor || theme.palette.colors.slate[100],
      },
      '&:hover': {
        background: hoverColor || theme.palette.colors.slate[100],
      },
      background: isSelected
        ? theme.palette.colors.slate[100]
        : theme.palette.colors.white,
      borderRadius: optionBorderRadius ?? 'inherit',
      color: optionColor || Colors.ui.text.primary,
      cursor: 'pointer',
      textAlign: 'left',
    }),
    singleValue: () => ({
      color: props.isDisabled
        ? theme.palette.colors.grey[300]
        : color
        ? color
        : theme.palette.colors.white,
      fontFamily: color ? 'Plus Jakarta Sans' : undefined,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    valueContainer: () => ({
      color: theme.palette.colors.white,
      display: 'flex',
    }),
  };

  const errorMessageId = uuidv4();

  return (
    <div
      aria-label={
        !!props['aria-label'] ? `${props['aria-label']} dropdown` : 'dropdown'
      }
      role='group'
      style={{ width: width || 'auto' }}
    >
      <ReactSelect
        aria-errormessage={errorMessage}
        aria-invalid={isValueInvalid}
        components={{
          ClearIndicator: ClearIndicatorDark,
          DropdownIndicator: color ? DropdownIndicatorDark : DropdownIndicator,
          MenuList,
        }}
        isDisabled={isDisabled}
        isSearchable={false}
        maxMenuHeight={maxMenuHeight}
        styles={stylesConfig}
        {...props}
      />
      <ErrorMessage color={theme.palette.error.main} id={errorMessageId}>
        {errorMessage}
      </ErrorMessage>
    </div>
  );
};

const ErrorMessage = styled('div')<{ color: string }>`
  font-size: 12px;
  color: ${({ color }) => color};

  :not(:empty) {
    margin-top: 4px;
  }
`;
