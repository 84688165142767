import { LaunchOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';

interface TicketIdProps {
  link: string;
  ticketId: string;
}
const TicketId = ({ link, ticketId }: TicketIdProps) => {
  const { palette } = useTheme();

  if (!ticketId) {
    return null;
  }

  return (
    <Box
      alignItems='center'
      columnGap={1}
      display='flex'
      sx={{ a: { textDecoration: 'none' } }}
    >
      <Typography color={palette.colors.grey[600]} variant='font12'>
        Ticket ID:
      </Typography>
      <a href={link} rel='noreferrer' target='_blank'>
        <Box alignItems='center' columnGap={1} component='span' display='flex'>
          <Typography color={palette.colors.purple[500]} variant='font12'>
            {ticketId}
          </Typography>
          <LaunchOutlined sx={{ height: 14, width: 14 }} />
        </Box>
      </a>
    </Box>
  );
};

export default TicketId;
