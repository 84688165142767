import { useEffect } from 'react';
import { Box } from '@mui/material';

import CodeSnippetSection from './CodeSnippetSection';
import DomainFormSection from './DomainFormSection';
import { getWidgetApiKey } from 'src/actions/workflow-builder/workflowBuilderActions';
import { useAppDispatch } from 'src/store/hooks';

const CodeTabContent = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWidgetApiKey());
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DomainFormSection />
      <Box borderBottom='1px solid rgba(0, 0, 0, 0.1);' pt='38px' />
      <CodeSnippetSection />
    </Box>
  );
};

export default CodeTabContent;
