import { dashboardApi } from '../dashboard-api';
import { createFilterBody, createTimeStamps } from './helpers';
import {
  DuplicateVersionRequest,
  ModelBaseUpdate,
  ModelLabelsRequest,
  ModelLabelsResponse,
  PublishVersionRequest,
  TicketTableRequest,
  TicketTableResponse,
  TriagePredictionRunResponse,
  TriagePredictionTestResultResponse,
  TriageTicket,
  TriageTicketOverviewRequest,
  TriageTicketOverviewResponse,
} from './types';
import { TriageModelLabel } from 'src/components/dashboard-pages/triage-model-detail-page/types';
import {
  GetModelsAndVersionsResponse,
  HelpdeskOptionsResponse,
  HelpdeskResponseV2,
  PatchTriageModelRequest,
  TestTriageModelRequest,
  TestTriageModelResponse,
  TriageModelDetail,
  TriageSimulateRequest,
  TriageTicketFieldsResponse,
  VersionedTriageModel,
  VersionedTriageModelRequest,
} from 'src/reducers/triageSettingsReducer/types';

const triageV1 = '/dashboard/triage/v1';

const triageApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    createModel: builder.mutation<
      VersionedTriageModel,
      VersionedTriageModelRequest
    >({
      invalidatesTags: [{ type: 'TriageModelDetail' }],
      query: ({ model_description, model_name }) => ({
        body: { model_description, model_name },
        method: 'POST',
        url: `${triageV1}/models`,
      }),
    }),
    deleteModel: builder.mutation<VersionedTriageModel, string>({
      invalidatesTags: (_, __, arg) => [
        { type: 'TriageModelDetail' },
        { id: arg, type: 'TriageModelDetail' },
      ],
      query: modelId => ({
        method: 'DELETE',
        url: `${triageV1}/models/${modelId}`,
      }),
    }),
    deleteVersionModel: builder.mutation<VersionedTriageModel, ModelBaseUpdate>(
      {
        invalidatesTags: (_, __, arg) => [
          { id: arg.modelId, type: 'TriageModelDetail' },
        ],
        query: ({ modelId, versionId }) => ({
          method: 'DELETE',
          url: `${triageV1}/models/${modelId}/versions/${versionId}`,
        }),
      },
    ),
    duplicateVersion: builder.mutation<
      VersionedTriageModel,
      DuplicateVersionRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
      ],
      query: ({ modelId, versionId, versionName }) => ({
        body: { version_name: versionName },
        method: 'POST',
        url: `${triageV1}/models/${modelId}/versions/${versionId}`,
      }),
    }),
    getHelpdesk: builder.query<HelpdeskResponseV2, void>({
      query: () => ({
        method: 'GET',
        url: 'ssel/triage/helpdesk-integrations',
      }),
    }),
    getHelpdeskOptions: builder.query<HelpdeskOptionsResponse, void>({
      query: () => ({
        method: 'GET',
        url: `${triageV1}/helpdesk-options`,
      }),
    }),
    getModelById: builder.query<VersionedTriageModel, string>({
      providesTags: (_, __, arg) => [{ id: arg, type: 'TriageModelDetail' }],
      query: modelId => ({
        method: 'GET',
        url: `${triageV1}/models/${modelId}`,
      }),
    }),
    getModelLabels: builder.query<ModelLabelsResponse, ModelLabelsRequest>({
      providesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
      ],
      query: ({ endDate, modelId, startDate }) => ({
        method: 'GET',
        url: `${triageV1}/models/${modelId}/label-table${createTimeStamps(
          startDate,
          endDate,
        )}`,
      }),
    }),
    getModelsAndVersions: builder.query<GetModelsAndVersionsResponse[], void>({
      query: () => ({
        method: 'GET',
        url: `${triageV1}/models-and-versions`,
      }),
    }),
    getModelVersion: builder.query<VersionedTriageModel, ModelBaseUpdate>({
      providesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
      ],
      query: ({ modelId, versionId }) => ({
        method: 'GET',
        url: `${triageV1}/models/${modelId}/versions/${versionId}`,
      }),
    }),
    getModelVersions: builder.query<VersionedTriageModel[], string>({
      providesTags: (_, __, arg) => [{ id: arg, type: 'TriageModelDetail' }],
      query: modelId => ({
        method: 'GET',
        url: `${triageV1}/models/${modelId}/versions`,
      }),
    }),
    getPredictionRuns: builder.query<TriagePredictionRunResponse, void>({
      providesTags: ['Simulation'],
      query: () => ({
        url: `${triageV1}/prediction-tests`,
      }),
    }),
    getPredictionTestResults: builder.query<ModelLabelsResponse, string>({
      query: predictionTestId => ({
        url: `${triageV1}/prediction-test-result/${predictionTestId}`,
      }),
      transformResponse: (result: TriagePredictionTestResultResponse) => {
        // Re-using a labels/tickets table hacky fast work for demo, matching response here
        const transformedResult: ModelLabelsResponse = {
          labels: result.labels_metrics.map(item => {
            return {
              accuracy: item.accuracy,
              description: item.description,
              finalized_count: item.finalized_tickets_count,
              name: item.label_name,
              predicted_count: item.predictions_count,
              recall: item.recall,
            };
          }) as TriageModelLabel[],
          overview: {
            actual_count: null,
            avg_accuracy: result.label_overall_metrics.accuracy,
            avg_coverage: null,
            correct_count:
              result.label_overall_metrics.correct_predictions_count,
            eligible_count: null,
            finalized_count: null,
            pending_count: null,
            predicted_count: result.label_overall_metrics.predictions_count,
            prediction_count: null,
            qualified_count: null,
            recall: null,
            total_count: result.label_overall_metrics.total_tickets_count,
          },
        };
        return transformedResult;
      },
    }),
    getPredictionTestResultsForTicket: builder.query<
      TicketTableResponse,
      string
    >({
      query: predictionTestId => ({
        url: `${triageV1}/prediction-test-result/${predictionTestId}`,
      }),
      transformResponse: (result: TriagePredictionTestResultResponse) => {
        // Re-using a labels/tickets table hacky fast work for demo, matching response here
        const transformedResult: TicketTableResponse = {
          next_page: null,
          tickets: result.tickets_metrics.map(item => {
            return {
              body: item.body,
              current_label: item.final_label,
              id: item.ticket_id,
              is_prediction_accurate: item.is_correct,
              predicted_label: item.predicted_label,
              reason_no_prediction: item.reason_no_prediction,
              status: item.status,
              timestamp: 0,
              title: item.title,
              written_to_helpdesk: false,
            } as TriageTicket;
          }),
        };
        return transformedResult;
      },
    }),
    getSelfServeTriageModels: builder.query<Array<TriageModelDetail>, void>({
      providesTags: [{ type: 'TriageModelDetail' }],
      query: () => ({
        method: 'GET',
        url: `${triageV1}/models`,
      }),
    }),
    getTicketFields: builder.query<TriageTicketFieldsResponse, string>({
      query: dataSource => ({
        method: 'GET',
        url: `ssel/triage/models/data_source/${dataSource}/ticket_fields`,
      }),
    }),
    getTicketsOverviewMetrics: builder.query<
      TriageTicketOverviewResponse,
      TriageTicketOverviewRequest
    >({
      query: params => {
        return {
          body: createFilterBody(params),
          method: 'POST',
          url: `${triageV1}/ticket-tab/ticket-overview-metrics`,
        };
      },
    }),
    getTicketsTable: builder.query<TicketTableResponse, TicketTableRequest>({
      forceRefetch: ({ currentArg, previousArg }) => {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCacheData, responseData, otherArgs) => {
        if (otherArgs.arg.page > 1) {
          currentCacheData.tickets = currentCacheData.tickets.concat(
            responseData.tickets,
          );
          currentCacheData.next_page = responseData.next_page;
        } else {
          currentCacheData.tickets = responseData.tickets;
          currentCacheData.next_page = responseData.next_page;
        }
      },
      query: params => {
        return {
          body: createFilterBody(params),
          method: 'POST',
          url: `${triageV1}/ticket-tab/ticket-table`,
        };
      },

      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
    }),
    getTriageModel: builder.query<TriageModelDetail, string>({
      query: modelName => ({
        method: 'GET',
        url: `ssel/triage/models/${modelName}`,
      }),
    }),
    getTriageModels: builder.query<Array<TriageModelDetail>, void>({
      providesTags: [{ type: 'TriageModelDetail' }],
      query: () => ({
        method: 'GET',
        url: 'ssel/triage/models',
      }),
    }),
    patchSelfServeTriageModel: builder.mutation<
      VersionedTriageModel,
      PatchTriageModelRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { id: arg.modelId, type: 'TriageModelDetail' },
        { type: 'TriageModelDetail' },
      ],
      query: ({ body, modelId, versionId }) => ({
        body,
        method: 'PATCH',
        url: `${triageV1}/models/${modelId}/versions/${versionId}`,
      }),
    }),
    publishVersion: builder.mutation<
      VersionedTriageModel,
      PublishVersionRequest
    >({
      invalidatesTags: (_, __, arg) => [
        { type: 'TriageModelDetail' },
        { id: arg.modelId, type: 'TriageModelDetail' },
      ],
      query: ({ modelId, publish, versionId }) => ({
        body: { publish },
        method: 'PUT',
        url: `${triageV1}/models/${modelId}/versions/${versionId}`,
      }),
    }),
    simulateTriageTickets: builder.mutation<void, TriageSimulateRequest>({
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(dashboardApi.util.invalidateTags(['Simulation']));
        }, 3000);
      },
      query: ({
        end_datetime,
        model_id,
        name,
        start_datetime,
        ticket_ids,
        version_id,
      }) => ({
        body: {
          end_datetime,
          model_id,
          name,
          start_datetime,
          ticket_ids,
          version_id,
        },
        method: 'POST',
        url: `${triageV1}/models/simulate`,
      }),
    }),
    testSelfServeModel: builder.mutation<
      TestTriageModelResponse,
      TestTriageModelRequest
    >({
      query: ({ body, modelId, versionId }) => ({
        body: body,
        method: 'POST',
        url: `${triageV1}/models/${modelId}/versions/${versionId}/test`,
      }),
    }),
  }),
});

export const {
  useCreateModelMutation,
  useDeleteModelMutation,
  useDeleteVersionModelMutation,
  useDuplicateVersionMutation,
  useGetHelpdeskOptionsQuery,
  useGetHelpdeskQuery,
  useGetModelByIdQuery,
  useGetModelLabelsQuery,
  useGetModelsAndVersionsQuery,
  useGetModelVersionQuery,
  useGetModelVersionsQuery,
  useGetPredictionRunsQuery,
  useGetPredictionTestResultsForTicketQuery,
  useGetPredictionTestResultsQuery,
  useGetSelfServeTriageModelsQuery,
  useGetTicketFieldsQuery,
  useGetTicketsOverviewMetricsQuery,
  useGetTicketsTableQuery,
  useGetTriageModelQuery,
  useGetTriageModelsQuery,
  usePatchSelfServeTriageModelMutation,
  usePublishVersionMutation,
  useSimulateTriageTicketsMutation,
  useTestSelfServeModelMutation,
} = triageApi;
export { triageApi };
