import { SSEL_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { EmbeddedAnalyticsSettingsResponse } from './types';

const embeddedAnalyticsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getEmbeddedAnalyticsSettings: builder.query<
      EmbeddedAnalyticsSettingsResponse,
      void
    >({
      query: () => ({
        url: SSEL_BASE_URL + '/embedded-analytics/',
      }),
    }),
    updateAllowedEmails: builder.mutation<
      EmbeddedAnalyticsSettingsResponse,
      { allowed_emails: Array<string> }
    >({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          embeddedAnalyticsApi.util.updateQueryData(
            'getEmbeddedAnalyticsSettings',
            undefined,
            draft => {
              Object.assign(draft, data);
            },
          ),
        );
      },
      query: ({ allowed_emails }) => ({
        body: { allowed_emails },
        method: 'PUT',
        url: SSEL_BASE_URL + '/embedded-analytics/allowed-emails',
      }),
    }),
  }),
});

export const {
  useGetEmbeddedAnalyticsSettingsQuery,
  useUpdateAllowedEmailsMutation,
} = embeddedAnalyticsApi;
