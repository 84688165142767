import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Direction, ViewType } from './types';

interface CardProps {
  children: React.ReactNode;
  direction?: Direction;
  viewType?: ViewType;
}

const Card = ({
  children,
  direction = 'row',
  viewType = 'primary',
}: CardProps) => {
  const { palette } = useTheme();

  const baseSx = {
    border: `1px solid ${palette.colors.slate[200]}`,
    borderRadius: '8px',
    display: 'flex',
    padding: '24px',
  };

  const viewTypeSx = {
    dashboard: {
      background:
        'linear-gradient(270deg, rgba(189, 0, 255, 0.10) 0%, rgba(56, 102, 245, 0.10) 100%)',
      color: palette.colors.black,
      padding: '20px 24px',
    },
    default: {
      background: `${palette.colors.white}`,
      color: palette.colors.black,
    },
    primary: {
      '.MuiTypography-font16Bold': {
        color: palette.colors.slate[700],
      },
      background:
        'linear-gradient(270deg, rgba(189, 0, 255, 0.10) 0%, rgba(56, 102, 245, 0.10) 100%)',
      color: palette.colors.black,
    },
  };

  const directionSx = {
    column: {
      flexDirection: 'column',
    },
    row: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  };

  return (
    <Box
      sx={{
        ...baseSx,
        ...viewTypeSx[viewType],
        ...directionSx[direction],
      }}
    >
      {children}
    </Box>
  );
};

export default Card;
