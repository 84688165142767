import { styled, useTheme } from '@mui/material';
import { IconExclamationCircle } from '@tabler/icons-react';

import {
  NonIdealState,
  Typography,
} from '@forethought-technologies/forethought-elements';

const TITLE = 'Oh no! Something went wrong.';
const SUBTITLE =
  'Our team has been notified of the issue and will fix it as soon as possible.';

export default function ErrorMessage() {
  const { palette } = useTheme();

  return (
    <Container>
      <NonIdealState
        icon={
          <IconContainer>
            <IconExclamationCircle />
          </IconContainer>
        }
        title={TITLE}
      >
        <Typography color={palette.colors.grey[600]} variant='font16Bold'>
          {SUBTITLE}
        </Typography>
      </NonIdealState>
    </Container>
  );
}

const Container = styled('div')`
  background-color: ${({ theme }) => theme.palette.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const IconContainer = styled('div')`
  height: 90px;
  width: 90px;

  > svg {
    fill: none;
    stroke: ${({ theme }) => theme.palette.colors.purple[500]};
    height: 100%;
    width: 100%;
  }
`;
