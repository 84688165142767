import React from 'react';

import Suggestions, {
  SuggestionsProps,
} from 'src/pages/workflow-builder-edit/suggestions/Suggestions';

type OmittedProps = {
  displayField: string;
  fields: Array<string>;
  idField: string;
  storeFields: Array<string>;
};

export const ContextVariableSuggestions: React.FC<
  React.PropsWithChildren<Omit<SuggestionsProps, keyof OmittedProps>>
> = React.memo(({ ...otherProps }) => (
  <Suggestions
    {...otherProps}
    displayField='context_variable_name'
    fields={['context_variable_name']}
    idField='context_variable_id'
    storeFields={['context_variable_name', 'context_variable_id']}
  />
));

ContextVariableSuggestions.displayName = 'ContextVariableSuggestions';
