import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getDiscoverAutomations } from './thunks';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { selectIntentWorkflowId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import type { RootState } from 'src/store/rootReducer';
import {
  DiscoverAutomationsByTypeResponse,
  DiscoverErrorResponse,
} from 'src/types/DiscoverTypes';

type DataState = {
  automations: {
    data: DiscoverAutomationsByTypeResponse[];
    error: DiscoverErrorResponse | null;
    loading: boolean;
  };
};

// Initial state
export const initialState: DataState = {
  automations: {
    data: [],
    error: null,
    loading: false,
  },
};

// Main
const dataSlice = createSlice({
  extraReducers(builder) {
    builder.addCase(getDiscoverAutomations.fulfilled, (state, { payload }) => {
      state.automations.data = payload;
      state.automations.loading = false;
      state.automations.error = null;
    });
    builder.addCase(getDiscoverAutomations.pending, state => {
      state.automations.data = [];
      state.automations.loading = true;
    });
    builder.addCase(getDiscoverAutomations.rejected, (state, { payload }) => {
      state.automations.error = payload ?? null;
      state.automations.loading = false;
    });
  },
  initialState,
  name: 'data',
  reducers: {},
});

export const selectDiscoverAutomations = (
  state: RootState,
): DataState['automations'] => state.data.automations;

export const selectDiscoverTopicsByIntentId = createSelector(
  [
    selectIntentWorkflowId,
    (state: RootState): DiscoverAutomationsByTypeResponse[] =>
      state.data.automations.data,
  ],
  (intentWorkflowId, dicoverAutomations) => {
    let discoverTopics: Array<DiscoverTopic> = [];

    const solveWorkflowAutomations = dicoverAutomations.find(
      automation => automation.type === 'solve_workflow',
    );

    solveWorkflowAutomations?.automations.forEach(automation => {
      if (
        automation.automated_object.automated_object_id === intentWorkflowId
      ) {
        discoverTopics = discoverTopics.concat(automation.topics);
      }
    });

    return discoverTopics;
  },
);

export default dataSlice.reducer;
