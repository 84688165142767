import { SolveChannels } from 'src/utils/enums';

/**
 * Function that returns 'Cost Savings' description for Overview cards.
 * @param isSolveWorkflows {boolean} - Represents if user is currently on Solve Workflows tab.
 * @returns String with description for 'Cost Savings' card.
 */
const getCostSavingsDescription = (isSolveWorkflows: boolean): string => {
  if (isSolveWorkflows) {
    return 'Resolutions x cost per ticket';
  } else {
    return 'Instant Resolution x cost per ticket';
  }
};

/**
 * Function that returns 'Self-Serve Rate' description for Overview cards.
 * @param isSolveWorkflows {boolean} - Represents if user is currently on Solve Workflows tab.
 * @param channel {string} - Current data channel the user is on. e.g: Widget vs Email.
 * @returns String with description for 'Self-Serve Rate' card.
 */
const getSelfServeDescription = (
  isSolveWorkflows: boolean,
  channel: string,
): string => {
  if (isSolveWorkflows) {
    return 'Percentage of Resolutions over all non-empty conversations. We discard dropoffs or conversation with no significant interactions from the user.';
  } else {
    switch (channel) {
      case SolveChannels.WIDGET:
      case SolveChannels.API:
        return 'Instant Resolutions / Total Interactions';
      default:
        return 'Instant Resolution / Total tickets';
    }
  }
};

/**
 * Function that returns 'CSAT' description for Overview cards.
 * @param isSolveWorkflows {boolean} - Represents if user is currently on Solve Workflows tab.
 * @returns String with description for 'CSAT' card.
 */
const getCsatDescription = (isSolveWorkflows: boolean): string => {
  if (isSolveWorkflows) {
    return 'Percentage of 4-star and 5-star ratings over the total number of responses.';
  } else {
    return 'Positive CSAT survey responses for Agatha / Overall responses';
  }
};

/**
 * Tooltip description mapping.
 * @returns Tooltip description mapping.
 */
export const overviewTooltips = (
  selectedChannel: string,
  isSolveWorkflows: boolean,
): {
  [index: string]: { [key: string]: string };
} => {
  let tooltipData = {};

  tooltipData = {
    cost_savings: {
      description: getCostSavingsDescription(isSolveWorkflows),
      name: 'Cost Savings',
    },

    csat: {
      description: getCsatDescription(isSolveWorkflows),
      name: 'CSAT',
    },

    self_serve_rate: {
      description: getSelfServeDescription(isSolveWorkflows, selectedChannel),
      name: 'Self-Serve Rate',
    },
  };

  if (!isSolveWorkflows) {
    Object.assign(tooltipData, {
      reopen_rate: {
        description: 'Reopens / Solve Responses',
        name: 'Reopen Rate',
      },
    });
  }

  return tooltipData;
};
