import React from 'react';
import { Button, FormControl, Menu } from '@mui/material';
import { useRemirrorContext } from '@remirror/react';

import { ContextVariableIndentedMenuItems } from '../context-variable-indented-menu-items/ContextVariableIndentedMenuItems';
import CustomThemeProvider from '../theme';
import { replaceContextVariableIdsWithMarkup } from './helpers/replaceContextVariableIdsWithMarkup';
import { type MentionComponentProps } from './types';

export default function ContextVariableTool({
  contextVariables,
  disabled,
}: {
  contextVariables: MentionComponentProps['contextVariables'];
  disabled: boolean;
}) {
  const { commands } = useRemirrorContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const insertContextVariable = (contextVariableId: string) => {
    const mentionMarkup = replaceContextVariableIdsWithMarkup(
      `{{${contextVariableId}}}`,
      contextVariables,
    );
    commands.insertHtml(mentionMarkup);
    commands.focus();
  };

  if (!contextVariables?.length) {
    return null;
  }

  return (
    <CustomThemeProvider>
      <FormControl size='small'>
        <Button
          disabled={disabled}
          onClick={e => setAnchorEl(e.currentTarget)}
          sx={theme => ({
            border: `1px solid ${theme.palette.colors.slate[200]}`,
            color: theme.palette.colors.grey[800],
            maxWidth: '400px',
            padding: '4px 12px',
          })}
        >
          $Variables
        </Button>
        <Menu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          PaperProps={{
            elevation: 1,
            sx: {
              maxHeight: 300,
            },
          }}
        >
          <ContextVariableIndentedMenuItems
            onClick={option => {
              setAnchorEl(null);
              insertContextVariable(option.id);
            }}
            options={contextVariables}
          />
        </Menu>
      </FormControl>
    </CustomThemeProvider>
  );
}
