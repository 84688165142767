import {
  ContextVariableListItems,
  FormField,
  FormStepFieldsType,
  WorkflowConfigDict,
} from 'src/services/apiInterfaces';
import { StepTypes } from 'src/utils/enums';

/**
 * Generate list of all context variables and store in redux.
 * This will be consumed in dropdown component and parsing mentions.
 */
const generateContextVariablesList = (
  workflowConfigData: WorkflowConfigDict,
): Array<ContextVariableListItems> => {
  const contextVariablesDict = workflowConfigData?.context_variables;
  const stepMap = workflowConfigData?.step_map;
  // Variables
  let list: Array<ContextVariableListItems> = [];

  for (const contextVariableId in contextVariablesDict) {
    const stepId = contextVariablesDict[contextVariableId].created_by_step;
    const step = stepMap[stepId];
    const type = contextVariablesDict[contextVariableId].context_variable_type;
    let name = '';
    if (step.step_name) {
      if (step.step_type === StepTypes.BUTTONS) {
        name = step.step_name;
      } else if (step.step_type === StepTypes.FORM) {
        (step.step_fields as FormStepFieldsType).form_fields.map(
          ({ output_variable, placeholder }: FormField) => {
            if (contextVariableId === output_variable) {
              name = `${step.step_name}.${placeholder}`;
            }
          },
        );
      } else if (step.step_type === StepTypes.ZENDESK_TICKET_CREATION) {
        name = `${step.step_name}.ticket id`;
      } else if (step.step_type === StepTypes.ZENDESK_CHAT_HANDOFF) {
        name = `${step.step_name}.handoff-success`;
      } else if (step.step_type === StepTypes.ARTICLE_SUGGESTION) {
        name = `${step.step_name}.article-ids`;
      }
      list.push({ displayName: name, id: contextVariableId, type });
    }
  }

  // Filter Context Variables with no display name
  list = list.filter(({ displayName }) => !!displayName && displayName !== ' ');

  return list;
};

export { generateContextVariablesList };
