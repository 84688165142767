import { PreviewLogProps } from 'src/slices/workflow-preview/types';

interface ForethoughtMessageEvent extends MessageEvent {
  data: {
    message: string;
    sender: 'forethought';
  };
}

interface PreviewPageLoadedMessageEvent extends ForethoughtMessageEvent {
  data: ForethoughtMessageEvent['data'] & {
    message: 'previewPageLoaded';
  };
}

interface ForethoughtWidgetLoadedMessageEvent extends ForethoughtMessageEvent {
  data: ForethoughtMessageEvent['data'] & {
    message: 'forethoughtWidgetLoaded';
  };
}

interface ForethoughtWidgetLoadedEvent extends MessageEvent {
  data: ForethoughtMessageEvent['data'] & {
    event: 'forethoughtWidgetLoaded';
  };
}

export interface ForethoughtWidgetPreviewLogsEvent extends MessageEvent {
  data: {
    conversationId?: string;
    event: 'forethoughtWidgetPreviewLogsEvent';
    previewLogs?: PreviewLogProps[];
  };
}

export interface ForethoughtWidgetPreviewRestartEvent extends MessageEvent {
  data: {
    event: 'forethoughtWidgetPreviewRestartEvent';
  };
}

export interface ForethoughtPreviewInteractiveEmailClickedEvent
  extends MessageEvent {
  data: {
    event: '"forethoughtPreviewInteractiveEmailClicked"';
    token: string;
  };
}

const isForethoughtMessageEvent = (
  event: MessageEvent,
): event is ForethoughtMessageEvent =>
  'sender' in event.data && event.data.sender === 'forethought';

export const isPreviewPageLoadedMessageEvent = (
  event: MessageEvent,
): event is PreviewPageLoadedMessageEvent =>
  isForethoughtMessageEvent(event) &&
  event.data.message === 'previewPageLoaded';

export const isForethoughtWidgetLoadedMessageEvent = (
  event: MessageEvent,
): event is ForethoughtWidgetLoadedMessageEvent =>
  isForethoughtMessageEvent(event) &&
  event.data.message === 'forethoughtWidgetLoaded';

export const isForethoughtWidgetLoadedEvent = (
  event: MessageEvent,
): event is ForethoughtWidgetLoadedEvent =>
  event.data.event === 'forethoughtWidgetLoaded';

export const isForethoughtWidgetPreviewLogsEvent = (
  event: MessageEvent,
): event is ForethoughtWidgetPreviewLogsEvent =>
  event.data.event === 'forethoughtWidgetPreviewLogsEvent';

export const isForethoughtWidgetPreviewRestartEvent = (
  event: MessageEvent,
): event is ForethoughtWidgetPreviewRestartEvent =>
  event.data.event === 'forethoughtWidgetPreviewRestartEvent';

export const isForethoughtPreviewInteractiveEmailClickedEvent = (
  event: MessageEvent,
): event is ForethoughtPreviewInteractiveEmailClickedEvent =>
  event.data.event === 'forethoughtPreviewInteractiveEmailClicked';
