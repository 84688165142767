import React from 'react';
import Box from '@mui/material/Box';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  DiscoverCardBottomLinkContainer,
  DiscoverCardContainer,
  DiscoverCardContainerLink,
} from '../dashboard-pages/discover-dashboard-page/styles';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface DiscoverCardProps {
  children?: React.ReactNode;
  featureName?: string;
  headerComponent?: React.ReactNode;
  linkText?: string;
  linkUrl?: string;
  onClick?: () => void;
}

const DiscoverCard = ({
  children,
  featureName,
  headerComponent,
  linkText,
  linkUrl,
  onClick,
}: DiscoverCardProps) => {
  const Container = linkUrl ? DiscoverCardContainerLink : DiscoverCardContainer;
  return (
    <Container
      data-appcues-target={getAppCuesId({
        componentType: 'container',
        featureName: featureName || '',
        pageName: 'discover',
        subType: '',
      })}
      onClick={onClick}
      to={linkUrl ?? ''}
    >
      <Box height='100%' p={3}>
        {headerComponent}
        {children}
      </Box>
      {linkText && (
        <DiscoverCardBottomLinkContainer>
          <Typography color={theme.palette.colors.grey[800]} variant='font12'>
            {linkText}
          </Typography>
        </DiscoverCardBottomLinkContainer>
      )}
    </Container>
  );
};

export default DiscoverCard;
