import React from 'react';
import { Box, Theme } from '@mui/material';
import {
  IconBrandTelegram,
  IconCarouselHorizontal,
  IconMessageHeart,
  IconMoodSmileBeam,
  IconPaperclip,
  IconPhotoPlus,
  IconRotateClockwise,
  IconRoute,
  IconVariable,
} from '@tabler/icons-react';

import addConditionIcon from 'src/assets/images/add-condition.svg';
import addFormIcon from 'src/assets/images/add-form.svg';
import addOptionIcon from 'src/assets/images/add-option.svg';
import addVideoIcon from 'src/assets/images/add-video.svg';
import articleIcon from 'src/assets/images/article-icon.svg';
import actionIcon from 'src/assets/images/dynamic-article-suggestion-revamped-big.svg';
import regularActionIcon from 'src/pages/workflow-builder-edit/assets/icons/filled-action.svg';
import { QuasiStepTypes, StepTypes } from 'src/utils/enums';

const iconMap: Partial<
  Record<
    StepTypes | QuasiStepTypes,
    { bgColor: string | ((theme: Theme) => string); icon: React.ReactNode }
  >
> = {
  [StepTypes.BUTTONS]: {
    bgColor: theme => theme.palette.colors.blue[300],
    icon: <img alt='' src={addOptionIcon} />,
  },
  [StepTypes.FORM]: {
    bgColor: theme => theme.palette.colors.purple[300],
    icon: <img alt='' src={addFormIcon} />,
  },
  [StepTypes.CONDITION]: {
    bgColor: theme => theme.palette.colors.yellow[300],
    icon: <img alt='' src={addConditionIcon} />,
  },
  [StepTypes.IMAGE]: {
    bgColor: theme => theme.palette.colors.brand.pastelGreen,
    icon: <IconPhotoPlus color='black' size={20} />,
  },
  [StepTypes.EMBED]: {
    bgColor: theme => theme.palette.colors.red[300],
    icon: <img alt='' src={addVideoIcon} />,
  },
  [StepTypes.GO_TO_INTENT]: {
    bgColor: theme => theme.palette.colors.green[600],
    icon: <IconRoute color='white' size={20} />,
  },
  [StepTypes.RESTART_CONVERSATION]: {
    bgColor: theme => theme.palette.colors.green[600],
    icon: <IconRotateClockwise color='white' size={20} />,
  },
  [StepTypes.FLAMETHROWER_API_CALL]: {
    bgColor: theme => theme.palette.colors.blue[500],
    icon: <img alt='' src={regularActionIcon} />,
  },
  [StepTypes.GO_TO_HANDOFF]: {
    bgColor: '#EE39BB',
    icon: <IconBrandTelegram color='white' size={20} />,
  },
  [StepTypes.ARTICLE_SUGGESTION]: {
    bgColor: 'white',
    icon: <img alt='' src={articleIcon} />,
  },
  [StepTypes.SET_CONTEXT_VARIABLE]: {
    bgColor: theme => theme.palette.colors.grey[800],
    icon: <IconVariable color='white' size={20} />,
  },
  [StepTypes.DYNAMIC_CARD]: {
    bgColor: theme => theme.palette.colors.green[300],
    icon: <IconCarouselHorizontal color='black' size={20} />,
  },
  [QuasiStepTypes.FEEDBACK]: {
    bgColor: '#29B5E8',
    icon: <IconMessageHeart color='white' size={20} />,
  },
  [QuasiStepTypes.DYNAMIC_ARTICLE_SUGGESTION]: {
    bgColor: 'transparent',
    icon: <img alt='' src={actionIcon} />,
  },
  [StepTypes.CSAT_TRIGGER_POINT]: {
    bgColor: theme => theme.palette.colors.brand.pastelPink,
    icon: <IconMoodSmileBeam color='black' size={20} />,
  },
  [StepTypes.END_INTERACTIVE_EMAIL_CHAT]: {
    bgColor: theme => theme.palette.colors.black,
    icon: <IconBrandTelegram color='white' size={20} />,
  },
  [StepTypes.ATTACHMENT_ANALYSIS_UPLOAD]: {
    bgColor: '#ACBFFF',
    icon: <IconPaperclip color='black' size={20} />,
  },
};

const CircularStepIcon = ({
  stepType,
}: {
  stepType: StepTypes | QuasiStepTypes;
}) => {
  const { bgColor, icon } = iconMap[stepType] ?? {};

  return (
    <Box
      alignItems='center'
      bgcolor={bgColor}
      border={
        bgColor === 'white' ? '1px solid var(--color-slate-200)' : undefined
      }
      borderRadius='17px'
      display='flex'
      mr={1}
      p={`6px 6px 6px ${bgColor === 'transparent' ? 0 : 6}px`}
    >
      {icon}
    </Box>
  );
};

export default CircularStepIcon;
