import {
  formatMessageFromBackendForReactMentions,
  formatReactMentionsMessageToSendToBackend,
} from 'src/pages/solve-workflows/utils/stepHelper';
import { FlatCv } from 'src/utils/actionBuilder/helpers';

export const formatContextVariableForDisplay = (
  contextVariables: FlatCv[],
  value: string,
): string => {
  return formatMessageFromBackendForReactMentions(
    contextVariables.map(cv => ({
      displayName: cv.context_variable_name,
      id: cv.context_variable_id ?? '',
      type:
        'context_variable_type' in cv ? cv.context_variable_type : undefined,
    })),
    value || '',
  );
};

export const formatContextVariableForPersistence = (
  contextVariables: FlatCv[],
  value: string,
): string => {
  return formatReactMentionsMessageToSendToBackend(
    contextVariables.map(cv => ({
      displayName: cv.context_variable_name,
      id: cv.context_variable_id ?? '',
      type:
        'context_variable_type' in cv ? cv.context_variable_type : undefined,
    })),
    value,
  );
};
