export const formatBooleanValue = (
  value?: boolean | null,
  falseLabel = 'No',
) => {
  if (typeof value === 'boolean') {
    if (value === true) {
      return 'Yes';
    }
    return falseLabel;
  }
  return '-';
};
