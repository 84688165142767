import './FuzzySearchModal.scss';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import ModalItem from '../modal-item/ModalItem';
import SearchBar from '../search-bar/SearchBar';
import { DISCOVER_SEARCH_PARAMS_LOCAL_STORAGE_KEY } from 'src/constants/discover';
import { getSearchOrganizationsData } from 'src/reducers/superAdminReducer/superAdminReducer';
import { OrgsDict } from 'src/services/apiInterfaces';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';

interface FuzzySearchProps {
  className?: string;
  handleModalVisibility(): void;
  reference?: React.RefObject<HTMLDivElement>;
}

const FuzzySearchModal: React.FC<React.PropsWithChildren<FuzzySearchProps>> = (
  props: FuzzySearchProps,
) => {
  const [search, setSearch] = useState<string>('');
  const [selectedOrg, setSelectedOrg] = useState<OrgsDict | null>(null);
  const [isMouseIn, setMouseIn] = useState<boolean>(false);
  const isComponentMounted = useRef(false);
  const {
    className,
    handleModalVisibility: handleModalVisibility,
    reference,
  } = props;
  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const orgs = useSelector(getSearchOrganizationsData);

  const toggleOrg = (org: OrgsDict) => {
    if (
      selectedOrg?.org_name === org.org_name &&
      selectedOrg?.org_id === org.org_id
    ) {
      return setSelectedOrg(null);
    }
    return setSelectedOrg(org);
  };

  useEffect(() => {
    if (reference) {
      document.addEventListener('mousedown', e => {
        if (!isMouseIn) {
          return handleModalsVisibility(
            isComponentMounted.current,
            e,
            reference,
            ContainerRef,
            handleModalVisibility,
          );
        }
      });
    }

    return () => {
      isComponentMounted.current = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted.current),
      );
    };
  }, [handleModalVisibility, isMouseIn, reference]);

  const applyBreakdownButton = classNames(
    'BreakdownModal-applyBreakdownButton',
    {
      'BreakdownModal-EnabledApplyBreakdownButton': selectedOrg !== null,
    },
  );

  const getFields = (): Array<OrgsDict> => {
    const fields: Array<OrgsDict> = [];
    orgs.map((field: OrgsDict) => {
      fields.push(field);
    });
    return fields;
  };

  const matchedItems = getFields().filter((field: OrgsDict) => {
    if (field.org_name.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
  });

  const handleOnChange = (value: string) => {
    setSearch(value);
  };

  return (
    <div
      className={classNames('BreakdownModal-container', className)}
      id='FuzzySearch'
      onMouseEnter={() => setMouseIn(true)}
      onMouseLeave={() => setMouseIn(false)}
      ref={reference}
    >
      <p className='FuzzySearch-title'>Select An Organization</p>
      <SearchBar
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleOnChange(e.target.value);
        }}
      />

      <div className='BreakdownModal-content' ref={ContainerRef}>
        {!matchedItems.length ? (
          <span className={'BreakdownModal-noResults'}>No results</span>
        ) : (
          matchedItems.map(field => {
            const isSelected =
              selectedOrg?.org_name === field.org_name &&
              selectedOrg?.org_id === field.org_id;
            return (
              <ModalItem
                fieldType={'ORG'}
                isDark
                isSelected={isSelected}
                itemValue={field.org_name}
                key={field.org_name}
                onClickFunction={() => toggleOrg(field)}
              />
            );
          })
        )}
      </div>
      <button
        className={applyBreakdownButton}
        disabled={selectedOrg ? false : true}
        onClick={() => {
          localStorage.setItem('org', JSON.stringify(selectedOrg));
          // search param options vary from org to org. We need to invalidate upon org change
          // to prevent unexpected behavior when restoring the search params
          localStorage.removeItem(DISCOVER_SEARCH_PARAMS_LOCAL_STORAGE_KEY);
          handleModalVisibility();
          location.href = '/';
        }}
      >
        Confirm
      </button>
    </div>
  );
};

export default FuzzySearchModal;
