import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import { FetchAction, SortObject } from '../../types/types';
import {
  GET_ANSWERS_BREAKDOWN_VALUES_SUCESS,
  GET_ANSWERS_CHART_DATA_SUCCESS,
  GET_ANSWERS_TABLE_DATA_SUCCESS,
  GET_DATA_FAILURE,
} from '../actionTypes';
import { PageSettingsActions } from '../actionTypes';
import { loadChartYAxis } from '../pageSettings/pageSettings';
import { PageSettingsState } from 'src/reducers/pageSettingsReducer/types';
import { ChartDataDict, TableDataDict } from 'src/services/apiInterfaces';
import { getAnswersDataAPI } from 'src/services/apiV1';
import type { RootState } from 'src/store/rootReducer';

export const getAnswersTableDataSuccess = createAction<TableDataDict>(
  GET_ANSWERS_TABLE_DATA_SUCCESS,
);

export const getAnswersChartDataSuccess = createAction<ChartDataDict>(
  GET_ANSWERS_CHART_DATA_SUCCESS,
);

export const getAnswersBreakdownValuesSuccess = createAction<string[]>(
  GET_ANSWERS_BREAKDOWN_VALUES_SUCESS,
);

export const setLoading = createAction<boolean>(
  PageSettingsActions.SET_LOADING,
);

export const getDataFailure = (error: string) => ({
  error,
  type: GET_DATA_FAILURE,
});

export const getAnswersData: Function =
  () =>
  (dispatch: Dispatch, getState: Function): Promise<void | FetchAction> => {
    const state: RootState = getState();

    const { viewMode } = state.pageSettings as PageSettingsState;
    const selectedY = state.pageSettings.chartYAxis;

    dispatch(setLoading(true));

    return getAnswersDataAPI(state)
      .then((data: ChartDataDict & TableDataDict) => {
        if (viewMode === 'Chart') {
          dispatch(getAnswersChartDataSuccess(data));
          dispatch(getAnswersBreakdownValuesSuccess(data.breakdown_values));

          if (selectedY) {
            dispatch(loadChartYAxis(selectedY));
          }
        } else if (viewMode === 'Table') {
          dispatch(getAnswersTableDataSuccess(data));
        }

        dispatch(setLoading(false));
      })
      .catch((err: Error) => {
        dispatch(getDataFailure(err.message));
        dispatch(setLoading(false));
      });
  };

export const sortTable = createAction<SortObject>(
  PageSettingsActions.SORT_TABLE,
);
