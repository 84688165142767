import { css, GlobalStyles } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import { breakpoints } from './breakpoints';

// font size base 16px
const GlobalStyle = () => (
  <GlobalStyles
    styles={css`
      :root {
        --background-color-sidebar-selected: rgba(255, 255, 255, 0.15);
        --background-color-hover: rgba(0, 0, 0, 0.05);
        --background-color-focus: rgba(
          0,
          0,
          0,
          0.1
        ); // theme.palette.colors.grey[100]
        --border-radius-button: 4px;
        --border-radius-section: 8px;
        --border-radius-circle: 50%;
        --border-radius-pill: 60px;
        --box-shadow-sidebar: 4px 0px 12px rgba(0, 0, 0, 0.15);
        --box-shadow-modal: 0px 0px 1px rgba(0, 12, 32, 0.02),
          2px 8px 16px rgba(3, 17, 38, 0.107135);

        // Red
        --color-red-100: ${theme.palette.colors.red[100]};
        --color-red-200: ${theme.palette.colors.red[200]};
        --color-red-300: ${theme.palette.colors.red[300]};
        --color-red-400: ${theme.palette.colors.red[400]};
        --color-red-500: ${theme.palette.colors.red[500]};
        --color-red-600: ${theme.palette.colors.red[600]};
        --color-red-700: ${theme.palette.colors.red[700]};
        --color-red-800: ${theme.palette.colors.red[800]};
        --color-red-900: ${theme.palette.colors.red[900]};

        // Blue
        --color-blue-100: ${theme.palette.colors.blue[100]};
        --color-blue-200: ${theme.palette.colors.blue[200]};
        --color-blue-300: ${theme.palette.colors.blue[300]};
        --color-blue-400: ${theme.palette.colors.blue[400]};
        --color-blue-500: ${theme.palette.colors.blue[500]};
        --color-blue-600: ${theme.palette.colors.blue[600]};
        --color-blue-700: ${theme.palette.colors.blue[700]};
        --color-blue-800: ${theme.palette.colors.blue[800]};
        --color-blue-900: ${theme.palette.colors.blue[900]};

        // Purple
        --color-purple-100: ${theme.palette.colors.purple[100]};
        --color-purple-200: ${theme.palette.colors.purple[200]};
        --color-purple-300: ${theme.palette.colors.purple[300]};
        --color-purple-400: ${theme.palette.colors.purple[400]};
        --color-purple-500: ${theme.palette.colors.purple[500]};
        --color-purple-600: ${theme.palette.colors.purple[600]};
        --color-purple-700: ${theme.palette.colors.purple[700]};
        --color-purple-800: ${theme.palette.colors.purple[800]};
        --color-purple-900: ${theme.palette.colors.purple[900]};

        // Grey
        --color-grey-100: ${theme.palette.colors.grey[100]};
        --color-grey-200: ${theme.palette.colors.grey[200]};
        --color-grey-300: ${theme.palette.colors.grey[300]};
        --color-grey-400: ${theme.palette.colors.grey[400]};
        --color-grey-500: ${theme.palette.colors.grey[500]};
        --color-grey-600: ${theme.palette.colors.grey[600]};
        --color-grey-700: ${theme.palette.colors.grey[700]};
        --color-grey-800: ${theme.palette.colors.grey[800]};
        --color-grey-900: ${theme.palette.colors.grey[900]};

        // Slate
        --color-slate-100: ${theme.palette.colors.slate[100]};
        --color-slate-200: ${theme.palette.colors.slate[200]};
        --color-slate-300: ${theme.palette.colors.slate[300]};
        --color-slate-400: ${theme.palette.colors.slate[400]};
        --color-slate-500: ${theme.palette.colors.slate[500]};
        --color-slate-600: ${theme.palette.colors.slate[600]};
        --color-slate-700: ${theme.palette.colors.slate[700]};
        --color-slate-800: ${theme.palette.colors.slate[800]};
        --color-slate-900: ${theme.palette.colors.slate[900]};

        // Text
        --color-text-primary: ${Colors.ui.text.primary};
        --color-text-secondary: ${Colors.ui.text.secondary};
        --color-text-tertiary: ${Colors.ui.text.tertiary};
        --color-text-quaternary: ${Colors.ui.text.quaternary};
        --color-text-placeholder: ${Colors.ui.text.placeholder};
        --color-text-disabled: ${Colors.ui.text.disabled};
        --color-text-white: ${theme.palette.colors.white};

        // Backgrounds
        --color-background-default: ${Colors.ui.backgrounds
          .default}; // i.e. white
        --color-background-purple: ${Colors.ui.backgrounds.purple};
        --color-background-blue: ${Colors.ui.backgrounds.blue};
        --color-background-red: ${Colors.ui.backgrounds.red};
        --color-background-yellow: ${Colors.ui.backgrounds.yellow};
        --color-background-green: ${Colors.ui.backgrounds.green};
        --color-background-slate: ${Colors.ui.backgrounds.slate};

        --color-background-selection: #bed6fb;

        // Links
        --color-link-default: ${Colors.ui.link.default};
        --color-link-hover: ${Colors.ui.link.hover};
        --color-link-active: ${Colors.ui.link.active};
        --color-link-disabled: ${Colors.ui.link.disabled};

        // Semantic
        --color-success-default: ${Colors.ui.semantic.success.default};
        --color-success-hovered: ${Colors.ui.semantic.success.hovered};
        --color-warning-default: ${Colors.ui.semantic.warning.default};
        --color-warning-hovered: ${Colors.ui.semantic.warning.hovered};
        --color-destructive-default: ${Colors.ui.semantic.destructive.default};
        --color-destructive-hovered: ${Colors.ui.semantic.destructive.hovered};

        --color-solid-black: ${theme.palette.colors.black};
        --color-solid-white: ${theme.palette.colors.white};
        --gradient-blue-purple: linear-gradient(
          90deg,
          ${theme.palette.colors.blue[600]} 0%,
          ${theme.palette.colors.purple[500]} 100%
        );

        --duration-fast: 200ms;

        // root font size = 16px
        --font-size-2xl: 2rem; // 32px
        --font-size-xl: 1.75rem; // 28px
        --font-size-l: 1.5rem; // 24px
        --font-size-m: 1.25rem; // 20px
        --font-size-ms: 1.125rem; // 18px
        --font-size-base: 1rem; // 16px
        --font-size-s: 0.875rem; // 14px
        --font-size-xs: 0.75rem; // 12px
        --font-size-2xs: 0.625rem; // 10px
        --font-weight-light: 300;
        --font-weight-regular: 400;
        --font-weight-medium: 500;
        --font-weight-semibold: 600;
        --font-weight-bold: 600;

        --width-sidebar: 250px;
        --width-minimized-sidebar: 56px;
        @media only screen and ${breakpoints.mobile} {
          --width-sidebar: 100%;
        }

        // overrides third party
        --lightSquidInk: var(--color-purple-500);
      }
    `}
  ></GlobalStyles>
);

export default GlobalStyle;
