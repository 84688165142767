import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DateRange } from 'src/types/types';
import { dateRangeToTimeFilter } from 'src/utils/discover/helpers';

interface OverviewTitleProps {
  dateRange: DateRange;
  title: string;
}

const OverviewTitle = ({ dateRange, title }: OverviewTitleProps) => {
  const { palette } = useTheme();
  const dateLabel = dateRangeToTimeFilter(dateRange);

  return (
    <Box
      alignItems='center'
      columnGap={1}
      display='flex'
      sx={{
        '.MuiTypography-root:nth-child(2)': {
          fontWeight: 500,
        },
      }}
    >
      <Typography variant='font24'>{title}</Typography>
      <Typography color={palette.colors.grey[400]} variant='font24'>
        {dateLabel.label}
      </Typography>
    </Box>
  );
};

export default OverviewTitle;
