import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { selectIsWorkflowActive } from '../canvas-workflow-builder/workflowBuilderSlice';
import { createIdP } from '../sso/thunks';
import {
  GlobalCopyToastOptions,
  GlobalDiscoverArticleOptions,
  GlobalDiscoverOptions,
  GlobalHandoffDrawerOptions,
  GlobalIntentDrawerOptions,
  GlobalToastOptions,
  GlobalWorkflowBuilderOptions,
  IntentDrawerType,
  UISliceState,
} from './types';
import { patchTopic } from 'src/actions/discover/discover';
import { PatchTopicRequest } from 'src/reducers/discoverReducer/types';
import {
  selectIntentData,
  selectIsIntentEnabled,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import type { RootState } from 'src/store/rootReducer';
import { Intent } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

const getSidebarToggleFromStorage = (): boolean => {
  try {
    const item = window.localStorage.getItem('sidebarToggle');

    return item ? JSON.parse(item) : true;
  } catch (error) {
    return true;
  }
};

export const emptyIntent = {
  intent_id: '',
  is_custom: true,
  phrases: [],
  title: '',
};

const initialState: UISliceState = {
  globalCopyToastOptions: null,
  globalDiscoverArticleOptions: {
    articleSortFilterValue: 'newest',
    feedbackArticleModalId: '',
    selectedArticleId: '',
    shouldShowArticlePublishModal: false,
  },
  globalDiscoverOptions: {
    patchedField: null,
    patchedTopic: null,
    postAutomationActionModalOptions: {
      automatedObjectName: '',
      isOpen: false,
      redirectUrl: '',
    },
    reportedTicket: null,
    topicToAutomate: null,
  },
  globalHandoffDrawerOptions: {
    isCreateHandoffDrawerOpen: false,
    isEditing: false,
  },
  globalIntentDrawerOptions: null,
  globalToastOptions: null,
  globalWorkflowBuilderOptions: {
    dialogType: null,
    hasUnsavedChanges: false,
    isCvDrawerOpen: false,
    isTranslationDrawerOpen: false,
  },
  intentToEdit: emptyIntent,
  isDisabledIntentEditionModalVisible: false,
  isSidebarExpanded: getSidebarToggleFromStorage(),
  navbarNotificationToggle: { [Routes.DISCOVER_DASHBOARD]: true },
};

const uiSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(createIdP.rejected, state => {
      state.globalToastOptions = {
        title: 'Something went wrong creating an identity provider',
        variant: 'danger',
      };
    });
    builder.addCase(patchTopic.fulfilled, (state, action) => {
      const changedField = Object.keys(
        action.meta.arg.body,
      )[0] as keyof PatchTopicRequest;
      state.globalDiscoverOptions.patchedTopic = action.payload;
      state.globalDiscoverOptions.patchedField = changedField;
    });
  },
  initialState,
  name: 'ui',
  reducers: {
    closeGlobalIntentDrawer(state) {
      state.globalIntentDrawerOptions = null;
      state.isDisabledIntentEditionModalVisible = false;
      state.intentToEdit = emptyIntent;
    },
    openGlobalIntentDrawer(
      state,
      action: PayloadAction<GlobalIntentDrawerOptions | null>,
    ) {
      state.globalIntentDrawerOptions = action.payload;
    },
    setGlobalCopyToastOptions(
      state,
      action: PayloadAction<GlobalCopyToastOptions | null>,
    ) {
      state.globalCopyToastOptions = action.payload;
    },
    setGlobalDiscoverArticleOptions(
      state,
      action: PayloadAction<Partial<GlobalDiscoverArticleOptions>>,
    ) {
      state.globalDiscoverArticleOptions = {
        ...state.globalDiscoverArticleOptions,
        ...action.payload,
      };
    },
    setGlobalDiscoverOptions(
      state,
      action: PayloadAction<Partial<GlobalDiscoverOptions>>,
    ) {
      state.globalDiscoverOptions = {
        ...state.globalDiscoverOptions,
        ...action.payload,
      };
    },
    setGlobalHandoffDrawerOptions(
      state,
      action: PayloadAction<GlobalHandoffDrawerOptions>,
    ) {
      state.globalHandoffDrawerOptions = {
        ...state.globalHandoffDrawerOptions,
        ...action.payload,
      };
    },
    setGlobalToastOptions(
      state,
      action: PayloadAction<GlobalToastOptions | null>,
    ) {
      state.globalToastOptions = action.payload;
    },
    setGlobalWorkflowBuilderOptions(
      state,
      action: PayloadAction<Partial<GlobalWorkflowBuilderOptions>>,
    ) {
      state.globalWorkflowBuilderOptions = {
        ...state.globalWorkflowBuilderOptions,
        ...action.payload,
      };
    },
    setIntentToEdit(state, action: PayloadAction<Intent>) {
      state.intentToEdit = action.payload;
    },
    setIsDisabledIntentEditionModalVisible(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isDisabledIntentEditionModalVisible = action.payload;
    },
    setIsSidebarExpanded(state, action: PayloadAction<boolean>) {
      state.isSidebarExpanded = action.payload;
    },
    setNavbarNotificationToggle(state, action: PayloadAction<Routes>) {
      state.navbarNotificationToggle[action.payload] =
        !state.navbarNotificationToggle[action.payload];
    },
  },
});

export const {
  closeGlobalIntentDrawer,
  openGlobalIntentDrawer,
  setGlobalCopyToastOptions,
  setGlobalDiscoverArticleOptions,
  setGlobalDiscoverOptions,
  setGlobalHandoffDrawerOptions,
  setGlobalToastOptions,
  setGlobalWorkflowBuilderOptions,
  setIntentToEdit,
  setIsDisabledIntentEditionModalVisible,
  setIsSidebarExpanded,
  setNavbarNotificationToggle,
} = uiSlice.actions;

export const selectGlobalToastOptions = (
  state: RootState,
): GlobalToastOptions | null => {
  return state.ui.globalToastOptions;
};

export const selectGlobalCopyToastOptions = (
  state: RootState,
): GlobalCopyToastOptions | null => {
  return state.ui.globalCopyToastOptions;
};

export const selectGlobalDiscoverOptions = (
  state: RootState,
): GlobalDiscoverOptions => {
  return state.ui.globalDiscoverOptions;
};

export const selectGlobalDiscoverArticleOptions = (
  state: RootState,
): GlobalDiscoverArticleOptions => {
  return state.ui.globalDiscoverArticleOptions;
};

export const selectGlobalWorkflowBuilderOptions = (
  state: RootState,
): GlobalWorkflowBuilderOptions => {
  return state.ui.globalWorkflowBuilderOptions;
};

export const selectGlobalHandoffDrawerOptions = (
  state: RootState,
): GlobalHandoffDrawerOptions => {
  return state.ui.globalHandoffDrawerOptions;
};

export const selectIsSidebarExpanded = (state: RootState): boolean =>
  state.ui.isSidebarExpanded;

export const selectNavbarNotificationToggle =
  (route: Routes) =>
  (state: RootState): boolean | undefined => {
    return state.ui.navbarNotificationToggle[route];
  };

export const selectIsDisabledIntentEditionModalVisible = (
  state: RootState,
): boolean => state.ui.isDisabledIntentEditionModalVisible;

export const selectGlobalIntentDrawerOptions = (
  state: RootState,
): UISliceState['globalIntentDrawerOptions'] =>
  state.ui.globalIntentDrawerOptions;

const selectIntentToEdit = (state: RootState): Intent => state.ui.intentToEdit;

export const selectIntentDrawerDataByType = createSelector(
  [
    selectIntentData,
    selectIsIntentEnabled,
    selectIsWorkflowActive,
    selectIntentToEdit,
    // Extract the second argument to pass it on
    (state: RootState, type: IntentDrawerType | null) => type,
  ],
  (intentData, isIntentActive, isWorkflowActive, intentToEdit, type) => {
    const getIsActive = () => {
      if (type === 'landing') {
        return Boolean(intentToEdit.isActive);
      }

      if (type === 'email') {
        return isIntentActive;
      }

      return isWorkflowActive;
    };

    const isActive = getIsActive();

    if (!type) {
      return { intent: null, isActive };
    }

    return {
      intent:
        type === 'landing' || type === 'suggested' ? intentToEdit : intentData,
      isActive,
    };
  },
);

export default uiSlice.reducer;
