import React from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Box, styled, Typography } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Button,
  SelectDropdown,
  TextField,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import {
  JourneyMapTestField,
  useGetConversationLanguageCV,
  useGetData,
} from './hooks';
import partition from 'lodash/fp/partition';
import BaseModal from 'src/components/base-modal';
import ContextVariableInput from 'src/components/context-variable-input';
import Spinner from 'src/components/spinner';
import TriageSampleValuesDropdown from 'src/components/triage-sample-values-dropdown/TriageSampleValuesDropdown';
import { HelpdeskFieldType } from 'src/pages/workflow-builder-edit/types';
import { selectUser } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { testJourney } from 'src/services/email-builder/emailBuilderApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

const DROPDOWN_FIELD_TYPES: HelpdeskFieldType[] = ['sing_select', 'reference'];

interface TestJourneyModalProps {
  intentDefinitionId: string;
  isOpen: boolean;
  setIsTestJourneyModalVisible: (isVisible: boolean) => void;
}

export default function TestJourneyModal({
  intentDefinitionId,
  isOpen,
  setIsTestJourneyModalVisible,
}: TestJourneyModalProps) {
  const dispatch = useAppDispatch();
  const { enabledLanguages, isLoading, testContextVariables, testFields } =
    useGetData(isOpen);

  const getMappedFieldValue = (formFieldValue: string) => {
    const lowerCaseValue = formFieldValue.toLowerCase();
    const isBoolean = lowerCaseValue === 'true' || lowerCaseValue === 'false';
    return isBoolean ? lowerCaseValue === 'true' : formFieldValue;
  };

  const executeTestJourney = async (formFields: Record<string, string>) => {
    const customFieldValues: Record<string, string> = {};
    const context: Record<string, string | boolean> = {};

    const { email, ticketDescription, ticketSubject, ...rest } = formFields;

    for (const [key, value] of Object.entries(rest)) {
      if (!value) {
        continue;
      }
      const field = testFields.find(field => field.id === key);
      const isContextField = !!field?.mapping_type && !!field?.mapping_data;

      if (!field) {
        // This is a context variable.
        context[key] = value;
      } else if (isContextField) {
        context[key] = getMappedFieldValue(value);
      } else {
        customFieldValues[key] = value;
      }
    }

    try {
      await testJourney({
        context,
        custom_field_values: customFieldValues,
        intent_definition_id: intentDefinitionId,
        test_email: email,
        ticket_description: ticketDescription?.trim() || undefined,
        ticket_subject: ticketSubject?.trim() || undefined,
      });
      setIsTestJourneyModalVisible(false);
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 4000,
          title: 'Test email successfully sent.',
          variant: 'main',
        }),
      );
    } catch {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 4000,
          title: 'Error sending test email, please try again.',
          variant: 'danger',
        }),
      );
    }
  };

  return (
    <BaseModal
      headerTitle={
        <Typography variant='font18Bold'>Send Test Email</Typography>
      }
      hideCloseButton
      isOpen={isOpen}
      maxWidth='sm'
      onClose={() => setIsTestJourneyModalVisible(false)}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <TestJourneyForm
          enabledLanguages={enabledLanguages}
          onCancel={() => setIsTestJourneyModalVisible(false)}
          onSubmit={executeTestJourney}
          testContextVariables={testContextVariables}
          testFields={testFields}
        />
      )}
    </BaseModal>
  );
}

interface TestJourneyFormProps {
  enabledLanguages: string[];
  onCancel: () => void;
  onSubmit: (formFields: Record<string, string>) => void;
  testContextVariables: ContextVariable[] | null;
  testFields: JourneyMapTestField[];
}

function TestJourneyForm({
  enabledLanguages,
  onCancel,
  onSubmit,
  testContextVariables,
  testFields,
}: TestJourneyFormProps) {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const { user } = useSelector(selectUser);
  const [triageFields, ticketFields] = partition(
    testField => testField.mapping_type === 'triage',
    testFields,
  );
  const isSolveEmailTranslationEnabled = featureFlags?.includes(
    'solve_email_translation_enabled',
  );

  const { context_variable_id: conversationLanguageCvId = '' } =
    useGetConversationLanguageCV() || {};

  const intialCustomValues = testFields.reduce<Record<string, string>>(
    (acc, curr) => {
      acc[curr.id] = '';
      return acc;
    },
    {},
  );

  const initialCVValues = (testContextVariables || []).reduce<
    Record<string, string>
  >((acc, curr) => {
    acc[curr.context_variable_id] = '';
    return acc;
  }, {});

  const initialLanguageValues =
    conversationLanguageCvId && isSolveEmailTranslationEnabled
      ? { [conversationLanguageCvId]: '' }
      : null;

  const initialValues: Record<string, string> = {
    email: user?.email ?? '',
    ticketDescription: '',
    ticketSubject: '',
    ...intialCustomValues,
    ...initialCVValues,
    ...initialLanguageValues,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box display='flex' flexDirection='column' gap={2} padding='0 24px'>
              <TextField
                label='Test email address'
                name='email'
                onChange={handleChange}
                placeholder='Enter email'
                type='email'
                value={values.email}
              />
              <FormSection
                title='Test Dynamic Article'
                tooltip='After adding a Dynamic Article to an email reponse in this Journey, enter ticket contents to test dynamic article retrieval.'
              >
                <TextField
                  label='Ticket title'
                  name='ticketSubject'
                  onChange={handleChange}
                  placeholder='Enter ticket title'
                  required={false}
                  value={values.ticketSubject}
                />
                <TextField
                  label='Ticket body'
                  multiline
                  name='ticketDescription'
                  onChange={handleChange}
                  placeholder='Enter ticket body'
                  required={false}
                  value={values.ticketDescription}
                />
              </FormSection>
              {isSolveEmailTranslationEnabled && (
                <FormSection
                  title='Multilingual'
                  tooltip='Select a language to test your multilingual content'
                >
                  <SelectDropdown
                    disabled={!enabledLanguages.length}
                    id='language-selector'
                    onChange={e =>
                      setFieldValue(conversationLanguageCvId, e.target.value)
                    }
                    options={enabledLanguages.map(language => ({
                      label: language,
                      value: language,
                    }))}
                    placeholder={
                      enabledLanguages.length
                        ? 'Select a language'
                        : 'There are no languages enabled'
                    }
                    value={values[conversationLanguageCvId]}
                  />
                </FormSection>
              )}
              {!!ticketFields.length && (
                <FormSection
                  title='Test ticket fields'
                  tooltip='Supply help desk ticket fields to allow for testing.'
                >
                  {ticketFields.map(mapping => {
                    const { id, label, options, required, type } = mapping;
                    if (
                      type &&
                      DROPDOWN_FIELD_TYPES.includes(type) &&
                      options
                    ) {
                      return (
                        <SelectDropdown
                          id={id}
                          key={id}
                          label={label}
                          onChange={e => setFieldValue(id, e.target.value)}
                          optional={!required}
                          options={options}
                          placeholder={label}
                          value={values[id]}
                        />
                      );
                    }
                    return (
                      <TextField
                        key={id}
                        label={label}
                        onChange={e => setFieldValue(id, e.target.value)}
                        placeholder='Enter value'
                        value={values[id]}
                      />
                    );
                  })}
                </FormSection>
              )}
              {!!triageFields.length && (
                <FormSection
                  title='Test Triage fields'
                  tooltip='Predefine Triage model outputs to test different paths.'
                >
                  {triageFields.map(mapping => {
                    const { id, label, mapping_data, required } = mapping;
                    return (
                      <TriageSampleValuesDropdown
                        componentId={id}
                        key={id}
                        label={label}
                        model={mapping_data?.model_name ?? ''}
                        onChange={value => setFieldValue(id, value)}
                        optional={!required}
                        value={values[id]}
                      />
                    );
                  })}
                </FormSection>
              )}
              {!!testContextVariables?.length && (
                <FormSection
                  title='Test Context Variables'
                  tooltip="Predefine testing conditions by setting context variables to test different paths. This will skip action steps in the test as long as all of the action steps' output variables are defined here."
                >
                  {testContextVariables.map(contextVariable => {
                    return (
                      <ContextVariableInput
                        contextVariable={contextVariable}
                        // @ts-expect-error TODO: Fix this, something about the definition for context_variable_type is not right.
                        contextVariableType={
                          contextVariable.context_variable_type
                        }
                        key={contextVariable.context_variable_id}
                        onChange={value =>
                          setFieldValue(
                            contextVariable.context_variable_id,
                            value,
                          )
                        }
                        required={false}
                        value={values[contextVariable.context_variable_id]}
                      />
                    );
                  })}
                </FormSection>
              )}
            </Box>
            <Box
              sx={{ display: 'flex', gap: '8px', justifyContent: 'end', p: 3 }}
            >
              <Button onClick={onCancel} variant='ghost'>
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant='main'
              >
                Send Test Email
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

function FormSection({
  children,
  title,
  tooltip,
}: {
  children: React.ReactNode;
  title: string;
  tooltip: string;
}) {
  return (
    <>
      <Divider />
      <Box display='flex' flexDirection='column' gap={2}>
        <Box alignItems='center' display='flex' gap={0.5} mb='4px'>
          <Typography variant='font16Bold'>{title}</Typography>
          <Tooltip tooltipContent={tooltip}>
            <IconInfoCircle size={16} />
          </Tooltip>
        </Box>
        {children}
      </Box>
    </>
  );
}

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
`;
