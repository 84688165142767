import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

import {
  elevations,
  theme,
} from '@forethought-technologies/forethought-elements';

export const DiscoverCardContainer = styled(Box)`
  border: 1px solid ${theme.palette.colors.slate[200]};
  border-radius: 8px;
  ${props =>
    props.onClick &&
    `&:hover {
    box-shadow: ${elevations.z2};
    & > div:last-child .MuiTypography-font12 {
      color: ${theme.palette.colors.purple[500]};
    }
    cursor: pointer;
  }`}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DiscoverCardContainerLink = styled(Link)`
  border: 1px solid ${theme.palette.colors.slate[200]};
  border-radius: 8px;
  text-decoration: none;
  &:hover {
    box-shadow: ${elevations.z2};
    & > div:last-child .MuiTypography-font12 {
      color: ${theme.palette.colors.purple[500]};
    }
  }
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DiscoverCardBottomLinkContainer = styled('div')`
  border-top: 1px solid ${theme.palette.colors.slate[200]};
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;
`;

export const TableLabelContainer = styled('div')`
  & > div {
    vertical-align: text-bottom;
  }
  white-space: nowrap;
`;

export const EmptyTableStateContainer = styled('span')`
  width: 400px;
`;
