import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { hasAtLeastOneError } from '../handoff-configuration/helpers';
import { FieldNameToIdx } from '../handoff-configuration/zendesk/constants';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import ZendeskChatHandoffConfiguration from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/chat-only';
import {
  initializeFieldTouchedArr,
  validateChatOnlyCustomizationData,
} from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import {
  selectCanvasActionSettings,
  selectCanvasIsLoadingActionCustomization,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  ZendeskChatHandoffCustomization,
  ZendeskHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';
interface ZendeskChatHandoffCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const ZendeskChatHandoffCustomizationPanel: FC<
  React.PropsWithChildren<ZendeskChatHandoffCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const theme = useTheme();
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const actionSettings = useSelector(selectCanvasActionSettings);
  const isLoadingActionCustomization = useSelector(
    selectCanvasIsLoadingActionCustomization,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultZendeskHandoffCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<ZendeskHandoffCustomization>;
  const defaultZendeskChatHandoffCustomizationSettings = useMemo(
    () => ({
      available_zd_chat_departments:
        defaultZendeskHandoffCustomizationSettings.agent_chat_handoff_settings
          ?.available_zd_chat_departments || [],
      chat_tags: [],
      email: '',
      name: '',
      one_chat: false,
      question: 'free_form_query',
      zd_chat_department: null,
    }),
    [
      defaultZendeskHandoffCustomizationSettings.agent_chat_handoff_settings
        ?.available_zd_chat_departments,
    ],
  );
  const handoffCustomizationSettings = addingAction
    ? defaultZendeskChatHandoffCustomizationSettings
    : (actionSettings.configuration_fields as ZendeskChatHandoffCustomization);
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const dispatch = useDispatch();

  // Creating a new state so we can keep track of what has changed
  const [currentCustomizationData, setCurrentCustomizationData] =
    useState<ZendeskChatHandoffCustomization>(handoffCustomizationSettings);

  const errorMessages = useMemo(
    () =>
      validateChatOnlyCustomizationData({
        allowableContextVariables: contextVariables,
        customizationData: currentCustomizationData,
      }),
    [contextVariables, currentCustomizationData],
  );

  const isPristine = isEqual(
    currentCustomizationData,
    handoffCustomizationSettings,
  );

  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(currentCustomizationData),
  );

  const nameErrorMessage = isFieldTouchedArr[FieldNameToIdx.NAME]
    ? errorMessages[FieldNameToIdx.NAME]
    : '';
  const emailErrorMessage = isFieldTouchedArr[FieldNameToIdx.EMAIL]
    ? errorMessages[FieldNameToIdx.EMAIL]
    : '';
  const questionErrorMessage = isFieldTouchedArr[FieldNameToIdx.QUESTION]
    ? errorMessages[FieldNameToIdx.QUESTION]
    : '';

  const isSaveDisabledFromForm =
    hasWorkflowConflict ||
    Boolean(nameErrorMessage) ||
    Boolean(emailErrorMessage) ||
    Boolean(questionErrorMessage) ||
    (!addingAction && isPristine);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!isLoadingActionCustomization) {
      setCurrentCustomizationData(
        addingAction
          ? defaultZendeskChatHandoffCustomizationSettings
          : handoffCustomizationSettings,
      );
    }
  }, [
    isLoadingActionCustomization,
    handoffCustomizationSettings,
    addingAction,
    defaultZendeskChatHandoffCustomizationSettings,
  ]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2,
        ),
      );
    }
  }, [dispatch, addingAction]);

  if (
    isLoadingActionCustomization ||
    !handoffCustomizationSettings ||
    !currentCustomizationData
  ) {
    return <LoadingSkeleton />;
  }

  return (
    <Box
      sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}
    >
      <ZendeskChatHandoffConfiguration
        customizationData={currentCustomizationData}
        errorMessages={errorMessages}
        isFieldTouchedArr={isFieldTouchedArr}
        setCustomizationData={setCurrentCustomizationData}
        setIsFieldTouchedArr={setIsFieldTouchedArr}
      />
      {/* Zopim One Chat specific settings */}
      {featureFlags.includes('zendesk_zopim_one_chat') && (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.colors.grey[100]}`,
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            padding: '28px 15px 15px 15px',
          }}
        >
          <Typography component='h2' variant='font16Bold'>
            Zendesk One Chat Settings
          </Typography>
          <Checkbox
            checked={Boolean(currentCustomizationData.one_chat)}
            label='Enable Zendesk One Chat'
            onChange={() => {
              setCurrentCustomizationData({
                ...currentCustomizationData,
                one_chat: !currentCustomizationData.one_chat,
              });
            }}
          />
        </Box>
      )}
      <SaveContainer>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            gap: 2,
          }}
        >
          <Button
            onClick={() => {
              onDiscard();
            }}
            size='large'
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            disabled={isSaveDisabledFromForm}
            fullWidth
            onClick={() => {
              setIsFieldTouchedArr(isFieldTouchedArr.map(() => true));

              if (hasAtLeastOneError(errorMessages)) {
                return;
              }

              dispatch(
                addingAction
                  ? addAction({
                      ...addingAction,
                      data: {
                        ...addingAction.data,
                        configuration_fields: currentCustomizationData,
                      },
                    })
                  : updateActionSettings({
                      action_type: actionSettings.action_type as string,
                      configuration_fields: currentCustomizationData,
                    }),
              );
              dispatchActionEditedTrackingEvent({
                actionType: ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF,
              });
              setActionPanelVisibilityParameters('hidden');
              setAreActionSettingsUnsaved(false);
              dispatch(setCustomizableActionId(''));
              onDismiss();
            }}
            size='large'
            variant='main'
          >
            Save
          </Button>
        </Box>
      </SaveContainer>
    </Box>
  );
};

export default ZendeskChatHandoffCustomizationPanel;

const SaveContainer = styled('div')`
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
`;
