import { SOLVE_EMAIL_BASE_URL, SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { getFormattedTranslations } from './utils';
import {
  EmailTranslationsRequest,
  EmailTranslationsResponse,
  RestoreEmailTranslationRequest,
  UpdateEmailManualTranslationRequest,
} from 'src/pages/workflow-builder-edit/types';
import { setEmailTranslations } from 'src/slices/email-translations/emailTranslationsSlice';
import {
  ConversationChannel,
  LanguagesEnabledResponse,
} from 'src/types/workflowBuilderAPITypes';

const solveEmailMultilingualApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getEmailTranslations: builder.query<
      EmailTranslationsResponse,
      EmailTranslationsRequest
    >({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const emailTranslations = getFormattedTranslations(data.translations);
        dispatch(setEmailTranslations(emailTranslations));
      },
      query: ({ mode, targetLanguage }) => {
        return `${SOLVE_EMAIL_BASE_URL}/translations/mode/${mode}/language/${targetLanguage}`;
      },
    }),
    getEnabledLanguages: builder.query<
      LanguagesEnabledResponse,
      ConversationChannel
    >({
      keepUnusedDataFor: 0,
      query: channel =>
        `${SOLVE_V2_BASE_URL}/workflow-builder/languages-enabled?channel=${channel}`,
    }),
    restoreManualTranslation: builder.mutation<
      EmailTranslationsResponse,
      RestoreEmailTranslationRequest
    >({
      async onQueryStarted(
        { mode, targetLanguage },
        { dispatch, queryFulfilled },
      ) {
        const { data: updatedTranslationData } = await queryFulfilled;
        dispatch(
          solveEmailMultilingualApi.util.updateQueryData(
            'getEmailTranslations',
            { mode, targetLanguage },
            data => {
              data.translations = updatedTranslationData.translations;
            },
          ),
        );

        const emailTranslations = getFormattedTranslations(
          updatedTranslationData.translations,
        );
        dispatch(setEmailTranslations(emailTranslations));
      },
      query: ({
        manualTranslationId,
        mode,
        modified_date,
        targetLanguage,
      }) => ({
        body: { modified_date },
        method: 'PUT',
        url: `${SOLVE_EMAIL_BASE_URL}/translations/mode/${mode}/language/${targetLanguage}/translation_id/${manualTranslationId}`,
      }),
    }),
    updateManualTranslation: builder.mutation<
      EmailTranslationsResponse,
      UpdateEmailManualTranslationRequest
    >({
      async onQueryStarted(
        { mode, targetLanguage },
        { dispatch, queryFulfilled },
      ) {
        const { data } = await queryFulfilled;
        dispatch(
          solveEmailMultilingualApi.util.updateQueryData(
            'getEmailTranslations',
            { mode: mode, targetLanguage },
            data => {
              data.translations = data.translations;
            },
          ),
        );

        const emailTranslations = getFormattedTranslations(data.translations);
        dispatch(setEmailTranslations(emailTranslations));
      },
      query: ({ manualTranslationList, mode, targetLanguage }) => ({
        body: { manual_translation_list: manualTranslationList },
        method: 'PUT',
        url: `${SOLVE_EMAIL_BASE_URL}/translations/mode/${mode}/language/${targetLanguage}`,
      }),
    }),
  }),
});

export const {
  useGetEnabledLanguagesQuery,
  useLazyGetEmailTranslationsQuery,
  useRestoreManualTranslationMutation,
  useUpdateManualTranslationMutation,
} = solveEmailMultilingualApi;

export const getEmailTranslationsLazyQueryState = ({
  mode,
  targetLanguage,
}: EmailTranslationsRequest) => {
  const { data, isFetching } =
    solveEmailMultilingualApi.endpoints.getEmailTranslations.useQueryState({
      mode,
      targetLanguage,
    });
  return { data, isFetching };
};
