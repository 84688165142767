import { Typography } from '@forethought-technologies/forethought-elements';
import { ResponseMessage } from './StyledComponents';
import { HyperlinkRedirectApiWidgetComponent } from 'src/slices/workflow-preview/types';

export const ApiHyperlinkRedirectStep = ({
  step,
}: {
  step: HyperlinkRedirectApiWidgetComponent;
}) => {
  return (
    <ResponseMessage isPlainText>
      <Typography variant='font14Medium'>
        <a href={step.destination_link} rel='noreferrer' target='_blank'>
          {step.destination_link}{' '}
        </a>
      </Typography>
    </ResponseMessage>
  );
};
