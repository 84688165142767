import { sortBreakdownTableData } from '../dataDeprecatedReducer/helper';
import { filterResults } from './helper';
import { FetchAction, SolveDataState } from './types';
import {
  FETCH_SOLVE_CHANNELS_ERROR,
  FETCH_SOLVE_CHANNELS_SUCCESS,
  GET_DATA_FAILURE,
  GET_OVERVIEW_DATA_SUCCESS,
  GET_SOLVE_TABLE_DATA_SUCCESS,
  SET_FILTER_TYPE,
  SET_SELECTED_CHANNEL,
} from 'src/actions/actionTypes';
import { PageSettingsActions } from 'src/actions/actionTypes';
import { SolveDataActions } from 'src/actions/solveData/actionTypes';
import { SolveTableDict } from 'src/services/apiInterfaces';
import type { RootState } from 'src/store/rootReducer';

export const initialState: SolveDataState = {
  channels: [],
  error: '',
  filterType: { key: 'all', name: 'All Macros & Articles' },
  isLoading: false,
  isSolveWorkflows: false,
  selectedChannel: '',
};

export default function solveDataReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === GET_OVERVIEW_DATA_SUCCESS) {
    const overviewData = action.payload;
    return { ...state, overviewData };
  }

  if (action.type === SET_SELECTED_CHANNEL) {
    return { ...state, selectedChannel: action.payload };
  }

  if (action.type === SET_FILTER_TYPE) {
    const { isSolveWorkflows } = state;
    const { key, name } = action.payload;
    const tableData = { ...state.tableData } as SolveTableDict;

    if (!isSolveWorkflows) {
      tableData.breakdown = filterResults(
        state.unsortedTableData?.breakdown || [],
        key,
      );
    }

    return { ...state, filterType: { key, name }, tableData };
  }

  if (action.type === GET_SOLVE_TABLE_DATA_SUCCESS) {
    const { key } = state.filterType;
    const { isSolveWorkflows } = state;
    const tableData = { ...action.payload } as SolveTableDict;
    const { breakdown } = tableData;
    if (!isSolveWorkflows) {
      tableData.breakdown = filterResults(breakdown, key);
    }

    return {
      ...state,
      tableData,
      unsortedTableData: tableData,
    };
  }

  if (action.type === GET_DATA_FAILURE) {
    return {
      ...state,
      error: action.error?.toString() || 'Error',
      overviewData: undefined,
      tableData: undefined,
    };
  }

  if (action.type === PageSettingsActions.SET_LOADING) {
    return { ...state, isLoading: action.payload };
  }

  if (action.type === PageSettingsActions.SORT_TABLE) {
    const { field, sortAsc } = action.payload;

    const tableData = { ...state.tableData } as SolveTableDict;
    tableData.breakdown = sortBreakdownTableData(
      state.unsortedTableData?.breakdown || [],
      field,
      sortAsc,
    );

    return { ...state, tableData };
  }

  if (action.type === FETCH_SOLVE_CHANNELS_SUCCESS) {
    const { selectedChannel } = state;
    const { channels } = action.payload;
    const channel: string =
      selectedChannel.length === 0 ? channels[0] : selectedChannel;
    return { ...state, channels, selectedChannel: channel };
  }

  if (action.type === FETCH_SOLVE_CHANNELS_ERROR) {
    const channels: [] = [];
    return { ...state, channels };
  }

  if (action.type === SolveDataActions.SET_IS_SOLVE_WORKFLOWS) {
    return { ...state, isSolveWorkflows: action.payload };
  }

  return state;
}

// SELECTORS

export const selectChannel = (
  state: RootState,
): SolveDataState['selectedChannel'] => state.solveData.selectedChannel;

export const selectTableData = (
  state: RootState,
): SolveDataState['tableData'] => state.solveData.tableData;

export const selectOverviewData = (
  state: RootState,
): SolveDataState['overviewData'] => state.solveData.overviewData;

export const selectIsLoading = (
  state: RootState,
): SolveDataState['isLoading'] => state.solveData.isLoading;

export const selectFilterType = (
  state: RootState,
): SolveDataState['filterType'] => state.solveData.filterType;

export const selectChannels = (state: RootState): SolveDataState['channels'] =>
  state.solveData.channels;

export const selectIsSolveWorkflows = (
  state: RootState,
): SolveDataState['isSolveWorkflows'] => state.solveData.isSolveWorkflows;
