import { renderToString } from 'react-dom/server';

import { ApiTextStep } from './ApiTextStep';
import { BaseHandoffApiWidgetComponent } from 'src/slices/workflow-preview/types';

interface ApiHandoffStepProps {
  step: BaseHandoffApiWidgetComponent;
}

const ApiHandoffStep = ({ step }: ApiHandoffStepProps) => {
  const { visitor_email, visitor_name, visitor_question } = step;

  return (
    <ApiTextStep
      message={renderToString(
        <>
          <p>Executing handoff with fields:</p>
          <ul>
            {[visitor_email, visitor_name, visitor_question]
              .filter(Boolean)
              .map(field => (
                <li key={field}>{field}</li>
              ))}
          </ul>
        </>,
      )}
    />
  );
};

export default ApiHandoffStep;
