import React from 'react';

import { TextField } from '@forethought-technologies/forethought-elements';
import {
  KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE,
  validateKnowledgeConfigurationPrompt,
} from './helpers';

interface KnowledgeConfigurationPromptTextFieldProps {
  error?: false | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const KnowledgeConfigurationPromptTextField = ({
  error,
  ...props
}: KnowledgeConfigurationPromptTextFieldProps) => {
  return (
    <TextField
      description={KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE}
      error={error || validateKnowledgeConfigurationPrompt(props.value)}
      label='Note the following in customer conversations'
      minRows={4}
      multiline
      placeholder="Inform the bot that you may have various user personas (e.g. buyers, sellers) and prompt clarification before providing tailored information utilizing distinct personas or acronyms (e.g 'X' stands for spelled out name)."
      {...props}
    />
  );
};
