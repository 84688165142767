import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Icon } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import TabBody from './TabBody';

interface Tab {
  component: React.ReactNode;
  Icon: Icon;
  label: string;
  title: string;
}

interface VerticalTabBarProps {
  tabs: Tab[];
}

export default function VerticalTabBar({ tabs }: VerticalTabBarProps) {
  const [tabIndex, setTabIndex] = useState(0);
  const { palette } = useTheme();

  return (
    <Box display='flex' height='100%'>
      <TabBody title={tabs[tabIndex].title}>{tabs[tabIndex].component}</TabBody>
      <Tabs>
        {tabs.map((tab, index) => {
          const { Icon, label } = tab;
          const isActive = index === tabIndex;

          return (
            <TabItem key={label} onClick={() => setTabIndex(index)}>
              <Button>
                <Box
                  bgcolor={
                    isActive ? palette.colors.purple[100] : 'transparent'
                  }
                  borderRadius='4px'
                  display='flex'
                  padding='8px'
                >
                  <Icon
                    color={
                      isActive
                        ? palette.colors.purple[500]
                        : palette.colors.grey[700]
                    }
                    size={24}
                  />
                </Box>
                <Typography color={palette.colors.grey[700]} variant='font11'>
                  {label}
                </Typography>
              </Button>
            </TabItem>
          );
        })}
      </Tabs>
    </Box>
  );
}

const Tabs = styled('ul')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 20px 0;
`;

const TabItem = styled('li')`
  padding: 0 8px;
`;

const Button = styled('button')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  padding: 4px 12px;
`;
