import { CreateTicketFieldMappingRequest } from 'src/pages/workflow-builder-edit/types';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

export const getNonTriageContextVariables = (
  ticketFieldMappings: Array<CreateTicketFieldMappingRequest>,
  contextVariables: Array<ContextVariable>,
) => {
  const mappedFields = ticketFieldMappings.filter(
    field => !!field.mapping_type && !!field.mapping_data,
  );

  const filteredContextVariables = contextVariables
    .filter(cv => {
      const isContextVariableMapped = mappedFields.some(
        field => field.context_variable_id === cv.context_variable_id,
      );
      return !isContextVariableMapped;
    })
    .filter(cv => cv.context_variable_type !== 'MULTIOPTIONS');

  return filteredContextVariables;
};
