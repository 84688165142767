import React, { useMemo } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import VersionCard from './VersionCard';
import closeIcon from 'src/assets/images/close-icon.svg';
import { useGetAutoflowVersionHistoryQuery } from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';

interface VersionHistoryDrawerProps {
  intentWorkflowId: string;
  setIsVersionHistoryPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const VersionHistoryDrawer = ({
  intentWorkflowId,
  setIsVersionHistoryPanelOpen,
}: VersionHistoryDrawerProps) => {
  const { palette } = useTheme();
  const { data: autoflowVersionHistory } = useGetAutoflowVersionHistoryQuery(
    intentWorkflowId,
    {
      skip: !intentWorkflowId,
    },
  );

  const latestVersion = useMemo(() => {
    if (autoflowVersionHistory) {
      return Math.max(
        ...autoflowVersionHistory.map(version => version.version),
      );
    }
    return 0;
  }, [autoflowVersionHistory]);

  const draftVersionHistory = useMemo(
    () => autoflowVersionHistory?.find(version => version.is_draft),
    [autoflowVersionHistory],
  );

  const liveVersionHistory = useMemo(
    () =>
      autoflowVersionHistory?.find(
        version => !version.is_draft && version.version === latestVersion,
      ),
    [autoflowVersionHistory, latestVersion],
  );

  const versionHistoryPreviousVersionList = useMemo(
    () =>
      autoflowVersionHistory
        ?.filter(
          version => !version.is_draft && version.version < latestVersion,
        )
        .sort(
          (a, b) =>
            new Date(b.modified_date).valueOf() -
            new Date(a.modified_date).valueOf(),
        ),
    [autoflowVersionHistory, latestVersion],
  );

  return (
    <Box
      sx={{
        backgroundColor: palette.colors.white,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        padding: '20px 0px',
        width: '300px',
        zIndex: 1,
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        onClick={() => setIsVersionHistoryPanelOpen(false)}
        padding='0px 20px 20px 20px'
      >
        <Typography color={palette.colors.black} variant='font20'>
          Version history
        </Typography>
        <CloseButton alt='Close icon' src={closeIcon} />
      </Box>
      <Box>
        <Box
          bgcolor={palette.colors.brand.greyBackground}
          display='flex'
          padding='10px 20px'
          sx={{
            '.MuiTypography-root': {
              fontWeight: 600,
            },
          }}
        >
          <Typography variant='font12Medium'>Current</Typography>
        </Box>
        {draftVersionHistory && (
          <VersionCard showIndicator versionHistory={draftVersionHistory} />
        )}
        {liveVersionHistory && (
          <VersionCard showIndicator versionHistory={liveVersionHistory} />
        )}
      </Box>
      {versionHistoryPreviousVersionList && (
        <Box>
          <Box
            bgcolor={palette.colors.brand.greyBackground}
            display='flex'
            padding='10px 20px'
            sx={{
              '.MuiTypography-root': {
                fontWeight: 600,
              },
            }}
          >
            <Typography variant='font12Medium'>
              Version History ({versionHistoryPreviousVersionList.length})
            </Typography>
          </Box>
          {versionHistoryPreviousVersionList.map(version => (
            <VersionCard key={version.version} versionHistory={version} />
          ))}
        </Box>
      )}
    </Box>
  );
};

const CloseButton = styled('img')`
  cursor: pointer;
`;

export default VersionHistoryDrawer;
