/* eslint-disable react/prop-types -- false warnings for column definitions */

import { useMemo } from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import { styled, useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useEmailMetricBreakdownById } from '../hooks/useEmailMetricBreakdownById';
import IntentWorkflowsTable from '../IntentWorkflowsTable';
import {
  IntentDataWithTopics,
  IntentWorkflowsTableContainerProps,
} from '../types';
import { formatNumberWithCommas } from '../utils';
import { NA } from 'src/constants/solve';
import {
  EmailConversationMetricBreakdownRow,
  IntentData,
} from 'src/types/workflowBuilderAPITypes';
import { formatNToPercentageDecimal } from 'src/utils/formatToPercentage';
import { isOnlyInteractiveEmail } from 'src/utils/solve/intentUtils';

function getRowByIntentDefinitionId(
  intentData: IntentData,
  breakdownById: Record<string, EmailConversationMetricBreakdownRow> | null,
): EmailConversationMetricBreakdownRow | Record<string, never> {
  return breakdownById?.[intentData.intent_definition_id] || {};
}

export default function EmailIntentWorkflowsTableContainer(
  props: IntentWorkflowsTableContainerProps,
) {
  const { breakdownById, isFetching } = useEmailMetricBreakdownById();
  const { palette } = useTheme();

  const columnHelper = createMRTColumnHelper<IntentDataWithTopics>();

  const materialReactTableColumns = useMemo(() => {
    const rows = [
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intent = row.original;

          const { inquiries = 0 } = getRowByIntentDefinitionId(
            intent,
            breakdownById,
          );

          const { is_handoff: isHandoff } = intent;
          const isOnlyInteractive = isOnlyInteractiveEmail(
            intent.intent_definition_id,
          );
          if (isHandoff || isOnlyInteractive) {
            return (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          }

          return (
            <DataCell width={120}>
              {isFetching ? (
                <Skeleton width='60px' />
              ) : (
                formatNumberWithCommas(inquiries)
              )}
            </DataCell>
          );
        },
        header: 'Emails Received',
        id: 'inquiries',
        sortingFn: (rowA, rowB) => {
          const { inquiries: inquiriesA = 0 } = getRowByIntentDefinitionId(
            rowA.original,
            breakdownById,
          );
          const { inquiries: inquiriesB = 0 } = getRowByIntentDefinitionId(
            rowB.original,
            breakdownById,
          );
          return inquiriesB - inquiriesA;
        },
      }),
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intent = row.original;

          const { potential_attempts = 0 } = getRowByIntentDefinitionId(
            intent,
            breakdownById,
          );

          const { is_handoff: isHandoff } = intent;
          const isOnlyInteractive = isOnlyInteractiveEmail(
            intent.intent_definition_id,
          );
          if (isHandoff || isOnlyInteractive) {
            return (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          }

          return (
            <DataCell width={80}>
              {isFetching ? (
                <Skeleton width='60px' />
              ) : (
                formatNumberWithCommas(potential_attempts)
              )}
            </DataCell>
          );
        },
        header: 'Eligible Emails',
        id: 'potential_attempts',
        sortingFn: (rowA, rowB) => {
          const { potential_attempts: potential_attemptsA = 0 } =
            getRowByIntentDefinitionId(rowA.original, breakdownById);
          const { potential_attempts: potential_attemptsB = 0 } =
            getRowByIntentDefinitionId(rowB.original, breakdownById);
          return potential_attemptsB - potential_attemptsA;
        },
      }),
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intent = row.original;

          const { attempted = 0 } = getRowByIntentDefinitionId(
            intent,
            breakdownById,
          );

          const { is_handoff: isHandoff } = intent;
          const isOnlyInteractive = isOnlyInteractiveEmail(
            intent.intent_definition_id,
          );
          if (isHandoff || isOnlyInteractive) {
            return (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          }

          return (
            <DataCell width={110}>
              <span>
                {isFetching ? (
                  <Skeleton width='60px' />
                ) : (
                  formatNumberWithCommas(attempted)
                )}
              </span>
            </DataCell>
          );
        },
        header: 'Responses Sent',
        id: 'attempted',
        sortingFn: (rowA, rowB) => {
          const { attempted: attemptedA = 0 } = getRowByIntentDefinitionId(
            rowA.original,
            breakdownById,
          );
          const { attempted: attemptedB = 0 } = getRowByIntentDefinitionId(
            rowB.original,
            breakdownById,
          );
          return attemptedB - attemptedA;
        },
      }),
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intent = row.original;

          const { self_served: selfServed = 0 } = getRowByIntentDefinitionId(
            intent,
            breakdownById,
          );

          const { is_handoff: isHandoff } = intent;
          const isOnlyInteractive = isOnlyInteractiveEmail(
            intent.intent_definition_id,
          );
          if (isHandoff || isOnlyInteractive) {
            return (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          }

          return (
            <DataCell width={100}>
              <span>
                {isFetching ? (
                  <Skeleton width='60px' />
                ) : (
                  formatNumberWithCommas(selfServed)
                )}
              </span>
            </DataCell>
          );
        },
        header: 'Deflections',
        id: 'self_served',
        sortingFn: (rowA, rowB) => {
          const { self_served: selfServedA = 0 } = getRowByIntentDefinitionId(
            rowA.original,
            breakdownById,
          );
          const { self_served: selfServedB = 0 } = getRowByIntentDefinitionId(
            rowB.original,
            breakdownById,
          );
          return selfServedB - selfServedA;
        },
      }),
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intent = row.original;

          const { responded_deflection_rate: respondedDeflectionRate = 0 } =
            getRowByIntentDefinitionId(intent, breakdownById);

          const { is_handoff: isHandoff } = intent;
          const isOnlyInteractive = isOnlyInteractiveEmail(
            intent.intent_definition_id,
          );
          if (isHandoff || isOnlyInteractive) {
            return (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          }

          return (
            <DataCell width={106}>
              {isFetching ? (
                <Skeleton width='60px' />
              ) : (
                formatNToPercentageDecimal(respondedDeflectionRate)
              )}
            </DataCell>
          );
        },
        header: 'Deflection Rate',
        id: 'responded_deflection_rate',
        sortingFn: (rowA, rowB) => {
          const { responded_deflection_rate: respondedDeflectionRateA = 0 } =
            getRowByIntentDefinitionId(rowA.original, breakdownById);
          const { responded_deflection_rate: respondedDeflectionRateB = 0 } =
            getRowByIntentDefinitionId(rowB.original, breakdownById);
          return respondedDeflectionRateB - respondedDeflectionRateA;
        },
      }),
    ];

    return rows;
  }, [breakdownById, columnHelper, isFetching, palette]);

  return (
    <IntentWorkflowsTable
      {...props}
      channelColumns={[
        {
          cellRenderer: intent => {
            const { inquiries = 0 } = getRowByIntentDefinitionId(
              intent,
              breakdownById,
            );

            return (
              <DataCell width={120}>
                {isFetching ? (
                  <Skeleton width='60px' />
                ) : (
                  formatNumberWithCommas(inquiries)
                )}
              </DataCell>
            );
          },
          comparatorFunction: (a, b) => {
            const { inquiries: inquiriesA } = getRowByIntentDefinitionId(
              a,
              breakdownById,
            );
            const { inquiries: inquiriesB } = getRowByIntentDefinitionId(
              b,
              breakdownById,
            );
            return inquiriesB - inquiriesA;
          },
          id: 'inquiries',
          label: 'Emails Forethought Received',
          width: 120,
        },
        {
          cellRenderer: intent => {
            const { potential_attempts = 0 } = getRowByIntentDefinitionId(
              intent,
              breakdownById,
            );

            return (
              <DataCell width={80}>
                {isFetching ? (
                  <Skeleton width='60px' />
                ) : (
                  formatNumberWithCommas(potential_attempts)
                )}
              </DataCell>
            );
          },
          comparatorFunction: (a, b) => {
            const { potential_attempts: potential_attemptsA } =
              getRowByIntentDefinitionId(a, breakdownById);
            const { potential_attempts: potential_attemptsB } =
              getRowByIntentDefinitionId(b, breakdownById);
            return potential_attemptsB - potential_attemptsA;
          },
          id: 'potential_attempts',
          label: 'Eligible Emails',
          width: 80,
        },
        {
          cellRenderer: intent => {
            const { attempted = 0 } = getRowByIntentDefinitionId(
              intent,
              breakdownById,
            );

            const { is_handoff: isHandoff } = intent;
            const isOnlyInteractive = isOnlyInteractiveEmail(
              intent.intent_definition_id,
            );
            if (isHandoff || isOnlyInteractive) {
              return (
                <Typography color={palette.colors.grey[500]} variant='font14'>
                  {NA}
                </Typography>
              );
            }

            return (
              <DataCell width={110}>
                <span>
                  {isFetching ? (
                    <Skeleton width='60px' />
                  ) : (
                    formatNumberWithCommas(attempted)
                  )}
                </span>
              </DataCell>
            );
          },
          comparatorFunction: (a, b) => {
            const { attempted: attemptedA } = getRowByIntentDefinitionId(
              a,
              breakdownById,
            );
            const { attempted: attemptedB } = getRowByIntentDefinitionId(
              b,
              breakdownById,
            );
            return attemptedB - attemptedA;
          },
          id: 'attempted',
          label: 'Responses Sent',
          width: 110,
        },
        {
          cellRenderer: intent => {
            const { self_served: selfServed = 0 } = getRowByIntentDefinitionId(
              intent,
              breakdownById,
            );

            const { is_handoff: isHandoff } = intent;
            if (isHandoff) {
              return (
                <Typography color={palette.colors.grey[500]} variant='font14'>
                  {NA}
                </Typography>
              );
            }

            return (
              <DataCell width={100}>
                <span>
                  {isFetching ? (
                    <Skeleton width='60px' />
                  ) : (
                    formatNumberWithCommas(selfServed)
                  )}
                </span>
              </DataCell>
            );
          },
          comparatorFunction: (a, b) => {
            const { self_served: selfServedA } = getRowByIntentDefinitionId(
              a,
              breakdownById,
            );
            const { self_served: selfServedB } = getRowByIntentDefinitionId(
              b,
              breakdownById,
            );
            return selfServedB - selfServedA;
          },
          id: 'self_served',
          label: 'Deflected Emails',
          width: 100,
        },
        {
          cellRenderer: intent => {
            const { responded_deflection_rate: respondedDeflectionRate = 0 } =
              getRowByIntentDefinitionId(intent, breakdownById);

            const { is_handoff: isHandoff } = intent;
            if (isHandoff) {
              return (
                <Typography color={palette.colors.grey[500]} variant='font14'>
                  {NA}
                </Typography>
              );
            }

            return (
              <DataCell width={106}>
                {isFetching ? (
                  <Skeleton width='60px' />
                ) : (
                  formatNToPercentageDecimal(respondedDeflectionRate)
                )}
              </DataCell>
            );
          },
          comparatorFunction: (a, b) => {
            const { responded_deflection_rate: respondedDeflectionRateA } =
              getRowByIntentDefinitionId(a, breakdownById);
            const { responded_deflection_rate: respondedDeflectionRateB } =
              getRowByIntentDefinitionId(b, breakdownById);
            return respondedDeflectionRateB - respondedDeflectionRateA;
          },
          id: 'responded_deflection_rate',
          label: 'Responded Deflection Rate',
          width: 106,
        },
      ]}
      channelMaterialReactTableColumns={materialReactTableColumns}
    />
  );
}

const DataCell = styled('div')<{
  width: number;
}>(({ width }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '2px',
  width: `${width}px`,
}));
