import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

export default function TestValuesTable<
  TestValues extends Partial<Record<string, string>>,
>({
  contextVariables,
  setTestValues,
  tableWidth,
  testValues,
}: {
  contextVariables: ContextVariable[];
  setTestValues: (testValues: TestValues) => void;
  tableWidth?: string;
  testValues: TestValues;
}) {
  const { palette } = useTheme();

  return (
    <Box
      component='table'
      marginTop={1}
      sx={{ borderSpacing: 0 }}
      width={tableWidth}
    >
      <tr>
        <Box
          component='th'
          fontWeight='normal'
          paddingBlock={1}
          paddingLeft={0}
          paddingRight={6}
          textAlign='left'
        >
          Context Variable
        </Box>
        <Box component='th' fontWeight='normal' textAlign='left'>
          Test Value
        </Box>
      </tr>
      {contextVariables.map(cv => (
        <Box component='tr' key={cv.context_variable_id}>
          <Box component='td' paddingBlock={1} paddingLeft={0} paddingRight={6}>
            <Typography color={palette.colors.purple[500]} variant='font14Bold'>
              ${cv.context_variable_name}
            </Typography>
          </Box>
          <Box component='td' minWidth='200px' paddingBlock={1}>
            <TextField
              aria-label={`Test value for ${cv.context_variable_name}`}
              error={
                !testValues[cv.context_variable_id] &&
                'Test value cannot be empty'
              }
              fullWidth
              onChange={event => {
                setTestValues({
                  ...testValues,
                  [cv.context_variable_id]: event.target.value,
                });
              }}
              placeholder='Enter value'
              value={testValues[cv.context_variable_id] ?? ''}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
