import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '../../../components/table/index';
import { NoDataViewTextProps } from './utils/enum';
import {
  trainedMacroHeaderDict,
  untrainedMacroHeaderDict,
} from './utils/tableHeaderUtils';
import {
  trainedMacrosTableVal,
  untrainedMacrosTableVal,
} from './utils/tableValues';
import MacroModal from './macroModal';
import {
  setFilterdMacrosData,
  setSelectedMacro,
} from 'src/actions/macroControls';
import {
  setMacroControlsViewMode,
  setPageNumber,
} from 'src/actions/pageSettings/pageSettings';
import LoadingSpinner from 'src/components/reusable-components/loading-spinner/loadingSpinner';
import ViewNavigator from 'src/components/reusable-components/view-navigator/index';
import { useConstructQueryParams } from 'src/hooks/hooks';
import {
  selectFilteredMacrosData,
  selectIsLoading,
  selectSelectedMacro,
} from 'src/reducers/macroControlsReducer';
import { selectMacroControlsViewMode } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { MacroDataDict } from 'src/services/apiInterfaces';
import {
  trainedMacrosEmptyBreakdown,
  untrainedMacrosEmptyBreakdown,
} from 'src/utils/analyticsUtils';

export const MacroControlView: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useDispatch();
  const view: string = useSelector(selectMacroControlsViewMode);
  const [isMacroVisible, setIsMacroVisible] = useState<boolean>(false);
  const filteredMacrosData = useSelector(selectFilteredMacrosData);
  const isloading = useSelector(selectIsLoading);
  const selectedMacro = useSelector(selectSelectedMacro);
  const [search, setSearch] = useState<string>('');

  useConstructQueryParams();

  return (
    <div className='analytics-page'>
      <ViewNavigator
        isSubNavigationView
        onChangeHandler={search => {
          setSearch(search);
          dispatch(setFilterdMacrosData({ currentView: view, search }));
          dispatch(setPageNumber(0));
        }}
        selectedView={view}
        setView={(view: string) => {
          dispatch(setPageNumber(0));
          dispatch(setMacroControlsViewMode(view));
          dispatch(setFilterdMacrosData({ currentView: view, search }));
        }}
        shouldShowSearchBar
        ViewNavigatorClass={'--subNav'}
        viewOptions={['Trained Macros', 'Untrained Macros']}
      />
      {isloading ? (
        <LoadingSpinner />
      ) : (
        <Table
          breakdownData={filteredMacrosData}
          isMacroTable={true}
          noDataViewBreakdown={
            view === 'Trained Macros'
              ? trainedMacrosEmptyBreakdown
              : untrainedMacrosEmptyBreakdown
          }
          noDataViewClass={NoDataViewTextProps.CLASS}
          noDataViewMessage={NoDataViewTextProps.MESSAGE}
          onRowClick={(macro: MacroDataDict) => {
            dispatch(setSelectedMacro(macro));
            setIsMacroVisible(true);
          }}
          sortedProperty={''}
          tableData={filteredMacrosData}
          tableHeaderDict={
            view === 'Trained Macros'
              ? trainedMacroHeaderDict
              : untrainedMacroHeaderDict()
          }
          tableValues={
            view === 'Trained Macros'
              ? trainedMacrosTableVal
              : untrainedMacrosTableVal
          }
        />
      )}
      {!isloading && isMacroVisible && selectedMacro && (
        <MacroModal
          closeMacro={() => setIsMacroVisible(false)}
          selectedMacro={selectedMacro}
        />
      )}
    </div>
  );
};
