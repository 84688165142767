import { useSelector } from 'react-redux';

import { BlueText } from './TextComponents';
import { selectWorkflowBuilderLanguages } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { LanguageChangePreviewLogProps } from 'src/slices/workflow-preview/types';

export const LanguageChangePreviewLog = ({
  previewLog,
}: {
  previewLog: LanguageChangePreviewLogProps;
}) => {
  const { language_code: languageCode } = previewLog;
  const languages = useSelector(selectWorkflowBuilderLanguages);
  const currentLanguage = languages.find(lang => lang.code === languageCode);
  return (
    <div>
      <span>Language: </span>
      <BlueText>
        {currentLanguage
          ? currentLanguage.display_name
          : languageCode === 'en'
          ? 'English'
          : languageCode}
      </BlueText>
    </div>
  );
};
