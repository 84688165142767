import { User } from 'src/services/apiInterfaces';

export function getFullName({ first_name, last_name }: User) {
  let name = first_name.trim();
  const lastName = last_name.trim();
  if (lastName) {
    name += ` ${lastName}`;
  }
  return name;
}

export function getAvatarTextFromUser(user: User) {
  const fullName = getFullName(user);
  return (fullName || user.email).toUpperCase();
}

// Take snake_case permission level, return string with spaces and capitalized words
// ex: 'SUPER_ADMIN' -> 'Super Admin'
export function formatPermissionLevel(
  permissionLevel: User['permission_level'],
) {
  return permissionLevel
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}
