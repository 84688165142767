import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getConfigurationTranslationsForOrg,
  getStepTranslationsForOrg,
  getWorkflowBuilderLanguagesEnabledSuccess,
  setIsErrorBannerVisible,
  setNetworkError,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import { EmailTranslation } from 'src/pages/workflow-builder-edit/types';
import {
  selectDraftTranslations,
  selectError,
  selectStepTranslations,
  selectTranslationsTableMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useLazyGetEmailTranslationsQuery } from 'src/services/solve-email-multilingual/solveEmailMultilingualApi';
import { updateWorkflowBuilderLanguagesEnabledAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import { selectEmailTranslations } from 'src/slices/email-translations/emailTranslationsSlice';
import { useAppDispatch } from 'src/store/hooks';
import { isDefaultNetworkError } from 'src/types/types';
import {
  ConversationChannel,
  LanguagesEnabledRequest,
} from 'src/types/workflowBuilderAPITypes';
import { TranslationsTableMode } from 'src/utils/enums';

export const getisInitialStateEmailValidation = (
  translation: EmailTranslation,
) => {
  const {
    auto_translation: autoTranslation,
    current_translation: currentTranslation,
    manual_translation: manualTranslation,
  } = translation;
  return manualTranslation
    ? manualTranslation === currentTranslation
    : autoTranslation === currentTranslation;
};
export const useGetTranslationsChangedData = (
  selectedChannelLabel: ConversationChannel,
) => {
  const draftWidgetTranslations = useSelector(selectDraftTranslations);
  const sourceWidgetTranslations = useSelector(selectStepTranslations);
  const draftEmailTranslations = useSelector(selectEmailTranslations);

  const [hasEmptyOverride, setHasEmptyOverride] = useState(false);
  const [isTranslationPhrasesUnchanged, setIsTranslationPhrasesUnchanged] =
    useState(true);
  const isWidgetChannel = selectedChannelLabel === 'widget';

  useEffect(() => {
    if (
      isWidgetChannel &&
      draftWidgetTranslations.length != sourceWidgetTranslations.length
    ) {
      return;
    }
    const isEmailInitialState = draftEmailTranslations.every(translation =>
      getisInitialStateEmailValidation(translation),
    );
    const isWidgetInitialState = !draftWidgetTranslations.some(
      (translation, index) =>
        translation.target_text !== sourceWidgetTranslations[index].target_text,
    );
    const hasEmailEmptyOverride = draftEmailTranslations.some(
      translation => translation.current_translation.trim() === '',
    );
    const hasWidgetEmptyOverride = draftWidgetTranslations.some(
      translation =>
        translation.target_text.trim() === '' && translation.source_text !== '',
    );

    setIsTranslationPhrasesUnchanged(
      isWidgetChannel ? isWidgetInitialState : isEmailInitialState,
    );
    setHasEmptyOverride(
      isWidgetChannel ? hasWidgetEmptyOverride : hasEmailEmptyOverride,
    );
  }, [
    draftWidgetTranslations,
    sourceWidgetTranslations,
    isWidgetChannel,
    draftEmailTranslations,
  ]);

  return {
    hasEmptyOverride,
    isTranslationPhrasesUnchanged,
  };
};

const useGetRecentTranslations = () => {
  const dispatch = useAppDispatch();
  const [getEmailTranslationsTriggger] = useLazyGetEmailTranslationsQuery();
  const tableMode = useSelector(selectTranslationsTableMode);

  return useCallback(
    ({
      languageCode,
      selectedChannel,
    }: {
      languageCode: string;
      selectedChannel: ConversationChannel;
    }) => {
      if (selectedChannel === 'email') {
        getEmailTranslationsTriggger({
          mode: tableMode,
          targetLanguage: languageCode,
        });
      } else {
        dispatch(
          getStepTranslationsForOrg({
            language_code: languageCode,
            mode: tableMode,
          }),
        );
        if (tableMode === TranslationsTableMode.LIVE) {
          dispatch(
            getConfigurationTranslationsForOrg({
              language_code: languageCode,
            }),
          );
        }
      }
    },
    [getEmailTranslationsTriggger, tableMode, dispatch],
  );
};

export const useUpdateEnabledLanguages = () => {
  const dispatch = useAppDispatch();
  const networkError = useSelector(selectError);
  const getRecentTranslations = useGetRecentTranslations();

  return useCallback(
    async (
      requestData: LanguagesEnabledRequest,
      selectedChannel: ConversationChannel,
      shouldGetRecentTranslations: boolean,
    ) => {
      const { language_codes } = requestData;

      try {
        dispatch(
          getWorkflowBuilderLanguagesEnabledSuccess(
            await updateWorkflowBuilderLanguagesEnabledAPI(requestData),
          ),
        );
      } catch (e) {
        if (isDefaultNetworkError(e)) {
          const errorType = e.error_type ?? '';
          dispatch(setNetworkError(errorType));
          dispatch(setIsErrorBannerVisible(true));
        }
      }
      if (!networkError && shouldGetRecentTranslations) {
        getRecentTranslations({
          languageCode: language_codes[0],
          selectedChannel,
        });
      }
    },
    [dispatch, networkError, getRecentTranslations],
  );
};
