import { Box } from '@mui/material';

import SafeImage from 'src/components/reusable-components/safe-image/index';
import { ImageApiWidgetComponent } from 'src/slices/workflow-preview/types';

export const ApiImageStep = ({ step }: { step: ImageApiWidgetComponent }) => {
  return (
    <Box alignItems='flex-start' display='flex' flexDirection='column' gap={1}>
      {step.images.map(image => {
        return (
          <Box
            key={image.url}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <SafeImage
              alt={image.description}
              fallbackAlt={
                'Image cannot be found, most likely due to context variables being part of the image url'
              }
              src={image.url}
              sx={{
                borderRadius: '8px',
                display: 'block',
                flexBasis: '100%',
                height: 'initial',
                maxHeight: '291px',
                maxWidth: '100%',
                objectFit: 'scale-down',
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};
