import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { LoadingSkeleton } from '../../../components/reusable-components/loading-skeleton';
import StyledButton, {
  SecondaryButton,
} from '../../../components/styled-button';
import KustomerHandoffConfiguration from '../handoff-configuration/kustomer';
import { getKustomerCustomAttributesData } from './utils';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { updateActionSettings } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { KustomerHandoffCustomization } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

type KustomerHandoffCustomizationPanelProps = {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
};

const KustomerHandoffCustomizationPanel: FC<
  React.PropsWithChildren<KustomerHandoffCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const handoffCustomization = canvasState?.actionSettings
    .configuration_fields as KustomerHandoffCustomization;
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const dispatch = useDispatch();

  // Creating a new state so we can keep track of what has changed
  const [customizationData, setCustomizationData] =
    useState<KustomerHandoffCustomization>(handoffCustomization);

  const isPristine = isEqual(customizationData, handoffCustomization);

  const {
    custom_attributes: agentChatHandoffCustomAttributes = [],
    kustomer_custom_attributes: agentChatHandoffKustomerCustomAttributes = [],
  } = customizationData?.agent_chat_handoff_settings || {};

  const {
    custom_attributes: conversationCreationCustomAttributes = [],
    kustomer_custom_attributes:
      kustomerConversationCreationCustomAttributes = [],
  } = customizationData?.conversation_creation_settings || {};

  const chatAgentHanndoffCustomAttributesData = getKustomerCustomAttributesData(
    agentChatHandoffCustomAttributes,
    agentChatHandoffKustomerCustomAttributes,
  );

  const conversationCreationCustomAttributesData =
    getKustomerCustomAttributesData(
      conversationCreationCustomAttributes,
      kustomerConversationCreationCustomAttributes,
    );

  const kustomerAttributesContainErrors = [
    ...chatAgentHanndoffCustomAttributesData,
    ...conversationCreationCustomAttributesData,
  ].some(attribute => attribute.error);

  const isFormDisabled = () => {
    if (kustomerAttributesContainErrors || isPristine) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setCustomizationData(handoffCustomization);
    }
  }, [canvasState.isLoadingCustomization, handoffCustomization]);

  if (canvasState.isLoadingCustomization || !handoffCustomization) {
    return <LoadingSkeleton />;
  }

  return (
    <Container>
      <KustomerHandoffConfiguration
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
      <SaveContainer hasTopBorder>
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isFormDisabled() || hasWorkflowConflict}
          onClick={() => {
            dispatch(
              updateActionSettings({
                action_type: canvasState.actionSettings.action_type as string,
                configuration_fields: customizationData,
              }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.KUSTOMER_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </SaveContainer>
    </Container>
  );
};

export default KustomerHandoffCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
