import React, { Dispatch, FC, SetStateAction } from 'react';

import RadioButton from 'src/components/radio-button/RadioButton';
import {
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { ZendeskHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface AutoCreateTicketProps {
  customizationData: ZendeskHandoffCustomization;
  setCustomizationData: Dispatch<SetStateAction<ZendeskHandoffCustomization>>;
}
const AutoCreateTicket: FC<React.PropsWithChildren<AutoCreateTicketProps>> = ({
  customizationData,
  setCustomizationData,
}) => (
  <Row>
    <RadioButton
      checked={!customizationData.ticket_creation_settings?.create_ticket}
      onChange={() =>
        setCustomizationData(handoffData => {
          return {
            ...handoffData,
            ticket_creation_settings: {
              ...handoffData.ticket_creation_settings,
              create_ticket:
                !customizationData.ticket_creation_settings?.create_ticket,
            },
          };
        })
      }
    />
    <Spacer width='8px' />
    <span>Custom Message</span>
  </Row>
);

export default AutoCreateTicket;
