import { useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/fp/debounce';
import { SEARCH_TOPIC_FILTER_DEBOUNCE_DELAY } from 'src/components/dashboard-pages/discover-all-topics-page/constants';
import {
  DISCOVER_SHARED_PARAM_NAMES,
  initialSearchTextValue,
} from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import { genericSerializeAndDeserialize } from 'src/utils/discover/helpers';

export const useGetSearchTopicText = () => {
  const [searchText, setSearchText] = useStateParams({
    deserialize: genericSerializeAndDeserialize,
    initialState: initialSearchTextValue,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.SEARCH,
    serialize: genericSerializeAndDeserialize,
  });
  const [uiSearchText, setUiSearchText] = useState(searchText);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(SEARCH_TOPIC_FILTER_DEBOUNCE_DELAY, (value: string) =>
        setSearchText(value),
      ),
    [setSearchText],
  );

  useEffect(() => {
    if (uiSearchText !== searchText) {
      debouncedChangeHandler(uiSearchText);
    }
  }, [debouncedChangeHandler, searchText, uiSearchText]);

  return { searchText, setSearchText, setUiSearchText, uiSearchText };
};
