import Box from '@mui/material/Box';
import MuiTooltip from '@mui/material/Tooltip';
import { IconInfoCircle } from '@tabler/icons-react';

import { theme, Tooltip } from '@forethought-technologies/forethought-elements';
import { DiscoverTooltipVariants } from 'src/components/app/types';
import { getTooltipValueByMetricType } from 'src/utils/discover/helpers';

type TooltipIconSizes = 'medium' | 'small';

interface StaticDiscoverMetricTooltipProps {
  dynamicContent?: never;
  isLight?: boolean;
  metricType: DiscoverTooltipVariants;
  size?: TooltipIconSizes;
}

interface DynamicDiscoverMetricTooltipProps {
  dynamicContent: string;
  isLight?: boolean;
  metricType?: never;
  size?: TooltipIconSizes;
}

type DiscoverMetricTooltipProps =
  | StaticDiscoverMetricTooltipProps
  | DynamicDiscoverMetricTooltipProps;

const DiscoverMetricTooltip = ({
  dynamicContent,
  isLight,
  metricType,
  size = 'medium',
}: DiscoverMetricTooltipProps) => {
  const TooltipContent = metricType
    ? getTooltipValueByMetricType(metricType)
    : dynamicContent;

  if (!TooltipContent) {
    return null;
  }

  const dimensions =
    size === 'medium' ? { height: 16, width: 16 } : { height: 12, width: 12 };

  return isLight ? (
    <MuiTooltip
      arrow
      componentsProps={{
        popper: {
          sx: [
            {
              '& .MuiTooltip-arrow': {
                '&:before': {
                  border: `1px solid ${theme.palette.colors.slate[200]}`,
                },
                color: theme.palette.colors.white,
              },
              '& .MuiTooltip-tooltip': {
                backgroundColor: theme.palette.colors.white,
                border: `1px solid ${theme.palette.colors.slate[200]}`,
                color: theme.palette.colors.black,
                maxWidth: 320,
                whiteSpace: 'pre-line',
              },
            },
          ],
        },
      }}
      placement='bottom'
      title={
        typeof TooltipContent === 'string' ? TooltipContent : <TooltipContent />
      }
    >
      <Box display='inline-flex'>
        <IconInfoCircle color='currentColor' height={16} width={16} />
      </Box>
    </MuiTooltip>
  ) : (
    <Tooltip
      sx={{ alignItems: 'center', minHeight: '16px', minWidth: '16px' }}
      tooltipContent={
        typeof TooltipContent === 'string' ? TooltipContent : <TooltipContent />
      }
    >
      <IconInfoCircle color='currentColor' {...dimensions} />
    </Tooltip>
  );
};

export default DiscoverMetricTooltip;
