import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/system';

import { FilterButton } from '@forethought-technologies/forethought-elements';
import { setChannel } from 'src/actions/solveData';
import apiIcon from 'src/assets/images/chat-icon.svg';
import emailIcon from 'src/assets/images/email-icon.svg';
import widgetIcon from 'src/assets/images/web-icon.svg';
import SettingsLabel from 'src/components/reusable-components/settings-label';
import {
  selectChannel,
  selectIsSolveWorkflows,
} from 'src/reducers/solveReducer';
import { SolveChannels } from 'src/utils/enums';
import { getData } from 'src/utils/getDataUtil';

interface Props {
  channels: string[];
  eventTracker?: Function;
  isDropdownDisabled?: boolean;
}

const ChannelPicker: React.FC<React.PropsWithChildren<Props>> = ({
  channels,
  eventTracker,
  isDropdownDisabled,
}: Props) => {
  const dispatch = useDispatch();
  const channelPickerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const isSolveWorkflows = useSelector(selectIsSolveWorkflows);
  let selectedChannel = useSelector(selectChannel);
  // Solve workflows view only has widget as a channel
  selectedChannel = isSolveWorkflows ? 'widget' : selectedChannel;

  const channelOptions = channels.map(channel => ({
    label: channel[0].toLocaleUpperCase() + channel.substring(1),
    value: channel,
  }));

  const setSelectedChannel = (option: string) => {
    dispatch(setChannel(option));
    eventTracker && eventTracker(option);
    getData();
  };

  const icon = useMemo(() => {
    let _icon = null;
    switch (selectedChannel) {
      case SolveChannels.EMAIL:
        _icon = emailIcon;
        break;
      case SolveChannels.WIDGET:
        _icon = widgetIcon;
        break;
      case SolveChannels.API:
        _icon = apiIcon;
        break;
    }
    return _icon;
  }, [selectedChannel]);

  return (
    <Container className='view-dropdown' ref={channelPickerRef}>
      <SettingsLabel name={'channel'} />
      <FilterButton
        aria-label='channel selector'
        disabled={isDropdownDisabled}
        initialValue={SolveChannels.WIDGET}
        onChange={(value: string) => setSelectedChannel(value)}
        options={channelOptions}
        startAdornment={icon && <SVG src={icon} />}
        value={selectedChannel}
      />
    </Container>
  );
};

const SVG = styled(ReactSVG)`
  height: 16px;
  margin-right: 5px;
`;

const Container = styled('div')`
  display: flex;
  text-transform: capitalize;
  justify-content: center;
  flex-direction: column;
  margin: 0 8px;
`;

export default ChannelPicker;
