import { styled, useTheme } from '@mui/material';
import { Box } from '@mui/system';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

const statusToColor = {
  active: theme.palette.colors.green[500],
  disconnected: 'transparent',
  error: theme.palette.colors.red[500],
  idle: theme.palette.colors.grey[400],
  warning: theme.palette.colors.yellow[500],
} as const;

interface StatusIndicatorProps {
  height?: number;
  label?: string;
  status: keyof typeof statusToColor;
  width?: number;
}

export default function StatusIndicator({
  height = 12,
  label,
  status,
  width = 12,
}: StatusIndicatorProps) {
  const { palette } = useTheme();
  return (
    <Box alignItems='center' display='flex' gap='6px' justifyContent='center'>
      <ActivatedIcon
        bgcolor={statusToColor[status]}
        height={height}
        width={width}
      />
      {label && (
        <Typography color={palette.colors.grey[700]} variant='font14Bold'>
          {label}
        </Typography>
      )}
    </Box>
  );
}

const ActivatedIcon = styled(Box)<{ height: number; width: number }>`
  height: ${({ height }) => height.toString()}px;
  width: ${({ width }) => width.toString()}px;
  border-radius: 50%;
`;
