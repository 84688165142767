import { useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { selectTimeRange } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { selectIntentWorkflowId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { Routes } from 'src/utils/enums';
import { constructTimeRangeQueryParams } from 'src/utils/timeRangeHelpers';

export const useWorkflowDeletionChannelListener = () => {
  const navigate = useNavigate();
  const timeRange = useSelector(selectTimeRange);
  const currentIntentWorkflowId = useSelector(selectIntentWorkflowId);

  useEffect(() => {
    const workflowDeletionChannel = new BroadcastChannel('workflow-deletion');

    const workflowDeletionChannelHandler = (deletedWorkflowId: string) => {
      if (deletedWorkflowId === currentIntentWorkflowId) {
        navigate({
          pathname: Routes.WORKFLOW_BUILDER,
          search: `?${constructTimeRangeQueryParams(timeRange, 'widget')}`,
        });
      }
    };
    workflowDeletionChannel.addEventListener(
      'message',
      workflowDeletionChannelHandler,
    );

    return () => {
      workflowDeletionChannel.removeEventListener(
        'message',
        workflowDeletionChannelHandler,
      );
    };
  }, [currentIntentWorkflowId, navigate, timeRange]);
};
