export const intentSelectedOptions = [
  { disabled: true, label: 'Mixed intents', value: '' },
  { label: 'Active intents', value: 'All active intents' },
  { label: 'Inactive intents', value: 'All inactive intents' },
  { label: 'Autoflows', value: 'All autoflows' },
  { label: 'All intents', value: 'All intents' },
];

export const modeSelectedOptions = [
  { disabled: true, label: 'Mixed mode', value: '' },
  { label: 'Classic', value: 'All classic' },
  { label: 'Autoflow', value: 'All autoflow' },
];

export const statusSelectedOptions = [
  { disabled: true, label: 'Mixed status', value: '' },
  { label: 'Published', value: 'All published' },
  { label: 'Draft', value: 'All draft' },
];

export const darkModeBlue = '#42A4FF';

export const darkModeGreen = '#29CF26';

export const darkModeGrey = '#AABBCA';

export const darkModePurple = '#DE82FF';

export const darkModeOrange = '#FD9A4E';

export const autoflowColors = {
  dark: {
    agentResponse: darkModeOrange,
    agentThoughts: darkModeGrey,
    default: darkModeBlue,
    userQuery: darkModeGreen,
  },
  light: {
    agentResponse: darkModeOrange,
    agentThoughts: '#9194A0',
    default: '#1472FF',
    userQuery: '#17B790',
  },
};

export const INTENT_NOT_PREDICTED = 'intent_not_predicted';
