import './BreakdownModal.scss';

import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import ModalItem from '../../../reusable-components/modal-item/ModalItem';
import SearchBar from '../../../reusable-components/search-bar/SearchBar';
import {
  applyBreakdownField,
  clearSelectedBreakdownField,
  toggleBreakdownField,
} from 'src/actions/breakdowns/breakdowns';
import { getAnswersData } from 'src/actions/data/data';
import {
  getBreakdownFields,
  getSelectedBreakdownField,
} from 'src/reducers/breakdownsReducer/breakdownsReducer';
import { Field } from 'src/services/apiInterfaces';

interface BreakdownModalProps {
  handleModalVisibility(): void;

  reference: React.RefObject<HTMLDivElement>;
}

const BreakdownModal: React.FC<React.PropsWithChildren<BreakdownModalProps>> = (
  props: BreakdownModalProps,
) => {
  const { handleModalVisibility, reference } = props;
  const [search, setSearch] = useState<string>('');
  const [selectedIndexType, setSelectedIndexType] = useState<number>(0);
  const types = ['AGENT', 'TICKET'];
  const breakdownFields = useSelector(getBreakdownFields);
  const selectedField = useSelector(getSelectedBreakdownField);

  const dispatch = useDispatch();

  const getFields = (index: number): Field[] => {
    const fields: Field[] = [];

    if (index === 0) {
      breakdownFields.agent.map((field: Field) => {
        const newField = { ...field, field_category: 'Agent' };
        fields.push(newField);
      });
    } else if (index === 1) {
      breakdownFields.ticket.map((field: Field) => {
        const newField = { ...field, field_category: 'Ticket' };
        fields.push(newField);
      });
    }

    return fields;
  };

  const matchedItems = getFields(selectedIndexType).filter(field => {
    if (field.field_name.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
  });

  const applyBreakdownButton = classNames(
    'BreakdownModal-applyBreakdownButton',
    {
      'BreakdownModal-EnabledApplyBreakdownButton': selectedField !== null,
    },
  );

  const handleOnChange = (value: string) => {
    setSearch(value);
  };

  return (
    <div className='BreakdownModal-container' ref={reference}>
      <SearchBar
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleOnChange(e.target.value);
        }}
      />

      <div className='BreakdownModal-typeContainer'>
        {types.map((type, index) => (
          <div
            className={
              index === selectedIndexType
                ? 'BreakdownModal-type BreakdownModal-selectedType'
                : 'BreakdownModal-type'
            }
            key={type}
            onClick={() => {
              dispatch(clearSelectedBreakdownField());
              setSelectedIndexType(index);
            }}
          >
            {type}
          </div>
        ))}
      </div>
      <div className='BreakdownModal-content'>
        {!matchedItems.length ? (
          <span className='BreakdownModal-noResults'>No results</span>
        ) : (
          matchedItems.map(field => {
            const isSelected =
              selectedField?.field_id === field.field_id &&
              selectedField?.field_name === field.field_name;

            return (
              <ModalItem
                fieldType={field.field_type}
                isDark
                isSelected={isSelected}
                itemValue={field.field_name}
                key={field.field_name}
                onClickFunction={() => dispatch(toggleBreakdownField(field))}
              />
            );
          })
        )}
      </div>
      <button
        className={applyBreakdownButton}
        disabled={selectedField ? false : true}
        onClick={() => {
          dispatch(applyBreakdownField(selectedField));
          handleModalVisibility();
          dispatch(getAnswersData());
        }}
      >
        Apply Breakdown
      </button>
    </div>
  );
};

export default BreakdownModal;
