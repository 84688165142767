import React from 'react';
import { styled } from '@mui/material';

import {
  Checkbox,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import { Section } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';

interface IntercomOptionComponentProps {
  checked: boolean;
  label: string;
  onChange: React.FormEventHandler<HTMLInputElement>;
  tooltip?: string;
}

const IntercomOptionComponent = ({
  checked,
  label,
  onChange,
  tooltip,
}: IntercomOptionComponentProps) => (
  <Section>
    <CheckBoxOption>
      <Checkbox checked={checked} label={label} onChange={onChange} />
      {tooltip && (
        <Tooltip tooltipContent={tooltip}>
          <Icon src={tooltipInfoIcon} />
        </Tooltip>
      )}
    </CheckBoxOption>
  </Section>
);

export default IntercomOptionComponent;

const CheckBoxOption = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

const Icon = styled('img')`
  margin-bottom: 8px;
  margin-left: 4px;
`;
