import Pusher from 'pusher-js';

import { SOLVE_INTENT_TESTS_BASE_URL, SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import {
  CreateIntentTestRequest,
  FeedbackRecord,
  IntentDefinitionModel,
  IntentPredictionRecords,
  IntentTestResponse,
  IntentTestsResponse,
  IntentTestUpdateEvent,
} from './types';
import { PusherEvents } from 'src/hooks/PusherEventHooks';

const intentTestApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    createIntentPredictionFeedback: builder.mutation<void, FeedbackRecord>({
      async onQueryStarted(feedbackRecord, { dispatch }) {
        const { intent_prediction_record_id, intent_test_id } = feedbackRecord;
        dispatch(
          intentTestApi.util.updateQueryData(
            'getIntentTestPredictions',
            intent_test_id,
            data => {
              const updateRecord = data.records.find(
                record => record.record_id === intent_prediction_record_id,
              );
              if (!updateRecord) {
                return;
              }
              if (!updateRecord.feedback_records) {
                updateRecord.feedback_records = [];
              }
              updateRecord.feedback_records.unshift(feedbackRecord);
            },
          ),
        );
      },
      query: FeedbackRecord => ({
        body: FeedbackRecord,
        method: 'POST',
        url: SOLVE_V2_BASE_URL + '/intent-prediction-feedback',
      }),
    }),
    createIntentTest: builder.mutation<
      IntentTestResponse,
      CreateIntentTestRequest
    >({
      invalidatesTags: ['IntentTests'],
      query: createIntentTestRequest => ({
        body: createIntentTestRequest,
        method: 'POST',
        url: SOLVE_INTENT_TESTS_BASE_URL,
      }),
    }),
    getEligibleIntents: builder.query<IntentDefinitionModel[], void>({
      query: () => `${SOLVE_INTENT_TESTS_BASE_URL}/eligible-intents`,
    }),
    getIntentTestPredictions: builder.query<IntentPredictionRecords, string>({
      query: (intentTestId: string) =>
        `${SOLVE_INTENT_TESTS_BASE_URL}/${intentTestId}/predictions`,
    }),
    getIntentTests: builder.query<IntentTestsResponse, void>({
      async onCacheEntryAdded(
        _,
        { cacheDataLoaded, cacheEntryRemoved, updateCachedData },
      ) {
        // create a websocket connection when the cache subscription starts
        if (!window.pusher) {
          window.pusher = new Pusher(PUSHER_KEY, {
            cluster: PUSHER_CLUSTER,
          });
        }
        let updateChannel: string | null = null;
        try {
          // wait for the initial query to resolve
          const { data } = await cacheDataLoaded;
          updateChannel = data.update_channel;

          const channel = window.pusher.subscribe(updateChannel);
          // on event, merge the updated intent test into the cache
          const callback = (updateEvent: IntentTestUpdateEvent) => {
            updateCachedData(draftState => {
              const updatedTests = draftState.intent_tests.map(intentTest => {
                if (intentTest.intent_test_id === updateEvent.intent_test_id) {
                  return { ...intentTest, ...updateEvent };
                }
                return intentTest;
              });
              draftState.intent_tests = updatedTests;
            });
          };
          channel.bind(PusherEvents.INTENT_TEST_UPDATED, callback);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`, in which case `cacheDataLoaded` will throw
        }
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        await cacheEntryRemoved;
        if (updateChannel) {
          window.pusher.unsubscribe(updateChannel);
        }
      },
      providesTags: ['IntentTests'],
      query: () => SOLVE_INTENT_TESTS_BASE_URL,
    }),
  }),
});

export const {
  useCreateIntentPredictionFeedbackMutation,
  useCreateIntentTestMutation,
  useGetEligibleIntentsQuery,
  useGetIntentTestPredictionsQuery,
  useGetIntentTestsQuery,
} = intentTestApi;
