import './init';
import './polyfills';
import './index.scss';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { ThemeWrapper } from '@forethought-technologies/forethought-elements';
import InitApp from './components/authentication-wall/initApp';
import { initSentry } from './utils/sentryUtils';
import UnsecuredPage from 'src/components/unsecured-page';
import store from 'src/store/store';
import { initLogRocket } from 'src/utils/logRocketUtils';

initLogRocket();
initSentry();

const container = document.getElementById('root');

if (container) {
  if (window.self === window.top) {
    // prevents click jacking
    const root = createRoot(container);
    root.render(
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <ThemeWrapper>
            <Router>
              <InitApp />
            </Router>
          </ThemeWrapper>
        </Provider>
      </Sentry.ErrorBoundary>,
    );
  } else {
    const root = createRoot(container);
    root.render(<UnsecuredPage />);
  }
}
