import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import noTopMoversImage from 'src/assets/images/discover-no-top-movers.svg';

const DashboardTabEmpty = () => {
  const { palette } = useTheme();
  // Height after calculating navbar + inner-navbar + margins
  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      height={'calc(100vh - 450px)'}
      marginTop='100px'
    >
      <img src={noTopMoversImage} width={145} />
      <Box mb={1} mt={3}>
        <Typography color={palette.colors.grey[700]} variant='font20'>
          No tickets found for date range
        </Typography>
      </Box>
      <Typography variant='font14'>
        Select a different date range to view data
      </Typography>
    </Box>
  );
};

export default DashboardTabEmpty;
