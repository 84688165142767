import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { css, styled } from '@mui/material';

import profileBot from './assets/icons/profile-bot.svg';
import { Condition } from './conditions/Condition';
import RestartConversationStep from './restart-card/RestartConversationStep';
import ArticleStep from './ArticleStep';
import AttachmentAnalysisUploadStep from './AttachmentAnalysisUploadStep';
import ButtonsStep from './ButtonsStep';
import CsatTriggerPointStep from './CsatTriggerPointStep';
import { EmbedStep } from './EmbedStep';
import FormStep from './FormStep';
import { ImageStep } from './ImageStep';
import TextStep from './TextStep';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import GoToIntentStep from 'src/pages/workflow-builder-edit/go-to-step/GoToIntentStep';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { StepTypes } from 'src/utils/enums';

interface GenericStepProps {
  isCanvasDisabled: boolean;
  isInAction: boolean;
  stepId: string;
}

const GenericStep: React.FC<React.PropsWithChildren<GenericStepProps>> = ({
  isCanvasDisabled,
  isInAction,
  stepId,
}) => {
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const { steps } = canvasData;
  const stepType = canvasData.steps[stepId].step_type;
  const hideProfileIcon =
    stepType === StepTypes.GO_TO_INTENT ||
    stepType === StepTypes.RESTART_CONVERSATION ||
    stepType === StepTypes.ARTICLE ||
    stepType === StepTypes.CONDITION ||
    stepType === StepTypes.IMAGE ||
    stepType === StepTypes.EMBED ||
    stepType === StepTypes.FORM ||
    stepType === StepTypes.BUTTONS ||
    stepType === StepTypes.CSAT_TRIGGER_POINT ||
    stepType === StepTypes.ATTACHMENT_ANALYSIS_UPLOAD;
  const [shouldShowEditMenu, setShouldShowEditMenu] = useState(false);

  return (
    <Container
      isRevampedStep={stepType !== StepTypes.TEXT_MESSAGE}
      isRevampedTextMessage={stepType === StepTypes.TEXT_MESSAGE}
      onMouseEnter={() => setShouldShowEditMenu(true)}
      onMouseLeave={() => setShouldShowEditMenu(false)}
    >
      {!hideProfileIcon && <Image src={profileBot} />}
      {stepType === StepTypes.TEXT_MESSAGE && (
        <TextStep
          contextVariables={contextVariables}
          isCanvasDisabled={isCanvasDisabled}
          isInAction={isInAction}
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.CONDITION && (
        <Condition
          isCanvasDisabled={isCanvasDisabled}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.BUTTONS && (
        <ButtonsStep
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.FORM && (
        <FormStep
          canvasData={canvasData}
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.GO_TO_INTENT && (
        <GoToIntentStep
          canvasData={canvasData}
          isCanvasDisabled={isCanvasDisabled}
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.RESTART_CONVERSATION && (
        <RestartConversationStep
          isCanvasDisabled={isCanvasDisabled}
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.ARTICLE && (
        <ArticleStep
          isCanvasDisabled={isCanvasDisabled}
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.IMAGE && (
        <ImageStep
          contextVariables={contextVariables}
          isCanvasDisabled={isCanvasDisabled}
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.EMBED && (
        <EmbedStep
          isCanvasDisabled={isCanvasDisabled}
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.CSAT_TRIGGER_POINT && (
        <CsatTriggerPointStep
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {stepType === StepTypes.ATTACHMENT_ANALYSIS_UPLOAD && (
        <AttachmentAnalysisUploadStep
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowEditMenu={shouldShowEditMenu}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
    </Container>
  );
};

export default GenericStep;

const Image = styled('img')`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;

const Container = styled('div')<{
  isRevampedStep: boolean;
  isRevampedTextMessage: boolean;
}>`
  border: 1px solid transparent; // prevent layout shift on hover
  display: flex;
  width: 100%;
  padding: 10px 20px;

  :hover {
    background-color: #f8f8f8;
  }
  ${props =>
    props.isRevampedTextMessage &&
    css`
      padding: 20px;
      :hover {
        background-color: transparent;
        border: 1px solid rgb(188, 189, 197);
      }
    `}

  ${props =>
    props.isRevampedStep &&
    css`
      padding: 0;
      :hover {
        background-color: transparent;
      }
    `}
`;
