import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  SearchBar,
} from '@forethought-technologies/forethought-elements';
import UserMetrics from '../user-metrics';
import UsersTable from '../users-table';

export default function TeamMembersTab() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');

  return (
    <>
      <UserMetrics />
      <Box padding='0 40px 32px'>
        <UsersTable
          filterQuery={query}
          topToolbar={
            <Box
              alignItems='center'
              display='flex'
              flex={1}
              gap={1}
              justifyContent='space-between'
              paddingTop='4px'
            >
              <Box width='250px'>
                <SearchBar
                  aria-label='Users search bar filter'
                  fullWidth
                  onChange={({ target }) => setQuery(target.value)}
                  onClear={() => setQuery('')}
                  placeholder='Search users'
                  size='small'
                  value={query}
                />
              </Box>
              <Button
                onClick={() => navigate({ search: 'tab=1' })}
                startIcon={<IconPlus size={16} />}
                variant='secondary'
              >
                Invite new member
              </Button>
            </Box>
          }
        />
      </Box>
    </>
  );
}
