import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
import assistAutomationPreviewIcon from 'src/assets/images/assist-automation-preview-icon.svg';
import Card from 'src/components/card';
import { getShortDate } from 'src/components/dashboard-pages/integrations-page/integration-drawer/integration-overview/utils';
import { Automation } from 'src/services/apiInterfaces';
import { Routes } from 'src/utils/enums';

interface AutomationCardProps {
  automation: Automation;
}

export default function AutomationCard({ automation }: AutomationCardProps) {
  const {
    automation_id,
    created_on,
    description,
    emblem_urls,
    published,
    title,
  } = automation;

  return (
    <StyledLink to={`${Routes.ASSIST_AUTOMATIONS}?automation=${automation_id}`}>
      <Card
        iconSrc={
          emblem_urls.length ? emblem_urls[0] : assistAutomationPreviewIcon
        }
        linkText='Manage'
        onClick={() => ({})}
        status={published ? 'active' : 'warning'}
        statusLabel={`Created on ${getShortDate(created_on)}`}
        subtitle={description}
        title={title}
      />
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.palette.colors.black};
`;
