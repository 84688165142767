import Box from '@mui/material/Box';

import {
  Drawer,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TriageConfigDetailPredictions from '../../triage-config-detail-page/triage-config-detail-demo';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

export interface TestModelPanelProps {
  isOpen: boolean;
  model: VersionedTriageModel;
  setIsOpen: (isOpen: boolean) => void;
}

const TestModelPanel = ({ isOpen, model, setIsOpen }: TestModelPanelProps) => {
  return (
    <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} width='434px'>
      <Box display='flex' flexDirection='column' p={3} rowGap={4}>
        <Typography variant='font20'>Test</Typography>
        <Box display='flex' flexDirection='column'>
          <TriageConfigDetailPredictions selfServeModel={model} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default TestModelPanel;
