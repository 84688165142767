import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import {
  Checkbox,
  theme,
} from '@forethought-technologies/forethought-elements';
import { Spacer } from '../handoff-configuration/styles';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectActionSettingsConfigurationFields } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectAddingAction } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { LiveChatChatHandoffActionSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const LiveChatChatHandoffCustomizationPanel: React.FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const addingAction = useSelector(selectAddingAction);
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as LiveChatChatHandoffActionSettings;
  const initialCustomizationData: LiveChatChatHandoffActionSettings =
    addingAction
      ? {
          email_variable: null,
          include_transcript: false,
          name_variable: null,
          question_variable: 'free_form_query',
        }
      : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<LiveChatChatHandoffActionSettings>(initialCustomizationData);
  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (actionSettingsConfigurationFields) {
      setCustomizationData({ ...actionSettingsConfigurationFields });
    }
  }, [actionSettingsConfigurationFields]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>Live Chat Chat Handoff</Title>
        <Spacer height='20px' />
        <Section>
          <Checkbox
            checked={customizationData.include_transcript}
            label='Send transcript with question'
            onChange={() => {
              setCustomizationData({
                ...customizationData,
                include_transcript: !customizationData.include_transcript,
              });
            }}
          />
        </Section>
        <Section>
          <ContextVariableSelectDropdown
            label='Question Context Variable'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                question_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.question_variable}
          />
        </Section>
        <Section>
          <ContextVariableSelectDropdown
            label='Name Context Variable'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                name_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.name_variable || ''}
          />
        </Section>
        <Section>
          <ContextVariableSelectDropdown
            label='Email Context Variable'
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                email_variable: value,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.email_variable || ''}
          />
        </Section>
      </Box>
      {/* Save and Cancel Buttons */}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isPristine || hasWorkflowConflict}
          onClick={() => {
            if (addingAction) {
              dispatch(
                addAction({
                  ...addingAction,
                  data: {
                    ...addingAction.data,
                    configuration_fields: { ...customizationData },
                  },
                }),
              );
            } else {
              dispatch(
                updateActionSettings({
                  action_type: ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF,
                  configuration_fields: { ...customizationData },
                }),
              );
            }

            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default LiveChatChatHandoffCustomizationPanel;

const Title = styled('h1')`
  font-size: var(--font-size-m);
  text-align: left;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  cursor: default;
  margin: 0 10px;
`;

const Section = styled('section')`
  border-top: 1px solid ${theme.palette.colors.grey[100]};
  border-left: none;
  border-right: none;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;
