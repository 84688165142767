import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';

import { CanvasModes } from '../../constants';
import BaseDraggableCard from './BaseDraggableCard';
import { BusinessLogicProps } from './types';
import { DRAG_AND_DROP_NEW_STEP_TYPE } from 'src/constants/solve';
import {
  selectChatOrder,
  selectChatOrderLastStepId,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { startAddingActionThunk } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useAppDispatch } from 'src/store/hooks';
import { Action, ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { RevampedDragNDrops, StepTypes } from 'src/utils/enums';
import { formRequiredActions, isDropValid } from 'src/utils/solve/stepUtils';

interface DraggableCardProps {
  isDisabled: boolean;
  label: string;
  mode: CanvasModes;
  stepType: StepTypes;
}

const useDragStep = ({
  action,
  isDisabled,
  mode,
  setActionPanelVisibilityParameters,
  squeezeStepParentId,
}: BusinessLogicProps & { action?: Action; mode: CanvasModes }) => {
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const chatOrder = useSelector(selectChatOrder);

  const dispatch = useAppDispatch();

  const [{ opacity }, drag] = useDrag(
    () => ({
      canDrag: !isDisabled,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
      }),
      end: (_, monitor) => {
        if (!action) {
          return;
        }

        const shouldReturnEarly = !isDropValid(monitor.getDropResult());

        if (shouldReturnEarly) {
          return;
        }

        if (!monitor.didDrop()) {
          return;
        }

        if (!formRequiredActions.includes(action.action_type)) {
          return;
        }

        const payload = {
          action,
          data: {
            action_id: action.action_id,
          },
        };

        dispatch(startAddingActionThunk(payload));
        setActionPanelVisibilityParameters(
          action.action_type as ActionPanelMode,
        );
      },
      item: { mode },
      type: action
        ? RevampedDragNDrops.REVAMPED_FT_ACTION
        : DRAG_AND_DROP_NEW_STEP_TYPE,
    }),
    [
      isDisabled,
      dispatch,
      mode,
      squeezeStepParentId,
      chatOrder.length,
      lastStepId,
      isDisabled,
      action,
    ],
  );

  return { drag, opacity };
};

const DraggableCard = ({
  isDisabled,
  label,
  mode,
  stepType,
  ...rest
}: DraggableCardProps & BusinessLogicProps) => {
  const { data } = useGetActionBuilderActionsQuery({});
  const action = data?.actions.find(action => action.action_type === stepType);

  const { drag, opacity } = useDragStep({ action, isDisabled, mode, ...rest });

  return (
    <BaseDraggableCard
      drag={drag}
      label={label}
      opacity={opacity}
      stepType={stepType}
    />
  );
};

export default DraggableCard;
