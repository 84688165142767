import { combineReducers } from '@reduxjs/toolkit';

import analyticsReducer from '../reducers/analyticsReducer/analyticsReducer';
import breakdownsReducer from '../reducers/breakdownsReducer/breakdownsReducer';
import dataDeprecatedReducer from '../reducers/dataDeprecatedReducer/dataDeprecatedReducer';
import discoverReducer from '../reducers/discoverReducer/discoverReducer';
import filtersReducer from '../reducers/filtersReducer/filtersReducer';
import pageSettingsReducer from '../reducers/pageSettingsReducer/pageSettingsReducer';
import reportsReducer from '../reducers/reportsReducer/reportsReducer';
import searchReducer from '../reducers/searchReducer/searchReducer';
import solveDataReducer from '../reducers/solveReducer/index';
import toastNotificationReducer from '../reducers/toastNotificationReducer/toastNotificationReducer';
import userReducer from '../reducers/userReducer/userReducer';
import { dashboardApi } from '../services/dashboard-api';
import assistAutomationsReducer from '../slices/assist-automations/assistAutomationsSlice';
import assistNotes from '../slices/assist-notes';
import auditSlice from '../slices/audit/auditSlice';
import canvasWorkflowBuilderReducer from '../slices/canvas-workflow-builder/workflowBuilderSlice';
import connectorsReducer from '../slices/connectors/connectorsSlice';
import dataSlice from '../slices/data/dataSlice';
import emailBuilderSlice from '../slices/email-builder/emailBuilderSlice';
import emailTranslationSlice from '../slices/email-translations/emailTranslationsSlice';
import emailWorkflowSlice from '../slices/email-workflow/emailWorkflowSlice';
import experimentsSlice from '../slices/experiments/experimentsSlice';
import sessionReducer from '../slices/session/sessionSlice';
import settingsReducer from '../slices/settings/settingsSliceInit';
import solveConfigSlice from '../slices/solve-config/solveConfigSlice';
import solveInsightsSlice from '../slices/solve-insights/solveInsightsSlice';
import teamSlice from '../slices/team/teamSlice';
import ticketFieldMappingReducer from '../slices/ticket-field-mappings/ticketFieldMappingsSlice';
import triageAnalyticsSlice from '../slices/triage-analytics/triageAnalyticsSlice';
import uiSlice from '../slices/ui/uiSlice';
import workflowPreviewSlice from '../slices/workflow-preview/workflowPreviewSlice';
import actionBuilderReducer from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import macroControlsDataReducer from 'src/reducers/macroControlsReducer/index';
import solveWorkflowsReducer from 'src/reducers/solveWorkflowsReducer';
import superAdminReducer from 'src/reducers/superAdminReducer/superAdminReducer';
import triageSettingsReducer from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import workflowBuilderReducer from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import ssoSlice from 'src/slices/sso/ssoSlice';

const rootReducer = combineReducers({
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  actionBuilder: actionBuilderReducer,
  analytics: analyticsReducer,
  assistAutomations: assistAutomationsReducer,
  assistNotes,
  audit: auditSlice,
  breakdowns: breakdownsReducer,
  canvasWorkflowBuilder: canvasWorkflowBuilderReducer,
  connectors: connectorsReducer,
  data: dataSlice,
  dataDeprecated: dataDeprecatedReducer,
  discover: discoverReducer,
  emailBuilder: emailBuilderSlice,
  emailTranslations: emailTranslationSlice,
  emailWorkflow: emailWorkflowSlice,
  experiments: experimentsSlice,
  filters: filtersReducer,
  macroControlsData: macroControlsDataReducer,
  pageSettings: pageSettingsReducer,
  reports: reportsReducer,
  search: searchReducer,
  session: sessionReducer,
  settings: settingsReducer,
  solveConfig: solveConfigSlice,
  solveData: solveDataReducer,
  solveInsights: solveInsightsSlice,
  solveWorkflows: solveWorkflowsReducer,
  sso: ssoSlice,
  superAdmin: superAdminReducer,
  team: teamSlice,
  ticketFieldMapping: ticketFieldMappingReducer,
  toastNotification: toastNotificationReducer,
  triageAnalytics: triageAnalyticsSlice,
  triageSettings: triageSettingsReducer,
  ui: uiSlice,
  user: userReducer,
  workflowBuilder: workflowBuilderReducer,
  workflowPreview: workflowPreviewSlice,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
