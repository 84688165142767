//todo change file location
import { Filters } from 'src/services/apiInterfaces';

export const convertRecievedFilter = (filter: Filters): string => {
  const operator = filter.operator;
  const negate = filter.negate;

  if (operator === '>') {
    return 'GREATER THAN';
  }
  if (operator === '<') {
    return 'LESS THAN';
  }
  if (operator === '=' && !negate) {
    return 'EQUALS';
  }
  if (operator === '=' && negate) {
    return 'DOES NOT EQUAL';
  }

  if (operator === 'contains' && !negate) {
    return 'CONTAINS';
  }
  if (operator === 'contains' && negate) {
    return 'DOES NOT CONTAIN';
  }

  if (operator === 'startswith') {
    return 'STARTS WITH';
  }
  if (operator === 'endswith') {
    return 'ENDS WITH';
  }
  if (operator === 'exists' && !negate) {
    return 'EXISTS';
  }

  if (operator === 'exists' && negate) {
    return 'DOES NOT EXIST';
  }

  if (operator === 'between') {
    return 'BETWEEN';
  }

  return '';
};
