import React, { FocusEventHandler, useRef, useState } from 'react';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import SimpleTag from '../../../components/reusable-components/simple-tag/SimpleTag';
import { isContextVariable } from '../handoff-configuration/helpers';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from '../hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import isEmpty from 'lodash/fp/isEmpty';
import { Label } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { ContextVariableSuggestions } from 'src/pages/workflow-builder-edit/suggestions/SuggestionsFactory';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

interface TagInputWithContextVariablesProps {
  allowSpaces?: boolean;
  contextVariables: ContextVariable[];
  // if tags can allow strings with spaces.
  errorMessages: Array<string | Array<string>>;
  errorTag: number;
  handleBlur?: FocusEventHandler<HTMLElement>;
  handleTagAddition: (input: string) => void;
  handleTagDeletion: (tagIndex: number) => void;
  label?: string;
  tags: Array<string>;
}

const TagInputWithContextVariables: React.FC<
  React.PropsWithChildren<TagInputWithContextVariablesProps>
> = ({
  contextVariables,
  errorMessages,
  errorTag,
  handleBlur,
  handleTagAddition,
  handleTagDeletion,
  label,
  tags,
}: TagInputWithContextVariablesProps) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();

  const inputRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState('');

  const isSearchingContextVariables = input.charAt(0) === '$';
  const tagErrorMessages = (errorMessages[errorTag] as Array<string>) ?? [];
  const isAtLeastOneTagWithError = tagErrorMessages.some(
    (err: string) => !!err,
  );

  const handleTagColor = (tag: string) => {
    if (isContextVariable(tag)) {
      // Get cv id from tag
      const cleanTag = tag.substring(2, tag.length - 2);
      const cv = contextVariables.find(
        cv => cv.context_variable_name === cleanTag,
      );
      if (undefinedContextVariableIds.includes(cv?.context_variable_id ?? '')) {
        return theme.palette.colors.red[500];
      } else {
        return theme.palette.colors.purple[500];
      }
    }

    return theme.palette.colors.grey[900];
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      {isSearchingContextVariables && !isEmpty(contextVariables) && (
        <ContextVariableSuggestions
          indexData={contextVariables}
          onMouseDown={result => {
            handleTagAddition(`{{${result.context_variable_name}}}`);
            setInput('');
          }}
          search={input.slice(1)}
          tetheredRef={inputRef}
          width='25%'
        />
      )}
      <TagEditor hasError={isAtLeastOneTagWithError}>
        <TagsContainer shouldAddMargin={tags.length > 0}>
          {tags.map((tag, tagIndex) => (
            <TagContainer hasError={!!tagErrorMessages[tagIndex]} key={tag}>
              <SimpleTag
                backgroundColor={theme.palette.colors.grey[100]}
                color={handleTagColor(tag)}
                onClick={() => handleTagDeletion(tagIndex)}
                text={
                  isContextVariable(tag)
                    ? `$${tag.slice(2, tag.length - 2)}`
                    : tag
                }
              />
            </TagContainer>
          ))}
        </TagsContainer>
        <Input
          aria-label='Tags'
          onBlur={handleBlur}
          onChange={e => setInput(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              const trimmedInput = input.trim();
              if (trimmedInput) {
                const tag =
                  trimmedInput.charAt(0) === '$'
                    ? `{{${trimmedInput.slice(1)}}}`
                    : trimmedInput;
                handleTagAddition(tag);
                setInput('');
              }
            }
          }}
          placeholder={'Press enter to add a new tag'}
          ref={inputRef}
          value={input}
        />
      </TagEditor>
      {isAtLeastOneTagWithError && (
        <ErrorMessage>
          {tagErrorMessages.filter((err: string) => !!err)[0]}
        </ErrorMessage>
      )}
    </>
  );
};

export default TagInputWithContextVariables;

const TagEditor = styled('div')<{ hasError: boolean }>`
  padding: 10px 12px 12px 12px;
  border-radius: 4px;
  border: 1px solid
    ${props =>
      props.hasError
        ? Colors.ui.semantic.destructive.default
        : theme.palette.colors.grey[300]};
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.colors.white};

  &:focus-within {
    ${props =>
      !props.hasError && `border: 1px solid ${theme.palette.primary.main}`};
  }
`;

const TagsContainer = styled('div')<{ shouldAddMargin: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${props => (props.shouldAddMargin ? '10px' : '0')};
`;

const TagContainer = styled('div')<{ hasError: boolean }>`
  margin: 5px 5px 0 0;
  ${props =>
    props.hasError &&
    `border: 1px solid ${Colors.ui.semantic.destructive.default}`};
`;

const Input = styled('input')`
  border: none;
  outline: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-style: italic;
    font-size: 16px;
    color: ${Colors.ui.text.placeholder};
  }
`;

const ErrorMessage = styled('div')`
  color: ${Colors.ui.semantic.destructive.default};
`;
