import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import {
  Button,
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import { AddIcon } from '../../../customization-panel/article-suggestion-settings-panel';
import IntentEmailArticleSource from './IntentEmailArticleSource';
import IntentEmailForm from './IntentEmailForm';
import set from 'lodash/fp/set';
import addPurpleIcon from 'src/assets/images/add-purple-icon.svg';
import { formatArticleSourceQueries } from 'src/components/customization-panel/article-suggestion-settings-panel/formatArticleSourceQueries';
import {
  DynamicArticleComponentFields,
  ToolbarFormComponentProps,
} from 'src/components/email-builder/types';
import { selectAvailableArticleSources } from 'src/slices/email-builder/emailBuilderSlice';
import {
  ArticleSource,
  ArticleSuggestionFilterQuery,
} from 'src/types/workflowBuilderAPITypes';

const IntentEmailDynamicArticleForm = ({
  component,
  onClickCancel,
  onClickSave,
  setIsActiveComponentValid,
}: ToolbarFormComponentProps) => {
  const [componentFields, setComponentFields] = useState(
    component.component_fields as DynamicArticleComponentFields,
  );

  const availableArticleSources = useSelector(selectAvailableArticleSources);

  const {
    article_sources: articleSources,
    number_of_articles: numberOfArticles,
  } = componentFields;

  const isFormInvalid = useMemo(() => {
    return articleSources.some(articleSource => {
      if (!articleSource.doc_type) {
        return true;
      }
      return articleSource.filter_queries?.some(
        filterQuery =>
          !filterQuery.field ||
          !filterQuery.value ||
          filterQuery.value.value === '',
      );
    });
  }, [articleSources]);

  useEffect(() => {
    // set flag for parent component to show confirmation dialog when clicking outside
    setIsActiveComponentValid(!isFormInvalid);
  }, [isFormInvalid, setIsActiveComponentValid]);

  const updateArticleSource = (
    updatedArticleSource: ArticleSource,
    index: number,
  ) => {
    return set(
      ['article_sources', index],
      updatedArticleSource,
      componentFields,
    );
  };

  const deleteArticleSource = (index: number) => {
    return {
      ...componentFields,
      article_sources: componentFields.article_sources.filter(
        (_, fieldIndex) => fieldIndex !== index,
      ),
    };
  };

  const addArticleSource = () => {
    return {
      ...componentFields,
      article_sources: [
        ...componentFields.article_sources,
        { doc_type: '', filter_queries: [] },
      ],
    };
  };

  const getFilterQueryToAdd = (articleSourceIndex: number) => {
    const articleSourceToUpdate =
      componentFields.article_sources[articleSourceIndex];
    const filterQueriesLength = articleSourceToUpdate.filter_queries?.length;
    const isFilterQueriesEmpty = !filterQueriesLength;

    const newFilterQuery: ArticleSuggestionFilterQuery = {
      boolean_operator: null,
      field: '',
      operator: 'is',
      value: null,
    };

    if (isFilterQueriesEmpty) {
      return newFilterQuery;
    }
    return set(
      'boolean_operator',
      filterQueriesLength && filterQueriesLength === 1
        ? 'and'
        : articleSourceToUpdate.filter_queries[1].boolean_operator,
      newFilterQuery,
    );
  };

  const addFilterQuery = (articleSourceIndex: number) => {
    const articleSourceToUpdate =
      componentFields.article_sources[articleSourceIndex];
    const filterQueriesLength = articleSourceToUpdate.filter_queries?.length;
    const isFilterQueriesEmpty = !filterQueriesLength;

    const updatedArticleSource = set(
      ['filter_queries', isFilterQueriesEmpty ? 0 : filterQueriesLength],
      getFilterQueryToAdd(articleSourceIndex),
      articleSourceToUpdate,
    );

    return set(
      ['article_sources', articleSourceIndex],
      updatedArticleSource,
      componentFields,
    );
  };

  useEffect(() => {
    setComponentFields(
      component.component_fields as DynamicArticleComponentFields,
    );
  }, [component]);

  return (
    <IntentEmailForm
      isSaveButtonDisabled={isFormInvalid}
      onClickCancel={() => onClickCancel()}
      onClickSave={() => {
        onClickSave(componentFields);
      }}
    >
      <Box
        height='100%'
        overflow='scroll'
        sx={{ label: { fontSize: '16px', fontWeight: 600 } }}
      >
        <Box
          borderBottom={`1px solid ${theme.palette.colors.slate[200]}`}
          marginBottom='12px'
          paddingBottom='23px'
        >
          <SelectDropdown
            id='article-number-selector'
            label='Show # of articles'
            onChange={e => {
              setComponentFields({
                ...componentFields,
                number_of_articles: Number(e.target.value),
              });
            }}
            options={[
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
              { label: '5', value: '5' },
            ]}
            value={numberOfArticles.toString()}
          />
        </Box>
        <Box marginBottom='24px'>
          {articleSources.map((articleSource, index) => (
            <IntentEmailArticleSource
              addFilterQuery={() => setComponentFields(addFilterQuery(index))}
              articleSource={articleSource}
              deleteArticleSource={() =>
                setComponentFields(deleteArticleSource(index))
              }
              index={index}
              isDeleteButtonDisabled={articleSources.length === 1}
              key={index}
              setArticleSource={updatedArticleSource => {
                const [convertedArticleSource] = formatArticleSourceQueries(
                  [updatedArticleSource],
                  availableArticleSources,
                );

                setComponentFields(
                  updateArticleSource(convertedArticleSource, index),
                );
              }}
            />
          ))}
        </Box>
        <Button
          onClick={() => {
            setComponentFields(addArticleSource());
          }}
          variant='secondary'
        >
          <AddIcon src={addPurpleIcon} />
          Add article source
        </Button>
      </Box>
    </IntentEmailForm>
  );
};

export default IntentEmailDynamicArticleForm;
