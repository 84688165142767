import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DUPLICATE_ACTION_TEXT } from '../constants';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

const createMessage = (
  model: VersionedTriageModel,
  inVersionsPage?: boolean,
) => {
  if (inVersionsPage && !model.is_published) {
    return null;
  }

  if (model.is_published) {
    return `This model is live and read-only. Click ‘${DUPLICATE_ACTION_TEXT}’ to modify.`;
  }
};

interface BannerProps {
  inVersionsPage?: boolean;
  model: VersionedTriageModel;
}

const ModelHeaderBanner = ({ inVersionsPage, model }: BannerProps) => {
  const { palette } = useTheme();
  const message = createMessage(model, inVersionsPage);

  if (!message) {
    return null;
  }
  return (
    <Box
      borderRadius={1}
      px={2}
      py={0.5}
      sx={{
        backgroundColor: 'rgba(242, 244, 247, 0.50)',
        border: `solid 1px ${palette.colors.grey[100]}`,
        borderRadius: '5px',
      }}
    >
      <Typography color={palette.colors.grey[700]} noWrap variant='font14'>
        {message}
      </Typography>
    </Box>
  );
};

export default ModelHeaderBanner;
