import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  Alert,
  Button,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { clearWorkflowError } from 'src/actions/workflow-builder/workflowBuilderActions';
import { selectDecommissionWorkflowError } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetIntentsQuery } from 'src/services/workflow-builder-metrics';
import { DecommissionWorkflowErrorReferencedData } from 'src/types/workflowBuilderAPITypes';
import { DecommissionWorkflowType, Routes } from 'src/utils/enums';

const DecommissionedWorkflowErrorModal: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useDispatch();
  const { data } = useGetIntentsQuery();
  const { intents = [] } = data ?? {};

  const decommissionedWorkflowError = useSelector(
    selectDecommissionWorkflowError,
  );

  const errorType = decommissionedWorkflowError?.type;
  const intentsList = decommissionedWorkflowError?.referenced_data;

  let actionText = '';
  switch (errorType) {
    case DecommissionWorkflowType.DEACTIVATE:
      actionText = 'deactivate';
      break;
    case DecommissionWorkflowType.DELETE:
      actionText = 'delete';
      break;
  }

  const generateWorkflowEditUrl = (workflowId: string) => {
    const queryParams = new URLSearchParams({ workflowId });

    return `${Routes.WORKFLOW_BUILDER_EDIT}?${queryParams}`;
  };

  return (
    <Alert title={`Unable to ${actionText} intent`} variant='warning'>
      <Body>
        <Box marginRight='44px' width='452px'>
          <Typography color={theme.palette.colors.grey[600]} variant='font14'>
            The intent is currently used in other workflows. To {actionText},
            you must first remove it from the following workflows:
          </Typography>
          <UnorderdList>
            {intentsList?.map(
              (data: DecommissionWorkflowErrorReferencedData, idx: number) => (
                <Typography
                  color={theme.palette.colors.grey[600]}
                  key={`intentName-${idx}`}
                  variant='font14'
                >
                  <li aria-label={`${data.name}`}>
                    <Link
                      href={generateWorkflowEditUrl(data.id)}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      {data.name ??
                        intents.find(
                          intent => intent.intent_workflow_id === data.id,
                        )?.intent_name}
                    </Link>
                  </li>
                </Typography>
              ),
            )}
          </UnorderdList>
        </Box>
        <GotItButton
          onClick={() => dispatch(clearWorkflowError())}
          variant='ghost'
        >
          Got It
        </GotItButton>
      </Body>
    </Alert>
  );
};

const Body = styled('div')`
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const UnorderdList = styled('ul')`
  margin: 16px 0 4px 22px;
  padding: 0;
  &:li {
    text-decoration: underline;
  }
`;

const GotItButton = styled(Button)`
  width: 78px !important;
`;

const Link = styled('a')`
  color: ${theme.palette.colors.grey[600]};
`;

export default DecommissionedWorkflowErrorModal;
