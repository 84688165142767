import { useLocation } from 'react-router';

import { getWorkflowValueFromSearch } from 'src/pages/workflow-builder-edit/autonomous-agent/helpers';
import { CommonIntentWorkflowType } from 'src/utils/enums';

const useGetIsAutoflowCapableWorkflow = (): boolean => {
  const { search } = useLocation();

  const intentId = getWorkflowValueFromSearch(search, 'intentId');

  const isKnowledgeRetrieval =
    intentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const isFallbackHandoffWorkflow =
    intentId === CommonIntentWorkflowType.FALLBACK;

  return !isKnowledgeRetrieval && !isFallbackHandoffWorkflow;
};

export default useGetIsAutoflowCapableWorkflow;
