import React from 'react';
const handleModalsVisibility = (
  componentMounted: boolean,
  event?: Event,
  ref?: React.RefObject<HTMLDivElement> | React.RefObject<HTMLButtonElement>,
  refContainer?: React.RefObject<HTMLDivElement>,
  setVisibility?: Function,
  valuesRef?: React.RefObject<HTMLDivElement>,
) => {
  if (componentMounted && event?.target instanceof Node) {
    if (
      refContainer?.current?.contains(event.target) ||
      valuesRef?.current?.contains(event.target)
    ) {
      setVisibility && setVisibility(true);
    } else if (!ref?.current?.contains(event.target)) {
      setVisibility && setVisibility(false);
    } else {
      return;
    }
  }
};

export default handleModalsVisibility;
