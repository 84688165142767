import React from 'react';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { ForethoughtEditStepMenu } from './edit-menu/ForethoughtEditStepMenu';
import { OptionsStep } from './workflow-builder/revamped';
import { ButtonsStepChatProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { ButtonsStepFields } from 'src/types/workflowBuilderAPITypes';

const ButtonsStep: React.FC<React.PropsWithChildren<ButtonsStepChatProps>> = ({
  setShouldShowEditMenu,
  shouldShowEditMenu,
  step,
  stepId,
}) => {
  // Process data
  const fields = step.step_fields as ButtonsStepFields;

  return (
    <>
      <OptionsStep
        fields={fields}
        stepId={stepId}
        transitions={step.transitions}
      />
      <ForethoughtEditStepMenu
        isVisible={shouldShowEditMenu}
        setIsVisible={setShouldShowEditMenu}
        stepFields={fields}
        stepId={stepId}
        stepType={step.step_type}
      />
    </>
  );
};

export const ButtonOptionComp = styled('button')<{
  highlight: boolean;
}>`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 10px 10px 10px 16px;
  border-radius: 21px;
  border: 1px solid
    ${props =>
      props.highlight
        ? theme.palette.colors.slate[800]
        : theme.palette.colors.slate[200]};
  background: ${props =>
    props.highlight
      ? theme.palette.colors.slate[100]
      : theme.palette.colors.white};
  margin-right: 8px;
  margin-top: 10px;
  cursor: pointer;
  transition: border-color 0.2s linear;

  &:hover:not(:disabled) {
    border: 1px solid
      ${props =>
        props.highlight
          ? theme.palette.colors.slate[800]
          : theme.palette.colors.slate[600]};
  }
`;

export default ButtonsStep;
