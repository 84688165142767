import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Chip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Card as BannerLayoutCard, CardMetric } from '../common/banner-layout';
import debounce from 'lodash/fp/debounce';
import costReductionIcon from 'src/assets/images/discover-cost-reduction-icon.svg';
import timeReductionIcon from 'src/assets/images/discover-time-reduction-icon.svg';
import volumeReductionIcon from 'src/assets/images/discover-volume-reduction-icon.svg';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  DiscoverAutomatedObject,
  DiscoverTopic,
} from 'src/reducers/discoverReducer/types';
import { getDiscoverBannerValues } from 'src/utils/discover/helpers';

interface RecommendationCardPostAutomateProps {
  automatedObject: DiscoverAutomatedObject;
  topic: DiscoverTopic;
  trackingEventMetadata: Record<string, string>;
}

const RecommendationCardPostAutomate = ({
  automatedObject,
  topic,
  trackingEventMetadata,
}: RecommendationCardPostAutomateProps) => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const relevantTrackingData = useMemo(
    () => ({
      ...trackingEventMetadata,
      bookmarked: topic.is_bookmarked,
      page: 'Topic view',
      'page-area': 'Recommendation',
      recommended: topic.is_recommended,
      'topic-name': topic.topic_name,
    }),
    [
      topic.is_bookmarked,
      topic.is_recommended,
      topic.topic_name,
      trackingEventMetadata,
    ],
  );
  const onMouseEnter = useMemo(() => {
    return debounce(DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT, () =>
      emitTrackingEventCallback(
        'discover-automation-banner-hovered',
        relevantTrackingData,
      ),
    );
  }, [emitTrackingEventCallback, relevantTrackingData]);

  const solveWorkflowActionableValue = topic.actionable_value?.solve_workflow;

  if (!solveWorkflowActionableValue) {
    return null;
  }

  const { costValue, fullResolutionTimeValue, volumeValue } =
    getDiscoverBannerValues(solveWorkflowActionableValue);

  const viewType = 'default';
  const isAutomatedObjectActive = automatedObject.status === 'active';

  return (
    <Box onMouseEnter={onMouseEnter}>
      <BannerLayoutCard direction={'column'} viewType={viewType}>
        <Box mb={3}>
          <Box alignItems='center' display='flex' mb={1}>
            <Box alignItems='center' display='flex' mr={0.5}>
              <Typography color={palette.colors.grey[500]} variant='font11'>
                Workflow
              </Typography>
            </Box>
            <Chip
              label={isAutomatedObjectActive ? 'Active' : 'Inactive'}
              startAdornment={
                <Box
                  bgcolor={
                    isAutomatedObjectActive
                      ? palette.colors.green[500]
                      : palette.colors.grey[400]
                  }
                  borderRadius={100}
                  height='12px'
                  width='12px'
                />
              }
              sx={{
                borderColor: palette.colors.slate[200],
                color: palette.colors.grey[700],
                fontSize: 12,
                fontWeight: 500,
                height: '26px',
              }}
              variant='outlined'
            />
          </Box>
          <Typography variant='font16Bold'>{automatedObject.name}</Typography>
        </Box>
        <Box mb='6px'>
          <Typography color={palette.colors.grey[800]} variant='font14'>
            Automation impact over the last 30 days.
          </Typography>
        </Box>
        <Box display='flex' flexDirection={'column'} mb='50px'>
          <CardMetric
            direction='column'
            icon={<img src={volumeReductionIcon} />}
            labelTitle={DISCOVER_CARD_TEXT.volumeTitle}
            value={volumeValue}
            viewType={viewType}
          />
          <CardMetric
            direction='column'
            icon={<img src={timeReductionIcon} />}
            labelTitle={DISCOVER_CARD_TEXT.resolutionTimeTitle}
            shouldShowBorderTop
            value={fullResolutionTimeValue}
            viewType={viewType}
          />
          <CardMetric
            direction='column'
            icon={<img src={costReductionIcon} />}
            labelTitle={DISCOVER_CARD_TEXT.costTitle}
            shouldShowBorderTop
            value={costValue}
            viewType={viewType}
          />
        </Box>
        <Box>
          {topic.automation_urls && (
            <StyledLink to={decodeURIComponent(topic.automation_urls[0])}>
              <Button
                fullWidth
                onClick={() =>
                  emitTrackingEventCallback(
                    'discover-view-workflow-button-clicked',
                    relevantTrackingData,
                  )
                }
                size='large'
                variant='ghost'
              >
                <Typography variant='font14Bold'>
                  {isAutomatedObjectActive
                    ? 'View workflow'
                    : 'Activate workflow'}
                </Typography>
              </Button>
            </StyledLink>
          )}
        </Box>
      </BannerLayoutCard>
    </Box>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default RecommendationCardPostAutomate;
