import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, SelectChangeEvent, useTheme } from '@mui/material';
import { IconTags } from '@tabler/icons-react';

import {
  ListOption,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import LabelItem from './LabelItem';
import {
  CurrentOrgHelpdeskDetail,
  PatchTriageModelRequest,
  TagDefinitionResponse,
  VersionedTriageModel,
} from 'src/reducers/triageSettingsReducer/types';
import { useGetTicketFieldsQuery } from 'src/services/triage/triageApi';

interface LabelListProps {
  currentPatchModel: PatchTriageModelRequest['body'];
  handleLabelMappingChange: (
    e: SelectChangeEvent<string>,
    label: TagDefinitionResponse,
  ) => void;
  helpdesk: CurrentOrgHelpdeskDetail | null;
  isLoadingLabels: boolean;
  modelLabels: TagDefinitionResponse[] | undefined;
  outputFieldLabelOptions?: ListOption[];
  targetedModel: VersionedTriageModel | undefined;
}

const NoLabelState = ({
  modelLabels,
}: {
  modelLabels: TagDefinitionResponse[] | undefined;
}) => {
  const { palette } = useTheme();
  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      pt={10}
    >
      <IconTags color='#D2D9E5' size={140} strokeWidth={1} />
      <Box columnGap={1} display='flex'>
        <Typography color={palette.colors.grey[500]} variant='font18Bold'>
          {modelLabels?.length === 0 &&
            "You haven't created any labels for this model yet"}
          {Boolean(modelLabels?.length) &&
            'No labels found with this search term'}
        </Typography>
      </Box>
    </Box>
  );
};

const LoadingState = () => {
  const { palette } = useTheme();

  return [1, 2, 3, 4].map(id => (
    <Box
      borderBottom={`1px solid ${palette.colors.slate[200]}`}
      display='flex'
      gap='30px'
      justifyContent='space-between'
      key={id}
      p='12px'
    >
      <Skeleton width='50%' />
      <Skeleton width='50%' />
    </Box>
  ));
};

const LabelList: React.FC<LabelListProps> = ({
  currentPatchModel,
  handleLabelMappingChange,
  helpdesk,
  isLoadingLabels,
  modelLabels,
  outputFieldLabelOptions,
  targetedModel,
}) => {
  const { palette } = useTheme();

  // External data
  const { isFetching: isFetchingFields, isLoading: isLoadingFields } =
    useGetTicketFieldsQuery(helpdesk?.name ?? '', { skip: !helpdesk });

  // Local state
  const [searchText, setSearchText] = React.useState('');
  const isLoadingField = isFetchingFields || isLoadingFields;
  const searchedLabels = modelLabels?.filter(
    label =>
      label.title.trim().toLowerCase().includes(searchText.toLowerCase()) ||
      label.description.toLowerCase().includes(searchText.toLowerCase()),
  );

  // Callbacks
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <Box pl='40px' width='260px'>
        <SearchBar
          onChange={handleSearchChange}
          placeholder='Search label'
          size='small'
          value={searchText}
        />
      </Box>
      <Box display='flex' flexDirection='column' pt='8px' px='40px'>
        <Box
          borderBottom={`1px solid ${palette.colors.slate[200]}`}
          display='flex'
          gap='70px'
          justifyContent='space-between'
          p='12px 130px 12px 0'
        >
          <Box flex={1}>
            <Typography color={palette.colors.grey[800]} variant='font16Bold'>
              Label
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography color={palette.colors.grey[800]} variant='font16Bold'>
              Mapping
            </Typography>
          </Box>
        </Box>
        {isLoadingLabels && <LoadingState />}
        {!isLoadingLabels && searchedLabels?.length === 0 && (
          <NoLabelState modelLabels={modelLabels} />
        )}
        {!isLoadingLabels &&
          searchedLabels?.map(label => (
            <LabelItem
              currentPatchModel={currentPatchModel}
              handleLabelMappingChange={handleLabelMappingChange}
              helpdesk={helpdesk}
              isLoadingField={isLoadingField}
              key={label.title}
              label={label}
              outputFieldLabelOptions={outputFieldLabelOptions}
              targetedModel={targetedModel}
            />
          ))}
      </Box>
    </>
  );
};

export default LabelList;
