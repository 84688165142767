import { ReportsState } from './types';
import {
  APPLY_BREAKDOWN_FIELD,
  DELETE_BREAKDOWN_FIELD,
  FilterActions,
  GET_ALL_REPORTS,
  GET_ALL_REPORTS_FAILURE,
  GET_REPORT,
  GET_REPORT_FAILURE,
  PageSettingsActions,
  ReportsActions,
  TOGGLE_BREAKDOWN_VALUE,
  TOGGLE_SELECT_ALL_BREAKDOWNS,
} from 'src/actions/actionTypes';
import type { RootState } from 'src/store/rootReducer';

interface FetchAction {
  //todo: define payload @gigi
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  type: string;
}

export const initialState: ReportsState = {
  error: '',
  isReportModified: false,
};

const getReportsDataInitialState = () => {
  return {
    reports: [],
    user_bookmarked_report_ids: [],
    user_owned_report_ids: [],
  };
};

export default function reportsReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === GET_ALL_REPORTS) {
    return { ...state, reportsData: action.payload };
  }

  if (action.type === GET_ALL_REPORTS_FAILURE) {
    return {
      ...state,
      error: action.error?.toString() || 'Error',
      reportsData: getReportsDataInitialState(),
    };
  }

  // Todo when do we actually use this?
  // We use it in get report. Use it when opening from URL
  if (action.type === GET_REPORT) {
    return { ...state, report: action.payload };
  }

  if (action.type === GET_REPORT_FAILURE) {
    return {
      ...state,
      error: action.error?.toString() || 'Error',
      report: {},
    };
  }

  // This is for isReportModified
  if (
    action.type === PageSettingsActions.SET_VIEW_MODE ||
    action.type === PageSettingsActions.SET_Y_AXIS ||
    action.type === FilterActions.SET_FILTER ||
    action.type === FilterActions.CLEAR_FILTERS ||
    action.type === FilterActions.DELETE_FILTER ||
    action.type === APPLY_BREAKDOWN_FIELD ||
    action.type === TOGGLE_BREAKDOWN_VALUE ||
    action.type === TOGGLE_SELECT_ALL_BREAKDOWNS ||
    action.type === DELETE_BREAKDOWN_FIELD
  ) {
    return {
      ...state,
      isReportModified: true,
    };
  }

  if (action.type === ReportsActions.SET_IS_REPORT_MODIFIED) {
    return { ...state, isReportModified: action.payload };
  }

  return state;
}

// SELECTORS
export const selectReportsData = (
  state: RootState,
): ReportsState['reportsData'] => state.reports.reportsData;

export const selectReport = (state: RootState): ReportsState['report'] =>
  state.reports.report;

export const selectIsReportModified = (
  state: RootState,
): ReportsState['isReportModified'] => state.reports.isReportModified;
