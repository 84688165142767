import { useCallback, useState } from 'react';

import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { interestedButtonClickedApi } from 'src/services/discover/api';

export const useInterestedButtonClickedMutation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>(null);

  const interestedButtonClicked = useCallback(
    async (body: Partial<DiscoverTopic>) => {
      setIsLoading(true);
      try {
        await interestedButtonClickedApi(body);
      } catch (error) {
        setError(error);
      }

      setIsLoading(false);
    },
    [],
  );

  return { error, interestedButtonClicked, isLoading };
};
