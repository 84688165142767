import React, { CSSProperties, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, useTheme } from '@mui/material';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';

const DEFAULT_COLUMN_WIDTH = 100;

interface RowProps {
  /**
   * Optionally determine whether row can be clicked
   * Defaults to true
   */
  isClickable?: boolean;
  /**
   * Optionally include tagText to display a tag in the top left corner of the row
   */
  tagText?: string | null;
}

interface CardTableProps<T> {
  /**
   * Column data
   */
  columns: {
    columnWidth?: number;
    id: string;
    label: string;
    labelTooltip?: string;
    renderCell: (row: T) => React.ReactNode;
  }[];
  /**
   * Height of each item must be set and must be the same for all items for virtualized list
   * Defaults to 90px
   */
  itemSize?: number;
  /**
   * Handle row click
   */
  onClickRow: (row: T) => void;
  /**
   * Row data
   */
  rows: (T & RowProps)[];
  /**
   * Space between items
   * Defaults to 16px
   */
  spacing?: number;
}

export default function CardTable<T = unknown>({
  columns,
  itemSize = 90,
  onClickRow,
  rows,
  spacing = 16,
}: CardTableProps<T>) {
  const [hasScrolled, setHasScrolled] = useState(false);
  const { palette } = useTheme();

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const row = rows[index];
    if (!row) {
      return <Box sx={{ ...style }} />;
    }
    const { isClickable = true, tagText } = row;
    const onClick = isClickable ? () => onClickRow(row) : undefined;

    return (
      <Box style={{ ...style, paddingBottom: spacing }}>
        <Box
          onClick={onClick}
          sx={{
            ...(isClickable
              ? {
                  '&:hover': {
                    border: `1px solid ${palette.colors.grey[800]}`,
                    boxShadow:
                      '0px 0px 1px rgba(0, 12, 32, 0.04), 0px 2px 6px rgba(3, 17, 38, 0.107135)',
                  },
                }
              : {}),
            alignItems: 'center',
            bgcolor: palette.colors.white,
            border: `1px solid ${palette.colors.slate[200]}`,
            borderRadius: '8px',
            cursor: isClickable ? 'pointer' : 'default',
            display: 'flex',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {tagText && (
            <Box
              sx={theme => ({
                alignItems: 'flex-end',
                bgcolor: theme.palette.colors.yellow[500],
                display: 'flex',
                height: 90,
                justifyContent: 'center',
                padding: '6px',
                position: 'absolute',
                top: 0,
                transform: 'rotate(-45deg) translateY(-64px)',
                width: 90,
              })}
            >
              <Typography variant='font11'>{tagText}</Typography>
            </Box>
          )}
          {columns.map(({ columnWidth, id, renderCell }) => (
            <Box
              flex={1}
              key={id}
              minWidth={columnWidth || DEFAULT_COLUMN_WIDTH}
              padding='24px'
            >
              {renderCell(row)}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: hasScrolled ? palette.colors.slate[300] : 'transparent',
          display: 'flex',
          transition: 'border-color 0.2s',
        }}
      >
        {columns.map(({ columnWidth, id, label, labelTooltip }) => (
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            gap='4px'
            key={id}
            minWidth={columnWidth || DEFAULT_COLUMN_WIDTH}
            padding='16px 24px'
          >
            <Typography variant='font14Bold'>{label}</Typography>
            {labelTooltip && (
              <Tooltip tooltipContent={labelTooltip}>
                <InfoIcon fontSize='small' />
              </Tooltip>
            )}
          </Box>
        ))}
      </Box>
      <Box flex='1'>
        <AutoSizer>
          {({ height = 0, width = 0 }) => (
            <List
              height={height}
              itemCount={rows.length}
              itemSize={itemSize + spacing}
              onScroll={({ scrollOffset }) => {
                setHasScrolled(scrollOffset > 0);
              }}
              overscanCount={10}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </Box>
    </>
  );
}
