import { EdgeProps, MarkerType, useReactFlow } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import { FLOW_EDGES } from '../components/flow-layout/EdgeTypes';
import { FLOW_NODES } from '../components/flow-layout/NodeTypes';

// this hook implements the logic for clicking the button on a workflow edge
// on edge click: create a dropzone node in between the two nodes that are connected by the edge
export default function useEdgeClick(id: EdgeProps['id']) {
  const { getEdge, getNode, setEdges, setNodes } = useReactFlow();

  const handleEdgeClick = () => {
    // retrieve the edge object to get the source and target id
    const edge = getEdge(id);
    if (!edge) {
      return;
    }

    // retrieve the target node to get its position
    const targetNode = getNode(edge.target);
    if (!targetNode) {
      return;
    }

    // create a unique id for newly added elements
    const insertNodeId = uuidv4();
    const { parentTransitionId } = targetNode.data;

    // this is the node object that will be added in between source and target node
    const insertNode = {
      data: {
        parentTransitionId,
      },
      id: insertNodeId,
      // we place the node at the current position of the target (prevents jumping)
      position: { x: targetNode.position.x, y: targetNode.position.y },
      type: FLOW_NODES.DROPZONE,
    };

    // new connection from source to new node
    const sourceEdge = {
      ...edge,
      data: {
        ...edge.data,
        hideAddButton: true,
      },
      id: `${edge.source}->${insertNodeId}`,
      markerEnd: { strokeWidth: 2, type: MarkerType.Arrow },
      source: edge.source,
      target: insertNodeId,
      type: FLOW_EDGES.WORKFLOW,
    };

    // new connection from new node to target
    const targetEdge = {
      data: {
        hideAddButton: true,
      },
      id: `${insertNodeId}->${edge.target}`,
      markerEnd: { strokeWidth: 2, type: MarkerType.Arrow },
      source: insertNodeId,
      target: edge.target,
      type: FLOW_EDGES.WORKFLOW,
    };

    // remove the edge that was clicked as we have a new connection with a node inbetween
    setEdges(edges =>
      edges.filter(e => e.id !== id).concat([sourceEdge, targetEdge]),
    );

    // insert the node between the source and target node in the react flow state
    setNodes(nodes => {
      const targetNodeIndex = nodes.findIndex(node => node.id === edge.target);
      return [
        ...nodes.slice(0, targetNodeIndex),
        insertNode,
        ...nodes.slice(targetNodeIndex, nodes.length),
      ];
    });
  };

  return handleEdgeClick;
}
