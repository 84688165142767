import { useMemo } from 'react';
import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

const USER = 'User: ';
const CHATBOT = 'Chatbot: ';

/**
 * - Trim whitespace
 * - Format [user]: and [Chatbot]: prefix
 */
function formatLine(line: string) {
  return line.trim().replace('[user]:', USER).replace('[Chatbot]:', CHATBOT);
}

export default function ConversationTranscript({
  transcript,
  variant = 'cell',
}: {
  transcript: string[];
  variant?: 'cell' | 'drawer';
}) {
  const { palette } = useTheme();

  const splitTranscript = useMemo(() => {
    return transcript
      .flatMap(line => line.split(/(?=\[user\]:)/))
      .filter(Boolean)
      .map(formatLine);
  }, [transcript]);

  const Container = variant === 'cell' ? CellContainer : DrawerContainer;

  return (
    <Container>
      {splitTranscript.map((line, index) => {
        const highlight = variant === 'drawer' && line.startsWith(USER);
        return (
          <div key={index} style={{ wordWrap: 'break-word' }}>
            <Typography
              color={
                highlight ? palette.colors.blue[600] : palette.colors.black
              }
              variant='font14'
            >
              {line}
            </Typography>
          </div>
        );
      })}
    </Container>
  );
}

export const CellContainer = styled(Box)`
  line-height: 1.6;
  /* ellipsis when overflowing: */
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DrawerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
