import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { Box, useTheme } from '@mui/material';

import {
  Badge,
  Pagination,
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EditTranslationCell from './EditTranslationCell';
import {
  HeaderCell,
  HeaderCellContainer,
  Row,
  RowCell,
  Table,
} from './EditTranslationPhrasesTab';
import { DebouncedGlobalSearchBar } from './EditTranslationPhrasesTabHelper';
import { getEmailTranslationCopiesData } from './helpers';
import { getResponseOptionsList } from './helpers';
import { getEmailTranslationFilterOptions } from './helpers';
import { formatContextVariableForDisplayInDiv } from './helpers';
import { setNetworkError } from 'src/actions/workflow-builder/workflowBuilderActions';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { formatContextVariableForPersistence } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import { EmailTranslation } from 'src/pages/workflow-builder-edit/types';
import {
  selectStepLevelTranslationsId,
  selectTranslationsTableMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  getEmailTranslationsLazyQueryState,
  useRestoreManualTranslationMutation,
} from 'src/services/solve-email-multilingual/solveEmailMultilingualApi';
import {
  selectEmailTranslations,
  setEmailTranslations,
} from 'src/slices/email-translations/emailTranslationsSlice';
import { TranslationsTableMode } from 'src/utils/enums';

export const ALL_INTENTS = 'All Intents';
export const ALL_RESPONSES = 'All Responses';

export type EmailTranslationFilterType = 'intent' | 'response';

export type AllOptions = typeof ALL_INTENTS | typeof ALL_RESPONSES;

interface EmailTranslationsTableProps {
  languageOptions: Array<{ label: string; value: string }>;
  onSelectLanguageChange: (language: string) => void;
  selectedLanguage: string;
}

const EmailTranslationsTable: React.FC<
  React.PropsWithChildren<EmailTranslationsTableProps>
> = ({
  languageOptions,
  onSelectLanguageChange,
  selectedLanguage,
}: EmailTranslationsTableProps) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const [restoreTranslation, { isLoading: isRestoringTranslation }] =
    useRestoreManualTranslationMutation();

  const tableMode = useSelector(selectTranslationsTableMode);
  const {
    data: sourceEmailTranslationData,
    isFetching: isFetchingEmailTranslations,
  } = getEmailTranslationsLazyQueryState({
    mode: tableMode,
    targetLanguage: selectedLanguage,
  });

  const { translations: sourceEmailTranslations = [] } =
    sourceEmailTranslationData || {};

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const stepLevelTranslationsId = useSelector(selectStepLevelTranslationsId);
  const emailTranslations = useSelector(selectEmailTranslations);
  const allowPageResetRef = useRef(true);

  const [intentFilter, setIntentFilter] = useState<string>(ALL_INTENTS);
  const [responseFilter, setResponseFilter] = useState<string>(ALL_RESPONSES);

  const responseOptionsList = useMemo(() => {
    return getResponseOptionsList(intentFilter, sourceEmailTranslations);
  }, [intentFilter, sourceEmailTranslations]);

  const intentOptionsList = useMemo(() => {
    return getEmailTranslationFilterOptions(
      ALL_INTENTS,
      'intent',
      sourceEmailTranslations,
    );
  }, [sourceEmailTranslations]);

  const setAllowPageResetRef = (flag: boolean) => {
    allowPageResetRef.current = flag;
  };

  const columns = useMemo<Column<EmailTranslation>[]>(
    () => [
      {
        accessor: 'intent_name',
        Cell: data => data.row.original.intent_name,
        Header: 'Intent',
      },
      {
        accessor: 'intent_email_configuration_name',
        Cell: data => data.row.original.intent_email_configuration_name,
        Header: 'Response',
      },
      {
        accessor: 'original_text',
        Cell: data =>
          formatContextVariableForDisplayInDiv(
            contextVariables,
            data.row.original.original_text,
          ),
        Header: () => (
          <Box alignItems='center' display='flex' gap='12px'>
            <span>Phrases</span>
            <Box bgcolor={palette.colors.slate[100]}>
              {tableMode === TranslationsTableMode.DRAFT && (
                <Badge label='Draft' variant='outlined' />
              )}
            </Box>
          </Box>
        ),
      },
      {
        accessor: 'current_translation',
        Header: 'Translation',
      },
    ],
    [contextVariables, tableMode, palette.colors.slate],
  );

  const tableData = useMemo(() => {
    const filteredData = emailTranslations.filter(data => {
      if (intentFilter === ALL_INTENTS) {
        return data.intent_name;
      }
      return data.intent_name === intentFilter;
    });

    return filteredData.filter(data => {
      if (responseFilter === ALL_RESPONSES) {
        return data.intent_email_configuration_name;
      }
      return data.intent_email_configuration_name === responseFilter;
    });
  }, [emailTranslations, intentFilter, responseFilter]);

  const sourceTextMap = useMemo(() => {
    const map: {
      [key: string]: {
        intentNames: Array<string>;
        translationIds: Array<string>;
      };
    } = {};

    tableData.forEach(translation => {
      if (!(translation.auto_translation in map)) {
        map[translation.auto_translation] = {
          intentNames: [],
          translationIds: [],
        };
      }
      map[translation.auto_translation].intentNames.push(
        translation.intent_name,
      );
      map[translation.auto_translation].translationIds.push(
        translation.email_translation_id,
      );
    });
    return map;
  }, [tableData]);

  const {
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    pageOptions,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable(
    {
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      autoResetPage: allowPageResetRef.current,
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: 40 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );

  const handleUpdateTranslation = (
    translationId: string,
    newTranslation: string,
  ) => {
    // we should disallow auto reset page index when updating a translation
    setAllowPageResetRef(false);

    const newTranslations = emailTranslations.map(translation => {
      if (translation.email_translation_id === translationId) {
        return {
          ...translation,
          current_translation: formatContextVariableForPersistence(
            contextVariables,
            newTranslation,
          ),
        };
      }
      return translation;
    });

    dispatch(setEmailTranslations(newTranslations));
  };

  const handleReplaceAll = (
    translationIdsToReplace: string[],
    newTargetText: string,
  ) => {
    // we should disallow auto reset page index when updating translations
    setAllowPageResetRef(false);

    const newTranslations = emailTranslations.map(translation => {
      if (translationIdsToReplace.includes(translation.email_translation_id)) {
        return {
          ...translation,
          current_translation: formatContextVariableForPersistence(
            contextVariables,
            newTargetText,
          ),
        };
      }
      return translation;
    });

    dispatch(setEmailTranslations(newTranslations));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Box
        sx={{
          '.MuiFormControl-root': {
            width: 'fit-content',
          },
          '.MuiInputBase-root': {
            height: '100%',
          },
          display: 'flex',
          gap: '8px',
          height: '32px',
        }}
      >
        <DebouncedGlobalSearchBar
          ariaLabel='search translations'
          initialValue={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        {!stepLevelTranslationsId && (
          <>
            <SelectDropdown
              id='filter-email-by-intent'
              onChange={e => {
                setIntentFilter(e.target.value);
                setResponseFilter(ALL_RESPONSES);
              }}
              options={intentOptionsList}
              value={intentFilter}
            />
            <SelectDropdown
              id='filter-email-by-response'
              onChange={e => setResponseFilter(e.target.value)}
              options={responseOptionsList}
              value={responseFilter}
            />
          </>
        )}
        <SelectDropdown
          id='select-email-language'
          onChange={e => {
            onSelectLanguageChange(e.target.value);
          }}
          options={languageOptions}
          value={selectedLanguage}
        />
      </Box>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => {
            const { key, ...restHeaderGroupProps } =
              headerGroup.getHeaderGroupProps();
            return (
              <Row key={key} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column, index) => {
                  const { key, ...restColumn } = column.getHeaderProps();
                  return (
                    <HeaderCell
                      key={key}
                      {...restColumn}
                      isFirstColumn={index === 0}
                    >
                      <HeaderCellContainer>
                        <Typography variant='font14Bold'>
                          {column.render('Header')}
                        </Typography>
                      </HeaderCellContainer>
                    </HeaderCell>
                  );
                })}
              </Row>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <Row key={key} {...restRowProps}>
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  const translation = cell.row.original;
                  const {
                    auto_translation: autoTranslation,
                    current_translation: currentTranslation,
                    email_translation_id: translationId,
                    intent_name: intentName,
                    manual_translation: manualTranslation,
                    modified_date: modifiedDate,
                  } = translation;

                  const { affectedIntents, translationIdOfCopies } =
                    getEmailTranslationCopiesData(
                      sourceTextMap,
                      translationId,
                      autoTranslation,
                      intentName,
                    );
                  const shouldOverrideTranslation =
                    !!manualTranslation &&
                    manualTranslation !== autoTranslation;

                  return cell.column.id === 'current_translation' ? (
                    <EditTranslationCell
                      allContextVariables={contextVariables}
                      cell={cell}
                      intentsToReplace={affectedIntents}
                      isRestoringTranslation={isRestoringTranslation}
                      isTranslationEmpty={currentTranslation.trim() === ''}
                      isTranslationsLoading={isFetchingEmailTranslations}
                      mentionsData={contextVariables.map(
                        ({ context_variable_id, context_variable_name }) => ({
                          display: context_variable_name,
                          id: context_variable_id,
                        }),
                      )}
                      onReplaceAll={() =>
                        handleReplaceAll(
                          translationIdOfCopies,
                          currentTranslation,
                        )
                      }
                      onRestoreTranslation={() =>
                        restoreTranslation({
                          manualTranslationId: translation.email_translation_id,
                          mode: tableMode,
                          modified_date: modifiedDate,
                          targetLanguage: selectedLanguage,
                        })
                          .unwrap()
                          .catch(e => {
                            const errorType = e?.data?.error_type ?? '';
                            dispatch(setNetworkError(errorType));
                          })
                      }
                      onUpdateTranslations={newTargetText =>
                        handleUpdateTranslation(translationId, newTargetText)
                      }
                      repeatedTranslationsLength={translationIdOfCopies.length}
                      selectedLanguage={selectedLanguage}
                      setAllowPageResetRef={setAllowPageResetRef}
                      shouldOverrideTranslation={shouldOverrideTranslation}
                      value={currentTranslation}
                    />
                  ) : (
                    <RowCell
                      key={key}
                      {...restCellProps}
                      isFirstColumn={cell.column.id === 'intent_name'}
                    >
                      {cell.render('Cell')}
                    </RowCell>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
      </Table>
      {pageCount > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '8px',
          }}
        >
          <Pagination
            count={pageOptions.length}
            onChange={(_, page) => gotoPage(page - 1)}
            page={state.pageIndex + 1}
          />
        </Box>
      )}
    </Box>
  );
};

export default EmailTranslationsTable;
