export const YOUR_PLAN = 'Your Plan';
export const CONTRACT = 'Contract';
export const CONTACT = 'Contact';
export const VIEW_PLANS = 'View Plans';
export const RENEWS_ON = 'Renews on';
export const TERM = 'Term';
export const VIEW_CONTRACT = 'View Contract';
export const BILL_TO = 'Bill to';
export const UPGRADE = 'Upgrade';
export const CONTACT_SALES = 'Contact Sales';
export const EFFECTIVE_AT = 'Effective at';
export const EMAIL = 'Email';
export const BILLING_ADDRESS = 'Billing Address';
export const CURRENT_PLAN = 'Current Plan';
export const USAGE = 'Usage';
export const EFFECTIVE_ON = 'Effective on';
export const CONTRACT_QUOTA = 'Contract Quota';
export const PERIOD = 'period';
export const DIALOG_TITLE = 'Let us know your interests';
export const DIALOG_SUBTITLE = 'Let us know your interests';
export const DEFLECTIONS = 'Deflections';
export const PREDICTIONS = 'Predictions';
export const PROJECTED = 'Projected';
export const BASIC = 'Basic';
export const PROFESSIONAL = 'Professional';
export const ENTERPRISE = 'Enterprise';
export const ADD_ONS = 'Add-ons';
export const ADD_ONS_DIALOG_LABEL =
  "We'd love to hear from you, and we'll be in touch shortly.";
export const ADD_ONS_DIALOG_TEXT =
  "I'm interested in learning more about the available add-on items.";
export const UPGRADE_PLAN_DIALOG_LABEL =
  'Your Account Manager will be in touch shortly.';
export const UPGRADE_PLAN_DIALOG_TEXT =
  "I'm interested in upgrading my current plan to explore additional features and benefits.";

export const SOLVE_SECTION_TITLE = `Cumulative deflections for Solve since the term's start, as well as future deflection projections. ${CONTRACT_QUOTA}:`;

export const ASSIST_SECTION_TITLE = 'The number of available seats.';

export const TRIAGE_SECTION_TITLE = `Cumulative predictions for Triage since the term's start, as well as future prediction projections. ${CONTRACT_QUOTA}:`;

export const AGENT_SEATS = 'agent seats';

export const PLATFORM_FEE = 'platform fee';

export const tabIndexToLabel: Record<string, string> = {
  assist: ASSIST_SECTION_TITLE,
  solve: SOLVE_SECTION_TITLE,
  triage: TRIAGE_SECTION_TITLE,
};

export const currentPlanToPreviousPlan: Record<string, string> = {
  Enterprise: PROFESSIONAL,
  Professional: BASIC,
};

export const UPGRADE_PLAN_DIALOG_DATA = {
  label: UPGRADE_PLAN_DIALOG_LABEL,
  text: UPGRADE_PLAN_DIALOG_TEXT,
};

export const ADD_ON_DIALOG_DATA = {
  label: ADD_ONS_DIALOG_LABEL,
  text: ADD_ONS_DIALOG_TEXT,
};
