import 'emoji-mart/css/emoji-mart.css';

import { useRef, useState } from 'react';
import { BaseEmoji, Picker } from 'emoji-mart';
import { Popover } from '@mui/material';
import {
  CommandButton,
  CommandButtonGroup,
  useCommands,
} from '@remirror/react';
import { IconMoodSmile } from '@tabler/icons-react';

import { buttonStyles } from './helpers/buttonStyles';

export const EmojiButton = ({
  disabled,
}: {
  disabled: boolean;
}): JSX.Element => {
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const commands = useCommands();
  const emojiButtonRef = useRef<SVGSVGElement>(null);

  return (
    <CommandButtonGroup>
      <CommandButton
        active={isEmojiPickerVisible}
        commandName='suggestEmoji'
        data-appcues-target='CanvasAddEmojiButton'
        disabled={disabled}
        enabled
        icon={<IconMoodSmile ref={emojiButtonRef} size='18px' />}
        label='Add Emoji'
        onClick={() => {
          setIsEmojiPickerVisible(true);
        }}
        onSelect={() => {
          // `onSelect()` is required but not used.
        }}
        sx={buttonStyles}
      />
      <Popover
        anchorEl={emojiButtonRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        onClose={() => setIsEmojiPickerVisible(false)}
        open={isEmojiPickerVisible}
        sx={{
          left: -5,
          top: -15,
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <Picker
          onSelect={(emoji: BaseEmoji) => {
            commands.insertText(emoji.native);
            setIsEmojiPickerVisible(false);
            commands.focus();
          }}
          showPreview={false}
          showSkinTones={false}
          style={{ border: 'none' }}
        />
      </Popover>
    </CommandButtonGroup>
  );
};
