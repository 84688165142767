import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';

const DeploymentId: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <Field name='agent_chat_handoff_settings.deployment_id'>
        {({ field, form: { setFieldValue }, meta }: FieldProps) => (
          <>
            <ContextMention
              errorMessage={meta.error}
              hasError={Boolean(meta.touched && meta.error)}
              label='Deployment ID'
              onChange={value => {
                setFieldValue(field.name, value);
              }}
              required
              value={field.value}
            />
          </>
        )}
      </Field>
    </>
  );
};

export default DeploymentId;
