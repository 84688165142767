import { Box, Theme } from '@mui/material';

import DonutGraph from './DonutGraph';
import { DONUT_SIZE } from 'src/components/dashboard-pages/solve-insights/constants';
import { numbersToStringWithCommas } from 'src/components/dashboard-pages/solve-insights/helpers';
import GraphCenterElement from 'src/components/dashboard-pages/solve-insights/overview/GraphCenterElement';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import {
  CommonCsatResponse,
  DonutGraphResponse,
} from 'src/services/insights/types';

export const DonutGraphWrapper = ({
  data,
  theme,
}: {
  data: DonutGraphResponse | CommonCsatResponse;
  theme: Theme;
}) => {
  const { donut, misc, type } = data;
  const { palette } = theme;
  let colors;
  switch (type) {
    case 'insights_feedback_articles':
      colors = [
        palette.colors.green[500],
        palette.colors.red[500],
        palette.colors.slate[100],
      ];
      return (
        <DonutGraph
          colors={colors}
          containerProps={{
            style: { height: `${DONUT_SIZE}px`, width: `${DONUT_SIZE}px` },
          }}
          data={donut}
          disableTooltip
        />
      );
    case 'insights_csat_topic':
      colors = [
        palette.colors.purple[600],
        palette.colors.purple[500],
        palette.colors.purple[400],
        palette.colors.purple[300],
        palette.colors.purple[200],
      ];
      return (
        <DonutGraph
          colors={colors}
          containerProps={{
            style: { height: `${DONUT_SIZE}px`, width: `${DONUT_SIZE}px` },
          }}
          data={donut}
          disableTooltip
        />
      );
    case 'insights_csat_common':
      colors = [
        palette.colors.purple[600],
        palette.colors.purple[500],
        palette.colors.purple[400],
        palette.colors.purple[300],
        palette.colors.purple[200],
      ];
      return (
        <DonutGraph
          centerElement={
            <GraphCenterElement theme={theme} value={misc?.delta_value} />
          }
          centerSubText={misc?.sub_title}
          centerText={numbersToStringWithCommas({
            canBeFloat: true,
            number: misc?.value,
            style: 'decimal',
          })}
          colors={colors}
          containerProps={{
            style: { height: `${DONUT_SIZE}px`, width: `${DONUT_SIZE}px` },
          }}
          data={donut}
          disableTooltip
        />
      );
    default:
      return <Box>{NA}</Box>;
  }
};
