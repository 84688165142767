import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Drawer,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetBuilderQueryParams } from '../hooks';
import { cleanURL } from './utils';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import {
  selectCanvasWorkflowBuilder,
  selectChatOrder,
  selectChatOrderLastStepId,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectIsSqueezingStep } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { addEmbedStep } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useAppDispatch } from 'src/store/hooks';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  StepTypes,
} from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
}

export const EmbedVideo: React.FC<React.PropsWithChildren<Props>> = ({
  hasWorkflowConflict,
  onDiscard,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const chatOrder = useSelector(selectChatOrder);
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const isSqueezingStep = useSelector(selectIsSqueezingStep);
  const { workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const isEntryStep =
    Object.keys(canvasData.steps).length === 0 || !chatOrder.length;
  const [url, setURL] = useState('');
  const [cleanedURL, setcleanedURL] = useState('');
  const [testURL, setTestURL] = useState('');

  useEffect(() => {
    setcleanedURL(cleanURL(url));
  }, [url]);

  function saveEmbedStep() {
    if (!selectedWorkflowId || !cleanedURL) {
      return;
    }

    // save step
    dispatch(
      addEmbedStep({
        is_entry_step: shouldSqueezeIntoEntry || isEntryStep,
        parent_step_id: isSqueezingStep ? squeezeStepParentId : lastStepId,
        step_fields: { embed_url: cleanedURL },
        step_type: StepTypes.EMBED,
      }),
    );
    dispatchTrackingAction(
      FlamethrowerTrackingEventTypes.WORKFLOW_ADDED_EMBED_STEP,
      {
        workflow_id: selectedWorkflowId,
      },
    );
    onDiscard();
  }

  return (
    <Drawer
      footerContent={
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Button onClick={onDiscard} variant='secondary'>
            Cancel
          </Button>
          <Box sx={{ display: 'flex', flex: '1' }}>
            <Button
              disabled={!cleanedURL || hasWorkflowConflict}
              fullWidth
              onClick={saveEmbedStep}
              variant='main'
            >
              Save
            </Button>
          </Box>
        </Box>
      }
      isOpen={true}
      onClose={onDiscard}
    >
      <Box sx={{ padding: '40px' }}>
        <Typography variant='font24'>Add video embed url</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '30px',
          }}
        >
          {/* Input */}
          <TextField
            description='Must be a valid URL'
            label='Embed URL'
            onChange={e => {
              setURL(e.target.value);
            }}
            placeholder='e.g. https://www.youtube.com/embed/abc123'
            type='url'
            value={url}
          />
          {/* Embed Test */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Button
              disabled={!cleanedURL}
              onClick={() => setTestURL(cleanedURL)}
              variant='secondary'
            >
              Test
            </Button>
            {testURL && (
              <Button onClick={() => setTestURL('')} variant='danger'>
                Clear Test
              </Button>
            )}
          </Box>
          {testURL && (
            <Box
              sx={{
                aspectRatio: '16 / 9',
                width: '100%',
              }}
            >
              <Box
                allowFullScreen
                component='iframe'
                name='Forethought Embed'
                referrerPolicy='no-referrer'
                src={testURL}
                sx={{
                  border: `1px solid ${theme.palette.colors.slate[200]}`,
                  borderRadius: '8px',
                  height: '100%',
                  width: '100%',
                }}
                title='Forethought Embed'
              />
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};
