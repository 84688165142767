import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box } from '@mui/material';

import {
  convertIntentFiltersToDict,
  getInteractiveEmailPreviewIframeUrl,
  useGetIntentFiltersMode,
} from '../utils';
import Spinner from 'src/components/spinner';
import { useGetPreviewTokenQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import {
  selectEmailPreviewToken,
  selectPreviewConversationId,
  selectPreviewIntentFilters,
  selectPreviewLanguage,
  selectPreviewWorkflowCvs,
  selectPreviewWorkflowTag,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { isForethoughtWidgetLoadedEvent } from 'src/types/solveWidgetEventTypes';

export const InteractiveEmailWidgetPreview = () => {
  const location = useLocation();
  const { data: previewTokenData } = useGetPreviewTokenQuery();
  const { preview_api_token: previewToken = '' } = previewTokenData ?? {};
  const selectedLanguage = useSelector(selectPreviewLanguage);
  const selectedTag = useSelector(selectPreviewWorkflowTag);
  const workflowCvs = useSelector(selectPreviewWorkflowCvs);
  const currentIntentFilters = useSelector(selectPreviewIntentFilters);
  const intentFiltersMode = useGetIntentFiltersMode();
  const token = useSelector(selectEmailPreviewToken);
  const emailConversationId = useSelector(selectPreviewConversationId);

  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);

  // iframe src URL with all the configs passed in as attributes
  const iframeURL = getInteractiveEmailPreviewIframeUrl({
    conversationId: emailConversationId || '',
    initialIntentId: previewFromIntentId || '',
    language: selectedLanguage,
    previewToken,
    tag: selectedTag,
    token: token,
    workflowCvs,
  });

  const postIntentFiltersToIFrame = useCallback(() => {
    const previewIframe = document.getElementById(
      'preview-iframe',
    ) as HTMLIFrameElement;

    const intentFilterDict = convertIntentFiltersToDict(currentIntentFilters);

    previewIframe?.contentWindow?.postMessage(
      {
        event: 'updateIntentFiltersForPreview',
        intentFiltersForPreview: intentFilterDict,
        isLiveMode:
          intentFiltersMode === 'live-traffic' ||
          intentFiltersMode === 'current-intent-and-live-traffic',
      },
      '*',
    );
  }, [currentIntentFilters, intentFiltersMode]);

  useEffect(() => {
    postIntentFiltersToIFrame();
  }, [postIntentFiltersToIFrame]);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (isForethoughtWidgetLoadedEvent(event)) {
        setIsWidgetLoaded(true);
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  if (!token) {
    return null;
  }

  return (
    <Box
      display='flex'
      flex={1}
      height='100%'
      id='iframe-container'
      justifyContent='center'
      maxHeight='100%'
      maxWidth='100%'
      sx={{
        '#preview-iframe': {
          border: 'none',
          height: '100%',
          maxWidth: '570px',
          width: isWidgetLoaded ? '100%' : '0px',
        },
      }}
      width='100%'
    >
      {!isWidgetLoaded && (
        <Box
          alignItems='center'
          display='flex'
          height='100%'
          justifyContent='center'
          width='100%'
        >
          <Spinner />
        </Box>
      )}
      <iframe
        id='preview-iframe'
        key={iframeURL}
        onLoad={() => {
          // slight delay to ensure solve-ui is ready to receive the filters
          setTimeout(postIntentFiltersToIFrame, 100);
        }}
        src={iframeURL}
      />
    </Box>
  );
};
