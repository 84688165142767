import React, { FC } from 'react';
import { styled } from '@mui/material';

import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from 'src/pages/workflow-builder-edit/hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { FieldAndValue } from 'src/types/workflowBuilderAPITypes';

interface CVTextFieldProps {
  contextVariables: Array<ContextVariable>;
  fieldName: string;
  fieldValue: string;
  idValueArr: Array<FieldAndValue>;
  idx: number;
  isCustomField?: boolean;
  setFieldValue: (name: string, value: Array<FieldAndValue>) => void;
}

const CVTextField: FC<React.PropsWithChildren<CVTextFieldProps>> = ({
  fieldName,
  fieldValue,
  idValueArr,
  idx,
  isCustomField,
  setFieldValue,
}) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();

  return (
    <Container>
      <ContextVariableAutocomplete
        aria-label='Field Value'
        onChange={fieldValue => {
          const updatedFieldValues = idValueArr
            .slice(0, idx)
            .concat({
              id: idValueArr[idx].id,
              is_custom: isCustomField,
              value: fieldValue,
            })
            .concat(idValueArr.slice(idx + 1));

          setFieldValue(fieldName, updatedFieldValues);
        }}
        placeholder='Field Value'
        undefinedContextVariables={undefinedContextVariableIds}
        value={fieldValue}
      />
    </Container>
  );
};

export default CVTextField;

const Container = styled('div')`
  flex: 1 1 50%;
`;
