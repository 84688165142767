export const operators = [
  'is',
  'is not',
  'contains',
  'does not contain',
  'is empty',
  'is not empty',
];

export const checkboxTypeValueOptions = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
];

export const checkboxTypeOperators = ['is', 'is empty', 'is not empty'];

export const numberTypeOperators = [
  'is',
  'is not',
  'is empty',
  'is not empty',
  'greater than',
  'less than',
];

export const listTypeOperators = ['is', 'is not', 'is empty', 'is not empty'];

export const multiSelectListTypeOperators = [
  'contains one of',
  'does not contain one of',
];

export const multiOptionsTypeOperators = [
  'contains one of',
  'does not contain one of',
];

export const FILLED_CONDITION_ERR_MSG =
  'Condition with subsequent steps cannot be deleted';
