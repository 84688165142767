import moment from 'moment';

import { BranchingStepMetricsResponse } from 'src/types/workflowBuilderAPITypes';
import {
  ActionBuilderActionTypes,
  WorkflowMetricsTimePeriods,
} from 'src/utils/enums';

export const timePeriods = ['7D', '30D', 'All time'];

export const featureEnabledDateString = '2022-10-11 00:00 +0000';

export const mapTimePeriodsToAPIParam = (timePeriod: string) => {
  switch (timePeriod) {
    case '7D':
      return WorkflowMetricsTimePeriods.SEVEN_DAYS;
    case '30D':
      return WorkflowMetricsTimePeriods.THIRTY_DAYS;
    case 'All time':
      return WorkflowMetricsTimePeriods.ALL_TIME;
    default:
      return WorkflowMetricsTimePeriods.THIRTY_DAYS;
  }
};

export const getFormattedDate = (stepLastModifiedAt: string) => {
  const endDate = moment(new Date(moment.now()));
  const startDate = new Date(stepLastModifiedAt);

  return endDate.diff(startDate, 'days');
};

export const isCurrentDate = (stepLastModifiedAt: string) => {
  const endDate = moment(new Date(moment.now()));
  const startDate = new Date(stepLastModifiedAt);
  return moment(startDate).isSame(endDate, 'day');
};

export const stepModifiedAfterFeatureEnabled = (stepLastModifiedAt: string) => {
  return moment(stepLastModifiedAt).isAfter(featureEnabledDateString);
};

export const mapButtonValuesToNames = (
  branchingStepMetrics: BranchingStepMetricsResponse,
  actionType?: string,
) => {
  const buttonValuesToNames: Map<string, string> = new Map();
  if (actionType === ActionBuilderActionTypes.ARTICLE_SUGGESTION) {
    buttonValuesToNames.set('yes_resolve', 'Yes, thank you!');
    buttonValuesToNames.set('no_resolve', 'No, I need more help.');
  } else {
    branchingStepMetrics.buttons.forEach(button => {
      buttonValuesToNames.set(button.button_value, button.button_display_name);
    });
  }

  return buttonValuesToNames;
};
