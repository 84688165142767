import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { BUTTON_SIZE, COLUMN_SIZE } from '../constants';
import { AutomationTypeHeaderTitle } from '../types';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';

interface DiscoverAutomationCardsHeaders {
  hasScrolled: boolean;
  title: AutomationTypeHeaderTitle;
}

const DiscoverAutomationCardsHeader = ({
  hasScrolled,
  title,
}: DiscoverAutomationCardsHeaders) => {
  const { palette } = useTheme();
  const isArticle = title === 'Article';
  const isWorkflow = title === 'Workflow';

  return (
    <Box
      sx={[
        {
          backgroundColor: palette.colors.white,
          color: palette.colors.grey[800],
          columnGap: 1,
          display: isArticle ? 'grid' : 'flex',
          gridTemplateColumns: `auto repeat(${isWorkflow ? '4' : '3'}, 200px)`,
          p: 3,
          pr: 4,
          zIndex: 1,
        },
        hasScrolled && {
          borderBottom: '1px solid' + palette.colors.slate[300],
        },
      ]}
    >
      <Box alignItems='center' display='flex' flex={1} minWidth={COLUMN_SIZE}>
        <Typography variant='font14Bold'>{title}</Typography>
      </Box>
      {isArticle && (
        <>
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            minWidth={COLUMN_SIZE}
          >
            <Typography variant='font14Bold'>Related topics</Typography>
          </Box>
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            minWidth={COLUMN_SIZE}
          >
            <Typography variant='font14Bold'>Related articles</Typography>
          </Box>
        </>
      )}
      <Box
        alignItems='flex-start'
        display='flex'
        flexDirection='column'
        width={COLUMN_SIZE}
      >
        <Typography variant='font14Bold'>
          {isArticle
            ? DISCOVER_CARD_TEXT.ticketsCoveredByArticle
            : DISCOVER_CARD_TEXT.volumeTitle}

          <Box component='span' sx={{ color: palette.grey[500] }}>
            {isWorkflow ? '/last 30 days' : '/year'}
          </Box>
        </Typography>
      </Box>

      {!isArticle && (
        <Box
          alignItems='flex-start'
          display='flex'
          flexDirection='column'
          width={isWorkflow ? COLUMN_SIZE : COLUMN_SIZE + BUTTON_SIZE}
        >
          <Typography variant='font14Bold'>
            {DISCOVER_CARD_TEXT.costTitle}
            <Box component='span' sx={{ color: palette.grey[500] }}>
              {isWorkflow ? '/last 30 days' : '/year'}
            </Box>
          </Typography>
        </Box>
      )}
      {isWorkflow && (
        <Box
          alignItems='flex-start'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          width={COLUMN_SIZE + BUTTON_SIZE}
        >
          <Typography variant='font14Bold'>Status</Typography>
        </Box>
      )}
    </Box>
  );
};

export default DiscoverAutomationCardsHeader;
