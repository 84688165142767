import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { v4 as uuid } from 'uuid';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

interface PhoneNumberInputProps {
  'aria-label': string;
  /** Color for autofill action */
  autofillColor: string;
  /** Background Color */
  backgroundColor: string;
  /** Border color  */
  borderColor: string;
  /** Boolean to determine if value is valid phone number */
  isPhoneNumberValid: boolean;
  /** Function to handle onChange action */
  onChange: (value: string) => void;
  /** Placeholder text */
  placeholder?: string;
  /** Color for the placeholder text */
  placeholderColor: string;
  /** Color for the text */
  textColor: string;
  /** Value for validation message text */
  validationMessageText: string;
  /** Value for the input */
  value: string;
}

const PhoneNumberInput: React.FC<
  React.PropsWithChildren<PhoneNumberInputProps>
> = ({
  'aria-label': ariaLabel,
  backgroundColor,
  borderColor,
  isPhoneNumberValid,
  onChange,
  placeholder,
  textColor,
  validationMessageText,
  value,
}) => {
  const theme = useTheme();
  const validationMessageId = uuid();

  const isValidationMessageVisible = Boolean(!isPhoneNumberValid && value);

  const focusedBorderColor = isValidationMessageVisible
    ? theme.palette.error.main
    : theme.palette.colors.grey[800];

  return (
    <>
      <PhoneInput
        containerStyle={{
          backgroundColor: backgroundColor,
          borderColor: focusedBorderColor,
        }}
        country='us'
        disableDropdown={true}
        disableSearchIcon={true}
        enableSearch={false}
        inputProps={{
          'aria-errormessage': validationMessageId,
          'aria-invalid': isValidationMessageVisible,
          'aria-label': ariaLabel,
        }}
        inputStyle={{
          border: `1px solid ${borderColor}`,
          borderColor: borderColor,
          borderRadius: '4px',
          color: textColor,
          fontSize: '16px',
          height: '40px',
          padding: '10px 12px',
          width: '100%',
        }}
        onChange={(value: string) => onChange(value)}
        placeholder={placeholder}
        showDropdown={false}
        specialLabel=''
        value={value}
      />
      {isValidationMessageVisible && (
        <ValidationMessage
          borderColor={theme.palette.colors.white}
          id={validationMessageId}
          textColor={theme.palette.error.main}
          themeColor={theme.palette.colors.grey[800]}
        >
          {validationMessageText}
        </ValidationMessage>
      )}
    </>
  );
};

export default PhoneNumberInput;

const ValidationMessage = styled('p')<{
  borderColor: string;
  textColor: string;
  themeColor: string;
}>`
  border: 1px solid ${props => props.borderColor};
  font-size: 12px;
  color: ${props => props.textColor};
  margin: 0 0 5px 0;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    border: 2px solid ${props => props.themeColor};
  }
`;
