import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCurrentUser,
  selectSession,
} from '../../../slices/session/sessionSlice';
import Spinner from '../../spinner';
import OrgSettings from './OrgSettings';
import UserSettings from './UserSettings';
import SettingsPageLayout from 'src/components/settings-page-layout';

export default function AccountSettingsPage() {
  const dispatch = useDispatch();
  const { currentUser, organization } = useSelector(selectSession);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  return (
    <SettingsPageLayout>
      {currentUser.data && organization.data ? (
        <>
          <UserSettings
            isLoading={currentUser.loading}
            user={currentUser.data}
          />
          <OrgSettings
            currentUser={currentUser.data}
            isLoading={organization.loading}
            organization={organization.data}
          />
        </>
      ) : (
        <Spinner />
      )}
    </SettingsPageLayout>
  );
}
