import { Typography } from '@forethought-technologies/forethought-elements';
import { numbersToStringWithCommas } from '../../solve-insights/helpers';
import ValueCell from './ValueCell';
import {
  DiscoverTopicMetric,
  TopicMetricTypeDetails,
} from 'src/reducers/discoverReducer/types';
import { getHoursFromSeconds } from 'src/utils/discover/helpers';

type NumberValueProps = {
  isNormalFont: boolean;
  metric: DiscoverTopicMetric;
  metricType?: TopicMetricTypeDetails;
};
const NumberValue = (props: NumberValueProps) => {
  const variant = props.isNormalFont ? 'font16' : 'font16Medium';
  if (!props.metricType) {
    return <Typography variant={variant}>N/A</Typography>;
  }

  if (props.metric.name === 'sentiment') {
    return null;
  }

  const numVal = Number(props.metric.value || 0);

  if (props.metricType.data_type === 'seconds') {
    return (
      <Typography variant={variant}>{getHoursFromSeconds(numVal)}</Typography>
    );
  }

  if (props.metric.name === 'volume') {
    return (
      <ValueCell
        isNormalFont={props.isNormalFont}
        noColor
        subValue={`${props.metric.percent_of_total}%`}
        value={numbersToStringWithCommas({
          number: numVal,
        })}
      />
    );
  }

  if (props.metric.name === 'first_contact_resolution') {
    return <Typography variant={variant}>{numVal}%</Typography>;
  }

  return <Typography variant={variant}>{numVal}</Typography>;
};

export default NumberValue;
