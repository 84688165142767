import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';

type ValueCellProps = {
  isInvertedColor?: boolean;
  isNormalFont: boolean;
  noColor?: boolean;
  subValue?: number | string;
  value: number | string;
};

const parseValue = (value: number | string) => {
  if (!value) {
    return 0;
  }
  if (typeof value === 'number') {
    return value;
  }
  const removePercent = value.replace('%', '');
  return Number(removePercent);
};

const applyColor = (value: number, color: string, removeColor?: boolean) => {
  if (removeColor || !value) {
    return undefined;
  }
  return color;
};

const getTargetColor = ({
  isInvertedColor,
  negativeColor,
  numberValue,
  positiveColor,
}: {
  isInvertedColor?: boolean;
  negativeColor: string;
  numberValue: number;
  positiveColor: string;
}) => {
  if (isInvertedColor) {
    return numberValue > 0 ? negativeColor : positiveColor;
  }
  return numberValue > 0 ? positiveColor : negativeColor;
};

const ValueCell = ({
  isInvertedColor,
  isNormalFont,
  noColor,
  subValue,
  value,
}: ValueCellProps) => {
  const { palette } = useTheme();
  const numberValue = parseValue(value);
  const targetColor = getTargetColor({
    isInvertedColor,
    negativeColor: palette.colors.green[500],
    numberValue,
    positiveColor: palette.colors.red[500],
  });
  const removeColor = typeof value === 'number';
  const color = !noColor
    ? applyColor(numberValue, targetColor, removeColor)
    : undefined;
  if (value === null || value === undefined) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font14'>
        {NA}
      </Typography>
    );
  }
  const variant = isNormalFont ? 'font16' : 'font16Medium';

  return (
    <Box alignItems='center' columnGap={1} display='flex'>
      <Typography color={color} variant={variant}>
        {value}
      </Typography>
      {Boolean(subValue) && (
        <Typography color={palette.colors.grey[600]} variant='font14'>
          ({subValue})
        </Typography>
      )}
    </Box>
  );
};

export default ValueCell;
