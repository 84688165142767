import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import emptyImageIcon from 'src/assets/images/empty-image-icon.svg';
import { EmailComponentAlignment } from 'src/components/email-builder/types';

interface IntentEmailImageBaseComponentProps {
  addUploadMessage?: boolean;
  alignment: EmailComponentAlignment;
  backgroundColor: string;
  imgSrc: string;
  width: number;
}

const IntentEmailImageBaseComponent = ({
  alignment,
  backgroundColor,
  imgSrc,
  width,
}: IntentEmailImageBaseComponentProps) => {
  const { palette } = useTheme();
  return (
    <Container alignment={alignment} backgroundColor={backgroundColor}>
      {imgSrc ? (
        <Image src={imgSrc} width={width} />
      ) : (
        <NoImageContainer width={width}>
          <PlaceholderImage src={emptyImageIcon} />
          <Box marginTop='25px'>
            <Typography color={palette.colors.grey[700]} variant='font18'>
              Add image
            </Typography>
          </Box>
        </NoImageContainer>
      )}
    </Container>
  );
};

export default IntentEmailImageBaseComponent;

const Image = styled('img')<{ width: number }>`
  width: ${props => props.width}%;
  max-height: '270px';
`;

const Container = styled('div')<{ alignment: string; backgroundColor: string }>`
  background: ${props => props.backgroundColor};
  display: flex;
  justify-content: ${props => props.alignment};
  padding: 14px 16px;
  width: 100%;
`;

export const NoImageContainer = styled('div')<{ width: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.palette.colors.purple[100]};
  align-items: center;
  width: ${props => props.width}%;
  min-width: 135px;
  aspect-ratio: 3 / 1;
  padding: 16px;
  border-radius: 4px;
`;

const PlaceholderImage = styled('img')`
  width: 98px;
`;
