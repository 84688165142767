import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Button } from '@forethought-technologies/forethought-elements';
import BaseModal from 'src/components/base-modal';
import { DynamicListOption } from 'src/types/actionBuilderApiTypes';
import { flattenCvs } from 'src/utils/actionBuilder/helpers';

interface DynamicListOptionsViewerProps {
  displayName?: string;
  isOpen: boolean;
  onClose: () => void;
  options: DynamicListOption[];
}

export const DynamicListOptionsViewer = ({
  displayName = '',
  isOpen,
  onClose,
  options,
}: DynamicListOptionsViewerProps) => {
  const dynamicListCvOptions = useMemo(() => {
    return flattenCvs([
      {
        configuration_fields: {
          dynamic_list_config: {
            context_variables: options,
            json_schema: {},
          },
        },
        context_variable_id: '',
        context_variable_name: displayName,
        context_variable_type: 'DYNAMIC_LIST',
        is_universal_context_variable: false,
      },
    ]);
  }, [options, displayName]);

  return (
    <BaseModal
      headerTitle={<Typography variant='font16Bold'>List items</Typography>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box height='450px' overflow='auto' pb={2}>
        <Box
          alignItems='center'
          borderTop={theme => `1px solid ${theme.palette.colors.slate[200]}`}
          display='flex'
          mx={3}
          p={2}
        >
          <Typography
            color={theme => theme.palette.colors.grey[800]}
            variant='font14Medium'
          >
            Value
          </Typography>
        </Box>
        {dynamicListCvOptions.map((cv, index) => (
          <Box
            alignItems='center'
            borderBottom={theme =>
              index === dynamicListCvOptions.length - 1
                ? `1px solid ${theme.palette.colors.slate[200]}`
                : undefined
            }
            borderTop={theme => `1px solid ${theme.palette.colors.slate[200]}`}
            display='flex'
            key={cv.context_variable_name}
            mx={3}
            p={2}
          >
            <Typography
              color={theme => theme.palette.colors.black}
              variant='font16'
            >
              ${cv.context_variable_name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display='flex' justifyContent='flex-end' p={3}>
        <Button onClick={onClose} variant='main'>
          Done
        </Button>
      </Box>
    </BaseModal>
  );
};
