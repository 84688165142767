import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useSendSolveEmailTrackigEvents } from 'src/hooks/hooks';
import { GeneratedEmailResponse } from 'src/pages/workflow-builder-edit/types';
import { selectIntentEmailConfiguration } from 'src/slices/email-builder/emailBuilderSlice';

export const useSendUserViewedGeneratedResponsesTrackingEvent = (
  generatedResponses: Array<GeneratedEmailResponse>,
) => {
  const emailConfiguration = useSelector(selectIntentEmailConfiguration);
  const dispatchTrackingEvent = useSendSolveEmailTrackigEvents('solve-email');
  useEffect(() => {
    if (generatedResponses.length) {
      dispatchTrackingEvent('user-observed-generated-responses', {
        email_response_id: emailConfiguration?.configuration_id || '',
        intent_definition_id: emailConfiguration?.intent_definition_id || '',
      });
    }
  }, [
    generatedResponses.length,
    emailConfiguration?.configuration_id,
    emailConfiguration?.intent_definition_id,
    dispatchTrackingEvent,
  ]);
};
