import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
import { selectAutomations } from '../../../slices/assist-automations/assistAutomationsSlice';
import { fetchAssistAutomations } from '../../../slices/assist-automations/thunks';
import AssistAutomationsEdit from 'src/components/assist-automations-edit';
import AssistAutomationsHome from 'src/components/assist-automations-home';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { Routes } from 'src/utils/enums';

const AssistAutomationsPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { availableAutomations, isAvailableAutomationsLoading } =
    useSelector(selectAutomations);
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const {
    is_prefilled_automation_template_enabled:
      isPreFilledTemplateEnabled = false,
  } = orgConfigData ?? {};

  const query = new URLSearchParams(search);
  const automationId = query.get('automation');

  useEffect(() => {
    dispatch(fetchAssistAutomations(isPreFilledTemplateEnabled));
  }, [dispatch, automationId, isPreFilledTemplateEnabled]);

  if (!availableAutomations) {
    return null;
  }

  return (
    <Page id={Routes.ASSIST_AUTOMATIONS}>
      {automationId && (
        <AssistAutomationsEdit
          automationId={automationId}
          automations={availableAutomations}
        />
      )}
      {!automationId && (
        <AssistAutomationsHome
          automations={availableAutomations}
          isLoading={isAvailableAutomationsLoading}
        />
      )}
    </Page>
  );
};

const Page = styled('div')`
  background-color: ${theme.palette.colors.white};
  width: 100%;
`;

export default AssistAutomationsPage;
