import React from 'react';

import { ForethoughtEditStepMenu } from './edit-menu/ForethoughtEditStepMenu';
import { CsatTriggerPointStep as RevampedCsatTriggerPointStep } from './workflow-builder/revamped';
import { CsatTriggerPointStepProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';

const CsatTriggerPointStep: React.FC<
  React.PropsWithChildren<CsatTriggerPointStepProps>
> = ({ setShouldShowEditMenu, shouldShowEditMenu, step, stepId }) => {
  return (
    <>
      <RevampedCsatTriggerPointStep stepId={stepId} />
      <ForethoughtEditStepMenu
        isVisible={shouldShowEditMenu}
        setIsVisible={setShouldShowEditMenu}
        stepId={stepId}
        stepType={step.step_type}
      />
    </>
  );
};

export default CsatTriggerPointStep;
