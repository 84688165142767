import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import {
  Colors,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { LoadingSkeleton } from '../../../components/reusable-components/loading-skeleton';
import ContextMention from '../context-mention-input';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import {
  Label,
  Row,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import type { RootState } from 'src/store/rootReducer';
import { textMediumStyle } from 'src/styles/styledMixin';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { HyperlinkRedirectCustomizationSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface HyperlinkRedirectCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const HyperlinkRedirectCustomizationPanel: FC<
  React.PropsWithChildren<HyperlinkRedirectCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  // @ts-expect-error legacy code with untyped state
  const hyperlinkCustomizationSettings: HyperlinkRedirectCustomizationSettings =
    useSelector(
      (state: RootState) =>
        state.canvasWorkflowBuilder?.actionSettings.configuration_fields,
    );
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const actionSettings = canvasState?.actionSettings;
  const addingAction = useSelector(selectAddingAction);
  const initialCustomizationData = addingAction
    ? {
        destination_link: '',
        redirect_option: 'new_tab',
      }
    : hyperlinkCustomizationSettings;
  const [customizationData, setCustomizationData] =
    useState<HyperlinkRedirectCustomizationSettings>(initialCustomizationData);
  const isPristine = isEqual(customizationData, initialCustomizationData);
  const isFormDisabled = () => {
    if (destinationLinkErrorMessage || (!addingAction && isPristine)) {
      return true;
    }

    return false;
  };
  const [isDestinationLinkTouched, setIsDestinationLinkTouched] =
    useState(false);
  const destinationLinkErrorMessage =
    isDestinationLinkTouched && !customizationData.destination_link
      ? 'Field cannot be empty'
      : '';

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (hyperlinkCustomizationSettings) {
      setCustomizationData({ ...hyperlinkCustomizationSettings });
    }
  }, [hyperlinkCustomizationSettings]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <ContentWrapper useFullHeight={true}>
        <Title>Hyperlink Redirect</Title>
        <Spacer height='20px' />
        <Section>
          <ContextMention
            errorMessage={destinationLinkErrorMessage}
            label='Destination Link'
            onBlur={() => {
              setIsDestinationLinkTouched(true);
            }}
            onChange={value => {
              setIsDestinationLinkTouched(true);
              setCustomizationData(data => {
                return {
                  ...data,
                  destination_link: value,
                };
              });
            }}
            placeholder='https://example.com'
            required
            tooltip='Link that the user should be redirected to.'
            value={customizationData.destination_link}
          />
          <Spacer height='20px' />
          <Row>
            <Label>Redirect Option</Label>
            <Tooltip tooltipContent={'How should user be redirected.'}>
              <Icon src={tooltipInfoIcon} />
            </Tooltip>
            <Spacer width='4px' />
          </Row>
          <DropdownContainer>
            <SelectDropdown
              id='select-dropdown'
              onChange={({ target }) => {
                setCustomizationData({
                  ...customizationData,
                  redirect_option: target.value,
                });
              }}
              options={[
                {
                  label: 'Current Tab',
                  value: 'current_tab',
                },
                {
                  label: 'New Tab',
                  value: 'new_tab',
                },
              ]}
              placeholder='Please select an option'
              value={customizationData.redirect_option}
            />
          </DropdownContainer>
        </Section>
      </ContentWrapper>
      <SaveContainer hasTopBorder>
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isFormDisabled() || hasWorkflowConflict}
          onClick={() => {
            setIsDestinationLinkTouched(true);

            if (!customizationData.destination_link) {
              return;
            }

            dispatch(
              addingAction
                ? addAction({
                    ...addingAction,
                    data: {
                      ...addingAction.data,
                      configuration_fields: customizationData,
                    },
                  })
                : updateActionSettings({
                    action_type: actionSettings.action_type as string,
                    configuration_fields: customizationData,
                  }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.HYPERLINK_REDIRECT,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </SaveContainer>
    </>
  );
};

export default HyperlinkRedirectCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ContentWrapper = styled('div')<{ useFullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 0 50px 0;
`;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const Icon = styled('img')`
  margin-bottom: 8px;
  margin-left: 4px;
`;

const DropdownContainer = styled('div')`
  display: flex;
  align-self: center;
  width: 100%;
  background-color: ${Colors.ui.backgrounds.default};
`;
