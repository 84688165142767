import React, { FC, useRef } from 'react';
import { Field, FieldProps } from 'formik';
import { styled } from '@mui/material';

import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { ErrorMessage } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { SalesforceHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface CustomMessageProps {
  customizationData: SalesforceHandoffCustomization;
}

const CustomMessage: FC<React.PropsWithChildren<CustomMessageProps>> = ({
  customizationData,
}) => {
  const editorRef = useRef<EditorRef>(null);

  const initialValue =
    customizationData?.ticket_creation_settings?.custom_message || '';

  return (
    <>
      <Field
        name='ticket_creation_settings.custom_message'
        validate={(value: string) => {
          if (value === '') {
            return 'Custom message cannot be empty';
          }
        }}
      >
        {({
          field,
          form: { setFieldTouched, setFieldValue },
          meta,
        }: FieldProps) => (
          <>
            <RichTextEditorContainer
              isError={Boolean(meta.touched && meta.error)}
            >
              <RichTextEditor
                editorRef={editorRef}
                initialContent={initialValue}
                onBlur={() => setFieldTouched(field.name, true)}
                onChange={html => {
                  setFieldValue(field.name, html);
                }}
                placeholder='Add custom message'
              />
            </RichTextEditorContainer>
            {meta.touched && meta.error && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </Field>
    </>
  );
};

export default CustomMessage;

const RichTextEditorContainer = styled('div')<{ isError: boolean }>`
  background: ${props => props.theme.palette.background.default};
  border: 1px solid
    ${props =>
      props.isError
        ? props.theme.palette.error.main
        : props.theme.palette.colors.grey[200]};
  border-radius: 6px;
`;
