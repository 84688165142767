import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import DiscoverNewTopicBadge from 'src/components/discover-new-topic-badge';
import { useGetLabelFromValue } from 'src/hooks/discover/useGetLabelFromValue';
import { useGetMetadata } from 'src/hooks/discover/useGetMetadata';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import {
  DiscoverDashboardTopMoversForMetric,
  TopicMetricTypeDetails,
} from 'src/reducers/discoverReducer/types';
import {
  deriveTopicName,
  getDisplayableDataByMetricType,
  getDisplayableValueChanged,
  getNumberIndicator,
  getPercentChangeColor,
  isSentimentValue,
  overrideDiscoverSearchParams,
  replaceIdInRoute,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

interface TopMoversByMetricTypeProps {
  onMetricClick: (metricName: string) => void;
  timeFilter: TopicTimeFilter;
  timePeriod: TopicPeriodicalFilter;
  topMoversByMetricType: DiscoverDashboardTopMoversForMetric[];
}

const TopMoversByMetricType = ({
  onMetricClick,
  timeFilter,
  timePeriod,
  topMoversByMetricType,
}: TopMoversByMetricTypeProps) => {
  const { palette } = useTheme();

  const { data: metadata } = useGetMetadata();
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };

  return (
    <>
      {topMoversByMetricType.map((topMoverMetric, index) => {
        if (topMoverMetric.metric_name === 'sentiment') {
          return null;
        }

        return (
          <Box
            key={topMoverMetric.metric_name}
            mt={3}
            sx={[
              index !== 0 && {
                borderTop: '1px solid' + palette.colors.slate[200],
                mt: 2,
                pt: 1,
              },
            ]}
          >
            <TopMoverByMetricType
              availableMetrics={availableMetrics}
              onMetricClick={onMetricClick}
              timeFilter={timeFilter}
              timePeriod={timePeriod}
              topMoverByMetricType={topMoverMetric}
            />
          </Box>
        );
      })}
    </>
  );
};

interface TopMoverByMetricTypeProps {
  availableMetrics: never[] | TopicMetricTypeDetails[];
  onMetricClick: (metricName: string) => void;
  timeFilter: TopicTimeFilter;
  timePeriod: TopicPeriodicalFilter;
  topMoverByMetricType: DiscoverDashboardTopMoversForMetric;
}

const TopMoverByMetricType = ({
  availableMetrics,
  onMetricClick,
  timeFilter,
  timePeriod,
  topMoverByMetricType,
}: TopMoverByMetricTypeProps) => {
  const { metric_name: metricName, topics } = topMoverByMetricType;

  const { palette } = useTheme();
  const metricTitle = useGetLabelFromValue({
    type: 'metric',
    value: metricName,
  });
  const pathWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  return (
    <>
      <Box
        mb={1}
        onClick={() => onMetricClick(metricName)}
        sx={{
          '&>span': {
            '&:hover': {
              textDecoration: 'underline',
            },
            cursor: 'pointer',
          },
        }}
      >
        <Typography color={palette.colors.purple[500]} variant='font14Bold'>
          {metricTitle}
        </Typography>
      </Box>

      <Box>
        {topics
          .slice(0, 2)
          .map(
            ({
              current_metric: currentMetric,
              is_new_topic: isNewTopic,
              previous_metric: previousMetric,
              topic_display_name: topicDisplayName,
              topic_id: topicId,
              topic_name: topicName,
            }) => {
              const dataType =
                availableMetrics.find(item => item.type === currentMetric.name)
                  ?.data_type ?? 'unknown';

              const name = deriveTopicName(topicDisplayName, topicName);

              if (
                isSentimentValue(currentMetric.value) ||
                isSentimentValue(previousMetric.value)
              ) {
                // sentiment not yet supported in dashboard
                return null;
              }

              return (
                <Box
                  component={Link}
                  key={topicId}
                  onClick={() => {
                    emitTrackingEventCallback('discover-clicked-on-top-mover', {
                      'metric-selected': metricName,
                      page: 'Discover Dashboard',
                      'page-area': 'Top movers',
                      'time-comparison-selected': timePeriod,
                      'time-period-selected': timeFilter.key,
                      topic: name,
                    });
                  }}
                  rel='noreferrer'
                  sx={{
                    '&:hover > div:first-child': {
                      textDecoration: 'underline',
                    },
                    alignItems: 'center',
                    color: palette.colors.black,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: 0.5,
                    textDecoration: 'none',
                  }}
                  target='_blank'
                  to={overrideDiscoverSearchParams(
                    replaceIdInRoute(pathWithSearch, topicId),
                    {
                      metric: metricName,
                      name,
                      source: 'dashboard',
                    },
                  )}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      minWidth: '0%',
                    }}
                  >
                    <Typography noWrap variant='font14'>
                      {deriveTopicName(topicDisplayName, topicName)}
                    </Typography>
                    <DiscoverNewTopicBadge isNewTopic={isNewTopic} />
                  </Box>
                  <Box
                    display='grid'
                    gridTemplateColumns='1fr 1fr 1fr'
                    ml={2}
                    width='220px'
                  >
                    <Box minWidth='73.33px'>
                      <Typography
                        color={palette.colors.black}
                        noWrap
                        variant='font14'
                      >
                        {getDisplayableDataByMetricType({
                          dataType,
                          metric: metricName,
                          value: currentMetric.value,
                        })}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        minWidth: '73.33px',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        color={palette.colors.grey[500]}
                        noWrap
                        variant='font14'
                      >
                        (
                        {getDisplayableDataByMetricType({
                          dataType,
                          metric: metricName,
                          value: previousMetric.value,
                        })}
                        )
                      </Typography>
                    </Box>
                    <Box sx={{ minWidth: '73.33px', whiteSpace: 'nowrap' }}>
                      <Typography
                        color={getPercentChangeColor(
                          currentMetric.value_changed ?? 0,
                          metricName,
                        )}
                        noWrap
                        variant='font14Bold'
                      >
                        {getNumberIndicator(currentMetric.value_changed ?? 0)}
                        {getDisplayableValueChanged(
                          currentMetric.value_changed,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            },
          )}
      </Box>
    </>
  );
};

export default TopMoversByMetricType;
