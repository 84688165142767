import { Button, Dialog } from '@forethought-technologies/forethought-elements';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useDeleteVersionModelMutation } from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

interface DeleteVersionDialogProps {
  isOpen: boolean;
  model: VersionedTriageModel;
  onClose: () => void;
  onSuccess: (versionId: string) => void;
}

const DeleteVersionDialog = ({
  isOpen,
  model,
  onClose,
  onSuccess,
}: DeleteVersionDialogProps) => {
  const [mutate, { isLoading }] = useDeleteVersionModelMutation();
  const emitTrackingEventCallback = useSelfServeEvents({
    model,
  });

  const handleDelete = () => {
    mutate({
      modelId: model.model_id,
      versionId: model.version_id,
    })
      .unwrap()
      .then(() => {
        emitTrackingEventCallback(
          TRIAGE_LLM_TRACKING_EVENTS.DELETE_VERSION_SUCCESS,
        );
        onSuccess(model.version_id);
        onClose();
      });
  };

  return (
    <Dialog
      footer={
        <>
          <Button disabled={isLoading} onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleDelete}
            variant='danger'
          >
            Delete
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title={`Delete this ${model.version_name || 'version'}?`}
      variant='sm'
    >
      This action will be deleted permanently and will not be retrievable.
    </Dialog>
  );
};

export default DeleteVersionDialog;
