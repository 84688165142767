import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

import {
  IconButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Message } from './Message';

export const MessageComponent = ({ messages }: { messages: string[] }) => {
  const [messageIndex, setMessageIndex] = useState(0);

  const moreThanOneError = messages.length > 1;

  if (messages.length) {
    return (
      <Box alignItems='center' display='flex' gap='20px'>
        <Message message={messages[messageIndex]} />
        {moreThanOneError && (
          <Pagination
            messageIndex={messageIndex}
            messagesLength={messages.length}
            setMessageIndex={setMessageIndex}
          />
        )}
      </Box>
    );
  }

  return null;
};

const Pagination = ({
  messageIndex,
  messagesLength,
  setMessageIndex,
}: {
  messageIndex: number;
  messagesLength: number;
  setMessageIndex: (value: number) => void;
}) => {
  const { palette } = useTheme();
  const currentErrorNumber = messageIndex + 1;
  const isLeftDisabled = messageIndex <= 0;
  const isRightDisabled = messageIndex + 1 >= messagesLength;

  return (
    <Box alignItems='center' display='flex' gap='4px'>
      <IconButton
        aria-label='See previous error'
        disabled={isLeftDisabled}
        onClick={() => setMessageIndex(messageIndex - 1)}
        variant='ghost'
      >
        <IconChevronLeft
          color={
            isLeftDisabled ? palette.colors.grey[400] : palette.colors.grey[700]
          }
        />
      </IconButton>
      <Typography
        color={palette.colors.slate[700]}
        variant='font12Medium'
      >{`${currentErrorNumber} of ${messagesLength}`}</Typography>
      <IconButton
        aria-label='See next error'
        disabled={isRightDisabled}
        onClick={() => setMessageIndex(messageIndex + 1)}
        variant='ghost'
      >
        <IconChevronRight
          color={
            isRightDisabled
              ? palette.colors.grey[400]
              : palette.colors.grey[700]
          }
        />
      </IconButton>
    </Box>
  );
};
