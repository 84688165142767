import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EmptyState from './EmptyState';
import {
  featureEnabledDateString,
  getFormattedDate,
  isCurrentDate,
  mapTimePeriodsToAPIParam,
  stepModifiedAfterFeatureEnabled,
  timePeriods,
} from './helpers';
import SelectButton from './SelectButton';
import StepMetrics from './StepMetrics';
import Spinner from 'src/components/spinner';
import { selectIntentWorkflowId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { getStepMetricsAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import { selectCurrentWorkflowVersion } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { StepMetricsResponse } from 'src/types/workflowBuilderAPITypes';

interface StepMetricsWindowProps {
  isLoadingMetricsWindow: boolean;
  left: number;
  setIsEditMenuVisible: (bool: boolean) => void;
  setIsLoadingMetricsWindow: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMetricsWindowVisible: (isVisible: boolean) => void;
  stepId: string;
  top: number;
}

const StepMetricsWindow: React.FC<
  React.PropsWithChildren<StepMetricsWindowProps>
> = ({
  isLoadingMetricsWindow,
  left,
  setIsEditMenuVisible,
  setIsLoadingMetricsWindow,
  setIsMetricsWindowVisible,
  stepId,
  top,
}) => {
  // Selector
  const workflowId = useSelector(selectIntentWorkflowId);
  const activeVersion = useSelector(selectCurrentWorkflowVersion);

  const containerRef = useRef<HTMLDivElement>(null);

  // states
  const [selectedRangeIndex, setSelectedRangeIndex] = useState(1);
  const [stepMetrics, setStepMetrics] = useState<StepMetricsResponse>({
    csat: {
      average_score: 0.0,
    },
    deflection_rate: {
      count: 0,
      total_count: 0,
    },
    dropoff: {
      count: 0,
      total_count: 0,
    },
    step_is_modified: false,
    step_last_modified_at: '',
    traffic: {
      count: 0,
      total_count: 0,
    },
  });
  const [currentPosition, setCurrentPosition] = useState<{
    bottom?: number;
    left?: number;
    top?: number;
  }>({
    left,
    top,
  });
  const [isLoading, setIsLoading] = useState(true);

  // calculate strings to display
  const stepLastModifiedAt = stepMetrics.step_last_modified_at;
  const wasModifiedToday = isCurrentDate(stepLastModifiedAt);
  const formattedLastModifiedDate = getFormattedDate(stepLastModifiedAt);
  const formattedFeatureEnabledDate = getFormattedDate(
    featureEnabledDateString,
  );

  const modifiedTodayLabel = 'Data available since step last updated today';
  const lastModifiedLabel = `Data available since step last updated ${formattedLastModifiedDate} days ago`;
  const featureEnabledLabel = `Data available since ${formattedFeatureEnabledDate} days ago`;

  let timeFrameLabel = null;
  if (!stepLastModifiedAt) {
    timeFrameLabel = 'Last updated at ...';
  } else if (wasModifiedToday) {
    timeFrameLabel = modifiedTodayLabel;
  } else if (stepModifiedAfterFeatureEnabled(stepLastModifiedAt)) {
    timeFrameLabel = lastModifiedLabel;
  } else {
    timeFrameLabel = featureEnabledLabel;
  }

  const setContainerCurrentTopPosition = useCallback(() => {
    const screenHeight = window.innerHeight;
    const containerHeight = containerRef.current?.offsetHeight ?? 0;
    const containerTopPosition = currentPosition.top ?? 0;
    const sum = containerHeight + containerTopPosition;

    if (sum > screenHeight) {
      setCurrentPosition({ ...currentPosition, bottom: 10, top: undefined });
    }
  }, [currentPosition]);

  const handleGetMetrics = useCallback(
    (timePeriod: string) => {
      setIsLoading(true);
      const metricsPromise = getStepMetricsAPI(
        workflowId || '',
        activeVersion,
        stepId,
        mapTimePeriodsToAPIParam(timePeriod),
      );
      metricsPromise
        .then(data => setStepMetrics(data))
        .catch(() =>
          console.error(
            `failed to fetch step metrics for time period ${timePeriod}`,
          ),
        )
        .finally(() => {
          setIsLoading(false);
          setIsLoadingMetricsWindow(false);
        });
    },
    [activeVersion, stepId, workflowId, setIsLoadingMetricsWindow],
  );

  useEffect(() => {
    handleGetMetrics('30D');
  }, [handleGetMetrics]);

  useEffect(() => {
    if (!isLoadingMetricsWindow) {
      setContainerCurrentTopPosition();
    }
  }, [isLoadingMetricsWindow, setContainerCurrentTopPosition]);

  return (
    <Backdrop
      data-testid='backdrop'
      onClick={() => {
        setIsMetricsWindowVisible(false);
        setIsEditMenuVisible(false);
      }}
    >
      {!isLoadingMetricsWindow && (
        <MetricsWrapper
          bottom={currentPosition.bottom}
          left={currentPosition.left}
          onClick={e => {
            e.stopPropagation();
          }}
          ref={containerRef}
          top={currentPosition.top}
        >
          <Container>
            <TimePeriodSelector>
              {timePeriods.map((timePeriod, index) => (
                <SelectButton
                  index={index}
                  key={index}
                  onClick={() => {
                    if (selectedRangeIndex === index) {
                      return;
                    }
                    setSelectedRangeIndex(index);
                    handleGetMetrics(timePeriod);
                  }}
                  selectedIndex={selectedRangeIndex}
                  text={timePeriod}
                />
              ))}
            </TimePeriodSelector>
            {isLoading ? (
              <LoadingContainer>
                <Spinner height='190px' />
              </LoadingContainer>
            ) : stepMetrics.step_is_modified ? (
              <EmptyState />
            ) : (
              <>
                <StepMetricsContainer>
                  <StepMetrics
                    csat={stepMetrics.csat}
                    deflection_rate={stepMetrics.deflection_rate}
                    dropoff={stepMetrics.dropoff}
                    traffic={stepMetrics.traffic}
                  />
                </StepMetricsContainer>
                <Separator />
                <Footer>
                  <Typography
                    color={theme.palette.colors.grey[600]}
                    variant='font12'
                  >
                    {timeFrameLabel}
                  </Typography>
                </Footer>
              </>
            )}
          </Container>
        </MetricsWrapper>
      )}
    </Backdrop>
  );
};

const Backdrop = styled('div')`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 199;
  top: 0px;
  left: 0px;
`;

const MetricsWrapper = styled('div')<{
  bottom?: number;
  left?: number;
  top?: number;
}>`
  position: absolute;
  z-index: 200;
  ${props => props.left !== undefined && `left:${props.left}px;`}
  ${props => props.top !== undefined && `top:${props.top}px;`}
  ${props => props.bottom !== undefined && `bottom:${props.bottom}px;`}
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.colors.white};
  width: fit-content;
  border: 1px solid ${theme.palette.colors.slate[300]};
  box-shadow: 0px 0px 1px rgba(0, 12, 32, 0.02),
    2px 8px 16px rgba(3, 17, 38, 0.107135);
  border-radius: 8px;
`;

const TimePeriodSelector = styled('div')`
  display: flex;
  padding: 16px;
`;

const StepMetricsContainer = styled('div')`
  padding: 0px 16px 16px 16px;
`;

const Separator = styled('div')`
  width: 340px;
  border-top: 1px solid ${theme.palette.colors.slate[200]};
`;

const Footer = styled('div')`
  display: flex;
  padding: 16px;
`;

export const LoadingContainer = styled('div')`
  width: 340px;
`;

export default StepMetricsWindow;
