import { useState } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { IconPencil, IconTrash } from '@tabler/icons-react';

import {
  IconButton,
  Toggle,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import useHelpdeskTicketFields from '../../hooks/useHelpdeskTicketFields';
import DraggableAutomationListItem from '../draggable-automation-list-item/DraggableAutomationListItem';
import EmailAutomationPage from '../email-automation-page/EmailAutomationPage';
import {
  generateWordingByOperator,
  generateWordingForAutomationAction,
} from './utils';
import draggableIconSrc from 'src/assets/images/email-builder-draggable.svg';
import ConfirmModal from 'src/components/email-builder/components/ConfirmModal';
import { EmailAutomation } from 'src/pages/workflow-builder-edit/types';
import { ConditionExpressions } from 'src/services/apiInterfaces';
import {
  useActivateEmailAutomationMutation,
  useDeactivateEmailAutomationMutation,
  useDeleteEmailAutomationMutation,
} from 'src/services/email-automation/emailAutomationApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface AutomationListItemProps {
  /**Email automation to render */
  emailAutomation: EmailAutomation;
  /**Automation index */
  index: number;
  /**Whether component is draggable */
  isDraggable?: boolean;
  /** Function when drag starts */
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  /** Function to call when drag ends */
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
}

const AutomationListItem = ({
  emailAutomation,
  index,
  isDraggable = false,
  onDrag,
  onDrop,
}: AutomationListItemProps) => {
  const { mappedFields, staticFields, textTypeCustomFields } =
    useHelpdeskTicketFields();

  const ticketFields = [...staticFields, ...textTypeCustomFields];

  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const [isAutomationPageVisible, setIsAutomationPageVisible] = useState(false);
  const [isConfirmationDialogVisible, setIsConfirmationDialogIsVisible] =
    useState(false);
  const [
    isActivationConfirmationDialogVisible,
    setIsActivationConfirmationDialogIsVisible,
  ] = useState(false);

  const [deleteEmailAutomation] = useDeleteEmailAutomationMutation();
  const [activateAutomation] = useActivateEmailAutomationMutation();
  const [deactivateAutomation] = useDeactivateEmailAutomationMutation();

  const closeDialog = () => {
    setIsActivationConfirmationDialogIsVisible(false);
    setIsConfirmationDialogIsVisible(false);
  };

  const {
    automation_actions,
    case_insensitive,
    email_automation_id,
    is_active,
    query_expression,
    run_other_automations,
  } = emailAutomation;

  const expressions =
    query_expression.expression_type === 'filter'
      ? [query_expression]
      : query_expression.expressions;

  const displayListOfValues = (values: ConditionExpressions['values']) => {
    return (
      <Box>
        Values:
        <Ul>
          {values?.map(value => (
            <li key={value.toString()}>{value}</li>
          ))}
        </Ul>
      </Box>
    );
  };

  const cardItem = (
    <Box
      alignItems='center'
      bgcolor={palette.colors.white}
      border={`1px solid ${palette.colors.grey[100]}`}
      borderRadius='8px'
      display='flex'
      gap='12px'
      justifyContent='space-between'
      padding='16px'
      width='100%'
    >
      {isDraggable && (
        <Box alignItems='center' display='flex' gap='12px'>
          <Box alignItems='center' display='flex' justifyItems='center'>
            <DraggableIcon src={draggableIconSrc} />
          </Box>
        </Box>
      )}
      <Box
        alignItems='center'
        display='flex'
        flexGrow={1}
        flexWrap='wrap'
        gap='3px'
      >
        <Typography variant='font16'>If</Typography>

        {expressions?.map((expression, index) => {
          const expressionValues = expression.values || [];
          const shouldDisplayTooltip =
            expression.operator === 'contains_one_of' &&
            expressionValues.length > 1;
          const expressionsLength = expressions.length;
          const shouldAddWhiteSpace = index === expressionsLength - 1;
          const shouldAddBooleanOperator = index === expressionsLength - 2;
          const endOfExpression = shouldAddWhiteSpace ? (
            ' '
          ) : shouldAddBooleanOperator ? (
            <Typography color={palette.colors.black} variant='font16Bold'>
              {` ${query_expression.operator?.toUpperCase()} `}
            </Typography>
          ) : (
            ', '
          );

          return (
            <Typography
              color={palette.colors.purple[500]}
              key={index}
              variant='font16Bold'
            >
              <Tooltip
                tooltipContent={
                  shouldDisplayTooltip
                    ? displayListOfValues(expressionValues)
                    : ''
                }
              >
                <span>
                  {generateWordingByOperator(expression, ticketFields, [
                    ...mappedFields,
                  ])}
                </span>
              </Tooltip>
              {endOfExpression}
            </Typography>
          );
        })}

        <Typography variant='font16'>
          <Box>
            {generateWordingForAutomationAction(
              emailAutomation.automation_actions,
            )}
          </Box>
        </Typography>
        {run_other_automations && (
          <>
            <Typography color={palette.colors.black} variant='font16'>
              and
            </Typography>
            <Typography variant='font16'>
              <Box>{'run other automations'}</Box>
            </Typography>
          </>
        )}
      </Box>
      <Box alignItems='center' display='flex'>
        <Box marginRight={'10px'}>
          <Toggle
            checked={is_active}
            onChange={() => setIsActivationConfirmationDialogIsVisible(true)}
          />
        </Box>
        <IconButton
          aria-label={`Edit automation ${index + 1}`}
          onClick={() => setIsAutomationPageVisible(true)}
          variant='ghost'
        >
          <IconPencil
            color={palette.colors.purple[500]}
            height='20px'
            width='20px'
          />
        </IconButton>
        <IconButton
          aria-label={`Delete automation ${index + 1}`}
          onClick={() => setIsConfirmationDialogIsVisible(true)}
          variant='ghost'
        >
          <IconTrash
            color={palette.colors.purple[500]}
            height='20px'
            width='20px'
          />
        </IconButton>
      </Box>
      <EmailAutomationPage
        emailAutomation={{
          automation_actions,
          case_insensitive,
          email_automation_id,
          query_expression,
          run_other_automations,
        }}
        isVisible={isAutomationPageVisible}
        onClose={() => setIsAutomationPageVisible(false)}
      />
      <ConfirmModal
        actions={[
          {
            onClick: () => closeDialog(),
            title: 'Cancel',
          },
          {
            onClick: () => {
              const callable = () =>
                is_active
                  ? deactivateAutomation(email_automation_id)
                  : activateAutomation(email_automation_id);
              callable()
                .unwrap()
                .then(() => {
                  closeDialog();
                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: 4000,
                      title: `Automation was moved to the “${
                        is_active ? 'Inactive' : 'Active'
                      }” tab.`,
                      variant: 'default',
                    }),
                  );
                })
                .catch(() => {
                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: 4000,
                      title:
                        'An error ocurred, please refresh the page or try again',
                      variant: 'danger',
                    }),
                  );
                });
            },
            title: is_active ? 'Deactivate' : 'Activate',
          },
        ]}
        description={
          <>
            {`This will make your automation go ${
              is_active ? 'offline' : 'online'
            }`}
            .<p>Are you sure you want to continue?</p>
          </>
        }
        isOpen={isActivationConfirmationDialogVisible}
        onClose={closeDialog}
        title={`${is_active ? 'Deactivate' : 'Activate'}?`}
      />
      <ConfirmModal
        actions={[
          {
            onClick: () => closeDialog(),
            title: 'No',
          },
          {
            onClick: () => {
              deleteEmailAutomation(email_automation_id || '');
              closeDialog();
            },
            title: 'Yes, delete',
          },
        ]}
        description='You are about to delete an email automation rule that will affect how we handle responding to your email inquiries. Are you sure you want to delete it?'
        isOpen={isConfirmationDialogVisible}
        maxWidth='sm'
        onClose={closeDialog}
        title='Delete email automation'
      />
    </Box>
  );

  return (
    <Box alignItems='center' display='flex' gap='12px'>
      {isDraggable ? (
        <>
          <Box alignItems='center' height='16px' width='16px'>
            <Typography variant='font20'>{index + 1}</Typography>
          </Box>
          <DraggableAutomationListItem
            id={email_automation_id}
            index={index}
            onDrop={(dragIndex, dropIndex) =>
              onDrop && onDrop(dragIndex, dropIndex)
            }
            reorderComponentsOnDrag={(dragIndex, dropIndex) =>
              onDrag && onDrag(dragIndex, dropIndex)
            }
          >
            {cardItem}
          </DraggableAutomationListItem>
        </>
      ) : (
        cardItem
      )}
    </Box>
  );
};

export default AutomationListItem;

const Ul = styled('ul')`
  padding: 0 0 0 20px;
  margin: 5px 0 0 0;
`;

const DraggableIcon = styled('img')`
  height: 16px;
  width: 16px;
`;
