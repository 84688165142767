import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface SummarySectionProps {
  article: KnowledgeGapArticleRecommendation;
}

const SummarySection = ({ article }: SummarySectionProps) => {
  const { palette } = useTheme();

  if (!article.summary) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Typography variant='font11'>Summary</Typography>
      <Box
        borderRadius={1}
        display='flex'
        p={2}
        sx={{
          background:
            'linear-gradient(270deg, rgba(189, 0, 255, 0.05) 0%, rgba(56, 102, 245, 0.05) 100%)',
        }}
      >
        {article.summary ? (
          <Typography color={palette.colors.slate[700]} variant='font12Medium'>
            {article.summary}
          </Typography>
        ) : (
          <Typography color={palette.colors.grey[700]} variant='font12'>
            {NA}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SummarySection;
