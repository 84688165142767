import React from 'react';

import { Roles } from '../app/types';
import { useGetPermissionsQuery } from 'src/services/dashboard-api';
import { getUserRole } from 'src/utils/enums';

const ROLE_LEVELS: Array<Roles> = [
  'ROLE_AGENT',
  'ROLE_USER',
  'ROLE_ADMIN',
  'ROLE_SUPER_ADMIN',
];

const SecuredComponent = ({
  children,
  permissionKey,
  role = 'ROLE_SUPER_ADMIN',
}: {
  children: React.ReactNode;
  permissionKey?: string;
  role?: Roles;
}) => {
  const { data } = useGetPermissionsQuery();
  const userRole = getUserRole();
  const targetRoleIndex = ROLE_LEVELS.indexOf(role);
  const userRoleIndex = ROLE_LEVELS.indexOf(userRole);
  // Hide the component if the user role is lower permission
  // than the target role
  if (userRoleIndex < targetRoleIndex || !data) {
    return null;
  }

  if (permissionKey && !data[permissionKey]) {
    return null;
  }

  return <>{children}</>;
};

export default SecuredComponent;
