import { useNavigate } from 'react-router';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';

export default function OnboardingCard({
  completedIconSrc = '',
  completedText = '',
  description,
  href,
  isCompleted,
  subtitle,
  title,
}: {
  completedIconSrc?: string;
  completedText?: string;
  description: string;
  href: string;
  isCompleted: boolean;
  subtitle: string;
  title: string;
}): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const smallerThanXL = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <Box
      sx={{
        [theme.breakpoints.down('lg')]: {
          flexBasis: 'calc(50% - 16px)',
          height: '50%',
        },
        backgroundColor: theme.palette.background.default,
        border: '1px',
        borderColor: theme.palette.colors.grey[100],
        borderRadius: '8px',
        boxShadow: '2px 8px 16px 0px #0311261B, 0px 0px 1px 0px #000C2005',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        maxWidth: '289px',
        padding: '24px',
      }}
    >
      <Box
        sx={{
          '.MuiTypography-root': {
            background: 'linear-gradient(270deg, #BD00FF 0%, #3866F5 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
          display: 'flex',
        }}
      >
        <Typography variant={smallerThanXL ? 'font14Bold' : 'font16Bold'}>
          {title}
        </Typography>
      </Box>
      {isCompleted ? (
        <>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            <img alt='' src={completedIconSrc} />
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant={smallerThanXL ? 'font14Bold' : 'font16Bold'}>
              {completedText}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box py='8px'>
            <Typography variant={smallerThanXL ? 'font12' : 'font14'}>
              {description}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                '.MuiTypography-root': {
                  fontWeight: 600,
                },
                marginBottom: '11px',
              }}
            >
              <Typography
                color={theme.palette.colors.grey[500]}
                variant='font11'
              >
                {subtitle}
              </Typography>
            </Box>
            <Button
              fullWidth
              onClick={() => {
                navigate(href);
              }}
              variant='main'
            >
              Let’s do this!
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
