import React, { FC, useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import { ActionSvg } from '../ForethoughtActionsList';
import actionIcon from 'src/assets/images/action-2.svg';
import { ActionOutlineProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';

export const ActionOutline: FC<React.PropsWithChildren<ActionOutlineProps>> = ({
  children,
  isOver,
  isPositionedFirst,
  isSqueezingMode,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Container
      isPositionedFirst={isPositionedFirst}
      isSqueezingMode={isSqueezingMode}
      ref={ref}
    >
      <Outline isOver={isOver}>
        <ActionSvg height={20} marginRight={7} width={14}>
          <ReactSVG src={actionIcon} />
        </ActionSvg>
        {children}
      </Outline>
    </Container>
  );
};

export const Outline = styled('div')<{
  isOver: boolean;
}>`
  border: 1px dashed ${theme.palette.colors.purple[500]};
  width: 472px;
  padding: 10px 16px 10px 12px;
  border-radius: 20px;
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  background: ${props =>
    props.isOver
      ? Colors.ui.backgrounds.purple
      : Colors.ui.backgrounds.default};
`;

export const Container = styled('div')<{
  isPositionedFirst?: boolean;
  isSqueezingMode?: boolean;
}>`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: ${props => {
    if (props.isPositionedFirst) {
      return '20px auto 0 auto';
    }
    if (props.isSqueezingMode) {
      return '20px auto 0 auto';
    }
    return '20px auto 20px auto';
  }};
`;
