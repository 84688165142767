import React from 'react';
import { Box } from '@mui/material';

interface ChatsTableLayoutProps {
  table: React.ReactNode;
}

const ChatsTableLayout = ({ table }: ChatsTableLayoutProps) => {
  return <Box maxWidth='100%'>{table}</Box>;
};

export default ChatsTableLayout;
