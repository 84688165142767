import { ReactNode } from 'react';
import { Box } from '@mui/material';

interface WidgetPrimaryInstructionProps {
  children: ReactNode;
}

const WidgetPrimaryInstruction = ({
  children,
}: WidgetPrimaryInstructionProps) => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
        fontStyle: 'italic',
        textAlign: 'center',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
      {children}
    </Box>
  );
};

export default WidgetPrimaryInstruction;
