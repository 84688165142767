import moment from 'moment';

import { Filters } from 'src/services/apiInterfaces';

interface NumberOperatorInterface {
  [key: string]: string;
}
const operators: NumberOperatorInterface = {
  BETWEEN: 'between',
  CONTAINS: 'contains',
  'DOES NOT CONTAIN': 'contains',
  'DOES NOT EQUAL': '=',
  'DOES NOT EXIST': 'exists',
  'ENDS WITH': 'endswith',
  EQUALS: '=',
  EXISTS: 'exists',
  FALSE: '=',
  'GREATER THAN': '>',
  'LESS THAN': '<',
  'STARTS WITH': 'startswith',
  TRUE: '=',
};

export const generateFiltersArray = (
  filters: Array<Filters>,
): Array<Filters> => {
  return filters.map((oldFilter: Filters) => {
    const filter = { ...oldFilter };

    const operator = filter.operator;

    const field = {
      field_category: filter.field.field_category?.toLowerCase(),
      field_id: filter.field.field_id,
    };

    const ret = {
      field,
      negate:
        operator.includes('NOT') ||
        operator.includes('FALSE') ||
        operator.includes('DOES NOT EXIST'),
      operator: operators[operator],
      values: filter.values,
    };

    if (operator === 'BETWEEN') {
      ret.values = [
        moment(filter.values[0] as Date).unix(),
        moment(filter.values[1] as Date).unix(),
      ];
    }

    return ret;
  });
};
