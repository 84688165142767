import React, { FunctionComponent, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

import { Modes } from './Modes';

interface SidebarContainerProps {
  children: ReactNode;
  isExpanded: boolean;
  mode: Modes.FULL | Modes.MINIMIZED | Modes.OVERLAY;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const SidebarContainer: FunctionComponent<
  React.PropsWithChildren<SidebarContainerProps>
> = ({ children, isExpanded, mode, onMouseEnter, onMouseLeave }) => {
  const Component = ComponentMap[mode];

  return (
    <Component
      id='sidebarnav'
      isExpanded={isExpanded}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Component>
  );
};

const containerGridStyle = `
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  z-index: 1201;
`;

const MinimizedContainer = styled('div')<{
  isExpanded: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  transition-duration: var(--duration-fast);
  will-change: width box-shadow;
  min-width: ${props =>
    props.isExpanded
      ? 'var(--width-sidebar)'
      : 'var(--width-minimized-sidebar)'};
  box-shadow: ${props =>
    props.isExpanded ? 'var(--box-shadow-sidebar)' : undefined};
  ${containerGridStyle}
`;
const OverlayContainer = styled('div')<{
  isExpanded: boolean;
}>`
  min-width: var(--width-sidebar);
  top: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  transition-duration: var(--duration-fast);
  transform: ${props => (!props.isExpanded ? 'translateX(-100%)' : undefined)};
  ${containerGridStyle}
`;

const FullContainer = styled('div')`
  min-width: var(--width-sidebar);
  ${containerGridStyle}
`;

const ComponentMap: {
  [key: string]:
    | typeof FullContainer
    | typeof MinimizedContainer
    | typeof OverlayContainer;
} = {
  full: FullContainer,
  minimized: MinimizedContainer,
  overlay: OverlayContainer,
};

export default SidebarContainer;
