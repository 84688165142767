import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import {
  ALLOW_LIST_EMPTY,
  KNOWLEDGE_ERROR_STATUS,
  KNOWLEDGE_IN_PROGRESS_STATUS,
  KNOWLEDGE_SOURCE_IS_MISSING_STATUS,
  PUBLISHED_RECENTLY,
} from 'src/slices/solve-config/constants';
import {
  getKnowledgeConfiguration,
  getWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

export const Message = ({ message }: { message: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { palette } = useTheme();
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const params = new URLSearchParams(location.search);
  const query = params.get('tab') ?? '';
  const knowledgeHref = `${location.pathname}?tab=knowledge`;
  const embedHref = `${location.pathname}?tab=embed`;

  const handleKnowledgeNavigate = () => {
    if (query === 'knowledge') {
      dispatch(getKnowledgeConfiguration());
    } else {
      navigate({
        pathname: isSolveLiteEnabled
          ? Routes.SOLVE_LITE
          : Routes.SOLVE_CONFIGURATION,
        search: '?tab=knowledge',
      });
    }
  };

  const handleEmbedNavigate = () => {
    if (query === 'embed') {
      dispatch(getWidgetConfiguration());
    } else {
      navigate({
        pathname: Routes.SOLVE_LITE,
        search: '?tab=embed',
      });
    }
  };

  const handleRenderMessage = (message: string) => {
    switch (message) {
      case KNOWLEDGE_SOURCE_IS_MISSING_STATUS:
        return (
          <Box>
            <Typography
              color={palette.colors.slate[700]}
              variant='font14Medium'
            >
              Please provide the source of your organization&apos;s{' '}
            </Typography>
            <Typography
              color={palette.colors.purple[500]}
              variant='font14Medium'
            >
              <Link
                onClick={handleKnowledgeNavigate}
                style={{
                  cursor: 'pointer',
                  lineHeight: '20px',
                  textDecoration: 'underline',
                }}
                to={knowledgeHref}
              >
                Knowledge base
              </Link>
            </Typography>
          </Box>
        );
      case KNOWLEDGE_ERROR_STATUS:
        return (
          <Box>
            <Typography
              color={palette.colors.slate[700]}
              variant='font14Medium'
            >
              Failed to connect{' '}
            </Typography>
            <Typography
              color={palette.colors.purple[500]}
              variant='font14Medium'
            >
              <Link
                onClick={handleKnowledgeNavigate}
                style={{
                  cursor: 'pointer',
                  lineHeight: '20px',
                  textDecoration: 'underline',
                }}
                to={knowledgeHref}
              >
                Knowledge base
              </Link>
            </Typography>
          </Box>
        );
      case KNOWLEDGE_IN_PROGRESS_STATUS:
        return (
          <Typography color={palette.colors.slate[700]} variant='font14Medium'>
            Your bot is being built!
          </Typography>
        );
      case PUBLISHED_RECENTLY:
        return (
          <Typography color={palette.colors.slate[700]} variant='font14Medium'>
            Live config will be updated within 5 minutes
          </Typography>
        );
      case ALLOW_LIST_EMPTY:
        return (
          <Typography color={palette.colors.slate[700]} variant='font14Medium'>
            <Link
              onClick={handleEmbedNavigate}
              style={{
                cursor: 'pointer',
                lineHeight: '20px',
                textDecoration: 'underline',
              }}
              to={embedHref}
            >
              Domain allowlist
            </Link>{' '}
            is required to publish
          </Typography>
        );
      default:
        return null;
    }
  };

  return handleRenderMessage(message);
};
