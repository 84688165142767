import { Box, useTheme } from '@mui/material';

export default function ActiveIndicator({
  isActive,
  margin,
}: {
  isActive: boolean;
  margin?: string;
}) {
  const { palette } = useTheme();

  return (
    <Box
      bgcolor={isActive ? palette.colors.green[500] : palette.colors.grey[400]}
      borderRadius='50%'
      height='12px'
      margin={margin}
      minWidth='12px'
      width='12px'
    />
  );
}
