import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { IconDiscountCheck } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  Card as BannerLayoutCard,
  CardMetric,
} from '../../common/banner-layout';
import debounce from 'lodash/fp/debounce';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverDashboardBanner } from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { getDiscoverBannerValues } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

interface Props {
  data: DiscoverDashboardBanner;
}

const DashboardTabBanner = ({ data }: Props) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const isLarge = useMediaQuery(breakpoints.up('xl'));
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const onMouseEnter = useMemo(() => {
    return debounce(DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT, () =>
      emitTrackingEventCallback('discover-automation-banner-hovered', {
        page: 'Discover Dashboard',
      }),
    );
  }, [emitTrackingEventCallback]);

  const navigate = useNavigate();
  const {
    costValue,
    fullResolutionTimeValue,
    shortCostValue,
    shortVolumeValue,
    volumeValue,
  } = getDiscoverBannerValues(data.actionable_value);

  const suffix = '/year';
  const flexDirection = isMobile ? 'column' : 'row';

  const getTitle = () => {
    const titles = {
      cost: `Spend less, do more. ${
        isLarge ? '' : '<br />'
      } Automate and save ${costValue}${suffix}.`,
      full_resolution_time: `Save up to ${fullResolutionTimeValue} with automation.`,
      volume: `Save up to ${volumeValue} per year with automation.`,
    };

    return titles[data.highlighted_metric];
  };

  const handleGetStartedClick = () => {
    emitTrackingEventCallback('discover-automation-banner-clicked', {
      page: 'Discover Dashboard',
    });
    navigate(Routes.DISCOVER_AUTOMATION);
  };

  return (
    <Box
      data-appcues-target={getAppCuesId({
        componentType: 'container',
        featureName: 'recommendation',
        pageName: 'discover',
        subType: 'banner',
      })}
      mt={3}
      onMouseEnter={onMouseEnter}
    >
      <BannerLayoutCard direction={flexDirection} viewType='dashboard'>
        <Box mb={isMobile ? 1 : 0}>
          <Box display='flex' mb={1}>
            <Box display='flex' mr={0.5}>
              <IconDiscountCheck color={palette.colors.slate[700]} />
            </Box>
            <Typography color={palette.colors.slate[700]} variant='font16Bold'>
              Recommendation
            </Typography>
          </Box>
          <Box mr={!isMobile ? 3 : 0}>
            <Typography variant='font24'>
              <HtmlComponent content={getTitle()} />
            </Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection={flexDirection}
          gap={isMobile ? 0 : '12px'}
          mb={isMobile ? '50px' : 0}
        >
          <CardMetric
            direction={flexDirection}
            labelTitle={`${DISCOVER_CARD_TEXT.volumeTitle}${suffix}`}
            labelTitleWidth={isMobile ? 'auto' : 125}
            shouldShowBorderRight={!isMobile}
            value={isLarge ? volumeValue : shortVolumeValue}
            viewType='dashboard'
          />
          <CardMetric
            direction={flexDirection}
            labelTitle={`${DISCOVER_CARD_TEXT.resolutionTimeTitle}${suffix}`}
            labelTitleWidth={isMobile ? 'auto' : 159}
            shouldShowBorderRight={!isMobile}
            shouldShowBorderTop={isMobile}
            value={fullResolutionTimeValue}
            viewType='dashboard'
            wrapperMinWidth={isMobile ? 'auto' : 165}
          />
          <CardMetric
            direction={flexDirection}
            labelTitle={`${DISCOVER_CARD_TEXT.costTitle}${suffix}`}
            labelTitleWidth={isMobile ? 'auto' : 125}
            shouldShowBorderTop={isMobile}
            value={isLarge ? costValue : shortCostValue}
            viewType='dashboard'
          />
        </Box>
        <Box>
          <Button
            data-appcues-target={getAppCuesId({
              componentType: 'button',
              featureName: 'recommendation',
              pageName: 'discover',
              subType: 'calltoaction',
            })}
            onClick={handleGetStartedClick}
            variant='main'
          >
            <Typography variant='font14Bold'>Get started</Typography>
          </Button>
        </Box>
      </BannerLayoutCard>
    </Box>
  );
};

export default DashboardTabBanner;
