import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { theme } from '@forethought-technologies/forethought-elements';

interface ErrorIconProps {
  marginLeft?: number;
  marginRight?: number;
}

const ErrorIcon = ({ marginLeft, marginRight }: ErrorIconProps) => {
  return (
    <CloseRoundedIcon
      color='error'
      sx={{
        border: '1.4px solid ' + theme.palette.colors.red[500],
        borderRadius: '50%',
        fontSize: '0.8rem',
        marginLeft,
        marginRight,
      }}
    />
  );
};

export default ErrorIcon;
