import { useState } from 'react';
import * as Yup from 'yup';

import { updateUser } from '../../../slices/session/sessionSlice';
import AccountSettingsForm from 'src/components/account-settings-form/AccountSettingsForm';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { User } from 'src/services/apiInterfaces';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { formatPermissionLevel } from 'src/utils/sessionUtils';

interface UserSettingsProps {
  isLoading: boolean;
  user: User;
}

export default function UserSettings({ isLoading, user }: UserSettingsProps) {
  const { email, first_name, last_name, permission_level, user_id } = user;
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useAppDispatch();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  return (
    <AccountSettingsForm
      avatarText={email.charAt(0).toUpperCase()}
      editButtonLabel='Edit personal info'
      formConfig={[
        {
          id: 'first_name',
          label: 'First name',
          value: first_name,
        },
        {
          id: 'last_name',
          label: 'Last name',
          value: last_name,
        },
        {
          id: 'email',
          label: 'Email',
          readOnly: permission_level === 'USER',
          value: email,
        },
        {
          id: 'permission_level',
          label: 'Permission level',
          readOnly: true,
          tooltipText:
            'Permissions allow specific users in your org to perform specific actions.',
          value: formatPermissionLevel(permission_level),
        },
      ]}
      isEditing={isEditing}
      isLoading={isLoading}
      onSubmit={async formData => {
        try {
          await dispatch(
            updateUser({
              userId: user_id,
              userRequestBody: {
                email,
                enabled: true,
                first_name: formData.first_name,
                last_name: formData.last_name,
                permission_level,
              },
            }),
          ).unwrap();
          setIsEditing(false);
        } catch (error) {
          console.error(error);
          dispatch(
            setGlobalToastOptions({
              title: 'Something went wrong updating user details',
              variant: 'danger',
            }),
          );
        }
      }}
      setIsEditing={
        isEditing
          ? setIsEditing
          : isEditing => {
              emitTrackingEventCallback(
                'account-personal-details-edit-clicked',
              );
              setIsEditing(isEditing);
            }
      }
      title='Personal Information'
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
      })}
    />
  );
}
