import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { captureMessage } from '@sentry/react';

import {
  Button,
  PasswordField,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import LoadingSpinner from '../../spinner';
import { passwordRegex } from 'src/utils/passwordUtils';

interface TempUser {
  challengeParam: {
    userAttributes: {
      email: string;
    };
  };
}

interface CreatePasswordFormProps {
  handleMagicLinkError: () => void;
  handleSuccess: () => void;
  oldPassword: string;
  title: string;
  username: string;
}

export function NewPasswordForm({
  email = '',
  errorMessage = '',
  handleSubmit,
  showCodeField = false,
  title,
}: {
  email?: string;
  errorMessage?: string;
  handleSubmit: (args: {
    code?: string;
    password1: string;
    password2: string;
  }) => void;
  showCodeField?: boolean;
  title: string;
}) {
  const theme = useTheme();

  const initialValues = {
    password1: '',
    password2: '',
    ...(showCodeField ? { code: '' } : {}),
  };

  const validationSchema = Yup.object().shape({
    password1: Yup.string()
      .required('This field is required')
      .min(12, 'Password must have a minimum length of 12 characters')
      .matches(passwordRegex, 'Password does not meet requirements'),
    password2: Yup.string().when('password1', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password1')],
        'Passwords do not match',
      ),
    }),
    ...(showCodeField
      ? {
          code: Yup.string().required('This field is required'),
        }
      : {}),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, handleChange, handleSubmit, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <h2>{title}</h2>
            {email && (
              <TextField disabled label='Email' type='text' value={email} />
            )}
            {showCodeField && (
              <TextField
                label='Security code'
                name='code'
                onChange={handleChange}
                placeholder='Enter security code'
                type='text'
                value={values.code || ''}
              />
            )}
            <PasswordField
              error={errors.password1}
              label='Password'
              name='password1'
              onChange={handleChange}
              placeholder='Password'
              type='password'
              value={values.password1}
            />
            <PasswordField
              error={errors.password2}
              label='Confirm password'
              name='password2'
              onChange={handleChange}
              placeholder='Confirm password'
              type='password'
              value={values.password2}
            />
            <Requirements>
              <b>Your password should:</b>
              <ul>
                <li>have an upper and lower case letter</li>
                <li>have a number</li>
                <li>have a special character</li>
                <li>have a minimum length of 12 characters</li>
              </ul>
            </Requirements>
            {errorMessage && (
              <Box>
                <Typography
                  color={theme.palette.colors.red[500]}
                  variant='font16'
                >
                  {errorMessage}
                </Typography>
              </Box>
            )}
            <Button fullWidth type='submit' variant='main'>
              Set password
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

const CreatePasswordForm = ({
  handleMagicLinkError,
  handleSuccess,
  oldPassword,
  title,
  username,
}: CreatePasswordFormProps) => {
  const [user, setUser] = useState<TempUser | null>(null);

  useEffect(() => {
    Auth.signIn(username, oldPassword)
      .then(setUser)
      .catch(reason => {
        captureMessage('Amplify Rejection in CreatePasswordForm', {
          extra: {
            amplifyRejection: reason,
          },
          level: 'info',
        });
        handleMagicLinkError();
      });
  }, [oldPassword, username, handleMagicLinkError]);

  const handleSubmit = async ({ password1 }: { password1: string }) => {
    // make request to configure password
    await Auth.completeNewPassword(user, password1);
    handleSuccess();
  };

  if (!user) {
    return <LoadingSpinner />;
  }

  const { email } = user.challengeParam.userAttributes;

  return (
    <NewPasswordForm email={email} handleSubmit={handleSubmit} title={title} />
  );
};

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Requirements = styled('div')`
  text-align: left;

  > ul {
    margin: 0;
    padding-inline-start: 24px;
  }
`;

export default CreatePasswordForm;
