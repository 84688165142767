import { styled } from '@mui/material';

export const GreenText = styled('span')`
  color: ${props => props.theme.palette.colors.green[500]};
`;

export const GreyText = styled('span')`
  color: ${props => props.theme.palette.colors.grey[500]};
`;

export const BlueText = styled('span')`
  color: ${props => props.theme.palette.colors.blue[500]};
`;

export const PurpleText = styled('span')`
  color: ${props => props.theme.palette.colors.purple[400]};
`;

export const MessageBox = styled('div')`
  padding: 12px 16px;
  border-radius: 8px;
  background: ${props =>
    props.theme.palette.mode === 'dark'
      ? props.theme.palette.colors.grey[800]
      : props.theme.palette.colors.slate[100]};
`;
