import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import WorkflowPublishButtons from '../builder-top-bar/WorkflowPublishButtons';
import {
  EmailWorkflowResponse,
  IntentEmailConfigurationResponse,
} from '../types';
import { PublishEmailWorkflowPrompt } from './PublishEmailWorkflowPrompt';
import { getResponsesInStepMap } from './utils';
import { selectIntentData } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectIntentEmailConfigurations } from 'src/slices/email-builder/emailBuilderSlice';
import {
  publishEmailWorkflow,
  selectValidationErrors,
} from 'src/slices/email-workflow/emailWorkflowSlice';
import { useAppDispatch } from 'src/store/hooks';
import { minimumPhrasesRequired } from 'src/utils/constants';
import { CommonIntentWorkflowType, Routes } from 'src/utils/enums';

interface EmailPublishButtonsProps {
  emailWorkflow: EmailWorkflowResponse | null;
  intentEmailConfiguration: IntentEmailConfigurationResponse | null;
  isIntentActive: boolean;
  isJourneyMapTab: boolean;
  onUpdateButtonClick: () => void;
  setIsActivateConfigurationDialogVisible: (isActive: boolean) => void;
  setIsRequiredPhrasesModalVisible: (isVisible: boolean) => void;
}

export default function EmailPublishButtons({
  emailWorkflow,
  intentEmailConfiguration,
  isIntentActive,
  isJourneyMapTab,
  onUpdateButtonClick,
  setIsActivateConfigurationDialogVisible,
  setIsRequiredPhrasesModalVisible,
}: EmailPublishButtonsProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const intentData = useSelector(selectIntentData);
  const { stepErrors, workflowErrors } = useSelector(selectValidationErrors);
  const emailConfigurations = useSelector(selectIntentEmailConfigurations);

  if (isJourneyMapTab) {
    if (!emailWorkflow) {
      return null;
    }

    const {
      email_workflow_id: emailWorkflowId,
      has_modified_responses: hasModifiedResponses,
      is_draft: isDraft,
      is_modified: isModified,
      last_modified_date: lastModifiedDate,
      version,
    } = emailWorkflow;

    const responsesInUse = getResponsesInStepMap(
      emailWorkflow.step_map,
      emailConfigurations,
    );
    const hasMissingStaticArticle = responsesInUse.some(
      response => response.missing_static_article,
    );

    const isUpdateButtonDisabled =
      !!workflowErrors.length ||
      !!Object.keys(stepErrors).length ||
      (!isModified && !hasModifiedResponses) ||
      hasMissingStaticArticle;

    const isCommonIntent = Object.values(CommonIntentWorkflowType).includes(
      intentData?.intent_id as CommonIntentWorkflowType,
    );

    return (
      <WorkflowPublishButtons
        areChangesUnsaved={isModified}
        isDiscardButtonDisabled={!isModified}
        isDraftVersion={isDraft}
        isJourneyMapTab={isJourneyMapTab}
        isPreviewButtonVisible={true}
        isToggleButtonOn={isIntentActive}
        isUpdateButtonDisabled={isUpdateButtonDisabled}
        onActivateToggleButtonChange={() => {
          const phrases = intentData?.phrases;
          const phrasesInvalid =
            phrases && phrases.length < minimumPhrasesRequired;
          const shouldDisplayRequiredPhraseModal =
            !isIntentActive && phrasesInvalid && !isCommonIntent;
          if (shouldDisplayRequiredPhraseModal) {
            setIsRequiredPhrasesModalVisible(true);
          } else {
            setIsActivateConfigurationDialogVisible(true);
          }
        }}
        onDiscardButtonClick={() => {
          dispatch(
            publishEmailWorkflow({
              emailWorkflowId,
              isPublishing: false,
              lastModifiedDate,
              version,
            }),
          );
        }}
        onPreviewButtonClick={() => {
          const previewPath = generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
            channel: 'email',
          });
          navigate({
            pathname: previewPath,
            search: `?intentId=${intentData?.intent_id}&workflowId=${emailWorkflowId}`,
          });
        }}
        onUpdateButtonClick={() => {
          onUpdateButtonClick();
        }}
        previewButtonText='Preview'
        saveChangesButtonLabel={'Publish'}
        toggleButtonTooltipText={`${
          isIntentActive ? 'Deactivate' : 'Activate'
        } configuration`}
      />
    );
  }

  if (!intentEmailConfiguration?.is_modified) {
    return null;
  }

  return <PublishEmailWorkflowPrompt />;
}
