import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import {
  ErrorFilterValuesRequestBody,
  ErrorFilterValuesResponse,
  ErrorQueryRequestBody,
  ErrorQueryResponse,
} from 'src/types/ErrorEventTypes';

const errorEventsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getErrorEvents: builder.query<ErrorQueryResponse, ErrorQueryRequestBody>({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.error_events = [...newItems.error_events];
        } else {
          currentCache.error_events = [
            ...currentCache.error_events,
            ...newItems.error_events,
          ];
        }
      },
      query: ({
        channel,
        conversation_id,
        end,
        error_message,
        error_payload,
        page,
        page_size,
        sort_column,
        sort_direction,
        start,
      }) => {
        const qParams = new URLSearchParams({
          channel,
          conversation_id,
          end,
          error_message,
          error_payload,
          page: page.toString(),
          page_size: page_size ?? '100',
          sort_column,
          sort_direction,
          start,
        });

        return (
          SOLVE_V2_BASE_URL +
          '/workflow-builder/intent-conversations/error-events' +
          '?' +
          qParams
        );
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { end, page_size, sort_column, sort_direction, start } =
          queryArgs;
        const qParams = new URLSearchParams({
          end,
          page_size: page_size ?? '100',
          sort_column,
          sort_direction,
          start,
        });
        return endpointName + '&' + qParams;
      },
    }),
    getErrorFilterValues: builder.query<
      ErrorFilterValuesResponse,
      ErrorFilterValuesRequestBody
    >({
      query: ({ end, start }) => {
        const qParams = new URLSearchParams({
          end,
          start,
        });

        return (
          SOLVE_V2_BASE_URL +
          '/workflow-builder/intent-conversations/error-filter-values' +
          '?' +
          qParams
        );
      },
    }),
  }),
});

export const { useGetErrorEventsQuery, useGetErrorFilterValuesQuery } =
  errorEventsApi;
