import { useEffect } from 'react';

import { useGetGeneratedArticlesMutation } from 'src/services/insights/insightsApi';

export const useGetGeneratedArticles = (
  shouldIncludeGeneratedDiscoverArticles: boolean,
  isParentTopic?: boolean,
  topicId?: string,
) => {
  const [getGeneratedArticles, { isLoading: isLoadingGeneratedArticles }] =
    useGetGeneratedArticlesMutation();

  useEffect(() => {
    if (shouldIncludeGeneratedDiscoverArticles) {
      getGeneratedArticles({
        is_parent_topic: isParentTopic,
        topic_id: topicId,
      });
    }
  }, [
    getGeneratedArticles,
    shouldIncludeGeneratedDiscoverArticles,
    isParentTopic,
    topicId,
  ]);

  return { isLoadingGeneratedArticles };
};
