import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ErrorIcon from '../../triage-config-detail-page/ErrorIcon';

const tooltipText =
  'This field no longer exists in the selected integration. Create or select another field.';

const IntegrationErrorInfo = () => {
  const { palette } = useTheme();

  return (
    <Tooltip tooltipContent={tooltipText}>
      <Box alignItems='center' columnGap={1} display='flex'>
        <ErrorIcon />
        <Typography color={palette.colors.red[500]} variant='font14'>
          Error
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default IntegrationErrorInfo;
