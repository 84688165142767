import { OnChunkReceived, sendAuthRequestWithErrorHandling } from '../api';
import {
  PreviewLogProps,
  SolveApiRequest,
  SolveApiResponse,
} from 'src/slices/workflow-preview/types';

export const initializeConversationApi = (
  action: SolveApiRequest,
  onChunkReceived: OnChunkReceived,
): Promise<SolveApiResponse | undefined> => {
  return sendAuthRequestWithErrorHandling(
    `${SOLVE_API_URL}solve/api/v1/conversation`,
    {
      context_variables: action.context_variables,
      conversation_id: action.conversation_id,
      intent_filters_for_preview: action.intent_filters_for_preview,
      is_preview: action.is_preview,
      query: action.query,
      stream: action.stream,
    },
    'POST',
    async () => action.solve_api_token,
    { isStream: action.stream, onChunkReceived },
  );
};

export const updateConversationApi = (
  action: SolveApiRequest,
  onChunkReceived: OnChunkReceived,
): Promise<SolveApiResponse | undefined> => {
  return sendAuthRequestWithErrorHandling(
    `${SOLVE_API_URL}solve/api/v1/conversation/${action.conversation_id}`,
    {
      context_variables: action.context_variables,
      intent_filters_for_preview: action.intent_filters_for_preview,
      is_preview: action.is_preview,
      query: action.query,
      stream: action.stream,
    },
    'PUT',
    async () => action.solve_api_token,
    { isStream: action.stream, onChunkReceived },
  );
};

export const getPreviewLogsApi = (params: {
  conversationId: string;
  token: string;
}): Promise<PreviewLogProps[]> => {
  return sendAuthRequestWithErrorHandling(
    `${SOLVE_API_URL}solve/api/v1/conversation/${params.conversationId}/preview-logs`,
    null,
    'GET',
    async () => params.token,
  );
};
