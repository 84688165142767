import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import debounce from 'lodash/fp/debounce';

export const TextFieldWithMessage = ({
  ariaLabel,
  characterCap = 60,
  footer,
  message,
  onChange,
  placeholder,
  value,
}: {
  ariaLabel: string;
  characterCap?: number;
  footer: string;
  message?: string | React.ReactElement;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  value: string;
}) => {
  const theme = useTheme();
  const [shouldShowError, setShouldShowError] = useState(false);

  const debouncedHideTheError = useMemo(
    () =>
      debounce(1000, () => {
        setShouldShowError(false);
      }),
    [],
  );

  const handleShowErrorTemporarily = () => {
    setShouldShowError(true);
    debouncedHideTheError();
  };

  return (
    <Box>
      {message && <Typography variant='font14'>{message}</Typography>}
      <TextField
        aria-label={ariaLabel}
        onChange={e => {
          if (e.target.value.length > characterCap) {
            handleShowErrorTemporarily();
            return;
          }
          onChange(e);
        }}
        placeholder={placeholder}
        value={value}
      />
      <Typography
        color={
          shouldShowError
            ? theme.palette.colors.red[500]
            : theme.palette.colors.grey[400]
        }
        variant='font12'
      >
        {footer}
      </Typography>
    </Box>
  );
};
