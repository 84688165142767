import { useCallback, useMemo, useState } from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';

import {
  DateRangeFilterButton,
  InfiniteTable,
  MultiSelectFilter,
  Order,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getTimeRangeFromQueryParams } from '../../workflow-builder/intent-workflows-table/utils';
import AuditEventLogsDrawer from './AuditEventLogsDrawer';
import { DateCell } from './DateCell';
import {
  useDebouncedCall,
  useGetAuditEventLogFilters,
  useGetAuditEventLogs,
  useGetAuditPageQueryParams,
  useSetDefaultTimeRangeQueryParams,
} from './hooks';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import { DateRange } from 'src/components/dashboard-pages/discover-dashboard-page/types';
import { useStateParams } from 'src/hooks/hooks';
import {
  selectAuditEventLogsMetadata,
  selectFilterValues,
  selectIsLoadingAuditEventsData,
} from 'src/slices/audit/auditSlice';
import {
  AuditEventSortColumn,
  AuditEventSortDirection,
  AuditQueryEvent,
} from 'src/types/auditTypes';
import { genericSerializeAndDeserialize } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';
import {
  constructDefaultTimeRangeQueryParams,
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

const LIMIT = 100;

const AuditPage = () => {
  //state
  const [multiSelected, setMultiSelected] = useState<string[]>([]);
  const [emailSearch, setEmailSearch] = useState<string>('');
  const [page, setPage] = useState(1);
  const [selectedAuditEventlogId, setSelectedAuditEventlogId] = useState('');
  const [sortDirection, setSortDirection] =
    useStateParams<AuditEventSortDirection>({
      deserialize: (param: string) => param as AuditEventSortDirection,
      initialState: 'desc',
      paramsName: 'sort_direction',
      serialize: genericSerializeAndDeserialize,
    });
  const [sortColumn, setSortColumn] = useStateParams<AuditEventSortColumn>({
    deserialize: (param: string) => param as AuditEventSortColumn,
    initialState: 'timestamp',
    paramsName: 'sort_column',
    serialize: genericSerializeAndDeserialize,
  });
  const [debouncedUserEmail, setDebouncedUserEmail] = useState<string>('');

  //selectors
  const metadata = useSelector(selectAuditEventLogsMetadata);
  const isLoadingAuditEventsData = useSelector(selectIsLoadingAuditEventsData);
  const defaultFilterValues = useSelector(selectFilterValues);

  //hooks
  const theme = useTheme();
  const navigate = useNavigate();
  useSetDefaultTimeRangeQueryParams();
  const { endDate, startDate } = useGetAuditPageQueryParams();
  const auditEventLogFilters = useGetAuditEventLogFilters();
  const updateDebouncedEmail = useDebouncedCall();

  const showLoadingSkeleton =
    isLoadingAuditEventsData || (page === 1 && isLoadingAuditEventsData);

  const filters = useMemo(() => {
    return {
      action: multiSelected.filter(
        value =>
          defaultFilterValues && defaultFilterValues.actions.includes(value),
      ),
      product: multiSelected.filter(
        value =>
          defaultFilterValues && defaultFilterValues.products.includes(value),
      ),
    };
  }, [defaultFilterValues, multiSelected]);

  const auditEventLogs = useGetAuditEventLogs({
    end_timestamp: endDate ? Number(endDate) : 0,
    offset: LIMIT * (page - 1),
    sort_column: sortColumn,
    sort_direction: sortDirection,
    start_timestamp: startDate ? Number(startDate) : 0,
    user_email: debouncedUserEmail,
    ...filters,
  });

  const selectAuditEventLog = useMemo(() => {
    if (!selectedAuditEventlogId) {
      return;
    }
    return auditEventLogs.find(
      event => event.audit_id === selectedAuditEventlogId,
    );
  }, [auditEventLogs, selectedAuditEventlogId]);

  const selectedDateRange = useMemo(() => {
    return getTimeRangeFromQueryParams({
      from: startDate,
      to: endDate,
    });
  }, [startDate, endDate]);

  const columnHelper = createMRTColumnHelper<AuditQueryEvent>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('timestamp', {
        Cell: p => {
          const formattedDate = p.cell.getValue().replace(' UTC', '');
          return <DateCell dateString={formattedDate + 'Z'} />;
        },
        grow: false,
        header: 'Timestamp',
        size: 214,
      }),
      columnHelper.accessor('product', {
        grow: false,
        header: 'Product',
        size: 200,
      }),
      columnHelper.accessor('action', {
        grow: false,
        header: 'Action',
        maxSize: 320,
        minSize: 240,
      }),
      columnHelper.accessor('user_email', {
        grow: true,
        header: 'User email',
      }),
    ];
  }, [columnHelper]);

  //handlers
  const handleLoadMore = useCallback(
    () => setPage(prevPage => prevPage + 1),
    [],
  );

  const handleDateRangeOnChange = (dateRange: DateRange) => {
    setPage(1);

    const existingQueryParams = new URLSearchParams(location.search);
    const newSearchParams = constructDefaultTimeRangeQueryParams(dateRange);

    const mergedSearchParams = new URLSearchParams({
      ...Object.fromEntries(existingQueryParams),
      ...Object.fromEntries(newSearchParams),
    });

    navigate(
      {
        pathname: Routes.LOG,
        search: mergedSearchParams.toString(),
      },
      { replace: true },
    );
  };

  const handleSortCallback = useCallback(
    (property: keyof AuditQueryEvent, order: Order) => {
      setPage(1);
      setSortColumn(property as AuditEventSortColumn, () =>
        setSortDirection(order),
      );
    },
    [setSortColumn, setSortDirection],
  );

  const handleUserEmail = (value: string) => {
    setEmailSearch(value);
    updateDebouncedEmail(() => setDebouncedUserEmail(value));
  };

  return (
    <>
      <Box
        sx={{
          background: 'white',
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          gap: '26px',
          padding: '16px 40px',
        }}
      >
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Typography color={theme.palette.colors.grey[600]} variant='font16'>
            See the history of changes made within this account.
          </Typography>
          <Typography color={theme.palette.colors.grey[600]} variant='font12'>
            Updated hourly
          </Typography>
        </Box>
        <Box flex='1'>
          <InfiniteTable
            hasNextPage={metadata.more_records_to_fetch}
            initialSorting={[
              {
                desc: sortDirection === 'desc',
                id: sortColumn,
              },
            ]}
            isError={false}
            isLoadingFirstPage={showLoadingSkeleton}
            isLoadingNextPage={isLoadingAuditEventsData}
            onLoadMore={handleLoadMore}
            onSortCallback={handleSortCallback}
            stickyHeaderHeight={229}
            tableOptions={{
              columns: columns,
              data: auditEventLogs || [],
              enableColumnActions: false,
              enableColumnFilters: false,
              enableExpanding: false,
              enableFullScreenToggle: false,
              enableGlobalFilter: false,
              enableRowActions: false,
              muiTableBodyRowProps: ({ row }) => ({
                onClick: () => {
                  setSelectedAuditEventlogId(row.original.audit_id);
                },
                sx: {
                  cursor: 'pointer',
                },
              }),
              renderTopToolbarCustomActions: () => (
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <Box width='360px'>
                    <SearchBar
                      aria-label='Email filter'
                      fullWidth
                      onChange={({ target }) => handleUserEmail(target.value)}
                      onClear={() => handleUserEmail('')}
                      placeholder='Search by user email'
                      size='small'
                      value={emailSearch}
                    />
                  </Box>
                  <DateRangeFilterButton
                    initialValue={last30DaysTimeRange}
                    onChange={dateRange => handleDateRangeOnChange(dateRange)}
                    options={datePickerRangeOptionsRevamp}
                    value={selectedDateRange}
                  />
                  <MultiSelectFilter
                    icon={<img src={filterIcon} />}
                    maxHeight={400}
                    onChange={setMultiSelected}
                    options={[...auditEventLogFilters]}
                    placeholder='Filter by'
                    value={multiSelected}
                    variant='secondary'
                  />
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
      <AuditEventLogsDrawer
        auditEventLog={selectAuditEventLog}
        isLoading={isLoadingAuditEventsData}
        onClose={() => setSelectedAuditEventlogId('')}
      />
    </>
  );
};

export default AuditPage;
