import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { Button } from '@forethought-technologies/forethought-elements';
import { LoadingSkeleton } from '../../../components/reusable-components/loading-skeleton';
import KustomerConversationCreationConfiguration from '../handoff-configuration/kustomer/conversation-creation-only';
import { FieldNameToIdx } from '../handoff-configuration/zendesk/constants';
import {
  initializeFieldTouchedArr,
  validateZendeskOrSalesforceContextVariables,
} from '../handoff-configuration/zendesk/helpers';
import { getKustomerCustomAttributesData } from './utils';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { hasAtLeastOneError } from 'src/pages/workflow-builder-edit/handoff-configuration/helpers';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  KustomerConversationCreationCustomization,
  KustomerHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

type KustomerConversationCreationCustomizationPanelProps = {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
};

const KustomerConversationCreationCustomizationPanel: FC<
  React.PropsWithChildren<KustomerConversationCreationCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultKustomerHandoffCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<KustomerHandoffCustomization>;
  const defaultKustomerConversationCreationCustomizationSettings = useMemo(
    () => ({
      conversation_creation_settings: {
        confirmation_message: '',
        custom_attributes: [],
        handoff_form_question_message: '',
        kustomer_custom_attributes: [],
        subject: '',
        write_transcript_to_note: true,
        ...defaultKustomerHandoffCustomizationSettings?.conversation_creation_settings,
      },
      email: 'email',
      name: null,
      question: 'free_form_query',
    }),
    [
      defaultKustomerHandoffCustomizationSettings?.conversation_creation_settings,
    ],
  );
  const handoffCustomization = addingAction
    ? defaultKustomerConversationCreationCustomizationSettings
    : (canvasState?.actionSettings
        .configuration_fields as KustomerConversationCreationCustomization);
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const dispatch = useDispatch();

  // Creating a new state so we can keep track of what has changed
  const [customizationData, setCustomizationData] =
    useState<KustomerConversationCreationCustomization>(handoffCustomization);

  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(customizationData),
  );

  const errorMessages = validateZendeskOrSalesforceContextVariables({
    customizationData: customizationData,
    shouldAllowEmptyName: true,
  });

  const emailErrorMessage = isFieldTouchedArr[FieldNameToIdx.EMAIL]
    ? errorMessages[FieldNameToIdx.EMAIL]
    : '';
  const questionErrorMessage = isFieldTouchedArr[FieldNameToIdx.QUESTION]
    ? errorMessages[FieldNameToIdx.QUESTION]
    : '';

  const { custom_attributes = [], kustomer_custom_attributes = [] } =
    customizationData?.conversation_creation_settings || {};

  const kustomerCustomAttributesData = getKustomerCustomAttributesData(
    custom_attributes,
    kustomer_custom_attributes,
  );

  const kustomerAttributesContainErrors = kustomerCustomAttributesData.some(
    attribute => attribute.error,
  );

  const isPristine = isEqual(customizationData, handoffCustomization);

  const isSaveDisabledFromForm =
    hasWorkflowConflict ||
    Boolean(emailErrorMessage) ||
    Boolean(questionErrorMessage) ||
    kustomerAttributesContainErrors ||
    (!addingAction && isPristine);

  useEffect(() => {
    setAreActionSettingsUnsaved(!isPristine);
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setCustomizationData(
        addingAction
          ? defaultKustomerConversationCreationCustomizationSettings
          : handoffCustomization,
      );
    }
  }, [
    canvasState.isLoadingCustomization,
    handoffCustomization,
    addingAction,
    defaultKustomerConversationCreationCustomizationSettings,
  ]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION,
        ),
      );
    }
  }, [dispatch, addingAction]);

  if (
    canvasState.isLoadingCustomization ||
    !handoffCustomization ||
    !customizationData
  ) {
    return <LoadingSkeleton />;
  }

  return (
    <Container>
      <KustomerConversationCreationConfiguration
        customizationData={customizationData}
        errorMessages={errorMessages}
        isFieldTouchedArr={isFieldTouchedArr}
        setCustomizationData={setCustomizationData}
        setIsFieldTouchedArr={setIsFieldTouchedArr}
      />
      <Box display='flex' gap={2} marginTop='15px'>
        <Button
          onClick={() => {
            onDiscard();
          }}
          size='large'
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          disabled={isSaveDisabledFromForm}
          fullWidth
          onClick={() => {
            setIsFieldTouchedArr(isFieldTouchedArr.map(() => true));

            if (hasAtLeastOneError(errorMessages)) {
              return;
            }

            dispatch(
              addingAction
                ? addAction({
                    ...addingAction,
                    data: {
                      ...addingAction.data,
                      configuration_fields: customizationData,
                    },
                  })
                : updateActionSettings({
                    action_type: canvasState.actionSettings
                      .action_type as string,
                    configuration_fields: customizationData,
                  }),
            );
            dispatchActionEditedTrackingEvent({
              actionType:
                ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};

export default KustomerConversationCreationCustomizationPanel;

const Container = styled('div')`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;
