import Box from '@mui/material/Box';

import OverviewItem, { DataItem } from './OverviewItem';
import OverviewTitle from './OverviewTitle';
import { DateRange } from 'src/types/types';

interface OverviewHeaderProps {
  data: DataItem[];
  dateRange: DateRange;
  gridTemplateColumns?: string;
  isLoading?: boolean;
  title: string;
}

const OverviewHeader = ({
  data,
  dateRange,
  gridTemplateColumns = 'repeat(5, 1fr)',
  isLoading,
  title,
}: OverviewHeaderProps) => {
  return (
    <Box display='flex' flexDirection='column' rowGap={3}>
      <OverviewTitle dateRange={dateRange} title={title} />
      <Box
        columnGap={1}
        display='grid'
        gridTemplateColumns={gridTemplateColumns}
      >
        {data.map(item => (
          <OverviewItem isLoading={isLoading} item={item} key={item.key} />
        ))}
      </Box>
    </Box>
  );
};

export default OverviewHeader;
