const a11yName = 'team-settings';

export const a11yTabPanelProps = (index: number) => {
  return {
    'aria-labelledby': `${a11yName}-tab-${index}`,
    id: `${a11yName}-tabpanel-${index}`,
  };
};

export const a11yTabProps = (index: number) => {
  return {
    'aria-controls': `${a11yName}-tabpanel-${index}`,
    id: `${a11yName}-tab-${index}`,
  };
};
