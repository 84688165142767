import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { styled, Theme, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import {
  IconBolt,
  IconCircleCheckFilled,
  IconPencil,
  IconPlus,
  IconVariable,
} from '@tabler/icons-react';

import {
  Badge,
  Button,
  FilterButton,
  IconButton,
  SearchBar,
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import intentRoutingIcon from '../../assets/icons/intent-routing.svg';
import {
  actionFilterOptions,
  routeFilterOptions,
  TEMPLATE_INTENT_ID,
} from '../../constants';
import {
  useGetAutoflowWorkflowOrAutoflowTemplate,
  useGetIsTemplateIntentWorkflow,
} from '../../hooks';
import {
  sortContextVariables,
  sortTools,
} from '../../workflow-builder/business-logic/helpers';
import VariableBox from '../../workflow-builder/revamped/VariableBox';
import { useGetUsableTools } from '../hooks';
import groupBy from 'lodash/fp/groupBy';
import filterIcon from 'src/assets/images/action-type-filter.svg';
import { TemplateBadge } from 'src/components/template-badge';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useIntents } from 'src/pages/workflow-builder/intent-workflows-table/hooks/useIntents';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { useGetIntentsQuery } from 'src/services/workflow-builder-metrics';
import {
  useAddAutoflowToolMutation,
  useDeleteAutoflowToolMutation,
  usePatchAutoflowCVsMutation,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  emptyIntent,
  openGlobalIntentDrawer,
  setGlobalWorkflowBuilderOptions,
  setIntentToEdit,
} from 'src/slices/ui/uiSlice';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { AutoflowTool } from 'src/types/workflowBuilderAPITypes';
import {
  getVariablesFromAction,
  replaceActionIdInRoute,
} from 'src/utils/actionBuilder/helpers';
import { ContextVariableTypes, Routes } from 'src/utils/enums';

const DEFAULT_TOOL_NUM_TO_SHOW = 3;
const ACCEPTABLE_CV_TYPES: (keyof typeof ContextVariableTypes)[] = [
  'SHORT_TEXT',
  'LONG_TEXT',
  'CHECKBOX',
  'NUMBER',
  'EMAIL',
  'PHONE',
  'DATE',
];

const useGetCVS = (
  intentWorkflowId: string,
): (ContextVariable & { isActive: boolean })[] => {
  const { autoflowWorkflow } =
    useGetAutoflowWorkflowOrAutoflowTemplate(intentWorkflowId);
  const isTemplate = useGetIsTemplateIntentWorkflow();

  const { context_variables: contextVariablesInUse } = autoflowWorkflow ?? {};

  const { contextVariables } = useGetContextVariables({
    shouldIncludeTemplateContextVariables: isTemplate,
  });

  const cvs = useMemo(() => {
    // filter out conversation language
    const filteredWorkflowCvs = contextVariables.filter(
      cv => cv.context_variable_name !== 'Conversation Language',
    );
    return filteredWorkflowCvs.map(workflowCv => ({
      ...workflowCv,
      isActive: Boolean(
        contextVariablesInUse?.find(
          cvInUse => cvInUse === workflowCv.context_variable_id,
        ),
      ),
    }));
  }, [contextVariables, contextVariablesInUse]);

  return cvs;
};

interface ActionDrawerProps {
  intentWorkflowId: string;
  setEditToolId: (toolId: string) => void;
}

const ActionDrawer = ({
  intentWorkflowId,
  setEditToolId,
}: ActionDrawerProps) => {
  const cvs = useGetCVS(intentWorkflowId);

  const { palette } = useTheme();
  const dispatch = useDispatch();

  const { intents: allIntents } = useIntents();
  const { isLoading, tools } = useGetUsableTools();
  const isTemplate = useGetIsTemplateIntentWorkflow();

  const navigate = useNavigate();

  const [addAutoflowTool] = useAddAutoflowToolMutation();
  const [deleteAutoflowTool] = useDeleteAutoflowToolMutation();
  const [patchContextVariable] = usePatchAutoflowCVsMutation();

  const [isActionsExpanded, setIsActionsExpanded] = useState(false);
  const [isCvsExpanded, setIsCvsExpanded] = useState(false);
  const [isIntentRoutingsExpanded, setIsIntentRoutingsExpanded] =
    useState(false);
  const [searchRoutesText, setSearchRoutesText] = useState('');
  const [searchActionsText, setSearchActionsText] = useState('');
  const [searchCvsText, setSearchCvsText] = useState('');
  const [routeFilter, setRouteFilter] = useState('All');
  const [actionFilter, setActionFilter] = useState('All');

  const lowerCaseSearchRoutesText = searchRoutesText.toLowerCase();
  const lowerCaseSearchActionsText = searchActionsText.toLowerCase();
  const lowerCaseSearchCvsText = searchCvsText.toLowerCase();

  const groups = groupBy('tool_type', [
    ...tools.sort((a, b) => Number(b.isActive) - Number(a.isActive)),
  ]);

  const apiTools = groups['api_call'] ?? [];
  const templateApiTools = groups['template_api_call'] ?? [];
  const systemTools = groups['demo'] ?? [];
  const systemBuiltInTools = groups['system_built_in'] ?? [];
  const handoffTools = (groups['hand_off'] ?? []).sort(sortTools);
  const intentRoutingTools = (groups['intent_routing'] ?? []).sort(sortTools);

  const nonHandoffTools = [
    ...apiTools,
    ...systemTools,
    ...templateApiTools,
    ...systemBuiltInTools,
  ]
    .sort(sortTools)
    .filter(({ tool_name: toolName }) => {
      return toolName.toLowerCase().includes(lowerCaseSearchActionsText);
    })
    .filter(
      ({ tool_type: toolType }) =>
        actionFilter === 'All' ||
        (actionFilter === 'API' && toolType === 'api_call') ||
        (actionFilter === 'Other' && toolType !== 'api_call'),
    );

  const workflowTools = [...intentRoutingTools, ...handoffTools]
    .sort(sortTools)
    .map(intent => {
      const fullIntentDetails = allIntents.find(
        ({ intent_definition_id: intentDefinitionId }) =>
          intentDefinitionId === intent.tool_id,
      );

      return {
        ...intent,
        is_autoflow: fullIntentDetails?.is_autoflow || false,
      };
    })
    .filter(({ tool_name: toolName }) => {
      return toolName.toLowerCase().includes(lowerCaseSearchRoutesText);
    })
    .filter(
      ({ is_autoflow: isAutoflow, tool_type: toolType }) =>
        routeFilter === 'All' ||
        (routeFilter === 'Handoff Workflow' && toolType === 'hand_off') ||
        (routeFilter === 'Workflow - Classic' &&
          toolType !== 'hand_off' &&
          !isAutoflow) ||
        (routeFilter === 'Workflow - AutoFlow' &&
          toolType !== 'hand_off' &&
          isAutoflow),
    );

  const actionsToRender = isActionsExpanded
    ? nonHandoffTools
    : nonHandoffTools.slice(0, DEFAULT_TOOL_NUM_TO_SHOW);
  const contextVariablesToDisplay = (
    isTemplate ? cvs.filter(cv => cv.isActive) : cvs
  )
    .sort(sortContextVariables)
    .filter(({ context_variable_name: contextVariableName }) => {
      return contextVariableName.toLowerCase().includes(lowerCaseSearchCvsText);
    });

  const cvsToRender = isCvsExpanded
    ? contextVariablesToDisplay
    : contextVariablesToDisplay.slice(0, DEFAULT_TOOL_NUM_TO_SHOW);
  const intentRoutingsToRender = isIntentRoutingsExpanded
    ? workflowTools
    : workflowTools.slice(0, DEFAULT_TOOL_NUM_TO_SHOW);

  const numOfActiveActions = nonHandoffTools.filter(
    tool => tool.isActive,
  ).length;
  const numOfActiveCvs = cvs.filter(cv => cv.isActive).length;
  const activeIntentRoutingTool = workflowTools.find(tool => tool.isActive);

  const hasAnyNonHandoffTools = Boolean(nonHandoffTools.length);
  const hasAnyCvs = Boolean(cvs.length);
  const hasAnyIntentRoutingTools = Boolean(workflowTools.length);

  const getNonHandoffToolMessage = () => {
    if (isTemplate) {
      return 'Action in use for this template';
    }

    if (numOfActiveActions) {
      return `${numOfActiveActions} Action${
        numOfActiveActions > 1 ? 's' : ''
      } applied`;
    }

    if (hasAnyNonHandoffTools) {
      return 'You can select multiple actions to apply';
    }

    return "No actions created yet. Let's create one";
  };

  const getCvMessage = () => {
    if (numOfActiveCvs) {
      return `${numOfActiveCvs} Pre-defined context variable${
        numOfActiveCvs > 1 ? 's' : ''
      } applied`;
    }

    if (hasAnyCvs) {
      return 'You can select pre-defined context variables to apply';
    }

    return "No context variable created yet. Let's create one";
  };

  const getIntentRoutingToolMessage = () => {
    if (Boolean(activeIntentRoutingTool)) {
      return 'Redirect to other ‘Live’ workflows applied';
    }

    if (hasAnyIntentRoutingTools) {
      return 'You can select workflows to apply';
    }

    return "No workflows created yet. Let's create one";
  };

  const applyLoadingStateToMessages = (getMessage: () => string) => {
    if (isLoading) {
      return <Skeleton />;
    }

    return getMessage();
  };

  const onClickTool = async (tool: AutoflowTool & { isActive: boolean }) => {
    if (isTemplate) {
      return;
    }

    // if clicked tool is active, deactivate it
    if (tool.isActive) {
      deleteAutoflowTool({
        body: { tool_id: tool.tool_id },
        intentWorkflowId,
      });
      return;
    }

    const toolData = {
      body: { tool_id: tool.tool_id, tool_type: tool.tool_type },
      intentWorkflowId,
    };

    addAutoflowTool(toolData);
  };

  const onClickCV = (cv: ContextVariable & { isActive: boolean }) => {
    const cvData = {
      body: { context_variable_id: cv.context_variable_id },
      intentWorkflowId,
    };

    patchContextVariable(cvData);
  };

  return (
    <>
      {(!isTemplate || Boolean(nonHandoffTools.length)) && (
        <Section>
          <StickySectionHeader>
            <Typography variant='font18Bold'>Actions</Typography>
          </StickySectionHeader>
          <StickySectionNote mb={1}>
            <Typography color={palette.colors.grey[500]} variant='font14'>
              {applyLoadingStateToMessages(getNonHandoffToolMessage)}
            </Typography>
          </StickySectionNote>
          {!isTemplate && (
            <Box pb={1} width='100%'>
              <Button
                fullWidth
                onClick={() => {
                  navigate(replaceActionIdInRoute(''));
                }}
                startIcon={<IconPlus />}
                variant='secondary'
              >
                <Typography variant='font14Bold'>Create new Action</Typography>
              </Button>
            </Box>
          )}
          <Box
            alignItems='center'
            display='flex'
            gap={2}
            justifyContent='space-between'
            my={2}
            width={'100%'}
          >
            <Box width={'100%'}>
              <SearchBar
                onChange={e => setSearchActionsText(e.target.value)}
                placeholder='Search'
                value={searchActionsText}
              />
            </Box>
            <Box>
              <FilterButton
                aria-label='Action type'
                initialValue='All'
                onChange={newValue => {
                  setActionFilter(newValue);
                }}
                options={actionFilterOptions}
                startAdornment={<img alt='' src={filterIcon} />}
                value={actionFilter}
              />
            </Box>
          </Box>
          <ToolsSection>
            {isLoading ? (
              <LoadingState />
            ) : (
              actionsToRender.map(tool => (
                <ToolItem
                  key={tool.tool_id}
                  onClickTool={onClickTool}
                  setEditToolId={setEditToolId}
                  tool={tool}
                />
              ))
            )}
            {nonHandoffTools.length > DEFAULT_TOOL_NUM_TO_SHOW && (
              <Box pt={1}>
                <Typography variant='font14Bold'>
                  <ClickableLink
                    onClick={() => setIsActionsExpanded(prev => !prev)}
                  >
                    See {isActionsExpanded ? 'less' : 'more'} actions
                  </ClickableLink>
                </Typography>
              </Box>
            )}
          </ToolsSection>
        </Section>
      )}
      {(!isTemplate || Boolean(intentRoutingsToRender.length)) && (
        <Section>
          <StickySectionHeader>
            <Typography variant='font18Bold'>Routes</Typography>
          </StickySectionHeader>
          <StickySectionNote mb={1}>
            <Typography color={palette.colors.grey[500]} variant='font14'>
              {applyLoadingStateToMessages(getIntentRoutingToolMessage)}
            </Typography>
          </StickySectionNote>
          {!isTemplate && (
            <Box pb={1} width='100%'>
              <Button
                fullWidth
                onClick={() => {
                  dispatch(setIntentToEdit(emptyIntent));
                  dispatch(openGlobalIntentDrawer({ type: 'landing' }));
                }}
                startIcon={<IconPlus />}
                variant='secondary'
              >
                <Typography variant='font14Bold'>
                  Create new Workflow
                </Typography>
              </Button>
            </Box>
          )}
          <Box
            alignItems='center'
            display='flex'
            gap={2}
            justifyContent='space-between'
            my={2}
            width={'100%'}
          >
            <Box width={'100%'}>
              <SearchBar
                aria-label='Routes search bar'
                onChange={e => setSearchRoutesText(e.target.value)}
                placeholder='Search'
                value={searchRoutesText}
              />
            </Box>
            <Box>
              <FilterButton
                aria-label='Route type'
                initialValue='All'
                onChange={newValue => {
                  setRouteFilter(newValue);
                }}
                options={routeFilterOptions}
                startAdornment={<img alt='' src={filterIcon} />}
                value={routeFilter}
              />
            </Box>
          </Box>
          <ToolsSection>
            {isLoading ? (
              <LoadingState />
            ) : (
              intentRoutingsToRender.map(tool => (
                <ToolItem
                  badgeText={
                    tool.tool_type === 'hand_off'
                      ? 'Handoff Workflow'
                      : `Workflow - ${
                          tool.is_autoflow ? 'AutoFlow' : 'Classic'
                        }`
                  }
                  key={tool.tool_id}
                  onClickTool={onClickTool}
                  setEditToolId={setEditToolId}
                  tool={tool}
                />
              ))
            )}
            {intentRoutingTools.length > DEFAULT_TOOL_NUM_TO_SHOW && (
              <Box pt={1}>
                <Typography variant='font14Bold'>
                  <ClickableLink
                    onClick={() => setIsIntentRoutingsExpanded(prev => !prev)}
                  >
                    See {isIntentRoutingsExpanded ? 'less' : 'more'}{' '}
                    &apos;Live&apos; workflows
                  </ClickableLink>
                </Typography>
              </Box>
            )}
          </ToolsSection>
        </Section>
      )}
      {(!isTemplate || Boolean(contextVariablesToDisplay.length)) && (
        <Section>
          <StickySectionHeader>
            <Typography variant='font18Bold'>Context Variables</Typography>
          </StickySectionHeader>
          <StickySectionNote mb={1}>
            <Typography color={palette.colors.grey[500]} variant='font14'>
              {applyLoadingStateToMessages(getCvMessage)}
            </Typography>
          </StickySectionNote>
          <Box pb={1} width='100%'>
            {!isTemplate && (
              <Button
                fullWidth
                onClick={() => {
                  dispatch(
                    setGlobalWorkflowBuilderOptions({ isCvDrawerOpen: true }),
                  );
                }}
                startIcon={<IconPlus />}
                variant='secondary'
              >
                <Typography variant='font14Bold'>Create new CV</Typography>
              </Button>
            )}
          </Box>
          <Box
            alignItems='center'
            display='flex'
            gap={2}
            justifyContent='space-between'
            my={2}
            width={'100%'}
          >
            <SearchBar
              aria-label='Context Variables search bar'
              onChange={e => setSearchCvsText(e.target.value)}
              placeholder='Search'
              value={searchCvsText}
            />
          </Box>

          <ToolsSection>
            {isLoading ? (
              <LoadingState />
            ) : (
              cvsToRender.map(cv => (
                <ContextVariableItem
                  cv={cv}
                  key={cv.context_variable_id}
                  onClickCV={onClickCV}
                />
              ))
            )}

            {contextVariablesToDisplay.length > DEFAULT_TOOL_NUM_TO_SHOW && (
              <Box pt={1}>
                <Typography variant='font14Bold'>
                  <ClickableLink
                    onClick={() => setIsCvsExpanded(prev => !prev)}
                  >
                    See {isCvsExpanded ? 'less' : 'more'} context variables
                  </ClickableLink>
                </Typography>
              </Box>
            )}
          </ToolsSection>
        </Section>
      )}
    </>
  );
};

const getIconForTool = (tool: AutoflowTool, theme: Theme) => {
  switch (tool.tool_type) {
    case 'demo':
    case 'api_call':
    case 'template_api_call':
    case 'system_built_in':
      return (
        <CircleIcon bgcolor={theme.palette.colors.blue[500]}>
          <IconBolt size={20} />
        </CircleIcon>
      );
    case 'hand_off':
    case 'intent_routing':
      return (
        <CircleIcon bgcolor='#1F866A'>
          <Icon src={intentRoutingIcon} />
        </CircleIcon>
      );
    default:
      return null;
  }
};

const useGetHrefForTool = (tool: AutoflowTool) => {
  const { data } = useGetIntentsQuery();
  const { tool_id: toolId, tool_type: toolType } = tool;

  const isToolIdIntentId =
    toolType === 'hand_off' || toolType === 'intent_routing';

  const workflowId = useMemo(() => {
    if (!isToolIdIntentId) {
      return '';
    }

    return (
      data?.intents.find(intent => intent.intent_definition_id === toolId)
        ?.intent_workflow_id ?? ''
    );
  }, [data?.intents, isToolIdIntentId, toolId]);

  if (toolType === 'api_call' || toolType === 'template_api_call') {
    return replaceActionIdInRoute(toolId);
  }

  if (isToolIdIntentId && workflowId) {
    const params = new URLSearchParams({ intentId: toolId, workflowId });
    return `${Routes.WORKFLOW_BUILDER_EDIT}?${params}`;
  }

  return null;
};

const useGetActionFieldsForTool = (
  tool: AutoflowTool,
):
  | (ReturnType<typeof getVariablesFromAction> & {
      description: string;
      isDisabled: boolean;
    })
  | Record<string, never> => {
  const { data } = useGetActionBuilderActionsQuery({});
  const { contextVariables } = useGetContextVariables({
    shouldIncludeTemplateContextVariables: true,
  });

  if (tool.tool_type === 'api_call' || tool.tool_type === 'template_api_call') {
    const action = data?.actions.find(
      action => action.action_id === tool.tool_id,
    );
    if (!action) {
      return {};
    }
    const variables = getVariablesFromAction(action, contextVariables);

    const hasAcceptableInputVariables = variables.inputVariables.every(
      inputVariable =>
        ACCEPTABLE_CV_TYPES.includes(inputVariable.context_variable_type),
    );

    return {
      description: action?.action_description,
      ...variables,
      isDisabled:
        !hasAcceptableInputVariables ||
        Boolean(variables.dynamicListOutputVariables?.length),
    };
  }

  return {};
};

interface ToolItemProps {
  badgeText?: string;
  onClickTool: (tool: AutoflowTool & { isActive: boolean }) => void;
  setEditToolId: (toolId: string) => void;
  tool: AutoflowTool & { isActive: boolean };
}

const ToolItem = ({
  badgeText,
  onClickTool,
  setEditToolId,
  tool,
}: ToolItemProps) => {
  const theme = useTheme();
  const isActive = tool.isActive;
  const href = useGetHrefForTool(tool);
  const {
    description = '',
    dynamicListOutputVariables = [],
    inputVariables = [],
    isDisabled,
    outputVariables = [],
  } = useGetActionFieldsForTool(tool);

  const label = <Typography variant='font14'>{tool.tool_name}</Typography>;

  const componentBody = (
    <ToolCard
      isActive={isActive}
      isDisabled={isDisabled}
      onClick={() => !isDisabled && onClickTool(tool)}
    >
      <Box
        alignItems={
          !description && !inputVariables.length && !outputVariables.length
            ? 'center'
            : 'flex-start'
        }
        display='flex'
        gap='8px'
        sx={{
          a: {
            '&:hover': {
              textDecoration: 'underline',
            },
            color: theme.palette.common.black,
            textDecoration: 'none',
          },
        }}
      >
        {getIconForTool(tool, theme)}
        <Box display='flex' flexDirection='column'>
          <Typography variant='font14Bold'>
            {href ? (
              <Link
                onClick={e => e.stopPropagation()}
                target='_blank'
                to={href}
              >
                {label}
              </Link>
            ) : (
              label
            )}
          </Typography>
          {Boolean(description) && (
            <Box>
              <Typography
                color={theme.palette.colors.grey[600]}
                variant='font12'
              >
                {description}
              </Typography>
            </Box>
          )}
          {(inputVariables.length > 0 ||
            outputVariables.length > 0 ||
            dynamicListOutputVariables.length > 0) && (
            <Box>
              <VariableBox contextVariables={inputVariables} label='Input:' />
              <VariableBox contextVariables={outputVariables} label='Output:' />
              <VariableBox
                contextVariables={dynamicListOutputVariables}
                label='Dynamic list output:'
              />
            </Box>
          )}
        </Box>
        <Box display='flex' flex={1} gap={1} justifyContent='flex-end'>
          {(tool.tool_type === 'template_api_call' ||
            tool.tool_id.startsWith(TEMPLATE_INTENT_ID)) && <TemplateBadge />}
          <ActivenessIndicator isActive={isActive} />
        </Box>
      </Box>
      {badgeText && (
        <Box ml={5}>
          <Badge label={badgeText} variant='macro' />
        </Box>
      )}
    </ToolCard>
  );

  return isDisabled ? (
    <Tooltip
      arrow
      placement='top'
      title='This action contains context variables of an unacceptable type and cannot be used in Autoflows'
    >
      {componentBody}
    </Tooltip>
  ) : tool.tool_type === 'system_built_in' && tool.isActive ? (
    <Tooltip
      arrow={false}
      componentsProps={{
        popper: {
          sx: [
            {
              '& .MuiTooltip-arrow': {
                '&:before': {
                  border: `1px solid ${theme.palette.colors.slate[200]}`,
                },
                color: theme.palette.colors.white,
              },
              '& .MuiTooltip-tooltip': {
                backgroundColor: theme.palette.colors.white,
                border: `1px solid ${theme.palette.colors.slate[200]}`,
                color: theme.palette.colors.black,
                maxWidth: 320,
                whiteSpace: 'pre-line',
              },
            },
          ],
        },
      }}
      placement='top-end'
      title={
        <Tooltip title='Edit tool'>
          <Box>
            <IconButton
              aria-label='Edit tool'
              onClick={() => setEditToolId(tool.tool_id)}
              variant='ghost'
            >
              <IconPencil color={theme.palette.grey[700]} size={20} />
            </IconButton>
          </Box>
        </Tooltip>
      }
    >
      {componentBody}
    </Tooltip>
  ) : (
    componentBody
  );
};

interface ContextVariableItemProps {
  cv: ContextVariable & { isActive: boolean };
  onClickCV: (cv: ContextVariable & { isActive: boolean }) => void;
}

const ContextVariableItem = ({ cv, onClickCV }: ContextVariableItemProps) => {
  const isActive = cv.isActive;
  const theme = useTheme();
  const isTemplate = useGetIsTemplateIntentWorkflow();

  return (
    <ToolCard isActive={isActive} onClick={() => onClickCV(cv)}>
      <Box alignItems='center' display='flex' gap='8px'>
        <CircleIcon bgcolor={theme.palette.colors.blue[700]}>
          <IconVariable size={20} />
        </CircleIcon>
        <Typography variant='font14'>{cv.context_variable_name}</Typography>
        <Box
          alignItems='center'
          display='flex'
          flex={1}
          gap={1}
          justifyContent='flex-end'
        >
          {isTemplate && <TemplateBadge />}
          <ActivenessIndicator isActive={isActive} />
        </Box>
      </Box>
    </ToolCard>
  );
};

export const ActivenessIndicator = ({ isActive }: { isActive: boolean }) => {
  const theme = useTheme();

  return isActive ? (
    <Box height='20px' sx={{ color: theme.palette.colors.green[500] }}>
      <IconCircleCheckFilled size={20} />
    </Box>
  ) : (
    <Box
      height='16.67px'
      sx={{
        border: '1px solid ' + theme.palette.colors.grey[200],
        borderRadius: '50%',
        color: theme.palette.colors.green[500],
        mr: '1px', // to fix alignment with filled check
      }}
      width='16.67px'
    />
  );
};

const CircleIcon = styled('div')<{ bgcolor: string }>`
  background-color: ${props => props.bgcolor};
  border-radius: 16px;
  color: white;
  height: 32px;
  padding: 6px;
`;

const LoadingState = () => {
  const elements = Array(DEFAULT_TOOL_NUM_TO_SHOW)
    .fill(null)
    .map((_, index) => <Skeleton height='54px' key={index} />);

  return <>{elements}</>;
};

const Icon = styled(ReactSVG)`
  svg {
    [fill] {
      fill: ${props => props.theme.palette.colors.white};
    }
    [stroke] {
      stroke: ${props => props.theme.palette.colors.white};
    }
  }
`;

const Section = styled('section')`
  width: 100%;
`;

const StickySectionHeader = styled('div')`
  background-color: ${props => props.theme.palette.colors.white};
  margin-bottom: 8px;
`;

const StickySectionNote = styled(Box)`
  background-color: ${props => props.theme.palette.colors.white};
`;

const ToolsSection = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-bottom: 24px;
`;

const ToolCard = styled('div')<{ isActive: boolean; isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  padding: 10px 16px 10px 8px;
  background-color: ${props => {
    if (props.isActive) {
      return props.theme.palette.colors.slate[100];
    }

    if (props.isDisabled) {
      return props.theme.palette.colors.slate[100];
    }

    return props.theme.palette.colors.white;
  }};
  border: ${props =>
    props.isActive
      ? `1px solid ${props.theme.palette.colors.purple[500]}`
      : `1px solid ${props.theme.palette.colors.slate[200]}`};
  &:hover {
    background-color: ${props =>
      !props.isDisabled && props.theme.palette.colors.slate[100]};
  }
`;

const ClickableLink = styled('button')`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  padding: 0;
`;

export default ActionDrawer;
