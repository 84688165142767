import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getPreviewLogsApi,
  initializeConversationApi,
  updateConversationApi,
} from 'src/services/api-preview/apiPreviewApi';
import {
  ForethoughtError,
  isForethoughtError,
  PreviewLogProps,
  SolveApiRequest,
  SolveApiResponse,
} from 'src/slices/workflow-preview/types';
import { receiveApiPreviewChunk } from 'src/slices/workflow-preview/workflowPreviewSlice';
import { RootState } from 'src/store/rootReducer';

export const initializeConversation = createAsyncThunk<
  SolveApiResponse | undefined,
  SolveApiRequest,
  {
    rejectValue: ForethoughtError;
  }
>('solveApi/conversation', async (params, { dispatch, rejectWithValue }) => {
  try {
    const res = await initializeConversationApi(params, received =>
      dispatch(receiveApiPreviewChunk(received)),
    );
    return res;
  } catch (error) {
    if (isForethoughtError(error)) {
      return rejectWithValue(error);
    }

    throw error;
  }
});

export const updateConversation = createAsyncThunk<
  SolveApiResponse | undefined,
  SolveApiRequest,
  {
    rejectValue: ForethoughtError;
  }
>(
  'solveApi/message',
  async (params: SolveApiRequest, { dispatch, rejectWithValue }) => {
    try {
      const res = await updateConversationApi(params, received =>
        dispatch(receiveApiPreviewChunk(received)),
      );
      return res;
    } catch (error) {
      if (isForethoughtError(error)) {
        return rejectWithValue(error);
      }

      throw error;
    }
  },
);

export const getPreviewLogs = createAsyncThunk<
  PreviewLogProps[],
  { conversationId?: string; token: string }
>('solveApi/previewLogs', async (params, { getState }) => {
  // @ts-expect-error getState is not typed
  const state: RootState = getState();

  return getPreviewLogsApi({
    ...params,
    ...(state.workflowPreview.apiPreview.conversation_id
      ? { conversationId: state.workflowPreview.apiPreview.conversation_id }
      : { conversationId: '' }),
  });
});
