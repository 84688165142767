import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import { textMediumStyle, textRegularStyle } from 'src/styles/styledMixin';

export const FieldContainer = styled('div')<{ marginTop?: string }>`
  margin-top: ${props => props.marginTop || '0'};
  display: flex;
  align-items: center;
`;

export const FieldLabel = styled('label')<{
  isWithWidth?: boolean;
  marginLeft?: string;
}>`
  ${textMediumStyle({ fontSize: '15px' })};
  margin-right: 20px;
  margin-left: ${props => props.marginLeft || '0'};
  width: ${props => (props.isWithWidth ? '118px' : 'auto')};
`;

export const ErrorMessage = styled('span')`
  ${textMediumStyle({ fontSize: '12px' })}
  color: ${Colors.ui.semantic.destructive.default};
  margin-top: 4px;
`;

export const InputButton = styled('button')`
  ${textMediumStyle({ fontSize: '15px' })};
  border-radius: 4px;
  background-color: ${Colors.ui.backgrounds.slate};
  color: ${theme.palette.colors.blue[600]};
  padding: 11px 30px;
  width: 106px;
  text-align: center;
  cursor: pointer;
`;

export const ColorInput = styled('input')`
  opacity: 0;
  width: 0;
  margin-right: -4px;
`;

export const FakeInput = styled('div')<{ isPlaceholder?: boolean }>`
  ${textRegularStyle({ fontSize: '15px' })};
  border-radius: 4px;
  background-color: ${Colors.ui.backgrounds.slate};
  color: ${props =>
    props.isPlaceholder ? Colors.ui.text.placeholder : Colors.ui.text.primary};
  padding: 11px 16px;
  width: 178px;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
