export interface FilterDict {
  key: string;
  name: string;
}

export const filterDict: Array<FilterDict> = [
  {
    key: 'all',
    name: 'All Macros & Articles',
  },
  {
    key: 'macro',
    name: 'Only Macros',
  },
  {
    key: 'article',
    name: 'Only Articles',
  },
];

export const workflowsFilterDict: Array<FilterDict> = [
  {
    key: 'table-workflow',
    name: 'All Workflows',
  },
  {
    key: 'table-knowledge',
    name: 'All Articles',
  },
];
