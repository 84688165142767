import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';

import { getWhitelistedDomains, updateWhitelistedDomains } from './thunks';
import { SettingsSlice } from './types';

export const initialState: SettingsSlice = {
  allowAllDomains: false,
  pusherSessionId: uuidv4(),
  whitelistedDomains: [],
};

const settingsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getWhitelistedDomains.fulfilled, (state, action) => {
      state.whitelistedDomains = action.payload.whitelist;
      state.allowAllDomains = action.payload.allow_all_domains;
    });

    builder.addCase(updateWhitelistedDomains.fulfilled, (state, action) => {
      state.whitelistedDomains = action.payload.whitelist;
      state.allowAllDomains = action.payload.allow_all_domains;
    });
  },
  initialState,
  name: 'settings',
  reducers: {},
});

export default settingsSlice.reducer;
