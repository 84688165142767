import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { styled } from '@mui/material';

import { Colors } from '@forethought-technologies/forethought-elements';
import Checkbox from 'src/components/checkbox';
import { textRegularStyle } from 'src/styles/styledMixin';

const IgnoreNameEmailCheckbox: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Field name='agent_chat_handoff_settings.ignore_name_email'>
      {({ field }: FieldProps) => (
        <CheckBoxOption>
          <Checkbox checked={!!field.value} {...field} />
          <CheckboxLabel>
            Do not ask user for name and email prior to agent handoff
          </CheckboxLabel>
        </CheckBoxOption>
      )}
    </Field>
  );
};

export default IgnoreNameEmailCheckbox;

const CheckboxLabel = styled('p')`
  margin-left: 6px;
  ${textRegularStyle({ color: Colors.ui.text.primary, fontSize: '16px' })}
`;

const CheckBoxOption = styled('div')`
  display: flex;
  align-items: center;
`;
