import { ContextVariable } from 'src/types/actionBuilderApiTypes';

/**
 * Converts input text example - <p>Hiya $WC Name&nbsp;</p>
 * to <p>Hiya {{29efbee5_e0c2_4380_89c2_32dcbaaa88c5}}&nbsp;</p>
 */
export const makeContextVariableAware = (
  text: string,
  context_variables: ContextVariable[],
) => {
  let contextAwareText = text;
  const sortedContextVariables = [...context_variables].sort(
    (itemA, itemB) =>
      itemB.context_variable_name.length - itemA.context_variable_name.length,
  );
  if (contextAwareText.includes('$')) {
    sortedContextVariables.forEach(ctxVar => {
      const varSlug = `$${ctxVar.context_variable_name}`;
      if (contextAwareText.includes(varSlug)) {
        contextAwareText = contextAwareText.replaceAll(
          varSlug,
          `{{${ctxVar.context_variable_id.replaceAll('-', '_')}}}`,
        );
      }
    });
  }
  return contextAwareText;
};
