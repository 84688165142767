import 'react-loading-skeleton/dist/skeleton.css';

import Skeleton from 'react-loading-skeleton';
import { styled } from '@mui/material';

export const LoadingSkeleton = () => {
  return (
    <>
      <Skeleton
        borderRadius={3}
        height='12px'
        style={{
          display: 'block',
          margin: '0 0 20px 0',
          verticalAlign: 'middle',
        }}
        width='25%'
      />
      <LoadingContainer>
        <Skeleton
          borderRadius={3}
          height='20px'
          inline
          style={{ marginRight: '10px', verticalAlign: 'middle' }}
          width='20px'
        />
        <Skeleton
          borderRadius={3}
          height='12px'
          inline
          style={{ verticalAlign: 'middle' }}
          width={'40%'}
        />
      </LoadingContainer>
      <LoadingContainer>
        <Skeleton
          borderRadius={3}
          height='20px'
          inline
          style={{ marginRight: '10px', verticalAlign: 'middle' }}
          width='20px'
        />
        <Skeleton
          borderRadius={3}
          height='12px'
          inline
          style={{ verticalAlign: 'middle' }}
          width={'50%'}
        />
      </LoadingContainer>
      <LoadingContainer>
        <Skeleton
          borderRadius={3}
          height='20px'
          inline
          style={{ marginRight: '10px', verticalAlign: 'middle' }}
          width='20px'
        />
        <Skeleton
          borderRadius={3}
          height='12px'
          inline
          style={{ verticalAlign: 'middle' }}
          width={'55%'}
        />
      </LoadingContainer>
      <LoadingContainer>
        <Skeleton
          borderRadius={3}
          height='20px'
          inline
          style={{ marginRight: '10px', verticalAlign: 'middle' }}
          width='20px'
        />
        <Skeleton
          borderRadius={3}
          height='12px'
          inline
          style={{ verticalAlign: 'middle' }}
          width={'40%'}
        />
      </LoadingContainer>
    </>
  );
};

const LoadingContainer = styled('div')`
  border-left: none;
  border-right: none;
  width: 100%;
  height: 50px;
  align-items: center;
  line-height: 3;
  margin: 1px 0;
`;
