import ExtendIcon from 'src/assets/images/extend.svg';
import ShortenIcon from 'src/assets/images/shorten.svg';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';
import { GenerateResponseActionType } from 'src/types/workflowBuilderAPITypes';

export const actionToIconURL: Record<GenerateResponseActionType, string> = {
  extend: ExtendIcon,
  rephrase: SupportGPTIcon,
  shorten: ShortenIcon,
};

export const actionToDisplayName: Record<GenerateResponseActionType, string> = {
  extend: 'Extend',
  rephrase: 'Generate response',
  shorten: 'Shorten',
};
