import './styles.scss';

import React, { useState } from 'react';
import classNames from 'classnames';

interface SearchBarProps {
  /** Function that is executed when component changes  */
  onChangeHandler(value: string): void;
}

const SearchBarComponent: React.FC<React.PropsWithChildren<SearchBarProps>> = ({
  onChangeHandler,
}: SearchBarProps) => {
  const [search, setSearch] = useState<string>('');
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    onChangeHandler(value);
  };

  const searchIconClasses = classNames('SearchBarComponent-searchIcon', {
    'SearchBarComponent-darkIcon': search.length,
    'SearchBarComponent-icon': !search.length,
  });
  return (
    <div className='SearchBarComponent-container'>
      <span className={searchIconClasses} />
      <input
        className='SearchBarComponent-input'
        data-testid='search-bar'
        onChange={e => handleOnChange(e)}
        placeholder='Search'
        value={search}
      />
      {search.length !== 0 && (
        <span
          className='SearchBarComponent-cancelIcon'
          data-testid='cancel-btn'
          onClick={() => {
            setSearch('');
            onChangeHandler('');
          }}
        />
      )}
    </div>
  );
};

export default SearchBarComponent;
