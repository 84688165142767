// This is a modified version of the code taken from
// https://github.com/remirror/remirror/blob/main/packages/storybook-react/stories/react/replace-content-imperative.tsx

import { forwardRef, type Ref, useImperativeHandle } from 'react';
import { useCommands, useRemirrorContext } from '@remirror/react';

import { replaceContextVariableIdsWithMarkupRef } from './helpers/replaceContextVariableIdsWithMarkup';
import { type ContextVariablesRef, type EditorRef } from './types';

export const ImperativeHandle = forwardRef(function ImperativeHandle(
  {
    contextVariablesRef,
    onSetContent,
  }: {
    contextVariablesRef: ContextVariablesRef;
    onSetContent: (content: string) => void;
  },
  ref: Ref<EditorRef>,
) {
  const { commands, setContent } = useRemirrorContext({ autoUpdate: true });
  const { focus, insertText } = useCommands();

  // Expose content handling to outside
  useImperativeHandle(
    ref,
    () =>
      ({
        focus,
        insertText,
        replaceText: ({ content }) => {
          const processedContent = replaceContextVariableIdsWithMarkupRef(
            content,
            contextVariablesRef,
          );

          commands.insertHtml(processedContent);
        },
        setContent: content => {
          const processedContent = replaceContextVariableIdsWithMarkupRef(
            content,
            contextVariablesRef,
          );
          setContent(processedContent);
          onSetContent(processedContent);
        },
      } as const),
  );

  return <></>;
});
