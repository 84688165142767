import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DiscoverArticle,
  DiscoverState,
  DiscoverTopic,
  KnowledgeGapArticleRecommendation,
} from './types';
import {
  automateTopic,
  getAllTopics,
  getArticleIntegrations,
  getCustomIntents,
  getDashboardMetrics,
  getDiscoverRecommendationDetails,
  getDiscoverRecommendations,
  getKnowledgeBaseSections,
  getKnowledgeGapRecommendations,
  getMetadata,
  getTicketDetails,
  getTopicMetricsById,
  getTopicTickets,
  patchArticle,
  patchTopic,
  postReportedTopic,
  publishArticle,
} from 'src/actions/discover/discover';
import type { RootState } from 'src/store/rootReducer';
import { DiscoverErrorResponse } from 'src/types/DiscoverTypes';
import { ArticleDataSorter } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

export const initialState: DiscoverState = {
  allTopics: {
    cacheKey: null,
    data: null,
    error: null,
    loading: false,
  },
  articleIntegrations: {
    data: null,
    error: null,
    loading: false,
  },
  automateTopic: {
    error: null,
    loading: false,
  },
  dashboardMetrics: {
    cacheKey: null,
    data: null,
    error: null,
    loading: false,
  },
  intents: {
    data: null,
    error: null,
    loading: false,
  },
  knowledgeBaseSections: {
    data: null,
    error: null,
    loading: false,
  },
  knowledgeGapRecommendations: {
    data: null,
    error: null,
    loading: false,
  },
  metadata: {
    data: null,
    error: null,
    loading: false,
  },
  patchArticle: {
    error: null,
    loading: false,
  },
  patchTopic: {
    error: null,
    loading: false,
  },
  recommendationDetails: {
    data: null,
    error: null,
    loading: false,
  },
  recommendations: {
    data: null,
    error: null,
    loading: false,
  },
  ticketDetails: {
    data: null,
    error: null,
    loading: false,
  },
  topicDetailMetrics: {
    cacheKey: null,
    data: null,
    error: null,
    loading: false,
  },
  topicTickets: {
    cacheKey: null,
    data: null,
    error: null,
    loading: false,
  },
};

const patchTopicInStore: CaseReducer<
  DiscoverState,
  PayloadAction<DiscoverTopic>
> = (state, { payload }) => {
  if (state.allTopics.data) {
    const updatedBreakdown = state.allTopics.data.breakdown.map(item =>
      item.topic?.topic_id === payload.topic_id
        ? { ...item, topic: payload }
        : item,
    );
    state.allTopics.data.breakdown = updatedBreakdown;
  }
  if (state.topicDetailMetrics.data?.topic?.topic_id === payload.topic_id) {
    state.topicDetailMetrics.data.topic = payload;
  }
};

const patchArticleInStore: CaseReducer<
  DiscoverState,
  PayloadAction<DiscoverArticle>
> = (state, { payload }) => {
  const mutateArticles = (articles: DiscoverArticle[]) => {
    const currentArticles = [...articles];
    const indexOfPutArticle = currentArticles.findIndex(
      article => article.article_id === payload.article_id,
    );

    if (indexOfPutArticle === -1) {
      return;
    }

    currentArticles[indexOfPutArticle] = {
      ...currentArticles[indexOfPutArticle],
      ...payload,
    };

    return currentArticles;
  };

  if (state.topicDetailMetrics.data) {
    const mutatedArticles = mutateArticles(
      state.topicDetailMetrics.data.articles,
    );
    if (mutatedArticles) {
      state.topicDetailMetrics.data.articles = mutatedArticles;
    }
  }

  if (state.knowledgeGapRecommendations.data) {
    const mutatedArticles = mutateArticles(
      state.knowledgeGapRecommendations.data.articles,
    );
    if (mutatedArticles) {
      state.knowledgeGapRecommendations.data.articles =
        mutatedArticles as KnowledgeGapArticleRecommendation[];
    }
  }
};

const discoverSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getTopicMetricsById.fulfilled, (state, { payload }) => {
      state.topicDetailMetrics.data = payload.data;
      state.topicDetailMetrics.loading = false;
      const request = payload.request;
      const currentFilter = `${request.topicId}+${request.timeFilter.key}+${request.interval}`;
      const currentCacheKey =
        currentFilter + JSON.stringify(request.dataFilterQuery);
      state.topicDetailMetrics.cacheKey = currentCacheKey;
    });
    builder.addCase(getTopicMetricsById.pending, state => {
      state.topicDetailMetrics.error = null;
      state.topicDetailMetrics.loading = true;
    });
    builder.addCase(getTopicMetricsById.rejected, (state, { payload }) => {
      state.topicDetailMetrics.error = payload as DiscoverErrorResponse;
      state.topicDetailMetrics.loading = false;
      state.topicDetailMetrics.cacheKey = null;
    });
    builder.addCase(getAllTopics.fulfilled, (state, { payload }) => {
      state.allTopics.data = payload.data;
      state.allTopics.loading = false;
      // in the future if we support arbitrary time window for this, ignore caching
      const currentCacheKey =
        payload.request.timeFilter.key +
        JSON.stringify(payload.request.dataFilterQuery);
      state.allTopics.cacheKey = currentCacheKey;
    });
    builder.addCase(getAllTopics.pending, state => {
      state.allTopics.error = null;
      state.allTopics.loading = true;
    });
    builder.addCase(getAllTopics.rejected, (state, { payload }) => {
      state.allTopics.error = payload as DiscoverErrorResponse;
      state.allTopics.loading = false;
      state.allTopics.cacheKey = null;
    });
    builder.addCase(getDashboardMetrics.fulfilled, (state, { payload }) => {
      state.dashboardMetrics.data = payload.data;
      state.dashboardMetrics.loading = false;
      const request = payload.request;
      state.dashboardMetrics.cacheKey = `${request.timeFilter.key}+${
        request.interval
      }${JSON.stringify(request.dataFilterQuery)}`;
    });
    builder.addCase(getDashboardMetrics.pending, state => {
      state.dashboardMetrics.error = null;
      state.dashboardMetrics.loading = true;
    });
    builder.addCase(getDashboardMetrics.rejected, (state, { payload }) => {
      state.dashboardMetrics.error = payload as DiscoverErrorResponse;
      state.dashboardMetrics.loading = false;
      state.dashboardMetrics.cacheKey = null;
    });
    builder.addCase(getTopicTickets.fulfilled, (state, { payload }) => {
      state.topicTickets.data = payload.data;
      state.topicTickets.loading = false;
      const request = payload.request;
      const currentFilter = `${request.topicId}+${request.timeFilter.key}`;
      const currentCacheKey =
        currentFilter +
        JSON.stringify(request.dataFilterQuery) +
        (request.searchText || '');
      state.topicTickets.cacheKey = currentCacheKey;
    });
    builder.addCase(getTopicTickets.pending, state => {
      state.topicTickets.error = null;
      state.topicTickets.loading = true;
    });
    builder.addCase(getTopicTickets.rejected, (state, { payload }) => {
      state.topicTickets.error = payload as DiscoverErrorResponse;
      state.topicTickets.loading = false;
      state.topicTickets.cacheKey = null;
    });
    builder.addCase(
      getDiscoverRecommendations.fulfilled,
      (state, { payload }) => {
        state.recommendations.data = payload;
        state.recommendations.loading = false;
        state.recommendations.error = null;
      },
    );
    builder.addCase(getDiscoverRecommendations.pending, state => {
      state.recommendations.data = null;
      state.recommendations.loading = true;
    });
    builder.addCase(
      getDiscoverRecommendations.rejected,
      (state, { payload }) => {
        state.recommendations.error = payload ?? null;
        state.recommendations.loading = false;
      },
    );
    builder.addCase(
      getDiscoverRecommendationDetails.fulfilled,
      (state, action) => {
        state.recommendationDetails.data = action.payload;
        state.recommendationDetails.loading = false;
        state.recommendationDetails.error = null;
      },
    );
    builder.addCase(getDiscoverRecommendationDetails.pending, state => {
      state.recommendationDetails.data = null;
      state.recommendationDetails.loading = true;
    });
    builder.addCase(
      getDiscoverRecommendationDetails.rejected,
      (state, { payload }) => {
        state.recommendationDetails.error = payload ?? null;
        state.recommendationDetails.loading = false;
      },
    );
    builder.addCase(getCustomIntents.fulfilled, (state, { payload }) => {
      state.intents.data = payload;
      state.intents.loading = false;
    });
    builder.addCase(getCustomIntents.pending, state => {
      state.intents.data = null;
      state.intents.loading = true;
      state.intents.error = null;
    });
    builder.addCase(getCustomIntents.rejected, (state, { payload }) => {
      state.intents.error = payload;
      state.intents.loading = false;
    });
    builder.addCase(patchTopic.fulfilled, (state, action) => {
      patchTopicInStore(state, action);
      // Topic modifications that lead to us wanting to fetch metrics again.
      // As of now we only reset cache for metrics, all other cached data will
      // get updated via `patchTopicInStore`
      state.dashboardMetrics.cacheKey = null;
      state.patchTopic.loading = false;
      state.patchTopic.error = null;
    });
    builder.addCase(patchTopic.pending, state => {
      state.patchTopic.loading = true;
      state.patchTopic.error = null;
    });
    builder.addCase(patchTopic.rejected, (state, { payload }) => {
      state.patchTopic.error = payload ?? null;
      state.patchTopic.loading = false;
    });
    builder.addCase(automateTopic.fulfilled, (state, action) => {
      patchTopicInStore(state, action);
      state.automateTopic.loading = false;
      state.automateTopic.error = null;
    });
    builder.addCase(automateTopic.pending, state => {
      state.automateTopic.loading = true;
      state.automateTopic.error = null;
    });
    builder.addCase(automateTopic.rejected, (state, { payload }) => {
      state.automateTopic.error = payload ?? null;
      state.automateTopic.loading = false;
    });
    builder.addCase(postReportedTopic.fulfilled, (state, { payload }) => {
      if (state.topicTickets.data) {
        state.topicTickets.data.tickets =
          state.topicTickets.data.tickets.filter(
            ticket => ticket.id !== payload.ticket_id,
          );
      }
    });
    builder.addCase(getMetadata.fulfilled, (state, { payload }) => {
      state.metadata.data = payload;
      state.metadata.loading = false;
      state.metadata.error = null;
    });
    builder.addCase(getMetadata.pending, state => {
      state.metadata.loading = true;
    });
    builder.addCase(getMetadata.rejected, (state, { payload }) => {
      state.metadata.error = payload ?? {
        error_data: { org_id: 0 },
        error_message: 'failed getting metadata',
        error_type: 'no connection',
      };
      state.metadata.loading = false;
    });
    builder.addCase(
      getKnowledgeBaseSections.fulfilled,
      (state, { payload }) => {
        state.knowledgeBaseSections.data = payload;
        state.knowledgeBaseSections.loading = false;
        state.knowledgeBaseSections.error = null;
      },
    );
    builder.addCase(getKnowledgeBaseSections.pending, state => {
      state.knowledgeBaseSections.loading = true;
      state.knowledgeBaseSections.error = null;
    });
    builder.addCase(getKnowledgeBaseSections.rejected, (state, { payload }) => {
      state.knowledgeBaseSections.error = payload ?? null;
      state.knowledgeBaseSections.loading = false;
    });
    builder.addCase(getArticleIntegrations.fulfilled, (state, { payload }) => {
      state.articleIntegrations.data = payload;
      state.articleIntegrations.loading = false;
      state.articleIntegrations.error = null;
    });
    builder.addCase(getArticleIntegrations.pending, state => {
      state.articleIntegrations.loading = true;
      state.articleIntegrations.error = null;
    });
    builder.addCase(getArticleIntegrations.rejected, (state, { payload }) => {
      state.articleIntegrations.error = payload ?? null;
      state.articleIntegrations.loading = false;
    });
    builder.addCase(publishArticle.fulfilled, (state, action) => {
      patchArticleInStore(state, action);
    });
    builder.addCase(patchArticle.fulfilled, (state, action) => {
      patchArticleInStore(state, action);
      // Article modifications that lead to us wanting to fetch all topics again.
      // As of now we only reset cache for all topics, all other cached data will
      // get updated via `patchArticleInStore`
      state.allTopics.cacheKey = null;
      state.patchArticle.loading = false;
      state.patchArticle.error = null;
    });
    builder.addCase(patchArticle.pending, state => {
      state.patchArticle.loading = true;
      state.patchArticle.error = null;
    });
    builder.addCase(patchArticle.rejected, (state, { payload }) => {
      state.patchArticle.error = payload ?? null;
      state.patchArticle.loading = false;
    });
    builder.addCase(
      getKnowledgeGapRecommendations.fulfilled,
      (state, { payload }) => {
        state.knowledgeGapRecommendations.data = payload;
        state.knowledgeGapRecommendations.loading = false;
        state.knowledgeBaseSections.error = null;
      },
    );
    builder.addCase(getKnowledgeGapRecommendations.pending, state => {
      state.knowledgeGapRecommendations.loading = true;
      state.knowledgeGapRecommendations.error = null;
    });
    builder.addCase(
      getKnowledgeGapRecommendations.rejected,
      (state, { payload }) => {
        state.knowledgeGapRecommendations.error = payload ?? null;
        state.knowledgeGapRecommendations.loading = false;
      },
    );

    builder.addCase(getTicketDetails.fulfilled, (state, { payload }) => {
      state.ticketDetails.data = payload;
      state.ticketDetails.loading = false;
    });
    builder.addCase(getTicketDetails.pending, state => {
      state.ticketDetails.loading = true;
      state.ticketDetails.error = null;
    });
    builder.addCase(getTicketDetails.rejected, (state, action) => {
      state.ticketDetails.error = action.payload ?? null;
      state.ticketDetails.data = {
        replies: undefined,
        ticket_html_body: undefined,
        ticket_id: action.meta.arg.ticketId,
      };

      state.ticketDetails.loading = false;
    });
  },
  initialState,
  name: 'discover',
  reducers: {
    clearCacheKeys: state => {
      state.allTopics.cacheKey = null;
      state.topicDetailMetrics.cacheKey = null;
      state.dashboardMetrics.cacheKey = null;
    },
    patchArticleInStoreAction: patchArticleInStore,
  },
});

export const { clearCacheKeys } = discoverSlice.actions;

export const selectTopicDetailMetrics = (
  state: RootState,
): DiscoverState['topicDetailMetrics'] => state.discover.topicDetailMetrics;

export const selectTicketDetails = (
  state: RootState,
): DiscoverState['ticketDetails'] => state.discover.ticketDetails;

export const selectAllTopics = (state: RootState): DiscoverState['allTopics'] =>
  state.discover.allTopics;

export const selectDashboardMetrics = (
  state: RootState,
): DiscoverState['dashboardMetrics'] => state.discover.dashboardMetrics;

export const selectTopicTickets = (
  state: RootState,
): DiscoverState['topicTickets'] => state.discover.topicTickets;

export const selectRecommendations = (
  state: RootState,
): DiscoverState['recommendations'] => state.discover.recommendations;

export const selectRecommendationDetails = (
  state: RootState,
): DiscoverState['recommendationDetails'] =>
  state.discover.recommendationDetails;

export const selectCustomIntents = (
  state: RootState,
): DiscoverState['intents'] => state.discover.intents;

export const selectPatchTopic = (
  state: RootState,
): DiscoverState['patchTopic'] => state.discover.patchTopic;

export const selectPatchArticle = (
  state: RootState,
): DiscoverState['patchArticle'] => state.discover.patchArticle;

export const selectAutomateTopic = (
  state: RootState,
): DiscoverState['automateTopic'] => state.discover.automateTopic;

export const selectMetadata = (state: RootState): DiscoverState['metadata'] =>
  state.discover.metadata;

export const selectKnowledgeBaseSections = (
  state: RootState,
): DiscoverState['knowledgeBaseSections'] =>
  state.discover.knowledgeBaseSections;

export const selectArticleIntegrations = (
  state: RootState,
): DiscoverState['articleIntegrations'] => state.discover.articleIntegrations;

export const selectKnowledgeGapRecommendations = (
  state: RootState,
): DiscoverState['knowledgeGapRecommendations'] =>
  state.discover.knowledgeGapRecommendations;

export const selectDiscoverArticlesByPath =
  (path: string) =>
  (state: RootState): DiscoverArticle[] => {
    if (path === Routes.DISCOVER_AUTOMATION) {
      return state.discover.knowledgeGapRecommendations?.data?.articles ?? [];
    }

    const sorter = new ArticleDataSorter(
      state.discover.topicDetailMetrics?.data?.articles ?? [],
    );

    const sortFilterValue =
      state.ui.globalDiscoverArticleOptions.articleSortFilterValue;

    return sorter
      .handleFilterState()
      .handleArticleDateSort(sortFilterValue)
      .handleThumbSort().data;
  };

export default discoverSlice.reducer;
