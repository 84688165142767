import React, {
  cloneElement,
  FunctionComponent,
  memo,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material';

import Navbar from '../navbar/navbarV2';
import { Modes } from './Modes';
import SidebarContainer from './sidebarContainer';
import Sidebar from 'src/components/app/sidebar';
import SentryErrorBoundary from 'src/components/sentry-error-boundary/SentryErrorBoundary';
import { useNpsSurvey } from 'src/hooks/hooks';
import useMediaQuery from 'src/hooks/useMediaQuery';
import {
  selectIsSidebarExpanded,
  setIsSidebarExpanded,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { breakpoints } from 'src/styles/breakpoints';
import { Routes } from 'src/utils/enums';

interface LayoutProps {
  footer?: ReactNode;
  globalComponent?: ReactNode;
  main: ReactNode;
  navbar?: ReactNode;
  permissions: Set<string>;
  showMainApp?: boolean;
  showSideBar?: boolean;
}

const Layout: FunctionComponent<React.PropsWithChildren<LayoutProps>> = ({
  globalComponent = null,
  main,
  navbar = <Navbar />,
  permissions = new Set(),
  showMainApp,
  showSideBar = false,
}) => {
  const isMobile = useMediaQuery(breakpoints.mobile);
  const location = useLocation();
  const dispatch = useAppDispatch();

  // Flag to track toggle of when sidebar is shown/minimize
  const [isSidebarHovered, setIsSidebarHovered] = useState(false);
  const isSidebarExpanded = useSelector(selectIsSidebarExpanded);

  const toggleSidebar = useCallback(() => {
    setIsSidebarHovered(!isSidebarHovered);
  }, [isSidebarHovered]);

  // Flag to track toggle state of always show/minimize
  const toggleSidebarFlag = useCallback(() => {
    const updatedToggleFlag = !isSidebarExpanded;
    dispatch(setIsSidebarExpanded(updatedToggleFlag));
    toggleSidebar();
  }, [dispatch, isSidebarExpanded, toggleSidebar]);

  const pathName = location.pathname;

  const isEditActionView = pathName === Routes.ACTION_BUILDER_EDIT;
  const isPredictionSettingsDetailView = pathName.startsWith(
    Routes.PREDICTIONS_SETTINGS + '/',
  );

  const isTopBarVisible = !isEditActionView && !isPredictionSettingsDetailView;

  const getSidebarMode = () => {
    if (isSidebarExpanded && !isMobile) {
      return Modes.FULL;
    } else if (isMobile) {
      return Modes.OVERLAY;
    }
    return Modes.MINIMIZED;
  };

  useNpsSurvey();
  const sidebarMode = getSidebarMode();

  return (
    <>
      {globalComponent}
      {showMainApp ? (
        <AppContainer>
          {showSideBar && (
            <SidebarContainer
              isExpanded={isSidebarHovered || (isSidebarExpanded && !isMobile)}
              mode={sidebarMode}
              onMouseEnter={() => setIsSidebarHovered(true)}
              onMouseLeave={() => setIsSidebarHovered(false)}
            >
              <Sidebar
                isFullWidth={
                  sidebarMode === Modes.OVERLAY ||
                  sidebarMode === Modes.FULL ||
                  (sidebarMode === Modes.MINIMIZED && isSidebarHovered)
                }
                isMobile={isMobile}
                isSidebarExpandedToggle={isSidebarExpanded}
                permissions={permissions}
                toggleSidebar={toggleSidebar}
                toggleSidebarFlag={toggleSidebarFlag}
              />
            </SidebarContainer>
          )}
          <MainContentContainer
            data-testid='main-content'
            sidebarMode={showSideBar ? sidebarMode : undefined}
          >
            {isTopBarVisible && navbar
              ? cloneElement(navbar as React.ReactElement, {
                  toggleSidebar,
                })
              : null}
            <SentryErrorBoundary>
              <MainContentWrapper>{main}</MainContentWrapper>
            </SentryErrorBoundary>
          </MainContentContainer>
        </AppContainer>
      ) : null}
    </>
  );
};

const AppContainer = styled('div')`
  background-color: var(--color-slate-100);
  display: flex;
  height: 100%;
  position: relative;
`;

const MainContentContainer = styled('main')<{
  sidebarMode?: Modes;
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  ${props =>
    props.sidebarMode === Modes.MINIMIZED
      ? `
    margin-left: var(--width-minimized-sidebar);
  `
      : undefined}
`;

const MainContentWrapper = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  isolation: isolate;
  z-index: 0;
  height: calc(100% - 69px);
  overflow-y: auto;
`;

const MemoizedLayout = memo(Layout);

export default MemoizedLayout;
