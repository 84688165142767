import classNames from 'classnames';
import moment from 'moment';

import {
  ListOption,
  theme,
} from '@forethought-technologies/forethought-elements';
import {
  formatNToPercentage,
  formatNToPercentageDecimal,
  roundNumToPercentage,
} from './formatToPercentage';

export const formatDate = (timeframe: string, timestamp: number) => {
  let dataDate;
  const dateStamp = timestamp;
  if (timeframe === 'Daily') {
    dataDate = moment.utc(dateStamp).format('dddd, MMMM Do, YYYY');
  } else if (timeframe === 'Weekly') {
    dataDate = `${
      moment.utc(dateStamp).format('MMM Do') +
      ' - ' +
      moment.utc(dateStamp).add(6, 'days').format('MMM Do, YYYY')
    }`;
  } else if (timeframe === 'Monthly') {
    dataDate = moment.utc(dateStamp).format('MMMM, YYYY');
  }
  return dataDate;
};

export const chartTimeframeFilters = ['Daily', 'Weekly', 'Monthly'];

export const chartTimeframeListOptions: ListOption[] = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

export const chartDataListOptions: ListOption[] = [
  { label: 'Total predictions', value: 'total_predictions' },
  { label: 'Total accurate predictions', value: 'total_accurate_predictions' },
  { label: 'Total cases', value: 'total_cases' },
  { label: 'Prediction accuracy', value: 'prediction_accuracy' },
  { label: 'Case coverage', value: 'case_coverage' },
];

export const dataViews = ['Table', 'Chart'];

export const dataViewListOptions: ListOption[] = [
  {
    label: 'Table',
    optionStartAdornment: <span className='table-view-icon' />,
    value: 'table',
  },
  {
    label: 'Chart',
    optionStartAdornment: <span className='chart-view-icon' />,
    value: 'chart',
  },
];

export const datePickerDropdownOptions = [
  'Last 7 days',
  'Last 14 days',
  'Last 30 days',
  'This month',
  'Previous month',
  'Custom',
];

export const chartEmptyData = (y: string) => [
  {
    timestamp: 1546300800,
    [y]: 3,
  },
  {
    timestamp: 1548979200,
    [y]: 4,
  },
  {
    timestamp: 1551398400,
    [y]: 5,
  },
  {
    timestamp: 1554076800,
    [y]: 6,
  },
  {
    timestamp: 1556668800,
    [y]: 8,
  },
  {
    timestamp: 1559347200,
    [y]: 7,
  },
  {
    timestamp: 1561939200,
    [y]: 6,
  },
  {
    timestamp: 1564617600,
    [y]: 9,
  },
  {
    timestamp: 1567296000,
    [y]: 6,
  },
  {
    timestamp: 1569888000,
    [y]: 8,
  },
  {
    timestamp: 1572566400,
    [y]: 7,
  },
  {
    timestamp: 1575158400,
    [y]: 9,
  },
];

export const barEmptyData = () => [
  {
    color: theme.palette.colors.slate[100],
    data: [
      { x: 1606247674.400148 * 1000, y: 159 },
      { x: 1606161274.400148 * 1000, y: 199 },
      { x: 1606074874.400148 * 1000, y: 157 },
      { x: 1605988474.400148 * 1000, y: 186 },
      { x: 1605902074.400148 * 1000, y: 204 },
      { x: 1605815674.400148 * 1000, y: 178 },
      { x: 1605729274.400148 * 1000, y: 250 },
      { x: 1605642874.400148 * 1000, y: 222 },
      { x: 1605556474.400148 * 1000, y: 195 },
      { x: 1605470074.400148 * 1000, y: 190 },
      { x: 1605383674.400148 * 1000, y: 182 },
      { x: 1605297274.400148 * 1000, y: 244 },
      { x: 1605210874.400148 * 1000, y: 185 },
      { x: 1605124474.400148 * 1000, y: 218 },
      { x: 1605038074.400148 * 1000, y: 173 },
      { x: 1604951674.400148 * 1000, y: 184 },
      { x: 1604865274.400148 * 1000, y: 156 },
      { x: 1604778874.400148 * 1000, y: 176 },
      { x: 1604692474.400148 * 1000, y: 205 },
      { x: 1604606074.400148 * 1000, y: 249 },
      { x: 1604519674.400148 * 1000, y: 197 },
      { x: 1604433274.400148 * 1000, y: 197 },
      { x: 1604346874.400148 * 1000, y: 159 },
      { x: 1604260474.400148 * 1000, y: 178 },
      { x: 1604170474.400148 * 1000, y: 221 },
      { x: 1604084074.400148 * 1000, y: 234 },
      { x: 1603997674.400148 * 1000, y: 164 },
      { x: 1603911274.400148 * 1000, y: 243 },
      { x: 1603824874.400148 * 1000, y: 229 },
      { x: 1603738474.400148 * 1000, y: 184 },
    ],
    id: 'no-data',
    name: 'no data',
  },
];

export const pieEmptyData = () => [
  {
    color: theme.palette.colors.slate[100],
    id: 'no data',
    name: 'No Data',
    y: 100,
  },
];

export const numberWithCommas = (numberToFormat: number | undefined) => {
  return (
    numberToFormat &&
    numberToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  );
};

//if types of y axis values is being sent from backend we use this utility
const formatVal = (
  property: string,
  val: number,
  valueTypes: { [index: string]: string },
  isAssistChart?: boolean,
) => {
  const propertyType = valueTypes[property];
  if (isAssistChart && propertyType === 'PERCENTAGE') {
    return roundNumToPercentage(val);
  } else if (propertyType === 'PERCENTAGE') {
    return formatNToPercentageDecimal(val);
  } else if (propertyType === 'INTEGER') {
    return numberWithCommas(val);
  } else if (propertyType === 'CURRENCY') {
    return `$${numberWithCommas(val)}`;
  } else {
    return val;
  }
};

export const formatNum = (
  property: string,
  numberToFormat: number,
  valueType: {},
  isAssistChart?: boolean,
) => {
  if (typeof numberToFormat === 'string') {
    return numberToFormat;
  }
  if (typeof numberToFormat === 'boolean') {
    return numberToFormat;
  } else if (valueType && Object.keys(valueType).length > 0) {
    return formatVal(property, numberToFormat, valueType, isAssistChart);
  } else if (Number.isInteger(numberToFormat)) {
    return numberWithCommas(numberToFormat);
  } else {
    return formatNToPercentage(numberToFormat);
  }
};

export const emailEmptyBreakdown = {
  csat: 0,
  field_value: 'Macros & Articles',

  reopen_rate: 0,

  reopens: 0,

  resolutions: 0,
  //TODO shoudl be active filter type
  responses: 0,
};

export const widgetEmptyBreakdown = {
  field_value: 'Macros & Articles', //TODO shoudl be active filter type
  interactions: 0,
  resolutions: 0,
  resolve_rate: 0,
  ticket_creations: 0,
};

export const apiEmptyBreakdown = {
  field_value: 'Macros & Articles', //TODO shoudl be active filter type
  interactions: 0,
  resolutions: 0,
  resolve_rate: 0,
  ticket_creations: 0,
};

export const trainedMacrosEmptyBreakdown = {
  is_active: '- - -',
  macro_id: '- - - - - - - - - - ',
  name: ' - - - - - - - - - - ',
  resolutions: '- - -',
};

export const untrainedMacrosEmptyBreakdown = {
  macro_id: '- - - - - - - - - - ',
  name: ' - - - - - - - - - - ',
  usage: '- - -',
};

/** Styling for cells if the value is sibject to change */
export const isFinalValueStyle = (isFinalValue: boolean, element: string) => {
  let style;
  if (element === 'cell') {
    style = classNames({
      cell: isFinalValue,
      'cell Cell-notFinal': !isFinalValue,
    });
  } else if (element === 'cell-p') {
    style = classNames({
      'cell-p': isFinalValue,
      'cell-p Cell-p-notFinal': !isFinalValue,
    });
  }
  return style;
};
