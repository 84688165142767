import { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';

import { selectDashboardApplicationsByRole } from 'src/reducers/userReducer/userReducer';
import {
  useGetFeatureFlagsQuery,
  useGetPaidPlanDetailsQuery,
} from 'src/services/dashboard-api';
import { PlanUsage } from 'src/services/subscription-plan/types';
import { Permission } from 'src/utils/enums';

const isPermissionEnabled = (
  permission: Permission,
  dashboardApplications: Partial<Record<Permission, string>>,
) => permission in dashboardApplications && dashboardApplications[permission];

export const useGetTabs = () => {
  const { data: paidPlansData, isLoading: isLoadingPaidPlanDetails } =
    useGetPaidPlanDetailsQuery();
  const { data: featureFlags, isLoading: isLoadingFeatureFlags } =
    useGetFeatureFlagsQuery();
  const { enablement_info } = paidPlansData || {};
  const dashboardApplications = useSelector(selectDashboardApplicationsByRole);

  const isSolveWidgetEnabled = isPermissionEnabled(
    Permission.WORKFLOW_BUILDER,
    dashboardApplications,
  );

  const isSolveEmailEnabled =
    featureFlags && featureFlags.feature_flags.includes('solve_email_enabled');

  const isSolveEnabled =
    isPermissionEnabled(Permission.FLAMETHROWER, dashboardApplications) &&
    (isSolveWidgetEnabled || isSolveEmailEnabled);

  const isAssistEnabled = enablement_info && enablement_info.assist;

  const isTriageEnabled = enablement_info && enablement_info.triage;

  return useMemo(() => {
    const tabs: string[] = [];

    if (
      dashboardApplications &&
      (isLoadingPaidPlanDetails || isLoadingFeatureFlags)
    ) {
      return tabs;
    }

    if (isSolveEnabled) {
      tabs.push('Solve');
    }

    if (isTriageEnabled) {
      tabs.push('Triage');
    }

    if (isAssistEnabled) {
      tabs.push('Assist');
    }

    return tabs;
  }, [
    isLoadingFeatureFlags,
    isSolveEnabled,
    isAssistEnabled,
    isTriageEnabled,
    isLoadingPaidPlanDetails,
    dashboardApplications,
  ]);
};

export const useFormatUsageData = (usage: PlanUsage[]) => {
  const { palette } = useTheme();
  const purpleColor = palette.colors.purple;

  return usage.map(({ month, projected, usage }) => {
    // Parse the month string into a moment object
    const parsedMonth = moment(month, 'MMMM YYYY');

    // Get the current month
    const currentMonth = moment();
    // Check if the parsed month is before, after, or the same as the current month
    const isBeforeCurrentMonth = parsedMonth.isBefore(currentMonth, 'month');
    const isSameAsCurrentMonth = parsedMonth.isSame(currentMonth, 'month');
    const isAfterCurrentMonth = !isBeforeCurrentMonth && !isSameAsCurrentMonth;

    return {
      color: isAfterCurrentMonth ? purpleColor[300] : purpleColor[500],
      y: isAfterCurrentMonth ? projected : usage,
    };
  });
};
