import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import closeIcon from 'src/assets/images/close-icon.svg';
import VersionCard from 'src/pages/workflow-builder-edit/version-history/VersionCard';
import { selectCurrentWorkflowVersions } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';

type VersionHistoryProps = {
  resetCanvasModes: () => void;
  setShouldShowVersionHistory: React.Dispatch<React.SetStateAction<boolean>>;
};

const VersionHistory: React.FC<
  React.PropsWithChildren<VersionHistoryProps>
> = ({ resetCanvasModes, setShouldShowVersionHistory }) => {
  const versions = useSelector(selectCurrentWorkflowVersions);

  return (
    <Container>
      <CloseButton onClick={() => setShouldShowVersionHistory(false)}>
        <img alt='close icon' src={closeIcon} />
      </CloseButton>
      <h2>Version history</h2>
      <Versions>
        {versions.map(versionData => (
          <VersionCard
            key={versionData.version}
            resetCanvasModes={resetCanvasModes}
            versionData={versionData}
          />
        ))}
      </Versions>
    </Container>
  );
};

export default VersionHistory;

const Container = styled('div')`
  position: fixed;
  right: 0;
  padding: 24px;
  width: 300px;
  height: calc(100vh - 64px);
  transform: translateY(-20px);
  background: ${theme.palette.colors.white};
  border-left: 1px solid ${theme.palette.colors.slate[200]};
  z-index: 9;
  overflow-y: scroll;

  h2 {
    font-size: 20px;
    margin: 0;
  }
`;

const CloseButton = styled('button')`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  line-height: 1;
  padding: 4px;

  &:hover {
    border-radius: 4px;
    background: ${theme.palette.colors.grey[100]};
  }
`;

const Versions = styled('div')`
  padding-top: 16px;
  border-top: 1px solid ${theme.palette.colors.slate[200]};
  margin-top: 20px;
`;
