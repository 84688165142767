import { styled } from '@mui/material';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

const SelectButton = ({
  index,
  onClick,
  selectedIndex,
  text,
}: {
  index: number;
  onClick: () => void;
  selectedIndex: number;
  text: string;
}) => {
  const isSelected = index === selectedIndex;

  return (
    <StyledButton
      aria-checked={isSelected}
      isSelected={isSelected}
      onClick={onClick}
      role='switch'
      type='button'
    >
      <Typography
        color={
          isSelected
            ? theme.palette.colors.purple[500]
            : theme.palette.colors.grey[700]
        }
        variant='font14Bold'
      >
        {text}
      </Typography>
    </StyledButton>
  );
};

const StyledButton = styled('button')<{ isSelected: boolean }>`
  cursor: pointer;
  background-color: ${props =>
    props.isSelected
      ? theme.palette.colors.slate[100]
      : theme.palette.colors.white};
  padding: 6px 12px;
  border: 1px solid ${theme.palette.colors.slate[200]};
  flex: 1 1 0px;
  font-size: 14px;
  line-height: 20px;

  :last-child {
    border-radius: 0 4px 4px 0;
  }

  :first-child {
    border-radius: 4px 0 0 4px;
  }

  :hover {
    border-color: ${theme.palette.colors.purple[500]};
    background-color: ${theme.palette.colors.purple[100]} !important;
  }
`;

export default SelectButton;
