import 'jsoneditor/dist/jsoneditor.css';
import './styles/ResponseView.scss';

import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import JSONEditor, { EditableNode } from 'jsoneditor';
import { useDispatch } from 'react-redux';
import toJsonSchema from 'to-json-schema';
import { Tooltip } from '@mui/material';

import get from 'lodash/fp/get';
import isBoolean from 'lodash/fp/isBoolean';
import isNumber from 'lodash/fp/isNumber';
import isString from 'lodash/fp/isString';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { OutputValues } from 'src/types/actionBuilderApiTypes';

interface JsonViewerProps {
  disabled?: boolean;
  isActive?: boolean;
  isSearchBarVisible: boolean;
  /** Json object to be displayed. Using type unknown as this could be any type */
  jsonData: unknown;
  /** Callback that fires as new param is added */
  onAddNewParam: (outputValues: OutputValues) => void;
  theme?: 'dark' | 'light';
}

export const JsonViewer: FC<React.PropsWithChildren<JsonViewerProps>> = ({
  disabled = false,
  isActive,
  isSearchBarVisible,
  jsonData,
  onAddNewParam,
  theme = 'light',
}) => {
  // Using `any` here since the ref is expecting one type and the constructor a different type I couldn't get it to work without casting. Lmk if you prefer casting.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const jsonEditorRef = useRef<any>();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data } = useGetFeatureFlagsQuery();
  const isDynamicListCVEnabled = data?.feature_flags.includes(
    'dynamic_list_context_variable',
  );

  useEffect(() => {
    // Cleans json path to value
    const cleanPath = (path: string | number) => {
      if (typeof path === 'number') {
        return path;
      }
      const found = path.includes('.');
      // If path contains dot escapte it with backwards slash as agreed with backend team.
      if (found) {
        return path.split('.').join('\\.');
      } else {
        return path;
      }
    };
    // Feed this into _.get, so that it doesn't get confused with keys containing periods.
    const getPathsArr = (node: EditableNode) =>
      node.path.map(value => String(value));

    // This path, with escaping, is customized for our backend.
    const getPath = (node: EditableNode) => {
      if (node.path.length === 0) {
        return '';
      }

      const pathSegments = node.path.map(value =>
        typeof value === 'number' ? `[${value}]` : String(cleanPath(value)),
      );
      return pathSegments.join('.');
    };

    const newEditor: JSONEditor = new JSONEditor(jsonEditorRef.current, {
      colorPicker: false,
      enableSort: false,
      enableTransform: false,
      history: false,
      mainMenuBar: !disabled,
      mode: 'view',
      navigationBar: !disabled && theme === 'light',
      onChange: () => newEditor.get,
      onEditable: () => false,
      onEvent: function (node, event) {
        if (isActive || disabled) {
          return;
        }
        // Library typing for the event type is wrong, it lists event type as string but it could be a mouseEvent/change event and others.
        const e = event as unknown as MouseEvent;
        // Path for node
        const pathsArr = getPathsArr(node);
        const pathData = pathsArr.length ? get(pathsArr, jsonData) : jsonData;
        // Element for popper and bounds.
        const element = e.target as HTMLElement;
        const elementClassName = element.getAttribute('class');
        // Validation for allowed types
        const isPrimitive =
          isBoolean(pathData) ||
          isString(pathData) ||
          isNumber(pathData) ||
          pathData === null;
        const isList = Array.isArray(pathData);
        const isValidValue = isPrimitive || isList;
        if (elementClassName === 'jsoneditor-field' && isValidValue) {
          if (isList) {
            if (!isDynamicListCVEnabled) {
              return;
            }
          }
          setAnchorEl(element);
        } else {
          setAnchorEl(null);
        }
        if (isValidValue && e.type === 'click') {
          // Logic to create path for nested JSON
          const key = getPath(node);
          if (isList) {
            if (!isDynamicListCVEnabled) {
              return;
            }
            // use the first index of the list, this performs better
            const jsonSchema = toJsonSchema([pathData[0]]);
            onAddNewParam({
              jsonSchema,
              key,
              newContextVariableName: '',
              value: '',
            });
            return;
          }

          // api action will set the entire output as the cv value
          // when the key is 'n/a'
          onAddNewParam({ key: key || 'n/a', value: '' });
        }
      },
      search: isSearchBarVisible,
    });
    newEditor.set(jsonData);
    if (JSON.stringify(jsonData)?.length < 50000) {
      newEditor.expandAll();
    }

    // Add placeholder to search box. Ufortunately there isn't an option to pass it to the library.
    const jsonEditor = document.querySelector('.ResponseView');
    if (jsonEditor) {
      const searchBox = jsonEditor.getElementsByTagName('input')?.[0];
      if (searchBox) {
        searchBox.placeholder = 'Search';
      }
    }

    return () => {
      newEditor.destroy();
      setAnchorEl(null);
    };
  }, [
    jsonData,
    dispatch,
    onAddNewParam,
    isActive,
    isSearchBarVisible,
    disabled,
    theme,
    isDynamicListCVEnabled,
  ]);

  useEffect(() => {
    const jsonEditor = document.getElementById('jsoneditor');
    const handleTooltipVisibility = (e: Event) => {
      // mouseover returns an element as the target.
      const element = e.target as HTMLElement;
      const elementClassName = element.getAttribute('class');

      // The mouse over event listener on `onEvent` only recieves events for the nodes of the tree. This one will take care of hiding it when the user moves the mouse away from the nodes.
      if (elementClassName !== 'jsoneditor-field') {
        setAnchorEl(null);
      }
    };
    jsonEditor?.addEventListener('mouseover', handleTooltipVisibility);
    return () => {
      jsonEditor?.removeEventListener('mouseover', handleTooltipVisibility);
    };
  }, []);

  return (
    <>
      <Tooltip
        arrow
        disableInteractive
        placement='top'
        PopperProps={{
          anchorEl,
          open: Boolean(anchorEl),
        }}
        title='Click to add as Output Parameter'
      >
        <div
          className={classNames('ResponseView', theme, {
            disabled,
            'is-advanced-filter-enabled': !isSearchBarVisible,
          })}
          id='jsoneditor'
          ref={jsonEditorRef}
        />
      </Tooltip>
    </>
  );
};
