import { useState } from 'react';
import { Box, Menu, useTheme } from '@mui/material';
import { IconTag } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';

const MAX_TAGS_SHOWN = 3;

interface TagListProps {
  tagList: string[];
}

const TagList = ({ tagList }: TagListProps) => {
  const { palette } = useTheme();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const isMoreTagOpened = Boolean(anchorElement);

  const shouldShoreMoreTag = tagList.length > MAX_TAGS_SHOWN;

  return (
    <Box
      alignItems='center'
      borderRadius='4px'
      display='flex'
      flex={1}
      gap='4px'
    >
      {tagList.slice(0, MAX_TAGS_SHOWN).map(tag => (
        <Box
          key={tag}
          sx={{
            backgroundColor: palette.colors.slate[100],
            display: 'flex',
            padding: '4px 8px',
          }}
        >
          <Box alignItems='center' display='flex' mr={1}>
            <IconTag color={palette.colors.blue[500]} size={16} />
          </Box>
          <Typography
            color={palette.colors.grey[800]}
            noWrap
            variant='font12Medium'
          >
            {tag}
          </Typography>
        </Box>
      ))}
      {shouldShoreMoreTag && (
        <>
          <Box
            alignItems='center'
            bgcolor={palette.colors.slate[100]}
            borderRadius='4px'
            display='flex'
            onClick={e => setAnchorElement(e.currentTarget)}
            p='4px 8px'
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography
              color={palette.colors.grey[700]}
              noWrap
              variant='font12Medium'
            >
              + {tagList.length - MAX_TAGS_SHOWN} more
            </Typography>
          </Box>
          <Menu
            anchorEl={anchorElement}
            onClose={() => setAnchorElement(null)}
            open={isMoreTagOpened}
            sx={{
              '& .MuiMenu-list': {
                border: '1px solid' + palette.colors.grey[300],
                borderRadius: '4px',
                padding: '8px',
              },
            }}
          >
            <Typography color={palette.colors.grey[500]} variant='font14'>
              Tags
            </Typography>
            {tagList.map(tag => (
              <Box display='flex' key={tag} padding={1}>
                <Box alignItems='center' display='flex' mr={1}>
                  <IconTag color={palette.colors.blue[500]} size={16} />
                </Box>
                <Typography
                  color={palette.colors.grey[800]}
                  noWrap
                  variant='font12Medium'
                >
                  {tag}
                </Typography>
              </Box>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default TagList;
