import { Box, css, styled } from '@mui/material';

import {
  Colors,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DynamicArticleLogo from 'src/assets/images/dynamic_article_logo.svg';
import { ArticleContainer } from 'src/pages/workflow-builder-edit/DocumentPreview';

interface DynamicArticleCardsProps {
  isCanvasVariant?: boolean;
  message: string;
}

const DynamicArticleCard = ({
  isCanvasVariant,
  message,
}: DynamicArticleCardsProps) => {
  return (
    <Wrapper>
      <Container isCanvasVariant={isCanvasVariant}>
        <Box display='flex' whiteSpace='nowrap'>
          <Logo src={DynamicArticleLogo} />
          <Typography variant='font16Bold'>
            Dynamic article suggestions
          </Typography>
        </Box>
        <Typography color={Colors.ui.text.tertiary} variant='font12'>
          {message}
        </Typography>
      </Container>
    </Wrapper>
  );
};

export default DynamicArticleCard;

const Logo = styled('img')`
  padding-right: 5px;
`;

const Container = styled(ArticleContainer)<{
  isCanvasVariant?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: ${({ isCanvasVariant }) =>
    isCanvasVariant ? 'center' : 'start'};
  height: 98px;
  border: 1px solid transparent;
  border-radius: 7px;
  width: 100%;
  ${props =>
    props.isCanvasVariant &&
    css`
      width: 100%;
      padding: 20px 24px;
    `}
  &:hover:not(:disabled) {
    border: 1px solid transparent;
    ${props => props.isCanvasVariant && 'box-shadow:none;'}
  }
`;

export const Wrapper = styled('div')`
  width: 100%;
  background: linear-gradient(
      270deg,
      ${Colors.brand.magenta} 0%,
      ${Colors.brand.deepBlue} 100%
    ),
    linear-gradient(
      0deg,
      ${theme.palette.colors.white},
      ${theme.palette.colors.white}
    );
  border-radius: 8px;
  padding: 1px;
`;
