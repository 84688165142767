import React, { useRef } from 'react';

import { Button } from '@forethought-technologies/forethought-elements';

interface FileUploadButtonProps {
  accept: string;
  disabled?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  title: string;
}

export default function FileUploadButton({
  accept,
  disabled = false,
  onChange,
  title,
}: FileUploadButtonProps) {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => {
          if (ref.current) {
            ref.current.value = '';
            ref.current.click();
          }
        }}
        size='large'
        variant='main'
      >
        {title}
      </Button>
      <input accept={accept} hidden onChange={onChange} ref={ref} type='file' />
    </>
  );
}
