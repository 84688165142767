import React, { Dispatch, FC, SetStateAction } from 'react';

import NameEmailQuestion from '../../name-email-question/NameEmailQuestion';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown/ContextVariableSelectDropdown';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  SectionContainerFlush,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { FieldNameToIdx } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import {
  formatContextVariableForDisplay,
  formatContextVariableForPersistence,
} from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import TagInputWithContextVariables from 'src/pages/workflow-builder-edit/tag-input-with-context-variables/TagInputWithContextVariables';
import {
  ZendeskChatHandoffCustomization,
  ZendeskOrSalesforceContextVariables,
} from 'src/types/workflowBuilderAPITypes';

interface ChatOnlyHandoffProps {
  customizationData: ZendeskChatHandoffCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<
    SetStateAction<ZendeskChatHandoffCustomization>
  >;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
  shouldHideNameEmailQuestion?: boolean;
}

const ChatOnlyHandoff: FC<React.PropsWithChildren<ChatOnlyHandoffProps>> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  setCustomizationData,
  setIsFieldTouchedArr,
  shouldHideNameEmailQuestion = false,
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const handleBlurOnTicketTags = () => {
    const arrCopy = [...isFieldTouchedArr];
    arrCopy[FieldNameToIdx.CHAT_TAGS] = true;
    setIsFieldTouchedArr(arrCopy);
  };
  const optionsList = customizationData?.available_zd_chat_departments?.map(
    department => {
      return {
        category: 'Departments',
        label: department.name,
        value: department.id,
      };
    },
  );

  const handleTagDeletion = (idx: number) => {
    setCustomizationData(handoffData => {
      const chatTags = handoffData?.chat_tags || [];
      return {
        ...handoffData,
        chat_tags: chatTags
          .slice(0, idx)
          .concat(chatTags.slice(idx + 1, chatTags.length)),
      };
    });
  };

  const handleTagAddition = (tag: string) => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        chat_tags: [
          ...(handoffData?.chat_tags || []),
          formatContextVariableForPersistence(contextVariables, tag),
        ],
      };
    });
  };

  const tags = (customizationData?.chat_tags || []).map(tag =>
    formatContextVariableForDisplay(contextVariables, tag),
  );

  const getDepartmentNameFromID = (id: string): string => {
    for (const department of optionsList) {
      if (department.value === id) {
        return department.label;
      }
    }

    return id;
  };

  return (
    <SectionContainerFlush>
      {!shouldHideNameEmailQuestion && (
        <>
          <NameEmailQuestion
            customizationData={customizationData}
            errorMessages={errorMessages}
            isFieldTouchedArr={isFieldTouchedArr}
            setCustomizationData={
              setCustomizationData as Dispatch<
                SetStateAction<ZendeskOrSalesforceContextVariables>
              >
            }
            setIsFieldTouchedArr={setIsFieldTouchedArr}
          />
          <Spacer height='28px' />
        </>
      )}

      <ContextVariableSelectDropdown
        additionalOptions={optionsList}
        id='zendesk-select-department'
        isClearable
        label='Chat Department'
        onChange={value =>
          setCustomizationData({
            ...customizationData,
            zd_chat_department: {
              id: value,
              name: getDepartmentNameFromID(value),
            },
          })
        }
        shouldIncludeSystemContextVariables
        shouldProvideCVIdFormatting
        value={customizationData?.zd_chat_department?.id || ''}
      />
      <Spacer height='28px' />
      <TagInputWithContextVariables
        contextVariables={contextVariables}
        errorMessages={errorMessages}
        errorTag={FieldNameToIdx.CHAT_TAGS}
        handleBlur={handleBlurOnTicketTags}
        handleTagAddition={handleTagAddition}
        handleTagDeletion={handleTagDeletion}
        label='Chat Tags'
        tags={tags}
      />
    </SectionContainerFlush>
  );
};

export default ChatOnlyHandoff;
