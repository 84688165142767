import { styled } from '@mui/material/styles';

export const UnstyledButton = styled('button')`
  margin: 0;
  cursor: pointer;
  display: block;
`;

export const UnstyledUnorderedList = styled('ul')`
  margin: 0;
  padding: 0;
  list-style: none;

  & .subMenu {
    font-size: 14px;
  }

  & .subMenu a {
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;
