import { Box } from '@mui/material';

import { LaunchButtonWithProactivePrompt } from '../launch-button/LaunchButtonWithProactivePrompt';

const PromptWidgetContent = () => {
  return (
    <Box display='flex' flexDirection='column' height='100%' paddingTop='30px'>
      <Box display='flex' flex={1} flexDirection='column' justifyContent='end'>
        <LaunchButtonWithProactivePrompt />
      </Box>
    </Box>
  );
};

export default PromptWidgetContent;
