import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import {
  Button,
  Dialog,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  selectIntegrationSelectedState,
  selectIsFocusedTriageModelPostLoading,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';

interface TriageConfigDetailSettingAlertProps {
  dialogSaveText: string;
  handleOnSaveToApi: () => void;
  isSaveModalOpen: boolean;
  setIsSaveModalOpen: (flag: boolean) => void;
}

const TriageConfigDetailSettingAlert = (
  props: TriageConfigDetailSettingAlertProps,
) => {
  const {
    dialogSaveText,
    handleOnSaveToApi,
    isSaveModalOpen,
    setIsSaveModalOpen,
  } = props;

  const integrationSelectedState = useSelector(selectIntegrationSelectedState);
  const isFocusedModalLoading = useSelector(
    selectIsFocusedTriageModelPostLoading,
  );

  const isIntegrationSelectedLoading = integrationSelectedState === 'loading';
  const isLoading = isFocusedModalLoading || isIntegrationSelectedLoading;

  return (
    <>
      {/* Save Dialog */}
      <Dialog
        onClose={() => setIsSaveModalOpen(false)}
        open={isSaveModalOpen}
        title='Overwrite existing field?'
      >
        <DialogContainer>
          <Typography color={theme.palette.colors.black} variant='font14'>
            {dialogSaveText}
          </Typography>
          <DialogButtonContainer>
            <Button
              disabled={isLoading}
              onClick={() => setIsSaveModalOpen(false)}
              variant='ghost'
            >
              Cancel
            </Button>
            <Button
              aria-label='save-dialog'
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleOnSaveToApi}
              variant='danger'
            >
              Confirm and save
            </Button>
          </DialogButtonContainer>
        </DialogContainer>
      </Dialog>
    </>
  );
};

const DialogContainer = styled('div')`
  width: 500px;
  white-space: pre-line;
`;

const DialogButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 40px;
  gap: 5px;
`;

export default TriageConfigDetailSettingAlert;
