import { styled } from '@mui/material/styles';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface AddSectionButtonProps {
  disabled?: boolean;
  iconSrc: string;
  onClick: () => void;
  text: string;
}

const AddSectionButton = ({
  disabled = false,
  iconSrc,
  onClick,
  text,
}: AddSectionButtonProps) => (
  <Wrapper disabled={disabled} onClick={onClick} type='button'>
    <img src={iconSrc} />
    <Typography color={theme.palette.colors.grey[500]} variant='font14'>
      {text}
    </Typography>
  </Wrapper>
);

const Wrapper = styled('button')`
  width: 100%;
  align-items: center;
  background-color: ${theme.palette.colors.white};
  border: 1px dashed ${theme.palette.colors.slate[300]};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 13px 30px;
  z-index: 2;
  margin-top: 32px;

  &:disabled {
    cursor: not-allowed;
  }
`;

export default AddSectionButton;
