import { useField } from 'formik';

import { useGetActionUsagesQuery } from 'src/services/action-builder/actionBuilderApi';
import { useGetIntentsQuery } from 'src/services/workflow-builder-metrics';

export const useIsActionActive = (actionId = '') => {
  const { data } = useGetActionUsagesQuery();

  if (actionId.endsWith('template')) {
    return true;
  }

  return (data?.action_id_to_intent_ids[actionId] ?? []).length > 0;
};

export const useGetActionBuilderDetailFormIsActive = () => {
  const [{ value: actionId }] = useField<string | undefined>({
    name: 'actionId',
  });

  return useIsActionActive(actionId);
};

export const useGetIntentIdUsages = (actionId = '') => {
  const { data: intentsData, isLoading: isLoadingIntentsData } =
    useGetIntentsQuery();
  const { data: usagesData, isLoading: isLoadingUsages } =
    useGetActionUsagesQuery();

  const allActionUsages = usagesData?.action_id_to_intent_ids[actionId] ?? [];

  return {
    isLoading: isLoadingIntentsData || isLoadingUsages,
    usages: allActionUsages.filter(entityId =>
      intentsData?.intents.some(
        intent => intent.intent_definition_id === entityId,
      ),
    ),
  };
};

const reverseActionUsageWithLists = (
  actionUsageDict: Record<string, string[]>,
): Record<string, string[]> => {
  const entityToActionDict: Record<string, string[]> = {};

  for (const actionId in actionUsageDict) {
    const entityIds = actionUsageDict[actionId];

    for (const entityId of entityIds) {
      if (!entityToActionDict[entityId]) {
        entityToActionDict[entityId] = [];
      }
      if (!entityToActionDict[entityId].includes(actionId)) {
        // Avoid duplicates
        entityToActionDict[entityId].push(actionId);
      }
    }
  }

  return entityToActionDict;
};

export const useGetActionUsagesByEntityId = () => {
  const { data: usagesData, isLoading } = useGetActionUsagesQuery();

  return {
    isLoading: isLoading,
    usages: reverseActionUsageWithLists(
      usagesData?.action_id_to_intent_ids ?? {},
    ),
  };
};
