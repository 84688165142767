import './MagicLink.scss';

import React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';

import magicLink from 'src/assets/images/magic-link.png';
import LandingPageLayout from 'src/components/reusable-components/landing-page-layout';

const MagicLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { hash } = useLocation();
  const { username } = queryString.parse(hash);

  return (
    <LandingPageLayout>
      <img alt='email' className='MagicLink-img' src={magicLink} />
      <h2 className='MagicLink-title'>Check your email</h2>
      <p className='MagicLink-subtitle'>
        We’ve sent a magic link to{' '}
        {username && (
          <>
            <b>{username}</b> to{' '}
          </>
        )}
        verify your work email. Click on the link to finish signing up.
      </p>
    </LandingPageLayout>
  );
};

export default MagicLink;
