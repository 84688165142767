import { Auth } from 'aws-amplify';

const COGNITO_NETWORK_ERROR_FIX_ENABLED = 'cognitoNetworkErrorFixEnabled';

export function setCognitoNetworkErrorFixEnabled(enabled: boolean) {
  window.sessionStorage.setItem(
    COGNITO_NETWORK_ERROR_FIX_ENABLED,
    JSON.stringify(enabled),
  );
}

function getCognitoNetworkErrorFixEnabled() {
  const value = window.sessionStorage.getItem(
    COGNITO_NETWORK_ERROR_FIX_ENABLED,
  );

  if (!value) {
    return false;
  }

  try {
    return JSON.parse(value) === true;
  } catch {
    return false;
  }
}

function waitForOnline() {
  return new Promise<void>(resolve => {
    if (navigator.onLine) {
      resolve();
    } else {
      const handleOnline = () => {
        window.removeEventListener('online', handleOnline);
        resolve();
      };

      window.addEventListener('online', handleOnline);
    }
  });
}

/**
 * Get the Cognito session for the current user.
 * Check for network connectivity before making the request because if the request fails due to network issues, the user will be logged out.
 */
export async function getCognitoSession() {
  if (getCognitoNetworkErrorFixEnabled()) {
    await waitForOnline();
  }
  return Auth.currentSession();
}

export async function getCognitoToken() {
  const session = await getCognitoSession();
  return session.getIdToken().getJwtToken();
}
