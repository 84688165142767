import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextInput } from 'src/components/reusable-components/text-input/TextInput';
import { validateAssistEmail } from 'src/utils/assistAuthUtils';
import { Routes } from 'src/utils/enums';

const ValidateEmailForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const { data, status } = await validateAssistEmail(email);

      let agentLoginURL = data.login_url;

      /* If org exists and doesn't use single sign on, redirect to magic link */
      if (status === 201 && !agentLoginURL.endsWith('single-sign-on')) {
        agentLoginURL += Routes.MAGIC_LINK;
      }

      window.location.assign(
        `${agentLoginURL}#extensionAuth=1&username=${encodeURIComponent(
          email,
        )}`,
      );
    } catch (error: any) {
      if (error.response.status === 404) {
        navigate(Routes.ORG_NOT_FOUND);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div className='AssistLogin-mainTitle'>Get Started</div>
      <p className='AssistLogin-subTitle'>
        Assist Anywhere is an AI tool that pulls all of your organization’s
        knowledge resources into one.
      </p>
      <p className='AssistLogin-prompt'>
        Enter your work email to join your team.
      </p>
      <form onSubmit={handleSubmit}>
        <div className='AssistLogin--input-wrapper'>
          <TextInput
            containerClassName='AssistLogin--text-input'
            isRequired={false}
            key='email'
            name='email'
            onChange={e => setEmail(e.currentTarget.value)}
            placeholder='Email address'
            type='email'
            value={email}
          />
          <button
            className='AssistLogin--button'
            disabled={!email.length || submitting}
            type='submit'
          >
            Continue
          </button>
        </div>
      </form>
    </>
  );
};

export default ValidateEmailForm;
