import { Appcues, Loader as AppcuesLoader } from 'appcues-loader';

import { getOrgIdAndOrgCode } from 'src/services/api';
import { getCurrentUser } from 'src/services/apiV1';
import store from 'src/store/store';
import { UserRoles } from 'src/utils/enums';
import { Routes } from 'src/utils/enums';

// appcues-loader types are outdated, declare missing types manually:
declare module 'appcues-loader' {
  interface Appcues {
    group: (
      groupId: string,
      groupProperties: Record<string, string | boolean | number>,
    ) => void;
  }
}

export const isRouteValid = (path: string, validPaths: Routes[]) => {
  const pathArray = path.split('/');
  if (pathArray.length > 2) {
    // check nested routes
    // if the length of the `pathArray` is greater than 2
    // this is a nested route and we should attempt to check for
    // dynamic routing eg: `/triage/:name`
    const lastPathName = pathArray[pathArray.length - 1];
    for (const validPath of validPaths) {
      const validPathArray = validPath.split(':');
      if (validPathArray.length > 1) {
        if (validPathArray[0] + lastPathName === path) {
          return true;
        }
      }
    }
  }

  return validPaths.includes(path as Routes);
};

export const loadAppCues = async () => {
  const appcuesAccountId = '115031';
  const loader = new AppcuesLoader(appcuesAccountId);

  const userState = store.getState().user;
  const userRole = userState.role;
  const cognitoUser = userState.user?.user;

  if (cognitoUser) {
    let createdAt = 0;
    let companyName: string;

    const { account_id: accountId, organization_id: orgName } =
      await getOrgIdAndOrgCode();

    /**
     * ROLE_AGENT doesn't have access to user handler apis,
     * so for now we need to get the org name from the cognito user
     */
    if (userRole === UserRoles.ROLE_AGENT) {
      companyName = orgName;
    } else {
      const currentUserData = await getCurrentUser();
      companyName = currentUserData.organization.display_name ?? '';
      createdAt = Math.round(currentUserData.current_user.created_date ?? 0);
    }

    const userEmail = cognitoUser.email;
    const userId = cognitoUser['cognito:username'];

    loader.load().then((appcues: Appcues) => {
      appcues.identify(userId, {
        companyName,
        createdAt,
        email: userEmail,
        role: userRole,
      });

      const groupId =
        userRole === UserRoles.ROLE_SUPER_ADMIN ? 'super-admins' : accountId;

      appcues.group(groupId, {
        accountId,
        companyName,
        orgName,
      });
    });
  }
};
