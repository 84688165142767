import { useSelector } from 'react-redux';

import { Button, Toast } from '@forethought-technologies/forethought-elements';
import { patchTopic, postReportedTopic } from 'src/actions/discover/discover';
import {
  selectGlobalDiscoverOptions,
  setGlobalDiscoverOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';

const DiscoverToast = () => {
  const dispatch = useAppDispatch();
  const { patchedField, patchedTopic, reportedTicket } = useSelector(
    selectGlobalDiscoverOptions,
  );

  const isReportingTicket = Boolean(reportedTicket);
  const isPatchingTopic = Boolean(patchedTopic) && patchedField === 'is_hidden';

  const open = isReportingTicket || isPatchingTopic;

  const deriveTitleAndCallbacks = () => {
    if (patchedTopic) {
      return {
        onClickButton: () =>
          dispatch(
            patchTopic({
              body: {
                ...patchedTopic,
                is_hidden: !patchedTopic?.is_hidden,
              },
              topicId: patchedTopic?.topic_id,
            }),
          ),
        onClose: () =>
          dispatch(
            setGlobalDiscoverOptions({
              patchedField: null,
              patchedTopic: null,
            }),
          ),
        title: `'${deriveTopicNameFromTopic(patchedTopic)}' was ${
          patchedTopic.is_hidden ? 'hidden' : 'unhidden'
        }`,
      };
    }

    if (reportedTicket) {
      return {
        onClickButton: () =>
          dispatch(
            setGlobalDiscoverOptions({
              reportedTicket: null,
            }),
          ),
        onClose: async () => {
          await dispatch(
            postReportedTopic({
              body: {
                flag_reasons: reportedTicket.reasons,
                notes: reportedTicket.notes || undefined,
                ticket_id: reportedTicket.id,
              },
              topicId: reportedTicket.topicId,
            }),
          );

          dispatch(
            setGlobalDiscoverOptions({
              reportedTicket: null,
            }),
          );
        },
        title: `'${reportedTicket.title}'  was reported and removed`,
      };
    }

    return {
      onClose: () => console.error('onClose called in empty state'),
      title: '',
    };
  };

  const { onClickButton, onClose, title } = deriveTitleAndCallbacks();

  return (
    <Toast
      actionComponent={
        <Button onClick={onClickButton} variant='ghost'>
          Undo
        </Button>
      }
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoHideDuration={3000}
      onClose={onClose}
      open={open}
      showXIcon={false}
      title={title}
      variant='main'
      width='500px'
    />
  );
};

export default DiscoverToast;
