import { styled } from '@mui/material/styles';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  margin-bottom: 24px;
  background-color: ${theme.palette.colors.white};
  border-radius: 4px;
  box-shadow: ${theme.shadows[1]};
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
`;

const Icon = styled('img')`
  margin-right: 10px;
  max-width: 72px;
`;

const TitleWrapper = styled('div')`
  margin-bottom: 5px;
`;

interface CardProps {
  description: string;
  iconSrc?: string;
  title: string;
}

export default function Card({ description, iconSrc, title }: CardProps) {
  return (
    <Wrapper>
      {iconSrc && <Icon src={iconSrc} />}
      <Content>
        <TitleWrapper>
          <Typography variant='font14'>{title}</Typography>
        </TitleWrapper>
        <Typography color={theme.palette.colors.grey[500]} variant='font12'>
          {description}
        </Typography>
      </Content>
    </Wrapper>
  );
}
