import { Box, useMediaQuery } from '@mui/material';

import RecommendationCard from './RecommendationCard';
import RecommendationCardPostAutomate from './RecommendationCardPostAutomate';
import {
  DiscoverAutomatedObject,
  DiscoverTopic,
} from 'src/reducers/discoverReducer/types';

interface RecommendationSectionProps {
  automatedObject?: DiscoverAutomatedObject | null;
  isLargerScreen: boolean;
  isLoading: boolean;
  topic?: DiscoverTopic | null;
  trackingEventMetadata: Record<string, string>;
}

const RecommendationSection = ({
  automatedObject,
  isLargerScreen,
  isLoading,
  topic,
  trackingEventMetadata,
}: RecommendationSectionProps) => {
  const { is_automated: isAutomated, is_recommended: isRecommended } =
    topic ?? {};
  const customBannerWidthTransformFlag = useMediaQuery('(min-width:1300px)');
  const width = customBannerWidthTransformFlag ? 360 : 300;

  if (isLoading) {
    return (
      <Box
        mb={isLargerScreen ? undefined : 3}
        ml={isLargerScreen ? 5 : 0}
        width={isLargerScreen ? `${width}px` : '100%'}
      />
    );
  }

  if ((!isAutomated && !isRecommended) || !topic) {
    return null;
  }

  return (
    <Box
      mb={isLargerScreen ? undefined : 3}
      ml={isLargerScreen ? 5 : 0}
      width={isLargerScreen ? `${width}px` : '100%'}
    >
      {isAutomated && automatedObject ? (
        <RecommendationCardPostAutomate
          automatedObject={automatedObject}
          topic={topic}
          trackingEventMetadata={trackingEventMetadata}
        />
      ) : (
        <RecommendationCard
          topic={topic}
          trackingEventMetadata={trackingEventMetadata}
        />
      )}
    </Box>
  );
};

export default RecommendationSection;
