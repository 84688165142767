export function getYouTubeEmbedURL(videoID: string) {
  return `https://www.youtube.com/embed/${videoID}`;
}

export function getVimeoEmbedURL(videoID: string) {
  return `https://player.vimeo.com/video/${videoID}`;
}

export function cleanURL(url: string) {
  try {
    const urlObject = new URL(url);

    if (urlObject.host.includes('youtube.com')) {
      const videoID = urlObject.searchParams.get('v');
      if (videoID) {
        return getYouTubeEmbedURL(videoID);
      }
    } else if (urlObject.host.includes('youtu.be')) {
      const videoID = urlObject.pathname.split('/')[1];
      if (videoID) {
        return getYouTubeEmbedURL(videoID);
      }
    } else if (urlObject.host === 'vimeo.com') {
      const videoID = urlObject.pathname.split('/')[1];
      if (videoID) {
        return getVimeoEmbedURL(videoID);
      }
    }

    return urlObject.href;
  } catch {
    return '';
  }
}
