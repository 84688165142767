import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material';

import {
  elevations,
  VerticalTabBar,
} from '@forethought-technologies/forethought-elements';
import { EMAIL_TABS } from './constants';

const EmailConfigurationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tabIndex = useMemo(() => {
    const initialTabIndex = EMAIL_TABS.findIndex(
      tab => tab.id === searchParams.get('tab'),
    );
    return Math.max(initialTabIndex, 0);
  }, [searchParams]);

  const handleTabClick = useCallback(
    (index: number) => {
      const tab = EMAIL_TABS[index].id;
      setSearchParams(prevParams => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('tab', tab);
        return newParams;
      });
    },
    [setSearchParams],
  );

  const isFullWidth = EMAIL_TABS[tabIndex]?.isFullWidth ?? false;

  return (
    <ToolbarContainer
      isFullScreen={isFullWidth}
      isTabSelected={typeof tabIndex === 'number'}
    >
      <VerticalTabBar
        setTabIndex={handleTabClick}
        tabBodyWidth={520}
        tabIndex={tabIndex}
        tabs={EMAIL_TABS}
      />
    </ToolbarContainer>
  );
};

export default EmailConfigurationPage;

const ToolbarContainer = styled('div')<{
  isFullScreen: boolean;
  isTabSelected: boolean;
}>`
  background-color: ${props => props.theme.palette.colors.white};
  box-shadow: ${elevations.z3};
  flex: ${props =>
    props.isTabSelected ? (props.isFullScreen ? 1 : '0 0 480px') : 0};
  position: sticky;
  top: 0;
  height: 100%;
`;
