import { Box } from '@mui/material';

import { useGetCvNameDict } from '../utils';
import { BlueText, PurpleText } from './TextComponents';
import { ApiStepPreviewLogProps } from 'src/slices/workflow-preview/types';

export const ApiStepPreviewLog = ({
  previewLog,
}: {
  previewLog: ApiStepPreviewLogProps;
}) => {
  const {
    action_name: actionName,
    input_params: inputParams,
    output_params: outputParams,
    url_used: urlUsed,
  } = previewLog;

  const inputCvNameDict = useGetCvNameDict(inputParams);
  const outputCvNameDict = useGetCvNameDict(outputParams);
  const inputCvNames = Object.keys(inputCvNameDict);
  const outputCvNames = Object.keys(outputCvNameDict);

  return (
    <Box display='flex' flexDirection='column'>
      <span>
        API connection / Actions: <BlueText>{actionName}</BlueText>
      </span>
      <span>
        Target URL: <BlueText>{urlUsed}</BlueText>
      </span>
      {inputCvNames.length > 0 && (
        <span>
          Input:{' '}
          {inputCvNames.map((name, index) => (
            <PurpleText key={name}>
              {index > 0 ? `, $${name}` : `$${name}`}
            </PurpleText>
          ))}
        </span>
      )}
      {outputCvNames.length > 0 && (
        <span>
          Output:{' '}
          {outputCvNames.map((name, index) => (
            <PurpleText key={name}>
              {index > 0 ? `, $${name}` : `$${name}`}
            </PurpleText>
          ))}
        </span>
      )}
      {inputCvNames.length + outputCvNames.length > 0 && (
        <>
          <Box height='24px' />
          <span>Context variable value:</span>
          {inputCvNames.map(name => (
            <span key={name}>
              {`$${name}: `}
              <BlueText>{JSON.stringify(inputCvNameDict[name])}</BlueText>
            </span>
          ))}
          {outputCvNames.map(name => (
            <span key={name}>
              {`$${name}: `}
              <BlueText>{JSON.stringify(outputCvNameDict[name])}</BlueText>
            </span>
          ))}
        </>
      )}
    </Box>
  );
};
