import InfoIcon from '@mui/icons-material/Info';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { ASSIST_AUTOMATIONS_TEXT } from 'src/utils/constants';

const InputArrayTooltip = () => {
  return (
    <Tooltip tooltipContent={ASSIST_AUTOMATIONS_TEXT.inputArrayTypeTooltip}>
      <InfoIcon fontSize='small' />
    </Tooltip>
  );
};

export default InputArrayTooltip;
