import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { permissionOptions } from '../permission-level-select/utils';
import groupBy from 'lodash/fp/groupBy';
import Spinner from 'src/components/spinner';
import { selectInvites, selectUsers } from 'src/slices/team/teamSlice';

function useUserMetrics() {
  const { data: inviteData } = useSelector(selectInvites);
  const { data: userData, error } = useSelector(selectUsers);

  return useMemo(() => {
    if (!userData || !inviteData) {
      return { error };
    }

    const allUsers = [...userData, ...inviteData];
    const usersByRole = groupBy('permission_level')(allUsers);

    const data = [
      { count: allUsers.length, title: 'Total accounts' },
      { count: inviteData.length, title: 'Pending invites' },
      {
        count: usersByRole['ADMIN']?.length || 0,
        title: 'Admins',
        tooltipContent: permissionOptions['ADMIN'].description,
      },
      {
        count: usersByRole['USER']?.length || 0,
        title: 'Users',
        tooltipContent: permissionOptions['USER'].description,
      },
      {
        count: usersByRole['AGENT']?.length || 0,
        title: 'Agents',
        tooltipContent: permissionOptions['AGENT'].description,
      },
    ];

    return {
      data,
    };
  }, [error, userData, inviteData]);
}

function Count({
  count,
  title,
  tooltipContent = '',
}: {
  count: number;
  title: string;
  tooltipContent?: string;
}) {
  return (
    <Box
      role='row'
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <Box role='cell'>
        <Typography variant='font24'>{count}</Typography>
      </Box>
      <Box
        role='rowheader'
        sx={{ alignItems: 'center', display: 'flex', gap: '2px' }}
      >
        <Typography variant='font16'>{title}</Typography>
        {tooltipContent && (
          <Tooltip tooltipContent={tooltipContent}>
            <IconInfoCircle height={16} width={16} />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

export default function UserMetrics() {
  const { data, error } = useUserMetrics();

  if (error) {
    return null; // error message handled by table component
  }

  if (!data) {
    return <Spinner height='116px' />;
  }

  return (
    <Box
      aria-label='Account totals'
      role='table'
      sx={{
        alignItems: 'start',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 3,
        justifyContent: 'space-around',
        py: 4,
        whiteSpace: 'nowrap',
      }}
    >
      {data.map(metric => (
        <Count {...metric} key={metric.title} />
      ))}
    </Box>
  );
}
