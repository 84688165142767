import Box from '@mui/material/Box';

import HighlightedText from './HighlightedText';
import notesSharedIcon from 'src/assets/images/notes-shared-icon.svg';
import { AssistNote } from 'src/slices/assist-notes/types';

interface TitleCellProps {
  data: AssistNote;
  searchText: string;
}

const TitleCell = ({ data, searchText }: TitleCellProps) => {
  const { is_public: isPublic, title } = data;

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      width='100%'
    >
      <Box alignItems='center' display='flex' minWidth='0'>
        <Box
          sx={{
            minWidth: 0,
            mr: '6px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <HighlightedText searchText={searchText} text={title} />
        </Box>
        {isPublic && <img src={notesSharedIcon} />}
      </Box>
    </Box>
  );
};

export default TitleCell;
