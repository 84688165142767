export const TABLE_COLUMNS = [
  {
    key: 'name',
    size: 300,
    title: 'Name',
  },
  {
    key: 'status',
    size: 200,
    title: 'Status',
  },
  {
    key: 'model_name',
    size: 300,
    title: 'Model',
  },
  {
    key: 'version_name',
    size: 300,
    title: 'Version',
  },
  {
    key: 'created_at',
    size: 200,
    title: 'Created at',
  },
];
