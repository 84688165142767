import { styled } from '@mui/material';

export const IconButtonWithActive = styled('button')<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  gap: 10px;
  padding: 6px;
  font-size: 13px;
  outline: none;
  border: none;
  text-align: left;
  cursor: pointer;

  & span[role='progressbar'] {
    height: 20px !important;
    width: 20px !important;
  }

  ${props =>
    props.isActive &&
    `background-color: ${props.theme.palette.colors.purple[100]};` +
      `svg {
        [fill] {
          fill: ${props.theme.palette.colors.purple[500]};
        }
        [stroke] {
          stroke: ${props.theme.palette.colors.purple[500]};
        }
      }`}

  &:hover {
    background: ${props => props.theme.palette.colors.purple[100]};
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;
