import React, { FC, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { updateActionSettings } from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { validationSchemaCatchAll } from 'src/pages/workflow-builder-edit/customization-panel/constants';
import { buildCatchAllInitialFormValue } from 'src/pages/workflow-builder-edit/customization-panel/helpers';
import CatchAllHandoffConfiguration from 'src/pages/workflow-builder-edit/handoff-configuration/catch-all';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import type { RootState } from 'src/store/rootReducer';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { CatchAllHandoffCustomization } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface CatchAllHandoffCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const CatchAllHandoffCustomizationPanel: FC<
  React.PropsWithChildren<CatchAllHandoffCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();

  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  // @ts-expect-error legacy code with untyped state
  const handoffCustomization: CatchAllHandoffCustomization = useSelector(
    (state: RootState) =>
      state.canvasWorkflowBuilder?.actionSettings.configuration_fields,
  );
  const [showLinkPreview, setShowLinkPreview] = useState(
    handoffCustomization?.show_link_preview || false,
  );
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  if (canvasState.isLoadingCustomization) {
    return <LoadingSkeleton />;
  }

  return (
    <Formik
      initialValues={buildCatchAllInitialFormValue(handoffCustomization)}
      onSubmit={(values, { resetForm }) => {
        dispatch(
          updateActionSettings({
            action_type: canvasState.actionSettings.action_type as string,
            configuration_fields: {
              ...values,
              show_link_preview: showLinkPreview,
            },
          }),
        );
        dispatchActionEditedTrackingEvent({
          actionType: ActionBuilderActionTypes.CATCH_ALL_HANDOFF,
        });
        resetForm({
          values,
        });
        setActionPanelVisibilityParameters('hidden');
        setAreActionSettingsUnsaved(false);
        dispatch(setCustomizableActionId(''));
        onDismiss();
      }}
      validationSchema={validationSchemaCatchAll}
    >
      {/* This translate prop is a dummy. Sidesteps a typescript error due to older version of @types/react */}
      {({
        dirty,
        errors,
        values,
      }: FormikProps<CatchAllHandoffCustomization>) => (
        <Form
          onChange={() => setAreActionSettingsUnsaved(dirty)}
          translate='yes'
        >
          <CatchAllHandoffConfiguration
            customizationData={values}
            linkPreviewConfig={{ setShowLinkPreview, showLinkPreview }}
          />
          <SaveContainer hasTopBorder>
            <SecondaryButton
              onClick={() => {
                onDiscard();
              }}
            >
              Cancel
            </SecondaryButton>
            <StyledButton
              disabled={
                Object.values(errors).filter(err => !!err).length > 0 ||
                !dirty ||
                hasWorkflowConflict
              }
              type='submit'
            >
              Save
            </StyledButton>
          </SaveContainer>
        </Form>
      )}
    </Formik>
  );
};

export default CatchAllHandoffCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
