import './SearchBar.scss';

import React, { useState } from 'react';

interface SearchBarProps {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

const SearchBar: React.FC<React.PropsWithChildren<SearchBarProps>> = props => {
  const { onChange } = props;
  const [search, setSearch] = useState<string>('');
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    onChange(e);
  };
  return (
    <div className='SearchBar-container'>
      <span className='SearchBar-searchIcon' />
      <input
        className='SearchBar-input'
        onChange={e => handleOnChange(e)}
        placeholder='Search'
        value={search}
      />
    </div>
  );
};

export default SearchBar;
