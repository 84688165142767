import { Box, useTheme } from '@mui/material';

import {
  formatToDateOnly,
  Tabs,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { AGENT_SEATS, PERIOD, tabIndexToLabel, TERM, USAGE } from './constants';
import { useFormatUsageData, useGetTabs } from './hooks';
import PriceColumnGraph from './PriceColumnGraph';
import { formatNumber, getCategories, getUsageData } from './utils';
import Spinner from 'src/components/spinner/Spinner';
import { useStateParams } from 'src/hooks/hooks';
import { useGetUsageDataQuery } from 'src/services/subscription-plan/subscriptionPageApi';
import { genericSerializeAndDeserialize } from 'src/utils/discover/helpers';

const a11yTabProps = (index: number) => {
  const a11yName = 'subscription-plan';
  return {
    'aria-controls': `${a11yName}-tabpanel-${index}`,
    id: `${a11yName}-tab-${index}`,
  };
};

const SubscriptionPlanPageBody = () => {
  const { palette } = useTheme();
  const [tab, setTab] = useStateParams<string>({
    deserialize: (param: string) => param,
    initialState: '0',
    paramsName: 'tab',
    serialize: genericSerializeAndDeserialize,
  });

  const { data, isLoading = true } = useGetUsageDataQuery();

  const tabs: string[] = useGetTabs();
  const currentTabLabel = tabs.length ? tabs[Number(tab)].toLowerCase() : '';

  const usageData = getUsageData(currentTabLabel, data).usage || [];
  const allocatedValue =
    getUsageData(currentTabLabel, data).allocatedValue || 0;
  const categories = getCategories(usageData);
  const graphData = useFormatUsageData(usageData);
  const tooltipTitle = getUsageData(currentTabLabel, data).tooltipTitle || '';
  const yAxisLabel = getUsageData(currentTabLabel, data).yAxisLabel || '';
  const assistAllocatedSeats = data?.allocated_assist_seats || 0;
  const contractQuotaLabel = ['triage', 'solve'].includes(currentTabLabel)
    ? `${formatNumber(allocatedValue)} ${tooltipTitle.toLowerCase()}.`
    : '';

  return (
    <Box display='flex' flexDirection='column' gap='24px'>
      <Typography variant='font20'>{USAGE}</Typography>

      <Box
        border={`1px solid ${palette.colors.slate[200]}`}
        borderRadius={'8px'}
        display='flex'
        flexDirection='column'
        gap='16px'
        justifyContent={'center'}
        minHeight={'460px'}
        padding='24px'
      >
        {isLoading ? (
          <Spinner height={'50px'} />
        ) : (
          <>
            <Tabs
              a11yProps={a11yTabProps}
              aria-label='Subscription Plan Page Products'
              onChange={(_, value) => setTab(value.toString())}
              tabs={tabs}
              typographyVariant='font20'
              value={Number(tab)}
            />
            <Box display='flex' justifyContent={'space-between'}>
              <Typography color={palette.colors.grey[600]} variant='font16'>
                {`${tabIndexToLabel[currentTabLabel]} ${contractQuotaLabel}`}
              </Typography>
              <Typography color={palette.colors.grey[600]} variant='font12'>
                {`${TERM} ${PERIOD} ${formatToDateOnly(
                  data?.start_date || '',
                )} - ${formatToDateOnly(data?.renewal_date || '')}`}
              </Typography>
            </Box>
            {currentTabLabel === 'assist' ? (
              <Box
                alignItems='center'
                display='flex'
                flexDirection='column'
                gap='16px'
                height='100%'
                justifyContent='center'
              >
                <Typography variant='font42'>{assistAllocatedSeats}</Typography>
                <Typography color={palette.colors.grey[600]} variant='font16'>
                  {AGENT_SEATS}
                </Typography>
              </Box>
            ) : (
              <PriceColumnGraph
                categories={categories}
                contractQuota={allocatedValue}
                data={graphData}
                tooltipTitle={tooltipTitle}
                yAxisLabel={yAxisLabel}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionPlanPageBody;
