import './index.scss';

import React from 'react';
import classNames from 'classnames';

import { IconYPosition } from './types';

type Props = {
  /** Style for button */
  buttonStyle: string;
  /** Text to be displayed on button */
  buttonText: string;
  /** Callback when user clicks on the button */
  callbackFunction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  /** Style of the icon */
  iconStyle?: string;
  /** Side of the icon */
  iconYPosition?: IconYPosition;
  /** Controls if button is disabled or not */
  isDisabled?: boolean;
  /** ID to be able to test component */
  testId?: string;
  /** Text to be shown on button hover */
  tooltipText?: string;
};

const Button: React.FC<React.PropsWithChildren<Props>> = ({
  buttonStyle,
  buttonText,
  callbackFunction,
  iconStyle,
  iconYPosition = IconYPosition.RIGHT,
  isDisabled = false,
  testId,
  tooltipText,
}: Props) => {
  const iconElement = <div className={iconStyle} data-testid='icon' />;

  const buttonClassNames = classNames('ButtonStyle', buttonStyle, {
    'ButtonStyle--disabled': isDisabled,
  });

  return (
    <button
      className={buttonClassNames}
      data-testid={testId}
      disabled={isDisabled}
      onClick={callbackFunction}
    >
      {iconStyle && iconYPosition === IconYPosition.LEFT && iconElement}
      {buttonText}
      {tooltipText && (
        <div className='ButtonStyle-tooltip'>
          <div className='ButtonStyle-tooltipContent'>
            <div className='ButtonStyle-tooltipArrow' />
            {tooltipText}
          </div>
        </div>
      )}
      {iconStyle && iconYPosition === IconYPosition.RIGHT && iconElement}
    </button>
  );
};

export default Button;
