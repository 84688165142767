import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import wordCount from 'word-count';
import { ClickAwayListener, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { CommandButton } from '@remirror/react';
import { IconCheck } from '@tabler/icons-react';

import {
  CanvasWorkflowBuilderState,
  selectIsRichTextEditorValueEmpty,
  selectRichTextEditorValue,
  setEditingStepId,
  setMode,
  setRichTextEditorValue,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addTextMessage,
  updateWorkflowStep,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useGetBuilderQueryParams } from '../hooks';
import { getTopicIds, hasElement } from './utils';
import { buttonStyles } from 'src/components/rich-text-editor/helpers/buttonStyles';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { stripHTML } from 'src/hooks/useStrippedHTML';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import {
  selectCanvasWorkflowBuilder,
  selectEditingStepId,
  selectEditingStepType,
  selectMode,
  selectUndefinedContextVariablesInStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectDiscoverTopicsByIntentId } from 'src/slices/data/dataSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  MessageStepData,
  TextMessageFields,
} from 'src/types/workflowBuilderAPITypes';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';
import { flattenDynamicContextVariables } from 'src/utils/solve/dynamicContextVariableUtils';

export default function TextStepEditor({
  autoFocus = false,
  isSqueezingStep,
  setIsSqueezingStep,
  setShouldSqueezeIntoEntry,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}: {
  autoFocus?: boolean;
  isSqueezingStep: boolean;
  setIsSqueezingStep?: (shouldShow: boolean) => void;
  setShouldSqueezeIntoEntry?: (shouldSqueezeIntoEntry: boolean) => void;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
}): JSX.Element {
  const dispatch = useAppDispatch();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );
  const { workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const editingStepId = useSelector(selectEditingStepId);
  const editingStepType = useSelector(selectEditingStepType);
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const userCanAddStep = useSelector(selectUserCan('add_step'));
  const discoverTopics = useSelector(selectDiscoverTopicsByIntentId);
  const topicIds = getTopicIds(discoverTopics);
  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );
  const mode = useSelector(selectMode);
  const richTextEditorValue = useSelector(selectRichTextEditorValue);
  const isRichTextEditorValueEmpty = useSelector(
    selectIsRichTextEditorValueEmpty,
  );

  const editorRef = useRef<EditorRef>(null);

  const initialRichTextEditorValue = editingStepId
    ? (canvasData.steps[editingStepId].step_fields as TextMessageFields).message
    : '';

  useEffect(() => {
    dispatch(setRichTextEditorValue(initialRichTextEditorValue));
  }, [initialRichTextEditorValue, dispatch]);

  const handleChange = useCallback(
    (newRichTextEditorValue: string) => {
      dispatch(setRichTextEditorValue(newRichTextEditorValue));
    },
    [dispatch],
  );

  const [showLinkPreview, setShowLinkPreview] = useState(
    editingStepId
      ? (canvasData.steps[editingStepId].step_fields as TextMessageFields)
          .show_link_preview ?? true
      : true,
  );
  const isEditMode = editingStepId !== '';
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const undefinedContextVariableIds = useMemo(
    () => undefinedContextVariablesInStep[editingStepId] || [],
    [editingStepId, undefinedContextVariablesInStep],
  );
  const remirrorContextVariables = useMemo(() => {
    const flattened = flattenDynamicContextVariables(contextVariables);
    return flattened.map(cv => ({
      ...cv,
      isDefined: Boolean(cv.id && !undefinedContextVariableIds.includes(cv.id)),
    }));
  }, [contextVariables, undefinedContextVariableIds]);

  const setText = (newRichTextEditorValue: string) => {
    editorRef.current?.setContent(newRichTextEditorValue);
  };

  const onSave = () => {
    if (selectedWorkflowId === null) {
      return;
    }

    // Currently in edit mode
    if (isEditMode) {
      if (editingStepType === 'text_message') {
        dispatch(
          updateWorkflowStep({
            condition_name: canvasData.steps[editingStepId].condition_name,
            step_fields: {
              message: richTextEditorValue,
              show_link_preview: showLinkPreview,
            },
            stepId: editingStepId,
            transitions: canvasData.steps[editingStepId].transitions,
          }),
        );
        setText('');
        setShowLinkPreview(true);
      }

      dispatchTrackingAction(
        FlamethrowerTrackingEventTypes.WORKFLOW_STEP_EDITED,
        {
          stepId: editingStepId,
          stepType: editingStepType,
        },
      );

      dispatch(setEditingStepId(''));
      dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));

      return;
    }

    if (richTextEditorValue) {
      dispatchTrackingAction(
        FlamethrowerTrackingEventTypes.WORKFLOW_ADDED_TEXT_STEP,
        {
          topic_ids: topicIds,
          workflow_id: selectedWorkflowId,
        },
      );
      if (wordCount(stripHTML(richTextEditorValue)) > 50) {
        dispatchTrackingAction(
          FlamethrowerTrackingEventTypes.WORKFLOW_ADDED_TEXT_STEP_OVER_50_WORDS,
          {
            topic_ids: topicIds,
            workflow_id: selectedWorkflowId,
          },
        );
      }
      if (hasElement(richTextEditorValue, 'a')) {
        dispatchTrackingAction(
          FlamethrowerTrackingEventTypes.WORKFLOW_ADDED_TEXT_STEP_WITH_LINK,
          {
            topic_ids: topicIds,
            workflow_id: selectedWorkflowId,
          },
        );
      }
      const stepData: MessageStepData = {
        message: richTextEditorValue,
        showLinkPreview,
      };

      if (!shouldSqueezeIntoEntry && isSqueezingStep) {
        stepData.parentStepId = squeezeStepParentId;
      }
      if (shouldSqueezeIntoEntry && isSqueezingStep) {
        stepData.isEntryStep = shouldSqueezeIntoEntry;
      }
      dispatch(addTextMessage(stepData));
      setIsSqueezingStep?.(false);
      setShouldSqueezeIntoEntry?.(false);
      setText('');
    }
    dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
  };

  const onCancel = () => {
    // Prevent `onClickAway()` triggering when adding/editing other types of
    // steps:
    if (mode !== CanvasModes.MESSAGE) {
      return;
    }

    // Text
    setShowLinkPreview(true);
    setText('');

    // Squeeze
    setIsSqueezingStep?.(false);
    setShouldSqueezeIntoEntry?.(false);

    // Clear editing step and mode
    dispatch(setEditingStepId(''));
  };

  const onEditingComplete = () => {
    if (isRichTextEditorValueEmpty) {
      onCancel();
    } else {
      onSave();
    }
  };

  const isEditable = !canvasData.loading && userCanAddStep;

  return (
    <ClickAwayListener onClickAway={onEditingComplete}>
      <Box
        sx={{
          '& .ProseMirror': {
            padding: '0 !important',
          },
          '& .remirror-editor-wrapper': {
            fontSize: '14px !important',
            padding: '0 !important',
          },
          flex: 1,
        }}
      >
        <RichTextEditor
          additionalToolbarButtons={[
            <EditingCompletedButton
              disabled={!isEditable}
              key='saveStep'
              onClick={onEditingComplete}
            />,
          ]}
          autoFocus={autoFocus || Boolean(editingStepId)}
          contentMargin='14px 0'
          contextVariables={remirrorContextVariables}
          editable={isEditable}
          editorRef={editorRef}
          initialContent={
            editingStepId
              ? (
                  canvasData.steps[editingStepId]
                    .step_fields as TextMessageFields
                ).message
              : undefined
          }
          label='Message'
          linkPreviewConfig={{ setShowLinkPreview, showLinkPreview }}
          onChange={handleChange}
          placeholder='Type a chatbot message or drag a new step here'
          shouldRenderLinkButton={false}
          shouldShowFloatingToolbarWhenFocused={!isRichTextEditorValueEmpty}
          shouldShowToolbar={false}
        />
      </Box>
    </ClickAwayListener>
  );
}

interface EditingCompletedButtonProps {
  disabled: boolean;
  onClick: () => void;
}

const EditingCompletedButton = ({
  disabled,
  onClick,
}: EditingCompletedButtonProps) => {
  const { palette } = useTheme();
  return (
    <CommandButton
      commandName='saveStep'
      disabled={disabled}
      enabled
      icon={<IconCheck color={palette.success.main} size='18px' />}
      label='Save'
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
      onSelect={() => {
        // `onSelect()` is required but not used.
      }}
      sx={{
        ...buttonStyles,
        '&:hover:not(:disabled,.Mui-selected)': {
          backgroundColor: palette.colors.green[100],
        },
      }}
    />
  );
};
