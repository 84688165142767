import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';

import { Colors } from '@forethought-technologies/forethought-elements';
import KustomerChatHandoffConfiguration from './agent-chat-handoff';
import KustomerConversationCreationConfiguration from './conversation-creation';
import Checkbox from 'src/components/checkbox';
import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { textMediumStyle, textRegularStyle } from 'src/styles/styledMixin';
import { KustomerHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

type KustomerHandoffConfigurationProps = {
  customizationData: KustomerHandoffCustomization;
  setCustomizationData: Dispatch<SetStateAction<KustomerHandoffCustomization>>;
};

const KustomerHandoffConfiguration: FC<
  React.PropsWithChildren<KustomerHandoffConfigurationProps>
> = ({ customizationData, setCustomizationData }) => {
  return (
    <ContentWrapper>
      <Content>
        <DefaultTitle>Kustomer Handoff Configuration</DefaultTitle>
        <Spacer height='20px' />
        <StyledSection>
          <CheckBoxOption>
            <Checkbox
              checked={!!customizationData?.include_agent_chat_handoff}
              onChange={() =>
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    include_agent_chat_handoff:
                      !handoffData.include_agent_chat_handoff,
                  };
                })
              }
            />
            <CheckboxLabel>Include agent chat handoff</CheckboxLabel>
          </CheckBoxOption>
          {customizationData?.include_agent_chat_handoff && (
            <KustomerChatHandoffConfiguration
              customizationData={customizationData}
              setCustomizationData={setCustomizationData}
            />
          )}
        </StyledSection>
        <StyledSection>
          <CheckBoxOption>
            <Checkbox
              checked={
                !!customizationData?.include_conversation_creation_handoff
              }
              onChange={() =>
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    include_conversation_creation_handoff:
                      !handoffData.include_conversation_creation_handoff,
                  };
                })
              }
            />
            <CheckboxLabel>Include ticket creation handoff</CheckboxLabel>
            <Spacer height='4px' />
          </CheckBoxOption>
          {customizationData?.include_conversation_creation_handoff && (
            <KustomerConversationCreationConfiguration
              customizationData={customizationData}
              setCustomizationData={setCustomizationData}
            />
          )}
        </StyledSection>
        {customizationData?.include_conversation_creation_handoff && (
          <Spacer height='180px' />
        )}
      </Content>
    </ContentWrapper>
  );
};

export default KustomerHandoffConfiguration;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DefaultTitle = styled('h2')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const CheckBoxOption = styled('div')`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled('p')`
  margin-left: 6px;
  ${textRegularStyle({ color: Colors.ui.text.primary, fontSize: '16px' })}
`;

const StyledSection = styled(Section)`
  margin: 0 10px;
`;

const Content = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 0;
`;
