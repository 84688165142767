import React from 'react';
import { Box, styled } from '@mui/material';

import { Button } from '@forethought-technologies/forethought-elements';

interface IntentEmailFormProps {
  children?: React.ReactNode;
  isSaveButtonDisabled?: boolean;
  onClickCancel: () => void;
  onClickSave: () => void;
}

const IntentEmailForm = ({
  children,
  isSaveButtonDisabled,
  onClickCancel,
  onClickSave,
}: IntentEmailFormProps) => {
  return (
    <Box display='flex' flexDirection='column' height='100%' width='100%'>
      <Box flex='1' overflow='scroll'>
        {children}
      </Box>
      <Buttons>
        <Button
          onClick={() => onClickCancel()}
          size='large'
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          data-testid='intent-email-form-save'
          disabled={isSaveButtonDisabled}
          fullWidth
          onClick={() => onClickSave()}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Buttons>
    </Box>
  );
};

const Buttons = styled('div')`
  display: flex;
  gap: 16px;
`;

export default IntentEmailForm;
