import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material';

import {
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import InvitesTable from '../invites-table';
import UserForm from '../user-form';
import { createUser } from 'src/slices/team/thunks';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { hasErrorMessage } from 'src/utils/typeGuards';

export default function InvitesTab() {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState('');

  return (
    <Box padding='16px 40px 0'>
      <UserFormContainer>
        <Typography variant='font18Bold'>Invite new team member</Typography>
        <UserForm
          onSubmit={async (userRequestBody, { resetForm }) => {
            try {
              await dispatch(createUser({ userRequestBody })).unwrap();
              resetForm();
            } catch (error) {
              if (
                hasErrorMessage(error) &&
                error.message.includes('already exists in pool')
              ) {
                return dispatch(
                  setGlobalToastOptions({
                    title: 'This user already exists',
                    variant: 'warning',
                  }),
                );
              }

              dispatch(
                setGlobalToastOptions({
                  title: 'Something went wrong creating user',
                  variant: 'danger',
                }),
              );
            }
          }}
        />
      </UserFormContainer>
      <Box paddingBottom={0} py={4}>
        <Typography variant='font18Bold'>Pending invites</Typography>
        <InvitesTable
          filterQuery={query}
          topToolbar={
            <Box
              alignItems='center'
              display='flex'
              flex={1}
              gap={1}
              justifyContent='space-between'
              paddingTop='4px'
            >
              <Box width='250px'>
                <SearchBar
                  aria-label='Invite search bar filter'
                  fullWidth
                  onChange={({ target }) => setQuery(target.value)}
                  onClear={() => setQuery('')}
                  placeholder='Search invites'
                  size='small'
                  value={query}
                />
              </Box>
            </Box>
          }
        />
      </Box>
    </Box>
  );
}

const UserFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
`;
