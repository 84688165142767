import 'reactflow/dist/style.css';

import { useMemo } from 'react';
import ReactFlow, {
  Background,
  Controls,
  NodeTypes,
  ProOptions,
  ReactFlowProps,
  useReactFlow,
} from 'reactflow';
import { useTheme } from '@mui/material';

import { DndContextProvider } from '../../DndContext';
import { ForethoughtFlowProps } from '../../ForethoughtFlow';
import useLayout from '../../hooks/useLayout';
import { FlowConfiguration } from '../../types';
import FlowDropzone from '../FlowDropzone';
import edgeTypes from './EdgeTypes';
import defaultNodeTypes from './NodeTypes';

const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

const fitViewOptions = {
  padding: 0.2,
};

interface FlowLayoutProps {
  flowConfiguration: FlowConfiguration;
  flowProps: ReactFlowProps;
  onDropCallback?: ForethoughtFlowProps['onDropCallback'];
}

export default function FlowLayout({
  flowConfiguration,
  flowProps,
  onDropCallback,
}: FlowLayoutProps) {
  const { palette } = useTheme();
  const { fitView } = useReactFlow();
  useLayout();

  const nodeTypes = useMemo(() => {
    // Take the config object and reduce it to a mapping of nodeType -> React component
    const customNodeTypes = Object.entries(flowConfiguration).reduce(
      (acc: NodeTypes, [nodeType, { node }]) => {
        acc[nodeType] = node;
        return acc;
      },
      {},
    );

    return {
      ...defaultNodeTypes,
      ...customNodeTypes,
    };
  }, [flowConfiguration]);

  return (
    <DndContextProvider>
      <FlowDropzone onDropCallback={onDropCallback}>
        <ReactFlow
          edgeTypes={edgeTypes}
          fitView
          fitViewOptions={fitViewOptions}
          minZoom={0.2}
          nodesConnectable={false}
          nodesDraggable={false}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          snapToGrid
          zoomOnDoubleClick={false}
          // set default options, but allow defaults to be overridden
          {...flowProps}
        >
          <Background color={palette.colors.slate[100]} />
          <Controls
            // override onFitView to apply padding at top
            onFitView={() => fitView(fitViewOptions)}
            showInteractive={false}
          />
        </ReactFlow>
      </FlowDropzone>
    </DndContextProvider>
  );
}
