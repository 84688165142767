export const transformSnakeCaseToText = (input: string) => {
  // Split the string into words
  const words = input.split('_');

  // Capitalize the first letter of each word
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back together
  return words.join(' ');
};
