import { Box } from '@mui/material';
import { styled } from '@mui/system';

import {
  Button,
  TextField,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { Label, Row } from '../handoff-configuration/styles';
import { DeleteButton } from './FreshdeskTicketCreationCustomizationPanel';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';

interface TagSelectionProps {
  addTag: () => void;
  deleteTag: (index: number) => void;
  editTag: (index: number, value: string) => void;
  includeTooltip?: boolean;
  label: string;
  tags: Array<string>;
}

const TagSelection = ({
  addTag,
  deleteTag,
  editTag,
  includeTooltip,
  label,
  tags,
}: TagSelectionProps) => {
  return (
    <Box>
      <Row>
        <Label>{label}</Label>
        {includeTooltip && (
          <Tooltip tooltipContent='Tags to be added to the ticket'>
            <Icon src={tooltipInfoIcon} />
          </Tooltip>
        )}
      </Row>
      {tags.map((tag, index) => (
        <Box
          key={index}
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            pb: 1,
          }}
        >
          <TextField
            aria-label='Add a tag'
            onChange={({ target }) => {
              editTag(index, target.value);
            }}
            placeholder='Enter tag'
            value={tag}
          />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              height: '42px',
              justifyContent: 'flex-end',
              width: '30px',
            }}
          >
            <DeleteButton
              alt='delete tag icon'
              onClick={() => {
                deleteTag(index);
              }}
              role='button'
              src={xIcon}
            />
          </Box>
        </Box>
      ))}
      <Button onClick={() => addTag()} variant='ghost'>
        + Add Tag
      </Button>
    </Box>
  );
};

export default TagSelection;

const Icon = styled('img')`
  margin-bottom: 8px;
  margin-left: 4px;
`;
