import { useRef, useState } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { IconTag, IconTrash } from '@tabler/icons-react';

import {
  Button,
  Drawer,
  Toggle,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { NEW_NOTE_ID } from '../constants';
import { unixTimestampToDateCreated } from '../helpers';
import UnsavedNoteDialog from '../note-dialog/UnsavedNoteDialog';
import AgentEmailCell from '../notes-table/AgentEmailCell';
import TagList from '../TagList';
import AddTagComponent from './AddTagComponent';
import { getEditedTimeStringFromNow, isNoteValid } from './helpers';
import { useIsNoteDirty, useSyncSelectedNoteWithInputs } from './hooks';
import notesSharedIcon from 'src/assets/images/notes-shared-icon.svg';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { AssistNote } from 'src/slices/assist-notes/types';

interface NoteDrawerCallbackProps {
  onDelete: (noteId: string) => void;
  onSaveNote: (body: { body: string; title: string }) => void;
  onToggleNoteShare: (noteId: string, isPublic: boolean) => void;
  onUnshare: (noteId: string) => void;
}

interface NoteDrawerProps extends NoteDrawerCallbackProps {
  allAvailableTags: string[];
  onClose: () => void;
  onSaveNote: (body: { body: string; title: string }) => void;
  selectedNote: AssistNote | undefined;
}

const NoteDrawer = ({
  allAvailableTags,
  onClose,
  onDelete,
  onSaveNote,
  onToggleNoteShare,
  onUnshare,
  selectedNote,
}: NoteDrawerProps) => {
  const editorRef = useRef<EditorRef>(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState<string | null>(null);
  const [isUnsavedDialogOpen, setIsUnsavedDialogOpen] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const isDirty = useIsNoteDirty(body, title, selectedNote);

  useSyncSelectedNoteWithInputs({ selectedNote, setBody, setTitle });
  const { typography } = useTheme();

  const handleClose = () => {
    onClose();
    setWasSaved(false);
    setBody(null);
    setTitle('');
    setIsUnsavedDialogOpen(false);
  };

  const isOpen = Boolean(selectedNote);
  const onCloseOrUnsavedChanges = () => {
    if (isDirty && !wasSaved) {
      setIsUnsavedDialogOpen(true);
    } else {
      handleClose();
    }
  };

  const changeBody = (newBody: string) => {
    setBody(newBody);
    setWasSaved(false);
  };

  const changeTitle = (newTitle: string) => {
    setTitle(newTitle);
    setWasSaved(false);
  };

  const handleSave = () => {
    setWasSaved(true);
    onSaveNote({ body: body || '', title });
  };

  const onSaveFromUnsavedDialog = () => {
    handleSave();
    handleClose();
  };

  const onDontSaveFromUnsavedDialog = () => {
    setIsUnsavedDialogOpen(false);
    handleClose();
  };

  return (
    <>
      <Drawer
        footerContent={
          body !== null && (
            <Footer
              body={body}
              onDelete={onDelete}
              onSaveNote={handleSave}
              onToggleNoteShare={onToggleNoteShare}
              onUnshare={onUnshare}
              selectedNote={selectedNote}
              title={title}
              wasSaved={wasSaved}
            />
          )
        }
        isOpen={isOpen}
        onClose={onCloseOrUnsavedChanges}
      >
        {selectedNote && body !== null ? (
          <Box
            display='flex'
            flexDirection='column'
            height='100%'
            p={5}
            sx={{
              '& .ProseMirror': {
                padding: '0 !important',
              },
            }}
          >
            <Header
              allAvailableTags={allAvailableTags}
              selectedNote={selectedNote}
            />
            <RichTextEditor
              belowToolbarComponent={
                <Box
                  sx={{
                    ...typography.font24,
                    fontFamily: typography.fontFamily,
                    mt: 5,
                  }}
                >
                  <Input
                    onChange={e => changeTitle(e.target.value)}
                    placeholder='Give it a name'
                    value={title}
                  />
                </Box>
              }
              editorRef={editorRef}
              initialContent={body}
              onChange={changeBody}
              placeholder='Enter some rich text...'
            />
          </Box>
        ) : null}
      </Drawer>
      <UnsavedNoteDialog
        isOpen={isUnsavedDialogOpen}
        onClose={() => setIsUnsavedDialogOpen(false)}
        onDontSave={onDontSaveFromUnsavedDialog}
        onSave={onSaveFromUnsavedDialog}
      />
    </>
  );
};

const Header = ({
  allAvailableTags,
  selectedNote,
}: {
  allAvailableTags: Array<string>;
  selectedNote: AssistNote;
}) => {
  const { palette } = useTheme();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const { created_date, last_modified_date, note_id, tags } = selectedNote;
  const isNewNote = note_id === NEW_NOTE_ID;
  const createdDate = !isNewNote
    ? unixTimestampToDateCreated(created_date)
    : '--';

  return (
    <>
      <Box
        alignItems='center'
        display='flex'
        height='40px'
        justifyContent='space-between'
        mb='29px'
        mt='3px' // to align with close button
        pr='56px'
      >
        <Typography variant='font24'>Note</Typography>
        {!isNewNote ? (
          <Typography color={palette.colors.grey[500]} variant='font12'>
            Last edited {getEditedTimeStringFromNow(last_modified_date)}
          </Typography>
        ) : null}
      </Box>
      <Box
        alignItems='center'
        display='grid'
        gap='14px 0'
        gridTemplateColumns='120px 1fr'
      >
        <Typography color={palette.grey[600]} variant='font12'>
          Created by
        </Typography>
        <AgentEmailCell data={selectedNote} />
        <Typography color={palette.grey[600]} variant='font12'>
          Date created
        </Typography>
        <Typography variant='font14'>{createdDate}</Typography>
      </Box>
      <Box
        borderBottom={`1px solid ${palette.colors.slate[200]}`}
        display='flex'
        pb={2}
        pt={3}
      >
        <TagList tagList={tags} />
        <Tooltip
          tooltipContent={isNewNote ? 'Save this note before adding tags' : ''}
        >
          <Button
            disabled={isNewNote}
            onClick={e => setAnchorElement(e.currentTarget)}
            startIcon={<IconTag size={20} />}
            variant='ghost'
          >
            Add tag
          </Button>
        </Tooltip>
      </Box>
      <AddTagComponent
        allAvailableTags={allAvailableTags}
        anchorElement={anchorElement}
        handleClose={() => setAnchorElement(null)}
        selectedNote={selectedNote}
      />
    </>
  );
};

const Footer = ({
  body,
  onDelete,
  onSaveNote,
  onToggleNoteShare,
  onUnshare,
  selectedNote,
  title,
  wasSaved,
}: {
  body: string;
  onSaveNote: () => void;
  selectedNote: AssistNote | undefined;
  title: string;
  wasSaved: boolean;
} & NoteDrawerCallbackProps) => {
  const { palette } = useTheme();
  const isDirty = useIsNoteDirty(body, title, selectedNote);

  if (!selectedNote) {
    return null;
  }
  const { is_public, note_id } = selectedNote;
  const isNewNote = note_id === NEW_NOTE_ID;
  const notValid =
    body !== ''
      ? !isNoteValid({
          ...selectedNote,
          body,
          title,
        })
      : false;

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      pb={1}
      px={2}
      zIndex={-1}
    >
      <Box alignItems='center' display='flex' gap={1}>
        <Button
          disabled={notValid || !isDirty || wasSaved}
          onClick={async () => {
            // TODO Graceful error handling
            onSaveNote();
          }}
          size='large'
          variant='main'
        >
          {wasSaved ? 'Saved' : 'Save'}
        </Button>
        {!isNewNote ? (
          <Button
            onClick={() => onDelete(note_id)}
            size='large'
            variant='ghost'
          >
            <Box color={palette.error.main} display='flex'>
              <Box alignItems='center' display='flex' mr={1}>
                <IconTrash size={20} />
              </Box>
              <Typography variant='font14Bold'>Delete note</Typography>
            </Box>
          </Button>
        ) : null}
      </Box>
      <Box alignItems='center' display='flex' gap={1}>
        <Box display='flex'>
          <img src={notesSharedIcon} />
          <Typography color={palette.colors.grey[700]} variant='font14Button'>
            Share
          </Typography>
        </Box>
        <Toggle
          checked={is_public}
          disabled={isNewNote}
          onChange={() => {
            if (is_public) {
              onUnshare(note_id);
              return;
            }

            onToggleNoteShare(note_id, false);
          }}
        />
      </Box>
    </Box>
  );
};

const Input = styled('input')`
  font-weight: inherit;
  width: 100%;
  outline: none;
  border: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export default NoteDrawer;
