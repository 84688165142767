import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { IconExternalLink } from '@tabler/icons-react';

import {
  Button,
  Drawer,
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Scope } from '../types';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGetArticleQuery } from 'src/services/insights/insightsApi';
import { selectSelectedArticleId } from 'src/slices/solve-insights/solveInsightsSlice';
import { setSelectedArticleId } from 'src/slices/solve-insights/solveInsightsSlice';
import { useAppDispatch } from 'src/store/hooks';

interface InsightArticleDrawerProps {
  scope: Scope;
}

const InsightArticleDrawer: React.FC<
  React.PropsWithChildren<InsightArticleDrawerProps>
> = ({ scope }) => {
  // Hooks
  const dispatch = useAppDispatch();
  const selectedArticleId = useSelector(selectSelectedArticleId);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const onClose = () => dispatch(setSelectedArticleId(null));
  const {
    data: article,
    isFetching,
    isLoading,
  } = useGetArticleQuery(
    { article_id: selectedArticleId || '' },
    { skip: !selectedArticleId },
  );

  useEffect(() => {
    if (article) {
      // TODO - when tab navigation is added this should be updated
      emitTrackingEventCallback('insight-article-drawer-open', {
        id: article?.id,
        scope,
        tab: 'article',
        title: article?.article_title,
      });
    }
  }, [emitTrackingEventCallback, article, scope]);
  const isPrepared = !isFetching && !isLoading;
  return (
    <Drawer
      footerContent={
        <Box
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          p='24px'
        >
          <a
            href={article?.article_source_link}
            onClick={() => {
              emitTrackingEventCallback(
                'insight-article-drawer-helpdesk-clicked',
                {
                  id: article?.id,
                  link: article?.article_source_link,
                  scope,
                },
              );
            }}
            rel='noreferrer'
            target='_blank'
          >
            <Button startIcon={<IconExternalLink size={20} />} variant='main'>
              Go to helpdesk
            </Button>
          </a>
        </Box>
      }
      isOpen={Boolean(selectedArticleId)}
      onClose={() => {
        onClose();
        emitTrackingEventCallback('insight-article-drawer-close', {
          id: article?.id,
          link: article?.article_source_link,
          scope,
          tab: 'article',
        });
      }}
      width='650px'
    >
      {isPrepared ? (
        <Box
          display='flex'
          flexDirection='column'
          gap='24px'
          height='100%'
          overflow='hidden'
          px='40px'
          width='100%'
        >
          <Box position='sticky' pt='40px' top='0' width='90%' zIndex={1}>
            <Typography variant='font24'>{article?.article_title}</Typography>
          </Box>
          <Box
            flex='1'
            overflow='auto'
            sx={{
              '* > img': {
                maxWidth: '100%',
              },
            }}
          >
            <ReactMarkdown>{article?.content || ''}</ReactMarkdown>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          flexDirection='column'
          height='100%'
          px='40px'
          width='100%'
        >
          <Box position='sticky' pt='40px' top='0' width='90%' zIndex={1}>
            <Skeleton height='35px' />
          </Box>
          <Box display='flex' height='650px' justifyContent='start'>
            <Skeleton height='100%' variant='text' width='100%' />
          </Box>
        </Box>
      )}
    </Drawer>
  );
};

export default InsightArticleDrawer;
