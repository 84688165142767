import React from 'react';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import { IconBookmark } from '@tabler/icons-react';

import {
  IconButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import {
  PATCHING_TOPIC_TABLE_ROW_CLASS_NAME,
  WATCH_ICON_SIZE,
  WATCH_ICON_STROKE,
} from 'src/constants/discover';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface DiscoverBookmarkIconProps {
  isBookmarked?: boolean;
  isStatic?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  version?: 'V1' | 'V2';
}

const DiscoverBookmarkIcon = ({
  isBookmarked,
  isStatic,
  onClick,
  version = 'V1',
}: DiscoverBookmarkIconProps) => {
  const theme = useTheme();
  if (isStatic) {
    return (
      <Box
        alignItems='center'
        display='inline-block'
        justifyContent='center'
        sx={{
          verticalAlign: 'top',
        }}
      >
        <Box
          sx={{
            color:
              version === 'V2'
                ? theme.palette.colors.purple[500]
                : theme.palette.colors.grey[500],
            position: 'relative',
          }}
        >
          <IconBookmark
            color='currentColor'
            fill='transparent'
            height={WATCH_ICON_SIZE}
            stroke={WATCH_ICON_STROKE}
            width={WATCH_ICON_SIZE}
          />
          <X className='xIcon' newVersion={version === 'V2'}>
            &#43;
          </X>
        </Box>
      </Box>
    );
  }
  return (
    <Tooltip tooltipContent={isBookmarked ? 'Remove bookmark' : 'Bookmark'}>
      <Box
        data-appcues-target={getAppCuesId({
          componentType: 'button',
          featureName: 'topic',
          pageName: 'discover',
          subType: 'bookmark',
        })}
        sx={{
          '&:hover .MuiBox-root': {
            color: theme.palette.colors.purple[500],
          },
          '&:hover .xIcon': {
            backgroundColor: `${theme.palette.colors.purple[200]} !important`, // important needed to override table hover styles applied
          },
          '.MuiTableRow-hover & .xIcon': {
            backgroundColor: '#f5f5f5',
            opacity: 1,
          },
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IconButton
          aria-label='Bookmark'
          hoverBackgroundColor={theme.palette.colors.purple[200]}
          onClick={e => {
            e.stopPropagation();

            if (!onClick) {
              return;
            }

            onClick(e);
          }}
          variant='ghost'
        >
          <Box
            sx={{
              '.MuiTableRow-hover &': {
                color: theme.palette.colors.purple[500],
                opacity: isBookmarked ? 1 : 0,
              },
              '.MuiTableRow-hover:hover &': {
                opacity: 1,
              },
              [`.${PATCHING_TOPIC_TABLE_ROW_CLASS_NAME} &`]: {
                opacity: 1,
              },
              color:
                isBookmarked || version === 'V2'
                  ? theme.palette.colors.purple[500]
                  : theme.palette.colors.grey[500],
              display: 'flex',
              position: 'relative',
            }}
          >
            <IconBookmark
              color='currentColor'
              fill={isBookmarked ? 'currentColor' : 'transparent'}
              height={WATCH_ICON_SIZE}
              stroke={WATCH_ICON_STROKE}
              width={WATCH_ICON_SIZE}
            />
            {!isBookmarked && (
              <X className='xIcon' newVersion={version === 'V2'}>
                &#43;
              </X>
            )}
          </Box>
        </IconButton>
      </Box>
    </Tooltip>
  );
};

const X = styled('span')<{ newVersion: boolean }>`
  right: 1px;
  position: absolute;
  top: -4px;
  height: 12px;
  width: 10px;
  background-color: ${p =>
    p.newVersion ? p.theme.palette.colors.grey[100] : 'white'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  transition: background-color 0.2s linear;
  font-weight: bold;
`;

export default DiscoverBookmarkIcon;
