import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getEmailEnabledLanguagages,
  getWorkflowBuilderLanguagesEnabledAndTranslations,
  getWorkflowBuilderLanguagesEnabledForStep,
  HTTP_STATUS_NOT_FOUND,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import {
  selectIntentWorkflowId,
  selectStepLevelTranslationsId,
  selectTranslationsTableMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useLazyGetEmailTranslationsQuery } from 'src/services/solve-email-multilingual/solveEmailMultilingualApi';
import { updateWorkflowBuilderLanguagesEnabledAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import { useAppDispatch } from 'src/store/hooks';
import { TranslationChannel } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

export const useGetTranslationChannels = () => {
  const { pathname, search } = useLocation();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const SolveEmailTranslationEnabled = featureFlags?.includes(
    'solve_email_translation_enabled',
  );
  const isSolveWidgetEnabled = featureFlags?.includes('solve_widget_enabled');
  const isSolveWidgetConfigRoute = Routes.SOLVE_CONFIGURATION === pathname;
  const isSolveWidgetCanvasView =
    new URLSearchParams(search).get('view') === 'widget';
  const isSolveEmailCanvasView =
    new URLSearchParams(search).get('view') === 'email';
  const isWorkflowBuilderLandingPage = pathname === Routes.WORKFLOW_BUILDER;

  const isSolveEmailEnabled =
    featureFlags?.includes('solve_email_enabled') &&
    SolveEmailTranslationEnabled;
  const isInteractiveEmailEnabled =
    isSolveEmailEnabled && featureFlags?.includes('interactive_email');

  const isEmailTabVisibleInCanvas =
    isSolveEmailEnabled && isSolveEmailCanvasView;

  const isWidgetTabVisibleInCanvas =
    isSolveWidgetEnabled && isSolveWidgetCanvasView;

  return useMemo(() => {
    const channels: Array<TranslationChannel> = [];

    if (
      ((isWorkflowBuilderLandingPage || isSolveWidgetConfigRoute) &&
        isSolveWidgetEnabled) ||
      isWidgetTabVisibleInCanvas
    ) {
      channels.push('widget');
    }

    if (
      (isWorkflowBuilderLandingPage && isSolveEmailEnabled) ||
      isEmailTabVisibleInCanvas
    ) {
      channels.push('email');
      if (isInteractiveEmailEnabled) {
        channels.push('interactive_email');
      }
    }

    return channels;
  }, [
    isWorkflowBuilderLandingPage,
    isSolveWidgetConfigRoute,
    isSolveWidgetEnabled,
    isWidgetTabVisibleInCanvas,
    isSolveEmailEnabled,
    isEmailTabVisibleInCanvas,
    isInteractiveEmailEnabled,
  ]);
};

export const useGetEmailChannelData = () => {
  const dispatch = useAppDispatch();
  const [getEmailTranslationsTriggger] = useLazyGetEmailTranslationsQuery();
  const tableMode = useSelector(selectTranslationsTableMode);

  return useCallback(async () => {
    dispatch(getEmailEnabledLanguagages())
      .then(enabledLanguages => {
        if (enabledLanguages.length > 0) {
          getEmailTranslationsTriggger({
            mode: tableMode,
            targetLanguage: enabledLanguages[0],
          });
        }
      })
      .catch(error => {
        if (error.code == HTTP_STATUS_NOT_FOUND) {
          // Config not found - create it
          updateWorkflowBuilderLanguagesEnabledAPI({
            channel: 'email',
            language_codes: [],
            modified_date: null,
          });
        }
      });
  }, [dispatch, getEmailTranslationsTriggger, tableMode]);
};

export const useGetWidgetChannelData = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const dispatch = useAppDispatch();
  const stepLevelTranslationsId = useSelector(selectStepLevelTranslationsId);
  const currentWorkflowId = useSelector(selectIntentWorkflowId);
  const tableMode = useSelector(selectTranslationsTableMode);

  return useCallback(() => {
    Promise.all([
      // Get draft widget config
      stepLevelTranslationsId
        ? dispatch(
            getWorkflowBuilderLanguagesEnabledForStep(
              currentWorkflowId || '',
              stepLevelTranslationsId,
            ),
          )
        : dispatch(
            getWorkflowBuilderLanguagesEnabledAndTranslations(tableMode),
          ),
    ]).then(() => setIsLoading(false));
  }, [
    dispatch,
    setIsLoading,
    currentWorkflowId,
    stepLevelTranslationsId,
    tableMode,
  ]);
};
