import React from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Dialog,
  TextField,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DEFAULT_BANNER_IMAGE, FIVE_MB_IN_BYTES } from '../constants';
import { useDebouncedSubmitConfigForRTE } from '../hooks/useDebouncedSubmitConfig';
import { uploadImagetoS3 } from '../utils';
import {
  selectWidgetConfiguration,
  setBannerImageConfig,
} from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const BannerTabContent = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const [file, setFile] = React.useState<File>();
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const [dialogSettings, setDialogSettings] = React.useState({
    imageSrc: '',
    isNewImage: false,
    show: false,
  });
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { alt_text, image, is_enabled, link } =
    widgetConfiguration.banner_image_config;

  const debouncedSubmitConfig = useDebouncedSubmitConfigForRTE();

  const handleImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file && file.size > FIVE_MB_IN_BYTES) {
      dispatch(
        setGlobalToastOptions({
          title: 'Image cannot be larger than 5MB.',
          variant: 'danger',
        }),
      );
    } else if (file) {
      setFile(file);
      setDialogSettings({
        imageSrc: URL.createObjectURL(file),
        isNewImage: true,
        show: true,
      });
    }

    // Reset the input so that the user can upload the same image again
    e.target.value = '';
  };

  const handleConfigChange = ({
    config,
    type,
    value,
  }: {
    config: WidgetConfiguration;
    type: string;
    value: string;
  }) => {
    dispatch(
      setBannerImageConfig({
        ...config.banner_image_config,
        image: config.banner_image_config.image || DEFAULT_BANNER_IMAGE,
      }),
    );
    debouncedSubmitConfig(config, type, value);
  };

  const clearDialog = () => {
    setDialogSettings({
      imageSrc: '',
      isNewImage: false,
      show: false,
    });
    setUploadingImage(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        {/* enabled input */}
        <Toggle
          checked={is_enabled}
          label='Banner image is enabled'
          onChange={e => {
            handleConfigChange({
              config: {
                ...widgetConfiguration,
                banner_image_config: {
                  ...widgetConfiguration.banner_image_config,
                  is_enabled: e.target.checked,
                },
              },
              type: 'banner_image_config.is_enabled',
              value: e.target.checked.toString(),
            });
          }}
        />
        {/* image input */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography
            color={theme.palette.colors.black}
            component='h2'
            variant='font16Bold'
          >
            Banner Image
          </Typography>
          <Box
            component='button'
            onClick={() => {
              setDialogSettings({
                imageSrc: image || DEFAULT_BANNER_IMAGE,
                isNewImage: false,
                show: true,
              });
            }}
            sx={{
              cursor: 'pointer',
              padding: 0,
              paddingBlock: 0,
              paddingInline: 0,
            }}
          >
            <Box
              component='img'
              src={image || DEFAULT_BANNER_IMAGE}
              sx={{ borderRadius: '4px', width: '100%' }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                inputRef?.current?.click();
              }}
              variant='ghost'
            >
              Change Image
            </Button>
          </Box>
        </Box>
        {/* redirect input */}
        <Box>
          <TextField
            label='Banner image URL redirect'
            onChange={e => {
              handleConfigChange({
                config: {
                  ...widgetConfiguration,
                  banner_image_config: {
                    ...widgetConfiguration.banner_image_config,
                    link: e.target.value,
                  },
                },
                type: 'banner_image_config.link',
                value: e.target.value,
              });
            }}
            placeholder='e.g. https://forethought.ai'
            value={link || ''}
          />
          <Typography color={theme.palette.colors.grey[400]} variant='font12'>
            Clicking on the banner image will open this URL in a new tab
          </Typography>
        </Box>
      </Box>
      {/* hidden input */}
      <input
        accept='image/*'
        aria-label='Upload banner image'
        hidden
        onChange={handleImageInputChange}
        ref={inputRef}
        type='file'
      />
      {/* dialog */}
      <Dialog
        footer={
          <Button
            disabled={
              !widgetConfiguration.banner_image_config.alt_text ||
              uploadingImage
            }
            isLoading={uploadingImage}
            onClick={() => {
              if (dialogSettings.isNewImage && file) {
                setUploadingImage(true);
                uploadImagetoS3({ description: alt_text || '', file })
                  .then(response => {
                    handleConfigChange({
                      config: {
                        ...widgetConfiguration,
                        banner_image_config: {
                          ...widgetConfiguration.banner_image_config,
                          image: response.public_url,
                        },
                      },
                      type: 'banner_image_config.image',
                      value: response.public_url,
                    });
                  })
                  .catch(() => {
                    dispatch(
                      setGlobalToastOptions({
                        title: 'Failed to upload image',
                        variant: 'danger',
                      }),
                    );
                  })
                  .finally(() => {
                    clearDialog();
                  });
              } else {
                // only alt text was possibly changed and it has already updated
                clearDialog();
              }
            }}
            variant='main'
          >
            Save
          </Button>
        }
        onClose={() => {
          clearDialog();
        }}
        open={dialogSettings.show}
        title='Add banner image'
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Box component='img' src={dialogSettings.imageSrc} />
          {/* alt text input */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <Box>
              <Typography variant='font16Bold'>Alt Text</Typography>
            </Box>
            <Box>
              <TextField
                aria-label='alt text'
                multiline
                onChange={e => {
                  handleConfigChange({
                    config: {
                      ...widgetConfiguration,
                      banner_image_config: {
                        ...widgetConfiguration.banner_image_config,
                        alt_text: e.target.value,
                      },
                    },
                    type: 'banner_image_config.alt_text',
                    value: e.target.value,
                  });
                }}
                placeholder='Write a description of this photo for increased accessibility'
                required
                rows={3}
                value={alt_text || ''}
              />
              <Typography color={theme.palette.grey[500]} variant='font12'>
                Alt text is read aloud to visually impaired users or when the
                image fails to load.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default BannerTabContent;
