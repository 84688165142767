import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { INTENT_QUERY_FILTER_DEBOUNCE_DELAY } from '../constants';
import debounce from 'lodash/fp/debounce';
import { handleUpdateOnQueryParam } from 'src/utils/reactRouterUtils';

export const useGetIntentFilterQuery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('query') ?? '';

  const [uiQuery, setUiQuery] = useState(query);

  const handleOnChange = useCallback(
    (value: string) => {
      handleUpdateOnQueryParam(navigate, { property: 'query', value: value });
    },
    [navigate],
  );

  // Optimizations on search intent keyword
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce(INTENT_QUERY_FILTER_DEBOUNCE_DELAY, (value: string) =>
        handleOnChange(value),
      ),
    [handleOnChange],
  );

  useEffect(() => {
    if (uiQuery !== query) {
      debouncedOnChangeHandler(uiQuery);
    }
  }, [debouncedOnChangeHandler, query, uiQuery]);

  return { setUiQuery, uiQuery };
};
