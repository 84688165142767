import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { EmailBuilderConfigurationKey } from '../types';
import DraggableComponent from './DraggableComponent';
import draggableIconSrc from 'src/assets/images/email-builder-draggable.svg';

export default function DraggableEmailBuilderComponent({
  adornment,
  componentType,
  iconSrc,
  name,
}: {
  adornment?: React.ReactNode;
  componentType: EmailBuilderConfigurationKey;
  iconSrc: string;
  name: string;
}) {
  const { palette } = useTheme();

  return (
    <DraggableComponent componentType={componentType}>
      <DraggableItem>
        {adornment && <AdornmentContainer>{adornment}</AdornmentContainer>}
        <ToolIcon alt={name} src={iconSrc} />
        <Typography color={palette.colors.grey[700]} variant='font14Bold'>
          {name}
        </Typography>
        <DraggableIcon src={draggableIconSrc} />
      </DraggableItem>
    </DraggableComponent>
  );
}

const DraggableItem = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${props => props.theme.palette.colors.slate[200]};
  border-radius: 8px;
  padding: 20px 4px 4px;
  cursor: pointer;
  background-color: ${props => props.theme.palette.colors.white};
  text-align: center;
  height: 100%;
  position: relative;
`;

const AdornmentContainer = styled('div')`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const ToolIcon = styled('img')`
  height: 40px;
  width: 40px;
`;

const DraggableIcon = styled('img')`
  height: 20px;
  width: 20px;
`;
