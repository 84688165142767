import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import HighlightedText from 'src/components/dashboard-pages/assist-notes-page/notes-table/HighlightedText';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { useGetCollectionTemplatesQuery } from 'src/services/action-builder/actionBuilderApi';
import { useAppDispatch } from 'src/store/hooks';
import { onClickTemplateHandlers } from 'src/utils/actionBuilder/helpers';
import { ActionBuilderTemplate } from 'src/utils/constants';

interface CollectionsTabProps {
  searchText: string;
}

const CollectionsTab = ({ searchText }: CollectionsTabProps) => {
  const dispatch = useAppDispatch();
  const { data: actionTemplates, isLoading } = useGetCollectionTemplatesQuery();
  const filteredTemplates = actionTemplates?.templates.filter(({ title }) => {
    const lowerCaseSearchText = searchText.toLowerCase();
    return title.toLowerCase().includes(lowerCaseSearchText);
  });

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!filteredTemplates) {
    return null;
  }

  return (
    <Box display='flex'>
      {/* default templates */}
      {filteredTemplates.map(({ description, title, type, ...rest }) => (
        <TemplateCard
          aria-description={description}
          aria-label={title}
          description={description}
          key={title}
          onClick={() => dispatch(onClickTemplateHandlers[type]())}
          searchText={searchText}
          title={title}
          {...rest}
        />
      ))}
    </Box>
  );
};

const TemplateCard = ({
  description,
  icon,
  onClick,
  searchText,
  title,
}: Omit<
  ActionBuilderTemplate & { onClick: () => void; searchText: string },
  'type'
>) => {
  return (
    <Box
      onClick={onClick}
      sx={theme => ({
        '&:hover': {
          border: `1px solid ${theme.palette.colors.grey[800]}`,
          boxShadow:
            '0px 0px 1px rgba(0, 12, 32, 0.04), 0px 2px 6px rgba(3, 17, 38, 0.107135)',
        },
        border: '1px solid' + theme.palette.colors.grey[100],
        borderRadius: '8px',
        cursor: 'pointer',
        flex: 1,
        maxWidth: '280px',
        mr: 3,
        padding: 3,
      })}
    >
      <Box alignItems='center' display='flex' mb={3}>
        <Box component='img' height='48px' mr={1} src={icon} width='48px' />
        <Box>
          <Typography variant='font16Medium'>
            <HighlightedText searchText={searchText} text={title} />
          </Typography>
        </Box>
      </Box>
      <Box
        sx={theme => ({
          color: theme.palette.text.secondary,
          display: 'inline-flex',
        })}
      >
        <Typography variant='font14'>{description}</Typography>
      </Box>
    </Box>
  );
};

export default CollectionsTab;
