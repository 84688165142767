import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NodeProps } from 'reactflow';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useGetIsParentRuleStep } from '../../hooks/useGetIsParentRuleStep';
import IntentEmailNode from '../IntentEmailNode';
import ConfirmModal from 'src/components/email-builder/components/ConfirmModal';
import {
  deletePath,
  selectEmailWorkflowState,
} from 'src/slices/email-workflow/emailWorkflowSlice';

export default function RuleNode(props: NodeProps) {
  const dispatch = useDispatch();

  const { step } = props.data;
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);

  const [
    isDeleteStepConfirmationDialogVisible,
    setIsDeleteStepConfirmationDialogVisible,
  ] = useState(false);

  const stepId = step?.step_id || '';
  const name = step?.step_fields?.condition_name || '';
  const transitions = step?.transitions || [];

  const shouldAddDeleteCallback = transitions.length > 1;
  const isParentRuleStep = useGetIsParentRuleStep(stepId);

  const onDelete = () => {
    if (emailWorkflow) {
      const { email_workflow_id, last_modified_date, version } = emailWorkflow;
      dispatch(
        deletePath({
          emailWorkflowId: email_workflow_id,
          keepParentTransition: isParentRuleStep,
          lastModifiedDate: last_modified_date,
          stepId: stepId,
          version,
        }),
      );
      closeDialog();
    }
  };

  const closeDialog = () => {
    setIsDeleteStepConfirmationDialogVisible(false);
  };

  return (
    <>
      <IntentEmailNode
        isVerticalMenuVisible
        nodeName={name}
        nodeType='rule'
        onDelete={
          shouldAddDeleteCallback
            ? () => setIsDeleteStepConfirmationDialogVisible(true)
            : undefined
        }
        title='If/Otherwise'
        {...props}
      >
        {name && <Typography variant='font14Bold'>{name}</Typography>}
      </IntentEmailNode>
      <ConfirmModal
        actions={[
          {
            onClick: () => closeDialog(),
            title: 'Cancel',
          },
          {
            onClick: () => onDelete(),
            title: 'Delete',
          },
        ]}
        description='By deleting this node you will also delete all its paths and the subsequent nodes that belong to those paths. This action cannot be undone.'
        isOpen={isDeleteStepConfirmationDialogVisible}
        onClose={() => closeDialog()}
        title='Delete?'
      />
    </>
  );
}
