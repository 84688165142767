import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  Checkbox,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { NONE_RTE_INPUT_DEBOUNCE_DELAY } from '../constants';
import debounce from 'lodash/fp/debounce';
import {
  selectKnowledgeConfiguration,
  setExcludePrivateArticles,
  updateKnowledgeConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { SolveKnowledgeSource } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

export default function ExcludePrivateArticlesCheckbox(): JSX.Element {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const { selected_knowledge_source: knowledgeSource } = useSelector(
    selectKnowledgeConfiguration,
  );

  const debouncedSubmitConfig = useMemo(
    () =>
      debounce(
        NONE_RTE_INPUT_DEBOUNCE_DELAY,
        (config: SolveKnowledgeSource) => {
          dispatch(updateKnowledgeConfiguration(config));
        },
      ),
    [dispatch],
  );

  if (!knowledgeSource) {
    return <></>;
  }

  return (
    <Box display='flex' flexDirection='column' marginTop='10px'>
      <Checkbox
        checked={knowledgeSource?.exclude_private_articles ?? true}
        label='Exclude private articles'
        onChange={e => {
          const checked = e.target.checked;
          dispatch(setExcludePrivateArticles(checked));
          debouncedSubmitConfig({
            ...knowledgeSource,
            exclude_private_articles: checked,
          });
        }}
      />
      <Box pl='36px'>
        <Typography color={palette.colors.grey[600]} variant='font14'>
          Exclude private articles will not surface private articles from your
          knowledge base.
        </Typography>
      </Box>
    </Box>
  );
}
