import React, { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Node, ReactFlowProps, useNodes, useReactFlow } from 'reactflow';
import { Box } from '@mui/system';

import { elevations } from '@forethought-technologies/forethought-elements';
import FlowLayout from './components/flow-layout';
import { DragItem, FlowConfiguration } from './types';
import IntentEmailToggleBar from 'src/pages/workflow-builder-edit/email-builder-page/IntentEmailToggleBar';

export interface ForethoughtFlowProps {
  /**
   * An optional component to show above canvas.
   * This will likely be removed once the journey map becomes the 'default' view for Solve email v2.
   */
  contentOverCanvas?: React.ReactNode;
  /*
  Node types and their configurations
  */
  flowConfiguration: FlowConfiguration;
  /*
  Props to pass down to reactflow.
  */
  flowProps: Partial<ReactFlowProps>;
  /*
  What to show in the sidebar in idle state
  */
  idleSidebarContent: React.ReactNode;
  /**
   * Callback to handle when a node is dropped on the canvas
   */
  onDropCallback?: (
    item: DragItem,
    insertNodeId: string,
    parentStep: Node,
    parentTransitionId: string | null,
  ) => void;
}

export default function ForethoughtFlow({
  contentOverCanvas,
  flowConfiguration,
  flowProps,
  idleSidebarContent,
  onDropCallback,
}: ForethoughtFlowProps) {
  const { setNodes } = useReactFlow();
  const [activeNodeId, setActiveNodeId] = useState<string | null>(null);
  const nodes = useNodes();

  const activeNode = useMemo(() => {
    if (activeNodeId) {
      return nodes.find(({ id }) => id === activeNodeId);
    }
    return null;
  }, [activeNodeId, nodes]);

  const activeNodeConfig = useMemo(() => {
    if (activeNode?.type && flowConfiguration[activeNode.type]) {
      return flowConfiguration[activeNode.type];
    }
    return null;
  }, [activeNode, flowConfiguration]);

  const isSidebarActive = !!(activeNode && activeNodeConfig?.renderSidebar);

  function handleCancel() {
    setActiveNodeId(null);
    setNodes(nodes =>
      nodes.map(node => ({
        ...node,
        selected: false,
      })),
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display='flex' height='100%'>
        <Box display='flex' flex='1' flexDirection='column' position='relative'>
          {contentOverCanvas && (
            <Box
              sx={{
                left: '50%',
                position: 'absolute',
                transform: 'translateX(-50%)',
                width: '100%',
                zIndex: 1,
              }}
            >
              {contentOverCanvas}
            </Box>
          )}
          <FlowLayout
            flowConfiguration={flowConfiguration}
            flowProps={{
              ...flowProps,
              onSelectionChange: ({ nodes }) =>
                setActiveNodeId(nodes[0]?.id ?? null),
            }}
            onDropCallback={onDropCallback}
          />
        </Box>
        <Box
          sx={theme => ({
            background: theme.palette.colors.white,
            boxShadow: elevations.z1,
            overflow: 'scroll',
            width: isSidebarActive ? '700px' : '400px',
          })}
        >
          <IntentEmailToggleBar />
          {isSidebarActive
            ? activeNodeConfig.renderSidebar?.(activeNode, handleCancel)
            : idleSidebarContent}
        </Box>
      </Box>
    </DndProvider>
  );
}
