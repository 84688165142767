import React from 'react';
import moment from 'moment';
import { Box, MenuItem, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import VersionActionMenu from './VersionActionMenu';
import VersionEditDialog from './VersionEditDialog';
import { AutoflowVersionHistory } from 'src/types/workflowBuilderAPITypes';

interface VersionCardProps {
  showIndicator?: boolean;
  versionHistory: AutoflowVersionHistory;
}

const VersionCard = ({
  showIndicator = false,
  versionHistory,
}: VersionCardProps) => {
  const { palette } = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) =>
    setMenuAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setMenuAnchorEl(null);

  const isDraft = versionHistory.is_draft;

  const handleRenderHeader = () => {
    if (versionHistory.version_name) {
      return (
        <Typography color={palette.colors.black} noWrap variant='font14'>
          {versionHistory.version_name}
        </Typography>
      );
    }

    return (
      <Typography color={palette.colors.black} variant='font14'>
        {moment(versionHistory.modified_date).format('lll')}
      </Typography>
    );
  };

  return (
    <>
      <Box
        sx={{
          '&:hover': {
            bgcolor: isDraft
              ? palette.colors.blue[200]
              : palette.colors.blue[100],
          },
          alignItems: 'center',
          bgcolor: isDraft ? palette.colors.blue[200] : palette.colors.white,
          display: 'flex',
          padding: '20px',
          placeContent: 'space-between',
        }}
      >
        <Box width='220px'>
          <Box display='flex'>{handleRenderHeader()}</Box>
          {versionHistory.description && (
            <Box display='flex'>
              <Typography
                color={palette.colors.grey[600]}
                noWrap
                variant='font12'
              >
                {versionHistory.description}
              </Typography>
            </Box>
          )}
          <Box display='flex'>
            <Typography color={palette.colors.black} noWrap variant='font12'>
              {versionHistory.updated_by}
            </Typography>
          </Box>
          {showIndicator && (
            <Box alignItems='center' display='flex' gap='4px'>
              <ActivatedIcon
                bgcolor={
                  isDraft ? palette.colors.grey[500] : palette.colors.green[500]
                }
              />
              <Typography color={palette.colors.black} variant='font12Medium'>
                {isDraft ? 'Draft' : 'Live'}
              </Typography>
            </Box>
          )}
        </Box>
        <VersionActionMenu
          handleClickMenu={handleClickMenu}
          handleCloseMenu={handleCloseMenu}
          menuAnchorEl={menuAnchorEl}
          setIsEditDialogOpen={setIsEditDialogOpen}
        />
      </Box>
      <VersionEditDialog
        handleCloseMenu={handleCloseMenu}
        isEditDialogOpen={isEditDialogOpen}
        setIsEditDialogOpen={setIsEditDialogOpen}
        versionHistory={versionHistory}
      />
    </>
  );
};

const ActivatedIcon = styled(Box)`
  height: 8px;
  width: 8px;
  border-radius: 50%;
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    background-color: ${props => props.theme.palette.colors.purple[100]};
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export default VersionCard;
