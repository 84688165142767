import { useMemo } from 'react';
import { useTheme } from '@mui/material';

import AggregateMetrics, {
  AggregateMetric,
} from '../workflow-builder/aggregate-metrics/AggregateMetrics';
import useIsDeflectionInsightsEnabled from './hooks/useIsDeflectionInsightsEnabled';
import { SHARED_METRIC_DEFINITIONS, TEMP_BLUE } from 'src/constants/solve';
import { NA } from 'src/constants/solve';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import { ConversationAggregateMetricsResponse } from 'src/services/apiInterfaces';
import { formatNumberWithPercentage } from 'src/utils/solve/deflectionInsightsUtils';

const OverallAnalytics = ({
  data,
  hideCSAT,
  isLoading,
  showOnlyCSAT,
}: {
  data?: ConversationAggregateMetricsResponse;
  hideCSAT: boolean;
  isLoading: boolean;
  showOnlyCSAT: boolean;
}) => {
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const { palette } = useTheme();
  const lightBlue = palette.colors.blue[400];
  const red = palette.colors.red[500];

  const getCsatColor = useGetCSATColor();

  const metrics = useMemo(() => {
    const enabledMetrics: AggregateMetric[] = [];

    const getDeflectionRate = () => {
      if (data && data.num_deflected && data.num_conversations) {
        return (data.num_deflected / data.num_conversations).toLocaleString(
          undefined,
          {
            maximumFractionDigits: 1,
            style: 'percent',
          },
        );
      }
      return NA;
    };

    if (!showOnlyCSAT) {
      enabledMetrics.push({
        label: SHARED_METRIC_DEFINITIONS.totalChats.label,
        tooltip: SHARED_METRIC_DEFINITIONS.totalChats.tooltip,
        value: data?.num_conversations?.toLocaleString('en-US') || String(0),
      });

      if (isDeflectionInsightsEnabled && data?.num_deflected) {
        enabledMetrics.push({
          label: 'Deflections',
          tooltip: SHARED_METRIC_DEFINITIONS.totalDeflectedChats.tooltip,
          value: formatNumberWithPercentage(
            data.num_deflected,
            data?.num_conversations,
          ),
        });
      } else {
        enabledMetrics.push(
          {
            label: SHARED_METRIC_DEFINITIONS.totalDeflectedChats.label,
            tooltip: SHARED_METRIC_DEFINITIONS.totalDeflectedChats.tooltip,
            value: data?.num_deflected?.toLocaleString('en-US') || String(0),
          },
          {
            label: SHARED_METRIC_DEFINITIONS.deflectionRate.label,
            tooltip: SHARED_METRIC_DEFINITIONS.deflectionRate.tooltip,
            value: getDeflectionRate(),
          },
        );
      }
    }

    if (!hideCSAT) {
      enabledMetrics.push({
        color: getCsatColor(data?.avg_csat_score),
        label: SHARED_METRIC_DEFINITIONS.avgCSAT.label,
        tooltip: SHARED_METRIC_DEFINITIONS.avgCSAT.tooltip,
        value: data?.avg_csat_score ? data.avg_csat_score.toFixed(1) : NA,
      });
    }

    if (isDeflectionInsightsEnabled) {
      enabledMetrics.push({
        ...SHARED_METRIC_DEFINITIONS.relevance,
        value: [
          {
            ...SHARED_METRIC_DEFINITIONS.relevant,
            color: TEMP_BLUE,
            value:
              data?.relevance_breakdown?.relevant_count?.toLocaleString() ?? NA,
          },
          {
            ...SHARED_METRIC_DEFINITIONS.somewhatRelevant,
            color: lightBlue,
            value:
              data?.relevance_breakdown?.somewhat_relevant_count?.toLocaleString() ??
              NA,
          },
          {
            ...SHARED_METRIC_DEFINITIONS.irrelevant,
            color: red,
            value:
              data?.relevance_breakdown?.irrelevant_count?.toLocaleString() ??
              NA,
          },
        ],
      });
      enabledMetrics.push({
        ...SHARED_METRIC_DEFINITIONS.userEngagement,
        color: TEMP_BLUE,
        value: data?.dropoff_breakdown
          ? formatNumberWithPercentage(
              data.dropoff_breakdown.no_dropoff_count,
              data.dropoff_breakdown.no_dropoff_count +
                data.dropoff_breakdown.dropoff_count,
            )
          : NA,
      });
    }
    return enabledMetrics;
  }, [
    showOnlyCSAT,
    hideCSAT,
    isDeflectionInsightsEnabled,
    data,
    lightBlue,
    red,
    getCsatColor,
  ]);

  return (
    <AggregateMetrics
      metrics={metrics}
      numLoadingSkeletons={isLoading ? 4 : undefined}
    />
  );
};

export default OverallAnalytics;
