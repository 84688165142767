import { useState } from 'react';
import Box from '@mui/material/Box';

import { IntegrationTabProps } from '../types';
import EditableSelectField from './EditableSelectField';
import IntegrationErrorInfo from './IntegrationErrorInfo';

const integrationType = 'zendesk';

const IntegrationZendesk = ({
  integrationSelectedState,
  isDisabled = false,
  onChange,
  options,
  predictedFieldValue,
}: IntegrationTabProps) => {
  const [isNewField, setIsNewField] = useState(false);

  const handleSelectChange = ({
    inputValue,
    selectValue,
  }: {
    inputValue: string;
    selectValue: string;
  }) => {
    onChange({
      input: inputValue,
      integrationType,
      isNewField,
      select: selectValue,
    });
  };

  const handleToggle = (isInput: boolean) => {
    setIsNewField(isInput);
  };

  const optionItems: { label: string; value: string }[] = options.map(item => ({
    label: item.label || '',
    value: item.value || '',
  }));

  const disabled = integrationSelectedState === 'loading' || isDisabled;
  const hasError = integrationSelectedState === 'error';

  return (
    <Box display='flex' flexDirection='column' rowGap={4}>
      <Box display='flex' flexDirection='column' rowGap={1}>
        <EditableSelectField
          disabled={disabled}
          onChange={handleSelectChange}
          onToggleCallback={handleToggle}
          options={optionItems}
          tooltip='This model will output to this field in the selected integration'
          value={predictedFieldValue}
        />
        {hasError && <IntegrationErrorInfo />}
      </Box>
    </Box>
  );
};

export default IntegrationZendesk;
