import { OrgConfigResponse } from '../apiInterfaces';
import { dashboardApi } from '../dashboard-api';

const settingsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getOrgConfig: builder.query<OrgConfigResponse, void>({
      query: () => `${API_URL}org-config`,
    }),
  }),
});

export const { useGetOrgConfigQuery } = settingsApi;
