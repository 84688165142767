import Box from '@mui/material/Box';

import { Avatar, Badge } from '@forethought-technologies/forethought-elements';
import HighlightedText from './HighlightedText';
import { AssistNote } from 'src/slices/assist-notes/types';

interface AgentEmailCellProps {
  data: AssistNote;
  searchText?: string;
}

const AgentEmailCell = ({ data, searchText }: AgentEmailCellProps) => {
  const { agent_email: agentEmail, created_by_role: createdByRole } = data;
  const createdByAdmin =
    createdByRole === 'ADMIN' || createdByRole === 'SUPER_ADMIN';

  return (
    <Box alignItems='center' display='flex' width='100%'>
      <Avatar variant='xs'>{data.agent_email}</Avatar>
      <Box
        sx={{
          fontSize: 14,
          minWidth: 0,
          mx: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        <HighlightedText searchText={searchText} text={agentEmail} />
      </Box>
      {createdByAdmin && <Badge label='Admin' variant='macro' />}
    </Box>
  );
};

export default AgentEmailCell;
