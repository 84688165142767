import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

import {
  Button,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import AutomationCard from './automation-card';
import CardSkeleton from 'src/components/card/CardSkeleton';
import { Automation } from 'src/services/apiInterfaces';
import { breakpoints } from 'src/styles/breakpoints';
import { Routes } from 'src/utils/enums';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  background-color: ${theme.palette.colors.white};
  min-height: calc(100vh - 57px);
`;

const AutomationsContainer = styled('div')`
  padding: 25px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 0 30px 8px 0;
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 8px 0;
  }
`;

const TitleWrapper = styled('div')`
  @media ${breakpoints.mobile} {
    margin-bottom: 25px;
  }
`;

const Title = styled('h2')`
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 4px;
  line-height: 26px;
`;

const AssistAutomationsHome: React.FC<
  React.PropsWithChildren<{
    automations: Array<Automation>;
    isLoading: boolean;
  }>
> = ({ automations, isLoading }) => {
  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>Automations</Title>
          <Typography color={theme.palette.colors.grey[500]} variant='font12'>
            Active and Inactive workflows used by your team
          </Typography>
        </TitleWrapper>

        <StyledLink to={`${Routes.ASSIST_AUTOMATIONS_CREATE}`}>
          <Button
            size='medium'
            startIcon={<AddIcon fontSize='small' />}
            variant='secondary'
          >
            New Automation
          </Button>
        </StyledLink>
      </Header>
      <AutomationsContainer>
        {isLoading
          ? Array(6)
              .fill(null)
              .map((_, i) => <CardSkeleton key={i} />)
          : automations.map(automation => (
              <AutomationCard
                automation={automation}
                key={automation.automation_id}
              />
            ))}
      </AutomationsContainer>
    </Container>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default AssistAutomationsHome;
