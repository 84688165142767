import React from 'react';
import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { signUpText } from './constants';
import PaperplaneGif from 'src/assets/images/paperplane.gif';

const SignUpSuccess: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Box alignItems='center' display='flex' flexDirection='column'>
      <PaperPlane src={PaperplaneGif} />
      <Box mb='16px'>
        <Typography variant='font32'>{signUpText.successTitle}</Typography>
      </Box>
      <Typography variant='font16'>{signUpText.successSubtitle}</Typography>
    </Box>
  );
};

export default SignUpSuccess;

const PaperPlane = styled('img')`
  width: 50%;
  height: 50%;
`;
