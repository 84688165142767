export interface SolveConfigState {
  handoff: {
    data: HandoffConfiguration;
    error: string | null;
    loading: boolean;
  };
  isInitialized: boolean;
  isPublishing: boolean;
  isSolveLiteEnabled: boolean;
  knowledge: {
    data: KnowledgeConfiguration;
    error: string | null;
    loading: boolean;
  };
  liveVersionExists: boolean;
  liveVersionIsActive: boolean;
  uiLoadingState: {
    isGenericFieldsLoading: boolean;
    isHighFrequencyFieldsLoading: boolean;
  };
  validationState: {
    isGreetingWarningModalVisible: boolean;
    isHandoffWarningModalVisible: boolean;
    isKnowledgePromptWarningModalVisible: boolean;
    isPromptWarningModalVisible: boolean;
    validationFailure: ValidationFailure | null;
  };
  widget: {
    data: WidgetConfiguration;
    error: string | null;
    loading: boolean;
  };
}

export interface WidgetConfigurationResponse {
  live_version_exists: boolean;
  live_version_is_active: boolean;
  solve_widget_configuration: WidgetConfiguration;
}

export interface TopIntent {
  intent_id: string;
  intent_name: string;
}

export interface BannerImageConfig {
  alt_text: string | null;
  image: string | null;
  is_enabled: boolean;
  link: string | null;
}

export interface WidgetConfiguration {
  agent_chat_image: string | null;
  banner_image_config: BannerImageConfig;
  csat_config: CsatConfig;
  display_proactive_chat: boolean;
  display_proactive_chat_intents: boolean;
  free_form_intent_detection_enabled: boolean;
  greeting_line: string;
  header_image: string | null;
  help_button_image: string | null;
  is_active: boolean;
  is_draft: boolean;
  is_modified: boolean;
  knowledge_configuration_prompt: string;
  manually_selected_top_intents: string[];
  num_top_intents: number;
  org_id: number;
  privacy_consent_config: PrivacyConsentConfig;
  proactive_chat_greeting: string | null;
  quick_feedback_config: QuickFeedbackConfig;
  tab_name: string;
  tag_selection_enabled: boolean;
  theme_color: string;
  top_n_intents: TopIntent[] | null;
  use_help_button_image_as_icon: boolean;
  whitelisted_domains: string[];
}

export interface PrivacyConsentConfig {
  background_color: string;
  call_to_action_label: string;
  is_banner_dismissible: boolean;
  placement: 'top' | 'bottom';
  privacy_policy: string;
  prompt_header: string;
  show_when_conversation_starts: boolean;
  style: 'call_to_action' | 'sticky_banner';
}

export interface CsatConfig {
  is_enabled: boolean;
  negative_rating_routing_intent?: string;
  no_resolve_option: string;
  one_to_five_labels: string[];
  options_for_resolution_confirmation: string[];
  question_for_additional_feedback: string;
  question_for_high_ratings: string;
  question_for_low_ratings: string;
  question_for_resolution_confirmation: string;
  rating_question: string;
  reasons_for_high_ratings: string[];
  reasons_for_low_ratings: string[];
  scale: 'one_to_two' | 'one_to_five';
  should_request_additional_feedback: boolean;
  should_request_feedback_for_high_ratings: boolean;
  should_request_feedback_for_low_ratings: boolean;
  should_request_resolution_confirmation: boolean;
  should_show_submit_confirmation_message: boolean;
  should_use_rating_based_submit_message: boolean;
  show_on_header: boolean;
  show_when_agent_chat_ends: boolean;
  show_when_widget_minimizes: boolean;
  style: 'star' | 'smile' | 'bw_smile' | 'thumbs';
  submit_confirmation_message: string;
  submit_message_for_high_ratings: string;
  submit_message_for_low_ratings: string;
  yes_resolve_option: string;
}

export interface QuickFeedbackConfig {
  feedback_prompt: string;
  hover_label_bad: string;
  hover_label_good: string;
  is_enabled: boolean;
  negative_routing_intent_workflow_id: string | null;
  question_for_high_ratings: string;
  question_for_low_ratings: string;
  should_request_feedback_for_high_ratings: boolean;
  should_request_feedback_for_low_ratings: boolean;
  style: string;
}

export interface AcknowledgedResponse {
  acknowledged: boolean;
}

export interface HandoffConfiguration {
  handoff_message: string;
  is_modified: boolean;
}

export interface SolveKnowledgeSource {
  custom_url?: string;
  exclude_private_articles?: boolean;
  integration_display_name?: string;
  integration_name: string;
  last_sync_date?: string;
  status?: string;
}

export interface KnowledgeConfiguration {
  available_knowledge_sources: SolveKnowledgeSource[];
  is_modified: boolean;
  selected_knowledge_source?: SolveKnowledgeSource;
}

export enum ValidationFailure {
  EMPTY_GREETING = 'empty_greeting',
  EMPTY_HANDOFF = 'empty_handoff',
  EMPTY_PROMPT = 'empty_prompt',
  KNOWLEDGE_PROMPT_TOO_LONG = 'knowledge_prompt_too_long',
}
