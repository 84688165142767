import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { LinkPreviewConfig } from 'src/components/rich-text-editor/types';
import CustomMessage from 'src/pages/workflow-builder-edit/handoff-configuration/catch-all/CustomMessage';
import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { textMediumStyle } from 'src/styles/styledMixin';
import { CatchAllHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface HandoffConfigurationProps {
  customizationData: CatchAllHandoffCustomization;
  linkPreviewConfig?: LinkPreviewConfig;
}

const CatchAllHandoffConfiguration: FC<
  React.PropsWithChildren<HandoffConfigurationProps>
> = ({ customizationData, linkPreviewConfig }) => {
  const canvasState = useSelector(selectCanvasWorkflowBuilder);

  return (
    <>
      {canvasState.isLoadingCustomization ? (
        <LoadingSkeleton />
      ) : (
        <ContentWrapper>
          <Content>
            <Title>Handoff</Title>
            <Spacer height='20px' />
            <StyledSection>
              <CustomMessage
                customizationData={customizationData}
                linkPreviewConfig={linkPreviewConfig}
              />
            </StyledSection>
          </Content>
        </ContentWrapper>
      )}
    </>
  );
};

export default CatchAllHandoffConfiguration;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  max-height: calc(100vh - 180px);
`;

const Content = styled('div')`
  margin-top: 0;
  margin-bottom: 50px;
  overflow-y: auto;
`;

const StyledSection = styled(Section)`
  padding: 15px;
  box-sizing: border-box;
`;
