import { Box, styled, Theme, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA, USER_ENGAGEMENT_COPY } from '../constants';
import { Conversation } from 'src/services/apiInterfaces';

type Variant = 'positive' | 'negative' | 'mixed' | 'default';

const metricToVariant = (
  metric: Conversation['dropoff_rating'] | Conversation['relevance_rating'],
): Variant => {
  if (typeof metric === 'boolean') {
    return metric ? 'negative' : 'positive';
  }

  switch (metric) {
    case 'relevant':
      return 'positive';
    case 'irrelevant':
      return 'negative';
    case 'somewhat relevant':
      return 'mixed';
    default:
      return 'default';
  }
};

const variantToBgColor = ({
  deflectionVariant,
  theme,
}: {
  deflectionVariant: Variant;
  theme: Theme;
}) => {
  // TODO get from theme
  const blue = '#003BD2';
  switch (deflectionVariant) {
    case 'positive':
      return blue;
    case 'negative':
      return theme.palette.colors.red[500];
    case 'mixed':
      return theme.palette.colors.blue[400];
    default:
      return theme.palette.colors.grey[500];
  }
};

const metricToLabel = (
  metric: Conversation['dropoff_rating'] | Conversation['relevance_rating'],
) => {
  if (typeof metric === 'boolean') {
    return metric ? USER_ENGAGEMENT_COPY.no : USER_ENGAGEMENT_COPY.yes;
  }

  return metric;
};

export const DeflectionInsightCell = ({
  isProcessing = false,
  metric,
}: {
  isProcessing?: boolean;
  metric: Conversation['dropoff_rating'] | Conversation['relevance_rating'];
}) => {
  const { palette } = useTheme();

  if (!isProcessing && (metric === null || metric === undefined)) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font14'>
        {NA}
      </Typography>
    );
  }

  return (
    <Box alignItems='center' display='flex'>
      <DeflectionBadge deflectionVariant={metricToVariant(metric)}>
        <Typography color={palette.colors.white} noWrap variant='font11'>
          {isProcessing ? 'Processing' : metricToLabel(metric)}
        </Typography>
      </DeflectionBadge>
    </Box>
  );
};

const DeflectionBadge = styled('div')<{ deflectionVariant: Variant }>`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  text-transform: capitalize;
  background-color: ${variantToBgColor};
  border-radius: 4px;
`;
