import { DateRange } from '../../discover-dashboard-page/types';
import { InsightPeriod, InsightSortDirection } from '../types';
import { InsightTopicData, InsightTopicDataColumnKeys } from './types';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';
export type InsightTopicSortColumns = 'times_surfaced';

export const initialInsightTopicParams: {
  date: DateRange;
  period: InsightPeriod;
  topicColumns: InsightTopicDataColumnKeys[];
  topicColumnSort: InsightTopicSortColumns;
  topicColumnSortDirection: InsightSortDirection;
} = {
  date: last30DaysTimeRange,
  period: 'weekly',
  topicColumns: [
    'chats_count',
    'non_deflected_chats_count',
    'deflected_chats_count',
    'csat',
    'workflows',
    'related_articles',
    'relevance_breakdown',
    'dropoff_breakdown',
    'engagement_rate',
    'realized_saving',
    'potential_saving',
  ],
  topicColumnSort: 'times_surfaced',
  topicColumnSortDirection: 'DESC',
};

export const TABLE_COLUMN_TO_LABEL_MAP: {
  topic: Record<InsightTopicDataColumnKeys, string>;
} = {
  topic: {
    chats_count: 'Chats',
    csat: 'Avg. CSAT',
    deflected_chats_count: 'Deflections',
    dropoff_breakdown: 'User engagement',
    engagement_rate: 'Engagement rate',
    insight_subtopics: 'Topics',
    non_deflected_chats_count: 'Non-deflections',
    parentTopicName: '',
    potential_saving: 'Potential saving',
    realized_saving: 'Realized saving',
    related_articles: 'Top 3 surfaced articles',
    relevance_breakdown: 'Relevance',
    type: 'type',
    workflows: 'Top 3 surfaced workflows',
  },
};

export const TABLE_COLUMN_TO_TOOLTIP_MAP: {
  topic: Record<InsightTopicDataColumnKeys, string>;
} = {
  topic: {
    chats_count: '',
    csat: 'The average CSAT scores of chats in which the chat topics were detected.',
    deflected_chats_count: '',
    dropoff_breakdown:
      'User engagement uses AI to determine whether the user remained engaged through the chat or dropped off mid-way through the conversation. Each deflected chat receives one rating. No rating is offered for non-deflected chats.',
    engagement_rate: '',
    insight_subtopics: '',
    non_deflected_chats_count: '',
    parentTopicName: '',
    potential_saving:
      'Maximum potential savings you could have realized for this topic with Discover automation.',
    realized_saving: 'PLACEHOLDER',
    related_articles:
      'Top 3 articles surfaced in chats categorized under this topic.',
    relevance_breakdown: '',
    type: '',
    workflows:
      'Top 3 workflows applied in response to chats categorized under this topic.',
  },
};

export const TABLE_COLUMNS: {
  topics: { label: string; value: keyof InsightTopicData }[];
} = {
  topics: [
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.topic.chats_count,
      value: 'chats_count',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.topic.deflected_chats_count,
      value: 'deflected_chats_count',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.topic.non_deflected_chats_count,
      value: 'non_deflected_chats_count',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.topic.csat,
      value: 'csat',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.topic.workflows,
      value: 'workflows',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.topic.related_articles,
      value: 'related_articles',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.topic.realized_saving,
      value: 'realized_saving',
    },
    {
      label: TABLE_COLUMN_TO_LABEL_MAP.topic.potential_saving,
      value: 'potential_saving',
    },
  ],
};

export const ACTION_COLUMN_ID = 'action';

export const TOPIC_COLOR_ARRAY = [
  '#7B33FB',
  '#CC2A9B',
  '#2C57E9',
  '#53BFC4',
  '#C37E29',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

/**
 * Even - solid lines
 *
 * Odd - dashed lines
 */
export const OVERVIEW_OVERTIME_COLOR_ARRAY = [
  '#7B33FB',
  '#C89CFF',

  '#CC2A9B',
  '#E26ABD',

  '#2C57E9',
  '#7794F2',

  '#53BFC4',
  '#91D8DB',

  '#C37E29',
  '#E0A761',

  '#FB800E',
  '#FEAC60',

  '#7B33FB',
  '#C89CFF',

  '#CC2A9B',
  '#E26ABD',
];

export const INFINITE_TABLE_COLUMNS: {
  accessorKey: InsightTopicDataColumnKeys | 'cluster_name';
  header: string;
  minSize?: number;
  size?: number;
}[] = [
  {
    accessorKey: 'cluster_name',
    header: 'Topics',
    minSize: 200,
  },
  {
    accessorKey: 'chats_count',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.chats_count,
  },
  {
    accessorKey: 'deflected_chats_count',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.deflected_chats_count,
  },
  {
    accessorKey: 'non_deflected_chats_count',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.non_deflected_chats_count,
  },
  {
    accessorKey: 'csat',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.csat,
  },
  {
    accessorKey: 'workflows',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.workflows,
    size: 300,
  },
  {
    accessorKey: 'related_articles',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.related_articles,
    size: 300,
  },
  {
    accessorKey: 'relevance_breakdown',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.relevance_breakdown,
    size: 300,
  },
  {
    accessorKey: 'dropoff_breakdown',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.dropoff_breakdown,
    size: 300,
  },
  {
    accessorKey: 'engagement_rate',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.engagement_rate,
    size: 220,
  },
  {
    accessorKey: 'realized_saving',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.realized_saving,
    size: 220,
  },
  {
    accessorKey: 'potential_saving',
    header: TABLE_COLUMN_TO_LABEL_MAP.topic.potential_saving,
    size: 220,
  },
];

export const INITIAL_SORTING = [{ desc: true, id: 'chats_count' }];

export const WORKFLOW_DETAIL_COLOR_ARRAY = [
  '#7B33FB',
  '#CC2A9B',
  '#2C57E9',
  '#53BFC4',
  '#C37E29',
  '#FB800E',
  '#C89CFF',
  '#E26ABD',
  '#7794F2',
  '#91D8DB',
  '#E0A761',
  '#FEAC60',
];
