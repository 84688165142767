import React, { useMemo, useState } from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Drawer,
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useFallbackIntentForChannel } from '../hooks';
import AutosizeIframe from 'src/components/autosize-iframe/AutosizeIframe';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  getCreateIntentUrl,
  getWorkflowBuilderUrl,
  openTab,
} from 'src/pages/intent-conversation-analytics/helpers';
import IntentBadge from 'src/pages/intent-conversation-analytics/IntentBadge';
import {
  IntentPredictionRecord,
  isEmailConversation,
  isEmailInquiry,
} from 'src/services/intent-tests/types';
import { useGetIntentsQuery } from 'src/services/workflow-builder-metrics';
import {
  channelToDisplayName,
  ConversationChannel,
} from 'src/types/workflowBuilderAPITypes';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';
import { formatReadableDatetime } from 'src/utils/dateUtils';
import { isIntentEditable } from 'src/utils/solve/intentUtils';

export default function IntentPredictionDrawer({
  feedbackButtons,
  onClose,
  predictionRecord,
}: {
  feedbackButtons?: React.ReactNode;
  onClose: () => void;
  predictionRecord?: IntentPredictionRecord;
}) {
  const isOpen = !!predictionRecord;

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      {isOpen && (
        <DrawerBody
          feedbackButtons={feedbackButtons}
          predictionRecord={predictionRecord}
        />
      )}
    </Drawer>
  );
}

function DrawerBody({
  feedbackButtons,
  predictionRecord,
}: {
  feedbackButtons?: React.ReactNode;
  predictionRecord: IntentPredictionRecord;
}) {
  const { conversation, inquiry, intent_prediction } = predictionRecord;
  const { palette } = useTheme();
  const [selectedIntentId, setSelectedIntentId] = useState('');
  const { data: intentsResponse } = useGetIntentsQuery();

  const isEmail = isEmailInquiry(inquiry);
  const channel: ConversationChannel = isEmail ? 'email' : 'widget';
  const userInquiry =
    (isEmail && inquiry.summarizer_output) || (!isEmail && inquiry.user_input);

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const emailResponse = useMemo(() => {
    if (!isEmailConversation(conversation)) {
      return null;
    }
    if (!contextVariables.length || !conversation.email_response_html) {
      return null;
    }
    return contextVariableAliasHtml({
      contextVariables,
      html: conversation.email_response_html,
      undefinedContextVariableIds: [],
    });
  }, [contextVariables, conversation]);

  const assignPhraseToSelectedIntent = async (phrase: string) => {
    const intent = intentsResponse?.intents.find(
      intent => intent.intent_definition_id === selectedIntentId,
    );
    if (!intent) {
      return;
    }
    const url = getWorkflowBuilderUrl({
      channel,
      initialIntentPhrase: phrase,
      intentDefinitionId: intent.intent_definition_id,
      intentWorkflowId: intent.intent_workflow_id,
    });
    openTab(url);
  };

  const handleCreateIntent = async (phrase: string) => {
    const url = getCreateIntentUrl(phrase, channel);
    openTab(url);
  };

  const intentOptions = useMemo(() => {
    if (!intentsResponse) {
      return [];
    }

    return intentsResponse.intents
      .filter(intent => isIntentEditable(intent.intent_definition_id))
      .map(intent => ({
        label: intent.intent_name,
        value: intent.intent_definition_id,
      }));
  }, [intentsResponse]);

  const fallbackIntent = useFallbackIntentForChannel(channel);

  const intentDefinitionId =
    intent_prediction?.intent_definition_id ||
    fallbackIntent?.intent_definition_id;
  const intentName = intent_prediction?.title || fallbackIntent?.intent_name;

  return (
    <Box p='40px'>
      <Box py='6px'>
        <Typography variant='font24'>Details</Typography>
      </Box>
      <Box py='24px'>
        <Box display='flex' flexDirection='column' gap='30px'>
          <Box display='flex' flexWrap='wrap' gap='30px'>
            <Box display='flex' flex={1} flexDirection='column' gap='2px'>
              <Typography color={palette.colors.grey[600]} variant='font11'>
                Channel
              </Typography>
              <Typography variant='font14'>
                {channelToDisplayName[channel]}
              </Typography>
            </Box>
            <Box display='flex' flex={1} flexDirection='column' gap='2px'>
              <Typography color={palette.colors.grey[600]} variant='font11'>
                Date created
              </Typography>
              <Typography variant='font14'>
                {formatReadableDatetime(new Date(inquiry.created_date))}
              </Typography>
            </Box>
            <Box
              display='flex'
              flex={`1 0 ${isEmail ? 'auto' : '100%'}`}
              flexDirection='column'
              gap='2px'
            >
              <Typography color={palette.colors.grey[600]} variant='font11'>
                {isEmail ? 'Ticket' : 'Chat'} ID
              </Typography>
              <Typography variant='font14'>
                {isEmail ? inquiry.source_id : inquiry.conversation_id}
              </Typography>
            </Box>
          </Box>

          {userInquiry && (
            <Box display='flex' flex={1} flexDirection='column' gap='4px'>
              <Typography color={palette.colors.grey[600]} variant='font11'>
                {`${isEmail ? 'Summarized' : 'User'} inquiry`}
              </Typography>
              <Typography variant='font14'>
                &quot;
                {userInquiry}
                &quot;
              </Typography>
            </Box>
          )}

          <Box display='flex' flexDirection='column' gap='10px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[600]} variant='font11'>
                Predicted intent
              </Typography>
            </Box>
            <Card>
              <Box
                alignItems='center'
                display='flex'
                justifyContent='space-between'
              >
                <IntentBadge
                  channel={channel}
                  intentDefinitionId={intentDefinitionId}
                  intentName={intentName}
                />
                {feedbackButtons}
              </Box>
              {userInquiry && (
                <Box display='flex' flexDirection='column' gap='16px' pt='8px'>
                  <TextDivider
                    size='small'
                    text='Reassign to new or existing intent'
                  />
                  <Button
                    fullWidth
                    onClick={() => handleCreateIntent(userInquiry)}
                    startIcon={<IconPlus size={16} />}
                    variant='secondary'
                  >
                    Define a New Intent
                  </Button>
                  <Form
                    onSubmit={e => {
                      e.preventDefault();
                      assignPhraseToSelectedIntent(userInquiry);
                    }}
                  >
                    <SelectDropdown
                      id='assign-intent-selector'
                      isMenuSearchable
                      menuMaxHeight={360}
                      onChange={e => setSelectedIntentId(e.target.value)}
                      options={intentOptions}
                      placeholder='Assign to an existing intent'
                      value={selectedIntentId}
                    />
                    <Button
                      disabled={!selectedIntentId}
                      fullWidth
                      type='submit'
                      variant='secondary'
                    >
                      Assign
                    </Button>
                  </Form>
                </Box>
              )}
            </Card>
          </Box>
          {emailResponse && (
            <Box display='flex' flexDirection='column' gap='10px'>
              <Typography variant='font11'>Email Response</Typography>
              <Card>
                <AutosizeIframe padding={20} srcDoc={emailResponse} />
              </Card>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const Card = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  padding: 16px;
`;

const TextDivider = ({
  size,
  text,
}: {
  size: 'small' | 'large';
  text: string;
}) => {
  return (
    <Box alignItems='center' display='flex' width='100%'>
      <Divider />
      <Box px='16px' whiteSpace='nowrap'>
        <Typography variant={size === 'small' ? 'font12' : 'font14Bold'}>
          {text}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
`;

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
