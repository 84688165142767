import { FormProps } from '@rjsf/core';

import CreateIntegrationForm from '../integration-form/create-integration-form/CreateIntegrationForm';
import UpdateIntegrationForm from '../integration-form/update-integration-form/UpdateIntegrationForm';
import {
  Connector,
  ConnectorDefinition,
  Credentials,
} from 'src/services/apiInterfaces';

interface IntegrationDrawerBodyProps {
  connectorDefinition: ConnectorDefinition;
  formProps: FormProps<Credentials>;
  selectedConnector?: Connector;
}

export default function IntegrationDrawerBody({
  connectorDefinition,
  formProps,
  selectedConnector,
}: IntegrationDrawerBodyProps) {
  return selectedConnector && connectorDefinition.setup_type !== 'OAUTH' ? (
    <UpdateIntegrationForm
      selectedConnector={selectedConnector}
      {...formProps}
    />
  ) : (
    <CreateIntegrationForm
      connectorDefinition={connectorDefinition}
      oauthConnector={selectedConnector}
      {...formProps}
    />
  );
}
