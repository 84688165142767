import React from 'react';
import { Box, useTheme } from '@mui/material';

interface ArticlesPageLayoutProps {
  cards: React.ReactNode;
  header: React.ReactNode;
  tabs: React.ReactNode;
}

const ArticlesPageLayout = ({
  cards,
  header,
  tabs,
}: ArticlesPageLayoutProps) => {
  const { palette } = useTheme();
  return (
    <Box bgcolor={palette.colors.white} height='100%' width='100%'>
      <Box display='flex' padding='24px 40px 0px 40px'>
        {header}
      </Box>
      <Box
        bgcolor={palette.colors.white}
        display='flex'
        flexDirection='column'
        px='40px'
        py='24px'
      >
        {cards}
        <Box display='flex' flexDirection='column'>
          {tabs}
        </Box>
      </Box>
    </Box>
  );
};
export default ArticlesPageLayout;
