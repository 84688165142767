import { styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { HistoricalSolveApiRequest } from 'src/slices/workflow-preview/types';

export const RequestStep = ({
  request,
}: {
  request: HistoricalSolveApiRequest;
}) => {
  const theme = useTheme();
  const textToShow = request.query
    ? request.query
    : Object.values(request.context_variables).join(', ');
  return (
    <ResponseMessage>
      <Typography color={theme.palette.colors.white} variant='font14Medium'>
        {textToShow}
      </Typography>
    </ResponseMessage>
  );
};

const ResponseMessage = styled('div')`
  align-self: flex-end;
  background-color: ${props => props.theme.palette.colors.black};
  border-radius: 8px;
  padding: 10px 16px;
`;
