import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  CALL_TO_ACTION_LABEL_PLACEHOLDER,
  PRIVACY_POLICY_PLACEHOLDER,
  PROMPT_HEADER_PLACEHOLDER,
} from '../constants';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import { replaceContextVariableIdsWithMarkup } from 'src/components/rich-text-editor/helpers/replaceContextVariableIdsWithMarkup';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

const PrivacyConsentWidgetContent = () => {
  const theme = useTheme();
  const remirrorContextVariables = useGetRemirrorContextVariables();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const fontColor = useSelector(selectLegibleTextColor);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const isPrivacyConsentEnabled = featureFlags.includes('privacy_consent');

  if (!isPrivacyConsentEnabled) {
    return null;
  }

  const { theme_color: themeColor } = widgetConfiguration;
  const {
    call_to_action_label: callToActionLabel,
    privacy_policy: privacyPolicy,
    prompt_header: promptHeader,
    show_when_conversation_starts: showWhenConversationStarts,
  } = widgetConfiguration.privacy_consent_config;
  return (
    <Box>
      {showWhenConversationStarts && (
        <Box
          sx={{
            bgcolor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: '12px',
            display: 'flex',
            height: 'calc(100vh - 146px)',
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '588px',
          }}
        >
          <Box
            sx={{
              alignSelf: 'center',
              bgcolor: `${theme.palette.colors.white}`,
              border: `1px solid ${theme.palette.colors.grey[100]}`,
              borderRadius: '8px',
              boxShadow:
                '2px 8px 16px 0px rgba(3, 17, 38, 0.11), 0px 0px 1px 0px rgba(0, 12, 32, 0.02)',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              height: 'fit-content',
              maxHeight: '80%',
              padding: '24px',
              width: '410px',
            }}
          >
            <Box>
              <Typography variant='font16Bold'>
                {promptHeader || PROMPT_HEADER_PLACEHOLDER}
              </Typography>
            </Box>
            <Box
              sx={{
                overflowY: 'scroll',
              }}
            >
              <HtmlComponent
                content={replaceContextVariableIdsWithMarkup(
                  privacyPolicy || `<p>${PRIVACY_POLICY_PLACEHOLDER}</p>`,
                  remirrorContextVariables,
                )}
                linkifyHtmlOptions={solveLinkifyHtmlOptions}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  alignItems: 'center',
                  bgcolor: themeColor,
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '10px 24px',
                }}
              >
                <Typography color={fontColor} variant='font16Bold'>
                  {callToActionLabel || CALL_TO_ACTION_LABEL_PLACEHOLDER}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        alignSelf='center'
        display='flex'
        flexDirection='column'
        pl='90px'
        pr='90px'
        sx={{
          '.MuiTypography-root': {
            background:
              'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
            fontStyle: 'italic',
            textAlign: 'center',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
          marginTop: '30px',
        }}
      >
        <Typography variant='font16Medium'>
          Personalize your Privacy and consent by using the menu on the right.
          Utilize a prompt to make the privacy policy mandatory for customers to
          accept your terms
        </Typography>
        <Box pb='20px' />
        <Typography color={theme.palette.colors.white} variant='font16Medium'>
          Check the preview for the correct resolution
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyConsentWidgetContent;
