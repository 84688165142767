import { ArticleSuggestionActionSettings } from 'src/types/workflowBuilderAPITypes';

export const removeArticleSource = ({
  articleSourceIndex,
  articleSources,
}: {
  articleSourceIndex: number;
  articleSources: ArticleSuggestionActionSettings['article_sources'];
}) => {
  const updatedArticleSources = articleSources.filter(
    (_, index) => index !== articleSourceIndex,
  );

  return updatedArticleSources;
};
