import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectActionSettingsConfigurationFields } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectAddingAction } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { ForethoughtLiveChatHandoffActionSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const ForethoughtLiveChatHandoffCustomizationPanel: React.FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const addingAction = useSelector(selectAddingAction);
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as ForethoughtLiveChatHandoffActionSettings;
  const initialCustomizationData: ForethoughtLiveChatHandoffActionSettings =
    addingAction
      ? {
          helpdesk_config: null,
          placeholder_message: 'Please wait while we connect you to an agent',
          user_id_variable: null,
        }
      : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<ForethoughtLiveChatHandoffActionSettings>(
      initialCustomizationData,
    );
  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (actionSettingsConfigurationFields) {
      setCustomizationData({ ...actionSettingsConfigurationFields });
    }
  }, [actionSettingsConfigurationFields]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: '24px',
          mb: 6.25,
        }}
      >
        <Typography variant='font24'>Forethought Live Chat Handoff</Typography>
        <Typography color={theme.palette.colors.grey[700]} variant='font14'>
          Forethought’s Live Chat transition feature enables a unified agent
          handoff experience, allowing your customers to remain in a single chat
          for a seamless transition.
        </Typography>
        <Separator />
        <TextField
          label='Message to customers when connecting with a live agent'
          onChange={e => {
            setCustomizationData({
              ...customizationData,
              placeholder_message: e.target.value,
            });
          }}
          value={customizationData.placeholder_message || ''}
        />
        <ContextVariableSelectDropdown
          label='User id variable'
          onChange={value => {
            setCustomizationData({
              ...customizationData,
              user_id_variable: value,
            });
          }}
          shouldIncludeSystemContextVariables
          value={customizationData.user_id_variable || ''}
        />
        {/* helpdesk specific configs */}
        {customizationData.helpdesk_config?.helpdesk === 'freshdesk' &&
          customizationData.freshchat_instances_options &&
          customizationData.freshchat_instances_options.length && (
            <SelectDropdown
              id='freshchat_api_url'
              label='Freshchat instance API Url'
              onChange={e => {
                setCustomizationData({
                  ...customizationData,
                  helpdesk_config: {
                    ...customizationData.helpdesk_config,
                    api_url: e.target.value,
                    helpdesk: 'freshdesk',
                  },
                });
              }}
              options={customizationData.freshchat_instances_options}
              value={
                customizationData.helpdesk_config.api_url ||
                customizationData.freshchat_instances_options[0].value
              }
            />
          )}
      </Box>
      <Box display='flex' gap={2}>
        <Button onClick={onDiscard} size='large' variant='secondary'>
          Cancel
        </Button>
        <Button
          disabled={isPristine || hasWorkflowConflict}
          fullWidth
          onClick={() => {
            if (addingAction) {
              dispatch(
                addAction({
                  ...addingAction,
                  data: {
                    ...addingAction.data,
                    configuration_fields: { ...customizationData },
                  },
                }),
              );
            } else {
              dispatch(
                updateActionSettings({
                  action_type:
                    ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF,
                  configuration_fields: { ...customizationData },
                }),
              );
            }

            dispatchActionEditedTrackingEvent({
              actionType:
                ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ForethoughtLiveChatHandoffCustomizationPanel;

const Separator = styled('div')`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.palette.colors.slate[200]};
`;
