import React from 'react';
import {
  createMRTColumnHelper,
  MRT_ColumnDef,
  MRT_RowData,
} from 'material-react-table';

import { isOther } from '../../common/tableHelpers';
import {
  HistogramFilters,
  MetricMultiFilterValue,
  MetricUnit,
  SearchType,
} from '../types';
import ColumnHeaderItem, { ColumnHeaderItemProps } from './ColumnHeaderItem';
import {
  ACTION_BUTTON_WIDTH,
  AUTOMATION_BUTTON_WIDTH,
  BUTTON_ICON_WIDTH,
  BUTTON_NUM_ITEMS,
  CHAR_SIZE,
  ColumnItem,
  columns,
  WORKFLOW_BUTTON_WIDTH,
} from './constants';
import NumberValue from './NumberValue';
import PercentChangeValue from './PercentChangeValue';
import SentimentValue from './SentimentValue';
import TopicName, { TopicNameProps } from './TopicName';
import TopicNameHeader from './TopicNameHeader';
import ValueCell from './ValueCell';
import { TopicTimeFilter } from 'src/components/app/types';
import {
  DiscoverTopic,
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicMetric,
  DiscoverTopicMetrics,
  DiscoverTopicSentimentValue,
  TopicMetricTypeDetails,
} from 'src/reducers/discoverReducer/types';
import {
  evaluateGroupFilter,
  getMetricValueFromType,
  normalizeMetric,
} from 'src/utils/discover/helpers';

type SetAnchorElement = React.Dispatch<
  React.SetStateAction<HTMLElement | null>
>;

export type AllTopicsTableV3Filter = {
  groupFilters: string[];
  metricFilters: DiscoverTopicAggregatedMetricType[];
  searchText: string;
  showPercentChanged: boolean;
  timeFilter: TopicTimeFilter;
};

const createMetricData = (
  metrics: (DiscoverTopicMetric | null)[],
  isNormalFont: boolean,
  dataType?: TopicMetricTypeDetails[],
) => {
  if (!metrics) {
    return {};
  }

  return metrics.reduce((prev, current) => {
    if (!current) {
      return prev;
    }
    const metricType = dataType?.find(item => item.type === current.name);
    const transformedValue = (
      <NumberValue
        isNormalFont={isNormalFont}
        metric={current}
        metricType={metricType}
      />
    );
    const returnValue = {
      ...prev,
      [current.name]: transformedValue,
      [`_${current.name}`]: current,
    };

    const hasPercentChange = columns.find(
      item => item.relatedKey === current.name,
    );

    if (current.name === 'sentiment') {
      const sentiment = current.value as DiscoverTopicSentimentValue;
      return {
        ...returnValue,
        drop_in_sentiment: (
          <ValueCell
            isNormalFont={isNormalFont}
            value={sentiment?.negative_change || 0}
          />
        ),
        ending_sentiment: (
          <SentimentValue
            isNormalFont={isNormalFont}
            value={sentiment?.ending_sentiment || 0}
          />
        ),
        starting_sentiment: (
          <SentimentValue
            isNormalFont={isNormalFont}
            value={sentiment?.starting_sentiment || 0}
          />
        ),
      };
    }

    if (hasPercentChange) {
      return {
        ...returnValue,
        [hasPercentChange.accessorKey]: (
          <PercentChangeValue
            accessorKey={hasPercentChange.accessorKey}
            change={current.value_changed}
            isNormalFont={isNormalFont}
            metricType={metricType}
            value={current.value}
          />
        ),
      };
    }

    return returnValue;
  }, {});
};

type CreateRowProps = {
  data?: DiscoverTopicMetrics['breakdown'];
  dataType?: TopicMetricTypeDetails[];
  invalidSort: boolean;
  isChild?: boolean;
  isTopicGrouped: boolean;
  onClickArticleButton: (topic: DiscoverTopic) => void;
  onNavigateToTopic: TopicNameProps['onNavigateToTopic'];
  searchText: string;
  sortedBy: string;
};

type RowMetricItem = {
  breakdown?: DiscoverTopicMetrics['breakdown'];
  id?: string | null;
  name: React.ReactNode;
  subRows: RowMetricItem[];
  topic: DiscoverTopic | null;
};

export const createRows = (props: CreateRowProps): RowMetricItem[] => {
  if (!props.data) {
    return [];
  }

  const dataArr: (RowMetricItem | null)[] = props.data.map(
    (item: DiscoverTopicMetrics & { parentTopicName?: string }) => {
      const metricData = createMetricData(
        item.metrics,
        Boolean(props.isChild || item.parentTopicName),
        props.dataType,
      );

      if (!item.breakdown?.length && !props.isChild && props.isTopicGrouped) {
        return null;
      }
      return {
        ...metricData,
        _name: item.topic?.topic_name,
        id: item.id,
        name: (
          <TopicName
            isChild={props.isChild}
            numberOfSubTopics={item.breakdown?.length}
            onClickArticleButton={props.onClickArticleButton}
            onNavigateToTopic={props.onNavigateToTopic}
            parentTopicName={
              !props.isTopicGrouped ? item.parentTopicName : undefined
            }
            searchText={props.searchText}
            topic={item.topic}
          />
        ),
        subRows: createRows({
          data: item.breakdown,
          dataType: props.dataType,
          invalidSort: props.invalidSort,
          isChild: true,
          isTopicGrouped: props.isTopicGrouped,
          onClickArticleButton: props.onClickArticleButton,
          onNavigateToTopic: props.onNavigateToTopic,
          searchText: props.searchText,
          sortedBy: props.sortedBy,
        }),
        topic: item.topic,
      };
    },
  );

  return dataArr
    .filter((item): item is RowMetricItem => item !== null)
    .sort((rowA, rowB) => {
      if (!props.invalidSort) {
        return 0;
      }
      if (!rowA.topic || !rowB.topic) {
        return 0;
      }
      if (isOther(rowA.topic.topic_name)) {
        return 1;
      }
      if (isOther(rowB.topic.topic_name)) {
        return -1;
      }
      return 0;
    });
};

type CreateColumnHeaderItemProps = {
  histogram: ColumnHeaderItemProps['histogram'];
  item: ColumnItem;
  setAnchorElement: SetAnchorElement;
  size: number;
  topicCount?: number;
};

const createColumnItem = ({
  histogram,
  item,
  setAnchorElement,
  size,
  topicCount,
}: CreateColumnHeaderItemProps) => {
  if (item.accessorKey === 'name') {
    return {
      ...item,
      Header: <TopicNameHeader header={item.header} topicCount={topicCount} />,
      size,
      visibleInShowHideMenu: false,
    };
  }

  return {
    ...item,
    Header: (
      <ColumnHeaderItem
        histogram={histogram}
        item={item}
        setAnchorElement={setAnchorElement}
      />
    ),
    visibleInShowHideMenu: !item.isPercentChange && !item.isSentiment,
  };
};

const createColSize = (data?: DiscoverTopicMetrics['breakdown']) => {
  let len = 0;
  data?.forEach((item: DiscoverTopicMetrics) => {
    const name = item.topic?.topic_name;
    const nameLength = name?.length || 0;
    if (nameLength > len) {
      len = nameLength;
    }
    item.breakdown?.forEach(subItem => {
      const subNameLength = subItem.topic?.topic_name?.length || 0;
      if (subNameLength > len) {
        len = subNameLength;
      }
    });
  });

  const buttonWidth = BUTTON_ICON_WIDTH * BUTTON_NUM_ITEMS;

  return len * CHAR_SIZE + buttonWidth;
};

const getSortItems = ({
  item,
  rowA,
  rowB,
}: {
  item: Partial<ColumnItem>;
  rowA: MRT_RowData;
  rowB: MRT_RowData;
}) => {
  const aData = rowA.original;
  const bData = rowB.original;
  const targetA = aData[`_${item.relatedKey}`] || aData[`_${item.accessorKey}`];
  const targetB = bData[`_${item.relatedKey}`] || bData[`_${item.accessorKey}`];
  if (item.accessorKey === 'name') {
    return {
      a: targetA,
      b: targetB,
    };
  }
  if (item.accessorKey === 'drop_in_sentiment') {
    return {
      a: aData._sentiment.value?.negative_change || 0,
      b: bData._sentiment.value?.negative_change || 0,
    };
  }
  if (item.isSentiment) {
    return {
      a: aData._sentiment.value
        ? aData._sentiment.value[item.accessorKey as string]
        : 0,
      b: bData._sentiment.value
        ? bData._sentiment.value[item.accessorKey as string]
        : 0,
    };
  }
  if (!targetA) {
    return null;
  }
  if (item.isPercentChange) {
    return {
      a: targetA.value_changed,
      b: targetB.value_changed,
    };
  }
  return {
    a: targetA.value,
    b: targetB.value,
  };
};

export const generateColumns = ({
  filteredData,
  histogram,
  setAnchorElement,
  sortDirection,
  topicCount,
}: {
  filteredData?: DiscoverTopicMetrics['breakdown'];
  histogram: ColumnHeaderItemProps['histogram'];
  setAnchorElement: SetAnchorElement;
  sortDirection: string;
  topicCount?: number;
}) => {
  const size = createColSize(filteredData);
  const columnHelper = createMRTColumnHelper<{ [k: string]: string }>();

  return columns
    .map(item => {
      const col = createColumnItem({
        histogram,
        item,
        setAnchorElement,
        size,
        topicCount,
      });

      return columnHelper.accessor(col.accessorKey, {
        header: col.header,
        Header: () => col.Header,
        size: col.size,
        sortingFn: (rowA: MRT_RowData, rowB: MRT_RowData) => {
          const isDescending = sortDirection === 'desc';

          const sortItems = getSortItems({ item, rowA, rowB });
          if (!sortItems) {
            return 0;
          }
          const { a, b } = sortItems;
          if (b === null || b === undefined) {
            return isDescending ? 1 : -1;
          }
          if (a === null || a === undefined) {
            return isDescending ? -1 : 1;
          }
          if (isOther(rowA.original.topic.topic_name)) {
            return isDescending ? -1 : 1;
          }
          if (isOther(rowB.original.topic.topic_name)) {
            return isDescending ? 1 : -1;
          }
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        },
        visibleInShowHideMenu: col.visibleInShowHideMenu,
      });
    })
    .concat(
      columnHelper.accessor('sentiment', {
        header: 'Sentiment',
        Header: null,
        maxSize: 0,
        minSize: 0,
        size: 0,
        visibleInShowHideMenu: true,
      }),
    ) as MRT_ColumnDef<MRT_RowData, unknown>[];
};

export const filterBySearchText = (
  data: DiscoverTopicMetrics['breakdown'] | undefined,
  searchText: string,
  isTopicGrouped: boolean,
  searchType: SearchType,
) => {
  if (!searchText || searchType == 'ticket') {
    return data;
  }
  const text = searchText.trim().toLowerCase();

  return data
    ?.map(item => {
      const topicName = item.topic?.topic_display_name
        ? item.topic?.topic_display_name?.toLowerCase()
        : item.topic?.topic_name.toLowerCase() || '';

      if (topicName.includes(text) && isTopicGrouped) {
        return item;
      }

      const breakdown = item.breakdown?.filter(childTopic =>
        childTopic.topic?.topic_name.toLowerCase().includes(text),
      );

      if (breakdown?.length) {
        return {
          ...item,
          breakdown,
        };
      }

      return null;
    })
    .filter(item => item !== null) as DiscoverTopicMetrics['breakdown'];
};

export const filterByGroup = (
  data: DiscoverTopicMetrics['breakdown'] | undefined,
  groupFilters: string[],
) => {
  if (!data) {
    return [];
  }
  if (!groupFilters.length) {
    return data;
  }
  const filteredData = data
    .map(item => {
      const newBreakdown = item.breakdown?.filter(childTopic =>
        evaluateGroupFilter(childTopic.topic, groupFilters),
      );

      if (!newBreakdown || !newBreakdown.length) {
        return null;
      }

      return {
        ...item,
        breakdown: newBreakdown,
      };
    })
    .filter(item => item !== null);

  return filteredData as DiscoverTopicMetrics['breakdown'];
};

export const countAllTopics = (
  data: DiscoverTopicMetrics[] | undefined,
  isTopicGrouped: boolean,
) => {
  if (data === undefined) {
    return 0;
  }

  if (!isTopicGrouped) {
    return data.length;
  }

  return data.reduce((prev, current) => {
    return prev + (current?.breakdown?.length || 0);
  }, 0);
};

const validateRange = (range?: number[]) => {
  if (!range) {
    return false;
  }
  if (!Array.isArray(range)) {
    return false;
  }
  if (range.length < 2) {
    return false;
  }
  return true;
};

const compareRangeValues = (value: number, range?: number[]) => {
  if (!range) {
    return false;
  }
  return value >= range[0] && value <= range[1];
};

const extractChildItems = (
  data: DiscoverTopicMetrics['breakdown'] | undefined,
) =>
  data?.reduce(
    (prevValue, currentValue) =>
      (prevValue || []).concat(
        currentValue.breakdown?.map(breakdown => {
          return {
            ...breakdown,
            parentTopicName: currentValue.topic?.topic_name,
          };
        }) || [],
      ),
    [] as DiscoverTopicMetrics['breakdown'],
  ) || [];

export const filterByHistogram = (
  data: DiscoverTopicMetrics['breakdown'] | undefined,
  histogramFilters: HistogramFilters,
  isTopicGrouped: boolean,
) => {
  if (!data) {
    return [];
  }

  const filtersArr = Object.entries(histogramFilters);

  const targetData = !isTopicGrouped ? extractChildItems(data) : data;

  if (!histogramFilters || !filtersArr.length) {
    return targetData;
  }

  return filtersArr.reduce((prev, current) => {
    const [key, metricFilter] = current;
    return (
      prev?.filter(({ metrics }) => {
        let isValid = true;
        metrics.forEach(item => {
          for (const comparisonType in metricFilter) {
            const targetValue = metricFilter[comparisonType as MetricUnit];
            const isRange = validateRange(targetValue);
            const normalizedItem = normalizeMetric(item);
            const value = item
              ? normalizedItem[comparisonType as MetricUnit] ?? 0
              : 0;
            const metricValue =
              comparisonType === 'value'
                ? getMetricValueFromType(key, value)
                : value;

            const testIsValid = Boolean(
              isRange && compareRangeValues(Number(metricValue), targetValue),
            );
            if (isValid === true && item?.name === key) {
              isValid = testIsValid;
            }
          }
          return isValid;
        });
        return isValid;
      }) || []
    );
  }, targetData);
};

export const getActionsColumnSize = (
  rows: RowMetricItem[],
  isTopicGrouped: boolean,
) => {
  let hasAutomation = false;
  let hasAutomated = false;

  const data = isTopicGrouped
    ? rows.reduce(
        (prev, current) => prev.concat(current.subRows),
        [] as RowMetricItem[],
      )
    : rows;

  data?.forEach(item => {
    const showAutomatedButton = item.topic?.is_automated;
    const showAutomateButton =
      !showAutomatedButton && Boolean(item.topic?.summaries?.length);

    if (!hasAutomation) {
      hasAutomation = Boolean(showAutomateButton);
    }
    if (!hasAutomated) {
      hasAutomated = Boolean(showAutomatedButton);
    }
  });

  if (hasAutomated) {
    return WORKFLOW_BUTTON_WIDTH;
  }

  if (hasAutomation) {
    return AUTOMATION_BUTTON_WIDTH;
  }

  return ACTION_BUTTON_WIDTH;
};

export const formatSignedValue = (change: number) => {
  if (change > 0) {
    return `+${change}`;
  }
  return change;
};

export const getMetricFromMetricsParam = (value: MetricMultiFilterValue[]) => {
  const item = value[0]?.split('.');

  return item[0];
};

export const createColumnVisibility = (
  metricFilters: string[],
  showPercentChanged: boolean,
) => {
  return columns.reduce(
    (prev, current) => {
      const isIncluded = metricFilters.includes(current.accessorKey);
      const showPercent =
        showPercentChanged &&
        current.isPercentChange &&
        current.relatedKey &&
        metricFilters.includes(current.relatedKey);
      const hasSentiment = metricFilters.includes('sentiment');
      const isSentiment = Boolean(current.isSentiment && hasSentiment);
      const data = {
        [current.accessorKey]: Boolean(
          isIncluded || showPercent || isSentiment,
        ),
        sentiment: hasSentiment,
        ...prev,
      };
      return data;
    },
    { name: true },
  );
};
