import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useGetUndefinedContextVariableIdsOnCurrentAction } from '../../hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown/ContextVariableSelectDropdown';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  Label,
  SectionContainerFlush,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { FieldNameToIdx } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import { ZendeskOrSalesforceContextVariables } from 'src/types/workflowBuilderAPITypes';

interface NameEmailQuestionProps {
  customizationData: ZendeskOrSalesforceContextVariables;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<
    SetStateAction<ZendeskOrSalesforceContextVariables>
  >;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
  shouldAllowEmptyName?: boolean;
}

const NameEmailQuestion: FC<
  React.PropsWithChildren<NameEmailQuestionProps>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  setCustomizationData,
  setIsFieldTouchedArr,
  shouldAllowEmptyName = false,
}) => {
  const { palette } = useTheme();

  return (
    <SectionContainerFlush>
      <Label>
        Name
        {!shouldAllowEmptyName && (
          <Required color={palette.colors.grey[400]}>Required</Required>
        )}
      </Label>
      <CVSelector
        autoFocus
        customizationData={customizationData}
        errorMessages={errorMessages}
        fieldName='name'
        isClearable={shouldAllowEmptyName}
        isFieldTouchedArr={isFieldTouchedArr}
        setCustomizationData={setCustomizationData}
        setIsFieldTouchedArr={setIsFieldTouchedArr}
      />
      <Spacer height='28px' />
      <Label>
        Email
        <Required color={palette.colors.grey[400]}>Required</Required>
      </Label>
      <CVSelector
        customizationData={customizationData}
        errorMessages={errorMessages}
        fieldName='email'
        isClearable={false}
        isFieldTouchedArr={isFieldTouchedArr}
        setCustomizationData={setCustomizationData}
        setIsFieldTouchedArr={setIsFieldTouchedArr}
      />
      <Spacer height='28px' />
      <Label>
        Question
        <Required color={palette.colors.grey[400]}>Required</Required>
      </Label>
      <CVSelector
        customizationData={customizationData}
        errorMessages={errorMessages}
        fieldName='question'
        isClearable={false}
        isFieldTouchedArr={isFieldTouchedArr}
        setCustomizationData={setCustomizationData}
        setIsFieldTouchedArr={setIsFieldTouchedArr}
      />
    </SectionContainerFlush>
  );
};

const CVSelector = (props: {
  autoFocus?: boolean;
  customizationData: ZendeskOrSalesforceContextVariables;
  errorMessages: Array<string | Array<string>>;
  fieldName: 'name' | 'email' | 'question';
  isClearable: boolean;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<
    SetStateAction<ZendeskOrSalesforceContextVariables>
  >;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();
  const cvMap = useMemo(
    () =>
      contextVariables.map(cv => ({
        label: `$${cv.context_variable_name}`,
        value: cv.context_variable_id,
      })),
    [contextVariables],
  );

  const valueId = props.customizationData[props.fieldName] || '';
  const value = useMemo(
    () => ({
      label: valueId
        ? cvMap.find(cvOpt => cvOpt.value === valueId)?.label ?? ''
        : 'Select a context variable',
      value: valueId,
    }),
    [cvMap, valueId],
  );

  let fieldIndex: FieldNameToIdx = 0;
  if (props.fieldName === 'name') {
    fieldIndex = FieldNameToIdx.NAME;
  } else if (props.fieldName === 'email') {
    fieldIndex = FieldNameToIdx.EMAIL;
  } else if (props.fieldName === 'question') {
    fieldIndex = FieldNameToIdx.QUESTION;
  }

  return (
    <ContextVariableSelectDropdown
      aria-label={`Context variable for "${props.fieldName}" field`}
      error={
        props.isFieldTouchedArr[fieldIndex]
          ? (props.errorMessages[fieldIndex] as string)
          : ''
      }
      isClearable={props.isClearable}
      onChange={value => {
        props.setCustomizationData({
          ...props.customizationData,
          [props.fieldName]: value ?? '',
        });
      }}
      shouldIncludeSystemContextVariables
      undefinedContextVariables={undefinedContextVariableIds}
      value={value.value}
    />
  );
};

export default NameEmailQuestion;

const Required = styled('span')<{ color: string }>`
  margin-left: 4px;
  cursor: default;
  color: ${({ color }) => color};
`;
