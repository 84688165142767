import React from 'react';
import { styled } from '@mui/material/styles';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface DrawerSectionProps {
  title: string;
}

export default function DrawerSection({
  children,
  title,
}: React.PropsWithChildren<DrawerSectionProps>) {
  return (
    <Container>
      <Header>
        <Typography variant='font16Bold'>{title}</Typography>
      </Header>
      {children}
    </Container>
  );
}

const Container = styled('div')`
  padding-top: 32px;
`;

const Header = styled('div')`
  border-bottom: 1px solid ${theme.palette.grey[100]};
  padding: 0 0 8px;
  margin-bottom: 8px;
`;
