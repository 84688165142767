import { Box, useTheme } from '@mui/material';
import { IconCircleCheck } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Wrapper } from '../workflow-builder-edit/email-builder-page/intent-email-builder/components/DynamicArticleCard';
import { ADD_ONS, CURRENT_PLAN, UPGRADE } from './constants';

interface SubscriptionPlanDetailsCardProps {
  description?: string;
  extraFeatures?: string[];
  features: string[];
  isCurrentPlan?: boolean;
  onClick?: () => void;
  title: string;
}

const SubscriptionPlanDetailsCard = ({
  description,
  extraFeatures,
  features,
  isCurrentPlan = false,
  onClick,
  title,
}: SubscriptionPlanDetailsCardProps) => {
  const { palette } = useTheme();
  const header = isCurrentPlan ? (
    <Typography color={palette.colors.grey[600]} variant='font14'>
      {CURRENT_PLAN}
    </Typography>
  ) : (
    <Button onClick={onClick} variant='main'>
      {UPGRADE}
    </Button>
  );

  const content = (
    <Box
      sx={{
        background: isCurrentPlan
          ? palette.colors.purple[100]
          : palette.colors.white,
        border: `1px solid ${palette.colors.slate[200]}`,
        borderRadius: isCurrentPlan ? '7px' : '8px',
        display: 'flex',

        flexDirection: 'column',
        gap: '24px',
        padding: '26px',
      }}
    >
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='font24'>{title}</Typography>
        {header}
      </Box>
      {description && (
        <Typography color={palette.colors.black} variant='font14'>
          {description}
        </Typography>
      )}
      <Box display='flex' flexDirection='column' gap='12px'>
        {features.map(feature => (
          <Box alignItems='center' display='flex' gap='6px' key='feature'>
            <IconCircleCheck color={palette.colors.purple[500]} size={18} />
            <Typography color={palette.colors.black} variant='font14'>
              {feature}
            </Typography>
          </Box>
        ))}
      </Box>
      {!!extraFeatures?.length && (
        <Box display='flex' flexDirection='column' gap='12px'>
          <Typography variant='font14'>{ADD_ONS}</Typography>
          {extraFeatures.map(feature => (
            <Box alignItems='center' display='flex' gap='6px' key={feature}>
              <IconCircleCheck color={palette.colors.purple[500]} size={18} />
              <Typography color={palette.colors.black} variant='font14'>
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );

  return isCurrentPlan ? <Wrapper>{content}</Wrapper> : content;
};

export default SubscriptionPlanDetailsCard;
