import { generatePath, useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { formatTestConfig } from '../utils';
import CardTable from 'src/components/card-table/CardTable';
import HighlightedText from 'src/components/dashboard-pages/assist-notes-page/notes-table/HighlightedText';
import { IntentTestResponse } from 'src/services/intent-tests/types';
import { channelToDisplayName } from 'src/types/workflowBuilderAPITypes';
import { formatReadableDatetime } from 'src/utils/dateUtils';
import { Routes } from 'src/utils/enums';

const PENDING = 'Pending';
const TOOLTIPS = {
  coverage: 'The percentage of inquiries where an intent prediction was made',
  volume: 'The number of inquiries that were tested',
};

export default function IntentTestTable({
  intentTests,
  searchText,
}: {
  intentTests: IntentTestResponse[];
  searchText: string;
}) {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <CardTable
        columns={[
          {
            columnWidth: 400,
            id: 'name',
            label: 'Name',
            renderCell: intentTest => (
              <Box>
                <Box
                  overflow='hidden'
                  textOverflow='ellipsis'
                  title={intentTest.name}
                  whiteSpace='nowrap'
                >
                  <Typography variant='font16Bold'>
                    <HighlightedText
                      searchText={searchText}
                      text={intentTest.name}
                    />
                  </Typography>
                </Box>
                <Box
                  maxWidth='100%'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  title={formatTestConfig(intentTest)}
                  whiteSpace='nowrap'
                >
                  <Typography color={palette.colors.grey[600]} variant='font11'>
                    {formatTestConfig(intentTest)}
                  </Typography>
                </Box>
              </Box>
            ),
          },
          {
            columnWidth: 240,
            id: 'created_at',
            label: 'Created at',
            renderCell: ({ created_at }) => (
              <Typography color={palette.colors.grey[700]} variant='font16Bold'>
                {formatReadableDatetime(new Date(created_at))}
              </Typography>
            ),
          },
          {
            id: 'channel',
            label: 'Channel',
            renderCell: ({ channel }) => (
              <Typography color={palette.colors.grey[700]} variant='font16Bold'>
                {channelToDisplayName[channel]}
              </Typography>
            ),
          },
          {
            id: 'status',
            label: 'Status',
            renderCell: ({ status }) => (
              <Typography color={palette.colors.grey[700]} variant='font16Bold'>
                {status.slice(0, 1).toUpperCase() + status.slice(1)}
              </Typography>
            ),
          },
          {
            id: 'volume',
            label: 'Volume',
            labelTooltip: TOOLTIPS.volume,
            renderCell: ({ volume }) => (
              <Typography color={palette.colors.grey[700]} variant='font16Bold'>
                {volume?.toLocaleString() || '--'}
              </Typography>
            ),
          },
          {
            id: 'coverage',
            label: 'Coverage',
            labelTooltip: TOOLTIPS.coverage,
            renderCell: ({ coverage }) => (
              <Typography color={palette.colors.grey[700]} variant='font16Bold'>
                {coverage ? (coverage * 100).toFixed(2) + '%' : '--'}
              </Typography>
            ),
          },
        ]}
        onClickRow={({ intent_test_id }) => {
          navigate({
            pathname: generatePath(Routes.PREDICT_INTENTS_TEST_DETAIL, {
              intentTestId: intent_test_id,
            }),
          });
        }}
        rows={intentTests.map(intentTest => {
          return {
            isClickable: intentTest.status === 'success',
            tagText: intentTest.status === 'pending' ? PENDING : null,
            ...intentTest,
          };
        })}
      />
    </>
  );
}
