import '../common/datePicker.scss';
import '../common/tablePage.scss';
import 'react-day-picker/lib/style.css';
import 'react-day-picker/lib/style.css';

import React from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';

import NoDataView from '../../reusable-components/no-data-view/NoDataView';
import TableHeader from '../../reusable-components/table-header/tableHeader';
import TableRow from '../../reusable-components/table-row/tableRow';
import { TotalsAndAverages } from '../common/totals-and-averages';
import { setPageNumber } from 'src/actions/pageSettings/pageSettings';
import { selectPageNumber } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { ChartTotals, TableTotals } from 'src/services/apiInterfaces';
import { TableDataDict } from 'src/services/apiInterfaces';
import { noDataViewProps } from 'src/utils/enums';
import { roundNumToPercentage } from 'src/utils/formatToPercentage';

interface TableProps {
  sortedProperty: string;
  tableData: TableDataDict;
}

const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  sortedProperty,
  tableData,
}: TableProps): JSX.Element => {
  const dispatch = useDispatch();

  const pageNumber: number = useSelector(selectPageNumber);

  const handlePageClick = (data: ChartTotals & TableTotals) => {
    dispatch(setPageNumber(data.selected));
  };

  const { aggregate, breakdown } = tableData;

  // Pagination without chunking
  const numberOfElements = breakdown?.length || 1;
  const numberOfPages = Math.ceil(numberOfElements / 50);
  const start = pageNumber * 50;
  const pageElements: TableDataDict['breakdown'] =
    breakdown?.slice(start, start + 50) || [];

  let renderedTableData;
  if (pageElements && pageElements.length > 0) {
    renderedTableData = pageElements.map((tableData, index: number) => {
      return (
        <TableRow
          breakdown={[1]}
          cells={[
            tableData.field_value,
            tableData.engaged_count,
            tableData.total_count,
            roundNumToPercentage(tableData.engaged_ratio as number),
          ]}
          key={index}
          showInitialsIcon // Pass an array w something so it's not grayed out
        />
      );
    });
  } else {
    //no results object to display in rows for no results UI
    const emptyBreakdown = {
      engaged_count: 0,
      engaged_ratio: 0,
      field_value: 'Agent Name',
      total_count: 0,
    };
    //specifies we want an arr with 10 space and fill puts emptyBreakdown in there
    const noResultsBreakdown = Array(10).fill(emptyBreakdown);
    renderedTableData = noResultsBreakdown.map((tableData, index: number) => (
      <TableRow
        breakdown={[]}
        cells={[
          tableData.field_value,
          tableData.engaged_count,
          tableData.total_count,
          roundNumToPercentage(tableData.engaged_ratio),
        ]}
        key={index}
        showInitialsIcon
      />
    ));
  }

  const resetPage = () => {
    dispatch(setPageNumber(0));
  };

  return (
    <div className='analytics-table'>
      {pageElements && pageElements.length === 0 && (
        <NoDataView
          message={noDataViewProps.ANALYTICS_TIME_RANGE}
          noDataViewClass={noDataViewProps.DATE_ICON_CLASS}
        />
      )}
      <table className='analytics'>
        <tbody>
          <tr className='table-header'>
            <TableHeader
              divClass={
                sortedProperty === 'field_value'
                  ? 'sort-wrapper focused'
                  : 'sort-wrapper'
              }
              heading={'AGENT NAME'}
              headingClass={'agent-heading heading-type'}
              onClick={resetPage}
              productSorted={'answers_table'}
              propertyName={'field_value'}
            />
            <TableHeader
              divClass={
                sortedProperty === 'engaged_count'
                  ? 'sort-wrapper focused'
                  : 'sort-wrapper'
              }
              heading={'ENGAGED TICKETS'}
              headingClass={'heading-type'}
              onClick={resetPage}
              productSorted={'answers_table'}
              propertyName={'engaged_count'}
              tooltipClass={'second-col'}
              tooltipText={'Number of unique tickets where Assist was used.'}
              tooltipTitle={'Engaged Tickets'}
            />
            <TableHeader
              divClass={
                sortedProperty === 'total_count'
                  ? 'sort-wrapper focused'
                  : 'sort-wrapper'
              }
              heading={'SOLVED TICKETS'}
              headingClass={'heading-type'}
              onClick={resetPage}
              productSorted={'answers_table'}
              propertyName={'total_count'}
              tooltipClass={'third-col'}
              tooltipText={'Total number of tickets closed in the time range.'}
              tooltipTitle={'Solved Tickets'}
            />
            <TableHeader
              divClass={
                sortedProperty === 'engaged_ratio'
                  ? 'sort-wrapper focused'
                  : 'sort-wrapper'
              }
              heading={'ENGAGEMENT'}
              headingClass={'heading-type'}
              onClick={resetPage}
              productSorted={'answers_table'}
              propertyName={'engaged_ratio'}
              tooltipClass={'fourth-col'}
              tooltipText={'Calculated as Engaged tickets / Total tickets.'}
              tooltipTitle={'Engagement'}
            />
          </tr>
          {pageElements && pageElements.length > 0 && (
            <TotalsAndAverages
              cells={[
                aggregate.engaged_count,
                aggregate.total_count,
                roundNumToPercentage(aggregate.engaged_ratio),
              ]}
            />
          )}
          {renderedTableData}
        </tbody>
      </table>
      <ReactPaginate
        activeClassName={'active'}
        breakClassName={'break-me'}
        breakLabel={'...'}
        containerClassName={'pagination'}
        forcePage={pageNumber}
        marginPagesDisplayed={0}
        nextLabel={''}
        onPageChange={handlePageClick}
        pageCount={numberOfPages}
        pageRangeDisplayed={2}
        previousLabel={''}
        subContainerClassName={'pages pagination'}
      />
    </div>
  );
};

export default Table;
