import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  setEditingStepId,
  setEditingStepType,
  setMode,
  setTargetStepId,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import { CanvasModes } from '../constants';
import { ForethoughtEditMenu } from './ForethoughtEditMenu';
import { setStepLevelTranslationsId } from 'src/actions/workflow-builder/workflowBuilderActions';
import { ForethoughtEditStepMenuProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { setGlobalWorkflowBuilderOptions } from 'src/slices/ui/uiSlice';
import { StepTypes } from 'src/utils/enums';

export const ForethoughtEditStepMenu: FC<
  React.PropsWithChildren<ForethoughtEditStepMenuProps>
> = ({
  hasCondition,
  isDeletionDisabled,
  isVisible,
  setIsVisible,
  stepId,
  stepType,
}) => {
  const dispatch = useDispatch();

  return (
    <ForethoughtEditMenu
      hasCondition={hasCondition}
      isDeletionDisabled={isDeletionDisabled}
      isVisible={isVisible}
      onEditClick={() => {
        dispatch(setEditingStepId(stepId));
        dispatch(setEditingStepType(stepType));
        if (stepType === StepTypes.EMBED) {
          dispatch(setMode({ contextVariables: [], mode: CanvasModes.EMBED }));
        }
      }}
      onTranslationClick={() => {
        // remove the highlighted step if there's any
        dispatch(setTargetStepId(null));

        dispatch(setStepLevelTranslationsId(stepId));
        dispatch(
          setGlobalWorkflowBuilderOptions({
            isTranslationDrawerOpen: true,
          }),
        );
      }}
      setIsVisible={setIsVisible}
      stepId={stepId}
      stepType={stepType}
    />
  );
};
