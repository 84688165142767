import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import RadioButton from 'src/components/radio-button/RadioButton';
import {
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';

const AutoCreateTicket: FC<React.PropsWithChildren<unknown>> = () => (
  <Row>
    <Field name='ticket_creation_settings.create_ticket'>
      {({ field, form: { setFieldValue } }: FieldProps) => (
        <RadioButton
          checked={!field.value}
          onChange={() => setFieldValue(field.name, !field.value)}
        />
      )}
    </Field>
    <Spacer width='8px' />
    <span>Custom Message</span>
  </Row>
);

export default AutoCreateTicket;
