import { useCallback, useEffect, useRef, useState } from 'react';

import { ConnectorJob, ESDocumentOverview } from 'src/services/apiInterfaces';
import {
  getConnectorDocumentCount,
  getConnectorJobHistory,
} from 'src/services/apiV1';

// TODO: remove this after errored jobs are filtered out on backend
function getLastNonErrorJob(jobs: ConnectorJob[]) {
  return jobs.find(job => job.public_stage !== 'errored');
}

/**
 * - fetches last connector job
 * - additionally returns 'setConnectorJobPendingState', which when called will
 *   poll for last connector job every 5s until that job enters a 'success' state
 */
export const useLastConnectorJob = (connectorId?: string) => {
  const intervalCount = useRef<ReturnType<typeof setInterval>>();
  const [isPending, setIsPending] = useState(false);
  const [lastConnectorJob, setLastConnectorJob] = useState<ConnectorJob | null>(
    null,
  );

  const fetchLastJob = useCallback(async (connectorId: string) => {
    const { jobs } = await getConnectorJobHistory(connectorId);
    const lastJob = getLastNonErrorJob(jobs) || null;
    setLastConnectorJob(lastJob);
    setIsPending(lastJob?.public_stage === 'pending');

    return lastJob;
  }, []);

  useEffect(() => {
    if (connectorId) {
      fetchLastJob(connectorId);
    }
    if (connectorId) {
      intervalCount.current = setInterval(
        () => fetchLastJob(connectorId),
        5000,
      );
    }

    return () => {
      if (intervalCount.current) {
        clearTimeout(intervalCount.current);
      }
    };
  }, [connectorId, fetchLastJob]);

  const setConnectorJobPendingState = (pendingState: boolean) => {
    setIsPending(pendingState);
    if (connectorId) {
      intervalCount.current = setInterval(
        () => fetchLastJob(connectorId),
        5000,
      );
    }
  };

  return {
    isPending,
    lastConnectorJob,
    setConnectorJobPendingState,
  };
};

export const useConnectorDocumentCount = (
  isPending: boolean,
  connectorId?: string,
) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const [documentCountInformation, setDocumentCountInformation] = useState<
    ESDocumentOverview[]
  >([]);

  const requestProcess = (realConnectorId: string) => {
    getConnectorDocumentCount(realConnectorId).then(
      documentCountInformation => {
        if (
          documentCountInformation.es_document_overview &&
          documentCountInformation.es_document_overview.length !== 0
        ) {
          setDocumentCountInformation(
            documentCountInformation.es_document_overview,
          );
        }
      },
    );
  };

  useEffect(() => {
    if (!connectorId) {
      setDocumentCountInformation([]);
      return;
    }
    if (isPending) {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
      timeoutRef.current = setInterval(() => requestProcess(connectorId), 5000);
    } else {
      requestProcess(connectorId);
    }
    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }
    };
  }, [connectorId, isPending]);
  return documentCountInformation;
};
