import { useMemo } from 'react';
import { Box } from '@mui/material';

import { CanvasModes } from '../../constants';
import { useGetBuilderQueryParams } from '../../hooks';
import { BuilderView } from '../../types';
import DraggableCard from './DraggableCard';
import { BusinessLogicProps } from './types';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { StepTypes } from 'src/utils/enums';

const ADD_STEPS = [
  {
    label: 'Create new Options',
    mode: CanvasModes.BUTTON,
    stepType: StepTypes.BUTTONS,
  },
  {
    label: 'Create new Conditions',
    mode: CanvasModes.CONDITION,
    stepType: StepTypes.CONDITION,
  },
  {
    label: 'Create new Forms',
    mode: CanvasModes.FORM,
    stepType: StepTypes.FORM,
  },
  {
    label: 'Create Dynamic Cards',
    mode: CanvasModes.ACTION_EDIT,
    stepType: StepTypes.DYNAMIC_CARD,
  },
  {
    label: 'Add a Video',
    mode: CanvasModes.EMBED,
    stepType: StepTypes.EMBED,
  },
  {
    label: 'Add an Image',
    mode: CanvasModes.IMAGE,
    stepType: StepTypes.IMAGE,
  },
  {
    label: 'Add CSAT Survey Trigger Point',
    mode: CanvasModes.CSAT_TRIGGER_POINT,
    stepType: StepTypes.CSAT_TRIGGER_POINT,
  },
  {
    label: 'Add Attachment',
    mode: CanvasModes.ATTACHMENT_ANALYSIS_UPLOAD,
    stepType: StepTypes.ATTACHMENT_ANALYSIS_UPLOAD,
  },
];

const UNSUPPORTED_STEPS_FOR_API = [
  StepTypes.CSAT_TRIGGER_POINT,
  StepTypes.DYNAMIC_CARD,
];

const UNSUPPORTED_STEPS_FOR_SLACK = [
  StepTypes.CSAT_TRIGGER_POINT,
  StepTypes.DYNAMIC_CARD,
  StepTypes.EMBED,
];

const UNSUPPORTED_STEPS_BY_VIEW: Partial<Record<BuilderView, StepTypes[]>> = {
  api: UNSUPPORTED_STEPS_FOR_API,
  slack: UNSUPPORTED_STEPS_FOR_SLACK,
};

const AddTabContent = ({ ...rest }: BusinessLogicProps) => {
  const { data } = useGetFeatureFlagsQuery();
  const featureFlags = data?.feature_flags;
  const isDynamicListCVEnabled = featureFlags?.includes(
    'dynamic_list_context_variable',
  );
  const isCsatConfigRevampEnabled =
    featureFlags?.includes('csat_config_revamp');
  const isCsatTriggerPointEnabled =
    featureFlags?.includes('csat_trigger_point');
  const isAttachmentAnalysisEnabled = featureFlags?.includes(
    'attachment_analysis_upload_step',
  );
  const { view: currentView } = useGetBuilderQueryParams();

  const filteredSteps = useMemo(() => {
    return ADD_STEPS.filter(step => {
      if (step.stepType === StepTypes.DYNAMIC_CARD && !isDynamicListCVEnabled) {
        return false;
      }

      if (
        step.stepType === StepTypes.CSAT_TRIGGER_POINT &&
        (!isCsatConfigRevampEnabled || !isCsatTriggerPointEnabled)
      ) {
        return false;
      }

      if (
        step.stepType === StepTypes.ATTACHMENT_ANALYSIS_UPLOAD &&
        !isAttachmentAnalysisEnabled
      ) {
        return false;
      }

      if (UNSUPPORTED_STEPS_BY_VIEW[currentView]?.includes(step.stepType)) {
        return false;
      }

      return true;
    });
  }, [
    isDynamicListCVEnabled,
    isCsatConfigRevampEnabled,
    isCsatTriggerPointEnabled,
    isAttachmentAnalysisEnabled,
    currentView,
  ]);

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {filteredSteps.map((props, index) => (
        <DraggableCard {...props} {...rest} key={index} />
      ))}
    </Box>
  );
};

export default AddTabContent;
