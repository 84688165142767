import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { Toggle } from '@forethought-technologies/forethought-elements';
import { useDebouncedSubmitConfigForNonRTE } from '../hooks/useDebouncedSubmitConfig';
import CsatTabConfiguration from './CsatTabConfiguration';
import set from 'lodash/fp/set';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectWidgetConfiguration,
  setCsatConfigByKey,
} from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

const CsatTabContent = () => {
  const dispatch = useAppDispatch();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isCsatConfigEnabled = featureFlags.includes('csat_config_revamp');

  const debouncedSubmitConfigForNonRTE = useDebouncedSubmitConfigForNonRTE();

  if (!isCsatConfigEnabled) {
    return null;
  }

  const { is_enabled: isEnabled } = widgetConfiguration.csat_config;

  return (
    <Box>
      <Box mb={3}>
        <Toggle
          checked={isEnabled}
          label={isEnabled ? 'CSAT is on' : 'CSAT is off'}
          onChange={e => {
            const checked = e.target.checked;
            dispatch(
              setCsatConfigByKey({
                isGenericField: true,
                key: 'is_enabled',
                value: checked,
              }),
            );
            const updatedConfig: WidgetConfiguration = set(
              ['csat_config', 'is_enabled'],
              checked,
              widgetConfiguration,
            );
            debouncedSubmitConfigForNonRTE(
              updatedConfig,
              'csat config - is_enabled',
              (checked || '').toString(),
            );
          }}
        />
      </Box>

      {isEnabled && <CsatTabConfiguration />}
    </Box>
  );
};

export default CsatTabContent;
