import { type ComponentProps } from 'react';

import { Typography } from '@forethought-technologies/forethought-elements';

type TopicCountProps = {
  count?: number;
  variant?: ComponentProps<typeof Typography>['variant'];
};

const TopicCount = (props: TopicCountProps) => {
  if (props.count === undefined) {
    return null;
  }
  const variant = props.variant || 'font14';

  return <Typography variant={variant}>({props.count})</Typography>;
};

export default TopicCount;
