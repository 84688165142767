import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material';
import { IconCopy } from '@tabler/icons-react';

import {
  IconButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import metricsIcon from '../assets/icons/metrics.svg';
import { useGetBuilderQueryParams } from '../hooks';
import BranchStepMetricsWindow from '../step-level-metrics/BranchStepMetricsWindow';
import { handleCopyGroupOfSteps } from '../utils';
import { EditMenuDeleteButton } from './EditMenuDeleteButton';
import { HoverMenu } from './ForethoughtEditMenu';
import { IconButtonWithActive } from './StyledComponents';
import customizeIcon from 'src/assets/images/customize-icon.svg';
import Spinner from 'src/components/spinner';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { ForethoughtEditActionMenuProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  Features,
  Pages,
} from 'src/pages/workflow-builder-edit/workflow-builder/appCuesIdentifiers';
import {
  selectCanvasWorkflowBuilder,
  selectIntentTitle,
  selectIsEditingCondition,
  selectIsEditMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectIsDraft,
  setTargetStepId,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  setGlobalCopyToastOptions,
  setGlobalToastOptions,
} from 'src/slices/ui/uiSlice';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  ActionBuilderActionTypes,
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';

export const ForethoughtEditActionMenu: FC<
  React.PropsWithChildren<ForethoughtEditActionMenuProps>
> = ({
  actionType,
  isDeletionDisabled,
  isVisible,
  onCustomizeClick,
  setIsVisible,
  shouldShowCustomizeButton,
  stepId,
}) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const isEditMode = useSelector(selectIsEditMode);
  const isEditingCondition = useSelector(selectIsEditingCondition);
  const isDraft = useSelector(selectIsDraft);
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const intentTitle = useSelector(selectIntentTitle);
  const { workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );

  // Ref
  const menuRef = useRef<HTMLDivElement | null>(null);

  // States
  const [isMetricsWindowVisible, setIsMetricsWindowVisible] = useState(false);
  const [metricsWrapperPosY, setMetricsWrapperPosY] = useState(200);
  const [metricsWrapperPosX, setMetricsWrapperPosX] = useState(660);
  const [isLoadingMetricsWindow, setIsLoadingMetricsWindow] = useState(false);

  return (
    <>
      <Wrapper
        aria-label='Options menu'
        isVisible={!isEditMode && !isEditingCondition && isVisible}
      >
        <HoverMenu ref={menuRef}>
          {shouldShowCustomizeButton && isDraft && (
            <Tooltip tooltipContent='Customize'>
              <IconButton
                aria-label='customize action'
                onClick={onCustomizeClick}
                variant='ghost'
              >
                <SVGIcon src={customizeIcon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip tooltipContent='Copy all below'>
            <IconButton
              aria-label='Copy action'
              onClick={() => {
                // remove the highlighted step if there's any
                dispatch(setTargetStepId(null));

                const group = handleCopyGroupOfSteps(
                  canvasData,
                  stepId,
                  intentTitle,
                );
                if (group) {
                  dispatch(
                    setGlobalCopyToastOptions({
                      title: `"${intentTitle}" steps are on your clipboard`,
                    }),
                  );
                  dispatchTrackingAction(
                    FlamethrowerTrackingEventTypes.COPY_WORKFLOW_STEPS,
                    {
                      intent_id: selectedWorkflowId,
                      intent_name: intentTitle,
                      num_of_steps_copied: Object.keys(group.step_map).length,
                      org_id: canvasData.org_id,
                    },
                  );
                } else {
                  dispatch(
                    setGlobalToastOptions({
                      title: `Error with copying "${intentTitle}" steps. Not allowed to copy dynamic article suggestion step`,
                      variant: 'danger',
                    }),
                  );
                }
              }}
              variant='ghost'
            >
              <IconCopy
                color={palette.colors.grey[700]}
                size={20}
                style={{ transform: 'scaleX(-1)' }}
              />
            </IconButton>
          </Tooltip>

          {actionType === ActionBuilderActionTypes.ARTICLE_SUGGESTION && (
            <Tooltip tooltipContent='Metrics'>
              <IconButtonWithActive
                aria-label='Metrics for action'
                data-appcues-target={getAppCuesId({
                  componentType: 'Metrics',
                  featureName: Features.CANVAS,
                  pageName: Pages.SOLVE,
                  subType: 'Button',
                })}
                isActive={isMetricsWindowVisible && !isLoadingMetricsWindow}
                onClick={() => {
                  // remove the highlighted step if there's any
                  dispatch(setTargetStepId(null));

                  setIsMetricsWindowVisible(true);
                  if (menuRef.current !== null) {
                    const position = menuRef.current.getBoundingClientRect();
                    setMetricsWrapperPosY(position.y);
                    setMetricsWrapperPosX(position.right + 20);
                  }
                  setIsLoadingMetricsWindow(true);
                }}
              >
                {isLoadingMetricsWindow ? (
                  <Spinner height='20px' />
                ) : (
                  <SVGIcon src={metricsIcon} />
                )}
              </IconButtonWithActive>
            </Tooltip>
          )}
          {isDraft && (
            <EditMenuDeleteButton
              actionType={actionType}
              isCurrentStepDeletable={!isDeletionDisabled}
              setIsEditMenuVisible={setIsVisible}
              stepId={stepId}
            />
          )}
        </HoverMenu>
      </Wrapper>
      {isMetricsWindowVisible &&
        actionType === ActionBuilderActionTypes.ARTICLE_SUGGESTION && (
          <BranchStepMetricsWindow
            actionType={actionType}
            isLoadingMetricsWindow={isLoadingMetricsWindow}
            left={metricsWrapperPosX}
            setIsEditMenuVisible={setIsVisible}
            setIsLoadingMetricsWindow={setIsLoadingMetricsWindow}
            setIsMetricsWindowVisible={setIsMetricsWindowVisible}
            stepId={stepId}
            top={metricsWrapperPosY}
          />
        )}
    </>
  );
};

const Wrapper = styled('div')<{ isVisible: boolean }>`
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

const SVGIcon = styled(ReactSVG)`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
