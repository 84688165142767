import React from 'react';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import CsatIcon from '../assets/icons/metrics-csat.svg';
import DropoffIcon from '../assets/icons/metrics-dropoff.svg';
import SelfServedIcon from '../assets/icons/metrics-self-served.svg';
import TrafficIcon from '../assets/icons/metrics-traffic.svg';

export interface StepMetricsProps {
  csat: {
    average_score: number;
  };
  deflection_rate: {
    count: number;
    total_count: number;
  };
  dropoff?: {
    count: number;
    total_count: number;
  };
  traffic: {
    count: number;
    total_count: number;
  };
}

const StepMetrics: React.FC<React.PropsWithChildren<StepMetricsProps>> = ({
  csat,
  deflection_rate,
  dropoff,
  traffic,
}) => {
  const percentTraffic =
    traffic.total_count === 0
      ? 0
      : Math.round((traffic.count / traffic.total_count) * 1000) / 10;
  const percentDeflectionRate =
    deflection_rate.total_count === 0
      ? 0
      : Math.round(
          (deflection_rate.count / deflection_rate.total_count) * 1000,
        ) / 10;
  const percentDropoff = dropoff
    ? dropoff.total_count === 0
      ? 0
      : Math.round((dropoff.count / dropoff.total_count) * 1000) / 10
    : null;

  const roundedCsat = csat.average_score?.toFixed(2);

  const displaySelfServedRate = deflection_rate.total_count > 0;
  const displayTrafficPercentage = traffic.total_count > 0;
  const displayCSAT = csat.average_score !== null;
  const displayDropoffPercentage = (dropoff?.total_count ?? 0) > 0;

  return (
    <Container>
      <Row>
        <Label>
          <Icon src={TrafficIcon} />
          <Typography variant='font14Medium'>Traffic</Typography>
        </Label>
        <Value>
          {displayTrafficPercentage && (
            <Typography variant='font14Medium'>{percentTraffic}%</Typography>
          )}
          <Typography
            color={theme.palette.colors.grey[600]}
            variant='font14Medium'
          >
            {traffic.count}
          </Typography>
        </Value>
      </Row>
      {displaySelfServedRate && (
        <Row>
          <Label>
            <Icon src={SelfServedIcon} />
            <Typography variant='font14Medium'>Self service rate</Typography>
          </Label>
          <Value>
            <Typography variant='font14Medium'>
              {percentDeflectionRate}%
            </Typography>
          </Value>
        </Row>
      )}
      {displayCSAT && (
        <Row>
          <Label>
            <Icon src={CsatIcon} />
            <Typography variant='font14Medium'>CSAT</Typography>
          </Label>
          <Value>
            <Typography variant='font14Medium'>{roundedCsat}</Typography>
          </Value>
        </Row>
      )}
      {dropoff && (
        <Row>
          <Label>
            <Icon src={DropoffIcon} />
            <Typography variant='font14Medium'>Dropoff</Typography>
          </Label>
          <Value>
            {displayDropoffPercentage && (
              <Typography variant='font14Medium'>{percentDropoff}%</Typography>
            )}
            <Typography
              color={theme.palette.colors.grey[600]}
              variant='font14Medium'
            >
              {dropoff.count}
            </Typography>
          </Value>
        </Row>
      )}
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.colors.white};
  gap: 8px;
  width: 308px;
`;

export const Label = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Value = styled('div')`
  display: flex;
  gap: 8px;
`;

export const Row = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled(ReactSVG)`
  height: 20px;
`;

export default StepMetrics;
