import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getCustomIntents } from 'src/actions/discover/discover';
import { selectCustomIntents } from 'src/reducers/discoverReducer/discoverReducer';
import { useAppDispatch } from 'src/store/hooks';

export const useGetCustomIntents = (isSolveEnabled: boolean) => {
  const dispatch = useAppDispatch();
  const intents = useSelector(selectCustomIntents);

  useEffect(() => {
    if (!isSolveEnabled) {
      return;
    }
    dispatch(getCustomIntents());
  }, [dispatch, isSolveEnabled]);

  return intents;
};
