import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconBulb } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';

const TopicDescription = ({ description }: { description?: string | null }) => {
  const { palette } = useTheme();

  if (!description) {
    return null;
  }

  const mainColor = palette.colors.purple[500];

  return (
    <Box
      borderRadius={2}
      display='flex'
      flexDirection='column'
      mb={3}
      px={3}
      py={2}
      rowGap={1}
      sx={{ border: `1px solid ${mainColor}` }}
    >
      <Box
        alignItems='center'
        columnGap={0.5}
        display='flex'
        sx={{ '&>span': { fontWeight: 600 } }}
      >
        <IconBulb color={mainColor} height={20} width={20} />
        <Typography variant='font12'>Summary</Typography>
      </Box>
      <Typography variant='font14'>{description}</Typography>
    </Box>
  );
};

export default TopicDescription;
