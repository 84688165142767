import { styled } from '@mui/material/styles';

import automatedDiscoverIcon from 'src/assets/images/automated-discover.svg';

const DiscoverAutomatedBadge = ({ size = 18 }: { size?: number }) => {
  return <DiscoverBadgeStyled size={size} src={automatedDiscoverIcon} />;
};

const DiscoverBadgeStyled = styled('img')(
  ({ size }: { size: number; src: string }) => ({
    height: `${size}px`,
    width: `${size}px`,
  }),
);

export default DiscoverAutomatedBadge;
