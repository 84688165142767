import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import { selectEditingStepId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { CsatTriggerPointStepFields } from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';

// Not used for now, but might be needed in the future
interface CsatTriggerPointStepProps {
  fields?: CsatTriggerPointStepFields;
  stepId?: string;
}

const CsatTriggerPointStep = ({ stepId }: CsatTriggerPointStepProps) => {
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const theme = useTheme();
  const editingStepId = useSelector(selectEditingStepId);
  const isBeingEdited = editingStepId === stepId || !stepId;

  const { is_enabled: isEnabled } = widgetConfiguration.csat_config;

  return (
    <StepContainer isBeingEdited={isBeingEdited}>
      <StepHeader stepType={StepTypes.CSAT_TRIGGER_POINT} />
      <Typography variant='font16Bold'>CSAT Survey Trigger Point</Typography>
      {!isEnabled && (
        <Box>
          <Typography color={theme.palette.colors.grey[600]} variant='font11'>
            CSAT is turned off in the configuration. Switch it on to enable this
            option.
          </Typography>
        </Box>
      )}
    </StepContainer>
  );
};

export default CsatTriggerPointStep;
