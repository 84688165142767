import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createConnector,
  deleteConnector,
  toggleConnectorIsActive,
  updateConnector,
} from './thunks';
import { ConnectorsSliceState } from './types';
import { Connector } from 'src/services/apiInterfaces';
import type { RootState } from 'src/store/rootReducer';

export const initialState: ConnectorsSliceState = {
  availableConnectors: [],
  createdConnectors: [],
  isActiveStateLoading: false,
  isEditing: false,
  isLoading: false,
  isUpdateError: false,
  isUpdateLoading: false,
  numConnectorChanges: 0,
};

const connectorsSlice = createSlice({
  extraReducers: builder => {
    /**
     * toggleConnectorIsActive
     */
    builder.addCase(toggleConnectorIsActive.pending, state => {
      state.isActiveStateLoading = true;
      state.isUpdateError = false;
    });
    builder.addCase(toggleConnectorIsActive.fulfilled, (state, action) => {
      const toggledConnector = state.createdConnectors.find(
        ({ connector_id }) => connector_id === action.meta.arg.connectorId,
      );
      if (toggledConnector) {
        toggledConnector.is_active = action.meta.arg.isActive;
      }
      state.isActiveStateLoading = false;
      state.numConnectorChanges += 1;
    });
    builder.addCase(toggleConnectorIsActive.rejected, state => {
      state.isActiveStateLoading = false;
      state.isUpdateError = true;
    });
    /**
     * deleteConnector
     */
    builder.addCase(deleteConnector.pending, state => {
      state.isActiveStateLoading = true;
      state.isUpdateError = false;
    });
    builder.addCase(deleteConnector.fulfilled, (state, action) => {
      state.createdConnectors = state.createdConnectors.filter(connector => {
        return connector.connector_id !== action.meta.arg.connectorId;
      });
      state.availableConnectors = action.payload;
      state.isActiveStateLoading = false;
      state.numConnectorChanges += 1;
    });
    builder.addCase(deleteConnector.rejected, state => {
      state.isActiveStateLoading = false;
      state.isUpdateError = true;
    });
    /**
     * createConnector
     */
    builder.addCase(createConnector.pending, state => {
      state.isUpdateLoading = true;
      state.isUpdateError = false;
    });
    builder.addCase(createConnector.fulfilled, (state, action) => {
      state.createdConnectors.push(action.payload);
      state.availableConnectors = state.availableConnectors.filter(
        ({ slug }) => {
          return slug !== action.payload.connector_definition.slug;
        },
      );
      state.numConnectorChanges += 1;
    });
    builder.addCase(createConnector.rejected, state => {
      state.isUpdateLoading = false;
      state.isUpdateError = true;
    });
    /**
     * updateConnector
     */
    builder.addCase(updateConnector.pending, state => {
      state.isUpdateLoading = true;
      state.isUpdateError = false;
    });
    builder.addCase(updateConnector.fulfilled, (state, action) => {
      const updatedConnector = action.payload;
      const updatedConnectorList = state.createdConnectors.map(connector => {
        if (connector.connector_id === updatedConnector.connector_id) {
          return updatedConnector;
        }
        return connector;
      });
      state.createdConnectors = updatedConnectorList;
      state.isEditing = false;
      state.numConnectorChanges += 1;
    });
    builder.addCase(updateConnector.rejected, state => {
      state.isUpdateLoading = false;
      state.isUpdateError = true;
    });
  },
  initialState,
  name: 'connectors',
  reducers: {
    addConnectedConnector: (state, action: PayloadAction<Connector>) => {
      const existingAvailableConnector = state.createdConnectors.find(
        ({ connector_id }) => connector_id !== action.payload.connector_id,
      );
      if (existingAvailableConnector) {
        state.createdConnectors.push(action.payload);
        state.availableConnectors = state.availableConnectors.filter(
          ({ slug }) => slug !== action.payload.connector_definition.slug,
        );
      }
    },
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    setIsUpdateError: (state, action: PayloadAction<boolean>) => {
      state.isUpdateError = action.payload;
    },
    setIsUpdateLoading: (state, action: PayloadAction<boolean>) => {
      state.isUpdateLoading = action.payload;
    },
  },
});

export const {
  addConnectedConnector,
  setIsEditing,
  setIsUpdateError,
  setIsUpdateLoading,
} = connectorsSlice.actions;

export const selectConnectors = (state: RootState): ConnectorsSliceState =>
  state.connectors;

export const selectNumConnectorChanges = (state: RootState): number =>
  state.connectors.numConnectorChanges;

export default connectorsSlice.reducer;
