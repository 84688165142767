import { styled } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';

const Wrapper = styled('div')<{ statusColor: string }>`
  display: flex;
  border-radius: 4px;
  padding: 13px 10px;
  margin-bottom: 15px;

  ${({ statusColor }) => `
    background-color: ${statusColor};
  `}
`;

const Content = styled('div')`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  margin-left: 8px;
`;

const Icon = styled('img')`
  align-self: flex-start;
  margin-top: 2px;
`;

interface ActionStatusProps {
  description: string;
  iconSrc: string;
  statusColor: string;
  title: string;
}

export default function ActionStatus({
  description,
  iconSrc,
  statusColor,
  title,
}: ActionStatusProps) {
  return (
    <Wrapper statusColor={statusColor}>
      <Icon src={iconSrc} />
      <Content>
        <Typography variant='font14Bold'>{title}</Typography>
        <Typography variant='font12'>{description}</Typography>
      </Content>
    </Wrapper>
  );
}
