import ReactDOMServer from 'react-dom/server';
import Highcharts from 'highcharts';
import moment from 'moment';
import { Theme } from '@mui/material';

import { DEFLECTIONS, PREDICTIONS, PROJECTED } from './constants';
import {
  PlanUsage,
  SubscriptionPlan,
} from 'src/services/subscription-plan/types';

const getIsUpcomingMonth = (month: string) => {
  const parsedMonth = moment(month, 'MMMM YYYY');
  const currentMonth = moment();
  return parsedMonth.isAfter(currentMonth, 'month');
};

const getIsCurrentMonth = (month: string) => {
  const parsedMonth = moment(month, 'MMMM YYYY');
  const currentMonth = moment();
  return parsedMonth.isSame(currentMonth, 'month');
};

export const generateTooltip = ({
  contractQuota,
  object,
  theme,
  title,
}: {
  contractQuota: number;
  object: Highcharts.TooltipFormatterContextObject;
  theme: Theme;
  title: string;
}) => {
  const stringifiedXaxisValue = object.x?.toString() || '';
  const tooltipTitle = getIsUpcomingMonth(stringifiedXaxisValue)
    ? `${PROJECTED} ${title}`
    : title;
  const xAxisLabel = stringifiedXaxisValue.replace(/<br>/g, '');

  const getOverQuotaValue = () => {
    const currentValue = object.y || 0;
    if (currentValue > contractQuota) {
      return currentValue - contractQuota;
    }
    return 0;
  };

  const separateThousandsByComma = (value: number) => value.toLocaleString();

  const tooltip = (
    <div
      style={{
        border: `1px solid ${theme.palette.colors.slate[200]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        justifyContent: 'left',
        minWidth: '146px',
        padding: '8px',
      }}
    >
      <span
        style={{
          color: theme.palette.text.primary,
          fontSize: 14,
          fontWeight: 600,
        }}
      >
        {tooltipTitle}
      </span>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <div style={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
          <div
            style={{
              backgroundColor: object.color?.toString() || '',
              borderRadius: '2px',
              height: 12,
              width: 12,
            }}
          ></div>
          <span
            style={{
              color: theme.palette.colors.grey[600],
              fontSize: 12,
              fontWeight: 400,
            }}
          >
            {xAxisLabel}
          </span>
        </div>
        <span
          style={{
            color: theme.palette.text.primary,
            display: 'block',
            fontSize: 14,
            fontWeight: 600,
            marginLeft: '20px',
          }}
        >
          {separateThousandsByComma(object.y || 0)}
        </span>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          marginTop: '8px',
        }}
      >
        <span
          style={{
            color: theme.palette.grey[600],
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          Over quota
        </span>
        <span
          style={{
            color: theme.palette.text.primary,
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {separateThousandsByComma(getOverQuotaValue())}
        </span>
      </div>
    </div>
  );
  return ReactDOMServer.renderToString(tooltip);
};

export const getCategories = (usage: PlanUsage[]) => {
  return usage.map(({ month }) => {
    const parsedMonth = moment(month, 'MMMM YYYY');

    const formattedDate = parsedMonth.format('MMM YYYY');

    return getIsCurrentMonth(month)
      ? `${formattedDate} <br> (Current)`
      : formattedDate;
  });
};

export const getUsageData = (
  tab: string,
  data: SubscriptionPlan | undefined,
) => {
  if (!data) {
    return {};
  }

  switch (tab) {
    case 'solve':
      return {
        allocatedValue: data.allocated_solve_deflections,
        tooltipTitle: `${DEFLECTIONS}`,
        usage: data.solve_usage,
        yAxisLabel: DEFLECTIONS,
      };
    case 'triage':
      return {
        allocatedValue: data.allocated_triage_predictions,
        tooltipTitle: `${PREDICTIONS}`,
        usage: data.triage_usage,
        yAxisLabel: PREDICTIONS,
      };
    default:
      return {};
  }
};

export const formatNumber = (num: number) => {
  if (num >= 1_000_000) {
    const millions = num / 1_000_000;
    // If the number in millions is a whole number, format without decimals
    return millions % 1 === 0 ? `${millions}M` : `${millions.toFixed(2)}M`;
  } else if (num >= 100_000) {
    // Format with 'k' suffix, representing thousands
    return `${Math.floor(num / 1_000)}k`;
  } else if (num >= 1_000) {
    // Format with commas for thousands
    return num.toLocaleString();
  }
  // For numbers less than 1,000, return the number as a string without formatting
  else {
    return num.toString();
  }
};
