import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { styled, useTheme } from '@mui/material/styles';

import {
  Button,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { setIsOverlayVisible } from 'src/actions/workflow-builder/workflowBuilderActions';
import EqualIcon from 'src/assets/images/equal-icon.svg';
import BaseModal from 'src/components/base-modal';
import UnsavedChangesModal from 'src/components/unsaved-changes-modal';
import { AuthorizationConfig } from 'src/types/actionBuilderApiTypes';
import { ActionBuilderSettingsTokensDict } from 'src/utils/actionBuilder/types';

interface NewBearerTokenModalProps {
  authorizationConfigs: AuthorizationConfig[];
  authType: string;
  isDisabled: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSave: (token: ActionBuilderSettingsTokensDict) => void;
}

export const NewBearerTokenModal = ({
  authorizationConfigs,
  authType,
  isDisabled,
  isOpen,
  onClose,
  onSave,
}: NewBearerTokenModalProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [token, setToken] = useState<ActionBuilderSettingsTokensDict>({
    name: '',
    value: '',
  });

  const tokenIds = useMemo(
    () => authorizationConfigs.map(config => config.authorization_id),
    [authorizationConfigs],
  );

  const isTokenNameFilled = token.name.trim() !== '';

  const isTokenNameValid = !(
    tokenIds.some(id => id === token.name) || !isTokenNameFilled
  );
  const isTokenValueValid = token.value.trim() !== '';

  const handleOnClose = () => {
    setToken({ name: '', value: '' });
    dispatch(setIsOverlayVisible(false));
    onClose();
  };

  const handleOnCloseRoute = () => {
    if (isTokenNameFilled || isTokenValueValid) {
      dispatch(setIsOverlayVisible(true));
    } else {
      handleOnClose();
    }
  };

  return (
    <>
      <BaseModal
        headerTitle={
          <Typography variant='font16Bold'>
            Create a new bearer token
          </Typography>
        }
        isOpen={isOpen}
        maxWidth='sm'
        onClose={handleOnCloseRoute}
      >
        <DialogContent sx={{ py: 0, ...theme.typography.font14 }}>
          <SelectDropdown
            disabled
            id='api-token-type-dropdown'
            label='API token type'
            onChange={() => null}
            options={[
              {
                label: authType,
                value: authType,
              },
            ]}
            placeholder={authType}
            value={authType}
          />
          <Box paddingBottom={2} />
          <FormContainer>
            <TextField
              error={!isTokenNameValid}
              label='API token name'
              onChange={({ target }) =>
                setToken({ ...token, name: target.value })
              }
              placeholder='Enter a token name'
              value={token.name}
            />
            <ImageContainer alt='' src={EqualIcon} />
            <TextField
              error={!isTokenValueValid}
              label='Value'
              onChange={({ target }) =>
                setToken({ ...token, value: target.value })
              }
              placeholder='Enter a value'
              value={token.value}
            />
          </FormContainer>
          <FooterContainer>
            <Button onClick={handleOnCloseRoute} variant='ghost'>
              Cancel
            </Button>
            <Button
              disabled={!(isTokenNameValid && isTokenValueValid) || isDisabled}
              onClick={() => {
                onSave(token);
                handleOnClose();
              }}
              variant='main'
            >
              Save
            </Button>
          </FooterContainer>
        </DialogContent>
      </BaseModal>
      <UnsavedChangesModal
        onCancel={() => dispatch(setIsOverlayVisible(false))}
        onDiscard={handleOnClose}
      />
    </>
  );
};

const FooterContainer = styled('div')`
  display: flex;
  gap: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: end;
`;

const FormContainer = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const ImageContainer = styled('img')`
  padding-top: 25px;
`;
