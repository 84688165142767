import React from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { captureMessage, configureScope } from '@sentry/react';

import App from '../app/app';
import SSO from '../dashboard-pages/sso';
import GlobalCopyToast from '../global-copy-toast/GlobalCopyToast';
import GlobalToast from '../global-toast';
import Spinner from '../spinner';
import ForgotUserPassword from './forgot-password/forgot.password';
import Login from './login/login';
import AssistLogin from './assist-login';
import CreatePassword from './create-password';
import MagicLink from './magic-link';
import OrgNotFound from './org-not-found';
import SignUp from './signup';
import WithOrgSelected from './with-org-selector';
import {
  selectUser,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import * as API from 'src/services/api';
import { IdPDetails } from 'src/services/apiInterfaces';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { RootState } from 'src/store/rootReducer';
import GlobalStyle from 'src/styles/globalStyle';
import { sendSessionToAssistExtension } from 'src/utils/assistAuthUtils';
import { setCognitoNetworkErrorFixEnabled } from 'src/utils/authUtils';
import { Routes as AppRoutes, UserRoles } from 'src/utils/enums';
import { withRouter, WithRouterProps } from 'src/utils/withRouter';

const USER_EXISTS_ERROR_REGEX = /already.found.an.entry.for.username/gi;
const PRESIGNUP_FAILED_ERROR_REGEX =
  /PreSignUp.failed.with.error.User.does.not.exist/gi;

// This version of the Amplify lib types 'data' as 'any', so that is what we use here. When we update that package, this should be fixed as well.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRoleFromUser(amplifyHubPayload: any): `${UserRoles}` | null {
  return amplifyHubPayload?.signInUserSession?.idToken?.payload?.[
    'cognito:groups'
  ]?.[0];
}

const INTERNAL_SUBDOMAINS = [
  'localhost',
  'dashboard-int',
  'dashboard-stage-int',
];

type MyState = {
  authState: string;
  isLoading: boolean;
  isSSOEnabled: boolean;
  isSSOEnforced: boolean;
  userRole: `${UserRoles}` | null;
};

class InitApp extends React.Component<
  PropsFromRedux & WithRouterProps,
  MyState
> {
  constructor(props: PropsFromRedux & WithRouterProps) {
    super(props);
    this.state = {
      authState: 'signIn',
      isLoading: true,
      isSSOEnabled: false,
      isSSOEnforced: false,
      userRole: null,
    };
  }

  async componentDidMount(): Promise<void> {
    const propsUser = this.props.user;
    const { auth_time: sessionId } = propsUser?.user ?? {};
    const eventBody = {
      sessionId,
      userRole: this.props.userRole || this.state.userRole,
    };
    const queryParams = new URLSearchParams(this.props.location.search);
    const cognitoErrorMessage = queryParams.get('error_description');

    // Due to a known issue with Cognito, users have to go through the SSO page twice when signing up the first time.
    // This will catch an error and redirect them back to the single-sign-on page.
    if (
      cognitoErrorMessage &&
      USER_EXISTS_ERROR_REGEX.test(cognitoErrorMessage.toString())
    ) {
      this.props.navigate(AppRoutes.SINGLE_SIGN_ON);
    }

    if (
      cognitoErrorMessage &&
      PRESIGNUP_FAILED_ERROR_REGEX.test(cognitoErrorMessage.toString())
    ) {
      this.props.setGlobalToastOptions({
        subtitle:
          'Please reach out to your account administrator for an invite.',
        title: 'You do not have access to this account.',
        variant: 'danger',
      });
      this.props.navigate('/');
    }

    let provider: IdPDetails | null = null;
    let isSSOEnabled = false;
    let isSSOEnforced = false;

    const subdomain = window.location.hostname.split('.')[0];

    // if subdomain is not a reserved internal subdomain, fetch org-specific idp/sso details based on subdomain
    if (!INTERNAL_SUBDOMAINS.includes(subdomain)) {
      try {
        const {
          cognito_network_error_fix_enabled,
          idp_details,
          is_sso_enabled,
          is_sso_enforced,
        } = await API.getOrgDiscovery(subdomain);
        setCognitoNetworkErrorFixEnabled(cognito_network_error_fix_enabled);
        provider = idp_details;
        isSSOEnabled = is_sso_enabled;
        isSSOEnforced = is_sso_enforced;
      } catch (error) {
        console.error(
          `error fetching identity provider: ${JSON.stringify(error)}`,
        );
      }
    }

    Amplify.configure({
      Auth: {
        oauth: {
          domain: DOMAIN,
          redirectSignIn: window.location.origin,
          redirectSignOut: `${window.location.origin}/logout`,
          responseType: 'code',
          scope: ['email', 'openid', 'profile'],
        },
        region: 'us-west-2a',
        userPoolId: COGNITO_USER_POOL,
        userPoolWebClientId: provider?.pool_client_id || COGNITO_WEB_POOL,
      },
    });

    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          // Tell the assist extension the auth state has changed
          sendSessionToAssistExtension();
          this.setState({
            authState: 'signedIn',
            userRole: getRoleFromUser(data.payload.data),
          });
          API.emitTrackingEventApi({
            ...eventBody,
            eventType: 'dashboard-sign-in',
          });
          break;
        case 'signIn_failure':
          this.setState({ authState: 'signIn' });
          break;
        case 'signOut':
        case 'oAuthSignOut':
          // Tell the assist extension the auth state has changed
          sendSessionToAssistExtension();
          this.setState({ authState: 'signOut' });
          API.emitTrackingEventApi({
            ...eventBody,
            eventType: 'dashboard-sign-out',
          });
          break;
        case 'customOAuthState':
          const url = atob(unescape(data.payload.data));
          if (window.location.pathname !== `${url}`) {
            window.location.href = `${window.location.origin}${url}`;
          }
          break;
        case 'tokenRefresh_failure':
          configureScope(scope => {
            scope.setExtra('data', data);
            captureMessage(data.payload.event);
          });
          API.emitTrackingEventApi({
            ...eventBody,
            eventType: 'dashboard-sign-out',
          });
          break;
        default:
          break;
      }
    });

    // Every time the page loads, send the current auth state to the assist extension
    // If the `signout` query param is set, log out the current user and remove the parameter
    if (queryParams.has('signout')) {
      queryParams.delete('signout');
      this.props.navigate(
        {
          search: queryParams.toString(),
        },
        { replace: true },
      );
      await API.emitTrackingEventApi({
        ...eventBody,
        eventType: 'dashboard-sign-out',
      });
      await Auth.signOut();
    } else {
      sendSessionToAssistExtension();
    }

    try {
      const data = await Auth.currentAuthenticatedUser();

      if (data) {
        const userRole = getRoleFromUser(data);

        this.setState({
          authState: 'signedIn',
          isLoading: false,
          isSSOEnabled,
          isSSOEnforced,
          userRole,
        });
      }
    } catch {
      if (this.state.authState !== 'signedIn') {
        this.setState({
          authState: 'signIn',
          isLoading: false,
          isSSOEnabled,
          isSSOEnforced,
        });
      }
    }
  }

  render() {
    const { host } = window.location;
    const isInternalUI = host === INTERNAL_UI_URL;
    const { authState, isLoading, isSSOEnabled, isSSOEnforced, userRole } =
      this.state;
    const isSignedIn = authState === 'signedIn';
    const isSuperAdmin = userRole === UserRoles.ROLE_SUPER_ADMIN;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        {/*
          Global components go here if they are needed in logged-in and logged-out state.
          If the component requires user auth it belongs in <App /> or further down in the component tree.
        */}
        <GlobalStyle />
        <GlobalToast />
        <GlobalCopyToast />
        <Routes>
          <Route element={<SSO />} path={AppRoutes.SINGLE_SIGN_ON} />
          {isSignedIn ? (
            <Route
              element={
                isSuperAdmin ? (
                  <WithOrgSelected>
                    <App />
                  </WithOrgSelected>
                ) : (
                  <App />
                )
              }
              path='*'
            />
          ) : (
            <>
              <Route element={<AssistLogin />} path={AppRoutes.ASSIST_LOGIN} />
              <Route
                element={<CreatePassword />}
                path={AppRoutes.CREATE_PASSWORD}
              />
              <Route element={<OrgNotFound />} path={AppRoutes.ORG_NOT_FOUND} />
              <Route element={<MagicLink />} path={AppRoutes.MAGIC_LINK} />
              <Route
                element={<ForgotUserPassword />}
                path={AppRoutes.FORGOT_PASSWORD}
              />
              {isInternalUI ? (
                <Route element={<SignUp />} path={AppRoutes.SIGN_UP} />
              ) : null}
              <Route
                element={
                  <Login
                    isSSOEnabled={isSSOEnabled}
                    isSSOEnforced={isSSOEnforced}
                  />
                }
                path='*'
              />
            </>
          )}
        </Routes>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: selectUser(state),
  userRole: selectUserRole(state),
});

const connector = connect(mapStateToProps, { setGlobalToastOptions });
type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedInitApp = connector(
  withRouter<PropsFromRedux & WithRouterProps>(InitApp),
);

export default ConnectedInitApp;
