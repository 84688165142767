import { DISCOVER_SHARED_PARAM_NAMES } from 'src/constants/discover';
import { DiscoverRecommendationTopicData } from 'src/reducers/discoverReducer/types';
import { replaceIdInRoute } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

const a11yName = 'discover-automation';

export const a11yTabProps = (index: number) => {
  return {
    'aria-controls': `${a11yName}-tabpanel-${index}`,
    id: `${a11yName}-tab-${index}`,
  };
};

export const a11yTabPanelProps = (index: number) => {
  return {
    'aria-labelledby': `${a11yName}-tab-${index}`,
    id: `${a11yName}-tabpanel-${index}`,
  };
};

const generateUrlParams = (name: string, articleId = '') => {
  const params = new URLSearchParams({
    name,
    [DISCOVER_SHARED_PARAM_NAMES.SOURCE]: 'automation',
  });

  if (articleId) {
    params.set(DISCOVER_SHARED_PARAM_NAMES.SELECTED_ARTICLE_ID, articleId);
    params.set(DISCOVER_SHARED_PARAM_NAMES.TAB, 'Generated articles');
    params.set(DISCOVER_SHARED_PARAM_NAMES.SOURCE, 'knowledge gap');
  }

  return params.toString();
};

export const generateDiscoverTopicDetailUrl = (
  topic: DiscoverRecommendationTopicData & { article_id?: string },
) => {
  return `${replaceIdInRoute(
    Routes.DISCOVER_TOPIC_DETAIL,
    topic.topic_id,
  )}?${generateUrlParams(topic.topic_name, topic.article_id)}`;
};
