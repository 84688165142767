import { v4 as uuidv4 } from 'uuid';

import { ComponentType, IntentEmailConfigurationComponent } from '../../types';
import {
  ComponentDisplayFields,
  ComponentFields,
  EmailBuilderComponent,
  EmailBuilderConfigurationKey,
} from 'src/components/email-builder/types';
import {
  BRACKETED_VALUE_REGEX,
  INVALID_CV_DATA_ATTR,
} from 'src/components/rich-text-editor/InvalidCVCheckerExtension';
import { UUID_REGEX } from 'src/constants/regex';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { makeContextVariableAware } from 'src/utils/makeContextVariableAware';

/**
 * Generate a new email builder component based on component type.
 * Add a temporary id to be replaced by backend response.
 */
export function generateTemporaryComponent({
  componentFields,
  componentType,
  displayFields,
}: {
  componentFields: ComponentFields;
  componentType: EmailBuilderConfigurationKey;
  displayFields?: ComponentDisplayFields;
}) {
  if (componentType === 'macro') {
    // The macro component uses the same text editor as the text component, before saving the first time we need to set the editor's initial value here.
    componentFields.message = displayFields?.html || '';
  }

  return {
    component_fields: componentFields,
    component_id: uuidv4(),
    component_type: componentType,
    display_fields: displayFields,
    temporary: true,
  };
}

/**
 * For some components there are slight differences between the component_fields and display_fields properties
 * For example, the 'static_article' component will have a 'source_type' field, while the backend expects 'document_type'
 * This function formats the component in the format the backend expects
 */
export function formatRequestComponentFields(
  component: EmailBuilderComponent,
  contextVariables: ContextVariable[],
): ComponentFields {
  const { component_type } = component;

  if (component_type === 'static_article') {
    const { source_type, ...otherComponentFields } = component.component_fields;
    return {
      document_type: source_type,
      ...component.display_fields,
      ...otherComponentFields,
    };
  }

  if (component_type === 'text_message') {
    return {
      ...component.component_fields,
      message: makeContextVariableAware(
        component.component_fields.message,
        contextVariables,
      ),
    };
  }

  if (component_type === 'macro') {
    return {
      ...component.component_fields,
      message: makeContextVariableAware(
        component.component_fields.message,
        contextVariables,
      ),
      standardized_document_id: component.component_fields.document_id,
    };
  }

  if (component_type === 'image') {
    return {
      ...component.component_fields,
      image_url: component.component_fields.image_url,
      standardized_document_id: component.component_fields.document_id,
    };
  }

  return component.component_fields;
}

/**
 * Mapping frontend component types to types the backend expects.
 * For now, only for interactive_email_button (a unique component type on the frontend) to button (a generic component type on the backend
 */
export function formatRequestComponentType(
  emailBuilderComponentType: EmailBuilderConfigurationKey,
): ComponentType {
  if (emailBuilderComponentType === 'interactive_email_button') {
    return 'button';
  }

  return emailBuilderComponentType;
}

/**
 * Some frontend component types differ from those on the backend.
 * For now, only for interactive_email_button (a unique component type on the frontend) which is defined as 'button' on the backend.
 */
export function formatEmailBuilderComponent(
  component: IntentEmailConfigurationComponent,
): EmailBuilderComponent {
  if (
    component.component_type === 'button' &&
    component.component_fields.button_type === 'interactive_email'
  ) {
    return {
      ...component,
      component_type: 'interactive_email_button',
    };
  }

  return component;
}

/**
 * Check if str has bracketed values that are not UUIDs
 * 'oh hi {{current_user.name}}' -> true
 * 'oh hi {{ae887076-6d94-4a2e-b4df-c5528b955357}}' -> false
 */
export function hasUnprocessibleContextVariables(str: string) {
  const bracketedValues = [...str.matchAll(BRACKETED_VALUE_REGEX)];
  return bracketedValues.some(value => !UUID_REGEX.test(value[1]));
}

export function replaceUnprocessibleContextVariables(str: string) {
  return str.replace(BRACKETED_VALUE_REGEX, (bracketedValue, value) => {
    if (UUID_REGEX.test(value)) {
      return bracketedValue;
    }
    // to be rendered as a special node by InvalidCVCheckerExtension
    return `<span ${INVALID_CV_DATA_ATTR}="${bracketedValue}">${bracketedValue}</span>`;
  });
}

/**
 * Convert input values to their correct types
 */
export const convertInputToCorrectType = (
  value: string,
): string | number | boolean => {
  // Check for boolean values
  if (value.toLowerCase() === 'true') {
    return true;
  } else if (value.toLowerCase() === 'false') {
    return false;
  }

  // Check for integer
  if (!isNaN(parseInt(value)) && !isNaN(Number(value))) {
    return parseInt(value);
  }

  // If it's not a number or boolean, return it as a string
  return value;
};
