import { NodeTypes } from 'reactflow';

import DropzoneNode from './DropzoneNode';

export const FLOW_NODES = {
  DROPZONE: 'DROPZONE',
} as const;

// the default node types for this package
// other node types can be passed in as flowProps
const nodeTypes: NodeTypes = {
  [FLOW_NODES.DROPZONE]: DropzoneNode,
};

export default nodeTypes;
