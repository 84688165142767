import { useState } from 'react';

import { REQUIRED_LABELS } from '../constants';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

export const useTestDrawer = (model: VersionedTriageModel) => {
  const [isTestDrawerOpen, setIsTestDrawerOpen] = useState(false);
  const testDisabled = model.labels.length < REQUIRED_LABELS;

  return {
    isTestDrawerOpen,
    setIsTestDrawerOpen,
    testDisabled,
  };
};
