import useInfiniteScroll from 'react-infinite-scroll-hook';
import { styled } from '@mui/material';
import { Box } from '@mui/system';

import { DraggableComponent } from 'src/components/email-builder';
import Spinner from 'src/components/spinner';
import { DocumentPreview } from 'src/pages/workflow-builder-edit/DocumentPreview';
import { ComponentType } from 'src/pages/workflow-builder-edit/types';
import { Article } from 'src/types/workflowBuilderAPITypes';

export default function DocumentList({
  componentType,
  disabled,
  documents,
  hasNextPage,
  loading,
  loadMoreItems,
}: {
  componentType: ComponentType;
  disabled: boolean;
  documents: Article[];
  hasNextPage: boolean;
  loading: boolean;
  loadMoreItems: () => void;
}) {
  const [listenerRef] = useInfiniteScroll({
    disabled,
    hasNextPage,
    loading,
    onLoadMore: loadMoreItems,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <ListContainer>
      {documents.map(document => {
        const { document_id, source_type, ...rest } = document;
        return (
          <DraggableComponent
            componentMetadata={{
              componentFields: {
                document_id,
                source_type,
              },
              displayFields: {
                ...rest,
              },
            }}
            componentType={componentType}
            key={source_type + document_id}
          >
            <DocumentPreview article={document} />
          </DraggableComponent>
        );
      })}
      {hasNextPage && (
        <div ref={listenerRef}>
          <Spinner />
        </div>
      )}
    </ListContainer>
  );
}

const ListContainer = styled(Box)`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
