import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import OnboardingCard from './OnboardingCard';
import OnboardingCompleted from './OnboardingCompleted';
import iconOnboardingCompleted1 from 'src/assets/images/onboarding-completed-1.svg';
import iconOnboardingCompleted2 from 'src/assets/images/onboarding-completed-2.svg';
import iconOnboardingCompleted3 from 'src/assets/images/onboarding-completed-3.svg';
import iconOnboardingCompleted4 from 'src/assets/images/onboarding-completed-4.svg';
import { selectOnboardingFlags } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { getOnboardingFlags } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

export default function OnboardingCards() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const onboardingFlags = useSelector(selectOnboardingFlags);

  useEffect(() => {
    dispatch(getOnboardingFlags());
  }, [dispatch]);

  if (onboardingFlags === null) {
    return <></>;
  }

  const {
    is_bot_activated,
    is_handoff_onboarding_passed,
    is_knowledge_onboarding_passed,
    is_onboarding_finished,
    is_widget_onboarding_passed,
  } = onboardingFlags;

  const isOnboardingCompletedCardVisible =
    is_bot_activated &&
    is_handoff_onboarding_passed &&
    is_knowledge_onboarding_passed &&
    is_widget_onboarding_passed;

  if (is_onboarding_finished) {
    return null;
  }

  return isOnboardingCompletedCardVisible ? (
    <OnboardingCompleted />
  ) : (
    <Box
      sx={{
        [theme.breakpoints.down('lg')]: {
          flexWrap: 'wrap',
        },
        alignItems: 'center',
        backgroundColor: theme.palette.colors.slate[200],
        display: 'flex',
        gap: '16px',
        justifyContent: 'space-evenly',
        marginBottom: '15px',
        padding: '40px 20px',
      }}
    >
      <OnboardingCard
        completedIconSrc={iconOnboardingCompleted1}
        completedText='Looking good!'
        description='Add a personalized touch to your chat interface, you can customize it with your brand elements and launch icons.'
        href={`${Routes.SOLVE_CONFIGURATION}?tab=theme`}
        isCompleted={is_widget_onboarding_passed}
        subtitle='Visit Theme'
        title='1. Customize your Bot'
      />
      <OnboardingCard
        completedIconSrc={iconOnboardingCompleted2}
        completedText='Impressive!'
        description='Connect with knowledge bases for reliable information. Customize Knowledge Retrieval filters for a tailored experience.'
        href={`${Routes.SOLVE_CONFIGURATION}?tab=knowledge`}
        isCompleted={is_knowledge_onboarding_passed}
        subtitle='Visit Knowledge'
        title='2. Connect a Knowledge Base'
      />
      <OnboardingCard
        completedIconSrc={iconOnboardingCompleted3}
        completedText='Brilliant!'
        description='Provide handoff options to redirect your customers to additional resources or support channels for further assistance.'
        href={`${Routes.SOLVE_CONFIGURATION}?tab=handoff`}
        isCompleted={is_handoff_onboarding_passed}
        subtitle='Visit Handoff'
        title='3. Offer a Handoff Option'
      />
      <OnboardingCard
        completedIconSrc={iconOnboardingCompleted4}
        completedText='Magnificent!'
        description='Embed the Bot into your web pages or application and publish it to make it live!'
        href={`${Routes.SOLVE_CONFIGURATION}?tab=embed`}
        isCompleted={is_bot_activated}
        subtitle='Go Live'
        title='4. Embed and Publish your Bot'
      />
    </Box>
  );
}
