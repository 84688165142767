import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import {
  Badge,
  Button,
  Dialog,
  theme,
  Toggle,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TriageModelDetail } from '../../../../reducers/triageSettingsReducer/types';
import ErrorIcon from '../ErrorIcon';
import {
  getDisabledModelMessage,
  getIsModelPremium,
  premiumDisabledTooltipText,
  TOAST_TIMEOUT,
} from '../helpers';
import { updateStatusOfTriageModelByModelName } from 'src/actions/triageSettings/triageSettings';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  selectHasMinimalPermission,
  selectIntegrationSelectedState,
  selectIsHelpdeskNotConfigured,
  selectIsHelpdeskSupported,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';

interface TriageConfigDetailDescriptionProps {
  triageModel: TriageModelDetail;
}

const TriageConfigDetailDescription = ({
  triageModel,
}: TriageConfigDetailDescriptionProps) => {
  const {
    description,
    display_name,
    icon,
    model_paid_plan,
    name,
    sample_values,
    status,
  } = triageModel;
  const dispatch = useDispatch();
  const isHelpdeskSupported = useSelector(selectIsHelpdeskSupported);
  const integrationSelectedState = useSelector(selectIntegrationSelectedState);
  const isHelpdeskNotConfigured = useSelector(selectIsHelpdeskNotConfigured);
  const hasMinimalPermission = useSelector(selectHasMinimalPermission);

  const [isActivateModelDialogOpen, setIsActivateModelDialogOpen] =
    useState(false);

  const [isSampleValueModalOpen, setIsSampleValueModalOpen] = useState(false);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const isTriageModelActivated = status === 'enabled';
  const dialogueText = `The classifier will ${
    isTriageModelActivated ? 'no longer' : ''
  } output to the selected integration.`;
  const toggleLabel = isTriageModelActivated ? 'Active' : 'Inactive';

  const isIntegrationNotSelected = integrationSelectedState === 'not-selected';
  const isIntegrationFieldError = integrationSelectedState === 'error';
  // on update, should give the opposing status to know if the user is turning on or off
  const newUpdateStatus = status === 'enabled' ? 'disabled' : 'enabled';

  const handleUpdateModelStatus = () => {
    dispatch(
      updateStatusOfTriageModelByModelName({
        modelName: name,
        status: newUpdateStatus,
      }),
    );

    dispatch(
      setGlobalToastOptions({
        autoHideDuration: TOAST_TIMEOUT,
        title:
          status === 'enabled'
            ? 'Classifier deactivated'
            : 'Classifier activated',
        variant: 'main',
      }),
    );
  };

  const maxSampleValueLength = 5;
  const hasMoreThanFiveSampleValues =
    sample_values.length > maxSampleValueLength;

  const isPremium = getIsModelPremium(model_paid_plan);

  const renderToggleComponent = () => {
    if (
      isHelpdeskNotConfigured ||
      isIntegrationNotSelected ||
      isPremium ||
      !isHelpdeskSupported ||
      !hasMinimalPermission
    ) {
      return (
        <>
          <Tooltip
            tooltipContent={
              isPremium
                ? premiumDisabledTooltipText
                : getDisabledModelMessage(
                    isHelpdeskNotConfigured,
                    isHelpdeskSupported,
                  )
            }
          >
            <TooltipToggleContainer>
              <Toggle
                checked={isTriageModelActivated}
                disabled
                onChange={() => setIsActivateModelDialogOpen(true)}
              />
            </TooltipToggleContainer>
          </Tooltip>
          <Typography variant='font14'>{toggleLabel}</Typography>
        </>
      );
    }

    if (isIntegrationFieldError) {
      return (
        <>
          <Toggle
            checked={isTriageModelActivated}
            onChange={() => setIsActivateModelDialogOpen(true)}
          />
          <Tooltip tooltipContent='This classifier is not outputting to the selected integration field since it no longer exists. Create or select another output field to use this classifier.'>
            <TooltipToggleContainer>
              <ErrorIcon marginLeft={1.75} marginRight={0.75} />
              <Typography
                color={theme.palette.colors.red[500]}
                variant='font14'
              >
                Error
              </Typography>
            </TooltipToggleContainer>
          </Tooltip>
        </>
      );
    }

    return (
      <Toggle
        checked={isTriageModelActivated}
        label={toggleLabel}
        onChange={() => {
          emitTrackingEventCallback(
            'triage-clicked-toggled-model-status-button',
            { model_name: name, scope: 'self-serve', status },
          );

          setIsActivateModelDialogOpen(true);
        }}
      />
    );
  };

  return (
    <>
      <CardIconContainer>
        <Icon size={36} src={icon} />
        {isPremium && <Badge label='Premium' variant='macro' />}
      </CardIconContainer>
      <DescriptionContainer>
        <Typography color={theme.palette.colors.black} variant='font24'>
          {display_name}
        </Typography>
        <DescriptionTextContainer>
          <Typography color={theme.palette.colors.grey[700]} variant='font14'>
            {description}
          </Typography>
        </DescriptionTextContainer>
        {sample_values.length ? (
          <OutputContainer>
            <Typography variant='font14Bold'>Outputs</Typography>
            <BadgeLabelContainer>
              {sample_values.slice(0, maxSampleValueLength).map(sampleValue => {
                const value = sampleValue.toString();
                return (
                  <Tooltip key={value} tooltipContent={value}>
                    <PointerContainer>
                      <Badge
                        key={value}
                        label={value}
                        maxWidth={103}
                        noWrap
                        variant='macro'
                      />
                    </PointerContainer>
                  </Tooltip>
                );
              })}
              {hasMoreThanFiveSampleValues && (
                <PointerContainer
                  onClick={() => setIsSampleValueModalOpen(true)}
                >
                  <Typography
                    color={theme.palette.colors.grey[700]}
                    variant='font11'
                  >
                    {`+ ${sample_values.length - maxSampleValueLength} more`}
                  </Typography>
                </PointerContainer>
              )}
              <Dialog
                footer={
                  <Button
                    onClick={() => setIsSampleValueModalOpen(false)}
                    variant='secondary'
                  >
                    Close
                  </Button>
                }
                onClose={() => setIsSampleValueModalOpen(false)}
                open={isSampleValueModalOpen}
                title={`${display_name} outputs`}
              >
                <SampleValueDialogContainer>
                  {sample_values.map(sampleValue => {
                    const value = sampleValue.toString();
                    return <Badge key={value} label={value} variant='macro' />;
                  })}
                </SampleValueDialogContainer>
              </Dialog>
            </BadgeLabelContainer>
          </OutputContainer>
        ) : null}
        <OutputContainer>
          <Typography variant='font14Bold'>Status</Typography>
          <ToggleContainer id='triage-activation-toggle'>
            {renderToggleComponent()}
          </ToggleContainer>
        </OutputContainer>
      </DescriptionContainer>
      <Dialog
        onClose={() => setIsActivateModelDialogOpen(false)}
        open={isActivateModelDialogOpen}
        title={
          isTriageModelActivated
            ? 'Deactivate classifier?'
            : 'Activate classifier?'
        }
      >
        <DialogContainer>
          <Typography color={theme.palette.colors.grey[700]} variant='font14'>
            {dialogueText}
          </Typography>
          <DialogButtonContainer>
            <Button
              onClick={() => setIsActivateModelDialogOpen(false)}
              variant='ghost'
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                emitTrackingEventCallback(
                  'triage-clicked-confirm-change-model-status',
                  {
                    model_name: name,
                    scope: 'self-serve',
                    status: newUpdateStatus,
                  },
                );

                handleUpdateModelStatus();
                setIsActivateModelDialogOpen(false);
              }}
              variant='secondary'
            >
              {isTriageModelActivated ? ' Deactivate' : 'Activate'}
            </Button>
          </DialogButtonContainer>
        </DialogContainer>
      </Dialog>
    </>
  );
};

const CardIconContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 18px;
`;

const Icon = styled('img')<{
  size: number;
}>`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

const DescriptionContainer = styled('div')`
  margin-bottom: 25px;
`;

const DescriptionTextContainer = styled('div')`
  padding-top: 8px;
`;

const BadgeLabelContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 8px;
  gap: 8px;
`;

const ToggleContainer = styled('div')`
  margin-top: 8px;
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    display: inline-block;
  }
`;

const TooltipToggleContainer = styled('div')`
  margin-right: 12px;
  width: min-content;
  display: flex;
  align-items: center;
`;

const DialogContainer = styled('div')`
  width: 346px;
`;

const SampleValueDialogContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  width: 600px;
  padding-top: 8px;
`;

const DialogButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 16px;
  gap: 5px;
`;

const PointerContainer = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const OutputContainer = styled('div')`
  margin-top: 24px;
`;

export default TriageConfigDetailDescription;
