// Style
import './index.scss';

import React from 'react';
import { useSelector } from 'react-redux';

import Tooltip from 'src/components/reusable-components/tooltip/tooltip';
import { overviewTooltips } from 'src/pages/solve-analytics/overviewTooltips';
import {
  selectChannel,
  selectIsSolveWorkflows,
} from 'src/reducers/solveReducer';
import { formatNum, numberWithCommas } from 'src/utils/analyticsUtils';
import { cleanStr } from 'src/utils/cleanStr';

interface Props {
  /** cost per ticket value */
  costPerTicket: number | null;
  /** Name of the value being displayed */
  name: string;
  /** value that indicates to show contact support */
  showContactSupport: boolean;
  /** value to be displayed */
  value: number | boolean | null;
  /** Type of value. This could be int or percentage */
  valueType: {};
}

const OverviewCard: React.FC<React.PropsWithChildren<Props>> = ({
  costPerTicket,
  name,
  showContactSupport,
  value,
  valueType,
}: Props) => {
  //Selectors
  const selectedChannel = useSelector(selectChannel);
  const isSolveWorkflows = useSelector(selectIsSolveWorkflows);

  const cardVal = showContactSupport ? (
    <a className='OverviewCard-value-link' href='mailto:support@forethought.ai'>
      Contact support to setup
    </a>
  ) : (
    formatNum(name, value as number, valueType) || 0
  );

  const isCostInTooltip =
    overviewTooltips(selectedChannel, isSolveWorkflows)[name].name ===
      'Cost Savings' &&
    costPerTicket &&
    !showContactSupport;

  const tooltipText = isCostInTooltip
    ? overviewTooltips(selectedChannel, isSolveWorkflows)[
        name
      ]?.description.replace(
        'x',
        `x $${numberWithCommas(costPerTicket as number)} `,
      )
    : overviewTooltips(selectedChannel, isSolveWorkflows)[name].description;

  return (
    <div className='OverviewCard'>
      <div className='OverviewCard-nameContainer'>
        <span className='OverviewCard-name'>
          {name === 'csat' ? 'CSAT' : cleanStr(name)}
        </span>
        <Tooltip
          tooltipText={tooltipText}
          tooltipTitle={
            overviewTooltips(selectedChannel, isSolveWorkflows)[name]?.name
          }
        />
      </div>
      <p className='OverviewCard-value'>{cardVal}</p>
    </div>
  );
};

export default OverviewCard;
