import React, { useCallback } from 'react';
import { useField } from 'formik';

import { TextField } from '@forethought-technologies/forethought-elements';
import { useGetActionBuilderDetailFormIsActive } from 'src/pages/action-builder/hooks';

interface ActionBuilderFormTextFieldProps {
  disabled?: boolean;
  label?: string;
  multiline?: boolean;
  name: string;
  placeholder?: string;
  required?: boolean;
}

const ActionBuilderFormTextField = ({
  disabled,
  label,
  multiline,
  name,
  placeholder,
  required = false,
}: ActionBuilderFormTextFieldProps) => {
  const [{ value }, meta, { setValue }] = useField<string>({
    name,
  });
  const isActive = useGetActionBuilderDetailFormIsActive();

  const setFieldValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  return (
    <TextField
      aria-label={name}
      disabled={isActive || disabled}
      error={meta.error}
      label={label}
      multiline={multiline}
      name={name}
      onChange={setFieldValue}
      placeholder={placeholder}
      required={required}
      rows={multiline ? 5 : undefined}
      value={value}
    />
  );
};

export default ActionBuilderFormTextField;
