import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { CommandButton, FloatingWrapper, useActive } from '@remirror/react';
import { IconEdit, IconLink, IconUnlink } from '@tabler/icons-react';

import {
  Button,
  Checkbox,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { theme } from '@forethought-technologies/forethought-elements';
import { useFloatingLinkState } from './hooks';
import { LinkPreviewConfig } from './types';
import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';

interface LinkToolsProps extends ReturnType<typeof useFloatingLinkState> {
  buttonStyles: SxProps<Theme>;
  disabled?: boolean;
}

const LinkTools = ({
  buttonStyles,
  clickEdit,
  disabled = false,
  onRemove,
}: LinkToolsProps) => {
  const active = useActive();
  const activeLink = active.link();

  const linkEditButtons = activeLink ? (
    <>
      <CommandButton
        aria-label='Edit link'
        commandName='updateLink'
        disabled={disabled}
        enabled
        icon={<IconEdit size='18px' />}
        label='Edit link'
        onSelect={clickEdit}
        sx={buttonStyles}
      />
      <CommandButton
        aria-label='Remove link'
        commandName='removeLink'
        disabled={disabled}
        enabled
        icon={<IconUnlink size='18px' />}
        label='Remove link'
        onSelect={onRemove}
        sx={buttonStyles}
      />
    </>
  ) : (
    <CommandButton
      aria-label='Create link'
      commandName='updateLink'
      disabled={disabled}
      enabled
      icon={<IconLink size='18px' />}
      label='Create link'
      onSelect={clickEdit}
      sx={buttonStyles}
    />
  );

  return <>{linkEditButtons}</>;
};

interface LinkToolFormProps {
  cancelHref: () => void;
  enabled: boolean;
  errorMessage?: string;
  href: string;
  linkPreviewConfig?: LinkPreviewConfig;
  setHref: (href: string) => void;
  setShouldOpenInNewTab: React.Dispatch<React.SetStateAction<boolean>>;
  shouldOpenInNewTab: boolean;
  submitHref: () => void;
}

export const LinkToolForm = ({
  cancelHref,
  enabled,
  errorMessage,
  href,
  linkPreviewConfig,
  setHref,
  setShouldOpenInNewTab,
  shouldOpenInNewTab,
  submitHref,
}: LinkToolFormProps) => {
  return (
    <FloatingWrapper
      containerClass='floating-wrapper'
      enabled={enabled}
      placement='bottom'
      positioner='always'
      renderOutsideEditor={true}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.colors.white,
          border: `1px solid ${theme.palette.colors.slate[300]}`,
          borderRadius: '0.25rem',
          display: 'flex',
          margin: '4px',
          minWidth: '300px',
          padding: '12px',
        }}
      >
        {/* Can't use <form> here, because Rich Text Editor can be used inside
          a form, and nested forms are not allowed in HTML */}
        <Box
          onKeyDown={event => {
            const { key } = event;

            if (key === 'Escape') {
              cancelHref();

              return;
            }

            if (key === 'Enter') {
              // Prevent parent form submission:
              event.preventDefault();
            }
          }}
          width='100%'
        >
          <ContextMention
            errorMessage={errorMessage}
            label='Link'
            onChange={value => {
              setHref(value);
            }}
            placeholder='https://example.com'
            value={href}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '8px',
            }}
          >
            <Checkbox
              checked={shouldOpenInNewTab}
              label='Open in new tab'
              onChange={() => {
                setShouldOpenInNewTab(previous => !previous);
              }}
            />
            {linkPreviewConfig && (
              <Checkbox
                checked={linkPreviewConfig.showLinkPreview}
                label='Show link preview for this message'
                onChange={() => {
                  linkPreviewConfig.setShowLinkPreview(
                    !linkPreviewConfig.showLinkPreview,
                  );
                }}
              />
            )}
          </Box>
          <Box
            alignItems='center'
            display='flex'
            gap='6px'
            justifyContent='flex-end'
            py={1}
          >
            <Button onClick={cancelHref} type='button' variant='secondary'>
              <Typography variant='font14Bold'>Cancel</Typography>
            </Button>
            <Button
              disabled={Boolean(errorMessage)}
              onClick={submitHref}
              type='button'
              variant='main'
            >
              <Typography variant='font14Bold'>OK</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </FloatingWrapper>
  );
};

export default LinkTools;
