import React from 'react';
import { Box, useTheme } from '@mui/material';
import { IconThumbUp } from '@tabler/icons-react';

import {
  IconButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { Product } from 'src/services/apiInterfaces';
import { useCreateIntentPredictionFeedbackMutation } from 'src/services/intent-tests';
import { Sentiment } from 'src/services/intent-tests/types';

export default function FeedbackButtonGroup({
  activeSentiment,
  intentDefinitionId,
  intentTestId,
  predictionRecordId,
  product,
}: {
  activeSentiment: Sentiment | null;
  intentDefinitionId: string | null;
  intentTestId: string;
  predictionRecordId: string;
  product: Product;
}) {
  const { palette } = useTheme();
  const [createFeedback] = useCreateIntentPredictionFeedbackMutation();

  const handleClick =
    (sentiment: Sentiment) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      createFeedback({
        correct_intent_id: null,
        intent_definition_id: intentDefinitionId,
        intent_prediction_record_id: predictionRecordId,
        intent_test_id: intentTestId,
        notes: null,
        product,
        sentiment,
      });
    };

  return (
    <Box display='flex' gap='4px' justifyContent='center'>
      <Tooltip tooltipContent='Correct'>
        <IconButton
          aria-label='Like'
          onClick={handleClick('positive')}
          variant='ghost'
        >
          <IconThumbUp
            color={
              activeSentiment === 'positive'
                ? palette.colors.green[500]
                : palette.colors.grey[800]
            }
            fill={
              activeSentiment === 'positive'
                ? palette.colors.green[100]
                : 'none'
            }
          />
        </IconButton>
      </Tooltip>
      <Tooltip tooltipContent='Incorrect'>
        <IconButton
          aria-label='Dislike'
          onClick={handleClick('negative')}
          variant='ghost'
        >
          <IconThumbUp
            color={
              activeSentiment === 'negative'
                ? palette.colors.red[500]
                : palette.colors.grey[800]
            }
            fill={
              activeSentiment === 'negative' ? palette.colors.red[100] : 'none'
            }
            style={{ transform: 'rotate(180deg)' }}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
