import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Checkbox,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { refreshWidgetApiKey } from 'src/actions/workflow-builder/workflowBuilderActions';
import checkmarkIcon from 'src/assets/images/checkmark.svg';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  selectIsSolveLiteEnabled,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import { selectWidgetApiKey } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetWorkflowTagsQuery } from 'src/services/workflow-builder-metrics';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  getContextEmbedScriptParams,
  getWorkflowContextVariables,
} from 'src/utils/actionBuilder/helpers';
import { ContextVariableTypeKeys, UserRoles } from 'src/utils/enums';

const escape = (workflowParam: string) => workflowParam.replaceAll(' ', '-');

const WORKFLOW_TAG_CV = 'Workflow Tag';

const CodeSnippetSection = () => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const widgetApiKey = useSelector(selectWidgetApiKey);
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const { data: workflowTags = [] } = useGetWorkflowTagsQuery();
  const { contextVariables } = useGetContextVariables();
  const workflowCvs = getWorkflowContextVariables(contextVariables);
  const workflowParams = getContextEmbedScriptParams(contextVariables);
  const userRole = useSelector(selectUserRole);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  // States
  const [showApiKey, setShowApiKey] = useState(false);
  const [justCopied, setJustCopied] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const currentWhiteListedDomains = widgetConfiguration.whitelisted_domains;
  const listTypeCVs = workflowCvs.filter(
    cv => cv.context_variable_type === ContextVariableTypeKeys.LIST,
  );
  const productName = isSolveLiteEnabled ? 'Solve Lite' : 'Solve';

  const getEmbedScriptRawText = (widgetApiKey: string) => {
    const defaultLines = [
      '<script',
      'src="https://solve-widget.forethought.ai/embed.js"',
      'id="forethought-widget-embed-script"',
      `data-api-key="${widgetApiKey}"`,
    ];
    let additionalLines: string[] = [];
    if (!isSolveLiteEnabled) {
      additionalLines = workflowParams.map(workflowParam => {
        let post = '"ENTER-VALUE-HERE"';
        const cvIfListType = listTypeCVs.filter(
          cv => cv.context_variable_name === workflowParam,
        );
        if (!!cvIfListType.length) {
          post = `"AVAILABLE OPTIONS: ${cvIfListType[0].list_type_options
            ?.map(option => option.label)
            .join(', ')}"`;
        }
        return `data-ft-${escape(workflowParam)}=${post}`;
      });

      additionalLines.push(
        workflowTags.length > 0
          ? `data-ft-workflow-tag="AVAILABLE TAGS: ${workflowTags.join(', ')}"`
          : '',
      );
    }
    const embeddedLines = defaultLines.concat(additionalLines);
    embeddedLines.push('></script>');

    return embeddedLines.filter(Boolean).join('\n');
  };

  const confirmModal = (
    <Dialog
      footer={
        <>
          <Button
            onClick={() => {
              setShowConfirmModal(false);
            }}
            variant='ghost'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(refreshWidgetApiKey());
              setShowConfirmModal(false);
            }}
            variant='secondary'
          >
            OK
          </Button>
        </>
      }
      hideCloseButton
      onClose={() => setShowConfirmModal(false)}
      open={showConfirmModal}
      title='Are you sure?'
    >
      <Typography variant='font14Medium'>
        Refreshing your API key will disable your current {productName}{' '}
        deployment.
      </Typography>
    </Dialog>
  );

  if (currentWhiteListedDomains.length) {
    return (
      <Box>
        {confirmModal}
        <Box paddingTop={3}>
          <Box
            alignItems='center'
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
          >
            <Typography variant='font16Bold'>2. Code Snippet</Typography>
            {justCopied ? (
              <Button
                disabled={true}
                onClick={undefined}
                startIcon={<img alt='' src={checkmarkIcon} />}
                variant='secondary'
              >
                Copied
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setJustCopied(true);
                  navigator.clipboard.writeText(
                    getEmbedScriptRawText(widgetApiKey),
                  );
                  setTimeout(() => {
                    setJustCopied(false);
                  }, 1500);
                }}
                variant='secondary'
              >
                Copy code
              </Button>
            )}
          </Box>
          <Box
            alignItems='center'
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            paddingTop='21px'
          >
            <Checkbox
              checked={showApiKey}
              label='Show API key in code snippet'
              onChange={() => {
                setShowApiKey(prev => !prev);
              }}
            />
            {[UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN].includes(
              userRole,
            ) && (
              <Button onClick={() => setShowConfirmModal(true)} variant='ghost'>
                Refresh API key
              </Button>
            )}
          </Box>
          <Box paddingTop='18px'>
            <Typography color={palette.colors.grey[500]} variant='font14Medium'>
              Auto-generated code snippet
            </Typography>
            <Box paddingTop='15px' />
            <Box
              bgcolor={palette.colors.slate[100]}
              display='flex'
              flexDirection='column'
              padding='16px'
            >
              <Typography variant='font14'>{'<script'}</Typography>
              <Box paddingLeft='16px'>
                <Typography variant='font14'>
                  {'src="https://solve-widget.forethought.ai/embed.js"'}
                </Typography>
                <Box />
                <Typography variant='font14'>
                  {'id="forethought-widget-embed-script"'}
                </Typography>
                <Box display='flex'>
                  <Typography variant='font14Bold'>
                    {'data-api-key='}
                  </Typography>
                  {showApiKey ? (
                    <Typography variant='font14'>{`"${widgetApiKey}"`}</Typography>
                  ) : (
                    <Typography
                      color={palette.colors.purple[500]}
                      variant='font14Medium'
                    >
                      “ENABLE TO REVEAL HERE”
                    </Typography>
                  )}
                </Box>
                {workflowParams.map(workflowParam => {
                  // workflow tag CV is used for passing workflow tag as CV, and shouldn't be be
                  // defined by the user via the embed script
                  if (workflowParam === WORKFLOW_TAG_CV) {
                    return null;
                  }
                  const cvIfListType = listTypeCVs.filter(
                    cv => cv.context_variable_name === workflowParam,
                  );
                  return (
                    <Box key={workflowParam}>
                      <Typography variant='font14Bold'>{`data-ft-${escape(
                        workflowParam,
                      )}=`}</Typography>
                      <Typography
                        color={palette.colors.grey[500]}
                        variant='font14Medium'
                      >
                        {!!cvIfListType.length
                          ? `AVAILABLE OPTIONS: ${cvIfListType[0].list_type_options
                              ?.map(option => option.label)
                              .join(', ')}`
                          : '"ENTER-VALUE-HERE"'}
                      </Typography>
                    </Box>
                  );
                })}
                {workflowTags.length ? (
                  <Box>
                    <Typography variant='font14Bold'>
                      data-ft-workflow-tag=
                    </Typography>
                    <Typography
                      color={palette.colors.grey[500]}
                      variant='font14Medium'
                    >
                      {`"AVAILABLE TAGS: ${workflowTags.join(', ')}"`}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              <Typography variant='font14'>{'></script>'}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};

export default CodeSnippetSection;
