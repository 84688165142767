import React, { InputHTMLAttributes, Ref } from 'react';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import inputCheckmark from '../../../assets/images/input-checkmark.svg';
import { textMediumStyle, textRegularStyle } from 'src/styles/styledMixin';

/* For more info about accepted props: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement */
export interface StyledInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  /** Should show checkmark */
  hasCheckmark?: boolean;
  /** Should input set error state */
  isValueInvalid?: boolean;
  /** Label Text */
  labelText?: string;
  /** Override margin style */
  margin?: string;
  /** Override width style */
  width?: string | number;
}
export const StyledInput = React.forwardRef<HTMLInputElement, StyledInputProps>(
  (componentProps: StyledInputProps, ref: Ref<HTMLInputElement>) => {
    const {
      hasCheckmark = false,
      isValueInvalid = false,
      labelText,
      ...otherProps
    } = componentProps;
    return (
      <>
        {labelText && <Label>{labelText}</Label>}
        <Input
          data-testid='StyledInput'
          isValueInvalid={isValueInvalid}
          ref={ref}
          {...otherProps}
        />
        {hasCheckmark && (
          <StyledImg height='16px' src={inputCheckmark} width='16px' />
        )}
      </>
    );
  },
);
StyledInput.displayName = 'StyledInput';

const Input = styled('input')<{
  isValueInvalid?: boolean;
  margin?: string | number;
  width?: string | number;
}>`
  ${textRegularStyle};
  color: ${Colors.ui.text.primary};
  font-size: 15px;
  width: ${props => (props.width ? props.width : '190px')};
  height: 40px;

  // min-height is required to fix weird flexbox behavior (display: flexbox on
  // the parent element makes the input box height be way too small):
  min-height: 40px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${Colors.ui.backgrounds.default};
  border-radius: var(--border-radius-button);
  border: ${props =>
    props.isValueInvalid
      ? `1px solid ${Colors.ui.semantic.destructive.default}`
      : `1px solid ${theme.palette.colors.grey[300]}`};
  margin: ${props => (props.margin ? props.margin : '10px')};
  padding: 0 10px;
  cursor: text;

  :disabled {
    cursor: default;
    color: ${theme.palette.colors.grey[300]};
  }
`;

const StyledImg = styled('img')`
  position: relative;
  left: -40px;
  margin-right: -16px;
`;

const Label = styled('label')`
  ${textMediumStyle({ fontSize: '16px' })};
  text-align: left;
  display: block;
  margin: 0 10px;
`;
