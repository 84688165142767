import './index.scss';

import React from 'react';
import classNames from 'classnames';

interface OverlayProps {
  /** Heading string*/
  displayOverlay: boolean;
  /** Should overlay be darker */
  isDarkest?: boolean;
}

const Overlay: React.FC<React.PropsWithChildren<OverlayProps>> = ({
  displayOverlay,
  isDarkest,
}: OverlayProps) => {
  const overlayStyle = classNames({
    none: !displayOverlay,
    Overlay: displayOverlay,
    'Overlay--darker': isDarkest,
  });
  return <div className={overlayStyle} />;
};

export default Overlay;
