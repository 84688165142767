import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';

import {
  Button,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import GreenCheck from 'src/assets/images/green-check.svg';

interface UpgradePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const UpgradePlanModal = ({ isOpen, onClose }: UpgradePlanModalProps) => {
  return (
    <Dialog fullWidth maxWidth='xs' onClose={onClose} open={isOpen}>
      <DialogContent
        sx={{
          paddingX: 4,
          paddingY: 5,
        }}
      >
        <DialogContentTop>
          <GreenCircle>
            <img src={GreenCheck} />
          </GreenCircle>
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContentTop>
        <DialogContentBottom>
          <Typography variant='font18Bold'>
            Our team will be in touch soon
          </Typography>
          <Box sx={{ mb: 1.5 }} />
          <Typography color={theme.palette.colors.grey[700]} variant='font14'>
            A team member will reach out to help upgrade your plan
          </Typography>
          <Box sx={{ mb: 3.75 }} />
          <Button onClick={onClose} variant='ghost'>
            Close
          </Button>
        </DialogContentBottom>
      </DialogContent>
    </Dialog>
  );
};

const DialogContentTop = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DialogContentBottom = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const GreenCircle = styled('div')`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${theme.palette.colors.green[100]};
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default UpgradePlanModal;
