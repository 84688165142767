import CardValue from '../../common/banner-layout/CardValue';
import costReductionIcon from 'src/assets/images/discover-cost-reduction-icon.svg';
import timeReductionIcon from 'src/assets/images/discover-time-reduction-icon.svg';
import volumeReductionIcon from 'src/assets/images/discover-volume-reduction-icon.svg';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import { useGetMetadata } from 'src/hooks/discover/useGetMetadata';
import { DiscoverTopicActionableValue } from 'src/reducers/discoverReducer/types';
import {
  getCostReductionCopy,
  getDiscoverBannerValues,
} from 'src/utils/discover/helpers';

interface DiscoverPostAutomationBannerProps {
  automationValue?: DiscoverTopicActionableValue | null;
  hasScrolled: boolean;
  isLoading: boolean;
}

const DiscoverPostAutomationBanner = ({
  automationValue,
  hasScrolled,
  isLoading,
}: DiscoverPostAutomationBannerProps) => {
  const { costValue, fullResolutionTimeValue, volumeValue } =
    getDiscoverBannerValues(automationValue);

  const { data } = useGetMetadata();
  const {
    description: costReductionDescription,
    tooltip: costReductionTooltip,
  } = getCostReductionCopy(data?.ticket_cost_info);

  const viewMode = hasScrolled ? 'minimized' : 'full';

  if (!automationValue && !isLoading) {
    return null;
  }

  return (
    <CardValue
      costReduction={{
        description: costReductionDescription,
        icon: (
          <img
            src={costReductionIcon}
            width={viewMode === 'minimized' ? 20 : 32}
          />
        ),
        tooltip: costReductionTooltip,
        value: costValue,
      }}
      loading={isLoading}
      resolutionTimeReduction={{
        description: DISCOVER_CARD_TEXT.resolutionTimeDescription,
        icon: (
          <img
            src={timeReductionIcon}
            width={viewMode === 'minimized' ? 20 : 32}
          />
        ),
        value: fullResolutionTimeValue,
      }}
      title={DISCOVER_CARD_TEXT.postAutomationPageBannerTitle}
      viewMode={viewMode}
      viewType={'default'}
      volumeReduction={{
        description: DISCOVER_CARD_TEXT.volumeDescription,
        icon: (
          <img
            src={volumeReductionIcon}
            width={viewMode === 'minimized' ? 20 : 32}
          />
        ),
        value: volumeValue + (hasScrolled ? ' tickets' : ''),
      }}
    />
  );
};

export default DiscoverPostAutomationBanner;
