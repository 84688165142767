export enum WorkflowBuilderActions {
  CLEAR_SUGGESTED_INTENTS = 'clear/suggestedIntents',
  ClEAR_WORKFLOW_ERROR = 'clear/workflowError',
  CREATE_INTENT_WORKFLOW = 'createIntentWorkflow',
  DUPLICATE_INTENT_WORKFLOW = 'duplicateIntentWorkflow',
  GET_CONFIGURATION_TRANSLATION_FOR_ORG_SUCCESS = 'get/stepConfigurationForOrgSuccess',
  GET_IS_MODEL_TRAINING = 'get/isModelTraining',
  GET_LANDING_DATA_SUCCESS = 'get/landingDataSuccess',
  GET_LANGUAGES_ENABLED_SUCCESS = 'get/languagesEnabledSuccess',
  GET_LANGUAGES_SUCCESS = 'get/languagesSuccess',
  GET_RESTORE_TRANSLATION_SUCCESS = 'get/restoreTranslationSuccess',
  GET_STEP_TRANSLATION_FOR_ORG_SUCCESS = 'get/stepTranslationForOrgSuccess',
  GET_STEP_TRANSLATIONS_FOR_STEP_SUCCESS = 'get/stepTranslationsForStepSuccess',
  GET_SUGGESTED_INTENTS_SUCCESS = 'get/suggestedIntentsSuccess',
  GET_USABLE_CONTEXT_VARIABLES_FOR_TRANSLATION_SUCCESS = 'get/usableContextVariablesForTranslationSuccess',
  GET_WIDGET_API_KEY_SUCCESS = 'get/widgetApiKeySuccess',
  GET_WIDGET_CONFIG_SUCCESS = 'get/widgetConfigSuccess',
  SET_CHANNEL = 'set/channel',
  SET_CURRENT_VIEW = 'set/currentView',
  SET_DEACTIVATE_WORKFLOW_ERROR = 'set/deactivateWorkflowError',
  SET_DELETE_WORKFLOW_ERROR = 'set/deleteWorkflowError',
  SET_DRAFT_TRANSLATIONS = 'set/draftTranslations',
  SET_INTENT_WORKFLOW_ID = 'set/intentWorkflowId',
  SET_IS_CONFIG_TRANSLATIONS_LOADING = 'set/isConfigTranslationsLoading',
  SET_IS_ERROR_BANNER_VISIBLE = 'set/isErrorBannerVisible',
  SET_IS_INFO_BANNER_VISIBLE = 'set/isInfoBannerVisible',
  SET_IS_LOADING = 'set/isLoading',
  SET_IS_MODEL_TRAINING = 'set/isModelTraining',
  SET_IS_OVERLAY_VISIBLE = 'set/isOverlayVisible',
  SET_IS_PREVIEW_LOADED = 'set/isPreviewLoaded',
  SET_IS_STEP_TRANSLATIONS_LOADING = 'set/isStepTranslationsLoading',
  SET_NETWORK_ERROR = 'set/networkError',
  SET_STEP_LEVEL_TRANSLATIONS_ID = 'set/stepLevelTranslationsId',
  SET_TRANSLATIONS_TABLE_MODE = 'set/translationsTableMode',
}
