import Box from '@mui/material/Box';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface TicketStatusItemProps {
  color: string;
  isLoading: boolean;
  title: string;
  value: string;
}

const TicketStatusItem = ({
  color,
  isLoading,
  title,
  value,
}: TicketStatusItemProps) => {
  return (
    <Box display='flex' flexDirection='column' rowGap={0.5}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Typography color={color} variant='font24'>
          {value}
        </Typography>
      )}
      <Typography variant='font14'>{title}</Typography>
    </Box>
  );
};

export default TicketStatusItem;
