import { useCallback, useState } from 'react';

import { useGetHelpdesk } from '../dashboard-pages/triage-config-detail-page/hooks/useGetHelpdesk';
import { determineOutputFieldValueOptions } from './helpers';
import { getFieldPredictorOutputField } from 'src/reducers/triageSettingsReducer/helpers';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useGetTicketFieldsQuery } from 'src/services/triage/triageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export const useModelValidityToast = (
  model: VersionedTriageModel | undefined,
  callback: (valid: boolean, publishState?: boolean) => void,
) => {
  // hooks
  const dispatch = useAppDispatch();
  const helpdesk = useGetHelpdesk();
  const [valid, setValid] = useState(true);
  const { data: ticketFields } = useGetTicketFieldsQuery(helpdesk?.name ?? '', {
    skip: !helpdesk,
  });

  const showValidityToast = useCallback(
    (isValid: boolean, publishState?: boolean) => {
      setValid(isValid);
      callback(isValid, publishState);

      if (isValid) {
        dispatch(setGlobalToastOptions(null));

        return;
      }
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: undefined,
          title: 'Accurate label mapping is required to publish this model.',
          variant: 'warning',
        }),
      );
    },
    [dispatch, callback],
  );

  const checkValidity = useCallback(
    (publishState?: boolean) => {
      const outputValue =
        helpdesk?.name && model?.model
          ? getFieldPredictorOutputField(helpdesk.name, model.model) || {}
          : undefined;
      if (!model || !outputValue || !ticketFields) {
        showValidityToast(true, publishState);
        return;
      }

      const chosenOutputField = Object.keys(outputValue)[0];
      const isOutputFieldChosen = !!chosenOutputField;
      if (!isOutputFieldChosen) {
        showValidityToast(true, publishState);
        return;
      }
      const chosenValueMapping =
        model.model.model_output_formatter.value_mapping;
      const outputFieldValueOptions = determineOutputFieldValueOptions(
        chosenOutputField,
        ticketFields,
      );
      // We don't care if the output field is not a picklist
      const isOutputFieldPicklist =
        outputFieldValueOptions && outputFieldValueOptions?.length > 0;
      if (!isOutputFieldPicklist) {
        showValidityToast(true, publishState);
        return;
      }
      // If the output is a picklist, we need a value mapping
      const isValueMappingEmpty =
        !chosenValueMapping || Object.keys(chosenValueMapping).length === 0;
      if (isValueMappingEmpty) {
        showValidityToast(false, publishState);
        return;
      }

      // Gather the values of the mapping and the values of the picklist
      // If the values of the mapping are not in the picklist, the mapping isn't valid
      const mappingValues = Object.values(chosenValueMapping);
      const picklistValues = outputFieldValueOptions.map(
        option => option.value,
      );
      const areAllMappingValuesValid = mappingValues.every(value =>
        picklistValues.includes(value as string),
      );
      if (areAllMappingValuesValid) {
        showValidityToast(true, publishState);
        return;
      }
      showValidityToast(false, publishState);
      return;
    },
    [model, ticketFields, showValidityToast, helpdesk],
  );

  return { checkValidity, valid };
};
