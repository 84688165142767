import React from 'react';
import { useDrag } from 'react-dnd';
import { Box } from '@mui/system';

import { ComponentMetadata, EmailBuilderConfigurationKey } from '../types';

const IDLE_OPACITY = 1;
const ACTIVE_OPACITY = 0.5;

interface DraggableComponentProps {
  /**
   * Children components
   */
  children: React.ReactNode;
  /**
   * For some components, we need to pass extra fields from drag source to drop target
   */
  componentMetadata?: ComponentMetadata;
  /**
   * Type of component
   */
  componentType: EmailBuilderConfigurationKey;
}

export default function DraggableComponent({
  children,
  componentMetadata,
  componentType,
}: DraggableComponentProps) {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      collect: monitor => ({
        opacity: monitor.isDragging() ? ACTIVE_OPACITY : IDLE_OPACITY,
      }),
      item: {
        componentMetadata,
        componentType,
      },
      type: componentType,
    }),
    [],
  );

  return (
    <Box
      ref={dragRef}
      sx={{
        opacity,
        // trick to keep border radius on drag: https://github.com/react-dnd/react-dnd/issues/788#issuecomment-367300464
        transform: 'translate(0, 0)',
      }}
    >
      {children}
    </Box>
  );
}
