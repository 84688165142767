import { BreakdownsState, FetchAction } from './types';
import {
  APPLY_BREAKDOWN_FIELD,
  CLEAR_SELECTED_BREAKDOWN,
  DELETE_BREAKDOWN_FIELD,
  GET_ANSWERS_BREAKDOWN_VALUES_SUCESS,
  GET_BREAKDOWN_DATA_FAILURE,
  GET_BREAKDOWN_FIELDS_SUCCESS,
  TOGGLE_BREAKDOWN_FIELD,
  TOGGLE_BREAKDOWN_VALUE,
  TOGGLE_SELECT_ALL_BREAKDOWNS,
} from 'src/actions/actionTypes';
import { PageSettingsActions } from 'src/actions/actionTypes';
import type { RootState } from 'src/store/rootReducer';

const getBreakdownFieldsInitialState = () => {
  return {
    agent: [],
    ticket: [],
  };
};

export const initialState: BreakdownsState = {
  appliedBreakdown: null,
  breakdownFields: getBreakdownFieldsInitialState(),
  breakdownValues: [],
  error: '',
  isLoading: false,
  selectedBreakdownField: null,
  selectedBreakdownValues: [],
};

export default function breakdownsReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === PageSettingsActions.SET_BREAKDOWN_LOADING) {
    return { ...state, isLoading: action.payload };
  }

  if (action.type === GET_ANSWERS_BREAKDOWN_VALUES_SUCESS) {
    return { ...state, breakdownValues: action.payload };
  }

  if (action.type === TOGGLE_BREAKDOWN_VALUE) {
    const newValue = action.payload;

    let selectedBreakdownValues = [...state.selectedBreakdownValues];

    if (state.selectedBreakdownValues.includes(newValue)) {
      selectedBreakdownValues = selectedBreakdownValues.filter(
        e => e !== newValue,
      );
    } else if (
      !state.selectedBreakdownValues.includes(newValue) &&
      selectedBreakdownValues.length < 15
    ) {
      selectedBreakdownValues.push(newValue);
    }

    return { ...state, selectedBreakdownValues };
  }

  if (action.type === TOGGLE_SELECT_ALL_BREAKDOWNS) {
    let selectedBreakdownValues = [...state.selectedBreakdownValues];
    const breakdownValues = [...state.breakdownValues];
    const firstFifteenItems: string[] = breakdownValues.slice(0, 15);

    if (
      selectedBreakdownValues.length === 15 ||
      selectedBreakdownValues.length === breakdownValues.length
    ) {
      selectedBreakdownValues = [];
    } else {
      selectedBreakdownValues = firstFifteenItems;
    }
    return { ...state, selectedBreakdownValues };
  }

  if (action.type === TOGGLE_BREAKDOWN_FIELD) {
    if (
      state.selectedBreakdownField?.field_id === action.payload.field_id &&
      state.selectedBreakdownField?.field_category ===
        action.payload.field_category
    ) {
      return { ...state, selectedBreakdownField: null };
    }

    return {
      ...state,
      selectedBreakdownField: {
        field_category: action.payload.field_category,
        field_id: action.payload.field_id,
        field_name: action.payload.field_name,
        field_type: action.payload.field_type,
      },
    };
  }

  if (action.type === CLEAR_SELECTED_BREAKDOWN) {
    return { ...state, selectedBreakdownField: null };
  }

  if (action.type === APPLY_BREAKDOWN_FIELD) {
    return {
      ...state,
      appliedBreakdown: {
        field_category: action.payload.field_category,
        field_id: action.payload.field_id,
        field_name: action.payload.field_name,
        field_type: action.payload.field_type,
      },
    };
  }

  if (action.type === DELETE_BREAKDOWN_FIELD) {
    return {
      ...state,
      appliedBreakdown: null,
      selectedBreakdownField: null,
      selectedBreakdownValues: [],
    };
  }

  if (action.type === GET_BREAKDOWN_DATA_FAILURE) {
    return { ...state, error: action.payload?.toString() || 'Error' };
  }

  if (action.type === GET_BREAKDOWN_FIELDS_SUCCESS) {
    const breakdownFields = action.payload || getBreakdownFieldsInitialState();

    return {
      ...state,
      breakdownFields,
    };
  }

  return state;
}

// SELECTORS

export const selectIsLoading = (
  state: RootState,
): BreakdownsState['isLoading'] => state.breakdowns.isLoading;

export const getBreakdownFields = (
  state: RootState,
): BreakdownsState['breakdownFields'] => state.breakdowns.breakdownFields;

export const getSelectedBreakdownField = (
  state: RootState,
): BreakdownsState['selectedBreakdownField'] =>
  state.breakdowns.selectedBreakdownField;

export const getAppliedBreakdownField = (
  state: RootState,
): BreakdownsState['appliedBreakdown'] => state.breakdowns.appliedBreakdown;

export const selectBreakdownValues = (state: RootState): string[] => {
  return state.breakdowns.breakdownValues;
};

export const selectSelectedBreakdownValues = (state: RootState): string[] => {
  return state.breakdowns.selectedBreakdownValues;
};
