import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { textRegularStyle } from 'src/styles/styledMixin';

interface RadioButtonProps {
  checked: boolean;
  label?: string;
  onChange: () => void;
}

const RadioButton = ({ checked, label, onChange }: RadioButtonProps) => {
  return (
    <Label>
      <Button checked={checked} onChange={onChange} type='radio' />
      {label}
    </Label>
  );
};

export default RadioButton;

const Label = styled('label')`
  ${textRegularStyle({
    color: theme.palette.colors.grey[900],
    fontSize: '16px',
  })}
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  line-height: 1.1;
`;

const Button = styled('input')`
  appearance: none;
  background-color: ${theme.palette.colors.white};
  margin: 0;
  font: inherit;
  color: ${theme.palette.colors.purple[500]};
  width: 20px;
  height: 20px;
  border: 1px solid ${theme.palette.colors.grey[300]};
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 20px 20px ${theme.palette.colors.purple[500]};
  }

  &:checked::before {
    transform: scale(1);
  }

  &:hover {
    cursor: pointer;
  }
`;
