import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import {
  Button,
  IconButton,
  SelectDropdown,
} from '@forethought-technologies/forethought-elements';
import addPurpleIcon from '../../../../assets/images/add-purple-icon.svg';
import disabledTrashIcon from '../../../../assets/images/disabled-trash-can.svg';
import trashIcon from '../../../../assets/images/trash-icon.svg';
import { selectActionSettings } from '../../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import { addArticleField } from './addArticleField';
import FilterQuery from './FilterQuery';
import { AddIcon } from './index';
import { removeArticleSource } from './removeArticleSource';
import { updateDocType } from './updateDoctype';
import {
  ArticleField,
  type ArticleSource,
  ArticleSuggestionActionSettings,
  ArticleSuggestionFilterQuery,
} from 'src/types/workflowBuilderAPITypes';

interface ArticleSourceProps {
  articleSourceIndex: number;
  articleSources: ArticleSource[];
  docType: string | null;
  filterQueries: Array<ArticleSuggestionFilterQuery>;
  isRemoveActionEnabled: boolean;
  setArticleSources: React.Dispatch<React.SetStateAction<ArticleSource[]>>;
}

const ArticleSource: React.FC<React.PropsWithChildren<ArticleSourceProps>> = ({
  articleSourceIndex,
  articleSources,
  docType,
  filterQueries,
  isRemoveActionEnabled,
  setArticleSources,
}) => {
  const settings = useSelector(selectActionSettings);
  if (!settings.configuration_fields) {
    return null;
  }

  const configFields =
    settings.configuration_fields as ArticleSuggestionActionSettings;

  const { available_article_sources } = configFields;

  const docTypeData = available_article_sources.find(
    articleSource => articleSource.doc_type === docType,
  );

  const availableArticleFields: Array<ArticleField> =
    docTypeData?.available_article_fields || [];

  const articleSourceOptions = available_article_sources.map(articleSource => ({
    label: articleSource.doc_type,
    value: articleSource.doc_type,
  }));

  const filterFieldOptions = availableArticleFields?.map(
    field => field.field_name,
  );

  const getFilterValueOptionsByField = (selectedFilterField: string) => {
    return (
      availableArticleFields
        .find(field => selectedFilterField === field.field_name)
        ?.available_field_values.map(value => ({
          label: value.name,
          value: value.value.toString(),
        })) || []
    );
  };

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box display='flex' gap={1}>
        <SelectDropdown
          id='source-dropdown'
          onChange={e =>
            setArticleSources(
              updateDocType({
                articleSourceIndex,
                articleSources,
                value: e.target.value,
              }),
            )
          }
          options={articleSourceOptions}
          placeholder={'Select an article source...'}
          value={docType ?? ''}
        />

        <IconButton
          aria-label='Remove article source'
          disabled={!isRemoveActionEnabled}
          onClick={() => {
            if (isRemoveActionEnabled) {
              setArticleSources(
                removeArticleSource({ articleSourceIndex, articleSources }),
              );
            }
          }}
          variant='ghost'
        >
          <img
            alt=''
            src={isRemoveActionEnabled ? trashIcon : disabledTrashIcon}
          />
        </IconButton>
      </Box>
      {filterQueries.map((filterQuery, index) => {
        const operator = filterQuery.operator;
        const formattedOperatorLabel =
          operator.charAt(0).toUpperCase() + operator.slice(1);

        const articleFieldValue =
          (filterQuery.field && {
            label: filterQuery.field,
            value: filterQuery.field,
          }) ||
          null;

        const filterValue =
          (filterQuery.value && {
            label: filterQuery.value.name,
            value: filterQuery.value.value?.toString(),
          }) ||
          null;
        const filterValueOptions = getFilterValueOptionsByField(
          filterQuery.field || '',
        );

        return (
          <FilterQuery
            articleFieldValue={articleFieldValue}
            articleSourceIndex={articleSourceIndex}
            articleSources={articleSources}
            booleanOperatorValue={{
              label: filterQuery.boolean_operator?.toUpperCase() || '',
              value: filterQuery.boolean_operator || '',
            }}
            filterFieldOptions={
              filterFieldOptions?.map(field => ({
                label: field,
                value: field,
              })) || []
            }
            filterQueriesLength={filterQueries.length}
            filterQueryIndex={index}
            filterValue={filterValue}
            filterValueOptions={filterValueOptions}
            key={index}
            operator={{
              label: formattedOperatorLabel,
              value: filterQuery.operator,
            }}
            setArticleSources={setArticleSources}
            shouldDisplayBooleanOperator={index > 0}
            shouldDisplayTextInput={filterValueOptions.length === 0}
          />
        );
      })}
      <Box>
        <Button
          onClick={() =>
            setArticleSources(
              addArticleField(articleSources, articleSourceIndex),
            )
          }
          variant='ghost'
        >
          <AddIcon src={addPurpleIcon} />
          Add Filter Field
        </Button>
      </Box>
    </Box>
  );
};

export default ArticleSource;
