import React, { FC } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import { CanvasModes } from '../constants';
import DraggableArticleCard from '../workflow-builder/business-logic/DraggableArticleCard';
import ArticleOptionsDialog from './ArticleOptionsDialog';
import { articleLogos } from './constants';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  selectCanvasWorkflowBuilder,
  selectChatOrder,
  selectChatOrderLastStepId,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectIsSqueezingStep,
  selectreadArticleActionDialogId,
  setIsSqueezingStep,
  setMode,
  setReadArticleActionDialogId,
  setShouldSqueezeIntoEntry,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { addArticleStep } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { Article as ArticleType } from 'src/types/workflowBuilderAPITypes';
import { RevampedDragNDrops, StepTypes } from 'src/utils/enums';
import { isDropValid } from 'src/utils/solve/stepUtils';

export type ArticleVariant = 'wide';

interface ArticleProps {
  article: ArticleType;
  hasWorkflowConflict: boolean;
  isDisabled: boolean;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
}

const Article: FC<React.PropsWithChildren<ArticleProps>> = ({
  article,
  hasWorkflowConflict,
  isDisabled,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const { contextVariables } = useGetContextVariables();

  const dispatch = useDispatch();
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const chatOrder = useSelector(selectChatOrder);
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const readArticleActionDialogId = useSelector(
    selectreadArticleActionDialogId,
  );
  const isSqueezingStep = useSelector(selectIsSqueezingStep);
  const isEntryStep =
    Object.keys(canvasData.steps).length === 0 || !chatOrder.length;

  const saveArticleStep = (readArticleAction: string) => {
    dispatch(
      addArticleStep({
        is_entry_step: shouldSqueezeIntoEntry || isEntryStep,
        parent_step_id: isSqueezingStep ? squeezeStepParentId : lastStepId,
        step_fields: {
          doc_type: article.source_type,
          document_id: article.document_id,
          document_title: article.title,
          read_article_action: readArticleAction,
        },
        step_type: StepTypes.ARTICLE,
      }),
    );
    dispatch(setReadArticleActionDialogId(''));
    dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
    if (isSqueezingStep) {
      dispatch(setIsSqueezingStep(false));
      dispatch(setShouldSqueezeIntoEntry(false));
    }
  };

  const [{ isDragging }, drag] = useDrag(
    () => ({
      canDrag: !isDisabled,
      collect: monitor => ({
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging(),
      }),
      end: (_, monitor) => {
        if (!isDropValid(monitor.getDropResult())) {
          return;
        }

        if (monitor.didDrop() && !hasWorkflowConflict) {
          dispatch(setReadArticleActionDialogId(article.document_id));
        }
      },
      item: article,
      type: RevampedDragNDrops.REVAMPED_FT_ARTICLE,
    }),
    [isDisabled],
  );

  return (
    <>
      <DraggableArticleCard
        badgeText={article.permission}
        description={article.preview}
        drag={drag}
        icon={
          <Icon
            src={articleLogos[article.source_type] || articleLogos.webpage}
          />
        }
        isDragging={isDragging}
        label={article.title}
        link={article.link}
      />
      <ArticleOptionsDialog
        onClose={() => {
          dispatch(setReadArticleActionDialogId(''));
        }}
        onSubmit={saveArticleStep}
        open={readArticleActionDialogId === article.document_id}
        title={article.title}
      />
    </>
  );
};

const Icon = styled(ReactSVG)`
  width: 20px;
  height: 20px;
`;

export default Article;
