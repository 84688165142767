import { Box, useTheme } from '@mui/material';

import { Chip } from '@forethought-technologies/forethought-elements';
import { useSendPreviewApiRequest } from '../hooks';
import { ApiTextStep } from './ApiTextStep';
import {
  Button,
  ButtonApiWidgetComponent,
} from 'src/slices/workflow-preview/types';

export const ApiButtonStep = ({
  isTheLatestStep,
  step,
}: {
  isTheLatestStep: boolean;
  step: ButtonApiWidgetComponent;
}) => {
  const sendPreviewApiRequest = useSendPreviewApiRequest();

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <ApiTextStep message={step.message} />
      {isTheLatestStep && (
        <Box display='flex' flexWrap='wrap' gap={1}>
          {step.buttons.map(button => (
            <ButtonOption
              buttonOption={button}
              key={button.value}
              onClick={() => {
                const cv: Record<string, string> = {};
                cv[step.output_variable] = button.value;
                sendPreviewApiRequest({
                  contextVariables: cv,
                });
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

const ButtonOption = ({
  buttonOption,
  onClick,
}: {
  buttonOption: Button;
  onClick: React.MouseEventHandler;
}) => {
  const { palette } = useTheme();
  return (
    <Chip
      backgroundColor={palette.colors.white}
      color={palette.colors.black}
      fontSize='14px'
      key={buttonOption.value}
      label={buttonOption.display_name}
      onClick={onClick}
      size='medium'
      variant='intent'
    />
  );
};
