import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { patchArticle } from 'src/actions/discover/discover';
import { selectPatchArticle } from 'src/reducers/discoverReducer/discoverReducer';
import { PatchArticleRequest } from 'src/reducers/discoverReducer/types';
import { useAppDispatch } from 'src/store/hooks';

export const usePatchArticleMutation = () => {
  const data = useSelector(selectPatchArticle);
  const dispatch = useAppDispatch();

  const patchArticleMutation = useCallback(
    (articleId: string, body: PatchArticleRequest) => {
      dispatch(patchArticle({ articleId, body }));
    },
    [dispatch],
  );

  return { ...data, patchArticleMutation };
};
