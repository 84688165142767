import React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface BadgeProps {
  content: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({
  content,
  onClick,
}) => {
  const { palette } = useTheme();
  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.slate[100]}
      borderRadius='4px'
      component='div'
      display='flex'
      lineHeight='14px'
      onClick={onClick}
      padding='4px 8px'
      sx={{
        ':hover': {
          bgcolor: palette.colors.slate[200],
          cursor: 'pointer',
        },
      }}
      width='fit-content'
    >
      <Tooltip tooltipContent={content}>
        <Box maxWidth='190px' overflow='hidden' textOverflow='ellipsis'>
          <Typography
            color={palette.colors.grey[700]}
            noWrap
            variant='font12Medium'
          >
            {content}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default Badge;
