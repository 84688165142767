import { styled } from '@mui/material/styles';

export const InnerPage = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  gap: 16px;
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 32px;
`;

export const InlineForm = styled('form')`
  align-items: flex-end;
  display: flex;
  gap: 8px;
`;
