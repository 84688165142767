import './index.scss';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NoDataViewTextProps } from './macro-controls-view/utils/enum';
import { MacroControlView } from './macro-controls-view';
import {
  getBarChartName,
  getPieChartDescription,
  getPieChartName,
  getPieChartTooltipMessage,
  getSettingsContainerAxisName,
  getSolveViewOptions,
  getTableHeaderDict,
  getTableValues,
  showNoDataViewBreakdown,
} from './utils';
import {
  setMacroControlsViewMode,
  setPageNumber,
  setSolveView,
} from 'src/actions/pageSettings/pageSettings';
import ChannelPicker from 'src/components/channel-picker';
import CustomDatepicker from 'src/components/dashboard-pages/answers-analytics-page/SettingsBar/CustomDatepicker/CustomDatepicker';
import SettingsContainer from 'src/components/dashboard-pages/answers-analytics-page/SettingsBar/SettingsBarContainer';
import TimeframeDropdown from 'src/components/dashboard-pages/answers-analytics-page/SettingsBar/TimeframeDropdown/TimeframeDropdown';
import LoadingSpinner from 'src/components/reusable-components/loading-spinner/loadingSpinner';
import OverviewCard from 'src/components/reusable-components/overview-card';
import PieChart from 'src/components/reusable-components/pie-chart';
import StackedBarChart from 'src/components/reusable-components/stacked-bar-chart';
import ViewNavigator from 'src/components/reusable-components/view-navigator/index';
import Table from 'src/components/table';
import {
  useConstructQueryParams,
  useLoadSolve,
  useSendInitialTrackingEvent,
} from 'src/hooks/hooks';
import {
  CustomRangeModifier,
  selectTimeFrameFilter,
  selectTimeRange,
} from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { selectSolveViewMode } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import {
  selectChannel,
  selectFilterType,
  selectIsLoading,
  selectTableData,
} from 'src/reducers/solveReducer';
import {
  selectChannels,
  selectIsSolveWorkflows,
  selectOverviewData,
} from 'src/reducers/solveReducer';
import {
  selectUserRole,
  tabsAvailable,
} from 'src/reducers/userReducer/userReducer';
import {
  SolveOverviewDataDict,
  SolveTableDict,
} from 'src/services/apiInterfaces';
import {
  Permission,
  SolveChannels,
  Tabs,
  UserRoles,
  ViewModes,
} from 'src/utils/enums';
import { getData } from 'src/utils/getDataUtil';
import { sendTrackingEvents } from 'src/utils/sendTrackingEvent';

const SolveAnalyticsPageV2: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const view = useSelector(selectSolveViewMode);
  const tableData: SolveTableDict | undefined = useSelector(selectTableData);
  const isLoading: boolean = useSelector(selectIsLoading);
  const selectedChannel = useSelector(selectChannel);
  const channelsAvailable: string[] = useSelector(selectChannels);
  const overviewData: SolveOverviewDataDict | undefined =
    useSelector(selectOverviewData);
  const isSolveWorkflows = useSelector(selectIsSolveWorkflows);
  const timeFrameFilter: string = useSelector(selectTimeFrameFilter);
  const costPerTicket: number | null =
    (overviewData?.cards?.cost_per_ticket as number | null) || null;
  const timeRange = useSelector(selectTimeRange);
  const application = Permission.SOLVE;
  const userRole = useSelector(selectUserRole);
  const availableTabs: string[] | undefined = useSelector(tabsAvailable);
  const selectedFilter = useSelector(selectFilterType);

  // Effects
  useLoadSolve();
  useConstructQueryParams();
  useSendInitialTrackingEvent();

  const isSuperAdmin = userRole === UserRoles.ROLE_SUPER_ADMIN;
  const includesMacro = availableTabs?.includes(Tabs.MACRO_CONTROLS);

  return (
    <div className='SolveAnalyticsPage'>
      <ViewNavigator
        isSolveView
        selectedView={view}
        setView={(viewOption: string) => {
          dispatch(setSolveView(viewOption));
          getData();
          dispatch(setPageNumber(0));
          if (isSuperAdmin && includesMacro) {
            dispatch(setMacroControlsViewMode('Trained Macros'));
          }
        }}
        ViewNavigatorClass={'--solve'}
        viewOptions={getSolveViewOptions(
          isSolveWorkflows,
          availableTabs,
          userRole,
        )}
      />
      {view === 'overview' && !isSolveWorkflows && (
        <div className='SolveAnalyticsPage-content'>
          <h1>Overview</h1>
          <div className='SolveAnalyticsPage-OverviewCardsContainer'>
            {overviewData &&
              overviewData.cards &&
              Object.keys(overviewData.cards)
                .filter(
                  (val: string) =>
                    val !== 'contact_support' && val !== 'cost_per_ticket',
                )
                .map((val: string, index: number) => {
                  return (
                    <OverviewCard
                      costPerTicket={costPerTicket}
                      key={index}
                      name={val}
                      showContactSupport={
                        val === 'cost_savings' &&
                        !!overviewData?.cards?.contact_support
                      }
                      value={overviewData.cards[val]}
                      valueType={overviewData.data_types}
                    />
                  );
                })}

            <div className='SolveAnalyticsPage-OverviewCardsContainer-SettingsBar'>
              <CustomDatepicker
                eventTracker={(dateRange: CustomRangeModifier) => {
                  sendTrackingEvents(
                    dispatch,
                    'timeframe-changed',
                    dateRange,
                    application,
                    {
                      channel: selectedChannel,
                      view_mode: view,
                    },
                  );
                }}
              />
              <TimeframeDropdown
                eventTracker={(option: string) => {
                  sendTrackingEvents(
                    dispatch,
                    'chart-timeframe-changed',
                    timeRange,
                    application,
                    {
                      channel: selectedChannel,
                      timeframe_filter: option,
                    },
                  );
                }}
              />
              <ChannelPicker
                channels={channelsAvailable}
                eventTracker={(selectedChannel: string) =>
                  sendTrackingEvents(
                    dispatch,
                    'channel-changed',
                    timeRange,
                    application,
                    {
                      channel: selectedChannel,
                      view_mode: view,
                    },
                  )
                }
              />
            </div>
          </div>

          <div className='SolveAnalyticsPage-ChartContainer'>
            <div className='SolveAnalyticsPage-ChartContainer-pie'>
              <PieChart
                data-testid='piechart'
                description={getPieChartDescription(selectedChannel)}
                name={getPieChartName(isSolveWorkflows, selectedChannel)}
                overviewData={overviewData}
                selectedChannel={selectedChannel}
                tooltipMessage={getPieChartTooltipMessage(
                  selectedChannel as SolveChannels,
                )}
              />
            </div>
            <div className='SolveAnalyticsPage-ChartContainer-bar'>
              <StackedBarChart
                data-testid='stackedbar'
                name={getBarChartName(selectedChannel)}
                overviewData={overviewData}
                selectedChannel={selectedChannel}
                timeFrameFilter={timeFrameFilter}
              />
            </div>
          </div>
        </div>
      )}

      {view === 'breakdowns' && (
        <div className='analytics-page'>
          <SettingsContainer
            axisName={getSettingsContainerAxisName(
              isSolveWorkflows,
              selectedChannel,
              selectedFilter,
            )}
            channelEventTracker={(selectedChannel: string) =>
              sendTrackingEvents(
                dispatch,
                'channel-changed',
                timeRange,
                application,
                {
                  channel: selectedChannel,
                  view_mode: view,
                },
              )
            }
            channels={channelsAvailable}
            chartData={undefined}
            dateRangeEventTracker={(dateRange: CustomRangeModifier) =>
              sendTrackingEvents(
                dispatch,
                'timeframe-changed',
                dateRange,
                application,
                {
                  channel: selectedChannel,
                  view_mode: view,
                },
              )
            }
            hideYAxisDropdown
            shareBtnEventTracker={(option: string) => {
              sendTrackingEvents(
                dispatch,
                'share-report',
                timeRange,
                application,
                {
                  type_of_share: option,
                },
              );
            }}
            showChannelDropdown={true}
            showlegend={isSolveWorkflows ? false : !tableData?.is_final}
            showReportSettings={false}
            tableData={tableData}
            timeframeEventTracker={(option: string) => {
              sendTrackingEvents(
                dispatch,
                'chart-timeframe-changed',
                timeRange,
                application,
                {
                  channel: selectedChannel,
                  timeframe_filter: option,
                },
              );
            }}
            viewMode={ViewModes.TABLE}
          />

          {isLoading && <LoadingSpinner />}
          {!isLoading && tableData && (
            <Table
              breakdownData={tableData.breakdown}
              filterEventTracker={(option: string) =>
                sendTrackingEvents(
                  dispatch,
                  'breakdown-table-filter-type-changed',
                  timeRange,
                  application,
                  {
                    filter_type: option,
                  },
                )
              }
              noDataViewBreakdown={showNoDataViewBreakdown(
                isSolveWorkflows,
                selectedChannel,
              )}
              noDataViewClass={NoDataViewTextProps.CLASS}
              noDataViewMessage={NoDataViewTextProps.MESSAGE}
              showTotals
              sortedProperty={''}
              tableData={tableData}
              tableHeaderDict={getTableHeaderDict(
                isSolveWorkflows,
                selectedChannel,
              )}
              tableValues={getTableValues(isSolveWorkflows, selectedChannel)}
            />
          )}
        </div>
      )}

      {view === 'Macro Controls' && <MacroControlView />}
    </div>
  );
};

export default SolveAnalyticsPageV2;
