import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { Box } from '@mui/system';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import Spinner from 'src/components/spinner/Spinner';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { OrgsDict } from 'src/services/apiInterfaces';
import { getSearchOrganizationsAPI } from 'src/services/apiV1';

const ORG_ITEM_KEY = 'org';

/**
 * for SUPER_ADMIN users, ensure that an org is selected before rendering views
 */
export default function WithOrgSelected({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedOrg, setSelectedOrg] = useLocalStorage<OrgsDict | null>(
    ORG_ITEM_KEY,
    null,
  );

  if (!selectedOrg) {
    return <SuperAdminOrgSelectPage setSelectedOrg={setSelectedOrg} />;
  }

  return <>{children}</>;
}
interface SuperAdminOrgSelectPageProps {
  setSelectedOrg: (org: OrgsDict) => void;
}

function SuperAdminOrgSelectPage({
  setSelectedOrg,
}: SuperAdminOrgSelectPageProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orgs, setOrgs] = useState<OrgsDict[] | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(false);

    getSearchOrganizationsAPI()
      .then(({ organizations }) => setOrgs(organizations))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, []);

  const orgOptions = useMemo(() => {
    return orgs?.map(org => ({
      label: org.org_name,
      value: org.org_id.toString(),
    }));
  }, [orgs]);

  const onOrgSelect = useCallback(
    (e: SelectChangeEvent) => {
      const selectedOrg = orgs?.find(
        org => org.org_id.toString() === e.target.value,
      );

      if (!selectedOrg) {
        console.error('Selected org not found in list of available orgs');
        return;
      }

      setSelectedOrg(selectedOrg);
    },
    [orgs, setSelectedOrg],
  );

  return (
    <Box
      sx={theme => ({
        alignItems: 'center',
        backgroundColor: theme.palette.colors.white,
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
      })}
    >
      <Box sx={{ maxWidth: 600 }}>
        {loading && <Spinner />}
        {error && <h2>Something went wrong loading organizations</h2>}
        {!(loading || error) && orgOptions && (
          <>
            <h2>Select an organization to get started</h2>
            <SelectDropdown
              aria-label='Organization'
              id='orgSelect'
              isMenuSearchable
              onChange={onOrgSelect}
              options={orgOptions}
              placeholder='Select an organization'
              value=''
            />
          </>
        )}
      </Box>
    </Box>
  );
}
