import { useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import {
  Checkbox,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TOPIC_COLOR_ARRAY } from '../solve-insights/topics/constants';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { replaceIdInRoute } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

interface LineGraphFilterProps {
  filterList: { label: string; topicId: string; value: boolean }[];
  setFilterList: (
    values: { label: string; topicId: string; value: boolean }[],
  ) => void;
}

const LineGraphFilter = ({
  filterList,
  setFilterList,
}: LineGraphFilterProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.SOLVE_INSIGHTS_TOPIC_DETAIL,
  );

  return (
    <Box
      display='flex'
      flexDirection='column'
      maxHeight='330px'
      paddingLeft='20px'
      sx={{ overflowY: 'scroll' }}
    >
      {filterList.map(({ label, topicId, value }, idx) => (
        <Box
          alignItems='center'
          display='flex'
          gap='4px'
          key={label}
          sx={{
            '.MuiFormControlLabel-root': {
              margin: 0,
            },
          }}
        >
          <Checkbox
            aria-label={`checkbox_${label}_${value}`}
            checked={value}
            onChange={() => {
              const updatedFilterList = [...filterList];
              updatedFilterList[idx].value = !value;
              setFilterList(updatedFilterList);
              emitTrackingEventCallback(
                'insight-topic-line-graph-filter-change',
                {
                  scope: 'detail',
                  tab: 'topic',
                  value: updatedFilterList
                    .filter(item => item.value)
                    .map(item => item.topicId),
                },
              );
            }}
          />
          <Box
            alignItems='center'
            display='flex'
            gap={1}
            paddingRight='20px'
            width='200px'
          >
            <Box
              bgcolor={TOPIC_COLOR_ARRAY[idx]}
              borderRadius='2px'
              display='flex'
              height='12px'
              width='12px'
            />
            <Box
              display='flex'
              onClick={() =>
                navigate(replaceIdInRoute(linkWithSearch, topicId))
              }
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  textDecorationColor: palette.colors.purple[500],
                },
              }}
            >
              <Tooltip tooltipContent={label}>
                <Typography
                  color={palette.colors.purple[500]}
                  maxWidth='160px'
                  noWrap
                  variant='font14Medium'
                >
                  {label}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default LineGraphFilter;
