import './BooleanFilterModal.scss';

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import FiltersTooltip from '../filters-tooltip/FiltersTooltip';
import { getAnswersData } from 'src/actions/data/data';
import { setFilter } from 'src/actions/filters/filters';
import { selectSelectedFilterField } from 'src/reducers/filtersReducer/filtersReducer';
import setTooltipVisible from 'src/utils/setTooltipVisibility';

interface BooleanFilterModalProps {
  handleModalVisibility: Function;
  reference: React.RefObject<HTMLDivElement>;
}

const BooleanFilterModal: React.FC<
  React.PropsWithChildren<BooleanFilterModalProps>
> = (props: BooleanFilterModalProps) => {
  const { handleModalVisibility, reference } = props;
  const selectedFilterField = useSelector(selectSelectedFilterField);
  const dispatch = useDispatch();
  const searchFilters = ['TRUE', 'FALSE'];
  const [isDropdownOpen, setDropdownIsOpened] = useState<boolean>(false);
  const [dropdownOption, setDropdownOption] = useState<number>(0);
  const [isTitleOverFlowed, setIsTitleOverFlowed] = useState<boolean>(false);
  const [titleTooltipVisibility, setTitlteTooltipVisibility] =
    useState<boolean>(false);
  const titleRef: React.RefObject<HTMLSpanElement> = useRef(null);

  const handleDropdownState = () => {
    setDropdownIsOpened(!isDropdownOpen);
  };

  const handleDropdownOption = (index: number) => {
    setDropdownOption(index);
  };

  const dropdownSelectedOptionClass = classNames(
    'BooleanFilterModal-operatorDropdown',
    {
      'BooleanFilterModal--operatorDropdownOpen': isDropdownOpen,
    },
  );

  return (
    <div
      className='BooleanFilterModal-container'
      data-testid='boolean_modal'
      ref={reference}
    >
      <div className='BooleanFilterModal-titleContainer'>
        <span className='BooleanFilterModal-titleIcon  BooleanFilterModal-booleanTypeIcon' />
        <span
          className='BooleanFilterModal-title '
          onMouseEnter={() =>
            setTooltipVisible(
              true,
              setTitlteTooltipVisibility,
              setIsTitleOverFlowed,
              titleRef,
              196,
            )
          }
          onMouseLeave={() =>
            setTooltipVisible(
              false,
              setTitlteTooltipVisibility,
              setIsTitleOverFlowed,
              titleRef,
              196,
            )
          }
          ref={titleRef}
        >
          {isTitleOverFlowed && titleTooltipVisibility && (
            <FiltersTooltip
              isShort
              isTitle
              title={selectedFilterField?.field_name}
            />
          )}
          {selectedFilterField?.field_name}
        </span>
      </div>
      <div
        className='BooleanFilterModal-dropdownOperatorContainer'
        onClick={() => handleDropdownState()}
      >
        <div className={dropdownSelectedOptionClass}>
          <span className='BooleanFilterModal-selectedDropdownOption'>
            {searchFilters[dropdownOption]}
          </span>
          <span className='BooleanFilterModal-dropdownOperatorIcon' />
          {isDropdownOpen && (
            <div className='BooleanFilterModal-selectedDropdownOptionBorder'></div>
          )}
        </div>
        {isDropdownOpen && (
          <div className='BooleanFilterModal-dropdownOptionsContainer'>
            {searchFilters.map((filter, index) => (
              <span
                className='BooleanFilterModal-dropdownOption'
                key={filter}
                onClick={() => handleDropdownOption(index)}
              >
                {filter}
              </span>
            ))}
          </div>
        )}
      </div>
      <button
        className='BooleanFilterModal-applyFilterButton'
        onClick={() => {
          dispatch(
            setFilter({
              field: {
                field_category: selectedFilterField?.field_category,
                field_id: selectedFilterField?.field_id,
                field_name: selectedFilterField?.field_name,
                field_type: selectedFilterField?.field_type,
              },
              negate: false,
              operator: searchFilters[dropdownOption],
              values: [searchFilters[dropdownOption].toLowerCase()],
            }),
          );
          handleModalVisibility();
          dispatch(getAnswersData());
        }}
      >
        Apply Filter
      </button>
    </div>
  );
};

export default BooleanFilterModal;
