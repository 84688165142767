import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTriageConfigDetailUiErrorMessage, TOAST_TIMEOUT } from '../helpers';
import { selectError } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';

export const useDispatchGlobalToastErrors = () => {
  const dispatch = useDispatch();
  const errorType = useSelector(selectError);
  useEffect(() => {
    if (errorType === null) {
      return;
    }

    dispatch(
      setGlobalToastOptions({
        autoHideDuration: TOAST_TIMEOUT,
        title: getTriageConfigDetailUiErrorMessage(errorType),
        variant: 'danger',
      }),
    );
  }, [dispatch, errorType]);
};
