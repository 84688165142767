import * as yup from 'yup';

import { ContextVariableTypes } from './enums';
import { UUID_REGEX } from 'src/constants/regex';
import { isEmailValid } from 'src/pages/solve-analytics/macro-controls-view/utils/handleEmailUtil';
import { ContextVariableValue } from 'src/types/workflowBuilderAPITypes';
import isDecimal from 'validator/lib/isDecimal';

export const getIsValidPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber.length) {
    return false;
  }
  const re = /^[0-9]{11,14}$/;
  return re.test(phoneNumber);
};

export const getIsNumberValid = (numberAsString: string): boolean => {
  const number = Number(numberAsString);

  return (
    isDecimal(numberAsString) &&
    number >= Number.MIN_SAFE_INTEGER &&
    number <= Number.MAX_SAFE_INTEGER
  );
};

// Taken from https://stackoverflow.com/questions/6478875/regular-expression-matching-e-164-formatted-phone-numbers
export const isPhoneNumberValid = (phone_number: string): boolean => {
  if (!phone_number.length) {
    return false;
  }

  const re = /^[0-9]{11,14}$/;

  if (re.test(phone_number)) {
    return true;
  }

  return false;
};

export function isContextVariableValueValid({
  type,
  value,
}: {
  type: keyof typeof ContextVariableTypes;
  value: ContextVariableValue;
}) {
  if (type === 'EMAIL') {
    return isEmailValid(String(value));
  }

  if (type === 'PHONE') {
    return isPhoneNumberValid(String(value));
  }

  if (type === 'NUMBER') {
    return getIsNumberValid(String(value));
  }

  if (type === 'MULTI_SELECT_LIST') {
    return (value as string[]).length > 0;
  }

  return typeof value === 'string' ? value.trim() : true;
}

export const uuidOrEmptySchema = yup
  .string()
  .test(
    'is-valid-uuid',
    'Value must be a valid UUID, or a list of comma-separated UUIDs',
    value => {
      if (!value) {
        return true;
      }
      return value.split(',').every(uuid => UUID_REGEX.test(uuid));
    },
  );
