import { useMemo } from 'react';

import { useGetEmailBreakdownMetricsQuery } from 'src/services/workflow-builder-metrics';
import { SolveMetricsRequestQueryParams } from 'src/types/workflowBuilderAPITypes';

export function useEmailBreakdownMetricsRow({
  intentId,
  ...query
}: { intentId: string | null } & SolveMetricsRequestQueryParams) {
  const { data, ...rest } = useGetEmailBreakdownMetricsQuery(query, {
    skip: !intentId,
  });

  const dataFilteredByIntent = useMemo(() => {
    return data?.breakdown.find(row => row.intent_definition_id === intentId);
  }, [data, intentId]);

  return {
    data: dataFilteredByIntent,
    ...rest,
  };
}
