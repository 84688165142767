import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { styled } from '@mui/material';

import Tooltip from 'src/components/reusable-components/tooltip/tooltip';
import {
  ErrorMessage,
  Label,
  Required,
  Row,
  Spacer,
  TextArea,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';

type Props = {
  fieldName: string;
  label: string;
  placeholder: string;
  required?: boolean;
  tooltip?: string;
};

const FormikTextArea: FC<React.PropsWithChildren<Props>> = ({
  fieldName,
  label,
  placeholder,
  required = false,
  tooltip,
}) => {
  return (
    <>
      <Row>
        <Label>{label}</Label>
        {tooltip && (
          <TooltipWrapper>
            <Tooltip tooltipText={tooltip} />
          </TooltipWrapper>
        )}
        <Spacer width='4px' />
        {required && <Required>Required</Required>}
      </Row>
      <Field name={fieldName}>
        {({ field, meta }: FieldProps) => (
          <>
            <TextArea
              hasError={!!meta.error}
              placeholder={placeholder}
              {...field}
            />
            {meta.touched && meta.error && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </Field>
    </>
  );
};

export default FormikTextArea;

const TooltipWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
