import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import banner from 'src/assets/images/solve_lite_knowledge_banner.png';

const KnowledgeWidgetContent = () => {
  return (
    <Box>
      <Box pb='100px'>
        <Banner alt='' src={banner} />
      </Box>
      <Box
        alignSelf='center'
        display='flex'
        flexDirection='column'
        pl='90px'
        pr='90px'
        sx={{
          '.MuiTypography-root': {
            background:
              'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
            fontStyle: 'italic',
            textAlign: 'center',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          },
        }}
      >
        <Typography variant='font16Medium'>
          Please provide the public URL of your organization&apos;s knowledge
          base or an integrated help center such as Zendesk or Salesforce.
        </Typography>
        <Box pb='20px' />
        <Typography variant='font16Medium'>
          Our AI models can connect with your help center and use the
          information to train your bot, giving you a more personalized
          experience.
        </Typography>
      </Box>
    </Box>
  );
};

const Banner = styled('img')`
  max-width: 100%;
`;

export default KnowledgeWidgetContent;
