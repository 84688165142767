import { CreateOrgState, SelfSignUpRequestParameters } from './types';
import { selfSignUp } from 'src/services/api';

export const handleSelfSignUp = async ({
  email,
  firstName,
  helpdesk,
  lastName,
  orgName,
  token,
}: CreateOrgState) => {
  const selfSignUpRequestObject: SelfSignUpRequestParameters = {
    company_name: orgName,
    email: email,
    first_name: firstName,
    helpdesk: helpdesk,
    last_name: lastName,
    token: token,
  };
  await selfSignUp(selfSignUpRequestObject);
};
