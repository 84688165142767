import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, useTheme } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { StyledMenuItem } from '../DropdownMenu';
import { setGlobalHandoffDrawerOptions } from 'src/slices/ui/uiSlice';
interface CreateIntentDropdownMenuProps {
  onNewCustomIntentButtonClick: () => void;
}

export const CreateIntentDropdownMenu = ({
  onNewCustomIntentButtonClick,
}: CreateIntentDropdownMenuProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={<IconPlus size={16} />}
        variant={'main'}
      >
        Create New ...
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            border: `1px solid ${theme.palette.colors.grey[200]}`,
            borderRadius: '4px',
            boxShadow:
              '0px 0px 1px rgba(0, 12, 32, 0.04), 2px 5px 8px rgba(3, 17, 38, 0.107135)',
            minWidth: '160px',
            padding: '4px 0',
          },
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <StyledMenuItem
          disableRipple
          onClick={() => {
            onNewCustomIntentButtonClick();
            handleClose();
          }}
        >
          <Typography variant='font14'>Intent</Typography>
        </StyledMenuItem>
        <StyledMenuItem
          disableRipple
          onClick={() => {
            dispatch(
              setGlobalHandoffDrawerOptions({
                isCreateHandoffDrawerOpen: true,
                isEditing: false,
              }),
            );

            handleClose();
          }}
        >
          <Typography variant='font14'>Handoff</Typography>
        </StyledMenuItem>
      </Menu>
    </>
  );
};
