import { useEffect, useState } from 'react';
import { SolveConfigContentContainer } from '..';
import { Box, styled } from '@mui/material';

import SolveConfigPreviewHeader from '../solve-config-preview-header/SolveConfigPreviewHeader';
import { BrowserChrome } from './BrowserChrome';
import Spinner from 'src/components/spinner';
import { useGetPreviewTokenQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import {
  isForethoughtWidgetLoadedMessageEvent,
  isPreviewPageLoadedMessageEvent,
} from 'src/types/solveWidgetEventTypes';

const SolvePreviewPage = () => {
  const { data: previewTokenData } = useGetPreviewTokenQuery();
  const { preview_api_token: previewToken = '' } = previewTokenData ?? {};
  const [iframeSource, setIframeSource] = useState<MessageEventSource | null>(
    null,
  );
  const [isForethoughtWidgetLoaded, setIsForethoughtWidgetLoaded] =
    useState(false);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.origin === location.origin) {
        if (isPreviewPageLoadedMessageEvent(event)) {
          setIframeSource(event.source);

          return;
        }

        if (isForethoughtWidgetLoadedMessageEvent(event)) {
          setIsForethoughtWidgetLoaded(true);
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  useEffect(() => {
    if (iframeSource && previewToken) {
      iframeSource.postMessage(
        {
          apiKey: previewToken,
          message: 'initializeWidget',
          sender: 'forethought',
          src: SOLVE_URL,
        },
        { targetOrigin: location.origin },
      );
    }
  }, [iframeSource, previewToken]);

  return (
    <>
      <SolveConfigPreviewHeader />
      <Box display='flex' height='100%'>
        <SolveConfigContentContainer flexDirection='column'>
          <BrowserChrome>
            <Iframe src='/preview.html' title='Preview' />
            {!isForethoughtWidgetLoaded && (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
          </BrowserChrome>
        </SolveConfigContentContainer>
      </Box>
    </>
  );
};

const Iframe = styled('iframe')`
  width: 100%;
  flex-grow: 1;
  border: none;
`;

const SpinnerContainer = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SolvePreviewPage;
