/* eslint-disable react/prop-types -- false warnings for column definitions */

import { useCallback, useMemo, useState } from 'react';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Avatar,
  Badge,
  Button,
  getDefaultMRTOptions,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { useFilteredUsers, useIsCurrentUserAdmin } from '../hooks';
import { UserWithAnalyticsAccess } from '../types';
import EditUserDrawer from './EditUserDrawer';
import iconAnalyticsGrey from 'src/assets/images/icon-analytics-grey.svg';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGetEmbeddedAnalyticsSettingsQuery } from 'src/services/embedded-analytics/embeddedAnalyticsApi';
import { selectUsers } from 'src/slices/team/teamSlice';
import { deleteUser } from 'src/slices/team/thunks';
import { useAppDispatch } from 'src/store/hooks';
import {
  formatPermissionLevel,
  getAvatarTextFromUser,
  getFullName,
} from 'src/utils/sessionUtils';

interface UsersTableProps {
  filterQuery: string;
  topToolbar: JSX.Element;
}

export default function UsersTable({
  filterQuery,
  topToolbar,
}: UsersTableProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isCurrentUserAdmin = useIsCurrentUserAdmin();
  const { data, error, loading } = useSelector(selectUsers);
  const [selectedUser, setSelectedUser] =
    useState<UserWithAnalyticsAccess | null>(null);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { data: analyticsData } = useGetEmbeddedAnalyticsSettingsQuery();

  const handleDelete = useCallback(
    async (user: UserWithAnalyticsAccess) => {
      const userConfirms = await window.confirm(
        `Are you sure you want to delete ${user.email}?`,
      );
      if (userConfirms) {
        dispatch(deleteUser(user.user_id));
      }
    },
    [dispatch],
  );

  const filteredData = useFilteredUsers(data, filterQuery);
  const usersWithAnalyticsData = useMemo(
    () =>
      filteredData.map(user => {
        const hasAnalyticsAccess = analyticsData?.allowed_emails?.includes(
          user.email,
        );
        return {
          ...user,
          hasAnalyticsAccess: hasAnalyticsAccess ?? false,
        };
      }),
    [analyticsData?.allowed_emails, filteredData],
  );

  const columnHelper =
    createMRTColumnHelper<(typeof usersWithAnalyticsData)[number]>();

  const columns = useMemo(
    () => [
      columnHelper.accessor(getFullName, {
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Avatar variant='sm'>
                {getAvatarTextFromUser(row.original)}
              </Avatar>
              {renderedCellValue || '-'}
            </Box>
          );
        },
        header: 'Name',
        id: 'name',
      }),
      columnHelper.accessor('email', { header: 'Email', size: 350 }),
      columnHelper.accessor('permission_level', {
        Cell: ({ cell, row }) => (
          <Box alignItems='center' display='flex'>
            <RoleContainer>
              {formatPermissionLevel(cell.getValue())}
            </RoleContainer>
            {row.original.hasAnalyticsAccess && (
              <Tooltip tooltipContent='Access to Advanced Analytics'>
                <Image alt='Analytics icon' src={iconAnalyticsGrey} />
              </Tooltip>
            )}
          </Box>
        ),
        header: 'Permission level',
      }),
      columnHelper.accessor('enabled', {
        Cell: ({ cell }) => (
          <Badge
            label={cell.getValue() ? 'Enabled' : 'Disabled'}
            variant={cell.getValue() ? 'success' : 'error'}
          />
        ),
        header: 'Status',
      }),
    ],
    [columnHelper],
  );

  const defaultMRTOptions = getDefaultMRTOptions<
    (typeof usersWithAnalyticsData)[number]
  >({
    theme,
  });

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    columns,
    data: usersWithAnalyticsData,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 130,
      },
    },
    enableColumnActions: false,
    enableExpanding: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableRowActions: isCurrentUserAdmin,
    initialState: {
      columnPinning: {
        left: ['name'],
        right: ['mrt-row-actions'],
      },
    },
    layoutMode: 'grid',
    muiTableContainerProps: {
      sx: { maxHeight: 'calc(100vh - 213px)' },
    },
    muiToolbarAlertBannerProps: error
      ? {
          children: 'Error loading data',
          color: 'error',
        }
      : undefined,
    renderRowActions: ({ row }) => {
      return (
        <Box display='flex' gap='4px'>
          <Button
            onClick={() => {
              emitTrackingEventCallback('user-edit-clicked', {
                target_user: row.original.email,
              });

              setSelectedUser(row.original);
            }}
            variant='ghost'
          >
            Edit
          </Button>
          <Button onClick={() => handleDelete(row.original)} variant='ghost'>
            Remove
          </Button>
        </Box>
      );
    },
    renderTopToolbarCustomActions: () => topToolbar,
    state: {
      isLoading: loading,
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <EditUserDrawer
        onCancel={() => setSelectedUser(null)}
        selectedUser={selectedUser}
      />
    </>
  );
}

const Image = styled('img')`
  padding-left: 15px;
`;

const RoleContainer = styled('div')`
  width: 40px;
`;
