import { ComponentProps, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import { elevations } from '@forethought-technologies/forethought-elements';
import EmailConfigurationPage from '../workflow-builder-config/email/email-configuration-page/EmailConfigurationPage';
import { useLoadingState } from './hooks/useLoadingState';
import SlackTab from './slack/index';
import SolveConfigHeader from './solve-config-header/SolveConfigHeader';
import ApiTab from './api';
import { Channel } from './types';
import { WarningModals } from './WarningModals';
import WidgetTab from './widget-tab';
import {
  useSolveConfigTrackingEventAction,
  useStateParams,
} from 'src/hooks/hooks';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  getInitialSolveConfiguration,
  selectIsSolveConfigInitialized,
  selectIsValidationFailing,
  showWarningModal,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

const SolveConfigurationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const isSolveConfigInitialized = useSelector(selectIsSolveConfigInitialized);
  const isValidationFailing = useSelector(selectIsValidationFailing);
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  useLoadingState();
  const { data: featureFlagsData, isLoading: isFeatureFlagsLoading } =
    useGetFeatureFlagsQuery();

  const isSolveApiPermissionEnabled =
    featureFlagsData?.feature_flags.includes('solve_api');

  const isSolveSlackEnabled =
    featureFlagsData?.feature_flags.includes('solve_slack');

  const isApiConfigurationEnabled =
    !isSolveLiteEnabled && isSolveApiPermissionEnabled;

  const isSolveEmailEnabled = featureFlagsData?.feature_flags.includes(
    'solve_email_enabled',
  );

  const channelTabs = useMemo(
    () =>
      [
        'Widget',
        ...(isSolveEmailEnabled ? (['Email'] as const) : ([] as const)),
        ...(isApiConfigurationEnabled ? (['API'] as const) : ([] as const)),
        ...(isSolveSlackEnabled ? (['Slack'] as const) : ([] as const)),
      ] as const,
    [isApiConfigurationEnabled, isSolveEmailEnabled, isSolveSlackEnabled],
  );

  const [channel] = useStateParams<Channel>({
    deserialize: useCallback(value => value as Channel, []),
    initialState: channelTabs[0].toLowerCase() as Channel,
    paramsName: 'channel',
    serialize: String,
  });

  const activeChannelTabIndex = channelTabs.findIndex(
    id => id.toLowerCase() === channel,
  );

  const handleChannelTabChange = useCallback<
    ComponentProps<typeof SolveConfigHeader>['handleChannelTabChange']
  >(
    (_, value) => {
      // if validation is failing, we show the warning modal and exit the function
      if (isValidationFailing) {
        dispatch(showWarningModal());
        return;
      }
      const tab = channelTabs[value].toLowerCase();
      const tabLabel =
        tab === 'api' ? 'api_key' : tab === 'slack' ? 'integrate' : '';
      const tabParam = tabLabel ? `&tab=${tabLabel}` : '';
      navigate(
        {
          pathname: Routes.SOLVE_CONFIGURATION,
          search: `?channel=${channelTabs[value].toLowerCase()}${tabParam}`,
        },
        { replace: true },
      );
      dispatchTrackingAction(SolveConfigTrackingEventTypes.TAB_NAVIGATION, {
        tab: tabLabel,
      });
    },
    [
      channelTabs,
      dispatch,
      dispatchTrackingAction,
      isValidationFailing,
      navigate,
    ],
  );

  /**
   * `<Tabs>` component requires `tabs` to be mutable.
   */
  const mutableChannelTabs = useMemo(() => [...channelTabs], [channelTabs]);

  useEffect(() => {
    if (!isFeatureFlagsLoading) {
      dispatch(getInitialSolveConfiguration(isSolveLiteEnabled));
    }
  }, [dispatch, isSolveLiteEnabled, isFeatureFlagsLoading]);

  if (isSolveConfigInitialized) {
    return (
      <>
        <SolveConfigHeader
          activeChannelTabIndex={activeChannelTabIndex}
          channel={channel}
          channelTabs={mutableChannelTabs}
          handleChannelTabChange={handleChannelTabChange}
        />

        <Box
          aria-labelledby={`configuration-tab-${channel}`}
          display='flex'
          height='calc(100% - 50px)'
          id={`configuration-tabpanel-${channel}`}
          role='tabpanel'
        >
          {channel === 'widget' && <WidgetTab channel={channel} />}
          {channel === 'api' && <ApiTab channel={channel} />}
          {channel === 'email' && <EmailConfigurationPage />}
          {channel === 'slack' && <SlackTab channel={channel} />}
        </Box>

        <WarningModals />
      </>
    );
  }

  // TODO - loading screen / error screen
  return null;
};

export const SolveConfigContentContainer = styled('div')<{
  flexDirection: string;
}>`
  display: flex;
  flex: 1;
  flex-direction: ${props => props.flexDirection};
  justify-content: center;
  background-color: ${props => props.theme.palette.colors.slate[200]};
`;

export const ToolbarContainer = styled('div')<{
  isFullScreen: boolean;
  isTabSelected: boolean;
}>`
  background-color: ${props => props.theme.palette.colors.white};
  box-shadow: ${elevations.z3};
  flex: ${props =>
    props.isTabSelected ? (props.isFullScreen ? 1 : '0 0 480px') : 0};
  position: sticky;
  top: 0;
  height: 100%;
`;

export default SolveConfigurationPage;
