import { dashboardApi } from '../dashboard-api';
import {
  RedactionProductBlackList,
  UpdateRedactionProudctBlackist,
} from 'src/pages/workflow-builder-edit/types';

const SECURITY_SETTINGS_BASE_URL = '/security';

const securitySettingsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    addProductToBlacklist: builder.mutation<
      UpdateRedactionProudctBlackist,
      UpdateRedactionProudctBlackist
    >({
      invalidatesTags: ['RedactionProductBlacklist'],
      query: ({ product }) => ({
        body: { product },
        method: 'PUT',
        url: `${SECURITY_SETTINGS_BASE_URL}/redaction/product-blacklist/add`,
      }),
    }),
    getRedactionEntityList: builder.query<RedactionProductBlackList, void>({
      providesTags: ['RedactionProductBlacklist'],
      query: () => `${SECURITY_SETTINGS_BASE_URL}/redaction/product-blacklist`,
    }),
    removeProductFromBlacklist: builder.mutation<
      UpdateRedactionProudctBlackist,
      UpdateRedactionProudctBlackist
    >({
      invalidatesTags: ['RedactionProductBlacklist'],
      query: ({ product }) => ({
        body: { product },
        method: 'PUT',
        url: `${SECURITY_SETTINGS_BASE_URL}/redaction/product-blacklist/remove`,
      }),
    }),
  }),
});

export const {
  useAddProductToBlacklistMutation,
  useGetRedactionEntityListQuery,
  useRemoveProductFromBlacklistMutation,
} = securitySettingsApi;
