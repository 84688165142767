import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import ConnectionCard from './ConnectionCard';
import HelpdeskCard from './HelpdeskCard';
import { useGetArticleIntegrations } from 'src/hooks/discover/useGetArticleIntegrations';
import { useGetCreatedConnectorsQuery } from 'src/services/connector/connectorApi';

const ConnectionList = ({ type }: { type: 'data' | 'help_desk' }) => {
  const { data: connectorList } = useGetCreatedConnectorsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: integrations } = useGetArticleIntegrations();

  if (type === 'data') {
    const filteredConnectionList =
      connectorList?.filter(connector =>
        connector.connector_types.includes(type),
      ) || [];

    return (
      <>
        <Typography variant='font16Bold'>Current connections</Typography>
        <Box pt={1}>
          {filteredConnectionList.map(connector => {
            return (
              <ConnectionCard
                connector={connector}
                key={connector.connector_id}
              />
            );
          })}
        </Box>
      </>
    );
  }

  if (type === 'help_desk' && integrations?.connected_helpdesks?.length) {
    return (
      <>
        <Typography variant='font16Bold'>Current connections</Typography>
        <Box pt={1}>
          {integrations.connected_helpdesks.map(helpdesk => {
            return (
              <HelpdeskCard
                currentHelpdesk={integrations.current_helpdesk}
                helpdesk={helpdesk}
                key={helpdesk.name}
              />
            );
          })}
        </Box>
      </>
    );
  }

  return null;
};

export default ConnectionList;
