import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import {
  NEW_NOTE_ID,
  NOTE_TOAST_OPTIONS,
  NOTE_TOAST_TEXT,
  UserPermissionValue,
} from './constants';
import { buildFilterOptions, createNewNote } from './helpers';
import { useGetNotes } from './hooks';
import { DeleteNoteDialog, UnshareNoteDialog } from './note-dialog';
import NoteDrawer from './note-drawer';
import NotesTable from './notes-table';
import NotesTableToolbar from './notes-table-toolbar';
import {
  createNote,
  deleteNote,
  patchNote,
  putShareNote,
} from 'src/actions/assist';
import { useEmitTrackingEvent } from 'src/hooks/hooks';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { selectUser } from 'src/reducers/userReducer/userReducer';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const AssistNotesPage = () => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  useEmitTrackingEvent('assist-notes-viewed');
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const { data, loading } = useGetNotes();
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUser)?.user?.email || '';

  const [selectedNoteId, setSelectedNoteId] = useState('');
  const [deletingNoteId, setDeletingNoteId] = useState('');
  const [unSharingNoteId, setUnSharingNoteId] = useState('');

  const [searchText, setSearchText] = useState('');
  const [filterValue, setFilterValue] = useState<string[]>([]);
  const [tagFilterValue, setTagFilterValue] = useState<string[]>([]);
  const [userPermissionFilterValue, setUserPermissionFilterValue] =
    useState<UserPermissionValue>([]);

  const selectedNote = useMemo(() => {
    if (selectedNoteId === NEW_NOTE_ID) {
      return createNewNote({
        agent_email: userEmail,
        created_by_role: userRole,
      });
    }
    return data?.notes.find(note => note.note_id === selectedNoteId);
  }, [data?.notes, selectedNoteId, userRole, userEmail]);

  const allAvailableTags = useMemo(() => {
    const tags = data?.notes.map(note => note.tags).flat();
    return [...new Set(tags)];
  }, [data?.notes]);

  const onCloseNoteDrawer = useCallback(() => {
    setSelectedNoteId('');
  }, []);

  const onDeleteNote = useCallback(
    (noteId: string) => {
      dispatch(deleteNote(noteId));
      dispatch(
        setGlobalToastOptions({
          title: NOTE_TOAST_TEXT.deleted,
          ...NOTE_TOAST_OPTIONS,
        }),
      );
      emitTrackingEventCallback('assist-note-deleted', { note_id: noteId });

      onCloseNoteDrawer();
    },
    [dispatch, onCloseNoteDrawer, emitTrackingEventCallback],
  );

  const onToggleNoteShare = useCallback(
    (noteId: string, isPublic = true) => {
      const toastOptions = {
        title: NOTE_TOAST_TEXT.unshared,
        ...NOTE_TOAST_OPTIONS,
      };

      if (isPublic) {
        dispatch(
          putShareNote({
            is_public: false,
            note_id: noteId,
          }),
        );
        dispatch(
          setGlobalToastOptions({
            title: NOTE_TOAST_TEXT.unshared,
            ...NOTE_TOAST_OPTIONS,
          }),
        );

        onCloseNoteDrawer();
      } else {
        dispatch(putShareNote({ is_public: true, note_id: noteId }));
        toastOptions.title = NOTE_TOAST_TEXT.shared;
      }
      emitTrackingEventCallback(
        isPublic ? 'assist-note-unshared' : 'assist-note-shared',
        { note_id: noteId },
      );

      dispatch(setGlobalToastOptions(toastOptions));
    },
    [dispatch, emitTrackingEventCallback, onCloseNoteDrawer],
  );

  const onSaveNote = useCallback(
    async ({ body, title }: { body: string; title: string }) => {
      if (!selectedNote) {
        throw new Error(`selectedNote unexpected value ${selectedNote}`);
      }

      if (selectedNote.note_id === NEW_NOTE_ID) {
        const { note_id } = await dispatch(
          createNote({ body, title }),
        ).unwrap();
        setSelectedNoteId(note_id);
        emitTrackingEventCallback('assist-note-created', { note_id });
      } else {
        dispatch(
          patchNote({
            ...selectedNote,
            body,
            title,
          }),
        );
        emitTrackingEventCallback('assist-note-edited', {
          note_id: selectedNote.note_id,
        });
      }
    },

    [dispatch, emitTrackingEventCallback, selectedNote],
  );

  const filterOptions = useMemo(
    () => buildFilterOptions(data?.notes),
    [data?.notes],
  );

  return (
    <>
      <Box
        bgcolor={palette.colors.white}
        display='flex'
        flexDirection='column'
        height='calc(100vh - 69px)'
        px={5}
      >
        <NotesTableToolbar
          allAvailableTags={allAvailableTags}
          filterOptions={filterOptions}
          filterValue={filterValue}
          searchText={searchText}
          setFilterValue={setFilterValue}
          setSearchText={setSearchText}
          setSelectedNoteId={setSelectedNoteId}
          setTagFilterValue={setTagFilterValue}
          setUserPermissionFilterValue={setUserPermissionFilterValue}
          tagFilterValue={tagFilterValue}
          userPermissionFilterValue={userPermissionFilterValue}
        />
        <Box
          id='notes-table-container'
          sx={{ flex: 1, overflowX: 'auto', overflowY: 'hidden' }}
        >
          <NotesTable
            filterValue={filterValue}
            isLoading={loading}
            notes={data?.notes}
            onDelete={setDeletingNoteId}
            onToggleNoteShare={onToggleNoteShare}
            searchText={searchText}
            setSelectedNoteId={setSelectedNoteId}
            tagFilterValue={tagFilterValue}
            userEmail={userEmail}
            userPermissionFilterValue={userPermissionFilterValue}
          />
        </Box>
      </Box>
      <NoteDrawer
        allAvailableTags={allAvailableTags}
        onClose={onCloseNoteDrawer}
        onDelete={setDeletingNoteId}
        onSaveNote={onSaveNote}
        onToggleNoteShare={onToggleNoteShare}
        onUnshare={setUnSharingNoteId}
        selectedNote={selectedNote}
      />
      <DeleteNoteDialog
        noteId={deletingNoteId}
        onClose={() => setDeletingNoteId('')}
        onDelete={onDeleteNote}
      />
      <UnshareNoteDialog
        noteId={unSharingNoteId}
        onClose={() => setUnSharingNoteId('')}
        onUnshare={onToggleNoteShare}
      />
    </>
  );
};

export default AssistNotesPage;
