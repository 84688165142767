import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { updateActionSettings } from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { validateCustomizationData } from '../handoff-configuration/jira/helpers';
import JiraServiceManagementCreateRequestConfiguration from '../handoff-configuration/jira/JiraServiceManagementCreateRequestConfiguration';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { hasAtLeastOneError } from 'src/pages/workflow-builder-edit/handoff-configuration/helpers';
import {
  selectCanvasIsLoadingActionCustomization,
  selectCanvasWorkflowBuilder,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { JiraServiceManagementCreateRequestSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

export interface CustomFieldDict {
  [key: string]: string | number | boolean | null;
}

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const JiraServiceManagementCreateRequestCustomizationPanel: FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const actionSettings = canvasState?.actionSettings;
  const isLoadingActionCustomization = useSelector(
    selectCanvasIsLoadingActionCustomization,
  );

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const handoffCustomizationSettings =
    actionSettings.configuration_fields as JiraServiceManagementCreateRequestSettings;
  const [customizationData, setCustomizationData] =
    useState<JiraServiceManagementCreateRequestSettings>(
      handoffCustomizationSettings,
    );

  const errorMessages = validateCustomizationData({
    allowableContextVariables: contextVariables,
    customizationData: customizationData,
  });

  const isPristine = isEqual(customizationData, handoffCustomizationSettings);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!isLoadingActionCustomization) {
      setCustomizationData(handoffCustomizationSettings);
    }
  }, [isLoadingActionCustomization, handoffCustomizationSettings]);

  let isDisableSaveFromForm = false;

  if (customizationData) {
    if (isPristine) {
      isDisableSaveFromForm = true;
    } else if (customizationData?.other_request_fields) {
      isDisableSaveFromForm = hasAtLeastOneError(errorMessages);
    }
  }

  if (
    isLoadingActionCustomization ||
    !handoffCustomizationSettings ||
    !customizationData
  ) {
    return <LoadingSkeleton />;
  }

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <JiraServiceManagementCreateRequestConfiguration
        customizationData={customizationData}
        errorMessages={errorMessages}
        setCustomizationData={setCustomizationData}
      />
      <SaveContainer hasTopBorder>
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isDisableSaveFromForm || hasWorkflowConflict}
          onClick={() => {
            dispatch(
              updateActionSettings({
                action_type: actionSettings.action_type as string,
                configuration_fields: customizationData,
              }),
            );
            dispatchActionEditedTrackingEvent({
              actionType:
                ActionBuilderActionTypes.JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </SaveContainer>
    </Box>
  );
};

export default JiraServiceManagementCreateRequestCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
