import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  datePickerRangeOptions,
  DISCOVER_SEARCH_PARAMS_LOCAL_STORAGE_KEY,
  DISCOVER_SHARED_PARAM_NAMES,
  initialDateRangeValue,
} from 'src/constants/discover';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
} from 'src/utils/discover/helpers';

const replaceTimestampInUrl = (search: string) => {
  const params = new URLSearchParams(search);

  const dateRangeUrlParam = params.get(DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER);

  if (dateRangeUrlParam) {
    const timeFilter = dateRangeToTimeFilter(
      dateRangeDeserialize(dateRangeUrlParam),
    );

    if (!timeFilter.isCustom) {
      params.set(DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER, timeFilter.key);
    }
  }

  return params;
};

const replaceTimeFilterKeyInUrl = (search: string) => {
  const params = new URLSearchParams(search);

  const dateString = params.get(DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER);
  const nonCustomDateRangeKeys = new Set<string>(
    datePickerRangeOptions.map(option => option.key),
  );

  if (dateString && nonCustomDateRangeKeys.has(dateString)) {
    params.set(
      DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
      dateRangeSerialize(
        datePickerRangeOptions.find(item => item.key === dateString)?.value ??
          initialDateRangeValue,
      ),
    );
  }

  return params;
};

export const usePersistSearchParams = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (search) {
      window.localStorage.setItem(
        DISCOVER_SEARCH_PARAMS_LOCAL_STORAGE_KEY,
        replaceTimestampInUrl(search).toString(),
      );

      return;
    }

    navigate(
      {
        search: replaceTimeFilterKeyInUrl(
          window.localStorage.getItem(
            DISCOVER_SEARCH_PARAMS_LOCAL_STORAGE_KEY,
          ) ?? '',
        ).toString(),
      },
      { replace: true },
    );
  }, [search, navigate]);
};
