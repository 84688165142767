import React from 'react';
import Box from '@mui/material/Box';

const StepContainer: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{
      hasGradientBorder?: boolean;
      isBeingEdited?: boolean;
      paddingTop?: string;
    }>
  >
> = ({
  children,
  hasGradientBorder = false,
  isBeingEdited = false,
  paddingTop = 2.5,
}) => {
  return (
    <Box
      sx={theme => ({
        '&:hover': {
          borderColor: theme.palette.colors.grey[300],
        },
        border: isBeingEdited
          ? '1px solid ' + theme.palette.colors.purple[500]
          : '1px solid transparent',
        borderRadius: '8px',
        flex: 1,
        paddingBottom: 2.5,
        paddingTop,
        pl: 7.5,
        pointerEvents: isBeingEdited ? 'none' : undefined,
        pr: 7.5,
      })}
    >
      <Box
        sx={theme => ({
          bgcolor: theme.palette.background.paper,
          border: hasGradientBorder
            ? undefined
            : `1px solid ${theme.palette.colors.slate[200]}`,
          borderRadius: 'inherit',
          p: 2,
          ...(hasGradientBorder && {
            background:
              'linear-gradient(white, white) padding-box, var(--gradient-blue-purple) border-box',
            border: '1px solid transparent',
          }),
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

export default StepContainer;
