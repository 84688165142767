import { useMemo } from 'react';

import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { getWorkflowContextVariables } from 'src/utils/actionBuilder/helpers';
import { flattenDynamicContextVariables } from 'src/utils/solve/dynamicContextVariableUtils';

export const useGetRemirrorContextVariables = () => {
  const { contextVariables } = useGetContextVariables();

  const workflowContextVariables =
    getWorkflowContextVariables(contextVariables);

  const remirrorContextVariables = useMemo(() => {
    const flattened = flattenDynamicContextVariables(workflowContextVariables);
    return flattened.map(cv => ({
      ...cv,
      isDefined: true,
    }));
  }, [workflowContextVariables]);

  return remirrorContextVariables;
};
