import { useState } from 'react';
import { Box, useTheme } from '@mui/material';

import { ChatTrendsPeriodicalFilter, OverviewOvertimePeriod } from '../types';
import ChatComparisonSection from './ChatComparisonSection';
import ChatPerformanceSection from './ChatPerformanceSection';
import ChatTrendsSection from './ChatTrendsSection';

const SolveInsightsOverview = () => {
  const { palette } = useTheme();

  const [periodicalFilter, setPeriodicalFilter] =
    useState<ChatTrendsPeriodicalFilter>('weekly');

  const [chatComparisonPeriodicalFilter, setChatComparisonPeriodicalFilter] =
    useState<OverviewOvertimePeriod>('weekly');

  return (
    <Box bgcolor={palette.colors.white} height='100%'>
      <Box
        bgcolor={palette.colors.white}
        display='flex'
        flexDirection='column'
        gap={5}
        padding='24px 40px'
      >
        <ChatPerformanceSection />
        <ChatTrendsSection
          periodicalFilter={periodicalFilter}
          setPeriodicalFilter={setPeriodicalFilter}
        />
        <ChatComparisonSection
          periodicalFilter={chatComparisonPeriodicalFilter}
          setPeriodicalFilter={setChatComparisonPeriodicalFilter}
        />
      </Box>
    </Box>
  );
};

export default SolveInsightsOverview;
