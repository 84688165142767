import React from 'react';
import { Box } from '@mui/material';

import {
  Button,
  Dialog,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DIALOG_TITLE } from './constants';

interface PlanConfirmationModalProps {
  isDialogVisible: boolean;
  isLoading: boolean;
  label: string;
  onChange: (text: string) => void;
  onClose: () => void;
  onSend: () => void;
  value: string;
}

const PlanConfirmationModal: React.FC<PlanConfirmationModalProps> = ({
  isDialogVisible,
  isLoading,
  label,
  onChange,
  onClose,
  onSend,
  value,
}: PlanConfirmationModalProps) => {
  return (
    <Dialog
      footer={
        <>
          <Button disabled={isLoading} onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button isLoading={isLoading} onClick={onSend} variant='secondary'>
            Send
          </Button>
        </>
      }
      onClose={onClose}
      open={isDialogVisible}
      title={DIALOG_TITLE}
    >
      <Box display='flex' flexDirection='column' gap='16px' width='325px'>
        <Typography variant='font14'>{label}</Typography>
        <TextField
          aria-label='Insert upgrade plan description'
          multiline
          onChange={e => onChange(e.target.value)}
          value={value}
        />
      </Box>
    </Dialog>
  );
};

export default PlanConfirmationModal;
