import { Box } from '@mui/material';
import { ArrayFieldDescriptionProps } from '@rjsf/utils';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function ArrayFieldDescriptionTemplate({
  description,
}: ArrayFieldDescriptionProps) {
  return description ? (
    <Box marginTop='5px'>
      <Typography component='h6' variant='font14Medium'>
        {description}
      </Typography>
    </Box>
  ) : null;
}
