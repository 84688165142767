import { useState } from 'react';
import { Form, Formik } from 'formik';
import { DialogActions, DialogContent, styled } from '@mui/material';

import {
  Button,
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { publishArticle } from 'src/actions/discover/discover';
import BaseModal from 'src/components/base-modal';
import { useGetKnowledgeBaseSections } from 'src/hooks/discover/useGetKnowledgeBaseSections';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { ArticleIntegrations } from 'src/reducers/discoverReducer/types';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
interface ArticlePublishModalProps {
  articleId: string | null;
  integrations: ArticleIntegrations | null;
  isOpen: boolean;
  onClose: () => void;
}

const ArticlePublishModal = ({
  articleId,
  integrations,
  isOpen,
  onClose,
}: ArticlePublishModalProps) => {
  const dispatch = useAppDispatch();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { data } = useGetKnowledgeBaseSections();
  const [isPublishing, setIsPublishing] = useState(false);

  if (!articleId) {
    return null;
  }

  const knowledgeBaseSections = data?.sections ?? [];

  return (
    <BaseModal
      featureName='articlePublishModal'
      headerTitle={
        <Typography variant='font18Bold'>
          Select a destination to upload
        </Typography>
      }
      isOpen={isOpen}
      maxWidth='sm'
      onClose={onClose}
    >
      <Formik
        initialValues={{
          integration: integrations?.connected_helpdesks[0]?.name || '',
          section_id: '',
        }}
        onSubmit={async ({ integration, section_id }) => {
          setIsPublishing(true);

          try {
            await dispatch(
              publishArticle({
                articleId,
                is_draft: true,
                section_id: section_id || null,
              }),
            ).unwrap();

            const sectionName = knowledgeBaseSections.find(
              section => section.section_id === section_id,
            )?.section_name;
            const integrationName = integrations?.connected_helpdesks.find(
              helpdesk => helpdesk.name === integration,
            )?.display_name;

            dispatch(
              setGlobalToastOptions({
                autoHideDuration: 3000,
                subtitle:
                  'You can add all the finishing touches and publish it from there.',
                title: `Article draft uploaded successfully in ${integrationName} ${
                  sectionName ? ` under ${sectionName}` : ''
                }.`,
                variant: 'main',
              }),
            );
            emitTrackingEventCallback('discover-article-published', {
              article_id: articleId,
              is_draft: true,
              section_id,
            });
          } catch (error) {
            dispatch(
              setGlobalToastOptions({
                autoHideDuration: 3000,
                title: 'Unable to publish this article.',
                variant: 'danger',
              }),
            );
            console.error(error);
          }

          setIsPublishing(false);
          onClose();
        }}
      >
        {({ setFieldValue, values }) => {
          const selectedIntegration = integrations?.connected_helpdesks.find(
            integration => values.integration === integration.name,
          );
          const integrationIconSrc = selectedIntegration?.avatar || '';
          return (
            <Form translate={undefined}>
              <DialogContent
                sx={{
                  px: 3,
                  py: 0,
                }}
              >
                {integrations?.connected_helpdesks?.length ? (
                  <SelectDropdown
                    helperText='*You can edit this article in your knowledge center after uploading'
                    id='integrations-dropdown'
                    label='Integration'
                    onChange={e => setFieldValue('integration', e.target.value)}
                    options={integrations.connected_helpdesks.map(
                      integration => ({
                        label: integration.display_name,
                        optionStartAdornment: integration.avatar ? (
                          <IntegrationIcon src={integration.avatar} />
                        ) : undefined,
                        value: integration.name,
                      }),
                    )}
                    placeholder='Select an integration'
                    startAdornment={
                      integrationIconSrc ? (
                        <IntegrationIcon src={integrationIconSrc} />
                      ) : undefined
                    }
                    value={values.integration}
                  />
                ) : null}
                {knowledgeBaseSections.length ? (
                  <SelectDropdown
                    id='sections-dropdown'
                    label='Section'
                    onChange={e => setFieldValue('section_id', e.target.value)}
                    options={knowledgeBaseSections.map(section => ({
                      label: section.section_name,
                      value: section.section_id,
                    }))}
                    placeholder='Select a section'
                    value={values.section_id}
                  />
                ) : null}
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Button
                  disabled={
                    !Boolean(integrations?.connected_helpdesks?.length) ||
                    !values.integration.length ||
                    isPublishing
                  }
                  isLoading={isPublishing}
                  type='submit'
                  variant='secondary'
                >
                  Upload as a draft
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </BaseModal>
  );
};

const IntegrationIcon = styled('img')`
  height: 20px;
  width: 20px;
  margin-right: 4px;
`;

export default ArticlePublishModal;
