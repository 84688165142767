import React from 'react';
import { Link } from 'react-router-dom';

import { Action } from 'src/types/actionBuilderApiTypes';
import { replaceActionIdInRoute } from 'src/utils/actionBuilder/helpers';
import { ActionBuilderActionTypes, StepTypes } from 'src/utils/enums';
import {
  isArticleSuggestion,
  isSetContextVariableAction,
} from 'src/utils/solve/stepUtils';

export const linkifyActionName = (node: React.ReactNode, action: Action) => {
  if (action.action_type === ActionBuilderActionTypes.API) {
    return (
      <Link target='_blank' to={replaceActionIdInRoute(action.action_id)}>
        {node}
      </Link>
    );
  }

  return node;
};

export const extractCvIdFromDynamicCv = (anyCv: string) => {
  return anyCv.split('/./')?.[0] ?? anyCv;
};

export const getStepTypeFromActionType = (
  actionType: Action['action_type'],
) => {
  if (isArticleSuggestion(actionType)) {
    return StepTypes.ARTICLE_SUGGESTION;
  }

  if (isSetContextVariableAction(actionType)) {
    return StepTypes.SET_CONTEXT_VARIABLE;
  }

  const actionTypeToStepType: Partial<
    Record<Action['action_type'], StepTypes>
  > = {
    dynamic_card: StepTypes.DYNAMIC_CARD,
    end_interactive_email_chat: StepTypes.END_INTERACTIVE_EMAIL_CHAT,
    sunco_live_chat: StepTypes.SUNCO_LIVE_CHAT,
  };

  const stepType = actionTypeToStepType[actionType];
  return stepType ?? StepTypes.FLAMETHROWER_API_CALL;
};
