import { Typography } from '@forethought-technologies/forethought-elements';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

export function BooleanCell({ value }: { value: boolean }) {
  return (
    <Typography variant='font14'>
      {capitalizeFirstLetter(value.toString())}
    </Typography>
  );
}
