import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import DiscoverSentimentEmoji from 'src/components/discover-sentiment-emoji';
import { getSentimentEmojiVariantFromValue } from 'src/utils/discover/helpers';

const SentimentValue = ({
  isNormalFont,
  value,
}: {
  isNormalFont: boolean;
  value: number;
}) => {
  const variant = isNormalFont ? 'font16' : 'font16Medium';

  return (
    <Box alignItems='center' display='flex'>
      <Typography variant={variant}>{value}</Typography>
      <DiscoverSentimentEmoji
        ml={1}
        size='medium'
        variant={getSentimentEmojiVariantFromValue(value)}
      />
    </Box>
  );
};

export default SentimentValue;
