import { Formik, useField } from 'formik';
import Box from '@mui/material/Box';
import { StandardTextFieldProps } from '@mui/material/TextField';

import {
  Button,
  TextField,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

const TriagePredictionDemoWidget = ({
  getPredictions,
  isSelfServe = false,
  modelName,
}: {
  getPredictions: (text: string) => void;
  isSelfServe?: boolean;
  modelName: string;
}) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  return (
    <>
      <Box mb='4px'>
        <Typography component='h3' variant='font18Bold'>
          Test classifier with your own text
        </Typography>
      </Box>
      <Box mb='16px'>
        <Typography color={theme.palette.colors.grey[600]} variant='font14'>
          Paste customer requests to see what this classifier outputs
        </Typography>
      </Box>
      <Formik
        initialValues={{ text: '' }}
        onSubmit={({ text }) => getPredictions(text)}
      >
        {({ handleSubmit, values }) => {
          return (
            <>
              <FormikTextField
                name='text'
                placeholder='E.g. I need help with this item'
              />
              <Box sx={{ mb: 2 }} />
              <Button
                disabled={values.text.trim() === ''}
                onClick={() => {
                  // both events here come from different times.
                  // the triagellm event is from self serve, and the other
                  // is from the generic models
                  if (isSelfServe) {
                    emitTrackingEventCallback(
                      TRIAGE_LLM_TRACKING_EVENTS.TEST_USED,
                      {
                        model_name: modelName,
                        scope: 'self-serve',
                      },
                    );
                  } else {
                    emitTrackingEventCallback(
                      'triage-clicked-demo-widget-submit-button',
                      { model_name: modelName, scope: 'self-serve' },
                    );
                  }

                  handleSubmit();
                }}
                type='submit'
                variant='secondary'
              >
                Classify text
              </Button>
            </>
          );
        }}
      </Formik>
    </>
  );
};

interface FormikInputProps extends Omit<StandardTextFieldProps, 'label'> {
  name: string;
}

const FormikTextField = ({ name, ...props }: FormikInputProps) => {
  const [field, , { setValue }] = useField({ name });

  return (
    <TextField
      aria-label='demo-widget'
      multiline
      onChange={e => {
        setValue(e.target.value);
      }}
      rows={5}
      value={field.value}
      {...props}
    />
  );
};

export default TriagePredictionDemoWidget;
