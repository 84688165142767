import { Box, useTheme } from '@mui/material';

import { Skeleton } from '@forethought-technologies/forethought-elements';

const LoadingSkeletonCard = () => {
  const { palette } = useTheme();
  return (
    <Box
      aria-busy
      sx={{
        background: palette.colors.white,
        border: `1px solid ${palette.colors.slate[200]}`,
        borderRadius: '8px',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        gap: '24px',
        padding: '26px',
      }}
    >
      <Skeleton width='40%' />
      <Skeleton width='80%' />
      <Skeleton width='60%' />
      <Skeleton width='30%' />
    </Box>
  );
};

export default LoadingSkeletonCard;
