import React from 'react';
import linkifyHtml from 'linkify-html';
import sanitizeHtml from 'sanitize-html';
import { Box } from '@mui/material';

interface HtmlComponentProps {
  content: string;
  linkifyHtmlOptions?: Parameters<typeof linkifyHtml>[1];
}

const HtmlComponent: React.FC<React.PropsWithChildren<HtmlComponentProps>> = ({
  content,
  linkifyHtmlOptions,
}) => {
  const processedHtml = sanitizeHtml(linkifyHtml(content, linkifyHtmlOptions), {
    allowedAttributes: {
      a: ['href', 'name', 'target', 'style'],
      img: ['src'],
      span: ['style'],
    },
    allowedSchemesByTag: {
      img: ['data'],
    },
    allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img'],
  });
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: processedHtml }}
      sx={{
        '& > p:empty': {
          height: '20px',
        },
      }}
    />
  );
};

export default HtmlComponent;
