import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import {
  Button,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getIsModelPremium, premiumDisabledTooltipText } from '../helpers';
import EditIconDisabled from 'src/assets/images/edit-pencil-disabled-icon.svg';
import EditIcon from 'src/assets/images/edit-pencil-icon.svg';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  selectFocusedTriageModel,
  selectHasMinimalPermission,
  selectIntegrationSelectedState,
  selectIsHelpdeskNotConfigured,
  selectIsHelpdeskSupported,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';

interface TriageConfigDetailSettingHeaderProps {
  handleCancelOnEditMode: () => void;
  handleOnSaveToUi: () => void;
  isDisabled: boolean;
  isEditing: boolean;
  setIsEditing: (flag: boolean) => void;
}

const TriageConfigDetailSettingHeader = (
  props: TriageConfigDetailSettingHeaderProps,
) => {
  const {
    handleCancelOnEditMode,
    handleOnSaveToUi,
    isDisabled,
    isEditing,
    setIsEditing,
  } = props;

  const triageModel = useSelector(selectFocusedTriageModel);
  const integrationSelectedState = useSelector(selectIntegrationSelectedState);
  const isHelpdeskNotConfigured = useSelector(selectIsHelpdeskNotConfigured);
  const isHelpdeskSupported = useSelector(selectIsHelpdeskSupported);
  const hasMinimalPermission = useSelector(selectHasMinimalPermission);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const isIntegrationNotSelected = integrationSelectedState === 'not-selected';
  const isIntegrationSelectedLoading = integrationSelectedState === 'loading';

  const renderButtonComponents = () => {
    if (
      isHelpdeskNotConfigured ||
      !isHelpdeskSupported ||
      !hasMinimalPermission
    ) {
      return null;
    }

    if (isEditing) {
      return (
        <EditContainer>
          <Button
            aria-label='cancel'
            onClick={() => {
              emitTrackingEventCallback(
                'triage-clicked-cancel-prediction-config-button',
                { scope: 'self-serve' },
              );

              handleCancelOnEditMode();
            }}
            variant='ghost'
          >
            Cancel
          </Button>
          <Button
            aria-label='save'
            disabled={isDisabled || isIntegrationSelectedLoading}
            isLoading={isIntegrationSelectedLoading}
            onClick={handleOnSaveToUi}
            variant='main'
          >
            Save
          </Button>
        </EditContainer>
      );
    }

    if (isIntegrationNotSelected) {
      return null;
    }

    if (getIsModelPremium(triageModel?.model_paid_plan)) {
      return (
        <Tooltip tooltipContent={premiumDisabledTooltipText}>
          <div>
            <Button
              aria-label='edit'
              disabled
              startIcon={<Icon src={EditIconDisabled} />}
              variant='main'
            >
              Edit
            </Button>
          </div>
        </Tooltip>
      );
    }

    return (
      <Button
        aria-label='edit'
        isLoading={isIntegrationSelectedLoading}
        onClick={() => setIsEditing(true)}
        startIcon={<Icon src={EditIcon} />}
        variant='main'
      >
        Edit
      </Button>
    );
  };

  return (
    <HeaderContainer>
      <Typography color={theme.palette.colors.black} variant='font18Bold'>
        Helpdesk integration
      </Typography>
      {renderButtonComponents()}
    </HeaderContainer>
  );
};

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  min-height: 32px;
`;

const EditContainer = styled('div')`
  display: flex;
  gap: 10px;
`;

const Icon = styled('img')`
  height: 20px;
  width: 20px;
  padding-right: 5px;
`;

export default TriageConfigDetailSettingHeader;
