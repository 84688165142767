import { createSlice } from '@reduxjs/toolkit';

import { createUser, deleteUser, getUsers, updateUser } from './thunks';
import { TeamSliceState } from './types';
import partition from 'lodash/fp/partition';
import type { RootState } from 'src/store/rootReducer';

const initialState: TeamSliceState = {
  invites: {
    data: null,
    error: false,
    loading: false,
  },
  users: {
    data: null,
    error: false,
    loading: false,
  },
};

const teamSlice = createSlice({
  extraReducers: builder => {
    /**
     * getUsers
     */
    builder.addCase(getUsers.fulfilled, (state, action) => {
      // Separate confirmed and unconfirmed users
      const [users, invites] = partition(user => {
        return (
          user.status &&
          ['CONFIRMED', 'EXTERNAL_PROVIDER'].includes(user.status)
        );
      }, action.payload);

      state.users.data = users;
      state.invites.data = invites;
      state.users.loading = false;
      state.invites.loading = false;
    });
    builder.addCase(getUsers.pending, state => {
      state.users.error = false;
      state.invites.error = false;
      state.users.loading = true;
      state.invites.loading = true;
    });
    builder.addCase(getUsers.rejected, state => {
      state.users.error = true;
      state.invites.error = true;
      state.users.loading = false;
      state.invites.loading = false;
    });
    /**
     * createUser
     */
    builder.addCase(createUser.fulfilled, (state, action) => {
      const user = action.payload;
      const invites = state.invites.data || [];
      state.invites.data = [user, ...invites];
      state.invites.loading = false;
      state.invites.error = false;
    });
    builder.addCase(createUser.pending, state => {
      state.invites.loading = true;
      state.invites.error = false;
    });
    builder.addCase(createUser.rejected, state => {
      state.invites.error = true;
      state.invites.loading = false;
    });
    /**
     * deleteUser
     */
    builder.addCase(deleteUser.fulfilled, ({ invites, users }, action) => {
      const userId = action.meta.arg;
      if (invites.data) {
        invites.data = invites.data.filter(user => user.user_id !== userId);
      }
      if (users.data) {
        users.data = users.data.filter(user => user.user_id !== userId);
      }
      invites.loading = false;
      users.loading = false;
    });
    builder.addCase(deleteUser.pending, ({ invites, users }) => {
      invites.loading = true;
      users.loading = true;
      invites.error = false;
      users.error = false;
    });
    builder.addCase(deleteUser.rejected, ({ invites, users }) => {
      invites.error = true;
      users.error = true;
      invites.loading = false;
      users.loading = false;
    });
    /**
     * updateUser
     */
    builder.addCase(updateUser.fulfilled, ({ invites, users }, action) => {
      const updatedUser = action.payload;
      if (users.data) {
        const idx = users.data.findIndex(
          user => user.user_id === updatedUser.user_id,
        );
        users.data[idx] = updatedUser;
      }
      invites.loading = false;
      users.loading = false;
    });
    builder.addCase(updateUser.pending, ({ users }) => {
      users.loading = true;
      users.error = false;
    });
    builder.addCase(updateUser.rejected, ({ users }) => {
      users.error = true;
      users.loading = false;
    });
  },
  initialState,
  name: 'team',
  reducers: {},
});

export const selectUsers = (state: RootState): TeamSliceState['users'] =>
  state.team.users;

export const selectInvites = (state: RootState): TeamSliceState['invites'] =>
  state.team.invites;

export default teamSlice.reducer;
