import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import { CognitoUserRole } from 'src/services/apiInterfaces';

export type SelectDropdownProps = Parameters<typeof SelectDropdown>[0];

type SelectOption = SelectDropdownProps['options'][0];

type PublicCognitoUserRole = Exclude<CognitoUserRole, 'SUPER_ADMIN'>;

export const permissionOptions: Record<PublicCognitoUserRole, SelectOption> = {
  ADMIN: {
    description:
      'Can manage the org permissions, integrations and assign admin rights',
    label: 'Admin',
    value: 'ADMIN',
  },
  AGENT: {
    description: 'Can access Assist Anywhere only',
    label: 'Agent',
    value: 'AGENT',
  },
  USER: {
    description: 'Can access dashboard and view product analytics',
    label: 'User',
    value: 'USER',
  },
};

export const rolesByPriority: PublicCognitoUserRole[] = [
  'AGENT',
  'USER',
  'ADMIN',
];

export function getAuthorizedOptions(requesterUserRole: CognitoUserRole) {
  if (requesterUserRole === 'SUPER_ADMIN') {
    return rolesByPriority.map(role => permissionOptions[role]);
  }

  const roles = rolesByPriority.slice(
    0,
    rolesByPriority.indexOf(requesterUserRole) + 1,
  );
  return roles.map(role => permissionOptions[role]);
}
