import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { captureException } from '@sentry/react';

import { Toast } from '@forethought-technologies/forethought-elements';
import {
  resetConnectorWithAction,
  selectAutomations,
  setAutomationAction,
  setToastMessage,
} from '../../slices/assist-automations/assistAutomationsSlice';
import {
  deleteAssistAutomation,
  fetchAssistAutomations,
  fetchConnector,
  updateAssistAutomation,
} from '../../slices/assist-automations/thunks';
import { initialState, validate } from '../assist-automations-form/helpers';
import { serializeActionInputFormatter } from '../assist-automations-form/run-action-card/input-card/helpers';
import ActionRow from './action-row';
import DeleteAssistAutomationModal from './delete-assist-automation-modal';
import { AssistAutomationEditProps } from './types';
import cloneDeep from 'lodash/fp/cloneDeep';
import AssistAutomationsForm from 'src/components/assist-automations-form';
import AssistAutomationsPreview from 'src/components/assist-automations-preview';
import { selectTimeRange } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { selectUser } from 'src/reducers/userReducer/userReducer';
import { Connector } from 'src/services/apiInterfaces';
import * as API from 'src/services/apiV1';
import {
  automationTemplate,
  automationTemplateConnector,
  automationTemplateCreatedConnectors,
} from 'src/slices/assist-automations/mockData';
import { AutomationForm } from 'src/slices/assist-automations/types';
import { useAppDispatch } from 'src/store/hooks';
import { Permission, Routes } from 'src/utils/enums';
import { sendTrackingEvents } from 'src/utils/sendTrackingEvent';

const AssistAutomationsEdit: React.FC<
  React.PropsWithChildren<AssistAutomationEditProps>
> = ({ automationId, automations }) => {
  const dispatch = useAppDispatch();
  const { action, connector, toastMessage } = useSelector(selectAutomations);
  const navigate = useNavigate();
  const timeRange = useSelector(selectTimeRange);
  const user = useSelector(selectUser);
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState(initialState);
  const [createdConnectors, setCreatedConnectors] = useState<Connector[]>([]);
  const userEmail = user?.user?.email || '';
  const application = Permission.ASSIST_AUTOMATIONS;
  const isReadOnly = Boolean(
    automationId && automationId === automationTemplate.automation_id,
  );

  const handleSaveAutomationClick = async (values: Partial<AutomationForm>) => {
    if (automationId) {
      sendTrackingEvents(
        dispatch,
        'automation-builder-save',
        timeRange,
        application,
        {
          automation_description: values?.description,
          automation_title: values?.title,
          connector_used: connector?.connector_id,
          edited_by: userEmail,
          internal_text_field_used:
            values?.internal_text_field !== undefined ? 'Yes' : 'No',
          name_of_api: 'Update Automation',
          org_id: values?.org_id,
        },
      );

      const currentConnector = createdConnectors.find(
        connectorItem => connectorItem.connector_id === connector?.connector_id,
      );

      let reqData = cloneDeep(values);

      if (action && reqData.actions_input_formatter) {
        reqData.actions_input_formatter[action.action_id] =
          serializeActionInputFormatter(
            reqData.actions_input_formatter,
            action,
          );
      }

      if (currentConnector) {
        reqData = {
          ...reqData,
          emblem_urls: [currentConnector.connector_definition.avatar],
        };
      }

      dispatch(
        updateAssistAutomation({
          automationDetailsToUpdate: reqData,
          automationId,
        }),
      ).then(() => {
        dispatch(fetchAssistAutomations());
      });
    } else {
      sendTrackingEvents(
        dispatch,
        'automation-builder-create',
        timeRange,
        application,
        {
          automation_description: values?.description,
          automation_title: values?.title,
          connector_used: connector?.connector_id,
          edited_by: userEmail,
          internal_text_field_used:
            values?.internal_text_field !== undefined ? 'Yes' : 'No',
          name_of_api: 'Create Automation',
          org_id: values?.org_id,
        },
      );

      try {
        let reqData = cloneDeep(values);

        reqData = {
          ...reqData,
          internal_text_field: values.internal_text_field || '',
        };

        if (action && reqData.actions_input_formatter) {
          reqData.actions_input_formatter[action.action_id] =
            serializeActionInputFormatter(
              reqData.actions_input_formatter,
              action,
            );
        }

        setIsCreateLoading(true);
        // Without redux as should be redirect

        const data = await API.createAssistAutomation(reqData);

        setIsCreateLoading(false);

        dispatch(
          setToastMessage({
            show: true,
            title: 'Automation has been created',
            variant: 'main',
          }),
        );

        navigate({
          pathname: Routes.ASSIST_AUTOMATIONS,
          search: `?automation=${data.automation_id}`,
        });
      } catch (err) {
        if (err instanceof Error) {
          captureException(new Error(`Error: ${err.message || err}`));
        }

        dispatch(
          setToastMessage({
            show: true,
            title: 'Something went wrong',
            variant: 'danger',
          }),
        );
      }
    }
  };

  const onDeleteAssistAutomation = () => {
    if (automationId) {
      dispatch(deleteAssistAutomation(automationId)).then(() => {
        navigate({
          pathname: Routes.ASSIST_AUTOMATIONS,
        });
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        const createdConnectorsData = isReadOnly
          ? automationTemplateCreatedConnectors
          : await API.getCreatedConnectors();

        setCreatedConnectors(
          createdConnectorsData.filter(connector =>
            connector.connector_types?.includes('action'),
          ),
        );

        if (automationId) {
          const foundAutomation = automations?.find(
            automation => automation.automation_id === automationId,
          );

          if (foundAutomation?.action_list.length) {
            const currentConnector = isReadOnly
              ? automationTemplateConnector
              : await API.getConnectorById(
                  foundAutomation.action_list[0].connector_id,
                );

            if (currentConnector) {
              const _action =
                (currentConnector.actions &&
                  currentConnector.actions.find(
                    connectorAction =>
                      connectorAction.action_id ===
                      foundAutomation.action_list[0].action_id,
                  )) ||
                null;

              dispatch(
                fetchConnector(foundAutomation.action_list[0].connector_id),
              );

              if (_action) {
                dispatch(
                  setAutomationAction({
                    action: _action,
                    connector: foundAutomation.action_list[0].connector_id,
                  }),
                );
              }
            }
          }

          if (foundAutomation) {
            setInitialFormValues(foundAutomation);
          }
        } else {
          dispatch(resetConnectorWithAction());
          setInitialFormValues(initialState);
        }
      } catch (err) {
        if (err instanceof Error) {
          captureException(new Error(`Error: ${err.message || err}`));
        }
      }
    };

    init();
  }, [automationId, automations, dispatch, isReadOnly]);

  return (
    <Layout>
      <ToastContainer>
        <Toast
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={toastMessage.autoHideDuration}
          onClose={() =>
            dispatch(
              setToastMessage({ ...toastMessage, show: false, title: '' }),
            )
          }
          open={toastMessage.show}
          title={toastMessage.title}
          variant={toastMessage.variant}
          width='416px'
        />
      </ToastContainer>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={handleSaveAutomationClick}
        validate={values => validate(values, action)}
      >
        <Form translate='yes'>
          <ActionRow
            automationId={automationId}
            isCreateLoading={isCreateLoading}
            isReadOnly={isReadOnly}
          />
          <Content>
            <LeftColumn>
              <AssistAutomationsForm isReadOnly={isReadOnly} />
            </LeftColumn>
            <RightColumn>
              <AssistAutomationsPreview isReadOnly={isReadOnly} />
            </RightColumn>
          </Content>
        </Form>
      </Formik>
      {automationId && (
        <DeleteAssistAutomationModal onDelete={onDeleteAssistAutomation} />
      )}
    </Layout>
  );
};

const Layout = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 15px 30px 20px 40px;
`;

const Content = styled('div')`
  display: flex;
  height: 100%;
`;

const LeftColumn = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-right: 40px;
  min-width: 350px;
  width: 60%;
`;

const RightColumn = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 250px;
  width: 40%;
`;

const ToastContainer = styled('div')`
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
`;

export default AssistAutomationsEdit;
