import { ChangeEvent, KeyboardEvent } from 'react';
import { useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import {
  IconButton,
  TextField,
} from '@forethought-technologies/forethought-elements';
import isEmpty from 'lodash/isEmpty';

interface MultiTextFieldProps {
  addValue: string;
  description?: string;
  disabled?: boolean;
  error?: string | string[];
  label?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  setAddValue: (value: string) => void;
  setValues: (values: string[]) => void;
  values: string[];
}

const MultiTextField = ({
  addValue,
  description,
  disabled = false,
  error,
  label,
  onBlur,
  setAddValue,
  setValues,
  values,
}: MultiTextFieldProps) => {
  const { palette } = useTheme();

  const handleOnAddChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAddValue(event.currentTarget.value);
  };

  const addPhrase = () => {
    if (!addValue) {
      return;
    }
    setValues([addValue].concat(values));
    setAddValue('');
  };

  const handleOnItemChange = (editValue: string, index: number) => {
    const newValues = values.map((item, i) => {
      if (i === index) {
        return editValue;
      }
      return item;
    });
    setValues(newValues);
  };

  const handleRemoveItem = (index: number) => {
    const newValues = values.filter((_, i) => i !== index);
    setValues(newValues);
  };

  const hasLabel = Boolean(label);
  const hasDescription = Boolean(description);
  const hasError = typeof error === 'string';

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Box display='flex' flexDirection='column'>
        {hasLabel && <Typography variant='font16Bold'>{label}</Typography>}
        {hasDescription && (
          <Typography color={palette.colors.grey[600]} variant='font12'>
            {description}
          </Typography>
        )}
        {hasError && (
          <Typography color={palette.colors.red[500]} variant='font12'>
            {error}
          </Typography>
        )}
      </Box>
      {!disabled && (
        <Box
          alignItems='center'
          columnGap={1}
          display='grid'
          gridTemplateColumns='1fr auto'
        >
          <TextField
            aria-label='Add Phrase'
            disabled={disabled}
            onBlur={onBlur}
            onChange={handleOnAddChange}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key !== 'Enter') {
                return;
              }
              e.preventDefault();
              if (!isEmpty(addValue.trim())) {
                addPhrase();
              }
            }}
            placeholder={"Type a training phrase and press 'Enter'"}
            required={false}
            value={addValue}
          />
          <IconButton
            aria-label='Add'
            disabled={isEmpty(addValue.trim())}
            onClick={() => addPhrase()}
            variant='ghost'
          >
            <IconPlus color={palette.colors.grey[400]} />
          </IconButton>
        </Box>
      )}
      {values.map((item, index) => (
        <Box
          alignItems='center'
          columnGap={1}
          display='grid'
          gridTemplateColumns='1fr auto'
          key={index}
        >
          <TextField
            aria-label='Edit Phrase'
            disabled={disabled}
            onBlur={onBlur}
            onChange={event =>
              handleOnItemChange(event.currentTarget.value, index)
            }
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            placeholder={"Type a training phrase and press 'Enter'"}
            value={item}
          />
          {!disabled && (
            <IconButton
              aria-label='remove'
              onClick={() => handleRemoveItem(index)}
              variant='ghost'
            >
              <IconTrash />
            </IconButton>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default MultiTextField;
