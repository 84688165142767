/* eslint-disable react/display-name, react/prop-types*/
import React, { CSSProperties, FC } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import Article from './Article';
import Spinner from 'src/components/spinner';
import { Article as ArticleType } from 'src/types/workflowBuilderAPITypes';

interface ArticlesListProps {
  articles: Array<ArticleType>;
  hasMoreData: boolean;
  hasWorkflowConflict: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  loadNextPage: () => void;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
  totalHits: number;
}
const ArticlesList: FC<React.PropsWithChildren<ArticlesListProps>> = ({
  articles,
  hasMoreData,
  hasWorkflowConflict,
  isDisabled,
  isLoading,
  loadNextPage,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
  totalHits,
}) => {
  if (isLoading && articles.length === 0) {
    return <Spinner />;
  }
  if (!articles.length) {
    return <div>No articles found</div>;
  }
  const isItemLoaded = (index: number) => !!articles[index];
  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    if (!isItemLoaded(index)) {
      return null;
    }
    return (
      <div
        style={{
          ...style,
          height: `${(style.height as number) - 12}px`,
          overflowX: 'hidden',
          top: `${(style.top as number) + 12}px`,
        }}
      >
        <Article
          article={articles[index]}
          hasWorkflowConflict={hasWorkflowConflict}
          isDisabled={isDisabled}
          shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
          squeezeStepParentId={squeezeStepParentId}
        />
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ height = 0, width = 0 }: Size) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={articles.length}
          /* eslint-disable @typescript-eslint/no-empty-function */
          loadMoreItems={isLoading || !hasMoreData ? () => {} : loadNextPage}
          minimumBatchSize={100}
          threshold={50}
        >
          {({ onItemsRendered, ref }) => {
            return (
              <List
                height={height - 120}
                innerElementType={React.forwardRef(
                  ({ style, ...rest }, ref) => (
                    <div
                      ref={ref}
                      style={{
                        ...style,
                        paddingTop: 12,
                      }}
                      {...rest}
                    />
                  ),
                )}
                itemCount={totalHits}
                itemKey={(idx, data: ArticleType[]) =>
                  data && data[idx] && data[idx].document_id
                }
                itemSize={175}
                onItemsRendered={onItemsRendered}
                overscanCount={50}
                ref={ref}
                width={width}
              >
                {Row}
              </List>
            );
          }}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default ArticlesList;
