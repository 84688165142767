import { Add as AddIcon } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { getDefaultRegistry } from '@rjsf/core';
import {
  ArrayFieldTemplateProps,
  getTemplate,
  IconButtonProps,
  isMultiSelect,
} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Button } from '@forethought-technologies/forethought-elements';
import { ArrayFieldItem } from './ArrayFieldItem';

const AddButton = ({
  onClick,
  ...rest
}: Omit<IconButtonProps, 'className'>) => {
  return (
    <Button
      onClick={onClick}
      startIcon={<AddIcon />}
      variant='secondary'
      {...rest}
      fullWidth
    >
      Add Item
    </Button>
  );
};

const DefaultFixedArrayFieldTemplate = ({
  canAdd,
  disabled,
  idSchema,
  items,
  onAddClick,
  readonly,
  required = false,
  schema,
  uiSchema,
  ...rest
}: ArrayFieldTemplateProps) => {
  const title = uiSchema?.['ui:title'] || rest.title;
  const description = uiSchema?.['ui:description'] || schema.description;
  const ArrayFieldTitleTemplate = getTemplate(
    'ArrayFieldTitleTemplate',
    rest.registry,
  );

  return (
    <fieldset>
      {title && (
        <ArrayFieldTitleTemplate
          idSchema={idSchema}
          registry={rest.registry}
          required={required}
          schema={schema}
          title={title}
        />
      )}

      {description && <div>{description}</div>}
      {items && (
        <div>
          {items.map(({ key, ...rest }) => (
            <ArrayFieldItem key={key} {...rest} />
          ))}
        </div>
      )}
      {canAdd && (
        <AddButton
          disabled={disabled || readonly}
          onClick={onAddClick}
          {...rest}
        />
      )}
    </fieldset>
  );
};

const DefaultNormalArrayFieldTemplate = ({
  canAdd,
  disabled,
  idSchema,
  items,
  onAddClick,
  readonly,
  required = false,
  schema,
  uiSchema,
  ...rest
}: ArrayFieldTemplateProps) => {
  const title = uiSchema?.['ui:title'] || rest.title;
  const description = uiSchema?.['ui:description'] || schema.description;
  const ArrayFieldTitleTemplate = getTemplate(
    'ArrayFieldTitleTemplate',
    rest.registry,
  );
  const ArrayFieldDescriptionTemplate = getTemplate(
    'ArrayFieldDescriptionTemplate',
    rest.registry,
  );

  return (
    <Box>
      {title && (
        <ArrayFieldTitleTemplate
          idSchema={idSchema}
          registry={rest.registry}
          required={required}
          schema={schema}
          title={title}
        />
      )}

      {description && (
        <ArrayFieldDescriptionTemplate
          description={description}
          idSchema={idSchema}
          registry={rest.registry}
          schema={schema}
        />
      )}

      <Grid container={true} gap={2} mt={1}>
        {items.map(({ key, ...rest }) => (
          <ArrayFieldItem key={key} {...rest} />
        ))}
        {canAdd && (
          <AddButton
            disabled={disabled || readonly}
            onClick={onAddClick}
            {...rest}
          />
        )}
      </Grid>
    </Box>
  );
};

const ArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
  const { registry = getDefaultRegistry(), schema } = props;

  if (isMultiSelect(validator, schema, registry.rootSchema)) {
    return <DefaultFixedArrayFieldTemplate {...props} />;
  } else {
    return <DefaultNormalArrayFieldTemplate {...props} />;
  }
};

export default ArrayFieldTemplate;
