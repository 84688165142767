import {
  SolveAggregate,
  SolveTableDict,
  TableDataDict,
} from 'src/services/apiInterfaces';
import store from 'src/store/store';
import { formatNum } from 'src/utils/analyticsUtils';
import { SolveChannels, Tabs } from 'src/utils/enums';
import { formatCvsKey } from 'src/utils/formatCSVkey';
import {
  formatNToPercentageDecimal,
  roundNumToPercentage,
} from 'src/utils/formatToPercentage';

type CSVDataInterface = {
  [index: number]: {
    [key: string]: string | number;
  };
};
type CSVDataDictType = {
  [key: string]: Function;
};

const generateAnswerData = (data: TableDataDict): CSVDataInterface => {
  const { aggregate, breakdown } = data;

  const csvData =
    (breakdown &&
      breakdown.flat().map((data: { [key: string]: string | number }) => {
        return {
          'Agent Name': data.field_value,
          'Engaged Tickets': data.engaged_count,
          Engagement: roundNumToPercentage(data.engaged_ratio as number),
          'Solved Tickets': data.total_count,
        };
      })) ||
    [];

  const DataForCSV = [
    {
      'Agent Name': 'Totals & Averages',
      'Engaged Tickets': aggregate && aggregate.engaged_count,
      Engagement: roundNumToPercentage(aggregate && aggregate.engaged_ratio),
      'Solved Tickets': aggregate && aggregate.total_count,
    },
    ...csvData,
  ];

  return DataForCSV;
};

const generateSolveData = (tableData: SolveTableDict) => {
  const { aggregate, breakdown } = tableData;
  const state = store.getState();
  const { solveData } = state;
  const { filterType, selectedChannel } = solveData;
  const { key, name } = filterType;

  const csvData = // TODO make this reusable for all data like Totals below
    (breakdown &&
      breakdown.flat().map((data: { [key: string]: string | number }) => {
        switch (selectedChannel) {
          case SolveChannels.EMAIL:
            return {
              Csat: formatNToPercentageDecimal(data.csat as number),
              [name]: data.field_value,
              'Reopen Rate': formatNToPercentageDecimal(
                data.reopen_rate as number,
              ),
              Reopens: data.reopens,
              Resolutions: data.resolutions,
              Responses: data.responses,
            };
          case SolveChannels.API:
            return {
              Interactions: data.interactions,
              [name]: data.field_value,
              Resolutions: data.resolutions,
              'Resolve Rate': formatNToPercentageDecimal(
                data.resolve_rate as number,
              ),
              'Ticket Creations': data.ticket_creations,
            };
          case SolveChannels.WIDGET:
            return {
              Interactions: data.interactions,
              [name]: data.field_value,
              Resolutions: data.resolutions,
              'Resolve Rate': formatNToPercentageDecimal(
                data.resolve_rate as number,
              ),
              'Ticket Creations': data.ticket_creations,
            };
        }
      })) ||
    [];

  const totals: { [index: string]: number | string } = {};

  aggregate &&
    aggregate[key] &&
    Object.keys(aggregate && aggregate[key])
      .filter(value => value !== 'field_type' || 'field_name')
      .map(name => {
        return (totals[formatCvsKey(name)] = formatNum(
          name,
          (aggregate as SolveAggregate)[key][name], // This need to be casted because of the conditional value for the aggregate property
          tableData?.data_types,
        ) as string | number);
      });

  const DataForCSV = [
    {
      [name]: 'Totals & Averages',
      ...totals,
    },
    ...csvData,
  ];

  return DataForCSV;
};

const csvDataDict: CSVDataDictType = {
  [Tabs.ANSWERS]: generateAnswerData,
  [Tabs.SOLVE]: generateSolveData,
};

export default csvDataDict;
