import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';

export const Section = styled('section')`
  align-items: center;
  background-color: ${theme.palette.colors.white};
  display: flex;
  gap: 40px;
  justify-content: center;
  min-height: 300px;
`;

export const BubbleGroup = styled('div')`
  align-self: flex-start;
  display: flex;
  padding-top: 40px;
  width: 260px;

  &:first-of-type {
    transform: translateX(64px);
  }
`;

export const Content = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16;
  max-width: 500px;
  padding: 12px;
  text-align: center;
`;

export const NarrowContentWrapper = styled('div')`
  max-width: 400px;
  width: 100%;
`;

export const Title = styled('h2')`
  color: ${theme.palette.colors.black};
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin: 0;
`;

export const Subtitle = styled('p')`
  color: ${theme.palette.colors.grey[500]};
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 16px;
`;
