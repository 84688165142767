import { useMemo } from 'react';

import { Typography } from '@forethought-technologies/forethought-elements';
import AutosizeIframe from 'src/components/autosize-iframe/AutosizeIframe';
import Spinner from 'src/components/spinner';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetFormattedIntentEmailQuery } from 'src/services/solve-conversations/emailConversationsApi';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';

export function FormattedIntentEmail({
  conversationId,
}: {
  conversationId: string;
}) {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeWorkflowTagContextVariable: false,
  });
  const { data, isLoading } = useGetFormattedIntentEmailQuery({
    conversationId,
  });

  const emailResponse = useMemo(() => {
    if (!contextVariables.length || !data) {
      return null;
    }
    return contextVariableAliasHtml({
      contextVariables,
      html: data.formatted_email,
      undefinedContextVariableIds: [],
    });
  }, [contextVariables, data]);

  if (isLoading) {
    return <Spinner />;
  }

  if (!emailResponse) {
    return <Typography variant='font14Medium'>Not found</Typography>;
  }

  return <AutosizeIframe padding={20} srcDoc={emailResponse} />;
}
