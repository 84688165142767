import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from 'src/pages/workflow-builder-edit/hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

interface TicketTitleProps {
  contextVariables: ContextVariable[];
}

const TicketTitle: FC<React.PropsWithChildren<TicketTitleProps>> = () => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();
  return (
    <>
      <Field name='ticket_creation_settings.ticket_creation_config.ticket_title'>
        {({ field, form: { setFieldValue } }: FieldProps) => (
          <ContextVariableAutocomplete
            label='Case Title'
            onChange={value => setFieldValue(field.name, value)}
            placeholder='Enter case title'
            shouldIncludeSystemContextVariables
            undefinedContextVariables={undefinedContextVariableIds}
            value={field.value}
          />
        )}
      </Field>
    </>
  );
};

export default TicketTitle;
