import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import warningIcon from '../../assets/images/circular-warning-icon.svg';
import finishedIcon from './assets/icons/finished.svg';
import uniq from 'lodash/fp/uniq';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { selectIsBranchValid } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';

const BranchStepValidIndicator = ({
  transitionBranchStepId,
  undefinedTransitionCvs,
}: {
  transitionBranchStepId: string | null | undefined;
  undefinedTransitionCvs: string[];
}): JSX.Element | null => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const [isBranchFilled, undefinedContextVariableIds] = useSelector(
    selectIsBranchValid(transitionBranchStepId, false),
  );

  const combinedUndefinedCvs = undefinedTransitionCvs.concat(
    undefinedContextVariableIds,
  );
  const uniqueCombinedUndefinedCvs = uniq(combinedUndefinedCvs);
  const undefinedContextVariableNames = uniqueCombinedUndefinedCvs
    .map(
      id =>
        contextVariables.find(
          contextVariable => contextVariable.context_variable_id === id,
        )?.context_variable_name,
    )
    .map(name => `$${name}`)
    .join(', ');

  let tooltipContent = null;
  if (!isBranchFilled && uniqueCombinedUndefinedCvs.length > 0) {
    tooltipContent = `Complete empty branches, and collect ${undefinedContextVariableNames} via a form step or set as pre-defined context`;
  } else if (!isBranchFilled) {
    tooltipContent = 'Complete empty branches.';
  } else if (uniqueCombinedUndefinedCvs.length > 0) {
    tooltipContent = `Collect ${undefinedContextVariableNames} via a form step or set as pre-defined context`;
  } else {
    return <img alt='Branch is valid' src={finishedIcon} />;
  }

  return (
    <TooltipWrapper>
      <Tooltip tooltipContent={tooltipContent}>
        <img alt='Branch is invalid' src={warningIcon} />
      </Tooltip>
    </TooltipWrapper>
  );
};

export default BranchStepValidIndicator;

const TooltipWrapper = styled('div')`
  height: 20px;
`;
