import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getWhitelistedDomainsAPI,
  updateWhitelistedDomainsAPI,
} from 'src/services/workflow-builder/workflowBuilderApi';

export const getWhitelistedDomains = createAsyncThunk(
  'settings/getWhitelistedDomains',
  async () => {
    return await getWhitelistedDomainsAPI();
  },
);

export const updateWhitelistedDomains = createAsyncThunk(
  'settings/updateWhitelistedDomains',
  async (domains: string[]) => {
    return await updateWhitelistedDomainsAPI(domains);
  },
);
