import { useNavigate, useParams } from 'react-router';
import { lighten, styled } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { AUTONOMOUS_AGENT_PREVIEW_VISUALIZER_ID } from './constants';
import { getIframeUrl } from './helpers';
import Transcript from './transcript';
import autonomousPreviewBackground from 'src/assets/images/autonomous-preview-background.png';
import { useGetPreviewTokenQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';

const AutonomousAgentPreview = () => {
  const { intentId = '' } = useParams<'intentId'>();

  const navigate = useNavigate();
  const { data: previewTokenData } = useGetPreviewTokenQuery();
  const { preview_api_token: previewToken = '' } = previewTokenData ?? {};

  return (
    <Box
      flex={1}
      sx={{
        backgroundImage: `url(${autonomousPreviewBackground})`,
        backgroundSize: 'cover',
        display: 'flex',
        flex: 1,
        height: '100%',
      }}
    >
      <Box display='flex' flex={1} flexDirection='column'>
        <Box ml='46px' mt='18px'>
          <Typography color='white' variant='font24'>
            Preview
          </Typography>
        </Box>

        <Box
          alignItems='center'
          display='flex'
          flex={1}
          justifyContent='center'
          mx='12px'
          my='31px'
        >
          <Box
            flex={1}
            height='100%'
            id='iframe-container'
            maxHeight='100%'
            maxWidth='100%'
            sx={{
              '#forethought-iframe': {
                border: 'none',
                height: '100%',
                width: '100%',
              },
            }}
          >
            <iframe
              id='forethought-iframe'
              src={getIframeUrl(previewToken, intentId)}
            />
          </Box>
        </Box>
      </Box>
      <Box
        id={AUTONOMOUS_AGENT_PREVIEW_VISUALIZER_ID}
        sx={{
          backdropFilter: 'blur(50px)',
          background: 'rgba(6, 17, 27, 0.50)',
          borderBottomLeftRadius: '30px',
          borderTopLeftRadius: '30px',
          boxShadow:
            '0px 0px 1px 0px rgba(255, 255, 255, 0.15) inset, 0px 8px 6px 0px rgba(0, 0, 0, 0.05)',
          flex: 1,
          overflowY: 'auto',
        }}
      >
        <Box
          alignItems='center'
          display='flex'
          justifyContent='flex-end'
          position='sticky'
          pt={3}
          px={3}
          top={0}
        >
          <DarkThemeVariantButton
            onClick={() => navigate(-1)}
            variant='secondary'
          >
            Close
          </DarkThemeVariantButton>
        </Box>
        <Transcript />
      </Box>
    </Box>
  );
};

const DarkThemeVariantButton = styled(Button)`
  color: ${props => props.theme.palette.colors.purple[100]};
  background-color: ${props => props.theme.palette.colors.brand.darkGrey};
  &:hover {
    background-color: ${props =>
      lighten(props.theme.palette.colors.brand.darkGrey, 0.1)};
  }
`;

export default AutonomousAgentPreview;
