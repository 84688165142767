import store from 'src/store/store';
import { Tabs } from 'src/utils/enums';

// Callback function to execute when mutations are observed in the DOM
export const mutationObserverCallback = (
  mutationsList: Array<MutationRecord>,
) => {
  const { user } = store.getState();
  const currentTab = user?.currentTab;
  const npsSurveyDiv = document.getElementById('npsIframeContainer');
  const workflowBuilerTabs = [
    Tabs.WORKFLOW_BUILDER,
    Tabs.WORKFLOW_BUILDER_EDIT,
  ];

  const shouldHideNpsSurvey =
    // @ts-expect-error legacy code with untyped state
    currentTab && !workflowBuilerTabs.includes(currentTab);

  mutationsList.forEach(mutation => {
    mutation.addedNodes.forEach(node => {
      const areNodesEqual = node?.isEqualNode(npsSurveyDiv);
      if (npsSurveyDiv && areNodesEqual && shouldHideNpsSurvey) {
        npsSurveyDiv.style.display = 'none';
        return;
      }
    });
  });
};
