import '../common/datePicker.scss';
import '../common/tablePage.scss';
import 'react-day-picker/lib/style.css';
import 'react-day-picker/lib/style.css';
import './answersAnalyticsPageV1.scss';

import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Dispatch } from '@reduxjs/toolkit';

import {
  NonIdealState,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import LoadingSpinner from '../../reusable-components/loading-spinner/loadingSpinner';
import ToastNotification from '../../reusable-components/toast-notification/index';
import Chart from '../common/chart/chartV1';
import { AnswersAnalyticsCards } from './answersAnalyticsCards/answersAnalyticsCards';
import Breakdown from './breakdown/Breakdown';
import FilterBar from './filter-bar/FilterBar';
import SettingsContainer from './SettingsBar/SettingsBarContainer';
import { DispatchFromProps } from './answersAnalyticsV1Types';
import Table from './Table';
import { getBreakdownFields } from 'src/actions/breakdowns/breakdowns';
import { getFilterFields } from 'src/actions/filters/filters';
import { getAllReports } from 'src/actions/reports/reports';
import NoCloudIcon from 'src/assets/images/no-cloud-icon.svg';
import { selectSelectedBreakdownValues } from 'src/reducers/breakdownsReducer/breakdownsReducer';
import {
  selectChartData,
  selectDataError,
  selectIsLoading,
  selectTableData,
} from 'src/reducers/dataDeprecatedReducer/dataDeprecatedReducer';
import {
  selectChartYAxis,
  selectTimeFrameFilter,
  selectViewMode,
} from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { selectReport } from 'src/reducers/reportsReducer/reportsReducer';
import {
  ChartDataDict,
  Report,
  TableDataDict,
} from 'src/services/apiInterfaces';
import { ViewModes } from 'src/utils/enums';
import { setReportIdToUrl } from 'src/utils/urlBehavior';

type MyProps = {
  chartData?: ChartDataDict;
  chartYAxis: string;
  errorMessage?: string;
  getAllReports: Function;
  getBreakdownFields: Function;
  getFilterFields: Function;
  isLoading: boolean;
  loadFromObject?: Function;
  selectedBeakdownValues: string[];
  selectedReport?: Report;
  sortedProperty: string;
  tableData?: TableDataDict;
  timeFrameFilter: string;
  viewMode: ViewModes;
};

type MyState = {
  pageNumber: number;
  reportSelected: string;
};

class AnswersAnalyticsPage extends React.Component<MyProps, MyState> {
  componentDidMount() {
    this.props.getFilterFields();
    this.props.getBreakdownFields();
    this.props.selectedReport?.report_id &&
      setReportIdToUrl(this.props.selectedReport.report_id);
  }

  render(): React.ReactNode {
    const {
      chartData,
      chartYAxis,
      errorMessage,
      isLoading,
      selectedBeakdownValues,
      sortedProperty,
      tableData,
      timeFrameFilter,
      viewMode,
    } = this.props;

    return (
      <div className='analytics-page'>
        <AnswersAnalyticsCards
          chartTotals={chartData?.totals}
          tableTotals={tableData?.totals}
          viewMode={viewMode}
        />
        <SettingsContainer
          chartData={chartData}
          showReportSettings
          tableData={tableData}
          viewMode={viewMode}
        />
        <>
          {errorMessage && (
            <ErrorState>
              <NonIdealState
                icon={<img src={NoCloudIcon} />}
                title='No data was found'
              >
                <Typography
                  color={theme.palette.colors.grey[500]}
                  variant='font14'
                >
                  We could not load your helpdesk data, this might be because we
                  don&apos;t support your helpdesk
                </Typography>
              </NonIdealState>
            </ErrorState>
          )}
          {!errorMessage && chartData && viewMode === 'Chart' && (
            <div className='AnswersAnalyticsPage-displayData'>
              <div className='AnswersAnalyticsPage-chartFilterContainer'>
                <FilterBar />
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <Chart
                    breakdownValues={selectedBeakdownValues}
                    chartData={chartData.data}
                    dataTypes={chartData?.data_types}
                    mean={chartData.mean}
                    timeFrameFilter={timeFrameFilter}
                    y={chartYAxis}
                  />
                )}
              </div>
              <Breakdown />
            </div>
          )}

          {!errorMessage && tableData && viewMode === 'Table' && (
            <>
              <FilterBar />
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Table
                    sortedProperty={sortedProperty}
                    tableData={tableData}
                  />
                </>
              )}
            </>
          )}
        </>

        <ToastNotification />
      </div>
    );
  }
}

const ErrorState = styled('div')`
  height: 500px;
  display: flex;
  justify-content: center;
  background-color: ${theme.palette.colors.white};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => ({
  chartData: selectChartData(state),
  chartYAxis: selectChartYAxis(state),
  errorMessage: selectDataError(state),
  isLoading: selectIsLoading(state),
  selectedBeakdownValues: selectSelectedBreakdownValues(state),
  selectedReport: selectReport(state),
  sortedProperty: state.analytics.sortType,
  tableData: selectTableData(state),
  timeFrameFilter: selectTimeFrameFilter(state),
  viewMode: selectViewMode(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => ({
  getAllReports: (fetchData: boolean) => dispatch(getAllReports(fetchData)),
  getBreakdownFields: () => dispatch(getBreakdownFields()),
  getFilterFields: () => dispatch(getFilterFields()),
});

const ConnectedAnswersAnalyticsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnswersAnalyticsPage);

export default ConnectedAnswersAnalyticsPage;
