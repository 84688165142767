import { ModuleName } from './enums';
import { sendTrackingEvent } from 'src/actions/search';
import { CustomRangeModifier } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';

export const sendTrackingEvents = (
  dispatch: Function,
  eventType: string,
  timeRange: CustomRangeModifier,
  application: string,
  parameters: {},
) => {
  const data = {
    dashboard_application: application,
    end_timestamp: timeRange.to && timeRange.to.getTime() / 1000,
    event_type: eventType,
    start_timestamp: timeRange.from && timeRange.from.getTime() / 1000,
    ...parameters,
  };

  dispatch(sendTrackingEvent(ModuleName.ANALYTICS, data));
};
