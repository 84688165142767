import { ReactSVG } from 'react-svg';
import { Box, css, styled } from '@mui/material';
import { IconExternalLink } from '@tabler/icons-react';

import {
  Badge,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { articleLogos } from 'src/pages/workflow-builder-edit/article-picker/constants';
import { Article } from 'src/types/workflowBuilderAPITypes';

export type ArticleVariant = 'wide';

interface DocumentPreviewProps {
  article: Article;
  variant?: ArticleVariant;
}

export function DocumentPreview({ article, variant }: DocumentPreviewProps) {
  const { link, permission, preview, source_type, title } = article;

  const isWideVariant = variant === 'wide';
  const isPublicArticle = permission === 'Public';

  return (
    <ArticleContainer isWideVariant={!!isWideVariant}>
      <Row>
        <Icon src={articleLogos[source_type] || articleLogos.webpage} />
        <Box alignItems='center' display='flex' gap='5px'>
          {permission && <Badge label={permission} variant='macro' />}
          {isPublicArticle && (
            <LinkToArticle href={link} target='_blank'>
              <IconExternalLink height='20px' width='20px' />
            </LinkToArticle>
          )}
        </Box>
      </Row>
      <TitleContainer isWideVariant={isWideVariant}>
        <Typography variant='font14Bold'>{title}</Typography>
      </TitleContainer>
      <Description isWideVariant={isWideVariant}>{preview}</Description>
    </ArticleContainer>
  );
}

export const ArticleContainer = styled('div')<{ isWideVariant?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${props => props.theme.palette.colors.slate[200]};
  border-radius: 8px;
  background-color: ${props => props.theme.palette.colors.white};
  ${({ isWideVariant }) =>
    isWideVariant
      ? css`
          height: 126px;
        `
      : css`
          max-height: 174px;
        `}
  cursor: pointer;

  &:hover:not(:disabled) {
    border: 1px solid ${props => props.theme.palette.colors.grey[300]};
    box-shadow: 0px 0px 1px rgba(0, 12, 32, 0.04),
      2px 5px 8px rgba(3, 17, 38, 0.107135);
  }
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const Icon = styled(ReactSVG)`
  width: 20px;
  height: 20px;
`;

const Description = styled('div')<{ isWideVariant: boolean }>`
  color: ${props => props.theme.palette.colors.grey[600]};
  font-size: 12px;
  word-wrap: break-word;
  line-height: 20px;
  letter-spacing: -0.1px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: ${({ isWideVariant }) => (isWideVariant ? '2' : '3')};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const TitleContainer = styled('div')<{ isWideVariant: boolean }>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ isWideVariant }) => (isWideVariant ? '1' : '2')};
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 4px;
  line-height: 20px;
`;

const LinkToArticle = styled('a')`
  display: flex;
  color: ${props => props.theme.palette.colors.black};
`;
