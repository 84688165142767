import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { FormWithErrors } from '../types';
import { cleanURL } from 'src/pages/workflow-builder-edit/embed-video/utils';
import {
  selectEditingStepFields,
  setStepFieldValue,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import { EmbedStepFields } from 'src/types/workflowBuilderAPITypes';
import { isEmbedStepFields } from 'src/utils/solve/stepUtils';

const EmbedStepForm = ({ errorObject }: FormWithErrors<EmbedStepFields>) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [testURL, setTestURL] = useState('');
  const fields = useSelector(selectEditingStepFields);

  if (!isEmbedStepFields(fields)) {
    return null;
  }

  const cleanedURL = cleanURL(fields.embed_url);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography color={theme.palette.colors.grey[700]} variant='font14'>
          {`Enter the video URL using either the YouTube or Vimeo video's
            address bar URL, or the link from the "share" option.`}
        </Typography>
        {/* Input */}
        <TextField
          description='Must be a valid URL'
          error={errorObject?.embed_url}
          label='Embed URL'
          onChange={e => {
            dispatch(
              setStepFieldValue({
                field: 'embed_url',
                value: e.target.value,
              }),
            );
          }}
          placeholder='e.g. https://www.youtube.com/embed/abc123'
          type='url'
          value={fields.embed_url}
        />
        {/* Embed Test */}
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Button
            disabled={!cleanedURL}
            onClick={() => setTestURL(cleanedURL)}
            variant='secondary'
          >
            Test
          </Button>
          {testURL && (
            <Button onClick={() => setTestURL('')} variant='danger'>
              Clear Test
            </Button>
          )}
        </Box>
        {testURL && (
          <Box
            sx={{
              aspectRatio: '16 / 9',
              width: '100%',
            }}
          >
            <Box
              allowFullScreen
              component='iframe'
              name='Forethought Embed'
              referrerPolicy='no-referrer'
              src={testURL}
              sx={{
                border: `1px solid ${theme.palette.colors.slate[200]}`,
                borderRadius: '8px',
                height: '100%',
                width: '100%',
              }}
              title='Forethought Embed'
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default EmbedStepForm;
