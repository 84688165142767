import { Box, styled } from '@mui/system';

import {
  elevations,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface ToggleBarProps<T> {
  activeTab: T;
  tabs: { onClick: () => void; title: T }[];
}

export default function ToggleBar<T extends string>({
  activeTab,
  tabs,
}: ToggleBarProps<T>) {
  return (
    <Bar>
      {tabs.map(({ onClick, title }) => {
        return (
          <Button isActive={activeTab === title} key={title} onClick={onClick}>
            <Typography variant='font14Bold'>{title}</Typography>
          </Button>
        );
      })}
    </Bar>
  );
}

const Bar = styled(Box)`
  background-color: ${props => props.theme.palette.colors.slate[100]};
  border-radius: 6px;
  display: flex;
  border: 1px solid ${props => props.theme.palette.colors.slate[200]};
  padding: 2px;
`;

const Button = styled('button', {
  shouldForwardProp: prop => prop !== 'isActive',
})<{ isActive: boolean }>`
  background-color: ${props =>
    props.isActive ? props.theme.palette.colors.white : 'transparent'};
  border-radius: 4px;
  box-shadow: ${props => (props.isActive ? elevations.z1 : 'none')};
  color: ${props =>
    props.isActive
      ? props.theme.palette.colors.black
      : props.theme.palette.colors.grey[600]};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 176px;

  &:hover {
    color: ${props => props.theme.palette.colors.black};
  }
`;
