import { InputRule } from 'prosemirror-inputrules';
import {
  ApplySchemaAttributes,
  ExtensionTag,
  NodeExtension,
  NodeExtensionSpec,
} from '@remirror/core';

import { UUID_REGEX } from 'src/constants/regex';

export const INVALID_CV_DATA_ATTR = 'data-ft-invalid-cv' as const;
export const INVALID_CV_CLASS = 'ft-invalid-cv' as const;

export const BRACKETED_VALUE_REGEX = /\{\{([^}]+)\}\}/g;

export class InvalidCVCheckerExtension extends NodeExtension {
  dataAttribute = INVALID_CV_DATA_ATTR;
  tag = 'span' as const;

  get name() {
    return 'invalidCVChecker' as const;
  }

  createTags() {
    return [ExtensionTag.InlineNode, ExtensionTag.Behavior];
  }

  createNodeSpec(extra: ApplySchemaAttributes): NodeExtensionSpec {
    return {
      atom: true,
      attrs: {
        ...extra.defaults(),
        invalidCV: {},
      },
      group: 'inline',
      inline: true,
      parseDOM: [
        {
          getAttrs: node => {
            const domNode = node as HTMLElement;
            return {
              ...extra.parse(node),
              invalidCV: domNode.getAttribute(this.dataAttribute),
            };
          },
          tag: `${this.tag}[${this.dataAttribute}]`,
        },
      ],
      selectable: false,
      toDOM: node => {
        const { invalidCV } = node.attrs;
        const extraAttrs = extra.dom(node);
        extraAttrs[this.dataAttribute] = invalidCV;
        extraAttrs.class = INVALID_CV_CLASS;
        return [this.tag, extraAttrs, invalidCV];
      },
    };
  }

  createInputRules() {
    return [
      new InputRule(BRACKETED_VALUE_REGEX, (state, match, start, end) => {
        const [bracketedValue, value] = match;
        const { tr } = state;
        if (!UUID_REGEX.test(value)) {
          const attrs = { invalidCV: bracketedValue };
          tr.insertText('', start, end).replaceWith(
            start,
            start,
            this.type.create(attrs),
          );
        }
        return tr;
      }),
    ];
  }
}
