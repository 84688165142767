import { useState } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import PaginationControl from './PaginationControl';
import TicketDrawerDetails from './TicketDrawerDetails';
import Spinner from 'src/components/spinner';
import { DiscoverTicket } from 'src/reducers/discoverReducer/types';

interface TicketSourcesProps {
  articleId: string;
  isError: boolean;
  loading: boolean;
  tickets?: DiscoverTicket[];
}

const TicketSources = ({ isError, loading, tickets }: TicketSourcesProps) => {
  const { palette } = useTheme();
  const [currentPage, setCurrentPage] = useState(0);

  if (loading) {
    return <Spinner />;
  }

  if (isError || !tickets) {
    return null;
  }

  if (!tickets.length) {
    return (
      <Box p={2}>
        <Typography color={palette.colors.grey[600]} variant='font14'>
          No tickets found
        </Typography>
      </Box>
    );
  }

  const PageContol = (
    <PaginationControl<DiscoverTicket[]>
      currentIndex={currentPage}
      items={tickets}
      setIndex={index => setCurrentPage(index)}
    />
  );
  const currentItem = tickets[currentPage];

  return <TicketDrawerDetails PageControl={PageContol} ticket={currentItem} />;
};

export default TicketSources;
