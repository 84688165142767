import { Box } from '@mui/material';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverErrorBackground from 'src/assets/images/discover-error-bg.svg';
import { ERROR_DATABASE_IS_UNAVAILABLE } from 'src/constants/discover';

const ErrorPage = ({ errorType }: { errorType: string }) => {
  return (
    <Box bgcolor={theme.palette.colors.white} height='100%'>
      <Box
        sx={{
          backgroundImage: `url(${DiscoverErrorBackground})`,
          backgroundPositionX: 'center',
          // center - navbar height
          backgroundPositionY: 'calc(50% - 70px)',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <ErrorComponent errorType={errorType} />
      </Box>
    </Box>
  );
};

const ErrorComponent = ({ errorType }: { errorType: string }) => {
  const handleErrorText = () => {
    switch (errorType) {
      case ERROR_DATABASE_IS_UNAVAILABLE:
        return 'Calculating your data...';
      default:
        return 'Page temporarily unavailable';
    }
  };
  return (
    <Box
      sx={{
        alignItems: 'center',
        alignSelf: 'center',
        backgroundColor: theme.palette.colors.white,
        borderRadius: '20px',
        boxShadow:
          '0px 0px 1px rgb(0 12 32 / 4%), 0px 2px 6px rgb(3 17 38 / 11%)',
        display: 'flex',
        flexDirection: 'column',
        // adjust by navbar height
        marginBottom: '70px',
        padding: '44px',
      }}
    >
      <Box mb={2.5}>
        <Typography color={theme.palette.colors.black} variant='font36'>
          {handleErrorText()}
        </Typography>
      </Box>
      <Box>
        <Typography color={theme.palette.colors.grey[800]} variant='font18'>
          Please try again later
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorPage;
