import React, { Dispatch, FC, SetStateAction } from 'react';

import {
  ErrorMessage,
  Label,
  TextArea,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { FieldNameToIdx } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import { ZendeskHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface ConfirmationMessageProps {
  customizationData: ZendeskHandoffCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<SetStateAction<ZendeskHandoffCustomization>>;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const ConfirmationMessage: FC<
  React.PropsWithChildren<ConfirmationMessageProps>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  const errorMessage = errorMessages[FieldNameToIdx.CONFIRMATION_MESSAGE];
  const isTouched = isFieldTouchedArr[FieldNameToIdx.CONFIRMATION_MESSAGE];
  const showErrorMessage = !!errorMessage && !!isTouched;

  return (
    <>
      <Label>Confirmation Message</Label>
      <TextArea
        hasError={showErrorMessage}
        onBlur={() => {
          const arrCopy = [...isFieldTouchedArr];
          arrCopy[FieldNameToIdx.CONFIRMATION_MESSAGE] = true;
          setIsFieldTouchedArr(arrCopy);
        }}
        onChange={e => {
          const { value } = e.target;
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              ticket_creation_settings: {
                ...handoffData.ticket_creation_settings,
                ticket_creation_config: {
                  ...customizationData.ticket_creation_settings
                    ?.ticket_creation_config,
                  confirmation_message: value,
                },
              },
            };
          });
        }}
        placeholder='Add message'
        value={
          customizationData.ticket_creation_settings?.ticket_creation_config
            ?.confirmation_message
        }
      />
      {showErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default ConfirmationMessage;
