import { useMemo } from 'react';
import { Box } from '@mui/material';

import { Table } from '@forethought-technologies/forethought-elements';
import { UserPermissionValue } from '../constants';
import TagList from '../TagList';
import AgentEmailCell from './AgentEmailCell';
import DateCreatedCell from './DateCreatedCell';
import EmptyState from './EmptyState';
import { NotesTableFilter, noteStringCompare } from './helpers';
import TitleCell from './TitleCell';
import { useGetObservableContainerHeight } from 'src/hooks/discover/useGetObservableContainerHeight';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { AssistNote } from 'src/slices/assist-notes/types';

interface NotesTableProps {
  filterValue: string[];
  isLoading: boolean;
  notes?: AssistNote[];
  onDelete: (noteId: string) => void;
  onToggleNoteShare: (noteId: string, isPublic: boolean) => void;
  searchText: string;
  setSelectedNoteId: (id: string) => void;
  tagFilterValue: string[];
  userEmail?: string;
  userPermissionFilterValue: UserPermissionValue;
}

const minColumnWidth = '328px';

const NotesTable = ({
  filterValue,
  isLoading,
  notes = [],
  onDelete,
  onToggleNoteShare,
  searchText,
  setSelectedNoteId,
  tagFilterValue,
  userEmail,
  userPermissionFilterValue,
}: NotesTableProps) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const containerHeight = useGetObservableContainerHeight(
    'notes-table-container',
  );

  const columns = useMemo(
    () => [
      {
        cellRenderer: (data: AssistNote) => {
          return (
            <Box display='flex' flexDirection='column' gap='4px'>
              <TitleCell data={data} searchText={searchText} />
              <TagList tagList={data.tags} />
            </Box>
          );
        },
        comparatorFunction: (a: AssistNote, b: AssistNote) =>
          noteStringCompare(a.title, b.title),
        id: 'title',
        label: `Note (${notes.length ?? 0})`,
        minWidth: minColumnWidth,
      },
      {
        cellRenderer: (data: AssistNote) => (
          <AgentEmailCell data={data} searchText={searchText} />
        ),
        comparatorFunction: (a: AssistNote, b: AssistNote) =>
          noteStringCompare(a.agent_email, b.agent_email),
        id: 'agent_email',
        label: 'Created by',
        minWidth: minColumnWidth,
      },
      {
        cellRenderer: (data: AssistNote) => (
          <DateCreatedCell
            data={data}
            onDelete={onDelete}
            onToggleNoteShare={onToggleNoteShare}
          />
        ),
        id: 'created_date',
        label: 'Created',
        minWidth: minColumnWidth,
      },
      {
        cellRenderer: (data: AssistNote) => (
          <DateCreatedCell
            data={data}
            onDelete={onDelete}
            onToggleNoteShare={onToggleNoteShare}
            variant='updated'
          />
        ),
        id: 'last_modified_date',
        label: 'Updated',
        minWidth: minColumnWidth,
      },
    ],
    [notes.length, onDelete, onToggleNoteShare, searchText],
  );

  const notesFilter = new NotesTableFilter(notes);

  if (!isLoading && notes.length === 0) {
    return <EmptyState />;
  }

  return (
    <Table
      columns={columns}
      isLoading={isLoading}
      onClickRow={({ note_id }) => {
        setSelectedNoteId(note_id);
        emitTrackingEventCallback('assist-note-selected', { note_id });
      }}
      rows={
        notesFilter
          .filterByKeyword(searchText)
          .filterByCreator(filterValue)
          .filterByTag(tagFilterValue)
          .filterByPermission(userPermissionFilterValue, userEmail).data
      }
      shouldDisablePagination
      shouldVirtualizeTableRows
      tableBodyHeight={containerHeight ? `${containerHeight}px` : '85vh'}
    />
  );
};

export default NotesTable;
