import { styled } from '@mui/material/styles';

import {
  elevations,
  theme,
} from '@forethought-technologies/forethought-elements';

const sizes = [42, 26, 26, 34, 42];

export default function Bubble({
  idx,
  src,
  translate,
}: {
  idx: number;
  src: string;
  translate: [number, number];
}) {
  return (
    <BubbleWrap idx={idx} translateTuple={translate}>
      <Img src={src} />
    </BubbleWrap>
  );
}

const BubbleWrap = styled('div')<{
  idx: number;
  translateTuple: [number, number];
}>`
  align-items: center;
  border-radius: 50%;
  box-shadow: ${elevations.z1};
  border: ${theme.palette.colors.slate[100]};
  display: flex;
  height: ${p => sizes[p.idx]}px;
  justify-content: center;
  padding: 8px;
  transform: ${p =>
    p.translateTuple &&
    `translate(${p.translateTuple[0]}px, ${p.translateTuple[1]}px)`};
  width: ${p => sizes[p.idx]}px;
`;

const Img = styled('img')`
  width: 100%;
`;
