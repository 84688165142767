import { Box } from '@mui/material';

import DynamicArticleCard from './DynamicArticleCard';
import {
  DynamicArticleComponentFields,
  IdleCanvasComponentProps,
} from 'src/components/email-builder/types';

const IntentEmailDynamicArticleIdle = ({
  component,
}: IdleCanvasComponentProps) => {
  const { article_sources, number_of_articles } =
    component.component_fields as DynamicArticleComponentFields;

  const uniqueDocTypes = article_sources
    .map(articleSource => articleSource.doc_type)
    .filter(
      (docType, index, self) => docType && self.indexOf(docType) == index,
    );

  const numberofArticlesMessage = `Show ${number_of_articles} recommended article${
    number_of_articles > 1 ? 's' : ''
  }`;

  const getArticleSourceMessage = () => {
    const uniqueDocTypesLength = uniqueDocTypes.length;
    let articleSourcesMessage = `Source${
      uniqueDocTypesLength > 1 ? 's' : ''
    } from `;

    uniqueDocTypes.forEach((docType, index) => {
      const isLastItem = index + 1 === uniqueDocTypesLength;
      const isPenultimateItem = index === uniqueDocTypesLength - 2;
      articleSourcesMessage = `${articleSourcesMessage} ${docType}${
        isLastItem ? '' : isPenultimateItem ? ' and ' : ', '
      }`;
    });

    return articleSourcesMessage;
  };

  return (
    <Box
      display='flex'
      justifyContent='center'
      margin='0 auto'
      padding='16px'
      width='100%'
    >
      <DynamicArticleCard
        isCanvasVariant
        message={`${numberofArticlesMessage} | ${getArticleSourceMessage()}`}
      />
    </Box>
  );
};

export default IntentEmailDynamicArticleIdle;
