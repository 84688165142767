import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { ForethoughtEditStepMenu } from './edit-menu/ForethoughtEditStepMenu';
import StepContainer from './workflow-builder/revamped/StepContainer';
import StepHeader from './workflow-builder/revamped/StepHeader';
import { selectEditingStepId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { EmbedStepFields, Step } from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';

interface Props {
  isCanvasDisabled: boolean;
  setShouldShowEditMenu: (shouldShow: boolean) => void;
  shouldShowEditMenu: boolean;
  step: Step;
  stepId: string;
}

export const EmbedStep: React.FC<React.PropsWithChildren<Props>> = ({
  isCanvasDisabled,
  setShouldShowEditMenu,
  shouldShowEditMenu,
  step,
  stepId,
}) => {
  const stepFields = step.step_fields as EmbedStepFields;
  const hasCondition = !!step.condition_name;
  const editingStepId = useSelector(selectEditingStepId);

  return (
    <StepContainer isBeingEdited={editingStepId === stepId}>
      <StepHeader stepType={StepTypes.EMBED} />
      <Box sx={{ aspectRatio: '16 / 9', width: '100%' }}>
        <Box
          allowFullScreen
          component='iframe'
          name='Forethought Embed'
          referrerPolicy='no-referrer'
          src={stepFields.embed_url}
          sx={{
            border: 'none',
            height: '100%',
            width: '100%',
          }}
          title='Forethought Embed'
        />
      </Box>
      {!isCanvasDisabled && (
        <ForethoughtEditStepMenu
          hasCondition={hasCondition}
          isDeletionDisabled={hasCondition}
          isVisible={shouldShowEditMenu}
          setIsVisible={setShouldShowEditMenu}
          stepId={stepId}
          stepType={step.step_type}
        />
      )}
    </StepContainer>
  );
};
