import { Box, Typography, useTheme } from '@mui/material';
import { IconInfoCircleFilled } from '@tabler/icons-react';

import { MessageBox } from './TextComponents';
import warningIcon from 'src/assets/images/circular-warning-icon.svg';

export const InfoPreviewLog = ({
  isWarning = false,
  message,
  title,
}: {
  isWarning?: boolean;
  message?: string;
  title: string;
}) => {
  const theme = useTheme();

  return (
    <Box display='flex' flexDirection='column'>
      <Box alignItems='center' display='flex' gap='2px'>
        {isWarning ? (
          <img alt='' src={warningIcon} />
        ) : (
          <IconInfoCircleFilled
            size={20}
            style={{
              color: theme.palette.colors.green[500],
              fill: theme.palette.colors.green[500],
            }}
          />
        )}

        <Typography
          color={
            theme.palette.mode === 'dark'
              ? theme.palette.colors.white
              : theme.palette.colors.grey[800]
          }
          variant='font16'
        >
          {title}
        </Typography>
      </Box>
      {message && <MessageBox>{message}</MessageBox>}
    </Box>
  );
};
