import { InitialsIconColors } from 'src/utils/enums';

//function that will assign color to the icon based on first letter of the initial or if it's an unknown agent or disabled view
export function iconColor(str: string, colorArr: string[]): string {
  const agentName = str ? str.toLowerCase() : 'unknown agent';
  const initial = agentName[0];
  if (agentName === 'unknown agent') {
    return InitialsIconColors.UNKNOWN_AGENT;
  } else if (
    agentName === 'agent name' ||
    !initial ||
    initial < 'a' ||
    initial > 'z'
  ) {
    // Edge cases, empty string, non letter characters, no data table view
    return InitialsIconColors.DEEP_GRAY;
  } else {
    const initialCharCode = initial.charCodeAt(0) - 97;
    const index = Math.floor(initialCharCode / 2);
    return colorArr[index];
  }
}
