import './IntFilterModal.scss';

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import InputField from '../../../reusable-components/input-field/InputField';
import FiltersTooltip from '../filters-tooltip/FiltersTooltip';
import { getAnswersData } from 'src/actions/data/data';
import { setFilter } from 'src/actions/filters/filters';
import { selectSelectedFilterField } from 'src/reducers/filtersReducer/filtersReducer';
import setTooltipVisible from 'src/utils/setTooltipVisibility';

interface IntFilterModalProps {
  handleModalVisibility: Function;
  reference: React.RefObject<HTMLDivElement>;
}
const IntFilterModal: React.FC<React.PropsWithChildren<IntFilterModalProps>> = (
  props: IntFilterModalProps,
) => {
  const { handleModalVisibility, reference } = props;
  const selectedFilterField = useSelector(selectSelectedFilterField);
  const [value, setValue] = useState<string>('');
  const [isDropdownOpen, setDropdownIsOpened] = useState<boolean>(false);
  const [dropdownOption, setDropdownOption] = useState<number>(0);
  const [isTitleOverFlowed, setIsTitleOverFlowed] = useState<boolean>(false);
  const [titleTooltipVisibility, setTitlteTooltipVisibility] =
    useState<boolean>(false);
  const titleRef: React.RefObject<HTMLSpanElement> = useRef(null);
  const dispatch = useDispatch();
  const regExOnlyNumbers = new RegExp(/^-?\d+\.?(\d+)?$/);

  const searchFilters = [
    'GREATER THAN',
    'LESS THAN',
    'EQUALS',
    'DOES NOT EQUAL',
  ];

  const handleDropdownState = () => {
    setDropdownIsOpened(!isDropdownOpen);
  };

  const handleDropdownOption = (index: number) => {
    setDropdownOption(index);
  };

  const applyFilterClass = classNames('IntFilterModal-applyFilterButton', {
    'IntFilterModal-EnabledApplyFilterButton': value !== '',
  });

  const dropdownSelectedOptionClass = classNames(
    'IntFilterModal-operatorDropdown',
    {
      'IntFilterModal--operatorDropdownOpen': isDropdownOpen,
    },
  );

  return (
    <div
      className='IntFilterModal-container'
      data-testid='int_modal'
      ref={reference}
    >
      <div className='IntFilterModal-titleContainer'>
        <span className='IntFilterModal-titleIcon IntFilterModal-intTypeIcon' />
        <span
          className='IntFilterModal-title'
          onMouseEnter={() =>
            setTooltipVisible(
              true,
              setTitlteTooltipVisibility,
              setIsTitleOverFlowed,
              titleRef,
              196,
            )
          }
          onMouseLeave={() =>
            setTooltipVisible(
              false,
              setTitlteTooltipVisibility,
              setIsTitleOverFlowed,
              titleRef,
              196,
            )
          }
          ref={titleRef}
        >
          {isTitleOverFlowed && titleTooltipVisibility && (
            <FiltersTooltip
              isShort
              isTitle
              title={selectedFilterField?.field_name}
            />
          )}
          {selectedFilterField?.field_name}
        </span>
      </div>
      <div
        className='IntFilterModal-dropdownOperatorContainer'
        onClick={() => handleDropdownState()}
      >
        <div className={dropdownSelectedOptionClass}>
          <span className='IntFilterModal-selectedDropdownOption'>
            {searchFilters[dropdownOption]}
          </span>
          <span className='IntFilterModal-dropdownOperatorIcon' />
          {isDropdownOpen && (
            <div className='IntFilterModal-selectedDropdownOptionBorder'></div>
          )}
        </div>
        {isDropdownOpen && (
          <div className='IntFilterModal-dropdownOptionsContainer'>
            {searchFilters.map((filter, index) => (
              <span
                className='IntFilterModal-dropdownOption'
                key={filter}
                onClick={() => handleDropdownOption(index)}
              >
                {filter}
              </span>
            ))}
          </div>
        )}
      </div>
      <InputField
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const validNumber = e.target.value.match(regExOnlyNumbers);

          if (validNumber) {
            setValue(validNumber.input as string);
          } else if (e.target.value === '') {
            setValue('');
          } else if (e.target.value === '-') {
            setValue('-');
          } else {
            setValue(value);
          }
        }}
        value={value}
      />

      {
        <button
          className={applyFilterClass}
          disabled={value === ''}
          onClick={() => {
            dispatch(
              setFilter({
                field: {
                  field_category: selectedFilterField?.field_category,
                  field_id: selectedFilterField?.field_id,
                  field_name: selectedFilterField?.field_name,
                  field_type: selectedFilterField?.field_type,
                },
                negate: dropdownOption === 3 ? true : false,
                operator: searchFilters[dropdownOption],
                values: [value],
              }),
            );
            handleModalVisibility();
            dispatch(getAnswersData());
          }}
        >
          Apply Filter
        </button>
      }
    </div>
  );
};

export default IntFilterModal;
