import React, { FC } from 'react';
import { Field } from 'formik';
import { FieldProps } from 'formik';
import { styled } from '@mui/material';

import { Colors } from '@forethought-technologies/forethought-elements';
import Checkbox from 'src/components/checkbox';
import AgentChatHandoff from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/agent-chat-handoff';
import TicketCreation from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation';
import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { textMediumStyle, textRegularStyle } from 'src/styles/styledMixin';
import { SalesforceHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface SalesforceHandoffConfigurationProps {
  customizationData: SalesforceHandoffCustomization;
}

const SalesforceHandoffConfiguration: FC<
  React.PropsWithChildren<SalesforceHandoffConfigurationProps>
> = ({ customizationData }) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isGranularizedSalesforceActionsEnabled = featureFlags.includes(
    'granularized_salesforce_actions',
  );

  return (
    <ContentWrapper>
      <Title>
        Salesforce Handoff
        {isGranularizedSalesforceActionsEnabled && ' (template)'}
      </Title>
      <Spacer height='20px' />
      <Section>
        <Field name='include_agent_chat_handoff'>
          {({ field }: FieldProps) => (
            <CheckBoxOption>
              <Checkbox checked={!!field.value} {...field} />
              <CheckboxLabel>Include agent chat handoff</CheckboxLabel>
            </CheckBoxOption>
          )}
        </Field>
        {customizationData?.include_agent_chat_handoff && (
          <AgentChatHandoff customizationData={customizationData} />
        )}
      </Section>
      <Section>
        <Field name='include_ticket_creation_handoff'>
          {({ field }: FieldProps) => (
            <CheckBoxOption>
              <Checkbox checked={!!field.value} {...field} />
              <CheckboxLabel>Include case creation handoff</CheckboxLabel>
              <Spacer height='4px' />
            </CheckBoxOption>
          )}
        </Field>
        {customizationData?.include_ticket_creation_handoff && (
          <TicketCreation customizationData={customizationData} />
        )}
      </Section>
    </ContentWrapper>
  );
};

export default SalesforceHandoffConfiguration;

const CheckBoxOption = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const CheckboxLabel = styled('p')`
  margin-left: 6px;
  ${textRegularStyle({ color: Colors.ui.text.primary, fontSize: '16px' })}
`;
