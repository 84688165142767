import React, { useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';

import { UnstyledButton } from '../../common/resetUI';
import { SESSION_COPY_PASTE_STORAGE_KEY } from 'src/pages/workflow-builder-edit/constants';
import {
  selectUser,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import * as API from 'src/services/api';

type LogoutProps = {
  className?: string;
};

const Logout: React.FC<LogoutProps> = ({ className }) => {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectUserRole);
  const { auth_time: sessionId } = user?.user ?? {};
  const clickLogout = useCallback(async () => {
    localStorage.removeItem('org');
    sessionStorage.removeItem(SESSION_COPY_PASTE_STORAGE_KEY);
    await API.emitTrackingEventApi({
      eventType: 'dashboard-sign-out',
      sessionId,
      userRole,
    });
    Auth.signOut();
  }, [sessionId, userRole]);
  return (
    <UnstyledButton className={className} onClick={clickLogout}>
      Sign Out
    </UnstyledButton>
  );
};

export default Logout;
