import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import CircularStepIcon from '../business-logic/CircularStepIcon';
import { QuasiStepTypes, StepTypes } from 'src/utils/enums';

const labelMap: Partial<Record<StepTypes | QuasiStepTypes, string>> = {
  [StepTypes.BUTTONS]: 'Options',
  [StepTypes.FORM]: 'Form',
  [StepTypes.CONDITION]: 'Condition',
  [StepTypes.IMAGE]: 'Image',
  [StepTypes.EMBED]: 'Video',
  [StepTypes.FLAMETHROWER_API_CALL]: 'Action',
  [StepTypes.GO_TO_HANDOFF]: 'Handoff',
  [StepTypes.GO_TO_INTENT]: 'Intent Routing',
  [StepTypes.RESTART_CONVERSATION]: 'Intent Routing',
  [StepTypes.ARTICLE_SUGGESTION]: 'Articles',
  [StepTypes.SET_CONTEXT_VARIABLE]: 'Set Context Variables',
  [StepTypes.SUNCO_LIVE_CHAT]: 'Handoff',
  [StepTypes.DYNAMIC_CARD]: 'Dynamic Card',
  [StepTypes.CSAT_TRIGGER_POINT]: 'Trigger',
  [StepTypes.END_INTERACTIVE_EMAIL_CHAT]: 'Handoff',
  [StepTypes.ATTACHMENT_ANALYSIS_UPLOAD]: 'Attachment',
  [QuasiStepTypes.FEEDBACK]: 'Feedback',
  [QuasiStepTypes.DYNAMIC_ARTICLE_SUGGESTION]: 'Articles',
};

const StepHeader = ({
  children,
  iconUrl,
  stepType,
}: React.PropsWithChildren<{
  iconUrl?: string;
  stepType: StepTypes | QuasiStepTypes;
}>) => {
  const { palette } = useTheme();

  const label = labelMap[stepType];

  return (
    <Box alignItems='center' display='flex' mb={1}>
      {iconUrl ? (
        <Box height='32px' mr={1}>
          <img alt={stepType} height='32px' src={iconUrl} width='32px' />
        </Box>
      ) : (
        <CircularStepIcon stepType={stepType} />
      )}
      <Typography color={palette.colors.grey[600]} variant='font11'>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default StepHeader;
