import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import TopicArticlesTable from '../../discover-topic-detail-page/TopicArticlesTable';
import TabHeader from '../common/TabHeader';
import { initialInsightParams } from '../constants';
import { stringifyDateRange } from '../helpers';
import { useGetGeneratedArticles } from '../hooks/useGetGeneratedArticles';
import ArticlesCards from './ArticlesCards';
import ArticlesPageLayout from './ArticlesPageLayout';
import ArticlesTable from './ArticlesTable';
import InsightArticleDrawer from './InsightArticleDrawer';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import { useGetPermissionsQuery } from 'src/services/dashboard-api';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { selectGeneratedArticles } from 'src/slices/solve-insights/solveInsightsSlice';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  dateRangeToTimeFilter,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { Permission } from 'src/utils/enums';

const SolveInsights = () => {
  const { palette } = useTheme();
  // Hooks
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const { data: permissions = {} } = useGetPermissionsQuery();
  const isDiscoverEnabled = orgConfigData?.discover_status === 'enabled';
  const isDiscoverPermissionEnabled = Boolean(permissions[Permission.DISCOVER]);
  const shouldIncludeGeneratedDiscoverArticles =
    isDiscoverEnabled && isDiscoverPermissionEnabled;
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { isLoadingGeneratedArticles } = useGetGeneratedArticles(
    shouldIncludeGeneratedDiscoverArticles,
  );
  //selectors
  const generatedArticles = useSelector(selectGeneratedArticles);
  // state params
  const [activeArticlesTab, setActiveArticlesTab] = useStateParams<number>({
    deserialize: param => parseInt(param, 10),
    initialState: 0,
    paramsName: 'articlesTab',
    serialize: state => state.toString(),
  });
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: initialInsightParams.date,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });
  // end state params

  const tabs = useMemo(() => {
    const tabs = ['Knowledge articles'];
    if (shouldIncludeGeneratedDiscoverArticles) {
      tabs.push('Knowledge gap');
    }
    return tabs;
  }, [shouldIncludeGeneratedDiscoverArticles]);

  return (
    <Box
      flex='1'
      overflow='auto'
      sx={{
        bgcolor: palette.colors.white,
      }}
    >
      <ArticlesPageLayout
        cards={<ArticlesCards dateRange={dateRange} />}
        header={
          <TabHeader
            dateRange={stringifyDateRange(dateRange)}
            scope='main'
            tabName='Articles'
          />
        }
        tabs={
          <>
            <Box
              sx={{
                alignItems: 'center',
                bgcolor: palette.colors.white,
                borderBottom: `1px solid ${palette.colors.slate[200]}`,
                display: 'flex',
                gap: 1,
                position: 'sticky',
                top: 0,
                width: '100%',
                zIndex: 5,
              }}
            >
              <Tabs
                a11yProps={index => ({
                  'aria-controls': `tabpanel-${index}`,
                  id: `tab-${index}`,
                })}
                aria-label='tabs to pick data table below'
                onChange={(_, tab) => setActiveArticlesTab(tab)}
                tabs={tabs}
                typographyVariant='font18'
                value={activeArticlesTab}
              />
            </Box>
            {activeArticlesTab === 0 ? (
              <ArticlesTable
                dateRange={dateRange}
                scope='main'
                setDateRange={setDateRange}
              />
            ) : null}
            {activeArticlesTab === 1 ? (
              <TopicArticlesTable
                articleData={generatedArticles}
                emitArticleClickedTrackingEvent={({ value }) =>
                  emitTrackingEventCallback(
                    'insights-clicked-on-generated-article',
                    {
                      page: 'Widget insights',
                      'page-area': 'Knowledge gap',
                      value: value,
                    },
                  )
                }
                emitSortedTrackingEvent={({ value }) =>
                  emitTrackingEventCallback(
                    'insights-sorted-generated-articles',
                    {
                      page: 'Widget insights',
                      'page-area': 'Knowledge gap',
                      value: value,
                    },
                  )
                }
                isLoading={isLoadingGeneratedArticles}
                timeFilter={dateRangeToTimeFilter(dateRange)}
              />
            ) : null}
            <InsightArticleDrawer scope='main' />
          </>
        }
      />
    </Box>
  );
};

export default SolveInsights;
