import './chart.scss';

import { memo, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import borderRadius from 'highcharts-border-radius';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import {
  checkIfArrayHasUsableData,
  convertToUsableHighChartData,
  renderChartSentimentTooltip,
  renderChartTooltip,
  sentimentBarColors,
  TooltipFormatterContextObject,
} from './chartHelper';
import debounce from 'lodash/fp/debounce';
import { DEBOUNCED_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import {
  DiscoverMetadata,
  DiscoverMetricChart,
} from 'src/reducers/discoverReducer/types';
import { selectIsSidebarExpanded } from 'src/slices/ui/uiSlice';
import { getMetricLabel } from 'src/utils/discover/helpers';

// adds border radius options
borderRadius(Highcharts);

interface ChartProps {
  backgroundColor?: string;
  chartType?: string;
  data: DiscoverMetricChart | null;
  height?: number;
  metadata: DiscoverMetadata | null;
  showCrosshair?: boolean;
  showTitle?: boolean;
  showXAxisGridLines?: boolean;
  showXYLabel?: boolean;
  trackingEvent: () => void;
  useCustomPosition?: boolean;
  width?: number;
  xLabel?: string[];
}

const createChartType = (isSentimentMetric: boolean, chartType?: string) => {
  if (chartType) {
    return chartType;
  }

  if (isSentimentMetric) {
    return 'column';
  }

  return 'spline';
};

const DiscoverChart = ({
  backgroundColor,
  chartType,
  data,
  height,
  metadata,
  showCrosshair,
  showTitle,
  showXAxisGridLines,
  showXYLabel,
  trackingEvent,
  useCustomPosition,
  width,
  xLabel,
}: ChartProps) => {
  const theme = useTheme();
  const expanded = useSelector(selectIsSidebarExpanded);
  const ref = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    const chart = ref.current?.chart;

    if (chart) {
      chart.reflow();
    }
  }, [expanded]);

  if (!data || !metadata) {
    return null;
  }

  const haveAtLeastSingleDataPoint =
    checkIfArrayHasUsableData(data.current_line) ||
    checkIfArrayHasUsableData(data.previous_line);

  if (!haveAtLeastSingleDataPoint) {
    return null;
  }

  const { current, negative, neutral, positive, previous } =
    convertToUsableHighChartData(data);

  const isSentimentMetric = data.name === 'sentiment';

  const currentChartType = createChartType(isSentimentMetric, chartType);

  const options = {
    chart: {
      /**
       * allowMutatingData
       * chart mutates data points by default for optimizations
       * this is an issue because mutating data will not be detected by react to rerender
       * The only issue turning this off is related to speed which has no issues with smaller datasets
       */
      allowMutatingData: false,
      backgroundColor,
      height,
      style: {
        fontFamily: theme.typography.fontFamily,
      },
      type: currentChartType,
      width,
    },
    credits: {
      enabled: false,
    },
    legend: { enabled: false },
    plotOptions: {
      column: isSentimentMetric
        ? {
            borderRadiusTopLeft: 2,
            borderRadiusTopRight: 2,
            stacking: 'normal',
          }
        : {
            stacking: undefined,
          },
      series: {
        animation: false,
        events: {
          mouseOut: debounce(DEBOUNCED_TRACKING_EVENT_TIMEOUT, trackingEvent),
        },
        marker: {
          enabled:
            data.current_line.length === 1 && data.previous_line.length === 1,
          radius: 2,
          symbol: 'circle',
        },
        states: {
          hover: !isSentimentMetric,
        },
      },
    },
    series: isSentimentMetric
      ? [
          {
            color: sentimentBarColors[0],
            data: negative,
          },
          {
            color: sentimentBarColors[1],
            data: neutral,
          },
          {
            color: sentimentBarColors[2],
            data: positive,
          },
        ]
      : [
          {
            color: theme.palette.colors.grey[600],
            dashStyle: currentChartType === 'spline' ? 'dash' : 'solid',
            data: previous,
            lineWidth: 1,
            zIndex: 1,
          },
          {
            color: theme.palette.colors.purple[500],
            dashStyle: 'solid',
            data: current,
            lineWidth: 1,
            zIndex: 2,
          },
        ],
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: theme.palette.colors.white,
      borderColor: theme.palette.colors.slate[200],
      borderWidth: 0,
      formatter: function (this: TooltipFormatterContextObject) {
        return isSentimentMetric
          ? renderChartSentimentTooltip({
              context: this,
              hasCustomTooltipPosition: useCustomPosition,
              isSpline: false,
              metadata,
              metric: data.name,
              previousData: data.previous_line,
            })
          : renderChartTooltip({
              context: this,
              hasCustomTooltipPosition: useCustomPosition,
              isSpline: currentChartType === 'spline',
              metadata,
              metric: data.name,
            });
      },
      hideDelay: 0,
      outside: true,
      padding: 0,
      shadow: false,
      shared: true,
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
      },
      useHTML: true,
    },
    xAxis: {
      categories: showXYLabel ? xLabel : [],
      crosshair: {
        color:
          showCrosshair && !isSentimentMetric
            ? theme.palette.colors.purple[500]
            : 'transparent',
        dashStyle: 'dash',
        width: showCrosshair ? 1 : 0,
      },
      labels: {
        enabled: showXYLabel ?? false,
      },
      lineWidth: showXAxisGridLines ? 1 : 0,
    },
    yAxis: {
      gridLineColor: showXAxisGridLines
        ? theme.palette.colors.grey[200]
        : 'transparent',
      labels: {
        enabled: showXYLabel ?? false,
      },
      title: {
        text: showTitle ? getMetricLabel(data.name) : '',
      },
    },
  };

  return (
    <HighchartsReact
      containerProps={{ style: { height: '100%' } }}
      highcharts={Highcharts}
      options={options}
      ref={ref}
    />
  );
};

const MemoizedDiscoverChart = memo(DiscoverChart);

export default MemoizedDiscoverChart;
