import { Edge, MarkerType, Node } from 'reactflow';
import { v4 as uuid } from 'uuid';

import {
  EmailConfigurationListItem,
  EmailWorkflowResponse,
  EmailWorkflowStep,
  EmailWorkflowStepType,
  EmailWorkflowValidationError,
} from '../../types';
import { getEntryNodeEdgeInfo } from '../utils';
import {
  EMAIL_WORKFLOW_NODES,
  INTENT_NODE_PREFIX,
  TERMINAL_NODE_PREFIX,
} from './constants';
import keyBy from 'lodash/fp/keyBy';
import { Helpdesk } from 'src/components/app/types';
import {
  FLOW_EDGES,
  layoutNodes,
  WorkflowEdgeData,
} from 'src/components/forethought-flow';
import { StepFields } from 'src/services/apiInterfaces';
import { Action } from 'src/types/actionBuilderApiTypes';

interface WorkflowQueueItem {
  parentStepId: string | null;
  parentStepTypesEncountered: EmailWorkflowStepType[];
  parentTransitionId: string | null;
  stepId: string;
}

/**
 * Helper class for Solve Journey Map:
 * - convert intent email workflows to the format required by reactflow library
 * - combine workflow steps with step details (ex: take action_id from backend, attach action_name and other details)
 */
export default class WorkflowConverter {
  constructor(
    private actions: Record<string, Action>,
    private intentTitle: string,
    private responses: Record<string, EmailConfigurationListItem>,
    private workflow: EmailWorkflowResponse,
    private stepErrors: {
      [stepId: string]: EmailWorkflowValidationError[];
    },
    private entryNodeData: string,
    private terminalNodeData: Record<string, string>,
    private helpdesk: Helpdesk,
  ) {}

  /**
   * Does a breadth-first traversal of stepMap, outputs edges and nodes for flow library
   */
  makeEdgesAndNodes() {
    const { entryNodeData, helpdesk, intentTitle, workflow } = this;

    const intentNode = {
      ...INTENT_NODE_BASE,
      data: { intentTitle },
    };

    const nodes: Node[] = [intentNode];
    const edges: Edge[] = [];

    const { entry_step_id: entryStepId, step_map: stepMap } = workflow;

    const entryNodeEdgeInfo = getEntryNodeEdgeInfo(helpdesk);

    const entryNodeContent = {
      iconSrc: entryNodeEdgeInfo?.iconSrc || '',
      text: entryNodeEdgeInfo?.text || '',
      tooltipContent: entryNodeData,
    };

    const entryStep = entryStepId && stepMap[entryStepId];

    // for empty stepmap or stepmap where the only step is a ticket_update, return default values
    if (!entryStep || entryStep.step_type === 'ticket_update') {
      let stepErrors: EmailWorkflowValidationError[] = [];
      if (entryStepId && this.stepErrors[entryStepId]) {
        stepErrors = this.stepErrors[entryStepId];
      }
      const terminalNodeArgs = entryStep
        ? {
            id: entryStepId,
            stepErrors,
            stepFields: entryStep.step_fields,
            stepTypesEncountered: entryStep.step_types_encountered,
          }
        : { stepErrors, stepTypesEncountered: [] };

      const terminalNode = this.makeTerminalNode(terminalNodeArgs);
      nodes.push(terminalNode);
      const edges = [
        this.makeEdge({
          edgeData: {
            infoLabel: {
              ...entryNodeContent,
            },
          },
          source: nodes[0].id,
          target: nodes[1].id,
        }),
      ];

      return {
        edges,
        nodes: layoutNodes(nodes, edges),
      };
    }

    const queue: WorkflowQueueItem[] = [
      {
        parentStepId: null,
        parentStepTypesEncountered: [],
        parentTransitionId: null,
        stepId: entryStepId,
      },
    ];

    while (queue.length) {
      const next = queue.pop();
      if (!next) {
        continue;
      }
      const {
        parentStepId,
        parentStepTypesEncountered,
        parentTransitionId,
        stepId,
      } = next;
      const currStep = stepMap[stepId];

      const node = this.makeNode({
        parentStepId,
        parentStepTypesEncountered,
        parentTransitionId,
        step: currStep,
        stepId,
      });
      nodes.push(node);

      if (!currStep || currStep.step_type === 'ticket_update') {
        continue;
      }

      // If this is the entry node, create edge connecting it to the intent node
      if (stepId === entryStepId) {
        edges.push(
          this.makeEdge({
            edgeData: {
              infoLabel: {
                ...entryNodeContent,
              },
            },
            source: intentNode.id,
            target: stepId,
          }),
        );
      }

      // If this is a leaf node, add a placeholder child
      if (currStep.transitions.length === 0) {
        const terminalNode = this.makeTerminalNode({
          parentStepId: stepId,
          stepErrors: [],
          stepTypesEncountered: currStep.step_types_encountered,
        });
        nodes.push(terminalNode);
        edges.push(
          this.makeEdge({
            edgeData: {},
            source: stepId,
            target: terminalNode.id,
          }),
        );
        continue;
      }

      const { step_type: stepType, transitions } = currStep;
      const isOtherwiseSelected =
        transitions.length > 0 &&
        transitions[transitions.length - 1].condition_expression === null;

      transitions.forEach((transition, index) => {
        const { step_id: adjacentStepId, transition_id: transitionId } =
          transition;

        let targetStepId = adjacentStepId;

        if (!targetStepId) {
          targetStepId = this.generateTerminalNodeId();
        }

        queue.unshift({
          parentStepId: currStep.step_id,
          parentStepTypesEncountered: currStep.step_types_encountered,
          parentTransitionId: transitionId,
          stepId: targetStepId,
        });

        let endLabel = '';
        if (stepType === 'rule' && transitions.length > 1) {
          if (isOtherwiseSelected && index === transitions.length - 1) {
            endLabel = 'Otherwise';
          } else {
            endLabel = `Path ${index + 1}`;
          }
        }

        edges.push(
          this.makeEdge({
            edgeData: {
              endLabel,
              transitionId,
            },
            source: stepId,
            target: targetStepId,
          }),
        );
      });
    }

    const positionedNodes = layoutNodes(nodes, edges);

    return {
      edges,
      nodes: positionedNodes,
    };
  }

  generateTerminalNodeId() {
    return `${TERMINAL_NODE_PREFIX}-${uuid()}`;
  }

  makeNode({
    parentStepId,
    parentStepTypesEncountered = [],
    parentTransitionId = null,
    step,
    stepId,
  }: {
    parentStepId: string | null;
    parentStepTypesEncountered: EmailWorkflowStepType[];
    parentTransitionId: string | null;
    step?: EmailWorkflowStep;
    stepId: string;
  }): Node {
    if (!step || step.step_type === 'ticket_update') {
      return this.makeTerminalNode({
        id: stepId,
        parentStepId,
        parentTransitionId,
        stepErrors: this.stepErrors[stepId] ?? [],
        stepFields: step?.step_fields,
        stepTypesEncountered: parentStepTypesEncountered,
      });
    }

    if (step.step_type === 'action') {
      const action = this.actions[step.step_fields.action_id];
      return {
        data: {
          action,
          parentTransitionId,
          step,
          stepErrors: this.stepErrors[stepId] ?? [],
          stepId,
        },
        id: stepId,
        position: { x: 0, y: 0 },
        type: step.step_type,
      };
    }

    if (step.step_type === 'response') {
      const response =
        this.responses[step.step_fields.intent_email_configuration_id];
      return {
        data: {
          parentTransitionId,
          response,
          step,
          stepErrors: this.stepErrors[stepId] ?? [],
          stepId,
        },
        id: stepId,
        position: { x: 0, y: 0 },
        type: step.step_type,
      };
    }

    if (step.step_type === 'rule') {
      return {
        data: {
          parentTransitionId,
          rule: {},
          step,
          stepErrors: this.stepErrors[stepId] ?? [],
          stepId,
        },
        id: stepId,
        position: { x: 0, y: 0 },
        type: step.step_type,
      };
    }

    throw new Error('step type not yet supported');
  }

  makeEdge({
    edgeData = {},
    source,
    target,
  }: {
    edgeData?: WorkflowEdgeData;
    source: string;
    target: string;
  }) {
    return {
      data: edgeData,
      id: `edge-from-${source}-to-${target}`,
      markerEnd: { strokeWidth: 2, type: MarkerType.Arrow },
      source,
      target,
      type: FLOW_EDGES.WORKFLOW,
    };
  }

  makeTerminalNode({
    id,
    parentStepId = null,
    parentTransitionId = null,
    stepErrors,
    stepFields,
    stepTypesEncountered,
  }: {
    id?: string;
    parentStepId?: string | null;
    parentTransitionId?: string | null;
    stepErrors: EmailWorkflowValidationError[];
    stepFields?: StepFields;
    stepTypesEncountered: EmailWorkflowStepType[];
  }) {
    if (!id) {
      id = this.generateTerminalNodeId();
    }
    const { automated, not_automated } = this.terminalNodeData;

    const automatedValue = stepTypesEncountered.includes('response')
      ? automated
      : not_automated;

    return {
      data: {
        automatedValue,
        helpdesk: this.helpdesk,
        parentStepId,
        parentTransitionId,
        stepErrors,
        stepFields,
      },
      id,
      position: { x: 0, y: 0 },
      type: EMAIL_WORKFLOW_NODES.TERMINAL,
    };
  }

  static reconcileNodes({
    newEdges,
    newNodes,
    prevNodes,
  }: {
    newEdges: Edge[];
    newNodes: Node[];
    prevNodes: Node[];
  }) {
    // hydrate new nodes with prev nodes 'selected' state
    const prevNodesById = keyBy('id', prevNodes);
    const hydratedNodes = newNodes.map(node => {
      return {
        ...node,
        selected: Boolean(prevNodesById[node.id]?.selected),
      };
    });
    // position nodes with prevEdges to maintain placeholder placement
    const positionedNodes = layoutNodes(hydratedNodes, newEdges);
    return positionedNodes;
  }
}

const INTENT_NODE_BASE: Node = {
  data: {},
  id: `${INTENT_NODE_PREFIX}-${uuid()}`,
  position: { x: 0, y: 0 },
  type: 'intent',
};
