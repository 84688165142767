import { Box, styled, useTheme } from '@mui/material';
import { IconExternalLink } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import IntentEmailArticleError from './IntentEmailArticleError';
import { IdleCanvasComponentProps } from 'src/components/email-builder/types';
import useStrippedHTML from 'src/hooks/useStrippedHTML';

export default function IntentEmailArticle({
  component,
}: IdleCanvasComponentProps) {
  const { palette } = useTheme();
  const {
    display_field_errors: displayFieldErrors = [],
    display_fields: displayFields = {},
  } = component;
  const { link, preview, title } = displayFields;
  const strippedPreview = useStrippedHTML(preview);

  if (displayFieldErrors.includes('article-not-found')) {
    return <IntentEmailArticleError />;
  }

  return (
    <Box padding='16px'>
      <Card>
        <div>
          <Typography variant='font16Bold'>{title}</Typography>
        </div>
        <PreviewContainer>
          <Preview color={palette.colors.grey[600]} variant='font14'>
            {strippedPreview}
          </Preview>
        </PreviewContainer>
        {link && (
          <div>
            <Typography color={palette.colors.grey[500]} variant='font12'>
              {new URL(link).hostname}
            </Typography>
          </div>
        )}
        <Box alignItems='center' display='flex' gap='4px'>
          <Link href={link} rel='nofollow' target='_blank'>
            Read article
          </Link>
          <IconExternalLink size={20} />
        </Box>
      </Card>
    </Box>
  );
}

const Card = styled('div')`
  border: 1px solid ${props => props.theme.palette.colors.slate[200]};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Link = styled('a')`
  color: ${props => props.theme.palette.colors.black};
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
`;

const PreviewContainer = styled('div')`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
`;

const Preview = styled(Typography)`
  max-height: 80px;
`;
