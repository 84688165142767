import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';

interface InsightCardProps {
  headerRightText?: string;
  isFetching?: boolean;
  isLoading?: boolean;
  justifyContent?: string;
  title?: string;
  tooltip?: React.ReactNode;
}

const InsightCard: React.FC<React.PropsWithChildren<InsightCardProps>> = ({
  children,
  headerRightText,
  isFetching,
  isLoading,
  justifyContent = 'space-between',
  title,
  tooltip,
}) => {
  const { palette } = useTheme();
  return (
    <Box
      border={`1px solid ${palette.colors.slate[200]}`}
      borderRadius='8px'
      display='flex'
      flex='1'
      flexDirection='column'
      gap='16px'
      justifyContent={justifyContent}
      minWidth='419px' // From design -> overall width is 420px, 419px from the card and 1px from the border
      p='24px'
    >
      {isLoading || isFetching ? (
        <>
          <Skeleton height={22}></Skeleton>
          <Skeleton height={196} />
        </>
      ) : (
        <>
          {!isLoading && (
            <Box display='flex' justifyContent='space-between'>
              <Box alignItems='center' display='flex' gap={1}>
                <Typography variant='font16Bold'>{title}</Typography>
                {tooltip}
              </Box>
              <Typography color={palette.colors.grey[600]} variant='font12'>
                {headerRightText}
              </Typography>
            </Box>
          )}
          {children}
        </>
      )}
    </Box>
  );
};

export default InsightCard;
