import { useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Button,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ADD_LABEL_FIELDS, REQUIRED_TRAINING_PHRASES } from '../constants';
import { createSaveLabelTooltip, isModelReadOnly } from '../helpers';
import { TagFormValue } from '../types';
import MultiTextField from './MultiTextField';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

interface AddLabelFormProps {
  initialValues?: TagFormValue;
  model: VersionedTriageModel;
  onDelete: () => void;
  onSuccess: (values: TagFormValue) => void;
  tagId: string | null;
}

const initialFormState: TagFormValue = {
  addTrainingPhrase: '',
  description: '',
  name: '',
  trainingPhrases: [],
};

const validationSchema = Yup.object().shape({
  description: Yup.string().required('This field is required'),
  name: Yup.string().required('This field is required'),
  trainingPhrases: Yup.array().min(
    REQUIRED_TRAINING_PHRASES,
    'At least two phrases are required',
  ),
});

const AddLabelForm = ({
  initialValues = initialFormState,
  model,
  onDelete,
  onSuccess,
  tagId,
}: AddLabelFormProps) => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useSelfServeEvents({
    label: initialValues.name,
    model,
    tagId,
  });

  const greyColor = palette.colors.grey[600];
  const lightColor = palette.colors.grey[400];
  const redColor = palette.colors.red[500];
  const isTagExisting = Boolean(tagId);
  const isModelEditable = !isModelReadOnly(model);
  const drawerTitle = isTagExisting ? 'Edit label' : 'Create label';

  const handleDelete = useCallback(() => {
    emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.DELETE_LABEL);
    onDelete();
  }, [emitTrackingEventCallback, onDelete]);

  const handleSuccess = useCallback(
    (values: TagFormValue) => {
      const eventType = tagId
        ? TRIAGE_LLM_TRACKING_EVENTS.UPDATE_LABEL
        : TRIAGE_LLM_TRACKING_EVENTS.CREATE_LABEL;
      emitTrackingEventCallback(eventType);
      onSuccess(values);
    },
    [emitTrackingEventCallback, tagId, onSuccess],
  );

  return (
    <Formik<TagFormValue>
      initialValues={initialValues}
      onSubmit={handleSuccess}
      validate={(values: TagFormValue) => {
        if (
          model.labels.some(
            label =>
              label.title.toLocaleLowerCase() ===
                values.name.toLocaleLowerCase() && label.tag_id !== tagId,
          )
        ) {
          return { name: 'Label already exists' };
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldTouched,
        setFieldValue,
        touched,
        values,
      }) => {
        const hasInvalidFormValues =
          !isValid || !dirty || Boolean(Object.keys(errors).length);
        const saveIsDisabled = hasInvalidFormValues || !isModelEditable;

        return (
          <Form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              overflow='auto'
              rowGap='32px'
            >
              <Typography variant='font20'>{drawerTitle}</Typography>
              {ADD_LABEL_FIELDS.map(field => (
                <Box
                  display='flex'
                  flexDirection='column'
                  key={field.key}
                  rowGap={1}
                >
                  <Typography variant='font16Bold'>{field.label}</Typography>
                  <Box display='flex' flexDirection='column' rowGap={0.5}>
                    {Boolean(field.description) && (
                      <Typography color={greyColor} variant='font12'>
                        {field.description}
                      </Typography>
                    )}
                    <TextField
                      aria-label={field.label}
                      disabled={!isModelEditable}
                      error={
                        Boolean(errors[field.key]) &&
                        (Boolean(touched[field.key]) ||
                          Boolean(values[field.key]))
                      }
                      maxLength={field.maxLength}
                      multiline={field.multiline}
                      onBlur={() => setFieldTouched(field.key)}
                      onChange={e => {
                        setFieldValue(field.key, e.target.value);
                      }}
                      placeholder={field.placeholder}
                      required
                      value={values[field.key]}
                    />
                    {Boolean(field.info) && (
                      <Typography color={lightColor} variant='font12'>
                        {field.info}
                      </Typography>
                    )}
                    {Boolean(errors[field.key]) &&
                      (Boolean(touched[field.key]) ||
                        Boolean(values[field.key])) && (
                        <Typography color={redColor} variant='font12'>
                          {errors[field.key]}
                        </Typography>
                      )}
                  </Box>
                </Box>
              ))}
              <MultiTextField
                addValue={values.addTrainingPhrase}
                description='Input at least two training phrases for the AI to better classify tickets within this label.'
                disabled={!isModelEditable}
                error={
                  touched.trainingPhrases ? errors.trainingPhrases : undefined
                }
                label='Training phrases'
                onBlur={e => {
                  handleBlur(e);
                }}
                setAddValue={value => {
                  setFieldValue('addTrainingPhrase', value);
                }}
                setValues={items => {
                  setFieldTouched('trainingPhrases');
                  setFieldValue('trainingPhrases', items);
                }}
                values={values.trainingPhrases}
              />
            </Box>
            {isModelEditable && (
              <Box
                bottom={0}
                display='flex'
                gap='8px'
                position='sticky'
                pt={1}
                sx={{ backgroundColor: palette.colors.white }}
                width='100%'
              >
                {isTagExisting && (
                  <Box>
                    <Tooltip
                      fullWidth={true}
                      tooltipContent={
                        !isModelEditable &&
                        'Labels cannot be deleted for a published version.'
                      }
                    >
                      <Button
                        disabled={!isModelEditable || isSubmitting}
                        onClick={handleDelete}
                        variant='danger'
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  </Box>
                )}
                <Box flex={1}>
                  <Tooltip
                    fullWidth={true}
                    tooltipContent={createSaveLabelTooltip({
                      isModelEditable,
                      trainingPhrases: values.trainingPhrases,
                    })}
                  >
                    <Button
                      color='primary'
                      disabled={saveIsDisabled}
                      fullWidth
                      isLoading={isSubmitting}
                      type='submit'
                      variant='main'
                    >
                      {isTagExisting ? 'Save' : 'Create'}
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
`;

export default AddLabelForm;
