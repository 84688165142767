import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';

export const ExpandingField = styled('div')<{ isPending: boolean }>`
  background-color: ${p =>
    p.isPending
      ? theme.palette.colors.yellow[100]
      : theme.palette.colors.green[100]};
  border: 1px solid;
  border-color: ${p =>
    p.isPending ? 'transparent' : theme.palette.colors.green[200]};
  border-radius: 4px;
  overflow: hidden;
`;

export const EventDetails = styled('div')<{ isPending: boolean }>`
  cursor: ${p => (p.isPending ? 'auto' : 'pointer')};
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentLeft = styled('div')`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const Info = styled('div')`
  align-items: center;
  background-color: ${theme.palette.colors.green[200]};
  color: ${theme.palette.colors.green[600]};
  display: flex;
  gap: 14px;
  padding: 8px 14px;
`;

export const Dropdown = styled('div')<{ isOpen: boolean }>`
  display: ${p => (p.isOpen ? 'block' : 'none')};
`;

export const IconInfo = styled(InfoIcon)`
  height: 20px;
  width: 20px;
  padding: 0 6px;
`;

export const IconChevron = styled(KeyboardArrowDownIcon)<{ isOpen: boolean }>`
  height: 18px;
  width: 18px;
  transform: ${p => (p.isOpen ? 'rotate(-90deg)' : 'none')};
  transition: transform 150ms ease-in-out;
`;

export const TooltipRoot = styled('span')<{ isPending: boolean }>`
  color: ${p =>
    p.isPending
      ? theme.palette.colors.yellow[600]
      : theme.palette.colors.grey[700]};
`;
