import { SolveConfigContentContainer, ToolbarContainer } from '..';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { VerticalTabBar } from '@forethought-technologies/forethought-elements';
import { SOLVE_SLACK_CONFIG_TABS } from '../constants';
import { useGetSlackTabIndex } from '../hooks/useGetTabIndex';
import StaticSolveWidget from '../static-solve-widget/StaticSolveWidget';
import { Channel } from '../types';
import SlackIntegrationContent from './slack-integration-tab-content/SlackIntegrationContent';
import { useSolveConfigTrackingEventAction } from 'src/hooks/hooks';
import {
  selectIsValidationFailing,
  showWarningModal,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

export default function SlackTab({ channel }: { channel: Channel }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('tab') ?? '';

  const isValidationFailing = useSelector(selectIsValidationFailing);

  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  const handleTabOnClick = (index: number) => {
    // if validation is failing, we show the warning modal and exit the function
    if (isValidationFailing) {
      dispatch(showWarningModal());
      return;
    }
    const tab = SOLVE_SLACK_CONFIG_TABS[index].id;
    navigate(
      {
        pathname: Routes.SOLVE_CONFIGURATION,
        search: `?channel=${channel}&tab=${tab}`,
      },
      { replace: true },
    );
    dispatchTrackingAction(SolveConfigTrackingEventTypes.TAB_NAVIGATION, {
      tab: tab,
    });
  };

  const tabIndex = useGetSlackTabIndex();

  const handleRenderApiConfigurationBasedOnTabType = () => {
    switch (query) {
      case 'integrate':
        return <SlackIntegrationContent />;
      default:
        return <SlackIntegrationContent />;
    }
  };

  const isFullWidthContent = query === 'more';

  return (
    <>
      {!isFullWidthContent && (
        <SolveConfigContentContainer flexDirection='column'>
          <StaticSolveWidget type='minimized'>
            {handleRenderApiConfigurationBasedOnTabType()}
          </StaticSolveWidget>
        </SolveConfigContentContainer>
      )}
      <ToolbarContainer
        isFullScreen={isFullWidthContent}
        isTabSelected={typeof tabIndex === 'number'}
      >
        <VerticalTabBar
          setTabIndex={handleTabOnClick}
          tabBodyWidth={520}
          tabIndex={tabIndex}
          tabs={SOLVE_SLACK_CONFIG_TABS}
        />
      </ToolbarContainer>
    </>
  );
}
