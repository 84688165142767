import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PredictionMetricsOverall } from './types';
import { getPredictionMetricsOverallApi } from 'src/services/triage/api';
import { GetPredictionMetricsOverallParams } from 'src/services/triage/types';
import type { RootState } from 'src/store/rootReducer';

export interface TriageAnalyticsState {
  predictionMetricsOverall: {
    data: PredictionMetricsOverall | null;
    error: boolean;
    loading: boolean;
  };
}

const initialState: TriageAnalyticsState = {
  predictionMetricsOverall: {
    data: null,
    error: false,
    loading: false,
  },
};

export const getPredictionMetricsOverall = createAsyncThunk(
  'triageAnalytics/getPredictionMetricsOverall',
  (params: GetPredictionMetricsOverallParams) => {
    return getPredictionMetricsOverallApi(params);
  },
);

const triageAnalyticsSlice = createSlice({
  extraReducers(builder) {
    builder.addCase(
      getPredictionMetricsOverall.fulfilled,
      (state, { payload }) => {
        state.predictionMetricsOverall.data = payload;
        state.predictionMetricsOverall.loading = false;
        state.predictionMetricsOverall.error = false;
      },
    );
    builder.addCase(getPredictionMetricsOverall.pending, state => {
      state.predictionMetricsOverall.loading = true;
      state.predictionMetricsOverall.data = null;
    });
    builder.addCase(getPredictionMetricsOverall.rejected, state => {
      state.predictionMetricsOverall.error = true;
      state.predictionMetricsOverall.loading = false;
    });
  },
  initialState,
  name: 'triageAnalytics',
  reducers: {},
});

export const selectPredictionMetricsOverall = (
  state: RootState,
): TriageAnalyticsState['predictionMetricsOverall'] =>
  state.triageAnalytics.predictionMetricsOverall;

export default triageAnalyticsSlice.reducer;
