import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { BadgesForWorkflow } from '../workflow-builder/intent-workflows-table/BadgesForWorkflow';
import { useGetHandoffConfigurationForWorkflow } from './hooks';
import { WorkflowDescription } from './WorkflowDescription';
import iconFallback from 'src/assets/images/fallback.svg';
import {
  selectCanvasWorkflowBuilder,
  selectIntentId,
  selectIsEditMode,
  selectIsFallback,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { CommonIntentWorkflowType } from 'src/utils/enums';

const ForethoughtIntent = () => {
  const isEditMode = useSelector(selectIsEditMode);
  const handoffConfiguration = useGetHandoffConfigurationForWorkflow();
  const intentId = useSelector(selectIntentId);
  const isFallback = useSelector(selectIsFallback);

  const isHandoff = handoffConfiguration !== undefined;
  const isKnowledgeRetrieval =
    intentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;

  return (
    <Intent
      disabled={isEditMode}
      isFallback={isFallback}
      isHandoff={isHandoff}
      isKnowledgeRetrieval={isKnowledgeRetrieval}
    >
      <ChatContainer>
        <Header />
        <WorkflowDescription />
      </ChatContainer>
    </Intent>
  );
};

function Header(): JSX.Element {
  const theme = useTheme();
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const handoffConfiguration = useGetHandoffConfigurationForWorkflow();
  const intentId = useSelector(selectIntentId);
  const isFallback = useSelector(selectIsFallback);

  const phrases = canvasData.intent?.phrases;

  const isHandoff = handoffConfiguration !== undefined;
  const isDefaultHandoff =
    handoffConfiguration !== undefined &&
    handoffConfiguration.is_default_handoff;
  const isKnowledgeRetrieval =
    intentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;

  if (isHandoff || isKnowledgeRetrieval) {
    return (
      <Box display='flex' gap='8px' minHeight='28px'>
        <BadgesForWorkflow
          isDefaultHandoff={isDefaultHandoff}
          isHandoff={isHandoff}
        />
      </Box>
    );
  }

  if (isFallback) {
    return (
      <Box alignItems='center' display='flex' gap='8px' minHeight='28px'>
        <img alt='' src={iconFallback} />
        <Typography color={theme.palette.colors.white} variant='font14'>
          The default last response provided to address customer queries
        </Typography>
      </Box>
    );
  }

  return (
    <LeftContainer>
      <Typography color={theme.palette.colors.grey[500]} variant='font11'>
        User says
      </Typography>
      <Typography variant='font14'>
        <ItalicSpan>
          &quot;
          {phrases?.[0]?.phrase_text ?? canvasData.intent_title}
          &quot;
        </ItalicSpan>
      </Typography>
    </LeftContainer>
  );
}

const LeftContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const ItalicSpan = styled('div')`
  font-style: italic;
`;

const Intent = styled('div')<{
  disabled?: boolean;
  isFallback: boolean;
  isHandoff: boolean;
  isKnowledgeRetrieval: boolean;
}>`
  padding: 20px;
  border-radius: 16px 16px 0 0;
  ${props =>
    !props.isHandoff &&
    !props.isKnowledgeRetrieval &&
    !props.isFallback &&
    'padding: 20px 16px 0 16px;'}
  ${props => props.disabled && 'pointer-events: none; opacity: 0.4;'}
  ${props =>
    props.isHandoff &&
    'background: linear-gradient(270deg, #6BDAB6 0%, #66E0F6 100%);'}
      ${props =>
    props.isKnowledgeRetrieval &&
    'background: linear-gradient(270deg, #BD00FF 0%, #3866F5 100%);'}
  ${props =>
    props.isFallback &&
    'background: linear-gradient(270deg, #EC4A77 0%, #FF70C6 100%);'}
`;

const ChatContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default ForethoughtIntent;
