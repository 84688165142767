import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Routes } from 'src/utils/enums';

const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(Routes.PREDICTIONS_SETTINGS);
  };

  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='center'
      rowGap={2}
      width='100%'
    >
      <Typography variant='font18'>Not found</Typography>
      <Button onClick={handleClick} variant='main'>
        Back to Triage
      </Button>
    </Box>
  );
};

export default NotFound;
