import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Card from '../card/card';
import CreateReportModal from '../new-report-modal/index';
import { getAnswersData } from 'src/actions/data/data';
import { setCurrentReport } from 'src/actions/reports/reports';
import {
  selectIsReportModified,
  selectReport,
} from 'src/reducers/reportsReducer/reportsReducer';
import { selectReportsData } from 'src/reducers/reportsReducer/reportsReducer';
import { ChartTotals, Report, TableTotals } from 'src/services/apiInterfaces';

interface Props {
  chartTotals?: ChartTotals;
  tableTotals?: TableTotals;
  viewMode: string;
}

export const AnswersAnalyticsCards: React.FC<
  React.PropsWithChildren<Props>
> = ({ chartTotals, tableTotals, viewMode }: Props) => {
  const dispatch = useDispatch();

  const selectedReport: Report | undefined = useSelector(selectReport);
  const reportsData = useSelector(selectReportsData);
  const isReportModified: boolean = useSelector(selectIsReportModified);

  const reportsOrder: number[] = reportsData?.user_bookmarked_report_ids || [];

  // Function that creates an object arr with reports in bookmarked reports and then sorts arr by the order they appear in reportsOrder
  const reports = reportsData?.reports.slice() || [];

  const reportsCards = reports
    .filter((report: Report) =>
      reportsOrder.includes(report.report_id as number),
    )
    .sort(
      (a: Report, b: Report) =>
        reportsOrder.indexOf(a.report_id as number) -
        reportsOrder.indexOf(b.report_id as number),
    );

  if (selectedReport) {
    const report = reportsCards.find(
      rep => rep.report_id === selectedReport.report_id,
    );
    if (!report) {
      reportsCards.push(selectedReport);
    }
  }

  return (
    <div className='cards-container' data-testid='card'>
      {reportsCards &&
        reportsCards.map((report: Report, index: number) => {
          const yAxis =
            report.query?.query_type === 'TICKET'
              ? 'engaged_tickets'
              : 'active_agents';
          const selectedReportID = selectedReport?.report_id;

          const isReportSelected = selectedReportID === report.report_id;

          const reportName = report && report?.name.toLowerCase();
          const cardClasses = classNames('card-cont', {
            'c-focused': isReportSelected,
            'c-not-focused': !isReportSelected,
          });

          let totals: { [index: string]: number } = {};
          if (viewMode === 'Chart' && chartTotals) {
            for (const yProperty in chartTotals) {
              totals[yProperty] = chartTotals[yProperty].aggregate;
            }
          }
          if (viewMode === 'Table' && tableTotals) {
            totals = tableTotals;
          }

          return (
            <Card
              class={cardClasses}
              count={totals?.[yAxis]}
              description={reportName}
              isBookmarked={reportsData?.user_bookmarked_report_ids.includes(
                report.report_id as number,
              )}
              isDefault={report.is_default}
              isModified={isReportSelected ? isReportModified : false}
              isReportSelected={isReportSelected}
              key={index}
              onClick={() => {
                if (!isReportSelected) {
                  dispatch(setCurrentReport(report));
                  dispatch(getAnswersData());
                }
              }}
              report_id={report.report_id}
              tooltipClass={'first-card'}
              tooltipText={report.description}
              tooltipTitle={report.name}
            />
          );
        })}

      <CreateReportModal />
    </div>
  );
};
