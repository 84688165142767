import './index.scss';

import React from 'react';

interface Props {
  /** text to be displayed on label */
  name: string;
}

const SettingsLabel: React.FC<React.PropsWithChildren<Props>> = ({
  name,
}: Props) => {
  return <span className='SettingsLabel'>{name}</span>;
};

export default SettingsLabel;
