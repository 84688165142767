import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';

interface TriageConfigDetailLinkTextProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const TriageConfigDetailLinkText = ({
  children,
  onClick,
}: TriageConfigDetailLinkTextProps) => {
  const { palette } = useTheme();
  return (
    <Box
      display='flex'
      onClick={onClick}
      sx={{ cursor: 'pointer', width: 'fit-content' }}
    >
      <Typography color={palette.colors.purple[500]} variant='font12'>
        {children}
      </Typography>
    </Box>
  );
};

export default TriageConfigDetailLinkText;
