import React from 'react';
import { Box } from '@mui/material';

import {
  IconButton,
  MultiStringInput,
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import TriageSampleValuesDropdown from '../triage-sample-values-dropdown/TriageSampleValuesDropdown';
import {
  booleanOperatorOptions,
  checkboxTypeValueOptions,
  expressionData,
} from './constants';
import { QueryExpressionInputType } from './types';
import { getDropdownValue } from './utils';
import deleteIcon from 'src/assets/images/delete-icon.svg';
import { DeleteIcon } from 'src/pages/workflow-builder-edit/conditions/ConditionEditor';
import { ConditionExpressions } from 'src/services/apiInterfaces';
import { QueryExpressionTypes } from 'src/types/queryExpressionTypes';

export interface FieldOption {
  category?: string;
  fieldOptions?: Array<{ label: string; value: string }>;
  label: string;
  optionStartAdornment?: React.ReactNode;
  type: QueryExpressionTypes;
  value: string;
}

interface ExpressionBuilderProps {
  /** Boolean operator for boolean aggregator expressions */
  booleanOperator?: string;
  /** Query expression to be rendered */
  expression: ConditionExpressions;
  /** List of options to be rendered in the field dropdown */
  fieldOptions: Array<FieldOption>;
  /** Adornemnt for selected field value */
  fieldStartAdornment?: React.ReactNode;
  /** Type of the field selected */
  fieldType: QueryExpressionTypes;
  /** Index of the query expression */
  index: number;
  /** Determines if the dropdown of the boolean operator selector is disabled */
  isBooleanOperatorDisabled: boolean;
  /** Determines if the delete button is disabled */
  isDeleteButtonDisabled: boolean;
  /** Options to render as values in the when field of type list */
  listTypeOptions?: Array<{ label: string; value: string }>;
  /** Function to be executed when the delete button is clicked */
  onDeleteButtonClick?: () => void;
  /** Function to be executed when a value for the boolean operator is selected */
  onSelectBooleanOperator?: (value: string) => void;
  /** Function to be executed when a value for the expression field is selected */
  onSelectedField: (fieldValue: string) => void;
  /** Function to be executed when a value for the expression operator is selected */
  onSelectOperator: ({
    negate,
    value,
  }: {
    negate: boolean;
    value: string;
  }) => void;
  /** Function to be executed when a value for the expression value is selected */
  onValueChange: (value: string | number | boolean | Array<string>) => void;
  /** Model of the triage model mapped to the expression */
  triageModel?: string;
}

const ExpressionBuilder = ({
  booleanOperator,
  expression,
  fieldOptions,
  fieldStartAdornment,
  fieldType,
  index,
  isBooleanOperatorDisabled,
  isDeleteButtonDisabled,
  listTypeOptions = [],
  onDeleteButtonClick,
  onSelectBooleanOperator,
  onSelectedField,
  onSelectOperator,
  onValueChange,
  triageModel,
}: ExpressionBuilderProps) => {
  const operatorsByType = expressionData[fieldType] || [];

  const operatorOptions = operatorsByType.map(data => ({
    label: data.label,
    value: data.label,
  }));

  const inputType = operatorsByType.find(
    data => data.value === expression.operator,
  )?.inputType as QueryExpressionInputType;

  const expressionValues = expression.values;
  const expressionOperator = expression.operator;
  const isListTypeField = fieldType === 'LIST';
  const isMultiOptionsInput = inputType === 'multi_string';

  const getDropdownOptions = () => {
    if (isListTypeField) {
      return listTypeOptions;
    } else {
      return checkboxTypeValueOptions;
    }
  };

  const getExpressionValue = (type: QueryExpressionInputType) => {
    const value = expressionValues?.[0];
    if (type === 'dropdown') {
      return getDropdownValue(isListTypeField, value) || '';
    }

    return expressionValues && expressionValues.length ? String(value) : '';
  };

  const inputTypeSelector: {
    [inputType in QueryExpressionInputType]: React.ReactNode;
  } = {
    dropdown: (
      <SelectDropdown
        disabled={expressionOperator === 'empty'}
        id={`dropdown-type-selector-${index}`}
        onChange={e => {
          const value = e.target.value;
          onChangeValueHandlerByInputType(value, 'dropdown');
        }}
        options={getDropdownOptions()}
        placeholder='Select a value'
        value={getExpressionValue('dropdown') as string}
      />
    ),
    multi_string: (
      <MultiStringInput
        onChange={(_, value) =>
          onChangeValueHandlerByInputType(value, 'multi_string')
        }
        options={[]}
        placeholder='Add keywords on enter'
        value={expressionValues as Array<string>}
      />
    ),
    numeric_input: (
      <TextField
        aria-label={`Value for expression ${index + 1}`}
        disabled={expressionOperator === 'empty'}
        onChange={e =>
          onChangeValueHandlerByInputType(e.target.value, 'numeric_input')
        }
        placeholder='Enter a value'
        type='number'
        value={getExpressionValue('numeric_input') as string}
      />
    ),
    text_input: (
      <TextField
        aria-label={`Value for expression ${index + 1}`}
        disabled={expressionOperator === 'empty'}
        onChange={e => {
          onChangeValueHandlerByInputType(e.target.value, 'text_input');
        }}
        placeholder='Enter a value'
        value={getExpressionValue('text_input') as string}
      />
    ),
  };

  const onChangeValueHandlerByInputType = (
    receivedValue: string | Array<string>,
    type: QueryExpressionInputType,
  ) => {
    let value: string | boolean | number | Array<string> = receivedValue;

    switch (type) {
      case 'numeric_input': {
        value = Number(receivedValue);
        break;
      }
      case 'dropdown': {
        if (isListTypeField) {
          value = receivedValue;
        } else {
          const isTrue = receivedValue === 'true';
          value = isTrue;
        }
        break;
      }
    }

    onValueChange(value);
  };

  return (
    <Box alignItems='center' display='flex' gap='7px'>
      {booleanOperator && (
        <Box width='76px'>
          <SelectDropdown
            disabled={isBooleanOperatorDisabled}
            id={`select-boolean-operator-${index}`}
            onChange={e =>
              onSelectBooleanOperator && onSelectBooleanOperator(e.target.value)
            }
            options={booleanOperatorOptions}
            value={booleanOperator || ''}
          />
        </Box>
      )}

      <Box width='200px'>
        <SelectDropdown
          id={`select-query-expression-field-${index}`}
          isMenuSearchable
          onChange={e => onSelectedField(e.target.value)}
          options={fieldOptions}
          startAdornment={fieldStartAdornment}
          value={expression.field || ''}
        />
      </Box>
      <Box width='164px'>
        <SelectDropdown
          id={`select-query-expression-operator-${index}`}
          onChange={e => {
            const operator = operatorsByType.find(
              option => option.label === e.target.value,
            );
            onSelectOperator({
              negate: !!operator?.negate,
              value: operator?.value || '',
            });
          }}
          options={operatorOptions}
          value={
            operatorsByType.find(
              operator =>
                operator.value === expression.operator &&
                operator.negate === expression.negate,
            )?.label || ''
          }
        />
      </Box>
      <Box width='270px'>
        {triageModel ? (
          <TriageSampleValuesDropdown
            componentId={`triage-sample-values-dropdown-${index}`}
            disabled={expressionOperator === 'empty'}
            errorMessage=''
            model={triageModel}
            onChange={value =>
              onChangeValueHandlerByInputType(value, inputType)
            }
            value={
              isMultiOptionsInput
                ? expressionValues?.length
                  ? String(expressionValues?.[0])
                  : ''
                : getExpressionValue(inputType)
            }
          />
        ) : (
          inputTypeSelector[inputType]
        )}
      </Box>
      <IconButton
        aria-label={`delete expression ${index + 1}`}
        disabled={isDeleteButtonDisabled}
        onClick={() => onDeleteButtonClick && onDeleteButtonClick()}
        variant='ghost'
      >
        <DeleteIcon disabled={isDeleteButtonDisabled} src={deleteIcon} />
      </IconButton>
    </Box>
  );
};

export default ExpressionBuilder;
