import React, { FC } from 'react';
import { Box, Menu, useTheme } from '@mui/material';

import {
  Checkbox as FTCheckbox,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ListOption } from 'src/components/reusable-components/autocomplete-dropdown/types';
import {
  ActionBuilderSettingsVariablesDict,
  ContextVariableConfigurationDict,
} from 'src/utils/actionBuilder/types';

interface CVConfigurationModalProps {
  configAnchorEl: HTMLElement | null;
  configurationFields?: ContextVariableConfigurationDict;
  errMsg: string;
  rowIdx: number;
  setConfigAnchorEl: (anchorEl: null | HTMLElement) => void;
  updateVariables: (
    value: string | ListOption[] | boolean | ContextVariableConfigurationDict,
    idx: number,
    key: keyof ActionBuilderSettingsVariablesDict,
  ) => void;
}

const CVConfigurationModal: FC<
  React.PropsWithChildren<CVConfigurationModalProps>
> = ({
  configAnchorEl,
  configurationFields,
  errMsg,
  rowIdx,
  setConfigAnchorEl,
  updateVariables,
}) => {
  const { palette } = useTheme();
  const isCvConfigVisible = Boolean(configAnchorEl);

  const minLength = configurationFields?.minLength
    ? configurationFields?.minLength.toString()
    : '';

  const maxLength = configurationFields?.maxLength
    ? configurationFields?.maxLength.toString()
    : '';

  return (
    <Menu
      anchorEl={configAnchorEl}
      MenuListProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          margin: 0,
          padding: 0,
        },
      }}
      onClose={() => setConfigAnchorEl(null)}
      open={isCvConfigVisible}
      PaperProps={{
        sx: {
          background: palette.background.default,
          border: '1px solid' + palette.colors.slate[200],
          borderRadius: '4px',
          boxShadow: 3,
          display: 'flex',
          flexDirection: 'column',
          marginTop: '8px',
          padding: '16px',
          width: '400px',
        },
      }}
    >
      <Box
        alignItems='center'
        display='flex'
        flexDirection='row'
        sx={{
          '.MuiFormControl-root': {
            flex: 2,
          },
          '.MuiTypography-root': {
            flex: 1,
          },
        }}
      >
        <Typography color={palette.common.black} variant='font14'>
          Minimum length:
        </Typography>
        <TextField
          aria-label='Minimum length'
          error={errMsg}
          onChange={e => {
            const updatedConfigurationField = {
              ...configurationFields,
              minLength: parseInt(e.target.value.replace(/\D+/g, '')),
            };
            updateVariables(
              updatedConfigurationField,
              rowIdx,
              'configurationFields',
            );
          }}
          placeholder='E.g. 5'
          value={minLength}
        />
      </Box>
      <Box
        alignItems='center'
        display='flex'
        flexDirection='row'
        sx={{
          '.MuiFormControl-root': {
            flex: 2,
          },
          '.MuiTypography-root': {
            flex: 1,
          },
        }}
      >
        <Typography color={palette.common.black} variant='font14'>
          Maximum length:
        </Typography>
        <TextField
          aria-label='Maximum length'
          error={errMsg}
          onChange={e => {
            const updatedConfigurationField = {
              ...configurationFields,
              maxLength: parseInt(e.target.value.replace(/\D+/g, '')),
            };
            updateVariables(
              updatedConfigurationField,
              rowIdx,
              'configurationFields',
            );
          }}
          placeholder='E.g. 5'
          value={maxLength}
        />
      </Box>
      <Box alignItems='center' display='flex' flexDirection='row'>
        <FTCheckbox
          checked={Boolean(configurationFields?.isAlphanumeric)}
          label={
            <Typography color={palette.common.black} variant='font14'>
              Is alphanumeric
            </Typography>
          }
          onChange={() => {
            const updatedConfigurationField = {
              ...configurationFields,
              isAlphanumeric: !Boolean(configurationFields?.isAlphanumeric),
            };
            updateVariables(
              updatedConfigurationField,
              rowIdx,
              'configurationFields',
            );
          }}
        />
      </Box>
    </Menu>
  );
};

export default CVConfigurationModal;
