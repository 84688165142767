import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Edge,
  Node,
  ReactFlowProvider,
  useReactFlow,
  useUpdateNodeInternals,
} from 'reactflow';
import { styled, useTheme } from '@mui/material/styles';

import {
  Alert,
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { staleEmailWorkflowException } from '../../constants';
import {
  useGetBuilderQueryParams,
  useGetIntentTitle,
  useGetValidationErrors,
} from '../../hooks';
import { EmailConfigurationListItem } from '../../types';
import WorkflowConflictMessage from '../../WorkflowConflictMessage';
import { getEntryNodeInformation } from '../utils';
import IdleSidebarContent from './components/idle-sidebar-content';
import useActionBuilderActions from './hooks/useActionBuilderActions';
import { useCreateWorkflowNode } from './hooks/useCreateNode';
import { INTENT_EMAIL_FLOW_CONFIG } from './constants';
import JourneyMapNavBar from './JourneyMapNavBar';
import WorkflowConverter from './WorkflowConverter';
import keyBy from 'lodash/fp/keyBy';
import { getActionBuilderActions } from 'src/actions/action-builder-actions/actionBuilderActions';
import { AlertVariants } from 'src/components/email-builder/types';
import ForethoughtFlow from 'src/components/forethought-flow';
import Spinner from 'src/components/spinner';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import TestJourneyModal from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-journey-map/test-journey-modal/TestJourneyModal';
import { dashboardApi } from 'src/services/dashboard-api';
import { useGetEmailWorkflowStaticDataQuery } from 'src/services/email-workflow/emailWorkflowApi';
import {
  getEmailConfigurationsPerIntent,
  selectEmailBuilderState,
} from 'src/slices/email-builder/emailBuilderSlice';
import {
  cleanErrors,
  obtainDefaultEmailWorkflow,
  publishEmailWorkflow,
  selectEmailWorkflowState,
  validateEmailWorkflow,
} from 'src/slices/email-workflow/emailWorkflowSlice';
import {
  getTicketFieldMappings,
  selectTicketFieldMappingsState,
} from 'src/slices/ticket-field-mappings/ticketFieldMappingsSlice';
import { Action } from 'src/types/actionBuilderApiTypes';

interface IntentEmailJourneyMapProps {
  isUpdateJourneyModalVisible: boolean;
  setIsUpdateJourneyModalVisible: (isVisible: boolean) => void;
}

function InnerJourneyMap({
  edges,
  nodes,
  setIsTestJourneyModalVisible,
}: {
  edges: Edge[];
  nodes: Node[];
  setIsTestJourneyModalVisible: (isVisible: boolean) => void;
}) {
  const { getNodes, setEdges, setNodes } = useReactFlow();
  const handleCreateNode = useCreateWorkflowNode();
  const updateNodeInternals = useUpdateNodeInternals();
  const { isLoadingOptimisticUpdate } = useSelector(selectEmailWorkflowState);

  useEffect(() => {
    if (isLoadingOptimisticUpdate) {
      // If we are in the middle of an optimistic update, don't update the nodes.
      // This prevents cases where we switch back to the old state while deleting steps.
      return;
    }
    // When we receive new node data from the backend, update node data.
    // On adding a new node, we add a temp id and send a request;
    // This will 'hydrate' that node with its step id and other step data.
    const prevNodes = getNodes();
    const newNodes = WorkflowConverter.reconcileNodes({
      newEdges: edges,
      newNodes: nodes,
      prevNodes,
    });
    setNodes(newNodes);
    setEdges(edges);
    updateNodeInternals(newNodes.map(({ id }) => id));
  }, [
    edges,
    setEdges,
    getNodes,
    setNodes,
    nodes,
    updateNodeInternals,
    isLoadingOptimisticUpdate,
  ]);

  return (
    <ForethoughtFlow
      contentOverCanvas={
        <JourneyMapNavBar
          onTestJourneyClick={() => setIsTestJourneyModalVisible(true)}
        />
      }
      flowConfiguration={INTENT_EMAIL_FLOW_CONFIG}
      flowProps={{
        defaultEdges: edges,
        defaultNodes: nodes,
      }}
      idleSidebarContent={<IdleSidebarContent />}
      onDropCallback={handleCreateNode}
    />
  );
}

export default function IntentEmailJourneyMap({
  isUpdateJourneyModalVisible,
  setIsUpdateJourneyModalVisible,
}: IntentEmailJourneyMapProps) {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { intentId } = useGetBuilderQueryParams();
  const intentTitle = useGetIntentTitle(intentId);

  const [isTestJourneyModalVisible, setIsTestJourneyModalVisible] =
    useState(false);
  const [alertModalData, setAlertModalData] = useState<{
    message: string;
    variant: AlertVariants;
  }>({
    message: '',
    variant: 'main',
  });

  const {
    emailWorkflow,
    error,
    isLoading: isLoadingEmailWorkflow,
    validationErrors,
  } = useSelector(selectEmailWorkflowState);
  const { actions, actionsLoading } = useActionBuilderActions();
  const { intentEmailConfigurations, isLoadingIntentEmailConfigurations } =
    useSelector(selectEmailBuilderState);

  const { ticketFieldMappings } = useSelector(selectTicketFieldMappingsState);

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: true,
  });

  const { data: staticWorkflowData, isLoading: isLoadingStaticWorkflowData } =
    useGetEmailWorkflowStaticDataQuery();

  const {
    automated_message = {},
    context_variables: defaultContextVariables = [],
    helpdesk = 'zendesk',
  } = staticWorkflowData || {};

  const isStaleEmailWorkflowException = error === staleEmailWorkflowException;

  const shouldDisplayAlertToast =
    !!alertModalData.message || (error && !isStaleEmailWorkflowException);

  const nodesAndEdges = useMemo(() => {
    if (
      contextVariables.length &&
      actions &&
      emailWorkflow &&
      intentTitle &&
      !isLoadingEmailWorkflow &&
      !isLoadingIntentEmailConfigurations &&
      !actionsLoading &&
      !isLoadingStaticWorkflowData
    ) {
      const actionsById = keyBy<Action>('action_id')(actions);
      const responsesById = keyBy<EmailConfigurationListItem>(
        'configuration_id',
      )(intentEmailConfigurations);

      const entryNodeData = getEntryNodeInformation(
        helpdesk,
        contextVariables,
        ticketFieldMappings,
        defaultContextVariables,
      );

      return new WorkflowConverter(
        actionsById,
        intentTitle,
        responsesById,
        emailWorkflow,
        validationErrors.stepErrors,
        entryNodeData,
        automated_message,
        helpdesk,
      ).makeEdgesAndNodes();
    }

    return null;
  }, [
    automated_message,
    defaultContextVariables,
    helpdesk,
    contextVariables,
    validationErrors,
    actions,
    actionsLoading,
    intentEmailConfigurations,
    isLoadingEmailWorkflow,
    isLoadingIntentEmailConfigurations,
    intentTitle,
    emailWorkflow,
    ticketFieldMappings,
    isLoadingStaticWorkflowData,
  ]);

  const reloadEmailWorkflowData = () => {
    if (!emailWorkflow || !intentId) {
      return;
    }

    dispatch(obtainDefaultEmailWorkflow({ intentDefinitionId: intentId }));
    dispatch(getEmailConfigurationsPerIntent({ intentDefinitionId: intentId }));
    dispatch(
      validateEmailWorkflow({
        emailWorkflowId: emailWorkflow.email_workflow_id,
        version: emailWorkflow.version,
      }),
    );
    dispatch(getActionBuilderActions());
    dispatch(getTicketFieldMappings());
  };

  useEffect(() => {
    if (emailWorkflow?.email_workflow_id && intentId) {
      dispatch(
        getEmailConfigurationsPerIntent({ intentDefinitionId: intentId }),
      );
    }
  }, [dispatch, intentId, emailWorkflow?.email_workflow_id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (alertModalData.message) {
        setAlertModalData({ ...alertModalData, message: '' });
      }
    }, 4000);
    return () => clearTimeout(timer);
  }, [alertModalData]);

  useGetValidationErrors();

  if (!nodesAndEdges) {
    return <Spinner />;
  }

  const { edges, nodes } = nodesAndEdges;

  return (
    <ReactFlowProvider>
      <InnerJourneyMap
        edges={edges}
        nodes={nodes}
        setIsTestJourneyModalVisible={isVisible =>
          setIsTestJourneyModalVisible(isVisible)
        }
      />
      <TestJourneyModal
        intentDefinitionId={intentId}
        isOpen={isTestJourneyModalVisible}
        setIsTestJourneyModalVisible={isVisible =>
          setIsTestJourneyModalVisible(isVisible)
        }
      />
      {shouldDisplayAlertToast && (
        <AlertModalContainer>
          <Alert
            onClose={() => {
              if (alertModalData.message) {
                setAlertModalData({
                  ...alertModalData,
                  message: '',
                });
              } else if (error) {
                dispatch(cleanErrors());
              }
            }}
            title={
              alertModalData.message
                ? alertModalData.message
                : 'An error ocurred, please refresh the page or try again'
            }
            variant={alertModalData.message ? alertModalData.variant : 'danger'}
          />
        </AlertModalContainer>
      )}

      <WorkflowConflictMessage
        buttonMessage='Reload'
        message=' Reload this page to continue editing this workflow from the last
            updated version.'
        onButtonClick={() => reloadEmailWorkflowData()}
        open={isStaleEmailWorkflowException}
        title='The current email configuration has been updated by another user in your organization'
      />

      <Dialog
        footer={
          <>
            <Button
              onClick={() => setIsUpdateJourneyModalVisible(false)}
              variant='ghost'
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (!emailWorkflow) {
                  return;
                }
                setIsUpdateJourneyModalVisible(false);
                dispatch(
                  publishEmailWorkflow({
                    emailWorkflowId: emailWorkflow.email_workflow_id,
                    isPublishing: true,
                    lastModifiedDate: emailWorkflow.last_modified_date,
                    version: emailWorkflow.version,
                  }),
                );
                dispatch(dashboardApi.util.invalidateTags(['Intents']));
              }}
              variant='secondary'
            >
              Update Now
            </Button>
          </>
        }
        hideCloseButton
        onClose={() => setIsUpdateJourneyModalVisible(false)}
        open={isUpdateJourneyModalVisible}
        title='Update this Journey?'
        variant='sm'
      >
        <Typography color={palette.colors.grey[700]} variant='font14'>
          You are about to update the published Journey, and all<br></br>
          changes and unsaved responses will be made public.
        </Typography>
      </Dialog>
    </ReactFlowProvider>
  );
}

const AlertModalContainer = styled('div')`
  display: flex;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 121px;
  z-index: 9;
`;
