import { Icon, IconListNumbers } from '@tabler/icons-react';

import { EmailAutomationPageContainer } from '../email-automation-page/EmailAutomationPageContainer';

interface SolveEmailConfigTab {
  component: JSX.Element;
  Icon: Icon;
  id: string;
  isFullWidth?: boolean;
  label: string;
  title?: string;
}

export const EMAIL_TABS: SolveEmailConfigTab[] = [
  {
    component: <EmailAutomationPageContainer />,
    Icon: IconListNumbers,
    id: 'automations',
    isFullWidth: true,
    label: 'Automation rules',
  },
];
