import { useMemo } from 'react';
import { styled } from '@mui/material';

import { TOOLS_TAB_COMPONENT_TYPES } from '../constants';
import { DraggableEmailBuilderComponent } from 'src/components/email-builder';
import {
  EmailBuilderConfigurationKey,
  TabComponentProps,
} from 'src/components/email-builder/types';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

export default function IntentEmailToolsTab({
  emailBuilderConfiguration,
  emailConfigurationComponents,
}: TabComponentProps) {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isInteractiveEmailEnabled = featureFlags.includes('interactive_email');
  const isSolveEmailReplyGenerationEnabled = featureFlags?.includes(
    'solve_email_reply_generation_enabled',
  );

  // depending on current state of canvas, show/hide some tools
  // ex: feedback buttons can only be added once, so if they exist on the canvas, don't show the tool
  const toolsToShow = useMemo(() => {
    const existingTypes = emailConfigurationComponents.map(
      ({ component_type }) => component_type,
    );

    const conditionalTypes: EmailBuilderConfigurationKey[] = [];

    if (!existingTypes.includes('feedback')) {
      conditionalTypes.push('feedback');
    }

    const hasInteractiveEmailButton = existingTypes.includes(
      'interactive_email_button',
    );
    if (isInteractiveEmailEnabled && !hasInteractiveEmailButton) {
      conditionalTypes.push('interactive_email_button');
    }

    return [...TOOLS_TAB_COMPONENT_TYPES, ...conditionalTypes];
  }, [emailConfigurationComponents, isInteractiveEmailEnabled]);

  return (
    <ComponentList>
      {toolsToShow.map(componentType => {
        const { adornment, iconSrc, name } =
          emailBuilderConfiguration[componentType];

        const includeAdornment =
          isSolveEmailReplyGenerationEnabled &&
          componentType === 'text_message';

        return (
          <li aria-label={`${name} component`} key={componentType}>
            <DraggableEmailBuilderComponent
              adornment={includeAdornment ? adornment : ''}
              componentType={componentType}
              iconSrc={iconSrc || ''}
              name={name}
            />
          </li>
        );
      })}
    </ComponentList>
  );
}

const ComponentList = styled('ul')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
`;
