import { TableDataDict } from 'src/services/apiInterfaces';

export const filterResults = (
  tableDataBreakdown: TableDataDict['breakdown'],
  field: string,
): TableDataDict['breakdown'] => {
  if (!tableDataBreakdown?.length) {
    return [];
  }

  const tableDataBreakdownCopy = [...tableDataBreakdown];

  if (field === 'all') {
    return tableDataBreakdown;
  }

  return tableDataBreakdownCopy.filter(data => data.field_type === field);
};
