import { useMemo, useState } from 'react';
import { Box, ClickAwayListener, Popper, useTheme } from '@mui/material';
import { IconCheck, IconPlus } from '@tabler/icons-react';

import {
  Button,
  SearchBar,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getNotes, patchNoteTag } from 'src/actions/assist';
import { AssistNote } from 'src/slices/assist-notes/types';
import { useAppDispatch } from 'src/store/hooks';

interface AddTagComponentProps {
  allAvailableTags: Array<string>;
  anchorElement: HTMLElement | null;
  handleClose: () => void;
  selectedNote: AssistNote;
}

const AddTagComponent = ({
  allAvailableTags,
  anchorElement,
  handleClose,
  selectedNote,
}: AddTagComponentProps) => {
  const dispatch = useAppDispatch();
  const { palette, zIndex } = useTheme();
  const [tagQuery, setTagQuery] = useState('');
  const [newTagName, setNewTagName] = useState('');
  const existingTags = selectedNote.tags;
  const [newTags, setNewTags] = useState<string[]>([]);
  const [tagsSelected, setTagsSelected] = useState<string[]>(existingTags);
  const [isCreateTagOpen, setIsCreateTagOpen] = useState(false);

  const isOpen = Boolean(anchorElement);
  const hasTagsToUpdate = Boolean(tagsSelected.length || existingTags.length);
  const hasError = [...existingTags, ...newTags].includes(newTagName);

  const filteredTagOptions = useMemo(() => {
    const uniqueTags = Array.from(
      new Set([...allAvailableTags, ...existingTags, ...newTags]),
    );
    return uniqueTags.filter(tag =>
      tag.toLowerCase().includes(tagQuery.toLowerCase().trim()),
    );
  }, [tagQuery, existingTags, newTags, allAvailableTags]);

  const differenceInTags = useMemo(() => {
    const tagsToAdd = tagsSelected.filter(tag => !existingTags.includes(tag));
    const tagsToRemove = existingTags.filter(
      tag => !tagsSelected.includes(tag),
    );
    return tagsToAdd.length + tagsToRemove.length;
  }, [tagsSelected, existingTags]);

  const handleCancel = () => {
    setTagsSelected(existingTags);
    setTagQuery('');
    setIsCreateTagOpen(false);
    setNewTagName('');
    handleClose();
  };
  if (!anchorElement) {
    return null;
  }
  return (
    <ClickAwayListener
      onClickAway={() => {
        handleCancel();
      }}
    >
      <Popper
        anchorEl={anchorElement}
        open={isOpen}
        placement='bottom-start'
        sx={{
          backgroundColor: palette.colors.white,
          border: '1px solid' + palette.colors.grey[300],
          borderRadius: '4px',
          padding: '12px',
          width: '340px',
          zIndex: zIndex.modal + 1,
        }}
      >
        <Box display='flex' flexDirection='column' gap='16px' width='340px'>
          <Typography color={palette.colors.grey[700]} variant='font14'>
            Add existing tags or create a new tag
          </Typography>
          {isCreateTagOpen ? (
            <Box display='flex' flexDirection='column' gap={1}>
              <TextField
                error={hasError}
                label='Create new tag'
                onChange={e => {
                  const { value } = e.target;
                  setNewTagName(value);
                }}
                placeholder='Enter tag'
                value={newTagName}
              />
              <Box display='flex' gap={1}>
                <Button
                  fullWidth
                  onClick={() => {
                    setIsCreateTagOpen(false);
                    setNewTagName('');
                  }}
                  size='medium'
                  variant='ghost'
                >
                  Cancel
                </Button>
                <Button
                  disabled={!Boolean(newTagName) || hasError}
                  fullWidth
                  onClick={() => {
                    const updatedNewTags = [...newTags];
                    updatedNewTags.push(newTagName);
                    setNewTags(updatedNewTags);
                    const updatedTags = [...tagsSelected];
                    updatedTags.push(newTagName);
                    setTagsSelected(updatedTags);
                    setNewTagName('');
                  }}
                  size='medium'
                  variant='main'
                >
                  Create tag
                </Button>
              </Box>
            </Box>
          ) : (
            <Button
              fullWidth
              onClick={() => setIsCreateTagOpen(true)}
              size='large'
              startIcon={<IconPlus size={20} />}
              variant='ghost'
            >
              Create new Tag
            </Button>
          )}
          <SearchBar
            aria-label='Tag search bar filter'
            fullWidth
            onChange={({ target }) => setTagQuery(target.value)}
            onClear={() => setTagQuery('')}
            placeholder='Search tags'
            size='small'
            value={tagQuery}
          />
          <Box display='flex' flexDirection='column' gap={1}>
            {filteredTagOptions.map(tag => {
              return (
                <Box
                  key={tag}
                  onClick={() => {
                    const isChecked = tagsSelected.includes(tag);
                    let updatedTags = [...tagsSelected];
                    if (isChecked) {
                      updatedTags = updatedTags.filter(
                        tagItem => tagItem !== tag,
                      );
                    } else {
                      updatedTags.push(tag);
                    }

                    setTagsSelected([...new Set(updatedTags)]);
                  }}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    color={palette.colors.purple[500]}
                    variant='font14'
                  >
                    {tag}
                  </Typography>
                  {tagsSelected.includes(tag) && (
                    <IconCheck color={palette.colors.purple[500]} size={20} />
                  )}
                </Box>
              );
            })}
          </Box>
          {hasTagsToUpdate && (
            <Box
              borderTop={`1px solid ${palette.colors.slate[200]}`}
              display='flex'
              gap={1}
              paddingTop={2}
            >
              <Button
                fullWidth
                onClick={handleCancel}
                size='medium'
                variant='ghost'
              >
                Cancel
              </Button>
              <Button
                disabled={differenceInTags === 0}
                fullWidth
                onClick={() => {
                  dispatch(
                    patchNoteTag({
                      body: { tags: tagsSelected },
                      noteId: selectedNote.note_id,
                    }),
                  );
                  // To refresh
                  dispatch(getNotes());
                }}
                size='medium'
                variant='main'
              >
                {tagsSelected.length > 0
                  ? differenceInTags !== 0
                    ? `
                    Update ${differenceInTags} tag${
                        differenceInTags > 1 ? 's' : ''
                      }`
                    : 'Update tags'
                  : 'Clear tags'}
              </Button>
            </Box>
          )}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
};

export default AddTagComponent;
