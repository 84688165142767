import { useCallback } from 'react';
import { Palette, useTheme } from '@mui/material';

export function getCSATColor(palette: Palette, score?: number | null) {
  if (typeof score !== 'number') {
    return palette.colors.grey[500];
  }
  if (score >= 3 && score < 4) {
    return palette.colors.black;
  }
  if (score >= 4) {
    return palette.colors.green[500];
  }
  return palette.colors.red[500];
}

export default function useGetCSATColor() {
  const { palette } = useTheme();

  return useCallback(
    (score?: number | null) => getCSATColor(palette, score),
    [palette],
  );
}
