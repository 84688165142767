import { JSONSchema7Type } from 'json-schema';

import { ActionInput, ActionInputType, InputMethod } from '../../types';
import {
  AutomationInputAction,
  ConnectorAction,
} from 'src/services/apiInterfaces';
import { AutomationForm } from 'src/slices/assist-automations/types';

export const getInputType = (
  action: AutomationInputAction[] | null,
  key: string,
) =>
  String(
    action?.find((actionItem: AutomationInputAction) =>
      Boolean(
        Object.values(actionItem).filter(
          actionItemValue => actionItemValue === key,
        ).length,
      ),
    )?.type,
  );

export const formatInputValue = (inputType: string, inputValue: string) =>
  inputType?.includes('array-number') || inputType?.includes('array-integer')
    ? inputValue.replace(/[^0-9,]/g, '')
    : inputValue;

const serializeActionInputType = (
  actionSchema?: ActionInput,
): ActionInputType => {
  const defaultType = 'string';
  const allowedArrayTypes: JSONSchema7Type = ['string', 'integer', 'number'];

  if (!actionSchema) {
    return defaultType;
  }

  let resultType: ActionInputType = actionSchema.type || defaultType;

  if (
    actionSchema.type === 'array' &&
    actionSchema.items?.type &&
    allowedArrayTypes.includes(actionSchema.items?.type)
  ) {
    resultType = `array-${actionSchema.items.type}` as ActionInputType;
  }

  return resultType;
};

export const serializeActionInputFormatter = (
  actionsInputFormatter: Record<string, Array<AutomationInputAction>>,
  action: ConnectorAction,
): Array<AutomationInputAction> =>
  actionsInputFormatter[action.action_id].map(
    (inputAction: AutomationInputAction) => {
      const result = { ...inputAction };

      if (typeof result !== 'string') {
        const inputActionType = String(result.type);
        const inputId = Object.keys(result).filter(
          key => key !== 'type' && key !== 'is_input_field',
        )[0];
        const isVariable = String(result[inputId])[0] === '$';

        result.is_input_field = isVariable;

        switch (inputActionType) {
          case 'array-string':
            if (inputId) {
              result[inputId] = isVariable
                ? String(result[inputId])
                : String(result[inputId])
                    .split(',')
                    .map(item => item.trim());
            }
            break;

          case 'array-integer':
          case 'array-number':
            if (inputId) {
              result[inputId] = isVariable
                ? String(result[inputId])
                : String(result[inputId]).replaceAll(/ /g, '').split(',');
            }
          default:
            break;
        }
      }
      return result;
    },
  );

export function convertInputMapToFormatter(
  newActionInputMap: Record<string, string>,
  inputFields: AutomationForm['input_fields'],
  action?: ConnectorAction,
): AutomationInputAction[] {
  return Object.entries(newActionInputMap).map(
    ([existingKey, existingValue]) => {
      const isContextVariable = !!inputFields.find(
        inputField => inputField.context_variable === existingValue,
      );

      const actionSchema =
        action &&
        action.input_schema.properties &&
        (action.input_schema.properties[existingKey] as ActionInput);

      return {
        [existingKey]: existingValue,
        is_input_field: isContextVariable,
        type: serializeActionInputType(actionSchema),
      };
    },
  );
}

export function convertInputValueToVariable(value: string) {
  // first symbol is $
  // lowercase for every word
  // replace spaces with _
  return `$${value
    .split(' ')
    .map(word =>
      word
        .split(',')
        .map(char => char.toLowerCase())
        .join(','),
    )
    .join('_')}`;
}

export const inputMethodOptions = [
  { label: 'Agent Provided', value: InputMethod.AGENT_PROVIDED },
  { label: 'Hardcoded', value: InputMethod.HARDCODED },
];
