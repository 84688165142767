import { memo } from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { CsatCard } from '../common/CsatCard';
import InsightCard from '../common/InsightCard';
import QuickFeedbackCard from '../common/QuickFeedbackCard';
import { dateRangeToTimestamp, numbersToStringWithCommas } from '../helpers';
import DonutGraph from 'src/components/solve-insights-charts/donut-graph.tsx/DonutGraph';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { useGetArticlePerformanceQuery } from 'src/services/insights';
import { DonutGraphDataType } from 'src/services/insights/types';

interface ArticlesCardsProps {
  dateRange: DateRange;
}

const ArticlesCards = ({ dateRange }: ArticlesCardsProps) => {
  const { palette } = useTheme();

  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  const {
    data: articlePerformanceData,
    isFetching,
    isLoading,
  } = useGetArticlePerformanceQuery(backendReadyTimestamps);

  const getDifference = (numA?: number, numB?: number) => {
    if (numA && numB) {
      return numA - numB;
    }
  };

  const colorsA = [palette.colors.purple[500], palette.colors.red[500]];
  const nonDeflectionCount = getDifference(
    articlePerformanceData?.misc.conversation_count,
    articlePerformanceData?.misc.deflected_conversation_count,
  );
  const donutA: DonutGraphDataType = {
    values: [
      {
        label: 'deflections',
        value: articlePerformanceData?.misc.deflected_conversation_count ?? 0,
      },
      {
        label: 'non-deflections',
        value: typeof nonDeflectionCount === 'number' ? nonDeflectionCount : 0,
      },
    ],
  };

  const colorsB = [palette.colors.purple[500], palette.colors.slate[100]];
  const notClickedCount = getDifference(
    articlePerformanceData?.misc.article_surfaced,
    articlePerformanceData?.misc.clicked_count,
  );
  const donutB: DonutGraphDataType = {
    values: [
      {
        label: 'clicked',
        value: articlePerformanceData?.misc.clicked_count ?? 0,
      },
      {
        label: 'not clicked',
        value: typeof notClickedCount === 'number' ? notClickedCount : 0,
      },
    ],
  };

  return (
    <Box
      display='flex'
      gap='24px'
      justifyContent='space-between'
      mb='32px'
      sx={{
        overflowX: 'scroll',
      }}
      width='100%'
    >
      <ArticleDonutCard
        colors={colorsA}
        donut={donutA}
        donutText='deflection rate'
        isFetching={isFetching}
        isLoading={isLoading}
        subValueA={articlePerformanceData?.misc.conversation_count}
        subValueATitle='chats'
        subValueB={articlePerformanceData?.misc.deflected_conversation_count}
        subValueBTitle='deflections'
        subValueC={nonDeflectionCount}
        subValueCTitle='non-deflections'
        title='Chats with articles surfaced'
      />
      <ArticleDonutCard
        colors={colorsB}
        donut={donutB}
        donutText='click rate'
        isFetching={isFetching}
        isLoading={isLoading}
        subValueA={articlePerformanceData?.misc.article_surfaced}
        subValueATitle='surfaced'
        subValueB={articlePerformanceData?.misc.clicked_count}
        subValueBTitle='clicked'
        subValueC={notClickedCount}
        subValueCTitle='not clicked'
        title='Click rate'
      />
      <CsatCard dateRange={dateRange} />
      <QuickFeedbackCard dateRange={dateRange} />
    </Box>
  );
};

interface ArticleDonutCardProps {
  colors: string[];
  donut: DonutGraphDataType;
  donutText: string;
  isFetching: boolean;
  isLoading: boolean;
  subValueA?: number;
  subValueATitle: string;
  subValueB?: number;
  subValueBTitle: string;
  subValueC?: number;
  subValueCTitle: string;
  title: string;
}

const ArticleDonutCard = ({
  colors,
  donut,
  donutText,
  isFetching,
  isLoading,
  subValueA,
  subValueATitle,
  subValueB,
  subValueBTitle,
  subValueC,
  subValueCTitle,
  title,
}: ArticleDonutCardProps) => {
  const getRate = (numA?: number, numB?: number) => {
    if (numA && numB && numB !== 0) {
      return numbersToStringWithCommas({
        number: numB / numA,
        style: 'percent',
      });
    }
    return NA;
  };

  const rate = getRate(subValueA, subValueB);

  return (
    <InsightCard isFetching={isFetching} isLoading={isLoading} title={title}>
      <Box display='flex'>
        <Box
          display='flex'
          flex={1}
          flexDirection='column'
          gap='16px'
          pt='20px'
        >
          <Box display='flex' flexDirection='column' gap='2px'>
            <Typography variant='font16Bold'>
              {numbersToStringWithCommas({ number: subValueA })}
            </Typography>
            <Typography variant='font14'>{subValueATitle}</Typography>
          </Box>

          <Box display='flex' flexDirection='column' gap='2px'>
            <Typography variant='font16Bold'>
              {numbersToStringWithCommas({ number: subValueB })}
            </Typography>
            <Box alignItems='center' display='flex' gap='4px'>
              <Box
                sx={{
                  backgroundColor: colors?.[0],
                  borderRadius: '2px',
                  display: 'block',
                  height: '12px',
                  width: '12px',
                }}
              />
              <Typography variant='font14'>{subValueBTitle}</Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' gap='2px'>
            <Typography variant='font16Bold'>
              {numbersToStringWithCommas({ number: subValueC })}
            </Typography>
            <Box alignItems='center' display='flex' gap='4px'>
              <Box
                sx={{
                  backgroundColor: colors?.[1],
                  borderRadius: '2px',
                  display: 'block',
                  height: '12px',
                  width: '12px',
                }}
              />
              <Typography variant='font14'>{subValueCTitle}</Typography>
            </Box>
          </Box>
        </Box>
        <Box display='flex' flex={1}>
          <DonutGraph
            centerSubText={donutText}
            centerText={rate}
            colors={colors}
            containerProps={{ style: { height: '210px', width: '210px' } }}
            data={donut}
            disableTooltip
          />
        </Box>
      </Box>
    </InsightCard>
  );
};

const MemoizedArticlesCards = memo(ArticlesCards);

export default MemoizedArticlesCards;
