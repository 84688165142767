import { useState } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPlus } from '@tabler/icons-react';

import { Button } from '@forethought-technologies/forethought-elements';
import { ArticleSourceComponent } from '../customization-panel/granular-article-suggestion-v2-settings-panel/ArticleSourceComponent';
import { Separator } from '../ForethoughtCanvasRight';
import { useUpdateAutoflowToolConfigurationFieldsMutation } from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  ArticleSource,
  AutoflowTool,
  ToolDefinitionResponse,
} from 'src/types/workflowBuilderAPITypes';

interface EditToolDrawerProps {
  intentWorkflowId: string;
  setEditToolId: (toolId: string) => void;
  tool:
    | (AutoflowTool & { toolDefinition?: ToolDefinitionResponse | undefined })
    | undefined;
}

export const EditToolDrawer = ({
  intentWorkflowId,
  setEditToolId,
  tool,
}: EditToolDrawerProps) => {
  const [mutate] = useUpdateAutoflowToolConfigurationFieldsMutation();

  const [articleSources, setArticleSources] = useState<ArticleSource[]>(
    tool?.toolDefinition?.tool_fields?.article_sources
      ? tool?.toolDefinition?.tool_fields.article_sources
      : [{ doc_type: null, filter_queries: [] }],
  );

  const handleUpdateArticleSource = (
    articleSourceIndex: number,
    updatedArticleSource: ArticleSource,
  ) => {
    const copy = [...articleSources];
    copy[articleSourceIndex] = updatedArticleSource;

    setArticleSources(copy);
  };

  const handleDeleteArticleSource = (articleSourceIndex: number) => {
    setArticleSources(
      articleSources.filter((_, index) => index !== articleSourceIndex),
    );
  };

  const handleAddArticleSource = () => {
    setArticleSources([
      ...articleSources,
      { doc_type: null, filter_queries: [] },
    ]);
  };

  return (
    <Box
      alignItems='space-between'
      bgcolor={theme => theme.palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={2}
      p={3}
      width='650px'
    >
      <Box>
        <Typography variant='font20'>{tool?.tool_name}</Typography>
      </Box>
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        gap={2}
        overflow='scroll'
      >
        <Separator />
        {articleSources.map((articleSource, articleSourceIndex) => {
          return (
            <Box
              display='flex'
              flexDirection='column'
              gap={2}
              key={articleSourceIndex}
            >
              <ArticleSourceComponent
                areErrorsVisible
                articleSource={articleSource}
                articleSourceIndex={articleSourceIndex}
                availableArticleSources={
                  tool?.configuration_fields.available_article_sources ?? []
                }
                handleDeleteArticleSource={handleDeleteArticleSource}
                handleUpdateArticleSource={handleUpdateArticleSource}
                isDeleteDisabled={articleSources.length === 1}
              />
              <Separator />
            </Box>
          );
        })}
        <Button
          onClick={() => {
            handleAddArticleSource();
          }}
          startIcon={<IconPlus />}
          variant='ghost'
        >
          Add article source
        </Button>
      </Box>
      <Box display='flex' gap={2}>
        <Button
          onClick={() => setEditToolId('')}
          size='large'
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          fullWidth
          onClick={() => {
            mutate({
              body: {
                configuration_fields: { article_sources: articleSources },
                tool_id: tool?.tool_id || '',
              },
              intentWorkflowId,
            });
            setEditToolId('');
          }}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
