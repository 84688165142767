import Box from '@mui/material/Box';

import CardValue from '../../common/banner-layout/CardValue';
import { DiscoverArticleRecommendationValue } from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { getKnowledgeGapBannerValues } from 'src/utils/discover/helpers';

interface KnowledgeGapBannerProps {
  hasScrolled: boolean;
  isLoading: boolean;
  knowledgeGapRecommendationBannerData:
    | DiscoverArticleRecommendationValue
    | null
    | undefined;
}

const KnowledgeGapBanner = ({
  hasScrolled,
  isLoading,
  knowledgeGapRecommendationBannerData,
}: KnowledgeGapBannerProps) => {
  const viewMode = hasScrolled ? 'minimized' : 'full';

  const { articlesGenerated, lackingArticleCoverage, numberOfTicketsCovered } =
    getKnowledgeGapBannerValues(
      hasScrolled,
      knowledgeGapRecommendationBannerData,
    );

  return (
    <Box
      data-appcues-target={getAppCuesId({
        componentType: 'container',
        featureName: 'knowledge-gap',
        pageName: 'automation',
        subType: 'banner',
      })}
    >
      <CardValue
        articlesGenerated={{
          value: articlesGenerated,
        }}
        isKnowledgeGapBanner
        lackingArticleCoverage={{
          value: lackingArticleCoverage,
        }}
        loading={isLoading}
        ticketsCovered={{
          value: numberOfTicketsCovered,
        }}
        title='We  generated these articles by identifying gaps in your knowledge base:'
        viewMode={viewMode}
        viewType='primary'
      />
    </Box>
  );
};

export default KnowledgeGapBanner;
