import React, { useState } from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import DiscoverRealizedImpactTopicMenu from 'src/components/dashboard-pages/discover-automation-page/discover-realized-impact-tab/DiscoverRealizedImpactTopicMenu';
import PillButton from 'src/components/pill-button/PillButton';
import RealizedImpactBadge from 'src/components/realized-impact-badge/RealizedImpactBadge';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';

interface DiscoverTopicsRendererProps {
  numberOfTopicsToRender: number;
  topics: Array<DiscoverTopic>;
  trackingEventMetadata?: {
    intentId: string;
    source: string;
  };
}

const DiscoverTopicsRenderer: React.FC<
  React.PropsWithChildren<DiscoverTopicsRendererProps>
> = ({ numberOfTopicsToRender, topics, trackingEventMetadata }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const topicsLength = topics.length;

  const topicsToRender = topics.slice(0, numberOfTopicsToRender);

  return (
    <DiscoverTopicsContainer>
      {topicsToRender.map(topic => (
        <Box key={topic.topic_id} margin='4px 8px 4px 0'>
          <RealizedImpactBadge
            hasIcon
            topic={topic}
            trackingEventMetadata={trackingEventMetadata}
          />
        </Box>
      ))}
      {topicsLength > numberOfTopicsToRender && (
        <Box margin='4px 0 4px 0'>
          <PillButton
            isHighlighted={Boolean(anchorEl)}
            onClick={e => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
            variant='topic'
          >
            <Typography noWrap variant='font12Medium'>
              Show all {topicsLength}
            </Typography>
          </PillButton>
          {Boolean(anchorEl) && (
            <DiscoverRealizedImpactTopicMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              title='Discover topics associated with this workflow'
              topics={topics}
              trackingEventMetadata={trackingEventMetadata}
            />
          )}
        </Box>
      )}
    </DiscoverTopicsContainer>
  );
};

export default DiscoverTopicsRenderer;

const DiscoverTopicsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;
