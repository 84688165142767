import { Skeleton } from '@forethought-technologies/forethought-elements';
import { Container, Footer, Subtitle, Title, TitleContainer } from './styles';

export default function CardSkeleton() {
  return (
    <Container $loading>
      <TitleContainer>
        <Skeleton height={42} variant='circular' width={42} />
        <Title>{<Skeleton width='30%' />}</Title>
        <Subtitle>{<Skeleton />}</Subtitle>
        <Subtitle>{<Skeleton width='80%' />}</Subtitle>
      </TitleContainer>
      <Footer>
        <Skeleton height={20} variant='circular' width={20} />
        <Skeleton height={20} width='30%' />
      </Footer>
    </Container>
  );
}
