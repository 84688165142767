import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import {
  Badge,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { StyleOption } from '../types';
import debounce from 'lodash/fp/debounce';
import set from 'lodash/fp/set';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { CsatConfig } from 'src/slices/solve-config/types';

const LABEL_CHARACTER_CAP = 25;

export const LabelsForChosenStyle = ({
  chosenStyleOptions,
  handleUpdateField,
}: {
  chosenStyleOptions?: StyleOption;
  handleUpdateField: ({
    isGenericField,
    key,
    val,
  }: {
    isGenericField?: boolean;
    key: keyof CsatConfig;
    val: string | boolean | string[];
  }) => void;
}) => {
  const theme = useTheme();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const { one_to_five_labels: oneToFiveLabels } =
    widgetConfiguration.csat_config;
  const [shouldShowError, setShouldShowError] = useState(false);

  const debouncedHideTheError = useMemo(
    () =>
      debounce(1000, () => {
        setShouldShowError(false);
      }),
    [],
  );

  const handleShowErrorTemporarily = () => {
    setShouldShowError(true);
    debouncedHideTheError();
  };

  if (!chosenStyleOptions) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gap='8px' mt='16px'>
      {chosenStyleOptions.icons.map((icon, index) => {
        return (
          <Box alignItems='center' display='flex' gap='24px' key={index}>
            <Box display='flex'>
              <Box width='20px'>
                <Typography variant='font14Bold'>{index + 1}</Typography>
              </Box>
              <LabelImg
                src={
                  chosenStyleOptions.value === 'star'
                    ? chosenStyleOptions.selectedIcon
                    : icon
                }
              />
            </Box>
            <TextField
              aria-label={`Label for index ${index}`}
              onChange={e => {
                if (e.target.value.length > LABEL_CHARACTER_CAP) {
                  handleShowErrorTemporarily();
                  return;
                }
                const updatedLabels = set(
                  [index],
                  e.target.value,
                  oneToFiveLabels,
                );
                handleUpdateField({
                  key: 'one_to_five_labels',
                  val: updatedLabels,
                });
              }}
              value={oneToFiveLabels[index]}
            />
            <Box width='64px'>
              {index < 2 ? (
                <Badge label='Negative' variant='error' />
              ) : index === 2 ? (
                <Badge label='Neutral' variant='macro' />
              ) : (
                <Badge label='Positive' variant='success' />
              )}
            </Box>
          </Box>
        );
      })}
      <Typography
        color={
          shouldShowError
            ? theme.palette.colors.red[500]
            : theme.palette.colors.grey[400]
        }
        variant='font12'
      >
        Keep the labels under {LABEL_CHARACTER_CAP} characters
      </Typography>
    </Box>
  );
};

const LabelImg = styled('img')`
  height: 24px;
  width: 24px;
`;
