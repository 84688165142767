import './styles.scss';

import React from 'react';

import { countChars, getDate, setIcon } from './filterTagHelpers';
import { Filters } from 'src/services/apiInterfaces';
import ucFirstLetter from 'src/utils/uppercaseFirstLetter';

interface FilterTagProps {
  /** Data to display */
  data: Filters | string;
  /** Boolean to determine if it's the last item of the list */
  isLastItem?: boolean;
  /** Boolean to determine if tag needs to display objects or primitive data types */
  isSimpleTag?: boolean;
  /** Function executed when delete button is clicked */
  onClickDeleteBtn(): void;
  /** Function executed when component is hovered */
  onHover?(title?: string, category?: string, values?: string[]): void;
  /** Function to set tooltip visibility */
  setTooltipVisibility?: Function;
}

const FilterTag: React.FC<React.PropsWithChildren<FilterTagProps>> = (
  props: FilterTagProps,
) => {
  const {
    data,
    isLastItem,
    isSimpleTag,
    onClickDeleteBtn,
    onHover,
    setTooltipVisibility,
  } = props;

  const fieldType = (!isSimpleTag && (data as Filters).field.field_type) || '';
  const fieldName = (!isSimpleTag && (data as Filters).field.field_name) || '';
  const fieldCategory =
    (!isSimpleTag && (data as Filters).field.field_category) || '';
  const operator =
    (!isSimpleTag && (data as Filters).operator.toLowerCase()) || '';
  const isBoolean = fieldType === 'BOOLEAN';
  const isDate = fieldType === 'DATE';
  const isNumber = fieldType === 'INTEGER' || fieldType === 'DOUBLE';
  const isString = fieldType === 'STRING';

  const morethan180 =
    !isSimpleTag && (data as Filters) && countChars(data as Filters) > 180;

  return (
    <React.Fragment>
      <div className='FilterTag'>
        {isSimpleTag ? (
          <>
            <span className='FilterTag-container'>
              <span className='FilterTag-container-value' tabIndex={0}>
                {data as string}
              </span>
            </span>
          </>
        ) : (
          <>
            <span
              className='FilterTag-container'
              data-testid='filter-tag'
              onMouseEnter={() => {
                if (morethan180) {
                  onHover &&
                    onHover(
                      fieldName,
                      fieldCategory,
                      (data as Filters).values as string[],
                    );
                }
              }}
              onMouseLeave={() =>
                setTooltipVisibility && setTooltipVisibility(false)
              }
            >
              <span className={setIcon(fieldType)} data-testid='type-icon' />
              <span tabIndex={0}>
                [{ucFirstLetter(fieldCategory as string)}]
              </span>
              <span
                className='FilterTag-container-field'
                tabIndex={0}
              >{`${fieldName}`}</span>
              {isBoolean && <span tabIndex={0}>is {operator}</span>}
              {isDate && (
                <span className='FilterTag-container-field' tabIndex={0}>
                  {getDate((data as Filters).values[0] as Date)} to{' '}
                  {getDate((data as Filters).values[1] as Date)}
                </span>
              )}
              {isString && (
                <span className='FilterTag-container-boldField' tabIndex={0}>
                  {operator}
                </span>
              )}
              {isNumber && (
                <span className='FilterTag-container-boldField' tabIndex={0}>
                  {operator}
                </span>
              )}

              {operator &&
                !operator.includes('exist') &&
                (isNumber || isString) &&
                (data as Filters).values.map(
                  (value: string | boolean | number | Date, index: number) => (
                    <React.Fragment key={index}>
                      {!morethan180 && (
                        <span
                          className='FilterTag-container-value'
                          key={index}
                          tabIndex={0}
                        >
                          {(data as Filters).values.length - 1 === index
                            ? String(value)
                            : `${value},`}
                        </span>
                      )}
                    </React.Fragment>
                  ),
                )}

              {morethan180 && (
                <span className='FilterTag-container-ellipsis' tabIndex={0}>
                  [...]{' '}
                </span>
              )}
            </span>
          </>
        )}
        <span
          className='FilterTag-deleteFilterIcon'
          data-testid='delete-btn'
          onClick={() => onClickDeleteBtn()}
          tabIndex={0}
        />
      </div>

      {!isLastItem && !isSimpleTag && (
        <span className='FilterTag-ampersand' tabIndex={0}>
          {'&'}
        </span>
      )}
    </React.Fragment>
  );
};

export default FilterTag;
