import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';

import {
  Button,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { selectAutomations } from '../../../../slices/assist-automations/assistAutomationsSlice';
import cloneDeep from 'lodash/fp/cloneDeep';
// Assets
import EqualIcon from 'src/assets/images/equal-automation-icon.svg';
import { formatVariableStyle } from 'src/components/assist-automations-form/helpers';
// Services
import {
  AutomationOutputAction,
  ConnectorAction,
} from 'src/services/apiInterfaces';
import { AutomationForm } from 'src/slices/assist-automations/types';

const OutputCard: React.FC<
  React.PropsWithChildren<{
    action: ConnectorAction;
    isReadOnly: boolean;
  }>
> = ({ action, isReadOnly }) => {
  // This list represents the live state of the chosen outputs
  const [actionOutputList, setActionOutputList] = useState<
    Array<Record<'key' | 'value', string>>
  >([{ key: '', value: '' }]);

  const { errors, setFieldValue, values } = useFormikContext<AutomationForm>();
  const { availableAutomations } = useSelector(selectAutomations);

  // This list represents the output choices for this given action
  const outputOptions = useMemo(() => {
    if (!action?.output_schema.properties) {
      return [];
    }
    return Object.entries(action.output_schema.properties).map(
      ([key, property]) => {
        const title = (typeof property === 'object' && property.title) || '';
        return { label: title || key, value: key };
      },
    );
  }, [action]);

  // On change of an output, update the actionOutputList and
  // also update the automationForm field 'actions_output_formatter'
  function changeActionOutput(key: string, value: string, index: number) {
    const newActionOutputList = [...actionOutputList];
    newActionOutputList[index] = { key, value };
    setActionOutputList(newActionOutputList);

    const actionOutputArray: Array<AutomationOutputAction> =
      newActionOutputList.map(inputEntry => ({
        use_as_input: false,
        [inputEntry.key]: inputEntry.value,
      }));
    const currentOutputFormatter = cloneDeep(values.actions_output_formatter);
    currentOutputFormatter[action?.action_id] = actionOutputArray;

    setFieldValue('actions_output_formatter', currentOutputFormatter);
  }

  useEffect(() => {
    const currentAutomation = availableAutomations.find(
      automation => automation.automation_id === values.automation_id,
    );
    if (
      currentAutomation?.action_list.length &&
      action.output_schema.properties
    ) {
      let newActionOutputList: Array<Record<'key' | 'value', string>> = [];

      Object.keys(action.output_schema.properties).forEach(key => {
        if (values.actions_output_formatter[action?.action_id]) {
          values.actions_output_formatter[action?.action_id].forEach(input => {
            if (input[key] !== undefined) {
              newActionOutputList = [
                ...newActionOutputList,
                { key, value: String(input[key]) },
              ];
            }
          });
        }
      });

      setActionOutputList(newActionOutputList);
    }
  }, [
    action?.action_id,
    action.output_schema.properties,
    availableAutomations,
    values.actions_output_formatter,
    values.automation_id,
  ]);

  const variableStyleError = Boolean(errors.actions_output_formatter);

  if (
    !action?.output_schema.properties ||
    !Object.keys(action?.output_schema.properties).length
  ) {
    return null;
  }

  return (
    <>
      <TitleWrapper>
        <Typography variant='font14Medium'>Assign Outputs</Typography>
      </TitleWrapper>
      <InputRows>
        {actionOutputList.map((output, index) => {
          // Don't show already used options in the dropdowns
          let availableOptions = [...outputOptions];
          availableOptions = availableOptions.filter(
            outputOption =>
              !actionOutputList
                .filter(actionOutput => actionOutput.key !== output.key)
                .some(actionOutput => actionOutput.key === outputOption.value),
          );

          return (
            <InputRow isReadOnly={isReadOnly} key={index}>
              <SelectDropdown
                id={`output_selector_${index}`}
                label={index === 0 ? 'Output' : undefined}
                onChange={e => {
                  const variableStyle = `$${e.target.value
                    .toLowerCase()
                    .replace(/\s/g, '_')}`;
                  changeActionOutput(e.target.value, variableStyle, index);
                }}
                options={availableOptions}
                placeholder='Select output'
                value={output.key}
              />
              <Equal row={index} src={EqualIcon} />
              <TextField
                disabled={!output.key}
                error={variableStyleError}
                label={index === 0 ? 'Variable Style' : ''}
                onChange={e => {
                  const formattedVariableStyle = formatVariableStyle(
                    e.target.value,
                  );

                  changeActionOutput(
                    output.key,
                    formattedVariableStyle || '',
                    index,
                  );
                }}
                placeholder='Select variable style '
                required={true}
                value={output.value}
              />
            </InputRow>
          );
        })}
      </InputRows>
      <Button
        disabled={outputOptions.length === actionOutputList.length}
        onClick={() => {
          setActionOutputList([...actionOutputList, { key: '', value: '' }]);
        }}
        startIcon={<AddIcon />}
        variant='ghost'
      >
        Add new field
      </Button>
    </>
  );
};

const InputRows = styled('div')`
  margin: 10px 0 8px;
`;

const InputRow = styled('div')<{ isReadOnly: boolean }>`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 8px;

  ${({ isReadOnly }) => isReadOnly && 'pointer-events: none;'}
`;

const TitleWrapper = styled('div')`
  margin-top: 16px;
`;

const Equal = styled('img')<{ row: number }>`
  margin: 10px 17px 11px;
`;

export default OutputCard;
