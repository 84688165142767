/**
 * Get key of object by comparing value of a property.
 * @param {object} dataObj - Object we are trying to get the key from.
 * @param {string} property - Property we we'll check for value.
 * @param {string} value - Value to evaluate in the property.
 * @return key or empty string.
 */
const getKeyByValue = (
  dataObj: { [index: string]: { [key: string]: string | number } },
  property: string,
  value: string | null,
) => {
  return (
    (dataObj &&
      Object.keys(dataObj).find(key => {
        if (dataObj[key][property] === value) {
          return key;
        }
      })) ||
    ''
  );
};

export default getKeyByValue;
