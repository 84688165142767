import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import {
  CanvasWorkflowBuilderState,
  setMode,
} from '../../slices/canvas-workflow-builder/workflowBuilderSlice';
import { getActionSettings } from '../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import ActionCaseSelector from './article-case-selector/ActionCaseSelector';
import ActionStep from './ActionStep';
import { CanvasModes } from './constants';
import { getCamelCasedActionType } from './utils';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { selectIsActionDynamicArticleSuggestion } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import type { RootState } from 'src/store/rootReducer';
import {
  Action,
  ActionPanelMode,
  CustomizableActions,
} from 'src/types/actionBuilderApiTypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface CanvasActionsProps {
  availableAction: Action | undefined;
  canvasData: CanvasWorkflowBuilderState;
  editHandoffCallback: (actionType: ActionPanelMode) => void;
  isCanvasDisabled: boolean;
  shouldDisplayActionCaseSelector: boolean;
  squeezeStepMouseOver: (stepId: string) => void;
  stepId: string;
}

const customizableActions = [
  ActionBuilderActionTypes.ZENDESK_HANDOFF,
  ActionBuilderActionTypes.SALESFORCE_HANDOFF,
  ActionBuilderActionTypes.SALESFORCE_CASE_CREATION,
  ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF,
  ActionBuilderActionTypes.ARTICLE_SUGGESTION,
  ActionBuilderActionTypes.CATCH_ALL_HANDOFF,
  ActionBuilderActionTypes.JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST,
  ActionBuilderActionTypes.INTERCOM_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2,
  ActionBuilderActionTypes.ZENDESK_TICKET_CREATION,
  ActionBuilderActionTypes.HYPERLINK_REDIRECT,
  ActionBuilderActionTypes.KUSTOMER_HANDOFF,
  ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF,
  ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION,
  ActionBuilderActionTypes.TRIGGER_EVENT,
  ActionBuilderActionTypes.FRESHDESK_TICKET_CREATION,
  ActionBuilderActionTypes.FRESHDESK_TICKET_AND_CONTACT_CREATION,
  ActionBuilderActionTypes.ARTICLE_SUGGESTION_GRANULAR,
  ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF,
  ActionBuilderActionTypes.GORGIAS_CHAT_HANDOFF,
  ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2,
  ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF,
  ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF,
  ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF,
  ActionBuilderActionTypes.CONTEXT_VARIABLE_MAPPING,
  ActionBuilderActionTypes.ZENDESK_ATTACHMENT_UPLOAD,
  ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF,
  ActionBuilderActionTypes.PARSE_JWT,
  ActionBuilderActionTypes.SET_CONTEXT_VARIABLE,
  ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF,
  ActionBuilderActionTypes.SUNCO_LIVE_CHAT,
  ActionBuilderActionTypes.DYNAMIC_CARD,
];

export const CanvasActions: FC<React.PropsWithChildren<CanvasActionsProps>> = ({
  availableAction,
  canvasData,
  editHandoffCallback,
  isCanvasDisabled,
  shouldDisplayActionCaseSelector,
  squeezeStepMouseOver,
  stepId,
}: CanvasActionsProps) => {
  const dispatch = useDispatch();

  const { contextVariables } = useGetContextVariables();

  const actionIdToComponent: CanvasWorkflowBuilderState['canvas_action_id_to_action_component'] =
    useSelector(
      (state: RootState) =>
        state.canvasWorkflowBuilder.canvas_action_id_to_action_component,
    );
  const userCanCustomizeActionSettings = useSelector(
    selectUserCan('customize_action_settings'),
  );
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isDynamicArticleSuggestion = useSelector(
    selectIsActionDynamicArticleSuggestion(stepId),
  );
  const isDynamicArticleSuggestionMode =
    featureFlags.includes('knowledge_article_workflow') &&
    isDynamicArticleSuggestion;

  const [shouldShowEditMenu, setShouldShowEditMenu] = useState(false);

  const isActionCustomizable = customizableActions.includes(
    availableAction?.action_type as ActionBuilderActionTypes,
  );

  // handlers
  const getActionKey = () => {
    return (
      Object.keys(actionIdToComponent).find(
        key =>
          actionIdToComponent[key]['action_id'] ===
            availableAction?.action_id &&
          actionIdToComponent[key]['entry_step_id'] === stepId,
      ) ?? ''
    );
  };

  const onCustomizeClick = () => {
    dispatch(getActionSettings(getActionKey()));
    dispatch(setCustomizableActionId(getActionKey()));
    dispatch(setMode({ contextVariables, mode: CanvasModes.ACTION_EDIT }));
    if (availableAction?.action_type) {
      editHandoffCallback(availableAction?.action_type as CustomizableActions);
    }
  };

  // consts
  const camelCasedActionType = getCamelCasedActionType(availableAction);

  return (
    <ActionStepContainer
      aria-label={
        camelCasedActionType ? `${camelCasedActionType}Action` : 'Action'
      }
      data-appcues-target={
        camelCasedActionType ? `Canvas${camelCasedActionType}Action` : undefined
      }
      onMouseEnter={() => setShouldShowEditMenu(true)}
      onMouseLeave={() => setShouldShowEditMenu(false)}
      role='group'
    >
      {availableAction && (
        <ActionStep
          actionIdToComponent={actionIdToComponent}
          actionKey={getActionKey()}
          availableAction={availableAction}
          canvasData={canvasData}
          isCanvasDisabled={isCanvasDisabled}
          onCustomizeClick={onCustomizeClick}
          setShouldShowEditMenu={setShouldShowEditMenu}
          shouldShowCustomizeButton={
            isActionCustomizable && userCanCustomizeActionSettings
          }
          shouldShowEditMenu={shouldShowEditMenu}
          stepId={stepId}
        />
      )}
      {shouldDisplayActionCaseSelector && !isDynamicArticleSuggestionMode && (
        <ActionCaseSelector
          canvasData={canvasData}
          negativeCaseSelectedCallBack={() => {
            // disable squeeze step mouse over for "no" button
            squeezeStepMouseOver('');
          }}
          stepId={stepId}
        />
      )}
    </ActionStepContainer>
  );
};

const ActionStepContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  position: relative;

  :hover {
    background-color: transparent;
  }
`;
