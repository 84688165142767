import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TicketRequestItem from './TicketRequestItem';
import TicketResponse from './TicketResponse';
import TicketTimeStamp from './TicketTimeStamp';
import { NA } from 'src/constants/solve';
import { TextDivider } from 'src/pages/intent-conversation-analytics/ConversationDrawer';
import { DiscoverTicket } from 'src/reducers/discoverReducer/types';

const TicketDetails = ({ ticket }: { ticket: DiscoverTicket }) => {
  const { palette } = useTheme();
  const noReplies = ticket.replies.length === 0;

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      <Typography variant='font11'>Ticket</Typography>
      <Box
        borderRadius={1}
        display='flex'
        flexDirection='column'
        mb={4}
        p={2}
        rowGap={1.5}
        sx={{ border: `1px solid ${palette.colors.slate[200]}` }}
      >
        <Box py={1}>
          <TextDivider size='large' text='Customer request' />
        </Box>
        <TicketTimeStamp dateCreated={ticket.date_created} />
        <TicketRequestItem content={ticket.title} title='Subject' />
        <TicketRequestItem content={ticket.body} title='Body' />

        <Box py={1}>
          <TextDivider size='large' text='Agent response' />
        </Box>

        <Box display='flex' flexDirection='column' rowGap={8}>
          {noReplies && (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          )}
          {ticket.replies.map((reply, index) => (
            <TicketResponse
              body={reply.body}
              key={index}
              timeStamp={reply.source_created_date}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TicketDetails;
