import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';

import { Checkbox } from '@forethought-technologies/forethought-elements';
import NameEmailQuestion from '../../name-email-question/NameEmailQuestion';
import { Spacer } from '../../styles';
import KustomerConversationSubject from './KustomerConversationSubject';
import KustomerCustomAttributes from './KustomerCustomAttributes';
import {
  KustomerConversationCreationCustomization,
  ZendeskOrSalesforceContextVariables,
} from 'src/types/workflowBuilderAPITypes';

type Props = {
  customizationData: KustomerConversationCreationCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<
    SetStateAction<KustomerConversationCreationCustomization>
  >;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
};

const KustomerConversationCreationConfiguration: FC<
  React.PropsWithChildren<Props>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  return (
    <Container>
      <NameEmailQuestion
        customizationData={customizationData}
        errorMessages={errorMessages}
        isFieldTouchedArr={isFieldTouchedArr}
        setCustomizationData={
          setCustomizationData as Dispatch<
            SetStateAction<ZendeskOrSalesforceContextVariables>
          >
        }
        setIsFieldTouchedArr={setIsFieldTouchedArr}
        shouldAllowEmptyName
      />
      <Spacer height='28px' />
      <KustomerConversationSubject
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
      <Spacer height='28px' />
      <KustomerCustomAttributes
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
      <Spacer height='28px' />
      <Checkbox
        checked={
          !!customizationData?.conversation_creation_settings
            ?.write_transcript_to_note
        }
        label='Write transcript to conversation note'
        onChange={() =>
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              conversation_creation_settings: {
                ...handoffData.conversation_creation_settings,
                write_transcript_to_note:
                  !handoffData.conversation_creation_settings
                    .write_transcript_to_note,
              },
            };
          })
        }
      />
      <Checkbox
        checked={
          !!customizationData?.conversation_creation_settings
            .upload_files_attached
        }
        label='Prompt user for file attachments'
        onChange={() =>
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              conversation_creation_settings: {
                ...handoffData.conversation_creation_settings,
                upload_files_attached:
                  !handoffData.conversation_creation_settings
                    .upload_files_attached,
              },
            };
          })
        }
      />
    </Container>
  );
};

export default KustomerConversationCreationConfiguration;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 15px;
`;
