import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { Colors } from '@forethought-technologies/forethought-elements';
import warningIcon from '../../../assets/images/warning-yellow-icon.svg';
import { setIsErrorBannerVisible } from 'src/actions/workflow-builder/workflowBuilderActions';
import { selectIsErrorBannerVisible } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { textBoldStyle } from 'src/styles/styledMixin';

interface ErrorBannerProps {
  /** Message to display in the banner */
  message: string;
}

const ErrorBanner: React.FC<React.PropsWithChildren<ErrorBannerProps>> = ({
  message,
}) => {
  const dispatch = useDispatch();
  const isErrorBannerVisible = useSelector(selectIsErrorBannerVisible);

  useEffect(() => {
    if (isErrorBannerVisible) {
      const setCompVisibility = () => {
        dispatch(setIsErrorBannerVisible(false));
      };
      const timeout = setTimeout(setCompVisibility, 4000);
      return () => clearTimeout(timeout);
    }
  }, [isErrorBannerVisible, dispatch]);

  return (
    <>
      {isErrorBannerVisible && (
        <Container>
          <DecorativeRectangle />
          <WarningIcon alt='' src={warningIcon} />
          <span>{message}</span>
        </Container>
      )}
    </>
  );
};

export default ErrorBanner;

const Container = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  padding: 18px 16px 18px 22px;
  border: 1px solid var(--background-color-hover);
  border-radius: var(--border-radius-button);
  box-shadow: 0px 4px 12px var(--background-color-hover);
  ${textBoldStyle({
    color: Colors.ui.text.primary,
    fontSize: '14px',
  })}

  font-weight: var(--font-weight-semibold);

  background: var(--color-solid-white);
`;

const WarningIcon = styled('img')`
  margin-right: 16px;
`;

const DecorativeRectangle = styled('div')`
  position: absolute;
  left: 0;
  width: 6px;
  height: 100%;
  background: ${Colors.ui.semantic.warning};
  border-top-left-radius: var(--border-radius-button);
  border-bottom-left-radius: var(--border-radius-button);
`;
