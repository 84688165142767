import { dashboardApi } from '../dashboard-api';
import { SubscriptionPlan, UpgradePlanRequest } from './types';

const SUBSCRIPTION_PAGE_BASE_URL = '/plan/v0';
const subscriptionPageApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getIsPlanPageEnabled: builder.query<boolean, void>({
      query: () => `${SUBSCRIPTION_PAGE_BASE_URL}/`,
    }),
    getUsageData: builder.query<SubscriptionPlan, void>({
      query: () => `${SUBSCRIPTION_PAGE_BASE_URL}/usage-details`,
    }),
    upgradePlan: builder.mutation<void, UpgradePlanRequest>({
      query: body => ({
        body,
        method: 'POST',
        url: `${SUBSCRIPTION_PAGE_BASE_URL}/upgrade`,
      }),
    }),
  }),
});

export const {
  useGetIsPlanPageEnabledQuery,
  useGetUsageDataQuery,
  useUpgradePlanMutation,
} = subscriptionPageApi;
