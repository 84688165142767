import React from 'react';
import { Box, useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import IntentBadge from './IntentBadge';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';

const HeaderSection = ({
  channel = 'widget',
  dateLabel,
  extraItems,
  intentDefinitionId,
  intentName,
  isLoading,
  lastUpdated,
  overviewText,
}: {
  channel?: ConversationChannel;
  dateLabel: string | null;
  extraItems?: React.ReactNode;
  intentDefinitionId?: string | null;
  intentName?: string | null;
  isLoading?: boolean;
  lastUpdated?: string | null;
  overviewText?: string;
}) => {
  const { palette } = useTheme();

  if (isLoading) {
    return (
      <Box alignItems='center' display='flex' gap={2} padding='20px 0 16px'>
        <Skeleton height={38} width='40%' />
      </Box>
    );
  }

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      padding={`20px 8px ${intentName ? '16px' : '24px'}`}
    >
      <Box alignItems='center' display='flex' flexWrap='wrap' gap={2}>
        {intentName ? (
          <IntentBadge
            channel={channel}
            intentDefinitionId={intentDefinitionId}
            intentName={intentName}
            isOnHeader
          />
        ) : (
          <Typography noWrap variant='font24'>
            {overviewText}
          </Typography>
        )}
        {/* TODO: add variant to ft-elements, use typography */}
        <Box
          sx={{
            '.MuiTypography-root': {
              fontWeight: 500,
            },
          }}
        >
          <Typography color={palette.colors.grey[400]} noWrap variant='font24'>
            {dateLabel}
          </Typography>
        </Box>
      </Box>
      <Box alignItems='center' display='flex' gap='8px'>
        {lastUpdated && (
          <Typography color={palette.colors.grey[500]} variant='font12'>
            Last updated {lastUpdated}
          </Typography>
        )}
        {extraItems}
      </Box>
    </Box>
  );
};

export default HeaderSection;
