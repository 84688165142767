import React from 'react';

const LoadingSpinner: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div aria-busy className='table-loading-container'>
      <div className='loading-gif' />
    </div>
  );
};

export default LoadingSpinner;
