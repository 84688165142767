import { matchPath, useLocation } from 'react-router-dom';

import { DISCOVER_SHARED_PARAM_NAMES } from 'src/constants/discover';
import { Routes } from 'src/utils/enums';

const blacklist = [
  DISCOVER_SHARED_PARAM_NAMES.SELECTED_ARTICLE_ID,
  DISCOVER_SHARED_PARAM_NAMES.TAB,
];

const getSearchWithBlacklist = (search: string) => {
  // items in blacklist will be removed from any navigation between `sharedSearchParamGroupings`
  const params = new URLSearchParams(search);

  for (const item of blacklist) {
    params.delete(item);
  }

  return params;
};

export const useGenerateSidebarLinkWithSearch = ({
  path = '',
  search = '',
  // When a grouping (Array item) is specified they will share the same URL params
  sharedSearchParamGroupings = [
    [
      Routes.DISCOVER_DASHBOARD,
      Routes.DISCOVER_TOPICS,
      Routes.DISCOVER_TOPIC_DETAIL,
    ],
  ],
}) => {
  const { pathname, search: locationSearch } = useLocation();

  const shouldAppendLocationSearch = sharedSearchParamGroupings.some(
    grouping => {
      const groupedSet = new Set<string>(grouping);

      return (
        groupedSet.has(path) &&
        // matchPath needed to check routes with params
        // Example: topic/:topicId will match topic/1234556
        grouping.some(route => matchPath(pathname, route))
      );
    },
  );

  const combined = new URLSearchParams({
    ...(shouldAppendLocationSearch
      ? Object.fromEntries(
          new URLSearchParams(getSearchWithBlacklist(locationSearch)),
        )
      : undefined),
    ...Object.fromEntries(getSearchWithBlacklist(search)),
  }).toString();

  return combined ? `${path}?${combined}` : path;
};
