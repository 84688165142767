import { ModifyType } from './types';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

export const createVersionBy = (
  modifyType: ModifyType,
  model: VersionedTriageModel,
) => {
  if (modifyType === 'published') {
    return model.published_by;
  }
  if (modifyType === 'new') {
    return model.created_by;
  }
  return model.last_updated_by;
};

export const getTargetDate = (
  modifyType: ModifyType,
  model: VersionedTriageModel,
) => {
  if (modifyType === 'published') {
    return model.published_at;
  }
  if (modifyType === 'new') {
    return model.created_at;
  }
  return model.last_updated_at;
};

const getTime = (value?: string) => new Date(value || 0).getTime();

export const getVersionModifiedType = (
  model: VersionedTriageModel,
): ModifyType => {
  const publishedAt = getTime(model.published_at);
  const createdAt = getTime(model.created_at);
  const updatedAt = getTime(model.last_updated_at);

  if (!publishedAt) {
    return updatedAt > createdAt ? 'modified' : 'new';
  }

  if (publishedAt > updatedAt) {
    return 'published';
  }

  return 'modified';
};

export const getStatusIndicator = (isPublished: boolean) =>
  isPublished ? 'active' : 'idle';

export const createVersionName = (
  model: VersionedTriageModel,
  isCurrent?: boolean,
) => {
  if (!isCurrent) {
    return model.version_name;
  }
  if (model.is_published) {
    return `${model.version_name} (Live)`;
  }

  return `${model.version_name} (Draft)`;
};
