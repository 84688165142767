import { SEARCH_LOADING, UPDATE_QUERY_STRING } from './actionTypes';
import { sendEvent } from 'src/services/api';

export const searchLoading = (loading: boolean) => ({
  loading,
  type: SEARCH_LOADING,
});

export const updateQueryString = (query: any) => ({
  query,
  type: UPDATE_QUERY_STRING,
});

export const sendTrackingEvent: Function =
  (moduleName: string, event: object) => (): void => {
    sendEvent(moduleName, event);
  };
