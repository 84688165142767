import { Badge } from '@forethought-technologies/forethought-elements';
import { BadgeContainer, Description, Icon, Row, Title } from './styles';
import { Connector, ConnectorDefinition } from 'src/services/apiInterfaces';
import { DEFAULT_CONNECTOR_AVATAR } from 'src/utils/connectorUtils';

interface IntegrationDrawerHeaderProps {
  connectorDefinition: ConnectorDefinition;
  selectedConnector?: Connector;
}

export default function IntegrationDrawerHeader({
  connectorDefinition,
  selectedConnector,
}: IntegrationDrawerHeaderProps) {
  return (
    <>
      <Row>
        <Icon
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = DEFAULT_CONNECTOR_AVATAR;
          }}
          src={connectorDefinition.avatar}
        />
        <BadgeContainer>
          <Badge
            label={selectedConnector?.is_active ? 'Connected' : 'Disconnected'}
            variant={selectedConnector?.is_active ? 'success' : 'warning'}
          />
        </BadgeContainer>
      </Row>
      <Row>
        <Title>{connectorDefinition.name}</Title>
      </Row>
      <Row>
        <Description>{connectorDefinition.description}</Description>
      </Row>
    </>
  );
}
