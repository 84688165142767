import { Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';
import { AxisLabelsFormatterContextObject } from 'highcharts';
import { Series } from 'highcharts';
import { Box, Palette, Theme } from '@mui/material';

import { ExtendedLineTooltipFormatterContextObject } from '../types';
import LineGraph from './LineGraph';
import { TrackingEventType } from 'src/components/app/types';
import { numbersToStringWithCommas } from 'src/components/dashboard-pages/solve-insights/helpers';
import {
  OVERVIEW_OVERTIME_COLOR_ARRAY,
  TOPIC_COLOR_ARRAY,
  WORKFLOW_DETAIL_COLOR_ARRAY,
} from 'src/components/dashboard-pages/solve-insights/topics/constants';
import { Scope } from 'src/components/dashboard-pages/solve-insights/types';
import { TEMP_BLUE, TEMP_LIGHT_BLUE } from 'src/constants/solve';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { getCSATColor } from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import {
  LineGraphDataType,
  LineGraphLabelType,
  LineGraphResponse,
  LineGraphValueData,
  OverviewOvertimeGraphResponse,
} from 'src/services/insights/types';

const axisNameMap = {
  'Deflected rate': 'Rate',
} as const satisfies Partial<Record<LineGraphLabelType, string>>;

export const renderLineGraphUtils = (
  _: Palette,
  data: LineGraphResponse | OverviewOvertimeGraphResponse,
  scope: Scope,
  handleToggleVisibility?: (label: LineGraphLabelType) => void,
) => {
  const { line, type } = data;
  switch (type) {
    case 'insights_article_detail':
      return (
        <LineGraph
          colors={TOPIC_COLOR_ARRAY}
          containerProps={{ style: { height: '275px', width: '100%' } }}
          data={line}
          multipleYAxis
          scope={scope}
        />
      );
    case 'insights_topic_detail':
      return (
        <LineGraph
          colors={TOPIC_COLOR_ARRAY}
          containerProps={{ style: { height: '275px', width: '100%' } }}
          data={line}
          legendItemClickDisabled={scope === 'parent_detail'}
          multipleYAxis
          scope={scope}
        />
      );
    case 'insights_overview':
      return (
        <LineGraph
          colors={TOPIC_COLOR_ARRAY}
          containerProps={{ style: { height: '300px', width: '100%' } }}
          data={line}
          legendItemClickDisabled={scope === 'overview'}
          mergeDeflectionAxis
          multipleYAxis
          scope={scope}
        />
      );
    case 'insights_overview_overtime_chart':
      return (
        <LineGraph
          colors={OVERVIEW_OVERTIME_COLOR_ARRAY}
          containerProps={{ style: { height: '300px', width: '100%' } }}
          data={line}
          handleToggleVisibility={handleToggleVisibility}
          mergeOverviewOvertimeAxes
          multipleYAxis
          scope={scope}
        />
      );
    case 'insights_workflow_detail_chart':
      return (
        <LineGraph
          colors={WORKFLOW_DETAIL_COLOR_ARRAY}
          containerProps={{ style: { height: '275px', width: '100%' } }}
          data={line}
          handleToggleVisibility={handleToggleVisibility}
          mergeOverviewOvertimeAxes
          multipleYAxis
          scope={scope}
        />
      );
    default:
      return <Box>{NA}</Box>;
  }
};

export const handleYAxisOptions = ({
  data,
  mergeDeflectionAxis,
  mergeOverviewOvertimeAxes,
  theme,
}: {
  data: LineGraphDataType;
  mergeDeflectionAxis: boolean;
  mergeOverviewOvertimeAxes: boolean;
  theme: Theme;
}): Highcharts.YAxisOptions[] => {
  const tracker = new Map<string, boolean>();

  const standardOptions = data.values
    .map(value => {
      if (tracker.get(value.label)) {
        // Skip if label option was already added
        return;
      }
      tracker.set(value.label, true);
      const isPercent =
        value.type === 'percentage' || value.type === 'list_percentage';
      const isCsat = value.label === 'Avg. CSAT';
      return {
        ceiling: isPercent ? 1 : isCsat ? 5 : undefined,
        floor: isPercent || isCsat ? 0 : undefined,
        labels: {
          enabled: true,
          formatter: function (this: AxisLabelsFormatterContextObject) {
            if (typeof this.value === 'string') {
              return this.value;
            }
            return numbersToStringWithCommas({
              canBeFloat: true,
              number: this.value,
              showDecimals: isPercent,
              style: isPercent ? 'percent' : 'decimal',
            });
          },
          style: {
            color: theme.palette.colors.grey[700],
            fontFamily: theme.typography.fontFamily,
            fontSize: '11px',
          },
        },
        max: isPercent ? 1 : isCsat ? 5 : undefined,
        min: isPercent || isCsat ? 0 : undefined,
        showEmpty: false,
        tickAmount: isPercent ? 5 : isCsat ? 6 : undefined,
        tickInterval: isPercent ? 0.25 : isCsat ? 1 : undefined,
        title: {
          style: {
            color: theme.palette.colors.grey[700],
            fontFamily: theme.typography.fontFamily,
            fontSize: '12px',
          },
          text: mergeOverviewOvertimeAxes
            ? // @ts-expect-error TypeScript sucks
              axisNameMap[value.label] || value.label
            : value.label,
        },
      };
    })
    .filter(Boolean) as Highcharts.YAxisOptions[];

  // Merge deflection and non deflection (custom)
  if (mergeDeflectionAxis) {
    const customOptions: Highcharts.YAxisOptions[] = [];
    let customAxisUsed = false;
    standardOptions.forEach(option => {
      if (option.title?.text?.toLocaleLowerCase().includes('deflection')) {
        if (customAxisUsed) {
          return;
        }
        const updatedOption = { ...option };
        const customTitle = {
          style: {
            color: theme.palette.colors.grey[700],
            fontFamily: theme.typography.fontFamily,
            fontSize: '10px',
          },
          text: 'Deflected chats, Non-deflected chats',
        };
        updatedOption.title = customTitle;
        customOptions.push(updatedOption);
        customAxisUsed = true;
      } else {
        customOptions.push(option);
      }
    });
    return customOptions;
  }
  return standardOptions;
};

export const handleLegendEventsOnClick = ({
  emitTrackingEventCallback,
  event,
  legendItemClickDisabled,
  pointer,
  scope,
  subValueIds,
  value,
}: {
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: Record<string, unknown>,
  ) => void;
  event: Highcharts.SeriesLegendItemClickEventObject;
  legendItemClickDisabled: boolean;
  pointer: Series;
  scope?: Scope;
  subValueIds: string[];
  value: LineGraphValueData;
}) => {
  if (legendItemClickDisabled) {
    event.preventDefault();
  } else {
    const isVisible = pointer.visible;
    // When data point has subValues should hide when parent data point gets hidden
    if (subValueIds) {
      subValueIds.forEach(id => {
        const Series = pointer.chart.get(id) as Series | undefined;
        if (Series) {
          if (isVisible) {
            Series.hide();
          } else {
            Series.show();
          }
        }
      });
    }

    const visibility = isVisible ? 'hidden' : 'visible';
    emitTrackingEventCallback('insight-line-graph-legend-change', {
      name: value.label,
      scope,
      tab: 'article',
      value: visibility,
    });
  }
};

export const handleSplineOptionCreation = ({
  emitTrackingEventCallback,
  legendItemClickDisabled,
  scope,
  showInLegend,
  subValueId,
  subValueIds = [],
  trackYAxises,
  value,
  xAxisExtended,
}: {
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: Record<string, unknown>,
  ) => void;
  legendItemClickDisabled: boolean;
  scope?: Scope;
  showInLegend: boolean;
  subValueId?: string;
  subValueIds?: string[];
  trackYAxises: Map<string, number>;
  value: LineGraphValueData;
  xAxisExtended: string[];
}): Highcharts.SeriesSplineOptions => {
  const hasDataInArray = value.value.filter(item => item !== null).length > 0;
  const yAxis =
    typeof value.yAxis === 'number'
      ? value.yAxis
      : trackYAxises.get(value.label);
  return {
    color: value.color,
    dashStyle: value.dashStyle ? value.dashStyle : 'Solid',
    data: hasDataInArray
      ? value.value.map((itemValue, valueIdx) => {
          return {
            type: value.type ?? 'number',
            y: itemValue,
            yAxisExtended: xAxisExtended ? xAxisExtended[valueIdx] : undefined,
          };
        })
      : [],
    events: {
      legendItemClick: function (e) {
        handleLegendEventsOnClick({
          emitTrackingEventCallback: emitTrackingEventCallback,
          event: e,
          legendItemClickDisabled: legendItemClickDisabled,
          pointer: this,
          scope: scope,
          subValueIds,
          value,
        });
      },
      // mouseOut: function (e) {
      //   if (subValueIds) {
      //     subValueIds.forEach(id => {
      //       const Series = this.chart.get(id) as Series | undefined;
      //       if (Series) {
      //         Series.setState('inactive');
      //       }
      //     });
      //   }
      // },
      // mouseOver: function (e) {
      //   if (typeof yAxis === 'number') {
      //     const options = this.chart.getOptions() as unknown as Options;
      //     if (options.series?.length) {
      //       const getIdsOfSeriesToHighlight = options.series
      //         .filter(item => item.yAxis === yAxis)
      //         .map(item => item.id)
      //         .filter(Boolean) as string[];
      //       getIdsOfSeriesToHighlight.forEach(id => {
      //         const chartById = this.chart.get(id) as Series | undefined;
      //         if (chartById) {
      //           chartById.setState('hover', true);
      //         }
      //       });
      //       this.chart.redraw();
      //     }
      //   }
      // },
    },
    id: subValueId || `${value.label}_main`,
    name: value.label,
    // topic parent detail shows custom legend (hacky workaround)
    showInLegend: showInLegend,
    type: 'spline',
    yAxis: yAxis,
  };
};

export const getAllYAxises = ({ data }: { data: LineGraphDataType }) => {
  const trackYAxises = new Map<string, number>();
  // Cases where same Y axis is used for different arrays, merge the axis
  data.values.forEach(value => {
    if (!trackYAxises.has(value.label)) {
      trackYAxises.set(value.label, trackYAxises.size);
    }
  });
  return trackYAxises;
};

export const renderComparisonChartTooltip = (
  palette: Palette,
  object: ExtendedLineTooltipFormatterContextObject,
  colors?: string[],
) => {
  const point = object.point;

  if (!point) {
    return;
  }

  const nameMap = {
    'Quick feedback': 'Positive feedback rate',
    Relevance: 'Relevance rate',
    'User engagement': 'Engagement rate',
  } as const satisfies Partial<Record<LineGraphLabelType, string>>;

  const labelsWithDividerInTooltip = [
    'Quick feedback',
    'Relevance',
    'User engagement',
  ] as const satisfies LineGraphLabelType[];

  const tooltipComponent = (
    <div
      style={{
        border: `1px solid ${palette.colors.slate[200]}`,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        minWidth: '226px',
        padding: '8px',
      }}
    >
      <p
        style={{
          color: palette.colors.black,
          fontSize: 14,
          fontWeight: 600,
          margin: 0,
          padding: 0,
        }}
      >
        {nameMap[object.series.name as keyof typeof nameMap] ||
          object.series.name}
      </p>
      {point.tooltip_values.map((tooltipValue, tooltipValueIndex) => {
        const category =
          tooltipValue.extended_labels?.[point.index] ?? tooltipValue.category;
        return (
          <div key={category} style={{ display: 'flex' }}>
            <span
              style={{
                backgroundColor:
                  tooltipValueIndex === 0
                    ? colors?.[tooltipValue.index] || (object.color as string)
                    : undefined,
                border: `1px ${tooltipValueIndex === 0 ? 'solid' : 'dashed'} ${
                  colors?.[tooltipValue.index] || (object.color as string)
                }`,
                borderRadius: '2px',
                boxSizing: 'border-box',
                height: '12px',
                width: '12px',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '8px',
                width: '100%',
              }}
            >
              <p
                style={{
                  color: palette.colors.grey[600],
                  fontSize: 12,
                  fontWeight: 600,
                  margin: 0,
                  padding: 0,
                }}
              >
                {category}
              </p>
              {tooltipValue.tooltip_values.map(
                ({ header, label, type, value }, innerTooltipValueIndex) => {
                  function getValueColor() {
                    if (label === 'from previous period') {
                      if (value[point.index] > 0) {
                        return palette.colors.green[500];
                      }

                      if (value[point.index] === 0) {
                        return palette.colors.black;
                      }

                      if (value[point.index] === undefined) {
                        return palette.colors.grey[500];
                      }

                      return palette.colors.red[500];
                    }

                    if (header === 'Positive') {
                      return palette.colors.green[500];
                    }

                    if (header === 'Negative') {
                      return palette.colors.red[500];
                    }

                    if (object.series.name === 'Avg. CSAT') {
                      return getCSATColor(palette, value[point.index]);
                    }

                    if (header === 'Relevant') {
                      return TEMP_BLUE;
                    }

                    if (header === 'Somewhat relevant') {
                      return TEMP_LIGHT_BLUE;
                    }

                    if (header === 'Irrelevant') {
                      return palette.colors.red[500];
                    }

                    return palette.colors.black;
                  }

                  function getValuePrefix() {
                    return label === 'from previous period' &&
                      value[point.index] >= 0
                      ? '+'
                      : '';
                  }

                  return (
                    <Fragment key={innerTooltipValueIndex}>
                      {header && (
                        <span
                          style={{
                            color: palette.colors.black,
                            fontSize: 12,
                            fontWeight: 400,
                            marginBottom: '4px',
                            marginTop: '12px',
                          }}
                        >
                          {header}
                        </span>
                      )}
                      <p
                        style={{
                          color: getValueColor(),
                          fontSize: 14,
                          fontWeight: 600,
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {getValuePrefix()}
                        {numbersToStringWithCommas({
                          canBeFloat: true,
                          maximumFractionDigits:
                            type === 'list_percentage' ? 2 : undefined,
                          number: value[point.index],
                          style:
                            type === 'list_percentage' ? 'percent' : 'decimal',
                        })}{' '}
                        <span
                          style={{
                            color: palette.colors.grey[600],
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          {label}
                        </span>
                      </p>
                    </Fragment>
                  );
                },
              )}
              {tooltipValueIndex === 0 &&
                // @ts-expect-error TypeScript sucks
                labelsWithDividerInTooltip.includes(object.series.name) && (
                  <div
                    style={{
                      backgroundColor: palette.colors.grey[200],
                      height: '1px',
                      marginBottom: '8px',
                      marginLeft: '-20px',
                      marginTop: '12px',
                      width: 'calc(100% + 20px)',
                    }}
                  />
                )}
            </div>
          </div>
        );
      })}
    </div>
  );

  return ReactDOMServer.renderToString(tooltipComponent);
};

export const renderChartTooltip = (
  palette: Palette,
  object: ExtendedLineTooltipFormatterContextObject,
  colors?: string[],
) => {
  const point = object.point;
  if (!point) {
    return;
  }

  if (point.tooltip_values) {
    return renderComparisonChartTooltip(palette, object, colors);
  }

  const { type, y: pointA } = point;
  const tooltipComponent = (
    <div
      style={{
        border: `1px solid ${palette.colors.slate[200]}`,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        minWidth: '226px',
        padding: '8px',
      }}
    >
      <p
        style={{
          color: palette.colors.black,
          fontSize: 14,
          fontWeight: 600,
          margin: 0,
          padding: 0,
        }}
      >
        {object.series.name}
      </p>
      <div style={{ display: 'flex' }}>
        <span style={{ color: object.color as string }}>■</span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '8px',
          }}
        >
          <p
            style={{
              color: palette.colors.grey[600],
              fontSize: 12,
              fontWeight: 600,
              margin: 0,
              padding: 0,
            }}
          >
            {point.yAxisExtended ?? object.key}
          </p>
          <p
            style={{
              color: palette.colors.black,
              fontSize: 14,
              fontWeight: 600,
              margin: 0,
              padding: 0,
            }}
          >
            {numbersToStringWithCommas({
              canBeFloat: true,
              maximumFractionDigits: type === 'percentage' ? 2 : undefined,
              number: pointA,
              style: type === 'percentage' ? 'percent' : 'decimal',
            })}
          </p>
        </div>
      </div>
    </div>
  );
  return ReactDOMServer.renderToString(tooltipComponent);
};
