import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { css, styled } from '@mui/material';

import {
  Button,
  Colors,
  elevations,
  theme,
} from '@forethought-technologies/forethought-elements';
import get from 'lodash/get';
import plusIcon from 'src/assets/images/actions-plus-icon.svg';
import chevronDownIcon from 'src/assets/images/chevron-down.svg';
import chevronUpIcon from 'src/assets/images/chevron-up.svg';
import collapseIcon from 'src/assets/images/collapse.svg';
import puzzleIcon from 'src/assets/images/puzzle-icon.svg';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { IntentType } from 'src/pages/workflow-builder/suggested-improvements/constants';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
// Redux
import { selectWorkflowBuilderSuggestedGapIntents } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { openGlobalIntentDrawer, setIntentToEdit } from 'src/slices/ui/uiSlice';
// Styles / Assets
import {
  textBoldStyle,
  textMediumStyle,
  textRegularStyle,
} from 'src/styles/styledMixin';
// Constants
import {
  Phrase,
  SuggestedIntent as SuggestedIntentType,
} from 'src/types/workflowBuilderAPITypes';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';

interface SuggestedImprovementsProps {
  hideAlertModals: () => void;
  isExpanded: boolean;
  onClose: () => void;
}

const SuggestedImprovements: FC<
  React.PropsWithChildren<SuggestedImprovementsProps>
> = ({ hideAlertModals, onClose }) => {
  const dispatch = useDispatch();

  const gapDetectedSuggestions = useSelector(
    selectWorkflowBuilderSuggestedGapIntents,
  );

  const userCanAddSuggestedImprovements = useSelector(
    selectUserCan('add_suggested_improvements'),
  );

  const [isGapDetectedExpanded, setIsGapDetectedExpanded] = useState(true);

  const setSelectedSuggestion = (suggestedIntent: SuggestedIntentType) => {
    const intent = {
      intent_id: get(suggestedIntent, 'intent_id') as string,
      is_custom: get(suggestedIntent, 'intent_type') === IntentType.CUSTOM,
      phrases: get(suggestedIntent, 'phrases', []) as Phrase[],
      title: get(suggestedIntent, 'title') as string,
    };

    dispatch(setIntentToEdit(intent));
    dispatch(openGlobalIntentDrawer({ type: 'suggested' }));
  };

  useEffect(() => {
    setIsGapDetectedExpanded(true);
  }, []);

  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER,
  );

  return (
    <List>
      <Header>
        <Title>Suggested Intents</Title>
        <Button
          aria-label='collapse-improvements'
          onClick={onClose}
          startIcon={<ReactSVG src={collapseIcon} />}
          variant='ghost'
        />
      </Header>
      <Body>
        <Section isExpanded={isGapDetectedExpanded}>
          <SectionHeader
            data-testid='gap-suggestion-header'
            onClick={() => {
              hideAlertModals();
              setIsGapDetectedExpanded(previousVal => !previousVal);
            }}
          >
            <Row>
              <ReactSVG src={puzzleIcon} />
              <Spacer width='6px' />
              <SectionTitle>{`Gaps detected (${gapDetectedSuggestions.length})`}</SectionTitle>
            </Row>
            {isGapDetectedExpanded ? (
              <Button
                aria-label='collapse-gap-detected'
                hoverBackgroundColor={theme.palette.colors.white}
                startIcon={<ReactSVG src={chevronUpIcon} />}
                variant='ghost'
              />
            ) : (
              <Button
                aria-label='expand-gap-detected'
                hoverBackgroundColor={theme.palette.colors.white}
                startIcon={<ReactSVG src={chevronDownIcon} />}
                variant='ghost'
              />
            )}
          </SectionHeader>
          {isGapDetectedExpanded && (
            <SectionDescription>
              Intents detected based on past conversation data from your
              customers.
            </SectionDescription>
          )}
          <SectionBody isExpanded={isGapDetectedExpanded}>
            {isGapDetectedExpanded &&
              gapDetectedSuggestions.map(suggestedIntent => (
                <SuggestedIntent
                  canAdd={userCanAddSuggestedImprovements}
                  key={suggestedIntent.intent_id}
                  onClick={() => {
                    hideAlertModals();
                    if (userCanAddSuggestedImprovements) {
                      dispatchTrackingAction(
                        FlamethrowerTrackingEventTypes.STARTER_PACK_INTENT_VIEWED,
                      );
                      setSelectedSuggestion(suggestedIntent);
                    }
                  }}
                >
                  <IntentTitle isShortened>{suggestedIntent.title}</IntentTitle>
                  <Row>
                    <InquiryColumn>
                      <InquiriesCount>
                        {suggestedIntent.expected_coverage || 0}
                      </InquiriesCount>
                      <InquiriesLabel>Inquiries</InquiriesLabel>
                    </InquiryColumn>
                    <Button
                      aria-label={`Create ${suggestedIntent.title} workflow`}
                      hoverBackgroundColor={theme.palette.colors.white}
                      startIcon={<ReactSVG src={plusIcon} />}
                      variant='ghost'
                    />
                  </Row>
                </SuggestedIntent>
              ))}
          </SectionBody>
        </Section>
      </Body>
    </List>
  );
};

const MemoizedSuggestedImprovements = memo(SuggestedImprovements);

export default MemoizedSuggestedImprovements;

const List = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 24px;
  width: 100%;
`;

const Header = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: space-between;
  padding: 24px 0;
  width: 100%;
`;

const Body = styled('div')`
  flex: 0 0 914px;
  overflow: hidden;
`;

const Section = styled('div')<{ isExpanded: boolean }>`
  border-top: 1px solid ${theme.palette.colors.slate[200]};
  max-height: ${props => (props.isExpanded ? '840px' : '56px')};
  min-height: 56px;
  overflow: ${props => (props.isExpanded ? 'auto' : 'hidden')};
`;

const SectionBody = styled('div')<{ isExpanded: boolean }>`
  overflow: auto;
`;

const Title = styled('div')`
  ${textBoldStyle({
    color: Colors.ui.text.primary,
    fontSize: '20px',
  })};
  letter-spacing: -0.1px;
  line-height: 28px;
`;

const Row = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const InquiryColumn = styled('div')`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const InquiriesCount = styled('div')`
  ${textMediumStyle({
    color: Colors.ui.text.primary,
    fontSize: '14px',
  })};
  letter-spacing: -0.1px;
  line-height: 20px;
`;

const InquiriesLabel = styled('div')`
  ${textRegularStyle({
    color: Colors.ui.text.tertiary,
    fontSize: '12px',
  })};
  letter-spacing: -0.1px;
  line-height: 18px;
`;

const SectionHeader = styled('div')`
  display: flex;
  flex-direction: row;
  height: 54px;
  justify-content: space-between;
  padding: 16px 0;
  ${textMediumStyle({
    color: Colors.ui.text.primary,
    fontSize: '16px',
  })};
  letter-spacing: -0.1px;
  line-height: 22px;

  &:hover {
    color: ${theme.palette.colors.purple[500]};
    cursor: pointer;
    svg {
      [fill] {
        fill: ${theme.palette.colors.purple[500]};
      }
      [stroke] {
        stroke: ${theme.palette.colors.purple[500]};
      }
    }
  }
`;

const SectionDescription = styled('div')`
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  ${textRegularStyle({
    color: Colors.ui.text.tertiary,
    fontSize: '14px',
  })};
  letter-spacing: -0.1px;
  line-height: 20px;
`;

const SectionTitle = styled('div')`
  font-weight: var(--font-weight-bold);
  padding-bottom: 2px;
`;

const IntentTitle = styled('span')<{ isShortened?: boolean }>`
  max-width: ${props => (props.isShortened ? '180px' : '240px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const SuggestedIntent = styled('div')<{ canAdd: boolean }>`
  padding: 12px 16px;
  border: 1px solid ${theme.palette.colors.slate[200]};
  border-radius: 8px;
  ${textRegularStyle({
    fontSize: '14px',
  })};
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;

  ${props =>
    props.canAdd
      ? css`
          color: ${Colors.ui.text.primary};
          &:hover {
            border: 1px solid ${theme.palette.colors.slate[300]};
            box-shadow: ${elevations.z2};
            cursor: pointer;
            svg {
              [fill] {
                fill: ${theme.palette.colors.purple[500]};
              }
              [stroke] {
                stroke: ${theme.palette.colors.purple[500]};
              }
            }
          }
        `
      : `color: ${Colors.ui.text.disabled}`};
`;

const Spacer = styled('div')<{
  height?: string;
  width?: string;
}>`
  height: ${props => props.height || 0};
  width: ${props => props.width || 0};
`;
