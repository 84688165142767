import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getDashboardMetrics } from 'src/actions/discover/discover';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import { selectDashboardMetrics } from 'src/reducers/discoverReducer/discoverReducer';
import { Filters } from 'src/services/apiInterfaces';
import { useAppDispatch } from 'src/store/hooks';

export const useGetDashboardMetrics = ({
  dataFilterQuery,
  interval,
  timeFilter,
}: {
  dataFilterQuery: Filters[];
  interval: TopicPeriodicalFilter;
  timeFilter: TopicTimeFilter;
}) => {
  const dispatch = useAppDispatch();
  const { cacheKey, data, error, loading } = useSelector(
    selectDashboardMetrics,
  );

  useEffect(() => {
    const currentCacheKey = `${timeFilter.key}+${interval}${JSON.stringify(
      dataFilterQuery,
    )}`;

    if ((data && currentCacheKey === cacheKey) || loading || error) {
      return;
    }

    dispatch(
      getDashboardMetrics({
        dataFilterQuery,
        interval,
        timeFilter,
      }),
    );
  }, [
    error,
    dispatch,
    interval,
    timeFilter,
    cacheKey,
    data,
    loading,
    dataFilterQuery,
  ]);

  return { data, error, loading };
};
