import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

import {
  IconButton,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface PaginationControlProps<T> {
  currentIndex: number;
  items: T;
  setIndex: (index: number) => void;
}

const PaginationControl = <T extends Array<unknown>>({
  currentIndex,
  items,
  setIndex,
}: PaginationControlProps<T>) => {
  const { palette } = useTheme();

  if (!items || items.length < 2) {
    return null;
  }

  const total = items.length;

  const back = () => {
    const newIndex = currentIndex - 1;
    if (newIndex > -1) {
      setIndex(newIndex);
    }
  };

  const forward = () => {
    const newIndex = currentIndex + 1;
    if (newIndex < total) {
      setIndex(newIndex);
    }
  };

  return (
    <Box alignItems='center' display='flex' gap='4px' justifyContent='end'>
      <IconButton
        aria-label='Previous article'
        disabled={currentIndex === 0}
        onClick={back}
        variant='ghost'
      >
        <IconChevronLeft
          color={
            currentIndex === 0
              ? palette.colors.grey[300]
              : palette.colors.grey[800]
          }
        />
      </IconButton>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        {currentIndex + 1} of {total}
      </Typography>
      <IconButton
        aria-label='Next article'
        disabled={currentIndex === total - 1}
        onClick={forward}
        variant='ghost'
      >
        <IconChevronRight
          color={
            currentIndex === total - 1
              ? palette.colors.grey[300]
              : palette.colors.grey[800]
          }
        />
      </IconButton>
    </Box>
  );
};

export default PaginationControl;
