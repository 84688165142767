/* eslint-disable react/prop-types -- false warnings for column definitions */

import React from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import { NavigateFunction } from 'react-router';
import { Box, Palette } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Badge as FEBadge,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import Badge from '../common/Badge';
import { INSIGHT_TOOLTIP_COPY, TABLE_COLUMN_TO_LABEL_MAP } from '../constants';
import { numbersToStringWithCommas } from '../helpers';
import { Scope } from '../types';
import { TrackingEventType } from 'src/components/app/types';
import { TEMP_BLUE } from 'src/constants/solve';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import { BreakdownPaletteTooltip } from 'src/pages/workflow-builder/BreakdownPaletteTooltip';
import {
  RelevanceTooltip,
  UserEngagementTooltip,
} from 'src/pages/workflow-builder/intent-workflows-table/containers/WidgetIntentWorkflowsTableContainer';
import { HorizontalStackedBar } from 'src/pages/workflow-builder/intent-workflows-table/HorizontalStackedBar';
import { InsightArticle } from 'src/services/insights/types';
import { Routes } from 'src/utils/enums';
import { calculateSum } from 'src/utils/solve/deflectionInsightsUtils';

export const buildArticleColumns = (
  palette: Palette,
  getCSATColor: (score?: number | undefined) => string,
  handleSetSelectedArticleId: (articleId: string) => void,
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: Record<string, unknown>,
  ) => void,
  scope: Scope,
  navigate: NavigateFunction,
  tab: 'topic' | 'article' | 'chat' | 'workflow',
  shouldShowQuickFeedbackAnalytics: boolean,
  shouldShowDeflectionInsights: boolean,
) => {
  const handleClickLink = (
    e: React.MouseEvent<HTMLDivElement>,
    intentId: string,
    workflowId: string,
  ) => {
    e.stopPropagation();
    const search = `?intentId=${intentId}&workflowId=${workflowId}`;
    const pathName = Routes.WORKFLOW_BUILDER_EDIT;

    emitTrackingEventCallback('insight-related-workflow-link-clicked', {
      intentId,
      scope,
      tab,
      value: pathName,
      workflowId,
    });

    navigate({
      pathname: pathName,
      search: search,
    });
  };

  const columnHelper = createMRTColumnHelper<InsightArticle>();

  const columns = [
    columnHelper.accessor('title', {
      Cell: ({ cell, renderedCellValue, row }) => {
        return (
          <Box
            alignItems='center'
            columnGap={1}
            display='flex'
            onClick={e => {
              e.stopPropagation();
              handleSetSelectedArticleId(row.original.id);
              emitTrackingEventCallback('insight-table-row-title-clicked', {
                id: row.original.id,
                scope,
                tab,
                title: cell.getValue(),
              });
            }}
          >
            <Typography color={palette.colors.purple[500]} variant='font14'>
              {renderedCellValue}
            </Typography>
            {!row.original.is_public && (
              <FEBadge label='Internal' variant='inverse' />
            )}
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.article.title,
      size: 400,
    }),
    columnHelper.accessor('chats_count', {
      Cell: ({ cell }) => {
        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='font14'>
                {numbersToStringWithCommas({ number: cell.getValue() })}
              </Typography>
            </Box>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.article.chats_count,
    }),
    columnHelper.accessor('deflected_chats_count', {
      Cell: ({ cell, row }) => {
        const deflectedRate = (
          (cell.getValue() / row.original.chats_count) *
          100
        ).toFixed(0);
        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='font14'>
                {numbersToStringWithCommas({
                  number: cell.getValue(),
                })}{' '}
              </Typography>
              <Typography color={palette.colors.grey[600]} variant='font14'>
                ({deflectedRate}%)
              </Typography>
            </Box>
          </Box>
        );
      },
      Header: ({ column }) => (
        <Box display='flex' gap='4px'>
          {column.columnDef.header}
          <Box
            display='flex'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'article',
                value: 'deflected_chats_count',
              });
            }}
          >
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.articles_deflected_chats}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
      ),
      header: TABLE_COLUMN_TO_LABEL_MAP.article.deflected_chats_count,
    }),
    columnHelper.accessor('times_surfaced', {
      Cell: ({ cell, row }) => {
        const handleClickRate = () => {
          if (typeof row.original.click_rate === 'number') {
            return `${numbersToStringWithCommas({
              number: row.original.click_rate,
              showDecimals: true,
              style: 'percent',
            })}`;
          }
          const timesSurfacedGreaterThanZero =
            cell.getValue() && cell.getValue() > 0;
          return `${numbersToStringWithCommas({
            number: timesSurfacedGreaterThanZero ? 0 : undefined,
            showDecimals: true,
            style: 'percent',
          })}`;
        };
        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='font14'>
                {numbersToStringWithCommas({ number: cell.getValue() })}{' '}
              </Typography>
              <Typography color={palette.colors.grey[600]} variant='font14'>
                times surfaced
              </Typography>
            </Box>
            <Box>
              <Typography variant='font14'>
                {numbersToStringWithCommas({ number: row.original.clicks })}
              </Typography>
              <Typography color={palette.colors.grey[600]} variant='font14'>
                {' '}
                {`(${handleClickRate()}) clicked`}
              </Typography>
            </Box>
          </Box>
        );
      },
      Header: ({ column }) => (
        <Box display='flex' gap='4px'>
          {column.columnDef.header}
          <Box
            display='flex'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'article',
                value: 'times_surfaced',
              });
            }}
          >
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.articles_surfaced_and_click}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
      ),
      header: TABLE_COLUMN_TO_LABEL_MAP.article.times_surfaced,
      size: 200,
    }),
    columnHelper.accessor('csat', {
      Cell: ({ cell }) => {
        if (!cell.getValue()) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        const value = formatCSAT(cell.getValue());
        return (
          <Typography
            color={getCSATColor(Number(value))}
            variant='font14Medium'
          >
            {value}
          </Typography>
        );
      },
      Header: ({ column }) => (
        <Box display='flex' gap='4px'>
          {column.columnDef.header}
          <Box
            display='flex'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'article',
                value: 'csat',
              });
            }}
          >
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.articles_avg_csat}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
      ),
      header: TABLE_COLUMN_TO_LABEL_MAP.article.csat,
    }),
    columnHelper.accessor('user_feedback_positive', {
      Cell: ({ row }) => {
        const totalFeedback =
          row.original.user_feedback_positive +
          row.original.user_feedback_negative;

        if (totalFeedback < 1) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        return (
          <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
            <Tooltip
              fullWidth
              maxWidth={320}
              tooltipContent={
                <BreakdownPaletteTooltip
                  values={[
                    {
                      color: palette.colors.green[500],
                      label: 'Positive',
                      value: row.original.user_feedback_positive,
                    },
                    {
                      color: palette.colors.red[500],
                      label: 'Negative',
                      value: row.original.user_feedback_negative,
                    },
                    {
                      color: palette.colors.slate[100],
                      label: 'Not answered',
                      value: row.original.user_feedback_unanswered,
                    },
                  ]}
                />
              }
            >
              <HorizontalStackedBar
                data={[
                  {
                    color: palette.colors.green[500],
                    value: row.original.user_feedback_positive,
                  },
                  {
                    color: palette.colors.red[500],
                    value: row.original.user_feedback_negative,
                  },
                ]}
              />
            </Tooltip>
          </Box>
        );
      },
      Header: ({ column }) => (
        <Box display='flex' gap='4px'>
          {column.columnDef.header}
          <Box
            display='flex'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'article',
                value: 'quick_feedback',
              });
            }}
          >
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.articles_user_feedback}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
      ),
      header: 'Quick feedback',
      size: 200,
    }),
    ...(shouldShowDeflectionInsights
      ? [
          columnHelper.accessor('relevance_breakdown', {
            Cell: ({ cell }) => {
              const relevanceBreakdown = cell.getValue();
              if (
                !relevanceBreakdown ||
                calculateSum(Object.values(relevanceBreakdown)) === 0
              ) {
                return (
                  <Typography color={palette.colors.grey[500]} variant='font14'>
                    {NA}
                  </Typography>
                );
              }
              return (
                <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
                  <Tooltip
                    fullWidth
                    maxWidth={320}
                    tooltipContent={
                      <BreakdownPaletteTooltip
                        values={[
                          {
                            color: TEMP_BLUE,
                            label: 'Relevant',

                            value: relevanceBreakdown.relevant_count,
                          },
                          {
                            color: palette.colors.blue[400],
                            label: 'Somewhat relevant',
                            value: relevanceBreakdown.somewhat_relevant_count,
                          },
                          {
                            color: palette.colors.red[500],
                            label: 'Irrelevant',
                            value: relevanceBreakdown.irrelevant_count,
                          },
                        ]}
                      />
                    }
                  >
                    <HorizontalStackedBar
                      data={[
                        {
                          color: TEMP_BLUE,
                          value: relevanceBreakdown.relevant_count,
                        },
                        {
                          color: palette.colors.blue[400],
                          value: relevanceBreakdown.somewhat_relevant_count,
                        },
                        {
                          color: palette.colors.red[500],
                          value: relevanceBreakdown.irrelevant_count,
                        },
                      ]}
                    />
                  </Tooltip>
                </Box>
              );
            },
            Header: ({ column }) => (
              <Box alignItems='center' display='flex' gap='4px'>
                {column.columnDef.header}
                <Tooltip
                  maxWidth={320}
                  tooltipContent={
                    <RelevanceTooltip text={INSIGHT_TOOLTIP_COPY.relevance} />
                  }
                >
                  <IconInfoCircle color={palette.colors.grey[700]} size={20} />
                </Tooltip>
              </Box>
            ),
            header: TABLE_COLUMN_TO_LABEL_MAP.article.relevance_breakdown,
            id: 'total_relevant_count',
            size: 180,
          }),
          columnHelper.accessor('dropoff_breakdown', {
            Cell: ({ cell }) => {
              const dropoffBreakdown = cell.getValue();
              if (
                !dropoffBreakdown ||
                calculateSum(Object.values(dropoffBreakdown)) === 0
              ) {
                return (
                  <Typography color={palette.colors.grey[500]} variant='font14'>
                    {NA}
                  </Typography>
                );
              }
              return (
                <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
                  <Tooltip
                    fullWidth
                    maxWidth={320}
                    tooltipContent={
                      <BreakdownPaletteTooltip
                        values={[
                          {
                            color: TEMP_BLUE,
                            label: 'Yes',
                            value: dropoffBreakdown.no_dropoff_count,
                          },
                          {
                            color: palette.colors.red[500],
                            label: 'No',
                            value: dropoffBreakdown.dropoff_count,
                          },
                        ]}
                      />
                    }
                  >
                    <HorizontalStackedBar
                      data={[
                        {
                          color: TEMP_BLUE,
                          value: dropoffBreakdown.no_dropoff_count,
                        },
                        {
                          color: palette.colors.red[500],
                          value: dropoffBreakdown.dropoff_count,
                        },
                      ]}
                    />
                  </Tooltip>
                </Box>
              );
            },
            header: TABLE_COLUMN_TO_LABEL_MAP.article.dropoff_breakdown,
            Header: ({ column }) => (
              <Box alignItems='center' display='flex' gap='4px'>
                {column.columnDef.header}

                <Tooltip
                  maxWidth={320}
                  tooltipContent={
                    <UserEngagementTooltip
                      text={INSIGHT_TOOLTIP_COPY.engagement}
                    />
                  }
                >
                  <IconInfoCircle color={palette.colors.grey[700]} size={20} />
                </Tooltip>
              </Box>
            ),
            id: 'dropoff_breakdown',
            size: 210,
          }),
          columnHelper.accessor(row => row, {
            Cell: ({ row }) => {
              const dropoffRate = row.original.dropoff_rate;

              return `${(dropoffRate * 100).toFixed(1)}%`;
            },
            header: TABLE_COLUMN_TO_LABEL_MAP.article.dropoff_rate,
            id: 'dropoff_rate',
            size: 180,
          }),
        ]
      : []),
    columnHelper.accessor('workflows', {
      Cell: ({ cell }) => {
        return (
          <Box display='flex' flexDirection='column' gap='4px' width='200px'>
            {cell.getValue().map(workflow => (
              <Badge
                content={workflow.intent_name}
                key={workflow.intent_id}
                onClick={e =>
                  handleClickLink(e, workflow.intent_id, workflow.workflow_id)
                }
              />
            ))}
          </Box>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.article.workflows,
      minSize: 210,
    }),
    columnHelper.accessor('related_articles', {
      Cell: ({ cell }) => {
        return (
          <Box display='flex' flexDirection='column' width='200px'>
            {cell.getValue().map(article => (
              <a
                href={article.article_link}
                key={article.article_id}
                onClick={e => {
                  e.stopPropagation();
                  emitTrackingEventCallback(
                    'insight-related-article-link-clicked',
                    {
                      id: article.article_id,
                      link: article.article_link,
                      scope,
                    },
                  );
                }}
                rel='noreferrer'
                target='_blank'
              >
                <Tooltip tooltipContent={article.article_title}>
                  <Box
                    sx={{
                      '&:hover': {
                        bgcolor: palette.colors.slate[200],
                        cursor: 'pointer',
                      },
                      overflow: 'hidden',
                      textDecoration: 'underline',
                      textOverflow: 'ellipsis',
                      width: '200px',
                    }}
                  >
                    <Typography
                      color={palette.colors.blue[600]}
                      noWrap
                      variant='font12Medium'
                    >
                      {article.article_title}
                    </Typography>
                  </Box>
                </Tooltip>
              </a>
            ))}
          </Box>
        );
      },
      enableSorting: false,
      Header: ({ column }) => (
        <Box display='flex' gap='4px'>
          {column.columnDef.header}
          <Box
            display='flex'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'article',
                value: 'related_articles',
              });
            }}
          >
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.articles_related_articles}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
      ),
      header: TABLE_COLUMN_TO_LABEL_MAP.article.related_articles,
      minSize: 232,
    }),
  ];

  const quickFeedbackColumnIndex = columns.findIndex(
    column => column.header === 'Quick feedback',
  );

  if (!shouldShowQuickFeedbackAnalytics && quickFeedbackColumnIndex > -1) {
    columns.splice(quickFeedbackColumnIndex, 1);
  }

  return columns;
};

export class ArticleTableDataFilter {
  constructor(private data: InsightArticle[]) {}

  filterByKeyword = (searchText: string) => {
    if (searchText.trim()) {
      const searchTextToLowerCase = searchText.toLowerCase();
      this.data = this.data.filter(({ title: name }) => {
        return name?.toLowerCase().includes(searchTextToLowerCase);
      });
    }

    return this.data;
  };
}
