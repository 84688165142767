import React, { FC } from 'react';
import { styled } from '@mui/material';

import xIcon from 'src/assets/images/icon-close-bttn.svg';
import { FieldAndValue } from 'src/types/workflowBuilderAPITypes';

interface RemoveFieldButtonProps {
  fieldName: string;
  idValueArr: Array<FieldAndValue>;
  idx: number;
  setFieldValue: (name: string, value: Array<FieldAndValue>) => void;
}

const RemoveFieldButton: FC<
  React.PropsWithChildren<RemoveFieldButtonProps>
> = ({ fieldName, idValueArr, idx, setFieldValue }) => {
  return (
    <Container>
      <CloseButton
        onClick={() => {
          const updatedFieldValues = idValueArr
            .slice(0, idx)
            .concat(idValueArr.slice(idx + 1, idValueArr.length));
          setFieldValue(fieldName, updatedFieldValues);
        }}
        role='button'
        src={xIcon}
      />
    </Container>
  );
};

export default RemoveFieldButton;

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  width: 30px;
`;

const CloseButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;
