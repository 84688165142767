import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Menu, MenuItem, styled, useTheme } from '@mui/material';
import { IconTrash } from '@tabler/icons-react';

import {
  IconButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { IconButtonWithActive } from './StyledComponents';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import {
  selectCanvasActionIdToActionComponent,
  selectIsStepOrActionALeaf,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { dashboardApi } from 'src/services/dashboard-api';
import {
  selectIsRichTextEditorValueEmpty,
  setTargetStepId,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  deleteWorkflowAction,
  deleteWorkflowStep,
  deleteWorkflowStepAndBelow,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  StepTypes,
} from 'src/utils/enums';

interface EditMenuDeleteButtonProps {
  actionType?: string;
  isCurrentStepDeletable: boolean;
  setIsEditMenuVisible: (isVisible: boolean) => void;
  stepId: string;
  stepType?: StepTypes;
}

export const EditMenuDeleteButton = ({
  actionType,
  isCurrentStepDeletable,
  setIsEditMenuVisible,
  stepId,
  stepType,
}: EditMenuDeleteButtonProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );
  const canvasActionIdToActionComponent = useSelector(
    selectCanvasActionIdToActionComponent,
  );
  const isLeaf = useSelector(selectIsStepOrActionALeaf(stepId));
  const isRichTextEditorValueEmpty = useSelector(
    selectIsRichTextEditorValueEmpty,
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // remove the highlighted step if there's any
    dispatch(setTargetStepId(null));

    if (!isRichTextEditorValueEmpty) {
      return;
    }

    setAnchorEl(event.currentTarget);

    setIsMenuVisible(true);
    setIsEditMenuVisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteCurrentAction = () => {
    const [actionId] =
      Object.entries(canvasActionIdToActionComponent).find(([, value]) =>
        value.steps.includes(stepId),
      ) || [];
    if (actionId) {
      dispatchTrackingAction(
        FlamethrowerTrackingEventTypes.WORKFLOW_ACTION_DELETED,
        {
          actionId: actionId,
          actionType: actionType || '',
        },
      );
      dispatch(deleteWorkflowAction(actionId));
    }
    // to sync with action builder endpoints
    dispatch(dashboardApi.util.invalidateTags(['Actions', 'ActionUsages']));
  };

  const handleDeleteCurrentStep = () => {
    dispatch(deleteWorkflowStep(stepId));

    dispatchTrackingAction(
      FlamethrowerTrackingEventTypes.WORKFLOW_STEP_DELETED,
      { stepId: stepId, stepType: stepType || '' },
    );
  };

  const handleDeleteThisClicked = () => {
    // remove the highlighted step if there's any
    dispatch(setTargetStepId(null));

    if (!isRichTextEditorValueEmpty) {
      return;
    }

    if (stepType) {
      handleDeleteCurrentStep();
    } else {
      handleDeleteCurrentAction();
    }

    handleClose();
  };

  const handleDeleteStepAndBelowClicked = () => {
    // remove the highlighted step if there's any
    dispatch(setTargetStepId(null));

    if (stepType) {
      dispatch(deleteWorkflowStepAndBelow({ stepId: stepId }));

      dispatchTrackingAction(
        FlamethrowerTrackingEventTypes.WORKFLOW_STEP_AND_BELOW_DELETED,
        { stepId: stepId, stepType: stepType },
      );
    } else {
      const [actionId] =
        Object.entries(canvasActionIdToActionComponent).find(([, value]) =>
          value.steps.includes(stepId),
        ) || [];

      if (actionId) {
        dispatch(deleteWorkflowStepAndBelow({ canvasActionId: actionId }));

        dispatchTrackingAction(
          FlamethrowerTrackingEventTypes.WORKFLOW_STEP_AND_BELOW_DELETED,
          { actionType: actionType || '', stepId: stepId },
        );
      }
    }

    handleClose();
  };

  if (isLeaf) {
    return (
      <Tooltip tooltipContent={'Delete'}>
        <IconButton
          aria-label='Delete step'
          onClick={handleDeleteThisClicked}
          variant='ghost'
        >
          <IconTrash color={theme.palette.colors.red[500]} size={20} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <Tooltip tooltipContent={'Delete options...'}>
        <IconButtonWithActive
          aria-label='Delete step'
          isActive={isMenuVisible}
          onClick={handleClick}
        >
          <IconTrash color={theme.palette.colors.red[500]} size={20} />
        </IconButtonWithActive>
      </Tooltip>
      <Backdrop
        onClick={() => {
          setIsMenuVisible(false);
          setIsEditMenuVisible(false);
        }}
        open={isMenuVisible}
        sx={{ background: 'transparent' }}
      >
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          onClose={handleClose}
          open={open}
          sx={{
            '& .MuiPaper-root': {
              border: `1px solid ${theme.palette.colors.grey[200]}`,
              borderRadius: '4px',
              boxShadow:
                '0px 0px 1px rgba(0, 12, 32, 0.04), 2px 5px 8px rgba(3, 17, 38, 0.107135)',
              minWidth: '160px',
              padding: '4px 0',
            },
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <StyledMenuItem
            disabled={!isCurrentStepDeletable}
            disableRipple
            onClick={handleDeleteThisClicked}
          >
            <Typography variant='font14'>Delete this</Typography>
          </StyledMenuItem>
          <StyledMenuItem
            disableRipple
            onClick={handleDeleteStepAndBelowClicked}
          >
            <Typography variant='font14'>Delete this and all below</Typography>
          </StyledMenuItem>
        </Menu>
      </Backdrop>
    </>
  );
};

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    background-color: ${props => props.theme.palette.colors.purple[100]};
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
