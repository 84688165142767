import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { styled } from '@mui/material/styles';

import { Alert } from '@forethought-technologies/forethought-elements';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import Spinner from 'src/components/spinner';
import { getLookerDashboardURL } from 'src/services/api';
import { Tabs } from 'src/utils/enums';

const IFRAME_ID = 'looker';

const LookerDashboard = () => {
  const { dashboardId } = useParams<{ dashboardId?: string }>();
  const [embedURL, setEmbedURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmbedURL('');
    setErrorMessage('');
    setLoading(true);

    if (dashboardId) {
      getLookerDashboardURL(
        dashboardId,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      )
        .then(setEmbedURL)
        .catch(() => setErrorMessage('Error retrieving dashboard'))
        .finally(() => setLoading(false));
    }
  }, [dashboardId]);

  return (
    <>
      <NavbarV2 currentTabOverride={Tabs.LOOKER_ANALYTICS} />
      <Container>
        {loading && <Spinner />}
        {embedURL && (
          <EmbedFrame id={IFRAME_ID} key={dashboardId} src={embedURL} />
        )}
        {errorMessage && (
          <Alert isFullWidth title={errorMessage} variant='danger' />
        )}
      </Container>
    </>
  );
};

export default LookerDashboard;

const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EmbedFrame = styled('iframe')`
  display: flex;
  flex: 1;
  border: none;
`;
