export const buttonStyles = {
  '&:hover:not(:disabled,.Mui-selected)': {
    backgroundColor: '#e8e9eb',
  },
  '&:not(:disabled,.Mui-selected)': {
    color: '#000',
  },
  border: 'none !important',
  borderRadius: '6px',
  margin: '0 5px 0 0',
  padding: '6px',
} as const;
