import { useCallback, useEffect } from 'react';

import { useLocalStorage } from './useLocalStorage';
import { initialBadgedRoutes } from 'src/components/app/sidebar/navData';
import { BadgedRoutes } from 'src/components/app/sidebar/types';
import { Routes } from 'src/utils/enums';

export const useBadgedRoutes = (pathname: string) => {
  const [badgedRoutes, setBadgedRoutes] = useLocalStorage<BadgedRoutes>(
    'badgedRoutes',
    initialBadgedRoutes,
  );

  // The list of paths which are active
  const activeBadgedRoutes = Object.keys(badgedRoutes).filter(
    route => badgedRoutes[route as keyof BadgedRoutes],
  );

  // Helper provided to update the badge status of a path
  const updateBadgedRoute = useCallback(
    (route: Routes, to: boolean) => {
      badgedRoutes[route] = to;
      setBadgedRoutes(badgedRoutes);
    },
    [badgedRoutes, setBadgedRoutes],
  );

  // Check to see if the current path is one of the badged paths
  useEffect(() => {
    if (activeBadgedRoutes.find(path => path === pathname)) {
      setBadgedRoutes(draftBadgedRoutes => ({
        ...draftBadgedRoutes,
        [pathname]: false,
      }));
    }
  }, [pathname, activeBadgedRoutes, setBadgedRoutes]);

  return { activeBadgedRoutes, badgedRoutes, updateBadgedRoute };
};
