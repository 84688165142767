import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import {
  Skeleton,
  theme,
} from '@forethought-technologies/forethought-elements';
import { DISCOVER_CARD_MIN_WIDTH } from 'src/constants/discover';

export const DiscoverTabMetricCardSkeletonGroup = () => {
  return (
    <>
      {Array(6)
        .fill(null)
        .map((_, idx) => (
          <Grid
            item
            key={idx}
            lg={4}
            md={4}
            minWidth={DISCOVER_CARD_MIN_WIDTH}
            xs={2}
          >
            <Box
              pt={3.375}
              sx={{
                border: `1px solid ${theme.palette.colors.slate[200]}`,
                borderRadius: '8px',
              }}
            >
              <Box pl={3} pr={3}>
                <Skeleton height={16} variant='rectangular' width={138} />
                <Box
                  alignItems='center'
                  display='flex'
                  gap={2.5}
                  justifyContent='space-between'
                  mb={4.5}
                  mt={2.375}
                >
                  <Skeleton height={32} variant='rectangular' width={100} />
                  <Skeleton height={32} variant='rectangular' width={69} />
                  <Skeleton height={32} variant='rectangular' width={161} />
                </Box>
              </Box>
              <Box
                height={38}
                sx={{
                  borderTopColor: theme.palette.colors.slate[100],
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                }}
              />
            </Box>
          </Grid>
        ))}
    </>
  );
};

export const DiscoverTopicMetricDetailCardSkeleton = () => {
  return (
    <Box p={3} pb={6.125}>
      <Skeleton height={16} variant='rectangular' width={138} />
      <Box mb={2.375} />
      <Skeleton height={29} variant='rectangular' width={96} />
      <Box mb={2.375} />
      <Skeleton height={16} variant='rectangular' width={222} />
      <Box mb={2} />
      <Skeleton height={16} variant='rectangular' width={173} />
      {Array(5)
        .fill(null)
        .map((_, idx) => (
          <Box
            key={idx}
            mb={idx !== 5 ? 8 : 0}
            ml='10%'
            mr='5%'
            mt={idx === 0 ? 4 : 0}
            sx={{
              borderBottomColor: theme.palette.colors.slate[300],
              borderBottomStyle: 'solid',
              borderBottomWidth: idx == 5 ? 2 : 1,
            }}
          />
        ))}
    </Box>
  );
};

export const DiscoverTopMoverSkeleton = () => {
  return (
    <>
      {Array(6)
        .fill(null)
        .map((_, idx) => (
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            key={idx}
            pb={2.625}
            pt={idx === 0 ? 3 : 2}
            sx={{
              borderBottomColor: theme.palette.colors.slate[100],
              borderBottomStyle: 'solid',
              borderBottomWidth: 1,
            }}
          >
            <Skeleton height={16} variant='rectangular' width='25%' />
            <Box width='20%'>
              <Skeleton height={16} variant='rectangular' width='100%' />
              <Box pb={0.625} />
              <Skeleton height={16} variant='rectangular' width='25%' />
            </Box>
            <Skeleton height={32} variant='rectangular' width='13%' />
            <Skeleton height={32} variant='rectangular' width='25%' />
          </Box>
        ))}
    </>
  );
};

export const DiscoverCommonTopicSkeleton = () => {
  let graphWidthPercentage = 100;
  return (
    <>
      {Array(15)
        .fill(null)
        .map((_, idx) => {
          // Reducing width of skeleton volume on each iteration
          graphWidthPercentage /= 1.1;
          const titleWidth = (idx * 62603) % 100;
          return (
            <Box display='flex' key={idx} mb={1}>
              <Box display='flex' justifyContent='flex-end' mr={2} width={180}>
                <Skeleton
                  height={16}
                  variant='rectangular'
                  width={`${titleWidth}%`}
                />
              </Box>
              <Box alignItems='center' display='flex' flex={1}>
                <Skeleton
                  height={16}
                  variant='rectangular'
                  width={`${graphWidthPercentage}%`}
                />
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export const DiscoverTopicTicketSkeleton = () => {
  return (
    <Box display='flex' flexDirection='column' gap={3}>
      {Array(5)
        .fill(null)
        .map((_, idx) => (
          <Box
            key={idx}
            sx={{
              border: `1px solid ${theme.palette.colors.slate[200]}`,
              borderRadius: '8px',
            }}
          >
            <Box display='flex' flexDirection='column' gap={2} p={3} pt={3.375}>
              <Skeleton height={12} variant='rectangular' width='20%' />
              <Skeleton height={16} variant='rectangular' width='25%' />
              <Skeleton height={12} variant='rectangular' width='100%' />
              <Skeleton height={12} variant='rectangular' width='100%' />
            </Box>
            <Box
              mb={4.625}
              sx={{
                borderBottomColor: theme.palette.colors.slate[100],
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
              }}
            />
          </Box>
        ))}
    </Box>
  );
};
