import { createAction } from '@reduxjs/toolkit';

import { PageSettingsActions } from '../actionTypes';
import { CustomRangeModifier } from 'src/reducers/pageSettingsReducer/types';
import { ViewModes } from 'src/utils/enums';

export const setViewMode = createAction<ViewModes>(
  PageSettingsActions.SET_VIEW_MODE,
);

export const setSolveView = createAction<string>(
  PageSettingsActions.SET_SOLVE_VIEW_MODE,
);

export const setMacroControlsViewMode = createAction<string>(
  PageSettingsActions.SET_MACRO_CONTROLS_VIEW_MODE,
);

export const setTimeFrameFilter = createAction<string>(
  PageSettingsActions.SET_TIME_FRAME_FILTER,
);

export const setTimeRange = createAction<CustomRangeModifier>(
  PageSettingsActions.SET_TIME_RANGE,
);

export const setChartYAxis = createAction<string>(
  PageSettingsActions.SET_Y_AXIS,
);

export const loadChartYAxis = createAction<string>(
  PageSettingsActions.LOAD_Y_AXIS,
);

export const setPageNumber = createAction<number>(
  PageSettingsActions.SET_PAGE_NUMBER,
);
