import { dashboardApi } from '../dashboard-api';
import {
  GeneratedEmailResponse,
  GeneratedEmailResponses,
  UpdateGeneratedResponseStatusRequest,
} from 'src/pages/workflow-builder-edit/types';

const BASE_URL = 'solve/email';

const responseGenerationApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getGeneratedResponses: builder.query<GeneratedEmailResponses, string>({
      // We will execute some logic depending on the response of this query that can change
      // at any point in time so we don't want to rely on the cached data.
      keepUnusedDataFor: 0,
      query: intentId => `${BASE_URL}/generated-replies/${intentId}`,
    }),
    updateGeneratedResponseStatus: builder.mutation<
      void,
      UpdateGeneratedResponseStatusRequest
    >({
      async onQueryStarted(
        { generatedResponseId, intentDefinitionId, status },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          responseGenerationApi.util.updateQueryData(
            'getGeneratedResponses',
            intentDefinitionId,
            draft => {
              const generatedResponse: GeneratedEmailResponse | undefined =
                draft.generated_replies.find(
                  response => response.reply_id === generatedResponseId,
                );

              if (generatedResponse) {
                generatedResponse.status = status;
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
          throw e;
        }
      },
      query: ({ generatedResponseId, intentDefinitionId, status }) => ({
        body: { status },
        method: 'PUT',
        url: `${BASE_URL}/generated-replies/${intentDefinitionId}/${generatedResponseId}/status`,
      }),
    }),
  }),
});

export const {
  useGetGeneratedResponsesQuery,
  useUpdateGeneratedResponseStatusMutation,
} = responseGenerationApi;
