import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  FilterButton,
  Pagination,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ArticleCard from './ArticleCard';
import noArticlesIcon from 'src/assets/images/empty-article-table-image.svg';
import { TopicTimeFilter } from 'src/components/app/types';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import { DiscoverTopicTicketSkeleton } from 'src/components/discover-skeleton/DiscoverSkeleton';
import { ARTICLES_PER_PAGE, sortFilterOptions } from 'src/constants/discover';
import { DiscoverArticle } from 'src/reducers/discoverReducer/types';
import {
  selectGlobalDiscoverArticleOptions,
  setGlobalDiscoverArticleOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface TopicDetailArticleSectionProps {
  articleData: DiscoverArticle[];
  emitArticleClickedTrackingEvent: ({ value }: { value: string }) => void;
  emitSortedTrackingEvent: ({ value }: { value: string }) => void;
  isLoading: boolean;
  timeFilter: TopicTimeFilter;
}

const TopicArticlesTable = ({
  articleData,
  emitArticleClickedTrackingEvent,
  emitSortedTrackingEvent,
  isLoading,
  timeFilter,
}: TopicDetailArticleSectionProps) => {
  const dispatch = useAppDispatch();

  const { articleSortFilterValue } = useSelector(
    selectGlobalDiscoverArticleOptions,
  );

  const theme = useTheme();
  const [pageIndex, setPageIndex] = useState(0);

  const startingArticleIndex = pageIndex * ARTICLES_PER_PAGE;
  const pageArticleData = articleData.slice(
    startingArticleIndex,
    startingArticleIndex + ARTICLES_PER_PAGE,
  );
  const isArticleDataAvailable = pageArticleData.length > 0;

  return (
    <>
      {isArticleDataAvailable || isLoading ? (
        <>
          {isArticleDataAvailable && (
            <Box mt='24px'>
              <Typography
                color={theme.palette.colors.grey[600]}
                variant='font16'
              >
                We generated these articles by identifying gaps in your
                knowledge base based on your help desk tickets.
              </Typography>
            </Box>
          )}
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            mt='24px'
          >
            <FilterButton
              aria-label='sort article'
              disabledTooltipMessage='Not available for this metric'
              initialValue={sortFilterOptions[0].value}
              onChange={value => {
                emitSortedTrackingEvent({ value });
                dispatch(
                  setGlobalDiscoverArticleOptions({
                    articleSortFilterValue: value as 'newest' | 'oldest',
                  }),
                );
              }}
              options={sortFilterOptions.filter(option =>
                ['newest', 'oldest'].includes(option.value),
              )}
              startAdornment={<FilterButtonIcon dropdownType='ticket' />}
              value={articleSortFilterValue}
            />
            <Typography color={theme.palette.colors.grey[800]} variant='font12'>
              {timeFilter.label}
            </Typography>
          </Box>
          <Box
            data-appcues-target={getAppCuesId({
              componentType: 'list',
              featureName: 'articles',
              pageName: 'topicdetail',
              subType: 'articles',
            })}
            mt={3}
          >
            {isLoading ? (
              <DiscoverTopicTicketSkeleton />
            ) : (
              pageArticleData.map(articleData => (
                <ArticleCard
                  articleData={articleData}
                  key={articleData.article_id}
                  onClick={() => {
                    emitArticleClickedTrackingEvent({
                      value: articleData.article_id,
                    });

                    dispatch(
                      setGlobalDiscoverArticleOptions({
                        selectedArticleId: articleData.article_id,
                      }),
                    );
                  }}
                  setFeedBackArticleModalId={feedbackArticleModalId => {
                    dispatch(
                      setGlobalDiscoverArticleOptions({
                        feedbackArticleModalId,
                      }),
                    );
                  }}
                />
              ))
            )}
          </Box>
          <Box display='flex' justifyContent='center' width='100%'>
            {!isLoading && (
              <Pagination
                count={Math.ceil(articleData.length / ARTICLES_PER_PAGE)}
                defaultPage={0}
                onChange={(_, page) => setPageIndex(page - 1)}
                page={pageIndex + 1}
              />
            )}
          </Box>
        </>
      ) : (
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          mb={7.75}
          mt={5}
        >
          <img src={noArticlesIcon} />
          <Box mb={3} />
          <Typography color={theme.palette.colors.black} variant='font16Bold'>
            No generated articles found
          </Typography>
          <Box mb={1} />
          <Typography color={theme.palette.colors.grey[700]} variant='font14'>
            Try reloading the page later
          </Typography>
        </Box>
      )}
    </>
  );
};
export default TopicArticlesTable;
