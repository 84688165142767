import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { Routes } from 'src/utils/enums';

interface DynamicListCVSelectDropdownProps {
  onChange: (value: string) => void;
  value?: string;
}

export const DynamicListCVSelectDropdown = ({
  onChange,
  value = '',
}: DynamicListCVSelectDropdownProps) => {
  const { contextVariables } = useGetContextVariables();
  const { palette } = useTheme();
  const navigate = useNavigate();

  const dynamicListContextVariables = useMemo(
    () =>
      contextVariables.filter(
        cv => cv.context_variable_type === 'DYNAMIC_LIST',
      ),
    [contextVariables],
  );
  return (
    <SelectDropdown
      addNewButtonText='Create Dynamic Context Variable list'
      id='dynamic-list-cv-dropdown'
      isMenuSearchable
      key={dynamicListContextVariables.length}
      label='Select a Dynamic List Context Variable'
      menuMaxHeight={450}
      onAddNewClick={e => {
        e.stopPropagation();
        navigate(Routes.ACTION_BUILDER);
      }}
      onChange={e => {
        onChange(e?.target.value ?? '');
      }}
      options={dynamicListContextVariables.map(
        ({ context_variable_id: id, context_variable_name: displayName }) => ({
          color: palette.colors.purple[500],
          label: displayName,
          value: id,
        }),
      )}
      placeholder='Select Context Variable'
      value={value}
    />
  );
};
