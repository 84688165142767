import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import {
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { selectAutomations } from '../../../../slices/assist-automations/assistAutomationsSlice';
import InputArrayTooltip from '../../InputArrayTooltip';
import { InputMethod } from '../../types';
// Helpers
import {
  convertInputMapToFormatter,
  convertInputValueToVariable,
  inputMethodOptions,
} from './helpers';
import cloneDeep from 'lodash/fp/cloneDeep';
// Assets
import EqualIcon from 'src/assets/images/equal-automation-icon.svg';
// Services
import { ConnectorAction } from 'src/services/apiInterfaces';
import { AutomationForm } from 'src/slices/assist-automations/types';

const InputCard: React.FC<
  React.PropsWithChildren<{ action: ConnectorAction; isReadOnly: boolean }>
> = ({ action, isReadOnly }) => {
  const [actionInputMap, setActionInputMap] = useState<Record<string, string>>(
    {},
  );
  const { setFieldValue, values } = useFormikContext<AutomationForm>();
  const { availableAutomations } = useSelector(selectAutomations);
  const [inputMethodValue, setInputMethodValue] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    if (action?.input_schema.properties) {
      const temporaryInputObject: Record<string, string> = {};

      if (!values.actions_input_formatter[action?.action_id]) {
        Object.entries(action.input_schema.properties).forEach(([key]) => {
          temporaryInputObject[key] = '';
        });

        setActionInputMap(temporaryInputObject);
        const actionInputArray = convertInputMapToFormatter(
          temporaryInputObject,
          [],
          action,
        );
        const currentInputFormatter = cloneDeep(values.actions_input_formatter);
        currentInputFormatter[action?.action_id] = actionInputArray;

        setFieldValue('actions_input_formatter', currentInputFormatter);
      } else {
        const currentAutomation = availableAutomations.find(
          automation => automation.automation_id === values.automation_id,
        );
        if (currentAutomation?.action_list.length) {
          Object.keys(action.input_schema.properties).forEach(key => {
            values.actions_input_formatter[action?.action_id].forEach(input => {
              if (input[key]) {
                temporaryInputObject[key] = String(input[key]);
              }
            });
          });
          setActionInputMap(temporaryInputObject);
        }
      }
    }
  }, [
    action,
    availableAutomations,
    setFieldValue,
    values.actions_input_formatter,
    values.automation_id,
  ]);

  useEffect(() => {
    const inputs =
      values.actions_input_formatter &&
      values.actions_input_formatter[action?.action_id];
    const inputSchemaProperties = action?.input_schema.properties;

    if (inputs && inputs.length) {
      const temporaryInputMethodValue: Record<string, string> = {};

      if (inputSchemaProperties && values.org_id) {
        Object.keys(inputSchemaProperties).forEach(key => {
          inputs.forEach(input => {
            if (input[key]) {
              temporaryInputMethodValue[key] =
                String(input[key])[0] === '$'
                  ? InputMethod.AGENT_PROVIDED
                  : InputMethod.HARDCODED;
            }
          });
        });
      }

      setInputMethodValue(temporaryInputMethodValue);
    }

    // this useState hook only executes for set initial inputMethodValue on this point all needed values are existed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.org_id]);

  useEffect(() => {
    if (!Object.keys(values.actions_input_formatter).length) {
      setInputMethodValue({});
    }
  }, [values.actions_input_formatter]);

  function changeActionInput(key: string, value: string) {
    if (!action) {
      return;
    }
    const newActionInputMap = Object.assign({}, actionInputMap, {
      [key]: value,
    });
    setActionInputMap(newActionInputMap);

    const actionInputArray = convertInputMapToFormatter(
      newActionInputMap,
      values.input_fields,
      action,
    );
    const currentInputFormatter = cloneDeep(values.actions_input_formatter);
    currentInputFormatter[action?.action_id] = actionInputArray;

    setFieldValue('actions_input_formatter', currentInputFormatter);
  }

  function changeInputMethod(key: string, value: string) {
    if (!action) {
      return;
    }

    const newInputMethodValue = Object.assign({}, inputMethodValue, {
      [key]: value,
    });

    setInputMethodValue(newInputMethodValue);

    const propertyTitle =
      action?.input_schema.properties &&
      action.input_schema.properties[key].title;

    if (value === InputMethod.AGENT_PROVIDED && propertyTitle) {
      changeActionInput(key, convertInputValueToVariable(propertyTitle));
    } else {
      changeActionInput(key, '');
    }
  }

  if (
    !action.input_schema.properties ||
    !Object.keys(action.input_schema.properties).length
  ) {
    return null;
  }

  return (
    <>
      <Typography variant='font14Medium'>Required Inputs</Typography>
      <InputRows>
        {Object.entries(action.input_schema.properties).map(
          ([key, property], index) => {
            const title =
              (typeof property === 'object' && property.title) || '';

            const variableStyleValue = actionInputMap[key] || '';
            const variableStyleError =
              !variableStyleValue &&
              action.input_schema.required?.includes(key);
            const variableStyleOptions =
              typeof property === 'object' && property.enum
                ? property.enum.map(optionItem => ({
                    label: String(optionItem),
                    value: String(optionItem),
                  }))
                : [];

            return (
              <InputRow key={key}>
                <TextField
                  disabled
                  label={index === 0 ? 'API Input' : ''}
                  value={title}
                />
                <InputMethodWrapper>
                  <SelectDropdown
                    id={`input-method-${index}`}
                    label={index === 0 ? 'Input Method' : undefined}
                    onChange={e => {
                      if (!isReadOnly) {
                        changeInputMethod(key, e.target.value);
                      }
                    }}
                    options={inputMethodOptions}
                    placeholder='Input Method'
                    value={inputMethodValue[key]}
                  />
                </InputMethodWrapper>

                <Equal row={index} src={EqualIcon} />
                {variableStyleOptions.length &&
                inputMethodValue[key] !== InputMethod.AGENT_PROVIDED ? (
                  <SelectDropdown
                    disabled={!inputMethodValue[key]}
                    error={variableStyleError}
                    id={`variable-style-${index}`}
                    label={index === 0 ? 'Save Value as' : undefined}
                    onChange={e => {
                      changeActionInput(key, e.target.value);
                    }}
                    options={variableStyleOptions}
                    placeholder='Select variable style '
                    value={variableStyleValue}
                  />
                ) : (
                  <TextField
                    disabled={
                      !inputMethodValue[key] ||
                      inputMethodValue[key] === InputMethod.AGENT_PROVIDED
                    }
                    endAdornment={
                      property.type?.includes('array') ? (
                        <InputArrayTooltip />
                      ) : undefined
                    }
                    error={variableStyleError}
                    label={index === 0 ? 'Save Value as' : ''}
                    onChange={e => {
                      if (!isReadOnly) {
                        changeActionInput(key, e.target.value);
                      }
                    }}
                    placeholder='Select variable style '
                    required
                    value={variableStyleValue}
                  />
                )}
              </InputRow>
            );
          },
        )}
      </InputRows>
    </>
  );
};

const InputRows = styled('div')`
  margin: 10px 0 8px;
`;

const InputRow = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Equal = styled('img')<{ row: number }>`
  ${({ row }) => (row === 0 ? 'margin: 26px 17px 0;' : 'margin: 0 17px 0;')}
`;

const InputMethodWrapper = styled('div')`
  margin-left: 25px;
  min-width: 150px;
`;

export default InputCard;
