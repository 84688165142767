import { DescriptionFieldProps } from '@rjsf/utils';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function DescriptionFieldTemplate({
  description,
  schema,
}: DescriptionFieldProps) {
  // Do not render description for checkbox, because `<CheckboxWidget>` already
  // has description:
  if (schema.type === 'boolean') {
    return null;
  }

  return description ? (
    <Typography variant='font12'>{description}</Typography>
  ) : null;
}
