import { Link, List, ListItem, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ArticleSuggestionRecord } from 'src/services/apiInterfaces';

export function ArticleLinks({
  articles,
}: {
  articles: ArticleSuggestionRecord[];
}) {
  const { palette } = useTheme();

  return (
    <List component='ul' sx={{ p: 0 }}>
      {articles.map(article => (
        <ListItem key={article.source_doc_id} sx={{ p: 0 }}>
          <Link
            color={palette.colors.blue[500]}
            href={article.link}
            rel='noreferrer'
            target='_blank'
          >
            <Typography color='inherit' variant='font12'>
              {article.title}
            </Typography>
          </Link>
        </ListItem>
      ))}
    </List>
  );
}
