import { useEffect, useState } from 'react';
import { Box, css, styled, useTheme } from '@mui/material';
import { IconInfoCircle, IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  Checkbox,
  Drawer,
  SelectDropdown,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import useHelpdeskTicketFields from '../../hooks/useHelpdeskTicketFields';
import {
  addExpressionToBooleanAgregatorExpression,
  getIsExpressionEmpty,
  getQueryExpressionTypeByTicketType,
  isBooleanAgregatorExpressionEmpty,
  removeExpressionFromBooleanAgregatorExpression,
  transformBooleanAgregatorToFilterExpression,
  transformFromFilterToBooleanAgregatorExpression,
  updateAutomationActionFieldValue,
  updateAutomationActionType,
  updateBooleanAggregatorExpressionBooleanOperator,
  updateBooleanAggregatorExpressionField,
  updateBooleanAggregatorExpressionOperator,
  updateBooleanAggregatorExpressionValue,
  updateFilterExpressionField,
  updateFilterExpressionOperator,
  updateFilterExpressionValue,
} from './utils/utils';
import {
  defaultEmailAutomation,
  defaultFilterQueryExpression,
} from './constants';
import {
  automationActionTypeMap,
  automationActionTypeOptions,
  labelMap,
} from './constants';
import ConfirmModal from 'src/components/email-builder/components/ConfirmModal';
import ExpressionBuilder, {
  FieldOption,
} from 'src/components/expression-builder/ExpressionBuilder';
import Spinner from 'src/components/spinner/Spinner';
import { HELPDESK_LOGOS } from 'src/constants/solve';
import {
  AutomationActionType,
  EditEmailAutomation,
} from 'src/pages/workflow-builder-edit/types';
import { useGetHelpdeskQuery } from 'src/services/dashboard-api';
import {
  useCreateEmailAutomationMutation,
  useDeleteEmailAutomationMutation,
  useUpdateEmailAutomationMutation,
} from 'src/services/email-automation/emailAutomationApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { QueryExpressionTypes } from 'src/types/queryExpressionTypes';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

interface EmailAutomationPageProps {
  /** Email automation */
  emailAutomation: EditEmailAutomation;
  /** Determines if the component is visible */
  isVisible: boolean;
  /** Function to be executed once the component is closed */
  onClose: () => void;
  /** set */
}

const EmailAutomationPage = ({
  emailAutomation,
  isVisible,
  onClose,
}: EmailAutomationPageProps) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const {
    automation_actions,
    email_automation_id,
    query_expression,
    run_other_automations,
  } = emailAutomation;

  const [automationActions, setAutomationActions] =
    useState<EditEmailAutomation['automation_actions']>(automation_actions);
  const [queryExpression, setQueryExpression] =
    useState<EditEmailAutomation['query_expression']>(query_expression);
  const [runOtherAutomations, setRunOtherAutomations] = useState<
    EditEmailAutomation['run_other_automations']
  >(!!run_other_automations);
  const [caseInsensitive, setCaseInsensitive] = useState(
    emailAutomation.case_insensitive ?? false,
  );

  const [
    isDeleteConfirmationDialogVisible,
    setIsDeleteConfirmationDialogIsVisible,
  ] = useState(false);

  const [createEmailAutomation, { isLoading: isLoadingCreation }] =
    useCreateEmailAutomationMutation();
  const [updateEmailAutomation, { isLoading: isLoadingUpdate }] =
    useUpdateEmailAutomationMutation();
  const [deleteEmailAutomation] = useDeleteEmailAutomationMutation();

  const { data: helpdeskResponse } = useGetHelpdeskQuery();

  const {
    isLoading: isLoadingFields,
    mappedFields,
    staticFields,
    textTypeCustomFields,
  } = useHelpdeskTicketFields();

  const { helpdesk = '' } = helpdeskResponse || {};

  const {
    automation_action_fields: automationActionFields,
    automation_action_type: automationActionType,
  } = automationActions[0];

  const isNoResponse = automationActionType === 'no-response';
  const isResponseDelay = automationActionType === 'response-delay';
  const isThreadParsing = automationActionType === 'thread-parsing';

  const isFilterExpression = queryExpression.expression_type === 'filter';

  const triageModelMappings = mappedFields.filter(
    field => field.mapping_type === 'triage',
  );

  const formattedMappedFields: FieldOption[] = triageModelMappings.map(
    mappedField => ({
      category: 'Classifiers',
      label: `$${mappedField.title}`,
      type: mappedField.context_variable_type,
      value: mappedField.id,
    }),
  );

  const formattedStaticFields: FieldOption[] = staticFields.map(
    staticField => ({
      category: `${capitalizeFirstLetter(helpdesk)} Static Fields`,
      fieldOptions: staticField.field_options,
      label: staticField.title,
      type: getQueryExpressionTypeByTicketType(staticField.type),
      value: staticField.id,
    }),
  );

  const customTicketFieldAdornment = helpdesk && (
    <Box display='flex' mr={0.5}>
      <img alt='' src={HELPDESK_LOGOS[helpdesk]} />
    </Box>
  );
  const formattedCustomFields: FieldOption[] = textTypeCustomFields.map(
    customField => ({
      category: `${capitalizeFirstLetter(helpdesk)} Custom Fields`,
      label: customField.title,
      optionStartAdornment: customTicketFieldAdornment,
      type: 'SHORT_TEXT',
      value: customField.id,
    }),
  );

  const fieldOptions = [
    ...formattedStaticFields,
    ...formattedCustomFields,
    ...formattedMappedFields,
  ];

  const isUpdateMode = !!email_automation_id;

  const isQueryExpressionValueEmpty = isFilterExpression
    ? getIsExpressionEmpty(queryExpression)
    : isBooleanAgregatorExpressionEmpty(queryExpression);

  const isDelayInMinutesValueEmpty =
    isResponseDelay &&
    automationActions[0].automation_action_fields['delay_in_minutes'] === '';

  const isThreadParsingValueEmpty =
    isThreadParsing &&
    automationActions[0].automation_action_fields['comment_position'] === '';

  const isSaveButtonDisabled =
    isQueryExpressionValueEmpty ||
    isDelayInMinutesValueEmpty ||
    isThreadParsingValueEmpty;

  const getQueryExpressionFieldType = (field: string) => {
    return fieldOptions.find(data => data.value === field)
      ?.type as QueryExpressionTypes;
  };

  const closeDeleteConfirmationDialog = () => {
    setIsDeleteConfirmationDialogIsVisible(false);
  };

  const getShouldIncludeStartAdornment = (fieldValue: string) => {
    return formattedCustomFields.some(field => field.value === fieldValue);
  };

  const getTriageModel = (cvId: string) =>
    mappedFields.find(field => field.id === cvId)?.mapping_data?.model_name;

  //reset state when drawer is closed
  useEffect(() => {
    if (isVisible) {
      setQueryExpression(query_expression);
      setAutomationActions(automation_actions);
      setRunOtherAutomations(!!run_other_automations);
    } else {
      setQueryExpression(defaultFilterQueryExpression);
      setAutomationActions(defaultEmailAutomation.automation_actions);
      setRunOtherAutomations(false);
    }
  }, [isVisible, query_expression, automation_actions, run_other_automations]);

  const getIsExpressionUsingTriageModels = (
    queryExpression: EditEmailAutomation['query_expression'],
  ): boolean => {
    const { expression_type: type, expressions, field } = queryExpression;
    const isFilterExpressionType = type === 'filter';
    const isBooleanAggregatorType = type === 'boolean_aggregator';

    if (isFilterExpressionType) {
      return triageModelMappings.some(mapping => {
        return mapping.id === field && mapping.mapping_type === 'triage';
      });
    } else if (isBooleanAggregatorType) {
      return (
        !!expressions &&
        expressions?.some(expression =>
          getIsExpressionUsingTriageModels(expression),
        )
      );
    }

    return false;
  };

  const getListTypeFieldOptions = (field: string) =>
    fieldOptions.find(option => option.value === field)?.fieldOptions || [];

  return (
    <Drawer isOpen={isVisible} onClose={onClose} width='850px'>
      {isLoadingFields ? (
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          height='100%'
          justifyContent='center'
        >
          <Spinner />
        </Box>
      ) : (
        <Box display='flex' flexDirection='column' height='100%'>
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            padding='48px 97px 24px 40px'
          >
            <Typography variant='font24'>Automation</Typography>
            <Box alignItems='center' display='flex' gap='24px'>
              {isUpdateMode && (
                <DeleteButton
                  onClick={() => setIsDeleteConfirmationDialogIsVisible(true)}
                  variant='ghost'
                >
                  <IconTrash
                    color={palette.colors.red[500]}
                    height='20px'
                    width='20px'
                  />{' '}
                  Delete Automation
                </DeleteButton>
              )}
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            height='100%'
            padding='0 40px 40px 40px'
          >
            <Box flex='1' overflow='scroll'>
              <Section includeBorder>
                <Box display='flex' flexDirection='column' gap='16px'>
                  <Typography variant='font18'>If ticket:</Typography>
                  {isFilterExpression ? (
                    <Box display='flex' gap='7px'>
                      <IncludesLabelContainer width='76px'>
                        <Typography variant='font14Medium'>includes</Typography>
                      </IncludesLabelContainer>
                      <ExpressionBuilder
                        expression={queryExpression}
                        fieldOptions={fieldOptions}
                        fieldStartAdornment={
                          getShouldIncludeStartAdornment(
                            queryExpression.field || '',
                          )
                            ? customTicketFieldAdornment
                            : undefined
                        }
                        fieldType={getQueryExpressionFieldType(
                          queryExpression.field || '',
                        )}
                        index={0}
                        isBooleanOperatorDisabled={false}
                        isDeleteButtonDisabled
                        listTypeOptions={getListTypeFieldOptions(
                          queryExpression.field || '',
                        )}
                        onSelectedField={value => {
                          setQueryExpression(queryExpression =>
                            updateFilterExpressionField(queryExpression, value),
                          );
                        }}
                        onSelectOperator={operator =>
                          setQueryExpression(queryExpression =>
                            updateFilterExpressionOperator(
                              queryExpression,
                              operator,
                            ),
                          )
                        }
                        onValueChange={value =>
                          setQueryExpression(queryExpression =>
                            updateFilterExpressionValue(queryExpression, value),
                          )
                        }
                        triageModel={getTriageModel(
                          queryExpression.field || '',
                        )}
                      />
                    </Box>
                  ) : (
                    <Box display='flex' flexDirection='column' gap='16px'>
                      {queryExpression.expressions?.map((expression, index) => (
                        <Box display='flex' gap='7px' key={index}>
                          {index === 0 && (
                            <IncludesLabelContainer width='76px'>
                              <Typography variant='font14Medium'>
                                includes
                              </Typography>
                            </IncludesLabelContainer>
                          )}
                          <ExpressionBuilder
                            booleanOperator={
                              index === 0 ? undefined : queryExpression.operator
                            }
                            expression={expression}
                            fieldOptions={fieldOptions}
                            fieldStartAdornment={
                              getShouldIncludeStartAdornment(
                                expression.field || '',
                              )
                                ? customTicketFieldAdornment
                                : undefined
                            }
                            fieldType={getQueryExpressionFieldType(
                              expression.field || '',
                            )}
                            index={index}
                            isBooleanOperatorDisabled={index > 1}
                            isDeleteButtonDisabled={false}
                            listTypeOptions={getListTypeFieldOptions(
                              expression.field || '',
                            )}
                            onDeleteButtonClick={() =>
                              setQueryExpression(queryExpression =>
                                queryExpression.expressions?.length === 2
                                  ? transformBooleanAgregatorToFilterExpression(
                                      queryExpression,
                                      index,
                                    )
                                  : removeExpressionFromBooleanAgregatorExpression(
                                      queryExpression,
                                      index,
                                    ),
                              )
                            }
                            onSelectBooleanOperator={value =>
                              setQueryExpression(queryExpression =>
                                updateBooleanAggregatorExpressionBooleanOperator(
                                  queryExpression,
                                  value,
                                ),
                              )
                            }
                            onSelectedField={value => {
                              setQueryExpression(queryExpression =>
                                updateBooleanAggregatorExpressionField(
                                  queryExpression,
                                  value,
                                  index,
                                ),
                              );
                            }}
                            onSelectOperator={operator =>
                              setQueryExpression(queryExpression =>
                                updateBooleanAggregatorExpressionOperator(
                                  queryExpression,
                                  operator,
                                  index,
                                ),
                              )
                            }
                            onValueChange={value =>
                              setQueryExpression(queryExpression =>
                                updateBooleanAggregatorExpressionValue(
                                  queryExpression,
                                  value,
                                  index,
                                ),
                              )
                            }
                            triageModel={getTriageModel(expression.field || '')}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <AddButton
                  onClick={() =>
                    setQueryExpression(queryExpression =>
                      isFilterExpression
                        ? transformFromFilterToBooleanAgregatorExpression(
                            queryExpression,
                            defaultFilterQueryExpression,
                          )
                        : addExpressionToBooleanAgregatorExpression(
                            queryExpression,
                            defaultFilterQueryExpression,
                          ),
                    )
                  }
                  startIcon={<IconPlus fontSize='small' />}
                  variant='secondary'
                >
                  Add
                </AddButton>
              </Section>
              <Section
                alignItems='center'
                display='flex'
                gap='8px'
                includeBorder
              >
                <>
                  <Box width='160px'>
                    <SelectDropdown
                      id='selected-automation-action-type-dropdown'
                      onChange={e => {
                        const type = e.target.value as AutomationActionType;
                        setAutomationActions(automationAction =>
                          updateAutomationActionType(automationAction, type),
                        );
                      }}
                      options={automationActionTypeOptions}
                      value={automationActionType}
                    />
                  </Box>
                  {!isNoResponse && (
                    <>
                      <Typography variant='font14'>
                        {labelMap[automationActionType]}
                      </Typography>
                      <Typography variant='font14'>=</Typography>
                      <NumericInput
                        onChange={e => {
                          const value = e.target.value;
                          setAutomationActions(automationAction =>
                            updateAutomationActionFieldValue(
                              automationAction,
                              Object.keys(automationActionFields)[0],
                              value === '' ? '' : Number(value),
                            ),
                          );
                        }}
                        type='number'
                        value={
                          automationActionFields[
                            automationActionTypeMap[automationActionType]
                          ]
                        }
                      />
                      {isResponseDelay && (
                        <Typography variant='font14'>minutes</Typography>
                      )}
                    </>
                  )}
                </>
              </Section>
              <Section>
                {!isNoResponse && (
                  <Box alignItems='center' display='flex'>
                    <Checkbox
                      checked={!!runOtherAutomations}
                      label={'Run other automations'}
                      onChange={() => {
                        setRunOtherAutomations(value => !value);
                      }}
                    />
                    <Box left='-11px' position='relative' top='3px'>
                      <Tooltip tooltipContent='When active, it will re-run all automations matching on the ticket and it will try to match against other automations that have not been matched yet.'>
                        <IconInfoCircle height={18} width={18} />
                      </Tooltip>
                    </Box>
                  </Box>
                )}
                <Box alignItems='center' display='flex'>
                  <Checkbox
                    checked={caseInsensitive}
                    label='Case insensitive'
                    onChange={() => {
                      setCaseInsensitive(value => !value);
                    }}
                  />
                  <Box left='-11px' position='relative' top='3px'>
                    <Tooltip tooltipContent='Optionally match values ignoring letter case. Example: Admin@forethought.ai and admin@forethought.ai.'>
                      <IconInfoCircle height={18} width={18} />
                    </Tooltip>
                  </Box>
                </Box>
              </Section>
            </Box>
            <Box display='flex' gap='16px'>
              <Button onClick={() => onClose()} variant='secondary'>
                Cancel
              </Button>
              <Button
                disabled={
                  isLoadingCreation || isLoadingUpdate || isSaveButtonDisabled
                }
                fullWidth
                onClick={() => {
                  const request = {
                    automation_actions: automationActions,
                    case_insensitive: caseInsensitive,
                    query_expression: queryExpression,
                    run_other_automations: isNoResponse
                      ? undefined
                      : runOtherAutomations,
                    uses_mapped_fields:
                      getIsExpressionUsingTriageModels(queryExpression),
                  };
                  const callable = isUpdateMode
                    ? () =>
                        updateEmailAutomation({
                          ...request,
                          email_automation_id,
                        })
                    : () => createEmailAutomation(request);

                  callable()
                    .unwrap()
                    .then(() => onClose())
                    .catch(() => {
                      onClose();
                      dispatch(
                        setGlobalToastOptions({
                          autoHideDuration: 4000,
                          title:
                            'An error ocurred, please refresh the page or try again',
                          variant: 'danger',
                        }),
                      );
                    });
                }}
                variant='main'
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <ConfirmModal
        actions={[
          {
            onClick: () => closeDeleteConfirmationDialog(),
            title: 'No',
          },
          {
            onClick: () => {
              deleteEmailAutomation(email_automation_id || '')
                .unwrap()
                .then(closeDeleteConfirmationDialog)
                .catch(() => {
                  closeDeleteConfirmationDialog();
                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: 4000,
                      title:
                        'An error ocurred, please refresh the page or try again',
                      variant: 'danger',
                    }),
                  );
                });
            },
            title: 'Yes, delete',
          },
        ]}
        description='You are about to delete an email automation rule that will affect how we handle responding to your email inquiries. Are you sure you want to delete it?'
        isOpen={isDeleteConfirmationDialogVisible}
        maxWidth='sm'
        onClose={() => closeDeleteConfirmationDialog()}
        title='Delete email automation'
      />
    </Drawer>
  );
};

export default EmailAutomationPage;

const Section = styled(Box)<{ includeBorder?: boolean }>`
  padding: 30px 0;
  ${({ includeBorder, theme }) =>
    css`
      ${!!includeBorder &&
      `border-bottom: 1px solid ${theme.palette.colors.grey[100]};`}
    `}
`;

const BaseInput = styled('input')`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.colors.grey[300]};
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.colors.black};
  }
  background: transparent;
  padding: 10px 16px;
  height: 40px;
  font-size: 14px;
`;

const NumericInput = styled(BaseInput)`
  width: 90px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const AddButton = styled(Button)`
  margin-top: 16px;
  width: 76px;
`;

const IncludesLabelContainer = styled(Box)`
  width: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled(Button)`
  color: ${({ theme }) => theme.palette.colors.red[500]};
  &:hover {
    background: ${({ theme }) => theme.palette.colors.red[100]};
  }
`;
