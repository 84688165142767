import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { middleware } from './middleware';
import rootReducer, { RootState } from './rootReducer';
import { ReportsActions } from 'src/actions/actionTypes';
import { getStateFromSerializedReport } from 'src/services/deserializer/DESERIALIZER';
import { URL } from 'src/utils/enums';
import { setReportIdToUrl, updateURL } from 'src/utils/urlBehavior';

/**
 * NOTE: this is legacy code that cannot yet be removed.
 * Please contain logic like this to middleware or reducers instead of following this pattern.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const wrappedRootReducer = (state: any, action: any): RootState => {
  const answersPath = document.location.pathname;
  if (action.type === ReportsActions.SET_CURRENT_REPORT) {
    const reportId = action.payload.report_id;
    setReportIdToUrl(reportId);
    return getStateFromSerializedReport(action.payload, state);
  }
  if (action.type === ReportsActions.SET_UNSAVED_REPORT) {
    return getStateFromSerializedReport(action.payload, state, true);
  }
  const newState = rootReducer(state, action);
  if (newState?.reports?.isReportModified && answersPath === URL.PATH) {
    updateURL(newState);
  }
  return newState;
};

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => {
    const defaultMiddleware = getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    });
    return defaultMiddleware.concat(...middleware);
  },
  reducer: wrappedRootReducer,
});

setupListeners(store.dispatch);

export default store;
