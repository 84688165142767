import queryString, { ParsedQuery } from 'query-string';

import {
  GET_ANSWERS_CHART_DATA_SUCCESS,
  PageSettingsActions,
} from 'src/actions/actionTypes';
import type { RootState } from 'src/store/rootReducer';
import { calculateRangeWithTimestamp } from 'src/utils/calculateTimeframe';
import { TimeFrameFilters, ViewModes } from 'src/utils/enums';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers/timeRangeHelpers';

export interface CustomRangeModifier {
  from: Date | undefined;
  to?: Date | undefined;
}

interface FetchAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  type: string;
}

interface PageSettingsState {
  chartYAxis: string;
  macroControlsViewMode: string;
  pageNumber: number;
  solveViewMode: string;
  timeFrameFilter: string;
  timeRange: CustomRangeModifier;
  viewMode: ViewModes;
}

function getDefaultTimeRange() {
  const queryStr: ParsedQuery = queryString.parse(
    decodeURIComponent(location.search.replace(/\%20/g, '_')),
  );

  if (!queryStr?.start || !queryStr?.end) {
    return last30DaysTimeRange;
  } else {
    return calculateRangeWithTimestamp({
      end_timestamp: queryStr.end as unknown as number,
      start_timestamp: queryStr.start as unknown as number,
    });
  }
}

export const initialState: PageSettingsState = {
  chartYAxis: '',
  macroControlsViewMode: 'Trained Macros',
  pageNumber: 0,
  solveViewMode: 'overview',
  timeFrameFilter: TimeFrameFilters.DAILY,
  timeRange: getDefaultTimeRange(),
  viewMode: ViewModes.CHART,
};

export default function pageSettingsReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === PageSettingsActions.SET_VIEW_MODE) {
    return { ...state, viewMode: action.payload };
  }

  if (action.type === PageSettingsActions.SET_SOLVE_VIEW_MODE) {
    return { ...state, solveViewMode: action.payload };
  }

  if (action.type === PageSettingsActions.SET_MACRO_CONTROLS_VIEW_MODE) {
    return { ...state, macroControlsViewMode: action.payload };
  }

  if (action.type === PageSettingsActions.SET_TIME_FRAME_FILTER) {
    return { ...state, timeFrameFilter: action.payload };
  }

  if (action.type === PageSettingsActions.SET_TIME_RANGE) {
    const timeRange = action.payload as CustomRangeModifier;
    const newTimeRange = {
      from: timeRange?.from ? new Date(timeRange.from.getTime()) : undefined,
      to: timeRange?.to ? new Date(timeRange.to.getTime()) : undefined,
    };
    return { ...state, timeRange: newTimeRange };
  }

  if (action.type === PageSettingsActions.SET_Y_AXIS) {
    return { ...state, chartYAxis: action.payload };
  }

  if (action.type === PageSettingsActions.LOAD_Y_AXIS) {
    // without side effects
    return { ...state, chartYAxis: action.payload };
  }

  if (action.type === PageSettingsActions.SET_PAGE_NUMBER) {
    return { ...state, pageNumber: action.payload };
  }

  if (action.type === GET_ANSWERS_CHART_DATA_SUCCESS) {
    return { ...state, chartYAxis: action.payload?.y?.[0] || '' };
  }

  return state;
}

// SELECTORS

export const selectViewMode = (
  state: RootState,
): PageSettingsState['viewMode'] => state.pageSettings.viewMode;

export const selectSolveViewMode = (
  state: RootState,
): PageSettingsState['solveViewMode'] => state.pageSettings.solveViewMode;

export const selectMacroControlsViewMode = (
  state: RootState,
): PageSettingsState['macroControlsViewMode'] =>
  state.pageSettings.macroControlsViewMode;

export const selectTimeFrameFilter = (
  state: RootState,
): PageSettingsState['timeFrameFilter'] => state.pageSettings.timeFrameFilter;

export const selectTimeRange = (
  state: RootState,
): PageSettingsState['timeRange'] => state.pageSettings.timeRange;

export const selectChartYAxis = (
  state: RootState,
): PageSettingsState['chartYAxis'] => state.pageSettings.chartYAxis;

export const selectPageNumber = (
  state: RootState,
): PageSettingsState['pageNumber'] => state.pageSettings.pageNumber;
