import { SHARED_METRIC_DEFINITIONS } from 'src/constants/solve';
import { cleanStr } from 'src/utils/cleanStr';

export function getEmailCostSavingsTooltip(costPerTicket: number) {
  return `Estimated cost per ticket ($${costPerTicket}) multiplied by the total number of deflected emails.`;
}

export const EMAIL_METRIC_LABELS = {
  attempted: 'Responses Sent',
  cost_savings: 'Estimated Cost Savings',
  inquiries: 'Emails Received',
  potential_attempts: 'Eligible Emails',
  received_deflection_rate: 'Received Deflection Rate',
  responded_deflection_rate: 'Responded Deflection Rate',
  response_rate: 'Responses Sent',
  self_served: 'Deflections',
};

export const EMAIL_METRIC_TOOLTIPS = {
  attempted:
    'The total number of tickets/cases where Forethought sent a response to the end-user and the percentage of Eligible Emails that received a response.',
  inquiries:
    "The total number of tickets/cases received by Forethought from the client's help desk.",
  potential_attempts:
    'The total number of tickets/cases where Email Automation rules allow Forethought to respond. Email Automations can be configured from the Email Configuration page.',
  received_deflection_rate: 'Deflected Emails divided by Total Emails',
  responded_deflection_rate: 'Deflected Emails divided by Responses Sent',
  response_rate: 'Responses Sent divided by Eligible Emails',
  self_served:
    'A Deflection is defined as a Response Sent where the end-user neither replies to the email nor clicks on a negative feedback button in the email response. The Deflection Rate is calculated by dividing the number of Deflections by the total number of Responses Sent.',
  self_served_rate: 'Deflected Emails divided by Eligible Emails',
};

export const getLabelForMetric = (key: string) => {
  switch (key) {
    case 'deflection_rate':
      return SHARED_METRIC_DEFINITIONS.deflectionRate.label;
    case 'resolutions':
      return SHARED_METRIC_DEFINITIONS.totalDeflectedChats.label;
    case 'user_queries':
      return SHARED_METRIC_DEFINITIONS.totalChats.label;
    case 'self_served':
      return 'Total deflections';
    case 'self_served_rate':
      return 'Deflection rate';
    case 'cost_savings':
      return 'Total estimated cost savings';
    default:
      return cleanStr(key);
  }
};

export const getTooltipForMetric = (
  label: string,
  costPerTicket: number | null = null,
): string => {
  switch (label) {
    case 'cost_savings':
      if (costPerTicket) {
        return `This metric calculates the cost savings from deflecting chats instead of creating tickets. It multiplies an estimated cost per ticket ($${costPerTicket}) with the total number of deflections. If your cost per ticket is different, divide by $${costPerTicket} and multiply with deflections for accurate results.`;
      }

    case 'deflection_rate':
      return SHARED_METRIC_DEFINITIONS.deflectionRate.tooltip;
    case 'resolutions':
      return SHARED_METRIC_DEFINITIONS.totalDeflectedChats.tooltip;
    case 'user_queries':
      return SHARED_METRIC_DEFINITIONS.totalChats.tooltip;
    default:
      return '';
  }
};

export const formatNumber = (type: string, num: number | null): string => {
  if (num === null || isNaN(num)) {
    return 'N/A';
  }

  const isPositive = num >= 0;
  num = isPositive ? num : -num;
  const formattedStr = formatNumberHelper(type, num);
  return formattedStr;
};

export const formatNumberHelper = (type: string, num: number): string => {
  switch (type) {
    case 'deflection_rate':
    case 'self_served_rate':
    case 'received_deflection_rate':
    case 'responded_deflection_rate':
    case 'response_rate':
      if (num === 0) {
        return '0.0%';
      }
      num = Math.round(num * 1000) / 10;
      return num.toString() + '%';
    case 'cost_savings':
      if (num < 1000) {
        // 3 digits or less, show original number
        return '$' + num.toString();
      } else if (num < 1000000) {
        // 4 to 6 digits, round to thousands with 1 decimal
        num = Math.round(num / 100) / 10;
        return '$' + num.toString() + 'k';
      } else if (num < 10000000) {
        // 7 digits, round to thousands with no decimal, add comma
        let formattedStr = Math.round(num / 1000).toString();
        formattedStr = formattedStr.slice(0, 1) + ',' + formattedStr.slice(1);
        return '$' + formattedStr + 'k';
      } else {
        // 8 digits, round to millions with 1 decimal
        num = Math.round(num / 100000) / 10;
        return '$' + num.toString() + 'mn';
      }
    default:
      if (num > 0 && num < 1) {
        return num.toFixed(2);
      } else if (num < 1000) {
        // 3 digits or less, show original number
        return num.toString();
      } else if (num < 10000) {
        // 4 digits, add a comma
        let formattedStr = num.toString();
        formattedStr = formattedStr.slice(0, 1) + ',' + formattedStr.slice(1);
        return formattedStr;
      } else if (num < 1000000) {
        // 5 to 6 digits, round to thousands with 1 decimal
        num = Math.round(num / 100) / 10;
        return num.toString() + 'k';
      } else if (num < 10000000) {
        // 7 digits, round to thousands with no decimal, add comma
        let formattedStr = Math.round(num / 1000).toString();
        formattedStr = formattedStr.slice(0, 1) + ',' + formattedStr.slice(1);
        return formattedStr + 'k';
      } else {
        // 8 digits, round to millions with 1 decimal
        num = Math.round(num / 100000) / 10;
        return num.toString() + 'mn';
      }
  }
};
