import { createSlice } from '@reduxjs/toolkit';

import { AssistNotes } from './types';
import {
  createNote,
  deleteNote,
  getNotes,
  patchNote,
  putShareNote,
} from 'src/actions/assist';
import type { RootState } from 'src/store/rootReducer';

export interface AssistNotesState {
  notes: {
    data: AssistNotes | null;
    error: boolean;
    loading: boolean;
  };
}

const initialState: AssistNotesState = {
  notes: {
    data: null,
    error: false,
    loading: false,
  },
};

const assistNotesSlice = createSlice({
  extraReducers(builder) {
    builder.addCase(getNotes.fulfilled, (state, { payload }) => {
      state.notes.data = payload;
      state.notes.loading = false;
      state.notes.error = false;
    });
    builder.addCase(getNotes.pending, state => {
      state.notes.loading = true;
      state.notes.data = null;
    });
    builder.addCase(getNotes.rejected, state => {
      state.notes.error = true;
      state.notes.loading = false;
    });
    builder.addCase(createNote.fulfilled, (state, { payload }) => {
      if (state.notes.data?.notes) {
        state.notes.data.notes = [...state.notes.data.notes, payload];
      }
    });
    builder.addCase(deleteNote.fulfilled, (state, { payload }) => {
      if (payload && state.notes.data?.notes) {
        state.notes.data.notes = state.notes.data?.notes.filter(
          note => note.note_id !== payload,
        );
      }
    });
    builder.addCase(patchNote.fulfilled, (state, { payload }) => {
      if (state.notes.data?.notes) {
        state.notes.data.notes = state.notes.data.notes.map(note => {
          if (note.note_id === payload.note_id) {
            return payload;
          }

          return note;
        });
      }
    });
    builder.addCase(putShareNote.fulfilled, (state, { payload }) => {
      if (state.notes.data?.notes) {
        if (payload.isMe) {
          state.notes.data.notes = state.notes.data.notes.map(note => {
            if (note.note_id === payload.note_id) {
              return payload;
            }

            return note;
          });
          return;
        }

        state.notes.data.notes = state.notes.data?.notes.filter(
          note => note.note_id !== payload.note_id,
        );
      }
    });
  },
  initialState,
  name: 'assistNotes',
  reducers: {},
});

export const selectNotes = (state: RootState): AssistNotesState['notes'] =>
  state.assistNotes.notes;

export default assistNotesSlice.reducer;
