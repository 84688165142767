import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import AuditPage from './audit/AuditPage';
import ErrorPage from './error/ErrorPage';
import { LabelBadge } from 'src/components/label-badge/LabelBadge';
import { useStateParams } from 'src/hooks/hooks';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { useGetPermissionsByRoleQuery } from 'src/services/dashboard-api';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api/dashboardApi';
import { UserRoles } from 'src/utils/enums';

const EventsPage = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useStateParams<number>({
    deserialize: param => parseInt(param, 10),
    initialState: 0,
    paramsName: 'tabIndex',
    serialize: state => state.toString(),
  });
  const userRole = useSelector(selectUserRole);
  const { data: permissionsByRole = {} } = useGetPermissionsByRoleQuery();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const isSolveErrorTableEnabled = featureFlags.includes('solve_error_table');

  const getBadgeLabelForTab = useCallback(
    (key: 'audit' | 'error') => {
      if (key === 'error' && isSolveErrorTableEnabled) {
        return '';
      }

      const permissionData = permissionsByRole[key];
      if (!permissionData) {
        return 'Internal';
      }

      if (
        permissionData.is_super_admin_only &&
        userRole === UserRoles.ROLE_SUPER_ADMIN
      ) {
        return 'Internal';
      } else if (permissionData.is_beta) {
        return 'Beta';
      }
      return '';
    },
    [permissionsByRole, userRole, isSolveErrorTableEnabled],
  );

  const tabs = useMemo(() => {
    const tabComponents = [];
    const tabNames = [];
    const auditTabPermission = permissionsByRole['audit'];
    if (
      userRole === UserRoles.ROLE_SUPER_ADMIN ||
      auditTabPermission?.is_enabled
    ) {
      const auditBadgeLabel = getBadgeLabelForTab('audit');
      tabComponents.push(
        <Box key='audit'>
          Audit {auditBadgeLabel && <LabelBadge badgeLabel={auditBadgeLabel} />}
        </Box>,
      );
      tabNames.push('audit');
    }

    if (userRole === UserRoles.ROLE_SUPER_ADMIN || isSolveErrorTableEnabled) {
      const errorBadgeLabel = getBadgeLabelForTab('error');
      tabComponents.push(
        <Box key='error'>
          Error {errorBadgeLabel && <LabelBadge badgeLabel={errorBadgeLabel} />}
        </Box>,
      );
      tabNames.push('error');
    }

    return {
      tabComponents: tabComponents,
      tabNames: tabNames,
    };
  }, [
    userRole,
    getBadgeLabelForTab,
    isSolveErrorTableEnabled,
    permissionsByRole,
  ]);

  const getTabComponent = (tabIndex: number) => {
    const tabName = tabs.tabNames[tabIndex];
    switch (tabName) {
      case 'audit':
        return <AuditPage />;
      case 'error':
        return <ErrorPage />;
      default:
        return null;
    }
  };

  return (
    <Box
      bgcolor={theme.palette.colors.white}
      display='flex'
      flexDirection='column'
      gap={3}
    >
      <Box
        borderBottom={`1px solid ${theme.palette.colors.slate[200]}`}
        padding='0 40px'
      >
        <Tabs
          a11yProps={index => ({
            'aria-controls': `tabpanel-${index}`,
            id: `tab-${index}`,
          })}
          aria-label='Event tabs'
          onChange={(_, index) => {
            setTabIndex(index);
          }}
          tabs={tabs.tabComponents}
          typographyVariant='font16Bold'
          value={tabIndex}
        />
      </Box>
      {getTabComponent(tabIndex)}
    </Box>
  );
};

export default EventsPage;
