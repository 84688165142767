import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useSolveConfigTrackingEventAction } from 'src/hooks/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

const ConnectIntegration = ({
  isUsedByHandoffTab = false,
}: {
  isUsedByHandoffTab?: boolean;
}) => {
  const navigate = useNavigate();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  return (
    <>
      <Typography variant='font16Bold'>
        {isUsedByHandoffTab
          ? 'Connect Help Desk'
          : 'Connect with knowledge base'}
      </Typography>
      <Box pt={3}>
        <Button
          fullWidth
          onClick={() => {
            navigate({
              pathname: Routes.INTEGRATIONS,
            });
            dispatchTrackingAction(
              SolveConfigTrackingEventTypes.NAVIGATE_TO_INTEGRATIONS_PAGE,
            );
          }}
          startIcon={<AddIcon fontSize='small' />}
          variant='secondary'
        >
          <Typography variant='font14Bold'>Connect new integration</Typography>
        </Button>
      </Box>
    </>
  );
};

export default ConnectIntegration;
