import { styled } from '@mui/material';

export const ResponseMessage = styled('div')<{
  fullWidth?: boolean;
  isMarkdown?: boolean;
  isPlainText?: boolean;
}>`
  background-color: ${props => props.theme.palette.colors.slate[100]};
  border-radius: 8px;
  max-width: 100%;
  padding: ${props => (props.isPlainText ? '16px' : '0 16px')};
  word-break: break-word;
  align-self: flex-start;
  ${props => !props.isMarkdown && 'white-space: pre-wrap;'}
  ${props => props.fullWidth && 'width: 100%;'}

  a {
    color: var(--color-blue-500);
  }

  a:visited {
    color: var(--color-purple-600);
  }

  a:hover {
    color: var(--color-purple-600);
    text-decoration: none;
  }

  a:active {
    color: var(--color-purple-700);
  }

  // Less padding for the top lists:
  ul,
  ol {
    padding-left: 10px;
  }

  // Default padding for nested lists:
  ul ul,
  ol ol,
  ul ol,
  ol ul {
    padding-left: 40px;
  }
`;

export const CollapseButton = styled('button')`
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: ${props => props.theme.palette.colors.grey[800]};
  position: absolute;
  right: 40px;
  top: 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.palette.colors.grey[700]};
  }
`;
