import { ActionBuilderActionTypes } from './enums';
import store from 'src/store/store';
import { Action } from 'src/types/actionBuilderApiTypes';
import { ActionBuilderState } from 'src/types/actionBuilderReducerTypes';
import { ActionStepList } from 'src/types/workflowBuilderAPITypes';

export const getIsStepEntryOfArticleSuggestionAction = (
  stepId: string | undefined,
): boolean => {
  const { actionBuilder, canvasWorkflowBuilder } = store.getState();
  const { actions } = actionBuilder as ActionBuilderState;

  const canvasActions =
    canvasWorkflowBuilder.canvas_action_id_to_action_component;
  const actionIdKeys = Object.keys(canvasActions);

  const articleSuggestionActionId = actions.find(
    (action: Action) =>
      action.action_type === ActionBuilderActionTypes.ARTICLE_SUGGESTION,
  )?.action_id;

  return actionIdKeys.some(key => {
    const action = canvasActions[key];
    return (
      action['entry_step_id'] === stepId &&
      action['action_id'] === articleSuggestionActionId
    );
  });
};

export const getEntryFromArticleSuggestionActionStep = (
  stepId: string | undefined,
  canvas_action_id_to_action_component: Record<string, ActionStepList>,
): string | undefined => {
  const { actionBuilder } = store.getState();
  const { actions } = actionBuilder as ActionBuilderState;

  const actionIdKeys = Object.keys(canvas_action_id_to_action_component);

  const articleSuggestionActionId = actions.find(
    (action: Action) =>
      action.action_type === ActionBuilderActionTypes.ARTICLE_SUGGESTION,
  )?.action_id;

  for (const key of actionIdKeys) {
    const action = canvas_action_id_to_action_component[key];
    if (
      action['action_id'] === articleSuggestionActionId &&
      stepId &&
      action['steps'].includes(stepId)
    ) {
      return action['entry_step_id'];
    }
  }
};

export const getArticleSuggestionActionId = (
  stepId: string | undefined,
  canvas_action_id_to_action_component: Record<string, ActionStepList>,
): string => {
  const { actionBuilder } = store.getState();
  const { actions } = actionBuilder as ActionBuilderState;

  const actionIdKeys = Object.keys(canvas_action_id_to_action_component);

  const articleSuggestionActionId = actions.find(
    (action: Action) =>
      action.action_type === ActionBuilderActionTypes.ARTICLE_SUGGESTION,
  )?.action_id;

  for (const key of actionIdKeys) {
    const action = canvas_action_id_to_action_component[key];
    if (
      action['action_id'] === articleSuggestionActionId &&
      stepId &&
      action['steps'].includes(stepId)
    ) {
      return key;
    }
  }
  return '';
};
