import { useMemo } from 'react';
import { Formik } from 'formik';
import { styled } from '@mui/material/styles';

import {
  Button,
  TextField,
} from '@forethought-technologies/forethought-elements';
import InputArrayTooltip from 'src/components/assist-automations-form/InputArrayTooltip';
import {
  formatInputValue,
  getInputType,
} from 'src/components/assist-automations-form/run-action-card/input-card/helpers';
import { AutomationInputAction } from 'src/services/apiInterfaces';

const FormContent = styled('div')`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

const InputGroup = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-right: 7px;
  margin-bottom: 16px;
`;

const StyledTextField = styled(TextField)<{ label?: string }>`
  :not(:last-child) {
    margin-bottom: 5px;
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

interface FormProps {
  action: AutomationInputAction[] | null;
  formConfig: {
    id: string;
    label?: string;
    placeholder?: string;
    value: string;
  }[];
  isReadOnly: boolean;
  isSubmitting: boolean;
  onSubmit(formData: Record<string, string>): void;
}

export default function Form({
  action,
  formConfig,
  isReadOnly,
  isSubmitting,
  onSubmit,
}: FormProps) {
  const initialValues = useMemo(() => {
    return Object.fromEntries(formConfig.map(({ id, value }) => [id, value]));
  }, [formConfig]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <FormContent>
            <InputGroup>
              {formConfig.map(({ id, label = '', placeholder }) => {
                const inputType = getInputType(action, id);

                return (
                  <StyledTextField
                    endAdornment={
                      inputType?.includes('array') ? (
                        <InputArrayTooltip />
                      ) : undefined
                    }
                    key={id}
                    label={label}
                    onChange={e => {
                      const { value } = e.target;

                      setFieldValue(id, formatInputValue(inputType, value));
                    }}
                    placeholder={placeholder}
                    value={values[id]}
                  />
                );
              })}
            </InputGroup>
            <StyledButton
              disabled={
                !isReadOnly &&
                formConfig.some(input => !input.id || !values[input.id])
              }
              fullWidth
              isLoading={isSubmitting}
              onClick={() => handleSubmit()}
              size='large'
              type='button'
              variant='secondary'
            >
              Enter
            </StyledButton>
          </FormContent>
        );
      }}
    </Formik>
  );
}
