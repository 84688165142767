import { AssistNote } from 'src/slices/assist-notes/types';

export const NEW_NOTE_ID = 'new_note';

export const NEW_NOTE_OBJECT: AssistNote = {
  agent_email: '',
  body: null,
  created_by_role: 'ADMIN',
  created_date: -1,
  is_public: false,
  last_modified_date: -1,
  note_id: NEW_NOTE_ID,
  tags: [],
  title: '',
};

export const NOTE_TOAST_OPTIONS = {
  autoHideDuration: 3000,
  variant: 'main',
} as const;

export const NOTE_TOAST_TEXT = {
  deleted: 'Note has been deleted',
  saved: 'Note has been saved',
  shared: 'Note is now shared with the team',
  unshared: 'Note has been unshared',
};

export const USER_PERMISSION_FILTER_OPTIONS = [
  { label: 'Shared by agents', value: 'AGENT' },
  { label: 'Shared by admins', value: 'ADMIN' },
  { label: 'Owned by me', value: 'ME' },
] as const;

export type UserPermissionValue = Array<
  (typeof USER_PERMISSION_FILTER_OPTIONS)[number]['value']
>;
