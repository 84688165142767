import { FilterButton } from '@forethought-technologies/forethought-elements';
import { ALL_INTENTS } from '../constants';
import { getIntentOptions } from '../helpers';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import { IntentDataWithTopics } from 'src/pages/workflow-builder/intent-workflows-table/types';

const FILTER_BY = 'Filter by';

interface IntentFilterProps {
  intents: IntentDataWithTopics[];
  onChange: (intentId: string) => void;
  selectedIntentId: string;
}

export function IntentFilter({
  intents,
  onChange,
  selectedIntentId,
}: IntentFilterProps) {
  return (
    <FilterButton
      aria-label='intent filter'
      initialValue={ALL_INTENTS}
      menuProps={{
        isMenuSearchable: true,
        maxHeight: 360,
        width: 'auto',
      }}
      onChange={onChange}
      options={getIntentOptions(intents)}
      startAdornment={<img alt='' src={filterIcon} />}
      transformValue={({ label, value }) => {
        if (value === ALL_INTENTS) {
          return `${FILTER_BY} Intent detected`;
        }
        return `${FILTER_BY} ${label}`;
      }}
      value={selectedIntentId}
    />
  );
}
