export function getInitials(str: string) {
  if (str) {
    const names = str.split(' ');
    const fn = names[0].toString().charAt(0);
    if (names.length > 1) {
      const ln = names[1].toString().charAt(0);
      return fn + ln;
    }
    return fn;
  }
}
