import { useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { formatNumber } from './helpers';
import InnerDataOverviewCard from './InnerDataOverviewCard';
import { AggregateMetric } from 'src/pages/workflow-builder/aggregate-metrics/AggregateMetrics';

export const DataOverviewCard = ({
  color,
  difference,
  isChild = false,
  label,
  tooltip,
  value,
}: AggregateMetric & {
  isChild?: boolean;
}) => {
  const { palette } = useTheme();

  const isParent = Array.isArray(value);

  function getDifferenceColor(difference: number) {
    if (difference === 0 || difference === undefined) {
      return palette.colors.grey[600];
    }

    return difference > 0 ? palette.colors.green[500] : palette.colors.red[500];
  }

  function formatDifference(label: string, value: number) {
    const formattedDifference = formatNumber(label, value);
    if (value === 0) {
      return formattedDifference;
    }
    const prefix = value > 0 ? '+' : '-';

    return prefix + formattedDifference;
  }

  return (
    <InnerDataOverviewCard
      appCuesTarget={`${label}LandingPageCard`}
      bottomRow={
        difference !== undefined && (
          <div data-testid='data-overview-card-difference'>
            <Typography
              color={getDifferenceColor(difference)}
              variant='font14Bold'
            >
              {formatDifference(label, difference)}
            </Typography>
          </div>
        )
      }
      cardLabel={label}
      cardValue={
        isParent ? (
          <>
            {value.map(v => (
              <DataOverviewCard key={v.label} {...v} isChild />
            ))}
          </>
        ) : (
          value
        )
      }
      isChild={isChild}
      isParent={isParent}
      labelColor={color}
      tooltipContent={tooltip}
    />
  );
};
