import { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { hasUnprocessibleContextVariables } from '../utils';
import IntentEmailForm from './IntentEmailForm';
import { ToolbarFormComponentProps } from 'src/components/email-builder/types';

export function IntentEmailMacroForm(props: ToolbarFormComponentProps) {
  const { message } = props.component.component_fields;
  const { palette } = useTheme();

  const validationMessage = (
    <Typography color={palette.colors.grey[600]} variant='font14'>
      This macro template includes placeholders (example:{' '}
      <Typography color={palette.colors.red[500]} variant='font14'>
        {'{{value}}'}
      </Typography>{' '}
      ). Please replace the placeholders with other values before saving.
    </Typography>
  );

  const isDisabled = useMemo(
    () => hasUnprocessibleContextVariables(message),
    [message],
  );

  return (
    <IntentEmailForm {...props} isSaveButtonDisabled={isDisabled}>
      {isDisabled && (
        <Box
          display='flex'
          flexDirection='column'
          height='100%'
          justifyContent='flex-end'
        >
          {validationMessage}
        </Box>
      )}
    </IntentEmailForm>
  );
}
