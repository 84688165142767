import queryString from 'query-string';

import { URL } from './enums';
import { Report } from 'src/services/apiInterfaces';
import { buildQueryFromState } from 'src/services/serializer/SERIALIZER';
import type { RootState } from 'src/store/rootReducer';

const setReportIdToUrl = (reportId: number) => {
  // Add report_id as hash param while preserving previous hash params
  const hashParams = queryString.parse(window.location.hash);
  hashParams.report_id = String(reportId);
  const url = `${URL.PATH}#${queryString.stringify(hashParams)}`;

  window.history.pushState({ id: reportId }, '', url);
};

const getReportIdFromUrl = (): number => {
  const hash = window.location.hash;
  return parseInt(hash.replace(URL.URL_REPORT_KEY, ''));
};

const getUnsavedReportFromURL = (): Report | null => {
  const hash = window.location.hash;
  const hashString = hash.replace(URL.URL_UNSAVED_REPORT_KEY, '');
  try {
    const decodedURL = atob(hashString);
    return JSON.parse(decodedURL);
  } catch (e) {
    return null;
  }
};

const updateURL = (newState: RootState) => {
  const query = buildQueryFromState(newState);
  const unsavedReport = {
    description: newState.reports.report?.description,
    is_default: false,
    name: newState.reports.report?.name,
    query: query,
    selected_breakdown_values: [...newState.breakdowns.selectedBreakdownValues],
    selected_y_axis: newState.pageSettings.chartYAxis,
    user_id: newState.reports.report?.user_id,
  };
  const urlQuery = btoa(JSON.stringify(unsavedReport));
  const url = `${URL.PATH}${URL.URL_UNSAVED_REPORT_KEY}${urlQuery}`;
  window.history.pushState('', '', url);
};

export {
  getReportIdFromUrl,
  getUnsavedReportFromURL,
  setReportIdToUrl,
  updateURL,
};
