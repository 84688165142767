import Box from '@mui/material/Box';

import { unixTimestampToDateCreated } from '../helpers';
import MenuButton from '../menu-button';
import { AssistNote } from 'src/slices/assist-notes/types';

interface DateCreatedCellProps {
  data: AssistNote;
  onDelete: (noteId: string) => void;
  onToggleNoteShare: (noteId: string, isPublic: boolean) => void;
  variant?: 'created' | 'updated';
}

const DateCreatedCell = ({
  data,
  onDelete,
  onToggleNoteShare,
  variant = 'created',
}: DateCreatedCellProps) => {
  const {
    created_date: createdDate,
    is_public: isPublic,
    last_modified_date: lastModifiedDate,
  } = data;
  const metric = unixTimestampToDateCreated(
    variant === 'created' ? createdDate : lastModifiedDate,
  );

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      width='100%'
    >
      {metric}
      {variant === 'updated' && (
        <MenuButton
          isPublic={isPublic}
          noteId={data.note_id}
          onDelete={onDelete}
          onToggleNoteShare={onToggleNoteShare}
        />
      )}
    </Box>
  );
};

export default DateCreatedCell;
