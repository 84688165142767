import { useCallback } from 'react';
import { getIncomers, getOutgoers, NodeProps, useReactFlow } from 'reactflow';

import { FLOW_NODES } from '../components/flow-layout/NodeTypes';

export default function useRemoveNode(id: NodeProps['id']) {
  const { getEdges, getNode, getNodes, setEdges, setNodes } = useReactFlow();

  const handleRemove = useCallback(() => {
    const nodeToRemove = getNode(id);

    if (!nodeToRemove) {
      return;
    }

    const parentNode = getIncomers(nodeToRemove, getNodes(), getEdges())[0];
    const childNode = getOutgoers(nodeToRemove, getNodes(), getEdges())[0];

    // ensure that childNode has knowledge of parent transition id
    // so that if childNode is a dropzone node, it will be able to assign the new step to the correct parent
    childNode.data.parentTransitionId = nodeToRemove.data.parentTransitionId;

    setEdges(edges =>
      edges
        .map(edge => {
          if (
            edge.source === parentNode.id &&
            edge.target === nodeToRemove.id
          ) {
            return {
              ...edge,
              data: {
                ...edge.data,
                hideAddButton: childNode.type === FLOW_NODES.DROPZONE,
              },
              target: childNode.id,
            };
          } else {
            return edge;
          }
        })
        .filter(edge => edge.source !== nodeToRemove.id),
    );

    setNodes(nodes => {
      const removedNodeIndex = nodes.findIndex(node => node.id === id);
      const filteredNodes = nodes.filter(
        node => node.id !== id && node.id !== childNode.id,
      );
      filteredNodes.splice(removedNodeIndex, 0, childNode);
      return filteredNodes;
    });
  }, [setNodes, getEdges, setEdges, getNode, getNodes, id]);

  return handleRemove;
}
