import { DateRange } from '../../discover-dashboard-page/types';
import { InsightPeriod, InsightSortDirection } from '../types';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

export type InsightWorkflowSortColumns =
  | 'chat_count'
  | 'deflected_count'
  | 'non_deflected_count'
  | 'avg_csat'
  | 'article_ids'
  | 'relevant_count'
  | 'dropoff_count'
  | 'engagement_rate';

export const initialInsightWorkflowParams: {
  date: DateRange;
  period: InsightPeriod;
  workflowColumnSort: InsightWorkflowSortColumns;
  workflowColumnSortDirection: InsightSortDirection;
} = {
  date: last30DaysTimeRange,
  period: 'weekly',
  workflowColumnSort: 'chat_count',
  workflowColumnSortDirection: 'DESC',
};

export const TABLE_COLUMN_TO_SORT_MAP: {
  workflow: Record<string, InsightWorkflowSortColumns>;
} = {
  workflow: {
    avg_csat: 'avg_csat',
    chats: 'chat_count',
    deflected_count: 'deflected_count',
    dropoff_breakdown: 'dropoff_count',
    engagement_rate: 'engagement_rate',
    non_deflected_count: 'non_deflected_count',
    relevance_breakdown: 'relevant_count',
    surfaced_articles: 'article_ids',
  },
};

export const WORKFLOW_TABLE_FILTER_OPTIONS = [
  {
    label: 'Activate',
    options: [
      {
        label: 'On',
        value: 'activate.on',
      },
      {
        label: 'Off',
        value: 'activate.off',
      },
    ],
    value: 'activate',
  },
  {
    label: 'Types',
    options: [
      {
        label: 'AutoFlow',
        value: 'types.autoflow',
      },
      {
        label: 'Classic',
        value: 'types.classic',
      },
      {
        label: 'Handoff',
        value: 'types.handoff',
      },
      {
        label: 'Fallback',
        value: 'types.fallback',
      },
      {
        label: 'Knowledge Retrieval',
        value: 'types.knowledge_retrieval',
      },
    ],
    value: 'types',
  },
];
