import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
  setEditingConditionStepId,
  setMode,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import { ForethoughtEditMenu } from './ForethoughtEditMenu';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import { ForethoughtEditConditionMenuProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { StepTypes } from 'src/utils/enums';

export const ForethoughtEditConditionMenu: FC<
  React.PropsWithChildren<ForethoughtEditConditionMenuProps>
> = ({ isVisible, setIsVisible, stepId }) => {
  const dispatch = useDispatch();
  const { contextVariables } = useGetContextVariables();

  return (
    <ForethoughtEditMenu
      isVisible={isVisible}
      onEditClick={() => {
        dispatch(setEditingConditionStepId(stepId));
        dispatch(setMode({ contextVariables, mode: CanvasModes.CONDITION }));
      }}
      setIsVisible={setIsVisible}
      stepId={stepId}
      stepType={StepTypes.CONDITION}
    />
  );
};
