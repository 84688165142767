/* eslint-disable react/prop-types -- false warnings for column definitions */

import { useCallback, useMemo } from 'react';
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Avatar,
  Badge,
  Button,
  getDefaultMRTOptions,
} from '@forethought-technologies/forethought-elements';
import { useFilteredUsers, useIsCurrentUserAdmin } from '../hooks';
import ResendInviteButton from './ResendInviteButton';
import { User } from 'src/services/apiInterfaces';
import { selectInvites } from 'src/slices/team/teamSlice';
import { deleteUser } from 'src/slices/team/thunks';
import { useAppDispatch } from 'src/store/hooks';
import {
  formatPermissionLevel,
  getAvatarTextFromUser,
  getFullName,
} from 'src/utils/sessionUtils';

interface InvitesTableProps {
  filterQuery: string;
  topToolbar: JSX.Element;
}

export default function InvitesTable({
  filterQuery,
  topToolbar,
}: InvitesTableProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isCurrentUserAdmin = useIsCurrentUserAdmin();
  const { data, error, loading } = useSelector(selectInvites);

  const handleDelete = useCallback(
    async (user: User) => {
      const userConfirms = await window.confirm(
        `Are you sure you want to delete ${user.email}?`,
      );
      if (userConfirms) {
        dispatch(deleteUser(user.user_id));
      }
    },
    [dispatch],
  );

  const filteredData = useFilteredUsers(data, filterQuery);

  const columnHelper = createMRTColumnHelper<(typeof filteredData)[number]>();

  const columns = useMemo(
    () => [
      columnHelper.accessor(getFullName, {
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Avatar variant='sm'>
                {getAvatarTextFromUser(row.original)}
              </Avatar>
              {renderedCellValue || '-'}
            </Box>
          );
        },
        header: 'Name',
        id: 'name',
      }),
      columnHelper.accessor('email', { header: 'Email', size: 350 }),
      columnHelper.accessor('permission_level', {
        Cell: ({ cell }) => formatPermissionLevel(cell.getValue()),
        header: 'Permission level',
      }),
      ...(isCurrentUserAdmin
        ? [
            columnHelper.accessor('enabled', {
              Cell: ({ cell }) => (
                <Badge
                  label={cell.getValue() ? 'Enabled' : 'Disabled'}
                  variant={cell.getValue() ? 'success' : 'error'}
                />
              ),
              header: 'Status',
            }),
          ]
        : []),
    ],
    [columnHelper, isCurrentUserAdmin],
  );

  const defaultMRTOptions = getDefaultMRTOptions<(typeof filteredData)[number]>(
    {
      theme,
    },
  );

  const table = useMaterialReactTable({
    ...defaultMRTOptions,
    columns,
    data: filteredData,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 140,
      },
    },
    enableColumnActions: false,
    enableExpanding: false,
    enableFilters: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableRowActions: isCurrentUserAdmin,
    initialState: {
      columnPinning: {
        left: ['name'],
        right: ['mrt-row-actions'],
      },
    },
    layoutMode: 'grid',
    muiTableContainerProps: {
      sx: { maxHeight: 'calc(100vh - 213px)' },
    },
    muiToolbarAlertBannerProps: error
      ? {
          children: 'Error loading data',
          color: 'error',
        }
      : undefined,
    renderRowActions: ({ row }) => {
      return (
        <Box display='flex' gap='4px'>
          <ResendInviteButton email={row.original.email} />
          <Button onClick={() => handleDelete(row.original)} variant='ghost'>
            Delete
          </Button>
        </Box>
      );
    },
    renderTopToolbarCustomActions: () => topToolbar,
    state: {
      isLoading: loading,
    },
  });

  return <MaterialReactTable table={table} />;
}
