import React, { FC, useEffect } from 'react';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { updateActionSettings } from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { validationSchema } from 'src/pages/workflow-builder-edit/customization-panel/constants';
import {
  buildSalesforceInitialFormValue,
  cleanSalesforceCustomizationData,
} from 'src/pages/workflow-builder-edit/customization-panel/helpers';
import { SalesforceHandoffConfiguration } from 'src/pages/workflow-builder-edit/handoff-configuration';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import type { RootState } from 'src/store/rootReducer';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { SalesforceHandoffCustomization } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface SalesforceHandoffCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const SalesforceHandoffCustomizationPanel: FC<
  React.PropsWithChildren<SalesforceHandoffCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();

  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  // @ts-expect-error legacy code with untyped state
  const handoffCustomization: SalesforceHandoffCustomization = useSelector(
    (state: RootState) =>
      state.canvasWorkflowBuilder?.actionSettings.configuration_fields,
  );
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const FormObserver: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { dirty } = useFormikContext();

    useEffect(() => {
      setAreActionSettingsUnsaved(dirty);
    }, [dirty]);

    return null;
  };

  if (canvasState.isLoadingCustomization || !handoffCustomization) {
    return <LoadingSkeleton />;
  }

  return (
    <Formik
      initialValues={buildSalesforceInitialFormValue(handoffCustomization)}
      onSubmit={(values, { resetForm }) => {
        const cleanedValues = cleanSalesforceCustomizationData(values);
        dispatch(
          updateActionSettings({
            action_type: canvasState.actionSettings.action_type as string,
            configuration_fields: cleanedValues,
          }),
        );
        dispatchActionEditedTrackingEvent({
          actionType: ActionBuilderActionTypes.SALESFORCE_HANDOFF,
        });
        resetForm({
          values: cleanedValues,
        });
        setActionPanelVisibilityParameters('hidden');
        setAreActionSettingsUnsaved(false);
        dispatch(setCustomizableActionId(''));
        onDismiss();
      }}
      validationSchema={validationSchema}
    >
      {/* This translate prop is a dummy. Sidesteps a typescript error due to older version of @types/react */}
      {({
        dirty,
        errors,
        values,
      }: FormikProps<SalesforceHandoffCustomization>) => (
        <FormWrapper style={{ height: '100%' }} translate='yes'>
          <FormObserver />
          <Box display='flex' flexDirection='column' height='100%'>
            <SalesforceHandoffConfiguration customizationData={values} />
            <SaveContainer hasTopBorder>
              <SecondaryButton
                onClick={() => {
                  onDiscard();
                }}
                type='button'
              >
                Cancel
              </SecondaryButton>
              <StyledButton
                disabled={
                  Object.values(errors).filter(err => !!err).length > 0 ||
                  !dirty ||
                  hasWorkflowConflict
                }
                type='submit'
              >
                Save
              </StyledButton>
            </SaveContainer>
          </Box>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default SalesforceHandoffCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FormWrapper = styled(Form)`
  height: 100%;
`;
