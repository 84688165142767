import React, { createContext, useRef } from 'react';

import { EditorRef } from '../rich-text-editor/types';

interface EmailBuilderContextState {
  richTextEditorRef: React.MutableRefObject<EditorRef | null> | null;
}

export const EmailBuilderContext = createContext<EmailBuilderContextState>({
  richTextEditorRef: null,
});

export function EmailBuilderContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const richTextEditorRef = useRef(null);

  return (
    <EmailBuilderContext.Provider value={{ richTextEditorRef }}>
      {children}
    </EmailBuilderContext.Provider>
  );
}
