import { Button } from '@forethought-technologies/forethought-elements';
import NoteDialog from './NoteDialog';

interface DeleteNoteDialogProps {
  noteId: string;
  onClose: () => void;
  onDelete: (noteId: string) => void;
}

const DeleteNoteDialog = ({
  noteId,
  onClose,
  onDelete,
}: DeleteNoteDialogProps) => {
  const isOpen = Boolean(noteId);

  return (
    <NoteDialog
      actionButton={
        <Button
          onClick={() => {
            onDelete(noteId);
            onClose();
          }}
          variant='danger'
        >
          Delete
        </Button>
      }
      body='Deleting this note will permanently remove it for all agents, and it cannot be undone. Are you sure you want to proceed?'
      onClose={onClose}
      open={isOpen}
      title='Delete note?'
    />
  );
};

export default DeleteNoteDialog;
