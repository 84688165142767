import React from 'react';

import {
  MoreMenu,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import ExtendIcon from '../../assets/images/extend.svg';
import ShortenIcon from '../../assets/images/shorten.svg';
import GenerateButton from '../generate-button/GenerateButton';
import CustomThemeProvider from '../theme';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';
import { GenerateResponseActionType } from 'src/types/workflowBuilderAPITypes';

interface GenerativeOptionsDropdownProps {
  /**Determines if the menu is disabled */
  isDisabled: boolean;
  /** Function to execute after an option is selected */
  onChange: (value: GenerateResponseActionType) => void;
}

const options = [
  {
    label: 'Rephrase',
    optionStartAdornment: <img src={SupportGPTIcon} />,
    value: 'rephrase',
  },
  {
    label: 'Shorten',
    optionStartAdornment: <img src={ShortenIcon} />,
    value: 'shorten',
  },
  {
    label: 'Extend',
    optionStartAdornment: <img src={ExtendIcon} />,
    value: 'extend',
  },
];

const GenerativeOptionsDropdown: React.FC<
  React.PropsWithChildren<GenerativeOptionsDropdownProps>
> = ({ isDisabled, onChange }: GenerativeOptionsDropdownProps) => {
  return (
    <CustomThemeProvider>
      <Tooltip
        tooltipContent={
          isDisabled ? 'Highlight a piece of text and start generating' : ''
        }
      >
        <MoreMenu
          disabled={isDisabled}
          onChange={value => onChange(value as GenerateResponseActionType)}
          options={options}
          renderAnchorEl={() => <GenerateButton isDisabled={isDisabled} />}
        />
      </Tooltip>
    </CustomThemeProvider>
  );
};

export default GenerativeOptionsDropdown;
