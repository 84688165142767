import { Box, styled, useTheme } from '@mui/material';

import {
  formatToDateAndTime,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Connector } from 'src/services/apiInterfaces';

const ConnectionCard = ({ connector }: { connector: Connector }) => {
  const { palette } = useTheme();
  const {
    connector_definition: connectorDefinition,
    is_active: isActive,
    last_processed_at: lastProcessedAt,
  } = connector;

  const handleLastProcessedDate = () => {
    if (lastProcessedAt) {
      return formatToDateAndTime(lastProcessedAt);
    }

    return 'Unavailable';
  };

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      sx={{
        '&:last-child': {
          borderBottom: 0,
          paddingBottom: 0,
        },
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        paddingBottom: '16px',
        paddingTop: '16px',
      }}
    >
      <Box alignItems='center' display='flex' gap={1}>
        <img height={24} src={connectorDefinition.avatar} width={24} />
        <Box display='flex' flexDirection='column'>
          <Typography variant='font14Medium'>
            {connectorDefinition.name}
          </Typography>
          <Box alignItems='center' display='flex' gap='4px'>
            <StatusIcon isEnabled={isActive} />
            <Typography color={palette.colors.grey[700]} variant='font14'>
              {`Last sync: ${handleLastProcessedDate()}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const StatusIcon = styled('div')<{ isEnabled: boolean }>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${props =>
    props.isEnabled
      ? props.theme.palette.colors.green[500]
      : props.theme.palette.colors.grey[400]};
`;

export default ConnectionCard;
