import { RootState } from '../../mockStore';
import { storeInitialState } from '../../mockStore';
import { Field } from 'src/services/apiInterfaces';
import { convertRecievedFilter } from 'src/utils/filterOperatorHelpers';

const ucfirstLetter = (string: string): string => {
  const lowerCaseStr = string.toLowerCase();
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
};

const inflateField = (field: Field, state: any) => {
  const fieldCategory: any = field.field_category;
  const filterFields = state.filters.filterFields;

  const filterField = filterFields[fieldCategory]?.find(
    (fieldd: Field) => fieldd.field_id === field.field_id,
  );

  if (!filterField) {
    return field;
  }
  field.field_type = filterField.field_type;
  field.field_name = filterField.field_name;

  field.field_category = ucfirstLetter(field.field_category || '');

  return field;
};

export const getStateFromSerializedReport = (
  actionPayload: any,
  state: RootState,
  unsaved?: boolean,
): RootState => {
  const query = actionPayload.query;
  const filters = query.filters.map((filterOld: any) => {
    const filter = { ...filterOld };
    const field = inflateField(filter.field, state);
    filter.field = field;
    filter.operator = convertRecievedFilter(filter);
    return filter;
  });

  let breakdownObj: any = null;

  if (query.breakdown) {
    const breakdownField = inflateField(query.breakdown.field, state);
    breakdownObj = breakdownField;
  }

  // Get live data from redux if some changes have been made
  const isUnsavedReport = window.location.hash.includes('unsaved_report');
  const timeFrameFilter = isUnsavedReport
    ? state.pageSettings.timeFrameFilter
    : query.time_window_unit;

  const viewMode = isUnsavedReport
    ? state.pageSettings.viewMode
    : query.visualization_type;

  return {
    ...state,
    breakdowns: {
      ...storeInitialState.breakdowns,
      appliedBreakdown: breakdownObj,
      breakdownFields: state.breakdowns.breakdownFields,
      selectedBreakdownValues: actionPayload.selected_breakdown_values,
    },
    dataDeprecated: {
      ...state.dataDeprecated,
      error: '',
    },
    filters: {
      ...storeInitialState.filters,
      filterFields: state.filters.filterFields,
      filters,
      selectedFilterValues: state.filters.selectedFilterValues,
    },
    pageSettings: {
      ...storeInitialState.pageSettings,
      chartYAxis: actionPayload.selected_y_axis,
      timeFrameFilter: ucfirstLetter(timeFrameFilter),
      timeRange: { ...state.pageSettings.timeRange },
      viewMode: ucfirstLetter(viewMode),
    },
    reports: {
      ...state.reports,
      isReportModified: !!unsaved,
      report: actionPayload,
    },
  };
};
