import { sendAuthRequestWithErrorHandling } from '../api';
import { GetPredictionMetricsOverallParams } from './types';
import { PredictionMetricsOverall } from 'src/slices/triage-analytics/types';

export const getPredictionMetricsOverallApi = async ({
  endTimestamp,
  modelName,
  startTimestamp,
}: GetPredictionMetricsOverallParams): Promise<PredictionMetricsOverall> => {
  const urlParams = new URLSearchParams({
    end_timestamp: String(endTimestamp),
    model_name: modelName,
    start_timestamp: String(startTimestamp),
  });

  return await sendAuthRequestWithErrorHandling(
    `${API_URL}prediction-metrics/overall?${urlParams.toString()}`,
    {},
    'GET',
  );
};
