import React from 'react';
import { Box, useTheme } from '@mui/material';
import { IconArrowNarrowRight } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface SubscriptionPlanPageCardProps {
  bodyButtonLabel?: string;
  bodyContent?: React.ReactNode; //REMOVE OPTIONAL
  bodyTitle: React.ReactNode;
  footerButtonLabel?: string;
  onBodyButtonClick?: () => void;
  onFooterButtonClick?: () => void;
  title: string;
}

const SubscriptionPlanPageCard = ({
  bodyButtonLabel,
  bodyContent,
  bodyTitle,
  footerButtonLabel,
  onBodyButtonClick,
  onFooterButtonClick,
  title,
}: SubscriptionPlanPageCardProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        background: palette.colors.white,
        border: `1px solid ${palette.colors.slate[200]}`,
        borderRadius: '8px',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        gap: '24px',
        padding: '26px',
      }}
    >
      <Typography color={palette.colors.black} variant='font16Bold'>
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {bodyTitle}
          {bodyButtonLabel && (
            <Button onClick={onBodyButtonClick} variant='main'>
              {bodyButtonLabel}
            </Button>
          )}
        </Box>
        {bodyContent && bodyContent}
      </Box>
      {footerButtonLabel && onFooterButtonClick && (
        <Box>
          <Button onClick={onFooterButtonClick} variant='ghost'>
            {footerButtonLabel}{' '}
            {
              <IconArrowNarrowRight
                color={palette.colors.purple[500]}
                size={20}
              />
            }
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SubscriptionPlanPageCard;
