import { type ComponentProps } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Palette } from '@mui/material/styles/createPalette';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface TextWithInfoProps {
  textColor?: [keyof Palette['colors'], number];
  title: string;
  tooltip: string;
  variant: ComponentProps<typeof Typography>['variant'];
}

const TextWithInfo = ({
  textColor,
  title,
  tooltip,
  variant,
}: TextWithInfoProps) => {
  const { palette } = useTheme();
  const color = textColor
    ? (palette.colors[textColor[0]] as Palette['colors']['black'])[textColor[1]]
    : undefined;

  return (
    <Box alignItems='center' columnGap={0.5} display='flex'>
      <Typography color={color} noWrap variant={variant}>
        {title}
      </Typography>
      {Boolean(tooltip) && (
        <Tooltip tooltipContent={tooltip}>
          <IconInfoCircle color={palette.colors.grey[700]} size={20} />
        </Tooltip>
      )}
    </Box>
  );
};

export default TextWithInfo;
