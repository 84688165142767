import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  styled,
  useTheme,
} from '@mui/material';

import {
  Button,
  DateRangeFilterButton,
  MultiSelectFilter,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import {
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

interface IntentTestFormValues {
  conversationIds: string;
  dateRange: { from: Date; to: Date };
  eligibleIntents: string[];
  name: string | null;
  testBy: 'dateRange' | 'ids';
  ticketIds: string;
}

const validationSchema = Yup.object().shape({
  eligibleIntents: Yup.array().min(1, 'At least one intent required'),
});

interface IntentTestFormProps {
  channel: ConversationChannel;
  intentOptions: { label: string; value: string }[];
  onSubmit: (values: IntentTestFormValues) => void;
  setChannel: (channel: ConversationChannel) => void;
}

export default function IntentTestForm({
  channel,
  intentOptions,
  onSubmit,
  setChannel,
}: IntentTestFormProps) {
  const { palette } = useTheme();

  return (
    <Formik
      initialValues={{
        conversationIds: '',
        dateRange: last30DaysTimeRange,
        eligibleIntents: [],
        name: null,
        testBy: 'dateRange',
        ticketIds: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <TextField
            aria-label='name'
            name='name'
            onChange={handleChange}
            placeholder='Give it a name'
            required={false}
            type='text'
            value={values.name || ''}
          />

          <Divider
            sx={{
              background: palette.colors.slate[200],
            }}
          />

          <FormControl>
            <Box component='label' htmlFor='test-by' mb='16px'>
              <Typography variant='font16Bold'>Channel</Typography>
            </Box>
            <SelectDropdown
              aria-labelledby='channel'
              id='channel'
              onChange={e => setChannel(e.target.value as ConversationChannel)}
              options={[
                {
                  label: 'Email',
                  value: 'email',
                },
                {
                  label: 'Widget',
                  value: 'widget',
                },
              ]}
              placeholder='Select intents to test'
              value={channel}
            />
          </FormControl>

          <FormControl>
            <Box component='label' htmlFor='test-by' mb='16px'>
              <Typography variant='font16Bold'>Intents</Typography>
            </Box>
            <MultiSelectFilter
              aria-labelledby='intents'
              fullWidth
              maxHeight={400}
              menuWidth={550}
              onChange={value => setFieldValue('eligibleIntents', value)}
              options={intentOptions}
              placeholder='Select intents to test'
              value={values.eligibleIntents}
            />
            <FormHelperText error>
              <Typography variant='font12'>{errors.eligibleIntents}</Typography>
            </FormHelperText>
          </FormControl>

          <FormControl>
            <Box component='label' htmlFor='test-by' mb='16px'>
              <Typography variant='font16Bold'>Test by</Typography>
            </Box>
            <RadioGroup
              aria-labelledby='test-by'
              onChange={handleChange}
              value={values.testBy}
            >
              <FormControlLabel
                control={<Radio size='small' />}
                label='Date range'
                name='testBy'
                sx={{ fontSize: 14 }}
                value='dateRange'
              />
              {values.testBy === 'dateRange' && (
                <Box pl='28px'>
                  <DateRangeFilterButton
                    onChange={dateRange =>
                      setFieldValue('dateRange', dateRange)
                    }
                    options={datePickerRangeOptionsRevamp}
                    size='medium'
                    value={values.dateRange}
                  />
                </Box>
              )}
              <Divider
                sx={{
                  background: palette.colors.slate[200],
                  margin: '12px 0 12px 28px',
                }}
              />
              {channel === 'email' ? (
                <>
                  <FormControlLabel
                    control={<Radio size='small' />}
                    label='Ticket IDs'
                    name='testBy'
                    sx={{ fontSize: 14, mb: '12px' }}
                    value='ids'
                  />
                  {values.testBy === 'ids' && (
                    <Box pl='28px' width='100%'>
                      <TextField
                        aria-label='ticket ids'
                        name='ticketIds'
                        onChange={handleChange}
                        placeholder='Enter a comma-separated list of helpdesk ticket IDs'
                        value={values.ticketIds}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <FormControlLabel
                    control={<Radio size='small' />}
                    label='Conversation IDs'
                    name='testBy'
                    sx={{ fontSize: 14, mb: '12px' }}
                    value='ids'
                  />
                  {values.testBy === 'ids' && (
                    <Box pl='28px' width='100%'>
                      <TextField
                        aria-label='conversation ids'
                        name='conversationIds'
                        onChange={handleChange}
                        placeholder='Enter a comma-separated list of conversation IDs'
                        value={values.conversationIds}
                      />
                    </Box>
                  )}
                </>
              )}
            </RadioGroup>
          </FormControl>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              type='submit'
              variant='secondary'
            >
              Create
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
