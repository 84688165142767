import flatten from 'lodash/flatten';
import { ConnectorAction } from 'src/services/apiInterfaces';
import { AutomationForm } from 'src/slices/assist-automations/types';

const stripHtml = (htmlString: string) => {
  const tmp = document.createElement('div');
  tmp.innerHTML = htmlString;

  return tmp.textContent || tmp.innerText || '';
};

const serializeAutomationTextField = (htmlString: string) =>
  stripHtml(htmlString).replaceAll(' ', '').trim();

const validate = (values: AutomationForm, action: ConnectorAction | null) => {
  const errors: {
    [key: string]: string;
  } = {};

  const noActions = !values.action_list.length;
  const actionsValues = flatten(Object.values(values.actions_input_formatter));
  const outputValues = flatten(Object.values(values.actions_output_formatter));
  // Check if the add text button should be disabled. If a connector or action isn't chosen, or if any
  // required inputs aren't filled then disable it.
  const allInputItems = Object.assign({}, ...actionsValues);
  const allOutputItems = Object.assign({}, ...outputValues);

  allInputItems.is_input_field = true;

  action?.input_schema.required?.forEach((requiredInput: string) => {
    const inputAction = values.actions_input_formatter[action.action_id]?.find(
      inputItem => inputItem[requiredInput],
    );

    if (!inputAction) {
      errors.actions_input_formatter = 'Please provide a value';
    }
  });

  const outputNotCompleted = !Object.values(allOutputItems).every(
    value => value || value === false,
  );

  if (!values.title.trim()) {
    errors.title = 'Please enter a title';
  }

  if (!values.description.trim()) {
    errors.description = 'Please enter a description';
  }

  if (!values.input_description?.trim()) {
    errors.input_description = 'Please enter an input description';
  }

  if (
    !(
      values.input_fields &&
      values.input_fields.length &&
      values.input_fields[0].context_variable.trim() &&
      values.input_fields[0].input_text.trim()
    )
  ) {
    errors.input_fields = 'Please enter at least one input';
  }

  if (noActions) {
    errors.action_list_action = 'Please select an API';
  }

  if (outputNotCompleted) {
    errors.actions_output_formatter = 'Please provide a value for each output';
  }

  if (
    values.external_text_field !== undefined &&
    !values.external_text_field?.trim()
  ) {
    errors.external_text_field = 'Please provide an external text field';
  }

  return errors;
};

const formatVariableStyle = (valueString?: string) => {
  if (!valueString) {
    return '';
  }

  let value = valueString.replace(/\s/g, '');

  const startingContext = value.length === 1 && value !== '$';

  const enteringBeforeDollar = value.length > 0 && value[0] !== '$';

  const clearingContext = value.length === 0;

  if (startingContext) {
    value = '$' + value;
  } else if (enteringBeforeDollar) {
    return;
  } else if (clearingContext) {
    value = '$';
  }

  return value;
};

const initialState: AutomationForm = {
  action_list: [],
  actions_input_formatter: {},
  actions_output_formatter: {},
  created_on: '',
  description: '',
  emblem_urls: [],
  external_text_field: undefined,
  input_description: '',
  input_fields: [],
  internal_text_field: undefined,
  published: false,
  title: '',
};

export {
  formatVariableStyle,
  initialState,
  serializeAutomationTextField,
  validate,
};
