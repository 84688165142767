import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { IconGripHorizontal, IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  Checkbox,
  ListOption,
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { useGetContextVariablesForTextEditor } from '../../email-builder-page/intent-email-builder/hooks';
import { useSwapItem } from '../../hooks';
import { DynamicListCVSelectDropdown } from './DynamicListCVSelectDropdown';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectCanvasActionSettings } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetContextVariablesQuery } from 'src/services/dashboard-api';
import {
  selectActionCustomization,
  selectAddingAction,
  setActionCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { DynamicCardCustomizationSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes, Routes } from 'src/utils/enums';
import { convertDynamicListCVChildrenToListOptions } from 'src/utils/solve/dynamicContextVariableUtils';

const EMPTY_DYNAMIC_CARD_SETTINGS: DynamicCardCustomizationSettings = {
  display_option: { limit: 5, type: 'capped' },
  sections: [
    { components: [{ key: '' }], type: 'title' },
    { components: [{ key: '' }], type: 'image' },
    { components: [{ key: '' }], type: 'content' },
  ],
  style: 'carousel',
};

interface DynamicCardSettingsPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

export const DynamicCardSettingsPanel = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}: DynamicCardSettingsPanelProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data: contextVariableResponse } = useGetContextVariablesQuery();
  const remirrorContextVariables = useGetContextVariablesForTextEditor();
  const editorRef = useRef<EditorRef>(null);
  const actionSettings = useSelector(selectCanvasActionSettings);
  const dynamicCardSettings =
    actionSettings.configuration_fields as DynamicCardCustomizationSettings;

  const addingAction = useSelector(selectAddingAction);
  const initialCustomizationData: DynamicCardCustomizationSettings | undefined =
    addingAction ? EMPTY_DYNAMIC_CARD_SETTINGS : dynamicCardSettings;

  const customizationData = (useSelector(selectActionCustomization) ||
    initialCustomizationData) as DynamicCardCustomizationSettings;
  const { dynamic_list_context_variable: selectedDynamicListCV } =
    customizationData || {};

  const isPristine = isEqual(customizationData, initialCustomizationData);

  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const isFormDisabled = () => {
    if (!addingAction && isPristine) {
      return true;
    }

    const components = customizationData.sections.flatMap(
      section => section.components,
    );

    return components.some(component => {
      if (!component.key.trim()) {
        return true;
      }

      return false;
    });
  };

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (dynamicCardSettings) {
      dispatch(setActionCustomization(dynamicCardSettings));
    }
  }, [dynamicCardSettings, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setActionCustomization(null));
    };
  }, [dispatch]);

  const purple = theme.palette.colors.purple[500];

  const dynamicListChildOptions: ListOption[] | null = useMemo(() => {
    if (!selectedDynamicListCV || !contextVariableResponse) {
      return null;
    }
    const dynamicListCV = contextVariableResponse?.context_variables.find(
      cv => cv.context_variable_id === selectedDynamicListCV,
    );
    if (!dynamicListCV) {
      return null;
    }
    return convertDynamicListCVChildrenToListOptions(dynamicListCV, purple);
  }, [selectedDynamicListCV, contextVariableResponse, purple]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box display='flex' flexDirection='column' height='100%' overflow='auto'>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <DynamicCardsHeader />
        <DynamicListCVSelectDropdown
          onChange={value =>
            dispatch(
              setActionCustomization({
                ...EMPTY_DYNAMIC_CARD_SETTINGS,
                dynamic_list_context_variable: value,
              }),
            )
          }
          value={customizationData.dynamic_list_context_variable ?? ''}
        />
        {customizationData.dynamic_list_context_variable && (
          <>
            <Box mb={3}>
              <Typography variant='font14Medium'>Message</Typography>
              <Box
                border={`1px solid ${theme.palette.colors.grey[300]}`}
                borderRadius='4px'
                mt={1}
              >
                <RichTextEditor
                  contextVariables={remirrorContextVariables}
                  editorRef={editorRef}
                  initialContent={customizationData.message ?? ''}
                  onChange={value =>
                    dispatch(
                      setActionCustomization({
                        ...customizationData,
                        message: value,
                      }),
                    )
                  }
                  placeholder='Write a message for your customers...'
                />
              </Box>
            </Box>
            <Box
              borderBottom={'1px solid ' + theme.palette.colors.grey[300]}
              pb={3}
            >
              <RowRadioButtonsGroup
                displayOption={customizationData.display_option}
                setDisplayOption={displayOption =>
                  dispatch(
                    setActionCustomization({
                      ...customizationData,
                      display_option: displayOption,
                    }),
                  )
                }
              />
            </Box>
            <Box
              borderBottom={'1px solid ' + theme.palette.colors.grey[300]}
              display='flex'
              flexDirection='column'
              gap={3}
              pb={3}
            >
              {customizationData.sections.map((section, index) => (
                <DraggableSections
                  addComponent={() => {
                    const componentsCopy = structuredClone(section.components);
                    componentsCopy.push({ key: '' });

                    const sections = structuredClone(
                      customizationData.sections,
                    );
                    sections[index].components = componentsCopy;

                    dispatch(
                      setActionCustomization({
                        ...customizationData,
                        sections,
                      }),
                    );
                  }}
                  deleteComponent={componentIndex => {
                    const componentsCopy = structuredClone(section.components);
                    componentsCopy.splice(componentIndex, 1);

                    const sections = structuredClone(
                      customizationData.sections,
                    );
                    sections[index].components = componentsCopy;

                    dispatch(
                      setActionCustomization({
                        ...customizationData,
                        sections,
                      }),
                    );
                  }}
                  dynamicListChildOptions={dynamicListChildOptions}
                  index={index}
                  key={index}
                  section={section}
                  setComponent={(componentIndex, value) => {
                    const componentsCopy = structuredClone(section.components);
                    componentsCopy[componentIndex] = value;

                    const sections = structuredClone(
                      customizationData.sections,
                    );
                    sections[index].components = componentsCopy;

                    dispatch(
                      setActionCustomization({
                        ...customizationData,
                        sections,
                      }),
                    );
                  }}
                  swapComponents={sectionIndex => (dragIndex, hoverIndex) => {
                    const componentsCopy = structuredClone(
                      customizationData.sections[sectionIndex],
                    ).components;
                    const temp = componentsCopy[hoverIndex];
                    componentsCopy[hoverIndex] = componentsCopy[dragIndex];
                    componentsCopy[dragIndex] = temp;

                    const sections = structuredClone(
                      customizationData.sections,
                    );
                    sections[index].components = componentsCopy;

                    dispatch(
                      setActionCustomization({
                        ...customizationData,
                        sections,
                      }),
                    );
                  }}
                  swapSections={(dragIndex, hoverIndex) => {
                    const sectionsCopy = [...customizationData.sections];
                    const temp = sectionsCopy[hoverIndex];
                    sectionsCopy[hoverIndex] = sectionsCopy[dragIndex];
                    sectionsCopy[dragIndex] = temp;

                    dispatch(
                      setActionCustomization({
                        ...customizationData,
                        sections: sectionsCopy,
                      }),
                    );
                  }}
                />
              ))}
            </Box>
            <ContentConfig
              contentConfig={customizationData.content_config}
              setContentConfig={contentConfig =>
                dispatch(
                  setActionCustomization({
                    ...customizationData,
                    content_config: contentConfig,
                  }),
                )
              }
            />
          </>
        )}
        <Box display='flex' gap={2} mt='auto'>
          <Button onClick={onDiscard} size='large' variant='secondary'>
            Cancel
          </Button>
          <Button
            disabled={hasWorkflowConflict || isFormDisabled()}
            fullWidth
            onClick={() => {
              dispatch(
                addingAction
                  ? addAction({
                      ...addingAction,
                      data: {
                        ...addingAction.data,
                        configuration_fields: customizationData,
                      },
                    })
                  : updateActionSettings({
                      action_type: actionSettings.action_type as string,
                      configuration_fields: customizationData,
                    }),
              );
              dispatchActionEditedTrackingEvent({
                actionType: ActionBuilderActionTypes.DYNAMIC_CARD,
              });
              setActionPanelVisibilityParameters('hidden');
              setAreActionSettingsUnsaved(false);
              dispatch(setCustomizableActionId(''));
              onDismiss();
            }}
            size='large'
            variant='main'
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const DynamicCardsHeader = () => {
  const theme = useTheme();

  return (
    <>
      <Typography variant='font24'>Dynamic cards</Typography>
      <Typography
        color={theme.palette.colors.grey[700]}
        component='ol'
        pl={2}
        sx={{ a: { textDecoration: 'none' } }}
        variant='font14'
      >
        <li>
          To create a dynamic card that utilizes{' '}
          <Typography color={theme.palette.colors.black} variant='font14Medium'>
            Dynamic List Context Variables
          </Typography>{' '}
          holding essential information, start by creating one in{' '}
          <Link to={Routes.ACTION_BUILDER}>Action Builder</Link>.
        </li>
        <li>
          After establishing the{' '}
          <Typography color={theme.palette.colors.black} variant='font14Medium'>
            Dynamic List Context Variables
          </Typography>
          , choose the one you want to begin with.
        </li>
        <li>
          Design the Dynamic Card&apos;s content to showcase information derived
          from Dynamic List CVs.
        </li>
      </Typography>
      <Typography color={theme.palette.colors.grey[500]} variant='font14'>
        Solve automatically updates the card content as Dynamic List CVs change,
        ensuring current and accurate information.
      </Typography>
    </>
  );
};

const RowRadioButtonsGroup = ({
  displayOption,
  setDisplayOption,
}: {
  displayOption: DynamicCardCustomizationSettings['display_option'];
  setDisplayOption: (
    displayOption: DynamicCardCustomizationSettings['display_option'],
  ) => void;
}) => {
  const { limit, type } = displayOption;

  return (
    <FormControl>
      <FormLabel id='type-radio-buttons-group'>
        <Typography variant='font14Medium'>Display option</Typography>
      </FormLabel>
      <RadioGroup
        aria-labelledby='type-radio-buttons-group'
        name='row-radio-buttons-group'
        onChange={e =>
          setDisplayOption({
            ...displayOption,
            type: e.target.value as 'capped' | 'all',
          })
        }
        row
        value={type}
      >
        <FormControlLabel
          control={<Radio />}
          disableTypography
          label={<Typography variant='font14'>All records</Typography>}
          value='all'
        />
        <FormControlLabel
          control={<Radio />}
          disableTypography
          label={
            <Typography variant='font14'>Cap the record count at</Typography>
          }
          value='capped'
        />
        <Box>
          <TextField
            aria-label='Display limit'
            disabled={type === 'all'}
            onChange={e => {
              const newLimit = Number(e.target.value);
              setDisplayOption({
                ...displayOption,
                limit: newLimit < 0 ? 0 : newLimit,
              });
            }}
            type='number'
            value={String(limit)}
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

const DraggableSections = ({
  addComponent,
  deleteComponent,
  dynamicListChildOptions,
  index,
  section,
  setComponent,
  swapComponents,
  swapSections,
}: {
  addComponent: () => void;
  deleteComponent: (componentIndex: number) => void;
  dynamicListChildOptions: ListOption[] | null;
  index: number;
  section: DynamicCardCustomizationSettings['sections'][number];
  setComponent: (
    index: number,
    value: DynamicCardCustomizationSettings['sections'][number]['components'][number],
  ) => void;
  swapComponents: (
    sectionIndex: number,
  ) => (index: number, index2: number) => void;
  swapSections: (index: number, index2: number) => void;
}) => {
  const { palette } = useTheme();
  const { handlerId, opacity, ref } = useSwapItem({
    index,
    swap: swapSections,
  });

  return (
    <Box
      data-handler-id={handlerId}
      display='flex'
      gap={1}
      key={index}
      ref={ref}
      sx={{
        backgroundColor: palette.common.white,
        cursor: 'grab',
        flexDirection: 'column',
        opacity,
        transform: 'translate(0, 0)',
      }}
    >
      <Box alignItems='center' display='flex' gap={1}>
        <IconGripHorizontal color={palette.colors.grey[300]} size={20} />
        <Box flex={1} textTransform='capitalize'>
          <Typography variant='font16Bold'>{section.type}</Typography>
        </Box>
      </Box>
      {section.components.map((component, innerIndex) => (
        <DraggableComponents
          component={component}
          deleteComponent={deleteComponent}
          dynamicListChildOptions={dynamicListChildOptions}
          index={innerIndex}
          key={innerIndex}
          setComponent={setComponent}
          swapComponents={swapComponents(index)}
          type={section.type}
        />
      ))}
      <Box>
        <Button onClick={addComponent} variant='ghost'>
          <IconPlus size={20} />
          Add {section.type}
        </Button>
      </Box>
    </Box>
  );
};

const DraggableComponents = ({
  component,
  deleteComponent,
  dynamicListChildOptions,
  index,
  setComponent,
  swapComponents,
  type,
}: {
  component: DynamicCardCustomizationSettings['sections'][number]['components'][number];
  deleteComponent: (componentIndex: number) => void;
  dynamicListChildOptions: ListOption[] | null;
  index: number;
  setComponent: (
    index: number,
    value: DynamicCardCustomizationSettings['sections'][number]['components'][number],
  ) => void;
  swapComponents: (index: number, index2: number) => void;
  type: DynamicCardCustomizationSettings['sections'][number]['type'];
}) => {
  const { palette } = useTheme();
  const { handlerId, opacity, ref } = useSwapItem({
    accept: 'NESTED_SWAP',
    index,
    swap: swapComponents,
  });

  return (
    <Box
      alignItems='center'
      data-handler-id={handlerId}
      display='flex'
      gap={1}
      key={index}
      ref={ref}
      sx={{
        backgroundColor: palette.common.white,
        cursor: 'grab',
        opacity,
        transform: 'translate(0, 0)',
      }}
    >
      <IconGripHorizontal color={palette.colors.grey[300]} size={20} />
      <Box alignItems='center' display='flex' flex={1} gap={1}>
        {type !== 'image' && (
          <TextField
            aria-label='Label'
            onChange={e => {
              setComponent(index, {
                key: component.key,
                label: e.target.value,
              });
            }}
            placeholder='Label for value'
            value={component.label ?? ''}
          />
        )}
        {dynamicListChildOptions && (
          <SelectDropdown
            error={!component.key}
            id={index + component.key}
            isMenuSearchable
            onChange={e => {
              setComponent(index, {
                key: e.target.value,
                label: component.label,
              });
            }}
            options={dynamicListChildOptions}
            value={component.key}
          />
        )}
        <Button onClick={() => deleteComponent(index)} variant='ghost'>
          <IconTrash size={20} />
        </Button>
      </Box>
    </Box>
  );
};

const ContentConfig = ({
  contentConfig,
  setContentConfig,
}: {
  contentConfig: DynamicCardCustomizationSettings['content_config'];
  setContentConfig: (
    contentConfig: DynamicCardCustomizationSettings['content_config'],
  ) => void;
}) => {
  return (
    <Box>
      <Checkbox
        checked={Boolean(contentConfig)}
        label='Limit initial content rows. Users can expand cards for full details.'
        onChange={() =>
          contentConfig
            ? setContentConfig(null)
            : setContentConfig({ limit: 4 })
        }
      />
      {Boolean(contentConfig) && (
        <Box width='120px'>
          <TextField
            label='Row limit'
            onChange={e => {
              const newLimit = Number(e.target.value);
              setContentConfig({ limit: newLimit < 0 ? 0 : newLimit });
            }}
            type='number'
            value={String(contentConfig?.limit)}
          />
        </Box>
      )}
    </Box>
  );
};
