import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import supportGpt from 'src/assets/images/support-gpt.svg';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { selectNavbarNotificationToggle } from 'src/slices/ui/uiSlice';
import {
  listSerialize,
  overrideDiscoverSearchParams,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

interface NotificationBannerProps {
  generatedArticleCount: number;
  isLoading: boolean;
}

const NotificationBanner = ({
  generatedArticleCount,
  isLoading,
}: NotificationBannerProps) => {
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const isContentGenerationEnabled =
    orgConfigData?.is_content_generation_enabled;
  const isNotificationVisible = useSelector(
    selectNavbarNotificationToggle(Routes.DISCOVER_DASHBOARD),
  );

  const linkWithSearch = useGenerateLinkWithSearch(Routes.DISCOVER_TOPICS);

  if (isLoading || !isNotificationVisible || !isContentGenerationEnabled) {
    return null;
  }

  if (!generatedArticleCount) {
    return (
      <StyledBanner
        bodyText='Congratulations! SupportGPT™ has detected no knowledge gaps in your topics.'
        linkText='View topics with generated articles'
        linkTo={overrideDiscoverSearchParams(linkWithSearch, {
          'group-filters': listSerialize(['article-generated']),
        })}
      />
    );
  }

  return (
    <StyledBanner
      bodyText={`SupportGPT™ has detected topics with no articles, we've generated 
        ${generatedArticleCount} to fill that gap.`}
      linkText='View all generated articles'
      linkTo={Routes.DISCOVER_AUTOMATION + '?tab=Knowledge+gap'}
    />
  );
};

interface StyledBannerProps {
  bodyText: string;
  linkText: string;
  linkTo: string;
}

const StyledBanner = ({ bodyText, linkText, linkTo }: StyledBannerProps) => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.slate[100]}
      borderRadius='8px'
      display='flex'
      gap={1}
      mt={3}
      p={2}
    >
      <img src={supportGpt} />
      <Typography variant='font14Bold'>{bodyText}</Typography>
      <StyledLink to={linkTo}>
        <Typography color={palette.colors.purple[500]} variant='font14Bold'>
          {linkText}
        </Typography>
      </StyledLink>
    </Box>
  );
};

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export default NotificationBanner;
