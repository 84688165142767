import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { ForethoughtEditStepMenu } from './edit-menu/ForethoughtEditStepMenu';
import { FormStep as RevampedFormStep } from './workflow-builder/revamped';
import { FormStepChatProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { selectUndefinedContextVariablesInStep } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { FormStepFields } from 'src/types/workflowBuilderAPITypes';

const FormStep: React.FC<React.PropsWithChildren<FormStepChatProps>> = ({
  setShouldShowEditMenu,
  shouldShowEditMenu,
  step,
  stepId,
}) => {
  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );
  const undefinedContextVariableIds = useMemo(() => {
    return undefinedContextVariablesInStep[stepId] || [];
  }, [stepId, undefinedContextVariablesInStep]);

  const isDeletionDisabled = () => {
    return hasCondition;
  };

  const fields = step.step_fields as FormStepFields;

  const hasCondition = !!step.condition_name;

  return (
    <>
      <RevampedFormStep
        fields={fields}
        stepId={stepId}
        undefinedContextVariableIds={undefinedContextVariableIds}
      />
      <ForethoughtEditStepMenu
        hasCondition={hasCondition}
        isDeletionDisabled={isDeletionDisabled()}
        isVisible={shouldShowEditMenu}
        setIsVisible={setShouldShowEditMenu}
        stepId={stepId}
        stepType={step.step_type}
      />
    </>
  );
};

export const ChatImage = styled('img')`
  width: 32px;
  height: 32px;
  margin-top: 10px;
`;

export default FormStep;
