import React, { CSSProperties } from 'react';
import Box from '@mui/material/Box';

import {
  Button,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DiscoverAutomationCard } from '../components';
import { generateDiscoverTopicDetailUrl } from '../helpers';
import DiscoverNewTopicBadge from 'src/components/discover-new-topic-badge';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  deriveTopicNameFromTopic,
  getDiscoverBannerValues,
} from 'src/utils/discover/helpers';

interface DiscoverRecommendationCardProps {
  onMouseEnter: (topicName: string) => void;
  style: CSSProperties;
  topic: DiscoverTopic;
}

const DiscoverRecommendationCard = ({
  onMouseEnter,
  style,
  topic,
}: DiscoverRecommendationCardProps) => {
  const dispatch = useAppDispatch();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const { costValue, volumeValue } = getDiscoverBannerValues(
    topic.actionable_value?.solve_workflow,
  );

  const handleOnClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    emitTrackingEventCallback('discover-automate-button-clicked', {
      page: 'Automation',
      'page-area': 'Recommendation',
      'topic-name': topic.topic_name,
    });

    dispatch(
      setGlobalDiscoverOptions({
        topicToAutomate: topic,
      }),
    );
  };

  return (
    <DiscoverAutomationCard
      linkTo={generateDiscoverTopicDetailUrl(topic)}
      onMouseEnter={() => onMouseEnter(topic.topic_name)}
      style={style}
    >
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography noWrap variant='font16Bold'>
          {deriveTopicNameFromTopic(topic)}
        </Typography>
        <DiscoverNewTopicBadge
          isNewTopic={Boolean(topic.is_new_topic)}
          margin='0 5px'
        />
      </Box>
      <>
        <Typography noWrap variant='font16Bold'>
          {volumeValue}
        </Typography>
      </>
      <>
        <Typography noWrap variant='font16Bold'>
          {costValue}
        </Typography>
      </>
      <Tooltip tooltipContent={DISCOVER_CARD_TEXT.automateButtonTooltip}>
        <Button onClick={handleOnClick} variant='secondary'>
          <Typography variant='font14Bold'>Automate</Typography>
        </Button>
      </Tooltip>
    </DiscoverAutomationCard>
  );
};

export default DiscoverRecommendationCard;
