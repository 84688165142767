import { DiscoverTopicTableData } from '../types';
import { AutomationCallToActionRendererComponent } from './AutomatedCallToActionRenderer';

export const automationCallToActionCellRenderer =
  (trackingEventMetadata: Record<string, string | string[]> = {}) =>
  // eslint-disable-next-line react/display-name
  ({ topic }: DiscoverTopicTableData, isSummaryRow?: boolean) =>
    isSummaryRow ? null : (
      <AutomationCallToActionRendererComponent
        topic={topic}
        trackingEventMetadata={trackingEventMetadata}
      />
    );
