import { useEmitTrackingEventCallback } from '../hooks';
import { TriageSelfServeTrackingEventType } from 'src/components/app/types';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

interface SelfServeTrackingEventParams {
  label?: string;
  model?: VersionedTriageModel;
  modelId?: string;
  modelName?: string;
  page?: 'model_detail' | 'version_control' | 'model_list';
  tagId?: string | null;
  versionId?: string;
  versionName?: string;
}

const useSelfServeEvents = (params: SelfServeTrackingEventParams) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const {
    label,
    model,
    modelId,
    modelName,
    page,
    tagId,
    versionId,
    versionName,
  } = params;

  const eventObject = {
    label,
    model_id: model?.model_id ?? modelId,
    model_name: model?.model_name ?? modelName,
    page,
    tag_id: tagId,
    version_id: model?.version_id ?? versionId,
    version_name: model?.version_name ?? versionName,
  };

  const emitTrackingEvent = (
    eventName: TriageSelfServeTrackingEventType,
    extra?: Record<string, string | boolean | null>,
  ) => {
    emitTrackingEventCallback(eventName, {
      ...eventObject,
      ...extra,
    });
  };
  return emitTrackingEvent;
};

export default useSelfServeEvents;
