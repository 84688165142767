import kustomerLogoSrc from '../../../../assets/images/kustomer-article-logo.svg';
import salesforceLogoSrc from '../../../../assets/images/salesforce-article-icon.svg';
import RuleSidebar from './components/active-sidebars/RuleSidebar';
import { TerminalNodeSidebar } from './components/active-sidebars/TerminalNodeSidebar/TerminalNodeSidebar';
import ActionNode from './components/nodes/ActionNode';
import DropzoneNodeContainer from './components/nodes/DropzoneNodeContainer';
import IntentNode from './components/nodes/IntentNode';
import ResponseNode from './components/nodes/ResponseNode';
import RuleNode from './components/nodes/RuleNode';
import TerminalNode from './components/nodes/TerminalNode';
import freshdeskLogoSrc from 'src/assets/images/freshdesk-article-logo.svg';
import zendeskLogoSrc from 'src/assets/images/grey-zendesk-logo.svg';
import { FLOW_NODES, WorkflowEdgeData } from 'src/components/forethought-flow';
import { FlowConfiguration } from 'src/components/forethought-flow/types';

export const EMAIL_WORKFLOW_NODES = {
  ACTION: 'action',
  INTENT: 'intent',
  RESPONSE: 'response',
  RULE: 'rule',
  TERMINAL: 'terminal',
} as const;

export const INTENT_EMAIL_FLOW_CONFIG: FlowConfiguration = {
  [EMAIL_WORKFLOW_NODES.ACTION]: {
    node: ActionNode,
    renderSidebar: null,
  },
  // override the default dropzone node type so we can do additional solve email logic when a new component is dropped
  [FLOW_NODES.DROPZONE]: {
    node: DropzoneNodeContainer,
    renderSidebar: null,
  },
  [EMAIL_WORKFLOW_NODES.INTENT]: {
    node: IntentNode,
    renderSidebar: null,
  },
  [EMAIL_WORKFLOW_NODES.RESPONSE]: {
    node: ResponseNode,
    renderSidebar: null,
  },
  [EMAIL_WORKFLOW_NODES.RULE]: {
    node: RuleNode,
    renderSidebar: (node, handleCancel) => (
      <RuleSidebar handleCancel={handleCancel} node={node} />
    ),
  },
  [EMAIL_WORKFLOW_NODES.TERMINAL]: {
    node: TerminalNode,
    renderSidebar: (node, handleCancel) => (
      <TerminalNodeSidebar handleCancel={handleCancel} node={node} />
    ),
  },
};

export const entryStepMissingError =
  'This Journey map requires at least one response. Connect a response to the Journey map.';

export const zendeskEdgeInfo: WorkflowEdgeData['infoLabel'] = {
  iconSrc: zendeskLogoSrc,
  text: 'Zendesk Information',
};

export const salesforceEdgeInfo: WorkflowEdgeData['infoLabel'] = {
  iconSrc: salesforceLogoSrc,
  text: 'Salesforce Information',
};

export const kustomerEdgeInfo: WorkflowEdgeData['infoLabel'] = {
  iconSrc: kustomerLogoSrc,
  text: 'Kustomer Information',
};

export const freshdeskEdgeInfo: WorkflowEdgeData['infoLabel'] = {
  iconSrc: freshdeskLogoSrc,
  text: 'Freshdesk Information',
};

export const TERMINAL_NODE_PREFIX = 'terminal';
export const INTENT_NODE_PREFIX = 'intent';

export const MISSING_STATIC_ARTICLE_ERROR =
  'Errors in the response template need to be fixed before this response can be sent.';
