import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';

const ButtonId: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Field name='agent_chat_handoff_settings.button_id'>
      {({ field, form: { setFieldValue }, meta }: FieldProps) => (
        <>
          <ContextMention
            errorMessage={meta.error}
            hasError={Boolean(meta.touched && meta.error)}
            label='Button ID'
            onChange={value => {
              setFieldValue(field.name, value);
            }}
            required
            value={field.value}
          />
        </>
      )}
    </Field>
  );
};

export default ButtonId;
