import { createAsyncThunk } from '@reduxjs/toolkit';

import * as API from 'src/services/apiV1';
import type { RootState } from 'src/store/rootReducer';

export const fetchExperimentsTreatments = createAsyncThunk(
  'experiments/getExperimentsTreatments',
  async (_, { getState, requestId }) => {
    const state = getState() as RootState;
    if (requestId === state.experiments.loadingRequestId) {
      return API.getExperimentsTreatments();
    } else {
      return Promise.resolve({ treatments: state.experiments.treatments });
    }
  },
);
