import React, { FC, useEffect, useRef } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import StepContainer from '../workflow-builder/revamped/StepContainer';
import StepHeader from '../workflow-builder/revamped/StepHeader';
import TextBox from '../workflow-builder/revamped/TextBox';
import warningIcon from 'src/assets/images/circular-warning-icon.svg';
import { StepTypes } from 'src/utils/enums';

export const ArticleSuggestionStepOutline: FC<
  React.PropsWithChildren<unknown>
> = ({}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <StepContainer isBeingEdited>
      <StepHeader stepType={StepTypes.ARTICLE_SUGGESTION} />
      <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='font16Bold'>Article Suggestion (step)</Typography>
        {['If article is suggested', 'If article is not suggested'].map(
          (option, index) => (
            <TextBox
              index={index}
              key={index}
              validIndicator={
                <TooltipWrapper>
                  <Tooltip tooltipContent='Complete empty branches.'>
                    <img alt='Branch is invalid' src={warningIcon} />
                  </Tooltip>
                </TooltipWrapper>
              }
              value={option}
            />
          ),
        )}
      </Box>
    </StepContainer>
  );
};

const TooltipWrapper = styled('div')`
  margin-left: 2px;
  margin-right: -2px;
  height: 20px;
`;
