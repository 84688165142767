import React from 'react';
import { Field, FieldProps } from 'formik';
import { styled } from '@mui/material/styles';

import {
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
// Components
import AutomationCard from '../automation-card';
import {
  ASSIST_AUTOMATIONS_APPCUES_TARGET,
  ASSIST_AUTOMATIONS_TEXT,
} from 'src/utils/constants';

interface Props {
  isReadOnly: boolean;
}

const OverviewCard: React.FC<React.PropsWithChildren<Props>> = ({
  isReadOnly,
}) => {
  return (
    <AutomationCard
      dataAppcuesTarget={ASSIST_AUTOMATIONS_APPCUES_TARGET.overviewSection}
      description={ASSIST_AUTOMATIONS_TEXT.overviewDescription}
      title={
        <Typography variant='font16Bold'>
          {ASSIST_AUTOMATIONS_TEXT.overviewTitle}
        </Typography>
      }
    >
      <InputWrapper isReadOnly={isReadOnly}>
        <Field name='title'>
          {({ field, meta }: FieldProps) => (
            <TextField
              {...field}
              description='Max 42 characters'
              error={meta.touched && meta.error}
              label='Title'
              maxLength={42}
              placeholder='What does your action do e.g. Get documents from Salesforce'
            />
          )}
        </Field>
      </InputWrapper>
      <InputWrapper isReadOnly={isReadOnly}>
        <Field name='description'>
          {({ field, meta }: FieldProps) => (
            <TextField
              {...field}
              description='Max 62 characters'
              error={meta.touched && meta.error}
              label='Description'
              maxLength={62}
              placeholder='What best describes your action e.g. Sends Salesforce documents to all macros '
            />
          )}
        </Field>
      </InputWrapper>
    </AutomationCard>
  );
};

const InputWrapper = styled('div')<{ isReadOnly: boolean }>`
  margin: 16px 0;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ isReadOnly }) => isReadOnly && 'pointer-events: none;'}
`;

export default OverviewCard;
