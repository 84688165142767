import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverCard from '../../../discover-card/DiscoverCard';
import { DiscoverCommonTopicSkeleton } from '../../../discover-skeleton/DiscoverSkeleton';
import DiscoverMetricTooltip from '../../../discover-tooltip/DiscoverMetricTooltip';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import DiscoverNewTopicBadge from 'src/components/discover-new-topic-badge';
import { dataVisColors } from 'src/constants/colors';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectDashboardMetrics } from 'src/reducers/discoverReducer/discoverReducer';
import { DiscoverDashboardResponse } from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { deriveTopicName } from 'src/utils/discover/helpers';

type OnClickParams = {
  topic_id: string;
  topic_name: string;
};

interface DashboardTabCommonTopicsCardProps {
  onClick: () => void;
  onTopicClick: (params: OnClickParams) => void;
  timeFilter: TopicTimeFilter;
  timePeriod: TopicPeriodicalFilter;
}

const filterOthers = (
  dashboardMetrics?: DiscoverDashboardResponse['most_common_topics_list'],
) => {
  if (!dashboardMetrics) {
    return [];
  }

  return dashboardMetrics.filter(
    ({ topic_name }) => !topic_name.includes('- Others'),
  );
};

const DashboardTabCommonTopicsCard = ({
  onClick,
  onTopicClick,
  timeFilter,
  timePeriod,
}: DashboardTabCommonTopicsCardProps) => {
  const { data, loading } = useSelector(selectDashboardMetrics);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const mostCommonTopics = filterOthers(data?.most_common_topics_list);
  const maxVolumeValue = Math.max(
    ...mostCommonTopics.map(({ count }) => count),
  );

  return (
    <DiscoverCard
      featureName='commontopics'
      headerComponent={
        <Box
          color={theme.palette.colors.black}
          data-appcues-target={getAppCuesId({
            componentType: 'header',
            featureName: 'mostcommontopics',
            pageName: 'discover',
            subType: '',
          })}
          display='flex'
          justifyContent='space-between'
        >
          <Box display='flex'>
            <Typography variant='font16Bold'>Most common topics</Typography>
            <Box alignItems='center' display='flex' ml={1}>
              <DiscoverMetricTooltip metricType='most_common_topics' />
            </Box>
          </Box>
          <Typography color={theme.palette.colors.grey[700]} variant='font12'>
            {timeFilter.label}
          </Typography>
        </Box>
      }
      linkText='More topics'
      onClick={() => {
        emitTrackingEventCallback('discover-clicked-on-most-common-topics', {
          page: 'Discover Dashboard',
          'time-comparison-selected': timePeriod,
          'time-period-selected': timeFilter,
        });
        onClick();
      }}
    >
      <Box
        data-appcues-target={getAppCuesId({
          componentType: 'container',
          featureName: 'commontopics',
          pageName: 'discover',
          subType: 'card',
        })}
        display='flex'
        mb={1}
        mt={3}
      >
        <Box textAlign='left' width={180}>
          <Typography variant='font14Bold'>Topic</Typography>
        </Box>
        <Box textAlign='center' width='100%'>
          <Typography variant='font14Bold'>Volume</Typography>
        </Box>
      </Box>
      {loading ? (
        <DiscoverCommonTopicSkeleton />
      ) : (
        mostCommonTopics.map(
          (
            { count, is_new_topic, topic_display_name, topic_id, topic_name },
            idx,
          ) => {
            const topicName = deriveTopicName(topic_display_name, topic_name);
            return (
              <TopicItem
                display='flex'
                key={topic_name}
                mb={idx === mostCommonTopics.length - 1 ? 0 : 1}
                onClick={event => {
                  event.stopPropagation();

                  emitTrackingEventCallback('discover-clicked-on-topic', {
                    page: 'Discover Page',
                    topic: topic_name,
                  });

                  onTopicClick({
                    topic_id,
                    topic_name,
                  });
                }}
              >
                <Box
                  display='flex'
                  justifyContent='flex-start'
                  mr={2}
                  sx={{
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                  }}
                  textAlign='left'
                  width={180}
                >
                  <Typography variant='font14'>{topicName}</Typography>
                  <DiscoverNewTopicBadge isNewTopic={is_new_topic} />
                </Box>
                <Box alignItems='flex-start' display='flex' flex={1}>
                  <Box
                    bgcolor={dataVisColors[idx % dataVisColors.length]}
                    borderRadius={1}
                    height={16}
                    mr={0.5}
                    sx={{
                      marginTop: '4px',
                    }}
                    width={`${(count / maxVolumeValue) * 100}%`}
                  />
                  <Typography variant='font14'>{count}</Typography>
                </Box>
              </TopicItem>
            );
          },
        )
      )}
    </DiscoverCard>
  );
};

const TopicItem = styled(Box)(({ theme }) => ({
  ['&:hover']: {
    backgroundColor: theme.palette.colors.slate[100],
    borderRadius: theme.shape.borderRadius,
  },
}));

export default DashboardTabCommonTopicsCard;
