import '../dashboard-pages/common/datePicker.scss';
import '../dashboard-pages/common/tablePage.scss';
import 'react-day-picker/lib/style.css';
import 'react-day-picker/lib/style.css';

import React from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';

import { setPageNumber } from 'src/actions/pageSettings/pageSettings';
import { TotalsAndAverages } from 'src/components/dashboard-pages/common/totals-and-averages';
import NoDataView from 'src/components/reusable-components/no-data-view/NoDataView';
import TableHeader from 'src/components/reusable-components/table-header/tableHeader';
import TableRow from 'src/components/reusable-components/table-row/tableRow';
import { selectPageNumber } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import {
  selectFilterType,
  selectIsSolveWorkflows,
} from 'src/reducers/solveReducer';
import {
  Breakdown,
  ChartTotals,
  MacroDataDict,
  SolveTableDict,
  TableTotals,
  WorkflowsList,
} from 'src/services/apiInterfaces';
import { TableProps } from 'src/types/TableTypes';
import { formatNum } from 'src/utils/analyticsUtils';

const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  breakdownData,
  deleteCallBack,
  fieldNameCallback,
  fieldValueRowButtonCallback,
  fieldValueRowButtonStyle,
  fieldValueRowButtonText,
  filterEventTracker,
  getDeleteTooltipDescription,
  getDeleteTooltipText,
  getDeleteTooltipTitle,
  isMacroTable,
  noDataViewBreakdown,
  noDataViewClass,
  noDataViewMessage,
  onRowClick,
  rowTooltipText,
  rowTooltipTitle,
  shouldHideSortButtons,
  showDeleteButton,
  showTotals,
  showWorkflowIcon,
  sortedProperty,
  tableData,
  tableHeaderDict,
  tableValues,
  typeForRowButton,
  valueForTooltip,
}: TableProps): JSX.Element => {
  const dispatch = useDispatch();

  // Selectors
  const isSolveWorkflows = useSelector(selectIsSolveWorkflows);

  const pageNumber: number = useSelector(selectPageNumber);
  const selectedFilter: { [key: string]: string } =
    useSelector(selectFilterType);
  const { key, name } = selectedFilter;
  const handlePageClick = (data: ChartTotals & TableTotals) => {
    dispatch(setPageNumber(data.selected));
  };

  const { aggregate, data_types, is_final } = tableData as SolveTableDict;

  // Function to create cell values for table
  const createCellsValue = (
    tableData: MacroDataDict | Breakdown,
    isAggregate?: boolean,
  ): string[] => {
    const cells: string[] = [];
    const values = isAggregate ? tableValues.slice(1) : tableValues;
    tableData &&
      values.forEach((value: string) => {
        return cells.push(
          formatNum(value, tableData[value] as number, data_types) as string,
        );
      });

    return cells;
  };

  // Pagination without chunking
  const numberOfElements = breakdownData?.length || 1;
  const numberOfPages = Math.ceil(numberOfElements / 50);
  const start = pageNumber * 50;
  const pageElements = breakdownData?.slice(start, start + 50) || [];

  let renderedTableData;
  if (pageElements && pageElements.length > 0) {
    renderedTableData = pageElements.map((tableData, index: number) => {
      return (
        <TableRow
          breakdown={[1]}
          cells={createCellsValue(tableData)}
          deleteCallBack={() => {
            deleteCallBack && deleteCallBack(tableData);
          }}
          entryStepId={(tableData as WorkflowsList).entry_step_id}
          fieldNameCallback={
            fieldNameCallback ? () => fieldNameCallback(tableData) : undefined
          }
          fieldValueRowButtonCallback={() =>
            fieldValueRowButtonCallback && fieldValueRowButtonCallback()
          }
          fieldValueRowButtonStyle={fieldValueRowButtonStyle}
          fieldValueRowButtonText={fieldValueRowButtonText}
          getDeleteTooltipDescription={() =>
            getDeleteTooltipDescription &&
            getDeleteTooltipDescription(tableData)
          }
          getDeleteTooltipText={() =>
            getDeleteTooltipText && getDeleteTooltipText(tableData)
          }
          getDeleteTooltipTitle={getDeleteTooltipTitle}
          // Pass an array w something so it's not grayed out
          isFinalValue={is_final}
          isMacroTable={isMacroTable}
          key={index}
          onRowClick={onRowClick ? () => onRowClick(tableData) : undefined}
          showDeleteButton={
            showDeleteButton ? () => showDeleteButton(tableData) : undefined
          }
          showFieldValueRowButton={
            tableData?.workflow_type === typeForRowButton
          }
          showFieldValueTooltip={tableData?.field_value === valueForTooltip}
          showWorkflowIcon={showWorkflowIcon}
          tooltipText={rowTooltipText}
          tooltipTitle={rowTooltipTitle}
        />
      );
    });
  } else {
    //specifies we want an arr with 10 space and fill puts emptyBreakdown in there
    const noResultsBreakdown = Array(10).fill(noDataViewBreakdown);
    renderedTableData = noResultsBreakdown.map((tableData, index: number) => {
      return (
        <TableRow
          breakdown={[]}
          cells={createCellsValue(tableData)}
          isFinalValue={is_final}
          isMacroTable
          key={index}
          showWorkflowIcon={showWorkflowIcon}
        />
      );
    });
  }

  const resetPage = () => {
    dispatch(setPageNumber(0));
  };

  const setAggregateValue = () => {
    if (isSolveWorkflows) {
      return aggregate;
    } else {
      return aggregate[key];
    }
  };

  return (
    <div className='analytics-table' data-testid='table-id'>
      {pageElements && pageElements.length === 0 && (
        <NoDataView
          message={noDataViewMessage}
          noDataViewClass={noDataViewClass}
        />
      )}
      <table className='analytics'>
        <tbody>
          <tr className='table-header'>
            {tableHeaderDict.map(
              (value: { [index: string]: string | null }, index: number) => {
                const {
                  heading,
                  headingClass,
                  productSorted,
                  propertyName,
                  tooltipText,
                  tooltipTitle,
                } = value;

                return (
                  <TableHeader
                    divClass={
                      sortedProperty === value.propertyName
                        ? 'sort-wrapper focused'
                        : 'sort-wrapper'
                    }
                    eventTracker={filterEventTracker}
                    heading={
                      propertyName === 'field_value'
                        ? name
                        : (heading as string)
                    }
                    headingClass={headingClass as string}
                    key={index}
                    onClick={resetPage}
                    productSorted={productSorted as string}
                    propertyName={propertyName as string}
                    shouldHideSortButtons={shouldHideSortButtons}
                    showFilterDropdown={propertyName === 'field_value'}
                    tooltipClass={'second-col'}
                    tooltipText={tooltipText as string}
                    tooltipTitle={tooltipTitle as string}
                  />
                );
              },
            )}
          </tr>
          {showTotals && pageElements && pageElements.length > 0 && (
            <TotalsAndAverages
              cells={createCellsValue(
                setAggregateValue() as { [key: string]: number },
                true,
              )} // Casting to type because of the optional type for aggregate
              isFinalValue={is_final}
            />
          )}
          {renderedTableData}
        </tbody>
      </table>
      <ReactPaginate
        activeClassName={'active'}
        breakClassName={'break-me'}
        breakLabel={'...'}
        containerClassName={'pagination'}
        forcePage={pageNumber}
        marginPagesDisplayed={0}
        nextLabel={''}
        onPageChange={handlePageClick}
        pageCount={numberOfPages}
        pageRangeDisplayed={2}
        previousLabel={''}
        subContainerClassName={'pages pagination'}
      />
    </div>
  );
};

export default Table;
