import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconMail, IconPlus } from '@tabler/icons-react';

import {
  Button,
  TextEditor,
  Typography,
} from '@forethought-technologies/forethought-elements';
import AlertTooltip from '../../intent-email-journey-map/components/AlertTooltip';
import { MISSING_STATIC_ARTICLE_ERROR } from '../../intent-email-journey-map/constants';
import { useGoToConfiguration } from '../hooks';
import { useGetBuilderQueryParams } from 'src/pages/workflow-builder-edit/hooks';
import { IntentEmailConfigurationResponse } from 'src/pages/workflow-builder-edit/types';
import {
  createIntentEmailConfiguration,
  selectEmailBuilderState,
  updateIntentEmailConfigurationName,
} from 'src/slices/email-builder/emailBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';

export default function ResponseTemplateList({
  activeEmailConfiguration,
  onDeleteConfiguration,
}: {
  activeEmailConfiguration: IntentEmailConfigurationResponse | null;
  onDeleteConfiguration: () => void;
}) {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const { intentId } = useGetBuilderQueryParams();
  const { intentEmailConfigurations } = useSelector(selectEmailBuilderState);

  const activeConfigurationId =
    activeEmailConfiguration?.configuration_id || '';

  const goToConfiguration = useGoToConfiguration();

  async function onClickNewResponse() {
    try {
      const response = await dispatch(
        createIntentEmailConfiguration({ intentDefinitionId: intentId }),
      ).unwrap();
      goToConfiguration(response.configuration_id);
    } catch {}
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box padding='24px'>
        <Button
          fullWidth
          onClick={onClickNewResponse}
          startIcon={<IconPlus size={16} />}
          variant='secondary'
        >
          New response
        </Button>
      </Box>
      <Box height='100%' sx={{ overflowY: 'scroll' }}>
        {intentEmailConfigurations.map(
          (
            {
              configuration_id: configurationId,
              is_in_use: isInUse,
              missing_static_article: missingStaticArticle,
              name,
            },
            index,
          ) => {
            const isActive = configurationId === activeConfigurationId;
            const emailConigurationName = name || `Response ${index + 1}`;
            const configurationslistContainsOneElement =
              intentEmailConfigurations.length === 1;
            const shouldDisableDelete =
              configurationslistContainsOneElement || isInUse;

            const disabledDeleteButtonTooltipContent = isInUse
              ? 'Can not be deleted because it is in use'
              : configurationslistContainsOneElement
              ? 'You need at least one response'
              : '';

            return (
              <Box
                aria-label={`Select ${emailConigurationName}`}
                key={configurationId}
                onClick={() => {
                  goToConfiguration(configurationId);
                }}
                role='button'
                sx={theme => ({
                  '&:hover': {
                    backgroundColor: theme.palette.colors.blue[200],
                  },
                  alignItems: 'center',
                  backgroundColor: isActive
                    ? theme.palette.colors.blue[100]
                    : 'transparent',
                  cursor: 'pointer',
                  display: 'flex',
                  gap: '12px',
                  height: '56px',
                  padding: '0 18px',
                })}
              >
                <Box height='17px' width='17px'>
                  <IconMail color={palette.colors.grey[700]} size={18} />
                </Box>
                <Box
                  alignItems='center'
                  display='flex'
                  flexGrow={1}
                  gap='6px'
                  justifyContent='space-between'
                  overflow='hidden'
                >
                  {isActive ? (
                    <TextEditor
                      defaultValue={emailConigurationName}
                      disabledDeleteButtonTooltipContent={
                        disabledDeleteButtonTooltipContent
                      }
                      hideDeleteButton={false}
                      isDeleteButtonDisabled={shouldDisableDelete}
                      onDelete={() => onDeleteConfiguration()}
                      onSave={value => {
                        if (activeEmailConfiguration) {
                          dispatch(
                            updateIntentEmailConfigurationName({
                              configurationId,
                              name: value,
                              version: activeEmailConfiguration.version,
                            }),
                          );
                        }
                      }}
                      saveButtonAriaLabel='save response name'
                    />
                  ) : (
                    <Box
                      flex={1}
                      overflow='hidden'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                    >
                      <Typography variant='font14'>
                        {emailConigurationName}
                      </Typography>
                    </Box>
                  )}
                  {missingStaticArticle && (
                    <AlertTooltip
                      tooltipProps={{
                        placement: 'right',
                        tooltipContent: MISSING_STATIC_ARTICLE_ERROR,
                      }}
                    />
                  )}
                </Box>
              </Box>
            );
          },
        )}
      </Box>
    </Box>
  );
}
