import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/system';

import { Typography } from '@forethought-technologies/forethought-elements';
import NameEmailQuestion from '../name-email-question/NameEmailQuestion';
import AgentChatHandoffOnly from './agent-chat-handoff/agent-chat-handoff-only';
import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import {
  SalesforceChatHandoffCustomization,
  ZendeskOrSalesforceContextVariables,
} from 'src/types/workflowBuilderAPITypes';

interface SalesforceChatHandoffConfigurationProps {
  customizationData: SalesforceChatHandoffCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<
    SetStateAction<SalesforceChatHandoffCustomization>
  >;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const SalesforceChatHandoffConfiguration: FC<
  React.PropsWithChildren<SalesforceChatHandoffConfigurationProps>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  return (
    <ContentWrapper>
      <Typography variant='font20Medium'>Begin Salesforce Chat</Typography>
      <Section>
        <NameEmailQuestion
          customizationData={customizationData}
          errorMessages={errorMessages}
          isFieldTouchedArr={isFieldTouchedArr}
          setCustomizationData={
            setCustomizationData as Dispatch<
              SetStateAction<ZendeskOrSalesforceContextVariables>
            >
          }
          setIsFieldTouchedArr={setIsFieldTouchedArr}
          shouldAllowEmptyName
        />
        <Spacer height='28px' />
        <AgentChatHandoffOnly customizationData={customizationData} />
        <Spacer height='100px' />
      </Section>
    </ContentWrapper>
  );
};

export default SalesforceChatHandoffConfiguration;

const ContentWrapper = styled('div')`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
