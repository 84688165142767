import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { Colors } from '@forethought-technologies/forethought-elements';
import FallbackDescription from '../solve-config/fallback-tab-content/FallbackDescription';
import ForethoughtCanvas from './forethought-canvas/ForethoughtCanvas';
import ForethoughtInput from './Input/ForethoughtInput';
import { WorkflowMessageBanner } from './workflow-builder/WorkflowMessageBanner';
import ForethoughtIntent from './ForethoughtIntent';
import { useEmailMode, useGetBuilderQueryParams } from './hooks';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { ForethoughtWidgetProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { selectIsFallback } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectIsDraft,
  selectIsDuplicatedPendingCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';

const ForethoughtWidget: React.FC<
  React.PropsWithChildren<ForethoughtWidgetProps>
> = ({
  actionPanelVisibilityParameters,
  hasWorkflowConflict,
  hideAlertModals,
  setActionPanelVisibilityParameters,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const editorRef = useRef<EditorRef>(null);
  const isDraft = useSelector(selectIsDraft);
  const isFallback = useSelector(selectIsFallback);
  const { emailMode } = useEmailMode();
  const isDuplicatedPendingCustomization = useSelector(
    selectIsDuplicatedPendingCustomization,
  );
  const { view } = useGetBuilderQueryParams();

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      maxHeight='calc(100vh - 113px)'
      py='24px'
    >
      <WorkflowMessageBanner />
      <ChatBox
        isDisabled={
          isDuplicatedPendingCustomization &&
          (emailMode === 'Interactive' || view === 'api' || view === 'slack')
        }
      >
        <ForethoughtIntent />
        {isFallback && (
          <Box marginInline='65px' marginTop='24px'>
            <FallbackDescription isFallbackWordWithGradient />
          </Box>
        )}
        <ForethoughtCanvas
          actionPanelVisibilityParameters={actionPanelVisibilityParameters}
          editorRef={editorRef}
          hasWorkflowConflict={hasWorkflowConflict}
          hideAlertModals={() => hideAlertModals()}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
          squeezeStepParentId={squeezeStepParentId}
        />
        {isDraft && (
          <ForethoughtInput
            editorRef={editorRef}
            hasWorkflowConflict={hasWorkflowConflict}
            shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
            squeezeStepParentId={squeezeStepParentId}
          />
        )}
      </ChatBox>
    </Box>
  );
};

const ChatBox = styled('div')<{ isDisabled: boolean }>`
  background: ${Colors.ui.backgrounds.default};
  border-radius: 8px;
  width: 600px;
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  ${props => props.isDisabled && 'opacity: 0.5; pointer-events: none;'}
`;

export default ForethoughtWidget;
