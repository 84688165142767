import {
  CreateTicketFieldMappingRequest,
  TicketFieldMappingResponse,
} from 'src/pages/workflow-builder-edit/types';

export const getFormattedTicketFieldMappings = (
  ticketFieldMappings: Array<TicketFieldMappingResponse>,
): Array<CreateTicketFieldMappingRequest> => {
  return ticketFieldMappings.map(mapping => ({
    context_variable_id: mapping.context_variable_id,
    external_ticket_field_id: mapping.external_ticket_field_id,
    is_in_use: mapping.is_in_use,
    mapping_data: mapping.mapping_data,
    mapping_type: mapping.mapping_type,
    ticket_field_mapping_id: mapping.ticket_field_mapping_id,
  }));
};
