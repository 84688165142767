export enum SolveWorkflowsActions {
  GET_WORKFLOW_CONFIG_FAILURE = 'get/workflowConfigFailure',
  GET_WORKFLOW_CONFIG_SUCCESS = 'get/workflowConfigSucess',
  GET_WORKFLOWS_OVERVIEW_DATA_SUCCESS = 'get/workflowsOverviewDataSucess',
  SET_IS_EDIT_PANEL_VISIBLE = 'set/setIsEditPanelVisible',
  SET_IS_NEW_WORKFLOW = 'set/isNewWorkflow',
  SET_NEW_WORKFLOW_ID = 'set/newWorkflowId',
  SET_NEW_WORKFLOW_ID_FAILURE = 'set/newWorkflowIdFailure',
  SET_SELECTED_WORKFLOW_ID = 'set/selectedWorkflowId',
  SET_SOLVE_WORKFLOWS_VIEW = 'set/solveWorkflowsView',
  SET_WORKFLOW_ERROR = 'set/workflowError',
}
