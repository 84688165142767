import { MacroDataDict } from 'src/services/apiInterfaces';

export const getFilteredMacros = (
  search: string,
  macros: Array<MacroDataDict> | [],
): Array<MacroDataDict> => {
  let searchedMacros: Array<MacroDataDict> | [];

  if (search) {
    searchedMacros = macros?.filter((macro: MacroDataDict) => {
      if (
        macro.name.toLowerCase().includes(search.toLowerCase()) ||
        macro.macro_id.toLowerCase().includes(search.toLowerCase())
      ) {
        return macro;
      }
    });
  } else {
    searchedMacros = macros;
  }

  return searchedMacros;
};

const updateListItem = (
  macrosList: Array<MacroDataDict> | [],

  updatedMacro: MacroDataDict,
) => {
  const macroIdx = macrosList.findIndex(
    macro => macro.macro_id === updatedMacro.macro_id,
  );

  Object.assign(macrosList[macroIdx], updatedMacro);
  return macrosList;
};

export const updateMacro = (
  macrosList: Array<MacroDataDict> | [],
  updatedMacro: MacroDataDict,
  isList?: boolean,
) => {
  const macro = macrosList.find(
    macro => macro.macro_id === updatedMacro.macro_id,
  );
  const updatedValue = Object.assign({}, macro, updatedMacro);

  if (isList) {
    return updateListItem(macrosList, updatedMacro);
  } else {
    return updatedValue;
  }
};
