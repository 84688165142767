import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import { FilterButton } from '@forethought-technologies/forethought-elements';
import { setTimeFrameFilter } from 'src/actions/pageSettings/pageSettings';
import timeIcon from 'src/assets/images/time-icon.svg';
import disabledTimeIcon from 'src/assets/images/time-icon-disabled.svg';
import SettingsLabel from 'src/components/reusable-components/settings-label';
import { selectTimeFrameFilter } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { TimeFrameFilters } from 'src/utils/enums';
import { getData } from 'src/utils/getDataUtil';

interface Props {
  disabled?: boolean;
  eventTracker?: Function;
}

const TimeframeDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  disabled,
  eventTracker,
}: Props) => {
  const dispatch = useDispatch();
  const options = Object.values(TimeFrameFilters).map(option => ({
    label: option,
    value: option,
  }));
  const selectedTimeFrameFilter = useSelector(selectTimeFrameFilter);

  const chartTimeframeFilterMenuClick = (option: string): void => {
    dispatch(setTimeFrameFilter(option));
    getData();
    eventTracker && eventTracker(option);
  };

  return (
    <Container>
      <SettingsLabel name={'frequency'} />
      <FilterButton
        aria-label='time frame chooser'
        disabled={disabled}
        initialValue={TimeFrameFilters.DAILY}
        onChange={(value: string) => chartTimeframeFilterMenuClick(value)}
        options={options}
        startAdornment={<SVG src={disabled ? disabledTimeIcon : timeIcon} />}
        value={selectedTimeFrameFilter}
      />
    </Container>
  );
};

const SVG = styled(ReactSVG)`
  height: 16px;
  margin-right: 5px;
`;

const Container = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 8px;
`;

export default TimeframeDropdown;
