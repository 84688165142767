import './styles.scss';

import React from 'react';
import classNames from 'classnames';

type InputAttributes = React.ComponentProps<'input'>;

interface TextInputProps extends InputAttributes {
  /** Optional classnames to be passed to the container wrapping the text input */
  containerClassName?: string;
  /** Optional classnames to be passed to the the password input */
  inputClassName?: string;
  /** Determines if input required attribute is enabled or disabled */
  isRequired?: boolean;
  /** name property for input */
  name: string;
}

const TextInput: React.FC<React.PropsWithChildren<TextInputProps>> = ({
  containerClassName = '',
  inputClassName,
  isRequired,
  name,
  ...rest
}: TextInputProps) => {
  const containerClassNames = classNames(
    containerClassName,
    'TextInput--container',
  );
  const inputClasses = classNames(inputClassName, 'TextInput-input');

  return (
    <div className={containerClassNames}>
      <input
        className={inputClasses}
        name={name}
        required={isRequired}
        type='text'
        {...rest}
      />
    </div>
  );
};

export { TextInput };
