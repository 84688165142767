import { Permission, Routes, TablesToSort, Tabs } from 'src/utils/enums';

interface MappingsInterface {
  [key: string]: {
    permission: Permission;
    routes: Routes;
    tableToSort?: TablesToSort;
  };
}

export const navBarMappings: MappingsInterface = {
  [Tabs.SEARCH]: {
    permission: Permission.SEARCH,
    routes: Routes.SEARCH,
    tableToSort: TablesToSort.SEARCH,
  },
  [Tabs.PREDICTIONS]: {
    permission: Permission.PREDICTIONS,
    routes: Routes.PREDICTIONS,
    tableToSort: TablesToSort.PREDICTIONS,
  },
  [Tabs.DISCOVER_DASHBOARD]: {
    permission: Permission.DISCOVER,
    routes: Routes.DISCOVER_DASHBOARD,
  },
  [Tabs.DISCOVER_AUTOMATION]: {
    permission: Permission.DISCOVER,
    routes: Routes.DISCOVER_AUTOMATION,
  },
  [Tabs.ANSWERS]: {
    permission: Permission.ANSWERS,
    routes: Routes.ANSWERS,
    tableToSort: TablesToSort.ANSWERS,
  },
  [Tabs.SOLVE]: {
    permission: Permission.SOLVE,
    routes: Routes.SOLVE,
    tableToSort: TablesToSort.SOLVE,
  },
  [Tabs.SOLVE_LITE]: {
    permission: Permission.SOLVE_LITE,
    routes: Routes.SOLVE_LITE,
  },
  [Tabs.SOLVE_CONFIGURATION]: {
    permission: Permission.WORKFLOW_BUILDER,
    routes: Routes.SOLVE_CONFIGURATION,
  },
  [Tabs.WORKFLOW_BUILDER]: {
    permission: Permission.WORKFLOW_BUILDER,
    routes: Routes.WORKFLOW_BUILDER,
  },
  [Tabs.WORKFLOW_BUILDER_EDIT]: {
    permission: Permission.FLAMETHROWER_WORKFLOW_BUILDER_EDIT,
    routes: Routes.WORKFLOW_BUILDER_EDIT,
  },
  [Tabs.ACTION_BUILDER]: {
    permission: Permission.FLAMETHROWER,
    routes: Routes.ACTION_BUILDER,
    tableToSort: TablesToSort.ACTION_BUILDER,
  },
  [Tabs.SOLVE_PREVIEW]: {
    permission: Permission.SOLVE_PREVIEW,
    routes: Routes.SOLVE_PREVIEW,
  },
  [Tabs.ASSIST_NOTES]: {
    permission: Permission.ASSIST,
    routes: Routes.ASSIST_NOTES,
  },
  [Tabs.PREDICT_INTENTS]: {
    permission: Permission.PREDICT_INTENTS,
    routes: Routes.PREDICT_INTENTS,
  },
};
