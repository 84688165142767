export type ColumnItem = {
  accessorKey: string;
  header: string;
  isColorInverted?: boolean;
  isPercentChange?: boolean;
  isSentiment?: boolean;
  relatedKey?: string;
  size?: number;
};

const HEADER_WIDTH = 240;

export const columns: ColumnItem[] = [
  {
    accessorKey: 'name',
    header: 'Topic',
  },
  {
    accessorKey: 'volume',
    header: 'Tickets',
    size: 180,
  },
  {
    accessorKey: 'percent_volume_change',
    header: '% of tickets change',
    isPercentChange: true,
    relatedKey: 'volume',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'first_resolution_time',
    header: 'First resolution time (hrs)',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'percent_first_resolution_time_change',
    header: '% of first resolution time (hrs) change',
    isPercentChange: true,
    relatedKey: 'first_resolution_time',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'first_contact_resolution',
    header: 'First contact resolution',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'percent_first_contact_resolution_change',
    header: '% of first contact resolution change',
    isColorInverted: true,
    isPercentChange: true,
    relatedKey: 'first_contact_resolution',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'full_resolution_time',
    header: 'Full resolution time (hrs)',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'percent_resolution_time_change',
    header: '% of full resolution time (hrs) change',
    isPercentChange: true,
    relatedKey: 'full_resolution_time',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'reply_time',
    header: 'Reply time (hrs)',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'percent_reply_time_change',
    header: '% of reply time (hrs) change',
    isPercentChange: true,
    relatedKey: 'reply_time',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'number_of_agent_replies',
    header: 'Agent replies',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'percent_agent_replies_change',
    header: '% of agent replies change',
    isPercentChange: true,
    relatedKey: 'number_of_agent_replies',
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'starting_sentiment',
    header: 'Starting sentiment',
    isSentiment: true,
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'ending_sentiment',
    header: 'Ending sentiment',
    isSentiment: true,
    size: HEADER_WIDTH,
  },
  {
    accessorKey: 'drop_in_sentiment',
    header: 'Drop in sentiment',
    isSentiment: true,
    size: HEADER_WIDTH,
  },
];

export const BUTTON_ICON_WIDTH = 60;
export const CHAR_SIZE = 7;
export const AUTOMATION_BUTTON_WIDTH = 150;
export const WORKFLOW_BUTTON_WIDTH = 180;
export const ACTION_BUTTON_WIDTH = 52;
export const BUTTON_NUM_ITEMS = 2;
export const NAME_GAP = 4;
