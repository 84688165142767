import React, { FC } from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import ButtonId from './ButtonId';
import DeploymentId from './DeploymentId';
import ExtraPreChatValues from './ExtraPreChatValues';
import MessageCustomization from './MessageCustomization';
import StoreTranscriptDropdown from './StoreTranscriptDropdown';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { SalesforceHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface AgentChatHandoffProps {
  customizationData: SalesforceHandoffCustomization;
}

const AgentChatHandoff: FC<React.PropsWithChildren<AgentChatHandoffProps>> = ({
  customizationData,
}) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isStoreTranscriptEnabled = featureFlags.includes(
    'store_forethought_transcript_for_salesforce',
  );

  return (
    <Wrapper>
      <ButtonId />
      <Box mb={3} />
      <DeploymentId />
      <Box mb={3} />
      <ExtraPreChatValues customizationData={customizationData} />
      <Box mb={3} />
      <MessageCustomization />
      <Box mb={3} />
      {isStoreTranscriptEnabled && (
        <>
          <StoreTranscriptDropdown customizationData={customizationData} />
          <Box mb={3} />
        </>
      )}
    </Wrapper>
  );
};

export default AgentChatHandoff;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 28px 0 30px;
`;
