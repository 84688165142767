import { createAsyncThunk } from '@reduxjs/toolkit';

import { IdPSettings, SSOConfig } from 'src/services/apiInterfaces';
import * as API from 'src/services/apiV1';

export const getSSOSettings = createAsyncThunk('sso/getSSOSettings', () =>
  API.getSSOSettings(),
);

export const createIdP = createAsyncThunk(
  'sso/createIdP',
  (payload: IdPSettings) => API.createIdP(payload),
);

export const deleteIdP = createAsyncThunk('sso/deleteIdP', () =>
  API.deleteIdP(),
);

export const updateSSOConfig = createAsyncThunk(
  'sso/updateSSOConfig',
  (payload: SSOConfig) => API.updateSSOConfig(payload),
);

export const createSCIMKey = createAsyncThunk('sso/createSCIMKey', () =>
  API.createSCIMKey(),
);

export const disableSCIM = createAsyncThunk('sso/disableSCIM', () =>
  API.disableSCIM(),
);
