import { useEffect, useMemo, useState } from 'react';
import { FilterProps } from 'react-table';
import Box from '@mui/material/Box';

import {
  SearchBar,
  SelectDropdown,
} from '@forethought-technologies/forethought-elements';
import debounce from 'lodash/fp/debounce';
import { StepTranslation } from 'src/types/workflowBuilderAPITypes';

export function TypeColumnFilter({
  column: { preFilteredRows, setFilter },
}: FilterProps<StepTranslation>) {
  const optionAll = 'All Intents';
  // States
  const [displayValue, setDisplayValue] = useState(optionAll);
  // options for the type dropdown down button
  const typeFilterOptions = useMemo(() => {
    const bucketIdsSet = new Set<string>();
    bucketIdsSet.add(optionAll);
    const idToNameMap = new Map<string, string>();
    preFilteredRows.forEach(row => {
      bucketIdsSet.add(row.values.bucket_id);
      idToNameMap.set(row.values.bucket_id, row.original.display_name);
    });

    return Array.from(bucketIdsSet).map(bucketId => ({
      label: idToNameMap.get(bucketId) || optionAll,
      value: bucketId,
    }));
  }, [preFilteredRows]);

  useEffect(() => {
    if (displayValue === optionAll) {
      return;
    }
    if (!typeFilterOptions.some(option => option.value === displayValue)) {
      setFilter(undefined);
      setDisplayValue(optionAll);
      return;
    }
    setFilter(displayValue);
    /**
     * We can't add setFilter to the dependency array because setFilter would update itself and cause infinite
     * render loop that crashes the render. Only including preFilteredRows is fine because the purpose of this
     * useEffect is to persist the selected filter when admin changes a different language and preFilteredRows
     * changes only EditTranslaitonPhrasesTab -> translations changes, i.e. when admin changes to a different language
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilteredRows]);

  return (
    <SelectDropdown
      id='select-type'
      onChange={e => {
        e.target.value !== optionAll
          ? setFilter(e.target.value)
          : setFilter(undefined);
        setDisplayValue(e.target.value);
      }}
      options={typeFilterOptions}
      value={displayValue}
    ></SelectDropdown>
  );
}

export function DebouncedGlobalSearchBar({
  ariaLabel,
  initialValue,
  setGlobalFilter,
}: {
  ariaLabel: string;
  initialValue: string | number;
  setGlobalFilter: (value: string | undefined) => void;
}) {
  const [value, setValue] = useState(initialValue);
  const debouncedSetGlobalFilter = useMemo(
    () => debounce(500, setGlobalFilter),
    [setGlobalFilter],
  );

  return (
    <Box width='300px'>
      <SearchBar
        aria-label={ariaLabel}
        fullWidth
        onChange={({ target }) => {
          setValue(target.value);
          debouncedSetGlobalFilter(target.value);
        }}
        onClear={() => {
          setValue('');
          debouncedSetGlobalFilter('');
        }}
        placeholder='Search for text in any column'
        size='small'
        value={value}
      />
    </Box>
  );
}
