import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DeepLink } from './DeepLink';
import {
  ErrorQueryEvent,
  NoValidConditionPayload,
} from 'src/types/ErrorEventTypes';

export const NoValidConditionFix = ({
  channel,
  payload,
}: {
  channel: ErrorQueryEvent['channel'];
  payload: NoValidConditionPayload;
}) => {
  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Typography variant='font16'>To address this, you can:</Typography>
      <Typography variant='font16'>
        1. Add an &quot;Otherwise&quot; branch as a fallback to the condition
        step.
      </Typography>
      <Typography variant='font16'>
        2. Check the conditions and CVs used to make sure at least one branch
        would pass.
      </Typography>
      <Box mt={1}>
        <DeepLink
          channel={channel}
          stepId={payload.step_id}
          workflowId={payload.workflow_id}
        />
      </Box>
    </Box>
  );
};
