import ReactDOMServer from 'react-dom/server';
import { lighten, Theme } from '@mui/material';

import { ExtendedBarTooltipFormatterContextObject } from '../types';
import { numbersToStringWithCommas } from 'src/components/dashboard-pages/solve-insights/helpers';

export const renderChartTooltip = (
  theme: Theme,
  object: ExtendedBarTooltipFormatterContextObject,
  isStacked: boolean,
  isComparing: boolean,
) => {
  const { palette } = theme;
  const points = object.points;
  if (!points || !points?.length) {
    return;
  }
  const headerName = isComparing ? object.x : object.point.tooltip_header_label;
  const categories = object.point.categories ?? [];
  const type = object.point.type;

  let pointAX,
    pointBX,
    valueA,
    valueB,
    valueC,
    colorA,
    colorB,
    colorC,
    categoryA,
    categoryB,
    categoryC,
    tooltipValuesA: {
      label: string;
      type: string;
      value: number;
    }[] = [],
    tooltipValuesB: {
      label: string;
      type: string;
      value: number;
    }[] = [];
  if (isComparing) {
    const [{ point: pointA }, { point: pointB }] = points;
    const { options: optionsA } = pointA;
    const { options: optionsB } = pointB;
    pointAX = pointA.category;
    pointBX = pointB.category;
    categoryA = optionsA?.category ?? '';
    categoryB = optionsB?.category ?? '';
    tooltipValuesA = pointA.tooltip_values ?? [];
    tooltipValuesB = pointB.tooltip_values ?? [];
    colorA = pointA.actualColor ?? (pointA.color as string);
    colorB = pointB.actualColor ?? (pointB.color as string);
  } else if (isStacked) {
    const [{ color: color1, y: pointA }, { color: color2, y: pointB }] = points;
    let color3, pointC;
    if (points.length === 3) {
      color3 = points[2].color;
      pointC = points[2].y;
    }
    valueA = pointA;
    colorA = color1 as string;
    valueB = pointB;
    colorB = color2 as string;
    valueC = pointC;
    colorC = color3;
    categoryA = categories?.[0];
    categoryB = categories?.[1];
    categoryC = categories?.[2];
  } else {
    const [{ color, y: pointA }] = points;
    valueA = pointA;
    colorA = color as string;
    categoryA = categories?.[0];
    categoryB = categories?.[1];
  }

  const handleTooltipContent = ({
    tooltips,
    value,
    xCategory,
  }: {
    tooltips?: {
      label: string;
      type: string;
      value: number;
    }[];
    value?: number | null;
    xCategory?: string | number | null;
  }) => {
    if (isComparing) {
      return tooltips?.map(tooltip => {
        const { label, type, value } = tooltip;
        const handleValueColor = () => {
          // Unique color behavior on each Tooltip
          if (label === 'from previous period' && xCategory) {
            const xString = xCategory.toString();
            if (
              [
                '5-Excellent',
                '4-Good',
                'Relevant',
                'Somewhat relevant',
                'Yes',
              ].includes(xString)
            ) {
              if (value > 0) {
                return palette.colors.green[500];
              } else if (value === 0) {
                return palette.colors.black;
              } else {
                return palette.colors.red[500];
              }
            }
            if (['2-Bad', '1-Terrible', 'Irrelevant', 'No'].includes(xString)) {
              if (value > 0) {
                return palette.colors.red[500];
              } else if (value === 0) {
                return palette.colors.black;
              } else {
                return palette.colors.green[500];
              }
            }
            return palette.colors.black;
          }
        };
        return (
          <div key={tooltip.label} style={{ display: 'flex', gap: '4px' }}>
            <p
              style={{
                color: handleValueColor(),
                fontFamily: theme.typography.fontFamily,
                fontSize: 14,
                fontWeight: 600,
                margin: 0,
                padding: 0,
              }}
            >
              {numbersToStringWithCommas({
                canBeFloat: true,
                maximumFractionDigits: type === 'percentage' ? 2 : undefined,
                number: value ?? undefined,
                style: type === 'percentage' ? 'percent' : 'decimal',
              })}
            </p>
            <p
              style={{
                color: palette.colors.grey[600],
                fontFamily: theme.typography.fontFamily,
                fontSize: 14,
                fontWeight: 400,
                margin: 0,
                padding: 0,
              }}
            >
              {label}
            </p>
          </div>
        );
      });
    }

    return (
      <p
        style={{
          color: palette.colors.black,
          fontSize: 14,
          fontWeight: 600,
          margin: 0,
          padding: 0,
        }}
      >
        {numbersToStringWithCommas({
          canBeFloat: true,
          maximumFractionDigits: type === 'percentage' ? 2 : undefined,
          number: value ?? undefined,
          style: type === 'percentage' ? 'percent' : 'decimal',
        })}
      </p>
    );
  };

  const tooltipComponent = (
    <div
      style={{
        border: `1px solid ${palette.colors.slate[200]}`,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        minWidth: '226px',
        padding: '8px',
      }}
    >
      <p
        style={{
          color: palette.colors.black,
          fontSize: 14,
          fontWeight: 600,
          margin: 0,
          padding: 0,
        }}
      >
        {headerName}
      </p>
      <div
        style={{
          alignItems: !!categoryA ? 'flex-start' : 'center',
          display: 'flex',
        }}
      >
        <div
          style={{
            backgroundColor: colorA as string,
            borderRadius: '2px',
            height: '12px',
            width: '12px',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            marginLeft: '8px',
          }}
        >
          {categoryA && (
            <p
              style={{
                color: palette.colors.grey[600],
                fontFamily: theme.typography.fontFamily,
                fontSize: 12,
                fontWeight: 400,
                margin: 0,
                padding: 0,
              }}
            >
              {categoryA ?? ''}
            </p>
          )}
          {handleTooltipContent({
            tooltips: tooltipValuesA,
            value: valueA,
            xCategory: pointAX,
          })}
        </div>
      </div>
      {(isStacked || isComparing) && (
        <>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                background: isComparing
                  ? `repeating-linear-gradient(
                    -45deg,
                    ${colorB},
                    ${colorB} 3px,
                    ${lighten(colorB ?? palette.colors.purple[500], 0.9)} 3px,
                    ${lighten(colorB ?? palette.colors.purple[500], 0.9)} 6px
                  )`
                  : undefined,
                backgroundColor: colorB as string,
                borderRadius: '2px',
                height: '12px',
                width: '12px',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                marginLeft: '8px',
              }}
            >
              <p
                style={{
                  color: palette.colors.grey[600],
                  fontFamily: theme.typography.fontFamily,
                  fontSize: 12,
                  fontWeight: 400,
                  margin: 0,
                  padding: 0,
                }}
              >
                {categoryB ?? ''}
              </p>
              {handleTooltipContent({
                tooltips: tooltipValuesB,
                value: valueB,
                xCategory: pointBX,
              })}
            </div>
          </div>
          {categoryC && (
            <div style={{ display: 'flex' }}>
              <span style={{ color: colorC as string }}>■</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  marginLeft: '8px',
                }}
              >
                <p
                  style={{
                    color: palette.colors.grey[600],
                    fontFamily: theme.typography.fontFamily,
                    fontSize: 12,
                    fontWeight: 400,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  {categoryC ?? ''}
                </p>
                {handleTooltipContent({
                  value: valueC,
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
  return ReactDOMServer.renderToString(tooltipComponent);
};
