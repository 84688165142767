import './Breakdown.scss';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { formatLineColor } from '../../common/chart/chartColors';
import BreakdownModal from '../breakdown-modal/BreakdownModal';
import BreakdownValue from '../breakdown-value/BreakdownValue';
import FiltersTooltip from '../filters-tooltip/FiltersTooltip';
import {
  clearSelectedBreakdownField,
  deleteBreakdownField,
  toggleBreakdownValue,
  toggleSelectAllBreakdownValues,
} from 'src/actions/breakdowns/breakdowns';
import { getAnswersData } from 'src/actions/data/data';
import { getAppliedBreakdownField } from 'src/reducers/breakdownsReducer/breakdownsReducer';
import {
  selectBreakdownValues,
  selectSelectedBreakdownValues,
} from 'src/reducers/breakdownsReducer/breakdownsReducer';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';
import setTooltipVisible from 'src/utils/setTooltipVisibility';

const Breakdown: React.FC<React.PropsWithChildren<unknown>> = () => {
  const breakdownValues = useSelector(selectBreakdownValues);
  const selectedBreakdownValues = useSelector(selectSelectedBreakdownValues);
  let isComponentMounted = true;
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const hidden = !breakdownValues.length;
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  const appliedBreakdownField = useSelector(getAppliedBreakdownField);
  const appliedBreakdownRef: React.RefObject<HTMLSpanElement> = useRef(null);
  const modalRef: React.RefObject<HTMLDivElement> = useRef(null);
  const addBreakdownBtnRef: React.RefObject<HTMLButtonElement> = useRef(null);
  const [tooltipVisibleState, setTooltipVisibleState] =
    useState<boolean>(false);

  const [isFieldOverFlowed, setIsFieldOverFlowed] = useState<boolean>(false);

  const matchedItems = breakdownValues.filter(field => {
    const fieldName = field?.toString().toLowerCase();
    if (fieldName.includes(search.toLowerCase())) {
      return true;
    }
  });

  const checkboxSelectAll = classNames('Breakdown-checkboxSelectAllContainer', {
    'Breakdown-checkboxSelectAllContainer--hidden': search !== '',
  });

  const addBreakdownButton = classNames('Breakdown-addBreakdownButton', {
    'Breakdown--AddBreakdownButtonEnabled': !appliedBreakdownField,
  });

  const selectedValuesLength = selectedBreakdownValues.length;

  const handleSearchBarOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleModalVisibility = () => {
    setModalVisibility(!isModalVisible);
    if (!isModalVisible) {
      dispatch(clearSelectedBreakdownField());
    }
  };

  const isBreakdownChecked = (value: string): boolean => {
    return selectedBreakdownValues.includes(value);
  };

  const setIcon = (type: string): string => {
    const iconDefaultClass = 'Breakdown-filterTypeIcon';
    let typeIcon = '';
    if (type === 'STRING') {
      typeIcon = 'Breakdown-stringTypeIcon';
    }
    if (type === 'INTEGER' || type === 'DOUBLE') {
      typeIcon = 'Breakdown-intTypeIcon';
    }
    if (type === 'DATE') {
      typeIcon = 'Breakdown-dateTypeIcon';
    }
    if (type === 'BOOLEAN') {
      typeIcon = 'Breakdown-booleanTypeIcon';
    }
    return iconDefaultClass + ' ' + typeIcon;
  };

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        addBreakdownBtnRef,
        modalRef,
        setModalVisibility,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);

  return (
    <div className='Breakdown-container'>
      <div className='Breakdown-header'>
        <div className='Breakdown-addBreakdownButtonContainer'>
          <button
            className={addBreakdownButton}
            disabled={appliedBreakdownField ? true : false}
            onClick={handleModalVisibility}
            ref={addBreakdownBtnRef}
          >
            Add Breakdown{' '}
            <span
              className={
                !appliedBreakdownField
                  ? 'Breakdown--SelectedAddBreakdownButtonIcon'
                  : 'Breakdown--addBreakdownButtonIcon'
              }
            />
          </button>
          {isModalVisible && (
            <BreakdownModal
              handleModalVisibility={() => handleModalVisibility()}
              reference={modalRef}
            />
          )}
        </div>
        {appliedBreakdownField && (
          <div className='Breakdown-breakdownFilter'>
            <span className={setIcon(appliedBreakdownField.field_type)} />{' '}
            <span
              onMouseEnter={() =>
                setTooltipVisible(
                  true,
                  setTooltipVisibleState,
                  setIsFieldOverFlowed,
                  appliedBreakdownRef,
                  94,
                )
              }
              onMouseLeave={() =>
                setTooltipVisible(
                  false,
                  setTooltipVisibleState,
                  setIsFieldOverFlowed,
                  appliedBreakdownRef,
                  94,
                )
              }
              ref={appliedBreakdownRef}
            >
              {isFieldOverFlowed && tooltipVisibleState && (
                <FiltersTooltip
                  isShort
                  title={appliedBreakdownField.field_name}
                />
              )}
              <p className='Breakdown-breakdownFilterType'>
                {appliedBreakdownField.field_name}
              </p>
            </span>
            <span
              className='Breakdown-deleteFilterIcon'
              onClick={() => {
                dispatch(deleteBreakdownField());
                dispatch(getAnswersData());
              }}
            />
          </div>
        )}
      </div>
      <div className='Breakdown-content'>
        <div className='Breakdown-searchBar'>
          <span className='Breakdown-searchIcon'></span>
          <input
            className='Breakdown-input'
            onChange={e => handleSearchBarOnchange(e)}
            placeholder='Search'
            value={search}
          />
        </div>
        <div className='Breakdown-checkBoxListContainer'>
          {!hidden && (
            <div
              className={checkboxSelectAll}
              onClick={() => dispatch(toggleSelectAllBreakdownValues())}
            >
              <span
                className={
                  selectedValuesLength === 15 ||
                  selectedValuesLength === breakdownValues.length
                    ? 'Breakdown--itemSelected'
                    : 'Breakdown-checkboxIcon'
                }
              ></span>
              <span className='Breakdown-selector'>
                {selectedValuesLength === 15 ||
                selectedValuesLength === breakdownValues.length
                  ? 'Deselect All'
                  : 'Select All'}
              </span>
            </div>
          )}
          {!breakdownValues.length && !appliedBreakdownField ? (
            <span className='Breakdown-noBreakdowns'>
              No breakdown selected
            </span>
          ) : matchedItems.length !== 0 ? (
            matchedItems.map((field: string, index: number) => (
              <BreakdownValue
                checked={isBreakdownChecked(field)}
                color={formatLineColor(field)}
                isFirstItem={index === 0}
                key={index}
                onClick={() => {
                  dispatch(toggleBreakdownValue(field));
                }}
                value={field}
              />
            ))
          ) : (
            <span className='Breakdown-searchNotFound'>No results</span>
          )}
        </div>
      </div>
      <div className='Breakdown-botom'>
        <p className='Breakdown-selectedItems'>
          {selectedValuesLength} of {breakdownValues.length} selected
        </p>
      </div>
    </div>
  );
};

export default Breakdown;
