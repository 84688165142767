import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { IconDownload } from '@tabler/icons-react';

import {
  IconButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { selectCurrentUser } from 'src/reducers/userReducer/userReducer';
import { useDataExportMutation } from 'src/services/dashboard-api';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';

interface TableDownloadButtonProps {
  data_export_type: string;
  requestData: Record<
    string,
    | undefined
    | number
    | string
    | boolean
    | null
    | (string | number | boolean | null)[]
  >;
}

const TableDownloadButton = ({
  data_export_type,
  requestData,
}: TableDownloadButtonProps) => {
  const user = useSelector(selectCurrentUser) || {};
  const dispatch = useDispatch();
  const theme = useTheme();
  const [mutate, { isError, isLoading, isSuccess }] = useDataExportMutation();
  const userEmail = user?.user?.user?.email;

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setGlobalToastOptions({
          title: `Please allow up to 10 minutes for the report to arrive. We will notify you by email (${userEmail}).`,
          variant: 'main',
        }),
      );
    }
  }, [isSuccess, dispatch, userEmail]);

  useEffect(() => {
    if (isError) {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 5000,
          title: 'Export to csv failed',
          variant: 'danger',
        }),
      );
    }
  }, [isError, dispatch]);

  const handleDownload = () => {
    mutate({
      body: {
        data_export_type,
        ...requestData,
      },
    });
  };

  return (
    <Tooltip tooltipContent='Export CSV'>
      <IconButton
        aria-label='Export CSV'
        disabled={isLoading}
        isLoading={isLoading}
        onClick={handleDownload}
        variant='ghost'
      >
        <IconDownload color={theme.palette.primary.main} />
      </IconButton>
    </Tooltip>
  );
};

export default TableDownloadButton;
