import { ToastNotificationState } from './types';
import { ToastNotificationActions } from 'src/actions/actionTypes';
import type { RootState } from 'src/store/rootReducer';

interface FetchAction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  type: string; //TODO: @gigi define this
}

export const initialState: ToastNotificationState = {
  toastType: '',
};

export default function toastNotificationReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === ToastNotificationActions.SET_TOAST_NOTIFICATION) {
    return { ...state, toastType: action.payload };
  }

  return state;
}

// SELECTORS

export const selectToastType = (
  state: RootState,
): ToastNotificationState['toastType'] => state.toastNotification.toastType;
