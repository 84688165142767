import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Turnstile } from '@marsidev/react-turnstile';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { helpdeskOptions, signUpText } from './constants';
import { handleSelfSignUp } from './helpers';
import SignUpSuccess from './SignUpSuccess';
import { CreateOrgState } from './types';
import LandingPageLayout from 'src/components/reusable-components/landing-page-layout';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('This field is required'),
  firstName: Yup.string().required('This field is required'),
  helpdesk: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  orgName: Yup.string().required('This field is required'),
});

const SignUp: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { palette } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  const initialValues: CreateOrgState = {
    email: '',
    firstName: '',
    helpdesk: '',
    lastName: '',
    orgName: '',
    token: '',
  };

  const handleSubmit = async (form: CreateOrgState) => {
    setIsLoading(true);
    setError('');
    try {
      await handleSelfSignUp(form);
      setIsSuccess(true);
    } catch (error) {
      setError('Something went wrong');
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <LandingPageLayout testId='success-container'>
        <Container>
          <SignUpSuccess />
        </Container>
      </LandingPageLayout>
    );
  }

  return (
    <LandingPageLayout testId='signup-container'>
      <Container>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Box mb='16px'>
                  <Typography variant='font32'>{signUpText.title}</Typography>
                </Box>
                <TextField
                  aria-label='First Name'
                  error={(touched.firstName && errors.firstName) || false}
                  name='firstName'
                  onChange={(...args) => {
                    handleChange(...args);
                  }}
                  placeholder={signUpText.firstName}
                  type='text'
                  value={values.firstName}
                />
                <TextField
                  aria-label='Last Name'
                  error={(touched.lastName && errors.lastName) || false}
                  name='lastName'
                  onChange={(...args) => {
                    handleChange(...args);
                  }}
                  placeholder={signUpText.lastName}
                  type='text'
                  value={values.lastName}
                />

                <TextField
                  aria-label='Company Name'
                  error={(touched.orgName && errors.orgName) || false}
                  name='orgName'
                  onChange={(...args) => {
                    handleChange(...args);
                  }}
                  placeholder={signUpText.orgName}
                  type='text'
                  value={values.orgName}
                />
                <TextField
                  aria-label='Email'
                  error={(touched.email && errors.email) || false}
                  name='email'
                  onChange={(...args) => {
                    handleChange(...args);
                  }}
                  placeholder={signUpText.email}
                  type='text'
                  value={values.email}
                />
                <SelectDropdown
                  aria-label='Helpdesk'
                  error={(touched.helpdesk && errors.helpdesk) || false}
                  id='helpdesk'
                  inputProps={{ name: 'helpdesk' }}
                  onChange={(...args) => handleChange(...args)}
                  options={helpdeskOptions}
                  placeholder={signUpText.helpdesk}
                  value={values.helpdesk}
                />
                <TurnstileContainer>
                  <Turnstile
                    onError={() => setError('Could not validate you')}
                    onSuccess={token => setFieldValue('token', token)}
                    options={{ theme: 'light' }}
                    siteKey={TURNSTILE_SITEKEY}
                  />
                </TurnstileContainer>
                <ButtonContainer>
                  {error && (
                    <Box>
                      <Typography
                        color={palette.colors.red[500]}
                        variant='font16'
                      >
                        {error}
                      </Typography>
                    </Box>
                  )}
                  <Button
                    disabled={values.token === ''}
                    fullWidth
                    isLoading={isLoading}
                    size='large'
                    type='submit'
                    variant='main'
                  >
                    {signUpText.submit}
                  </Button>
                </ButtonContainer>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </LandingPageLayout>
  );
};

export default SignUp;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TurnstileContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
