import { useDrop } from 'react-dnd';
import { getIncomers, Node, NodeProps, useReactFlow } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/system';

import { Typography } from '@forethought-technologies/forethought-elements';
import useNodeDrop from '../../../hooks/useNodeDrop';
import NodeWrapper from '../../NodeWrapper';
import { useIsPlacerHolderNodeLeaf } from 'src/components/forethought-flow/hooks/useIsPlaceholderNodeLeaf';
import { DragItem } from 'src/components/forethought-flow/types';

interface DropzoneNodeProps extends NodeProps {
  onDrop?: (
    item: DragItem,
    insertNodeId: string,
    parentStep: Node,
    parentTransitionId?: string,
  ) => void;
}

const DropzoneNode = (props: DropzoneNodeProps) => {
  const handleNodeDrop = useNodeDrop(props.id);
  const { getEdges, getNode, getNodes } = useReactFlow();
  const isPlacerHolderNodeLeaf = useIsPlacerHolderNodeLeaf(props.id);

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: ['action', 'response', 'rule'],
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
      drop(item: DragItem, monitor) {
        const insertNodeType = monitor.getItemType();
        const node = getNode(props.id);

        if (!insertNodeType || !node) {
          return;
        }
        // create id for the new node
        const insertNodeId = uuidv4();
        const parentStep = getIncomers(node, getNodes(), getEdges())[0];
        const { parentTransitionId } = node.data;

        if (props.onDrop) {
          props.onDrop(item, insertNodeId, parentStep, parentTransitionId);
        }
        handleNodeDrop(insertNodeType as string, insertNodeId, item);
      },
    }),
    [handleNodeDrop, props.onDrop],
  );

  return (
    <NodeWrapper
      isActive={isOver}
      isPlaceholder
      isVerticalMenuVisible={!isPlacerHolderNodeLeaf}
      {...props}
    >
      <Box padding='16px' ref={dropRef} textAlign='center'>
        <Typography variant='font14Bold'>
          Drag and drop a new step here
        </Typography>
      </Box>
    </NodeWrapper>
  );
};

export default DropzoneNode;
