import React from 'react';
import { IconInfoCircle } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';

interface InsightsTooltipProps {
  color?: string;
  size?: number;
  tooltip: string;
}

const InsightTooltip: React.FC<
  React.PropsWithChildren<InsightsTooltipProps>
> = ({ color = 'black', size = 16, tooltip }) => {
  return (
    <Tooltip tooltipContent={tooltip}>
      <IconInfoCircle color={color} size={size} />
    </Tooltip>
  );
};

export default InsightTooltip;
