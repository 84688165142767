import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { IntentDrawerType } from 'src/slices/ui/types';
import { openGlobalIntentDrawer } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

/**
 * Special case for demo purposes - load a create intent drawer in a new tab
 * - check query params for `?initIntentPhrase=...`
 * - if present, open drawer with that phrase prefilled
 * - remove 'initIntentPhrase' from query params
 */
export default function useDemoNewTab(intentDrawerType: IntentDrawerType) {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();

  const userRole = useSelector(selectUserRole);

  useEffect(() => {
    const qParams = new URLSearchParams(search);
    const phrase = qParams.get('initialIntentPhrase');

    if (!phrase) {
      return;
    }

    dispatch(
      openGlobalIntentDrawer({
        initialPhrases: [
          {
            is_modifiable: true,
            phrase_id: uuid(),
            phrase_text: phrase,
          },
        ],
        type: intentDrawerType,
      }),
    );

    qParams.delete('initialIntentPhrase');
    navigate(
      {
        search: qParams.toString(),
      },
      { replace: true },
    );
  }, [dispatch, intentDrawerType, navigate, search, userRole]);
}
