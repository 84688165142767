import { ReactSVG } from 'react-svg';
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { useWorkflowBuilderMode } from '../hooks';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import classicIconSrc from 'src/assets/images/solve-classic-icon.svg';

export const WidgetModeButtonGroup = () => {
  const [mode, setMode] = useWorkflowBuilderMode();

  return (
    <ToggleButtonGroup
      exclusive
      onChange={(_, newAlignment) => {
        if (!newAlignment) {
          return;
        }
        setMode(newAlignment);
      }}
      orientation='vertical'
      sx={theme => ({
        '& .Mui-selected': {
          '&:hover': {
            backgroundColor: theme.palette.colors.purple[100] + ' !important',
          },
          background: theme.palette.background.default + ' !important',
          boxShadow: theme.shadows[1],
          color: theme.palette.text.primary,
        },
        backgroundColor: theme.palette.colors.slate[200],
      })}
      value={mode}
    >
      <Tooltip placement='right' tooltipContent='Classic'>
        <StyledToggleButton aria-label='Classic' value='classic'>
          <SVG src={classicIconSrc} />
        </StyledToggleButton>
      </Tooltip>
      <Tooltip placement='right' tooltipContent='Autoflow'>
        <StyledToggleButton aria-label='Autoflow' value='autoflow'>
          <SVG src={aaBadgeSrc} />
        </StyledToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};

const StyledToggleButton = styled(ToggleButton)`
  border-radius: 4px !important;
  background-color: ${props => props.theme.palette.colors.slate[200]};
  border: none;
  transition: ${props =>
    props.theme.transitions.create(['background-color'], { duration: 100 })};

  &:not(:hover):not(.Mui-selected) stop {
    stop-color: ${props => props.theme.palette.colors.grey[600]};
  }
`;

const SVG = styled(ReactSVG)`
  width: 28px;
  height: 28px;

  > div {
    width: 100%;
    height: 100%;
  }
`;
