import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import { FetchAction } from '../../types/types';
import { MacroControlsActions } from '../actionTypes';
import { setToastType } from '../toastNotification/toastNotification';
import { setLoading } from 'src/actions/data/data';
import { MacroDataDict, MacroTableDataDict } from 'src/services/apiInterfaces';
import { getMacrosAPI, updateMacroAPI } from 'src/services/apiV1';
import type { RootState } from 'src/store/rootReducer';
import { TypesOfToast } from 'src/utils/enums';

export const getMacroControlsDataSuccess = createAction<{
  currentView: string;
  data: MacroTableDataDict | [];
}>(MacroControlsActions.GET_MACRO_CONTROLS_DATA);

export const updateMacroDataSuccess = createAction<MacroDataDict>(
  MacroControlsActions.UPDATE_MACRO_DATA,
);

export const setSelectedMacro = createAction<MacroDataDict | null>(
  MacroControlsActions.SET_SELECTED_MACRO,
);

export const setMacroFieldtoUpdate = createAction<{
  [key: string]: string | boolean | number | string[];
}>(MacroControlsActions.SET_MACRO_FIELD_TO_UPDATE);

export const getMacroControlsDataFailure = createAction<string>(
  MacroControlsActions.GET_MACRO_CONTROLS_DATA_FAILURE,
);

export const setFilterdMacrosData = createAction<{
  currentView: string;
  search: string;
}>(MacroControlsActions.SET_FILTERED_MACROS_DATA);

export const getMacroControlsData: Function =
  () =>
  (dispatch: Dispatch, getState: Function): Promise<void | FetchAction> => {
    const state: RootState = getState();
    const { macroControlsViewMode } = state.pageSettings;
    dispatch(setLoading(true));
    return getMacrosAPI()
      .then((data: MacroTableDataDict) => {
        dispatch(
          getMacroControlsDataSuccess({
            currentView: macroControlsViewMode,
            data,
          }),
        );
        dispatch(setLoading(false));
      })
      .catch((err: Error) => {
        dispatch(getMacroControlsDataFailure(err.message));
        dispatch(setLoading(false));
      });
  };

export const updateMacroData: Function =
  () =>
  (
    dispatch: Dispatch,
    getState: Function,
  ): Promise<void | FetchAction | string | MacroDataDict> => {
    const state: RootState = getState();
    const { macroFieldtoUpdate } = state.macroControlsData;
    return updateMacroAPI()
      .then((data: { updated_macro: MacroDataDict }) => {
        const { updated_macro } = data;
        dispatch(updateMacroDataSuccess(updated_macro));
        if (macroFieldtoUpdate.hasOwnProperty('is_active')) {
          // @ts-expect-error legacy code with untyped state
          if (macroFieldtoUpdate['is_active']) {
            dispatch(setToastType(TypesOfToast.MACRO_UPDATE_SUCCESS));
            // @ts-expect-error legacy code with untyped state
          } else if (!macroFieldtoUpdate['is_active']) {
            dispatch(setToastType(TypesOfToast.MACRO_DEACTIVATION_SUCCESS));
          }
        }
      })
      .catch((res: { error: string; payload?: MacroDataDict }) => {
        const { error, payload } = res;
        if (payload) {
          dispatch(setToastType(TypesOfToast.MACRO_UPDATE_ERROR));
          dispatch(updateMacroDataSuccess(payload));
        } else {
          dispatch(setToastType(TypesOfToast.MACRO_UPDATE_ERROR));
          return error;
        }
      });
  };
