import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { User } from 'src/services/apiInterfaces';
import { UserRoles } from 'src/utils/enums';
import { getFullName } from 'src/utils/sessionUtils';

const ADMIN_ROLES = [UserRoles.ROLE_ADMIN, UserRoles.ROLE_SUPER_ADMIN];

export function useIsCurrentUserAdmin() {
  const currentUserRole = useSelector(selectUserRole);

  return useMemo(() => {
    return ADMIN_ROLES.includes(currentUserRole);
  }, [currentUserRole]);
}

export function useFilteredUsers(users: User[] | null, filterQuery: string) {
  return useMemo(() => {
    if (!users) {
      return [];
    }
    return users.filter(user => {
      const fullName = getFullName(user);
      return (
        fullName?.toLowerCase().includes(filterQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(filterQuery.toLowerCase())
      );
    });
  }, [filterQuery, users]);
}
