import { FetchAction } from '../../types/types';
import { SearchState } from './types';
import {
  SEARCH_LOADING,
  UPDATE_QUERY_STRING,
  UPDATE_SEARCH_DATA,
  UPDATE_SELECTED_RESULT,
} from 'src/actions/actionTypes';

export const initialState: SearchState = {
  currentSelectedResult: '',
  finalPageReached: false,
  loading: false,
  offset: 0,
  query: '',
  search: [],
  searchId: '',
};

export default function searchReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === UPDATE_QUERY_STRING) {
    return Object.assign({}, state, {
      query: action.query,
    });
  } else if (action.type === UPDATE_SEARCH_DATA) {
    let search;
    if (state.query === action.query) {
      search = [];
    } else {
      search = [];
    }
    while (action.search.length) {
      search.push(action.search.splice(0, 3));
    }
    return Object.assign({}, state, {
      error: null,
      finalPageReached: true,
      search: search,
      searchId: action.searchId,
    });
  } else if (action.type === SEARCH_LOADING) {
    return Object.assign({}, state, {
      loading: action.loading,
    });
  } else if (action.type === UPDATE_SELECTED_RESULT) {
    return Object.assign({}, state, {
      currentSelectedResult: action.currentSelectedResult,
    });
  }
  return state;
}
