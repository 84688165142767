import {
  KustomerHandoffCustomAttribute,
  KustomerResourceCustomAttribute,
} from 'src/types/workflowBuilderAPITypes';

const VALID_NUMBER_ERROR = 'The value should be a number or a context variable';

export const cvRegex = /^\{\{.*\}\}$/;

export const getKustomerCustomAttributesData = (
  customAttributes: KustomerHandoffCustomAttribute[],
  kustomerAttributes: KustomerResourceCustomAttribute[] | undefined,
) => {
  const customAttributeWithTypes = addTypeToKustomerCustomAttribute(
    customAttributes,
    kustomerAttributes,
  );

  return (
    customAttributeWithTypes?.map(attribute => {
      const value = attribute.value;
      const isCv = cvRegex.test(value.toString());
      const isRealNumber = typeof value === 'number';
      const shouldAddNumberError =
        attribute.type === 'number' && !isCv && !isRealNumber;

      return {
        error: shouldAddNumberError ? VALID_NUMBER_ERROR : '',
        id: attribute.field,
        type: attribute.type,
      };
    }) || []
  );
};

export const addTypeToKustomerCustomAttribute = (
  customAttributes: KustomerHandoffCustomAttribute[],
  kustomerAttributes: KustomerResourceCustomAttribute[] | undefined,
) =>
  customAttributes.map(attribute => ({
    ...attribute,
    type:
      kustomerAttributes?.find(
        kustomerAttribute => kustomerAttribute.id === attribute.field,
      )?.type || '',
  }));

export const convertToNumber = (value: string) => {
  const number = Number(value);
  return isNaN(number) ? value : number;
};
