import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { DISCOVER_SHARED_PARAM_NAMES } from 'src/constants/discover';

export const useScrollIntoArticleView = (numOfArticles = 0) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { search } = useLocation();

  useEffect(() => {
    if (!numOfArticles) {
      return;
    }

    const params = new URLSearchParams(search);
    if (params.get(DISCOVER_SHARED_PARAM_NAMES.TAB) === 'Generated articles') {
      ref.current?.scrollIntoView(true);
    }
  }, [search, numOfArticles]);

  return ref;
};
