import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { getWorkflowValueFromSearch } from '../../autonomous-agent/helpers';
import { TEMPLATE_CLONE_TOAST_OPTIONS } from '../../constants';
import {
  useGetAutoflowWorkflowOrAutoflowTemplate,
  useGetHandoffConfigurationForWorkflow,
  useGetIsTemplateIntentWorkflow,
  useWorkflowBuilderMode,
} from '../../hooks';
import {
  selectCanvasWorkflowBuilder,
  selectVersion,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useCustomizeAutoflowTemplateMutation } from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import {
  CanvasWorkflowBuilderState,
  selectIsWorkflowActive,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  setGlobalToastOptions,
  setGlobalWorkflowBuilderOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { minimumPhrasesRequired } from 'src/utils/constants';
import { CommonIntentWorkflowType, Routes } from 'src/utils/enums';

interface WorkflowPublishButtonProps {
  hasUnsavedChanges: boolean;
  validationMessage?: string | false;
}

const useGetIsIntentPhrasesRequired = (): boolean => {
  const { search } = useLocation();
  const isHandoffWorkflow =
    useGetHandoffConfigurationForWorkflow() !== undefined;

  const intentId = getWorkflowValueFromSearch(search, 'intentId');

  const isGeneralHandoffWorkflow =
    intentId === CommonIntentWorkflowType.GENERAL_HANDOFF;

  const isKnowledgeRetrieval =
    intentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const isFallbackHandoffWorkflow =
    intentId === CommonIntentWorkflowType.FALLBACK;

  return (
    !isHandoffWorkflow &&
    !isGeneralHandoffWorkflow &&
    !isKnowledgeRetrieval &&
    !isFallbackHandoffWorkflow
  );
};

const WorkflowPublishButton = ({
  hasUnsavedChanges,
  validationMessage,
}: WorkflowPublishButtonProps) => {
  const dispatch = useAppDispatch();

  const { search } = useLocation();

  const [mode] = useWorkflowBuilderMode();
  const navigate = useNavigate();
  const isTemplateAutoflowWorkflow = useGetIsTemplateIntentWorkflow();
  const [customizeAutoflowTemplate] = useCustomizeAutoflowTemplateMutation();
  const isWorkflowActive = useSelector(selectIsWorkflowActive);
  const version = useSelector(selectVersion);

  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const isIntentPhrasesRequired = useGetIsIntentPhrasesRequired();

  const intentWorkflowId = getWorkflowValueFromSearch(search, 'workflowId');
  const { autoflowWorkflow } =
    useGetAutoflowWorkflowOrAutoflowTemplate(intentWorkflowId);

  const { status } = autoflowWorkflow ?? {};

  const isAutoflowWorkflow = status === 'enabled';

  const isNotEnoughPhrases =
    (canvasData.intent?.phrases || []).length < minimumPhrasesRequired;

  const isPublishing =
    (mode === 'classic' && version <= 2) ||
    // TODO when we add versioning we will change this
    (mode === 'autoflow' && !isWorkflowActive);

  const isWorkflowEmpty = useMemo(
    () => !Object.keys(canvasData.steps).length,
    [canvasData.steps],
  );

  const isDisabled =
    (isIntentPhrasesRequired && isNotEnoughPhrases && isPublishing) ||
    canvasData.loading ||
    Boolean(validationMessage);

  const isTooltipVisible =
    isDisabled ||
    (mode === 'classic' && isWorkflowEmpty) ||
    (isAutoflowWorkflow && mode === 'classic' && isWorkflowEmpty);

  if (isTemplateAutoflowWorkflow) {
    return (
      <Button
        onClick={async () => {
          const response = await customizeAutoflowTemplate(intentWorkflowId);

          if (!('data' in response)) {
            return;
          }

          const { data } = response;
          const baseParams = new URLSearchParams({
            mode: 'autoflow',
            workflowId: data.intent_workflow_id,
          });

          dispatch(setGlobalToastOptions(TEMPLATE_CLONE_TOAST_OPTIONS));

          navigate(`${Routes.WORKFLOW_BUILDER_EDIT}?${baseParams}`);
        }}
        variant='main'
      >
        Clone template
      </Button>
    );
  }

  const getTooltipMessage = () => {
    if (validationMessage) {
      return validationMessage;
    }

    if (isDisabled) {
      return `Active intents require a minimum of ${minimumPhrasesRequired} training phrases to ensure sufficient coverage.`;
    }

    if (mode === 'classic' && isWorkflowEmpty) {
      return 'Add steps to publish';
    }

    if (isAutoflowWorkflow && mode === 'classic' && isWorkflowEmpty) {
      return 'The classic workflow is empty, add steps to publish';
    }

    return '';
  };

  const getPublishButton = () => {
    let button: React.ReactElement | null = null;

    if (isAutoflowWorkflow && mode === 'classic') {
      button = (
        <Button
          disabled={isWorkflowEmpty || isDisabled}
          onClick={() => {
            dispatch(
              setGlobalWorkflowBuilderOptions({
                dialogType: 'switch-aa-to-classic',
              }),
            );
          }}
          variant='main'
        >
          Replace and publish
        </Button>
      );
    }
    // initial "Publish" state will only be shown on regular intent workflows and not
    // handoff, general handoff or knowledge retrieval
    else if (isPublishing && isIntentPhrasesRequired) {
      button = (
        <Button
          disabled={isDisabled || (isWorkflowEmpty && mode === 'classic')}
          onClick={() => {
            dispatch(
              setGlobalWorkflowBuilderOptions({
                dialogType:
                  mode === 'classic' ? 'publish-classic' : 'publish-aa',
              }),
            );
          }}
          variant='main'
        >
          Publish
        </Button>
      );
    } else if (!isAutoflowWorkflow && mode === 'autoflow') {
      button = (
        <Button
          onClick={() => {
            dispatch(
              setGlobalWorkflowBuilderOptions({
                dialogType: 'switch-classic-to-aa',
              }),
            );
          }}
          variant='main'
        >
          Replace and publish
        </Button>
      );
    } else {
      button = (
        <Button
          disabled={
            !hasUnsavedChanges ||
            isDisabled ||
            (!isAutoflowWorkflow && isWorkflowEmpty)
          }
          onClick={() => {
            dispatch(
              setGlobalWorkflowBuilderOptions({
                dialogType:
                  mode === 'classic'
                    ? 'update-publish-classic'
                    : 'update-publish-aa',
              }),
            );
          }}
          variant='main'
        >
          Publish changes
        </Button>
      );
    }

    return button && isTooltipVisible ? (
      <Tooltip tooltipContent={getTooltipMessage()}>{button}</Tooltip>
    ) : (
      button
    );
  };

  return getPublishButton();
};

export default WorkflowPublishButton;
