import { Box } from '@mui/material';

import { autoflowColors } from '../constants';
import { useGetColorMode } from '../hooks';
import { AutoflowPreviewLogProps } from 'src/slices/workflow-preview/types';

export const AutoflowPreviewLog = ({
  previewLog,
}: {
  previewLog: AutoflowPreviewLogProps;
}) => {
  const colorMode = useGetColorMode();

  return (
    <Box display='flex' flexDirection='column'>
      {previewLog.user_query && (
        <AutoflowMessage
          message={previewLog.user_query}
          messageColor={autoflowColors[colorMode].userQuery}
          prefix={'User: '}
        />
      )}
      {previewLog.agent_thoughts.length > 0 && (
        <AutoflowMessage
          message={previewLog.agent_thoughts.join('\n')}
          messageColor={autoflowColors[colorMode].agentThoughts}
          prefix={'Agent thought: \n'}
        />
      )}
      {previewLog.agent_response && (
        <AutoflowMessage
          message={previewLog.agent_response}
          messageColor={autoflowColors[colorMode].agentResponse}
          prefix='Agent: '
        />
      )}
    </Box>
  );
};

const AutoflowMessage = ({
  message,
  messageColor,
  prefix,
}: {
  message: string;
  messageColor: string;
  prefix: string;
}) => {
  const colorMode = useGetColorMode();
  return (
    <span>
      <span style={{ color: autoflowColors[colorMode].default }}>{prefix}</span>
      <span
        style={{
          color: messageColor,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        {message}
      </span>
    </span>
  );
};
