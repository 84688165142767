import './BreakdownValue.scss';

import React, { useRef, useState } from 'react';

import Checkbox from '../breakdown/Checkbox';
import FiltersTooltip from '../filters-tooltip/FiltersTooltip';

interface BrekdownValueProps {
  checked: boolean;
  color: string;
  isFirstItem: boolean;
  onClick: Function;
  value: string;
}

const BrekdownValue: React.FC<React.PropsWithChildren<BrekdownValueProps>> = (
  props: BrekdownValueProps,
) => {
  const { checked, color, isFirstItem, onClick, value } = props;
  const [isValueOverFlowed, setIsValueOverFlowed] = useState<boolean>(false);
  const [valueTooltipVisibility, setValueTooltipVisibility] =
    useState<boolean>(false);
  const breakdownValueRef: React.RefObject<HTMLSpanElement> = useRef(null);
  const setValueTooltipVisible = (visible: boolean) => {
    setValueTooltipVisibility(visible);
    const offset = breakdownValueRef.current
      ? breakdownValueRef.current.offsetWidth
      : 0;
    if (offset > 115) {
      setIsValueOverFlowed(true);
    } else {
      setIsValueOverFlowed(false);
    }
  };
  return (
    <div className='BreakdownValue-Container' onClick={() => onClick()}>
      <Checkbox checked={checked} color={color} />
      <span
        className='BreakdownValue-selector'
        onMouseEnter={() => setValueTooltipVisible(true)}
        onMouseLeave={() => setValueTooltipVisible(false)}
        ref={breakdownValueRef}
      >
        {isValueOverFlowed && valueTooltipVisibility && (
          <FiltersTooltip
            isFirstItem={isFirstItem}
            isShort
            isTitle
            title={value}
          />
        )}
        {value}
      </span>
    </div>
  );
};

export default BrekdownValue;
