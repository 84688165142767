import { memo, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Theme } from '@mui/material';

import { HistogramFilters, SelectedHistogramFilter } from '../../types';
import HistogramGraph from './HistogramGraph';
import RangeInput from './RangeInput';
import RangeSlider from './RangeSlider';
import isEqual from 'lodash/fp/isEqual';
import { RANGE_SIZE_MULTIPLIER } from 'src/constants/discover';
import {
  DiscoverAllTopicsResponse,
  DiscoverTopicAggregatedMetricType,
} from 'src/reducers/discoverReducer/types';
import {
  generateHistogramData,
  getMetricLabel,
} from 'src/utils/discover/helpers';

interface DiscoverHistogramChartProps {
  data: DiscoverAllTopicsResponse;
  handleOnClose: () => void;
  histogramFilters: HistogramFilters;
  selectedHistogramFilter: SelectedHistogramFilter;
  setHistogramFilters: (value: HistogramFilters) => void;
}

const DiscoverHistogramChart = ({
  data,
  handleOnClose,
  histogramFilters,
  selectedHistogramFilter,
  setHistogramFilters,
}: DiscoverHistogramChartProps) => {
  const { metricType, type, value } = selectedHistogramFilter;
  const generatedData = useMemo(() => {
    return generateHistogramData(data.breakdown, metricType, type);
  }, [data.breakdown, metricType, type]);

  // increase the range values by fixed amount for smoother slider and also to get values between buckets
  const range = useMemo(
    () => [
      generatedData.min * RANGE_SIZE_MULTIPLIER,
      generatedData.max * RANGE_SIZE_MULTIPLIER,
    ],
    [generatedData],
  );

  const [filterRange, setFilterRange] = useState(
    value && value.length === 2
      ? value.map(range => range * RANGE_SIZE_MULTIPLIER)
      : range,
  );
  const responseData = generatedData.range;
  // create an array of values corresponding to y-axis
  const topicBucketCount = responseData.map(({ doc_count }) => doc_count ?? 0);

  const handleRealInputRangeConversion = () => {
    return [...filterRange].map(item => {
      const transformedValue = Number(
        (item / RANGE_SIZE_MULTIPLIER).toFixed(2),
      );
      /**
       * Due to conversion of ranges, the max range value can pass the
       * range max limit from the tickets
       *
       * For metric first_contact_resolution force any value above 100% to be 100%
       */
      if (metricType === 'first_contact_resolution' && transformedValue > 100) {
        return 100;
      }
      return transformedValue;
    });
  };

  const histogramFilterLabel = () => {
    if (metricType) {
      switch (type) {
        case 'value_changed':
          return `${getMetricLabel(metricType)} percent change`;
        case 'value':
          return getMetricLabel(metricType) ?? '';
        case 'value_deviance':
          return `${getMetricLabel(metricType)} deviance`;
        default:
          return '';
      }
    }
  };

  const label = histogramFilterLabel();
  const inputRange = handleRealInputRangeConversion();

  const onChangeCallBack = (sliderValues: ReadonlyArray<number>) => {
    setFilterRange([...sliderValues]);
  };
  const handleInputChange = (sliderValues: ReadonlyArray<number>) => {
    setFilterRange(sliderValues.map(item => item * RANGE_SIZE_MULTIPLIER));
  };

  const handleOnClickApply = () => {
    handleOnClose();
    if (metricType && type) {
      const updatedObj = histogramFilters[metricType];
      const updatedHistogramFilters = {
        ...histogramFilters,
        [metricType]: {
          ...updatedObj,
          [type]: inputRange,
        },
      };
      setHistogramFilters(updatedHistogramFilters);
    }
  };

  const handleReset = () => {
    if (metricType && type) {
      const updatedHistogramFilters = { ...histogramFilters };
      const filter =
        updatedHistogramFilters[
          metricType as DiscoverTopicAggregatedMetricType
        ];
      if (filter) {
        delete filter[type];
        setHistogramFilters(updatedHistogramFilters);
        setFilterRange(range);
        handleOnClose();
      }
    }
  };

  return (
    <Container>
      <Box paddingX={2}>
        <HistogramGraph
          data={topicBucketCount}
          leftEdgeSelection={filterRange[0]}
          maxRangeValue={range[1]}
          rightEdgeSelection={filterRange[1]}
        />
        <RangeSlider
          maxRange={range[1]}
          minRange={range[0]}
          onChange={onChangeCallBack}
          values={filterRange}
        />
      </Box>
      <Box height={20} />
      <RangeInput
        handleOnClickApply={handleOnClickApply}
        handleReset={handleReset}
        label={label ?? ''}
        leftEdgeNormalizedSelection={inputRange[0]}
        onChange={handleInputChange}
        removeDecimal={metricType === 'volume'}
        rightEdgeNormalizedSelection={inputRange[1]}
      />
    </Container>
  );
};

const Container = styled('div')(({ theme }: { theme?: Theme }) => ({
  backgroundColor: theme?.palette.colors.white,
  border: `1px solid ${theme?.palette.colors.grey[200]}`,
  borderRadius: 4,
  boxShadow:
    '0px 0px 1px rgba(0, 12, 32, 0.04), 2px 5px 8px rgba(3, 17, 38, 0.107135)',
  padding: 20,
  width: 635,
}));

const customComparator = (
  prevProps: DiscoverHistogramChartProps,
  nextProps: DiscoverHistogramChartProps,
) => {
  return isEqual(prevProps, nextProps);
};

const MemoizedDiscoverHistogramChart = memo(
  DiscoverHistogramChart,
  customComparator,
);

export default MemoizedDiscoverHistogramChart;
