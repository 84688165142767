import { useEffect, useState } from 'react';
import { useDragDropManager, XYCoord } from 'react-dnd';
import { Edge, useEdges } from 'reactflow';

// max distance in pixels to be considered a drop target - roughly the width of a drag item
const MAX_DISTANCE = 340;

// given one set of xy coordinates and a list of domrects, return the index of the nearest dom rect
function getNearestEdgeIdx({ x, y }: XYCoord, rects: DOMRect[]) {
  const distances = rects.map(({ x: rectX, y: rectY }) => {
    const a = x - rectX;
    const b = y - rectY;
    return Math.sqrt(a * a + b * b);
  });
  const minDistance = Math.min(...distances);

  if (minDistance > MAX_DISTANCE) {
    return -1;
  }

  return distances.indexOf(minDistance);
}

// get the center xy for DOMRect
function getCenter({ height, width, x, y }: DOMRect) {
  return { x: x + width / 2, y: y + height / 2 };
}

/**
 * Returns the nearest edge to the current drag position
 */
export function useNearestEdge(): Edge | null {
  const [edgeIdx, setEdgeIdx] = useState(-1);
  const edges = useEdges();
  const monitor = useDragDropManager().getMonitor();

  useEffect(
    () =>
      // reset edgeIdx when dragging stops
      monitor.subscribeToStateChange(() => {
        const isDragging = monitor.isDragging();
        if (!isDragging) {
          setEdgeIdx(-1);
        }
      }),
    [monitor],
  );

  useEffect(() => {
    // subscribe to offset changes and update edgeIdx
    return monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getClientOffset();
      if (!offset) {
        return;
      }

      // Since this code uses DOM directly via `document.getElementById()`,
      // it has to be inside this listener.
      // If you move it to the component level, it will break, because `edges`
      // and the actual DOM can be out of sync during the component rendering.
      const edgeLocations = edges.map(({ id }) => {
        const rect = document.getElementById(id)?.getBoundingClientRect();
        return rect ? getCenter(rect) : null;
      });

      const targets = edgeLocations.filter(Boolean) as DOMRect[];
      const idx = getNearestEdgeIdx(offset, targets);
      setEdgeIdx(idx);
    });
  }, [edges, monitor]);

  return edges[edgeIdx] ?? null;
}
