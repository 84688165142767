import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Button,
  SelectDropdown,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { convertIntentFiltersToDictForEmail } from '../utils';
import { getDescriptionFromIntent, getSubjectFromIntent } from './utils';
import ContextVariableInput from 'src/components/context-variable-input';
import Spinner from 'src/components/spinner';
import {
  JourneyMapTestField,
  useGetConversationLanguageCV,
} from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-journey-map/test-journey-modal/hooks';
import { selectUser } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { createEmailPreviewTemplate } from 'src/services/email-builder/emailBuilderApi';
import { useGetIntentQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import {
  selectPreviewIntentFilters,
  setEmailPreviewStage,
  setEmailPreviewState,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

interface EmailFormProps {
  enabledLanguages: string[];
  testContextVariables: ContextVariable[] | null;
  testFields: JourneyMapTestField[];
}

export const EmailForm = ({
  enabledLanguages,
  testContextVariables,
  testFields,
}: EmailFormProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const location = useLocation();
  const { user } = useSelector(selectUser);
  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');

  const currentIntentFilters = useSelector(selectPreviewIntentFilters);
  const { data: intent, isLoading } = useGetIntentQuery(
    { intentDefinitionId: previewFromIntentId || '' },
    { skip: !previewFromIntentId },
  );

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailTranslationEnabled = featureFlags.includes(
    'solve_email_translation_enabled',
  );

  const intialCustomValues = testFields.reduce<Record<string, string>>(
    (acc, curr) => {
      acc[curr.id] = '';
      return acc;
    },
    {},
  );

  const { context_variable_id: conversationLanguageCvId = '' } =
    useGetConversationLanguageCV() || {};

  const initialCVValues = (testContextVariables || []).reduce<
    Record<string, string>
  >((acc, curr) => {
    acc[curr.context_variable_id] = '';
    return acc;
  }, {});

  const initialLanguageValues =
    conversationLanguageCvId && isSolveEmailTranslationEnabled
      ? { [conversationLanguageCvId]: '' }
      : null;

  if (isLoading || !intent) {
    return <Spinner />;
  }

  const initialValues: Record<string, string> = {
    emailBody: getDescriptionFromIntent(intent),
    from: user?.email ?? '',
    subject: getSubjectFromIntent(intent),
    ...intialCustomValues,
    ...initialCVValues,
    ...initialLanguageValues,
  };

  const getMappedFieldValue = (formFieldValue: string) => {
    const lowerCaseValue = formFieldValue.toLowerCase();
    const isBoolean = lowerCaseValue === 'true' || lowerCaseValue === 'false';
    return isBoolean ? lowerCaseValue === 'true' : formFieldValue;
  };

  const submitEmailForm = async (formFields: Record<string, string>) => {
    const customFieldValues: Record<string, string> = {};
    const context: Record<string, string | boolean> = {};

    const { emailBody, subject, ...rest } = formFields;

    for (const [key, value] of Object.entries(rest)) {
      if (!value) {
        continue;
      }
      const field = testFields.find(field => field.id === key);
      const isContextField = !!field?.mapping_type && !!field?.mapping_data;

      if (!field) {
        // This is a context variable.
        context[key] = value;
      } else if (isContextField) {
        context[key] = getMappedFieldValue(value);
      } else {
        customFieldValues[key] = value;
      }
    }

    const intentFilters =
      convertIntentFiltersToDictForEmail(currentIntentFilters);

    try {
      const response = await createEmailPreviewTemplate({
        context,
        custom_field_values: customFieldValues,
        intent_filters_for_preview: intentFilters,
        ticket_description: emailBody,
        ticket_subject: subject,
      });

      // no response email, that means this email workflow doesn't have a response added yet
      if (!response.response_email) {
        dispatch(setEmailPreviewStage('email-template-error'));
      } else {
        dispatch(
          setEmailPreviewState({
            emailConversationId: response.conversation.conversation_id,
            responseEmail: response.response_email || '',
            stage: 'email-template',
            token: null,
          }),
        );
      }
    } catch {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 7000,
          subtitle:
            'This feature does not support some context variables and actions at this time. Please reach out to support@forethought.ai for further assistance.',
          title: 'Something went wrong',
          variant: 'danger',
        }),
      );
    }
  };

  return (
    <Box
      bgcolor='white'
      border='1px solid rgba(0, 0, 0, 0.10)'
      boxShadow='0px 12px 48px 0px rgba(0, 0, 0, 0.10)'
      display='flex'
      flexDirection='column'
      margin='auto'
      maxWidth='700px'
      padding='40px 48px 48px'
    >
      <Box display='flex' flexDirection='column' gap={1} pb='20px'>
        <Typography variant='font24'>Draft Test Email</Typography>
        <Typography color={theme.palette.colors.grey[600]} variant='font14'>
          Fill out the form below and click &apos;Next&apos; to simulate the
          email response. Note that executing this flow will not create a ticket
          in your help desk.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'scroll',
        }}
      >
        <Formik initialValues={initialValues} onSubmit={submitEmailForm}>
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            values,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <Box display='flex' flexDirection='column' gap={3} pb='20px'>
                  <TextField
                    label={
                      <TextWithTooltip
                        text='From'
                        tooltipText="Customer's email address"
                      />
                    }
                    name='from'
                    onChange={handleChange}
                    placeholder='Email'
                    type='email'
                    value={values.from}
                  />
                  <TextField
                    label={
                      <TextWithTooltip
                        text='Subject'
                        tooltipText="Customer's query title"
                      />
                    }
                    name='subject'
                    onChange={handleChange}
                    placeholder='Subject'
                    value={values.subject}
                  />
                  <TextField
                    label={
                      <TextWithTooltip
                        text='Email body'
                        tooltipText="Customer's question and issue details"
                      />
                    }
                    multiline
                    name='emailBody'
                    onChange={handleChange}
                    placeholder='Email body'
                    rows={5}
                    value={values.emailBody}
                  />
                  {isSolveEmailTranslationEnabled && (
                    <Box display='flex' flexDirection='column' gap={1}>
                      <TextWithTooltip
                        text='Multilingual'
                        tooltipText='Select a language to test your multilingual content'
                      />
                      <SelectDropdown
                        disabled={!enabledLanguages.length}
                        id='language-selector'
                        onChange={e =>
                          setFieldValue(
                            conversationLanguageCvId,
                            e.target.value,
                          )
                        }
                        options={enabledLanguages.map(language => ({
                          label: language,
                          value: language,
                        }))}
                        placeholder={
                          enabledLanguages.length
                            ? 'Select a language'
                            : 'There are no languages enabled'
                        }
                        value={values[conversationLanguageCvId]}
                      />
                    </Box>
                  )}
                  {!!testContextVariables?.length && (
                    <Box display='flex' flexDirection='column' gap={1}>
                      <TextWithTooltip
                        text='Test Context Variables'
                        tooltipText="Predefine testing conditions by setting context variables to test different paths. This will skip action steps in the test as long as all of the action steps' output variables are defined here."
                      />
                      {testContextVariables.map(contextVariable => {
                        return (
                          <ContextVariableInput
                            contextVariable={contextVariable}
                            // @ts-expect-error TODO: Fix this, something about the definition for context_variable_type is not right.
                            contextVariableType={
                              contextVariable.context_variable_type
                            }
                            key={contextVariable.context_variable_id}
                            onChange={value =>
                              setFieldValue(
                                contextVariable.context_variable_id,
                                value,
                              )
                            }
                            required={false}
                            value={
                              values[contextVariable.context_variable_id] ===
                              undefined
                                ? ''
                                : values[contextVariable.context_variable_id]
                            }
                          />
                        );
                      })}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    justifyContent: 'end',
                    marginTop: '24px',
                  }}
                >
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    isLoading={isSubmitting}
                    size='large'
                    type='submit'
                    variant='main'
                  >
                    Next
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

const TextWithTooltip = ({
  text,
  tooltipText,
}: {
  text: string;
  tooltipText: string;
}) => {
  return (
    <Box display='flex' gap={0.5}>
      <Typography variant='font14Bold'>{text}</Typography>
      <Tooltip tooltipContent={tooltipText}>
        <IconInfoCircle size={20} />
      </Tooltip>
    </Box>
  );
};
