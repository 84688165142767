const MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH = 200;
export const KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE = `Keep the note under ${MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH} words`;

export const validateKnowledgeConfigurationPrompt = (prompt: string) => {
  const numWords = prompt.split(' ');
  if (numWords.length >= MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH) {
    return KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE;
  }

  return false;
};
