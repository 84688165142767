import React, { useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
//Assets
import articleIcon from '../../../assets/images/article-suggestion-action.svg';
//Components
import { ActionSvg } from '../ForethoughtActionsList';
import { Container, Outline } from './ActionOutline';

interface ArticleOutlineProps {
  isOver: boolean;
  isPositionedFirst?: boolean;
  isSqueezingMode?: boolean;
}

const ArticleOutline: React.FC<
  React.PropsWithChildren<ArticleOutlineProps>
> = ({ isOver, isPositionedFirst, isSqueezingMode }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <Container
      isPositionedFirst={isPositionedFirst}
      isSqueezingMode={isSqueezingMode}
      ref={ref}
    >
      <Outline isOver={isOver}>
        <ActionSvg height={20} marginRight={11} width={14}>
          <IconContainer>
            <ReactSVG src={articleIcon} />
          </IconContainer>
        </ActionSvg>
        <Typography variant='font14'>Drop article here</Typography>
      </Outline>
    </Container>
  );
};

export default ArticleOutline;

const IconContainer = styled('div')`
  position: relative;
  bottom: 2px;
`;
