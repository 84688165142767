import { v4 as uuidv4 } from 'uuid';

import {
  ContextVariable,
  DynamicListOption,
} from 'src/types/actionBuilderApiTypes';

export interface ContextVariableOptionWithIndent {
  // disable parent items that should not be selectable
  disabled: boolean;
  // label including the parent CV name
  fullLabel?: string;
  // id of the context variable or a temporary id for parent items
  id: string;
  // indent level of the item in the object
  indent: number;
  // whether the item is undefined
  isDefined?: boolean;
  // label of the key
  label: string;
}

/**
 * Flattens the dynamic context variables and formats them in such a way that they can be used by the rich text editor mention component.
 */
export function flattenDynamicContextVariables(
  contextVariables: ContextVariable[],
  shouldIncludeDynamicListContextVariableChildren = true,
): ContextVariableOptionWithIndent[] {
  const flattened: ContextVariableOptionWithIndent[] = [];

  for (const cv of contextVariables) {
    const isDynamicList = cv.context_variable_type === 'DYNAMIC_LIST';

    const flattenedCV: ContextVariableOptionWithIndent = {
      disabled: isDynamicList,
      fullLabel: cv.context_variable_name,
      id: cv.context_variable_id,
      indent: 0,
      label: cv.context_variable_name,
    };
    flattened.push(flattenedCV);

    if (
      shouldIncludeDynamicListContextVariableChildren &&
      isDynamicList &&
      cv.configuration_fields?.dynamic_list_config?.context_variables
    ) {
      flattened.push(
        ...formatDynamicListChildOptions({
          indent: 1,
          options:
            cv.configuration_fields.dynamic_list_config.context_variables,
          parentCVName: cv.context_variable_name,
        }),
      );
    }
  }

  return flattened;
}

export function formatDynamicListChildOptions({
  indent = 0,
  options,
  parentCVName,
}: {
  indent?: number;
  options: DynamicListOption[];
  parentCVName: string;
}): ContextVariableOptionWithIndent[] {
  const flattened: ContextVariableOptionWithIndent[] = [];

  for (const option of options) {
    const flattenedOption: ContextVariableOptionWithIndent = {
      disabled: !option.context_variable_id,
      fullLabel: option.context_variable_id
        ? formatNestedListOption(parentCVName, option.context_variable_id)
        : undefined,
      id: option.context_variable_id || uuidv4(),
      indent,
      label: option.context_variable_name,
    };
    flattened.push(flattenedOption);
    if (option.options) {
      const nestedCVs = formatDynamicListChildOptions({
        indent: indent + 1,
        options: option.options,
        parentCVName,
      });
      flattened.push(...nestedCVs);
    }
  }
  return flattened;
}

/**
 * Returns a tuple of [id, suffix]
 * For example, `context_variable_id/./key0.key1` -> ['context_variable_id', 'key0.key1']
 */
export function splitDynamicContextVariableId(id: string) {
  return id.split(/\/\.\//g);
}

export function formatNestedListOption(parentCVName: string, id: string) {
  const suffix = splitDynamicContextVariableId(id).slice(1);

  return `${parentCVName}.${suffix}`;
}

export function convertDynamicListCVChildrenToListOptions(
  dynamicListCV: ContextVariable,
  color?: string,
) {
  const childrenOfSelectedCV =
    dynamicListCV.configuration_fields?.dynamic_list_config?.context_variables;
  if (!childrenOfSelectedCV) {
    return null;
  }
  const formattedOptions = formatDynamicListChildOptions({
    options: childrenOfSelectedCV,
    parentCVName: dynamicListCV.context_variable_name,
  });
  // options without fullLabel are not selectable because they point to a non-primitive value, so filter them out
  const validOptions = formattedOptions.filter(option => option.fullLabel);

  return validOptions.map(option => {
    const label = '$' + option.fullLabel;
    const [, value] = splitDynamicContextVariableId(option.id);
    return {
      color,
      label,
      value,
    };
  });
}
