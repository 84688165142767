import { useReactFlow } from 'reactflow';
import { getOutgoers, NodeProps } from 'reactflow';

export const useIsPlacerHolderNodeLeaf = (id: NodeProps['id']) => {
  const { getEdges, getNode, getNodes } = useReactFlow();

  const currentNode = getNode(id);

  if (!currentNode) {
    return;
  }

  return !getOutgoers(currentNode, getNodes(), getEdges()).length;
};
