import { Box, Typography } from '@mui/material';

interface RedactionTypeDescriptionProps {
  description: string;
  title: string;
}

const RedactionTypeDescription = ({
  description,
  title,
}: RedactionTypeDescriptionProps) => {
  return (
    <Box display='flex' flexDirection='column' gap='10px'>
      <Typography variant={'font18Bold'}>{title}</Typography>
      <Typography variant={'font14'}>{description}</Typography>
    </Box>
  );
};

export default RedactionTypeDescription;
