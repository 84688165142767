import { Box, useTheme } from '@mui/material';
import { IconX } from '@tabler/icons-react';

import { IconButton } from '@forethought-technologies/forethought-elements';
import GeneratedResponsesLabel from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-builder/components/generated-responses-loading-label/GeneratedResponsesLabel';

interface ResponseGenerationModalHeaderProps {
  /** Determines if the request to get the response is in progress */
  isLoadingResponse: boolean;
  /** Function to call when the close button is clicked */
  onClose: () => void;
}

const ResponseGenerationModalHeader = ({
  isLoadingResponse,
  onClose,
}: ResponseGenerationModalHeaderProps) => {
  const { palette } = useTheme();
  const label = isLoadingResponse
    ? 'Generating'
    : 'Email response with SupportGPT™';

  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.white}
      borderBottom={`1px solid ${palette.colors.slate[200]}`}
      borderRadius='7px 7px 0 0'
      display='flex'
      height='48px'
      justifyContent='space-between'
      padding='14px 10px 14px 16px'
      width='100%'
    >
      <GeneratedResponsesLabel
        addLoadingIndicator={isLoadingResponse}
        label={label}
      />
      <IconButton
        aria-label='close response generation modal'
        onClick={onClose}
        variant='ghost'
      >
        <IconX color={palette.colors.grey[800]} height={20} width={20} />
      </IconButton>
    </Box>
  );
};

export default ResponseGenerationModalHeader;
