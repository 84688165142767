import {
  DropoffBreakdown,
  RelevanceBreakdown,
} from 'src/services/apiInterfaces';

function isDateInLastNDays(date: Date, n: number) {
  const today = new Date();
  const nDaysAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - n,
  );
  return date >= nDaysAgo;
}

export function isDeflectionInsightsProcessing({
  hasDeflectionInsights,
  isDeflected,
  modifiedDate,
}: {
  hasDeflectionInsights: boolean;
  isDeflected: boolean;
  modifiedDate: string;
}) {
  return (
    !hasDeflectionInsights &&
    isDeflected === true &&
    isDateInLastNDays(new Date(modifiedDate), 1)
  );
}

// i.e. formatNumberWithPercentage(2000, 10000) => '2,000 (20%)'
export function formatNumberWithPercentage(num: number, total: number) {
  let percent = 0;
  if (total !== 0) {
    percent = (num / total) * 100;
  }
  return `${num.toLocaleString()} (${percent.toFixed(0)}%)`;
}

// i.e. formatPercentage(2000, 10000) => '20%'
export function formatPercentage(num: number, total: number) {
  let percent = 0;
  if (total !== 0) {
    percent = (num / total) * 100;
  }
  return percent.toFixed(0) + '%';
}

export function calculateRelevanceBreakdownScore(item: RelevanceBreakdown) {
  const weights = {
    irrelevant_count: 0.1,
    relevant_count: 2,
    somewhat_relevant_count: 1,
  };
  return (
    item.relevant_count * weights.relevant_count +
    item.somewhat_relevant_count * weights.somewhat_relevant_count +
    item.irrelevant_count * weights.irrelevant_count
  );
}

export function getEngagementRate(dropoffBreakdown: DropoffBreakdown) {
  const { dropoff_count, no_dropoff_count } = dropoffBreakdown;
  const total = dropoff_count + no_dropoff_count;
  return total ? Math.round((no_dropoff_count / total) * 100) : 0;
}

export function calculateSum(values: number[]) {
  return values.reduce((acc, curr) => acc + curr, 0);
}
