import { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import useTabs from './hooks/useTabs';
import SolveInsightsArticles from './articles';
import SolveInsightsChats from './chats';
import SolveInsightsOverview from './overview';
import SolveInsightsTopics from './topics';
import SolveInsightsWorkflows from './workflows';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';

const SolveInsightsMain = () => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  // state params
  const [activeTab, setActiveTab] = useStateParams<number>({
    deserialize: param => parseInt(param, 10),
    initialState: 0,
    paramsName: 'tab',
    serialize: state => state.toString(),
  });

  useEffect(() => {
    // Only trigger once, but need to get current activeTab
    emitTrackingEventCallback('insight-initial-page', {
      scope: 'main',
      tab: activeTab === 0 ? 'overview' : activeTab === 1 ? 'topic' : 'article',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitTrackingEventCallback]);

  const { availableTabs } = useTabs();

  const handlePageRender = () => {
    const tabValue = availableTabs[activeTab];

    if (typeof tabValue === 'string') {
      if (tabValue === 'Overview') {
        return <SolveInsightsOverview />;
      }
      if (tabValue === 'Topics') {
        return <SolveInsightsTopics />;
      }
      if (tabValue === 'Workflows') {
        return <SolveInsightsWorkflows />;
      }
      if (tabValue === 'Articles') {
        return <SolveInsightsArticles />;
      }
      if (tabValue === 'Chats') {
        return <SolveInsightsChats />;
      }
    }
    return <SolveInsightsArticles />;
  };

  return (
    <>
      <Box
        padding='0px 40px'
        sx={{
          bgcolor: palette.colors.white,
        }}
      >
        <Tabs
          a11yProps={index => ({
            'aria-controls': `tabpanel-${index}`,
            id: `main-tab-${index}`,
          })}
          aria-label='tabs to pick data table below'
          onChange={(_, tab) => {
            emitTrackingEventCallback('insight-tab-change', {
              scope: 'main',
              tab: tab === 0 ? 'overview' : tab === 1 ? 'topic' : 'article',
            });
            setActiveTab(tab);
          }}
          tabs={availableTabs}
          typographyVariant='font18'
          value={activeTab}
        />
      </Box>

      {handlePageRender()}
    </>
  );
};

export default SolveInsightsMain;
