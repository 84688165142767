import routes from '.';
import { useSelector } from 'react-redux';

import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

export const usePermittedRoutes = ({
  // @TODO, permission probably should live in Redux instead of top level app internal state
  permissionKeys,
}: {
  permissionKeys: string[];
}) => {
  const role = useSelector(selectUserRole);
  const { data } = useGetFeatureFlagsQuery();
  const permissionSet = new Set(permissionKeys);

  return routes
    .map(route => {
      const { featureFlags: routeFeatureFlags, permissionKey, roles } = route;
      const permissionsRequired = permissionKey ?? roles;
      const doesRouteHaveRequiredFeatureFlags = routeFeatureFlags?.length
        ? routeFeatureFlags.every(flag => data?.feature_flags?.includes(flag))
        : true;

      if (route.ignorePermissionForSuperAdmin && role === 'ROLE_SUPER_ADMIN') {
        return route;
      }

      if (
        permissionsRequired &&
        permissionKey &&
        !permissionSet.has(permissionKey)
      ) {
        return null;
      }

      if (permissionsRequired && roles && !roles.has(role)) {
        return null;
      }

      if (!doesRouteHaveRequiredFeatureFlags) {
        return null;
      }

      return route;
    })
    .filter(Boolean);
};
