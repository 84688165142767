import { AvailablePermissions, Roles } from './types';

const superAdminRolePermissions = [
  'add_custom_intent',
  'delete_custom_intent',
  'preview_workflows',
  'activate_workflow',
  'add_suggested_improvements',
  'squeeze_step',
  'edit_step',
  'delete_step',
  'delete_canvas_action',
  'update_training_phrases',
  'customize_action_settings',
  'add_step',
  'add_canvas_action',
  'add_workflow_tag',
  'remove_workflow_tag',
  'update_workflow',
  'discard_draft_workflow',
  'route_intent',
  'create_action',
  'configurate_action_settings',
  'delete_action',
  'edit_action',
] as const;

const userRolePermissions = [] as const;
const adminRolePermissions = [...superAdminRolePermissions] as const;
const agentRolePermissions = [...superAdminRolePermissions] as const;

export const rolePermissions: Record<
  Roles,
  Readonly<AvailablePermissions[]>
> = {
  ROLE_ADMIN: adminRolePermissions,
  ROLE_AGENT: agentRolePermissions,
  ROLE_SUPER_ADMIN: superAdminRolePermissions,
  ROLE_USER: userRolePermissions,
};
