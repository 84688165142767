import { useMemo } from 'react';

import { TopicTimeFilter } from 'src/components/app/types';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';
import { getMetricLabel, getTimeLabel } from 'src/utils/discover/helpers';

export const useGetLabelFromValue = ({
  type,
  value,
}: {
  type: 'metric' | 'time';
  value: DiscoverTopicAggregatedMetricType | TopicTimeFilter;
}) => {
  const label = useMemo(() => {
    if (type === 'metric') {
      return getMetricLabel(value as DiscoverTopicAggregatedMetricType);
    }

    if (type === 'time') {
      return getTimeLabel(value as TopicTimeFilter);
    }
  }, [value, type]);

  return label ?? '';
};
