import { createAsyncThunk } from '@reduxjs/toolkit';

import * as API from 'src/services/audit/auditApi';
import { AuditQueryRequestBody } from 'src/types/auditTypes';

export const getAuditEventLogs = createAsyncThunk(
  'audit/getAuditEventLogs',
  async (requestBody: AuditQueryRequestBody) => {
    return await API.getAuditEventLogs(requestBody);
  },
);

export const getAuditEventLogFilters = createAsyncThunk(
  'audit/getAuditEventLogFilters',
  async () => {
    return await API.getAuditEventLogFilters();
  },
);
