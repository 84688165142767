import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import closeIcon from '../../../assets/images/gray-close-icon.svg';
import { selectIsDraft } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';

interface SimpleTagProps {
  backgroundColor: string;
  color?: string;
  isNotCanvas?: boolean;
  isRemoveIconDisabled?: boolean;
  onClick: () => void;
  text: string;
}

const SimpleTag: React.FC<React.PropsWithChildren<SimpleTagProps>> = ({
  backgroundColor,
  color,
  isNotCanvas = false,
  isRemoveIconDisabled,
  onClick,
  text,
}: SimpleTagProps) => {
  const isDraft = useSelector(selectIsDraft);
  return (
    <Container
      aria-label={`${text} tag`}
      backgroundColor={backgroundColor}
      isDraft={isDraft}
      role='group'
    >
      <Typography color={color} variant='font14Medium'>
        {text}
      </Typography>
      {(isDraft || isNotCanvas) && (
        <CloseIcon
          aria-label={`Delete tag ${text}`}
          isDisabled={isRemoveIconDisabled}
          onClick={onClick}
          role='button'
          src={closeIcon}
        />
      )}
    </Container>
  );
};

export default SimpleTag;

const Container = styled('div')<{ backgroundColor: string; isDraft?: boolean }>`
  display: flex;
  padding: ${props => (!!props.isDraft ? '6px 4px 6px 16px' : '6px 16px')};
  border-radius: 4px;
  background: ${props => props.backgroundColor};
`;

const CloseIcon = styled('img')<{ isDisabled?: boolean }>`
  display: block;
  width: 12px;
  margin-left: 6px;
  padding: 3px;
  opacity: ${props => (props.isDisabled ? '0.4' : '1')};

  &:hover {
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
    ${props =>
      !props.isDisabled && 'background: var(--background-color-hover);'}
    border-radius: 50%;
  }
`;
