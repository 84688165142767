import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ErrorQueryEvent } from 'src/types/ErrorEventTypes';

export const ErrorPayload = ({
  payload,
}: {
  payload: ErrorQueryEvent['error_payload'];
}) => {
  const { palette } = useTheme();
  return (
    <Box display='flex' flexDirection='column' gap='10px'>
      <Typography variant='font11'>Error Payload</Typography>
      <Box
        bgcolor={palette.colors.black}
        border={`1px solid ${palette.colors.slate[200]}`}
        borderRadius='8px'
        display='flex'
        flexDirection='column'
        gap='16px'
        padding='16px'
      >
        <Box
          component='pre'
          margin={0}
          sx={{
            color: 'white',
            fontSize: '14px',
            overflowX: 'scroll',
            whiteSpace: 'pre-wrap',
          }}
        >
          <code>{JSON.stringify(payload, null, 2)}</code>
        </Box>
      </Box>
    </Box>
  );
};
