import { useSelector } from 'react-redux';
import { Box, styled, Typography, useTheme } from '@mui/material';

import { Toggle } from '@forethought-technologies/forethought-elements';
import RedactionTypeDescription from './RedactionTypeDescription';
import StatusIndicator from 'src/components/status-indicator';
import { SOLVE_WIDGET } from 'src/constants/security/constants';
import {
  selectUserEmail,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';
import {
  useAddProductToBlacklistMutation,
  useGetRedactionEntityListQuery,
  useRemoveProductFromBlacklistMutation,
} from 'src/services/security-settings/SecuritySettingsApi';
import { UserRoles } from 'src/utils/enums';

const PIIRedactionSettingsPage = () => {
  const { palette } = useTheme();

  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const userRole = useSelector(selectUserRole);
  const userEmail = useSelector(selectUserEmail);

  const { data: productBlackListData } = useGetRedactionEntityListQuery();
  const [addProductToBlacklist] = useAddProductToBlacklistMutation();
  const [removeProductFromBlacklist] = useRemoveProductFromBlacklistMutation();
  const { redaction_product_blacklist: productBlackList = [] } =
    productBlackListData ?? {};

  const enablementInfo = paidPlanData?.enablement_info;
  const isSolveEnabled =
    enablementInfo?.solve_lite || enablementInfo?.flamethrower;
  const isSuperAdmin = userRole === UserRoles.ROLE_SUPER_ADMIN;
  const isForehoughtEmail = userEmail?.includes('@forethought.ai');

  const getComponentByRole = () => {
    const includesSolveWidgetProduct = productBlackList.includes(SOLVE_WIDGET);
    if (isSuperAdmin || isForehoughtEmail) {
      const status = !includesSolveWidgetProduct ? 'active' : 'idle';
      const label = !includesSolveWidgetProduct ? 'On' : 'Off';
      return (
        <StatusIndicator height={8} label={label} status={status} width={8} />
      );
    }
    return (
      <Toggle
        checked={!includesSolveWidgetProduct}
        onChange={() => {
          if (includesSolveWidgetProduct) {
            removeProductFromBlacklist({ product: SOLVE_WIDGET });
          } else {
            addProductToBlacklist({
              product: SOLVE_WIDGET,
            });
          }
        }}
      />
    );
  };

  return (
    <Container
      display='flex'
      flexDirection='column'
      gap='50px'
      height='100%'
      padding='60px'
      sx={{ backgroundColor: palette.colors.white }}
    >
      <Container display='flex' flexDirection='column' gap='21px'>
        <Typography variant={'font24'}>Data Redaction</Typography>
        <Typography variant={'font14'}>
          Redaction involves selectively editing or obscuring parts of text to
          protect sensitive information. Forethought provides configuration of
          precise control over the management of sensitive data across your
          product set. Learn more at{' '}
          <a
            href='https://trust.forethought.ai/'
            rel='noreferrer'
            target='_blank'
          >
            trust.forethought.ai.
          </a>{' '}
          For custom redaction rules, please email your requirements to
          support@forethought.ai and security@forethought.ai.
        </Typography>
      </Container>
      <Container display='flex' flexDirection='column' gap='25px'>
        <Box display='flex' gap='50px'>
          <Typography color={palette.text.secondary} variant={'font12'}>
            Redaction
          </Typography>
          <Typography color={palette.text.secondary} variant={'font12'}>
            Area of redaction
          </Typography>
        </Box>
        <Box display='flex' gap='74px'>
          <StatusIndicator height={8} label='On' status={'active'} width={8} />
          <RedactionTypeDescription
            description='Forethought will redact any data retrieved from your Help Desk for analytical purposes.'
            title='Help Desk Data'
          />
        </Box>
        {isSolveEnabled && (
          <Box
            display='flex'
            gap={isSuperAdmin || isForehoughtEmail ? '74px' : '50px'}
          >
            {getComponentByRole()}
            <RedactionTypeDescription
              description="After a conversation has completed, transcripts of interactions are stored in Forethought's database in either redacted or unredacted format. Unredacted data provides more visibility into interactions with your customers; however, Forethought recommends keeping redaction enabled for data security purposes."
              title='Solve Conversation Storage'
            />
          </Box>
        )}
      </Container>
    </Container>
  );
};

export default PIIRedactionSettingsPage;

const Container = styled(Box)`
  flex-direction: column;
  display: flex;
`;
