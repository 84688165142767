import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import defaultTo from 'lodash/defaultTo';
import DiscoverSentimentEmoji from 'src/components/discover-sentiment-emoji';
import { Variant } from 'src/components/discover-sentiment-emoji/types';
import DiscoverMetricTooltip from 'src/components/discover-tooltip/DiscoverMetricTooltip';
import { sentimentTypes } from 'src/constants/discover';
import {
  DiscoverTopicMetric,
  DiscoverTopicValue,
} from 'src/reducers/discoverReducer/types';
import { isSentimentValue } from 'src/utils/discover/helpers';
import { formatNToPercentageDecimal } from 'src/utils/formatToPercentage';

interface SentimentMetricHeaderProps {
  metric: DiscoverTopicMetric;
  volumeMetricValue?: DiscoverTopicValue;
}

const SentimentMetricHeader = ({
  metric,
  volumeMetricValue,
}: SentimentMetricHeaderProps) => {
  const { palette } = useTheme();

  const value = metric.value;
  if (!isSentimentValue(value)) {
    return null;
  }

  const handleTypeName = (type: Variant) => {
    switch (type) {
      case 'positive':
        return 'Increase in sentiment';
      case 'neutral':
        return 'No changes';
      case 'negative':
        return 'Drop in sentiment';
      default:
        return '';
    }
  };

  const totalSentiment =
    defaultTo(value.negative_change, 0) +
    defaultTo(value.neutral_change, 0) +
    defaultTo(value.positive_change, 0);

  return (
    <Box display='flex' gap={1}>
      {sentimentTypes.map(type => (
        <Box display='flex' flexDirection='column' key={type}>
          <Box mb={1}>
            <Typography color={palette.colors.grey[600]} variant='font11'>
              {handleTypeName(type)}{' '}
              <DiscoverMetricTooltip
                metricType={`${type}_change`}
                size='small'
              />
            </Typography>
          </Box>
          <Box display='flex' gap={1}>
            <DiscoverSentimentEmoji size='large' variant={type} />
            <Typography color={palette.colors.black} variant='font32'>
              {value[`${type}_change`]}
            </Typography>
            {volumeMetricValue &&
              typeof value[`${type}_change`] === 'number' && (
                <Typography color={palette.colors.grey[600]} variant='font24'>
                  {`(${formatNToPercentageDecimal(
                    totalSentiment
                      ? value[`${type}_change`] / totalSentiment
                      : 0,
                  )})`}
                  &nbsp;
                </Typography>
              )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SentimentMetricHeader;
