import './index.scss';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Overlay from '../Overlay/index';
import { getAnswersData } from 'src/actions/data/data';
import { deleteReport } from 'src/actions/reports/reports';
import { setCurrentReport } from 'src/actions/reports/reports';
import {
  selectReport,
  selectReportsData,
} from 'src/reducers/reportsReducer/reportsReducer';
import { Report } from 'src/services/apiInterfaces';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';
import selectNextReport from 'src/utils/selectNextReport';

const DeleteReportModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const selectedReport: Report | undefined = useSelector(selectReport);
  const reportsData = useSelector(selectReportsData);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const reference: React.RefObject<HTMLDivElement> = useRef(null);
  let isComponentMounted = true;
  const isReportOwner = reportsData?.user_owned_report_ids.includes(
    selectedReport?.report_id || 0,
  );

  const delClassName = classNames({
    'DeleteReport-icon': isReportOwner && !isModalVisible,
    'DeleteReport-iconDisabled': !isReportOwner,
    'DeleteReport-iconFocused': isReportOwner && isModalVisible,
  });

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        reference,
        ContainerRef,
        setModalVisible,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);

  return (
    <>
      <Overlay displayOverlay={isModalVisible} />
      <div className='dropdown view-dropdown' ref={reference}>
        <button
          aria-expanded='false'
          className='select'
          data-testid='dropdown-button'
          onClick={(): void => {
            isReportOwner && setModalVisible(!isModalVisible);
            !isReportOwner && setModalVisible(false);
          }}
          type='button'
        >
          <span className='selected-option'>
            <span className={delClassName} />
          </span>
        </button>
        {isModalVisible && (
          <div className='DeleteReport--modal' ref={ContainerRef}>
            <p className='DeleteReport-title'>Delete this report?</p>
            <p className='DeleteReport-warning'>
              Once this report is deleted it can no longer be used by anyone who
              may have access to it.
            </p>
            <div className='DeleteReport--rightAligned'>
              <button
                className='DeleteReport-button-cancel'
                onClick={(): void => setModalVisible(false)}
              >
                Cancel
              </button>
              <button
                className='DeleteReport-button-delete'
                data-testid='delete-button'
                onClick={() => {
                  dispatch(deleteReport(selectedReport?.report_id));
                  const report = selectNextReport(reportsData, selectedReport);
                  if (report) {
                    dispatch(setCurrentReport(report));
                    dispatch(getAnswersData());
                  }
                  setModalVisible(false);
                }}
              >
                Delete Report
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DeleteReportModal;
