/** Enum containing properties for Range Slider component to display tooltips, labels and descriptions for coverage values */
export enum RangeSliderCoverageTextProps {
  LABEL = 'Coverage Control',
  MAX_RANGE_DESC = 'More Ticket Coverage. Higher chance of reopens.',
  MAX_RANGE_LABEL = 'Increase Coverage',
  MIN_RANGE_DESC = 'Less Ticket Coverage. Lower chance of reopens.',
  MIN_RANGE_LABEL = 'Decrease Coverage',
  TOOLTIP_DESC = 'Signal the coverage preference for this macro. An indicator of how strict (less coverage) or lenient (more coverage) Solve is when predicting this macro.',
  TOOLTIP_TITLE = 'Coverage Control',
}
/** Enum containing properties for Email Filter component to display tooltips and labels */
export enum EmailFilterTextProps {
  LABEL = 'Allowed Emails',
  TOGGLE_BUTTON_LABEL = 'Email Filtering',
  TOOLTIP_DESC = 'Specify a list of allowed emails that receive Solve replies with this macro.',
  TOOLTIP_TITLE = 'Allowed Emails',
}
/** Enum containing properties for No Data View component */
export enum NoDataViewTextProps {
  CLASS = 'noDataViewIcon',
  MESSAGE = 'There is no data to display',
}
