// List of tuples to define x,y positions of avatars
export const avatarTranslations: [number, number][] = [
  [0, 0],
  [0, 90],
  [54, 12],
  [-160, 180],
  [-54, 140],
];

export const bubbleAvatars = {
  /** salesforce, snowflake, guru, intercom, jira,*/
  left: [
    'https://d3tnn7lar6ozas.cloudfront.net/7839c3ec-fbbf-47e1-8b30-68636186443d.png',
    'https://d3tnn7lar6ozas.cloudfront.net/c7a0f941-d140-4922-8946-1b15d0be58fe.png',
    'https://d3tnn7lar6ozas.cloudfront.net/11e749f1-b142-4133-bdd3-150c7cdeafe9.png',
    'https://d3tnn7lar6ozas.cloudfront.net/0949fa97-0ecc-400d-a444-6b8f0920f8fe.png',
    'https://d3tnn7lar6ozas.cloudfront.net/fdb4c902-334d-410d-b7f3-915979b36fd9.png',
  ],
  /** contentful, confluence, zendesk, readmeio, airtable, */
  right: [
    'https://d3tnn7lar6ozas.cloudfront.net/4fbb773e-e40a-48d7-9df1-148bc716243b.png',
    'https://d3tnn7lar6ozas.cloudfront.net/1d5947ba-f0f0-4375-b059-761808ff922d.png',
    'https://d3tnn7lar6ozas.cloudfront.net/2e13ea8e-154e-4241-ae0e-79304b4cce5d.png',
    'https://d3tnn7lar6ozas.cloudfront.net/ed0ee79d-b51a-4b70-ae50-78be50f54af9.png',
    'https://d3tnn7lar6ozas.cloudfront.net/32a924d3-5ed0-4662-9d6a-15d0bbee4716.png',
  ],
};
