import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
import Card from 'src/components/card';
import { FooterLink } from 'src/components/card/styles';
import { Status } from 'src/components/card/types';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { ConnectorDefinition } from 'src/services/apiInterfaces';
import { DEFAULT_CONNECTOR_AVATAR } from 'src/utils/connectorUtils';

interface IntegrationsCardProps {
  connectorDefinition: ConnectorDefinition;
  disabled?: boolean;
  isInActiveSection?: boolean;
  status: Status;
}

export default function IntegrationsCard({
  connectorDefinition,
  disabled,
  isInActiveSection,
  status,
}: IntegrationsCardProps) {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { avatar, description, name, slug } = connectorDefinition;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Card
      disabled={disabled}
      disabledLink={
        <FooterLink>
          App disabled, to connect it{' '}
          <SupportLink href='mailto:support@forethought.ai'>
            contact support
          </SupportLink>
        </FooterLink>
      }
      iconSrc={avatar || DEFAULT_CONNECTOR_AVATAR}
      key={slug}
      linkText={`${isInActiveSection ? 'Manage' : 'Connect'} Integration`}
      onClick={() => {
        emitTrackingEventCallback('connector-clicked', {
          connector_definition_name: connectorDefinition.name,
          connector_definition_slug: connectorDefinition.slug,
          connector_types: connectorDefinition.connector_types,
        });

        navigate({
          pathname,
          search: `integrationSlug=${slug}`,
        });
      }}
      status={status}
      subtitle={description}
      title={name}
    />
  );
}

const SupportLink = styled('a')`
  color: ${theme.palette.colors.purple[500]};
  text-decoration: none;

  &:hover {
    color: ${theme.palette.colors.purple[600]};
  }
`;
