import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';

import {
  IconButton,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface AutomationCardProps {
  children: React.ReactNode;
  dataAppcuesTarget?: string;
  description: string;
  isIconVisible?: boolean;
  title: React.ReactNode;
}

const AutomationCard: React.FC<
  React.PropsWithChildren<AutomationCardProps>
> = ({
  children,
  dataAppcuesTarget,
  description,
  isIconVisible = true,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Card data-appcues-target={dataAppcuesTarget}>
      <Header>
        <HeaderLeft>
          {title}
          <Typography color={theme.palette.colors.grey[500]} variant='font12'>
            {description}
          </Typography>
        </HeaderLeft>
        {isIconVisible && (
          <IconButton
            aria-label='Expand card'
            onClick={() => setIsOpen(!isOpen)}
            variant='ghost'
          >
            <Flipper flipped={isOpen}>
              <DownloadIcon />
            </Flipper>
          </IconButton>
        )}
      </Header>
      {isOpen && children}
    </Card>
  );
};

const Card = styled('div')`
  background: ${theme.palette.colors.white};
  border-radius: 8px;
  box-shadow: ${theme.shadows[1]};
  padding: 32px;
  z-index: 2;
  margin-top: 32px;

  &:first-child {
    margin-top: 0;
  }
`;

const Flipper = styled('div')<{ flipped: boolean }>`
  transform: ${p => (p.flipped ? 'rotate(180deg)' : 'none')};
`;

const Header = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const HeaderLeft = styled('div')`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;

export default AutomationCard;
