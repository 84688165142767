import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ArticleIntegration } from 'src/reducers/discoverReducer/types';

const HelpdeskCard = ({
  currentHelpdesk,
  helpdesk,
}: {
  currentHelpdesk: ArticleIntegration | undefined;
  helpdesk: ArticleIntegration;
}) => {
  const { avatar, display_name: displayName } = helpdesk;

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      sx={{
        '&:last-child': {
          borderBottom: 0,
          paddingBottom: 0,
        },
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        paddingBottom: '16px',
        paddingTop: '16px',
      }}
    >
      <Box alignItems='center' display='flex' gap={1}>
        <img height={24} src={avatar} width={24} />
        <Box display='flex' flexDirection='column'>
          <Box alignItems='center' display='flex' gap='4px'>
            <StatusIcon
              isEnabled={currentHelpdesk?.display_name === displayName}
            />
            <Typography variant='font14Medium'>{displayName}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const StatusIcon = styled('div')<{ isEnabled: boolean }>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${props =>
    props.isEnabled
      ? props.theme.palette.colors.green[500]
      : props.theme.palette.colors.grey[400]};
`;

export default HelpdeskCard;
