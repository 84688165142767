import { useDrop } from 'react-dnd';

import { actionToTrackingEventMap } from '../constants';
import { getTopicIdsFromWorkflowId } from '../Input/utils';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { ActionFields } from 'src/types/actionBuilderApiTypes';
import {
  DragNDrops,
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';

interface DropActionProps {
  intentWorkflowId: string | null;
}

interface ActionData {
  action_description: string;
  action_fields: ActionFields;
  action_id: string;
  action_name: string;
  action_type: string;
  created_date: string;
  modified_date: string;
}

interface IntentData {
  intent_workflow_id: string;
}

type DragNDropData = ActionData | IntentData;

function isActionData(data: DragNDropData): data is ActionData {
  return (data as ActionData).action_id !== undefined;
}

export const useDropAction = ({ intentWorkflowId }: DropActionProps) => {
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );

  const actionDragNDropType = DragNDrops.FT_ACTION;
  const articleDragNDropType = DragNDrops.FT_ARTICLE;

  const [{ isOver }, drop] = useDrop(() => {
    return {
      accept: [
        actionDragNDropType,
        DragNDrops.FT_INTENT,
        DragNDrops.FT_RESTART,
        articleDragNDropType,
      ],
      collect: monitor => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
      drop: (data: DragNDropData, monitor) => {
        if (isActionData(data)) {
          const action = data as ActionData;
          if (intentWorkflowId && action.action_id) {
            if (monitor.getItemType() === articleDragNDropType) {
              dispatchTrackingAction(
                FlamethrowerTrackingEventTypes.WORKFLOW_ADDED_ARTICLE,
                {
                  action_id: action.action_id,
                  workflow_id: intentWorkflowId,
                },
              );
            } else if (
              Object.keys(actionToTrackingEventMap).includes(action.action_type)
            ) {
              dispatchTrackingAction(
                actionToTrackingEventMap[action.action_type],
                {
                  action_id: action.action_id,
                  workflow_id: intentWorkflowId,
                },
              );
            }
            dispatchTrackingAction(
              FlamethrowerTrackingEventTypes.WORKFLOW_ACTION_ADDED,
              {
                action_id: action.action_id,
                topic_ids: getTopicIdsFromWorkflowId(intentWorkflowId),
                workflow_id: intentWorkflowId,
              },
            );
          }
        } else if (monitor.getItemType() === DragNDrops.FT_RESTART) {
          dispatchTrackingAction(
            FlamethrowerTrackingEventTypes.RESTART_CONVERSATION_STEP_ADDED,
            {
              workflow_id: intentWorkflowId ?? '',
            },
          );
        } else if (monitor.getItemType() === DragNDrops.FT_INTENT) {
          dispatchTrackingAction(
            FlamethrowerTrackingEventTypes.INTENT_ROUTING_ADDED,
            {
              workflow_id: intentWorkflowId ?? '',
            },
          );
        }
      },
    };
  }, []);

  return { drop, isOver };
};
