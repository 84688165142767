import { useMemo, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import { IconButton, styled } from '@mui/material';

import {
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getMilitaryTime, getShortDate } from '../utils';
import {
  ContentLeft,
  Dropdown,
  EventDetails,
  ExpandingField,
  IconChevron,
  IconInfo,
  Info,
  TooltipRoot,
} from './styles';
import { Connector, ConnectorJob } from 'src/services/apiInterfaces';
import { runConnectorJob } from 'src/services/apiV1';

interface ConnectorJobDetailsProps {
  isPending: boolean;
  job: ConnectorJob;
  selectedConnector: Connector;
  setConnectorJobPendingState: (isPending: boolean) => void;
}

export default function ConnectorJobDetails({
  isPending,
  job,
  selectedConnector,
  setConnectorJobPendingState,
}: ConnectorJobDetailsProps) {
  const [isOpen, setIsOpen] = useState(false);

  const eventDetails = useMemo(() => {
    if (isPending) {
      return (
        <Typography color={theme.palette.colors.yellow[600]} variant='font12'>
          Data syncing. This may take a few minutes.
        </Typography>
      );
    } else if (job.job_end_time) {
      return (
        <Typography color={theme.palette.colors.green[600]} variant='font12'>
          Last Sync: {getShortDate(job.job_end_time)} -{' '}
          {getMilitaryTime(job.job_end_time)}
          &nbsp;&nbsp;•&nbsp;&nbsp;
          <Typography color={theme.palette.colors.green[600]} variant='font12'>
            Success
          </Typography>
        </Typography>
      );
    }

    return null;
  }, [isPending, job.job_end_time]);

  return (
    <ExpandingField isPending={isPending}>
      <EventDetails
        isPending={isPending}
        onClick={() => setIsOpen(prev => !prev)}
      >
        <ContentLeft>
          <Tooltip tooltipContent={isPending ? '' : 'Refresh'}>
            <TooltipRoot isPending={isPending}>
              <IconButton
                aria-label='Refresh'
                disabled={isPending}
                onClick={() => {
                  setConnectorJobPendingState(true);
                  runConnectorJob(selectedConnector.connector_id);
                }}
              >
                {isPending ? <SpinningCachedIcon /> : <CachedIcon />}
              </IconButton>
            </TooltipRoot>
          </Tooltip>
          {eventDetails}
        </ContentLeft>
        {!isPending && <IconChevronGreen isOpen={isOpen} />}
      </EventDetails>
      <Dropdown isOpen={!isPending && isOpen}>
        <Info>
          <IconInfo />
          <Typography color={theme.palette.colors.green[600]} variant='font12'>
            Data will be synced on a regular basis. To see changes immediately,
            trigger an update with the refresh button
          </Typography>
        </Info>
      </Dropdown>
    </ExpandingField>
  );
}

const IconChevronGreen = styled(IconChevron)`
  color: ${theme.palette.colors.green[600]};
`;

const SpinningCachedIcon = styled(CachedIcon)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(359deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
