import { useTheme } from '@mui/material/styles';

import { getSearchPositionInText } from 'src/utils/getSearchPositionInText';

interface HighlightedTextProps {
  searchText?: string;
  text: string;
}

const HighlightedText = ({ searchText, text }: HighlightedTextProps) => {
  const { palette } = useTheme();

  if (!searchText?.trim()) {
    return <>{text}</>;
  }

  const { start, stop } = getSearchPositionInText(text, searchText);

  if (start === -1) {
    return <>{text}</>;
  }

  return (
    <>
      {text.split('').map((char, index) => (
        <span
          key={index}
          style={{
            backgroundColor:
              index >= start && index < stop
                ? palette.colors.blue[200]
                : 'transparent',
          }}
        >
          {char}
        </span>
      ))}
    </>
  );
};

export default HighlightedText;
