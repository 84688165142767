import { css, styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import BaseInput from 'src/pages/action-builder/styled-input';
import { textMediumStyle, textRegularStyle } from 'src/styles/styledMixin';

const sectionContainerBase = () => css`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SectionContainer = styled('div')`
  ${sectionContainerBase};
  padding: 0 24px 0 30px;
`;

export const SectionContainerFlush = styled('div')`
  ${sectionContainerBase};
  padding: 0;
`;

export const Section = styled('section')`
  border-top: 1px solid ${theme.palette.colors.grey[100]};
  border-left: none;
  border-right: none;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;

export const Spacer = styled('div')<{
  height?: string;
  width?: string;
}>`
  height: ${props => props.height || 0};

  // min-height is required to fix weird flexbox behavior (display: flexbox on
  // the parent element makes the spacer height be way too small):
  min-height: ${props => props.height || 0};

  width: ${props => props.width || 0};
`;

export const ErrorMessage = styled('div')`
  color: ${Colors.ui.semantic.destructive.default};
  margin-top: 4px;
  font-size: 12px;
`;

export const Label = styled('div')`
  ${textMediumStyle({ color: Colors.ui.text.primary, fontSize: '16px' })}
  margin-bottom: 8px;
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const TextArea = styled('textarea')<{ hasError: boolean }>`
  ${textRegularStyle({ color: Colors.ui.text.primary, fontSize: '16px' })}
  flex: 1 1 auto;
  width: 100%;
  line-height: 20px;
  border: 1px solid
    ${props =>
      props.hasError
        ? Colors.ui.semantic.destructive.default
        : theme.palette.colors.grey[100]};
  border-radius: 4px;
  padding: 8px 8px 8px 16px;
  resize: vertical;
  min-height: 40px;
  max-height: 200px;
  background-color: ${Colors.ui.backgrounds.default};
  &::placeholder {
    font-style: italic;
    font-size: 16px;
    color: ${Colors.ui.text.placeholder};
  }
  &:focus {
    outline: none;
    border: 1px solid ${theme.palette.colors.grey[300]};
  }
`;

export const StyledInput = styled(BaseInput)`
  margin: 0;
  width: 100%;
`;

export const Required = styled('div')`
  ${textRegularStyle({ color: Colors.ui.text.quaternary, fontSize: '14px' })};
  line-height: 20px;
`;

export const Icon = styled('img')`
  margin-bottom: 8px;
  margin-left: 4px;
`;
