import { FetchAction, FilterState } from './types';
import isEqual from 'lodash/fp/isEqual';
import { FilterActions } from 'src/actions/actionTypes';
import type { RootState } from 'src/store/rootReducer';

export interface FieldsInterface {
  field_category?: string;
  field_id: string;
  field_name: string;
  field_type: string;
  isSelected?: boolean;
}

const filterFieldsInitialState = {
  agent: [],
  ticket: [],
};
export const initialState: FilterState = {
  error: '',
  filterFields: filterFieldsInitialState,
  filters: [],
  filterValues: [],
  isLoading: false,
  selectedFilterField: null,
  selectedFilterValues: [],
};

export default function filtersReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === FilterActions.SET_FILTER_LOADING) {
    return {
      ...state,
      isLoading: action.payload,
    };
  }
  // FILTER VALUES
  if (action.type === FilterActions.GET_FILTER_VALUES_SUCCESS) {
    return { ...state, filterValues: action.payload };
  }

  if (action.type === FilterActions.TOGGLE_FILTER_VALUE) {
    let selectedValues = [...state.selectedFilterValues];
    const newValue = action.payload;
    if (state.selectedFilterValues.includes(newValue)) {
      selectedValues = selectedValues.filter(
        (value: string) => value !== newValue,
      );
    } else {
      selectedValues.push(newValue);
    }
    return {
      ...state,
      selectedFilterValues: selectedValues,
    };
  }

  if (action.type === FilterActions.CLEAR_SELECTED_FILTER_VALUES) {
    return { ...state, selectedFilterValues: [] };
  }

  // FILTER FIELDS
  if (action.type === FilterActions.GET_FILTER_FIELDS_SUCCESS) {
    const filterFields = action.payload || filterFieldsInitialState;
    return {
      ...state,
      filterFields,
    };
  }

  if (action.type === FilterActions.CLEAN_FILTER_VALUES) {
    return { ...state, filterValues: [] };
  }

  if (action.type === FilterActions.TOGGLE_SELECTED_FILTER_FIELD) {
    let selectedFilterField = action.payload;

    if (isEqual(state.selectedFilterField, selectedFilterField)) {
      selectedFilterField = null;
    }

    return { ...state, selectedFilterField };
  }

  if (action.type === FilterActions.SET_FILTER_FIELD_NULL) {
    return { ...state, selectedFilterField: null };
  }

  if (action.type === FilterActions.SET_FILTER) {
    return { ...state, filters: [...state.filters, action.payload] };
  }

  // SETTINGS BAR
  if (action.type === FilterActions.CLEAR_FILTERS) {
    return { ...state, filters: [] };
  }

  if (action.type === FilterActions.DELETE_FILTER) {
    const filters = state.filters;
    const tempFilters = [...filters];
    tempFilters.splice(action.payload, 1);
    return { ...state, filters: tempFilters };
  }

  return state;
}

// SELECTORS

export const selectFilterFields = (
  state: RootState,
): FilterState['filterFields'] => state.filters.filterFields;

export const selectSelectedFilterField = (
  state: RootState,
): FilterState['selectedFilterField'] => state.filters.selectedFilterField;

export const selectFilterValues = (
  state: RootState,
): FilterState['filterValues'] => state.filters.filterValues;

export const selectFilters = (state: RootState): FilterState['filters'] =>
  state.filters.filters;

export const selectIsLoading = (state: RootState): FilterState['isLoading'] =>
  state.filters.isLoading;

export const selectSelectedFilterValues = (
  state: RootState,
): FilterState['selectedFilterValues'] => {
  return state.filters.selectedFilterValues;
};
