import React, { FC } from 'react';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import * as Styled from './styles';

interface CheckBoxProps extends React.HTMLAttributes<HTMLInputElement> {
  ariaLabel?: string;
  checked: boolean;
  disabledFlyoutMessage?: string;
  isDisabled?: boolean;
}

export const Checkbox: FC<React.PropsWithChildren<CheckBoxProps>> = ({
  ariaLabel,
  checked,
  disabledFlyoutMessage = '',
  isDisabled,
  /** Other props that are inherited are: onChange, defaultValue, defaultChecked, etc. More info: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement */
  ...props
}) => {
  return (
    <label aria-label={ariaLabel}>
      <Tooltip tooltipContent={isDisabled ? disabledFlyoutMessage : ''}>
        <Styled.CheckboxContainer>
          <Styled.HiddenCheckbox
            checked={checked}
            className='hidden-checkbox'
            disabled={isDisabled}
            type='checkbox'
            {...props}
          />
          <Styled.StyledCheckbox checked={checked} isDisabled={isDisabled}>
            <Styled.Icon className='ft-icon' viewBox='0 0 24 24'>
              <polyline points='20 6 9 17 4 12' />
            </Styled.Icon>
          </Styled.StyledCheckbox>
        </Styled.CheckboxContainer>
      </Tooltip>
    </label>
  );
};
