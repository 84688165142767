import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  callPreviewAssistAutomation,
  deleteAssistAutomation,
  fetchAssistAutomations,
  fetchConnector,
  toggleAssistAutomationPublished,
  updateAssistAutomation,
} from './thunks';
import { AssistAutomationsSliceState } from './types';
import { Connector, ConnectorAction } from 'src/services/apiInterfaces';
import type { RootState } from 'src/store/rootReducer';

export const initialState: AssistAutomationsSliceState = {
  action: null,
  availableAutomations: [],
  connector: null,
  isAvailableAutomationsLoading: false,
  isDeleteAssistAutomationModalVisible: false,
  isDeleteLoading: false,
  isPreviewSubmitLoading: false,
  isPublishedStateLoading: false,
  isUpdateLoading: false,
  previewOutput: {
    error: undefined,
    external_text_field: undefined,
    internal_text_field: undefined,
  },
  toastMessage: {
    show: false,
    title: '',
    variant: 'main',
  },
};

const TOAST_HIDE_DURATION = 2000;

const assistAutomationsSlice = createSlice({
  extraReducers: builder => {
    /**
     * fetchConnector
     */
    builder.addCase(fetchConnector.fulfilled, (state, action) => {
      const { connector } = action.payload;
      state.connector = connector;
    });
    builder.addCase(fetchConnector.rejected, (state, action) => {
      state.toastMessage = {
        show: true,
        title: action.error.message || 'Something went wrong',
        variant: 'danger',
      };
    });
    /**
     * fetchAssistAutomations
     */
    builder.addCase(fetchAssistAutomations.pending, state => {
      state.isAvailableAutomationsLoading = true;
    });
    builder.addCase(fetchAssistAutomations.fulfilled, (state, action) => {
      const { automations } = action.payload;
      state.availableAutomations = automations;
      state.isAvailableAutomationsLoading = false;
      state.previewOutput = {
        error: undefined,
        external_text_field: undefined,
        internal_text_field: undefined,
      };
    });
    builder.addCase(fetchAssistAutomations.rejected, (state, action) => {
      state.toastMessage = {
        show: true,
        title: action.error.message || 'Something went wrong',
        variant: 'danger',
      };

      state.isAvailableAutomationsLoading = false;
    });
    /**
     * updateAssistAutomation
     */
    builder.addCase(updateAssistAutomation.pending, state => {
      state.isUpdateLoading = true;
    });
    builder.addCase(updateAssistAutomation.fulfilled, (state, action) => {
      const updatedAutomation = action.payload;
      const updatedAutomationList = state.availableAutomations.map(
        automation => {
          if (automation.automation_id === updatedAutomation.automation_id) {
            return updatedAutomation;
          }
          return automation;
        },
      );
      state.availableAutomations = updatedAutomationList;
      state.isUpdateLoading = false;

      state.toastMessage = {
        autoHideDuration: TOAST_HIDE_DURATION,
        show: true,
        title: 'Automation has been updated',
        variant: 'main',
      };
    });
    builder.addCase(updateAssistAutomation.rejected, (state, action) => {
      state.isUpdateLoading = false;
      state.toastMessage = {
        show: true,
        title: action.error.message || 'Something went wrong',
        variant: 'danger',
      };
    });
    /**
     * callPreviewAssistAutomation
     */
    builder.addCase(callPreviewAssistAutomation.pending, state => {
      state.isPreviewSubmitLoading = true;
    });
    builder.addCase(callPreviewAssistAutomation.fulfilled, (state, action) => {
      state.previewOutput = {
        error: undefined,
        external_text_field: action.payload.external_text_field || '',
        internal_text_field: action.payload.internal_text_field || '',
      };
      state.isPreviewSubmitLoading = false;
    });
    builder.addCase(callPreviewAssistAutomation.rejected, (state, action) => {
      state.toastMessage = {
        show: true,
        title: action.error.message || 'Something went wrong',
        variant: 'danger',
      };

      state.previewOutput = {
        external_text_field: undefined,
        internal_text_field: undefined,
      };
      state.isPreviewSubmitLoading = false;
    });
    /**
     * toggleAssistAutomationPublished
     */
    builder.addCase(toggleAssistAutomationPublished.pending, state => {
      state.isPublishedStateLoading = true;
    });
    builder.addCase(
      toggleAssistAutomationPublished.fulfilled,
      (state, action) => {
        const toggledAutomation = state.availableAutomations.find(
          ({ automation_id }) => automation_id === action.meta.arg.automationId,
        );

        if (toggledAutomation) {
          toggledAutomation.published = action.meta.arg.published;
        }

        state.isPublishedStateLoading = false;

        if (action.meta.arg.published) {
          state.toastMessage = {
            autoHideDuration: TOAST_HIDE_DURATION,
            show: true,
            title: 'Automation has been published',
            variant: 'main',
          };
        } else {
          state.toastMessage = {
            autoHideDuration: TOAST_HIDE_DURATION,
            show: true,
            title: 'Automation has been unpublished',
            variant: 'main',
          };
        }
      },
    );
    builder.addCase(
      toggleAssistAutomationPublished.rejected,
      (state, action) => {
        state.isPublishedStateLoading = false;

        state.toastMessage = {
          show: true,
          title: action.error.message || 'Something went wrong',
          variant: 'danger',
        };
      },
    );
    /**
     * deleteAssistAutomation
     */
    builder.addCase(deleteAssistAutomation.pending, state => {
      state.isDeleteLoading = true;
    });
    builder.addCase(deleteAssistAutomation.fulfilled, state => {
      state.isDeleteLoading = false;
      state.isDeleteAssistAutomationModalVisible = false;
    });
    builder.addCase(deleteAssistAutomation.rejected, (state, action) => {
      state.toastMessage = {
        show: true,
        title: action.error.message || 'Something went wrong',
        variant: 'danger',
      };

      state.isDeleteLoading = false;
    });
  },
  initialState,
  name: 'assistAutomations',
  reducers: {
    resetConnectorWithAction: (state: AssistAutomationsSliceState) => {
      state.connector = null;
      state.action = null;
    },
    setAutomationAction: (
      state: AssistAutomationsSliceState,
      action: PayloadAction<{
        action: ConnectorAction | null;
        connector: Connector['connector_id'];
      }>,
    ) => {
      state.action = action.payload.action;
    },
    setDeleteAssistAutomationModalVisible: (
      state: AssistAutomationsSliceState,
      action: PayloadAction<
        AssistAutomationsSliceState['isDeleteAssistAutomationModalVisible']
      >,
    ) => {
      state.isDeleteAssistAutomationModalVisible = action.payload;
    },
    setToastMessage: (
      state: AssistAutomationsSliceState,
      action: PayloadAction<AssistAutomationsSliceState['toastMessage']>,
    ) => {
      state.toastMessage = action.payload;
    },
  },
});

export const {
  resetConnectorWithAction,
  setAutomationAction,
  setDeleteAssistAutomationModalVisible,
  setToastMessage,
} = assistAutomationsSlice.actions;

export const selectAutomations = (
  state: RootState,
): AssistAutomationsSliceState => state.assistAutomations;

export default assistAutomationsSlice.reducer;
