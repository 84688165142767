import { useSelector } from 'react-redux';

import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import { selectEditingStepId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { StepTypes } from 'src/utils/enums';

interface EmbedStepProps {
  stepId?: string;
}

const EmbedStep = ({ stepId }: EmbedStepProps) => {
  const editingStepId = useSelector(selectEditingStepId);
  const isBeingEdited = editingStepId === stepId || !stepId;

  return (
    <StepContainer isBeingEdited={isBeingEdited}>
      <StepHeader stepType={StepTypes.EMBED} />
    </StepContainer>
  );
};

export default EmbedStep;
