import { renderToString } from 'react-dom/server';

import { ApiTextStep } from './ApiTextStep';
import { ArticleAnswerApiWidgetComponent } from 'src/slices/workflow-preview/types';

interface ApiArticleAnswerStepProps {
  step: ArticleAnswerApiWidgetComponent;
}

export const ApiArticleAnswerStep = ({ step }: ApiArticleAnswerStepProps) => {
  const { articles, paraphrased_answer: text } = step;

  return (
    <ApiTextStep
      message={renderToString(
        <p>
          {text} {'\n\n'}
          Related articles:{'\n'}
          {articles.map((article, index) => (
            <>
              <a
                href={article.link}
                key={index}
                rel='noopener noreferrer'
                style={{ cursor: 'pointer' }}
                target='_blank'
              >
                {article.title}
              </a>
              {'\n'}
            </>
          ))}
        </p>,
      )}
    />
  );
};
