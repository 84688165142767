import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import { FilterButton } from '@forethought-technologies/forethought-elements';
import { setViewMode } from 'src/actions/pageSettings/pageSettings';
import chartIcon from 'src/assets/images/chart-icon.svg';
import tableIcon from 'src/assets/images/table-icon.svg';
import SettingsLabel from 'src/components/reusable-components/settings-label';
import { selectReport } from 'src/reducers/reportsReducer/reportsReducer';
import { ViewModes } from 'src/utils/enums';
import { getData } from 'src/utils/getDataUtil';

interface Props {
  viewMode: ViewModes;
}
const SVG = styled(ReactSVG)`
  height: 16px;
  margin-right: 5px;
`;
const TableIconFragment = <SVG src={tableIcon} />;
const ChartIconFragment = <SVG src={chartIcon} />;

const iconMap = {
  [ViewModes.CHART]: ChartIconFragment,
  [ViewModes.TABLE]: TableIconFragment,
};

const options = [
  {
    label: ViewModes.CHART,
    optionStartAdornment: iconMap[ViewModes.CHART],
    value: ViewModes.CHART,
  },
  {
    label: ViewModes.TABLE,
    optionStartAdornment: iconMap[ViewModes.TABLE],

    value: ViewModes.TABLE,
  },
];

const ViewModeDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  viewMode,
}: Props) => {
  const dispatch = useDispatch();
  const report = useSelector(selectReport);
  const disabled = report?.query.query_type === 'TICKET';

  const handleViewModeChange = (value: string) => {
    dispatch(setViewMode(value as ViewModes));
    getData();
  };

  return (
    <Container>
      <SettingsLabel name={'Display'} />
      <FilterButton
        aria-label='view mode dropdown'
        disabled={disabled}
        initialValue={ViewModes.CHART}
        onChange={handleViewModeChange}
        options={options}
        startAdornment={iconMap[viewMode]}
        value={viewMode}
      />
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 8px;
`;

export default ViewModeDropdown;
