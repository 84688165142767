import { useState } from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

import {
  SelectDropdown,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { IntegrationTabProps, OriginItem } from '../types';
import EditableSelectField from './EditableSelectField';
import IntegrationErrorInfo from './IntegrationErrorInfo';

const integrationType = 'salesforce';

interface Origin extends IntegrationTabProps {
  originFieldValue: string[];
  origins: OriginItem[];
  setOriginFieldValue: (values: string[]) => void;
}

const IntegrationSalesforce = ({
  integrationSelectedState,
  isDisabled = false,
  onChange,
  options,
  originFieldValue,
  origins,
  predictedFieldValue,
  setOriginFieldValue,
}: Origin) => {
  const { palette } = useTheme();
  const [isNewField, setIsNewField] = useState(false);

  const handleSelectChange = ({
    inputValue,
    selectValue,
  }: {
    inputValue: string;
    selectValue: string;
  }) => {
    onChange({
      input: inputValue,
      integrationType,
      isNewField,
      select: selectValue,
    });
  };

  const handleToggle = (isInput: boolean) => {
    setIsNewField(isInput);
  };
  const disabled = integrationSelectedState === 'loading' || isDisabled;
  const hasError = integrationSelectedState === 'error';

  return (
    <Box display='flex' flexDirection='column' rowGap={3}>
      <TooltipContainer>
        <Tooltip tooltipContent='You must output to a field on “Case” object'>
          <SelectDropdown
            aria-label='Object dropdown'
            disabled
            id='object-dropdown'
            onChange={() => undefined}
            options={[{ label: 'Case', value: 'Case' }]}
            placeholder='Select object'
            value='Case'
          />
        </Tooltip>
      </TooltipContainer>
      <Box>
        <Typography color={palette.colors.grey[800]} variant='font14Bold'>
          Origins
        </Typography>
        <SelectDropdown
          aria-label='Origin multiple dropdown'
          chipsToShow={4}
          disabled={disabled}
          error={!origin.length && 'Select at least one origin'}
          id='origin-dropdown'
          isMenuSearchable
          multiple
          onChange={({ target }) => {
            const { value } = target;
            if (typeof value === 'string') {
              return;
            }

            setOriginFieldValue(value);
          }}
          onClear={() => setOriginFieldValue([])}
          onClickOnlyButton={value => setOriginFieldValue(value)}
          onDeleteChip={(_, value) =>
            setOriginFieldValue(
              originFieldValue.filter(fieldValue => fieldValue !== value),
            )
          }
          options={origins}
          placeholder='Select origins'
          value={originFieldValue}
        />
      </Box>
      <Box display='flex' flexDirection='column' rowGap={1}>
        <EditableSelectField
          disabled={disabled}
          onChange={handleSelectChange}
          onToggleCallback={handleToggle}
          options={options}
          tooltip='This model will output to this field in the selected integration'
          value={predictedFieldValue}
        />
        {hasError && <IntegrationErrorInfo />}
      </Box>
    </Box>
  );
};

const TooltipContainer = styled('div')`
  & > div {
    display: block;
    width: 100%;
  }
`;

export default IntegrationSalesforce;
