import { styled } from '@mui/material';

export const ChatContainer = styled('div')<{
  isEditMode?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    .styled-line {
      ${props =>
        props.isEditMode ? 'visibility: hidden;' : 'visibility: visible;'}
    }
  }
`;
