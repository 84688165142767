export const componentText = {
  checkbox_no: 'No',
  checkbox_placeholder: 'Select one',
  checkbox_yes: 'Yes',
  email_error: 'Please enter a valid email',
  number_error: 'Please enter a valid number',
  phone_error: 'Please enter a valid phone number',
  short_text_alphanumeric_error: 'Text must be alphanumeric',
  short_text_equal_error: 'Text must be exactly %s characters',
  short_text_max_error: 'Text cannot go over %s characters',
  short_text_min_error: 'Text needs to be at least %s characters',
} as const;
