import { useNavigate } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';
import { IconBolt, IconVariable, IconWorld } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { setStepLevelTranslationsId } from 'src/actions/workflow-builder/workflowBuilderActions';
import { setGlobalWorkflowBuilderOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

const MoreTabContent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();

  return (
    <Container>
      <Box alignItems='center' display='flex' flexDirection='column' gap='40px'>
        {/* Context variables */}
        <ContentContainer>
          <ContentHeader
            onClick={() =>
              dispatch(
                setGlobalWorkflowBuilderOptions({ isCvDrawerOpen: true }),
              )
            }
          >
            <IconVariable
              color={palette.colors.grey[700]}
              size={50}
              strokeWidth={1.5}
            />
            <Typography variant='font24'>Context variables</Typography>
          </ContentHeader>
          <Box
            display='flex'
            flexDirection='column'
            pl='70px'
            sx={{
              '.MuiTypography-root': {
                background:
                  'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              },
            }}
          >
            <Typography variant='font16'>
              Context variables are custom placeholders that store and pass
              different values in workflows. They provide flexibility and can be
              used in help center tickets, allowing you to define, store, and
              utilize values throughout the process.
            </Typography>
          </Box>
        </ContentContainer>
        {/* Translations */}
        <ContentContainer>
          <ContentHeader
            onClick={() => {
              dispatch(setStepLevelTranslationsId(''));
              dispatch(
                setGlobalWorkflowBuilderOptions({
                  isTranslationDrawerOpen: true,
                }),
              );
            }}
          >
            <IconWorld
              color={palette.colors.grey[700]}
              size={50}
              strokeWidth={1.5}
            />
            <Typography variant='font24'>Translations</Typography>
          </ContentHeader>
          <Box
            display='flex'
            flexDirection='column'
            pl='70px'
            sx={{
              '.MuiTypography-root': {
                background:
                  'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              },
            }}
          >
            <Typography variant='font16'>
              Multilingual Support for Solve automates workflow translations
              available in over 27 languages, providing control for edits and
              meeting the demand for optimized translation capabilities.
            </Typography>
          </Box>
        </ContentContainer>
        {/* Actions */}
        <ContentContainer>
          <ContentHeader
            onClick={() =>
              navigate({
                pathname: Routes.ACTION_BUILDER,
              })
            }
          >
            <IconBolt
              color={palette.colors.grey[700]}
              size={50}
              strokeWidth={1.5}
            />
            <Typography variant='font24'>Actions</Typography>
          </ContentHeader>
          <Box
            display='flex'
            flexDirection='column'
            pl='70px'
            sx={{
              '.MuiTypography-root': {
                background:
                  'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              },
            }}
          >
            <Typography variant='font16'>
              Action Builder enhances Solve Widget customization by utilizing
              APIs to retrieve information from external sources. This
              information is saved as context variables, enriching workflows
              created with Workflow Builder and improving the self-service
              experience.
            </Typography>
          </Box>
        </ContentContainer>
      </Box>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  background-color: ${props => props.theme.palette.colors.slate[200]};
`;

const ContentContainer = styled('div')`
  width: 670px;
`;

const ContentHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export default MoreTabContent;
