import { BuilderView, WorkflowTypes } from './types';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { TranslationChannel } from 'src/types/workflowBuilderAPITypes';
import {
  ActionBuilderActionTypes,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';

export enum CanvasModes {
  ACTION = 'action',
  ACTION_EDIT = 'action-edit',
  ARTICLE_PICKER = 'article-picker',
  ATTACHMENT_ANALYSIS_UPLOAD = 'attachment-analysis-upload',
  BUTTON = 'button',
  CONDITION = 'condition',
  CSAT_TRIGGER_POINT = 'csat-trigger-point',
  CUSTOM_HANDOFF = 'custom-handoff',
  EMBED = 'embed',
  FORM = 'form',
  GO_TO_INTENT = 'go-to-intent',
  IMAGE = 'image',
  MESSAGE = 'message',
}

export const actionToTrackingEventMap: Record<
  string,
  FlamethrowerTrackingEventTypes
> = {
  [ActionBuilderActionTypes.ZENDESK_HANDOFF]:
    FlamethrowerTrackingEventTypes.WORKFLOW_ZENDESK_HANDOFF_ACTION_ADDED,
  [ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2]:
    FlamethrowerTrackingEventTypes.WORKFLOW_ZENDESK_CHAT_HANDOFF_ACTION_ADDED,
  [ActionBuilderActionTypes.ZENDESK_TICKET_CREATION]:
    FlamethrowerTrackingEventTypes.WORKFLOW_ZENDESK_TICKET_CREATION_ACTION_ADDED,
  [ActionBuilderActionTypes.ARTICLE_SUGGESTION]:
    FlamethrowerTrackingEventTypes.WORKFLOW_ADDED_ARTICLE_SUGGESTION,
  [ActionBuilderActionTypes.SALESFORCE_HANDOFF]:
    FlamethrowerTrackingEventTypes.WORKFLOW_SALESFORCE_HANDOFF_ACTION_ADDED,
  [ActionBuilderActionTypes.SALESFORCE_CASE_CREATION]:
    FlamethrowerTrackingEventTypes.WORKFLOW_SALESFORCE_CASE_CREATION_ACTION_ADDED,
  [ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF]:
    FlamethrowerTrackingEventTypes.WORKFLOW_SALESFORCE_CHAT_HANDOFF_ACTION_ADDED,
  [ActionBuilderActionTypes.CATCH_ALL_HANDOFF]:
    FlamethrowerTrackingEventTypes.WORKFLOW_GENERIC_HANDOFF_ACTION_ADDED,
  [ActionBuilderActionTypes.JIRA_SERVICE_MANAGEMENT_CREATE_REQUEST]:
    FlamethrowerTrackingEventTypes.WORKFLOW_JIRA_HANDOFF_ACTION_ADDED,
  [ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF]:
    FlamethrowerTrackingEventTypes.WORKFLOW_KUSTOMER_CHAT_HANDOFF_ACTION_ADDED,
  [ActionBuilderActionTypes.KUSTOMER_CONVERSATION_CREATION]:
    FlamethrowerTrackingEventTypes.WORKFLOW_KUSTOMER_CONVERSATION_CREATION_ACTION_ADDED,
};

//Builder page nav constants
export const WIDGET = 'widget';
const EMAIL = 'email';
export const API = 'api';

export const tabs: Array<{
  beta?: boolean;
  label: string;
  view: BuilderView;
}> = [
  { label: 'Widget', view: WIDGET },
  { label: 'Email', view: EMAIL },
  { label: 'API', view: API },
  { label: 'Slack', view: 'slack' },
];

export const widgetModes = [
  {
    label: 'AutoFlow',
    value: 'autoflow',
  },
  { label: 'Classic', value: 'classic' },
] as const;
export type WidgetMode = (typeof widgetModes)[number]['value'];

export const widgetWorkflowTypes: Array<WorkflowTypes> = [
  'flamethrower-preview',
  'flamethrower-live',
];

export const interactiveEmailWorkflowTypes: WorkflowTypes[] = [
  'interactive-email-live',
  'interactive-email-preview',
];

export const emailWorkflowTypes: Array<WorkflowTypes> = ['email'];

export const apiWorkflowTypes: Array<WorkflowTypes> = [
  'api-live',
  'api-preview',
];

export const slackWorkflowTypes: Array<WorkflowTypes> = [
  'slack-live',
  'slack-preview',
];

export const viewToWorkflowTypes: Record<BuilderView, WorkflowTypes[]> = {
  api: apiWorkflowTypes,
  email: emailWorkflowTypes,
  slack: slackWorkflowTypes,
  widget: widgetWorkflowTypes,
};

export const channelToWorkflowTypes: Record<
  TranslationChannel,
  WorkflowTypes[]
> = {
  api: ['api-live'],
  email: ['email'],
  interactive_email: ['interactive-email-live'],
  slack: ['slack-live'],
  widget: ['flamethrower-live'],
};

//API error messages

export const emailConfigurationSubVersionConflictException =
  'Can only update latest draft Email Configuration version';

export const staleRecordException = 'Cannot update stale record';

export const staleEmailWorkflowException =
  'Can only update latest email workflow version';

export const SESSION_COPY_PASTE_STORAGE_KEY = 'copy-paste';

export const CIRCULAR_PATH_MESSAGE = 'Circular path found between steps';

// Multilingual Error Types
export const STALE_ENABLED_LANGUAGES_CONFIGURATION =
  'STALE_ENABLED_LANGUAGES_CONFIGURATION';

export const STALE_WIDGET_TRANSLATION = 'STALE_WIDGET_TRANSLATION';

export const STALE_EMAIL_TRANSLATION = 'STALE_EMAIL_TRANSLATION';

export const TEMPLATE_INTENT_ID = 'template';
export const TEMPLATE_INTENT_WORKFLOW_ID_PREFIX = `${TEMPLATE_INTENT_ID}_`;
export const TEMPLATE_CLONE_TOAST_OPTIONS = {
  subtitle:
    'Replace the template elements with your specific business logic, such as Actions and Handoffs, to align it with your needs.',
  title: 'Customize and modify the template to apply your business logic.',
  variant: 'warning',
} as const;

// API error types
export const EMPTY_WORKFLOW = 'EMPTY_WORKFLOW';
export const NO_LIVE_VERSION_IS_PUBLISHED = 'NO_LIVE_VERSION_IS_PUBLISHED';
export const EMAIL_WORKFLOW_NOT_CREATED = 'EMAIL_WORKFLOW_NOT_CREATED';
export const API_WORKFLOW_NOT_FOUND = 'API_WORKFLOW_NOT_FOUND';

export const routeFilterOptions: {
  label: CanvasWorkflowBuilderState['routeFilter'];
  value: CanvasWorkflowBuilderState['routeFilter'];
}[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Handoff Workflow',
    value: 'Handoff Workflow',
  },
  {
    label: 'Workflow - Classic',
    value: 'Workflow - Classic',
  },
  {
    label: 'Workflow - AutoFlow',
    value: 'Workflow - AutoFlow',
  },
];

export const actionFilterOptions: {
  label: CanvasWorkflowBuilderState['actionFilter'];
  value: CanvasWorkflowBuilderState['actionFilter'];
}[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'API',
    value: 'API',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];
