import React, { Dispatch, FC, SetStateAction } from 'react';

import MarkAsNotDeflected from './MarkAsNotDeflected';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import TicketCustomFields from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/custom-fields';
import TicketTitle from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/TicketTitle';
import { Spacer } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { SalesforceCommonCustomization } from 'src/types/workflowBuilderAPITypes';

interface TicketCreationOnlyProps {
  customizationData: SalesforceCommonCustomization;
  setCustomizationData: Dispatch<SetStateAction<SalesforceCommonCustomization>>;
}

const TicketCreationOnly: FC<
  React.PropsWithChildren<TicketCreationOnlyProps>
> = ({ customizationData, setCustomizationData }) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  return (
    <>
      <TicketTitle contextVariables={contextVariables} />
      <Spacer height='28px' />
      <TicketCustomFields
        contextVariables={contextVariables}
        customizationData={customizationData}
      />
      <Spacer height='28px' />
      <MarkAsNotDeflected
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
    </>
  );
};

export default TicketCreationOnly;
