import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { textStyles } from './constants';
import { NA } from 'src/constants/solve';

interface TicketRequestItemProps {
  content: string | null;
  title: string;
}

const TicketRequestItem = ({ content, title }: TicketRequestItemProps) => {
  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' rowGap={0.5}>
      <Typography color={palette.colors.grey[600]} variant='font12'>
        {title}:
      </Typography>

      {!content && (
        <Typography color={palette.colors.grey[600]} variant='font14'>
          {NA}
        </Typography>
      )}
      <Box
        dangerouslySetInnerHTML={{ __html: content || '' }}
        sx={textStyles}
      ></Box>
    </Box>
  );
};

export default TicketRequestItem;
