import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material';
import { IconCopy, IconWorld } from '@tabler/icons-react';

import {
  Colors,
  IconButton,
  theme,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import {
  CanvasWorkflowBuilderState,
  selectIsDraft,
  setTargetStepId,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import metricsIcon from '../assets/icons/metrics.svg';
import { useGetBuilderQueryParams } from '../hooks';
import BranchStepMetricsWindow from '../step-level-metrics/BranchStepMetricsWindow';
import StepMetricsWindow from '../step-level-metrics/StepMetricsWindow';
import { handleCopyGroupOfSteps } from '../utils';
import { EditMenuDeleteButton } from './EditMenuDeleteButton';
import { IconButtonWithActive } from './StyledComponents';
import editIcon from 'src/assets/images/edit-icon.svg';
import Spinner from 'src/components/spinner';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { ForethoughtEditMenuProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  Features,
  Pages,
} from 'src/pages/workflow-builder-edit/workflow-builder/appCuesIdentifiers';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import {
  selectCanStepBeDeleted,
  selectCanvasWorkflowBuilder,
  selectIntentTitle,
  selectIsEditingCondition,
  selectIsEditMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  setGlobalCopyToastOptions,
  setGlobalToastOptions,
} from 'src/slices/ui/uiSlice';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  StepTypes,
} from 'src/utils/enums';

const TRANSLATABLE_STEP_TYPES = [
  StepTypes.TEXT_MESSAGE,
  StepTypes.BUTTONS,
  StepTypes.FORM,
];

export const ForethoughtEditMenu: FC<
  React.PropsWithChildren<ForethoughtEditMenuProps>
> = ({
  hasCondition = false,
  isDeletionDisabled = false,
  isVisible,
  onEditClick,
  onTranslationClick,
  setIsVisible,
  stepId,
  stepType,
}) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  // Selectors
  const userCanEditStep = useSelector(selectUserCan('edit_step'));
  const { workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const intentTitle = useSelector(selectIntentTitle);
  const userCanDeleteStep = useSelector(selectUserCan('delete_step'));
  const canDelete =
    useSelector(selectCanStepBeDeleted(stepId)) && userCanDeleteStep;
  const isEditMode = useSelector(selectIsEditMode);
  const isEditingCondition = useSelector(selectIsEditingCondition);
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const isDraft = useSelector(selectIsDraft);

  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );

  // consts
  const stepTypeByStepId = canvasData.steps[stepId]?.step_type;
  const hideEditOption =
    stepTypeByStepId === StepTypes.GO_TO_INTENT ||
    stepTypeByStepId === StepTypes.RESTART_CONVERSATION ||
    stepTypeByStepId === StepTypes.ARTICLE;
  const isUserEditing = isEditMode || isEditingCondition;
  const userDoesntHavePermissions = !userCanEditStep && !userCanDeleteStep;
  const isComponentHidden =
    isUserEditing || !isVisible || userDoesntHavePermissions;

  // Ref
  const menuRef = useRef<HTMLDivElement | null>(null);

  // States
  const [isMetricsWindowVisible, setIsMetricsWindowVisible] = useState(false);
  const [metricsWrapperPosY, setMetricsWrapperPosY] = useState(200);
  const [metricsWrapperPosX, setMetricsWrapperPosX] = useState(660);
  const [isLoadingMetricsWindow, setIsLoadingMetricsWindow] = useState(false);

  // Components
  const metricsComponent =
    stepType === StepTypes.BUTTONS ? (
      <BranchStepMetricsWindow
        isLoadingMetricsWindow={isLoadingMetricsWindow}
        left={metricsWrapperPosX}
        setIsEditMenuVisible={setIsVisible}
        setIsLoadingMetricsWindow={setIsLoadingMetricsWindow}
        setIsMetricsWindowVisible={setIsMetricsWindowVisible}
        stepId={stepId}
        top={metricsWrapperPosY}
      />
    ) : stepType === StepTypes.FORM ||
      stepType === StepTypes.TEXT_MESSAGE ||
      stepType === StepTypes.ARTICLE ? (
      <StepMetricsWindow
        isLoadingMetricsWindow={isLoadingMetricsWindow}
        left={metricsWrapperPosX}
        setIsEditMenuVisible={setIsVisible}
        setIsLoadingMetricsWindow={setIsLoadingMetricsWindow}
        setIsMetricsWindowVisible={setIsMetricsWindowVisible}
        stepId={stepId}
        top={metricsWrapperPosY}
      />
    ) : null;

  return (
    <>
      <Wrapper aria-label='Options menu' isVisible={!isComponentHidden}>
        <HoverMenu ref={menuRef}>
          {!hideEditOption && userCanEditStep && isDraft && (
            <Tooltip tooltipContent='Edit'>
              <IconButton
                aria-label='Edit step'
                onClick={() => onEditClick()}
                variant='ghost'
              >
                <SVGIcon src={editIcon} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip tooltipContent='Copy all below'>
            <IconButton
              aria-label='Copy step'
              onClick={() => {
                // remove the highlighted step if there's any
                dispatch(setTargetStepId(null));

                const group = handleCopyGroupOfSteps(
                  canvasData,
                  stepId,
                  intentTitle,
                );
                if (group) {
                  dispatch(
                    setGlobalCopyToastOptions({
                      title: `"${intentTitle}" steps are on your clipboard`,
                    }),
                  );
                  dispatchTrackingAction(
                    FlamethrowerTrackingEventTypes.COPY_WORKFLOW_STEPS,
                    {
                      intent_id: selectedWorkflowId,
                      intent_name: intentTitle,
                      num_of_steps_copied: Object.keys(group.step_map).length,
                      org_id: canvasData.org_id,
                    },
                  );
                } else {
                  dispatch(
                    setGlobalToastOptions({
                      title: `Error with copying "${intentTitle}" steps. Not allowed to copy dynamic article suggestion step`,
                      variant: 'danger',
                    }),
                  );
                }
              }}
              variant='ghost'
            >
              <IconCopy
                color={palette.colors.grey[700]}
                size={20}
                style={{ transform: 'scaleX(-1)' }}
              />
            </IconButton>
          </Tooltip>

          {metricsComponent !== null && (
            <Tooltip tooltipContent='Metrics'>
              <IconButtonWithActive
                aria-label='Metrics for step'
                data-appcues-target={getAppCuesId({
                  componentType: 'Metrics',
                  featureName: Features.CANVAS,
                  pageName: Pages.SOLVE,
                  subType: 'Button',
                })}
                isActive={isMetricsWindowVisible && !isLoadingMetricsWindow}
                onClick={() => {
                  // remove the highlighted step if there's any
                  dispatch(setTargetStepId(null));

                  setIsMetricsWindowVisible(true);
                  if (menuRef.current !== null) {
                    const position = menuRef.current.getBoundingClientRect();
                    setMetricsWrapperPosY(position.y);
                    setMetricsWrapperPosX(position.right + 20);
                  }
                  setIsLoadingMetricsWindow(true);
                  dispatchTrackingAction(
                    FlamethrowerTrackingEventTypes.STEP_LEVEL_METRICS_BUTTON_CLICKED,
                    {
                      intent_id: selectedWorkflowId,
                      intent_name: intentTitle,
                      org_id: canvasData.org_id,
                      step_type: stepTypeByStepId ?? '',
                    },
                  );
                }}
              >
                {isLoadingMetricsWindow ? (
                  <Spinner height='20px' />
                ) : (
                  <SVGIcon src={metricsIcon} />
                )}
              </IconButtonWithActive>
            </Tooltip>
          )}
          {onTranslationClick && TRANSLATABLE_STEP_TYPES.includes(stepType) && (
            <Tooltip
              tooltipContent={
                canvasData.unsaved_changes
                  ? 'To view step translations, please save your finalized workflow.'
                  : 'Translations'
              }
            >
              <IconButton
                aria-label='Show step translations'
                disabled={canvasData.unsaved_changes}
                onClick={onTranslationClick}
                size='medium'
                variant='ghost'
              >
                <IconWorld color={palette.colors.grey[700]} size={20} />
              </IconButton>
            </Tooltip>
          )}
          {isDraft && (
            <EditMenuDeleteButton
              isCurrentStepDeletable={
                canDelete && !isDeletionDisabled && !hasCondition
              }
              setIsEditMenuVisible={setIsVisible}
              stepId={stepId}
              stepType={stepType}
            />
          )}
        </HoverMenu>
      </Wrapper>
      {isMetricsWindowVisible && metricsComponent}
    </>
  );
};

const Wrapper = styled('div')<{ isVisible: boolean }>`
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

export const HoverMenu = styled('div')<{ right?: string }>`
  position: absolute;
  display: flex;
  flex-direction: row;
  background-color: ${Colors.ui.backgrounds.default};
  padding: 2px;
  box-shadow: 0 4px 12px ${theme.palette.colors.grey[100]};
  border-radius: 4px;
  z-index: 6;
  line-height: 18px;
  top: -10px;
  right: ${props => (props.right ? props.right : '10px')};
`;

const SVGIcon = styled(ReactSVG)`
  height: 20px;
  width: 20px;
`;
