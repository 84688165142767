import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { styled } from '@mui/system';

import { Typography } from '@forethought-technologies/forethought-elements';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import Spinner from 'src/components/spinner';
import * as API from 'src/services/email-builder/emailBuilderApi';
import { Routes } from 'src/utils/enums';

export default function EmailPreviewPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [srcDoc, setSrcDoc] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const version = queryParams.get('version');
    const configurationId = queryParams.get('configurationId');

    if (!version || !configurationId) {
      return setError('Configuration not found');
    }

    setIsLoading(true);
    setError('');
    setSrcDoc('');

    API.getIntentEmailConfigurationPreview({
      configurationId,
      version: parseInt(version, 10),
    })
      .then(response => setSrcDoc(response.email_content))
      .catch(() =>
        setError('Something went wrong fetching this email preview.'),
      )
      .finally(() => setIsLoading(false));
  }, [location.search]);

  return (
    <>
      <NavbarV2
        currentTabOverride='Preview'
        onGoBack={() =>
          navigate({
            pathname: Routes.WORKFLOW_BUILDER_EDIT,
            search: location.search,
          })
        }
      />
      <Container>
        {error && <Typography variant='font18'>{error}</Typography>}
        {isLoading && <Spinner />}
        {srcDoc && <Iframe srcDoc={srcDoc} />}
      </Container>
    </>
  );
}

const Container = styled('div')`
  background-color: ${props => props.theme.palette.colors.white};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Iframe = styled('iframe')`
  border: none;
  height: 100%;
  width: 100%;
`;
