import { Box, useTheme } from '@mui/material';

import {
  formatToDateOnly,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  BILL_TO,
  CONTACT,
  CONTACT_SALES,
  CONTRACT,
  EFFECTIVE_ON,
  EMAIL,
  RENEWS_ON,
  TERM,
  VIEW_CONTRACT,
  VIEW_PLANS,
  YOUR_PLAN,
} from './constants';
import LoadingSkeletonCard from './LoadingSkeletonCard';
import SubscriptionPlanPageCard from './SubscriptionPlanPageCard';
import { useGetUsageDataQuery } from 'src/services/subscription-plan/subscriptionPageApi';
interface SubscriptionPlanPageHeaderProps {
  setIsPlanDetailsDrawerOpen: () => void;
  setIsUpgradePlanDialogOpen: () => void;
}

const SubscriptionPlanPageHeader = ({
  setIsPlanDetailsDrawerOpen,
  setIsUpgradePlanDialogOpen,
}: SubscriptionPlanPageHeaderProps) => {
  const { palette } = useTheme();
  const { data, isLoading = true } = useGetUsageDataQuery();

  const { company_name, contact_email, contact_name } =
    data?.billing_information || {};

  const { name } = data?.current_plan || {};

  const loadingSkeleton = Array.from({ length: 3 }).map((_, index) => (
    <LoadingSkeletonCard key={index} />
  ));

  return (
    <Box
      display='flex'
      flexWrap='wrap'
      gap='25px'
      justifyContent='space-between'
    >
      {isLoading ? (
        loadingSkeleton
      ) : (
        <>
          <SubscriptionPlanPageCard
            bodyButtonLabel={CONTACT_SALES}
            bodyContent={
              <Box display='flex' gap='5px'>
                <Typography color={palette.colors.grey[600]} variant='font14'>
                  {RENEWS_ON}
                </Typography>
                <Typography color={palette.colors.black} variant='font14'>
                  {formatToDateOnly(data?.renewal_date || '')}
                </Typography>
              </Box>
            }
            bodyTitle={
              <Typography color={palette.colors.black} variant='font32'>
                {name}
              </Typography>
            }
            footerButtonLabel={VIEW_PLANS}
            onBodyButtonClick={setIsUpgradePlanDialogOpen}
            onFooterButtonClick={setIsPlanDetailsDrawerOpen}
            title={YOUR_PLAN}
          />
          <SubscriptionPlanPageCard
            bodyContent={
              <Box display='flex' gap='5px'>
                <Typography color={palette.colors.grey[600]} variant='font14'>
                  {TERM}
                </Typography>
                <Typography color={palette.colors.black} variant='font14'>
                  {`${data?.contract_length_months} months`}
                </Typography>
              </Box>
            }
            bodyTitle={
              <Box display='flex' flexWrap={'wrap'} gap='5px'>
                <Typography color={palette.colors.black} variant='font32'>
                  {EFFECTIVE_ON}
                </Typography>
                <Typography color={palette.colors.black} variant='font32'>
                  {formatToDateOnly(data?.start_date || '')}
                </Typography>
              </Box>
            }
            footerButtonLabel={VIEW_CONTRACT}
            title={CONTRACT}
          />
          <SubscriptionPlanPageCard
            bodyContent={
              <Box display='flex' flexDirection='column'>
                <Box display='flex' gap='5px'>
                  <Typography color={palette.colors.grey[600]} variant='font14'>
                    {CONTACT}
                  </Typography>
                  <Typography color={palette.colors.black} variant='font14'>
                    {contact_name}
                  </Typography>
                </Box>
                <Box display='flex' gap='5px'>
                  <Typography color={palette.colors.grey[600]} variant='font14'>
                    {EMAIL}
                  </Typography>
                  <Typography color={palette.colors.black} variant='font14'>
                    {contact_email}
                  </Typography>
                </Box>
              </Box>
            }
            bodyTitle={
              <Typography color={palette.colors.black} variant='font32'>
                {company_name}
              </Typography>
            }
            title={BILL_TO}
          />
        </>
      )}
    </Box>
  );
};

export default SubscriptionPlanPageHeader;
