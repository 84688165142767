import { IrReport } from '../../types/irReportApiTypes';
import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';

const irReportApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getIrReport: builder.query<IrReport | null, void>({
      providesTags: ['IRReport'],
      query: () => ({
        method: 'GET',
        url: SOLVE_V2_BASE_URL + '/information-retrieval-report',
      }),
    }),
  }),
});

export const { useGetIrReportQuery } = irReportApi;
