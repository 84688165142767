import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrop, XYCoord } from 'react-dnd';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { captureMessage } from '@sentry/react';

import { CanvasActions } from '../CanvasActions';
import ChatBoundary, { ChatBoundaryProps } from '../ChatBoundary';
import { ChatContainer } from '../ChatContainer';
import { Condition } from '../conditions/Condition';
import { CanvasModes } from '../constants';
import GenericStep from '../GenericStep';
import { useGetBuilderQueryParams, useGetCanvasActions } from '../hooks';
import { type RichTextEditorProps } from 'src/components/rich-text-editor/types';
import {
  conversationChannelToProductMap,
  DRAG_AND_DROP_NEW_STEP_TYPE,
} from 'src/constants/solve';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { selectActionBuilderActions } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import {
  selectCanvasWorkflowBuilder,
  selectChatOrder,
  selectChatOrderLastStepId,
  selectEditingConditionStepId,
  selectEditingStepId,
  selectIsBranching,
  selectIsLastRestartConversationStep,
  selectIsLastStepGoToIntentStep,
  selectIsTerminalStepSet,
  selectMode,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  getArticleSuggestionActionKeyId,
  showSqueezeForGranularArticleStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducerHelpers';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  CanvasWorkflowBuilderState,
  selectActionCaseMap,
  selectAddingAction,
  selectIsSqueezingStep,
  setIsSqueezingStep,
  setMode,
  setShouldSqueezeIntoEntry,
  setSqueezeStepParentId,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { TextMessageFields } from 'src/types/workflowBuilderAPITypes';
import { RevampedDragNDrops, StepTypes } from 'src/utils/enums';

const TERMINAL_DRAG_TYPES = [
  RevampedDragNDrops.REVAMPED_FT_RESTART,
  RevampedDragNDrops.REVAMPED_FT_INTENT,
  RevampedDragNDrops.REVAMPED_FT_NON_SQUEEZE_ACTION,
];

const useDropSteps = ({
  accept = [
    DRAG_AND_DROP_NEW_STEP_TYPE,
    RevampedDragNDrops.REVAMPED_FT_ACTION,
    RevampedDragNDrops.REVAMPED_FT_ARTICLE,
  ],
  onDrop,
}: {
  accept?: (RevampedDragNDrops | typeof DRAG_AND_DROP_NEW_STEP_TYPE)[];
  onDrop: (
    isAboveMiddleOfStepElement: boolean,
    isTerminal?: boolean,
  ) => boolean | void;
}) => {
  const { contextVariables } = useGetContextVariables();

  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [isAboveMiddleOfStepElement, setIsAboveMiddleOfStepElement] =
    useState(false);

  const [{ isOver: isStepOver }, dropRef] = useDrop(() => {
    return {
      accept,
      collect: monitor => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),

      drop: ({ mode }: { mode: CanvasModes | undefined }, monitor) => {
        const itemType = monitor.getItemType();
        const isTerminal = TERMINAL_DRAG_TYPES.includes(
          itemType as RevampedDragNDrops,
        );

        const isReturnEarly = onDrop(isAboveMiddleOfStepElement, isTerminal);

        if (isReturnEarly) {
          return { failed: true };
        }

        if (mode) {
          dispatch(setMode({ contextVariables, mode }));
        }
      },

      hover: (
        _: { mode: CanvasModes | undefined },
        monitor: DropTargetMonitor,
      ) => {
        const itemType = monitor.getItemType();
        const isTerminal = TERMINAL_DRAG_TYPES.includes(
          itemType as RevampedDragNDrops,
        );

        if (!ref.current) {
          return;
        }

        if (isTerminal) {
          setIsAboveMiddleOfStepElement(false);
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;
        setIsAboveMiddleOfStepElement(hoverClientY < hoverMiddleY);
      },
    };
  }, [onDrop, accept, isAboveMiddleOfStepElement, contextVariables]);

  dropRef(ref);

  return {
    dropRef: ref,
    isAboveMiddleOfStepElement,
    isStepOver,
  } as const;
};

interface ChatListProps {
  actionPanelVisibilityParameters: ActionPanelMode;
  editorRef: RichTextEditorProps['editorRef'];
  hasWorkflowConflict: boolean;
  isCanvasDisabled: boolean;
  isOver: boolean;
  isPasteAvailable: boolean;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
}

const ChatList: React.FC<React.PropsWithChildren<ChatListProps>> = ({
  actionPanelVisibilityParameters,
  editorRef,
  hasWorkflowConflict,
  isCanvasDisabled,
  isOver,
  isPasteAvailable,
  setActionPanelVisibilityParameters,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const dispatch = useAppDispatch();
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);
  const [isHoverBeforeFirstStep, setIsHoverBeforeFirstStep] = useState(false);
  const [isHoverArticleButtons, setIsHoverArticleButtons] = useState(false);

  const { data } = useGetFeatureFlagsQuery();

  const isBranching = useSelector(selectIsBranching);

  const chatOrder = useSelector(selectChatOrder);
  const isTerminalStepSet = useSelector(selectIsTerminalStepSet);
  const isSqueezingStep = useSelector(selectIsSqueezingStep);

  const onDrop = useCallback(
    (type: 'initial' | 'end') => (_: boolean, isTerminal?: boolean) => {
      if ((type === 'initial' || chatOrder.length === 0) && !isTerminal) {
        dispatch(setIsSqueezingStep(true));
        dispatch(setShouldSqueezeIntoEntry(true));
        setIsHoverBeforeFirstStep(true);
        return;
      }

      dispatch(setIsSqueezingStep(false));
      dispatch(setShouldSqueezeIntoEntry(false));
      setIsHoverBeforeFirstStep(false);
      dispatch(setSqueezeStepParentId(''));
    },
    [chatOrder.length, dispatch],
  );

  const { dropRef: initialDropRef, isStepOver: isInitialStepOver } =
    useDropSteps({ onDrop: onDrop('initial') });

  const { dropRef, isStepOver } = useDropSteps({
    accept:
      isBranching || isTerminalStepSet
        ? []
        : [
            DRAG_AND_DROP_NEW_STEP_TYPE,
            RevampedDragNDrops.REVAMPED_FT_ARTICLE,
            RevampedDragNDrops.REVAMPED_FT_ACTION,
            ...TERMINAL_DRAG_TYPES,
          ],
    onDrop: onDrop('end'),
  });

  //Selectors
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const editingStepId = useSelector(selectEditingStepId);
  const actions = useSelector(state =>
    selectActionBuilderActions(state, data?.feature_flags ?? []),
  );
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const addingAction = useSelector(selectAddingAction);
  const mode = useSelector(selectMode);

  const isSqueezingConditionMode =
    isSqueezingStep && mode === CanvasModes.CONDITION;
  const isEditMode = editingStepId !== '';
  const isEditingAction = mode === CanvasModes.ACTION_EDIT;
  const { canvas_action_id_to_action_component, is_draft, steps } = canvasData;
  const hasSteps = !!Object.keys(steps).length;
  const showSqueezeHover =
    isAddButtonVisible &&
    !isSqueezingStep &&
    actionPanelVisibilityParameters === 'hidden';
  const stepIsNotLast = squeezeStepParentId !== lastStepId;
  const showSqueezeForGranularArticle = showSqueezeForGranularArticleStep(
    canvas_action_id_to_action_component ?? {},
    actions,
    squeezeStepParentId ?? '',
    lastStepId ?? '',
  );

  const shouldShowSqueezeStepHover =
    showSqueezeHover &&
    stepIsNotLast &&
    !isEditMode &&
    !isCanvasDisabled &&
    showSqueezeForGranularArticle;

  const shouldShowPasteStepHover =
    isPasteAvailable &&
    showSqueezeHover &&
    !isTerminalStepSet &&
    !isEditMode &&
    !isCanvasDisabled &&
    [
      CanvasModes.MESSAGE,
      CanvasModes.FORM,
      CanvasModes.BUTTON,
      CanvasModes.CONDITION,
    ].includes(mode);

  const shouldShowSqueezeOutlineChat =
    isSqueezingStep &&
    !isEditMode &&
    [
      CanvasModes.MESSAGE,
      CanvasModes.FORM,
      CanvasModes.BUTTON,
      CanvasModes.EMBED,
      CanvasModes.CSAT_TRIGGER_POINT,
      CanvasModes.ATTACHMENT_ANALYSIS_UPLOAD,
    ].includes(mode);

  const actionStepsToHide = Object.values(
    canvas_action_id_to_action_component,
  ).flatMap(({ steps }) =>
    steps.filter(stepId => {
      const step = canvasData.steps[stepId];
      if (!step) {
        captureMessage('Step not found in ChatList actionStepsToHide', {
          extra: {
            stepId,
          },
          level: 'error',
        });
      }

      return !(
        step?.step_type === StepTypes.TEXT_MESSAGE &&
        (step?.step_fields as TextMessageFields).message ===
          'Let me find more ways to help you.'
      );
    }),
  );

  const shouldShowInitialDropMessage =
    (chatOrder.length === 0 && isStepOver) || isInitialStepOver;

  const isSqueezingAction =
    isSqueezingStep &&
    (mode === CanvasModes.ACTION || mode === CanvasModes.ACTION_EDIT);

  const isSqueezingArticle =
    isSqueezingStep && mode === CanvasModes.ARTICLE_PICKER;

  const shouldShowSqueezeEntryStepHover =
    showSqueezeHover &&
    chatOrder.length > 0 &&
    !isEditMode &&
    !isCanvasDisabled;

  const isSqueezing = isSqueezingStep || isSqueezingAction;

  const shouldHideEmptyOutline =
    isSqueezing ||
    isBranching ||
    isEditMode ||
    isTerminalStepSet ||
    (isEditingAction && !addingAction);

  const getSqueezedChatOutlineType = (
    stepId: string,
    allowAnyStepId = false,
  ): ChatBoundaryProps['type'] => {
    const displaySqueezeStepHoverState =
      shouldShowSqueezeStepHover &&
      !steps[stepId]?.condition_name &&
      (squeezeStepParentId === stepId || allowAnyStepId);

    if (
      (squeezeStepParentId !== stepId || shouldSqueezeIntoEntry) &&
      !allowAnyStepId
    ) {
      return 'hidden';
    }

    if (displaySqueezeStepHoverState) {
      return 'squeeze-button';
    } else if (isSqueezingStep && addingAction) {
      return 'adding-action';
    } else if (isSqueezingAction) {
      return 'action';
    } else if (isSqueezingArticle) {
      return 'article-picker';
    } else if (shouldShowPasteStepHover) {
      return 'paste';
    } else if (shouldShowSqueezeOutlineChat) {
      return 'message';
    } else if (isSqueezingConditionMode) {
      return 'condition';
    } else {
      return 'hidden';
    }
  };

  const getEntryChatOutlineType = (): ChatBoundaryProps['type'] => {
    if (shouldSqueezeIntoEntry) {
      if (isSqueezingArticle) {
        return 'article-picker';
      } else if (isSqueezingStep && addingAction) {
        return 'adding-action';
      } else if (isSqueezingAction) {
        return 'action';
      } else if (addingAction) {
        return 'adding-action';
      } else if (shouldShowSqueezeOutlineChat) {
        return 'message';
      } else {
        return 'hidden';
      }
    } else if (shouldShowSqueezeEntryStepHover) {
      return 'squeeze-button';
    } else {
      return 'hidden';
    }
  };

  const getLastChatOutlineType = (): ChatBoundaryProps['type'] => {
    if (shouldHideEmptyOutline) {
      return 'hidden';
    }

    if (mode === CanvasModes.ARTICLE_PICKER) {
      return 'article-picker';
    } else if (mode === CanvasModes.ACTION) {
      return 'action';
    } else if (mode === CanvasModes.ACTION_EDIT) {
      return 'adding-action';
    } else if (!isSqueezingStep && mode === CanvasModes.CONDITION) {
      return 'condition';
    } else if (mode === CanvasModes.GO_TO_INTENT) {
      return 'go-to-intent';
    } else if (mode === CanvasModes.CUSTOM_HANDOFF) {
      return 'custom-handoff';
    } else if (!hasSteps && shouldShowPasteStepHover) {
      return 'empty-and-paste';
    } else {
      // if (mode === CanvasModes.CONDITION) {
      //   return 'condition';
      // } else {
      return 'empty';
    }
  };

  const squeezeStepMouseOver = (stepId: string) => {
    if (!isSqueezingStep && !isEditMode) {
      setIsAddButtonVisible(true);
      dispatch(setShouldSqueezeIntoEntry(false));
      dispatch(setSqueezeStepParentId(stepId));
    }

    if (isEditMode) {
      setIsAddButtonVisible(false);
      dispatch(setIsSqueezingStep(false));
    }
  };

  return (
    <SavedChats
      onMouseLeave={() => {
        setIsAddButtonVisible(false);
      }}
    >
      {is_draft && (
        <ChatBoundaryContainer
          aria-label='before first step'
          isBeforeFirstStep
          isEditMode={isEditMode}
          onMouseOver={() => {
            if (isCanvasDisabled) {
              return;
            }

            setIsHoverBeforeFirstStep(true);
            if (!isSqueezingStep) {
              setIsAddButtonVisible(true);
              dispatch(setSqueezeStepParentId(''));
            }
          }}
          ref={initialDropRef}
          role='group'
        >
          {(isHoverBeforeFirstStep || shouldShowInitialDropMessage) && (
            <ChatBoundary
              editorRef={editorRef}
              hasWorkflowConflict={hasWorkflowConflict}
              isOver={isOver}
              isPositionedFirst={true}
              isStepOver={shouldShowInitialDropMessage}
              shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
              squeezeStepParentId={squeezeStepParentId}
              type={getEntryChatOutlineType()}
            />
          )}
        </ChatBoundaryContainer>
      )}
      {/* Since the "chat" is a linear UI, the tree has been squashed into an array representing a single branch of the tree */}
      {chatOrder.map((stepId, index) => {
        return (
          <ChatItem
            actionStepsToHide={actionStepsToHide}
            editorRef={editorRef}
            getSqueezedChatOutlineType={getSqueezedChatOutlineType}
            hasWorkflowConflict={hasWorkflowConflict}
            index={index}
            isCanvasDisabled={isCanvasDisabled}
            isHoverArticleButtons={isHoverArticleButtons}
            isHoverBeforeFirstStep={isHoverBeforeFirstStep}
            isLastStep={index === chatOrder.length - 1}
            isLastStepOver={isStepOver && !isTerminalStepSet}
            isOver={isOver}
            key={stepId}
            previousStepId={chatOrder[index - 1]}
            setActionPanelVisibilityParameters={
              setActionPanelVisibilityParameters
            }
            setIsHoverArticleButtons={setIsHoverArticleButtons}
            setIsHoverBeforeFirstStep={setIsHoverBeforeFirstStep}
            shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
            squeezeStepMouseOver={squeezeStepMouseOver}
            squeezeStepParentId={squeezeStepParentId}
            stepId={stepId}
          />
        );
      })}
      {is_draft && (
        <ChatBoundaryContainer
          aria-label='after last step'
          isEditMode={isEditMode}
          onMouseOver={() => {
            if (isCanvasDisabled) {
              return;
            }

            if (!isSqueezingStep) {
              setIsAddButtonVisible(true);
              dispatch(setSqueezeStepParentId(''));
            }
          }}
          ref={dropRef}
          role='group'
        >
          <ChatBoundary
            editorRef={editorRef}
            hasWorkflowConflict={hasWorkflowConflict}
            isOver={isOver}
            shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
            squeezeStepParentId={squeezeStepParentId}
            type={getLastChatOutlineType()}
          />
          <WorkflowMessage hasWorkflowConflict={hasWorkflowConflict} />
        </ChatBoundaryContainer>
      )}
    </SavedChats>
  );
};

interface ChatItemProps
  extends Pick<
    ChatListProps,
    | 'shouldSqueezeIntoEntry'
    | 'squeezeStepParentId'
    | 'isOver'
    | 'hasWorkflowConflict'
    | 'editorRef'
    | 'isCanvasDisabled'
    | 'setActionPanelVisibilityParameters'
  > {
  actionStepsToHide: string[];
  getSqueezedChatOutlineType: (
    stepId: string,
    allowAnyStepId?: boolean,
  ) => ChatBoundaryProps['type'];
  index: number;
  isHoverArticleButtons: boolean;
  isHoverBeforeFirstStep: boolean;
  isLastStep: boolean;
  isLastStepOver: boolean;
  previousStepId?: string;
  setIsHoverArticleButtons: (isHover: boolean) => void;
  setIsHoverBeforeFirstStep: (isHover: boolean) => void;
  squeezeStepMouseOver: (stepId: string) => void;
  stepId: string;
}

const ChatItem = ({
  actionStepsToHide,
  editorRef,
  getSqueezedChatOutlineType,
  hasWorkflowConflict,
  index,
  isCanvasDisabled,
  isHoverArticleButtons,
  isHoverBeforeFirstStep,
  isLastStep,
  isLastStepOver,
  isOver,
  previousStepId = '',
  setActionPanelVisibilityParameters,
  setIsHoverArticleButtons,
  setIsHoverBeforeFirstStep,
  shouldSqueezeIntoEntry,
  squeezeStepMouseOver,
  squeezeStepParentId,
  stepId,
}: ChatItemProps) => {
  const dispatch = useAppDispatch();
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const isBranching = useSelector(selectIsBranching);
  const { view } = useGetBuilderQueryParams();

  const { actions, isLoading } = useGetCanvasActions({
    product: conversationChannelToProductMap[view],
    shouldReturnAll: true,
  });

  const highlightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (stepId === canvasData.targetStepId && !isLoading) {
      highlightRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [canvasData.targetStepId, isLoading, stepId]);

  const editingConditionStepId = useSelector(selectEditingConditionStepId);
  const editingStepId = useSelector(selectEditingStepId);
  const actionCaseMap = useSelector(selectActionCaseMap);
  const { canvas_action_id_to_action_component, is_draft, steps } = canvasData;
  const actionStepIds = Object.values(canvas_action_id_to_action_component).map(
    ({ steps }) => steps,
  );
  const isTerminalStepSet = useSelector(selectIsTerminalStepSet);

  const isInMultiStepAction = actionStepIds.some(
    stepIds => stepIds.includes(stepId) && stepIds.length > 1,
  );
  const canOnlyAddStepAbove = isLastStep && (isBranching || isTerminalStepSet);

  const onDrop = useCallback(
    (isAboveMiddleOfStepElement: boolean, isTerminal?: boolean) => {
      if (canOnlyAddStepAbove && !isAboveMiddleOfStepElement) {
        return true;
      }

      if ((!isAboveMiddleOfStepElement && isLastStep) || isTerminal) {
        // this is the last step, remove squeeze state and add to end
        dispatch(setIsSqueezingStep(false));
        dispatch(setShouldSqueezeIntoEntry(false));
        setIsHoverBeforeFirstStep(false);
        dispatch(setSqueezeStepParentId(''));
        return;
      }

      dispatch(setIsSqueezingStep(true));

      if (!previousStepId && isAboveMiddleOfStepElement) {
        // we are squeezing into entry
        dispatch(setShouldSqueezeIntoEntry(true));
        setIsHoverBeforeFirstStep(true);
        return;
      }

      dispatch(setShouldSqueezeIntoEntry(false));
      setIsHoverBeforeFirstStep(false);

      if (isAboveMiddleOfStepElement) {
        dispatch(setSqueezeStepParentId(previousStepId));
        return;
      }

      dispatch(setSqueezeStepParentId(stepId));
    },
    [
      canOnlyAddStepAbove,
      isLastStep,
      previousStepId,
      setIsHoverBeforeFirstStep,
      dispatch,
      stepId,
    ],
  );

  const { dropRef, isAboveMiddleOfStepElement, isStepOver } = useDropSteps({
    accept: isInMultiStepAction
      ? []
      : isLastStep
      ? [
          DRAG_AND_DROP_NEW_STEP_TYPE,
          RevampedDragNDrops.REVAMPED_FT_ARTICLE,
          RevampedDragNDrops.REVAMPED_FT_ACTION,

          ...TERMINAL_DRAG_TYPES,
        ]
      : undefined,
    onDrop,
  });

  const actionsStepMap = canvas_action_id_to_action_component ?? {};
  const actionIds: string[] = [];
  const actionsStepsKey = getArticleSuggestionActionKeyId(
    actionsStepMap,
    actions,
    stepId,
  );
  const shouldDisplayActionCaseSelector = !!actionsStepsKey;

  const isArticleButtonsYes = !!actionCaseMap[stepId];
  Object.keys(actionsStepMap).forEach(pairId => {
    if (actionsStepMap[pairId]?.entry_step_id === stepId) {
      actionIds.push(actionsStepMap[pairId].action_id);
    }
  });
  const isEditMode = editingStepId !== '';
  const shouldShowDropItHere =
    (isStepOver || (isLastStep && isLastStepOver)) &&
    !(canOnlyAddStepAbove && !isAboveMiddleOfStepElement);

  const shouldShowChatBoundary =
    (is_draft && !isHoverBeforeFirstStep) || shouldShowDropItHere;

  return (
    <ChatContainer
      aria-label={`chat step #${index + 1}`}
      isEditMode={isEditMode}
      key={stepId}
      onMouseOver={() => {
        if (isCanvasDisabled) {
          return;
        }

        if (!shouldDisplayActionCaseSelector || isArticleButtonsYes) {
          squeezeStepMouseOver(stepId);
          setIsHoverBeforeFirstStep(false);
          setIsHoverArticleButtons(isArticleButtonsYes);
        }
      }}
      ref={dropRef}
      role='group'
    >
      {!actionStepsToHide.includes(stepId) && (
        <StepContainer
          className={`step-container ${
            (isEditMode && editingStepId === stepId) ||
            editingConditionStepId === stepId
              ? 'editing'
              : ''
          }`}
          data-testid={`chat-${stepId}`}
          isHighlight={stepId === canvasData.targetStepId}
          ref={highlightRef}
        >
          <GenericStep
            isCanvasDisabled={isCanvasDisabled}
            isInAction={isInMultiStepAction}
            stepId={stepId}
          />
        </StepContainer>
      )}
      {actionIds.map(actionId => {
        const availableAction = actions.find(
          actionSearch => actionSearch.action_id === actionId,
        );

        return (
          <div key={actionId}>
            <CanvasActions
              availableAction={availableAction}
              canvasData={canvasData}
              editHandoffCallback={(actionType: ActionPanelMode) =>
                setActionPanelVisibilityParameters(actionType)
              }
              isCanvasDisabled={isCanvasDisabled}
              shouldDisplayActionCaseSelector={shouldDisplayActionCaseSelector}
              squeezeStepMouseOver={squeezeStepMouseOver}
              stepId={stepId}
            />
          </div>
        );
      })}
      {steps[stepId]?.condition_name && (
        <Condition
          isCanvasDisabled={isCanvasDisabled}
          step={steps[stepId]}
          stepId={stepId}
        />
      )}
      {shouldShowChatBoundary && (
        <ChatBoundary
          editorRef={editorRef}
          hasWorkflowConflict={hasWorkflowConflict}
          isDragAboveMiddleOfStepElement={
            isAboveMiddleOfStepElement && !isLastStepOver
          }
          isOver={isOver}
          isStepOver={shouldShowDropItHere}
          label={`squeeze for step ${index + 1}`}
          shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
          squeezeStepParentId={squeezeStepParentId}
          type={getSqueezedChatOutlineType(
            stepId,
            isHoverArticleButtons && isArticleButtonsYes,
          )}
        />
      )}
    </ChatContainer>
  );
};

interface WorkflowMessageProps {
  hasWorkflowConflict: boolean;
}

const WorkflowMessage = ({ hasWorkflowConflict }: WorkflowMessageProps) => {
  const { palette } = useTheme();

  const isTerminalStepSet = useSelector(selectIsTerminalStepSet);
  const isBranching = useSelector(selectIsBranching);
  const isLastStepGoToIntentStep = useSelector(selectIsLastStepGoToIntentStep);
  const isLastRestartConversationStep = useSelector(
    selectIsLastRestartConversationStep,
  );
  const isSqueezingStep = useSelector(selectIsSqueezingStep);

  function getStatusText(): string {
    if (hasWorkflowConflict) {
      return 'Editing disabled due to conflict with other editors';
    }

    if (isSqueezingStep) {
      return '';
    }

    if (isLastStepGoToIntentStep) {
      return 'No additional step could be added as the workflow has routed to another intent';
    }

    if (isLastRestartConversationStep) {
      return 'No additional step could be added as the workflow has restarted';
    }

    if (isTerminalStepSet) {
      return 'Terminal step set for this workflow';
    }

    if (isBranching) {
      return 'Select a condition or button option to continue';
    }

    return '';
  }

  return (
    <Box padding='20px 64px' textAlign='center' width='100%'>
      <Typography color={palette.colors.grey[500]} variant='font14'>
        {getStatusText()}
      </Typography>
    </Box>
  );
};

export default ChatList;

const SavedChats = styled('div')`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const StepContainer = styled('div')<{ isHighlight?: boolean }>`
  display: flex;
  position: relative;
  background-color: ${props =>
    props.isHighlight
      ? `${props.theme.palette.colors.blue[200]}`
      : 'transparent'};

  transition: background-color 0.5s ease-in-out;
`;

const ChatBoundaryContainer = styled('div')<{
  isBeforeFirstStep?: boolean;
  isEditMode: boolean;
}>`
  flex: 1 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: ${props => (props.isBeforeFirstStep ? '30' : '0')}px;
  position: relative;

  &:hover {
    .styled-line {
      ${props =>
        props.isEditMode ? 'visibility: hidden;' : 'visibility: visible;'}
    }
  }

  &:first-child {
    flex: 0;
  }
`;
