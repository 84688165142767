import React from 'react';
import { OutlinedInput } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { componentText } from './componentText';
import PhoneNumberInput from './PhoneNumberInput';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { isEmailValid } from 'src/pages/solve-analytics/macro-controls-view/utils/handleEmailUtil';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { ContextVariableValue as ContextVariableValueType } from 'src/types/workflowBuilderAPITypes';
import { ContextVariableTypes } from 'src/utils/enums';
import { getIsNumberValid, isPhoneNumberValid } from 'src/utils/validators';

interface ContextVariableValueProps {
  contextVariable: ContextVariable;
  label: string;
  setValue: (newValue: ContextVariableValueType) => void;
  type: keyof typeof ContextVariableTypes;
  value: ContextVariableValueType;
}

const ContextVariableValue: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<ContextVariableValueProps>>
> = ({ contextVariable, label, setValue, type, value }) => {
  return (
    <InputComponent
      contextVariable={contextVariable}
      inputChange={setValue}
      inputType={type}
      label={label}
      value={value}
    />
  );
};

export default ContextVariableValue;

const InputComponent = ({
  contextVariable,
  inputChange,
  inputType,
  label,
  value,
}: {
  contextVariable: ContextVariable;
  inputChange: (newValue: ContextVariableValueType) => void;
  inputType: keyof typeof ContextVariableTypes;
  label: string;
  value: ContextVariableValueType;
}) => {
  const theme = useTheme();

  if (inputType === 'SHORT_TEXT') {
    return (
      <ContextVariableAutocomplete
        aria-label={label}
        onChange={inputChange}
        shouldIncludeSystemContextVariables
        value={value.toString()}
      />
    );
  }
  if (inputType === 'LONG_TEXT') {
    return (
      <ContextVariableAutocomplete
        aria-label={label}
        multiline
        onChange={inputChange}
        rows={4}
        shouldIncludeSystemContextVariables
        value={value.toString()}
      />
    );
  }
  if (inputType === 'EMAIL') {
    return (
      <TextField
        aria-label={label}
        autoCapitalize='none'
        autoCorrect='off'
        error={
          value.toString() &&
          !isEmailValid(value.toString()) &&
          componentText.email_error
        }
        maxLength={340}
        onChange={e => inputChange(e.target.value)}
        type='email'
        value={value.toString()}
      />
    );
  }

  if (inputType === 'LIST' && !!contextVariable.list_type_options) {
    return (
      <>
        <SelectDropdown
          aria-label={label}
          id={contextVariable.context_variable_id}
          onChange={(e: { target: { value: string | boolean } }) => {
            inputChange(e.target.value);
          }}
          options={contextVariable.list_type_options}
          placeholder={componentText.checkbox_placeholder}
          value={value.toString()}
        />
      </>
    );
  }

  if (inputType === 'PHONE') {
    return (
      <>
        <PhoneNumberInput
          aria-label={label}
          autofillColor={theme.palette.colors.grey[100]}
          backgroundColor={theme.palette.colors.white}
          borderColor={theme.palette.colors.grey[300]}
          isPhoneNumberValid={isPhoneNumberValid(value.toString())}
          onChange={(value: string) => inputChange(value)}
          placeholderColor={theme.palette.text.primary}
          textColor={theme.palette.text.primary}
          validationMessageText={componentText.phone_error}
          value={value.toString()}
        />
      </>
    );
  }
  if (inputType === 'NUMBER') {
    return (
      <TextField
        aria-label={label}
        error={
          value.toString() &&
          !getIsNumberValid(value.toString()) &&
          componentText.number_error
        }
        onChange={e => inputChange(parseInt(e.target.value) || '')}
        value={value.toString()}
      />
    );
  }
  if (inputType === 'CHECKBOX') {
    return (
      <SelectDropdown
        aria-label={label}
        id={contextVariable.context_variable_id}
        onChange={e => inputChange(e.target.value === 'Yes')}
        options={[
          {
            label: componentText.checkbox_yes,
            value: 'Yes',
          },
          {
            label: componentText.checkbox_no,
            value: 'No',
          },
        ]}
        placeholder={componentText.checkbox_placeholder}
        value={value === true ? 'Yes' : value === false ? 'No' : ''}
      />
    );
  }

  if (inputType === 'DATE') {
    return (
      <OutlinedInput
        aria-label={label}
        fullWidth
        inputProps={{
          sx: { height: '40px', padding: `0 ${theme.spacing(2)}` },
        }}
        onChange={e => {
          e.preventDefault();
          inputChange(e.target.value);
        }}
        type='date'
        value={value.toString()}
      ></OutlinedInput>
    );
  }

  if (
    inputType === 'MULTI_SELECT_LIST' &&
    !!contextVariable.list_type_options
  ) {
    return (
      <>
        <SelectDropdown
          aria-label={label}
          id={contextVariable.context_variable_id}
          multiple
          onChange={e => {
            inputChange(e.target.value);
          }}
          options={contextVariable.list_type_options}
          value={value ? (value as string[]) : []}
        />
      </>
    );
  }

  // Fall back to text field:
  return (
    <TextField
      aria-label={label}
      onChange={e => inputChange(e.target.value)}
      value={value.toString()}
    />
  );
};
