import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import thumbSvg from 'src/assets/images/grey-thumb.svg';
import moodAngrySvg from 'src/assets/images/mood-angry.svg';
import moodHappySvg from 'src/assets/images/mood-happy.svg';

type EmptyStateCardVariant = 'csat' | 'feedback';

export const EmptyStateCard = ({
  variant,
}: {
  variant: EmptyStateCardVariant;
}) => {
  const renderImage = () => {
    if (variant === 'csat') {
      return (
        <>
          <img height={64} src={moodHappySvg} width={64} />
          <img height={64} src={moodAngrySvg} width={64} />
        </>
      );
    }
    if (variant === 'feedback') {
      return (
        <>
          <img height={64} src={thumbSvg} width={64} />
          <img
            height={64}
            src={thumbSvg}
            style={{ transform: 'rotate(180deg)' }}
            width={64}
          />
        </>
      );
    }
  };
  return (
    <Box display='flex' flex={1} flexDirection='column' gap='18px' pt='38px'>
      <Box display='flex' justifyContent='center'>
        {renderImage()}
      </Box>
      <Box alignSelf='center' display='flex' width='172px'>
        <Typography variant='font14'>
          Insufficient data available to display at the moment
        </Typography>
      </Box>
    </Box>
  );
};
