import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { getPercentagePart } from './helpers';
import TicketStatusItem from './TicketStatusItem';
import { TriageTicketOverviewResponse } from 'src/services/triage/types';

export interface TicketStatusProps {
  data?: TriageTicketOverviewResponse;
  isLoading: boolean;
}

const TicketStatus = ({ data, isLoading }: TicketStatusProps) => {
  const { palette } = useTheme();
  const compute = getPercentagePart(data?.total_predictions_count);
  const resolvedValue = compute(data?.resolved_predictions_count);
  const openValue = compute(data?.open_predictions_count);
  const inProgressValue = compute(data?.in_progress_predictions_count);
  const closedValue = compute(data?.closed_predictions_count);

  return (
    <Box alignItems='center' columnGap={4} display='flex'>
      <TicketStatusItem
        color={palette.colors.green[500]}
        isLoading={isLoading}
        title='resolved'
        value={resolvedValue}
      />
      <TicketStatusItem
        color={palette.colors.red[500]}
        isLoading={isLoading}
        title='open'
        value={openValue}
      />
      <TicketStatusItem
        color={palette.colors.black}
        isLoading={isLoading}
        title='in progress'
        value={inProgressValue}
      />
      <TicketStatusItem
        color={palette.colors.green[500]}
        isLoading={isLoading}
        title='closed'
        value={closedValue}
      />
    </Box>
  );
};

export default TicketStatus;
