import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';

type Props = {
  isLightMode?: boolean;
  text?: string;
  title?: string;
};

const ReusableTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isLightMode,
  text,
  title,
}) => {
  const [childReference, setChildReference] = useState<HTMLDivElement | null>(
    null,
  );
  const [arrowReference, setArrowReference] = useState<HTMLDivElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { attributes, styles } = usePopper(childReference, popperElement, {
    modifiers: [
      { enabled: true, name: 'offset', options: { offset: [0, 10] } },
      {
        enabled: true,
        name: 'arrow',
        options: {
          element: arrowReference,
        },
      },
    ],
  });

  const displayTooltip =
    (title && title.length > 0) || (text && text.length > 0);

  return (
    <>
      {displayTooltip && (
        <ChildrenWrapper data-testid='tooltip' ref={setChildReference}>
          {children}
          <TooltipContainer
            className='tooltip-container'
            ref={setPopperElement}
            {...attributes.popper}
            data-testid='tooltip-container'
            isLightMode={isLightMode}
            style={styles.popper}
          >
            <Arrow
              className='arrow'
              ref={setArrowReference}
              style={styles.arrow}
            />
            <Title>{title}</Title>
            <Description isLightMode={isLightMode}>{text}</Description>
          </TooltipContainer>
        </ChildrenWrapper>
      )}
    </>
  );
};

export default ReusableTooltip;

const Arrow = styled('div')`
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: -4px;

  &:after {
    content: ' ';
    background-color: ${theme.palette.colors.slate[700]};
    position: absolute;
    left: 0;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
  }
`;

const TooltipContainer = styled('div')<{
  isLightMode: boolean | undefined;
}>`
  background-color: ${props =>
    props.isLightMode
      ? Colors.ui.backgrounds.default
      : theme.palette.colors.slate[700]};
  border-radius: var(--border-radius-button);
  cursor: auto;
  display: block;
  letter-spacing: 0;
  padding: 7px 12px;
  width: 145px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  visibility: hidden;
  cursor: pointer;
  box-shadow: ${props =>
    props.isLightMode ? '0px 20px 30px rgba(0, 0, 0, 0.1)' : 'none'};
  border: ${props =>
    props.isLightMode ? '1px solid rgba(0, 0, 0, 0.08)' : 'none'};

  &[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
  }
`;

const ChildrenWrapper = styled('div')`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 4px;
  position: relative;
  height: fit-content;
  width: fit-content;

  &:hover {
    .tooltip-container {
      visibility: visible;
    }
  }
`;

const Title = styled('span')`
  text-align: left;
  color: var(--color-solid-white);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-s);
  padding: 7px 10px 3px;
`;

const Description = styled('span')<{ isLightMode: boolean | undefined }>`
  text-align: left;
  color: ${props =>
    props.isLightMode ? Colors.ui.text.primary : 'rgba(255, 255, 255, 0.7)'};
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-s);
  padding: 3px 10px 7px;
  text-transform: initial;
  overflow-y: scroll;
`;
