import Box, { BoxProps } from '@mui/material/Box';

import { getDiscoverSentimentEmoji } from './helpers';
import { Size, Variant } from './types';

interface DiscoverSentimentEmojiProps extends BoxProps {
  size: Size;
  variant: Variant;
}

const DiscoverSentimentEmoji = ({
  size,
  variant,
  ...boxProps
}: DiscoverSentimentEmojiProps) => {
  return (
    <Box alignItems='center' display='flex' {...boxProps}>
      <img src={getDiscoverSentimentEmoji(variant, size)} />
    </Box>
  );
};

export default DiscoverSentimentEmoji;
