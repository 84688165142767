import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';
import { Box, styled, useTheme } from '@mui/material';
import { IconBrandTelegram } from '@tabler/icons-react';

import {
  Alert,
  TextField,
  Toggle,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { useSendPreviewApiRequest } from '../hooks';
import { InteractiveApiView } from './InteractiveApiView';
import {
  getWidgetConfiguration,
  selectLegibleTextColor,
} from 'src/slices/solve-config/solveConfigSlice';
import { isForethoughtError } from 'src/slices/workflow-preview/types';
import {
  initializeApiPreview,
  selectApiPreviewState,
  setPreviewIsStream,
} from 'src/slices/workflow-preview/workflowPreviewSlice';

export const ApiPreviewWidget = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const fontColor = useSelector(selectLegibleTextColor);
  const { history, isStream } = useSelector(selectApiPreviewState);
  const sendPreviewApiRequest = useSendPreviewApiRequest();
  const hasUnexpectedError = history.some(
    error => isForethoughtError(error) && error.status >= 500,
  );

  const [query, setQuery] = useState('');

  const isFontColorLegibleOnSelection =
    tinycolor.readability(fontColor, '#BED6FB') >= 4.5;
  const fontColorToUse = isFontColorLegibleOnSelection
    ? fontColor
    : palette.text.primary;

  useEffect(() => {
    dispatch(getWidgetConfiguration());
    dispatch(initializeApiPreview());
  }, [dispatch]);

  const handleSendQuery = () => {
    sendPreviewApiRequest({ query: query });
    setQuery('');
  };

  return (
    <Container>
      <Box display='flex' flexDirection='column' gap={3}>
        <Toggle
          checked={isStream}
          label='Streaming'
          onChange={() => {
            dispatch(setPreviewIsStream(!isStream));
          }}
        />
        <Widget
          sx={{
            '.MuiTypography-root': {
              '&::selection': {
                color: fontColorToUse,
              },
            },
          }}
        >
          {hasUnexpectedError && (
            <Box
              display='flex'
              p={2}
              sx={{
                '& > div': {
                  width: '100%',
                },
              }}
            >
              <Alert
                title={
                  <>
                    Something went wrong. Please{' '}
                    <StartNewChatButton
                      onClick={() => dispatch(initializeApiPreview())}
                    >
                      start a new chat
                    </StartNewChatButton>
                  </>
                }
                variant='danger'
              />
            </Box>
          )}
          {/* Content */}
          <InteractiveApiView />
          {/* Footer */}
          <Box
            display='flex'
            flexDirection='column'
            gap='12px'
            padding={2}
            paddingBottom='24px'
          >
            <Box
              alignItems='center'
              display='flex'
              gap={1}
              justifyContent='center'
            >
              <Box
                flex='1'
                sx={{
                  '.MuiInputBase-input': {
                    padding: '2px 40px ',
                  },
                }}
              >
                <Input
                  aria-label='input box'
                  onChange={e => {
                    setQuery(e.target.value);
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSendQuery();
                    }
                  }}
                  placeholder={
                    history.length === 0 ? 'Enter query to start' : 'Type here'
                  }
                  value={query}
                />
              </Box>
              <Box height='20px' position='absolute' right='30px'>
                <Tooltip tooltipContent='Submit query'>
                  <StyledSubmitButton
                    color='white'
                    disabled={query.trim() === ''}
                    onClick={handleSendQuery}
                  >
                    <IconBrandTelegram
                      color={
                        query.trim() === ''
                          ? palette.colors.grey[500]
                          : palette.colors.black
                      }
                      size={20}
                    />
                  </StyledSubmitButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Widget>
      </Box>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  & .MuiFormControlLabel-label {
    color: white;
  }
`;

const Widget = styled('div')`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 510px;
  border-radius: 12px;
  box-shadow: 0 12px 48px rgba(0, 0, 0, 0.05);
  border: 1.6px solid #fff;
  background-color: white;
  box-shadow: 0px 0.711px 17.07px -0.711px rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(8.534759521484375px);
  // 146px is the height of the header + paddings for the static widget
  height: calc(100vh - 146px);
  position: relative;
`;

const Input = styled(TextField)`
  flex: 1;

  .MuiInputBase-root {
    border-radius: 24px;
  }

  .MuiInputBase-input {
    line-height: 20px;
  }

  .MuiInputBase-multiline {
    padding: 14px 0;
  }
`;

const StyledSubmitButton = styled('button')<{
  color: string;
}>`
  border: none;
  outline: none;
  background-color: ${props => props.color};
  cursor: pointer;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  padding: 0;

  :disabled {
    cursor: not-allowed;
  }
`;

const StartNewChatButton = styled('button')`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: ${props => props.theme.palette.colors.purple[500]};
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const ModalHeaderActionButton = styled('button')`
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:not(:disabled):not([aria-disabled='true']) {
    background-color: #00000020;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--legible-text-color);
  }

  &:disabled,
  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
