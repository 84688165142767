import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { createHrsText } from './helpers';
import capitalize from 'lodash/capitalize';
import { formatDateForTicketCard } from 'src/components/dashboard-pages/discover-topic-detail-page/helpers';
import { NA } from 'src/constants/solve';
import { DiscoverTicket } from 'src/reducers/discoverReducer/types';
import { getHoursFromSeconds } from 'src/utils/discover/helpers';

interface TicketMetricsProps {
  ticket: DiscoverTicket;
}

const TicketMetrics = ({ ticket }: TicketMetricsProps) => {
  const { palette } = useTheme();

  const getMetric = (
    metricName: string,
    metricType: 'boolean' | 'string' | 'hrs' = 'string',
  ) => {
    const target = ticket.metrics.find(item => item.name === metricName);
    if (target === undefined) {
      return null;
    }

    if (metricType === 'boolean') {
      return target.value ? 'Yes' : 'No';
    }

    if (metricType === 'hrs') {
      const hrs = getHoursFromSeconds((target.value as number) || 0);
      return createHrsText(hrs);
    }

    return target.value?.toString();
  };

  const items = [
    [
      'Date Created',
      formatDateForTicketCard(new Date(ticket.date_created).getTime()),
    ],
    ['Channel', capitalize(ticket.channel || '')],
    ['Ticket status', capitalize(ticket.status || '')],
    [
      'Resolved on first contact',
      getMetric('resolved_on_first_contact', 'boolean'),
    ],
    ['First resolution time', getMetric('first_resolution_time', 'hrs')],
    ['Full resolution time', getMetric('full_resolution_time', 'hrs')],
    ['Agent replies', getMetric('number_of_agent_replies')],
    ['Average reply time', getMetric('reply_time', 'hrs')],
  ].map(item => {
    if (!item[1]) {
      return [item[0], NA];
    }
    return item;
  });

  return (
    <Box display='flex' flexDirection='column'>
      {items.map((item, index) => (
        <Box columnGap={1} display='flex' key={index}>
          <Typography color={palette.colors.grey[600]} variant='font12'>
            {item[0]}
          </Typography>
          <Typography variant='font12'>{item[1]}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default TicketMetrics;
