import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';

const DynamicListItem = ({ children }: { children: React.ReactNode }) => {
  const { palette } = useTheme();

  return (
    <Box border={'1px solid ' + palette.colors.slate[200]} borderRadius='8px'>
      <Box
        sx={{
          background: `linear-gradient(270deg, ${palette.colors.brand.magenta} 0%, ${palette.colors.brand.deepBlue} 100%)`,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
        }}
      >
        <Typography color={palette.colors.white} variant='font14Medium'>
          Generate Dynamic Context Variable list
        </Typography>
        <Tooltip tooltipContent='Generate a collection of context variables based on array values. These variables dynamically adjust and can be utilized within Workflow Builder.'>
          <IconInfoCircle color={palette.colors.white} size={20} />
        </Tooltip>
      </Box>
      <Box display='flex' flexDirection='column' gap={1} p={2}>
        {children}
      </Box>
    </Box>
  );
};

export default DynamicListItem;
