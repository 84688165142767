export const avatarColors = [
  'avatar-gray',
  'avatar-green',
  'avatar-orange',
  'avatar-teal',
  'avatar-red',
  'avatar-sky',
  'avatar-purple',
  'avatar-violet',
  'avatar-yellow',
  'avatar-brown',
  'avatar-camo-green',
  'avatar-hot-pink',
  'avatar-light-blue',
];
