import React, { MouseEventHandler } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';
import Box from '@mui/system/Box';

import { IconButton } from '@forethought-technologies/forethought-elements';
import ThumbIcon from 'src/assets/images/article-thumb.svg';
import DiscoverMenuButton from 'src/components/discover-menu-button/DiscoverMenuButton';
import { usePatchArticleMutation } from 'src/hooks/discover/usePatchArticleMutation';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverArticle } from 'src/reducers/discoverReducer/types';
import {
  dismissArticle as dismissArticleAction,
  updateArticleFeedbackData,
} from 'src/slices/solve-insights/solveInsightsSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';
const ArticleFeedbackButtons: React.FC<
  React.PropsWithChildren<{
    article: DiscoverArticle;
    buttonSize?: 'medium' | 'large';
    setPatchedArticleModalId: (articleId: string) => void;
  }>
> = ({ article, buttonSize = 'medium', setPatchedArticleModalId }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  // make this generic after demo
  const isSolveInsights = pathname.includes(Routes.SOLVE_INSIGHTS_MAIN);
  const { patchArticleMutation } = usePatchArticleMutation();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const dismissArticle = () => {
    patchArticleMutation(article.article_id, { state: 'dismissed' });
    emitTrackingEventCallback('discover-article-dismissed', {
      article_id: article.article_id,
      topic_id: article.topic_id,
    });
    if (isSolveInsights) {
      dispatch(dismissArticleAction(article.article_id));
    }
  };
  const thumbsUpArticle: MouseEventHandler<HTMLButtonElement> = event => {
    event?.stopPropagation();
    patchArticleMutation(article.article_id, {
      feedback: { note: null, thumb: article.feedback.thumb ? null : 'up' },
    });
    emitTrackingEventCallback(
      article.feedback.thumb
        ? 'discover-article-thumbs-reset'
        : 'discover-article-thumbs-up',
      {
        article_id: article.article_id,
        topic_id: article.topic_id,
      },
    );
    if (isSolveInsights) {
      dispatch(
        updateArticleFeedbackData({
          articleId: article.article_id,
          value: { note: null, thumb: article.feedback.thumb ? null : 'up' },
        }),
      );
    }
  };
  const thumbsDownArticle: MouseEventHandler<HTMLButtonElement> = event => {
    event?.stopPropagation();
    if (article.feedback.thumb === 'down') {
      emitTrackingEventCallback('discover-article-thumbs-reset', {
        article_id: article.article_id,
        topic_id: article.topic_id,
      });
      patchArticleMutation(article.article_id, {
        feedback: { note: null, thumb: null },
      });
      if (isSolveInsights) {
        dispatch(
          updateArticleFeedbackData({
            articleId: article.article_id,
            value: { note: null, thumb: null },
          }),
        );
      }
    } else {
      setPatchedArticleModalId(article.article_id);
    }
  };

  // Show each thumb button only if the other hasn't been clicked.
  // Show the dismiss menu if the user has thumbs downed already
  return (
    <Box display='flex' gap={1}>
      {article.feedback.thumb !== 'down' ? (
        <IconButton
          aria-label='rate-up article'
          onClick={thumbsUpArticle}
          size={buttonSize}
          variant='ghost'
        >
          <ThumbUp active={article.feedback.thumb === 'up'} src={ThumbIcon} />
        </IconButton>
      ) : null}
      {article.feedback.thumb !== 'up' ? (
        <IconButton
          aria-label='rate-down article'
          onClick={thumbsDownArticle}
          size={buttonSize}
          variant='ghost'
        >
          <ThumbDown
            active={article.feedback.thumb === 'down'}
            src={ThumbIcon}
          />
        </IconButton>
      ) : null}
      {article.feedback.thumb === 'down' ? (
        <DiscoverMenuButton
          buttonSize={buttonSize}
          dismissArticleCallback={dismissArticle}
          variant='article_card'
        />
      ) : null}
    </Box>
  );
};

const ThumbUp = styled(ReactSVG)<{ active: boolean }>`
  * svg {
    fill: ${props =>
      props.active ? props.theme.palette.colors.green[100] : null};
  }
  * path {
    stroke: ${props =>
      props.active ? props.theme.palette.colors.green[500] : null};
  }
`;

const ThumbDown = styled(ReactSVG)<{ active: boolean }>`
  height: 21px;
  * svg {
    fill: ${props =>
      props.active ? props.theme.palette.colors.red[100] : null};
  }
  * path {
    stroke: ${props =>
      props.active ? props.theme.palette.colors.red[500] : null};
  }
  transform: rotate(180deg);
`;
export default ArticleFeedbackButtons;
