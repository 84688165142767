export const getIframeUrl = (previewToken: string, intentId: string) => {
  const url = SOLVE_URL.replace('embed.js', '');

  const iframeURL = new URL(
    `${url}?data-api-key=${previewToken}&full-screen=true&config-ft-disable-close=true`,
  );
  iframeURL.searchParams.append(
    '__experimental-data-ft-autonomous-agent__',
    'true',
  );

  iframeURL.searchParams.append('preview', 'true');

  if (intentId) {
    iframeURL.searchParams.append('data-intent-id', intentId);
  }

  return iframeURL.href;
};
