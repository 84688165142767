import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ForethoughtEditStepMenu } from '../edit-menu/ForethoughtEditStepMenu';
import { Container } from '../go-to-step/GoToStepComponents';
import ActiveIndicator from '../workflow-builder/business-logic/ActiveIndicator';
import StepContainer from '../workflow-builder/revamped/StepContainer';
import StepHeader from '../workflow-builder/revamped/StepHeader';
import { selectIsDraft } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { StepTypes } from 'src/utils/enums';

interface RestartConversationStepProps {
  isCanvasDisabled: boolean;
  setShouldShowEditMenu: (shouldShow: boolean) => void;
  shouldShowEditMenu: boolean;
  stepId: string;
}

const RestartConversationStep: React.FC<
  React.PropsWithChildren<RestartConversationStepProps>
> = ({
  isCanvasDisabled,
  setShouldShowEditMenu,
  shouldShowEditMenu,
  stepId,
}) => {
  const isDraft = useSelector(selectIsDraft);

  return (
    <Container
      onMouseEnter={() => setShouldShowEditMenu(true)}
      onMouseLeave={() => setShouldShowEditMenu(false)}
    >
      {
        <StepContainer isBeingEdited={false}>
          <StepHeader stepType={StepTypes.RESTART_CONVERSATION} />
          <Box
            alignItems='center'
            display='flex'
            padding='8px 16px 16px 16px'
            width='100%'
          >
            <ActiveIndicator isActive margin='0 8px 0 0' />
            <Typography variant='font16Bold'>Restart Conversation</Typography>
          </Box>
          {!isCanvasDisabled && isDraft && (
            <ForethoughtEditStepMenu
              isDeletionDisabled={false}
              isVisible={shouldShowEditMenu}
              setIsVisible={setShouldShowEditMenu}
              stepId={stepId}
              stepType={StepTypes.RESTART_CONVERSATION}
            />
          )}
        </StepContainer>
      }
    </Container>
  );
};

export default RestartConversationStep;
