import { styled } from '@mui/material/styles';

import {
  Avatar,
  Drawer,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { UserWithAnalyticsAccess } from '../types';
import UserForm from '../user-form';
import {
  useGetEmbeddedAnalyticsSettingsQuery,
  useUpdateAllowedEmailsMutation,
} from 'src/services/embedded-analytics/embeddedAnalyticsApi';
import { updateUser } from 'src/slices/team/thunks';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { getAvatarTextFromUser } from 'src/utils/sessionUtils';

interface EditUserDrawerProps {
  onCancel: () => void;
  selectedUser: UserWithAnalyticsAccess | null;
}

export default function EditUserDrawer({
  onCancel,
  selectedUser,
}: EditUserDrawerProps) {
  const dispatch = useAppDispatch();
  const [updateUserAnalyticsAccess] = useUpdateAllowedEmailsMutation();
  const { data: analyticsData } = useGetEmbeddedAnalyticsSettingsQuery();

  return (
    <Drawer isOpen={!!selectedUser} onClose={onCancel}>
      {selectedUser && (
        <InnerDrawer>
          <Typography variant='font24'>Edit user details</Typography>
          <FormContainer>
            <AvatarContainer>
              <Avatar variant='lg'>
                {getAvatarTextFromUser(selectedUser)}
              </Avatar>
            </AvatarContainer>
            <UserForm
              initialValues={selectedUser}
              mode='update'
              onCancel={onCancel}
              onSubmit={async (
                { hasAnalyticsAccess, ...userRequestBody },
                { resetForm },
              ) => {
                try {
                  await dispatch(
                    updateUser({
                      userId: selectedUser.user_id,
                      userRequestBody,
                    }),
                  ).unwrap();

                  const allowedEmails = analyticsData?.allowed_emails ?? [];
                  const updatedAllowedEmails = hasAnalyticsAccess
                    ? Array.from(
                        new Set([...allowedEmails, userRequestBody.email]),
                      )
                    : allowedEmails.filter(
                        email => email !== userRequestBody.email,
                      );

                  await updateUserAnalyticsAccess({
                    allowed_emails: updatedAllowedEmails,
                  }).unwrap();

                  // this will reset the 'dirty' state of the form to disable the button for the updated values.
                  resetForm({
                    values: {
                      ...userRequestBody,
                      hasAnalyticsAccess,
                    },
                  });
                } catch (error) {
                  console.error(error);
                  dispatch(
                    setGlobalToastOptions({
                      title: 'Something went wrong updating the user.',
                      variant: 'danger',
                    }),
                  );
                }
              }}
            />
          </FormContainer>
        </InnerDrawer>
      )}
    </Drawer>
  );
}

const InnerDrawer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 40px 32px;
`;

const FormContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 0;
`;

const AvatarContainer = styled('div')`
  padding: 32px 0 24px;
`;
