import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { IconChevronDown } from '@tabler/icons-react';

interface FormAccordionProps {
  children: React.ReactNode;
  header: React.ReactNode;
}

const FormAccordion = ({ children, header }: FormAccordionProps) => {
  return (
    <Accordion
      defaultExpanded
      disableGutters
      sx={{
        '&:before': {
          display: 'none',
        },
        borderRadius: '8px !important',
        mb: 1,
      }}
    >
      <AccordionSummary expandIcon={<IconChevronDown />}>
        {header}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '33vh',
          overflow: 'auto',
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default FormAccordion;
