import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import {
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { Button } from '@forethought-technologies/forethought-elements';
import * as Styled from './styles';
import {
  createActionBuilderAction,
  setSelectedActionTemplate,
  updateActionBuilderAction,
} from 'src/actions/action-builder-actions/actionBuilderActions';
import { useGetActionBuilderData } from 'src/hooks/useGetActionBuilderData';
import { selectSelectedInitialActionName } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import {
  useGetActionBuilderActionsQuery,
  useGetActionUsagesQuery,
} from 'src/services/action-builder/actionBuilderApi';
import { ShopifySelectedActionTemplate } from 'src/types/actionBuilderApiTypes';

const ERROR_MESSAGES: Partial<Record<string, string>> = {
  url: 'Please enter a valid url',
};

interface ShopifyFormProps {
  closeCallback: () => void;
  onSaveCallback: () => void;
  selectedAction: ShopifySelectedActionTemplate;
}

export const ShopifyForm: FC<React.PropsWithChildren<ShopifyFormProps>> = ({
  closeCallback,
  onSaveCallback,
  selectedAction,
}) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const { data: usagesData } = useGetActionUsagesQuery();
  const [isLoading, setIsLoading] = useState(false);

  const { contextVariables } = useGetActionBuilderData();

  const { data: actionsData } = useGetActionBuilderActionsQuery({});
  const { actions = [] } = actionsData ?? {};
  const initialActionName = useSelector(selectSelectedInitialActionName);

  const shopifyActions = actions
    .filter(item => item.action_type === 'shopify_order_status_template')
    .map(filteredItem => filteredItem.action_name);

  const { customer_email, customer_id, password, url, username } =
    selectedAction.action_fields;

  // Const
  const textFields = [
    {
      label: 'Action Name',
      value: selectedAction.action_name,
    },
    {
      label: 'URL',
      value: url,
    },
    {
      label: 'Username',
      value: username,
    },
    { label: 'Password', value: password },
  ];

  const contextVariableFields = [
    {
      label: 'Customer Email',
      value: customer_email,
    },
    {
      label: 'Customer ID',
      value: customer_id,
    },
  ];
  const isActive =
    (usagesData?.action_id_to_intent_ids[selectedAction.action_id ?? ''] ?? [])
      .length > 0;

  const hasEmptyFields =
    textFields.some(({ value }) => value.trim() === '') ||
    contextVariableFields.some(({ value }) => value.trim() === '');

  const isValidHttpUrl = (urlStr: string) => {
    try {
      const url = new URL(urlStr);
      return url.protocol === 'http:' || url.protocol === 'https:';
    } catch (_) {
      return false;
    }
  };

  const isValidActionName = (actionNameStr: string) => {
    // actionNameStr can not be existing in current shopify actions unless updating itself
    if (actionNameStr) {
      return (
        !shopifyActions.includes(actionNameStr) ||
        (initialActionName !== '' && actionNameStr === initialActionName)
      );
    }

    return false;
  };

  const isTextFieldValid = (valueToUpdate: string, value: string) => {
    if (valueToUpdate === 'url') {
      return value !== '' && isValidHttpUrl(value);
    }
    if (valueToUpdate === 'action_name') {
      return isValidActionName(value);
    }

    return true;
  };

  const isSubmitDisabled =
    hasEmptyFields ||
    !isValidHttpUrl(url) ||
    !isValidActionName(selectedAction.action_name);

  const submitAction = async () => {
    setIsLoading(true);
    // Remove brackets in the case the user is updating an action where the backend returns these fields wrapped in brackets.
    const customerEmail = customer_email.replace(/[{}]/g, '');
    const customerId = customer_id.replace(/[{}]/g, '');
    const action = {
      ...selectedAction,
      action_fields: {
        ...selectedAction.action_fields,
        action_name: selectedAction.action_name,
        customer_email: `{{${customerEmail}}}`,
        customer_id: `{{${customerId}}}`,
      },
    };
    if (selectedAction.action_id) {
      await dispatch(
        updateActionBuilderAction(action, selectedAction.action_id),
      );
    } else {
      await dispatch(createActionBuilderAction(action));
    }

    setIsLoading(false);
    onSaveCallback();
    closeCallback();
  };

  const updateFieldVal = useCallback(
    (value: string, type: string) => {
      if (type === 'action_name') {
        const currentAction = {
          ...selectedAction,
          [type]: value,
        };
        dispatch(setSelectedActionTemplate(currentAction));
      } else {
        const currentAction = {
          ...selectedAction,
          action_fields: {
            ...selectedAction.action_fields,
            [type]: value,
          },
        };
        dispatch(setSelectedActionTemplate(currentAction));
      }
    },
    [dispatch, selectedAction],
  );

  return (
    <Styled.ActionTemplateForm
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <Styled.InputsContainer>
        {textFields.map(({ label, value }) => {
          const valueToUpdate = label.toLowerCase().replace(' ', '_');
          const isInvalid = !isTextFieldValid(valueToUpdate, value);
          const errorMessage = ERROR_MESSAGES[valueToUpdate] ?? true;

          return (
            <Styled.InputContainer key={label}>
              <TextField
                disabled={isActive}
                error={isInvalid && errorMessage}
                key={label}
                label={label}
                onChange={e => updateFieldVal(e.target.value, valueToUpdate)}
                placeholder={label}
                type={valueToUpdate === 'username' ? 'text' : valueToUpdate}
                value={value}
              />
            </Styled.InputContainer>
          );
        })}
        {contextVariableFields.map(({ label, value }) => {
          const selectedContextVariable =
            contextVariables.find(
              ({ context_variable_id: id }) =>
                id === value.replace(/[{}]/g, ''),
            ) ?? null;
          const valueToUpdate = label.toLowerCase().replace(' ', '_');
          return (
            <Styled.InputContainer key={label}>
              <SelectDropdown
                disabled={isActive}
                id={`context-variable-${label}`}
                label={label}
                onChange={e => {
                  e?.target && updateFieldVal(e.target.value, valueToUpdate);
                }}
                options={contextVariables.map(
                  ({
                    context_variable_id: id,
                    context_variable_name: displayName,
                  }) => ({
                    color: palette.primary.main,
                    label: '$' + displayName,
                    value: id,
                  }),
                )}
                placeholder={label}
                value={selectedContextVariable?.context_variable_id ?? ''}
              />
            </Styled.InputContainer>
          );
        })}
      </Styled.InputsContainer>
      <Styled.SaveContainer>
        <Button
          disabled={isActive || isSubmitDisabled || isLoading}
          isLoading={isLoading}
          onClick={submitAction}
          variant='main'
        >
          Save
        </Button>
      </Styled.SaveContainer>
    </Styled.ActionTemplateForm>
  );
};
