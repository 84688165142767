import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled, Theme } from '@mui/material';

import {
  Checkbox,
  Colors,
  SelectDropdown,
  TextField,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Spacer, StyledInput } from '../handoff-configuration/styles';
import { useGetFreshChatGroups } from './hooks';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import tooltipInfoIcon from 'src/assets/images/tooltip-icon-light.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import {
  selectActionSettingsConfigurationFields,
  selectCanvasIsLoadingActionCustomization,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { FreshchatChatHandoffActionSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const FreshchatChatHandoffCustomizationPanel: React.FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const addingAction = useSelector(selectAddingAction);
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as FreshchatChatHandoffActionSettings;
  const defaultActionSettingsConfigurationFields = useSelector(
    selectHandoffCustomization,
  ) as Partial<FreshchatChatHandoffActionSettings>;
  const isLoadingActionCustomization = useSelector(
    selectCanvasIsLoadingActionCustomization,
  );

  const initialConfiguration = useMemo(
    () => ({
      api_url: null,
      email_variable: null,
      first_name_variable: null,
      freshchat_instances_options:
        defaultActionSettingsConfigurationFields?.freshchat_instances_options ||
        [],
      group: defaultActionSettingsConfigurationFields?.group || '',
      include_transcript: false,
      last_name_variable: null,
      one_chat: defaultActionSettingsConfigurationFields?.one_chat || false,
      question_variable: 'free_form_query',
      tags: [],
      transcript_field: null,
      user_id_variable: null,
    }),
    [
      defaultActionSettingsConfigurationFields?.group,
      defaultActionSettingsConfigurationFields?.one_chat,
      defaultActionSettingsConfigurationFields?.freshchat_instances_options,
    ],
  );

  const initialCustomizationData: FreshchatChatHandoffActionSettings =
    addingAction ? initialConfiguration : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<FreshchatChatHandoffActionSettings>(initialCustomizationData);
  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);

  const freshchatGroups = useGetFreshChatGroups(
    customizationData?.api_url || '',
  );

  const groupOptions =
    freshchatGroups.map(group => ({
      label: group.name,
      value: group.id,
    })) || [];

  const deleteTag = (indexToDelete: number) => {
    setCustomizationData(data => {
      return {
        ...data,
        tags:
          data.tags?.filter((tag, index) => index !== indexToDelete) || null,
      };
    });
  };

  const editTag = (indexToUpdate: number, value: string) => {
    const currentTags = customizationData?.tags || [];
    const newTags = [...currentTags];
    newTags[indexToUpdate] = value;

    setCustomizationData(data => ({
      ...data,
      tags: [...newTags],
    }));
  };

  const save = () => {
    if (addingAction) {
      dispatch(
        addAction({
          ...addingAction,
          data: {
            ...addingAction.data,
            configuration_fields: { ...customizationData },
          },
        }),
      );
    } else {
      dispatch(
        updateActionSettings({
          action_type: ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF,
          configuration_fields: { ...customizationData },
        }),
      );
    }

    dispatchActionEditedTrackingEvent({
      actionType: ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF,
    });
    setActionPanelVisibilityParameters('hidden');
    setAreActionSettingsUnsaved(false);
    dispatch(setCustomizableActionId(''));
    onDismiss();
  };

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (actionSettingsConfigurationFields) {
      setCustomizationData({ ...actionSettingsConfigurationFields });
    }
  }, [actionSettingsConfigurationFields]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF,
        ),
      );
    }
  }, [dispatch, addingAction]);

  useEffect(() => {
    if (!isLoadingActionCustomization) {
      setCustomizationData(
        addingAction ? initialConfiguration : actionSettingsConfigurationFields,
      );
    }
  }, [
    initialConfiguration,
    actionSettingsConfigurationFields,
    addingAction,
    isLoadingActionCustomization,
  ]);

  if (!customizationData || isLoadingActionCustomization) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>Freshchat Chat Handoff</Title>
        <Spacer height='20px' />
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.colors.grey[100]}`,
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            paddingTop: '20px',
          }}
        >
          <ContextVariableSelectDropdown
            label='Question Context Variable'
            onChange={e => {
              setCustomizationData({
                ...customizationData,
                question_variable: e,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.question_variable}
          />
          <ContextVariableSelectDropdown
            label='First Name Context Variable'
            onChange={e => {
              setCustomizationData({
                ...customizationData,
                first_name_variable: e,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.first_name_variable || ''}
          />
          <ContextVariableSelectDropdown
            label='Last Name Context Variable'
            onChange={e => {
              setCustomizationData({
                ...customizationData,
                last_name_variable: e,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.last_name_variable || ''}
          />
          <ContextVariableSelectDropdown
            label='Email Context Variable'
            onChange={e => {
              setCustomizationData({
                ...customizationData,
                email_variable: e,
              });
            }}
            value={customizationData.email_variable || ''}
          />
          <Checkbox
            checked={customizationData.include_transcript}
            label='Prepend transcript to question'
            onChange={() => {
              setCustomizationData({
                ...customizationData,
                include_transcript: !customizationData.include_transcript,
              });
            }}
          />
          {/* transcript field */}
          <TextField
            label='Transcript Field'
            onChange={e => {
              setCustomizationData({
                ...customizationData,
                transcript_field: e.target.value,
              });
            }}
            placeholder='e.g. cf_forethought_transcript'
            tooltipContent='The custom conversation property in Freshchat to pass the transcript to.'
            value={customizationData.transcript_field || ''}
          />
          {/* tags */}
          <Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                gap: '4px',
              }}
            >
              <Typography variant='font14Medium'>Tags</Typography>
              <Tooltip tooltipContent='Tags to be added to Freshchat widget'>
                <Icon height={16} src={tooltipInfoIcon} sx={{ margin: '0' }} />
              </Tooltip>
            </Box>
            {customizationData.tags &&
              customizationData.tags.map((tag, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                      pb: 1,
                    }}
                  >
                    <StyledInput
                      onChange={({ target }) => {
                        editTag(index, target.value);
                      }}
                      placeholder='Enter tag'
                      type='string'
                      value={tag}
                      width='inherit'
                    />
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        height: '42px',
                        justifyContent: 'flex-end',
                        width: '30px',
                      }}
                    >
                      <DeleteButton
                        alt='delete tag icon'
                        onClick={() => {
                          deleteTag(index);
                        }}
                        role='button'
                        src={xIcon}
                      />
                    </Box>
                  </Box>
                );
              })}
            <Add
              isDisabled={false}
              onClick={() => {
                setCustomizationData(data => ({
                  ...data,
                  tags: customizationData.tags
                    ? [...customizationData.tags, '']
                    : [''],
                }));
              }}
            >
              <Typography variant='font14'>+ Add Tag</Typography>
            </Add>
          </Box>
          {/* one chat settings */}
          {featureFlags.includes('freshchat_one_chat') && (
            <>
              <Box
                sx={{
                  borderTop: `1px solid ${theme.palette.colors.grey[100]}`,
                  paddingTop: '15px',
                }}
              >
                <Typography variant='font16Bold'>
                  Freshchat One Chat Settings
                </Typography>
              </Box>
              <Checkbox
                checked={customizationData.one_chat}
                label='Enable Freshchat One Chat'
                onChange={() => {
                  setCustomizationData({
                    ...customizationData,
                    one_chat: !customizationData.one_chat,
                  });
                }}
              />
              <ContextVariableSelectDropdown
                label='User ID Variable'
                onChange={value => {
                  setCustomizationData({
                    ...customizationData,
                    user_id_variable: value,
                  });
                }}
                shouldIncludeSystemContextVariables={false}
                value={customizationData.user_id_variable || ''}
              />
              {!!customizationData.freshchat_instances_options?.length && (
                <SelectDropdown
                  id='freshchat_api_url'
                  label='Freshchat instance API Url'
                  onChange={e => {
                    setCustomizationData({
                      ...customizationData,
                      api_url: e.target.value,
                      group: '',
                    });
                  }}
                  options={customizationData.freshchat_instances_options}
                  placeholder='Select an instance'
                  value={customizationData.api_url || ''}
                />
              )}
              <SelectDropdown
                disabled={!groupOptions.length}
                id='freshdesk_groups'
                label='Freshdesk chat group'
                onChange={e => {
                  setCustomizationData({
                    ...customizationData,
                    group: e.target.value,
                  });
                }}
                options={groupOptions}
                placeholder={
                  groupOptions.length
                    ? 'Select a group'
                    : 'There no groups available'
                }
                value={customizationData.group || ''}
              />
            </>
          )}
        </Box>
      </Box>
      {/* Save and Cancel Buttons */}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isPristine || hasWorkflowConflict}
          onClick={save}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default FreshchatChatHandoffCustomizationPanel;
const Title = styled('h1')`
  font-size: ${theme.typography.font20Medium.fontSize};
  text-align: left;
  color: ${Colors.ui.text.primary};
  font-weight: ${theme.typography.font20Medium.fontWeight};
  cursor: default;
  margin: 0 10px;
`;

const Icon = styled('img')`
  margin-bottom: 8px;
  margin-left: 4px;
`;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const Add = styled('a')(
  ({ isDisabled, theme }: { isDisabled: boolean; theme?: Theme }) => ({
    color: isDisabled
      ? theme?.palette.colors.grey[300]
      : theme?.palette.colors.purple[500],
    cursor: 'pointer',
    fontStyle: isDisabled ? 'italic' : 'normal',
    marginTop: '16px',
    maxWidth: '100%',
    pointerEvents: isDisabled ? 'none' : 'auto',
    textAlign: 'left',
    width: '100%',
  }),
);
