import { AuthorizationConfig, KVPairs } from 'src/types/actionBuilderApiTypes';

export const authorizationConfigToKVPairs = (
  config: AuthorizationConfig,
): KVPairs => {
  return {
    key: config.authorization_id,
    value: config.authorization_fields['api_token'],
  };
};

export const KVPairsToAuthorizationConfig = (
  pairs: KVPairs,
): AuthorizationConfig => {
  return {
    authorization_fields: { api_token: pairs.value ?? '' },
    authorization_id: pairs.key,
    authorization_type: 'bearer',
  };
};
