import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { NodeProps } from 'reactflow';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { EMAIL_RESPONSE } from '../../../intent-email-builder/constants';
import { MISSING_STATIC_ARTICLE_ERROR } from '../../constants';
import AlertTooltip from '../AlertTooltip';
import IntentEmailNode from '../IntentEmailNode';
import StatusIndicator from 'src/components/status-indicator';
import {
  EmailConfigurationListItem,
  EmailWorkflowStep,
} from 'src/pages/workflow-builder-edit/types';
import { selectEmailBuilderState } from 'src/slices/email-builder/emailBuilderSlice';

interface NodeData {
  response?: EmailConfigurationListItem;
  step?: EmailWorkflowStep;
}

export default function ResponseNode(props: NodeProps<NodeData>) {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { intentEmailConfigurations } = useSelector(selectEmailBuilderState);

  const [isHovered, setIsHovered] = useState(false);

  const index = intentEmailConfigurations.findIndex(
    configuration =>
      configuration.configuration_id === props.data.response?.configuration_id,
  );
  const { response, step } = props.data;

  const { step_fields } = step || {};

  if (!response || !step) {
    return <></>;
  }

  const name = response.name || `Response ${index + 1}`;

  const contentRight = response?.missing_static_article ? (
    <AlertTooltip
      tooltipProps={{
        placement: 'right',
        tooltipContent: MISSING_STATIC_ARTICLE_ERROR,
      }}
    />
  ) : (
    <StatusIndicator
      status={
        step?.display_fields?.is_out_of_date_or_modified ? 'idle' : 'active'
      }
    />
  );

  const onTitleClick = () => {
    const queryParams = new URLSearchParams(search);
    queryParams.set('emailMode', EMAIL_RESPONSE);
    queryParams.set(
      'configurationId',
      step_fields?.intent_email_configuration_id,
    );

    navigate({
      search: queryParams.toString(),
    });
  };

  return (
    <IntentEmailNode
      nodeName={name}
      {...props}
      contentRight={contentRight}
      isVerticalMenuVisible
      nodeType='response'
      title='Send email'
    >
      {name && (
        <Box
          aria-label={`${name} response node`}
          component='span'
          onClick={() => onTitleClick()}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            cursor: 'pointer',
            textDecoration: isHovered ? 'underline' : 'none',
          }}
        >
          <Typography variant='font14Bold'>{name}</Typography>
        </Box>
      )}
    </IntentEmailNode>
  );
}
