import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TEMPLATE_INTENT_WORKFLOW_ID_PREFIX } from '../workflow-builder-edit/constants';
import { useGetIsTemplateIntentWorkflow } from '../workflow-builder-edit/hooks';
import { ApiPreviewWidget } from './api-preview/apiPreviewWidget';
import { EmailPreviewSection } from './interactive-email-preview/EmailPreviewSection';
import { WorkflowPreviewWidget } from './widget-preview/workflowPreviewWidget';
import { useGetColorMode } from './hooks';
import { PreviewRightSection } from './PreviewRightSection';
import { useGetDefaultIntentFilters } from './utils';
import autonomousPreviewBackground from 'src/assets/images/autonomous-preview-background.png';
import {
  initializeApiPreview,
  initializePreviewLogs,
  selectApiPreviewState,
  selectEmailPreviewStage,
  setEmailPreviewStage,
  setPreviewColorMode,
  setPreviewIntentFilters,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

export const WorkflowPreviewPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const colorMode = useGetColorMode();
  const { channel } = useParams<'channel'>();
  const { defaultIntentFilters } = useGetDefaultIntentFilters();
  const isTemplate = useGetIsTemplateIntentWorkflow();
  const emailPreviewStage = useSelector(selectEmailPreviewStage);
  const apiPreviewState = useSelector(selectApiPreviewState);

  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');
  const previewFromMode = params.get('mode');

  const isIntentFilterInitialized = useRef<boolean>(false);
  const isInDarkMode = colorMode === 'dark';

  useEffect(() => {
    if (channel === 'api') {
      dispatch(setPreviewColorMode('dark'));
      return;
    } else if (channel === 'widget') {
      if (previewFromMode === 'autoflow') {
        dispatch(setPreviewColorMode('dark'));
        return;
      } else {
        dispatch(setPreviewColorMode('light'));
        return;
      }
    }

    dispatch(setPreviewColorMode('light'));
  }, [dispatch, channel, previewFromMode]);

  useEffect(() => {
    if (
      (isIntentFilterInitialized.current ||
        defaultIntentFilters.length === 0) &&
      !isTemplate
    ) {
      return;
    }

    dispatch(initializePreviewLogs());

    // set intent filter based on previewFromIntentId
    if (previewFromIntentId) {
      const newIntentFilters = [...defaultIntentFilters].map(filter =>
        filter.intentId === previewFromIntentId
          ? {
              ...filter,
              isAutoflow:
                previewFromMode === 'landing-page' || !previewFromMode
                  ? filter.isAutoflow
                  : previewFromMode === 'autoflow',
              isChecked: true,
              isDraft: true,
            }
          : filter,
      );

      dispatch(setPreviewIntentFilters(newIntentFilters));
    } else {
      dispatch(setPreviewIntentFilters([...defaultIntentFilters]));
    }

    isIntentFilterInitialized.current = true;
  }, [
    dispatch,
    previewFromIntentId,
    previewFromMode,
    defaultIntentFilters,
    isTemplate,
  ]);

  const getPreviewWidget = (channel: string | undefined) => {
    switch (channel) {
      case 'api':
        return <ApiPreviewWidget />;
      case 'email':
        return <EmailPreviewSection />;
      default:
        return <WorkflowPreviewWidget />;
    }
  };

  const onGoBack = () => {
    const goBackToTemplate = () => {
      const params = new URLSearchParams(location.search);
      const intentId = params.get('intentId');
      const [, intentWorkflowId] =
        intentId?.split(TEMPLATE_INTENT_WORKFLOW_ID_PREFIX) ?? [];
      params.set('intentId', 'template');
      params.set('workflowId', intentWorkflowId);
      const templateDetailUrl = `${Routes.WORKFLOW_BUILDER_EDIT}?${params}`;
      navigate(templateDetailUrl);
    };

    if (isTemplate) {
      // template preview allows navigating to different template previews
      // we need to build the back url rather than go to previous
      goBackToTemplate();
      return;
    }

    navigate(-1);
  };

  const restartEmailPreview = () => {
    if (channel === 'email') {
      dispatch(setEmailPreviewStage('email-form'));
    }
  };

  const restartApiPreview = () => {
    dispatch(initializeApiPreview());
  };

  const getShouldShowRightPanel = () => {
    if (channel === 'email') {
      return emailPreviewStage === 'interactive-widget';
    }

    return true;
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      sx={
        isInDarkMode
          ? {
              backgroundImage: `url(${autonomousPreviewBackground})`,
              backgroundSize: 'cover',
              display: 'flex',
              flex: 1,
              height: '100%',
            }
          : {}
      }
    >
      <Box
        bgcolor={isInDarkMode ? 'transparent' : theme.palette.colors.white}
        borderBottom={`1px solid ${
          isInDarkMode
            ? theme.palette.colors.grey[800]
            : theme.palette.colors.slate[200]
        }`}
        boxSizing='border-box'
        display='flex'
        height='65px'
        padding='16px 24px 16px 40px'
        width='100%'
      >
        <Typography
          color={
            colorMode === 'dark'
              ? theme.palette.colors.white
              : theme.palette.colors.black
          }
          variant='font24'
        >
          Preview {isTemplate && 'Template'}
        </Typography>
        <Box display='flex' gap='8px' marginLeft='auto'>
          {channel === 'email' && (
            <Button
              disabled={emailPreviewStage === 'email-form'}
              onClick={restartEmailPreview}
              variant='ghost'
            >
              Restart
            </Button>
          )}
          {channel === 'api' && (
            <Button
              disabled={
                apiPreviewState.history.length === 0 ||
                apiPreviewState.isLoading
              }
              onClick={restartApiPreview}
              variant='ghost'
            >
              <Typography
                color={
                  apiPreviewState.history.length === 0
                    ? theme.palette.colors.grey[500]
                    : theme.palette.colors.white
                }
                variant='font14Bold'
              >
                Start new chat
              </Typography>
            </Button>
          )}
          <Button onClick={onGoBack} variant='secondary'>
            Close
          </Button>
        </Box>
      </Box>
      <Box display='flex' height='calc(100% - 65px)'>
        {getPreviewWidget(channel)}
        {getShouldShowRightPanel() && <PreviewRightSection />}
      </Box>
    </Box>
  );
};
