import { createSlice } from '@reduxjs/toolkit';

import {
  createIdP,
  createSCIMKey,
  deleteIdP,
  disableSCIM,
  getSSOSettings,
  updateSSOConfig,
} from './thunks';
import { SSOSliceState } from './types';
import type { RootState } from 'src/store/rootReducer';

const initialState: SSOSliceState = {
  idpSettings: null,
  isSCIMAllowed: false,
  isSCIMEnabled: false,
  isSSOEnabled: false,
  loading: false,
  loadingSCIM: false,
  scimToken: null,
};

const teamSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getSSOSettings.pending, state => {
      state.loading = true;
    });
    builder.addCase(getSSOSettings.fulfilled, (state, action) => {
      state.idpSettings = action.payload.idp_settings;
      state.isSSOEnabled = action.payload.is_sso_enabled;
      state.isSCIMEnabled = action.payload.is_scim_enabled;
      state.isSCIMAllowed = action.payload.is_scim_allowed;
      state.loading = false;
    });
    builder.addCase(getSSOSettings.rejected, state => {
      state.loading = false;
    });

    builder.addCase(createIdP.fulfilled, (state, action) => {
      state.idpSettings = action.meta.arg;
    });

    builder.addCase(deleteIdP.fulfilled, state => {
      state.idpSettings = {};
      state.isSSOEnabled = false;
    });

    builder.addCase(updateSSOConfig.fulfilled, (state, action) => {
      state.isSSOEnabled = action.meta.arg.is_sso_enabled;
    });

    builder.addCase(createSCIMKey.pending, state => {
      state.loadingSCIM = true;
    });
    builder.addCase(createSCIMKey.rejected, state => {
      state.loadingSCIM = false;
    });
    builder.addCase(createSCIMKey.fulfilled, (state, action) => {
      state.isSCIMEnabled = true;
      state.scimToken = action.payload.scim_token;
      state.loadingSCIM = false;
    });

    builder.addCase(disableSCIM.pending, state => {
      state.loadingSCIM = true;
    });
    builder.addCase(disableSCIM.rejected, state => {
      state.loadingSCIM = false;
    });
    builder.addCase(disableSCIM.fulfilled, state => {
      state.isSCIMEnabled = false;
      state.scimToken = null;
      state.loadingSCIM = false;
    });
  },
  initialState,
  name: 'sso',
  reducers: {},
});

export const selectSSOState = (state: RootState): SSOSliceState => state.sso;

export default teamSlice.reducer;
