import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Toggle } from '@forethought-technologies/forethought-elements';
import { IntentDataWithTopics } from '../intent-workflows-table/types';
import { setIsInfoBannerVisible } from 'src/actions/workflow-builder/workflowBuilderActions';
import ActivateWorkflowModal from 'src/components/activate-workflow-modal/ActivateWorkflowModal';
import StatusIndicator from 'src/components/status-indicator';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { useGetHasOtherQuestions } from 'src/hooks/solve';
import {
  API_WORKFLOW_NOT_FOUND,
  apiWorkflowTypes,
  EMAIL_WORKFLOW_NOT_CREATED,
  emailWorkflowTypes,
  EMPTY_WORKFLOW,
  NO_LIVE_VERSION_IS_PUBLISHED,
  slackWorkflowTypes,
  widgetWorkflowTypes,
} from 'src/pages/workflow-builder-edit/constants';
import { getTopicIds } from 'src/pages/workflow-builder-edit/Input/utils';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { getIntentAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import {
  useActivateIntentWorkflowMutation,
  useDeactivateIntentWorkflowMutation,
  useGetHandoffConfigurationsQuery,
} from 'src/services/workflow-builder-metrics';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { isApiError } from 'src/types/types';
import { TranslationChannel } from 'src/types/workflowBuilderAPITypes';
import { minimumPhrasesRequired } from 'src/utils/constants';
import {
  CommonIntentWorkflowType,
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';
import {
  getWorkflowActivationCopy,
  isIntentActiveForChannel,
} from 'src/utils/solve/intentUtils';

interface IntentWorkflowToggleProps {
  channel: TranslationChannel;
  disabled?: boolean;
  intentData: IntentDataWithTopics;
  showMinPhrasesDialog?: (intentId: string) => void;
  tooltipText?: string;
}

/**
 * Given a channel and intent data, show confirmation dialog and handle workflow activation for channel
 */
export default function IntentWorkflowToggle({
  channel,
  disabled,
  intentData,
  showMinPhrasesDialog,
  tooltipText = '',
}: IntentWorkflowToggleProps) {
  const {
    api_workflow_id: apiWorkflowId,
    intent_definition_id: intentDefinitionId,
    intent_name: intentName,
    intent_workflow_id: intentWorkflowId,
    slack_workflow_id: slackWorkflowId,
    topics,
  } = intentData;
  const dispatch = useAppDispatch();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [activateIntentWorkflow] = useActivateIntentWorkflowMutation();
  const [deactivateIntentWorkflow] = useDeactivateIntentWorkflowMutation();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const { data: handoffConfigurationsResponse } =
    useGetHandoffConfigurationsQuery();

  const hasOtherQuestions = useGetHasOtherQuestions();

  const handoffConfigurations =
    handoffConfigurationsResponse?.configurations ?? [];
  const isGeneralHandoffWorkflow = intentDefinitionId === 'general-handoff';
  const solveEmailOtherQuestionsEnabled = featureFlags.includes(
    'solve_email_other_questions_enabled',
  );
  const isHandoffRevampEnabled = featureFlags.includes('handoff_revamp');
  const isEmailChannel = channel == 'email';
  const isApiChannel = channel === 'api';

  const canToggleGeneralHandoffForEmail =
    isGeneralHandoffWorkflow &&
    solveEmailOtherQuestionsEnabled &&
    isEmailChannel;

  const isActive = isIntentActiveForChannel(
    intentData.active_workflow_types,
    channel,
  );
  const userCanActivateWorkflow = useSelector(
    selectUserCan('activate_workflow'),
  );
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER,
  );

  const toggleWorkflow = async () => {
    const topicIds = getTopicIds(topics);
    const trackingEvent = isActive
      ? FlamethrowerTrackingEventTypes.WORKFLOW_DEACTIVATED
      : FlamethrowerTrackingEventTypes.WORKFLOW_ACTIVATED;
    const workflowIdToUse = isApiChannel
      ? apiWorkflowId
      : channel === 'slack'
      ? slackWorkflowId
      : intentWorkflowId;

    dispatchTrackingAction(trackingEvent, {
      channel,
      topic_ids: topicIds,
      workflow_id: workflowIdToUse || '',
    });
    const getWorkflowTypesToToggle = () => {
      switch (channel) {
        case 'api':
          return apiWorkflowTypes;
        case 'email':
          return emailWorkflowTypes;
        case 'slack':
          return slackWorkflowTypes;
        default:
          return widgetWorkflowTypes;
      }
    };

    const workflowTypesToToggle = getWorkflowTypesToToggle();

    const apiErrors = [
      EMPTY_WORKFLOW,
      NO_LIVE_VERSION_IS_PUBLISHED,
      EMAIL_WORKFLOW_NOT_CREATED,
      API_WORKFLOW_NOT_FOUND,
    ];

    if (isActive) {
      try {
        await deactivateIntentWorkflow({
          intentWorkflowId: workflowIdToUse || '',
          workflowTypes: workflowTypesToToggle,
        }).unwrap();
      } catch (error) {
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: 4000,
            title: 'Something went wrong deactivating workflow.',
            variant: 'danger',
          }),
        );
      }
    } else {
      try {
        await activateIntentWorkflow({
          intentWorkflowId: workflowIdToUse || '',
          workflowTypes: workflowTypesToToggle,
        }).unwrap();
      } catch (error) {
        if (isApiError(error) && apiErrors.includes(error.data?.error_type)) {
          dispatch(
            setGlobalToastOptions({
              autoHideDuration: 10000,
              title: error.data.error_message,
              variant: 'warning',
            }),
          );
        }
      }
    }
  };

  const isKnowledgeArticle =
    intentDefinitionId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const isFallback = intentDefinitionId === CommonIntentWorkflowType.FALLBACK;

  const { confirmationMessage, label } = getWorkflowActivationCopy({
    channel,
    hasOtherQuestions,
    intentName,
    isActive,
    isKnowledgeArticle,
  });

  if (
    (intentData.intent_definition_id ===
      CommonIntentWorkflowType.GENERAL_HANDOFF &&
      (channel === 'widget' || channel === 'api')) ||
    intentData.is_handoff ||
    isFallback ||
    channel === 'interactive_email'
  ) {
    return (
      <StatusIndicator
        height={8}
        label={isActive ? 'On' : 'Off'}
        status={isActive ? 'active' : 'idle'}
        width={8}
      />
    );
  }

  return (
    <>
      <Toggle
        ariaLabel={label}
        checked={isActive}
        disabled={!userCanActivateWorkflow || disabled}
        onChange={async () => {
          if (isGeneralHandoffWorkflow && !canToggleGeneralHandoffForEmail) {
            dispatch(setIsInfoBannerVisible(true));
          } else if (!isActive && !canToggleGeneralHandoffForEmail) {
            const intent = await getIntentAPI(intentDefinitionId);
            const isDefaultHandoff = Object.values(
              CommonIntentWorkflowType,
            ).includes(intent.intent_id as CommonIntentWorkflowType);

            let isHandoff = false;
            if (isHandoffRevampEnabled) {
              isHandoff = handoffConfigurations.some(
                configuration =>
                  configuration.intent_definition_id === intent.intent_id,
              );
            }

            if (
              !isDefaultHandoff &&
              !isHandoff &&
              intent.phrases.length < minimumPhrasesRequired
            ) {
              return showMinPhrasesDialog?.(intentDefinitionId);
            }
            setIsDialogVisible(true);
          } else {
            setIsDialogVisible(true);
          }
        }}
        tooltipText={tooltipText || label}
      />
      <ActivateWorkflowModal
        isActivating={isActive}
        isDialogVisible={isDialogVisible}
        message={confirmationMessage}
        onClose={() => setIsDialogVisible(false)}
        onNegativeOptionSelected={() => setIsDialogVisible(false)}
        onPositiveOptionSelected={() => {
          setIsDialogVisible(false);
          toggleWorkflow();
        }}
      />
    </>
  );
}
