import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';

export const CustomThemeProvider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = ({ children }) => {
  const customTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
    },
  });

  return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
};
