import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

export const verifyValues = (article: KnowledgeGapArticleRecommendation) =>
  Boolean(article && (article.summary || article.relevant_articles?.length));

export const createHrsText = (hrs: string) => {
  if (hrs === '1.0') {
    return '1.0 hr';
  }
  return `${hrs} hrs`;
};
