import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { TERMINAL_NODE_PREFIX } from '../../../constants';
import {
  useCreateWorkflowTerminalNode,
  useRemoveWorkflowNode,
  useUpdateWorkflowTerminalNode,
} from '../../../hooks/useCreateNode';
import { ActiveSidebarProps } from '../types';
import { ZendeskTicketUpdateForm } from './helpdesks/zendesk/ZendeskTicketUpdateForm';
import { Divider } from 'src/pages/intent-conversation-analytics/ConversationDrawer';
import { TicketUpdateStepFields } from 'src/pages/workflow-builder-edit/types';
import { useGetEmailWorkflowStaticDataQuery } from 'src/services/email-workflow/emailWorkflowApi';

export function TerminalNodeSidebar({
  handleCancel,
  node,
}: ActiveSidebarProps) {
  const { automatedValue, stepFields } = node.data;
  const { palette } = useTheme();
  const handleCreate = useCreateWorkflowTerminalNode();
  const handleUpdate = useUpdateWorkflowTerminalNode();
  const handleRemove = useRemoveWorkflowNode();
  const { data: staticWorkflowData } = useGetEmailWorkflowStaticDataQuery();
  const isTicketUpdateEnabled = staticWorkflowData?.helpdesk === 'zendesk';

  /**
   * Handles the save action for the terminal node sidebar.
   * - if form is empty, delete the step
   * - if form is not empty and step does not exist, create the step
   * - if form is not empty and step exists, update the step
   */
  function handleSave(stepFields: TicketUpdateStepFields) {
    const terminalStepExists = !node.id.startsWith(TERMINAL_NODE_PREFIX);
    const isFormEmpty = Object.values(stepFields).every(
      value => value.length === 0,
    );
    const { parentStepId, parentTransitionId } = node.data;

    if (!terminalStepExists && isFormEmpty) {
      handleCancel();
    } else if (isFormEmpty) {
      handleRemove({ stepId: node.id });
    } else if (!terminalStepExists) {
      handleCreate({
        parentStepId,
        parentTransitionId,
        stepFields,
      });
    } else {
      handleUpdate({
        stepFields,
        stepId: node.id,
      });
    }
  }

  return (
    <Box key={node.id}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          padding: '24px',
        }}
      >
        <Typography variant='font24'>Path completed</Typography>
        <Typography color={palette.colors.grey[700]} variant='font14Medium'>
          Tickets processed through this path will be automatically tagged with
          &apos;{automatedValue}&apos;.
          {isTicketUpdateEnabled &&
            ' Select other tags you would like to add to the tickets.'}
        </Typography>
        <Divider />
      </Box>
      {isTicketUpdateEnabled && (
        <ZendeskTicketUpdateForm
          handleCancel={handleCancel}
          handleSave={handleSave}
          initialStepFields={stepFields}
        />
      )}
    </Box>
  );
}
