import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export const OverviewCardInformationSection = () => {
  const { palette } = useTheme();

  const primaryColor = palette.grey[100];
  const secondaryColor = palette.grey[600];

  return (
    <Box
      borderTop={`1px solid ${palette.colors.grey[200]}`}
      display='flex'
      gap='24px'
      paddingTop='16px'
    >
      <Box alignItems='center' display='flex' flexDirection='row' gap='4px'>
        <Box
          sx={{
            backgroundColor: secondaryColor,
            borderRadius: '2px',
            display: 'block',
            height: '12px',
            width: '12px',
          }}
        />
        <Typography color={secondaryColor} noWrap variant='font12'>
          Current period
        </Typography>
      </Box>
      <Box alignItems='center' display='flex' flexDirection='row' gap='4px'>
        <Box
          sx={{
            background: `repeating-linear-gradient(
                -45deg,
                ${primaryColor},
                ${primaryColor} 3px,
                ${secondaryColor} 3px,
                ${secondaryColor} 6px
              )`,
            borderRadius: '2px',
            display: 'block',
            height: '12px',
            width: '12px',
          }}
        />
        <Typography color={secondaryColor} noWrap variant='font12'>
          Previous period
        </Typography>
      </Box>
    </Box>
  );
};
