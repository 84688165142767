import React from 'react';
import { styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { theme } from '@forethought-technologies/forethought-elements';

interface SpinnerProps {
  height?: string;
}

const Spinner: React.FC<React.PropsWithChildren<SpinnerProps>> = ({
  height,
}) => {
  return (
    <LoadingContainer aria-busy={true} height={height} role='progressbar'>
      <CircularProgress color='inherit' />
    </LoadingContainer>
  );
};

const LoadingContainer = styled('div')<{ height?: string }>`
  display: flex;
  height: ${props => (props.height ? props.height : '250px')};
  justify-content: center;
  align-items: center;
  color: ${theme.palette.colors.purple[500]};
  grid-column: 1 / 3;
`;

export default Spinner;
