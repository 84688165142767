import { EMPTY_SUMMARY_ROW_VALUE } from '../constants';
import { DiscoverTopicTableData } from '../types';
import { AverageRendererComponent } from './AverageRenderer';
import { METRIC_FILTERS_SEPARATOR } from 'src/constants/discover';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';

export const averageCellRenderer =
  (metricFilter: DiscoverTopicAggregatedMetricType) =>
  // eslint-disable-next-line react/display-name
  (row: DiscoverTopicTableData, isSummaryRow?: boolean) => {
    const value =
      row[`${metricFilter}${METRIC_FILTERS_SEPARATOR}value` as const];
    if (isSummaryRow && value === null) {
      return EMPTY_SUMMARY_ROW_VALUE;
    }

    return (
      <AverageRendererComponent
        isHidden={Boolean(row.topic?.is_hidden)}
        metricFilter={metricFilter}
        percentOfTotal={row.percentOfTotal}
        value={value}
      />
    );
  };
