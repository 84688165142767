import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import {
  Button,
  Dialog,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { selectIsIntentEnabledForEmail } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { minimumPhrasesRequired } from 'src/utils/constants';

interface DisabledIntentEditionModalProps {
  isOpen: boolean;
  onClick: () => void;
}

const DisabledIntentEditionModal: React.FC<
  React.PropsWithChildren<DisabledIntentEditionModalProps>
> = ({ isOpen, onClick }) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');
  const isIntentEnabledForEmail = useSelector(selectIsIntentEnabledForEmail);

  return (
    <Box width='346px'>
      <Dialog
        footer={
          <Button fullWidth onClick={() => onClick()} variant='secondary'>
            Got it
          </Button>
        }
        hideCloseButton
        open={isOpen}
        title='More input phrases required'
        variant='sm'
      >
        <Box marginBottom='-15px' width='304px'>
          <Typography
            color={theme.palette.text.secondary}
            variant='font12Medium'
          >
            {`Active intents require a minimum of ${minimumPhrasesRequired} training phrases to ensure
            sufficient coverage. ${
              isIntentEnabledForEmail && isSolveEmailEnabled
                ? 'The current intent is active for the email section'
                : ''
            }`}
          </Typography>
        </Box>
      </Dialog>
    </Box>
  );
};

export default DisabledIntentEditionModal;
