import React from 'react';
import { Box } from '@mui/material';

import {
  Alert,
  Button,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { minimumPhrasesRequired } from 'src/utils/constants';

interface RequiredPhrasesModalProps {
  onActivateOptionClick: () => void;
  onLaterOptionClick: () => void;
}

const RequiredPhrasesModal: React.FC<
  React.PropsWithChildren<RequiredPhrasesModalProps>
> = ({ onActivateOptionClick, onLaterOptionClick }) => {
  return (
    <Alert title='More input phrases required' variant='warning'>
      <Box display='flex' width='573px'>
        <Typography color={theme.palette.colors.grey[600]} variant='font14'>
          Active intents require a minimum of {minimumPhrasesRequired} training
          phrases to ensure sufficient coverage.
        </Typography>
        <Box bottom='5px' display='flex'>
          <Box marginLeft='15px' marginRight='9px'>
            <Button onClick={() => onLaterOptionClick()} variant='ghost'>
              Later
            </Button>
          </Box>
          <Button
            fullWidth
            onClick={() => onActivateOptionClick()}
            variant='secondary'
          >
            <Box whiteSpace='nowrap'>Add phrases</Box>
          </Button>
        </Box>
      </Box>
    </Alert>
  );
};

export default RequiredPhrasesModal;
