import { createSlice } from '@reduxjs/toolkit';

import { fetchExperimentsTreatments } from './thunks';
import { ExperimentsSliceState } from './types';
import mergeWith from 'lodash/fp/mergeWith';
import type { RootState } from 'src/store/rootReducer';

export const initialState: ExperimentsSliceState = {
  hasLoaded: false,
  loadingRequestId: '',
  treatments: {
    'discover-filters-status': 'disabled',
    'discover-recommendation-service-enabled': 'disabled',
    'workflow-tag-filters': 'disabled',
  },
};

const experimentsSlice = createSlice({
  extraReducers: builder => {
    /**
     * fetchExperimentsTreatments
     */
    builder.addCase(fetchExperimentsTreatments.pending, (state, action) => {
      if (!state.loadingRequestId) {
        state.loadingRequestId = action.meta.requestId;
      }
    });
    builder.addCase(fetchExperimentsTreatments.fulfilled, (state, action) => {
      if (action.meta.requestId !== state.loadingRequestId) {
        return;
      }
      const { treatments } = action.payload;
      // mergedTreatment will ensure that if the treatment response doesn't contain all expected keys of Treatments that the default
      // values will be used over the undefined/null values
      const mergedTreatments = mergeWith(
        (_, fetchedObject) => {
          if (fetchedObject !== null && fetchedObject !== undefined) {
            return fetchedObject;
          }
        },
        initialState.treatments,
        treatments,
      );
      state.treatments = mergedTreatments;
      state.hasLoaded = true;
    });
    builder.addCase(fetchExperimentsTreatments.rejected, (state, action) => {
      if (action.meta.requestId !== state.loadingRequestId) {
        return;
      }
      state.hasLoaded = true;
    });
    /**
     * end fetchExperimentsTreatments
     */
  },
  initialState,
  name: 'experiments',
  reducers: {},
});

export const selectExperimentTreatments = (
  state: RootState,
): ExperimentsSliceState['treatments'] => state.experiments.treatments;

export const selectExperiments = (state: RootState): ExperimentsSliceState =>
  state.experiments;

export default experimentsSlice.reducer;
