import { useState } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  DateRangeFilterButton,
  FilterButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import { CHAT_COMPARISON_PERIOD_OPTIONS } from '../constants';
import { stringifyDateRange } from '../helpers';
import { OverviewOvertimePeriod } from '../types';
import ChatComparisonCards from './ChatComparisonCards';
import OverviewLineChart from './OverviewLineChart';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import { getPreviousDateRangeFromCurrentDateRange } from 'src/utils/dateUtils';
import {
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

const ChatComparisonSection = ({
  periodicalFilter,
  setPeriodicalFilter,
}: {
  periodicalFilter: OverviewOvertimePeriod;
  setPeriodicalFilter: (state: OverviewOvertimePeriod) => void;
}) => {
  const { palette } = useTheme();

  const [dateRange, setDateRange] = useState<DateRange>(last30DaysTimeRange);

  const getPreviousDateRange =
    getPreviousDateRangeFromCurrentDateRange(dateRange);
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={3}
      paddingTop='40px'
      sx={{
        borderTopColor: palette.colors.grey[200],
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
      }}
      width='100%'
    >
      <Box alignItems='center' display='flex' justifyContent='space-between'>
        <Box alignItems='center' display='flex' gap={2}>
          <Typography variant='font20'>Chat comparison</Typography>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={last30DaysTimeRange}
            onChange={setDateRange}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
        </Box>
        <Typography color={palette.colors.grey[600]} variant='font12'>
          {`Compare to ${stringifyDateRange(getPreviousDateRange)}`}
        </Typography>
      </Box>
      {/* Line graph section */}
      <Box
        border={`1px solid ${palette.colors.slate[200]}`}
        borderRadius='8px'
        padding='24px'
      >
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Typography variant='font16Bold'>Time series comparison</Typography>
          <FilterButton
            aria-label='time period filter'
            disabledTooltipMessage='Time period not valid for selected time range'
            initialValue='monthly'
            onChange={value =>
              setPeriodicalFilter(value as OverviewOvertimePeriod)
            }
            options={CHAT_COMPARISON_PERIOD_OPTIONS.map(option => {
              return { ...option };
            })}
            startAdornment={<FilterButtonIcon dropdownType='time-period' />}
            value={periodicalFilter}
          />
        </Box>
        <OverviewLineChart
          dateRange={dateRange}
          periodicalFilter={periodicalFilter}
          scope='main'
        />
      </Box>
      <ChatComparisonCards dateRange={dateRange} />
    </Box>
  );
};

export default ChatComparisonSection;
