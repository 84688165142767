import { sendAuthRequestWithErrorHandling } from '../api';
import { SOLVE_V2_BASE_URL } from '../constants';

export const getInstallUrl = (): Promise<string> => {
  return sendAuthRequestWithErrorHandling(
    `${API_URL}${SOLVE_V2_BASE_URL}/slack/install`,
    null,
    'GET',
  );
};
