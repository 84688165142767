import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  Checkbox,
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  FREE_FORM_QUERY_TYPE,
  INTENT_OPTION_MAXIMUM,
  INTENT_OPTION_MINIMUM,
  INTENT_OPTIONS,
  TAG_SELECTION_TYPE,
} from '../constants';
import { useDebouncedSubmitConfigForNonRTE } from '../hooks/useDebouncedSubmitConfig';
import {
  useFilterOutCommonIntents,
  useGetIntents,
} from '../hooks/useGetIntents';
import { Field } from '../styledComponents';
import { IntentOptionType } from '../types';
import { getIntentOptionType } from './helper';
import ManuallySelectedIntents from './ManuallySelectedIntents';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import {
  selectWidgetConfiguration,
  setFreeFormIntentDetectionEnabled,
  setIntentOptions,
  setManuallySelectedTopIntents,
  setNumTopIntents,
  setTagSelectionEnabled,
} from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

const IntentOptionsForm = () => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  // selectors
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);
  const intents = useGetIntents({ isActiveUserCreatedWorkflows: true });
  const intentsWithoutCommonIntents = useFilterOutCommonIntents({
    intents: intents,
  });
  const debouncedSubmitConfig = useDebouncedSubmitConfigForNonRTE();

  const {
    free_form_intent_detection_enabled: freeFormIntentDetectionEnabled,
    manually_selected_top_intents: manuallySelectedTopIntents,
    num_top_intents: numTopIntents,
    tag_selection_enabled: tagSelectionEnabled,
  } = widgetConfiguration;

  // states
  const [selectedIntentOption, setSelectedIntentOption] = useState<string>(
    getIntentOptionType(manuallySelectedTopIntents, numTopIntents),
  );

  const handleNoDisplayOptionChange = (value: string) => {
    const updatedObject = {
      manually_selected_top_intents: [],
      num_top_intents: 0,
    };
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      ...updatedObject,
    };
    debouncedSubmitConfig(
      updatedConfig,
      'intent_options',
      JSON.stringify(updatedObject),
    );
    dispatch(setIntentOptions(value));
  };

  const handleIntentOptionChange = (value: string) => {
    if (value === 'no_display') {
      handleNoDisplayOptionChange(value);
    }

    if (value === 'manual') {
      handleManualIntentChange(manuallySelectedTopIntents);
    }

    if (value === 'auto') {
      handleShowTopChange('3');
    }

    setSelectedIntentOption(value);
  };

  const handleShowTopChange = (value: string) => {
    const valueAsInt = parseInt(value);
    const updatedObject = {
      manually_selected_top_intents: [],
      num_top_intents: valueAsInt,
    };
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      ...updatedObject,
    };
    debouncedSubmitConfig(updatedConfig, 'num_top_intents', value);
    dispatch(setNumTopIntents(valueAsInt));
    dispatch(setManuallySelectedTopIntents([]));
  };

  const handleManualIntentChange = useCallback(
    (intents: string[]) => {
      const createdIntents = intentsWithoutCommonIntents.map(
        intent => intent.intent_definition_id,
      );
      const cleanedIntents = intents.filter(intent =>
        createdIntents.includes(intent),
      );
      const updatedObject = {
        manually_selected_top_intents: cleanedIntents,
        num_top_intents: 0,
      };
      const updatedConfig: WidgetConfiguration = {
        ...widgetConfiguration,
        ...updatedObject,
      };
      debouncedSubmitConfig(
        updatedConfig,
        'manually_selected_top_intents',
        JSON.stringify(cleanedIntents),
      );
      dispatch(setNumTopIntents(0));
      dispatch(setManuallySelectedTopIntents(cleanedIntents));
    },
    [
      debouncedSubmitConfig,
      dispatch,
      widgetConfiguration,
      intentsWithoutCommonIntents,
    ],
  );

  const handleOtherOptionsChange = (type: IntentOptionType, value: boolean) => {
    const updatedConfig: WidgetConfiguration = {
      ...widgetConfiguration,
      [type]: value,
    };
    debouncedSubmitConfig(updatedConfig, type, value.toString());
    if (type === FREE_FORM_QUERY_TYPE) {
      dispatch(setFreeFormIntentDetectionEnabled(value));
    }
    if (type === TAG_SELECTION_TYPE) {
      dispatch(setTagSelectionEnabled(value));
    }
  };

  // Max range is 11 = (10 intent values + 1 option to set to 0)
  const handleAutoIntentOptionLength = () => {
    // Maximum
    if (intentsWithoutCommonIntents.length > 10) {
      return INTENT_OPTION_MAXIMUM;
    }
    const dynamicOptionValue = intentsWithoutCommonIntents.length + 1;
    // Minimum
    if (dynamicOptionValue <= 4) {
      return INTENT_OPTION_MINIMUM;
    }
    return dynamicOptionValue;
  };

  const autoIntentOptionLength = handleAutoIntentOptionLength();

  if (isSolveLiteEnabled) {
    return null;
  }

  return (
    <>
      <Field>
        <Typography variant='font16Bold'>Intent options</Typography>
        <SelectDropdown
          id='intent-options'
          onChange={e => handleIntentOptionChange(e.target.value)}
          options={INTENT_OPTIONS}
          placeholder='Select an option'
          value={selectedIntentOption}
        />
        {selectedIntentOption === 'manual' && (
          <ManuallySelectedIntents
            handleManualIntentChange={handleManualIntentChange}
          />
        )}
      </Field>
      {selectedIntentOption === 'auto' && (
        <Field>
          <Box alignItems='center' display='flex' flexDirection='row' gap={1}>
            <Typography variant='font14Medium'>Show top</Typography>
            <Box width='70px'>
              <SelectDropdown
                id='intent-auto-selection'
                onChange={e => handleShowTopChange(e.target.value)}
                options={[...Array(autoIntentOptionLength)].map((_, idx) => {
                  const stringOption = idx.toString();
                  return { label: stringOption, value: stringOption };
                })}
                placeholder='Select an option'
                value={numTopIntents?.toString() || ''}
              />
            </Box>
            <Typography variant='font14Medium'>intents</Typography>
          </Box>
          {intentsWithoutCommonIntents?.length === 0 && (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              No intent/workflow was found. You have not yet created any
              workflows. This is an example of how the options will appear.
            </Typography>
          )}
        </Field>
      )}
      <Box borderTop={`1px solid ${palette.colors.slate[300]}`} />
      <Field>
        <Typography variant='font16Bold'>Other options</Typography>
        <Box display='flex' flexDirection='column'>
          <Checkbox
            checked={freeFormIntentDetectionEnabled}
            label='Enable free-form text input'
            onChange={e => {
              const checked = e.target.checked;
              handleOtherOptionsChange(FREE_FORM_QUERY_TYPE, checked);
            }}
          />
          <Checkbox
            checked={tagSelectionEnabled}
            label='Enable to confirm Tag selection'
            onChange={e => {
              const checked = e.target.checked;
              handleOtherOptionsChange(TAG_SELECTION_TYPE, checked);
            }}
          />
          <Box pl='36px'>
            <Typography color={palette.colors.grey[600]} variant='font14'>
              When enabled, customers will see a prompt to choose a relevant tag
              if no tag is provided through the embed script.
            </Typography>
          </Box>
        </Box>
      </Field>
    </>
  );
};

export default IntentOptionsForm;
