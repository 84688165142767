import { Box } from '@mui/material';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import {
  allowedTypesByHelpdesk,
  filterCustomTicketFieldsByType,
  formatCustomTicketFieldOptions,
  useGetMappingDropdownStartAdornment,
} from '../helpers';
import { Helpdesk } from 'src/components/app/types';
import { SELECT_DEFUALT_OPTION } from 'src/pages/workflow-builder-config/constants';
import { TicketCustomField } from 'src/pages/workflow-builder-edit/types';
import { TriageModelDetail } from 'src/reducers/triageSettingsReducer/types';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { ContextVariableTypes } from 'src/utils/enums';

interface MappingSelectDropdownProps {
  contextVariableType: keyof typeof ContextVariableTypes;
  customTicketFields: Array<TicketCustomField>;
  deleteTicketFieldMapping: () => void;
  helpdesk: Helpdesk;
  isSystemContext?: boolean;
  rowIndex: number;
  ticketMappingData: { isInUse: boolean; value: string };
  triageModels: Array<TriageModelDetail>;
  updateTicketFieldMappings: (selectedValue: string) => void;
}

const MappingSelectDropdown = ({
  contextVariableType,
  customTicketFields,
  deleteTicketFieldMapping,
  helpdesk,
  isSystemContext = false,
  rowIndex,
  ticketMappingData,
  triageModels,
  updateTicketFieldMappings,
}: MappingSelectDropdownProps) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const isTriageMappingEnabled = featureFlags.includes('triage_model_mapping');
  const allowedTypes = helpdesk ? allowedTypesByHelpdesk[helpdesk] ?? [] : [];

  const customTicketFieldsByType = allowedTypes.length
    ? filterCustomTicketFieldsByType(customTicketFields, contextVariableType)
    : [];

  const enabledModels = triageModels.filter(
    model => model.status === 'enabled',
  );

  const customTicketFieldOptions = formatCustomTicketFieldOptions(
    customTicketFieldsByType,
    helpdesk,
    !!enabledModels.length,
  );

  const triageModelOptions = enabledModels.map(triageModel => ({
    category: 'Classifiers',
    label: triageModel.display_name,
    optionStartAdornment: triageModel.icon ? (
      <Box display='flex' mr={0.5}>
        <img alt='' src={triageModel.icon} width='20px' />
      </Box>
    ) : undefined,
    value: triageModel.name,
  }));

  const { isInUse: isTicketFieldInUse, value: ticketMappingValue } =
    ticketMappingData;

  const isCustomTicketFieldOptionsEmpty = !customTicketFieldOptions.length;
  const isTriageModelOptionsEmpty = !triageModelOptions.length;

  const areOptionsEmpty = isTriageMappingEnabled
    ? isTriageModelOptionsEmpty && isCustomTicketFieldOptionsEmpty
    : isCustomTicketFieldOptionsEmpty;

  const areInvalidTypes =
    !isTriageMappingEnabled || isTriageModelOptionsEmpty
      ? !allowedTypes.includes(contextVariableType)
      : false;

  const isMappingDropdownDisabled =
    areInvalidTypes || areOptionsEmpty || isTicketFieldInUse || isSystemContext;

  const mappingsStartAdornmentSrc = useGetMappingDropdownStartAdornment(
    helpdesk,
    triageModels,
    ticketMappingValue,
  );

  const mappingStartAdornemnt = mappingsStartAdornmentSrc ? (
    <Box
      display='flex'
      mr={0.5}
      sx={{ opacity: isMappingDropdownDisabled ? 0.5 : 1 }}
    >
      <img alt='' src={mappingsStartAdornmentSrc} width='20px' />
    </Box>
  ) : null;

  const getPlaceholder = () => {
    if (isTriageMappingEnabled) {
      return 'Select a triage classifier or a custom field';
    } else {
      return isCustomTicketFieldOptionsEmpty
        ? `No ${helpdesk} ticket fields available`
        : `Select ${helpdesk} ticket field`;
    }
  };

  const defaultOption =
    !!customTicketFields.length || !!enabledModels.length
      ? {
          category: '',
          label: getPlaceholder(),
          value: SELECT_DEFUALT_OPTION,
        }
      : null;

  const options = defaultOption
    ? [defaultOption, ...customTicketFieldOptions, ...triageModelOptions]
    : [...customTicketFieldOptions, ...triageModelOptions];

  return (
    <SelectDropdown
      disabled={isMappingDropdownDisabled}
      id={`custom-ticket-field-${rowIndex}`}
      isMenuSearchable
      menuTitle={
        enabledModels.length
          ? undefined
          : `${capitalizeFirstLetter(helpdesk || '')} ticket fields`
      }
      onChange={e => {
        const value = e.target.value;
        if (value === SELECT_DEFUALT_OPTION) {
          deleteTicketFieldMapping();
        } else {
          updateTicketFieldMappings(value);
        }
      }}
      options={options}
      placeholder={getPlaceholder()}
      startAdornment={mappingStartAdornemnt}
      value={ticketMappingValue}
    />
  );
};

export default MappingSelectDropdown;
