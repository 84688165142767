import React, { Dispatch, FC, SetStateAction } from 'react';
import { Box, styled } from '@mui/material';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';
import {
  convertToNumber,
  getKustomerCustomAttributesData,
} from 'src/pages/workflow-builder-edit/customization-panel/utils';
import {
  Label,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import {
  KustomerHandoffCustomAttribute,
  KustomerHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';

type Props = {
  customizationData: KustomerHandoffCustomization;
  setCustomizationData: Dispatch<SetStateAction<KustomerHandoffCustomization>>;
};

const emptyAttribute: KustomerHandoffCustomAttribute = {
  field: '',
  value: '',
};

const KustomerChatCustomAttributes: FC<React.PropsWithChildren<Props>> = ({
  customizationData,
  setCustomizationData,
}) => {
  const customAttributes =
    customizationData.agent_chat_handoff_settings.kustomer_custom_attributes;
  const currentlyUsedAttributes = customizationData.agent_chat_handoff_settings
    .custom_attributes.length
    ? customizationData.agent_chat_handoff_settings.custom_attributes
    : [{ ...emptyAttribute }];

  const kustomerCustomAttributesData = getKustomerCustomAttributesData(
    currentlyUsedAttributes,
    customAttributes,
  );

  const availableAttributes = customAttributes.map(attribute => {
    return {
      label: attribute.display_name,
      value: attribute.id,
    };
  });

  const deleteAttribute = (idx: number) => {
    setCustomizationData(handoffData => {
      const newAttributes = [
        ...handoffData.agent_chat_handoff_settings.custom_attributes,
      ];
      newAttributes.splice(idx, 1);

      return {
        ...handoffData,
        agent_chat_handoff_settings: {
          ...handoffData.agent_chat_handoff_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const addAttribute = () => {
    setCustomizationData(handoffData => {
      const newAttributes = [
        ...handoffData.agent_chat_handoff_settings.custom_attributes,
      ];
      newAttributes.push({ ...emptyAttribute });

      return {
        ...handoffData,
        agent_chat_handoff_settings: {
          ...handoffData.agent_chat_handoff_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const getAttributeOptions = (field: string): string[] => {
    for (const attribute of customAttributes) {
      if (attribute.id === field) {
        return attribute.options;
      }
    }

    return [];
  };

  const onAttributeChange = (newValue: string, idx: number) => {
    const newAttributes = [...currentlyUsedAttributes];
    newAttributes.splice(idx, 1, { field: newValue, value: '' });

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        agent_chat_handoff_settings: {
          ...handoffData.agent_chat_handoff_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const onAttributeOptionValueChange = (newValue: string, idx: number) => {
    const newAttributes = [...currentlyUsedAttributes];
    const attributeField = currentlyUsedAttributes[idx].field;
    newAttributes.splice(idx, 1, { field: attributeField, value: newValue });

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        agent_chat_handoff_settings: {
          ...handoffData.agent_chat_handoff_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const getSelectedAttributeOption = (field: string): string => {
    for (const attribute of currentlyUsedAttributes) {
      if (attribute.field === field) {
        return attribute.value.toString();
      }
    }

    return '';
  };

  const onAttributeValueChange = (value: string | number, idx: number) => {
    const newAttributes = [...currentlyUsedAttributes];
    const attributeField = currentlyUsedAttributes[idx].field;
    newAttributes.splice(idx, 1, {
      field: attributeField,
      value,
    });

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        agent_chat_handoff_settings: {
          ...handoffData.agent_chat_handoff_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const getInputComponent = (
    customHandoffAttribute: KustomerHandoffCustomAttribute,
    idx: number,
  ): JSX.Element => {
    const options = getAttributeOptions(customHandoffAttribute.field);
    const selectedOption = getSelectedAttributeOption(
      customHandoffAttribute.field,
    );

    if (options.length) {
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.colors.white,
            flex: '1 1 50%',
          }}
        >
          <ContextVariableSelectDropdown
            additionalOptions={options.map(option => {
              return {
                category: 'Custom Attribute Options',
                label: option,
                value: option,
              };
            })}
            aria-label='Select a Value...'
            id={`select-kustomer-attribute-${customHandoffAttribute.value}`}
            onChange={value => onAttributeOptionValueChange(value, idx)}
            shouldIncludeSystemContextVariables
            shouldProvideCVIdFormatting
            value={selectedOption}
          />
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.colors.white,
            flex: '1 1 50%',
          }}
        >
          <ContextMention
            errorMessage={
              kustomerCustomAttributesData.find(
                error => error.id === customHandoffAttribute.field,
              )?.error
            }
            onChange={value => {
              const type = kustomerCustomAttributesData.find(
                customAttribute =>
                  customAttribute.id === customHandoffAttribute.field,
              )?.type;

              const newValue =
                value.trim() && type === 'number'
                  ? convertToNumber(value)
                  : value;

              onAttributeValueChange(newValue, idx);
            }}
            placeholder='Enter value'
            value={customHandoffAttribute.value.toString()}
          />
        </Box>
      );
    }
  };

  return (
    <>
      <Label>Chat Handoff Custom Attribute</Label>
      {currentlyUsedAttributes.map((customAttribute, idx) => {
        return (
          <Box
            key={`custom-field-${customAttribute.field}-${idx}`}
            sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box
                sx={{
                  backgroundColor: theme.palette.colors.white,
                  flex: '1 1 50%',
                }}
              >
                <SelectDropdown
                  aria-label='Select an Attribute...'
                  disablePortal
                  id={`select-${customAttribute.field}-${idx}`}
                  menuMaxHeight={180}
                  onChange={event => {
                    onAttributeChange(event.target.value, idx);
                  }}
                  options={availableAttributes}
                  value={customAttribute.field}
                />
              </Box>
              <Spacer width='8px' />
              {getInputComponent(customAttribute, idx)}
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  width: '30px',
                }}
              >
                <DeleteButton
                  onClick={() => {
                    deleteAttribute(idx);
                  }}
                  role='button'
                  src={xIcon}
                />
              </Box>
            </Box>
            {idx !== currentlyUsedAttributes.length - 1 && (
              <Spacer height='10px' />
            )}
          </Box>
        );
      })}
      <Add
        isDisabled={currentlyUsedAttributes.length === customAttributes.length}
        onClick={addAttribute}
      >
        + Add field
      </Add>
    </>
  );
};

export default KustomerChatCustomAttributes;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

export const Add = styled('a')<{ isDisabled: boolean }>(({ isDisabled }) => ({
  color: isDisabled
    ? theme.palette.colors.grey[300]
    : theme.palette.colors.purple[500],
  cursor: 'pointer',
  fontSize: '16px',
  fontStyle: isDisabled ? 'italic' : 'normal',
  lineHeight: '16px',
  marginTop: '16px',
  maxWidth: '100%',
  pointerEvents: isDisabled ? 'none' : 'auto',
  textAlign: 'left',
  width: '100%',
}));
