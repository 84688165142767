import { useNavigate } from 'react-router-dom';

import { TOAST_TIMEOUT } from '../triage-config-detail-page/helpers';
import DuplicateVersionDialog from '../triage-model-detail-page/drawers-and-dialogs/DuplicateVersionDialog';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useGetModelVersionsQuery } from 'src/services/triage/triageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

interface DuplicateVersionWrapperProps {
  isOpen: boolean;
  model: VersionedTriageModel;
  onClose: () => void;
}

const DuplicateVersionWrapper = ({
  isOpen,
  model,
  onClose,
}: DuplicateVersionWrapperProps) => {
  const { refetch } = useGetModelVersionsQuery(model.model_id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSuccess = async (data: VersionedTriageModel) => {
    refetch().then(() => {
      navigate(
        `${Routes.PREDICTIONS_SETTINGS}/version-control/${data.model_id}?version=${data.version_id}`,
      );
    });

    dispatch(
      setGlobalToastOptions({
        autoHideDuration: TOAST_TIMEOUT,
        title: 'Successfully duplicated the version',
        variant: 'main',
      }),
    );
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <DuplicateVersionDialog
      currentVersionName={model.version_name}
      isOpen={isOpen}
      modelId={model.model_id}
      onClose={onClose}
      onSuccess={handleSuccess}
      versionId={model.version_id}
    />
  );
};

export default DuplicateVersionWrapper;
