import React, { useRef } from 'react';
import type { Identifier } from 'dnd-core';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import { Box } from '@mui/material';

const ItemTypes = {
  AUTOMATION_LIST: 'automationList',
};

interface DragItem {
  id: string;
  index: number;
  originalIndex: number;
  type: string;
}

const DraggableAutomationListItem = ({
  children,
  id,
  index,
  onDrop,
  reorderComponentsOnDrag,
}: {
  children: React.ReactNode;
  id: string;
  index: number;
  onDrop: (dragIndex: number, hoverIndex: number) => void;
  reorderComponentsOnDrag: (dragIndex: number, hoverIndex: number) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag(
    {
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      item: () => {
        return { id, index, originalIndex: index };
      },
      type: ItemTypes.AUTOMATION_LIST,
    },
    [index],
  );

  const [{ handlerId }, dropRef] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >(
    {
      accept: ItemTypes.AUTOMATION_LIST,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      drop(item) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.originalIndex;
        const dropIndex = index;

        //  Don't replace items with themselves
        if (dragIndex === dropIndex) {
          return;
        }

        onDrop(dragIndex, dropIndex);
      },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index ?? null;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        reorderComponentsOnDrag(dragIndex, hoverIndex);

        item.index = hoverIndex;
      },
    },
    [index],
  );

  drag(dropRef(ref));

  const extraProps = {
    'data-handler-id': handlerId,
    ref: ref,
    sx: {
      opacity: isDragging ? 0 : 1,
      width: '100%',
    },
  };

  return <Box {...extraProps}>{children}</Box>;
};

export default DraggableAutomationListItem;
