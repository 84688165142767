import { useMemo } from 'react';

import { useGetConnectableIntentsQuery } from 'src/services/workflow-builder-metrics';
import { SolveWidgetProduct } from 'src/types/types';

export const useGetSortedConnectableIntents = ({
  product = 'workflow_builder',
}: {
  product?: SolveWidgetProduct;
} = {}) => {
  const { data, isLoading } = useGetConnectableIntentsQuery({ product });
  const { intents = [] } = data ?? {};

  const sortedIntents = useMemo(() => {
    const copyOfIntents = [...intents];

    copyOfIntents.sort((intentA, intentB) => {
      if (intentA.is_active && !intentB.is_active) {
        return -1;
      } else if (!intentA.is_active && intentB.is_active) {
        return 1;
      }
      return intentA.intent_name.localeCompare(intentB.intent_name);
    });

    return copyOfIntents;
  }, [intents]);

  return { intents: sortedIntents, isLoading };
};
