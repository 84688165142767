import { useTheme } from '@mui/material';

import {
  Button,
  LoadingIndicator,
} from '@forethought-technologies/forethought-elements';
import {
  getWorkflowBuilderUrl,
  openTab,
} from 'src/pages/intent-conversation-analytics/helpers';
import { useGetIntentsQuery } from 'src/services/workflow-builder-metrics/workflowBuilderMetricsApi';
import { AllConversationChannel } from 'src/types/workflowBuilderAPITypes';

export const DeepLink = ({
  channel,
  linkText = 'View the failed step ',
  stepId,
  workflowId,
}: {
  channel: AllConversationChannel | null;
  linkText?: string;
  stepId: string;
  workflowId: string;
}) => {
  const { palette } = useTheme();
  const { data, isLoading } = useGetIntentsQuery();

  const intentId =
    data?.intents.find(intent => intent.intent_workflow_id === workflowId)
      ?.intent_definition_id || '';

  const targetURL = getWorkflowBuilderUrl({
    channel: channel || 'widget',
    intentDefinitionId: intentId || '',
    intentWorkflowId: workflowId,
    mode: 'classic',
    stepId: stepId,
  });

  if (isLoading) {
    return <LoadingIndicator color={palette.colors.black} size='large' />;
  }

  return (
    <Button
      onClick={() => {
        openTab(targetURL);
      }}
      variant='main'
    >
      {linkText}
    </Button>
  );
};
