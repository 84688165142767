import React, { createContext, useMemo } from 'react';
import { Edge } from 'reactflow';

import { useNearestEdge } from './hooks/useNearestEdge';

interface DndContextState {
  nearestEdge: Edge | null;
}

export const DndContext = createContext<DndContextState>({ nearestEdge: null });

/**
 * Provider to calculate nearest 'dropzone' edge for a dragged item
 * Used by drop targets to determine their active/hover state
 * Used by drag sources to handle drop events and place component in the correct spot
 */
export function DndContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const nearestEdge = useNearestEdge();
  const value = useMemo(() => ({ nearestEdge }), [nearestEdge]);

  return <DndContext.Provider value={value}>{children}</DndContext.Provider>;
}
