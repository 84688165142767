import React, { useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { ReactSVG } from 'react-svg';
import { Cell } from 'react-table';
import useOnClickOutside from 'use-onclickoutside';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconCheck, IconInfoCircle } from '@tabler/icons-react';

import {
  Button,
  Colors,
  Dialog,
  Skeleton,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import returnIcon from 'src/assets/images/return-icon.svg';
import { formatContextVariableForDisplay } from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/helpers';
import { EmailTranslation } from 'src/pages/workflow-builder-edit/types';
import {
  ConfigurationTranslation,
  StepTranslation,
} from 'src/types/workflowBuilderAPITypes';
import { FlatCv } from 'src/utils/actionBuilder/helpers';

interface EditTranslationCellProps {
  allContextVariables: FlatCv[];
  cell: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | Cell<(StepTranslation | ConfigurationTranslation) & { index: number }, any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Cell<EmailTranslation, any>;
  intentsToReplace: Set<string>;
  isDisabled?: boolean;
  isRestoringTranslation: boolean;
  isTranslationEmpty: boolean;
  isTranslationsLoading: boolean;
  mentionsData: Array<{ display: string; id: string }>;
  onReplaceAll: () => void;
  onRestoreTranslation: () => void;
  onTextAreaFocus?: () => void;
  onUpdateTranslations: (newTargetText: string) => void;
  repeatedTranslationsLength: number;
  selectedLanguage: string;
  setAllowPageResetRef: (flag: boolean) => void;
  shouldOverrideTranslation: boolean;
  value: string;
}
const EditTranslationCell: React.FC<
  React.PropsWithChildren<EditTranslationCellProps>
> = ({
  allContextVariables,
  cell,
  intentsToReplace,
  isDisabled = false,
  isRestoringTranslation,
  isTranslationEmpty,
  isTranslationsLoading,
  mentionsData,
  onReplaceAll,
  onRestoreTranslation,
  onTextAreaFocus,
  onUpdateTranslations,
  repeatedTranslationsLength,
  setAllowPageResetRef,
  shouldOverrideTranslation,
  value,
}) => {
  const theme = useTheme();

  // States and refs
  const [isEditing, setIsEditing] = useState(false);
  const [isReplaceInfoModalVisible, setIsReplaceInfoModalVisible] =
    useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const rowCellRef = useRef<HTMLTableDataCellElement>(null);

  useOnClickOutside(rowCellRef, () => {
    // if the replace info modal is opened, then any clicks we performed shouldn't affect the isEditing state
    // of the cell
    if (!isReplaceInfoModalVisible) {
      setIsEditing(false);
    }
  });

  // cell props provided by React-table
  const { key, ...restCellProps } = cell.getCellProps();

  return (
    <RowCell
      key={key}
      {...restCellProps}
      isEditing={isEditing}
      isEmpty={isTranslationEmpty && !isTranslationsLoading}
      onClick={() => {
        textAreaRef.current?.focus();
      }}
      ref={rowCellRef}
    >
      {isTranslationsLoading && (
        <Box height='22px'>
          <Skeleton />
        </Box>
      )}
      {!isTranslationsLoading && (
        <TargetTextTextArea
          autoFocus={false}
          dir='auto'
          disabled={isDisabled}
          inputRef={textAreaRef}
          onBlur={() => setAllowPageResetRef(true)}
          onChange={e => {
            onUpdateTranslations(e.target.value);
          }}
          onFocus={e => {
            setIsEditing(true);
            onTextAreaFocus && onTextAreaFocus();
            // this set the cursor to be at the end of the text
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length,
            );
          }}
          value={formatContextVariableForDisplay(allContextVariables, value)}
        >
          <Mention
            data={mentionsData}
            displayTransform={(id, display) => `$${display}`}
            markup='{{__display__}}'
            trigger={/(\$([^$]*))$/u} // matches any number of $ at the end of the text
          />
        </TargetTextTextArea>
      )}
      {isEditing && repeatedTranslationsLength > 0 && (
        <Box sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
          <Button
            onClick={() => {
              onReplaceAll();
            }}
            size='medium'
            startIcon={<IconCheck size={20} />}
            variant='secondary'
          >
            Replace all
          </Button>
          <Box
            onClick={() => {
              setIsReplaceInfoModalVisible(true);
            }}
            sx={{
              '&:hover': {
                textDecoration: 'underline',
              },
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <IconInfoCircle size={20} />
            <Typography color={theme.palette.colors.grey[700]} variant='font14'>
              See details
            </Typography>
          </Box>
        </Box>
      )}
      {shouldOverrideTranslation && !isEditing && !isTranslationsLoading && (
        <RestoreButtonWrapper className='restore-button-wrapper'>
          <Button
            isLoading={isRestoringTranslation}
            onClick={e => {
              e.stopPropagation();
              onRestoreTranslation();
            }}
            startIcon={<ReactSVG src={returnIcon} />}
            variant='secondary'
          >
            restore
          </Button>
        </RestoreButtonWrapper>
      )}
      <Box width='450px'>
        <Dialog
          footer={
            <Button
              onClick={() => {
                onReplaceAll();
                setIsReplaceInfoModalVisible(false);
              }}
              startIcon={<IconCheck size={20} />}
              variant='secondary'
            >
              Replace all
            </Button>
          }
          onClose={() => setIsReplaceInfoModalVisible(false)}
          open={isReplaceInfoModalVisible}
          title={`${repeatedTranslationsLength} identical copies found`}
        >
          <>
            <Typography color={theme.palette.colors.grey[700]} variant='font14'>
              Below is a list of workflows or areas that contain the identical
              copies and will be replaced.
            </Typography>
            <Box sx={{ maxHeight: '450px', overflowY: 'scroll' }}>
              <Typography variant='font14'>
                <List>
                  {Array.from(intentsToReplace).map(intentName => (
                    <li key={intentName}>{intentName}</li>
                  ))}
                </List>
              </Typography>
            </Box>
          </>
        </Dialog>
      </Box>
    </RowCell>
  );
};

export default EditTranslationCell;

const RestoreButtonWrapper = styled('div')`
  position: absolute !important;
  margin: auto !important;
  visibility: hidden;
  right: 5px;
  top: 0;
  bottom: 0;
  height: fit-content;
`;

const TargetTextTextArea = styled(MentionsInput)`
  resize: none;

  [class$='__input'] {
    outline: none;
    cursor: pointer;
  }

  [class$='__suggestions'] {
    margin-top: 35px !important;
    border: 1px solid ${theme.palette.colors.grey[100]};
    box-shadow: 0 20px 30px ${theme.palette.colors.grey[100]};
    border-radius: 4px;
    height: 210px;
    overflow: scroll;
  }

  li {
    color: ${theme.palette.colors.purple[500]};
    padding: 10px;

    ::before {
      content: '$';
    }
  }

  [class$='__suggestions__item--focused'] {
    background-color: ${Colors.ui.backgrounds.slate};
  }
`;

const RowCell = styled('td')<{ isEditing: boolean; isEmpty: boolean }>`
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  ${props =>
    props.isEditing
      ? `border: 1px solid ${theme.palette.colors.purple[500]} !important;`
      : `border-bottom: 1px solid ${theme.palette.colors.slate[200]};`}
  ${props =>
    props.isEmpty &&
    `border: 1px solid ${theme.palette.colors.red[500]} !important;`}
  color: black;
  position: relative;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  cursor: pointer;
  vertical-align: top;
  width: 40%;

  &:hover {
    border: 1px solid ${theme.palette.colors.grey[600]};
  }

  &:hover .restore-button-wrapper {
    visibility: visible;
  }
`;

const List = styled('ul')`
  margin-bottom: 0;
`;
