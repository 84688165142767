import { useMemo } from 'react';

import { useSolveMetricsQueryParams } from '../../intent-workflows-table/hooks/useSolveMetricsQueryParams';
import capitalize from 'lodash/fp/capitalize';
import { URLParamTabs } from 'src/components/url-param-tabs/URLParamTabs';
import { supportedChannelsMap } from 'src/constants/solve';
import { useBuildInsightsChatNavigationCallback } from 'src/pages/workflow-builder-edit/hooks';

/**
 * Tabs shown within the landing page of the workflow builder (between header and breakdowns/conversations/templates).
 * They are dynamic and depend on the selected channel.
 */
export default function LandingPageSubTabs() {
  const { channel } = useSolveMetricsQueryParams();
  const navigateToInsightsChat = useBuildInsightsChatNavigationCallback();

  const tabs = useMemo(() => {
    return supportedChannelsMap[channel].map(capitalize);
  }, [channel]);

  return (
    <URLParamTabs
      ariaLabel='solve view tabs'
      paramName='header'
      tabCallback={tabIndex => {
        if (channel === 'widget' && tabs[tabIndex] === 'Chats') {
          navigateToInsightsChat();
        }
      }}
      tabs={tabs}
      typographyVariant='font16Bold'
    />
  );
}
