import React, { useCallback } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import {
  ScrollToTop,
  Table,
} from '@forethought-technologies/forethought-elements';
// @ts-expect-error Will be removed along with new_table_component feature flag
import { type RowData } from '@forethought-technologies/forethought-elements/dist/Components/Table/types';
import Spinner from 'src/components/spinner/Spinner';

export function InfiniteTable<T extends RowData>({
  hasNextPage,
  isLoadingFirstPage,
  isLoadingNextPage,
  onLoadMore,
  scrollTop,
  scrollToTop,
  stickyHeaderHeight,
  tableProps,
}: {
  hasNextPage: boolean;
  isLoadingFirstPage: boolean;
  isLoadingNextPage: boolean;
  onLoadMore: () => void;
  scrollTop: number;
  scrollToTop: (yOffset: number, smooth?: boolean) => void;
  stickyHeaderHeight: number;
  tableProps: React.ComponentProps<typeof Table<T>>;
}) {
  const [listenerRef] = useInfiniteScroll({
    hasNextPage,
    loading: isLoadingNextPage,
    onLoadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const handleScrollToTopClick = useCallback(
    () => scrollToTop(0, true),
    [scrollToTop],
  );

  return (
    <>
      <Table
        {...tableProps}
        customStickyHeaderTopValue={stickyHeaderHeight + 'px'}
        infiniteScrollListener={
          hasNextPage && (
            <div ref={listenerRef}>
              <Spinner />
            </div>
          )
        }
        isLoading={isLoadingFirstPage}
        rowsPerPage={isLoadingFirstPage ? 100 : undefined} // a hack to have the table take up more space while loading to prevent jumping on sort
        shouldDisablePagination
        shouldHandleSort={false}
        tableLayout='fixed'
      />
      <ScrollToTop
        isVisible={scrollTop > stickyHeaderHeight}
        onClick={handleScrollToTopClick}
      />
    </>
  );
}
