import { Box, useTheme } from '@mui/material';

import {
  Drawer,
  formatToDateAndTime,
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import Section from '../Section';
import { transformSnakeCaseToText } from './utils';
import { AuditQueryEvent } from 'src/types/auditTypes';

interface AuditEventLogsDrawerProps {
  auditEventLog?: AuditQueryEvent;
  isLoading: boolean;
  onClose: () => void;
}

export default function AuditEventLogsDrawer({
  auditEventLog,
  isLoading,
  onClose,
}: AuditEventLogsDrawerProps) {
  const isOpen = !!auditEventLog?.audit_id && !isLoading;

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      {isLoading && (
        <Box p='40px'>
          <Box py='6px'>
            <Skeleton height='24px' width='100%' />
          </Box>
          <Box py='20px'>
            <Skeleton height='400px' width='100%' />
          </Box>
        </Box>
      )}
      {isOpen && <DrawerBody auditEventLog={auditEventLog} />}
    </Drawer>
  );
}

function DrawerBody({ auditEventLog }: { auditEventLog: AuditQueryEvent }) {
  const { palette } = useTheme();

  const {
    action,
    audit_id,
    extra_information,
    product,
    timestamp,
    user_email,
    user_ip_address,
  } = auditEventLog;

  const extraInformationData = Object.keys(extra_information).map(key => {
    return {
      label: transformSnakeCaseToText(key),
      value: extra_information[key],
    };
  });

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='32px'
      height='100%'
      p='40px'
      sx={{ overflowY: 'scroll' }}
    >
      <Box py='6px'>
        <Typography variant='font24'>Audit Log Details</Typography>
      </Box>
      <Box>
        <Box display='flex' gap='4px'>
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            Audit ID:
          </Typography>
          <Typography color={palette.colors.black} variant='font12Medium'>
            {audit_id}
          </Typography>
        </Box>
        <Box display='flex' gap='4px'>
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            Timestamp:
          </Typography>
          <Typography color={palette.colors.black} variant='font12Medium'>
            {formatToDateAndTime(timestamp)}
          </Typography>
        </Box>
      </Box>

      <Section
        data={[
          { label: 'User Email', value: user_email },
          {
            includeDivider: true,
            label: 'User IP Address',
            value: user_ip_address,
          },
          {
            label: 'Product',
            value: product,
          },
          {
            label: 'Action',
            value: action,
          },
        ]}
        label='Context'
      />
      {extraInformationData.length > 0 && (
        <Section data={[...extraInformationData]} label='Extra Information' />
      )}
    </Box>
  );
}
