import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
import Spinner from '../../spinner';
import { useGetFocusedTriageModel } from '../triage-models-overview-page/hooks/useGetFocusedTriageModel';
import { useDispatchGlobalToastErrors } from './hooks/useDispatchGlobalToastErrors';
import { useGetHelpdesk } from './hooks/useGetHelpdesk';
import TriageConfigDetailPredictions from './triage-config-detail-demo';
import TriageConfigDetailDescription from './triage-config-detail-description';
import TriageConfigDetailSetting from './triage-config-detail-settings';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import { useEmitTrackingEvent } from 'src/hooks/hooks';
import {
  selectIsFocusedTriageModelGetLoading,
  selectIsHelpdeskIntegrationsLoading,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import { Routes } from 'src/utils/enums';

const TriageConfigDetailPage = () => {
  const { name = '' } = useParams<'name'>();
  const navigate = useNavigate();
  const triageModel = useGetFocusedTriageModel(name);
  const isFocusedTriageModelLoading = useSelector(
    selectIsFocusedTriageModelGetLoading,
  );
  const isHelpdeskIntegrationsLoading = useSelector(
    selectIsHelpdeskIntegrationsLoading,
  );
  useDispatchGlobalToastErrors();
  useGetHelpdesk();
  useEmitTrackingEvent('triage-viewed-prediction-config-page', {
    model_name: name,
    scope: 'self-serve',
  });

  const isPageLoaded =
    !isFocusedTriageModelLoading &&
    !isHelpdeskIntegrationsLoading &&
    triageModel;

  return (
    <>
      <NavbarV2
        currentTabOverride={
          isPageLoaded ? triageModel?.display_name || triageModel?.name : ''
        }
        onGoBack={() => navigate(Routes.PREDICTIONS_SETTINGS)}
      />
      <Container>
        {isPageLoaded ? (
          <>
            <SectionMain>
              <TriageDescriptionContainer>
                <TriageConfigDetailDescription triageModel={triageModel} />
              </TriageDescriptionContainer>
              <TriageSettingsContainer>
                <TriageConfigDetailSetting triageModel={triageModel} />
              </TriageSettingsContainer>
            </SectionMain>
            <SectionDemo>
              <TriageConfigDetailPredictions />
            </SectionDemo>
          </>
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

const Container = styled('section')`
  background-color: ${theme.palette.colors.white};
  display: grid;
  grid-template-columns: 1fr 450px;
  flex: 1;
`;

const SectionMain = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1 0 33%;
  padding: 40px;
`;

const TriageDescriptionContainer = styled('div')`
  flex: 1;
  min-height: 310px;
`;

const TriageSettingsContainer = styled('div')`
  flex: 2;
`;

const SectionDemo = styled('div')`
  border-left: 1px solid ${theme.palette.colors.slate[200]};
  padding: 24px 32px;
`;

export default TriageConfigDetailPage;
