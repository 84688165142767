import Box from '@mui/material/Box';

import { textStyles } from './constants';
import TicketTimeStamp from './TicketTimeStamp';

interface TicketResponseProps {
  body: string;
  timeStamp: string;
}

const TicketResponse = ({ body, timeStamp }: TicketResponseProps) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      rowGap={1.5}
      sx={{ wordWrap: 'break-word' }}
    >
      <TicketTimeStamp dateCreated={timeStamp as string} />
      <Box
        dangerouslySetInnerHTML={{ __html: body || '' }}
        sx={textStyles}
      ></Box>
    </Box>
  );
};

export default TicketResponse;
