import React from 'react';
import { Box } from '@mui/material';

interface SettingsPageLayoutProps {
  children: React.ReactNode;
  withPadding?: boolean;
}

export default function SettingsPageLayout({
  children,
  withPadding = true,
}: SettingsPageLayoutProps) {
  return (
    <Box
      sx={theme => ({
        backgroundColor: theme.palette.colors.white,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: withPadding ? '16px 40px' : 0,
      })}
    >
      {children}
    </Box>
  );
}
