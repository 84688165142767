import { useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { IconDotsVertical, IconTrash } from '@tabler/icons-react';

import {
  IconButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import shareNoteIcon from 'src/assets/images/share-note-icon.svg';

interface MenuButtonProps {
  isPublic: boolean;
  noteId: string;
  onDelete: (noteId: string) => void;
  onToggleNoteShare: (noteId: string, isPublic: boolean) => void;
}

const MenuButton = ({
  isPublic,
  noteId,
  onDelete,
  onToggleNoteShare,
}: MenuButtonProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        aria-label='Notes menu'
        hoverBackgroundColor={palette.colors.purple[200]}
        id='notes-menu-button'
        onClick={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        size='medium'
        variant='ghost'
      >
        <IconDotsVertical size={17.5} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id='discover-menu'
        MenuListProps={{
          'aria-labelledby': 'notes-menu-button',
          role: 'listbox',
          sx: {
            py: 1.5,
          },
        }}
        onClose={e => {
          //Casting is needed as MUI has {} as a type for this event
          const event = e as MouseEvent;
          event?.stopPropagation && event.stopPropagation();
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        PaperProps={{
          elevation: 1,
          sx: {
            border: `1px solid ${palette.colors.slate[200]}`,
            borderRadius: '4px',
            width: '300px',
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            onDelete(noteId);
          }}
          sx={{
            alignItems: 'center',
            color: palette.error.main,
            display: 'flex',
            padding: '6px 16px',
          }}
        >
          <Box alignItems='center' display='flex' mr={1}>
            <IconTrash size={20} />
          </Box>
          <Typography variant='font14'>Delete note</Typography>
        </MenuItem>
        {!isPublic && (
          <MenuItem
            onClick={e => {
              e.stopPropagation();

              onToggleNoteShare(noteId, false);
            }}
            sx={{
              alignItems: 'center',
              display: 'flex',
              padding: '6px 16px',
            }}
          >
            <Box alignItems='center' display='flex' mr={1}>
              <img src={shareNoteIcon} />
            </Box>
            <Typography variant='font14'>Share note</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MenuButton;
