import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import styled from '@emotion/styled';

import { useGetUndefinedContextVariableIdsOnCurrentAction } from '../../hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import { FieldNameToIdx } from './constants';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { CustomFieldDict } from 'src/pages/workflow-builder-edit/customization-panel/JiraServiceManagementCreateRequestCustomizationPanel';
import { JiraServiceManagementCreateRequestSettings } from 'src/types/workflowBuilderAPITypes';

interface TicketCustomFieldsProps {
  customizationData: JiraServiceManagementCreateRequestSettings;
  errorMessages: Array<string | Array<string>>;
  setCustomizationData: Dispatch<
    SetStateAction<JiraServiceManagementCreateRequestSettings>
  >;
}

const TicketCustomFields: FC<
  React.PropsWithChildren<TicketCustomFieldsProps>
> = ({ customizationData, errorMessages, setCustomizationData }) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();
  const [customFields, setCustomFields] = useState<CustomFieldDict[]>([]);

  useEffect(() => {
    const otherRequestFields = customizationData?.other_request_fields || {};
    const arrayOfObjects = Object.keys(otherRequestFields).map(key => {
      return {
        [key]: otherRequestFields[key],
      };
    });
    setCustomFields(arrayOfObjects);
  }, [setCustomFields, customizationData]);

  const handleConfigUpdate = (updatedCustomFields: CustomFieldDict[]) => {
    const updatedDict = Object.assign(
      {},
      ...updatedCustomFields.map(field => {
        const key = Object.keys(field)[0];
        const value = field[key];
        return { [key]: value };
      }),
    );
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        other_request_fields: updatedDict,
      };
    });
  };

  return (
    <>
      {customFields.map((field, index) => {
        const key = Object.keys(field)[0];
        const value = field[key]?.toString() || '';
        return (
          <CustomFieldContainer key={index}>
            <InputWrapper>
              <ContextVariableAutocomplete
                error={
                  errorMessages[FieldNameToIdx.CUSTOM_FIELD_KEY][index] !== ''
                }
                onChange={fieldValue => {
                  const updatedField = {
                    ...field,
                    [fieldValue]: value,
                  };
                  if (key || key === '') {
                    delete updatedField[key];
                  }
                  const updatedCustomFields = [...customFields];
                  updatedCustomFields[index] = updatedField;
                  setCustomFields(updatedCustomFields);
                  handleConfigUpdate(updatedCustomFields);
                }}
                placeholder='Custom Field Key'
                shouldIncludeSystemContextVariables
                undefinedContextVariables={undefinedContextVariableIds}
                value={key}
              />
            </InputWrapper>
            <InputWrapper>
              <ContextVariableAutocomplete
                error={
                  errorMessages[FieldNameToIdx.CUSTOM_FIELD_VALUE][index] !== ''
                }
                onChange={fieldValue => {
                  const updatedField = {
                    ...field,
                    [key]: fieldValue,
                  };
                  const updatedCustomFields = [...customFields];
                  updatedCustomFields[index] = updatedField;
                  setCustomFields(updatedCustomFields);
                  handleConfigUpdate(updatedCustomFields);
                }}
                placeholder='Custom Field Value'
                shouldIncludeSystemContextVariables
                undefinedContextVariables={undefinedContextVariableIds}
                value={value}
              />
            </InputWrapper>
            <CloseButton
              onClick={() => {
                const updatedCustomFields = [...customFields];
                updatedCustomFields.splice(index, 1);
                setCustomFields(updatedCustomFields);
                handleConfigUpdate(updatedCustomFields);
              }}
              role='button'
              src={xIcon}
            />
          </CustomFieldContainer>
        );
      })}
      <LinkOut
        onClick={() => {
          const updatedCustomFields = [...customFields];
          updatedCustomFields.push({
            '': '',
          });
          setCustomFields(updatedCustomFields);
        }}
      >
        + Add field
      </LinkOut>
    </>
  );
};

export default TicketCustomFields;

const InputWrapper = styled('div')`
  flex: 1 1 50%;
`;

const CloseButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const CustomFieldContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
`;

const LinkOut = styled('a')`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
`;
