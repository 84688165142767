import { useEffect } from 'react';

import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export const useSetGlobalErrorToast = (isError: boolean, message: string) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isError) {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 4000,
          title: message,
          variant: 'danger',
        }),
      );
    }
  }, [isError, message, dispatch]);
};
