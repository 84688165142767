import { useCallback } from 'react';
import {
  Edge,
  getOutgoers,
  MarkerType,
  Node,
  NodeProps,
  useReactFlow,
} from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import { FLOW_EDGES } from '../components/flow-layout/EdgeTypes';
import { FLOW_NODES } from '../components/flow-layout/NodeTypes';
import { DragItem } from '../types';

// this hook implements the logic for dropping a new node on a placeholder node
export default function useNodeDrop(id: NodeProps['id']) {
  const { getEdges, getNode, getNodes, setEdges, setNodes } = useReactFlow();

  const handleNodeDrop = useCallback(
    (insertNodeType: string, insertNodeId: string, item: DragItem) => {
      const parentNode = getNode(id);
      if (!parentNode) {
        return;
      }

      // for possibly adding new placeholders and additional branches
      const newEdges: Edge[] = [];
      const newNodes: Node[] = [];

      const hasChildren = !!getOutgoers(parentNode, getNodes(), getEdges())
        .length;

      // if this is a leaf node, add new placeholder as child
      if (!hasChildren) {
        // create a unique id for the placeholder node that will be added as a child of the new node
        const childPlaceholderId = uuidv4();
        // create a placeholder node that will be added as a child of the new node
        const childPlaceholderNode = {
          data: {},
          id: childPlaceholderId,
          // the placeholder is placed at the position of the new node
          // the layout function will animate it to its new position
          position: { x: parentNode.position.x, y: parentNode.position.y },
          type: FLOW_NODES.DROPZONE,
        };
        // a connection from the dropped node to the new placeholder
        const childPlaceholderEdge = {
          data: { hideAddButton: true },
          id: `${insertNodeId}=>${childPlaceholderId}`,
          markerEnd: { strokeWidth: 2, type: MarkerType.Arrow },
          source: insertNodeId,
          target: childPlaceholderId,
          type: FLOW_EDGES.WORKFLOW,
        };
        newEdges.push(childPlaceholderEdge);
        newNodes.push(childPlaceholderNode);
      }

      // rewrite the connecting edges
      setEdges(edges =>
        edges
          .map(edge => {
            // replace edge coming from parent node to this node
            if (edge.target === id) {
              return {
                ...edge,
                data: {
                  ...edge.data,
                  hideAddButton: false,
                },
                id: `${edge.source}->${insertNodeId}`,
                source: edge.source,
                target: insertNodeId,
                type: FLOW_EDGES.WORKFLOW,
              };
            }
            // replace edges going from this node to children
            if (edge.source === id) {
              return {
                ...edge,
                data: {
                  ...edge.data,
                  hideAddButton: !hasChildren,
                },
                id: `${insertNodeId}->${edge.target}`,
                source: insertNodeId,
                target: edge.target,
                type: FLOW_EDGES.WORKFLOW,
              };
            }
            return edge;
          })
          // concat child placeholder edge if this is a leaf node
          .concat(newEdges),
      );

      setNodes(nodes => {
        return (
          nodes
            .map(node => {
              // replace the placeholder node with the new node in the react flow state
              if (node.id === id) {
                return {
                  ...node,
                  data: { ...node.data, ...item.componentMetadata },
                  id: insertNodeId,
                  selected: true,
                  type: insertNodeType,
                };
              }
              return {
                ...node,
                selected: false,
              } as Node;
            })
            // concat child placeholder node if this is a leaf node
            .concat(newNodes)
        );
      });
    },
    [getEdges, getNode, getNodes, id, setEdges, setNodes],
  );

  return handleNodeDrop;
}
