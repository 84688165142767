import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CreateTicketFieldMappingRequest,
  TicketCustomField,
} from 'src/pages/workflow-builder-edit/types';
import { getCustomTicketFields } from 'src/services/custom-field-mapping/customFieldMappingApi';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useInitWorkflowBuilderSettingsQuery } from 'src/services/workflow-builder-metrics';
import {
  getTicketFieldMappings,
  selectTicketFieldMappingsState,
} from 'src/slices/ticket-field-mappings/ticketFieldMappingsSlice';

export const useLoadData = () => {
  const dispatch = useDispatch();

  const { isSuccess: isInitWorkflowBuilderSettingsSuccess } =
    useInitWorkflowBuilderSettingsQuery();

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [customTicketFields, setCustomTicketFields] = useState<
    Array<TicketCustomField>
  >([]);

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');

  useEffect(() => {
    if (!isInitWorkflowBuilderSettingsSuccess) {
      return;
    }

    const initializeWorkflowBuilderSettings = async () => {
      if (isSolveEmailEnabled) {
        await dispatch(getTicketFieldMappings());
        await getCustomTicketFields()
          .then(res => setCustomTicketFields(res.custom_fields))
          .finally(() => setIsLoadingData(false));
      } else {
        setIsLoadingData(false);
      }
    };
    initializeWorkflowBuilderSettings();
  }, [dispatch, isSolveEmailEnabled, isInitWorkflowBuilderSettingsSuccess]);

  return { customTicketFields, isLoadingData };
};

export const useHandleTicketFieldMappings = () => {
  const { isLoading, ticketFieldMappings } = useSelector(
    selectTicketFieldMappingsState,
  );

  const [localTicketFieldMappings, setTicketFieldMappings] = useState<
    Array<CreateTicketFieldMappingRequest>
  >([]);

  useEffect(() => {
    setTicketFieldMappings(ticketFieldMappings);
  }, [ticketFieldMappings]);

  return {
    isLoading,
    localTicketFieldMappings,
    setTicketFieldMappings,
    ticketFieldMappings,
  };
};
