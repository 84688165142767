import { FetchAction, SolveWorkflowState } from './types';
import { SolveWorkflowsActions } from 'src/actions/solveWorkflows/actionTypes';
import { WorkflowConfigDict } from 'src/services/apiInterfaces';
import type { RootState } from 'src/store/rootReducer';
import { generateContextVariablesList } from 'src/utils/generateContextVariablesList';

export const initialState: SolveWorkflowState = {
  addStepParameters: null,
  conditionExpressions: null,
  contextParameters: [],
  createStepType: '',
  currentTransitionIndex: -1,
  editMode: '',
  error: '',
  goToStepParentStepId: '',
  isContextWindowVisible: true,
  isEditMode: false,
  isEditPanelVisible: false,
  isEntryStep: false,
  isNewGoTo: false,
  isNewWorkflow: false,
  selectedWorkflowId: '',
  shouldDeleteGoToStep: false,
  shouldShowDeletePrompt: false,
  shouldShowDiscardPrompt: false,
  shouldShowError: false,
  solveWorkflowsView: 'workflows',
  squeezeStepParameters: null,
  stepId: '',
  transitionStepId: '',
  workflowList: [],
  workflowPopupAnchorId: null,
  workflowPopupLocation: null,
  workflowPopupType: null,
};

export default function solveWorkflowsReducer(
  state = initialState,
  action: FetchAction,
): SolveWorkflowState {
  if (action.type === SolveWorkflowsActions.GET_WORKFLOW_CONFIG_SUCCESS) {
    const workflowConfigData = action.payload as WorkflowConfigDict;

    const workflowList = Object.keys(workflowConfigData.workflows).map(
      (key: string) => {
        return workflowConfigData.workflows[key];
      },
    );

    const contextVariablesList =
      generateContextVariablesList(workflowConfigData);
    // @ts-expect-error legacy code with untyped state
    return { ...state, contextVariablesList, workflowConfigData, workflowList };
  }

  if (
    action.type === SolveWorkflowsActions.GET_WORKFLOWS_OVERVIEW_DATA_SUCCESS
  ) {
    const overviewData = action.payload;
    // @ts-expect-error legacy code with untyped state
    return { ...state, overviewData };
  }

  if (action.type === SolveWorkflowsActions.GET_WORKFLOW_CONFIG_FAILURE) {
    return {
      ...state,
      error: action.error?.toString() || 'Error',
      // @ts-expect-error legacy code with untyped state
      workflowConfigData: null,
    };
  }

  if (action.type === SolveWorkflowsActions.SET_SELECTED_WORKFLOW_ID) {
    const selectedWorkflowId = action.payload;
    // @ts-expect-error legacy code with untyped state
    return { ...state, selectedWorkflowId };
  }

  if (action.type === SolveWorkflowsActions.SET_SOLVE_WORKFLOWS_VIEW) {
    const solveWorkflowsView = action.payload;
    // @ts-expect-error legacy code with untyped state
    return { ...state, solveWorkflowsView };
  }

  if (action.type === SolveWorkflowsActions.SET_IS_NEW_WORKFLOW) {
    const isNewWorkflow = action.payload;
    // @ts-expect-error legacy code with untyped state
    return { ...state, isNewWorkflow };
  }
  if (action.type === SolveWorkflowsActions.SET_NEW_WORKFLOW_ID) {
    const newWorkflowId = action.payload;
    // @ts-expect-error legacy code with untyped state
    return { ...state, newWorkflowId };
  }

  if (action.type === SolveWorkflowsActions.SET_NEW_WORKFLOW_ID_FAILURE) {
    // @ts-expect-error legacy code with untyped state
    return { ...state, newWorkflowId: null };
  }

  if (action.type === SolveWorkflowsActions.SET_WORKFLOW_ERROR) {
    // @ts-expect-error legacy code with untyped state
    return { ...state, error: '', shouldShowError: action.payload };
  }

  if (action.type === SolveWorkflowsActions.SET_NEW_WORKFLOW_ID) {
    // @ts-expect-error legacy code with untyped state
    return { ...state, newWorkflowId: action.payload };
  }

  if (action.type === SolveWorkflowsActions.SET_IS_EDIT_PANEL_VISIBLE) {
    return {
      ...state,
      // @ts-expect-error legacy code with untyped state
      isEditPanelVisible: action.payload,
      shouldShowDeletePrompt: false,
      shouldShowDiscardPrompt: false,
    };
  }

  return state;
}

// SELECTORS

export const selectWorkflowConfig = (
  state: RootState,
): SolveWorkflowState['workflowConfigData'] =>
  state.solveWorkflows.workflowConfigData;

export const selectSelectedWorkflowId = (
  state: RootState,
): SolveWorkflowState['selectedWorkflowId'] =>
  state.solveWorkflows.selectedWorkflowId;

export const selectSolveWorkflowsView = (
  state: RootState,
): SolveWorkflowState['solveWorkflowsView'] =>
  state.solveWorkflows.solveWorkflowsView;

export const selectWorkflowList = (
  state: RootState,
): SolveWorkflowState['workflowList'] => state.solveWorkflows.workflowList;

export const selectShouldShowError = (
  state: RootState,
): SolveWorkflowState['shouldShowError'] =>
  state.solveWorkflows.shouldShowError;

export const selectError = (state: RootState): SolveWorkflowState['error'] =>
  state.solveWorkflows.error;

export const selectWorkflowsOverviewData = (
  state: RootState,
): SolveWorkflowState['overviewData'] => state.solveWorkflows.overviewData;
