import { useLocation } from 'react-router';

import OverviewHeader from '../common/OverviewHeader';
import { DataItem } from '../common/OverviewItem';
import { labelInfoMap, overviewPlaceholder } from '../constants';
import {
  TriageModelLabelOverview,
  TriageModelLabelOverviewKeys,
} from '../types';
import { NA } from 'src/constants/solve';
import { DateRange } from 'src/types/types';

const rowOrder: Array<
  Exclude<TriageModelLabelOverviewKeys, 'avg_coverage' | 'recall'>
> = [
  'total_count',
  'eligible_count',
  'predicted_count',
  'pending_count',
  'finalized_count',
  'correct_count',
  'avg_accuracy',
];

const rowOrderForSimulation: Array<
  Exclude<TriageModelLabelOverviewKeys, 'avg_coverage' | 'recall'>
> = ['total_count', 'predicted_count', 'correct_count', 'avg_accuracy'];

const LabelsOverview = ({
  dateRange,
  overview,
}: {
  dateRange: DateRange;
  overview?: Exclude<TriageModelLabelOverview, 'avg_coverage'>;
}) => {
  const location = useLocation();
  const isSimulation = location.pathname.includes('simulations');
  const updatedRowOrder = isSimulation ? rowOrderForSimulation : rowOrder;
  const overviewData = updatedRowOrder
    .map(key => {
      const value = overview?.[key] || overviewPlaceholder[key];
      const target = labelInfoMap[key];

      if (!key || !target) {
        return null;
      }

      return {
        key: target.key,
        tooltip: target.tooltip,
        value: value ? target.formatValue(value, overview) : NA,
      };
    })
    .filter((item): item is DataItem => item !== null);

  return (
    <OverviewHeader
      data={overviewData}
      dateRange={dateRange}
      gridTemplateColumns={
        isSimulation ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1.5fr 1fr 1fr 1fr 1fr'
      }
      title='All labels overview'
    />
  );
};

export default LabelsOverview;
