import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import GeneratedResponsesLoadingLabel from '../generated-responses-loading-label/GeneratedResponsesLabel';
import GeneratedResponsesLoadingState from '../generated-responses-loading-state/GeneratedResponsesLoadingState';
import { sortGeneratedResponses } from '../intent-email-text-form/utils';
import ResponseGenerationCard from '../response-generation-card/ResponseGenerationCard';
import { useSendUserViewedGeneratedResponsesTrackingEvent } from './hooks';
import { useSendSolveEmailTrackigEvents } from 'src/hooks/hooks';
import { useListenSolveEmailReplyGenerationJobStatus } from 'src/hooks/PusherEventHooks';
import { useSetGlobalErrorToast } from 'src/hooks/useSetGlobalErrorToast';
import {
  useGetGeneratedResponsesQuery,
  useUpdateGeneratedResponseStatusMutation,
} from 'src/services/response-generation/responseGenerationApi';
import { selectIntentEmailConfiguration } from 'src/slices/email-builder/emailBuilderSlice';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface GeneratedResponsesSectionProps {
  onApplyResponse: (generatedResponse: string) => void;
}

const noGeneratedResponsesMessage =
  'We are unable to provide a suggested response based on your ticket history related to this intent';

const GeneratedResponsesSection = ({
  onApplyResponse,
}: GeneratedResponsesSectionProps) => {
  const dispatch = useAppDispatch();

  const emailConfiguration = useSelector(selectIntentEmailConfiguration);

  const { intent_definition_id = '' } = emailConfiguration || {};
  const { data, isError, isLoading, refetch } =
    useGetGeneratedResponsesQuery(intent_definition_id);

  const [updateGeneratedResponseStatus] =
    useUpdateGeneratedResponseStatusMutation();

  const dispatchTrackingEvent = useSendSolveEmailTrackigEvents('solve-email');

  const generatedResponses = sortGeneratedResponses(
    data?.generated_replies || [],
  );
  const status = data?.status;
  const isGeneratedResponsesEmpty = !generatedResponses.length;
  const isInProgressStatus = status === 'in_progress';
  const isFailedStatus = status === 'failed';

  useListenSolveEmailReplyGenerationJobStatus({
    onEvent: refetch,
  });

  useSetGlobalErrorToast(
    isError || isFailedStatus,
    'Failed to fetch generated responses',
  );

  useSendUserViewedGeneratedResponsesTrackingEvent(generatedResponses);

  return (
    <>
      {isLoading || (isInProgressStatus && isGeneratedResponsesEmpty) ? (
        <GeneratedResponsesLoadingState />
      ) : (
        <>
          {isInProgressStatus && !isGeneratedResponsesEmpty && (
            <GeneratedResponsesLoadingLabel
              addLoadingIndicator
              label='Generating more responses for you'
            />
          )}
          <Box padding={'0 4px'}>
            <Typography variant='font16Bold'>
              {isGeneratedResponsesEmpty
                ? noGeneratedResponsesMessage
                : `${generatedResponses.length} suggested responses`}
            </Typography>
          </Box>
          {generatedResponses.map((response, index) => (
            <ResponseGenerationCard
              index={index}
              key={response.reply_id}
              onApplyButtonClick={() => {
                onApplyResponse(response.reply);
                updateGeneratedResponseStatus({
                  generatedResponseId: response.reply_id,
                  intentDefinitionId: intent_definition_id,
                  status: 'accepted',
                })
                  .unwrap()
                  .then(() => {
                    dispatchTrackingEvent('generated-response-accepted', {
                      email_response_id:
                        emailConfiguration?.configuration_id || '',
                      generated_response_id: response.reply_id,
                      intent_definition_id,
                      score: response.score,
                    });
                  })
                  .catch(() => {
                    dispatch(
                      setGlobalToastOptions({
                        autoHideDuration: 4000,
                        title:
                          'An error ocurred, please refresh the page or try again',
                        variant: 'danger',
                      }),
                    );
                  });
              }}
              onShowMeLaterButtonClick={() =>
                updateGeneratedResponseStatus({
                  generatedResponseId: response.reply_id,
                  intentDefinitionId: intent_definition_id,
                  status: 'rejected',
                })
                  .unwrap()
                  .then(() => {
                    dispatchTrackingEvent('generated-response-rejected', {
                      email_response_id:
                        emailConfiguration?.configuration_id || '',
                      generated_response_id: response.reply_id,
                      intent_definition_id,
                      score: response.score,
                    });
                  })
                  .catch(() => {
                    dispatch(
                      setGlobalToastOptions({
                        autoHideDuration: 4000,
                        title:
                          'An error ocurred, please refresh the page or try again',
                        variant: 'danger',
                      }),
                    );
                  })
              }
              response={response}
            />
          ))}
        </>
      )}
    </>
  );
};

export default GeneratedResponsesSection;
