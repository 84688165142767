import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DeepLink } from './DeepLink';
import {
  ErrorQueryEvent,
  MissingContextVariablePayload,
} from 'src/types/ErrorEventTypes';

export const MissingContextVariableFix = ({
  channel,
  payload,
}: {
  channel: ErrorQueryEvent['channel'];
  payload: MissingContextVariablePayload;
}) => {
  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Typography variant='font16'>
        To resolve this issue, ensure that the CV is defined by following one of
        these methods:
      </Typography>
      <Typography variant='font16'>
        1. Add a Form or Action step to collect the CV.
      </Typography>
      <Typography variant='font16'>
        2. Add the CV as a Workflow Context Variable when you set up the widget
        in &quot;Configuration&quot; by including it in the &quot;Embed&quot;
        script.
      </Typography>
      <Typography variant='font16'>
        3. Add a condition step to verify the CV’s existence before using it.
      </Typography>
      <Box mt={1}>
        <DeepLink
          channel={channel}
          stepId={payload.step_id}
          workflowId={payload.workflow_id}
        />
      </Box>
    </Box>
  );
};
