import Skeleton from 'react-loading-skeleton';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  formatToDateAndTime,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../discover-dashboard-page/types';
import { InsightPeriod } from '../solve-insights/types';
import { useGetTopicDetailMetrics } from 'src/hooks/discover/useGetTopicDetailMetrics';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGetTopicHeaderQuery } from 'src/services/insights';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { dateRangeToTimeFilter } from 'src/utils/discover/helpers';

interface TabHeaderProps {
  dateRange: DateRange;
  isParentTopic: boolean;
  periodicalFilter: InsightPeriod;
  tabName: string;
  topicId: string;
}

const TabHeader = ({
  dateRange,
  isParentTopic,
  periodicalFilter,
  tabName,
  topicId,
}: TabHeaderProps) => {
  const dispatch = useAppDispatch();
  // Hooks
  const { palette } = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const timeFilter = dateRangeToTimeFilter(dateRange);

  const { data: topicDetailMetricData, loading: isLoadingMetricData } =
    useGetTopicDetailMetrics({
      dataFilterQuery: [],
      interval: periodicalFilter,
      timeFilter: timeFilter,
      topicId,
    });

  const {
    data: topicHeaderData,
    isFetching: isHeaderFetching,
    isLoading: isHeaderLoading,
  } = useGetTopicHeaderQuery({
    is_parent_topic: isParentTopic,
    scope: 'detail',
    topic_id: topicId,
  });

  const isLoadingHeaderData = isHeaderFetching || isHeaderLoading;

  const topic = topicDetailMetricData?.topic ?? null;

  const lastUpdated =
    (topicHeaderData?.last_updated_at &&
      formatToDateAndTime(topicHeaderData.last_updated_at)) ??
    null;

  return (
    <Box alignItems='center' display='flex' justifyContent='space-between'>
      <Box
        alignItems='center'
        display='flex'
        gap='16px'
        id='tab-header'
        justifyContent='start'
      >
        {isLoadingMetricData ? (
          <Skeleton height={30} width='300px' />
        ) : (
          <>
            <Typography variant='font24'>{tabName}</Typography>
            {topic && !isParentTopic && (
              <Button
                onClick={e => {
                  e.stopPropagation();
                  emitTrackingEventCallback('insight-topic-automate-clicked', {
                    id: topicId,
                    scope: 'detail',
                  });
                  dispatch(
                    setGlobalDiscoverOptions({
                      topicToAutomate: topic,
                    }),
                  );
                }}
                variant='main'
              >
                Automate
              </Button>
            )}
          </>
        )}
      </Box>
      <Box display='flex'>
        {isLoadingHeaderData ? (
          <Skeleton height={20} width='200px' />
        ) : (
          <Typography color={palette.colors.grey[600]} variant='font12'>
            {lastUpdated}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TabHeader;
