import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown/ContextVariableSelectDropdown';
import { Select } from 'src/components/reusable-components/select/Select';
import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';
import {
  convertToNumber,
  getKustomerCustomAttributesData,
} from 'src/pages/workflow-builder-edit/customization-panel/utils';
import {
  Label,
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { selectStyleProps } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import {
  KustomerConversationCreationCustomization,
  KustomerHandoffCustomAttribute,
} from 'src/types/workflowBuilderAPITypes';

type Props = {
  customizationData: KustomerConversationCreationCustomization;
  setCustomizationData: Dispatch<
    SetStateAction<KustomerConversationCreationCustomization>
  >;
};

const emptyAttribute: KustomerHandoffCustomAttribute = {
  field: '',
  value: '',
};

const KustomerCustomAttributes: FC<React.PropsWithChildren<Props>> = ({
  customizationData,
  setCustomizationData,
}) => {
  const customAttributes =
    customizationData.conversation_creation_settings.kustomer_custom_attributes;
  const currentlyUsedAttributes = customizationData
    .conversation_creation_settings.custom_attributes.length
    ? customizationData.conversation_creation_settings.custom_attributes
    : [{ ...emptyAttribute }];

  const kustomerCustomAttributesData = getKustomerCustomAttributesData(
    currentlyUsedAttributes,
    customAttributes,
  );

  const currentlyUsedFields = (): Set<string> => {
    const usedAttributes = currentlyUsedAttributes.map(
      attribute => attribute.field,
    );

    return new Set(usedAttributes);
  };
  const currentlyAvailableAttributes = customAttributes
    .filter(attribute => {
      return !currentlyUsedFields().has(attribute.id);
    })
    .map(attribute => {
      return {
        label: attribute.display_name,
        value: attribute.id,
      };
    });

  const deleteAttribute = (idx: number) => {
    setCustomizationData(handoffData => {
      const newAttributes = [
        ...handoffData.conversation_creation_settings.custom_attributes,
      ];
      newAttributes.splice(idx, 1);

      return {
        ...handoffData,
        conversation_creation_settings: {
          ...handoffData.conversation_creation_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const addAttribute = () => {
    setCustomizationData(handoffData => {
      const newAttributes = [
        ...handoffData.conversation_creation_settings.custom_attributes,
      ];
      newAttributes.push({ ...emptyAttribute });

      return {
        ...handoffData,
        conversation_creation_settings: {
          ...handoffData.conversation_creation_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const getAttributeOptions = (field: string): string[] => {
    for (const attribute of customAttributes) {
      if (attribute.id === field) {
        return attribute.options;
      }
    }

    return [];
  };

  const onAttributeChange = (
    event: { label: string; value: string },
    idx: number,
  ) => {
    const newAttributes = [...currentlyUsedAttributes];
    newAttributes.splice(idx, 1, { field: event.value, value: '' });

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        conversation_creation_settings: {
          ...handoffData.conversation_creation_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const getDisplayName = (field: string): string => {
    let displayName = field;

    customAttributes.forEach(attribute => {
      if (attribute.id === field) {
        displayName = attribute.display_name;
      }
    });

    return displayName;
  };

  const onAttributeOptionValueChange = (newValue: string, idx: number) => {
    const newAttributes = [...currentlyUsedAttributes];
    const attributeField = currentlyUsedAttributes[idx].field;
    newAttributes.splice(idx, 1, { field: attributeField, value: newValue });

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        conversation_creation_settings: {
          ...handoffData.conversation_creation_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const getSelectedAttributeOption = (field: string): string => {
    for (const attribute of currentlyUsedAttributes) {
      if (attribute.field === field) {
        return attribute.value.toString();
      }
    }

    return '';
  };

  const onAttributeValueChange = (value: string | number, idx: number) => {
    const newAttributes = [...currentlyUsedAttributes];
    const attributeField = currentlyUsedAttributes[idx].field;
    newAttributes.splice(idx, 1, {
      field: attributeField,
      value,
    });

    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        conversation_creation_settings: {
          ...handoffData.conversation_creation_settings,
          custom_attributes: newAttributes,
        },
      };
    });
  };

  const getInputComponent = (
    customHandoffAttribute: KustomerHandoffCustomAttribute,
    idx: number,
  ): JSX.Element => {
    const options = getAttributeOptions(customHandoffAttribute.field);
    const selectedOption = getSelectedAttributeOption(
      customHandoffAttribute.field,
    );

    if (options.length) {
      return (
        <InputWrapper>
          <ContextVariableSelectDropdown
            additionalOptions={options.map(option => {
              return {
                category: 'Custom Attribute Options',
                label: option,
                value: option,
              };
            })}
            aria-label='Select a Value...'
            id={`select-kustomer-attribute-${customHandoffAttribute.value}`}
            onChange={value => onAttributeOptionValueChange(value, idx)}
            shouldIncludeSystemContextVariables
            shouldProvideCVIdFormatting
            value={selectedOption}
          />
        </InputWrapper>
      );
    } else {
      return (
        <InputWrapper>
          <ContextMention
            errorMessage={
              kustomerCustomAttributesData.find(
                error => error.id === customHandoffAttribute.field,
              )?.error
            }
            onChange={value => {
              const type = kustomerCustomAttributesData.find(
                customAttribute =>
                  customAttribute.id === customHandoffAttribute.field,
              )?.type;

              const newValue =
                value.trim() && type === 'number'
                  ? convertToNumber(value)
                  : value;

              onAttributeValueChange(newValue, idx);
            }}
            placeholder='Enter value'
            value={customHandoffAttribute.value.toString()}
          />
        </InputWrapper>
      );
    }
  };

  return (
    <>
      <Label>Conversation Custom Attribute</Label>
      <AttributesContainer>
        {currentlyUsedAttributes.map((customAttribute, idx) => {
          return (
            <div key={`custom-field-${customAttribute.field}-${idx}`}>
              <Row>
                <InputWrapper>
                  <Select
                    aria-label='Select an Attribute...'
                    maxMenuHeight={180}
                    onChange={event => {
                      if (event) {
                        onAttributeChange(event, idx);
                      }
                    }}
                    options={currentlyAvailableAttributes}
                    value={{
                      label: getDisplayName(customAttribute.field),
                      value: customAttribute.value.toString(),
                    }}
                    {...selectStyleProps}
                  />
                </InputWrapper>
                <Spacer width='8px' />
                {getInputComponent(customAttribute, idx)}
                <ButtonWrapper>
                  <DeleteButton
                    onClick={() => {
                      deleteAttribute(idx);
                    }}
                    role='button'
                    src={xIcon}
                  />
                </ButtonWrapper>
              </Row>
              {idx !== currentlyUsedAttributes.length - 1 && (
                <Spacer height='10px' />
              )}
            </div>
          );
        })}
      </AttributesContainer>
      <Add
        isDisabled={currentlyUsedAttributes.length === customAttributes.length}
        onClick={addAttribute}
      >
        + Add field
      </Add>
    </>
  );
};

export default KustomerCustomAttributes;

const InputWrapper = styled('div')`
  flex: 1 1 50%;
`;

const AttributesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  width: 30px;
`;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const Add = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 16px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  box-sizing: border-box;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;
