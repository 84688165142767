// Style
import '../../dashboard-pages/common/tableRow.scss';

import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import InitialsIcon from '../../dashboard-pages/answers-analytics-page/table/initials-icon/InitialsIcon'; // ToDo: fix this path
import Button from '../Button';
import PromptComponent from '../prompt-component';
import ToggleButton from '../toggle-button/ToggleButton';
import Tooltip from '../tooltip/tooltip';
import { deleteWorkflow } from 'src/actions/solveWorkflows';
import DisabledDeleteIcon from 'src/assets/images/disabled-delete.svg';
import ActiveDeleteIcon from 'src/assets/images/icon-delete-enabled.svg';
import TooltipPopper from 'src/components/tooltip-popper';
import { selectSelectedMacro } from 'src/reducers/macroControlsReducer';
import {
  selectWorkflowConfig,
  selectWorkflowList,
} from 'src/reducers/solveWorkflowsReducer';
import { TableRowProps } from 'src/types/TableRowTypes';
import { isFinalValueStyle } from 'src/utils/analyticsUtils';
import { cleanStr, formatEntities } from 'src/utils/cleanStr';
import { DeleteWorkflowPrompt } from 'src/utils/enums';
import getKeyByValue from 'src/utils/getKeyByValue/getKeyByValue';

const TableRow: React.FC<React.PropsWithChildren<TableRowProps>> = ({
  biggerTrailingRows = false,
  breakdown,
  cells,
  entryStepId,
  fieldNameCallback,
  fieldValueRowButtonCallback,
  fieldValueRowButtonStyle,
  fieldValueRowButtonText,
  getDeleteTooltipDescription,
  getDeleteTooltipText,
  getDeleteTooltipTitle = '',
  isFinalValue = true,
  isLabelCapitalized = true,
  isMacroTable,
  onRowClick,
  showDeleteButton,
  showFieldValueRowButton = false,
  showFieldValueTooltip,
  showInitialsIcon = false,
  showWorkflowIcon = false,
  tooltipText,
  tooltipTitle,
}: TableRowProps) => {
  const dispatch = useDispatch();
  const [shouldShowDeletePrompt, setShouldShowDeletePrompt] =
    useState<boolean>(false);

  const selectedMacro = useSelector(selectSelectedMacro);
  const workflowConfig = useSelector(selectWorkflowConfig);
  const workflowsList = useSelector(selectWorkflowList);
  const fieldValue = cells[0];
  const trailingCells = cells.slice(1, cells.length);
  const [isDeleteBttnHovered, setIsDeleteBttnHovered] =
    useState<boolean>(false);

  const CellClass = classNames({
    'bigger-trailing-rows': biggerTrailingRows,
    'data-row': breakdown && breakdown.length,
    'data-row TableRow-trails--big': showWorkflowIcon,
    'data-row-disabled': !(breakdown && breakdown.length),
    pointer: (breakdown && isMacroTable) || onRowClick,
    'totals data-row': trailingCells.find(
      cellData => cellData === selectedMacro?.macro_id,
    ),
  });

  const fieldNameContainerClass = classNames('TableRow-cellContainer', {
    pointer: fieldNameCallback,
    'TableRow-cellContainer--initialsIcon': showInitialsIcon,
  });

  const isMacroTableClass = classNames({
    MacroTableRow: isMacroTable,
  });

  const fieldValueClass = classNames('field-p', {
    'field-p--not-capitalized': !isLabelCapitalized,
    'TableRow-overflow--visible': showFieldValueTooltip,
  });

  const fieldMacroClass = classNames('field-p', {
    'field-p--not-capitalized': !isLabelCapitalized,
  });

  const fieldMacroCellValue = (
    <p
      className={fieldMacroClass}
      dangerouslySetInnerHTML={{
        __html: isMacroTable
          ? String(fieldValue)
          : typeof fieldValue === 'object'
          ? ''
          : cleanStr(fieldValue),
      }}
    />
  );

  const cellTooltip = (
    <Tooltip tooltipText={tooltipText} tooltipTitle={tooltipTitle} />
  );

  const rowButton = (
    <Button
      buttonStyle={fieldValueRowButtonStyle as string} // Because this is a conditional prop it has to be casted
      buttonText={fieldValueRowButtonText as string}
      callbackFunction={() =>
        fieldValueRowButtonCallback && fieldValueRowButtonCallback()
      } // Because this is a conditional prop it has to be casted
    />
  );

  const getWorkflowType = (name: string) => {
    const workflowId =
      (workflowConfig &&
        Object.keys(workflowConfig.workflows).find(
          key =>
            workflowConfig.workflows[key].workflow_name.toLowerCase() ===
            name.toLowerCase(),
        )) ||
      '';
    return workflowConfig?.workflows[workflowId]?.workflow_type || '';
  };

  const fieldCellValue = (
    <div className={fieldValueClass}>
      {typeof fieldValue === 'object'
        ? ''
        : formatEntities(cleanStr(fieldValue))}
      {/* Show row button */}
      {showFieldValueRowButton && rowButton}
      {/* Show tooltip for cell */}
      {showFieldValueTooltip && cellTooltip}
    </div>
  );

  /** Workflows field name table row */
  const workflowsCellValue = (
    <div className='TableRow-workflow'>
      <div className='TableRow-worflowIcon' />
      {typeof fieldValue === 'string' && (
        <span className='TableRow-workflowType'>
          {getWorkflowType(fieldValue)}
        </span>
      )}
      <p className='TableRow-fieldCell--strong'>
        {typeof fieldValue !== 'object' && cleanStr(fieldValue)}
      </p>
    </div>
  );
  /** Get Field mame row value to show */
  const getFieldValue = () => {
    if (isMacroTable) {
      return fieldMacroCellValue;
    }
    if (showWorkflowIcon) {
      return workflowsCellValue;
    } else {
      return fieldCellValue;
    }
  };

  /** Styles for delete button */
  const deleteBttnStyles = classNames({
    'TableRow-deleteImg--disabled': showDeleteButton && !showDeleteButton(),
    'TableRow-deleteImg--enabled': showDeleteButton && showDeleteButton(),
  });

  /** Get icon to show on delete button */
  const getDeleteIcon = (): string => {
    let src = ActiveDeleteIcon;
    if (showDeleteButton && !showDeleteButton()) {
      src = DisabledDeleteIcon;
    }
    return src;
  };

  const shouldShowDeleteWorkflow = useCallback(
    (entryStepId: string | undefined) => {
      if (entryStepId && workflowConfig && workflowsList.length) {
        // If it's the entry workflow the user can't delete the workflow.
        if (entryStepId === workflowConfig.entry_step_id) {
          return false;
        }
      } else {
        // If there is no entry step worklow can be deleted.
        return true;
      }
    },
    [workflowConfig],
  );

  return (
    <tr
      className={CellClass}
      id={isMacroTableClass}
      onClick={() => onRowClick && onRowClick()}
    >
      <td className='field-cell cell'>
        <div
          className={fieldNameContainerClass}
          onClick={() => fieldNameCallback && fieldNameCallback()}
        >
          {showInitialsIcon && (
            <InitialsIcon agentName={fieldValue as string} />
          )}

          {getFieldValue()}
        </div>
      </td>
      {trailingCells.map(
        (cellData: string | number | JSX.Element, index: number) => {
          if (typeof cellData === 'boolean') {
            //If we're getting a boolean it means is the value to show in the component for toggle button
            return (
              <td
                className={isFinalValueStyle(isFinalValue, 'cell')}
                key={index}
              >
                <ToggleButton
                  isChecked={cellData}
                  isNotToggleable
                  styleClass={'TableRow-cellContainer'}
                />
              </td>
            );
          }
          return (
            <td className={isFinalValueStyle(isFinalValue, 'cell')} key={index}>
              <div className='TableRow-cellContainer'>
                {typeof cellData === 'object' ? (
                  cellData
                ) : (
                  <p className={isFinalValueStyle(isFinalValue, 'cell-p')}>
                    {cellData || 0}
                  </p>
                )}
              </div>
            </td>
          );
        },
      )}
      {showDeleteButton && (
        <td className='cell'>
          <button
            className='TableRow-deleteBttn'
            data-testid='deleteBttn'
            onClick={() => {
              if (shouldShowDeleteWorkflow(entryStepId)) {
                setShouldShowDeletePrompt(true);
              }
            }}
            onMouseEnter={() => setIsDeleteBttnHovered(true)}
            onMouseLeave={() => setIsDeleteBttnHovered(false)}
          >
            {isDeleteBttnHovered && !showDeleteButton() && (
              <TooltipPopper
                tooltipDescription={
                  getDeleteTooltipDescription
                    ? getDeleteTooltipDescription()
                    : ''
                }
                tooltipText={getDeleteTooltipText && getDeleteTooltipText()}
                tooltipTitle={getDeleteTooltipTitle}
              />
            )}
            {isDeleteBttnHovered && showDeleteButton() && (
              <div className='TableRowDeleteBttn--hovered' />
            )}
            <img className={deleteBttnStyles} src={getDeleteIcon()} />
          </button>

          {shouldShowDeletePrompt && (
            <div className='promptComponent-position'>
              <PromptComponent
                description={DeleteWorkflowPrompt.DESCRIPTION}
                iconClass={'PromptComponent-failedIcon'}
                label={DeleteWorkflowPrompt.LABEL}
                leftBttnCallback={() => {
                  setShouldShowDeletePrompt(false);
                }}
                leftBttnText={DeleteWorkflowPrompt.LEFT_BUTTON_TEXT}
                prompt={DeleteWorkflowPrompt.PROMPT}
                rightBttnCallback={() => {
                  if (workflowConfig && (entryStepId || entryStepId === null)) {
                    dispatch(
                      deleteWorkflow(
                        getKeyByValue(
                          workflowConfig?.workflows,
                          'entry_step_id',
                          entryStepId,
                        ),
                      ),
                    );
                  }
                  setShouldShowDeletePrompt(false);
                }}
                rightBttnText={DeleteWorkflowPrompt.RIGHT_BUTTON_TEXT}
                shouldShowBttns
                shouldShowDescription
              />
            </div>
          )}
        </td>
      )}
    </tr>
  );
};

export default TableRow;
