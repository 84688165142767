import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import { SelectChangeEvent, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Button,
  SelectDropdown,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TriageConfigDetailLinkText from '../../triage-config-detail-page/triage-config-detail-settings/TriageConfigDetailLinkText';
import { hasComponent } from '../helpers';

interface EditableSelectFieldProps {
  allowNew?: boolean;
  buttonText?: string;
  disabled?: boolean;
  isClearable?: boolean;
  label?: string;
  onChange: (params: { inputValue: string; selectValue: string }) => void;
  onToggleCallback: (value: boolean) => void;
  options: { label: string; value: string }[];
  title?: string;
  tooltip?: string;
  value: string;
}
const textInputProps = {
  ariaLabel: 'input-object',
  description: '',
  linkText: 'Select an existing field instead',
  placeholder: 'Enter new field name',
};

const selectProps = {
  id: 'field-dropdown',
  placeholder: 'Select an existing field',
};
const EditableSelectField = ({
  allowNew = true,
  buttonText = 'Create new field instead',
  disabled,
  isClearable = false,
  label = 'Output Field',
  onChange,
  onToggleCallback,
  options,
  title,
  tooltip,
  value,
}: EditableSelectFieldProps) => {
  const { palette } = useTheme();
  const [isInput, setIsInput] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleToggle = () => {
    onToggleCallback(!isInput);
    setIsInput(value => !value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = () => {
    onChange({
      inputValue,
      selectValue: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    onChange({
      inputValue,
      selectValue: event.target.value,
    });
  };

  const handleClear = () => {
    onChange({
      inputValue,
      selectValue: '',
    });
  };

  return (
    <Box display='flex' flexDirection='column' rowGap={1}>
      {hasComponent<string>(
        title,
        <Typography variant='font16Bold'>{title}</Typography>,
      )}

      <Box columnGap={1} display='flex'>
        <Typography color={palette.colors.grey[800]} variant='font14Bold'>
          {label}
        </Typography>
        {Boolean(tooltip) && (
          <Tooltip tooltipContent={tooltip || ''}>
            <IconInfoCircle color={palette.colors.grey[700]} size={20} />
          </Tooltip>
        )}
      </Box>

      {isInput && (
        <Box>
          <TextField
            aria-label={textInputProps.ariaLabel}
            description={textInputProps.description}
            disabled={disabled}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            placeholder={textInputProps.placeholder}
            value={inputValue}
          />
          <TriageConfigDetailLinkText onClick={handleToggle}>
            {textInputProps.linkText}
          </TriageConfigDetailLinkText>
        </Box>
      )}

      {!isInput && (
        <Box display='flex' flexDirection='column' rowGap={2}>
          <SelectDropdown
            chipsToShow={4}
            disabled={disabled}
            id={selectProps.id}
            isMenuSearchable
            onChange={handleSelectChange}
            onClear={isClearable ? handleClear : undefined}
            options={options}
            placeholder={selectProps.placeholder}
            value={value}
          />
          {allowNew && (
            <Button
              disabled={disabled}
              onClick={handleToggle}
              startIcon={<Add />}
              variant='secondary'
            >
              {buttonText}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default EditableSelectField;
