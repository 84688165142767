import { useEffect, useMemo } from 'react';

import { useEmitTrackingEventCallback, useStateParams } from '../hooks';
import { processValuesToFilters } from 'src/components/analytic-filter/helper';
import { FilterOption } from 'src/components/analytic-filter/types';
import { DISCOVER_SHARED_PARAM_NAMES } from 'src/constants/discover';
import { Filters } from 'src/services/apiInterfaces';
import {
  useGetAllFilterFieldsQuery,
  useGetAllFiltersQuery,
} from 'src/services/discover/discoverApi';
import {
  createFilterRequest,
  createOptions,
} from 'src/utils/discover/filter-helpers';
import {
  dataFilterQueryToStringRepresentation,
  queryFilterDeserialize,
  queryFilterParameterValidator,
  queryFilterSerialize,
} from 'src/utils/discover/helpers';

export const useDataFilter = (): {
  dataFilterQuery: Filters[];
  isLoading: boolean;
  queryFilterOptions: FilterOption[] | null;
  queryFilters: string[];
  setQueryFilters: (filters: string[]) => URLSearchParams;
} => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [queryFilters, setQueryFilters] = useStateParams({
    deserialize: queryFilterDeserialize,
    initialState: [],
    paramsName: DISCOVER_SHARED_PARAM_NAMES.QUERY_FILTER,
    serialize: queryFilterSerialize,
    validator: queryFilterParameterValidator(),
  });
  const {
    data: filterFields,
    isFetching: isFetchingFilterFields,
    isLoading: isLoadingFilterFields,
  } = useGetAllFilterFieldsQuery();
  const filterFieldRequest = createFilterRequest(filterFields);
  const {
    data,
    isFetching,
    isLoading: isLoadingFilters,
  } = useGetAllFiltersQuery(
    { filters: filterFieldRequest },
    { skip: !filterFields },
  );

  const isLoading =
    isLoadingFilters ||
    isFetching ||
    isLoadingFilterFields ||
    isFetchingFilterFields;
  const queryFilterOptions = useMemo(
    () => createOptions(filterFieldRequest, data),
    [data, filterFieldRequest],
  );

  const dataFilterQuery = useMemo(
    () => processValuesToFilters(queryFilters, queryFilterOptions ?? []),
    [queryFilters, queryFilterOptions],
  );

  useEffect(() => {
    emitTrackingEventCallback('discover-data-filter-selected', {
      filters: dataFilterQueryToStringRepresentation(dataFilterQuery),
    });
  }, [dataFilterQuery, emitTrackingEventCallback]);

  return {
    dataFilterQuery: dataFilterQuery ?? [],
    isLoading,
    queryFilterOptions,
    queryFilters,
    setQueryFilters,
  };
};
