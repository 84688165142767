import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';

interface ChipListProps {
  chips: string[];
}

export default function ChipList({ chips }: ChipListProps) {
  return (
    <UL>
      {chips.map(chip => (
        <LI key={chip}>
          <Chip>{chip}</Chip>
        </LI>
      ))}
    </UL>
  );
}

const UL = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const LI = styled('li')`
  background-color: ${theme.palette.colors.slate[100]};
  border-radius: 4px;
  color: ${theme.palette.colors.grey[700]};
  font-size: 12px;
  font-weight: 600;
  padding: 4px;
`;

const Chip = styled('span')`
  text-transform: capitalize;
  white-space: nowrap;
`;
