import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';

import { Checkbox } from '@forethought-technologies/forethought-elements';
import { Spacer } from '../../styles';
import KustomerChatCustomAttributes from './KustomerChatCustomAttributes';
import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';
import { KustomerHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

type Props = {
  customizationData: KustomerHandoffCustomization;
  setCustomizationData: Dispatch<SetStateAction<KustomerHandoffCustomization>>;
};

const KustomerChatHandoffConfiguration: FC<React.PropsWithChildren<Props>> = ({
  customizationData,
  setCustomizationData,
}) => {
  const prompt =
    customizationData.agent_chat_handoff_settings.handoff_form_prompt;

  return (
    <Container>
      <ContextMention
        label='Agent Chat Handoff Form Prompt'
        onChange={value =>
          setCustomizationData({
            ...customizationData,
            agent_chat_handoff_settings: {
              ...customizationData.agent_chat_handoff_settings,
              handoff_form_prompt: value,
            },
          })
        }
        placeholder='Enter prompt'
        value={prompt}
      />
      <Spacer height='28px' />
      <KustomerChatCustomAttributes
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
      <Spacer height='28px' />
      <Checkbox
        checked={
          !!customizationData?.agent_chat_handoff_settings
            .write_transcript_to_note
        }
        label='Write transcript to conversation note'
        onChange={() =>
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              agent_chat_handoff_settings: {
                ...handoffData.agent_chat_handoff_settings,
                write_transcript_to_note:
                  !handoffData.agent_chat_handoff_settings
                    .write_transcript_to_note,
              },
            };
          })
        }
      />
      <Checkbox
        checked={
          !!customizationData?.agent_chat_handoff_settings.upload_files_attached
        }
        label='Prompt user for file attachments'
        onChange={() =>
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              agent_chat_handoff_settings: {
                ...handoffData.agent_chat_handoff_settings,
                upload_files_attached:
                  !handoffData.agent_chat_handoff_settings
                    .upload_files_attached,
              },
            };
          })
        }
      />
    </Container>
  );
};

export default KustomerChatHandoffConfiguration;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 15px;
`;
