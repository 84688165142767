import { ComponentProps } from 'react';
import { styled } from '@mui/material/styles';

import {
  elevations,
  theme,
} from '@forethought-technologies/forethought-elements';

export const FooterLink = (props: ComponentProps<typeof StyledFooterLink>) => (
  <StyledFooterLink
    {...props}
    className={`footer-link ${props.className}`}
  ></StyledFooterLink>
);

const StyledFooterLink = styled('p')`
  font-size: 12px;
  margin: 0;
  color: ${theme.palette.colors.grey[800]};
  line-height: 18px;
`;

export const Footer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
`;

export const Container = styled('div')<{
  $loading?: boolean;
  disabled?: boolean;
}>`
  border: 1px solid ${theme.palette.colors.slate[200]};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 210px;
  width: 325px;

  /* If not disabled or loading, show box-shadow. Cannot control with pointer-event property because link in footer still needs to be 'clickable' in disabled state. */
  &:hover {
    box-shadow: ${props =>
      !(props.disabled || props.$loading) ? elevations.z2 : 'none'};
  }
  /* If not disabled or loading state, change footer text color on hover of card */
  &:hover .footer-link {
    color: ${props =>
      !(props.disabled || props.$loading)
        ? theme.palette.colors.purple[500]
        : 'none'};
  }

  > *:not(.footer-link) {
    opacity: ${props => (props.disabled ? 0.4 : 1)};
  }
`;

export const TitleContainer = styled('div')`
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
`;

export const Icon = styled('img')`
  aspect-ratio: auto;
  margin-bottom: 16px;
  width: 42px;
`;

export const Title = styled('h4')`
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 4px;
  line-height: 20px;
`;

export const Subtitle = styled('p')`
  font-size: 12px;
  margin: 0;
  color: ${theme.palette.colors.grey[500]};
  line-height: 18px;
`;

export const StatusLabel = styled('p')`
  font-size: 12px;
  margin: 0 auto 0 8px;
  color: ${theme.palette.colors.grey[400]};
  line-height: 18px;
`;
