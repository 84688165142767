import React, { Dispatch, FC, SetStateAction } from 'react';

import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { ZendeskCommonCustomization } from 'src/types/workflowBuilderAPITypes';

interface TicketBrandProps {
  customizationData: ZendeskCommonCustomization;
  setCustomizationData: Dispatch<SetStateAction<ZendeskCommonCustomization>>;
}
const TicketBrand: FC<React.PropsWithChildren<TicketBrandProps>> = ({
  customizationData,
  setCustomizationData,
}) => {
  const brandOptions =
    customizationData.ticket_creation_settings?.ticket_creation_config
      ?.available_ticket_brands || [];

  const ticketBrandName =
    customizationData.ticket_creation_settings?.ticket_creation_config
      .ticket_brand || '';

  return (
    <>
      <ContextVariableSelectDropdown
        additionalOptions={brandOptions.map(tb => ({
          category: 'Brands',
          label: tb,
          value: tb,
        }))}
        id={'zendesk-select-ticket-brand'}
        isClearable
        label='Ticket Brand'
        onChange={value =>
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              ticket_creation_settings: {
                ...handoffData.ticket_creation_settings,
                ticket_creation_config: {
                  ...customizationData.ticket_creation_settings
                    ?.ticket_creation_config,
                  ticket_brand: value,
                },
              },
            };
          })
        }
        shouldIncludeSystemContextVariables
        shouldProvideCVIdFormatting
        value={ticketBrandName}
      />
    </>
  );
};

export default TicketBrand;
