import '../common/tablePage.scss';

import React from 'react';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@mui/material';
import { Box } from '@mui/material';
import { Dispatch } from '@reduxjs/toolkit';

import {
  Button,
  DateRangeFilterButton,
  FilterButton,
  theme,
} from '@forethought-technologies/forethought-elements';
import DropDownMenu from '../../reusable-components/dropdown-menu/dropdownMenu';
import LoadingSpinner from '../../reusable-components/loading-spinner/loadingSpinner';
import TableHeader from '../../reusable-components/table-header/tableHeader';
import TableRow from '../../reusable-components/table-row/tableRow';
import Chart from '../common/chart';
import { HandleAccuracy } from './HandleAccuracy';
import OverallMetrics from './OverallMetrics';
import {
  fetchModelsId,
  getChartPredictionsMetric,
  getPredictionsAnalytics,
} from 'src/actions/analytics';
import { searchLoading } from 'src/actions/search';
import { sendTrackingEvent } from 'src/actions/search';
import NoDataView from 'src/components/reusable-components/no-data-view/NoDataView';
import { ModelsInputObjectBreakdowns } from 'src/reducers/analyticsReducer/types';
import {
  chartDataListOptions,
  chartTimeframeListOptions,
  dataViewListOptions,
} from 'src/utils/analyticsUtils';
import { ModuleName, noDataViewProps } from 'src/utils/enums';
import { formatNToPercentage } from 'src/utils/formatToPercentage';
import {
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';
import { withRouter, WithRouterProps } from 'src/utils/withRouter';

export interface TableAggregateData extends TableData {
  predicted_fraction: number;
}

export interface TableBreakdownData extends TableData {
  field_value: string;
}

interface TableData {
  actual_count: number;
  correct_count: number;
  not_computed_count: number;
  precision: number;
  predicted_count: number;
  recall: number;
}

interface Model {
  displayName?: string;
  name: string;
}

type MyProps = {
  breakdown: any;
  chartData: Array<{
    [key: string]: number;
    timestamp: number;
  }>;
  end_time: string;
  fetchingData: Function;
  fetchModelsId: Function;
  finalPage: boolean;
  getChartPredictionsMetric: Function;
  getPredictionsMetrics: Function;
  history: any;
  loading: boolean;
  location: any;
  mean: object;
  metrics: any;
  modelName: string;
  modelsId: ModelsInputObjectBreakdowns | null;
  nextPageAnalyticsMetrics: Function;
  sendTrackingEvent: Function;
  sortedProperty: string;
  start_time: string;
};

type MyState = {
  chartDataViewDropdownVisible: boolean;
  chartTimeFrameFilterSelected: string;
  chartYAxisSelected: string;
  dateRangeValue: { from: Date; to: Date };
  models: any;
  pageNumber: number;
  selectedModel: Model;
  shareDropdownAnchorEl: null | HTMLElement;
  tableView: boolean;
};

class PredictionsPage extends React.Component<
  MyProps & WithRouterProps,
  MyState
> {
  _isMounted = false;
  chartDataView: any;

  constructor(props: MyProps & WithRouterProps) {
    super(props);

    this.state = {
      chartDataViewDropdownVisible: false,
      chartTimeFrameFilterSelected: 'daily',
      chartYAxisSelected: 'total_predictions',
      dateRangeValue: last30DaysTimeRange,
      models: [],
      pageNumber: 0,
      selectedModel: { name: '' },
      shareDropdownAnchorEl: null,
      tableView: true,
    };
  }

  //adds event listener
  componentWillMount(): void {
    document.addEventListener(
      'mousedown',
      this.handleBodyClick.bind(this),
      false,
    );
  }

  //removes event listener
  componentWillUnmount(): void {
    document.removeEventListener(
      'mousedown',
      this.handleBodyClick.bind(this),
      false,
    );
    this._isMounted = false;
  }

  //if body clicked outside of node close dropdown
  handleBodyClick() {
    if (
      this.chartDataView &&
      this.chartDataView.contains((event as Event).target)
    ) {
      return false;
    }
    if (this._isMounted) {
      this.setState({
        chartDataViewDropdownVisible: false,
        shareDropdownAnchorEl: null,
      });
    }
  }

  flattenModelAndInputObjects = (): {
    displayName?: string;
    name: string;
  }[] => {
    return Object.values(this.props.modelsId ?? {}).flatMap(value =>
      value.map(
        ({
          display_name: displayName,
          name,
        }: {
          display_name?: string;
          name: string;
        }) => ({
          displayName,
          name,
        }),
      ),
    );
  };

  get startTimestamp(): number {
    return this.state.dateRangeValue.from.valueOf();
  }

  get endTimestamp(): number {
    return this.state.dateRangeValue.to.valueOf();
  }

  //Share report dropdown on click action
  shareDropdown = () => {
    this.setState({ shareDropdownAnchorEl: null });
    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: this.endTimestamp,
      event_type: 'predictions-analytics-share-report',
      start_timestamp: this.startTimestamp,
      type_of_share: 'Download CSV',
    });
  };

  fetchData = async ({
    chartTimeFrame = this.state.chartTimeFrameFilterSelected,
    endTimestamp = this.endTimestamp,
    isTableView = this.state.tableView,
    model = this.state.selectedModel.name,
    startTimestamp = this.startTimestamp,
  }: Partial<{
    chartTimeFrame: string;
    endTimestamp: number;
    isTableView: boolean;
    model: string;
    startTimestamp: number;
  }> = {}) => {
    const { fetchingData, getChartPredictionsMetric, getPredictionsMetrics } =
      this.props;

    startTimestamp = Math.floor(startTimestamp / 1000);
    endTimestamp = Math.floor(endTimestamp / 1000);

    if (isTableView) {
      fetchingData(true);
      await getPredictionsMetrics(model, startTimestamp, endTimestamp, 0, 50);
      fetchingData(false);
    } else if (!isTableView) {
      fetchingData(true);
      await getChartPredictionsMetric(
        model,
        startTimestamp,
        endTimestamp,
        chartTimeFrame,
      );
      fetchingData(false);
    }
  };

  //handles pagination
  async handlePageClick(data: any) {
    if (this.props.breakdown[data.selected] === undefined) {
      this.props.fetchingData(true);
      await this.props.nextPageAnalyticsMetrics(
        this.props.modelName,
        Math.floor(data.selected / 2),
        50,
      );
      this.props.fetchingData(false);
    }
    this.setState({
      pageNumber: data.selected,
    });
    this.constructQueryParams({ page: data.selected });
  }

  //makes initial fetch call for analytics for the past 30 days and the model with highest count
  async componentDidMount() {
    this._isMounted = true;

    const params = new URLSearchParams(this.props.location.search);

    this.props.fetchingData(true);
    await this.props.fetchModelsId();

    //if there are no values in query string make api call with default values
    if (params.toString().length === 0) {
      const sortedModelAndInputObjectNames = this.flattenModelAndInputObjects();

      if (sortedModelAndInputObjectNames.length > 0) {
        const model = sortedModelAndInputObjectNames[0];
        const selectedModel = { model: model.name };
        //constructs the url with the query strings with default values when it loads
        this.constructQueryParams(selectedModel);
        await this.fetchData(selectedModel);
        this.setState({ selectedModel: model });
      }
    } else {
      await this.hydrateStateFromQueryParams();
    }

    this.props.fetchingData(false);
  }

  //fetch call when timeframe is selected
  onChangeDateRange = (value: any) => {
    this.setState({
      dateRangeValue: value,
      pageNumber: 0,
    });

    this.constructQueryParams({
      end: value.to.valueOf(),
      page: 0,
      start: value.from.valueOf(),
    });
    this.fetchData({ endTimestamp: value.to, startTimestamp: value.from });

    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: this.endTimestamp,
      event_type: this.state.tableView
        ? 'prediction-table-timeframe-changed'
        : 'prediction-chart-timeframe-changed',
      model_name: this.state.selectedModel.name,
      start_timestamp: this.startTimestamp,
    });
  };

  onChangeDataViewDropdown = (value: string) => {
    const isTableView = value === 'table';

    this.setState({
      pageNumber: 0,
      tableView: isTableView,
    });

    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      data_view: value,
      end_timestamp: this.endTimestamp,
      event_type: 'predictions-data-view-changed',
      model_name: this.state.selectedModel.name,
      start_timestamp: this.startTimestamp,
    });
    this.fetchData({ isTableView });
    this.constructQueryParams({ page: 0, tableView: isTableView });
  };

  //Chart timeframe filter dropdown on click action
  onChangeTimeFrame = (value: string) => {
    this.setState({
      chartTimeFrameFilterSelected: value,
      pageNumber: 0,
    });

    this.fetchData({ chartTimeFrame: value });
    this.constructQueryParams({ page: 0, timeframe: value });

    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: this.endTimestamp,
      event_type: 'predictions-chart-timeframe-changed',
      model_name: this.state.selectedModel.name,
      start_timestamp: this.startTimestamp,
      timeframe_filter: value,
      y_axis: this.state.chartYAxisSelected,
    });
  };

  //model names filter dropdown on click action
  onChangeSelectedModel = async (value: string) => {
    const model = this.flattenModelAndInputObjects().find(
      item => item.name === value,
    ) ?? { name: '' };

    this.setState({
      pageNumber: 0,
      selectedModel: model,
    });

    this.fetchData({ model: model.name });
    this.constructQueryParams({ model: model.name, page: 0 });

    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: this.endTimestamp,
      event_type: this.state.tableView
        ? 'prediction-table-model-changed'
        : 'prediction-chart-model-changed',
      model_name: model.name,
      start_timestamp: this.startTimestamp,
    });
  };

  //filter chart data by values dropdown on click action
  onChangeChartData = (e: Event, option: string): void => {
    this.setState({
      chartDataViewDropdownVisible: false,
      chartYAxisSelected: option,
    });

    this.constructQueryParams({ yaxis: option });

    this.props.sendTrackingEvent(ModuleName.ANALYTICS, {
      end_timestamp: this.endTimestamp,
      event_type: 'predictions-chart-yaxis-changed',
      model_name: this.state.selectedModel,
      start_timestamp: this.startTimestamp,
      timeframe_filter: this.state.chartTimeFrameFilterSelected,
      y_axis: option,
    });
  };

  constructQueryParams = ({
    end = this.endTimestamp,
    model = this.state.selectedModel.name,
    page = this.state.pageNumber,
    start = this.startTimestamp,
    tableView = this.state.tableView,
    timeframe = this.state.chartTimeFrameFilterSelected,
    yaxis = this.state.chartYAxisSelected,
  }: Partial<{
    end: number;
    model: string;
    page: number;
    start: number;
    tableView: boolean;
    timeframe: string;
    yaxis: string;
  }> = {}) => {
    const { location, navigate } = this.props;
    const params = new URLSearchParams({
      end: String(end),
      model,
      page: String(page + 1),
      start: String(start),
      timeframe,
      view: tableView ? 'table' : 'chart',
      yaxis,
    });

    navigate(`${location.pathname}?${params.toString()}`);
  };

  hydrateStateFromQueryParams = async () => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    const end = params.get('end');
    const start = params.get('start');
    const page = params.get('page');
    const timeframe = params.get('timeframe');
    const view = params.get('view');
    const model = params.get('model');
    const yaxis = params.get('yaxis');

    const data = {
      chartTimeFrameFilterSelected:
        timeframe ?? this.state.chartTimeFrameFilterSelected,
      chartYAxisSelected: yaxis ?? this.state.chartYAxisSelected,
      dateRangeValue:
        start && end
          ? { from: new Date(Number(start)), to: new Date(Number(end)) }
          : this.state.dateRangeValue,
      pageNumber: Number(page) - 1 || this.state.pageNumber,
      selectedModel: model ? { name: model } : this.state.selectedModel,
      tableView: view === 'table',
    };

    this.setState(state => {
      return {
        ...state,
        ...data,
      };
    });

    return await this.fetchData({
      chartTimeFrame: data.chartTimeFrameFilterSelected,
      endTimestamp: data.dateRangeValue.to.valueOf(),
      isTableView: data.tableView,
      model: data.selectedModel.name,
      startTimestamp: data.dateRangeValue.from.valueOf(),
    });
  };

  resetPage() {
    this.setState({ pageNumber: 0 });
  }

  render() {
    let metrics;
    const aggregate: TableAggregateData = this.props.metrics.aggregate;
    const breakdown = this.props.breakdown;
    const sortedProperty = this.props.sortedProperty;

    //no results object to display in rows for no results UI
    const emptyBreakdown = {
      actual_count: 0,
      field_value: this.state.selectedModel,
      precision: 0,
      predicted_count: 0,
      recall: 0,
    };
    //analytics table data
    if (
      breakdown[this.state.pageNumber] &&
      breakdown[this.state.pageNumber].length > 0 &&
      breakdown.length > 0
    ) {
      metrics =
        breakdown &&
        breakdown[this.state.pageNumber].map(
          (tableData: TableBreakdownData, index: number) => (
            <TableRow
              breakdown={breakdown}
              cells={[
                tableData.field_value,
                tableData.actual_count,
                tableData.predicted_count,
                formatNToPercentage(tableData.recall),
                <HandleAccuracy key='HandleAccuracy' {...tableData} />,
              ]}
              isLabelCapitalized={false}
              key={index}
            />
          ),
        );
    } else if (breakdown.length === 0 && this.props.loading === false) {
      //specifies we want an arr with 10 space and fill puts emptyBreakdown in there
      const noResultsBreakdown = Array(10).fill(emptyBreakdown);
      metrics = noResultsBreakdown.map((tableData: any, index: number) => (
        <TableRow
          breakdown={breakdown}
          cells={[
            tableData.field_value,
            tableData.actual_count,
            tableData.predicted_count,
            formatNToPercentage(tableData.recall),
            formatNToPercentage(tableData.precision),
          ]}
          isLabelCapitalized={false}
          key={index}
        />
      ));
    }

    //dropdown with model namesdropdown options
    const modelsDropDownOptions = this.flattenModelAndInputObjects().map(
      item => ({ label: item.displayName ?? item.name, value: item.name }),
    );

    const csvData =
      this.props.breakdown &&
      this.props.breakdown.flat().map((data: any) => {
        return {
          Accuracy: formatNToPercentage(data.precision),
          Actual: data.actual_count,
          Category: data.field_value,
          Coverage: formatNToPercentage(data.recall),
          Predicted: data.predicted_count,
        };
      });

    const predictionsData = [
      {
        Accuracy: aggregate && formatNToPercentage(aggregate.precision),
        Actual: aggregate && aggregate.actual_count,
        Category: 'Totals & Averages',
        Coverage:
          aggregate && formatNToPercentage(aggregate.predicted_fraction),
        Predicted: aggregate && aggregate.predicted_count,
      },
      ...csvData,
    ];

    return (
      <>
        <div className='analytics-page'>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: theme.palette.colors.white,
              borderBottom: `1px solid ${theme.palette.colors.grey[100]}`,
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <Box>
              <div
                className='dropdown view-dropdown'
                ref={node => (this.chartDataView = node)}
              >
                <button
                  aria-expanded='false'
                  className='select'
                  onClick={() =>
                    this.setState({
                      chartDataViewDropdownVisible:
                        !this.state.chartDataViewDropdownVisible,
                    })
                  }
                  type='button'
                >
                  <span
                    className={
                      !this.state.tableView
                        ? 'heading-y selected-option chart-filter-selected'
                        : 'heading-y chart-filter-selected'
                    }
                  >
                    <span className='dropdown-title'>
                      {this.state.tableView
                        ? 'Triage'
                        : chartDataListOptions.find(
                            item =>
                              item.value === this.state.chartYAxisSelected,
                          )?.label}
                    </span>
                  </span>
                  {!this.state.tableView && (
                    <span
                      className={
                        this.state.chartDataViewDropdownVisible
                          ? 'desc-selected -arrow'
                          : 'down-arrow'
                      }
                    ></span>
                  )}
                </button>
                {!this.state.tableView &&
                  this.state.chartDataViewDropdownVisible && (
                    <DropDownMenu
                      buttonClass={'dropdown-option answers-chart-data-view '}
                      data={chartDataListOptions.map(({ value }) => value)}
                      dropdownClass={'dropdown-menu view-menu'}
                      dropdownContClass={'v0-chart-data-menu'}
                      onClick={this.onChangeChartData}
                      selectedOption={this.state.chartYAxisSelected}
                    />
                  )}
              </div>
            </Box>
            <Box display='flex'>
              <OverallMetrics
                endTimestamp={Math.floor(this.endTimestamp / 1000)}
                modelName={this.state.selectedModel.name}
                startTimestamp={Math.floor(this.startTimestamp / 1000)}
              />
              <Box
                alignItems='center'
                display='flex'
                flexWrap='wrap'
                gap={1}
                padding={1}
              >
                <DateRangeFilterButton
                  initialValue={last30DaysTimeRange}
                  onChange={this.onChangeDateRange}
                  options={datePickerRangeOptionsRevamp}
                  value={this.state.dateRangeValue}
                />

                <FilterButton
                  aria-label='Views'
                  initialValue='table'
                  onChange={this.onChangeDataViewDropdown}
                  options={dataViewListOptions}
                  startAdornment={
                    <span
                      className={
                        this.state.tableView
                          ? 'table-view-icon'
                          : 'chart-view-icon'
                      }
                    />
                  }
                  value={this.state.tableView ? 'table' : 'chart'}
                />

                {!this.state.tableView && (
                  <FilterButton
                    aria-label='Time frame'
                    initialValue='daily'
                    onChange={this.onChangeTimeFrame}
                    options={chartTimeframeListOptions}
                    startAdornment={<span className='time-icon' />}
                    value={this.state.chartTimeFrameFilterSelected}
                  />
                )}

                <FilterButton
                  aria-label='Models'
                  initialValue='language-detection'
                  onChange={this.onChangeSelectedModel}
                  options={modelsDropDownOptions}
                  startAdornment={<span className='model-icon' />}
                  value={this.state.selectedModel.name}
                />

                {this.state.tableView && (
                  <>
                    <Button
                      onClick={e =>
                        this.setState({
                          shareDropdownAnchorEl: e.currentTarget,
                        })
                      }
                      startIcon={<span className='share-icon' />}
                      variant='main'
                    >
                      Share
                    </Button>

                    <Menu
                      anchorEl={this.state.shareDropdownAnchorEl}
                      onClose={() =>
                        this.setState({ shareDropdownAnchorEl: null })
                      }
                      open={Boolean(this.state.shareDropdownAnchorEl)}
                    >
                      <MenuItem
                        aria-labelledby='dropdownMenu'
                        className='dropdown-menu view-menu'
                      >
                        <CSVLink
                          data={predictionsData}
                          filename={'forethought-predictions-report.csv'}
                          onClick={() => this.shareDropdown()}
                          target='_blank'
                        >
                          Download CSV
                        </CSVLink>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          {this.props.loading && <LoadingSpinner />}

          {!this.props.loading && !this.state.tableView && (
            <Chart
              chartData={this.props.chartData}
              dataTypes={{}}
              mean={this.props.mean}
              timeFrameFilter={this.state.chartTimeFrameFilterSelected}
              useSingleTooltip={true}
              y={this.state.chartYAxisSelected}
            />
          )}

          {this.props.loading === false && this.state.tableView === true && (
            <div className='analytics-table'>
              {breakdown.length === 0 && (
                <NoDataView
                  message={noDataViewProps.ANALYTICS_TIME_RANGE}
                  noDataViewClass={noDataViewProps.DATE_ICON_CLASS}
                />
              )}
              <table className='analytics'>
                <tbody>
                  <tr className='table-header'>
                    <TableHeader
                      divClass={
                        sortedProperty === 'field_value'
                          ? 'sort-wrapper focused'
                          : 'sort-wrapper'
                      }
                      heading={
                        this.state.selectedModel.displayName ??
                        this.state.selectedModel.name ??
                        ''
                      }
                      headingClass={'ocurrence-heading'}
                      onClick={this.resetPage}
                      productSorted={'prediction_table'}
                      propertyName={'field_value'}
                    />
                    <TableHeader
                      divClass={
                        sortedProperty === 'actual_count'
                          ? 'sort-wrapper focused'
                          : 'sort-wrapper'
                      }
                      heading={'ACTUAL'}
                      headingClass={'heading-type'}
                      onClick={this.resetPage}
                      productSorted={'prediction_table'}
                      propertyName={'actual_count'}
                      tooltipClass={'second-col'}
                      tooltipText={
                        'Number of total tickets that received a specific label or category, either from the classifier or by an agent, including if an agent adjusts a label that the classifier previously made'
                      }
                    />
                    <TableHeader
                      divClass={
                        sortedProperty === 'predicted_count'
                          ? 'sort-wrapper focused'
                          : 'sort-wrapper'
                      }
                      heading={'PREDICTED'}
                      headingClass={'heading-type'}
                      onClick={this.resetPage}
                      productSorted={'prediction_table'}
                      propertyName={'predicted_count'}
                      tooltipClass={'second-col'}
                      tooltipText={
                        'Number of tickets that the classifier categorized with this label'
                      }
                    />
                    <TableHeader
                      divClass={
                        sortedProperty === 'recall'
                          ? 'sort-wrapper focused'
                          : 'sort-wrapper'
                      }
                      heading={'COVERAGE'}
                      headingClass={'heading-type'}
                      onClick={this.resetPage}
                      productSorted={'prediction_table'}
                      propertyName={'recall'}
                      tooltipClass={'second-col'}
                      tooltipText={
                        'Percentage of tickets that the classifier predicted out of the actual number of tickets that ended up being categorized this way'
                      }
                    />
                    <TableHeader
                      divClass={
                        sortedProperty === 'precision'
                          ? 'sort-wrapper focused'
                          : 'sort-wrapper'
                      }
                      heading={'ACCURACY'}
                      headingClass={'heading-type'}
                      onClick={this.resetPage}
                      productSorted={'prediction_table'}
                      propertyName={'precision'}
                      tooltipClass={'second-col'}
                      tooltipText={
                        'Percentage of time the classifier predicted a label accurately'
                      }
                    />
                  </tr>
                  {this.props.breakdown && this.props.breakdown.length > 0 && (
                    <tr className='totals data-row'>
                      <td className='field-cell'>
                        <p className='field-p'>Totals & Averages</p>
                      </td>
                      <td className='cell'>
                        <p className='cell-p'>
                          {aggregate && aggregate.actual_count}
                        </p>
                      </td>
                      <td className='cell'>
                        <p className='cell-p'>
                          {aggregate && aggregate.predicted_count}
                        </p>
                      </td>
                      <td className='cell'>
                        <p className='cell-p'>
                          {aggregate &&
                            formatNToPercentage(aggregate.predicted_fraction)}
                        </p>
                      </td>
                      <td className='cell'>
                        <p className='cell-p'>
                          {aggregate && <HandleAccuracy {...aggregate} />}
                        </p>
                      </td>
                    </tr>
                  )}
                  {metrics}
                </tbody>
              </table>
              <ReactPaginate
                activeClassName={'active'}
                breakClassName={'break-me'}
                breakLabel={'...'}
                containerClassName={'pagination'}
                forcePage={this.state.pageNumber}
                marginPagesDisplayed={0}
                nextLabel={''}
                onPageChange={this.handlePageClick.bind(this)}
                pageCount={
                  Object.keys(this.props.breakdown).length > 0
                    ? Object.keys(this.props.breakdown).length
                    : 1
                }
                pageRangeDisplayed={2}
                previousLabel={''}
                subContainerClassName={'pages pagination'}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  breakdown: state.analytics.predictionsBreakdown,
  chartData: state.analytics.chartPredictionMetricsData,
  end_time: state.analytics.predictionsEndTimestamp,
  finalPage: state.analytics.predictionsFinalPageReached,
  loading: state.search.loading,
  mean: state.analytics.chartPredictionMetrics.mean,
  metrics: state.analytics.predictionsMetrics,
  modelName: state.analytics.modelName,
  modelsId: state.analytics.modelsId,
  sortedProperty: state.analytics.sortType,
  start_time: state.analytics.predictionsStartTimestamp,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchingData: (isLoading: boolean) => dispatch(searchLoading(isLoading)),
  fetchModelsId: () => dispatch(fetchModelsId()),
  getChartPredictionsMetric: async (
    model_name: string,
    start_timestamp: any,
    end_timestamp: any,
    breakdown_type: string,
  ) =>
    dispatch(
      getChartPredictionsMetric(
        model_name,
        start_timestamp,
        end_timestamp,
        breakdown_type,
      ),
    ),
  getPredictionsMetrics: (
    model_name: string,
    start_timestamp: number,
    end_timestamp: number,
    offset?: number,
    limit?: number,
  ) =>
    dispatch(
      getPredictionsAnalytics(
        model_name,
        start_timestamp,
        end_timestamp,
        offset,
        limit,
      ),
    ),
  nextPageAnalyticsMetrics: (
    model_name: string,
    offset?: number,
    limit?: number,
  ) => dispatch(getPredictionsAnalytics(model_name, offset, limit)),
  sendTrackingEvent: (moduleName: string, event: any) =>
    dispatch(sendTrackingEvent(moduleName, event)),
});

const ConnectedPredictionsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PredictionsPage));

export default ConnectedPredictionsPage;
