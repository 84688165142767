import { useDrop } from 'react-dnd';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function EmailCanvasEmptyState() {
  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: [
      'feedback',
      'static_article',
      'text_message',
      'button',
      'dynamic_article',
      'image',
      'macro',
      'interactive_email_button',
      'html',
    ],
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
    drop: (_, monitor) => {
      return {
        hoverPosition: 0,
        type: monitor.getItemType() as string,
      };
    },
  }));

  return (
    <Box
      ref={dropRef}
      sx={theme => ({
        borderColor: isOver ? theme.palette.colors.purple[500] : 'transparent',
        borderStyle: 'solid',
        borderWidth: 1,
        padding: '16px',
        textAlign: 'center',
      })}
    >
      <Typography variant='font18'>
        Drag and drop components here to build a template
      </Typography>
    </Box>
  );
}
