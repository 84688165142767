import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import {
  AutoflowTool,
  BaseTransition,
  ButtonOption,
  Nullable,
} from 'src/types/workflowBuilderAPITypes';

export const createNewTransitionsFromButtonOptions = (
  options: ButtonOption[],
  transitions: Nullable<BaseTransition>[],
  outputVariable: string,
) => {
  const mappedTransitions = options.map((option, index) => {
    if (!option.display_name) {
      return {};
    }
    if (option.transition_id) {
      const oldTransition = transitions.find(
        transition => transition.transition_id === option.transition_id,
      );
      // if old transition exists, use updated old transition
      if (oldTransition) {
        // condition expression is not null
        if (oldTransition.condition_expression) {
          return {
            ...oldTransition,
            condition_expression: {
              ...oldTransition.condition_expression,
              values: [options[index]?.value],
            },
          };
        }
        // condition expression is null
        return {
          ...oldTransition,
          condition_expression: null,
        };
      }
    }

    // if old transition doesn't exist, create a default transition
    return {
      condition_expression: {
        expression_type: 'filter',
        field: outputVariable,
        negate: false,
        operator: '=',
        values: [options[index]?.value],
      },
      step_id: null,
    };
  });

  return mappedTransitions;
};

export const sortContextVariables = (
  firstContextVariable: ContextVariable & { isActive: boolean },
  secondContextVariable: ContextVariable & { isActive: boolean },
) =>
  Number(secondContextVariable.isActive) -
    Number(firstContextVariable.isActive) ||
  firstContextVariable.context_variable_name
    .trim()
    .localeCompare(secondContextVariable.context_variable_name.trim());

export const sortTools = (
  firstTool: AutoflowTool & { isActive: boolean },
  secondTool: AutoflowTool & { isActive: boolean },
) =>
  Number(secondTool.isActive) - Number(firstTool.isActive) ||
  Number(secondTool.tool_type === 'hand_off') -
    Number(firstTool.tool_type === 'hand_off') ||
  firstTool.tool_name.trim().localeCompare(secondTool.tool_name.trim());
