import React from 'react';
import { styled } from '@mui/material';

export const BrowserChrome = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <Container>
      <Toolbar>
        <Circle />
        <Circle />
        <Circle />
      </Toolbar>
      {children}
    </Container>
  );
};

const Container = styled('div')`
  background-color: ${props => props.theme.palette.background.default};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 85%;
  height: 98%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Toolbar = styled('div')`
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  padding-left: 24px;
  align-items: center;
`;

const Circle = styled('div')`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d2d3d8;
  margin-right: 8px;
`;
