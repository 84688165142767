import React, { FC } from 'react';
import { styled } from '@mui/material';

import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import { Select } from 'src/components/reusable-components/select/Select';
import { selectStyleProps } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import { FieldAndValue } from 'src/types/workflowBuilderAPITypes';

interface DropdownValuesProps {
  fieldArr: Array<{ id: string; title: string }>;
  fieldId: string;
  fieldName: string;
  idFieldMap: {
    [id: string]: {
      field_options: Array<{ name: string; value: string }>;
    };
  };
  idsWithValues: Set<string>;
  idValueArr: Array<FieldAndValue>;
  idx: number;
  setFieldValue: (name: string, value: Array<FieldAndValue>) => void;
}

const DropdownFields: FC<React.PropsWithChildren<DropdownValuesProps>> = ({
  fieldArr,
  fieldId,
  fieldName,
  idFieldMap,
  idsWithValues,
  idValueArr,
  idx,
  setFieldValue,
}) => {
  return (
    <Container>
      <Select
        aria-label='Select a Field...'
        maxMenuHeight={180}
        onChange={e => {
          if (e) {
            const updatedFieldValues = idValueArr
              .slice(0, idx)
              .concat({ id: e.value, value: '' })
              .concat(idValueArr.slice(idx + 1));

            setFieldValue(fieldName, updatedFieldValues);
          }
        }}
        options={fieldArr
          .filter(({ id }) => !idsWithValues.has(id))
          .map((customField: { id: string; title: string }) => ({
            label: customField.title,
            value: customField.id,
          }))}
        value={{
          label: getOr('Select a Field...', [fieldId, 'title'], idFieldMap),
          value: get([fieldId, 'id'], idFieldMap),
        }}
        {...selectStyleProps}
      />
    </Container>
  );
};

export default DropdownFields;

const Container = styled('div')`
  flex: 1 1 50%;
`;
