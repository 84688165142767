import Skeleton from 'react-loading-skeleton';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  CHAT_TRENDS_PERIOD_OPTIONS,
  CHAT_TRENDS_TOOLTIP_COPY,
  INSIGHT_TOOLTIP_COPY,
} from '../constants';
import { periodToTimestampsByNumOfRange } from '../helpers';
import { TABLE_COLUMN_TO_TOOLTIP_MAP } from '../topics/constants';
import { ChatTrendsPeriodicalFilter } from '../types';
import { EmptyGraphCard } from './EmptyGraphCard';
import { BarGraphWrapper } from 'src/components/solve-insights-charts/bar-graph/barGraphUtils';
import ToggleBar from 'src/components/toggle-bar/ToggleBar';
import { TEMP_BLUE } from 'src/constants/solve';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import { RelevanceTooltip } from 'src/pages/workflow-builder/intent-workflows-table/containers/WidgetIntentWorkflowsTableContainer';
import { PaletteTooltip } from 'src/pages/workflow-builder/intent-workflows-table/PaletteTooltip';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  useGetOverviewChatsChartQuery,
  useGetOverviewCsatChartQuery,
  useGetOverviewDeflectedChartQuery,
  useGetOverviewEngagementChartQuery,
  useGetOverviewKASurfacedChartQuery,
  useGetOverviewQuickFeedbackChartQuery,
  useGetOverviewRelevanceChartQuery,
} from 'src/services/insights';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

interface ChatTrendsSectionProps {
  periodicalFilter: ChatTrendsPeriodicalFilter;
  setPeriodicalFilter: (state: ChatTrendsPeriodicalFilter) => void;
}

const ChatTrendsSection = ({
  periodicalFilter,
  setPeriodicalFilter,
}: ChatTrendsSectionProps) => {
  const { palette } = useTheme();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;

  const tabs = CHAT_TRENDS_PERIOD_OPTIONS.map(option => {
    return {
      onClick: () => setPeriodicalFilter(option.value),
      title: option.label,
    };
  });

  return (
    <Box
      display='flex'
      gap={3}
      paddingTop='40px'
      sx={{
        borderTopColor: palette.colors.grey[200],
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
      }}
      width='100%'
    >
      <Box display='flex' flexDirection='column' gap={3} width='100%'>
        <Box alignItems='center' display='flex' gap={2} width='100%'>
          <Typography variant='font20'>Chat trends</Typography>
          <ToggleBar
            activeTab={capitalizeFirstLetter(periodicalFilter)}
            tabs={tabs}
          />
        </Box>
        {/* Graph Section */}
        <Box display='flex' gap='24px' sx={{ overflowX: 'scroll' }}>
          <TrendGraph periodicalFilter={periodicalFilter} type='chat' />
          <TrendGraph
            periodicalFilter={periodicalFilter}
            type='deflection_rate'
          />
          <TrendGraph periodicalFilter={periodicalFilter} type='ka_surfaced' />
          <TrendGraph periodicalFilter={periodicalFilter} type='csat' />
          {shouldShowQuickFeedbackAnalytics && (
            <TrendGraph
              periodicalFilter={periodicalFilter}
              type='quick_feedback'
            />
          )}
          {isDeflectionInsightsEnabled && (
            <>
              <TrendGraph
                periodicalFilter={periodicalFilter}
                type='relevance'
              />
              <TrendGraph
                periodicalFilter={periodicalFilter}
                type='engagement'
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

interface TrendGraphProps {
  periodicalFilter: ChatTrendsPeriodicalFilter;
  type:
    | 'chat'
    | 'deflection_rate'
    | 'ka_surfaced'
    | 'csat'
    | 'quick_feedback'
    | 'relevance'
    | 'engagement';
}

const TrendGraph = ({ periodicalFilter, type }: TrendGraphProps) => {
  const { palette } = useTheme();

  const timestamps = periodToTimestampsByNumOfRange({
    period: periodicalFilter,
  });

  const { data: chatData, isLoading: isChatDataLoading } =
    useGetOverviewChatsChartQuery(
      {
        period: periodicalFilter,
        timestamps: timestamps,
      },
      { skip: type !== 'chat' },
    );
  const { data: deflectedData, isLoading: isDeflectedDataLoading } =
    useGetOverviewDeflectedChartQuery(
      {
        period: periodicalFilter,
        timestamps: timestamps,
      },
      { skip: type !== 'deflection_rate' },
    );
  const { data: kaSurfacedData, isLoading: isKaSurfacedDataLoading } =
    useGetOverviewKASurfacedChartQuery(
      {
        period: periodicalFilter,
        timestamps: timestamps,
      },
      { skip: type !== 'ka_surfaced' },
    );
  const { data: csatData, isLoading: isCsatDataLoading } =
    useGetOverviewCsatChartQuery(
      {
        period: periodicalFilter,
        timestamps: timestamps,
      },
      { skip: type !== 'csat' },
    );
  const { data: quickFeedbackData, isLoading: isQuickFeedbackDataLoading } =
    useGetOverviewQuickFeedbackChartQuery(
      {
        period: periodicalFilter,
        timestamps: timestamps,
      },
      { skip: type !== 'quick_feedback' },
    );
  const { data: relevanceData, isLoading: isRelevanceDataLoading } =
    useGetOverviewRelevanceChartQuery(
      {
        period: periodicalFilter,
        timestamps: timestamps,
      },
      { skip: type !== 'relevance' },
    );

  const { data: enagementData, isLoading: isEngagementDataLoading } =
    useGetOverviewEngagementChartQuery(
      {
        period: periodicalFilter,
        timestamps: timestamps,
      },
      { skip: type !== 'engagement' },
    );

  const isLoading =
    isChatDataLoading ||
    isDeflectedDataLoading ||
    isKaSurfacedDataLoading ||
    isCsatDataLoading ||
    isQuickFeedbackDataLoading ||
    isRelevanceDataLoading ||
    isEngagementDataLoading;
  const data =
    chatData ||
    deflectedData ||
    kaSurfacedData ||
    csatData ||
    quickFeedbackData ||
    relevanceData ||
    enagementData;

  const handleTitle = () => {
    switch (type) {
      case 'chat':
        return 'Chats';
      case 'deflection_rate':
        return 'Deflection rate';
      case 'ka_surfaced':
        return 'Knowledge article surfaced';
      case 'csat':
        return 'Avg. CSAT';
      case 'quick_feedback':
        return 'Quick feedback';
      case 'relevance':
        return 'Relevance';
      case 'engagement':
        return 'User engagement';
    }
  };

  let tooltipContent: JSX.Element | string = CHAT_TRENDS_TOOLTIP_COPY[type];

  if (tooltipContent === 'relevance') {
    tooltipContent = <RelevanceTooltip text={INSIGHT_TOOLTIP_COPY.relevance} />;
  }
  if (tooltipContent === 'engagement') {
    tooltipContent = (
      <PaletteTooltip
        text={TABLE_COLUMN_TO_TOOLTIP_MAP.topic['dropoff_breakdown']}
        values={[
          { color: TEMP_BLUE, label: 'Yes' },
          { color: palette.colors.red[500], label: 'No' },
        ]}
      />
    );
  }

  return (
    <LoadingGraphContainerWrapper isLoading={isLoading}>
      <Box
        border={`1px solid ${palette.colors.slate[200]}`}
        borderRadius='8px'
        flex='1 1 0px'
      >
        <>
          <Box display='flex' padding='24px 0px 0px 24px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography variant='font16Bold'>{handleTitle()}</Typography>
              {tooltipContent && (
                <Tooltip maxWidth={320} tooltipContent={tooltipContent}>
                  <IconInfoCircle color='currentColor' height={16} width={16} />
                </Tooltip>
              )}
            </Box>
          </Box>
          {data && data.bar.values.length ? (
            <BarGraphWrapper data={data} palette={palette} />
          ) : (
            <EmptyGraphCard />
          )}
        </>
      </Box>
    </LoadingGraphContainerWrapper>
  );
};

const LoadingGraphContainerWrapper = ({
  children,
  isLoading,
}: {
  children: JSX.Element;
  isLoading: boolean;
}): JSX.Element => {
  if (isLoading) {
    return (
      <Box flex='1 1 0px' minWidth='300px'>
        <Skeleton height='300px' width='100%' />
      </Box>
    );
  }
  return children;
};

export default ChatTrendsSection;
