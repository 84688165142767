import { Box, useTheme } from '@mui/material';
import { IconChartBarOff } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';

export const EmptyGraphCard = () => {
  const { palette } = useTheme();

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      gap='18px'
      height='250px'
      justifyContent='center'
      minWidth='300px'
      width='100%'
    >
      <Box display='flex' justifyContent='center'>
        <IconChartBarOff color={palette.colors.slate[200]} size='48px' />
      </Box>
      <Box alignSelf='center' display='flex' width='172px'>
        <Typography variant='font14'>
          Insufficient data available to display at the moment
        </Typography>
      </Box>
    </Box>
  );
};
