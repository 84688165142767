import LogRocket from 'logrocket';

import { Environment } from 'src/utils/enums';

const getLogRocketKey = (): string | null => {
  if (ENVIRONMENT === Environment.STAGING) {
    return `${LOGROCKET_KEY}/stagingdashboard-ui-ubqtr`;
  }

  if (ENVIRONMENT === Environment.PRODUCTION) {
    return `${LOGROCKET_KEY}/proddashboard-ui-a6ssl`;
  }

  return null;
};

export function initLogRocket() {
  const key = getLogRocketKey();
  if (key) {
    LogRocket.init(key, {
      console: {
        shouldAggregateConsoleErrors: true,
      },
      network: {
        requestSanitizer: request => {
          request.headers['Authorization'] = '';
          return request;
        },
      },
      release: APP_VERSION,
    });
  }
}
