import { Box, styled, useTheme } from '@mui/material';
import { IconAlertTriangleFilled } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';

const TITLE = 'This article disappeared from your knowledge base';
const SUBTITLE =
  'Please remove this article and republish your Journey Map to send this response.';

export default function IntentEmailArticleError() {
  const { palette } = useTheme();

  return (
    <Box padding='16px'>
      <Card>
        <Box alignItems='center' display='flex' gap='8px'>
          <Box color={palette.colors.red[500]} display='flex'>
            <IconAlertTriangleFilled size={18} />
          </Box>
          <Typography variant='font16Bold'>{TITLE}</Typography>
        </Box>
        <Box maxWidth='340px'>
          <Typography color={palette.colors.grey[700]} variant='font14'>
            {SUBTITLE}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

const Card = styled('div')`
  border: 1px solid ${props => props.theme.palette.colors.red[400]};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
`;
