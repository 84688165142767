import { useSelector } from 'react-redux';

import DynamicArticleCard from './DynamicArticleCard';
import { DraggableComponent } from 'src/components/email-builder';
import { selectAvailableArticleSources } from 'src/slices/email-builder/emailBuilderSlice';

const IntentEmailDynamicArticleComponent = () => {
  const avaiableArticleSources = useSelector(selectAvailableArticleSources);
  const docType = avaiableArticleSources[0].doc_type;

  return (
    <DraggableComponent
      componentMetadata={{
        componentFields: {
          article_sources: [{ doc_type: docType, filter_queries: [] }],
          number_of_articles: 1,
        },
      }}
      componentType='dynamic_article'
    >
      <DynamicArticleCard
        message="Dynamically recommend up to three articles in your email based on the
        customer's request."
      />
    </DraggableComponent>
  );
};

export default IntentEmailDynamicArticleComponent;
