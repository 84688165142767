import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';

import { AUTONOMOUS_AGENT_PREVIEW_VISUALIZER_ID } from '../constants';

const colors = {
  gray: '#AABBCA',
  green: '#29CF26',
  orange: '#FD9A4E',
  purple: '#DE82FF',
  violet: '#42A4FF',
};

interface AutonomousAgentWidgetEvent {
  agentActions?: string[];
  agentResponse?: string;
  event: unknown;
  intentId?: string;
  intentTitle?: string;
  policy?: string;
  userQuery?: string;
}

type TranscriptMessageType = 'user' | 'agent' | 'agent-action';

interface TranscriptMessage {
  message: string;
  type: TranscriptMessageType;
}

const getPrefixAndColor = (type: TranscriptMessageType) => {
  if (type === 'agent') {
    return { color: colors.orange, prefix: 'Agent: ' };
  }

  if (type === 'user') {
    return { color: colors.green, prefix: 'User: ' };
  }

  return { color: colors.gray, prefix: 'Agent thought:\n' };
};

const useScrollToBottomOfPreviewVisualizer = (transcriptLength: number) => {
  useEffect(() => {
    const previewBox = document.getElementById(
      AUTONOMOUS_AGENT_PREVIEW_VISUALIZER_ID,
    );

    if (previewBox) {
      previewBox.scrollTo({ behavior: 'smooth', top: previewBox.scrollHeight });
    }
  }, [transcriptLength]);
};

const useGetTranscriptMessage = () => {
  const [transcript, setTranscript] = useState<TranscriptMessage[]>([]);
  const [intentData, setIntentData] = useState<{
    intentId?: string;
    intentTitle?: string;
    policy?: string;
  } | null>(null);

  useEffect(() => {
    const handleMessage = (e: MessageEvent<AutonomousAgentWidgetEvent>) => {
      if (e.data.event === 'autonomousAgentConversationUpdated') {
        const {
          agentActions,
          agentResponse,
          intentId,
          intentTitle,
          policy,
          userQuery,
        } = e.data;

        if (policy || intentId || intentTitle) {
          setIntentData({ intentId, intentTitle, policy });
        }

        if (userQuery) {
          setTranscript(oldTranscript => [
            ...oldTranscript,
            { message: userQuery, type: 'user' },
          ]);
        }

        if (agentActions?.length) {
          setTranscript(oldTranscript => [
            ...oldTranscript,
            { message: agentActions.join('\n'), type: 'agent-action' },
          ]);
        }

        if (agentResponse) {
          setTranscript(oldTranscript => [
            ...oldTranscript,
            { message: agentResponse, type: 'agent' },
          ]);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return [transcript, intentData] as const;
};

const Transcript = () => {
  const [transcript, intentData] = useGetTranscriptMessage();
  useScrollToBottomOfPreviewVisualizer(transcript.length);
  const { search, state } = useLocation();
  const intentTitleFromParam = new URLSearchParams(search).get('intentTitle');
  const policyDescription = state?.policy;

  const { intentTitle, policy } = intentData ?? {};

  const intentTitleToDisplay = intentTitle || intentTitleFromParam;
  const policyToDisplay = policyDescription || policy;

  return (
    <Box
      fontFamily='Fira Code'
      fontSize='18px'
      fontWeight='450px'
      lineHeight='150%'
      p='60px'
      pt='120px'
      sx={{ a: { color: 'rgba(255,255,255,0.66)' } }}
      whiteSpace='pre-line'
    >
      {intentTitleToDisplay && (
        <Box mb={4}>
          <span style={{ color: colors.purple }}>Journey:{'\n'}</span>
          <span style={{ color: colors.violet }}>{intentTitleToDisplay}</span>
        </Box>
      )}
      {policyToDisplay && (
        <Box mb={4}>
          <span style={{ color: colors.purple }}>
            Policy description:{'\n'}
          </span>
          <span
            dangerouslySetInnerHTML={{ __html: policyToDisplay }}
            style={{ color: colors.violet }}
          />
        </Box>
      )}
      <Box>
        <span style={{ color: colors.purple }}>Conversation history:</span>
      </Box>
      {transcript.map((message, index) => (
        <Message {...message} key={index} />
      ))}
    </Box>
  );
};

const Message = ({ message, type }: TranscriptMessage) => {
  const { color, prefix } = getPrefixAndColor(type);
  const shouldHaveMargin = type === 'agent';

  return (
    <Box mb={shouldHaveMargin ? 4 : 0}>
      <span style={{ color: colors.violet }}>{prefix}</span>
      <span style={{ color, wordWrap: 'break-word' }}>{message}</span>
    </Box>
  );
};

export default Transcript;
