import React from 'react';
import { Form, Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/material';

import {
  Button,
  Checkbox,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ThumbIcon from 'src/assets/images/email-builder-thumb.svg';
import BaseModal from 'src/components/base-modal';
import { usePatchArticleMutation } from 'src/hooks/discover/usePatchArticleMutation';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverArticle } from 'src/reducers/discoverReducer/types';
import { updateArticleFeedbackData } from 'src/slices/solve-insights/solveInsightsSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

interface ArticleFeedbackModalProps {
  articleId?: string;
  articles: DiscoverArticle[] | undefined;
  isOpen: boolean;
  onClose: () => void;
}

const checkboxOptions = [
  'This is harmful / unsafe',
  "This isn't true",
  "This isn't helpful",
] as const;

const ArticleFeedbackModal: React.FC<
  React.PropsWithChildren<ArticleFeedbackModalProps>
> = ({ articleId, articles, isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { patchArticleMutation } = usePatchArticleMutation();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  if (!articles || !articleId) {
    return null;
  }

  const isSolveInsights = pathname.includes(Routes.SOLVE_INSIGHTS_MAIN);

  const article: DiscoverArticle | undefined = articles.find(
    article => article.article_id === articleId,
  );
  if (!article) {
    return null;
  }
  const handleFocusAndClick = (
    event:
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.MouseEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <BaseModal
      featureName='articlePublishModal'
      headerTitle={
        <Box alignItems='center' display='flex' gap='10px'>
          <ThumbDown src={ThumbIcon} />
          <Typography variant='font18Bold'>
            Provide additional feedback
          </Typography>
        </Box>
      }
      isOpen={isOpen}
      maxWidth='sm'
      onClose={onClose}
    >
      <Formik
        initialValues={{
          check_0: false,
          check_1: false,
          check_2: false,
          note: '',
        }}
        onSubmit={async ({ check_0, check_1, check_2, note }) => {
          note = check_0 ? `${note}. [[${checkboxOptions[0]}]]` : note;
          note = check_1 ? `${note}. [[${checkboxOptions[1]}]]` : note;
          note = check_2 ? `${note}. [[${checkboxOptions[2]}]]` : note;
          patchArticleMutation(articleId, {
            feedback: {
              note: note || null,
              thumb: article.feedback.thumb ? null : 'down',
            },
          });
          emitTrackingEventCallback('discover-article-thumbs-down', {
            article_id: article.article_id,
            topic_id: article.topic_id,
          });

          if (isSolveInsights) {
            dispatch(
              updateArticleFeedbackData({
                articleId: article.article_id,
                value: {
                  note: note || null,
                  thumb: article.feedback.thumb ? null : 'down',
                },
              }),
            );
          }

          onClose();
        }}
      >
        {({ setFieldValue, values }) => (
          <Form noValidate translate={undefined}>
            <DialogContent
              sx={{
                px: 3,
                py: 0,
              }}
            >
              <TextField
                aria-label='thumbs down note input'
                maxLength={250}
                multiline={true}
                onChange={e => setFieldValue('note', e.target.value)}
                onClick={handleFocusAndClick}
                onFocus={handleFocusAndClick}
                placeholder='What was the issue with the article? How could it be improved?'
                rows={3}
                value={values.note}
              />
              <Box display='flex' flexDirection='column' mt={1}>
                {checkboxOptions.map((optionText, index) => (
                  <Checkbox
                    checked={values[`check_${index as 0 | 1 | 2}`]}
                    key={optionText}
                    label={
                      <Typography variant='font14'>{optionText}</Typography>
                    }
                    onChange={event => {
                      const castedEvent =
                        event as React.ChangeEvent<HTMLInputElement>;
                      setFieldValue(
                        `check_${index}`,
                        castedEvent.target.checked || false,
                      );
                    }}
                  />
                ))}
              </Box>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button type='submit' variant='secondary'>
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </BaseModal>
  );
};

const ThumbDown = styled(ReactSVG)`
  height: 21px;

  * svg {
    fill: ${props => props.theme.palette.colors.red[100]};
  }
  * path {
    stroke: ${props => props.theme.palette.colors.red[500]};
  }
  transform: rotate(180deg);
`;

export default ArticleFeedbackModal;
