import React, { FC, useEffect, useMemo, useState } from 'react';
import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { theme } from '@forethought-technologies/forethought-elements';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { hasAtLeastOneError } from '../handoff-configuration/helpers';
import { FieldNameToIdx } from '../handoff-configuration/zendesk/constants';
import {
  initializeFieldTouchedArr,
  validateZendeskOrSalesforceContextVariables,
} from '../handoff-configuration/zendesk/helpers';
import isEqual from 'lodash/fp/isEqual';
import pick from 'lodash/fp/pick';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { salesforceChatHandoffValidationSchema } from 'src/pages/workflow-builder-edit/customization-panel/constants';
import {
  buildSalesforceChatHandoffInitialFormValue,
  cleanSalesforceChatHandoffCustomizationData,
} from 'src/pages/workflow-builder-edit/customization-panel/helpers';
import { SalesforceChatHandoffConfiguration } from 'src/pages/workflow-builder-edit/handoff-configuration';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  SalesforceChatHandoffCustomization,
  SalesforceHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface SalesforceChatHandoffCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const SalesforceChatHandoffCustomizationPanel: FC<
  React.PropsWithChildren<SalesforceChatHandoffCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();

  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultSalesforceHandoffCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<SalesforceHandoffCustomization>;
  const defaultSalesforceChatHandoffCustomizationSettings = useMemo(
    () => ({
      agent_chat_handoff_settings: {
        button_id: '',
        chat_transcript_text_area_fields: [],
        deployment_id: '',
        extra_prechat_form_details: [],
        store_transcript_to_chat_transcript_field_id: '',
        ...defaultSalesforceHandoffCustomizationSettings.agent_chat_handoff_settings,
      },
      email: '',
      name: '',
      question: 'free_form_query',
    }),
    [defaultSalesforceHandoffCustomizationSettings.agent_chat_handoff_settings],
  );
  const handoffCustomization = addingAction
    ? defaultSalesforceChatHandoffCustomizationSettings
    : (canvasState?.actionSettings
        .configuration_fields as SalesforceChatHandoffCustomization);

  const [customizationData, setCustomizationData] =
    useState<SalesforceChatHandoffCustomization>(handoffCustomization);

  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setCustomizationData(
        addingAction
          ? defaultSalesforceChatHandoffCustomizationSettings
          : handoffCustomization,
      );
    }
  }, [
    canvasState.isLoadingCustomization,
    handoffCustomization,
    addingAction,
    defaultSalesforceChatHandoffCustomizationSettings,
  ]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF,
        ),
      );
    }
  }, [dispatch, addingAction]);

  if (
    canvasState.isLoadingCustomization ||
    !handoffCustomization ||
    !customizationData
  ) {
    return <LoadingSkeleton />;
  }

  return (
    <Formik<SalesforceChatHandoffCustomization>
      initialValues={buildSalesforceChatHandoffInitialFormValue(
        customizationData,
      )}
      onSubmit={(values, { resetForm }) => {
        const cleanedValues =
          cleanSalesforceChatHandoffCustomizationData(values);

        dispatch(
          addingAction
            ? addAction({
                ...addingAction,
                data: {
                  ...addingAction.data,
                  configuration_fields: cleanedValues,
                },
              })
            : updateActionSettings({
                action_type: canvasState.actionSettings.action_type as string,
                configuration_fields: cleanedValues,
              }),
        );
        dispatchActionEditedTrackingEvent({
          actionType: ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF,
        });
        resetForm({
          values: cleanedValues,
        });
        setActionPanelVisibilityParameters('hidden');
        setAreActionSettingsUnsaved(false);
        dispatch(setCustomizableActionId(''));
        onDismiss();
      }}
      validationSchema={salesforceChatHandoffValidationSchema}
    >
      {props => (
        <Contents
          addingAction={addingAction}
          customizationData={customizationData}
          handoffCustomization={handoffCustomization}
          hasWorkflowConflict={hasWorkflowConflict}
          onDiscard={onDiscard}
          setAreActionSettingsUnsaved={setAreActionSettingsUnsaved}
          {...props}
        />
      )}
    </Formik>
  );
};

export default SalesforceChatHandoffCustomizationPanel;

const Contents = ({
  addingAction,
  customizationData,
  dirty,
  errors,
  handoffCustomization,
  hasWorkflowConflict,
  onDiscard,
  setAreActionSettingsUnsaved,
  setTouched,
  setValues,
  touched,
  values,
}: FormikProps<SalesforceChatHandoffCustomization> & {
  addingAction: CanvasWorkflowBuilderState['addingAction'];
  customizationData: SalesforceChatHandoffCustomization;
  handoffCustomization: SalesforceChatHandoffCustomization;
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}) => {
  const FormObserver: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { dirty } = useFormikContext();

    useEffect(() => {
      setAreActionSettingsUnsaved(dirty);
    }, [dirty]);

    return null;
  };

  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(customizationData),
  );

  useEffect(() => {
    setValues(buildSalesforceChatHandoffInitialFormValue(customizationData));
  }, [customizationData, setValues]);

  const errorMessages = validateZendeskOrSalesforceContextVariables({
    customizationData: values,
    shouldAllowEmptyName: true,
  });

  const emailErrorMessage = isFieldTouchedArr[FieldNameToIdx.EMAIL]
    ? errorMessages[FieldNameToIdx.EMAIL]
    : '';
  const questionErrorMessage = isFieldTouchedArr[FieldNameToIdx.QUESTION]
    ? errorMessages[FieldNameToIdx.QUESTION]
    : '';

  const isPristine = isEqual(
    values,
    pick(Object.keys(values), handoffCustomization),
  );

  return (
    <FormWrapper translate='yes'>
      <FormObserver />
      <Box display='flex' flexDirection='column' height='100%'>
        <SalesforceChatHandoffConfiguration
          customizationData={values}
          errorMessages={errorMessages}
          isFieldTouchedArr={isFieldTouchedArr}
          setCustomizationData={setValues}
          setIsFieldTouchedArr={setIsFieldTouchedArr}
        />
        <SaveContainer hasTopBorder>
          <SecondaryButton
            onClick={() => {
              onDiscard();
            }}
            type='button'
          >
            Cancel
          </SecondaryButton>
          <StyledButton
            disabled={
              (touched.agent_chat_handoff_settings?.button_id &&
                Boolean(errors.agent_chat_handoff_settings?.button_id)) ||
              (touched.agent_chat_handoff_settings?.deployment_id &&
                Boolean(errors.agent_chat_handoff_settings?.deployment_id)) ||
              hasWorkflowConflict ||
              Boolean(emailErrorMessage) ||
              Boolean(questionErrorMessage) ||
              (!addingAction && isPristine && !dirty)
            }
            onClick={() => {
              setIsFieldTouchedArr(isFieldTouchedArr.map(() => true));
              setTouched({
                agent_chat_handoff_settings: {
                  button_id: true,
                  deployment_id: true,
                },
              });

              if (hasAtLeastOneError(errorMessages)) {
                return;
              }
            }}
            type='submit'
          >
            Save
          </StyledButton>
        </SaveContainer>
      </Box>
    </FormWrapper>
  );
};

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FormWrapper = styled(Form)`
  height: 100%;
`;
