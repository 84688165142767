import Article from './article';
import AutomationModal from './AutomationModal';
import DiscoverToast from './DiscoverToast';
import PostAutomationActionModal from './PostAutomationActionModal';

const GlobalDiscover = () => {
  return (
    <>
      <AutomationModal />
      <PostAutomationActionModal />
      <DiscoverToast />
      <Article />
    </>
  );
};

export default GlobalDiscover;
