import { createSlice } from '@reduxjs/toolkit';

import { getAuditEventLogFilters, getAuditEventLogs } from './thunks';
import { AuditSliceInitialState } from './types';
import type { RootState } from 'src/store/rootReducer';

const auditSliceInitialState: AuditSliceInitialState = {
  auditEventLogs: [],
  filterValues: null,
  isLoadingAuditEventsData: false,
  metadata: {
    current_offset: 0,
    last_updated_timestamp: 0,
    more_records_to_fetch: false,
  },
};

const auditSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getAuditEventLogs.fulfilled, (state, action) => {
      if (action.payload.metadata.current_offset === 0) {
        state.auditEventLogs = action.payload.events;
      } else {
        state.auditEventLogs = [
          ...state.auditEventLogs,
          ...action.payload.events,
        ];
      }
      state.metadata = action.payload.metadata;
      state.isLoadingAuditEventsData = false;
    });
    builder.addCase(getAuditEventLogs.pending, state => {
      state.isLoadingAuditEventsData = true;
    });
    builder.addCase(getAuditEventLogs.rejected, state => {
      state.isLoadingAuditEventsData = false;
    });
    builder.addCase(getAuditEventLogFilters.fulfilled, (state, action) => {
      state.filterValues = action.payload;
      state.isLoadingAuditEventsData = false;
    });
    builder.addCase(getAuditEventLogFilters.pending, state => {
      state.isLoadingAuditEventsData = true;
    });
    builder.addCase(getAuditEventLogFilters.rejected, state => {
      state.isLoadingAuditEventsData = false;
    });
  },
  initialState: auditSliceInitialState,
  name: 'audit',
  reducers: {},
});

export const selectAuditEventLogs = (
  state: RootState,
): AuditSliceInitialState['auditEventLogs'] => state.audit.auditEventLogs;

export const selectFilterValues = (
  state: RootState,
): AuditSliceInitialState['filterValues'] => state.audit.filterValues;

export const selectAuditEventLogsMetadata = (
  state: RootState,
): AuditSliceInitialState['metadata'] => state.audit.metadata;

export const selectIsLoadingAuditEventsData = (
  state: RootState,
): AuditSliceInitialState['isLoadingAuditEventsData'] =>
  state.audit.isLoadingAuditEventsData;

export default auditSlice.reducer;
