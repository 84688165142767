import { Formik } from 'formik';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { createModelFields, createModelFormValue } from './constants';
import HelpDesk from './HelpDesk';
import { getTriageModelsPageUrl } from './helpers';
import { CreateModelFormValue } from './types';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import {
  useCreateModelMutation,
  useGetSelfServeTriageModelsQuery,
} from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

const CreateModelForm = () => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useSelfServeEvents({});
  const [mutate, { isLoading }] = useCreateModelMutation();
  const { data: selfServeTriageModels } =
    useGetSelfServeTriageModelsQuery(undefined);

  const existingModelNames = selfServeTriageModels?.map(model => model.name);

  const greyColor = palette.colors.grey[600];
  const lightColor = palette.colors.grey[400];
  const redColor = palette.colors.red[500];
  const navigate = useNavigate();
  const handleSubmit = ({ description, displayName }: CreateModelFormValue) => {
    mutate({ model_description: description, model_name: displayName })
      .unwrap()
      .then(payload => {
        emitTrackingEventCallback(
          TRIAGE_LLM_TRACKING_EVENTS.CREATE_MODEL_SUCCESS,
          {
            model_id: payload.model_id,
            model_name: payload.model_name,
          },
        );
        navigate(getTriageModelsPageUrl(payload.model_id));
      })
      .catch(console.error);
  };

  return (
    <Formik
      initialValues={createModelFormValue}
      onSubmit={handleSubmit}
      validate={values => {
        const errors: Partial<CreateModelFormValue> = {};
        if (
          existingModelNames?.includes(
            values.displayName.toLocaleLowerCase().replace(' ', '-'),
          )
        ) {
          errors.displayName = 'Model name already exists';
        }
        return errors;
      }}
      validateOnChange={true}
    >
      {({ errors, handleSubmit, setFieldValue, values }) => {
        const nameExists = Boolean(
          existingModelNames?.includes(
            values.displayName.toLocaleLowerCase().replace(' ', '-'),
          ),
        );
        return (
          <Form
            onSubmit={event => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <Box display='flex' flexDirection='column' rowGap='32px'>
              <Typography variant='font24'>Create model</Typography>
              {createModelFields.map(item => (
                <Box
                  display='flex'
                  flexDirection='column'
                  key={item.key}
                  rowGap={1}
                >
                  <Typography variant='font16Bold'>{item.label}</Typography>
                  <TextField
                    aria-label={item.label}
                    disabled={isLoading}
                    error={Boolean(errors[item.key])}
                    maxLength={100}
                    multiline={Boolean(item.rows)}
                    onChange={e => {
                      setFieldValue(item.key, e.target.value);
                    }}
                    placeholder={item.placeholder}
                    required
                    rows={item.rows}
                    value={values[item.key]}
                  />
                  {Boolean(item.info) && (
                    <Typography color={lightColor} variant='font12'>
                      {item.info}
                    </Typography>
                  )}
                  {Boolean(errors[item.key]) && (
                    <Typography color={redColor} variant='font12'>
                      {errors[item.key]}
                    </Typography>
                  )}
                </Box>
              ))}
              <Box display='flex' flexDirection='column' rowGap={1}>
                <Typography variant='font16Bold'>
                  Helpdesk Integration
                </Typography>
                <HelpDesk />
              </Box>
              <Typography color={greyColor} variant='font12'>
                After creating the model, configure it to the Helpdesk output
                field in the ‘Configuration’ tab.
              </Typography>
            </Box>
            <Button
              color='primary'
              disabled={isLoading || nameExists}
              type='submit'
              variant='main'
            >
              Create
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 32px;
`;

export default CreateModelForm;
