import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

import {
  IconButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { NewDot } from '../components/TopicRenderer';
import ArticleIcon from 'src/assets/images/article-icon.svg';
import recommendationBadge from 'src/assets/images/recommendation-badge-image.svg';
import DiscoverAutomatedBadge from 'src/components/discover-automated-badge/DiscoverAutomatedBadge';
import DiscoverNewTopicBadge from 'src/components/discover-new-topic-badge';
import { DISCOVER_TEXT } from 'src/components/global-discover/constants';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';

type TopicBadgesProps = {
  onClickArticleButton: (topic: DiscoverTopic) => void;
  topic: DiscoverTopic;
};

const TopicBadges = (props: TopicBadgesProps) => {
  const { palette } = useTheme();

  const {
    generated_article_count,
    is_automated,
    is_new_topic,
    is_recommended,
    relevant_article_count,
  } = props.topic;

  const doesArticleHaveGap = relevant_article_count === 0;

  const isArticleGenerated = Boolean(
    generated_article_count && generated_article_count > 0,
  );

  const renderBadge = () => {
    if (is_automated) {
      return (
        <Tooltip tooltipContent={DISCOVER_TEXT.automatedBadgeTooltip}>
          <DiscoverAutomatedBadge size={20} />
        </Tooltip>
      );
    }

    if (is_recommended) {
      return (
        <Tooltip tooltipContent={DISCOVER_TEXT.recommendedBadgeTooltip}>
          <img src={recommendationBadge} />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <Box alignItems='center' display='flex'>
      <DiscoverNewTopicBadge isNewTopic={is_new_topic} />
      {(is_automated || is_recommended) && (
        <BadgeAlignment>{renderBadge()}</BadgeAlignment>
      )}
      {isArticleGenerated && (
        <IconButton
          aria-label='Navigate to article'
          hoverBackgroundColor={palette.colors.purple[200]}
          onClick={event => {
            event.stopPropagation();

            props.onClickArticleButton(props.topic);
          }}
          variant='ghost'
        >
          <Tooltip
            tooltipContent={
              doesArticleHaveGap
                ? 'This topic doesn’t appear to have any support articles. SupportGPT has generated articles to cover this topic.'
                : `SupportGPT has generated ${generated_article_count} ${
                    generated_article_count > 1 ? 'articles' : 'article'
                  } for this topic.`
            }
          >
            <>
              {isArticleGenerated && doesArticleHaveGap ? (
                <Box height={0} width={0}>
                  <NewDot />
                </Box>
              ) : null}
              {isArticleGenerated ? <img src={ArticleIcon} /> : null}
            </>
          </Tooltip>
        </IconButton>
      )}
    </Box>
  );
};

const BadgeAlignment = styled('span')`
  display: flex;
  margin-left: 8px;
`;
export default TopicBadges;
