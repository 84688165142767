import './AgentLandingPage.scss';

import { useEffect, useState } from 'react';

import LandingPageLayout from 'src/components/reusable-components/landing-page-layout';
import { Helpdesk } from 'src/services/apiInterfaces';
import { getAgentHelpdesk } from 'src/services/apiV1';

export default function AgentLandingPage() {
  const [helpdesk, setHelpdesk] = useState<Helpdesk | null>(null);

  useEffect(() => {
    getAgentHelpdesk().then(setHelpdesk).catch(console.error);
  }, []);

  return (
    <LandingPageLayout>
      <div className='AgentLandingPage-pageContainer'>
        <div className='AgentLandingPage-contentContainer'>
          <div className='AgentLandingPage-contentContainer-inner'>
            <div className='AgentLandingPage-mainTitle'>
              You&apos;re all set!
            </div>
            <p className='AgentLandingPage-subTitle'>
              Get the most out of Agatha Assist by going to your help desk.
            </p>
            {helpdesk && (
              <a
                className='AgentLandingPage-helpdeskButton'
                href={helpdesk.helpdesk_link}
              >
                Go to {helpdesk.helpdesk}
              </a>
            )}
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
}
