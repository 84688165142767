import { useNavigate } from 'react-router';
import { styled } from '@mui/system';
import { Box } from '@mui/system';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import useActionBuilderActions from '../../hooks/useActionBuilderActions';
import DraggableListItem from './DraggableListItem';
import { Routes } from 'src/utils/enums';

export default function ActionList() {
  const { actions } = useActionBuilderActions();
  const navigate = useNavigate();

  return (
    <Box>
      <Box mb='20px'>
        <Typography variant='font16Bold'>Actions</Typography>
      </Box>
      <Box mb='16px'>
        <Button
          fullWidth
          onClick={() => navigate(Routes.ACTION_BUILDER)}
          variant='secondary'
        >
          Create new action in Action Builder
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {actions?.map(action => {
          const titleComponent = (
            <Link
              href={Routes.ACTION_BUILDER_EDIT_V2.replace(
                ':actionId?',
                action.action_id,
              )}
              rel='noreferrer'
              target='_blank'
            >
              <Typography variant='font14'>{action.action_name}</Typography>
            </Link>
          );

          return (
            <div aria-label={action.action_name} key={action.action_id}>
              <DraggableListItem
                componentMetadata={{ action }}
                componentType='action'
                title={titleComponent}
              />
            </div>
          );
        })}
      </Box>
    </Box>
  );
}

export const Link = styled('a')`
  color: ${props => props.theme.palette.colors.black};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
