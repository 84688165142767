import React, { FC, useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';
import { Box } from '@mui/material';
import { IconBrandTelegram } from '@tabler/icons-react';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import downArrowIcon from '../assets/icons/down-arrow.svg';
import intentRoutingIcon from '../assets/icons/intent-routing.svg';
import { textRegularStyle } from 'src/styles/styledMixin';

interface GoToIntentOutlineProps {
  isCustomHandoffIntent?: boolean;
  isOver: boolean;
}

const GoToIntentOutline: FC<
  React.PropsWithChildren<GoToIntentOutlineProps>
> = ({ isCustomHandoffIntent = false, isOver }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Container>
      <Description>
        {isCustomHandoffIntent
          ? 'Handoff with...'
          : 'Continue workflow with...'}
      </Description>
      <ReactSVG src={downArrowIcon} />
      <Outline isOver={isOver} ref={ref}>
        {isCustomHandoffIntent ? (
          <IconBrandTelegram color={theme.palette.colors.grey[700]} size={20} />
        ) : (
          <Icon src={intentRoutingIcon} />
        )}
        <Box width='5px' />
        <Cta>
          {isCustomHandoffIntent ? 'Drop handoff here' : 'Drop intent here'}
        </Cta>
      </Outline>
    </Container>
  );
};

const Container = styled('div')`
  height: 140px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled('div')`
  ${textRegularStyle({
    color: Colors.ui.text.secondary,
    fontSize: '12px',
  })};
`;

const Cta = styled('div')`
  ${textRegularStyle({
    color: Colors.ui.text.secondary,
    fontSize: '14px',
  })};
`;

const Outline = styled('div')<{
  isOver: boolean;
  isSqueezingConditionMode?: boolean;
}>`
  border: 1px dashed ${theme.palette.colors.purple[500]};
  border-radius: 20px;
  height: 40px;
  width: 472px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: ${props =>
    props.isOver
      ? Colors.ui.backgrounds.purple
      : Colors.ui.backgrounds.default};
`;

const Icon = styled(ReactSVG)`
  position: relative;
  top: 2px;
`;

export default GoToIntentOutline;
