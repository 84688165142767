import './footer.scss';

import React from 'react';
import classNames from 'classnames';
interface FooterProps {
  /** Boolean used to determine if we show the initial login logo, or the logged in logo*/

  hasUserLoggedIn: boolean;
}

const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({
  hasUserLoggedIn,
}: FooterProps) => {
  const footerClassnames = classNames({
    'Footer-footerLogo-login': !hasUserLoggedIn,
  });

  return (
    <footer className='Footer--container'>
      <div className={footerClassnames}></div>
    </footer>
  );
};

export default Footer;
