import './index.scss';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import Overlay from '../SettingsBar/Overlay/index';
import RequiredAsterisk from '../SettingsBar/RequiredAsterisk/index';
import { createReport } from 'src/actions/reports/reports';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';

const CreateReportModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [baseReport, setBaseReport] = useState<string>('Tickets');
  const [isDropdownMouseOver, setisDropdownMouseOver] =
    useState<boolean>(false);
  const [reportName, setReportName] = useState<string>('');
  const [reportDescription, setReportDescription] = useState<string>('');
  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const reference: React.RefObject<HTMLDivElement> = useRef(null);
  let isComponentMounted = true;
  const submitButtonClass = classNames({
    'CreateReport-submitButton': reportName.length > 0,
    'CreateReport-submitButton--disabled': reportName.length === 0,
  });

  const clearState = () => {
    setReportName('');
    setReportDescription('');
  };

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        reference,
        ContainerRef,
        setModalVisible,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);

  return (
    <>
      <Overlay displayOverlay={isModalVisible} />
      <div className='CreateReport' ref={reference}>
        <button
          className='CreateReport-btn'
          data-testid='dropdown-button'
          onClick={(): void => {
            setModalVisible(!isModalVisible);
            clearState();
          }}
        >
          Create New Report +
        </button>
        {isModalVisible && (
          <div className='CreateReport-modal' ref={ContainerRef}>
            <label className='CreateReport-modal-title'>Create Report</label>
            <form
              className='CreateReport-container'
              onSubmit={(e: React.FormEvent): void => {
                e.preventDefault();
                if (reportName.length > 0 && reportDescription.length > 0) {
                  setModalVisible(false);
                  dispatch(
                    createReport(reportName, reportDescription, baseReport),
                  );
                }
              }}
            >
              <div className='CreateReport-dropdown'>
                <label
                  className='CreateReport-dropdown-label'
                  htmlFor='base-report'
                >
                  Base Report
                </label>
                <button
                  aria-expanded='false'
                  className='CreateReport-dropdown-button'
                  data-testid='base-report-dropdown-button'
                  id='base-report'
                  onBlur={() => !isDropdownMouseOver && setIsMenuVisible(false)}
                  onClick={(): void => setIsMenuVisible(!isMenuVisible)}
                  onMouseLeave={(): void => setisDropdownMouseOver(false)}
                  type='button'
                >
                  {baseReport}
                  <span className='CreateReport-dropdown-arrow' />
                </button>
                {isMenuVisible && (
                  <div
                    aria-labelledby='CreateReport-dropdownMenu'
                    className='CreateReport-dropdown-Optcontainer'
                    onMouseEnter={(): void => setisDropdownMouseOver(true)}
                  >
                    <button
                      aria-expanded='false'
                      className='CreateReport-dropdown-optionBtn'
                      onClick={(): void => {
                        setBaseReport('Tickets');
                        setIsMenuVisible(false);
                      }}
                      type='button'
                    >
                      Tickets
                    </button>
                    <button
                      className='CreateReport-dropdown-optionBtn'
                      onClick={(): void => {
                        setBaseReport('Agents');
                        setIsMenuVisible(false);
                      }}
                      type='button'
                    >
                      Agents
                    </button>
                  </div>
                )}
              </div>
              <label className='CreateReport-inputLabel' htmlFor='report-name'>
                NAME
                <RequiredAsterisk />
              </label>
              <input
                className='CreateReport-nameInput'
                data-testid='test-input'
                id='report-name'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setReportName(event.target.value)
                }
                placeholder='Untitled Report'
                required
                type='text'
                value={reportName}
              />
              <label
                className='CreateReport-textAreaLabel'
                htmlFor='report-name'
              >
                DESCRIPTION
                <RequiredAsterisk />
              </label>
              <textarea
                className='CreateReport-textAreaInput'
                data-testid='textarea'
                maxLength={1000}
                name='description'
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setReportDescription(event.target.value)
                }
                placeholder='Add description'
                required
                value={reportDescription}
              />
              <div className='CreateReport-bttnCont'>
                <button
                  className='CreateReport-cancelButton'
                  onClick={(): void => setModalVisible(false)}
                  type='button'
                >
                  Cancel
                </button>
                <button
                  className={submitButtonClass}
                  data-testid='submit-button'
                  type='submit'
                >
                  Create Report
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateReportModal;
