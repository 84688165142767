import { HelpdeskOption } from './types';

export const signUpText = {
  email: 'Work Email',
  firstName: 'First Name',
  genericErrorMessage: 'Something went wrong. Please try again.',
  helpdesk: 'Helpdesk',
  lastName: 'Last Name',
  orgName: 'Company Name',
  submit: 'Sign Up',
  successSubtitle:
    'Check your email for further details on how to get started.',
  successTitle: "You're all set!",
  title: 'Sign Up for Forethought',
};

export const helpdeskOptions: HelpdeskOption[] = [
  {
    label: 'Zendesk',
    value: 'zendesk',
  },
  {
    label: 'Freshdesk',
    value: 'freshdesk',
  },
  {
    label: 'Kustomer',
    value: 'kustomer',
  },
  {
    label: 'Salesforce',
    value: 'salesforce',
  },
  {
    label: 'ServiceNow',
    value: 'servicenow',
  },
  {
    label: 'Odo',
    value: 'odo',
  },
  {
    label: 'Jira',
    value: 'jira',
  },
  {
    label: 'Intercom',
    value: 'intercom',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
