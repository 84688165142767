export const emailTableVal = [
  'field_value',
  'responses',
  'resolutions',
  'reopens',
  'reopen_rate',
  'csat',
];

export const widgetTableVal = [
  'field_value',
  'interactions',
  'resolutions',
  'ticket_creations',
  'resolve_rate',
];

export const apiTableVal = [
  'field_value',
  'interactions',
  'resolutions',
  'ticket_creations',
  'resolve_rate',
];

export const workflowWidgetTableVal = [
  'field_value',
  'conversations',
  'resolutions',
  'handoffs',
  'efficiency',
  'self_serve_rate',
];
