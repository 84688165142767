import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import warningIcon from '../../assets/images/circular-warning-icon.svg';
import { useGetBuilderQueryParams } from './hooks';
import {
  selectInvalidGoToSteps,
  selectUndefinedContextVariablesInStep,
  selectUnsupportedSteps,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

const useUnsupportedStepsMessage = (stepId: string, steps?: string[]) => {
  const unsupportedSteps = useSelector(selectUnsupportedSteps);
  const { view } = useGetBuilderQueryParams();

  const widgetProduct =
    view === 'email'
      ? 'interactive email'
      : view === 'api'
      ? 'solve api'
      : 'classic widget';
  const stepsIncludesUnsupportedStep = unsupportedSteps.some(
    unsupportedStep =>
      steps?.includes(unsupportedStep) ?? stepId === unsupportedStep,
  );

  return stepsIncludesUnsupportedStep
    ? `This step is not supported for ${widgetProduct}.`
    : '';
};

const useIsWorkflowNotAvailableMessage = (stepId: string) => {
  const invalidGoToSteps = useSelector(selectInvalidGoToSteps);

  return invalidGoToSteps.includes(stepId)
    ? 'This step is referencing a workflow that is not enabled'
    : '';
};

const UndefinedContextVariablesTooltip = ({
  buildMessage,
  contextVariables = [],
  stepId,
  steps,
}: {
  buildMessage?: (cvNames: string) => string;
  contextVariables?: ContextVariable[];
  stepId: string;
  steps?: string[];
}): JSX.Element | null => {
  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );
  const unsupportedStepsMessage = useUnsupportedStepsMessage(stepId, steps);
  const workflowNotAvailableMessage = useIsWorkflowNotAvailableMessage(stepId);

  const doesContainDynamicListCv = useMemo(() => {
    const allUndefinedCvs = contextVariables.filter(cv =>
      undefinedContextVariablesInStep[stepId]?.includes(cv.context_variable_id),
    );

    return Boolean(
      allUndefinedCvs.find(cv => cv.context_variable_type === 'DYNAMIC_LIST'),
    );
  }, [contextVariables, stepId, undefinedContextVariablesInStep]);

  const getUndefinedContextVariableNames = () => {
    let undefinedContextVariableIds;
    if (steps && steps.length > 1) {
      /**
       * (Action with multiple step)
       * Get all undefined cv ids in these steps
       */
      const allUndefinedVariables: Set<string> = new Set();
      steps.forEach(stepId => {
        if (stepId in undefinedContextVariablesInStep) {
          undefinedContextVariablesInStep[stepId].forEach(cvId =>
            allUndefinedVariables.add(cvId),
          );
        }
      });
      undefinedContextVariableIds = Array.from(allUndefinedVariables);
    } else {
      undefinedContextVariableIds =
        undefinedContextVariablesInStep[stepId] || [];
    }
    return undefinedContextVariableIds
      .map(
        id =>
          contextVariables.find(
            contextVariable =>
              contextVariable.context_variable_id === id ||
              contextVariable.context_variable_id === id.slice(2, -2),
          )?.context_variable_name,
      )
      .map(name => `$${name}`);
  };

  const undefinedContextVariableNames =
    getUndefinedContextVariableNames().join(', ');
  const message =
    buildMessage?.(undefinedContextVariableNames) ??
    `Collect ${undefinedContextVariableNames} via a form step${
      doesContainDynamicListCv ? ' dynamic card step,' : ''
    } or set it as pre-defined context`;

  if (unsupportedStepsMessage) {
    return (
      <TooltipWrapper>
        <Tooltip tooltipContent={unsupportedStepsMessage}>
          <img alt='Is an unusable step' src={warningIcon} />
        </Tooltip>
      </TooltipWrapper>
    );
  }

  if (workflowNotAvailableMessage) {
    return (
      <TooltipWrapper>
        <Tooltip tooltipContent={workflowNotAvailableMessage}>
          <img alt='Is a disabled workflow' src={warningIcon} />
        </Tooltip>
      </TooltipWrapper>
    );
  }

  return undefinedContextVariableNames.length > 0 ? (
    <TooltipWrapper>
      <Tooltip tooltipContent={message}>
        <img alt='Has undefined context variables' src={warningIcon} />
      </Tooltip>
    </TooltipWrapper>
  ) : null;
};

export default UndefinedContextVariablesTooltip;

const TooltipWrapper = styled('div')`
  margin-left: 2px;
  margin-right: -2px;
  height: 20px;
`;
