import React, { FC } from 'react';
import { styled } from '@mui/material';

import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import AutoCreateTicketRadioButton from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/AutoCreateTicketRadioButton';
import ConfirmationMessage from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/ConfirmationMessage';
import TicketCustomFields from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/custom-fields';
import CustomMessage from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/CustomMessage';
import CustomMessageRadioButton from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/CustomMessageRadioButton';
import HandoffFormQuestionMessage from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/HandoffFormQuestionMessage';
import TicketTitle from 'src/pages/workflow-builder-edit/handoff-configuration/salesforce/ticket-creation/TicketTitle';
import {
  SectionContainer,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { SalesforceHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface TicketCreationProps {
  customizationData: SalesforceHandoffCustomization;
}

const TicketCreation: FC<React.PropsWithChildren<TicketCreationProps>> = ({
  customizationData,
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  return (
    <Wrapper>
      <AutoCreateTicketRadioButton />
      <Spacer height='32px' />
      {!!customizationData.ticket_creation_settings?.create_ticket && (
        <SectionContainer>
          <HandoffFormQuestionMessage />
          <Spacer height='28px' />
          <ConfirmationMessage />
          <Spacer height='28px' />
          <TicketTitle contextVariables={contextVariables} />
          <Spacer height='28px' />
          <TicketCustomFields
            contextVariables={contextVariables}
            customizationData={customizationData}
          />
          <Spacer height='60px' />
        </SectionContainer>
      )}
      <CustomMessageRadioButton />
      <Spacer height='20px' />
      {!customizationData.ticket_creation_settings?.create_ticket && (
        <SectionContainer>
          <CustomMessage customizationData={customizationData} />
        </SectionContainer>
      )}
      <Spacer height='100px' />
    </Wrapper>
  );
};

export default TicketCreation;

const Wrapper = styled('div')`
  padding-left: 30px;
`;
