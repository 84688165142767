import * as d3 from 'd3';

import { theme } from '@forethought-technologies/forethought-elements';
import {
  fiveValuesTooltip,
  handleTooltipMouseOut,
  height,
  margin,
  oneValueTooltip,
  responsivefy,
  threeValuesTooltip,
  width,
} from './d3Config';
import { AnswersReports, ChartClassNames } from './enums';
import { formatDate } from 'src/utils/analyticsUtils';
import {
  formatNToPercentage,
  roundNumToPercentage,
} from 'src/utils/formatToPercentage';

export default class D3Util {
  data: any;
  y: string;
  x: string;
  mean: any;
  timeframe: any;
  xScale: any;
  yScale: any;

  constructor() {
    this.x = '';
    this.y = '';
    this.data = [];
    this.mean = '';
    this.timeframe = '';
  }
  initializeChart(
    data: [{}],
    x: string,
    y: string,
    chart: string,
    noResults: boolean,
    mean: any,
    timeframe: string,
  ) {
    d3.selectAll('svg > *').remove();
    this.data = data;
    this.y = y;
    this.x = x;
    this.mean = mean;
    this.timeframe = timeframe;

    this.xScale = d3
      .scaleTime()
      .domain(
        d3.extent(data, (d: any) => {
          return new Date(d[x] * 1000);
        }),
      )
      .range([0, width]);
    this.yScale = d3
      .scaleLinear()
      .domain([
        Math.max(
          ...data.map((d: any) => {
            return d[this.y];
          }),
        ),
        0,
      ])
      .range([0, height]);

    this.drawAxes(chart);
    this.drawAreaChart(chart, noResults, mean, y);
  }

  make_x_gridlines() {
    return d3.axisLeft(this.yScale);
  }

  //selects line chart, positions svg, makes grid, creates axis
  drawAxes(chart: string) {
    const yAxis = d3.axisLeft(this.yScale);
    const xAxis = d3.axisBottom(this.xScale);

    const svg = d3
      .select(chart)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .call(responsivefy)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    svg
      .append('g')
      .attr('class', 'grid')
      .call(this.make_x_gridlines().tickSize(-width).tickFormat('').ticks(5));

    svg
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis);

    svg.append('g').attr('class', 'y axis').call(yAxis);
  }

  scaleXData = (point: any) => {
    return this.xScale(new Date(point[this.x] * 1000));
  };

  scaleYData = (point: any) => {
    return this.yScale(point[this.y]);
  };

  drawAreaChart(chart: string, noResults: boolean, mean: string, y: string) {
    let areaChartClass;
    let dataLineClass;

    let dotClass;
    if (noResults) {
      dotClass = 'dot-disabled';
    } else {
      dotClass = 'dot';
    }

    if (noResults) {
      areaChartClass = 'area-chart-disabled';
      dataLineClass = 'data-line-disabled';
    } else {
      dataLineClass = 'data-line';
      areaChartClass = 'area-chart';
    }

    const svg = d3.select(chart);

    const area = d3.area().x(this.scaleXData).y0(height).y1(this.scaleYData);

    const line = d3.line().x(this.scaleXData).y(this.scaleYData);

    // builds area
    svg
      .append('path')
      .datum(this.data)
      .attr('d', area)
      .attr('class', areaChartClass)
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    //builds line
    svg
      .append('path')
      .datum(this.data)
      .attr('d', line)
      .attr('class', dataLineClass)
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    //adds data point to graph
    svg
      .selectAll('.dot')
      .data(this.data)
      .enter()
      .append('circle') // Uses the enter().append() method
      .attr('class', dotClass) // Assign a class for styling
      .attr('transform', `translate(${margin.left}, ${margin.top})`)
      .attr('cx', (d: any) => this.xScale(new Date(d[this.x] * 1000)))
      .attr('cy', (d: any) => this.yScale(d[this.y]))
      .attr('r', 4)
      .on('mouseover', (d: any) => {
        if (!noResults && chart === '.predictions-line-chart') {
          oneValueTooltip(
            y,
            formatDate(this.timeframe, d.timestamp * 1000),
            `${d[this.y]}`,
            chart,
          )
            .transition()
            .duration(200);
        } else if (!noResults && chart === '.search-line-chart') {
          threeValuesTooltip(
            y,
            formatDate(this.timeframe, d.timestamp * 1000),
            'open count',
            d.open_count,
            'search frequency',
            d.frequency,
            'open rate',
            formatNToPercentage(d.open_rate),
            chart,
          )
            .transition()
            .duration(200);
        } else if (!noResults && chart === '.solve-line-chart') {
          fiveValuesTooltip(
            y,
            formatDate(this.timeframe, d.timestamp),
            'resolutions',
            d.resolutions,
            'atempts',
            d.attempts,
            'pending',
            d.pending,
            chart,
            'aqua-tooltip-heading',
            'accuracy',
            roundNumToPercentage(d.accuracy),
            'deflect rate',
            roundNumToPercentage(d.deflect_rate),
          )
            .transition()
            .duration(200);
        } else if (!noResults && chart === ChartClassNames.ACTIVE_AGENTS) {
          threeValuesTooltip(
            AnswersReports.ACTIVE_AGENTS,
            formatDate(this.timeframe, d.timestamp * 1000),
            'active agents',
            d.active_agents,
            'total agents',
            d.total_agents,
            'activation',
            formatNToPercentage(d.engaged_ratio),
            chart,
            'aqua-tooltip-heading',
          )
            .transition()
            .duration(200);
        } else if (!noResults && chart === ChartClassNames.ENGAGED_TICKETS) {
          threeValuesTooltip(
            AnswersReports.ENGAGED_TICKETS,
            formatDate(this.timeframe, d.timestamp * 1000),
            'engaged tickets',
            d.engaged_tickets,
            'total tickets',
            d.total_tickets,
            'engagement',
            formatNToPercentage(d.engaged_ratio),
            chart,
            'aqua-tooltip-heading',
          )
            .transition()
            .duration(200);
        }
      })
      .on('mouseout', handleTooltipMouseOut);

    //only draw lines if there's results
    if (!noResults) {
      //calculates mean line for chart
      if (mean) {
        const avgline = d3
          .line()
          .x((point: any) => {
            return this.xScale(new Date(point[this.x] * 1000));
          })
          .y(() => {
            return this.yScale(this.mean);
          });

        //draws path for mean/avg line
        svg
          .append('path')
          .attr('transform', `translate(56, ${margin.top})`)
          .datum(this.data)
          .attr('d', avgline)
          .attr('class', 'avg-line')
          .attr('id', 'avg-line');

        //container for mean line and label
        const g = svg.append('g').attr('class', 'avg-container');

        //creates rectangle/background behind text
        const rect = g
          .append('rect')
          .style('fill', theme.palette.colors.slate[700])
          .style('height', '44px')
          .style('width', '50px')
          .attr(
            'x',
            (document as any).querySelector('#avg-line').getBBox().x - 5,
          )
          .attr(
            'y',
            (document as any).querySelector('#avg-line').getBBox().y - 5,
          )
          .attr('rx', '4')
          //inverse to other  extreme of the chart
          .attr('transform', 'translate(24, 30)');
        // Add a text label.
        const text = g
          .append('text')
          //inverse to other  extreme of the chart
          .attr('transform', 'translate(-30, -4)');

        //text that goes in label
        text
          .append('textPath')
          .attr('fill', theme.palette.colors.white)
          .attr('class', 'label-text')
          .attr('xlink:href', '#avg-line')
          .style('font-size', '12px')
          .text(`${mean} `);

        const text2 = g
          .append('text')
          //inverse to other  extreme of the chart
          .attr('transform', 'translate(-30, 12)');

        text2
          .append('textPath')
          .attr('fill', theme.palette.colors.white)
          .attr('xlink:href', '#avg-line')
          .style('color', theme.palette.colors.white)
          .style('font-size', '9px')
          .html('Average');

        const textWidth = (document as any)
          .querySelector('.label-text')
          .getComputedTextLength();
        const widthValue = 70 - textWidth;
        const width = widthValue + textWidth;
        //give rectangle width of text
        rect.attr('width', width);
      }
    }
  }

  //resets chart and draws new chart
  redrawChart(
    data: [{}],
    x: string,
    y: string,
    chart: string,
    noResults: boolean,
    mean: any,
    timeframe: string,
  ) {
    d3.selectAll('svg > *').remove();
    this.initializeChart(data, x, y, chart, noResults, mean, timeframe);
  }
}
