import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import CreateModel from './CreateModel';
import TriageModelCard from './TriageModelCard';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import TriageLabelMappingDrawer from 'src/components/triage-label-mapping-drawer';
import { useEmitTrackingEvent } from 'src/hooks/hooks';
import { useGetSelfServeTriageModelsQuery } from 'src/services/triage/triageApi';

const TriageModelsOverviewPage: React.FC = () => {
  const { palette } = useTheme();
  const {
    data: selfServeTriageModels,
    isFetching,
    isLoading,
  } = useGetSelfServeTriageModelsQuery(undefined, {});
  const loadingData = !selfServeTriageModels && (isLoading || isFetching);
  const doneLoadingSelfServeTriageModels =
    selfServeTriageModels && !loadingData;
  const showLabelMappingDrawer =
    selfServeTriageModels && selfServeTriageModels.length > 0;

  useEmitTrackingEvent('triage-viewed-models-list-page', {
    scope: 'self-serve',
  });

  return (
    <>
      <NavbarV2>
        {showLabelMappingDrawer && (
          <Box
            alignItems='center'
            display='flex'
            justifyContent='end'
            mr='6px'
            width='100%'
          >
            <TriageLabelMappingDrawer />
          </Box>
        )}
      </NavbarV2>

      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        p={5}
        rowGap={4}
        sx={{ backgroundColor: palette.colors.white }}
      >
        <CreateModel />
        <GridContainer>
          {loadingData
            ? [1, 2, 3, 4].map(id => <Skeleton height='224px' key={id} />)
            : null}
          {doneLoadingSelfServeTriageModels
            ? selfServeTriageModels.map(triageModel => (
                <TriageModelCard
                  key={triageModel.name}
                  triageModel={triageModel}
                />
              ))
            : null}
        </GridContainer>
      </Box>
    </>
  );
};

const GridContainer = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 24px;
`;

export default TriageModelsOverviewPage;
