import { styled } from '@mui/material';

import squeezeStepButtonSrc from 'src/assets/images/squeeze-step-bttn.svg';

export default function DroppableLine({ visible }: { visible: boolean }) {
  return (
    <RelativePositioning>
      <Line visible={visible}>
        <Icon src={squeezeStepButtonSrc} />
      </Line>
    </RelativePositioning>
  );
}

// stay in the same spot but don't take up space
const RelativePositioning = styled('div')`
  pointer-events: none; // allow drop events to pass through
  position: relative;
`;

const Line = styled('div')<{ visible: boolean }>`
  background-color: ${props => props.theme.palette.colors.purple[500]};
  height: 2px;
  opacity: ${props => (props.visible ? 1 : 0)};
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const Icon = styled('img')`
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  height: 24px;
  width: 24px;
`;
