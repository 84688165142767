import { ContextVariableListItems } from 'src/services/apiInterfaces';

/**
 * Formats message for react-mentions received from backend in the case that there are Context Variables to parse.
 * @param {Array<ContextVariableListItems>} contextVariablesList - List of context variables.
 * @param {string} message - Original message.
 * @returns Message parsed to display the Context Variables as required for react-mentions.
 */
const formatMessageFromBackendForReactMentions = (
  contextVariablesList: Array<ContextVariableListItems>,
  message: string,
): string => {
  let newMessage = message;
  contextVariablesList.forEach((cv: ContextVariableListItems) => {
    if (message?.includes(cv.id)) {
      const formatedMessage = newMessage.replaceAll(
        `{{${cv.id}}}`,
        `{{${cv.displayName}}}`,
      );
      newMessage = formatedMessage;
    }
  });
  return newMessage;
};

/**
 * Formats react-mentions message before posting to backend in the case that there are Context Variables to parse.
 * @param {Array<ContextVariableListItems>} contextVariablesList - List of context variables.
 * @param {string} message - Original message.
 * @returns Message parsed  as required for the backend.
 */
const formatReactMentionsMessageToSendToBackend = (
  contextVariablesList: Array<ContextVariableListItems>,
  message: string,
): string => {
  let newMessage = message;
  contextVariablesList.forEach((cv: ContextVariableListItems) => {
    if (message.includes(`{{${cv.displayName}}}`)) {
      const formatedMessage = newMessage.replaceAll(
        `{{${cv.displayName}}}`,
        `{{${cv.id}}}`,
      );
      newMessage = formatedMessage;
    }
  });
  return newMessage;
};

export {
  formatMessageFromBackendForReactMentions,
  formatReactMentionsMessageToSendToBackend,
};
