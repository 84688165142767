import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import { IconEyeOff } from '@tabler/icons-react';

import {
  IconButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import DiscoverMetricTooltip from '../../../discover-tooltip/DiscoverMetricTooltip';
import ArticleIcon from 'src/assets/images/article-icon.svg';
import recommendationBadge from 'src/assets/images/recommendation-badge-image.svg';
import DiscoverAutomatedBadge from 'src/components/discover-automated-badge/DiscoverAutomatedBadge';
import DiscoverNewTopicBadge from 'src/components/discover-new-topic-badge';
import { DISCOVER_TEXT } from 'src/components/global-discover/constants';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import {
  deriveTopicName,
  overrideDiscoverSearchParams,
  replaceIdInRoute,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

export const TopicRendererComponent = ({
  doesArticleHaveGap,
  generatedArticleCount,
  isAutomated,
  isBookmarkedTable = false,
  isHidden,
  isNewTopic,
  isRecommended,
  isSummaryRow,
  name,
  percentageOfTicketVolume,
  searchText,
  topicDisplayName,
  topicId,
}: {
  doesArticleHaveGap: boolean;
  generatedArticleCount?: number;
  isAutomated: boolean;
  isBookmarkedTable?: boolean;
  isHidden: boolean;
  isNewTopic: boolean;
  isRecommended: boolean;
  isSummaryRow: boolean;
  name: string;
  percentageOfTicketVolume: string;
  searchText: string;
  topicDisplayName?: string | null;
  topicId?: string;
}) => {
  const { palette } = useTheme();
  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const navigate = useNavigate();

  const isArticleGenerated = Boolean(
    generatedArticleCount && generatedArticleCount > 0,
  );

  const topicName = deriveTopicName(topicDisplayName, name);
  if (isSummaryRow) {
    return (
      <>
        {topicName}
        <Box
          alignItems='center'
          color={palette.colors.grey[700]}
          display='flex'
          ml={0.5}
        >
          <DiscoverMetricTooltip
            dynamicContent={`All tickets after being filtered. ${percentageOfTicketVolume} of filtered tickets were categorized into topics.`}
          />
        </Box>
      </>
    );
  }

  const renderName = () => {
    if (!searchText.trim()) {
      return topicName;
    }

    const start = topicName.toLowerCase().indexOf(searchText);
    const stop = start + searchText.length;

    if (start === -1) {
      return topicName;
    }

    return topicName.split('').map((char, idx) => (
      <span
        key={idx}
        style={{
          backgroundColor:
            idx >= start && idx < stop
              ? palette.colors.blue[200]
              : 'transparent',
        }}
      >
        {char}
      </span>
    ));
  };

  const renderBadge = () => {
    if (isAutomated) {
      return (
        <Tooltip tooltipContent={DISCOVER_TEXT.automatedBadgeTooltip}>
          <DiscoverAutomatedBadge size={20} />
        </Tooltip>
      );
    }

    if (isRecommended) {
      return (
        <Tooltip tooltipContent={DISCOVER_TEXT.recommendedBadgeTooltip}>
          <img src={recommendationBadge} />
        </Tooltip>
      );
    }
    return null;
  };

  const onClickArticleButton: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.stopPropagation();

    navigate(
      overrideDiscoverSearchParams(
        replaceIdInRoute(linkWithSearch, topicId ?? ''),
        {
          name: topicName,
          source: 'all-topics',
          tab: 'Generated articles',
        },
      ),
    );
  };

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      width='100%'
    >
      <Box alignItems='center' display='flex' minWidth='0'>
        {isHidden && (
          <Box alignItems='center' display='flex' mr={1.5}>
            <IconEyeOff color={palette.colors.grey[600]} size={15} />
          </Box>
        )}
        <Box
          sx={[
            {
              color: isHidden ? palette.colors.grey[600] : palette.colors.black,
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
            isBookmarkedTable && { maxWidth: '210px' },
          ]}
        >
          {renderName()}
        </Box>
        <DiscoverNewTopicBadge isNewTopic={isNewTopic} />
        {(isAutomated || isRecommended) && (
          <BadgeAlignment>{renderBadge()}</BadgeAlignment>
        )}
      </Box>
      {isArticleGenerated && (
        <IconButton
          aria-label='Navigate to article'
          hoverBackgroundColor={palette.colors.purple[200]}
          onClick={onClickArticleButton}
          variant='ghost'
        >
          <Tooltip
            tooltipContent={
              doesArticleHaveGap
                ? 'This topic doesn’t appear to have any support articles. SupportGPT has generated articles to cover this topic.'
                : `SupportGPT has generated ${generatedArticleCount} articles for this topic.`
            }
          >
            <>
              {isArticleGenerated && doesArticleHaveGap ? (
                <Box height={0} width={0}>
                  <NewDot />
                </Box>
              ) : null}
              {isArticleGenerated ? <img src={ArticleIcon} /> : null}
            </>
          </Tooltip>
        </IconButton>
      )}
    </Box>
  );
};

export const NewDot = styled('div')`
  height: 9px;
  width: 9px;
  border-radius: 100px;
  position: relative;
  top: -5px;
  right: -12px;
  background: ${props => props.theme.palette.colors.yellow[500]};
  z-index: 100;
  border: 2px solid ${props => props.theme.palette.colors.white};
`;

const BadgeAlignment = styled('span')`
  display: flex;
  margin-left: 8px;
`;
