import React, { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ArticleList from './ArticleList';
import DescriptionText from './DescriptionText';
import KnowledgeGapItemContainer from './KnowledgeGapItemContainer';
import RelatedTopics from './RelatedTopics';
import ArticleIcon from 'src/assets/images/article-icon.svg';
import { HEADLINE_REGEX } from 'src/components/global-discover/constants';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { formatNumberWithCommas } from 'src/pages/workflow-builder/intent-workflows-table/utils';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';
import { setGlobalDiscoverArticleOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  overrideDiscoverSearchParams,
  replaceIdInRoute,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

interface KnowledgeGapArticleCardProps {
  article: KnowledgeGapArticleRecommendation;
  onMouseEnter: (topicName: string) => void;
  style: CSSProperties;
}

const KnowledgeGapArticleCard = ({
  article,
  onMouseEnter,
  style,
}: KnowledgeGapArticleCardProps) => {
  const {
    article_id,
    associated_topic_names,
    generated_body,
    generated_title,
    num_of_tickets_in_article,
    relevant_articles,
    topic_id,
    topic_name,
  } = article;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );
  const topicNames = [topic_name].concat(associated_topic_names).join(',');
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const handleTopicClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    emitTrackingEventCallback('discover-clicked-on-topic', {
      page: 'Knowledge Gap',
      topic: topic_name,
    });

    navigate(
      overrideDiscoverSearchParams(replaceIdInRoute(linkWithSearch, topic_id), {
        name: topic_name,
        source: 'knowledge gap',
      }),
    );
  };

  const generatedTitle = generated_title.replace(HEADLINE_REGEX, '');

  return (
    <KnowledgeGapItemContainer
      onClick={() => {
        dispatch(
          setGlobalDiscoverArticleOptions({
            selectedArticleId: article_id,
          }),
        );
      }}
      onMouseEnter={() => onMouseEnter(topic_name)}
      style={style}
    >
      <Box
        columnGap={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            mb: '2px',
            pr: '14px',
            whiteSpace: 'normal',
          }}
        >
          <img src={ArticleIcon} style={{ marginRight: '8px' }} />
          <Tooltip tooltipContent={generatedTitle}>
            <Typography noWrap variant='font16Bold'>
              {generatedTitle}
            </Typography>
          </Tooltip>
        </Box>
        <DescriptionText shouldHaveMarginTop>{generated_body}</DescriptionText>
      </Box>

      <RelatedTopics onTopicClick={handleTopicClick} topicNames={topicNames} />

      <ArticleList articles={relevant_articles} />

      <Typography noWrap variant='font16Bold'>
        {formatNumberWithCommas(num_of_tickets_in_article)}
      </Typography>
    </KnowledgeGapItemContainer>
  );
};

export default KnowledgeGapArticleCard;
