import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Button } from '@forethought-technologies/forethought-elements';
import { selectEmailWorkflowState } from 'src/slices/email-workflow/emailWorkflowSlice';

interface JourneyMapNavBarProps {
  onTestJourneyClick: (isVisible: boolean) => void;
}

export default function JourneyMapNavBar({
  onTestJourneyClick,
}: JourneyMapNavBarProps) {
  const { validationErrors } = useSelector(selectEmailWorkflowState);
  const { stepErrors, workflowErrors } = validationErrors;
  const isTestJourneyButtonDisabled =
    !!workflowErrors.length || !!Object.keys(stepErrors).length;

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='flex-end'
      mr={4}
      mt={2}
    >
      <Button
        disabled={isTestJourneyButtonDisabled}
        onClick={() => onTestJourneyClick(true)}
        variant='main'
      >
        Send Test Email
      </Button>
    </Box>
  );
}
