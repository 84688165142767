import React, { useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CanvasWorkflowBuilderState,
  setMode,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import { ForethoughtInputProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import {
  selectCanvasWorkflowBuilder,
  selectEditingStepId,
  selectEditingStepType,
  selectUndefinedContextVariablesInStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  ButtonsStepFields,
  FormStepFields,
  TextMessageFields,
} from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';

const ForethoughtInput: React.FC<
  React.PropsWithChildren<ForethoughtInputProps>
> = ({ editorRef }) => {
  const dispatch = useDispatch();

  // Selectors
  const editingStepId = useSelector(selectEditingStepId);
  const editingStepType = useSelector(selectEditingStepType);
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );

  const { contextVariables } = useGetContextVariables();

  const undefinedContextVariableIds = useMemo(
    () => undefinedContextVariablesInStep[editingStepId] || [],
    [editingStepId, undefinedContextVariablesInStep],
  );

  const setText = (newRichTextEditorValue: string) => {
    editorRef.current?.setContent(newRichTextEditorValue);
  };

  // Triggers when editing a step starts
  useLayoutEffect(() => {
    if (editingStepId === '') {
      return;
    }

    if (editingStepType === StepTypes.TEXT_MESSAGE) {
      editorRef.current?.focus('end');
      const stepFields = canvasData.steps[editingStepId]
        .step_fields as TextMessageFields;
      setText(stepFields.message);
      dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
      editorRef.current?.focus('end');
    } else if (editingStepType === StepTypes.BUTTONS) {
      const stepFields = canvasData.steps[editingStepId]
        .step_fields as ButtonsStepFields;
      setText(stepFields.prompt);
      dispatch(setMode({ contextVariables, mode: CanvasModes.BUTTON }));
    } else if (editingStepType === StepTypes.FORM) {
      const stepFields = canvasData.steps[editingStepId]
        .step_fields as FormStepFields;
      setText(stepFields.prompt);
      dispatch(setMode({ contextVariables, mode: CanvasModes.FORM }));
    } else if (editingStepType === StepTypes.IMAGE) {
      dispatch(setMode({ contextVariables, mode: CanvasModes.IMAGE }));
    } else if (editingStepType === StepTypes.CSAT_TRIGGER_POINT) {
      dispatch(
        setMode({ contextVariables, mode: CanvasModes.CSAT_TRIGGER_POINT }),
      );
    } else if (editingStepType === StepTypes.ATTACHMENT_ANALYSIS_UPLOAD) {
      dispatch(
        setMode({
          contextVariables,
          mode: CanvasModes.ATTACHMENT_ANALYSIS_UPLOAD,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvasData.steps,
    editingStepId,
    editingStepType,
    undefinedContextVariableIds,
  ]);

  return null;
};

export default ForethoughtInput;
