/**
 * The backend api always appends '- Others' to some
 * topics so we don't need to handle other
 * cases such as case insensitive and
 * white spaces.
 *
 * reference to the backend:
 *https://github.com/Forethought-Technologies/mlds/blob/788984396406b578ee3e00c6f3b689a01f41d08f/scripts/discover/update_datalake.py#L949
 */
export const isOther = (topicName: string) => topicName.endsWith('- Others');
