import { useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';

export const WorkflowContextTooltip = () => {
  const { palette } = useTheme();

  return (
    <Tooltip tooltipContent='Pre-defined variables are for sharing data, not data validation. They can be passed through code snippets to share data. Use them carefully to maintain code quality.'>
      <IconInfoCircle color={palette.colors.grey[500]} size={14} />
    </Tooltip>
  );
};
