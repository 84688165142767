export function hasErrorMessage(
  maybeError: unknown,
): maybeError is { message: string } {
  return (
    typeof maybeError === 'object' &&
    maybeError !== null &&
    'message' in maybeError &&
    typeof maybeError.message === 'string'
  );
}

export function hasErrorStatus(
  maybeError: unknown,
): maybeError is { status: number } {
  return (
    typeof maybeError === 'object' &&
    maybeError !== null &&
    'status' in maybeError &&
    typeof maybeError.status === 'number'
  );
}
