import React from 'react';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import {
  NonIdealState,
  theme,
} from '@forethought-technologies/forethought-elements';
import TranslateIcon from 'src/assets/images/translate.svg';

const NoLanguageSelected: React.FC<
  React.PropsWithChildren<Record<string, never>>
> = () => {
  return (
    <NonIdealState
      icon={<TranslateDisplay src={TranslateIcon} />}
      title='No supported languages yet'
    ></NonIdealState>
  );
};

export default NoLanguageSelected;

const TranslateDisplay = styled(ReactSVG)<{ isDisabled?: boolean }>`
  [stroke] {
    stroke: ${theme.palette.colors.purple[300]};
  }
  [width] {
    width: 36px;
  }
  [height] {
    height: 36px;
  }
`;
