import { Box, useTheme } from '@mui/material';
import { IconMailX } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { setEmailPreviewStage } from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';

export const EmailTemplateError = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const restartEmailPreview = () => {
    dispatch(setEmailPreviewStage('email-form'));
  };

  return (
    <Box
      alignSelf='center'
      bgcolor='white'
      border='1px solid rgba(0, 0, 0, 0.10)'
      boxShadow='0px 12px 48px 0px rgba(0, 0, 0, 0.10)'
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='space-between'
      maxWidth='700px'
      padding='30px 80px 56px 80px'
    >
      <Typography variant='font24'>No emails sent out</Typography>
      <Box
        alignItems='center'
        alignSelf='center'
        display='flex'
        flexDirection='column'
        width='100%'
      >
        <IconMailX color={theme.palette.colors.slate[300]} size={140} />
        <Box alignItems='center' display='flex' flexDirection='column' pt={3}>
          <Typography variant='font16'>
            No response detected. Please go back to the Workflow to add a
            response email.
          </Typography>
        </Box>
      </Box>

      <Button
        fullWidth
        onClick={restartEmailPreview}
        size='large'
        variant='main'
      >
        Restart preview
      </Button>
    </Box>
  );
};
