import './FiltersTooltip.scss';

import React from 'react';
import classNames from 'classnames';

import ucFirstLetter from 'src/utils/uppercaseFirstLetter';

interface FiltersTooltipProps {
  category?: string;
  isFilter?: boolean;
  // Is the first item within a list?
  isFirstItem?: boolean;
  isShort?: boolean;
  isTitle?: boolean;
  title?: string;
  values?: string[];
}

const FiltersTooltip: React.FC<React.PropsWithChildren<FiltersTooltipProps>> = (
  props: FiltersTooltipProps,
) => {
  const { category, isFilter, isFirstItem, isShort, isTitle, title, values } =
    props;

  const wrapperClass = classNames('FiltersTooltip-wrapper', {
    'FiltersTooltip--isTitleWrapper': isShort && isTitle,
    'FiltersTooltip--short': isShort && !isTitle,
    'FiltersTooltip-isFilterWrapper': isFilter,
  });

  const caratClass = classNames('FiltersTooltip-carat', {
    'FiltersTooltip-carat--short': isShort,
  });

  const containerClass = classNames('FiltersTooltip-container', {
    'FiltersTooltip--isFirstItemContainer': isFirstItem,
    'FiltersTooltip--isTitleContainer': isShort && isTitle,
    'FiltersTooltip--shortContainer': isShort && !isTitle,
  });

  return (
    <div className={wrapperClass}>
      <div className={containerClass}>
        {(!isShort || isFirstItem) && (
          <div className='FiltersTooltip-caratUp' />
        )}
        <p className='FiltersTooltip-title'>
          {!isShort
            ? `[${ucFirstLetter(category as string)}] ${title}`
            : `${title}`}
        </p>
        {values?.length && (
          <div className='FiltersTooltip-description'>
            {values?.map((value: string, index: number) =>
              index === values.length - 1 ? (
                <span className='FiltersTooltip-value' key={value}>
                  {value}
                </span>
              ) : (
                <span className='FiltersTooltip-value' key={value}>
                  {`${value}, `}{' '}
                </span>
              ),
            )}
          </div>
        )}
        {isShort && !isFirstItem && <div className={caratClass} />}
      </div>
    </div>
  );
};

export default FiltersTooltip;
