import { useMemo } from 'react';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import { getAuthorizedOptions, SelectDropdownProps } from './utils';
import { CognitoUserRole } from 'src/services/apiInterfaces';

interface PermissionLevelSelectProps
  extends Pick<SelectDropdownProps, 'onChange' | 'value'> {
  requesterUserRole: CognitoUserRole;
}

export default function PermissionLevelSelect({
  onChange,
  requesterUserRole,
  value,
}: PermissionLevelSelectProps) {
  const options = useMemo(
    () => getAuthorizedOptions(requesterUserRole),
    [requesterUserRole],
  );

  return (
    <SelectDropdown
      id='permission_level'
      label='Permission level'
      onChange={onChange}
      options={options}
      placeholder='Select a permission level'
      value={value}
    />
  );
}
