import { Button } from '@forethought-technologies/forethought-elements';
import NoteDialog from './NoteDialog';

interface UnshareNoteDialogProps {
  noteId: string;
  onClose: () => void;
  onUnshare: (noteId: string) => void;
}

const UnshareNoteDialog = ({
  noteId,
  onClose,
  onUnshare,
}: UnshareNoteDialogProps) => {
  const isOpen = Boolean(noteId);

  return (
    <NoteDialog
      actionButton={
        <Button
          onClick={() => {
            onUnshare(noteId);
            onClose();
          }}
          variant='secondary'
        >
          Unshare
        </Button>
      }
      body='Unsharing this note will prevent agents from viewing or editing it. Are you sure you want to proceed?'
      onClose={onClose}
      open={isOpen}
      title='Unshare note with team?'
    />
  );
};

export default UnshareNoteDialog;
