import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import { IconEyeOff } from '@tabler/icons-react';
import { CellContext } from '@tanstack/react-table';

import {
  IconButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { NewDot } from '../components/TopicRenderer';
import { isRowChild } from './helpers';
import ArticleIcon from 'src/assets/images/article-icon.svg';
import recommendationBadge from 'src/assets/images/recommendation-badge-image.svg';
import DiscoverAutomatedBadge from 'src/components/discover-automated-badge/DiscoverAutomatedBadge';
import DiscoverNewTopicBadge from 'src/components/discover-new-topic-badge';
import { DISCOVER_TEXT } from 'src/components/global-discover/constants';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { DiscoverTopicMetricsTreated } from 'src/reducers/discoverReducer/types';
import {
  deriveTopicName,
  overrideDiscoverSearchParams,
  replaceIdInRoute,
} from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

const TopicNameCell = ({
  cellProps,
  searchText,
}: {
  cellProps: CellContext<DiscoverTopicMetricsTreated, string>;
  searchText?: string;
}) => {
  const { palette } = useTheme();

  const navigate = useNavigate();
  const linkWithSearch = useGenerateLinkWithSearch(
    Routes.DISCOVER_TOPIC_DETAIL,
  );

  const { row } = cellProps;
  const isChild = isRowChild(row);
  const topic = row.original.topic;
  if (!topic) {
    return null;
  }
  const {
    generated_article_count,
    is_automated,
    is_hidden,
    is_new_topic,
    is_recommended,
    relevant_article_count,
    topic_display_name,
    topic_id,
    topic_name,
  } = topic;
  const doesArticleHaveGap = relevant_article_count === 0;

  const isArticleGenerated = Boolean(
    generated_article_count && generated_article_count > 0,
  );

  const topicName = deriveTopicName(topic_display_name, topic_name);

  const renderName = () => {
    if (!searchText?.trim()) {
      return topicName;
    }

    const start = topicName.toLowerCase().indexOf(searchText);
    const stop = start + searchText.length;

    if (start === -1) {
      return topicName;
    }

    return topicName.split('').map((char, idx) => (
      <span
        key={idx}
        style={{
          backgroundColor:
            idx >= start && idx < stop
              ? palette.colors.blue[200]
              : 'transparent',
        }}
      >
        {char}
      </span>
    ));
  };

  const renderBadge = () => {
    if (is_automated) {
      return (
        <Tooltip tooltipContent={DISCOVER_TEXT.automatedBadgeTooltip}>
          <DiscoverAutomatedBadge size={20} />
        </Tooltip>
      );
    }

    if (is_recommended) {
      return (
        <Tooltip tooltipContent={DISCOVER_TEXT.recommendedBadgeTooltip}>
          <img src={recommendationBadge} />
        </Tooltip>
      );
    }
    return null;
  };

  const onClickArticleButton: React.MouseEventHandler<
    HTMLButtonElement
  > = e => {
    e.stopPropagation();

    navigate(
      overrideDiscoverSearchParams(
        replaceIdInRoute(linkWithSearch, topic_id ?? ''),
        {
          name: topicName,
          source: 'all-topics',
          tab: 'Generated articles',
        },
      ),
    );
  };

  return (
    <Box
      alignItems='center'
      display='flex'
      gap='4px'
      justifyContent='start'
      width='100%'
    >
      {is_hidden && (
        <Box alignItems='center' display='flex' mr={1.5}>
          <IconEyeOff color={palette.colors.grey[600]} size={15} />
        </Box>
      )}
      <Box
        sx={[
          {
            color: is_hidden ? palette.colors.grey[600] : palette.colors.black,
            minWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        ]}
      >
        <Typography variant={isChild ? 'font14' : 'font16Bold'}>
          {renderName()} {isChild ? '' : ` (${row.subRows.length || 0})`}
        </Typography>
      </Box>
      <DiscoverNewTopicBadge isNewTopic={is_new_topic} />
      {(is_automated || is_recommended) && (
        <BadgeAlignment>{renderBadge()}</BadgeAlignment>
      )}
      {isArticleGenerated && (
        <IconButton
          aria-label='Navigate to article'
          hoverBackgroundColor={palette.colors.purple[200]}
          onClick={onClickArticleButton}
          variant='ghost'
        >
          <Tooltip
            tooltipContent={
              doesArticleHaveGap
                ? 'This topic doesn’t appear to have any support articles. SupportGPT has generated articles to cover this topic.'
                : `SupportGPT has generated ${generated_article_count} articles for this topic.`
            }
          >
            <>
              {isArticleGenerated && doesArticleHaveGap ? (
                <Box height={0} width={0}>
                  <NewDot />
                </Box>
              ) : null}
              {isArticleGenerated ? <img src={ArticleIcon} /> : null}
            </>
          </Tooltip>
        </IconButton>
      )}
    </Box>
  );
};

export default TopicNameCell;

const BadgeAlignment = styled('span')`
  display: flex;
  margin-left: 8px;
`;
