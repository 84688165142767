import { useMemo, useState } from 'react';

import { ContextVariableOptionWithIndentAndLabelToDisplay } from '../context-variable-indented-menu-items/ContextVariableIndentedMenuItems';
import { MentionAtomPopupComponent } from './MentionAtomPopupComponent';
import { type MentionComponentProps } from './types';

export function MentionComponent({
  contextVariables,
  setIsMentionActive,
}: MentionComponentProps) {
  const [query, setQuery] = useState('');

  const items = useMemo(() => {
    const filteredCVs = filterListOptionsWithParentsAndChildren(
      contextVariables,
      query,
    );
    return formatOptionsForMention(filteredCVs);
  }, [query, contextVariables]);

  return (
    <MentionAtomPopupComponent
      items={items}
      onChange={state => {
        setIsMentionActive(state !== null);
        setQuery(state?.query.full.toLowerCase() ?? '');
      }}
    />
  );
}

function formatOptionsForMention(
  options: MentionComponentProps['contextVariables'],
): (ContextVariableOptionWithIndentAndLabelToDisplay &
  Record<string, unknown>)[] {
  return options.map(opt => ({
    ...opt,
    // This is exactly what the mention component will add to the text area, so it must be the full label including the parent CV name, as well as the dollar sign prefix
    label: '$' + opt.fullLabel,
    // This is what we will show in the dropdown list. It is just the child cv name, to be indented under its parent.
    labelToDisplay: opt.label,
  }));
}

function filterListOptionsWithParentsAndChildren(
  options: MentionComponentProps['contextVariables'],
  searchString: string,
): MentionComponentProps['contextVariables'] {
  const result = [];
  const addedValues = new Set<string>();

  // Helper function to add an item's parents
  const addParents = (index: number) => {
    const parentsStack = []; // Use a stack to keep track of parents to maintain order
    let currentIndent = options[index].indent ?? 0;
    for (let i = index - 1; i >= 0; i--) {
      // Start looking for parents above the current index
      const indent = options[i].indent ?? 0;
      if (indent < currentIndent) {
        if (!addedValues.has(options[i].id)) {
          parentsStack.push(options[i]);
          addedValues.add(options[i].id);
        }
        currentIndent = indent; // Update currentIndent to find the next parent up
      }
    }
    // Add parents in the correct order (from top to bottom)
    while (parentsStack.length) {
      result.push(parentsStack.pop());
    }
  };

  // Helper function to add all children of a matched item
  const addChildren = (index: number) => {
    const parentIndent = options[index].indent ?? 0;
    for (let i = index + 1; i < options.length; i++) {
      if ((options[i].indent ?? 0) > parentIndent) {
        if (!addedValues.has(options[i].id)) {
          result.push(options[i]); // Add children in their original order
          addedValues.add(options[i].id);
        }
      } else {
        break; // Stop if we've reached an item at the same level or higher than the parent
      }
    }
  };

  for (let i = 0; i < options.length; i++) {
    if (
      options[i].label.toLowerCase().includes(searchString.toLowerCase()) &&
      !addedValues.has(options[i].id)
    ) {
      addParents(i); // Add parents of the matched item
      result.push(options[i]); // Add the matched item itself
      addedValues.add(options[i].id);
      addChildren(i); // Add all children of the matched item
    }
  }

  return result;
}
