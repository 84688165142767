import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import { IconCircleCheck, IconCircleCheckFilled } from '@tabler/icons-react';

import { SelectedActionDetail } from 'src/types/actionBuilderApiTypes';

interface CheckProps {
  errorsToCheck: (keyof SelectedActionDetail)[];
}

const Check = ({ errorsToCheck }: CheckProps) => {
  const { errors } = useFormikContext<SelectedActionDetail>();

  const hasError = errorsToCheck.some(error => Boolean(errors[error]));

  return (
    <Box sx={{ color: hasError ? 'grey' : '#17B790', mr: 1 }}>
      {hasError ? (
        <IconCircleCheck size={20} />
      ) : (
        <IconCircleCheckFilled size={20} />
      )}
    </Box>
  );
};

export default Check;
