import './index.scss';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import RequiredAsterisk from '../RequiredAsterisk/index';
import { modifyExistingReport } from 'src/actions/reports/reports';
import {
  selectIsReportModified,
  selectReport,
  selectReportsData,
} from 'src/reducers/reportsReducer/reportsReducer';
import { Report } from 'src/services/apiInterfaces';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';
import { setReportIdToUrl } from 'src/utils/urlBehavior';

const SaveReportModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const selectedReport: Report | undefined = useSelector(selectReport);
  const isReportModified: boolean = useSelector(selectIsReportModified);
  const reportsData = useSelector(selectReportsData);
  let isComponentMounted = true;
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [shouldSaveAsNew, setshouldSaveAsNew] = useState<boolean>(true);

  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const reference: React.RefObject<HTMLDivElement> = useRef(null);

  const previousName = selectedReport?.name || '';
  const previousDescription = selectedReport?.description || '';
  const selectedReportId = selectedReport?.report_id;
  const [reportName, setReportName] = useState<string>(previousName);
  const [reportDescription, setReportDescription] =
    useState<string>(previousDescription);

  const isReportOwner = reportsData?.user_owned_report_ids.includes(
    selectedReport?.report_id || 0,
  );

  useEffect(() => {
    if (shouldSaveAsNew) {
      setReportName('');
      setReportDescription('');
    } else {
      setReportName(previousName);
      setReportDescription(previousDescription);
    }
  }, [shouldSaveAsNew, previousName, previousDescription]);

  const handleCheckBox = () => {
    if (!isReportOwner || selectedReport?.is_default) {
      setshouldSaveAsNew(true);
    } else if (isReportOwner) {
      setshouldSaveAsNew(!shouldSaveAsNew);
    }
  };

  const handleSaveAsNew = () => {
    if (!isReportOwner || selectedReport?.is_default) {
      setshouldSaveAsNew(true);
    } else if (isReportOwner) {
      setshouldSaveAsNew(false);
    }
  };

  const labelClassName = classNames('SaveReport-newReportLabel', {
    'SaveReport-newReportLabel SaveReport-newReportLabel--disabled':
      !isReportOwner || selectedReport?.is_default,
  });

  const delClassName = classNames({
    'SaveReport-icon': !isModalVisible,
    'SaveReport-iconDisabled': !isReportModified,
    'SaveReport-iconFocused': isModalVisible,
  });

  const textAreaPlaceholder = shouldSaveAsNew
    ? 'Add description'
    : 'Add description (optional)';

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        reference,
        ContainerRef,
        setModalVisible,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);
  return (
    <div className='dropdown view-dropdown' ref={reference}>
      <button
        aria-expanded='false'
        className='select'
        data-testid='dropdown-button'
        onClick={(): void => {
          setModalVisible(!isModalVisible);
          !isReportModified && setModalVisible(false);
          handleSaveAsNew();
        }}
        type='button'
      >
        <span className='selected-option'>
          <span className={delClassName} />
        </span>
      </button>
      {isModalVisible && (
        <div className='SaveReport-modal' ref={ContainerRef}>
          <p className='SaveReport-title'>Save Report</p>
          <form
            className='SaveReport-container'
            onSubmit={(e: React.FormEvent): void => {
              e.preventDefault();
              if (shouldSaveAsNew) {
                dispatch(
                  modifyExistingReport(
                    selectedReport?.report_id,
                    reportName,
                    reportDescription,
                    true,
                  ),
                );
              } else {
                dispatch(
                  modifyExistingReport(
                    selectedReport?.report_id,
                    reportName,
                    reportDescription,
                    false,
                  ),
                );
              }
              setModalVisible(false);
              setReportIdToUrl(selectedReportId as number);
            }}
          >
            <label className='SaveReport-inputLabel' htmlFor='report-name'>
              NAME
              <RequiredAsterisk />
            </label>
            <input
              className='SaveReport-nameInput'
              data-testid='test-input'
              id='report-name'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setReportName(event.target.value)
              }
              placeholder='Report Name'
              required
              type='text'
              value={reportName}
            />
            <label className='SaveReport-textAreaLabel' htmlFor='report-name'>
              DESCRIPTION
              <RequiredAsterisk />
            </label>
            <textarea
              className='SaveReport-textAreaInput'
              maxLength={1000}
              name='description'
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                setReportDescription(event.target.value)
              }
              placeholder={textAreaPlaceholder}
              required
              value={reportDescription}
            />
            <div className='SaveReport-bttnCont'>
              <input
                checked={shouldSaveAsNew}
                className='SaveReport-newReportCheckbox'
                data-testid='checkbox'
                id='save-as-new'
                name='save-as-new'
                onChange={(): void => handleCheckBox()}
                type='checkbox'
                value='save-as-new'
              />
              <label className={labelClassName} htmlFor='save-as-new'>
                Save as new
              </label>
              <button
                className='SaveReport-cancelButton'
                onClick={(): void => {
                  setModalVisible(false);
                }}
                type='button'
              >
                Cancel
              </button>
            </div>
            <button className='SaveReport-submitButton' type='submit'>
              Save Report
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default SaveReportModal;
