import React, { type ComponentProps } from 'react';

import { MultiSelectFilter } from '@forethought-technologies/forethought-elements';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';

const FilterSelect = ({
  icon,
  maxHeight,
  menuTitle = 'Filter by',
  onChange,
  options,
  placeholder,
  value,
}: {
  icon?: React.ReactNode;
  maxHeight?: number;
  menuTitle?: string;
  onChange: (value: string[]) => void;
  options: ComponentProps<typeof MultiSelectFilter>['options'];
  placeholder?: string;
  value: string[];
}) => {
  return (
    <MultiSelectFilter
      icon={icon || <img src={filterIcon} />}
      isMenuSearchable={true}
      maxHeight={maxHeight}
      menuTitle={menuTitle}
      onChange={onChange}
      options={options}
      placeholder={menuTitle || placeholder || ''}
      value={value}
    />
  );
};

export default FilterSelect;
