import { forwardRef } from 'react';

import { FIVE_MB_IN_BYTES } from 'src/pages/solve-config/constants';
import { setSelectedImageFile } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export const HiddenImageInput = forwardRef<HTMLInputElement | null>(
  function HiddenImageInput({}, forwardedRef) {
    const dispatch = useAppDispatch();

    return (
      <input
        accept='image/*'
        aria-label='Upload image'
        hidden
        onChange={e => {
          const file = e.target.files?.[0];

          if (file && file.size > FIVE_MB_IN_BYTES) {
            dispatch(
              setGlobalToastOptions({
                title: 'Image cannot be larger than 5MB.',
                variant: 'danger',
              }),
            );
            e.target.value = '';
            return;
          }

          if (file) {
            dispatch(setSelectedImageFile(file));
          }

          // Reset the input so that the user can upload the same image again
          e.target.value = '';
        }}
        ref={forwardedRef}
        type='file'
      />
    );
  },
);
