import { useDispatch, useSelector } from 'react-redux';

import ConfirmModal from 'src/components/email-builder/components/ConfirmModal';
import { KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE } from 'src/components/knowledge-configuration-prompt-text-field/helpers';
import {
  hideWarningModal,
  selectWarningModalsVisibility,
} from 'src/slices/solve-config/solveConfigSlice';

export const WarningModals = () => {
  const dispatch = useDispatch();

  const [
    isGreetingWarningModalVisible,
    isPromptWarningModalVisible,
    isHandoffWarningModalVisible,
    isKnowledgePromptWarningModalVisible,
  ] = useSelector(selectWarningModalsVisibility);

  return (
    <>
      <ConfirmModal
        actions={[
          {
            onClick: () => {
              dispatch(hideWarningModal());
            },
            title: 'Got it',
          },
        ]}
        description='Enter a greeting message as the greeting field cannot be left empty.'
        isOpen={isGreetingWarningModalVisible}
        onClose={() => {
          dispatch(hideWarningModal());
        }}
        title='Greeting cannot be empty.'
      />
      <ConfirmModal
        actions={[
          {
            onClick: () => {
              dispatch(hideWarningModal());
            },
            title: 'Got it',
          },
        ]}
        description='Enter a prompt message as the Proactive prompt field cannot be left empty. If you like you can turn off the prompt.'
        isOpen={isPromptWarningModalVisible}
        onClose={() => {
          dispatch(hideWarningModal());
        }}
        title='Proactive Prompt cannot be empty.'
      />
      <ConfirmModal
        actions={[
          {
            onClick: () => {
              dispatch(hideWarningModal());
            },
            title: 'Got it',
          },
        ]}
        description='Enter a Handoff message as the Handoff message field cannot be left empty.'
        isOpen={isHandoffWarningModalVisible}
        onClose={() => {
          dispatch(hideWarningModal());
        }}
        title='Handoff message cannot be empty.'
      />
      <ConfirmModal
        actions={[
          {
            onClick: () => {
              dispatch(hideWarningModal());
            },
            title: 'Got it',
          },
        ]}
        description={KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE}
        isOpen={isKnowledgePromptWarningModalVisible}
        onClose={() => {
          dispatch(hideWarningModal());
        }}
        title='Knowledge configuration prompt is too long.'
      />
    </>
  );
};
