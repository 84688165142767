import React, { useRef } from 'react';
import { Box, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { IconArrowLeft } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useOnClickOutsideActiveComponent } from '../hooks';
import { EmailBuilderConfigurationComponent } from '../types';
import Spinner from 'src/components/spinner';

interface EditActiveConfigurationComponentOverlayrops {
  activeEditComponentConfiguration?: EmailBuilderConfigurationComponent;
  children: React.ReactNode;
  isLoading: boolean;
  onClickCancel: () => void;
}

const EditActiveConfigurationComponentOverlay = ({
  activeEditComponentConfiguration,
  children,
  isLoading,
  onClickCancel,
}: EditActiveConfigurationComponentOverlayrops) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutsideActiveComponent(ref, onClickCancel);
  return (
    <>
      {isLoading && (
        <LoadingOverlay>
          <Spinner></Spinner>
        </LoadingOverlay>
      )}
      <EditStateOverlay ref={ref}>
        <OverlayHeader>
          <IconButton aria-label='go back' onClick={onClickCancel}>
            <IconArrowLeft />
          </IconButton>
          <Box alignItems='center' display='flex' gap='5px'>
            <Typography variant='font24'>
              {activeEditComponentConfiguration?.name}
            </Typography>
          </Box>
        </OverlayHeader>
        {children}
      </EditStateOverlay>
    </>
  );
};

export default EditActiveConfigurationComponentOverlay;

const EditStateOverlay = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: ${props => props.theme.palette.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
`;
const OverlayHeader = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LoadingOverlay = styled(EditStateOverlay)`
  background: ${props => props.theme.palette.colors.black};
  z-index: 2;
  padding: 0;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.colors.white};
`;
