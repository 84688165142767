import React from 'react';
import { Box } from '@mui/material';

import { CanvasWorkflowBuilderState } from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import StepContainer from '../workflow-builder/revamped/StepContainer';
import StepHeader from '../workflow-builder/revamped/StepHeader';
import TextBox from '../workflow-builder/revamped/TextBox';
import ButtonOption from './ButtonOption';
import { QuasiStepTypes } from 'src/utils/enums';

interface ActionCaseSelectorProps {
  canvasData: CanvasWorkflowBuilderState;
  negativeCaseSelectedCallBack: () => void;
  stepId: string;
}

/** ActionCaseSelector is going to be present in canvas if an action has transition branches */

const ActionCaseSelector: React.FC<
  React.PropsWithChildren<ActionCaseSelectorProps>
> = ({ canvasData, negativeCaseSelectedCallBack, stepId }) => {
  const canvasActions = canvasData.canvas_action_id_to_action_component;
  const actionIds = Object.keys(canvasActions);
  const actionId = actionIds.find(
    id => canvasActions[id]['entry_step_id'] === stepId,
  );
  const buttonOptions =
    (actionId && canvasActions[actionId]['transition_branches']) || [];

  return (
    <StepContainer paddingTop='0'>
      <StepHeader stepType={QuasiStepTypes.FEEDBACK} />
      <Box display='flex' flexDirection='column' gap={1}>
        <TextBox
          isRichText
          value='Was I able to help you resolve your question?'
          variant='dark'
        />
        {buttonOptions.map((option, index) => (
          <ButtonOption
            canvasData={canvasData}
            index={index}
            key={option.parent_step_id}
            negativeCaseSelectedCallBack={negativeCaseSelectedCallBack}
            option={option}
            stepId={stepId}
            text={
              option.display_name === 'Yes'
                ? 'Yes, thank you!'
                : 'No, I need more help.'
            }
          />
        ))}
      </Box>
    </StepContainer>
  );
};

export default ActionCaseSelector;
