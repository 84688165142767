import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@forethought-technologies/forethought-elements';
import { selectConnectors } from '../../../../slices/connectors/connectorsSlice';
import {
  deleteConnector,
  toggleConnectorIsActive,
} from '../../../../slices/connectors/thunks';
import { Connector } from 'src/services/apiInterfaces';

export default function ConnectConnectorButton({
  connector,
}: {
  connector: Connector;
}) {
  const dispatch = useDispatch();
  const { isActiveStateLoading } = useSelector(selectConnectors);
  const isSunco = connector.connector_definition.slug === 'legacy_sunshine';
  const disconnectActionName = isSunco ? 'Delete' : 'Disconnect';

  const onClickConnect = useCallback(
    (connector: Connector) => {
      dispatch(
        toggleConnectorIsActive({
          connectorId: connector.connector_id,
          connectorTypes: connector.connector_types,
          isActive: true,
        }),
      );
    },
    [dispatch],
  );

  const onClickDisconnect = useCallback(
    async ({
      connector_definition,
      connector_id,
      connector_types,
    }: Connector) => {
      const userConfirms = await confirm(
        `Are you sure you want to ${disconnectActionName.toLowerCase()} the ${
          connector_definition.name
        } integration?`,
      );

      if (!userConfirms) {
        return;
      }

      if (isSunco) {
        dispatch(
          deleteConnector({
            connectorId: connector_id,
          }),
        );
      } else {
        dispatch(
          toggleConnectorIsActive({
            connectorId: connector_id,
            connectorTypes: connector_types,
            isActive: false,
          }),
        );
      }
    },
    [dispatch, disconnectActionName, isSunco],
  );

  return connector.is_active ? (
    <Button
      fullWidth
      isLoading={isActiveStateLoading}
      onClick={e => {
        e.preventDefault();
        onClickDisconnect(connector);
      }}
      size='large'
      variant='danger'
    >
      {disconnectActionName}
    </Button>
  ) : (
    <Button
      fullWidth
      isLoading={isActiveStateLoading}
      onClick={e => {
        e.preventDefault();
        onClickConnect(connector);
      }}
      size='large'
      variant='main'
    >
      Connect
    </Button>
  );
}
