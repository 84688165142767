import { useCallback, useState } from 'react';

import { Button } from '@forethought-technologies/forethought-elements';
import { resendUserInvite } from 'src/services/apiV1';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export default function ResendInviteButton({ email }: { email: string }) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleResendInvite = useCallback(
    async (email: string) => {
      setIsLoading(true);

      try {
        await resendUserInvite(email);
        dispatch(
          setGlobalToastOptions({
            title: `Invite has been sent to ${email}`,
            variant: 'main',
          }),
        );
      } catch {
        dispatch(
          setGlobalToastOptions({
            title: `Something went wrong sending invite to ${email}`,
            variant: 'danger',
          }),
        );
      }

      setIsLoading(false);
    },
    [dispatch],
  );

  return (
    <Button
      isLoading={isLoading}
      onClick={() => handleResendInvite(email)}
      variant='ghost'
    >
      Resend
    </Button>
  );
}
