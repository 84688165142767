import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';

import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import ChatOnlyHandoff from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/chat-only/ChatOnlyHandoff';
import { textMediumStyle } from 'src/styles/styledMixin';
import { ZendeskChatHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface HandoffConfigurationProps {
  customizationData: ZendeskChatHandoffCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  isSuncoLiveChat?: boolean;
  setCustomizationData: Dispatch<
    SetStateAction<ZendeskChatHandoffCustomization>
  >;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const ZendeskChatHandoffConfiguration: FC<
  React.PropsWithChildren<HandoffConfigurationProps>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  isSuncoLiveChat = false,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  return (
    <ContentWrapper useFullHeight={true}>
      <Content>
        <Title>
          {isSuncoLiveChat ? 'Sunco Live Chat' : 'Zendesk Handoff (Chat-Only)'}
        </Title>
        <Spacer height='20px' />
        <Section>
          <ChatOnlyHandoff
            customizationData={customizationData}
            errorMessages={errorMessages}
            isFieldTouchedArr={isFieldTouchedArr}
            setCustomizationData={setCustomizationData}
            setIsFieldTouchedArr={setIsFieldTouchedArr}
          />
        </Section>
      </Content>
    </ContentWrapper>
  );
};

export default ZendeskChatHandoffConfiguration;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0;
`;

const ContentWrapper = styled('div')<{ useFullHeight: boolean }>`
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  margin-top: 0;
  margin-bottom: 25px;
`;
