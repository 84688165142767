import { stringOperators } from 'src/components/expression-builder/constants';
import {
  EmailAutomation,
  TicketCustomField,
  TicketMappedField,
} from 'src/pages/workflow-builder-edit/types';
import { ConditionExpressions } from 'src/services/apiInterfaces';

export const getFieldLabel = (
  fieldValue: string,
  fields: Array<TicketCustomField>,
) => {
  return fields.find(field => field.id === fieldValue)?.title;
};

export const getOperatorLabel = (operatorValue: {
  negate: boolean;
  operator: string;
}) => {
  const label =
    stringOperators.find(
      operator =>
        operatorValue.negate === operator.negate &&
        operatorValue.operator === operator.value,
    )?.label || '';

  return label;
};

export const generateWordingForAutomationAction = (
  automationActions: EmailAutomation['automation_actions'],
) => {
  const automationAction = automationActions[0];

  const noResponseMessage = 'do not respond';

  const delayResponseMessage = `delay response for ${automationAction.automation_action_fields['delay_in_minutes']} minutes`;

  const threadParsingMessage = `parse the ${getNumberSuffix(
    automationAction.automation_action_fields['comment_position'] as number,
  )} comment from the ticket thread`;

  const valueMap = {
    'no-response': noResponseMessage,
    'response-delay': delayResponseMessage,
    'thread-parsing': threadParsingMessage,
  };

  return `then ${valueMap[automationAction.automation_action_type]}`;
};

const getDisplayNameValueFromFieldOptions = (
  ticketFields: Array<TicketCustomField>,
  field: string,
  value: string,
) => {
  const ticketField = ticketFields.find(
    ticketField => ticketField.id === field,
  );
  const fieldOptions = ticketField?.field_options || [];
  return fieldOptions.find(option => option.value === value)?.label;
};

export const generateWordingByOperator = (
  queryExpression: ConditionExpressions,
  ticketFields: Array<TicketCustomField>,
  mappedFields: Array<TicketMappedField>,
) => {
  const { field = '', negate, operator = '', values = [] } = queryExpression;

  const triageModelCv = mappedFields.find(
    mappedField => mappedField.id === field,
  )?.title;

  const fieldLabel = triageModelCv ?? getFieldLabel(field || '', ticketFields);
  const operatorLabel = getOperatorLabel({ negate, operator });

  if (operator === '=' || operator === 'contains') {
    const listTypeSelectedValue = getDisplayNameValueFromFieldOptions(
      ticketFields,
      field,
      values[0] as string,
    );
    const value = listTypeSelectedValue ?? values[0];
    return `${fieldLabel} ${operatorLabel} ${value}`;
  }

  if (operator === 'empty') {
    return `${fieldLabel} ${operatorLabel}`;
  }

  if (operator === 'contains_one_of') {
    const label = negate ? 'does not contain' : 'contains';

    if (values.length === 1) {
      return `${fieldLabel} ${label} ${values[0]}`;
    }

    return `${fieldLabel} ${label} one of ${values.length} values`;
  }
};

function getNumberSuffix(number: number) {
  const j = number % 10,
    k = number % 100;
  if (j == 1 && k != 11) {
    return number + 'st';
  }
  if (j == 2 && k != 12) {
    return number + 'nd';
  }
  if (j == 3 && k != 13) {
    return number + 'rd';
  }
  return number + 'th';
}
