import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import { FetchAction } from '../../types/types';
import {
  APPLY_BREAKDOWN_FIELD,
  CLEAR_SELECTED_BREAKDOWN,
  DELETE_BREAKDOWN_FIELD,
  GET_BREAKDOWN_DATA_FAILURE,
  GET_BREAKDOWN_FIELDS_SUCCESS,
  TOGGLE_BREAKDOWN_FIELD,
  TOGGLE_BREAKDOWN_VALUE,
  TOGGLE_SELECT_ALL_BREAKDOWNS,
} from '../actionTypes';
import { PageSettingsActions } from '../actionTypes';
import { Field, Fields } from 'src/services/apiInterfaces';
import { getBreakdownFieldsAPI } from 'src/services/apiV1';

export const getBreakdownFieldsSuccess = createAction<Fields>(
  GET_BREAKDOWN_FIELDS_SUCCESS,
);

export const setLoading = createAction<boolean>(
  PageSettingsActions.SET_BREAKDOWN_LOADING,
);

export const toggleBreakdownValue = createAction<string>(
  TOGGLE_BREAKDOWN_VALUE,
);

export const toggleBreakdownField = createAction<Field>(TOGGLE_BREAKDOWN_FIELD);

export const clearSelectedBreakdownField = createAction(
  CLEAR_SELECTED_BREAKDOWN,
);

export const toggleSelectAllBreakdownValues = createAction(
  TOGGLE_SELECT_ALL_BREAKDOWNS,
);

export const applyBreakdownField = createAction<Field | null | undefined>(
  APPLY_BREAKDOWN_FIELD,
);

export const deleteBreakdownField = createAction(DELETE_BREAKDOWN_FIELD);

export const getDataFailure = createAction<string>(GET_BREAKDOWN_DATA_FAILURE);

export const getBreakdownFields: Function =
  () =>
  (dispatch: Dispatch): Promise<void | FetchAction> => {
    dispatch(setLoading(true));

    return getBreakdownFieldsAPI()
      .then((data: Fields) => {
        dispatch(getBreakdownFieldsSuccess(data));
        dispatch(setLoading(false));
      })
      .catch((err: Error) => {
        dispatch(getDataFailure(err.message));
        dispatch(setLoading(false));
      });
  };
