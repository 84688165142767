import { Filters } from 'src/services/apiInterfaces';

export const setIcon = (type?: string): string => {
  const iconDefaultClass = 'FilterTag-container-typeIcon';
  let typeIcon = '';

  if (type === 'STRING' || type === 'URL') {
    typeIcon = 'FilterTag-container-stringTypeIcon';
  }
  if (type === 'INTEGER' || type === 'DOUBLE') {
    typeIcon = 'FilterTag-container-intTypeIcon';
  }
  if (type === 'DATE') {
    typeIcon = 'FilterTag-container-dateTypeIcon';
  }
  if (type === 'BOOLEAN') {
    typeIcon = 'FilterTag-container-booleanTypeIcon';
  }

  return iconDefaultClass + ' ' + typeIcon;
};

export const getDate = (date: Date): string => {
  if (date) {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const monthTemp = date.getMonth() + 1;
    const month = monthTemp < 10 ? '0' + monthTemp : monthTemp;
    const year = date.getFullYear();

    return month + '-' + day + '-' + year + '';
  }
  return '';
};

export const countChars = (filter: Filters): number => {
  const categoryLength = filter.field.field_category?.length as number;
  const nameLength = filter.field.field_name?.length as number;
  const operatorLength = filter.operator.length;
  const totalFieldCharCount = categoryLength + nameLength + operatorLength;
  const totalValueCharCount = filter.values.join('').length;
  return totalFieldCharCount + totalValueCharCount;
};
