import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';

export const Title = styled('h2')`
  font-size: 24px;
  margin: 0;
  margin-top: 16px;
  line-height: 30px;
`;

export const Description = styled('p')`
  font-size: 14px;
  color: ${theme.palette.colors.grey[500]};
  margin-block-end: 0;
`;

export const Icon = styled('img')`
  height: 42px;
  width: 42px;
  display: block;
`;

export const Row = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const BadgeContainer = styled('div')`
  padding-right: 56px;
`;

export const DrawerBody = styled('div')`
  padding: 40px;
`;
