import React from 'react';
import { Box, useTheme } from '@mui/material';

import LandingPageSubTabs from 'src/pages/workflow-builder/landing-page/components/LandingPageSubTabs';

interface ConversationsTabLayoutProps {
  aggregateMetrics: React.ReactNode;
  header: React.ReactNode;
  table: React.ReactNode;
}

export const ConversationsTabLayout = ({
  aggregateMetrics,
  header,
  table,
}: ConversationsTabLayoutProps) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        bgcolor: palette.colors.white,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: '0 40px',
      }}
    >
      {header}
      {aggregateMetrics}
      <LandingPageSubTabs />
      {table}
    </Box>
  );
};
