import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Box, Menu, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import tagIcon from '../../../assets/images/workflow-tag-icon.svg';
import { updateWorkflowTags } from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import TagInput from 'src/pages/workflow-builder-edit/tag-input/TagInput';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectIntentWorkflowId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useUpdateWorkflowTagsMutation } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';

type CanvasTaggerProps = {
  isDisabled?: boolean;
  onClick?: () => void;
};

const CanvasTagger: React.FC<React.PropsWithChildren<CanvasTaggerProps>> = ({
  isDisabled = false,
  onClick,
}) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const [updateWorkflowTagsApi, { isLoading }] =
    useUpdateWorkflowTagsMutation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userCanAddWorkflowTag = useSelector(selectUserCan('add_workflow_tag'));
  const intentWorkflowId = useSelector(selectIntentWorkflowId) ?? '';

  const workflowTags: Array<string> = useSelector(
    selectCanvasWorkflowBuilder,
  ).workflowTags;

  const isTagEditorVisible = Boolean(anchorEl);

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (!isDisabled) {
      onClick?.();
      setAnchorEl(e.currentTarget);
    }
  };

  return (
    <Box>
      <Button
        aria-label='tags'
        onClick={e => handleOnClick(e)}
        startIcon={<ReactSVG src={tagIcon} />}
        variant='ghost'
      >
        {workflowTags.length > 0 && (
          <Typography color={palette.common.black} variant='font14Bold'>
            {workflowTags.length}
          </Typography>
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        MenuListProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            margin: 0,
            padding: 0,
          },
        }}
        onClose={() => setAnchorEl(null)}
        open={isTagEditorVisible}
        PaperProps={{
          sx: {
            background: palette.background.default,
            border: '1px solid' + palette.colors.slate[200],
            borderRadius: '4px',
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            width: '468px',
          },
        }}
      >
        <TagInput
          isDisabled={!userCanAddWorkflowTag}
          isSaving={isLoading}
          onChange={(_, tags) => {
            dispatch(updateWorkflowTags(tags));
            updateWorkflowTagsApi({ intentWorkflowId, tags });
          }}
          tags={workflowTags}
        />
      </Menu>
    </Box>
  );
};

export default CanvasTagger;
