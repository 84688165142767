import React from 'react';
import {
  createMRTColumnHelper,
  MRT_ColumnDef,
  MRT_Row,
} from 'material-react-table';
import { useNavigate } from 'react-router';
import { Box, Palette } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';
import { IconBolt } from '@tabler/icons-react';

import {
  Table,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import OverflowDropdown from '../common/overflow-dropdown/OverflowDropdown';
import { INSIGHT_TOOLTIP_COPY, TABLE_COLUMN_TO_LABEL_MAP } from '../constants';
import { Scope } from '../types';
import { View } from './ChatsTable';
import autonomousAgentBadge from 'src/assets/images/autonomous-agent-badge.svg';
import { TrackingEventType } from 'src/components/app/types';
import { DateCell } from 'src/pages/intent-conversation-analytics/components/DateCell';
import { DeflectionInsightCell } from 'src/pages/intent-conversation-analytics/components/DeflectionInsightCell';
import {
  NA,
  SMALL_CELL_WIDTH,
} from 'src/pages/intent-conversation-analytics/constants';
import ConversationTranscript, {
  CellContainer,
} from 'src/pages/intent-conversation-analytics/ConversationTranscript';
import {
  formatCSAT,
  formatTimestamp,
} from 'src/pages/intent-conversation-analytics/helpers';
import { BreakdownPaletteTooltip } from 'src/pages/workflow-builder/BreakdownPaletteTooltip';
import { HorizontalStackedBar } from 'src/pages/workflow-builder/intent-workflows-table/HorizontalStackedBar';
import { InsightConversation } from 'src/services/insights/types';
import { Routes } from 'src/utils/enums';
import { isDeflectionInsightsProcessing } from 'src/utils/solve/deflectionInsightsUtils';

export type InsightConversationColumns = React.ComponentProps<
  typeof Table<InsightConversation>
>['columns'];

export const buildConversationColumns = (
  palette: Palette,
  getCSATColor: (score?: number | undefined) => string,
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: Record<string, unknown>,
  ) => void,
  scope: Scope,
  tab: 'topic' | 'article' | 'chat' | 'workflow',
  isDetail?: boolean,
  isDeflectionInsightsEnabled?: boolean,
  shouldShowQuickFeedbackAnalytics?: boolean,
) => {
  const columnHelper = createMRTColumnHelper<InsightConversation>();
  const relevanceRating = columnHelper.accessor('relevance_rating', {
    Cell: ({ row }: Row) => {
      return (
        <DeflectionInsightCell
          isProcessing={isDeflectionInsightsProcessing({
            hasDeflectionInsights: !!row.original.relevance_rating,
            isDeflected: row.original.is_deflected,
            modifiedDate: row.original.timestamp,
          })}
          metric={row.original.relevance_rating}
        />
      );
    },
    header: 'Relevance',
    Header: () => (
      <Box alignItems='center' display='flex' gap='4px'>
        Relevance
        <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.relevance}>
          <IconInfoCircle color={palette.colors.grey[700]} size={20} />
        </Tooltip>
      </Box>
    ),
    size: SMALL_CELL_WIDTH,
  });

  const dropoffRating = columnHelper.accessor('dropoff_rating', {
    Cell: ({ row }: Row) => {
      return (
        <DeflectionInsightCell
          isProcessing={isDeflectionInsightsProcessing({
            hasDeflectionInsights: !!row.original.relevance_rating,
            isDeflected: row.original.is_deflected,
            modifiedDate: row.original.timestamp,
          })}
          metric={row.original.dropoff_rating}
        />
      );
    },
    Header: () => (
      <Box alignItems='center' display='flex' gap='4px'>
        User engagement
        <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.engagement}>
          <IconInfoCircle color={palette.colors.grey[700]} size={20} />
        </Tooltip>
      </Box>
    ),
    header: 'User engagement',
    size: 200,
  });

  const quickFeedbackItem = columnHelper.accessor('useful_count', {
    Cell: ({ row }: Row) => {
      const not_useful_count = row.original.not_useful_count || 0;
      const useful_count = row.original.useful_count || 0;
      const unanswered_count = row.original.unanswered_count || 0;
      const totalFeedback = not_useful_count + useful_count;

      if (totalFeedback < 1) {
        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      }

      return (
        <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
          <Tooltip
            fullWidth
            maxWidth={320}
            tooltipContent={
              <BreakdownPaletteTooltip
                values={[
                  {
                    color: palette.colors.green[500],
                    label: 'Positive',
                    value: useful_count,
                  },
                  {
                    color: palette.colors.red[500],
                    label: 'Negative',
                    value: not_useful_count,
                  },
                  {
                    color: palette.colors.slate[100],
                    label: 'Not answered',
                    value: unanswered_count,
                  },
                ]}
              />
            }
          >
            <HorizontalStackedBar
              data={[
                {
                  color: palette.colors.green[500],
                  value: useful_count,
                },
                {
                  color: palette.colors.red[500],
                  value: not_useful_count,
                },
              ]}
            />
          </Tooltip>
        </Box>
      );
    },
    header: TABLE_COLUMN_TO_LABEL_MAP.conversation.useful_count,
    Header: () => {
      return (
        <Box alignItems='center' display='flex' gap='4px'>
          {TABLE_COLUMN_TO_LABEL_MAP.conversation.useful_count}
          <Tooltip
            tooltipContent={
              <Box>
                This reflects the Quick Feedback from <i>all</i> articles used
                in this chat
              </Box>
            }
          >
            <IconInfoCircle color={palette.colors.grey[700]} size={20} />
          </Tooltip>
        </Box>
      );
    },
    size: 250,
  });

  const initialColumns = [
    columnHelper.accessor('timestamp', {
      Cell: ({ row }: Row) => {
        return (
          <Box width={SMALL_CELL_WIDTH}>
            <DateCell
              dateString={formatTimestamp(row.original.timestamp + 'Z')}
            />
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.timestamp,
      size: SMALL_CELL_WIDTH,
    }),

    columnHelper.accessor('autonomous_agent_used', {
      Cell: ({ row }: Row) => {
        return row.original.autonomous_agent_used ? (
          <img
            alt='knowledge retrieval icon'
            height={24}
            src={autonomousAgentBadge}
            width={24}
          />
        ) : (
          <></>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.autonomous_agent_used,
      size: 40,
    }),

    columnHelper.accessor('chat', {
      Cell: ({ row }: Row) => {
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '8px',
              maxWidth: '540px',
              position: 'relative',
            }}
          >
            <ConversationTranscript transcript={row.original.chat} />
          </Box>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.chat,
      size: 320,
    }),

    columnHelper.accessor('user_queries', {
      Cell: ({ row }: Row) => {
        const userQueries = row.original.user_queries;

        if (userQueries.length === 1) {
          return (
            <CellContainer width='200px'>
              <i>
                <Typography color={palette.colors.blue[600]} variant='font14'>
                  &quot;{row.original.user_query}&quot;
                </Typography>
              </i>
            </CellContainer>
          );
        }

        if (userQueries.length > 1) {
          return (
            <OverflowDropdown
              data={userQueries}
              menuTitle='User query'
              scope='workflow'
              tab='chat'
              variant='user_queries'
            />
          );
        }

        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.user_queries,
      size: 200,
    }),

    columnHelper.accessor('is_deflected', {
      Cell: ({ row }: Row) => {
        return (
          <Typography variant='font14Medium'>
            {row.original.is_deflected ? 'Yes' : 'No'}
          </Typography>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.is_deflected,
      size: 130,
    }),

    columnHelper.accessor('detected_intents', {
      Cell: ({ row }: Row) => {
        return (
          <OverflowDropdown
            data={row.original.detected_intents}
            menuTitle='Workflows surfaced in this chat'
            scope={scope}
            tab='article'
            variant='workflow'
          />
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_intents,
      Header: () => {
        return (
          <Box
            alignItems='center'
            display='flex'
            gap='4px'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'chat',
                value: 'detected_intents',
              });
            }}
          >
            {TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_intents}
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.chats_workflows}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      size: 260,
    }),

    columnHelper.accessor('detected_topic', {
      Cell: ({ row }: Row) => {
        const navigate = useNavigate();
        if (row.original.detected_topic) {
          const { cluster_id, cluster_name } = row.original.detected_topic;
          return (
            <Box
              onClick={() => {
                const pathName = Routes.SOLVE_INSIGHTS_TOPIC_DETAIL.replace(
                  ':topicId',
                  cluster_id,
                );
                const queryParams = new URLSearchParams({
                  chats_sort_column: 'timestamp',
                });

                navigate({
                  pathname: pathName,
                  search: `?${queryParams}`,
                });
              }}
              sx={{
                '&:hover': { background: palette.colors.slate[200] },
                background: palette.colors.slate[100],
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                gap: '3px',
                padding: '4px 8px 4px 8px',
              }}
            >
              <Box alignItems='center' display='flex' width='17px'>
                <IconBolt
                  color={palette.colors.green[500]}
                  fill={palette.colors.green[500]}
                  height={17}
                  strokeWidth={1}
                  width={17}
                />
              </Box>
              <Typography color={palette.colors.grey[700]} variant='font12'>
                {cluster_name}
              </Typography>
            </Box>
          );
        }
        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },

      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_topic,
      Header: () => {
        return (
          <Box
            alignItems='center'
            display='flex'
            gap='4px'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'chat',
                value: 'detected_intents',
              });
            }}
          >
            {TABLE_COLUMN_TO_LABEL_MAP.conversation.detected_topic}
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.chats_detected_topic}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
    }),

    columnHelper.accessor('articles', {
      Cell: ({ row }: Row) => {
        if (isDetail && row.original.articles.length) {
          return (
            <OverflowDropdown
              data={row.original.articles}
              menuTitle='Articles surfaced in this chat'
              scope={scope}
              tab='article'
              variant='article'
            />
          );
        }

        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.articles,
      Header: () => {
        return (
          <Box
            alignItems='center'
            display='flex'
            gap='4px'
            onMouseEnter={() => {
              emitTrackingEventCallback('insight-tooltip-hover', {
                scope,
                tab,
                table: 'chat',
                value: 'articles',
              });
            }}
          >
            {TABLE_COLUMN_TO_LABEL_MAP.conversation.articles}
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.chats_surfaced_articles}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      size: 260,
    }),
    shouldShowQuickFeedbackAnalytics ? quickFeedbackItem : null,
    columnHelper.accessor('csat', {
      Cell: ({ row }: Row) => {
        if (!row.original.csat) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        const value = formatCSAT(row.original.csat);
        return (
          <Typography
            color={getCSATColor(Number(value))}
            variant='font14Medium'
          >
            {value}
          </Typography>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.csat,
      size: 100,
    }),
    isDeflectionInsightsEnabled ? relevanceRating : null,
    isDeflectionInsightsEnabled ? dropoffRating : null,
  ];

  return initialColumns.filter((item): item is Item => item !== null);
};

type Item =
  | MRT_ColumnDef<InsightConversation, string>
  | MRT_ColumnDef<InsightConversation, string[]>
  | MRT_ColumnDef<InsightConversation, boolean>;

type Row = { row: MRT_Row<InsightConversation> };

export const getTableHeight = (view: View) => {
  let height: number;

  switch (view) {
    case 'chats':
      height = 250;
    case 'topics':
      height = 300;
    case 'articles':
      height = 300;
    case 'workflows':
      height = 300;
    default:
      height = 250;
  }
  return height;
};
