import * as d3 from 'd3';

import { numberWithCommas } from './analyticsUtils';
import { cleanStr } from './cleanStr';
import { formatNToPercentage } from './formatToPercentage';

//global
const tooltip = d3.select('body').append('div');
let offset = 4;

//creates tooltip with one value
export const oneValueTooltip = (
  y: string,
  date: any,
  val1: any,
  chart?: string,
) => {
  let val = val1;
  val =
    y === 'prediction_accuracy' || y === 'case_coverage'
      ? formatNToPercentage(val1)
      : numberWithCommas(val1);
  const chartPosition = (document as any)
    .querySelector(chart)
    .getBoundingClientRect();
  const chartPositionRightEnd = chartPosition.right;
  const rEnd = d3.event.pageX + 200;
  if (rEnd > chartPositionRightEnd) {
    offset = chartPositionRightEnd - rEnd;
  }
  return tooltip
    .attr('class', 'one-val-tt-cont')
    .style('opacity', 1)
    .style('display', 'flex')
    .style('left', d3.event.pageX + offset + 'px')
    .style('top', d3.event.pageY - 115 + 'px')
    .html(
      `<div class="tooltip-heading"><p>${cleanStr(
        y,
      )}</p><p class=tooltip-date>${date} </p></div><div class=tooltip-inner><p class=one-value tooltip-value>${val}</p></div>`,
    );
};

//creates tooltip with three values in body
export const threeValuesTooltip = (
  y: string,
  date: any,
  val1Title: string,
  val1: any,
  val2Title?: string,
  val2?: any,
  val3Title?: string,
  val3?: any,
  chart?: string,
  tooltipClass?: string,
) => {
  const chartPosition = (document as any)
    .querySelector(chart)
    .getBoundingClientRect();
  //handles data card positioning based on cursor positioning
  const chartPositionRightEnd = chartPosition.right;
  const chartPositionLeftEnd = chartPosition.left;
  const rEnd = d3.event.pageX + 300;
  const rStart = d3.event.pageY;

  const tooltipClassName = tooltipClass ? `${tooltipClass}` : 'tooltip-heading';

  if (rEnd > chartPositionRightEnd) {
    offset = chartPositionRightEnd - rEnd;
  } else if (rStart > chartPositionLeftEnd) {
    offset = chartPositionLeftEnd - 35;
  }

  return tooltip
    .attr('class', 'three-val-tt-cont')
    .style('opacity', 1)
    .style('display', 'flex')
    .style('left', d3.event.pageX + offset + 'px')
    .style('top', d3.event.pageY - 140 + 'px')
    .html(
      `<div class=${tooltipClassName}><p class="tt-heading-title">${cleanStr(
        y,
      )}</p><p class=tooltip-date>${date} </p></div><div class=tooltip-inner><div class=tooltip-data-avg><p class="chart-tooltip-title">${val1Title}</p><p class=tooltip-value>${numberWithCommas(
        val1,
      )}</p></div><div class=tooltip-data-median><p class="chart-tooltip-title">${val2Title}</p><p class=tooltip-value>${numberWithCommas(
        val2,
      )}</p></div><div class=tooltip-data-count><p class="chart-tooltip-title">${val3Title}</p><p class=tooltip-value>${numberWithCommas(
        val3,
      )}</p></div></div>`,
    );
};

//TODO gigi: This will not be needed since solve will be done on highcharts in a few weeks **will be removed**
//creates tooltip with five values in body
export const fiveValuesTooltip = (
  y: string,
  date: string | undefined,
  val1Title: string,
  val1: number | undefined,
  val2Title?: string,
  val2?: number | undefined,
  val3Title?: string,
  val3?: number | undefined,
  chart?: string,
  tooltipClass?: string,
  val4Title?: string,
  val4?: string,
  val5Title?: string,
  val5?: string,
) => {
  const chartPosition = (document as any)
    .querySelector(chart)
    .getBoundingClientRect();
  //handles data card positioning based on cursor positioning
  const chartPositionRightEnd = chartPosition.right;
  const chartPositionLeftEnd = chartPosition.left;
  const rEnd = d3.event.pageX + 350;
  const rStart = d3.event.pageY;

  const tooltipClassName = tooltipClass ? `${tooltipClass}` : 'tooltip-heading';

  if (rEnd > chartPositionRightEnd) {
    offset = chartPositionRightEnd - rEnd;
  } else if (rStart > chartPositionLeftEnd) {
    offset = chartPositionLeftEnd - 35;
  }

  return tooltip
    .attr('class', 'five-val-tt-cont')
    .style('opacity', 1)
    .style('display', 'flex')
    .style('left', d3.event.pageX + offset + 'px')
    .style('top', d3.event.pageY - 140 + 'px')
    .html(
      `<div class=${tooltipClassName}><p class="tt-heading-title">${cleanStr(
        y,
      )}</p><p class=tooltip-date>${date} </p></div><div class=tooltip-inner><div class=tooltip-data-avg><p class="chart-tooltip-title">${val1Title}</p><p class=tooltip-value>${numberWithCommas(
        val1,
      )}</p></div><div class=tooltip-data-median><p class="chart-tooltip-title">${val2Title}</p><p class=tooltip-value>${numberWithCommas(
        val2,
      )}</p></div>
      <div class=tooltip-data-median><p class="chart-tooltip-title">${val3Title}</p><p class=tooltip-value>${numberWithCommas(
        val3,
      )}</p></div><div class=tooltip-data-median><p class="chart-tooltip-title">${val4Title}</p><p class=tooltip-value>${val4}</p></div><div class=tooltip-data-count><p class="chart-tooltip-title">${val5Title}</p><p class=tooltip-value>${val5}</p>
      </div>
      </div>`,
    );
};

//hides tooltip when mouse moves from data point
export const handleTooltipMouseOut = () => {
  return tooltip.style('display', 'none');
};

//positioning of graph + width and height
export const margin = { bottom: 70, left: 50, right: 50, top: 50 },
  width = 1840 - margin.left - margin.right, // Use the window's width
  height = 700 - margin.top - margin.bottom; // Use the window's height

//makes chart responsive
export function responsivefy(svg: any) {
  // get container + svg aspect ratio
  const container = d3.select(svg.node().parentNode),
    width = parseInt(svg.style('width')) + 30,
    height = parseInt(svg.style('height')),
    aspect = width / height;

  // get width of container and resize svg to fit it
  //fails here if it doesnt have anything TODO
  function resize() {
    const targetWidth = parseInt(container.style('width'));
    svg.attr('width', targetWidth);
    svg.attr('height', Math.round(targetWidth / aspect));
  }
  // add viewBox and preserveAspectRatio properties,
  // and call resize so that svg resizes on inital page load
  svg
    .attr('viewBox', '0 0 ' + width + ' ' + height)
    .attr('perserveAspectRatio', 'xMinYMid')
    .call(resize);

  // to register multiple listeners for same event type,
  // you need to add namespace, i.e., 'click.foo'
  // necessary if you call invoke this function for multiple svgs
  //github.com/mbostock/d3/wiki/Selections#on
  docs: https: d3.select(window).on('resize.' + container.attr('id'), resize);
}
