import React, { FunctionComponent, useCallback } from 'react';
import { styled } from '@mui/material/styles';

import { UnstyledUnorderedList } from '../navbar/common/resetUI';
import ExpandIndicator from './expandIndicator';
import NavMenuItem from './navMenuItem';
import { NavDataItem } from './types';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface NavItem {
  badgeLabel?: string;
  icon?: string;
  name: string;
  path?: string;
  search?: string;
}

interface NavGroupProps extends NavItem {
  children?: NavDataItem[];
}

const NavGroup: FunctionComponent<NavGroupProps> = ({
  badgeLabel,
  children,
  icon,
  name,
  path,
  search,
}) => {
  const [isSidebarNavGroupExpanded, setIsSidebarNavGroupExpanded] =
    useLocalStorage<Record<string, boolean>>('isSidebarNavGroupExpanded', {});
  const isExpanded = isSidebarNavGroupExpanded[name] || false;

  const handleExpandClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setIsSidebarNavGroupExpanded(prev => ({
        ...prev,
        [name]: !isExpanded,
      }));
    },
    [isExpanded, name, setIsSidebarNavGroupExpanded],
  );

  return (
    <Container
      data-appcues-target={getAppCuesId({
        componentType: 'container',
        featureName: 'sidebar',
        pageName: 'navigation',
        subType: name,
      })}
      key={name}
    >
      <NavMenuItem
        badgeLabel={badgeLabel}
        icon={icon}
        name={name}
        onClick={handleExpandClick}
        path={path}
        search={search}
      />
      {children?.length && (
        <ExpandIndicatorContainer>
          <ExpandIndicator
            activeRotation='90'
            hidden={false}
            isActive={isExpanded}
            onClick={handleExpandClick}
            rotation='270'
          />
        </ExpandIndicatorContainer>
      )}
      {children && isExpanded && (
        <UnstyledUnorderedList className='subMenu'>
          {children.map(
            ({
              badgeLabel,
              name: subMenuItemName,
              route: subMenuItemRoute,
            }) => (
              <li
                data-appcues-target={getAppCuesId({
                  componentType: 'item',
                  featureName: 'sidebar',
                  pageName: 'navigation',
                  subType: name,
                })}
                key={subMenuItemName}
              >
                <NavMenuItem
                  badgeLabel={badgeLabel}
                  name={subMenuItemName}
                  path={subMenuItemRoute?.path}
                  search={subMenuItemRoute?.search}
                />
              </li>
            ),
          )}
        </UnstyledUnorderedList>
      )}
    </Container>
  );
};

const Container = styled('li')`
  position: relative;
`;
const ExpandIndicatorContainer = styled('div')`
  position: absolute;
  right: 5px;
  top: 0;
  margin: 10px 0;
  display: flex;
`;

export default NavGroup;
