import { Box, styled, useTheme } from '@mui/material';

import {
  Drawer,
  formatToDateAndTime,
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import Section from '../Section';
import { RecommendedFix } from './recommended-fix/RecommendedFix';
import { ErrorPayload } from './ErrorPayload';
import { getChatUrl, getErrorDescriptionByMessage } from './utils';
import useTabs from 'src/components/dashboard-pages/solve-insights/hooks/useTabs';
import { ErrorQueryEvent } from 'src/types/ErrorEventTypes';

interface ErrorDrawerProps {
  error?: ErrorQueryEvent;
  isLoading: boolean;
  onClose: () => void;
}

export default function ErrorDrawer({
  error,
  isLoading,
  onClose,
}: ErrorDrawerProps) {
  const isOpen = !!error?.error_id && !isLoading;

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      {isLoading && (
        <Box p='40px'>
          <Box py='6px'>
            <Skeleton height='24px' width='100%' />
          </Box>
          <Box py='20px'>
            <Skeleton height='400px' width='100%' />
          </Box>
        </Box>
      )}
      {isOpen && <DrawerBody error={error} />}
    </Drawer>
  );
}

function DrawerBody({ error }: { error: ErrorQueryEvent }) {
  const { palette } = useTheme();

  const {
    channel,
    conversation_id: conversationId,
    error_id: errorId,
    error_message: errorMessage,
    error_payload: errorPayload,
    timestamp,
    trace_id,
  } = error;
  // get the index of the Solve Insights Chats tab
  const { tabIdToIndexMap } = useTabs();
  const chatsTabIndex = tabIdToIndexMap.chat;

  // Get the conversation hyperlink
  const conversationUrl = getChatUrl({
    channel,
    chatsTabIndex,
    conversationId,
  });

  const ConversationHyperLink = conversationUrl ? (
    <ConversationLink
      href={conversationUrl}
      rel='noopener noreferrer'
      target='_blank'
    >
      {conversationId}
    </ConversationLink>
  ) : null;

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='32px'
      height='100%'
      p='40px'
      sx={{ overflowY: 'scroll' }}
    >
      <Box py='6px'>
        <Typography variant='font24'>Error Details</Typography>
      </Box>
      <Box>
        <Box display='flex' gap='4px'>
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            Error ID:
          </Typography>
          <Typography color={palette.colors.black} variant='font12Medium'>
            {errorId}
          </Typography>
        </Box>
        <Box display='flex' gap='4px'>
          <Typography color={palette.colors.grey[600]} variant='font12Medium'>
            Timestamp:
          </Typography>
          <Typography color={palette.colors.black} variant='font12Medium'>
            {formatToDateAndTime(timestamp)}
          </Typography>
        </Box>
      </Box>

      <Section
        data={[
          { label: 'Error Message', value: errorMessage },
          {
            label: 'Error Description',
            value: getErrorDescriptionByMessage(errorMessage),
          },
          { label: 'Channel', value: channel },
          {
            label: 'Conversation ID',
            value: ConversationHyperLink || conversationId,
          },
          { label: 'Trace ID', value: trace_id },
        ]}
        label='Summary'
      />
      <RecommendedFix error={error} />
      <ErrorPayload payload={errorPayload} />
    </Box>
  );
}

const ConversationLink = styled('a')`
  text-decoration: none;
`;
