import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { captureException } from '@sentry/react';

import {
  SelectDropdown,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  selectAutomations,
  setAutomationAction,
} from '../../../slices/assist-automations/assistAutomationsSlice';
import { fetchConnector } from '../../../slices/assist-automations/thunks';
import AutomationCard from '../automation-card';
import InputCard from './input-card';
import { Connector } from 'src/services/apiInterfaces';
import * as API from 'src/services/apiV1';
import { automationTemplateCreatedConnectors } from 'src/slices/assist-automations/mockData';
import { AutomationForm } from 'src/slices/assist-automations/types';
import {
  ASSIST_AUTOMATIONS_APPCUES_TARGET,
  ASSIST_AUTOMATIONS_TEXT,
} from 'src/utils/constants';

interface Props {
  isReadOnly: boolean;
}

const RunActionCard: React.FC<React.PropsWithChildren<Props>> = ({
  isReadOnly,
}) => {
  const dispatch = useDispatch();
  const { action, connector } = useSelector(selectAutomations);
  const { setFieldValue } = useFormikContext<AutomationForm>();
  const [createdConnectors, setCreatedConnectors] = useState<Connector[]>([]);
  const [connectorOptions, setConnectorOptions] = useState<
    { label: string; value: string }[]
  >([]);

  // The list of actions available for the currently selected connection
  const actionOptions = useMemo(() => {
    if (!connector || !connector.actions) {
      return [];
    }

    // Exclude actions which don't have inputs and outputs
    const filteredActions = connector.actions.filter(
      action =>
        (action?.output_schema?.properties &&
          Object.keys(action.output_schema.properties).length) ||
        (action?.input_schema.properties &&
          Object.keys(action.input_schema.properties).length),
    );

    return filteredActions.map(action => ({
      label: action.title,
      value: action.action_id,
    }));
  }, [connector]);

  async function changeConnector(connector: string) {
    await dispatch(fetchConnector(connector));

    const currentConnector = createdConnectors.find(
      connectorItem => connectorItem.connector_id === connector,
    );

    if (currentConnector) {
      setFieldValue('emblem_urls', [
        currentConnector.connector_definition.avatar,
      ]);
    }
  }

  function changeAction(actionId: string) {
    if (!connector || !connector.actions) {
      return;
    }

    const _action =
      connector.actions.find(
        connectorAction => connectorAction.action_id === actionId,
      ) || null;

    setFieldValue('actions_output_formatter', {});
    setFieldValue('actions_input_formatter', {});

    if (_action) {
      setFieldValue('action_list', [
        {
          action_id: _action.action_id,
          connector_id: connector.connector_id,
        },
      ]);
    } else {
      setFieldValue('action_list', []);
    }

    dispatch(
      setAutomationAction({
        action: _action,
        connector: connector.connector_id,
      }),
    );
  }

  useEffect(() => {
    const init = async () => {
      try {
        const data = isReadOnly
          ? automationTemplateCreatedConnectors
          : await API.getCreatedConnectors();

        if (data.length) {
          const filteredConnectors = data.filter(connector =>
            connector.connector_types?.includes('action'),
          );
          setCreatedConnectors(filteredConnectors);
          setConnectorOptions(
            filteredConnectors.map((connector: Connector) => ({
              label: connector.connector_definition.name,
              value: connector.connector_id,
            })),
          );
        }
      } catch (err) {
        if (err instanceof Error) {
          captureException(new Error(`Error: ${err.message || err}`));
        }
      }
    };
    init();
  }, [isReadOnly]);

  return (
    <AutomationCard
      dataAppcuesTarget={ASSIST_AUTOMATIONS_APPCUES_TARGET.setupActionSection}
      description={ASSIST_AUTOMATIONS_TEXT.actionDescription}
      title={
        <Typography variant='font16Bold'>
          {ASSIST_AUTOMATIONS_TEXT.actionTitle}
        </Typography>
      }
    >
      <InputWrapper>
        <SelectDropdown
          id='connector'
          label='Available Connectors'
          onChange={connector => {
            if (!isReadOnly) {
              changeConnector(connector.target.value);
              changeAction('');
            }
          }}
          options={connectorOptions}
          placeholder='Select connector'
          value={connector ? connector.connector_id : ''}
        />
      </InputWrapper>
      {Boolean(actionOptions.length) && (
        <>
          <InputWrapper>
            <SelectDropdown
              disabled={!connector || !connector.actions}
              id='actions'
              label='Select Action'
              onChange={action => {
                if (!isReadOnly) {
                  changeAction(action.target.value);
                }
              }}
              options={actionOptions}
              placeholder='Select an API'
              value={action ? action.action_id : ''}
            />

            {action?.description && (
              <Typography
                color={theme.palette.colors.grey[500]}
                variant='font12'
              >
                {action.description}
              </Typography>
            )}
          </InputWrapper>
          {action && <InputCard action={action} isReadOnly={isReadOnly} />}
        </>
      )}
    </AutomationCard>
  );
};

const InputWrapper = styled('div')`
  margin: 16px 0;
  &:last-child {
    margin-bottom: 0px;
  }
`;

export default RunActionCard;
