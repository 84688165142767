import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import DiscoverCard from '../../../discover-card/DiscoverCard';
import { DiscoverTopMoverSkeleton } from '../../../discover-skeleton/DiscoverSkeleton';
import DiscoverMetricTooltip from '../../../discover-tooltip/DiscoverMetricTooltip';
import TopMoversByMetricType from './TopMoversByMetricType';
import NoTopMoversImage from 'src/assets/images/discover-no-top-movers.svg';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import { selectDashboardMetrics } from 'src/reducers/discoverReducer/discoverReducer';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface DashboardTabTopMoversCardProps {
  isMetadataLoading: boolean;
  onMetricClick: (metricName: string) => void;
  timeFilter: TopicTimeFilter;
  timePeriod: TopicPeriodicalFilter;
}

const DashboardTabTopMoversCard = ({
  isMetadataLoading,
  onMetricClick,
  timeFilter,
  timePeriod,
}: DashboardTabTopMoversCardProps) => {
  const { palette } = useTheme();

  const { data, loading } = useSelector(selectDashboardMetrics);

  const { top_movers_by_metric_type: topMoversByMetricType } = data ?? {};

  const renderTopMovers = () => {
    if (loading || isMetadataLoading) {
      return <DiscoverTopMoverSkeleton />;
    }

    if (topMoversByMetricType?.length) {
      return (
        <TopMoversByMetricType
          onMetricClick={onMetricClick}
          timeFilter={timeFilter}
          timePeriod={timePeriod}
          topMoversByMetricType={topMoversByMetricType}
        />
      );
    }

    return (
      <Box
        alignItems='center'
        display='flex'
        flexDirection='column'
        height='100%'
        justifyContent='center'
      >
        <img src={NoTopMoversImage} />
        <Box mt={3}>
          <Typography color={palette.colors.grey[800]} variant='font14'>
            There are no top movers for the selected time period
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <DiscoverCard
      featureName='topmovers'
      headerComponent={
        <Box
          data-appcues-target={getAppCuesId({
            componentType: 'header',
            featureName: 'topmovers',
            pageName: 'discover',
            subType: '',
          })}
          display='flex'
          justifyContent='space-between'
        >
          <Box display='flex'>
            <Typography variant='font16Bold'>Top movers by metrics</Typography>
            <Box
              alignItems='center'
              color={palette.colors.grey[800]}
              display='flex'
              ml={1}
            >
              <DiscoverMetricTooltip metricType='top_movers' />
            </Box>
          </Box>
          <Typography color={palette.colors.grey[700]} variant='font12'>
            {timeFilter.label}
          </Typography>
        </Box>
      }
    >
      {renderTopMovers()}
    </DiscoverCard>
  );
};

export default DashboardTabTopMoversCard;
