import React from 'react';
import { Box, Tooltip as CustomTooltip, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../../discover-dashboard-page/types';
import {
  dateRangeToTimestamp,
  getTooltipForMetric,
  numbersToStringWithCommas,
  numberToCurrency,
  percentageValueAsString,
  stringifyDateRange,
} from '../helpers';
import { OverviewCardInformationSection } from '../overview/OverviewCardInformationSection';
import { handleColorForComparisonChange } from '../overview/utils';
import { InsightsTab } from '../types';
import { getTooltipForMetric as getWorkflowTooltipForMetric } from '../workflows/helpers';
import InsightCard from './InsightCard';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  useGetChatsSavingsQuery,
  useGetWorkflowOverallDeflectionsQuery,
} from 'src/services/insights';
import { InsightsChatsSavingMisc } from 'src/services/insights/types';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';

export interface ChatsSavingsCardProps {
  dateRange: DateRange;
  tab: InsightsTab;
}

interface SavingsDetailProps {
  color: string;
  differenceRate?: number;
  secondaryNumber: number;
  type: 'potential' | 'realized';
}

interface SavingsLineProps {
  infoTooltipContent: string;
  isPrevious?: boolean;
  savings: number;
  tab: InsightsTab;
  tooltipContent:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal;
  type: 'potential' | 'realized';
  widthPercentage: number;
}

const SavingsDetail: React.FC<React.PropsWithChildren<SavingsDetailProps>> = ({
  color,
  differenceRate,
  secondaryNumber,
  type,
}) => {
  const { palette } = useTheme();

  const label = type === 'potential' ? 'non-deflections' : 'deflections';

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '8px' }}>
      <Box
        sx={{
          alignItems: 'baseline',
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
        }}
      >
        <Typography variant='font24'>
          {numbersToStringWithCommas({
            number: secondaryNumber,
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          ':before': {
            backgroundColor: color,
            borderRadius: '2px',
            content: '""',
            display: 'block',
            height: '12px',
            width: '12px',
          },
          alignItems: 'center',
          display: 'flex',
          gap: '4px',
        }}
      >
        <Typography variant='font14'>{label}</Typography>
      </Box>
      {differenceRate && (
        <Typography
          color={handleColorForComparisonChange(palette, type, differenceRate)}
          variant='font14Medium'
        >
          {percentageValueAsString(differenceRate)}
        </Typography>
      )}
    </Box>
  );
};

const SavingsLine: React.FC<React.PropsWithChildren<SavingsLineProps>> = ({
  infoTooltipContent,
  isPrevious = false,
  savings,
  tab,
  tooltipContent,
  type,
  widthPercentage,
}) => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleTrackingEvent = () => {
    emitTrackingEventCallback('insight-tooltip-hover', {
      scope: 'main',
      tab,
      value: `${type}_savings`,
    });
  };

  const barColor1 =
    type === 'potential' ? palette.colors.red[500] : palette.colors.purple[500];
  const barColor2 =
    type === 'potential' ? palette.colors.red[100] : palette.colors.purple[100];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        minWidth: '10%',
        width: `${widthPercentage}%`,
      }}
    >
      <CustomTooltip
        componentsProps={{
          popper: {
            sx: [
              {
                '& .MuiTooltip-arrow': {
                  color: palette.colors.grey[800],
                },
                '& .MuiTooltip-tooltip': {
                  backgroundColor: palette.colors.white,
                  maxWidth: 260,
                  padding: '8px 12px',
                  whiteSpace: 'pre-line',
                },
              },
            ],
          },
        }}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        placement='top'
        title={tooltipContent}
      >
        <Box
          sx={{
            background: isPrevious
              ? `repeating-linear-gradient(
                -45deg,
                ${barColor1},
                ${barColor1} 3px,
                ${barColor2} 3px,
                ${barColor2} 6px
              )`
              : undefined,
            backgroundColor: barColor1,
            borderRadius: '4px',
            display: 'block',
            height: '16px',
            width: '100%',
          }}
        />
      </CustomTooltip>
      <Box alignItems='center' display='flex' gap='4px' textOverflow='ellipsis'>
        <Typography noWrap variant='font14'>
          {numberToCurrency({ number: savings })}
        </Typography>
        <Box display='flex' onMouseEnter={handleTrackingEvent}>
          <Tooltip tooltipContent={infoTooltipContent}>
            <IconInfoCircle size={20} />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export const ChatsSavingsCard: React.FC<
  React.PropsWithChildren<ChatsSavingsCardProps>
> = ({ dateRange, tab }) => {
  const { palette } = useTheme();
  const showComparison = tab === 'overview';
  const isWorkflow = tab === 'workflow';
  const isTopic = tab === 'topic';
  // Hooks
  const timestamps = dateRangeToTimestamp(dateRange);
  const {
    data: chatsSavingData,
    isFetching: isChatsSavingFetching,
    isLoading: isChatsSavingLoading,
  } = useGetChatsSavingsQuery(
    {
      end_timestamp: timestamps.end_timestamp,
      start_timestamp: timestamps.start_timestamp,
      to_compare: showComparison,
      topics_only: isTopic,
    },
    { skip: isWorkflow },
  );
  // Workflow has different endpoint
  const {
    data: workflowOverallDeflections,
    isFetching: isWorkflowOverallDeflectionsFetching,
    isLoading: isWorkflowOverallDeflectionsLoading,
  } = useGetWorkflowOverallDeflectionsQuery(
    {
      end: timestamps.end_timestamp,
      start: timestamps.start_timestamp,
    },
    { skip: !isWorkflow },
  );
  const data = chatsSavingData ?? workflowOverallDeflections;
  const isLoading = isChatsSavingLoading || isWorkflowOverallDeflectionsLoading;
  const isFetching =
    isChatsSavingFetching || isWorkflowOverallDeflectionsFetching;

  const renderTooltipContent = ({
    type,
  }: {
    type: 'potential' | 'realized';
  }) => {
    if (!data) {
      return '';
    }

    const title = capitalizeFirstLetter(`${type} savings`);
    const dateRangeString = stringifyDateRange(dateRange);

    const barColor1 =
      type === 'potential'
        ? palette.colors.red[500]
        : palette.colors.purple[500];
    const barColor2 =
      type === 'potential'
        ? palette.colors.red[100]
        : palette.colors.purple[100];

    const handleTooltipContent = ({
      data,
      isPrevious,
    }: {
      data: InsightsChatsSavingMisc;
      isPrevious?: boolean;
    }) => {
      return (
        <Box display='flex' flexDirection='column' gap='4px'>
          <Box display='flex' gap='8px'>
            <Box
              bgcolor={
                type === 'potential'
                  ? palette.colors.red[500]
                  : palette.colors.purple[500]
              }
              borderRadius='2px'
              height='12px'
              sx={{
                background: isPrevious
                  ? `repeating-linear-gradient(
                    -45deg,
                    ${barColor1},
                    ${barColor1} 3px,
                    ${barColor2} 3px,
                    ${barColor2} 6px
                  )`
                  : undefined,
              }}
              width='12px'
            />
            <Typography color={palette.colors.grey[600]} variant='font12'>
              {isPrevious ? data.date_range : dateRangeString}
            </Typography>
          </Box>
          {data.potential_savings && data.realized_savings && (
            <Typography color={palette.colors.black} variant='font14Bold'>
              {numberToCurrency({
                number:
                  type === 'potential'
                    ? data.potential_savings
                    : data.realized_savings,
                shouldRound: true,
              })}
              <Typography color={palette.colors.grey[600]} variant='font12'>
                {` ${type} saving`}
              </Typography>
            </Typography>
          )}
          {data.deflections && data.non_deflections && (
            <Typography color={palette.colors.black} variant='font14Bold'>
              {numbersToStringWithCommas({
                number:
                  type === 'potential'
                    ? data.non_deflections
                    : data.deflections,
              })}
              <Typography color={palette.colors.grey[600]} variant='font12'>
                {` ${type === 'potential' ? 'non-deflections' : 'deflections'}`}
              </Typography>
            </Typography>
          )}
          {data.deflections_delta_percentage &&
            data.non_deflections_delta_percentage && (
              <Typography
                color={handleColorForComparisonChange(
                  palette,
                  type,
                  type === 'potential'
                    ? data.non_deflections_delta_percentage
                    : data.deflections_delta_percentage,
                )}
                variant='font14Bold'
              >
                {numbersToStringWithCommas({
                  number:
                    type === 'potential'
                      ? data.non_deflections_delta_percentage
                      : data.deflections_delta_percentage,
                  style: 'percent',
                })}
                <Typography color={palette.colors.grey[600]} variant='font12'>
                  {' from previous period'}
                </Typography>
              </Typography>
            )}
        </Box>
      );
    };

    return (
      <Box
        border={`1px solid ${palette.colors.slate[200]}`}
        borderRadius='4px'
        display='flex'
        flexDirection='column'
        gap='12px'
        minWidth='230px'
        padding='8px'
      >
        <Typography color={palette.colors.black} variant='font14Bold'>
          {title}
        </Typography>
        <Box display='flex' flexDirection='column' gap='8px'>
          {handleTooltipContent({ data: data?.misc })}
          {data?.misc.previous &&
            handleTooltipContent({
              data: data?.misc.previous,
              isPrevious: true,
            })}
        </Box>
      </Box>
    );
  };

  const handleTitle = () => {
    if (showComparison) {
      return 'Deflections';
    }
    if (isTopic) {
      return `Chats with ${capitalizeFirstLetter(tab)}s`;
    }

    return 'Chats';
  };

  const title = handleTitle();

  let NonDeflectionCard = null,
    DeflectionCard = null,
    NonDeflectionSavingLine = null,
    DeflectionsSavingLine = null,
    NonDeflectionsSavingLinePrev = null,
    DeflectionsSavingLinePrev = null;

  if (data) {
    NonDeflectionCard = (
      <SavingsDetail
        color={palette.colors.red[500]}
        differenceRate={data?.misc.non_deflections_delta_percentage}
        secondaryNumber={data?.misc.non_deflections}
        type='potential'
      />
    );
    DeflectionCard = (
      <SavingsDetail
        color={palette.colors.purple[500]}
        differenceRate={data?.misc.deflections_delta_percentage}
        secondaryNumber={data?.misc.deflections}
        type='realized'
      />
    );
    if (data?.misc.non_deflections !== 0) {
      NonDeflectionSavingLine = (
        <SavingsLine
          infoTooltipContent={
            isWorkflow
              ? getWorkflowTooltipForMetric(
                  'potential_savings',
                  data?.misc.cost_per_ticket,
                )
              : getTooltipForMetric('potential_savings')
          }
          savings={data?.misc.potential_savings}
          tab={tab}
          tooltipContent={renderTooltipContent({
            type: 'potential',
          })}
          type='potential'
          widthPercentage={
            (data?.misc.non_deflections / data?.misc.chats) * 100
          }
        />
      );
    }
    if (data?.misc.deflections) {
      DeflectionsSavingLine = (
        <SavingsLine
          infoTooltipContent={
            isWorkflow
              ? getWorkflowTooltipForMetric(
                  'realized_savings',
                  data?.misc.cost_per_ticket,
                )
              : getTooltipForMetric(
                  'realized_savings',
                  data?.misc.cost_per_ticket,
                )
          }
          savings={data?.misc.realized_savings}
          tab={tab}
          tooltipContent={renderTooltipContent({
            type: 'realized',
          })}
          type='realized'
          widthPercentage={(data?.misc.deflections / data?.misc.chats) * 100}
        />
      );
    }
    if (data?.misc.previous && data?.misc.previous?.non_deflections !== 0) {
      NonDeflectionsSavingLinePrev = (
        <SavingsLine
          infoTooltipContent={getTooltipForMetric('potential_savings')}
          isPrevious
          savings={data?.misc.previous.potential_savings}
          tab={tab}
          tooltipContent={renderTooltipContent({
            type: 'potential',
          })}
          type='potential'
          widthPercentage={
            (data?.misc.previous.non_deflections / data?.misc.previous.chats) *
            100
          }
        />
      );
    }
    if (data?.misc.previous && data?.misc.previous.deflections !== 0) {
      DeflectionsSavingLinePrev = (
        <SavingsLine
          infoTooltipContent={getTooltipForMetric(
            'realized_savings',
            data?.misc.previous.cost_per_ticket,
          )}
          isPrevious
          savings={data?.misc.previous.realized_savings}
          tab={tab}
          tooltipContent={renderTooltipContent({
            type: 'realized',
          })}
          type='realized'
          widthPercentage={
            (data?.misc.previous.deflections / data?.misc.previous.chats) * 100
          }
        />
      );
    }
  }

  return (
    <InsightCard
      headerRightText={
        showComparison ? stringifyDateRange(dateRange) : undefined
      }
      isFetching={isFetching}
      isLoading={isLoading}
      justifyContent={showComparison ? 'space-between' : ''}
      title={title}
    >
      {data && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              justifyContent: 'start',
            }}
          >
            {isTopic ? (
              <>
                {NonDeflectionCard && NonDeflectionCard}
                {DeflectionCard && DeflectionCard}
              </>
            ) : (
              <>
                {DeflectionCard && DeflectionCard}
                {NonDeflectionCard && NonDeflectionCard}
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              flexWrap: 'nowrap',
              gap: '1px',
            }}
          >
            {isTopic ? (
              <>
                {NonDeflectionSavingLine && NonDeflectionSavingLine}
                {DeflectionsSavingLine && DeflectionsSavingLine}
              </>
            ) : (
              <>
                {DeflectionsSavingLine && DeflectionsSavingLine}
                {NonDeflectionSavingLine && NonDeflectionSavingLine}
              </>
            )}
          </Box>
          {showComparison && data?.misc.previous && (
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'nowrap',
                gap: '1px',
              }}
            >
              {isTopic ? (
                <>
                  {NonDeflectionsSavingLinePrev && NonDeflectionsSavingLinePrev}
                  {DeflectionsSavingLinePrev && DeflectionsSavingLinePrev}
                </>
              ) : (
                <>
                  {DeflectionsSavingLinePrev && DeflectionsSavingLinePrev}
                  {NonDeflectionsSavingLinePrev && NonDeflectionsSavingLinePrev}
                </>
              )}
            </Box>
          )}
        </Box>
      )}
      <Box />
      <Box />
      {showComparison && <OverviewCardInformationSection />}
    </InsightCard>
  );
};
