import React, { useEffect, useState } from 'react';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';

import {
  Button,
  TextField,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import AutomationCard from '../automation-card';
import { formatVariableStyle } from '../helpers';
import cloneDeep from 'lodash/fp/cloneDeep';
import EqualIcon from 'src/assets/images/equal-automation-icon.svg';
import { Automation } from 'src/services/apiInterfaces';
import { selectAutomations } from 'src/slices/assist-automations/assistAutomationsSlice';
import { automationTemplate } from 'src/slices/assist-automations/mockData';
import { AutomationForm } from 'src/slices/assist-automations/types';
import {
  ASSIST_AUTOMATIONS_APPCUES_TARGET,
  ASSIST_AUTOMATIONS_TEXT,
} from 'src/utils/constants';

interface Props {
  isReadOnly: boolean;
}

const InputFieldCard: React.FC<React.PropsWithChildren<Props>> = ({
  isReadOnly,
}) => {
  const { errors, setFieldValue, touched, values } =
    useFormikContext<AutomationForm>();
  const [agentProvidedVariables, setAgentProvidedVariables] = useState<
    string[]
  >([]);
  const { action } = useSelector(selectAutomations);

  useEffect(() => {
    if (isReadOnly) {
      setAgentProvidedVariables([
        String(
          automationTemplate.actions_input_formatter.get_shipment_status[0]
            .order_id,
        ),
        String(
          automationTemplate.actions_input_formatter.get_shipment_status[1]
            .customer_email,
        ),
      ]);
    } else if (action?.input_schema.properties) {
      let inputFields: Automation['input_fields'] = [];
      let _agentProvidedVariables: string[] = [];

      Object.entries(action.input_schema.properties).forEach(
        ([key, property]) => {
          const inputText =
            (typeof property === 'object' && property.title) || '';

          values.actions_input_formatter[action?.action_id]?.forEach(input => {
            const inputValue = String(input[key]);

            if (inputValue && String(inputValue)[0] === '$') {
              inputFields = [
                ...inputFields,
                {
                  context_variable: inputValue,
                  input_text: inputText,
                },
              ];

              _agentProvidedVariables = [
                ..._agentProvidedVariables,
                String(inputValue),
              ];
            }
          });
        },
      );

      if (!inputFields.length) {
        inputFields = [{ context_variable: '', input_text: '' }];
      }

      setFieldValue('input_fields', inputFields);
      setAgentProvidedVariables(_agentProvidedVariables);
    }
  }, [
    action,
    values.actions_input_formatter,
    setFieldValue,
    values.org_id,
    isReadOnly,
  ]);

  return (
    <AutomationCard
      dataAppcuesTarget={ASSIST_AUTOMATIONS_APPCUES_TARGET.setInputSection}
      description={ASSIST_AUTOMATIONS_TEXT.inputFieldCardDescription}
      title={
        <Typography variant='font16Bold'>
          {ASSIST_AUTOMATIONS_TEXT.inputFieldCardTitle}
        </Typography>
      }
    >
      <Field name='input_description'>
        {({ field, meta }: FieldProps) => (
          <InputWrapper isReadOnly={isReadOnly}>
            <TextField
              {...field}
              description='Max 62 characters'
              error={meta.touched && meta.error}
              label='Description'
              maxLength={62}
              placeholder={ASSIST_AUTOMATIONS_TEXT.inputDescriptionPlaceholder}
              required={true}
              value={values.input_description || ''}
            />
          </InputWrapper>
        )}
      </Field>
      <FieldArray
        name='input_fields'
        render={arrayHelpers => (
          <div>
            <InputRows>
              {values.input_fields.map((inputField, index) => {
                const isInputDisabled = agentProvidedVariables.includes(
                  inputField.context_variable,
                );

                return (
                  <InputRow key={index}>
                    <Field name={`input_fields[${index}].input_text`}>
                      {({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          disabled={isInputDisabled}
                          error={
                            touched.input_fields && Boolean(errors.input_fields)
                          }
                          label={index === 0 ? 'Input field' : ''}
                          maxLength={42}
                          placeholder='Order number'
                          required={true}
                        />
                      )}
                    </Field>

                    <Equal row={index} src={EqualIcon} />

                    <Field name={`input_fields[${index}].context_variable`}>
                      {({ field }: FieldProps) => (
                        <TextField
                          {...field}
                          disabled={isInputDisabled}
                          error={
                            touched.input_fields && Boolean(errors.input_fields)
                          }
                          label={
                            index === 0 ? (
                              <InputVariableLabel>
                                {ASSIST_AUTOMATIONS_TEXT.assignVariablesText}
                                <Tooltip
                                  tooltipContent={
                                    ASSIST_AUTOMATIONS_TEXT.assignVariablesTooltipContent
                                  }
                                >
                                  <InfoIcon fontSize='small' />
                                </Tooltip>
                              </InputVariableLabel>
                            ) : (
                              ''
                            )
                          }
                          maxLength={42}
                          onChange={e => {
                            const formattedVariableStyle = formatVariableStyle(
                              e.target.value,
                            );

                            const copiedInput = cloneDeep(values.input_fields);

                            if (formattedVariableStyle) {
                              copiedInput[index]['context_variable'] =
                                formattedVariableStyle;
                            }

                            setFieldValue('input_fields', copiedInput);
                          }}
                          placeholder='$order'
                          required={true}
                        />
                      )}
                    </Field>
                  </InputRow>
                );
              })}
            </InputRows>
            <Button
              onClick={() => {
                if (!isReadOnly) {
                  arrayHelpers.push({ context_variable: '', input_text: '' });
                }
              }}
              startIcon={<AddIcon />}
              variant='ghost'
            >
              Add new field
            </Button>
            <Button
              disabled={values.input_fields.length === 1}
              onClick={() => {
                if (!isReadOnly) {
                  arrayHelpers.remove(values.input_fields.length - 1);
                }
              }}
              startIcon={<RemoveIcon />}
              variant='ghost'
            >
              Delete last field
            </Button>
          </div>
        )}
      />
    </AutomationCard>
  );
};

const InputWrapper = styled('div')<{ isReadOnly: boolean }>`
  margin: 16px 0;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ isReadOnly }) => isReadOnly && 'pointer-events: none;'}
`;

const Equal = styled('img')<{ row: number }>`
  ${({ row }) => (row === 0 ? 'margin: 26px 17px 0;' : 'margin: 0 17px 0;')}
`;

const InputVariableLabel = styled('div')`
  align-items: center;
  display: flex;
  justify-content: start;
`;

const InputRow = styled('div')`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const InputRows = styled('div')`
  margin-bottom: 8px;
`;

export default InputFieldCard;
