import React from 'react';
import 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import {
  formatToDateAndTime,
  theme,
} from '@forethought-technologies/forethought-elements';
import { useGetBuilderQueryParams } from '../hooks';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import {
  Features,
  Pages,
} from 'src/pages/workflow-builder-edit/workflow-builder/appCuesIdentifiers';
import {
  selectCanvasWorkflowBuilder,
  selectIntentTitle,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectCurrentWorkflowVersion,
  selectCurrentWorkflowVersions,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  getWorkflowVersion,
  loadDraftWorkflow,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { WorkflowVersion } from 'src/types/workflowBuilderAPITypes';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';

type VersionProps = {
  resetCanvasModes: () => void;
  versionData: WorkflowVersion;
};

const VersionCard: React.FC<React.PropsWithChildren<VersionProps>> = ({
  resetCanvasModes,
  versionData,
}) => {
  const dispatch = useDispatch();
  const activeVersion = useSelector(selectCurrentWorkflowVersion);
  const { workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const versions = useSelector(selectCurrentWorkflowVersions);
  const intentTitle = useSelector(selectIntentTitle);
  const canvasData = useSelector(selectCanvasWorkflowBuilder);

  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );

  const date =
    versionData.modified_date &&
    formatToDateAndTime(versionData.modified_date + 'Z');

  return (
    <Container
      active={versionData.version === activeVersion}
      aria-label={`${
        versionData.version === activeVersion ? 'selected' : ''
      } version ${versionData.version}`}
      data-appcues-target={getAppCuesId({
        componentType: 'VersionHistorySidePanel',
        featureName: Features.CANVAS,
        pageName: Pages.SOLVE,
        subType: `${versionData.is_draft ? 'Draft' : 'Saved'}Button`,
      })}
      onClick={() => {
        if (!!selectedWorkflowId) {
          const evenType = versionData.is_draft
            ? FlamethrowerTrackingEventTypes.NEW_WORKFLOW_DRAFT_VERSION_SELECTED
            : FlamethrowerTrackingEventTypes.LIVE_WORKFLOW_VERSION_SELECTED;

          if (versionData.version === activeVersion && versions.length === 1) {
            dispatch(loadDraftWorkflow(selectedWorkflowId));
          } else {
            dispatch(
              getWorkflowVersion({
                intentWorkflowId: selectedWorkflowId,
                version: versionData.version,
              }),
            );
          }
          resetCanvasModes();

          dispatchTrackingAction(evenType, {
            intent_id: selectedWorkflowId,
            intent_name: intentTitle,
            org_id: canvasData.org_id,
          });
        }
      }}
    >
      <h3>{!!versionData.modified_date ? date : 'Latest'}</h3>
      <p>{versionData.is_draft ? 'Draft' : 'Saved'} Version</p>
    </Container>
  );
};

export default VersionCard;

const Container = styled('button')<{ active: boolean }>`
  height: 62px;
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  background: ${props =>
    props.active ? theme.palette.colors.slate[100] : 'transparent'};
  cursor: pointer;

  &:hover {
    background: ${theme.palette.colors.slate[100]};
  }

  h3 {
    font-weight: ${props => (props.active ? 700 : 400)};
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  p {
    color: ${theme.palette.colors.grey[600]};
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    font-weight: 400;
  }
`;
