import { sendAuthRequestWithErrorHandling } from '../api';
import {
  AuditFilterValues,
  AuditQueryRequestBody,
  AuditQueryResponse,
} from 'src/types/auditTypes';

const AUDIT_BASE_URL = 'audit/v0';

export const getAuditEventLogs = async (
  body: AuditQueryRequestBody,
): Promise<AuditQueryResponse> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}${AUDIT_BASE_URL}/query`,
    body,
    'POST',
  );
};

export const getAuditEventLogFilters = async (): Promise<AuditFilterValues> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}${AUDIT_BASE_URL}/filter-values`,
    {},
    'GET',
  );
};
