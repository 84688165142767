import { SSEL_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { Connector, ConnectorDefinition } from 'src/services/apiInterfaces';
import { setIsUpdateLoading } from 'src/slices/connectors/connectorsSlice';

const connectorApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getConnectors: builder.query<
      {
        availableConnectors: ConnectorDefinition[];
        createdConnectors: Connector[];
      },
      number
    >({
      async queryFn(_arg, { dispatch }, _extraOptions, fetchWithBQ) {
        const [availableData, createdData] = await Promise.all([
          fetchWithBQ(SSEL_BASE_URL + '/connectors/available'),
          fetchWithBQ(SSEL_BASE_URL + '/connectors/created'),
        ]);

        dispatch(setIsUpdateLoading(false));

        return {
          data: {
            availableConnectors: availableData.data as ConnectorDefinition[],
            createdConnectors: createdData.data as Connector[],
          },
        };
      },
    }),
    getCreatedConnectors: builder.query<Connector[], void>({
      query: () => ({
        url: SSEL_BASE_URL + '/connectors/created',
      }),
    }),
  }),
});

export const { useGetConnectorsQuery, useGetCreatedConnectorsQuery } =
  connectorApi;
