import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  NON_RTE_LOADING_STATE_DEBOUNCE_DELAY,
  RTE_LOADING_STATE_DEBOUNCE_DELAY,
} from '../constants';
import debounce from 'lodash/fp/debounce';
import {
  selectUiLoadingState,
  setIsGenericFieldsLoading,
  setIsHighFrequencyFieldsLoading,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';

export const useLoadingState = () => {
  const dispatch = useAppDispatch();
  const uiLoadingState = useSelector(selectUiLoadingState);

  const debouncedNonRteLoadingUpdate = useMemo(
    () =>
      debounce(NON_RTE_LOADING_STATE_DEBOUNCE_DELAY, () =>
        dispatch(setIsGenericFieldsLoading(false)),
      ),
    [dispatch],
  );

  const debouncedRteLoadingUpdate = useMemo(
    () =>
      debounce(RTE_LOADING_STATE_DEBOUNCE_DELAY, () =>
        dispatch(setIsHighFrequencyFieldsLoading(false)),
      ),
    [dispatch],
  );

  useEffect(() => {
    if (uiLoadingState.isGenericFieldsLoading) {
      debouncedNonRteLoadingUpdate();
    }
    if (uiLoadingState.isHighFrequencyFieldsLoading) {
      debouncedRteLoadingUpdate();
    }
  }, [uiLoadingState, debouncedNonRteLoadingUpdate, debouncedRteLoadingUpdate]);
};
