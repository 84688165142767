import { html } from '@codemirror/lang-html';
import { Box, styled } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';

import IntentEmailForm from '../IntentEmailForm';
import { ToolbarFormComponentProps } from 'src/components/email-builder/types';
import { IntentEmailConfigurationHtmlComponent } from 'src/pages/workflow-builder-edit/types';

const IntentEmailHtmlForm = ({
  component,
  onClickCancel,
  onClickSave,
  setActiveComponentFields,
}: ToolbarFormComponentProps) => {
  const componentFields =
    component.component_fields as IntentEmailConfigurationHtmlComponent['component_fields'];

  const content = componentFields.content;

  return (
    <IntentEmailForm
      isSaveButtonDisabled={false}
      onClickCancel={() => onClickCancel()}
      onClickSave={() => onClickSave()}
    >
      <Container>
        <CodeMirror
          extensions={[html({})]}
          height='calc(100vh - 286px)'
          onChange={value => {
            setActiveComponentFields({
              ...componentFields,
              content: value,
            });
          }}
          value={content}
          width='100%'
        />
      </Container>
    </IntentEmailForm>
  );
};

export default IntentEmailHtmlForm;

const Container = styled(Box)`
  border: 1px solid ${props => props.theme.palette.grey[300]};
  border-radius: 8px;
  .cm-scroller {
    font-size: 14px !important;
  }
  .cm-editor {
    border-radius: 8px;
  }

  .cm-focused {
    outline: none;
  }

  .cm-gutters {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }
`;
