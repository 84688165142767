import { Box } from '@mui/material';

import AutomationListItem from '../automation-list-item/AutomationListItem';
import { EmailAutomation } from 'src/pages/workflow-builder-edit/types';
import { useUpdateEmailAutomationOrderMutation } from 'src/services/email-automation/emailAutomationApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface ActiveAutomationsPageProps {
  /** List of automations */
  automationsList: EmailAutomation[];
  /** Callback to modify activate automations order */
  setActiveAutomationsOrder: (dragIndex: number, dropIndex: number) => void;
}

const ActiveAutomationsPage = ({
  automationsList,
  setActiveAutomationsOrder,
}: ActiveAutomationsPageProps) => {
  const dispatch = useAppDispatch();

  const [updateEmailAutomationOrder] = useUpdateEmailAutomationOrderMutation();

  return (
    <Box display='flex' flex='1' flexDirection='column' gap='8px'>
      {automationsList.map((automation, index) => (
        <AutomationListItem
          emailAutomation={automation}
          index={index}
          isDraggable
          key={automation.email_automation_id}
          onDrag={(dragIndex, dropIndex) => {
            setActiveAutomationsOrder(dragIndex, dropIndex);
          }}
          onDrop={(dragIndex, hoverIndex) => {
            if (hoverIndex !== dragIndex) {
              updateEmailAutomationOrder({
                email_automation_ids: automationsList.map(
                  automation => automation.email_automation_id,
                ),
              })
                .unwrap()
                .then(() => {
                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: 4000,
                      title: 'Automation order updated successfully.',
                      variant: 'default',
                    }),
                  );
                })
                .catch(error => {
                  console.error(error);
                  dispatch(
                    setGlobalToastOptions({
                      autoHideDuration: 4000,
                      title:
                        'Automation order could not be updated. Please try again.',
                      variant: 'danger',
                    }),
                  );
                });
            }
          }}
        />
      ))}
    </Box>
  );
};

export default ActiveAutomationsPage;
