import { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import CreatePasswordForm from '../create-password-form';
import CreatePasswordError from './CreatePasswordError';
import { sendTrackingEvent } from 'src/actions/search';
import LandingPageLayout from 'src/components/reusable-components/landing-page-layout';
import Spinner from 'src/components/spinner';
import { selectTimeRange } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import * as API from 'src/services/api';
import { Routes } from 'src/utils/enums';

const CreatePassword = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { code, company_name: companyName, username } = queryString.parse(hash);
  const hasCredentials = !!(code && username);
  const dispatch = useDispatch();
  const timeRange = useSelector(selectTimeRange);

  const handleMagicLinkError = useCallback(() => {
    navigate({ hash: '' });
  }, [navigate]);

  useEffect(() => {
    // If SSO is enabled for this org, redirect to sso login page
    const orgName = window.location.hostname.split('.')[0];
    API.getOrgDiscovery(orgName)
      .then(res => {
        if (res.is_sso_enabled) {
          navigate(Routes.SINGLE_SIGN_ON);
        }
      })
      .finally(() => setIsLoading(false));
  }, [navigate]);

  return (
    <LandingPageLayout>
      {isLoading ? (
        <Spinner />
      ) : hasCredentials ? (
        <CreatePasswordForm
          handleMagicLinkError={handleMagicLinkError}
          handleSuccess={() => {
            sendTrackingEvent(
              dispatch,
              'user-created-self-registration',
              timeRange,
              '',
              {
                company_name: companyName,
                username,
              },
            );
            navigate(Routes.INTEGRATIONS);
          }}
          oldPassword={code.toString()}
          title={`Join ${companyName || 'your team'} on Forethought`}
          username={username.toString()}
        />
      ) : (
        <CreatePasswordError />
      )}
    </LandingPageLayout>
  );
};

export default CreatePassword;
