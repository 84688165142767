export enum ActionBuilderActions {
  CLEAR_OUTPUT_PARAMETERS_MODAL_ACTION_DATA = 'clear/outputParametersModalActionData',
  CLEAR_SELECTED_ACTION = 'clear/selectedAction',
  GET_ACTION_BUILDER_ACTIONS_SUCCESS = 'get/actionBuilderActionsSuccess',
  GET_ACTION_BUILDER_ACTIVE_INTEGRATIONS_SUCCESS = 'get/actionBuilderActiveIntegrationsSuccess',
  SET_ACITON_BUILDER_ACTIONS_LOADING = 'set/actionBuilderActionsLoading',
  SET_CONTEXT_VARIABLES_SETTINGS_ERROR_STATE = 'set/contextVariablesSettingsErrorState',
  SET_CUSTOMIZABLE_ACTION_ID = 'set/customizableActionId',
  SET_OUTPUT_PARAMETER_MODAL_ACTION_DATA = 'set/outputParameterModalActionData',
  SET_SELECTED_ACTION_TEMPLATE = 'set/selectedActionTemplate',
  SET_SELECTED_INITIAL_ACTION_NAME = 'set/selectedInitialActionName',
}
