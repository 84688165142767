import { useDispatch } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  TEMPLATE_CLONE_TOAST_OPTIONS,
  TEMPLATE_INTENT_ID,
  TEMPLATE_INTENT_WORKFLOW_ID_PREFIX,
} from '../../constants';
import { ActivenessIndicator } from '../action-drawer/ActionDrawer';
import { TemplateBadge } from 'src/components/template-badge';
import {
  useCustomizeAutoflowTemplateMutation,
  useGetAutoflowTemplatesQuery,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { TemplateAutoflowIntentWorkflow } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

interface TemplateDrawerProps {
  intentWorkflowId: string;
}

export const TemplateDrawer = ({ intentWorkflowId }: TemplateDrawerProps) => {
  const { data = [] } = useGetAutoflowTemplatesQuery();

  return (
    <Box display='flex' flexDirection='column' gap={2}>
      {data.map(template => (
        <TemplateCard
          isActive={template.intent_workflow_id === intentWorkflowId}
          key={template.intent_workflow_id}
          {...template}
        />
      ))}
    </Box>
  );
};

const TemplateCard = ({
  intent_workflow_id: intentWorkflowId,
  isActive,
  name,
  policy_description: policyDescription,
}: TemplateAutoflowIntentWorkflow & { isActive: boolean }) => {
  const navigate = useNavigate();
  const baseParams = new URLSearchParams({
    intentId: TEMPLATE_INTENT_ID,
    mode: 'autoflow',
    workflowId: intentWorkflowId,
  });
  const [customizeAutoflowTemplate] = useCustomizeAutoflowTemplateMutation();
  const dispatch = useDispatch();
  const templateDetailUrl = `${Routes.WORKFLOW_BUILDER_EDIT}?${baseParams}`;

  return (
    <Box
      border='1px solid'
      borderColor={theme =>
        isActive
          ? theme.palette.colors.purple[500]
          : theme.palette.colors.slate[200]
      }
      borderRadius='8px'
      onClick={() => {
        navigate(templateDetailUrl);
      }}
      sx={{ cursor: 'pointer' }}
    >
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        px={3}
        py={2}
      >
        <Typography variant='font14Bold'>{name}</Typography>
        <Box alignItems='center' display='flex' gap={1}>
          <TemplateBadge />
          <ActivenessIndicator isActive={isActive} />
        </Box>
      </Box>
      <Box
        dangerouslySetInnerHTML={{ __html: policyDescription }}
        fontSize='14px'
        maxHeight='140px'
        overflow='hidden'
        px={3}
      />
      <Box
        borderTop={theme => '1px solid ' + theme.palette.colors.slate[200]}
        display='flex'
        gap={1}
        p={2}
      >
        <Button
          fullWidth
          onClick={e => {
            e.stopPropagation();

            const previewPath = generatePath(Routes.WORKFLOW_BUILDER_PREVIEW, {
              channel: 'widget',
            });

            navigate({
              pathname: previewPath,
              search: `?intentId=${
                TEMPLATE_INTENT_WORKFLOW_ID_PREFIX + intentWorkflowId
              }&mode=autoflow`,
            });
          }}
          variant='ghost'
        >
          Preview
        </Button>
        <Button
          fullWidth
          onClick={async e => {
            e.stopPropagation();

            const response = await customizeAutoflowTemplate(intentWorkflowId);

            if (!('data' in response)) {
              return;
            }

            const { data } = response;
            const baseParams = new URLSearchParams({
              mode: 'autoflow',
              workflowId: data.intent_workflow_id,
            });

            navigate(`${Routes.WORKFLOW_BUILDER_EDIT}?${baseParams}`);

            dispatch(setGlobalToastOptions(TEMPLATE_CLONE_TOAST_OPTIONS));
          }}
          variant='ghost'
        >
          Clone
        </Button>
      </Box>
    </Box>
  );
};
