import { CSSProperties, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import Box from '@mui/material/Box';

import {
  DiscoverAutomationCardLoadingSkeleton,
  DiscoverAutomationCardsHeader,
} from '../components';
import EmptyTabState from '../components/EmptyTabState';
import { ITEM_HEIGHT, ITEM_MARGIN, MAX_RECOMMENDATIONS } from '../constants';
import { useGetKnowledgeGapRecommendations } from '../hooks/useGetKnowledgeGapRecommendations';
import { AutomationTabComponentProps } from '../types';
import KnowledgeGapArticleCard from './KnowledgeGapArticleCard';
import debounce from 'lodash/fp/debounce';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';

const KnowledgeGapTab = ({
  hasScrolled,
  setHasScrolled,
}: AutomationTabComponentProps) => {
  const { data, loading } = useGetKnowledgeGapRecommendations();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const onMouseEnter = useMemo(() => {
    return debounce(
      DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT,
      (topicName: string) =>
        emitTrackingEventCallback(
          'discover-knowledge-gap-recommendation-hovered',
          {
            page: 'Automation',
            'page-area': 'Knowledge gap',
            'topic-name': topicName,
          },
        ),
    );
  }, [emitTrackingEventCallback]);

  const shouldShowHeader = loading || Boolean(data?.articles.length);

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const article = data?.articles[index];
    if (!article) {
      return <Box sx={{ ...style }} />;
    }

    return (
      <Box pb='16px'>
        <KnowledgeGapArticleCard
          article={article}
          onMouseEnter={onMouseEnter}
          style={{ ...style, height: Number(style.height) - 16 }}
        />
      </Box>
    );
  };

  const renderKnowledgeGapArticles = () => {
    if (loading) {
      return Array(MAX_RECOMMENDATIONS)
        .fill(undefined)
        .map((_, index) => (
          <DiscoverAutomationCardLoadingSkeleton key={index} variant='topic' />
        ));
    }

    if (!data?.articles.length) {
      return <EmptyTabState type='knowledge' />;
    }

    return (
      <Box height='71vh'>
        <AutoSizer>
          {({ height = 0, width = 0 }) => (
            <List
              height={height}
              itemCount={data.articles.length + 1} // adding extra item for better scroll positioning
              itemSize={ITEM_HEIGHT + ITEM_MARGIN} // 150px height +16px margin bottom
              onScroll={scrollProps => {
                if (!scrollProps.scrollOffset) {
                  setHasScrolled(false);
                  return;
                }

                setHasScrolled(true);
              }}
              overscanCount={10}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </Box>
    );
  };

  return (
    <>
      {shouldShowHeader && (
        <DiscoverAutomationCardsHeader
          hasScrolled={hasScrolled}
          title='Article'
        />
      )}
      {<Box pt='1px'>{renderKnowledgeGapArticles()}</Box>}
    </>
  );
};

export default KnowledgeGapTab;
