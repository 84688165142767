import { styled } from '@mui/material';

import { GenerativeResponseModelFooterButtonAction } from '../email-builder/types';
import ResponseGenerationModalBody from './ResponseGenerationModalBody';
import ResponseGenerationModalFooter from './ResponseGenerationModalFooter';
import ResponseGenerationModalHeader from './ResponseGenerationModalHeader';
import { Wrapper } from 'src/pages/workflow-builder-edit/email-builder-page/intent-email-builder/components/DynamicArticleCard';
import { GenerateResponseActionType } from 'src/types/workflowBuilderAPITypes';

interface ResponseGenerationModalProps {
  /** Action executed by the user */
  action: GenerateResponseActionType;
  /** Generated response */
  generatedResponse: string;
  /** Determines if the request to get the response is in progress */
  isLoadingResponse: boolean;
  /** Function to call when the close button is clicked */
  onClose: () => void;
  /** Function to call when a button in the footer is clicked */
  onFooterButtonClick: (
    action: GenerativeResponseModelFooterButtonAction,
  ) => void;
}

const ResponseGenerationModal = ({
  action,
  generatedResponse,
  isLoadingResponse,
  onClose,
  onFooterButtonClick,
}: ResponseGenerationModalProps) => {
  return (
    <Container>
      <ResponseGenerationModalHeader
        isLoadingResponse={isLoadingResponse}
        onClose={onClose}
      />
      {generatedResponse && (
        <ResponseGenerationModalBody
          action={action}
          generatedResponse={generatedResponse}
        />
      )}
      <ResponseGenerationModalFooter
        isLoadingResponse={isLoadingResponse}
        onClick={action => onFooterButtonClick(action)}
      />
    </Container>
  );
};

export default ResponseGenerationModal;

const Container = styled(Wrapper)`
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;
