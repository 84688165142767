import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getDiscoverRecommendations } from 'src/actions/discover/discover';
import { selectRecommendations } from 'src/reducers/discoverReducer/discoverReducer';
import { useAppDispatch } from 'src/store/hooks';

export const useGetRecommendations = () => {
  const dispatch = useAppDispatch();
  const recommendations = useSelector(selectRecommendations);

  useEffect(() => {
    dispatch(getDiscoverRecommendations());
  }, [dispatch]);

  return recommendations;
};
