import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import ModelHeaderBanner from '../triage-model-detail-page/common/ModelHeaderBanner';
import { deriveNewModelFromIntegrationChange } from '../triage-model-detail-page/helpers';
import { IntegrationChangeParams } from '../triage-model-detail-page/types';
import NotFound from './NotFound';
import { tabComponents } from './tabComponents';
import VersionControlList from './VersionControlList';
import VersionControlTabs from './VersionControlTabs';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import Spinner from 'src/components/spinner';
import TriageLabelMappingDrawer from 'src/components/triage-label-mapping-drawer';
import { useStateParams } from 'src/hooks/hooks';
import {
  useGetHelpdeskQuery,
  useGetModelVersionQuery,
  useGetModelVersionsQuery,
  usePatchSelfServeTriageModelMutation,
} from 'src/services/triage/triageApi';
import { Routes } from 'src/utils/enums';

const TriageVersionControlDetail = () => {
  const { palette } = useTheme();
  const { modelId = '' } = useParams<'modelId'>();
  const [labelMappingIsOpen, setLabelMappingIsOpen] = useState(false);
  const [tabIndex, setTabIndex] = useStateParams({
    deserialize: Number,
    initialState: 0,
    paramsName: 'tab',
    serialize: String,
    validator: Boolean,
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const version = searchParams.get('version');
  const {
    data: versions,
    isError,
    isFetching,
    isLoading,
    isSuccess,
  } = useGetModelVersionsQuery(modelId);
  const {
    data: selectedVersion,
    isFetching: isFetchingVersion,
    isLoading: isLoadingVersion,
  } = useGetModelVersionQuery(
    { modelId, versionId: version || '' },
    { skip: !version },
  );
  const isModelLoading = isFetchingVersion || isLoadingVersion;

  const [patchModel] = usePatchSelfServeTriageModelMutation();
  const { data: helpdeskData } = useGetHelpdeskQuery();

  useEffect(() => {
    if (isSuccess && versions.length === 0) {
      navigate(`${Routes.PREDICTIONS_SETTINGS}/models/${modelId}`);
    }
  }, [isSuccess, versions, navigate, modelId]);

  if (isLoading || isLoadingVersion) {
    return <Spinner />;
  }

  if (!modelId || !selectedVersion || isError) {
    return <NotFound />;
  }

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const handleOnGoBack = () =>
    navigate(`${Routes.PREDICTIONS_SETTINGS}/models/${modelId}`);

  const handleVersionSelect = (versionId: string) =>
    navigate(
      `${Routes.PREDICTIONS_SETTINGS}/version-control/${modelId}?version=${versionId}`,
    );

  const CurrentTab = tabComponents[tabIndex];

  const handleTabUpdate = (updateData: IntegrationChangeParams) => {
    if (!selectedVersion || !helpdeskData) {
      return;
    }
    const newFieldPredictorModel = deriveNewModelFromIntegrationChange(
      updateData,
      selectedVersion,
      helpdeskData,
    );
    patchModel({
      body: { model: newFieldPredictorModel },
      modelId,
      versionId: selectedVersion.version_id,
    });
  };

  return (
    <Box
      display='grid'
      gridTemplateColumns='auto 300px'
      height='100vh'
      sx={{ backgroundColor: palette.colors.white }}
    >
      {isFetching && (
        <Box left={0} position='fixed' top={0} width='100vw' zIndex={100}>
          <Spinner />
        </Box>
      )}
      <Box display='grid' gridTemplateRows='69px 49px auto'>
        <NavbarV2
          currentTabOverride={selectedVersion.version_name}
          onGoBack={handleOnGoBack}
        >
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            mr='6px'
            width='100%'
          >
            <div></div>
            <ModelHeaderBanner inVersionsPage model={selectedVersion} />
            <TriageLabelMappingDrawer
              modelId={modelId}
              onCloseDrawer={() => setLabelMappingIsOpen(false)}
              showDrawer={labelMappingIsOpen}
              versionId={version || undefined}
            />
          </Box>
        </NavbarV2>
        <VersionControlTabs
          model={selectedVersion}
          onChange={handleTabChange}
          tabIndex={tabIndex}
        />
        <Box height='calc(100vh - 118px)' px={5} py={3}>
          <CurrentTab
            isModelLoading={isModelLoading}
            modelId={selectedVersion.model_id}
            onChange={handleTabUpdate}
            openLabelMapping={() => setLabelMappingIsOpen(true)}
            selectedVersion={selectedVersion}
          />
        </Box>
      </Box>
      <VersionControlList
        onSelect={handleVersionSelect}
        selectedVersion={selectedVersion}
        setLabelMappingIsOpen={value => setLabelMappingIsOpen(value)}
        versions={versions}
      />
    </Box>
  );
};

export default TriageVersionControlDetail;
