export const getIntentOptionType = (
  manuallySelectedTopIntents: string[],
  numTopIntents: number,
) => {
  if (manuallySelectedTopIntents.length === 0 && numTopIntents === 0) {
    return 'no_display';
  }

  if (manuallySelectedTopIntents.length === 0 && numTopIntents > 0) {
    return 'auto';
  }

  if (manuallySelectedTopIntents.length > 0) {
    return 'manual';
  }
  return '';
};
