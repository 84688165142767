import { useTheme } from '@mui/material/styles';

import { Chip } from '@forethought-technologies/forethought-elements';

interface LabelBadgeProps {
  badgeLabel: string;
}

export const LabelBadge = ({ badgeLabel }: LabelBadgeProps) => {
  const { palette } = useTheme();

  return (
    <Chip
      color={palette.colors.white}
      fontSize='11px'
      label={badgeLabel}
      size='small'
      sx={{
        '.MuiChip-label': {
          overflow: 'inherit',
          paddingX: '8px',
        },
        cursor: 'pointer',
        fontWeight: 700,
        height: '20px',
        marginLeft: '8px',
      }}
      variant={`gradient-${badgeLabel === 'TRIAL' ? 'pink' : 'blue'}`}
    />
  );
};
