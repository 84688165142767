import moment from 'moment';

import { QueryType, Report } from '../apiInterfaces';
import type { RootState } from 'src/store/rootReducer';
import { generateFiltersArray } from 'src/utils/generateFiltersArray/generateFiltersArray';

export const buildQueryFromState = (state: RootState) => {
  const { breakdowns, filters, pageSettings, reports } = state;
  const { timeFrameFilter, timeRange, viewMode } = pageSettings;
  const { appliedBreakdown } = breakdowns;
  const { report, reportsData } = reports;
  const stateFilters = filters.filters;
  const startTime = timeRange.from ? moment(timeRange.from).unix() : 0;
  const endTime = timeRange.to ? moment(timeRange.to).unix() : 9999999999;
  const serializedFilters = generateFiltersArray(stateFilters);

  const queryType =
    reportsData?.reports.find(
      (reportsData: Report) => reportsData.report_id === report.report_id,
    )?.query?.query_type ||
    report?.query.query_type ||
    '';

  const query: QueryType = {
    filters: serializedFilters,
    query_type: queryType.toUpperCase(),
    time_range: {
      end_time: endTime,
      start_time: startTime,
    },
    time_window_unit: timeFrameFilter.toUpperCase(),
    visualization_type: viewMode.toUpperCase(),
  };

  if (appliedBreakdown) {
    query.breakdown = {
      field: {
        field_category: appliedBreakdown.field_category?.toLowerCase(),
        field_id: appliedBreakdown.field_id,
      },
    };
  }

  return query;
};
