import { useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';

type CsatProps = {
  isChild?: boolean;
  value: number | null;
};

const CsatValue = ({ isChild, value }: CsatProps) => {
  const { palette } = useTheme();
  const getCSATColor = useGetCSATColor();
  if (!value) {
    return (
      <Typography
        color={palette.colors.grey[600]}
        variant={isChild ? 'font14' : 'font16Bold'}
      >
        {NA}
      </Typography>
    );
  }
  return (
    <Typography
      color={getCSATColor(value)}
      variant={isChild ? 'font14' : 'font16Bold'}
    >
      {formatCSAT(value)}
    </Typography>
  );
};

export default CsatValue;
