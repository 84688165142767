import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsNewFilterEqualToOld } from './useIsNewFilterEqualToOld';
import { getTopicMetricsById } from 'src/actions/discover/discover';
import {
  TopicPeriodicalFilter,
  TopicTimeFilter,
} from 'src/components/app/types';
import { selectTopicDetailMetrics } from 'src/reducers/discoverReducer/discoverReducer';
import { Filters } from 'src/services/apiInterfaces';

export const useGetTopicDetailMetrics = ({
  dataFilterQuery,
  interval,
  timeFilter,
  topicId,
}: {
  dataFilterQuery: Filters[];
  interval: TopicPeriodicalFilter;
  timeFilter: TopicTimeFilter;
  topicId: string;
}) => {
  const dispatch = useDispatch();
  const { cacheKey, data, error, loading } = useSelector(
    selectTopicDetailMetrics,
  );

  useEffect(() => {
    const currentFilter = `${topicId}+${timeFilter.key}+${interval}`;
    const currentCacheKey = currentFilter + JSON.stringify(dataFilterQuery);
    if ((data && currentCacheKey === cacheKey) || loading || error) {
      return;
    }
    dispatch(
      getTopicMetricsById({
        dataFilterQuery,
        interval,
        timeFilter,
        topicId,
      }),
    );
  }, [
    dispatch,
    error,
    topicId,
    timeFilter,
    interval,
    cacheKey,
    data,
    loading,
    dataFilterQuery,
  ]);

  // this prevents loading state from showing when only filters are changed
  const isNewFilterEqualToOld = useIsNewFilterEqualToOld(dataFilterQuery);

  return { data, error, loading: loading && isNewFilterEqualToOld };
};
