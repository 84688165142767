import {
  AutomationActionType,
  EditEmailAutomation,
  TicketFieldType,
} from 'src/pages/workflow-builder-edit/types';
import { ConditionExpressions } from 'src/services/apiInterfaces';

export const updateAutomationActionType = (
  automationActions: EditEmailAutomation['automation_actions'],
  value: AutomationActionType,
) => {
  const automationAction = automationActions[0];

  const valueMap = {
    'no-response': {},
    'response-delay': { delay_in_minutes: 5 },
    'thread-parsing': { comment_position: 1 },
  };

  return [
    {
      ...automationAction,
      automation_action_fields: valueMap[value],
      automation_action_type: value,
    },
  ];
};

export const updateAutomationActionFieldValue = (
  automationActions: EditEmailAutomation['automation_actions'],
  field: string,
  value: string | number,
) => {
  const automationAction = automationActions[0];
  const automationActionFields = { [field]: value };

  return [
    {
      ...automationAction,
      automation_action_fields: { ...automationActionFields },
    },
  ];
};

export const transformFromFilterToBooleanAgregatorExpression = (
  conditionExpression: ConditionExpressions,
  defaultExpression: ConditionExpressions,
) => {
  return {
    expression_type: 'boolean_aggregator',
    expressions: [{ ...conditionExpression }, { ...defaultExpression }],
    negate: false,
    operator: 'and',
  };
};

export const addExpressionToBooleanAgregatorExpression = (
  conditionExpression: ConditionExpressions,
  defaultExpression: ConditionExpressions,
) => {
  const expressions = conditionExpression.expressions || [];

  return {
    ...conditionExpression,
    expressions: [...expressions, { ...defaultExpression }],
  };
};

export const removeExpressionFromBooleanAgregatorExpression = (
  conditionExpression: ConditionExpressions,
  index: number,
) => {
  const currentExpressions = conditionExpression.expressions ?? [];
  const expressions = currentExpressions.filter(
    (_, expressionIndex) => index !== expressionIndex,
  );

  return {
    ...conditionExpression,
    expressions: [...expressions],
  };
};

export const transformBooleanAgregatorToFilterExpression = (
  conditionExpression: ConditionExpressions,
  index: number,
): ConditionExpressions => {
  const expression = conditionExpression.expressions?.find(
    (_, expressionIndex) => expressionIndex !== index,
  );

  return {
    expression_type: 'filter',
    field: expression?.field,
    negate: !!expression?.negate,
    operator: expression?.operator,
    values: expression?.values,
  };
};

export const updateFilterExpressionField = (
  expression: ConditionExpressions,
  field: string,
) => {
  return { ...expression, field, values: [] };
};

export const updateBooleanAggregatorExpressionField = (
  expression: ConditionExpressions,
  field: string,
  index: number,
) => {
  const expressions =
    expression.expressions?.map((expression, expressionIndex) => {
      if (expressionIndex === index) {
        return { ...expression, field, values: [] };
      }
      return expression;
    }) || [];

  return { ...expression, expressions };
};

export const updateFilterExpressionOperator = (
  expression: ConditionExpressions,
  operator: {
    negate: boolean;
    value: string;
  },
) => {
  return {
    ...expression,
    negate: operator.negate,
    operator: operator.value,
    values: [],
  };
};

export const updateBooleanAggregatorExpressionOperator = (
  expression: ConditionExpressions,
  operator: {
    negate: boolean;
    value: string;
  },
  index: number,
) => {
  const expressions =
    expression.expressions?.map((expression, expressionIndex) => {
      if (expressionIndex === index) {
        return {
          ...expression,
          negate: operator.negate,
          operator: operator.value,
          values: [],
        };
      }
      return expression;
    }) || [];

  return { ...expression, expressions };
};

export const updateFilterExpressionValue = (
  expression: ConditionExpressions,
  value: string | number | boolean | Array<string>,
) => {
  return {
    ...expression,
    values: Array.isArray(value) ? value : [value],
  };
};

export const updateBooleanAggregatorExpressionValue = (
  expression: ConditionExpressions,
  value: string | number | boolean | Array<string>,
  index: number,
) => {
  const expressions =
    expression.expressions?.map((expression, expressionIndex) => {
      if (expressionIndex === index) {
        return {
          ...expression,
          values: Array.isArray(value) ? value : [value],
        };
      }
      return expression;
    }) || [];

  return { ...expression, expressions };
};

export const updateBooleanAggregatorExpressionBooleanOperator = (
  expression: ConditionExpressions,
  operator: string,
) => {
  return { ...expression, operator };
};

export const getIsExpressionEmpty = (expression: ConditionExpressions) => {
  const expressionValues = expression.values || [];
  const value = expressionValues[0];
  const isBooleanValue = typeof value === 'boolean';
  return (
    expression.operator !== 'empty' &&
    (!expressionValues.length || (!isBooleanValue && !value))
  );
};

export const isBooleanAgregatorExpressionEmpty = (
  expression: ConditionExpressions,
) => {
  const queryExpressions = expression.expressions || [];

  return queryExpressions.some(getIsExpressionEmpty);
};

export const getQueryExpressionTypeByTicketType = (type: TicketFieldType) => {
  switch (type) {
    case 'text':
      return 'SHORT_TEXT';
    case 'sing_select':
      return 'LIST';
    case 'reference':
      return 'LIST';
    default:
      return 'SHORT_TEXT';
  }
};
