import { ActionBuilderActionTypes, StepTypes } from './enums';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import store from 'src/store/store';
import { Action } from 'src/types/actionBuilderApiTypes';
import { ActionBuilderState } from 'src/types/actionBuilderReducerTypes';

export const isEntryStepOfGranularArticleSuggestionV2 = (
  stepId: string | undefined,
): boolean => {
  const { actionBuilder, canvasWorkflowBuilder } = store.getState();
  const { actions } = actionBuilder as ActionBuilderState;
  const canvasActions = (canvasWorkflowBuilder as CanvasWorkflowBuilderState)
    .canvas_action_id_to_action_component;

  const granularArticleSuggestionActionId = actions.find(
    (action: Action) =>
      action.action_type ===
      ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2,
  )?.action_id;

  return Object.values(canvasActions).some(action => {
    return (
      action['entry_step_id'] === stepId &&
      action['action_id'] === granularArticleSuggestionActionId
    );
  });
};

export const getGranularArticleSuggestionV2ParentStepId = (
  stepId: string,
): string => {
  const state = store.getState() as {
    canvasWorkflowBuilder: CanvasWorkflowBuilderState;
  };
  const canvasActions =
    state.canvasWorkflowBuilder.canvas_action_id_to_action_component;
  const actionIdKeys = Object.keys(canvasActions);

  const granularArticleSuggestionActionId =
    actionIdKeys.find(
      key => canvasActions[key]?.['entry_step_id'] === stepId,
    ) || '';

  const transitionBranches =
    canvasActions[granularArticleSuggestionActionId]?.['transition_branches'];

  if (transitionBranches) {
    return transitionBranches[0]?.parent_step_id || stepId;
  }

  return stepId;
};

export const isGranularArticleSuggestionV2Deletable = (
  stepsWithinAction: string[],
): boolean => {
  const state = store.getState() as {
    canvasWorkflowBuilder: CanvasWorkflowBuilderState;
  };
  const steps = state.canvasWorkflowBuilder.steps;

  const conditionStepId =
    stepsWithinAction.find(
      stepId => steps[stepId].step_type === StepTypes.CONDITION,
    ) || '';
  const conditionStep = steps[conditionStepId];

  return (
    conditionStep.transitions[0].step_id === null ||
    conditionStep.transitions[1].step_id === null
  );
};
