import './InputField.scss';

import React from 'react';

interface InputFieldProps {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  value: string;
}

const InputField: React.FC<React.PropsWithChildren<InputFieldProps>> = (
  props: InputFieldProps,
) => {
  const { onChange, value } = props;

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };
  return (
    <input
      className='InputField'
      data-testid={'filters_input_field'}
      onChange={e => handleOnchange(e)}
      placeholder='Enter Value'
      type='text'
      value={value}
    ></input>
  );
};

export default InputField;
