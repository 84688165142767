import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { getAnswersData } from 'src/actions/data/data';
import {
  bookmarkReport,
  deleteReport,
  setCurrentReport,
} from 'src/actions/reports/reports';
import { Report } from 'src/services/apiInterfaces';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';

interface TableRowProps {
  /** Cells type string */
  cells: string[];
  /** id of report */
  id: number;
  /** Value that tells if user is the owner of the report  */
  isOwner: boolean;
  /** Name of the report  */
  name: string;
  /** close modal callback */
  onClick(): void;
  /** entire report data for report */
  report: Report;
  selectNextReport: Function;
  /** Is this report starred/bookmarked */
  starred: boolean;

  /** Type of visualization */
  type: string;
}

const ReportTableRow: React.FC<React.PropsWithChildren<TableRowProps>> = ({
  cells,
  id,
  isOwner,
  name,
  onClick,
  report,
  selectNextReport,
  starred,
  type,
}: TableRowProps) => {
  const dispatch = useDispatch();

  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);

  let isComponentMounted = true;

  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const reference: React.RefObject<HTMLDivElement> = useRef(null);

  const typeIs = classNames({
    'chart-view-icon': type === 'CHART',
    'table-view-icon': type === 'TABLE',
  });

  const isStarred = classNames({
    'Starred-iconActive': starred,
    'Starred-iconDefault': !starred,
  });

  const deleteIcon = classNames({
    'Modal-deleteIcon Modal-Bttn': !deleteConfirmation,
    'Modal-deleteIcon-active Modal-Bttn': isOwner && !deleteConfirmation,
    none: deleteConfirmation,
  });

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        reference,
        ContainerRef,
        setDeleteConfirmation,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);

  // Note: these components that receive widths would be a good candidate for StyledComponents
  return (
    <tr className='TableRow'>
      <td className='TableRow-cell' style={{ width: '30px' }}>
        <span className={typeIs} />
      </td>
      <td
        className='TableRow-linkContainer'
        onClick={() => {
          dispatch(setCurrentReport(report));
          dispatch(getAnswersData());
          onClick();
        }}
        style={{ width: '180px' }}
      >
        <p className=' TableRow-link' style={{ maxWidth: '140px' }}>
          {name}
        </p>
        <span className='Modal-openIcon' />
      </td>
      <td style={{ width: '170px' }}>
        <p className='TableRow-cell-p ' style={{ maxWidth: '160px' }}>
          {cells[0]}
        </p>
      </td>
      <td style={{ width: '270px' }}>
        <p className='TableRow-cell-p' style={{ maxWidth: '250px' }}>
          {cells[1]}
        </p>
      </td>
      <td style={{ width: '71px' }}>
        <p className='TableRow-cell-p'>{cells[2]}</p>
      </td>
      <td style={{ width: '50px' }}>
        <button
          className='Modal-Bttn'
          data-testid='bookmark-button'
          onClick={() => {
            dispatch(bookmarkReport(id, !starred));
          }}
        >
          <span className={isStarred} />
        </button>
      </td>
      <td style={{ width: '50px' }}>
        <div className='Modal-Bttn-cont Modal-delete-cont'>
          <button
            className={deleteIcon}
            data-testid='delete-button'
            onClick={() => isOwner && setDeleteConfirmation(true)}
          />
          {deleteConfirmation && (
            <div className='Modal-delete-confirmation' ref={reference}>
              <button
                className='Modal-delete-bttn'
                onClick={() => {
                  dispatch(deleteReport(id));
                  setDeleteConfirmation(false);
                  selectNextReport();
                }}
              >
                Confirm
              </button>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ReportTableRow;
