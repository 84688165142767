import React, {
  type FC,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type IdentifierSchemaAttributes } from 'remirror';
import { styled, useTheme } from '@mui/material';
import { GlobalStyles } from '@mui/material';
import {
  EditorComponent,
  EmojiPopupComponent,
  OnChangeHTML,
  Remirror,
  ThemeProvider,
  useRemirror,
} from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  replaceContextVariableIdsWithMarkupRef,
  replaceNewlinesWithBR,
} from './helpers/replaceContextVariableIdsWithMarkup';
import { FloatingLinkToolbar } from './FloatingLinkToolbar';
import FloatingToolbar from './FloatingToolbar';
import { ImperativeHandle } from './ImperativeHandle';
import {
  INVALID_CV_CLASS,
  InvalidCVCheckerExtension,
} from './InvalidCVCheckerExtension';
import { MentionComponent } from './MentionComponent';
import { TopToolbar } from './TopToolbar';
import { type MentionComponentProps, type RichTextEditorProps } from './types';
import {
  EmojiExtension,
  type FlatEmoji,
  HeadingExtension,
  LinkExtension,
  MentionAtomExtension,
  NodeFormattingExtension,
  wysiwygPreset,
} from 'remirror/extensions';
import data from 'svgmoji/emoji.json';

const extraAttributes: IdentifierSchemaAttributes[] = [
  {
    attributes: { role: { default: 'presentation' } },
    identifiers: ['mention', 'emoji'],
  },
  {
    attributes: {
      href: { default: null },
    },
    identifiers: ['mention'],
  },
];

const defaultContextVariables: MentionComponentProps['contextVariables'] = [];

export const RichTextEditor: FC<
  React.PropsWithChildren<PropsWithChildren<RichTextEditorProps>>
> = ({
  additionalToolbarButtons = [],
  belowToolbarComponent,
  children,
  contentMargin,
  contextVariables = null,
  editable = true,
  editorRef,
  errorMessage = '',
  highlightInvalidCVs = false,
  includeGenerateResponseDropdown = false,
  initialContent = '',
  linkPreviewConfig,
  onChange,
  onGenerativeOptionsDropdownChange,
  shouldRenderLinkButton = true,
  shouldReplaceNewlines = false,
  shouldShowFloatingToolbarWhenFocused = false,
  shouldShowToolbar = true,
  shouldValidateHrefLength = false,
  stringHandler = 'html',
  withAlignmentExtension = false,
  withEmojiButton = true,
  withHeadingExtension = false,
  withListButtons = true,
  ...rest
}) => {
  const [isMentionActive, setIsMentionActive] = useState(false);
  const theme = useTheme();
  const contextVariablesRef = useRef(contextVariables);
  const initialContentWithCV = useMemo(() => {
    return replaceContextVariableIdsWithMarkupRef(
      initialContent,
      contextVariablesRef,
    );
  }, [initialContent]);
  contextVariablesRef.current = contextVariables;

  const extensions = () => {
    return [
      ...(contextVariables
        ? [
            new MentionAtomExtension({
              extraAttributes: {
                'data-is-defined': props =>
                  contextVariablesRef?.current?.find(
                    contextVariable => contextVariable.id === props.attrs.id,
                  )?.isDefined !== false,
              },
              matchers: [
                {
                  char: '$',
                  matchOffset: 0,
                  name: 'context-variable',
                  validPrefixCharacters: /.*/u,
                },
              ],
            }),
          ]
        : []),
      new EmojiExtension({
        data: data as FlatEmoji[],
        plainText: true,
      }),
      new LinkExtension({
        autoLink: true,
        supportedTargets: ['_blank'],
      }),
      new NodeFormattingExtension({
        // Disable indentation of paragraphs on [Tab] key:
        indents: [],
      }),
      ...wysiwygPreset(),
      ...(withHeadingExtension ? [new HeadingExtension({})] : []),
      ...(highlightInvalidCVs ? [new InvalidCVCheckerExtension()] : []),
    ];
  };

  const handleChange = useCallback(
    (html: string) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');

      const isWhitespaceOnly = Array.from(doc.body.querySelectorAll('*')).every(
        element => element.tagName === 'P' && element.innerHTML.trim() === '',
      );

      if (isWhitespaceOnly) {
        onChange('');

        return;
      }

      // Remove last empty paragraph (Remirror bug, empty paragraph gets added
      // after lists):
      doc.querySelector('body > p:last-child:empty')?.remove();

      doc
        .querySelectorAll<HTMLSpanElement>(
          '[data-mention-atom-name="context-variable"]',
        )
        .forEach(element => {
          element.outerHTML = `{{${element.dataset.mentionAtomId}}}`;
        });

      onChange(doc.body.innerHTML);
    },
    [onChange],
  );

  const { manager } = useRemirror({
    extensions,
    extraAttributes,
    stringHandler,
  });

  const content = shouldReplaceNewlines
    ? replaceNewlinesWithBR(initialContentWithCV)
    : initialContentWithCV;

  return (
    <StyledWrapper contentMargin={contentMargin}>
      <GlobalStyles
        styles={{
          '.prosemirror-dropcursor-block': {
            display: 'none',
          },
          '.prosemirror-dropcursor-inline': {
            display: 'none',
          },
        }}
      />
      <ThemeProvider>
        <Remirror
          editable={editable}
          initialContent={content}
          manager={manager}
          {...rest}
        >
          {contextVariables && (
            <MentionComponent
              contextVariables={
                contextVariablesRef?.current || defaultContextVariables
              }
              setIsMentionActive={setIsMentionActive}
            />
          )}
          {shouldShowToolbar ? (
            <TopToolbar
              contextVariables={contextVariables}
              disabled={!editable}
              includeGenerateResponseDropdown={includeGenerateResponseDropdown}
              onGenerativeOptionsDropdownChange={value =>
                onGenerativeOptionsDropdownChange &&
                onGenerativeOptionsDropdownChange(value)
              }
              withAlignmentExtension={withAlignmentExtension}
              withEmojiButton={withEmojiButton}
              withHeadingExtension={withHeadingExtension}
              withListButtons={withListButtons}
            />
          ) : isMentionActive ? null : (
            <FloatingToolbar
              additionalToolbarButtons={additionalToolbarButtons}
              contextVariables={contextVariables}
              disabled={!editable}
              linkPreviewConfig={linkPreviewConfig}
              shouldShowWhenFocused={shouldShowFloatingToolbarWhenFocused}
              shouldValidateHrefLength={shouldValidateHrefLength}
              withEmojiButton={withEmojiButton}
            />
          )}
          {belowToolbarComponent}
          {shouldRenderLinkButton && shouldShowToolbar && (
            <FloatingLinkToolbar
              linkPreviewConfig={linkPreviewConfig}
              shouldValidateHrefLength={shouldValidateHrefLength}
            />
          )}
          <EditorComponent />
          <EmojiPopupComponent />
          <OnChangeHTML onChange={handleChange} />
          <ImperativeHandle
            contextVariablesRef={contextVariablesRef}
            onSetContent={handleChange}
            ref={editorRef}
          />
          {children}
        </Remirror>
      </ThemeProvider>
      <ErrorMessage>
        <Typography color={theme.palette.colors.red[500]} variant='font12'>
          {errorMessage}
        </Typography>
      </ErrorMessage>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(AllStyledComponent)<{ contentMargin?: string }>`
  .remirror-editor {
    box-shadow: none !important;
    min-height: 22px !important;
    font-family: 'Plus Jakarta Sans';
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: -10px;
    overflow-y: auto !important;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: ${props => props.contentMargin || '16px 0'} !important;
  }

  .floating-wrapper {
    z-index: 11;
  }

  .remirror-mention-atom {
    color: ${props => props.theme.palette.colors.purple[500]};
    font-weight: initial;
    font-size: initial;
    padding: 0;
    background: initial;
  }

  .remirror-mention-atom-zero-items {
    margin: 10px;
  }

  .remirror-floating-popover {
    z-index: 1;
  }

  .${INVALID_CV_CLASS} {
    color: ${props => props.theme.palette.colors.red[500]};
  }

  [data-is-defined='false'] {
    color: ${props => props.theme.palette.colors.red[500]};
  }
`;

const ErrorMessage = styled('div')`
  position: absolute;
`;
