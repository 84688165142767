import { useSelector } from 'react-redux';
import { Node } from 'reactflow';
import { v4 as uuid } from 'uuid';

import { DragItem } from 'src/components/forethought-flow/types';
import {
  EmailWorkflowStepType,
  TicketUpdateStepFields,
} from 'src/pages/workflow-builder-edit/types';
import {
  addEmailWorkflowRuleStep,
  addEmailWorkflowStep,
  removeEmailWorkflowStep,
  selectEmailWorkflowState,
  updateEmailWorkflowStep,
} from 'src/slices/email-workflow/emailWorkflowSlice';
import { useAppDispatch } from 'src/store/hooks';

function getStepFieldsForStepType({
  componentMetadata,
  componentType,
}: DragItem) {
  switch (componentType) {
    case 'action':
      return {
        action_id: componentMetadata.action.action_id,
      };
    case 'response':
      return {
        intent_email_configuration_id:
          componentMetadata.response?.configuration_id,
        intent_email_configuration_version:
          componentMetadata.response?.configuration_version,
      };
    case 'rule':
      return {
        condition_name: '',
      };
    default:
      return {};
  }
}

export function useCreateWorkflowNode() {
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);
  const dispatch = useAppDispatch();

  return async function handleCreate(
    item: DragItem,
    insertNodeId: string,
    parentStep: Node,
    parentTransitionId: string | null = null,
  ) {
    if (!emailWorkflow) {
      return;
    }

    const {
      email_workflow_id: emailWorkflowId,
      last_modified_date: lastModifiedDate,
      version,
    } = emailWorkflow;
    const { componentType } = item;
    const stepFields = getStepFieldsForStepType(item);

    const addRuleStep = async () => {
      await dispatch(
        addEmailWorkflowRuleStep({
          emailWorkflowId,
          lastModifiedDate,
          parentStepId: parentStep.data.stepId ?? null,
          parentTransitionId:
            parentStep.type === 'rule' ? parentTransitionId : null,
          stepFields,
          stepId: insertNodeId,
          transitions: [
            {
              condition_expression: null,
              step_id: null,
              transition_id: uuid(),
            },
          ],
          version,
        }),
      );
    };

    if (componentType === 'rule') {
      addRuleStep();
    } else {
      dispatch(
        addEmailWorkflowStep({
          emailWorkflowId,
          lastModifiedDate,
          parentStepId: parentStep.data.stepId ?? null,
          parentTransitionId:
            parentStep.type === 'rule' ? parentTransitionId : null,
          stepFields,
          stepId: insertNodeId,
          stepType: componentType as EmailWorkflowStepType,
          version,
        }),
      );
    }
  };
}

export function useCreateWorkflowTerminalNode() {
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);
  const dispatch = useAppDispatch();

  return async function handleCreate({
    parentStepId,
    parentTransitionId,
    stepFields,
  }: {
    parentStepId: string | null;
    parentTransitionId: string | null;
    stepFields: TicketUpdateStepFields;
  }) {
    if (!emailWorkflow) {
      return;
    }

    const {
      email_workflow_id: emailWorkflowId,
      last_modified_date: lastModifiedDate,
      version,
    } = emailWorkflow;

    dispatch(
      addEmailWorkflowStep({
        emailWorkflowId,
        lastModifiedDate,
        parentStepId,
        parentTransitionId,
        stepFields,
        stepId: uuid(),
        stepType: 'ticket_update',
        version,
      }),
    );
  };
}

export function useUpdateWorkflowTerminalNode() {
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);
  const dispatch = useAppDispatch();

  return async function handleUpdate({
    stepFields,
    stepId,
  }: {
    stepFields: TicketUpdateStepFields;
    stepId: string;
  }) {
    if (!emailWorkflow) {
      return;
    }

    const {
      email_workflow_id: emailWorkflowId,
      last_modified_date: lastModifiedDate,
      version,
    } = emailWorkflow;

    dispatch(
      updateEmailWorkflowStep({
        emailWorkflowId,
        lastModifiedDate,
        stepFields,
        stepId,
        version,
      }),
    );
  };
}

export function useRemoveWorkflowNode() {
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);
  const dispatch = useAppDispatch();

  return async function handleRemove({ stepId }: { stepId: string }) {
    if (!emailWorkflow) {
      return;
    }

    const {
      email_workflow_id: emailWorkflowId,
      last_modified_date: lastModifiedDate,
      version,
    } = emailWorkflow;

    dispatch(
      removeEmailWorkflowStep({
        emailWorkflowId,
        lastModifiedDate,
        stepId,
        version,
      }),
    );
  };
}
