import { getFilteredMacros, updateMacro } from './utils/getFilteredMacros';
import { sortMacros } from './helper';
import { FetchAction, MacroControlsDataState } from './types';
import {
  MacroControlsActions,
  PageSettingsActions,
} from 'src/actions/actionTypes';
import { sortMacrosTableData } from 'src/reducers/dataDeprecatedReducer/helper';
import { MacroDataDict } from 'src/services/apiInterfaces';
import type { RootState } from 'src/store/rootReducer';

export const initialState: MacroControlsDataState = {
  error: '',
  filteredMacrosData: [],
  isLoading: false,
  macroControlsData: [],
  macroFieldtoUpdate: {},
  selectedMacro: null,
};

export default function macroControlsDataReducer(
  state = initialState,
  action: FetchAction,
): MacroControlsDataState {
  if (action.type === MacroControlsActions.GET_MACRO_CONTROLS_DATA) {
    const currentView = action.payload.currentView;
    const isTrainedMacrosView = currentView === 'Trained Macros';
    const tableData = { ...action.payload.data };
    const { macros } = tableData;
    const sortedMacros = sortMacros(isTrainedMacrosView, macros);
    return {
      ...state,
      filteredMacrosData: sortedMacros,
      macroControlsData: macros,
    };
  }

  if (action.type === MacroControlsActions.GET_MACRO_CONTROLS_DATA_FAILURE) {
    return {
      ...state,
      error: action.error?.toString() || 'Error',
      macroControlsData: [],
    };
  }

  if (action.type === MacroControlsActions.SET_SELECTED_MACRO) {
    return {
      ...state,
      selectedMacro: action.payload,
    };
  }

  if (action.type === MacroControlsActions.SET_MACRO_FIELD_TO_UPDATE) {
    return {
      ...state,
      macroFieldtoUpdate: action.payload,
    };
  }

  if (action.type === MacroControlsActions.UPDATE_MACRO_DATA) {
    //Update obj in table
    const tableData = state.filteredMacrosData;
    const updatedTableData = updateMacro(tableData, action.payload, true);

    // Update only necessary values
    const updatedMacro = updateMacro(tableData, action.payload);
    return {
      ...state,
      // @ts-expect-error legacy code with untyped state
      filteredMacrosData: updatedTableData,
      macroFieldtoUpdate: {},
      // @ts-expect-error legacy code with untyped state
      selectedMacro: updatedMacro,
    };
  }

  if (action.type === PageSettingsActions.SET_LOADING) {
    return { ...state, isLoading: action.payload };
  }

  if (action.type === MacroControlsActions.SET_FILTERED_MACROS_DATA) {
    const currentView = action.payload.currentView;
    const isTrainedMacrosView = currentView === 'Trained Macros';
    const search = action.payload.search;
    const macros = state.macroControlsData;
    const sortedMacros = sortMacros(isTrainedMacrosView, macros);
    const filteredMacrosData = getFilteredMacros(
      search,
      sortedMacros as Array<MacroDataDict>,
    );

    return { ...state, filteredMacrosData };
  }

  if (action.type === PageSettingsActions.SET_LOADING) {
    return { ...state, isLoading: action.payload };
  }

  if (action.type === PageSettingsActions.SORT_TABLE) {
    const currentView = action.payload.tableView;
    const isTrainedMacrosView = currentView === 'Trained Macros';
    const { field, sortAsc } = action.payload;
    const tableData = state.macroControlsData || [];
    const filteredMacrosData = sortMacrosTableData(
      tableData,
      field,
      sortAsc,
      isTrainedMacrosView,
    );
    return { ...state, filteredMacrosData };
  }

  return state;
}

// SELECTORS

export const selectIsLoading = (
  state: RootState,
): MacroControlsDataState['isLoading'] => state.macroControlsData.isLoading;

export const selectFilteredMacrosData = (
  state: RootState,
): MacroControlsDataState['filteredMacrosData'] =>
  state.macroControlsData.filteredMacrosData;

export const selectSelectedMacro = (
  state: RootState,
): MacroControlsDataState['selectedMacro'] =>
  state.macroControlsData.selectedMacro;
