import axios, { AxiosResponse } from 'axios';

import { getCognitoSession } from './authUtils';

interface ValidateEmailResponse {
  // A url where the user can find their company's dashboard
  login_url: string;
}

export const validateAssistEmail = (
  email: string,
): Promise<AxiosResponse<ValidateEmailResponse>> => {
  return axios.post(`${ASSIST_API_URL}/assist-extension/v1/validate-email`, {
    email,
  });
};

/**
 * Pull current session from Amplify and post message to pass to Assist
 */
export async function sendSessionToAssistExtension() {
  // Noop if chrome is undefined
  if (!window.chrome?.runtime?.sendMessage) {
    return;
  }

  try {
    const session = await getCognitoSession();

    // Pass current session to assist extension
    window.chrome.runtime.sendMessage(ASSIST_EXTENSION_ID_PRODUCTION, {
      session,
      type: 'COGNITO_AUTH_EVENT',
    });
    window.chrome.runtime.sendMessage(ASSIST_EXTENSION_ID_INTERNAL, {
      session,
      type: 'COGNITO_AUTH_EVENT',
    });
  } catch {
    // Notify assist extension that there is no current session
    window.chrome.runtime.sendMessage(ASSIST_EXTENSION_ID_PRODUCTION, {
      session: null,
      type: 'COGNITO_AUTH_EVENT',
    });
    window.chrome.runtime.sendMessage(ASSIST_EXTENSION_ID_INTERNAL, {
      session: null,
      type: 'COGNITO_AUTH_EVENT',
    });
  }
}
