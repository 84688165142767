import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

/**
 * basic card with header, divider and content
 */
export default function Card({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  const { palette } = useTheme();

  return (
    <Box
      sx={theme => ({
        border: `1px solid ${theme.palette.colors.slate[200]}`,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        p: '0 24px',
      })}
    >
      <Box borderBottom={`1px solid ${palette.colors.slate[200]}`} py='24px'>
        <Typography variant='font16Bold'>{title}</Typography>
      </Box>
      <Box py='16px'>{children}</Box>
    </Box>
  );
}
