import { useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

type TopicNameHeaderProps = {
  totalTopicsCount: number;
};

const TopicNameHeader = ({ totalTopicsCount }: TopicNameHeaderProps) => {
  const { palette } = useTheme();

  return (
    <Typography color={palette.colors.grey[800]} variant='font14Bold'>
      {`Topics (${totalTopicsCount})`}
    </Typography>
  );
};

export default TopicNameHeader;
