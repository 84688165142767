import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { updateActionSettings } from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { ZendeskHandoffConfiguration } from 'src/pages/workflow-builder-edit/handoff-configuration';
import { hasAtLeastOneError } from 'src/pages/workflow-builder-edit/handoff-configuration/helpers';
import {
  cleanCustomizationData,
  validateCustomizationData,
} from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { ZendeskHandoffCustomization } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface ZendeskHandoffCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const ZendeskHandoffCustomizationPanel: FC<
  React.PropsWithChildren<ZendeskHandoffCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const handoffCustomization = canvasState?.actionSettings
    .configuration_fields as ZendeskHandoffCustomization;
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const dispatch = useDispatch();

  // Creating a new state so we can keep track of what has changed
  const [customizationData, setCustomizationData] =
    useState<ZendeskHandoffCustomization>(handoffCustomization);

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const errorMessages = validateCustomizationData({
    allowableContextVariables: contextVariables,
    customizationData: customizationData,
  });

  const customMessage =
    customizationData?.ticket_creation_settings?.custom_message;

  let isDisableSaveFromForm = false;
  const isPristine = isEqual(customizationData, handoffCustomization);

  if (customizationData?.include_ticket_creation_handoff) {
    if (isPristine) {
      isDisableSaveFromForm = true;
    } else if (customizationData?.ticket_creation_settings?.create_ticket) {
      isDisableSaveFromForm = hasAtLeastOneError(errorMessages);
    } else {
      isDisableSaveFromForm =
        customMessage === '<p><br></p>' || customMessage === '';
    }
  }

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setCustomizationData(handoffCustomization);
    }
  }, [canvasState.isLoadingCustomization, handoffCustomization]);

  if (canvasState.isLoadingCustomization || !handoffCustomization) {
    return <LoadingSkeleton />;
  }

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <ZendeskHandoffConfiguration
        customizationData={customizationData}
        errorMessages={errorMessages}
        isFromCanvas
        setCustomizationData={setCustomizationData}
      />

      <SaveContainer hasTopBorder>
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isDisableSaveFromForm || hasWorkflowConflict}
          onClick={() => {
            dispatch(
              updateActionSettings({
                action_type: canvasState.actionSettings.action_type as string,
                configuration_fields: cleanCustomizationData(customizationData),
              }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.ZENDESK_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </SaveContainer>
    </Box>
  );
};

export default ZendeskHandoffCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
