import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';

import { UnstyledButton } from '../navbar/common/resetUI';
import chevronRight from 'src/assets/images/chevron-right-icon.svg';

interface ExpandIndicatorProps {
  activeRotation?: string;
  hidden?: boolean;
  isActive: boolean;
  onClick?: (e: React.MouseEvent) => void;
  rotation?: string;
}

const ExpandIndicator: FunctionComponent<
  React.PropsWithChildren<ExpandIndicatorProps>
> = ({
  activeRotation = '180',
  hidden = false,
  isActive,
  onClick,
  rotation = '0',
}) => (
  <Wrapper
    activeRotation={activeRotation}
    hidden={hidden}
    isActive={isActive}
    onClick={onClick}
    rotation={rotation}
  >
    <Indicator alt={isActive ? 'Collapse' : 'Expand'} src={chevronRight} />
  </Wrapper>
);

const Wrapper = styled(UnstyledButton)<{
  activeRotation: string;
  isActive?: boolean;
  rotation: string;
}>`
  align-items: center;
  justify-content: center;
  transform: ${props =>
    props.isActive
      ? `rotate(${props.activeRotation}deg)`
      : `rotate(${props.rotation || 0}deg)`};
  display: ${props => (props.hidden ? 'none' : 'flex')};
  cursor: pointer;
`;

const Indicator = styled('img')`
  width: 24px;
  height: 24px;
`;

export default ExpandIndicator;
