import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { Typography } from '@forethought-technologies/forethought-elements';

interface StyledMenuItemProps {
  menuItem: {
    icon: JSX.Element;
    label: string;
    onClick: () => void;
  };
  onClose: () => void;
}

const StyledMenuItem = ({ menuItem, onClose }: StyledMenuItemProps) => (
  <MenuItem
    onClick={e => {
      e.stopPropagation();
      onClose();
      menuItem.onClick();
    }}
    sx={{ alignItems: 'center', display: 'flex', padding: '6px 16px' }}
  >
    <Box alignItems='center' display='flex' mx={1}>
      {menuItem.icon}
    </Box>
    <Typography variant='font14'>{menuItem.label}</Typography>
  </MenuItem>
);

export default StyledMenuItem;
