import styled from '@emotion/styled';
import { Box, useTheme } from '@mui/material';
import { IconDiscountCheck } from '@tabler/icons-react';

import CardValue from '../../common/banner-layout/CardValue';
import costReductionIcon from 'src/assets/images/discover-cost-reduction-icon-white.svg';
import timeReductionIcon from 'src/assets/images/discover-time-reduction-icon-white.svg';
import volumeReductionIcon from 'src/assets/images/discover-volume-reduction-icon-white.svg';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import { useGetMetadata } from 'src/hooks/discover/useGetMetadata';
import { DiscoverTopicActionableValue } from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  getCostReductionCopy,
  getDiscoverBannerValues,
} from 'src/utils/discover/helpers';

interface DiscoverAutomationBannerProps {
  hasScrolled: boolean;
  isLoading: boolean;
  recommendationValue?: DiscoverTopicActionableValue | null;
}

const DiscoverAutomationBanner = ({
  hasScrolled,
  isLoading,
  recommendationValue,
}: DiscoverAutomationBannerProps) => {
  const { palette } = useTheme();
  const viewMode = hasScrolled ? 'minimized' : 'full';
  const hasNoData = !recommendationValue;

  const { costValue, fullResolutionTimeValue, volumeValue } =
    getDiscoverBannerValues(recommendationValue, true);

  const { data } = useGetMetadata();
  const {
    description: costReductionDescription,
    tooltip: costReductionTooltip,
  } = getCostReductionCopy(data?.ticket_cost_info);

  if (!recommendationValue && !isLoading) {
    return null;
  }

  return (
    <Box
      data-appcues-target={getAppCuesId({
        componentType: 'container',
        featureName: 'recommendation',
        pageName: 'automation',
        subType: 'banner',
      })}
    >
      <CardValue
        costReduction={{
          description: costReductionDescription,
          emptyStateIcon: hasNoData ? (
            <Image src={costReductionIcon} viewMode={viewMode} />
          ) : null,
          tooltip: costReductionTooltip,
          value: costValue,
        }}
        icon={<IconDiscountCheck color={palette.colors.slate[700]} />}
        loading={isLoading}
        resolutionTimeReduction={{
          description: DISCOVER_CARD_TEXT.resolutionTimeDescription,
          emptyStateIcon: hasNoData ? (
            <Image src={timeReductionIcon} viewMode={viewMode} />
          ) : null,
          value: fullResolutionTimeValue,
        }}
        title={DISCOVER_CARD_TEXT.automationPageBannerTitle}
        viewMode={viewMode}
        viewType='primary'
        volumeReduction={{
          description: DISCOVER_CARD_TEXT.volumeDescription,
          emptyStateIcon: hasNoData ? (
            <Image src={volumeReductionIcon} viewMode={viewMode} />
          ) : null,
          value: volumeValue + (hasScrolled ? ' tickets' : ''),
        }}
      />
    </Box>
  );
};

const Image = styled('img')<{ viewMode: string }>`
  width: ${props => (props.viewMode === 'minimized' ? '20px' : '32px')};
`;

export default DiscoverAutomationBanner;
