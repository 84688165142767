import { useMemo } from 'react';

// Try to parse html as string
// On error, return original string
export function stripHTML(htmlString: string): string {
  try {
    const parser = new DOMParser();
    const tempDocument = parser.parseFromString(htmlString, 'text/html');
    return tempDocument.body.firstChild?.textContent || '';
  } catch {
    return htmlString;
  }
}

export default function useStrippedHTML(htmlString: string) {
  return useMemo(() => {
    return stripHTML(htmlString);
  }, [htmlString]);
}
