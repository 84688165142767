import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { formatErrorByStep } from './helpers';
import {
  AddEmailWorkflowRuleStepParams,
  AddEmailWorkflowStepParams,
  DeletePathParams,
  EmailWorkflowResponse,
  EmailWorkflowValidationError,
  ObtainDefaultEmailWorkflowParams,
  PublishEmailWorkflowParams,
  RemoveEmailWorkflowStepParams,
  UpdateEmailWorkflowStepParams,
  UpdateEmailWorkflowStepTransitionsParams,
  ValidateWorkflowParams,
  ValidateWorkflowResponse,
} from 'src/pages/workflow-builder-edit/types';
import * as API from 'src/services/email-builder/emailBuilderApi';
import type { RootState } from 'src/store/rootReducer';

export interface SolveEmailWorkflowState {
  emailWorkflow: EmailWorkflowResponse | null;
  error: string | null;
  isLoading: boolean;
  isLoadingOptimisticUpdate: boolean;
  validationErrors: {
    stepErrors: {
      [stepId: string]: EmailWorkflowValidationError[];
    };
    workflowErrors: ValidateWorkflowResponse['errors'];
  };
}

const ERROR = 'Error';

const initialState: SolveEmailWorkflowState = {
  emailWorkflow: null,
  error: null,
  isLoading: false,
  isLoadingOptimisticUpdate: false,
  validationErrors: { stepErrors: {}, workflowErrors: [] },
};

export const obtainDefaultEmailWorkflow = createAsyncThunk(
  'emailWorkflow/obtainDefaultEmailWorkflow',
  (params: ObtainDefaultEmailWorkflowParams) => {
    return API.obtainDefaultEmailWorkflow(params);
  },
);

export const addEmailWorkflowStep = createAsyncThunk(
  'emailWorkflow/addEmailWorkflowStep',
  (params: AddEmailWorkflowStepParams) => {
    return API.addEmailWorkflowStep(params);
  },
);

export const updateEmailWorkflowStep = createAsyncThunk(
  'emailWorkflow/updateEmailWorkflowStep',
  (params: UpdateEmailWorkflowStepParams) => {
    return API.updateEmailWorkflowStep(params);
  },
);

export const updateEmailWorkflowStepTransitions = createAsyncThunk(
  'emailWorkflow/updateEmailWorkflowStepTransitions',
  (params: UpdateEmailWorkflowStepTransitionsParams) => {
    return API.updateEmailWorkflowStepTransitions(params);
  },
);

export const addEmailWorkflowRuleStep = createAsyncThunk(
  'emailWorkflow/addEmailWorkflowRuleStep',
  (params: AddEmailWorkflowRuleStepParams) => {
    return API.addEmailWorkflowRuleStep(params);
  },
);

export const removeEmailWorkflowStep = createAsyncThunk(
  'emailWorkflow/removeEmailWorkflowStep',
  (params: RemoveEmailWorkflowStepParams) => {
    return API.removeEmailWorkflowStep(params);
  },
);

export const publishEmailWorkflow = createAsyncThunk(
  'emailWorkflow/publishEmailWorkflow',
  (params: PublishEmailWorkflowParams) => {
    return API.publishEmailWorkflow(params);
  },
);

export const validateEmailWorkflow = createAsyncThunk(
  'emailWorkflow/validateEmailWorkflow',
  (params: ValidateWorkflowParams) => {
    return API.validateEmailWorkflow(params);
  },
);

export const deletePath = createAsyncThunk(
  'emailWorkflow/deletePath',
  (params: DeletePathParams) => {
    return API.deletePath(params);
  },
);

const emailWorkflowSlice = createSlice({
  extraReducers(builder) {
    /**
     * obtainDefaultEmailWorkflow
     */
    builder.addCase(
      obtainDefaultEmailWorkflow.fulfilled,
      (state, { payload }) => {
        state.emailWorkflow = payload;
        state.isLoading = false;
        state.error = null;
      },
    );
    builder.addCase(obtainDefaultEmailWorkflow.pending, state => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(obtainDefaultEmailWorkflow.rejected, (state, action) => {
      state.error = action.error.message || ERROR;
      state.isLoading = false;
    });
    /**
     * addEmailWorkflowStep
     */
    builder.addCase(addEmailWorkflowStep.fulfilled, (state, { payload }) => {
      state.emailWorkflow = payload;
    });
    builder.addCase(addEmailWorkflowStep.pending, state => {
      state.error = null;
    });
    builder.addCase(addEmailWorkflowStep.rejected, (state, action) => {
      state.error = action.error.message || ERROR;
    });
    /**
     * addEmailWorkflowRuleStep
     */
    builder.addCase(
      addEmailWorkflowRuleStep.fulfilled,
      (state, { payload }) => {
        state.emailWorkflow = payload;
        state.isLoadingOptimisticUpdate = false;
      },
    );
    builder.addCase(addEmailWorkflowRuleStep.rejected, (state, { error }) => {
      state.error = error.message || ERROR;
      state.isLoadingOptimisticUpdate = false;
    });
    builder.addCase(addEmailWorkflowRuleStep.pending, state => {
      state.error = null;
      state.isLoadingOptimisticUpdate = true;
    });
    /**
     * updateEmailWorkflowStepTransitions
     */
    builder.addCase(updateEmailWorkflowStepTransitions.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      updateEmailWorkflowStepTransitions.fulfilled,
      (state, { payload }) => {
        state.emailWorkflow = payload;
        state.isLoading = false;
      },
    );
    builder.addCase(
      updateEmailWorkflowStepTransitions.rejected,
      (state, { error }) => {
        state.error = error.message || ERROR;
        state.isLoading = false;
      },
    );
    /**
     * updateEmailWorkflowStep
     */
    builder.addCase(updateEmailWorkflowStep.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(updateEmailWorkflowStep.fulfilled, (state, { payload }) => {
      state.emailWorkflow = payload;
      state.isLoading = false;
    });
    builder.addCase(updateEmailWorkflowStep.rejected, (state, { error }) => {
      state.error = error.message || ERROR;
      state.isLoading = false;
    });
    /**
     * removeEmailWorkflowStep
     */
    builder.addCase(removeEmailWorkflowStep.fulfilled, (state, { payload }) => {
      state.emailWorkflow = payload;
      state.isLoadingOptimisticUpdate = false;
    });
    builder.addCase(removeEmailWorkflowStep.rejected, (state, action) => {
      state.error = action.error.message || ERROR;
      state.isLoadingOptimisticUpdate = false;
    });
    builder.addCase(removeEmailWorkflowStep.pending, state => {
      state.error = null;
      state.isLoadingOptimisticUpdate = true;
    });
    /**
     * publishEmailWorkflow
     */
    builder.addCase(publishEmailWorkflow.fulfilled, (state, { payload }) => {
      state.emailWorkflow = payload.draft;
      state.isLoading = false;
    });
    builder.addCase(publishEmailWorkflow.pending, (state, action) => {
      if (action.meta.arg.isPublishing === false) {
        state.isLoading = true;
        state.error = null;
      }
    });
    builder.addCase(publishEmailWorkflow.rejected, (state, action) => {
      state.error = action.error.message || ERROR;
      state.isLoading = false;
    });
    /**
     * validateEmailWorkflow
     */
    builder.addCase(validateEmailWorkflow.fulfilled, (state, action) => {
      state.validationErrors.workflowErrors = action.payload.errors.filter(
        error => !error.step_id,
      );
      state.validationErrors.stepErrors = formatErrorByStep(
        action.payload.errors.filter(error => error.step_id),
      );
    });
    /**
     * deletePath
     */
    builder.addCase(deletePath.fulfilled, (state, { payload }) => {
      state.emailWorkflow = payload;
      state.isLoading = false;
    });
    builder.addCase(deletePath.rejected, (state, { error }) => {
      state.error = error.message || ERROR;
      state.isLoading = false;
    });
    builder.addCase(deletePath.pending, state => {
      state.error = null;
      state.isLoading = true;
    });
  },
  initialState,
  name: 'emailWorkflow',
  reducers: {
    cleanErrors: state => {
      state.error = null;
    },
  },
});

export const { cleanErrors } = emailWorkflowSlice.actions;

export const selectEmailWorkflowState = (
  state: RootState,
): SolveEmailWorkflowState => state.emailWorkflow;

export const selectValidationErrors = (
  state: RootState,
): SolveEmailWorkflowState['validationErrors'] =>
  state.emailWorkflow.validationErrors;

export default emailWorkflowSlice.reducer;
