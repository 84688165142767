import compact from 'lodash/fp/compact';

export const getAppCuesId = ({
  componentType,
  featureName,
  pageName,
  subType,
}: {
  componentType: string;
  featureName: string;
  pageName: string;
  subType: string;
}): string => {
  const facets = compact([pageName, featureName, componentType, subType]);

  return facets.join('-');
};
