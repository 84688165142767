import './index.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import EmailFilter from '../email-filter/EmailFilter';
import {
  EmailFilterTextProps,
  RangeSliderCoverageTextProps as Coverage,
} from '../utils/enum';
import {
  setMacroFieldtoUpdate,
  setSelectedMacro,
  updateMacroData,
} from 'src/actions/macroControls';
import { setToastType } from 'src/actions/toastNotification/toastNotification';
import { RangeSlider } from 'src/components/reusable-components/range-slider';
import ToastNotification from 'src/components/reusable-components/toast-notification';
import ToggleButton from 'src/components/reusable-components/toggle-button/ToggleButton';
import OpenURLButton from 'src/pages/solve-analytics/open-URL-button/OpenURLButton';
import { MacroDataDict } from 'src/services/apiInterfaces';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';

type Props = {
  /** Function that closes the macro */
  closeMacro: Function;
  /** Macro that's currently selected */
  selectedMacro: MacroDataDict;
};

const MacroModal: React.FC<React.PropsWithChildren<Props>> = ({
  closeMacro,
  selectedMacro: {
    coverage,
    is_active,
    is_trained,
    is_whitelist_emails_enabled,
    name,
    url,
    whitelisted_emails,
  },
}: Props) => {
  const dispatch = useDispatch();
  let isComponentMounted = true;
  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const reference: React.RefObject<HTMLDivElement> = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const handleVisibility = (e: Event) => {
    handleModalsVisibility(
      isComponentMounted,
      e,
      reference,
      ContainerRef,
      closeMacro,
    );
  };

  const clearSelectedMacro = () => {
    dispatch(setSelectedMacro(null));
    dispatch(setToastType(''));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleVisibility);

    return () => {
      clearSelectedMacro();
      isComponentMounted = false;
      document.removeEventListener('mousedown', handleVisibility);
    };
  }, []);

  return (
    <div className='MacroModal' ref={reference}>
      <div className='MacroModal-headingCont'>
        <p className='MacroModal-headingCont-name'>{name}</p>
        <button
          className='MacroModal-bttnClose'
          onClick={() => {
            clearSelectedMacro();
            closeMacro();
          }}
        >
          CLOSE
          <div className='MacroModal-bttnClose-icon' />
        </button>
      </div>
      <div className='MacroModal-macroinfo'>
        <div>
          <p className='MacroModal-urlTitle'>Helpdesk Macro Page</p>
          <div className='MacroModal-urlCont'>
            <a href={url} rel='noreferrer' target='_blank'>
              {url}
            </a>
            <OpenURLButton buttonText={'Open'} redirectURL={url} />
          </div>
        </div>

        {is_trained && (
          <div className='MacroModal-status'>
            <p>Status</p>
            <ToggleButton
              isChecked={is_active}
              label={'Active'}
              onClickHandler={(field: boolean) => {
                dispatch(setMacroFieldtoUpdate({ is_active: field }));
                if (is_active) {
                  setShowConfirmation(true);
                } else {
                  dispatch(updateMacroData());
                }
              }}
            />
          </div>
        )}
      </div>
      {is_trained && (
        <div className='MacroModal-controls'>
          <RangeSlider
            coverage={coverage}
            label={'Coverage Control'}
            maxRangeDescription={Coverage.MAX_RANGE_DESC}
            maxRangeLabel={Coverage.MAX_RANGE_LABEL}
            minRangeDescription={Coverage.MIN_RANGE_DESC}
            minRangeLabel={Coverage.MIN_RANGE_LABEL}
            onChange={(field: number) => {
              dispatch(setMacroFieldtoUpdate({ coverage: field }));
              dispatch(updateMacroData());
            }}
            tooltipDescription={Coverage.TOOLTIP_DESC}
            tooltipTitle={Coverage.TOOLTIP_TITLE}
          />
          <div className='MacroModal-controls-emailFilter'>
            <EmailFilter
              emails={whitelisted_emails}
              isEnabled={is_whitelist_emails_enabled}
              onSubmit={(field: { [key: string]: string[] }) => {
                dispatch(setMacroFieldtoUpdate(field));
                dispatch(updateMacroData());
              }}
              smallContainer={showConfirmation}
              tooltipDescription={EmailFilterTextProps.TOOLTIP_DESC}
              tooltipTitle={EmailFilterTextProps.TOOLTIP_TITLE}
            />
          </div>
        </div>
      )}

      {is_active && showConfirmation && (
        <div className='MacroModal-confirmation'>
          <div>
            <p>Deactivate Macro</p>
            <span>Are you sure you want to deactivate this macro?</span>
          </div>
          <div className='MacroModal-btnsContainer'>
            <button
              className='MacroModal-btnsContainer-cancelBtn'
              onClick={() => setShowConfirmation(false)}
              tabIndex={0}
            >
              Cancel
            </button>
            <button
              className='MacroModal-btnsContainer-deactivateBtn'
              onClick={() => {
                setShowConfirmation(false);
                dispatch(updateMacroData());
              }}
              tabIndex={0}
            >
              Deactivate
            </button>
          </div>
        </div>
      )}
      <ToastNotification />
    </div>
  );
};

export default MacroModal;
