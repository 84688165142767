import React from 'react';
import {
  MRT_ColumnDef,
  MRT_RowData,
  MRT_SortingState,
  MRT_TableInstance,
  MRT_TableOptions,
  MRT_TableState,
} from 'material-react-table';
import Box from '@mui/material/Box';

import { InfiniteTable } from '@forethought-technologies/forethought-elements';
import { HEADER_HEIGHT } from '../constants';
import TableFilters, { DateRangeFilter } from '../label-tab/TableFilters';

const TriageModelTable = <T extends MRT_RowData, S>({
  columns,
  Control,
  data,
  dateRangeFilter,
  FilterComponent,
  hasNextPage = false,
  initialSorting,
  initialState,
  isFetching = false,
  isLoading = false,
  onLoadMore,
  onSearch,
  onSearchTypeChange,
  options = {},
  renderEmptyRowsFallback,
  searchOptions,
  searchPlaceholderText,
  searchText,
  searchType,
  stickyHeaderHeight = HEADER_HEIGHT,
}: {
  columns: MRT_ColumnDef<T, S>[];
  Control?: React.ReactNode;
  data: T[];
  dateRangeFilter?: DateRangeFilter;
  FilterComponent?: React.ReactNode;
  hasNextPage?: boolean;
  initialSorting: MRT_SortingState;
  initialState?: Partial<MRT_TableState<T>>;
  isFetching?: boolean;
  isLoading?: boolean;
  onLoadMore?: () => void;
  onSearch?: (text: string) => void;
  onSearchTypeChange?: (value: string) => void;
  options?: Partial<MRT_TableOptions<T>>;
  renderEmptyRowsFallback?: (props: {
    table: MRT_TableInstance<T>;
  }) => React.ReactNode;
  searchOptions?: { text: string; value: string }[];
  searchPlaceholderText?: string;
  searchText?: string;
  searchType?: string;
  stickyHeaderHeight?: number;
}) => {
  const handleLoadMore = () => {
    if (onLoadMore) {
      onLoadMore();
    }
  };

  return (
    <InfiniteTable
      hasNextPage={hasNextPage}
      initialSorting={initialSorting}
      isError={false}
      isLoadingFirstPage={isLoading}
      isLoadingNextPage={isFetching}
      onLoadMore={handleLoadMore}
      onSortCallback={() => undefined}
      stickyHeaderHeight={stickyHeaderHeight}
      tableOptions={{
        enableBottomToolbar: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableExpanding: false,
        enableFilters: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: false,
        enableRowActions: false,
        initialState,
        manualSorting: false,
        renderEmptyRowsFallback,
        renderTopToolbarCustomActions: () => (
          <Box
            alignItems='center'
            display='flex'
            height='40px'
            justifyContent='space-between'
            width='100%'
          >
            <TableFilters
              dateRangeFilter={dateRangeFilter}
              FilterComponent={FilterComponent}
              onSearch={onSearch}
              onSearchTypeChange={onSearchTypeChange}
              placeholder={searchPlaceholderText}
              searchOptions={searchOptions}
              searchText={searchText}
              searchType={searchType}
            />
            {Control}
          </Box>
        ),
        ...options,
        columns,
        data,
      }}
    />
  );
};

export default TriageModelTable;
