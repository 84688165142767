import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Breakpoint, useTheme } from '@mui/material/styles';
import { IconX } from '@tabler/icons-react';

import { getAppCuesId } from 'src/utils/appCuesUtil';

interface BaseModalProps {
  children: React.ReactNode;
  featureName?: string;
  hasScrolled?: boolean;
  HeaderProps?: BoxProps;
  headerTitle?: React.ReactNode;
  hideCloseButton?: boolean; // only call onClose when clicking outside
  isOpen: boolean;
  maxWidth?: Breakpoint;
  onClose?: () => void;
}

const BaseModal = ({
  children,
  featureName,
  hasScrolled,
  HeaderProps,
  headerTitle,
  hideCloseButton = false,
  isOpen,
  maxWidth = 'md',
  onClose,
}: BaseModalProps) => {
  const { palette } = useTheme();
  return (
    <Dialog
      data-appcues-target={getAppCuesId({
        componentType: 'modal',
        featureName: featureName || '',
        pageName: 'discover',
        subType: '',
      })}
      fullWidth
      maxWidth={maxWidth}
      onClose={onClose}
      open={isOpen}
    >
      <Box
        sx={[
          {
            alignItems: 'center',
            color: palette.colors.black,
            display: 'flex',
            padding: 3,
          },
          headerTitle
            ? { justifyContent: 'space-between' }
            : { justifyContent: 'flex-end' },
          Boolean(hasScrolled) && {
            borderBottom: `1px solid ${palette.colors.slate[300]}`,
          },
        ]}
        {...HeaderProps}
      >
        {headerTitle}
        {onClose && !hideCloseButton && (
          <IconButton aria-label='Close' onClick={onClose}>
            <IconX />
          </IconButton>
        )}
      </Box>
      {children}
    </Dialog>
  );
};

export default BaseModal;
