import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getMetadata } from 'src/actions/discover/discover';
import { selectMetadata } from 'src/reducers/discoverReducer/discoverReducer';
import { useAppDispatch } from 'src/store/hooks';

export const useGetMetadata = () => {
  const { data, error, loading } = useSelector(selectMetadata);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data || loading || error) {
      return;
    }

    dispatch(getMetadata());
  }, [data, dispatch, loading, error]);

  return { data, error, loading };
};
