import { useState } from 'react';
import * as Yup from 'yup';

import { updateOrganization } from '../../../slices/session/sessionSlice';
import AccountSettingsForm from 'src/components/account-settings-form/AccountSettingsForm';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { Organization, User } from 'src/services/apiInterfaces';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface OrgSettingsProps {
  currentUser: User;
  isLoading: boolean;
  organization: Organization;
}

export default function OrgSettings({
  currentUser,
  isLoading,
  organization,
}: OrgSettingsProps) {
  const { display_name, email_domains, org_type_display_name } = organization;
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useAppDispatch();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  return (
    <AccountSettingsForm
      avatarText={display_name?.toUpperCase() || ''}
      disabled={currentUser.permission_level === 'USER'}
      editButtonLabel='Edit org details'
      formConfig={[
        {
          id: 'display_name',
          label: 'Org name',
          value: display_name || '',
        },
        {
          id: 'email_domains',
          label: 'Email domains',
          readOnly: true,
          // TODO: when this field is no longer readonly, either show this list as separate values or handle formdata on save by splitting string
          value: email_domains?.join(', '),
        },
        {
          id: 'org_type_display_name',
          label: 'Type',
          readOnly: true,
          value: org_type_display_name,
        },
      ]}
      isEditing={isEditing}
      isLoading={isLoading}
      onSubmit={async formData => {
        const { display_name } = formData;
        try {
          await dispatch(
            updateOrganization({ organization: { display_name } }),
          ).unwrap();
          setIsEditing(false);
        } catch (error) {
          console.error(error);
          dispatch(
            setGlobalToastOptions({
              title: 'Something went wrong updating org details',
              variant: 'danger',
            }),
          );
        }
      }}
      setIsEditing={
        isEditing
          ? setIsEditing
          : isEditing => {
              emitTrackingEventCallback('account-org-details-edit-clicked');
              setIsEditing(isEditing);
            }
      }
      title='Organization'
      validationSchema={Yup.object().shape({
        display_name: Yup.string().required('Required'),
      })}
    />
  );
}
