import { isContextVariable } from '../helpers';
import { formatContextVariableForDisplay } from '../zendesk/helpers';
import { FieldNameToIdx } from './constants';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { JiraServiceManagementCreateRequestSettings } from 'src/types/workflowBuilderAPITypes';

export const validateCustomizationData = ({
  allowableContextVariables,
  customizationData,
}: {
  allowableContextVariables: Array<ContextVariable>;
  customizationData: JiraServiceManagementCreateRequestSettings;
}) => {
  const numFieldsToValidate = Object.keys(FieldNameToIdx).length;
  const errorMessages = new Array(numFieldsToValidate).fill('');
  const cvTemplatesByName = allowableContextVariables.map(
    cv => `{{${cv.context_variable_name}}}`,
  );

  // Validate other request field
  if (customizationData) {
    const keyField = Object.keys(customizationData?.other_request_fields);
    const valueField = Object.values(
      customizationData?.other_request_fields,
    ).map(item => item?.toString() || '');
    errorMessages[FieldNameToIdx.CUSTOM_FIELD_KEY] = validateCVTextField(
      keyField,
      allowableContextVariables,
      cvTemplatesByName,
    );
    errorMessages[FieldNameToIdx.CUSTOM_FIELD_VALUE] = validateCVTextField(
      valueField,
      allowableContextVariables,
      cvTemplatesByName,
    );
  }

  return errorMessages;
};

const validateCVTextField = (
  tags: string[],
  allowableContextVariables: ContextVariable[],
  cvTemplatesByName: string[],
) => {
  const ticketTagErrorMessages = [];
  const tagsSeenSoFar = new Set();

  for (const ticketTag of tags) {
    const displayTag = formatContextVariableForDisplay(
      allowableContextVariables,
      ticketTag,
    );

    if (
      isContextVariable(displayTag) &&
      !cvTemplatesByName.includes(displayTag)
    ) {
      ticketTagErrorMessages.push(
        'The context variable in this tag was not found',
      );
    } else if (displayTag === '') {
      ticketTagErrorMessages.push('Tags has to have value');
    } else {
      ticketTagErrorMessages.push('');
    }
    tagsSeenSoFar.add(displayTag);
  }
  return ticketTagErrorMessages;
};
