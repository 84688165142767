import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';

import Spinner from 'src/components/spinner';
import * as API from 'src/services/api';

export default function SSO() {
  const navigate = useNavigate();

  useEffect(() => {
    async function checkOrgAndSignIn() {
      /**
       * Subdomain should either be an org name or 'dashboard' as in 'dashboard.forethought.app'
       * If we have an org name, try to sign in using that org's SSO identity provider
       */
      const orgName = window.location.hostname.split('.')[0];

      const pattern = new RegExp('dashboard');
      if (!pattern.test(orgName)) {
        try {
          const provider = await API.getIdentityProviderForOrg(orgName);
          return Auth.federatedSignIn({
            customProvider: provider.identity_provider,
          });
        } catch (error) {
          console.error(`Error signing in with SSO: ${error}`);
          navigate('/');
        }
      }
    }

    checkOrgAndSignIn();
  }, [navigate]);

  // Amplify handles redirect
  return <Spinner />;
}
