export const suggestionIntents = [
  'Account_Changes',
  'Cancel_Order',
  'Cancel_Subscription',
  'Change_Shipping_Address',
  'Discounts',
  'Exchanges_And_Returns',
  'Issue_With_Order',
  'Login_Help',
  'Order_Status',
  'Payments',
  'Place_An_Order',
  'Product_Inquiries',
  'Refunds',
  'Shipping_Questions',
];

export const INTENT_QUERY_FILTER_DEBOUNCE_DELAY = 200;

export const INTENT_TABLE_HEADER_PROPERTIES = [
  'name',
  'inquiries',
  'deflected',
  'deflection_rate',
];

export const WORKFLOW_BUILDER_QUERY_PARAM_KEY = 'workflow_query_param';
