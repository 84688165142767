import { createAction } from '@reduxjs/toolkit';

import { ActionBuilderActions } from './ActionBuilderActionsTypes';
import {
  addActionAPI,
  getActionBuilderActionsAPI,
  getActionBuilderActiveIntegrationsAPI,
  updateActionAPI,
} from 'src/services/action-builder/actionBuilderApi';
import type { AppDispatch } from 'src/store/hooks';
import {
  ActionBuilderAction,
  ActiveIntegrationsInterface,
  AddOrUpdateActionInterface,
  OutputParameterKeysType,
  SelectedActionTemplate,
} from 'src/types/actionBuilderApiTypes';

// Set handoff action id
export const setCustomizableActionId = createAction<string>(
  ActionBuilderActions.SET_CUSTOMIZABLE_ACTION_ID,
);

// Get/delete/update actions
const getActionBuilderActionsSuccess = createAction<
  ActionBuilderAction['actions']
>(ActionBuilderActions.GET_ACTION_BUILDER_ACTIONS_SUCCESS);

const setActionBuilderActionsLoading = createAction<boolean>(
  ActionBuilderActions.SET_ACITON_BUILDER_ACTIONS_LOADING,
);

export const getActionBuilderActions =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setActionBuilderActionsLoading(true));
    return getActionBuilderActionsAPI()
      .then((data: ActionBuilderAction) => {
        dispatch(getActionBuilderActionsSuccess(data.actions));
      })
      .catch(() => {
        console.error('An error has ocurred');
      })
      .finally(() => {
        dispatch(setActionBuilderActionsLoading(false));
      });
  };

export const createActionBuilderAction =
  (action: AddOrUpdateActionInterface) =>
  (dispatch: AppDispatch): Promise<void> => {
    return addActionAPI(action)
      .then((data: ActionBuilderAction) => {
        dispatch(getActionBuilderActionsSuccess(data.actions));
      })
      .catch(() => {
        console.error('An error has ocurred');
      });
  };

export const updateActionBuilderAction =
  (action: AddOrUpdateActionInterface, actionId: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    return updateActionAPI(action, actionId)
      .then((data: ActionBuilderAction) => {
        dispatch(getActionBuilderActionsSuccess(data.actions));
      })
      .catch(() => {
        console.error('An error has ocurred');
      });
  };

// Get active integrations
const getActionBuilderActiveIntegrationsSuccess =
  createAction<ActiveIntegrationsInterface>(
    ActionBuilderActions.GET_ACTION_BUILDER_ACTIVE_INTEGRATIONS_SUCCESS,
  );

export const getActionBuilderActiveIntegrationsSettings =
  () =>
  (dispatch: AppDispatch): Promise<void> => {
    return getActionBuilderActiveIntegrationsAPI()
      .then((data: ActiveIntegrationsInterface) => {
        dispatch(getActionBuilderActiveIntegrationsSuccess(data));
      })
      .catch(() => {
        console.error('An error has ocurred');
      });
  };

// Error state for context variables settings
export const setContextVariablesSettingsErrorState = createAction<boolean>(
  ActionBuilderActions.SET_CONTEXT_VARIABLES_SETTINGS_ERROR_STATE,
);

export const setOutputParameterModalActionData =
  createAction<OutputParameterKeysType>(
    ActionBuilderActions.SET_OUTPUT_PARAMETER_MODAL_ACTION_DATA,
  );

export const clearOutputParametersModalActionData = createAction(
  ActionBuilderActions.CLEAR_OUTPUT_PARAMETERS_MODAL_ACTION_DATA,
);

export const clearSelectedAction = createAction<undefined>(
  ActionBuilderActions.CLEAR_SELECTED_ACTION,
);

// Action Template Action
export const setSelectedActionTemplate =
  createAction<SelectedActionTemplate | null>(
    ActionBuilderActions.SET_SELECTED_ACTION_TEMPLATE,
  );

// Selected Action Name - Property before form changes
export const setSelectedInitialActionName = createAction<string>(
  ActionBuilderActions.SET_SELECTED_INITIAL_ACTION_NAME,
);
