import { Auth } from 'aws-amplify';

import { getSearchOrganizations } from 'src/actions/superAdmin';
import store from 'src/store/store';
import { UserRoles } from 'src/utils/enums';

export const setUserRole = (userInfoArr: []): string | undefined => {
  const { host } = window.location;
  const protocol = host.includes('localhost') ? 'http://' : 'https://';
  const isInternalUI = host === INTERNAL_UI_URL;

  const userRole: string | unknown = userInfoArr.find(
    (userInfo: string | number) => {
      if (typeof userInfo === 'string') {
        return userInfo.includes('ROLE');
      }
    },
  );

  localStorage.setItem('userRole', userRole as string);
  if (userRole !== UserRoles.ROLE_SUPER_ADMIN) {
    if (isInternalUI) {
      Auth.signOut();
      alert('User is not super admin, redirecting to public dashboard.');
      window.location.replace(`${protocol}${PUBLIC_UI_URL}`);
    }
  } else if (userRole === UserRoles.ROLE_SUPER_ADMIN) {
    if (!isInternalUI) {
      Auth.signOut();
      window.location.replace(`${protocol}${INTERNAL_UI_URL}`);
    } else {
      store.dispatch(getSearchOrganizations());
    }
  }
  return userRole as string;
};
