import { HTMLWithContext } from './HTMLWithContext';
import { IdleCanvasComponentProps } from 'src/components/email-builder/types';

export default function IntentEmailTextIdle({
  component,
}: IdleCanvasComponentProps) {
  const { message } = component.component_fields;

  return <HTMLWithContext html={message} />;
}
