import {
  ArticleSource,
  ArticleSuggestionActionSettings,
} from 'src/types/workflowBuilderAPITypes';

export const addArticleSource = (
  articleSources: ArticleSuggestionActionSettings['article_sources'],
) => {
  const newArticleSource: ArticleSource = {
    doc_type: null,
    filter_queries: [
      {
        boolean_operator: 'and',
        field: null,
        operator: 'is',
        value: null,
      },
    ],
  };

  return [...articleSources, newArticleSource];
};
