import React from 'react';
import { styled } from '@mui/material';

import { Button, theme } from '@forethought-technologies/forethought-elements';

interface SettingsSectionProps {
  actions?: {
    isLoading?: boolean;
    label: string;
    onClick: () => void;
  }[];
  disabled?: boolean;
  id?: string;
  subtitle?: string;
  title: string;
}

const SettingsSection: React.FC<
  React.PropsWithChildren<SettingsSectionProps>
> = ({ actions, children, disabled, id, subtitle, title }) => {
  return (
    <Section id={id}>
      <Header>
        <div>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </div>
        {actions && !disabled && (
          <ButtonContainer>
            {actions.map((action, idx, { length }) => (
              <Button
                isLoading={action.isLoading}
                key={action.label}
                onClick={action.onClick}
                variant={
                  // with more than one button, make the last one 'main' variant
                  length > 1 && idx === length - 1 ? 'main' : 'secondary'
                }
              >
                {action.label}
              </Button>
            ))}
          </ButtonContainer>
        )}
      </Header>
      {children}
    </Section>
  );
};

const Section = styled('section')`
  background-color: ${theme.palette.colors.white};
  font-family: 'Plus Jakarta Sans', sans-serif;
  padding-bottom: 32px;
`;

const Header = styled('div')`
  align-items: center;
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

const Title = styled('h2')`
  font-size: 18px;
  padding: 0;
  margin: 0;
`;

const Subtitle = styled('p')`
  font-size: 12px;
`;

const ButtonContainer = styled('div')`
  display: flex;
  gap: 8px;
`;

export default SettingsSection;
