import React from 'react';
import { useTheme } from '@mui/material';

import { useWidgetAggregateData } from '../intent-workflows-table/hooks/useWidgetAggregateData';
import AggregateMetrics, { AggregateMetric } from './AggregateMetrics';
import {
  formatNumber,
  getLabelForMetric,
  getTooltipForMetric,
} from 'src/components/reusable-components/data-overview-card/helpers';
import { SHARED_METRIC_DEFINITIONS, TEMP_BLUE } from 'src/constants/solve';
import { NA } from 'src/constants/solve';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { MetricAggregateResponse } from 'src/types/workflowBuilderAPITypes';
import {
  formatNumberWithPercentage,
  formatPercentage,
} from 'src/utils/solve/deflectionInsightsUtils';

interface FormatMetricsOpts {
  isDifferenceEnabled: boolean;
}

export default function WidgetAggregateMetricsContainer() {
  const { data: data, isLoading } = useWidgetAggregateData();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isDifferenceEnabled = featureFlags.includes(
    'workflow_builder_metrics_difference_enabled',
  );
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const isCSATEnabled = featureFlags.includes('landing_page_csat');
  const { palette } = useTheme();

  const getCsatColor = useGetCSATColor();

  const formatMetrics = React.useCallback(
    function formatMetrics(
      data: MetricAggregateResponse,
      { isDifferenceEnabled }: FormatMetricsOpts,
    ): AggregateMetric[] {
      return [
        {
          difference: isDifferenceEnabled
            ? data.aggregate.user_queries.change
            : undefined,
          label: getLabelForMetric('user_queries'),
          tooltip: getTooltipForMetric('user_queries'),
          value: formatNumber(
            'user_queries',
            data.aggregate.user_queries.user_queries,
          ),
        },
        {
          difference: isDifferenceEnabled
            ? data.aggregate.resolutions.change
            : undefined,
          label: getLabelForMetric('resolutions'),
          tooltip: getTooltipForMetric('resolutions'),
          value: formatNumber(
            'resolutions',
            data.aggregate.resolutions.resolutions,
          ),
        },
        {
          difference: isDifferenceEnabled
            ? data.aggregate.deflection_rate.change
            : undefined,
          label: getLabelForMetric('deflection_rate'),
          tooltip: getTooltipForMetric('deflection_rate'),
          value: formatNumber(
            'deflection_rate',
            data.aggregate.deflection_rate.deflection_rate,
          ),
        },
        {
          difference: isDifferenceEnabled
            ? data.aggregate.cost_savings.change
            : undefined,
          label: getLabelForMetric('cost_savings'),
          tooltip: getTooltipForMetric(
            'cost_savings',
            data.aggregate.cost_savings.cost_per_ticket,
          ),
          value: formatNumber(
            'cost_savings',
            data.aggregate.cost_savings.cost_savings,
          ),
        },
        ...(isCSATEnabled
          ? [
              {
                color: getCsatColor(data.aggregate.avg_csat),
                label: 'CSAT',
                value: formatCSAT(data.aggregate.avg_csat),
              },
            ]
          : []),
      ];
    },
    [getCsatColor, isCSATEnabled],
  );

  const formatMetricsWithDeflectionInsights = React.useCallback(
    function (data: MetricAggregateResponse) {
      const relevanceBreakdown = data.aggregate?.relevance_breakdown;

      return [
        {
          label: getLabelForMetric('user_queries'),
          tooltip: getTooltipForMetric('user_queries'),
          value: formatNumber(
            'user_queries',
            data.aggregate.user_queries.user_queries,
          ),
        },
        {
          label: 'Deflections',
          tooltip: getTooltipForMetric('resolutions'),
          value: formatNumberWithPercentage(
            data.aggregate.resolutions.resolutions,
            data.aggregate.user_queries.user_queries,
          ),
        },
        {
          label: 'Cost Savings',
          tooltip: getTooltipForMetric(
            'cost_savings',
            data.aggregate.cost_savings.cost_per_ticket,
          ),
          value: formatNumber(
            'cost_savings',
            data.aggregate.cost_savings.cost_savings,
          ),
        },

        {
          ...SHARED_METRIC_DEFINITIONS.relevance,
          value: [
            {
              ...SHARED_METRIC_DEFINITIONS.relevant,
              color: TEMP_BLUE,
              value: relevanceBreakdown
                ? formatPercentage(
                    relevanceBreakdown.relevant_count,
                    relevanceBreakdown.irrelevant_count +
                      relevanceBreakdown.relevant_count +
                      relevanceBreakdown.somewhat_relevant_count,
                  )
                : NA,
            },
            {
              ...SHARED_METRIC_DEFINITIONS.somewhatRelevant,
              color: palette.colors.blue[400],
              value: relevanceBreakdown
                ? formatPercentage(
                    relevanceBreakdown.somewhat_relevant_count,
                    relevanceBreakdown.irrelevant_count +
                      relevanceBreakdown.relevant_count +
                      relevanceBreakdown.somewhat_relevant_count,
                  )
                : NA,
            },
            {
              ...SHARED_METRIC_DEFINITIONS.irrelevant,
              color: palette.colors.red[500],
              value: relevanceBreakdown
                ? formatPercentage(
                    relevanceBreakdown.irrelevant_count,
                    relevanceBreakdown.irrelevant_count +
                      relevanceBreakdown.relevant_count +
                      relevanceBreakdown.somewhat_relevant_count,
                  )
                : NA,
            },
          ],
        },
        {
          ...SHARED_METRIC_DEFINITIONS.userEngagement,
          color: TEMP_BLUE,
          value: data.aggregate.dropoff_breakdown
            ? formatNumberWithPercentage(
                data.aggregate.dropoff_breakdown.no_dropoff_count,
                data.aggregate.dropoff_breakdown.no_dropoff_count +
                  data.aggregate.dropoff_breakdown.dropoff_count,
              )
            : NA,
        },
        ...(isCSATEnabled
          ? [
              {
                color: getCsatColor(data.aggregate.avg_csat),
                label: 'CSAT',
                value: formatCSAT(data.aggregate.avg_csat),
              },
            ]
          : []),
      ];
    },
    [palette, isCSATEnabled, getCsatColor],
  );

  const metrics = React.useMemo(() => {
    if (!data) {
      return null;
    }

    if (isDeflectionInsightsEnabled) {
      return formatMetricsWithDeflectionInsights(data);
    }

    return formatMetrics(data, { isDifferenceEnabled });
  }, [
    data,
    formatMetricsWithDeflectionInsights,
    isDifferenceEnabled,
    isDeflectionInsightsEnabled,
    formatMetrics,
  ]);

  return (
    <AggregateMetrics
      isDeflectionInsightsEnabled={isDeflectionInsightsEnabled}
      metrics={metrics}
      numLoadingSkeletons={
        isLoading ? (isDeflectionInsightsEnabled ? 5 : 4) : 0
      }
    />
  );
}
