import { DiscoverTopicTableData } from '../types';
import { BookmarkedRendererComponent } from './BookmarkedRenderer';

export const bookmarkedCellRenderer =
  (trackingEventMetadata: Record<string, string | string[]> = {}) =>
  // eslint-disable-next-line react/display-name
  ({ id, topic }: DiscoverTopicTableData, isSummaryRow?: boolean) =>
    isSummaryRow ? null : (
      <BookmarkedRendererComponent
        isBookmarked={topic?.is_bookmarked}
        topicId={id ?? ''}
        trackingEventMetadata={trackingEventMetadata}
      />
    );
