import React, { Dispatch, FC, SetStateAction } from 'react';

import { Checkbox } from '../../../../../components/checkbox/Checkbox';
import {
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { ZendeskCommonCustomization } from 'src/types/workflowBuilderAPITypes';

interface AllowAttachmentUploadProps {
  customizationData: ZendeskCommonCustomization;
  setCustomizationData: Dispatch<SetStateAction<ZendeskCommonCustomization>>;
}
const AllowAttachmentUpload: FC<
  React.PropsWithChildren<AllowAttachmentUploadProps>
> = ({ customizationData, setCustomizationData }) => {
  return (
    <Row>
      <Checkbox
        checked={
          !!customizationData.ticket_creation_settings?.ticket_creation_config
            ?.allow_attachment_upload
        }
        onChange={() =>
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              ticket_creation_settings: {
                ...handoffData.ticket_creation_settings,
                ticket_creation_config: {
                  ...customizationData.ticket_creation_settings
                    ?.ticket_creation_config,
                  allow_attachment_upload:
                    !handoffData.ticket_creation_settings
                      ?.ticket_creation_config?.allow_attachment_upload,
                },
              },
            };
          })
        }
      />
      <Spacer width='4px' />
      <span>Allow users to upload file to ticket</span>
    </Row>
  );
};

export default AllowAttachmentUpload;
