import { dashboardApi } from '../dashboard-api';
import {
  EmailAutomation,
  EmailAutomationOrder,
  EmailAutomationRequest,
  EmailAutomationsResponse,
  TicketCustomFieldsResponse,
} from 'src/pages/workflow-builder-edit/types';

const BASE_URL = 'solve/email';

const emailAutomationApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    activateEmailAutomation: builder.mutation<EmailAutomation, string>({
      async onQueryStarted(emailAutomationId, { dispatch, queryFulfilled }) {
        const { data: updatedEmailAutomation } = await queryFulfilled;
        dispatch(
          emailAutomationApi.util.updateQueryData(
            'getEmailAutomations',
            undefined,
            data => {
              const emailAutomationsList = data.email_automations;

              data.email_automations = emailAutomationsList.filter(
                automation => {
                  if (automation.email_automation_id === emailAutomationId) {
                    return false;
                  }
                  return true;
                },
              );

              // Ensure that the activated automation is at the end of the list
              data.email_automations.push(updatedEmailAutomation);
            },
          ),
        );
      },
      query: emailAutomationId => ({
        method: 'POST',
        url: `${BASE_URL}/automation/${emailAutomationId}/activate`,
      }),
    }),
    createEmailAutomation: builder.mutation<
      EmailAutomation,
      EmailAutomationRequest
    >({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: newEmailAutomation } = await queryFulfilled;
        dispatch(
          emailAutomationApi.util.updateQueryData(
            'getEmailAutomations',
            undefined,
            data => {
              data.email_automations.push(newEmailAutomation);
            },
          ),
        );
      },
      query: automation => ({
        body: automation,
        method: 'POST',
        url: `${BASE_URL}/automation`,
      }),
    }),

    deactivateEmailAutomation: builder.mutation<EmailAutomation, string>({
      async onQueryStarted(emailAutomationId, { dispatch, queryFulfilled }) {
        const { data: updatedEmailAutomation } = await queryFulfilled;
        dispatch(
          emailAutomationApi.util.updateQueryData(
            'getEmailAutomations',
            undefined,
            data => {
              const emailAutomationsList = data.email_automations;

              data.email_automations = emailAutomationsList.map(automation => {
                if (automation.email_automation_id === emailAutomationId) {
                  return { ...updatedEmailAutomation };
                }
                return automation;
              });
            },
          ),
        );
      },
      query: emailAutomationId => ({
        method: 'POST',
        url: `${BASE_URL}/automation/${emailAutomationId}/deactivate`,
      }),
    }),

    deleteEmailAutomation: builder.mutation<EmailAutomation, string>({
      async onQueryStarted(emailAutomationId, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          emailAutomationApi.util.updateQueryData(
            'getEmailAutomations',
            undefined,
            data => {
              const emailAutomationsList = data.email_automations;

              data.email_automations = emailAutomationsList.filter(
                automation =>
                  automation.email_automation_id !== emailAutomationId,
              );
            },
          ),
        );
      },
      query: emailAutomationId => ({
        body: {},
        method: 'DELETE',
        url: `${BASE_URL}/automation/${emailAutomationId}`,
      }),
    }),
    getEmailAutomations: builder.query<EmailAutomationsResponse, void>({
      query: () => `${BASE_URL}/automation`,
    }),

    getTicketCustomFields: builder.query<TicketCustomFieldsResponse, void>({
      query: () => 'solve/tickets/ticket-custom-field',
    }),

    updateEmailAutomation: builder.mutation<
      EmailAutomation,
      EmailAutomationRequest
    >({
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: updatedEmailAutomation } = await queryFulfilled;
        dispatch(
          emailAutomationApi.util.updateQueryData(
            'getEmailAutomations',
            undefined,
            data => {
              const emailAutomationsList = data.email_automations;

              data.email_automations = emailAutomationsList.map(automation => {
                if (
                  automation.email_automation_id ===
                  updatedEmailAutomation.email_automation_id
                ) {
                  return {
                    ...updatedEmailAutomation,
                  };
                }
                return automation;
              });
            },
          ),
        );
      },
      query: automation => {
        const {
          automation_actions,
          case_insensitive,
          email_automation_id,
          query_expression,
          run_other_automations,
          uses_mapped_fields,
        } = automation;
        return {
          body: {
            automation_actions,
            case_insensitive,
            query_expression,
            run_other_automations,
            uses_mapped_fields,
          },
          method: 'PUT',
          url: `${BASE_URL}/automation/${email_automation_id}`,
        };
      },
    }),
    updateEmailAutomationOrder: builder.mutation<
      EmailAutomationOrder,
      EmailAutomationOrder
    >({
      async onQueryStarted(emailAutomationOrder, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          emailAutomationApi.util.updateQueryData(
            'getEmailAutomations',
            undefined,
            draft => {
              draft.email_automations.sort(
                (emailAutomationA, emailAutomationB) => {
                  const indexA =
                    emailAutomationOrder.email_automation_ids.indexOf(
                      emailAutomationA.email_automation_id,
                    );
                  const indexB =
                    emailAutomationOrder.email_automation_ids.indexOf(
                      emailAutomationB.email_automation_id,
                    );
                  return indexA - indexB;
                },
              );
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
          throw e;
        }
      },
      query: emailAutomationOrder => {
        return {
          body: {
            email_automation_ids: emailAutomationOrder.email_automation_ids,
          },
          method: 'PUT',
          url: `${BASE_URL}/automation-order`,
        };
      },
    }),
  }),
});

export const {
  useActivateEmailAutomationMutation,
  useCreateEmailAutomationMutation,
  useDeactivateEmailAutomationMutation,
  useDeleteEmailAutomationMutation,
  useGetEmailAutomationsQuery,
  useGetTicketCustomFieldsQuery,
  useUpdateEmailAutomationMutation,
  useUpdateEmailAutomationOrderMutation,
} = emailAutomationApi;
