import moment from 'moment';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  ALERT_ERROR_MESSAGE,
  ALERT_ERROR_TITLE,
  ALERT_PROGRESS_MESSAGE,
  ALERT_PROGRESS_TITLE,
  ALERT_REPLACE_MESSAGE,
  ALERT_REPLACE_TITLE,
  ALERT_SUCCESS_MESSAGE,
  ALERT_SUCCESS_TITLE,
} from '../constants';
import {
  KNOWLEDGE_ERROR_STATUS,
  KNOWLEDGE_FINISHED_STATUS,
  KNOWLEDGE_IN_PROGRESS_STATUS,
} from 'src/slices/solve-config/constants';
import { SolveKnowledgeSource } from 'src/slices/solve-config/types';

interface NotificationStatusProps {
  isReplaceScenario: boolean;
  knowledgeSource?: SolveKnowledgeSource;
}

const NotificationStatus = ({
  isReplaceScenario,
  knowledgeSource,
}: NotificationStatusProps) => {
  const { palette } = useTheme();

  if (isReplaceScenario) {
    return (
      <Box pt='30px'>
        {knowledgeSource && (
          <>
            <Box>
              <Typography
                color={palette.colors.grey[700]}
                variant='font14Medium'
              >
                {'Current connection: '}
              </Typography>
              <Typography variant='font14Medium'>
                {knowledgeSource.custom_url
                  ? knowledgeSource.custom_url
                  : knowledgeSource.integration_name}
              </Typography>
            </Box>
            <Box pb='18px'>
              <Typography
                color={palette.colors.grey[700]}
                variant='font14Medium'
              >
                {`Last sync: ${moment(knowledgeSource.last_sync_date).format(
                  'MMMM Do YYYY [at] h:mm a',
                )}`}
              </Typography>
            </Box>
          </>
        )}
        <Box
          bgcolor='#F2F4F7'
          border={`1px solid ${palette.colors.slate[300]}`}
          padding='15px 20px'
        >
          <Typography variant='font14Bold'>{ALERT_REPLACE_TITLE}</Typography>
          <Box />
          <Typography color={palette.grey[700]} variant='font14Medium'>
            {ALERT_REPLACE_MESSAGE}
          </Typography>
        </Box>
      </Box>
    );
  }

  switch (knowledgeSource?.status) {
    case KNOWLEDGE_ERROR_STATUS:
      return (
        <>
          <Box pt='30px'>
            <Typography color={palette.colors.grey[700]} variant='font14Medium'>
              {'Attempted to connect: '}
            </Typography>
            <Typography variant='font14Medium'>
              {knowledgeSource.custom_url
                ? knowledgeSource.custom_url
                : knowledgeSource.integration_name}
            </Typography>
          </Box>
          {knowledgeSource.last_sync_date && (
            <Box>
              <Typography
                color={palette.colors.grey[700]}
                variant='font14Medium'
              >
                {`Last sync: ${moment(knowledgeSource.last_sync_date).format(
                  'MMMM Do YYYY [at] h:mm a',
                )}`}
              </Typography>
            </Box>
          )}
          <Box
            bgcolor='rgb(254, 177, 155, 0.1)'
            border={`1px solid ${palette.colors.red[500]}`}
            mt='18px'
            padding='15px 20px'
          >
            <Typography variant='font14Bold'>{ALERT_ERROR_TITLE}</Typography>
            <Box />
            <Typography color={palette.grey[700]} variant='font14Medium'>
              {ALERT_ERROR_MESSAGE}
            </Typography>
          </Box>
        </>
      );
    case KNOWLEDGE_FINISHED_STATUS:
      return (
        <>
          <Box pt='30px'>
            <Typography color={palette.colors.grey[700]} variant='font14Medium'>
              {'Current connection: '}
            </Typography>
            <Typography variant='font14Medium'>
              {knowledgeSource.custom_url
                ? knowledgeSource.custom_url
                : knowledgeSource.integration_name}
            </Typography>
          </Box>
          <Box pb='18px'>
            <Typography color={palette.colors.grey[700]} variant='font14Medium'>
              {`Last sync: ${moment(knowledgeSource.last_sync_date).format(
                'MMMM Do YYYY [at] h:mm a',
              )}`}
            </Typography>
          </Box>
          <Box
            bgcolor='#E8F6F1'
            border={`1px solid ${palette.colors.green[500]}`}
            padding='15px 20px'
          >
            <Typography variant='font14Bold'>{ALERT_SUCCESS_TITLE}</Typography>
            <Box />
            <Typography color={palette.grey[700]} variant='font14Medium'>
              {ALERT_SUCCESS_MESSAGE}
            </Typography>
          </Box>
        </>
      );
    case KNOWLEDGE_IN_PROGRESS_STATUS:
      return (
        <Box
          bgcolor='#F2F4F7'
          border={`1px solid ${palette.colors.slate[300]}`}
          mt='30px'
          padding='15px 20px'
        >
          <Typography variant='font14Bold'>{ALERT_PROGRESS_TITLE}</Typography>
          <Box />
          <Typography color={palette.grey[700]} variant='font14Medium'>
            {ALERT_PROGRESS_MESSAGE}
          </Typography>
        </Box>
      );
    default:
      return null;
  }
};

export default NotificationStatus;
