import { useMemo } from 'react';

import { useGetMetadata } from './useGetMetadata';
import { metricFilterOptions } from 'src/constants/discover';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';

export const useGetAvailableMetricFilterOptions = (
  metricFilter: DiscoverTopicAggregatedMetricType = 'volume',
): {
  label: string;
  value: DiscoverTopicAggregatedMetricType;
}[] => {
  const { data: metadata, loading: isMetadataLoading } = useGetMetadata();
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };
  const availableMetricFilterOptions = useMemo(() => {
    const availableMetricsTypes = new Set(
      availableMetrics.map(({ type }) => type),
    );
    return metricFilterOptions.filter(item =>
      availableMetricsTypes.has(item.value),
    );
  }, [availableMetrics]);

  const initialMetricFilterOptions = metricFilterOptions.filter(
    option => option.value === metricFilter,
  );

  return isMetadataLoading || availableMetricFilterOptions.length === 0
    ? initialMetricFilterOptions
    : availableMetricFilterOptions;
};
