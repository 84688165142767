import React, { FC } from 'react';

import ActionBuilderV2 from './action-builder-v2';
import { useGetTicketFieldMappings } from 'src/hooks/useGetTicketFieldMappings';

const ActionBuilderPage: FC<React.PropsWithChildren<unknown>> = () => {
  useGetTicketFieldMappings();

  return <ActionBuilderV2 />;
};

export { ActionBuilderPage };
