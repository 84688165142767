import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

interface KnowledgeGapItemContainerProps {
  children?: React.ReactNode;
  linkTo?: string;
  onClick?: () => void;
  onMouseEnter: () => void;
  style: CSSProperties;
}

const KnowledgeGapItemContainer = ({
  children,
  linkTo,
  onClick,
  onMouseEnter,
  style,
}: KnowledgeGapItemContainerProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    if (linkTo) {
      navigate(linkTo);
    }
  };
  return (
    <Box
      alignItems='center'
      borderRadius={1}
      color={palette.colors.black}
      columnGap={1}
      display='grid'
      gridTemplateColumns='auto repeat(3, 200px)'
      mb={2}
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      p={3}
      sx={[
        {
          border: `1px solid ${palette.colors.slate[200]}`,
          justifyItems: 'baseline',
          textDecoration: 'none',
        },
        linkTo || onClick
          ? {
              '&:hover': {
                border: `1px solid ${palette.colors.grey[800]}`,
                boxShadow:
                  '0px 0px 1px rgba(0, 12, 32, 0.04), 0px 2px 6px rgba(3, 17, 38, 0.107135)',
              },
              cursor: 'pointer',
            }
          : null,
        { ...style },
      ]}
    >
      {children}
    </Box>
  );
};

export default KnowledgeGapItemContainer;
