import { useContext } from 'react';
import { Box } from '@mui/material';

import GeneratedResponsesSection from '../generated-responses-section/GeneratedResponsesSection';
import IntentEmailForm from '../IntentEmailForm';
import { EmailBuilderContext } from 'src/components/email-builder/EmailBuilderContext';
import { ToolbarFormComponentProps } from 'src/components/email-builder/types';
import { IntentEmailConfigurationTextComponent } from 'src/pages/workflow-builder-edit/types';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

const IntentEmailTextForm = ({
  component,
  onClickCancel,
  onClickSave,
  setActiveComponentFields,
}: ToolbarFormComponentProps) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailReplyGenerationEnabled = featureFlags?.includes(
    'solve_email_reply_generation_enabled',
  );
  const { richTextEditorRef } = useContext(EmailBuilderContext);

  const componentFields =
    component.component_fields as IntentEmailConfigurationTextComponent['component_fields'];

  const message = componentFields.message;

  return (
    <IntentEmailForm
      isSaveButtonDisabled={false}
      onClickCancel={() => onClickCancel()}
      onClickSave={() => onClickSave()}
    >
      <Box
        display='flex'
        flex='1'
        flexDirection={'column'}
        gap='20px'
        overflow={'scroll'}
      >
        {isSolveEmailReplyGenerationEnabled && (
          <GeneratedResponsesSection
            onApplyResponse={(generatedResponse: string) => {
              const udpatedMessage = message + `<p>${generatedResponse}</p>`;
              setActiveComponentFields({
                message: udpatedMessage,
              });
              richTextEditorRef?.current?.setContent(udpatedMessage);
            }}
          />
        )}
      </Box>
    </IntentEmailForm>
  );
};

export default IntentEmailTextForm;
