import { useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/pages/intent-conversation-analytics/constants';

const EmptyValue = () => {
  const { palette } = useTheme();
  return (
    <Typography color={palette.colors.grey[600]} variant='font14'>
      {NA}
    </Typography>
  );
};

export default EmptyValue;
