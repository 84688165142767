import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  automationTemplate,
  automationTemplateConnector,
  automationTemplatePreviewOutput,
} from './mockData';
import { AutomationCallPreview } from './types';
import { Automation, Connector } from 'src/services/apiInterfaces';
import * as API from 'src/services/apiV1';

export const fetchConnector = createAsyncThunk(
  'assistAutomations/fetchConnector',
  async (connectorId: string): Promise<{ connector: Required<Connector> }> => {
    const connector =
      connectorId === automationTemplate.action_list[0].connector_id
        ? automationTemplateConnector
        : await API.getConnectorById(connectorId);

    return { connector: { ...connector, connector_id: connectorId } };
  },
);

export const fetchAssistAutomations = createAsyncThunk(
  'assistAutomations/automations',
  async (isPreFilledTemplateEnabled?: boolean) =>
    API.getAssistAutomations(isPreFilledTemplateEnabled),
);

export const updateAssistAutomation = createAsyncThunk(
  'assistAutomations/updateAssistAutomation',
  async ({
    automationDetailsToUpdate,
    automationId,
  }: {
    automationDetailsToUpdate: Partial<Automation>;
    automationId: string;
  }): Promise<Automation> => {
    // Remove automation_id from body as api throw an error
    const _automationDetailsToUpdate = { ...automationDetailsToUpdate };

    delete _automationDetailsToUpdate.automation_id;

    return await API.updateAssistAutomation(
      automationId,
      _automationDetailsToUpdate,
    );
  },
);

export const toggleAssistAutomationPublished = createAsyncThunk(
  'assistAutomations/toggleAssistAutomationPublished',
  async ({
    automationId,
    published,
  }: {
    automationId: string;
    published: boolean;
  }) => {
    return await API.updateAssistAutomation(automationId, {
      published,
    });
  },
);

export const callPreviewAssistAutomation = createAsyncThunk(
  'assistAutomations/callPreviewAssistAutomation',
  async ({
    automation,
    inputs,
    isReadOnly,
  }: {
    automation: AutomationCallPreview;
    inputs: Record<string, string | string[] | number>;
    isReadOnly: boolean;
  }) => {
    if (isReadOnly) {
      return automationTemplatePreviewOutput;
    }

    return await API.callPreviewAssistAutomation({
      automation,
      inputs,
    });
  },
);

export const deleteAssistAutomation = createAsyncThunk(
  'assistAutomations/deleteAssistAutomation',
  async (automationId: string) => {
    return await API.deleteAssistAutomation(automationId);
  },
);
