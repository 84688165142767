import partition from 'lodash/fp/partition';
import { GeneratedEmailResponse } from 'src/pages/workflow-builder-edit/types';

// Sorts generated responses by status and then by score, we should render accepted responses first
export const sortGeneratedResponses = (
  generatedResponses: Array<GeneratedEmailResponse>,
) => {
  const [acceptedResponses, rejectedResponses] = partition(
    response => response.status === 'accepted',
    generatedResponses,
  );

  acceptedResponses.sort((itemA, itemB) => itemB.score - itemA.score);
  rejectedResponses.sort((itemA, itemB) => itemB.score - itemA.score);

  return acceptedResponses.concat(rejectedResponses);
};
