import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { tableLoadingSkeleton } from '../../discover-all-topics-page/AllTopicsTableV2/helpers';
import { InsightTopicData } from './types';

type NameValueProps = {
  clusterId: string;
  clusterName: string;
  isChild?: boolean;
  onClick: (params: { clusterId: string; isChild: boolean }) => void;
  parentTopicName: string;
  subtopics: InsightTopicData[];
};

const NameValue = ({
  clusterId,
  clusterName,
  isChild,
  onClick,
  parentTopicName,
  subtopics,
}: NameValueProps) => {
  const { palette } = useTheme();

  const handleClick = () => {
    onClick({
      clusterId: clusterId,
      isChild: Boolean(isChild),
    });
  };
  const hasClusterName = Boolean(clusterName);

  const parentTopicNameComponent = (
    <Typography color={palette.colors.grey[600]} noWrap variant='font14'>
      &nbsp;({parentTopicName})
    </Typography>
  );

  const topicsLength = ` (${subtopics.length || 0})`;

  const getName = () => {
    return (
      <Typography variant={isChild ? 'font14' : 'font16Bold'}>
        {clusterName}
        {parentTopicName && parentTopicNameComponent}
        {!isChild && topicsLength}
      </Typography>
    );
  };

  return (
    <Box
      alignItems='center'
      display='flex'
      gap='4px'
      justifyContent='start'
      width='100%'
    >
      {hasClusterName ? (
        <Box
          onClick={handleClick}
          sx={[
            {
              cursor: 'pointer',
              minWidth: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          ]}
        >
          {getName()}
        </Box>
      ) : (
        <Box width={100}>{tableLoadingSkeleton}</Box>
      )}
    </Box>
  );
};

export default NameValue;
