import './index.scss';

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { setToastType } from 'src/actions/toastNotification/toastNotification';
import { selectToastType } from 'src/reducers/toastNotificationReducer/toastNotificationReducer';
import {
  DuplicateEmailToast,
  ErrorBookmarkToast,
  InvalidEmailToast,
  linkCopiedToast,
  MacroDeactivationSucessToast,
  MacroSucessToast,
  MacroUpdateErrorToast,
  TypesOfToast,
} from 'src/utils/enums';

const ToastNotification: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();

  const toastType = useSelector(selectToastType);

  const clearState = () => {
    dispatch(setToastType(''));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (toastType.length) {
        clearState();
      }
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [toastType.length]);

  const closeButtonClass = classNames('ToastNotification-closeButton', {
    none:
      toastType ===
      (TypesOfToast.MACRO_UPDATE_ERROR || TypesOfToast.MACRO_UPDATE_SUCCESS),
    'ToastNotification-closeButton--red':
      toastType === TypesOfToast.INVALID_EMAIL || TypesOfToast.DUPLICATE_EMAIL,
    'ToastNotification-closeButton--white':
      toastType !==
      (TypesOfToast.MACRO_UPDATE_ERROR || TypesOfToast.MACRO_UPDATE_SUCCESS),
  });

  const setToast = (toast: string) => {
    let toastType: { [index: string]: string } = {};
    if (toast) {
      if (toast === TypesOfToast.BOOKMARK_TOAST) {
        toastType = ErrorBookmarkToast;
      }
      if (toast === TypesOfToast.LINK_COPIED) {
        toastType = linkCopiedToast;
      }
      if (toast === TypesOfToast.MACRO_UPDATE_ERROR) {
        toastType = MacroUpdateErrorToast;
      }
      if (toast === TypesOfToast.MACRO_UPDATE_SUCCESS) {
        toastType = MacroSucessToast;
      }
      if (toast === TypesOfToast.MACRO_DEACTIVATION_SUCCESS) {
        toastType = MacroDeactivationSucessToast;
      }
      if (toast === TypesOfToast.INVALID_EMAIL) {
        toastType = InvalidEmailToast;
      }
      if (toast === TypesOfToast.DUPLICATE_EMAIL) {
        toastType = DuplicateEmailToast;
      }
    }
    return toastType;
  };

  const typeOfToast = setToast(toastType);

  return (
    <>
      {toastType.length > 0 && (
        <div className={`ToastNotification ${typeOfToast.TOASTCLASS}`}>
          <span className={`ToastNotification-icon ${typeOfToast.ICONCLASS}`} />
          {typeOfToast.TOASTMESSAGE}
          <button
            className={closeButtonClass}
            data-testid='close-toast'
            onClick={() => clearState()}
          />
        </div>
      )}
    </>
  );
};

export default ToastNotification;
