import './styles.scss';

import React from 'react';

interface OpenButtonProps {
  /** Text for the button */
  buttonText?: string;
  /** URL to which the button redirects */
  redirectURL?: string;
}

const OpenURLButton: React.FC<React.PropsWithChildren<OpenButtonProps>> = ({
  buttonText,
  redirectURL,
}: OpenButtonProps) => {
  return (
    <button
      className='OpenURLButton'
      data-testid='open-url-btn'
      onClick={() => {
        window.open(redirectURL, '_blank');
      }}
    >
      <span className='OpenURLButton-icon' />
      <span>{buttonText}</span>
    </button>
  );
};

export default OpenURLButton;
