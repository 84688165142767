import { styled } from '@mui/material/styles';

import {
  NonIdealState,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import iconBrokenSearch from 'src/assets/images/icon-broken-search.svg';

export default function IntegrationsEmptyState() {
  return (
    <Container>
      <NonIdealState
        icon={<img src={iconBrokenSearch} />}
        title='Oops, no results found'
      >
        <Typography color={theme.palette.colors.grey[500]} variant='font14'>
          No results match your search; try adjusting your search term. If you
          are looking for an app we don&apos;t support,{' '}
          <SupportLink href='mailto:support@forethought.ai'>
            contact our help team
          </SupportLink>
          .
        </Typography>
      </NonIdealState>
    </Container>
  );
}

const Container = styled('div')`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 24px;
`;

const SupportLink = styled('a')`
  text-decoration: none;
`;
