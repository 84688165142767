import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';

export const FormWrapper = styled('div')`
  padding-top: 32px;
`;

export const FormHeader = styled('div')`
  border-bottom: 1px solid ${theme.palette.grey[100]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

export const FormButtonGroup = styled('div')`
  align-items: center;
  display: flex;
  gap: 8px;
  padding-top: 16px;
`;
