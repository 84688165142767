import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { setIsOverlayVisible } from 'src/actions/workflow-builder/workflowBuilderActions';
//Redux
import { selectIsOverlayVisible } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
//Utils
import { unsavedChangesPrompt, unsavedChangesTitle } from 'src/utils/constants';

interface UnsavedChangesModalProps {
  isOpen?: boolean;
  onCancel: () => void;
  onClose?: () => void;
  onDiscard: () => void;
}

export const UnsavedChangesModal: FC<
  React.PropsWithChildren<UnsavedChangesModalProps>
> = ({ isOpen = false, onCancel, onClose, onDiscard }) => {
  const dispatch = useDispatch();

  const isOverlayVisible = useSelector(selectIsOverlayVisible);

  return (
    <Dialog
      footer={
        <>
          <Button onClick={onCancel} variant='ghost'>
            Cancel
          </Button>
          <Button onClick={onDiscard} variant='secondary'>
            Discard
          </Button>
        </>
      }
      hideCloseButton
      onClose={() => {
        if (onClose) {
          onClose();
          return;
        }

        dispatch(setIsOverlayVisible(false));
      }}
      open={onClose ? isOpen : isOverlayVisible}
      title={unsavedChangesTitle}
    >
      <Typography variant='font14'>{unsavedChangesPrompt}</Typography>
    </Dialog>
  );
};
