import React, { FC } from 'react';
import { Box } from '@mui/material';

import ButtonId from './ButtonId';
import DeploymentId from './DeploymentId';
import ExtraPreChatValues from './ExtraPreChatValues';
import StoreTranscriptDropdown from './StoreTranscriptDropdown';
import { SalesforceChatHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface AgentChatHandoffOnlyProps {
  customizationData: Pick<
    SalesforceChatHandoffCustomization,
    'agent_chat_handoff_settings'
  >;
}

const AgentChatHandoffOnly: FC<
  React.PropsWithChildren<AgentChatHandoffOnlyProps>
> = ({ customizationData }) => {
  return (
    <>
      <ButtonId />
      <Box mb={3} />
      <DeploymentId />
      <Box mb={3} />
      <StoreTranscriptDropdown customizationData={customizationData} />
      <Box mb={3} />
      <ExtraPreChatValues customizationData={customizationData} />
      <Box mb={3} />
    </>
  );
};

export default AgentChatHandoffOnly;
