import React from 'react';
import { useDrag } from 'react-dnd';
import { Box } from '@mui/system';

const IDLE_OPACITY = 1;
const ACTIVE_OPACITY = 0.5;

export interface DraggableFlowItemProps<T> {
  /**
   * Children components
   */
  children: React.ReactNode;
  /**
   * For some components, we need to pass extra fields from drag source to drop target
   */
  componentMetadata?: T;
  /**
   * Type of component
   */
  componentType: string;
}

export default function DraggableFlowItem<T>({
  children,
  componentMetadata,
  componentType,
}: DraggableFlowItemProps<T>) {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      collect: monitor => ({
        opacity: monitor.isDragging() ? ACTIVE_OPACITY : IDLE_OPACITY,
      }),
      item: {
        componentMetadata,
        componentType,
      },
      type: componentType,
    }),
    [],
  );

  return (
    <Box
      ref={dragRef}
      sx={theme => ({
        background: theme.palette.colors.white,
        border: `1px solid ${theme.palette.colors.slate[200]}`,
        borderRadius: '8px',
        cursor: 'grab',
        height: '100%',
        opacity,
        padding: '18px',
        // trick to keep border radius on drag: https://github.com/react-dnd/react-dnd/issues/788#issuecomment-367300464
        transform: 'translate(0, 0)',
      })}
    >
      {children}
    </Box>
  );
}
