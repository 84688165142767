import { JSONSchema7, JSONSchema7TypeName } from 'json-schema';

export enum InputMethod {
  AGENT_PROVIDED = 'agent_provided',
  HARDCODED = 'hardcoded',
}

export type ActionInputType =
  | JSONSchema7TypeName
  | JSONSchema7TypeName[]
  | 'array-integer'
  | 'array-number'
  | 'array-string';

export interface ActionInput extends JSONSchema7 {
  items: JSONSchema7;
}
