import './index.scss';

import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../Button';
import { IconYPosition } from '../Button/types';
import SearchBarComponent from '../marcos-search-bar/SearchBarComponent';
import { navBarMappings } from 'src/components/app/navbar/navBarRouteMappings';
import { selectTimeRange } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { selectCurrentTab } from 'src/reducers/userReducer/userReducer';
import { sendTrackingEvents } from 'src/utils/sendTrackingEvent';

export interface ViewNavigatorProps {
  /** Callback when user clicks button positioned before view options */
  backButtonCallbackFunction?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  /** Side of the icon for button positioned before view options */
  backButtonIconPosition?: IconYPosition;
  /** Style of the icon for button positioned before view options */
  backButtonIconStyle?: string;
  /** Style of the icon on button positioned before view options */
  backButtonStyle?: string;
  /** Text to be displayed on button positioned before view options */
  backButtonText?: string;
  /** Callback when user clicks on the button */
  buttonCallbackFunction?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  /** Side of the icon */
  buttonIconPosition?: IconYPosition;
  /** Style of the icon */
  buttonIconStyle?: string;
  /** Style for button */
  buttonStyle?: string;
  /** Text to be displayed on button */
  buttonText?: string;
  /** Value that indicates if view is for Solve Analytics Main Tab */
  isSolveView?: boolean;
  /** Value that indicates if view for navigator is a sub navigator */
  isSubNavigationView?: boolean;
  /** Function that is executed by Macros Search Bar Component */
  onChangeHandler?(search: string): void;
  /** Callback for second to last button */
  secondToLastBttnCallback?: Function;
  /** Style for second to last button */
  secondToLastBttnStyle?: string;
  /** Text for second to last button */
  secondToLastBttnText?: string;
  /** View that's currently displayed */
  selectedView: string;
  /** Function to set the current view */
  setView: Function;
  /** Show button before view options */
  shouldShowBackButton?: boolean;
  /** Should the button be visible */
  shouldShowButton?: boolean;
  /** Should the icon be visible */
  shouldShowIcon?: boolean;
  /** Should the search bar be visible */
  shouldShowSearchBar?: boolean;
  /** Should show second to last button */
  shouldShowSecondToLastBttn?: boolean;
  /** Styling for View Navigator */
  ViewNavigatorClass?: string;
  /** View options displayed on bar that users can see */
  viewOptions: string[];
}

const ViewNavigator: React.FC<React.PropsWithChildren<ViewNavigatorProps>> = ({
  backButtonCallbackFunction,
  backButtonIconPosition,
  backButtonIconStyle,
  backButtonStyle,
  backButtonText,
  buttonCallbackFunction,
  buttonIconPosition,
  buttonIconStyle,
  buttonStyle,
  buttonText,
  isSolveView,
  isSubNavigationView,
  onChangeHandler,
  secondToLastBttnCallback,
  secondToLastBttnStyle,
  secondToLastBttnText,
  selectedView,
  setView,
  shouldShowBackButton,
  shouldShowButton,
  shouldShowSearchBar,
  shouldShowSecondToLastBttn,
  ViewNavigatorClass,
  viewOptions,
}) => {
  const dispatch = useDispatch();
  // Selectors
  const timeRange = useSelector(selectTimeRange);
  const tab = useSelector(selectCurrentTab);
  const application = tab && navBarMappings[tab]?.permission;

  const options = viewOptions.map((viewOption: string, index: number) => {
    const isSelected: boolean = selectedView === viewOption;

    const isViewSelected = classNames({
      'ViewNavigator-solveNav-option--viewSelected': isSolveView && isSelected,
      'ViewNavigator-subNav-option--viewSelected':
        isSubNavigationView && isSelected,
    });

    const buttonStyle = classNames({
      'ViewNavigator-solveNav-option': isSolveView,
      'ViewNavigator-subNav-option': isSubNavigationView,
    });
    const eventType = 'view_mode_changed';

    return (
      <button
        className={`${buttonStyle + ' ' + isViewSelected}`}
        data-testid={isSelected ? 'selected-view-btn' : 'select-view-btn'}
        key={index}
        onClick={() => {
          setView(viewOption);
          sendTrackingEvents(
            dispatch,
            eventType,
            timeRange,
            application as string,
            {
              view_mode: viewOption,
            },
          );
        }}
      >
        {viewOption}
      </button>
    );
  });

  return (
    <div className={`ViewNavigator ViewNavigator${ViewNavigatorClass}`}>
      {shouldShowBackButton && (
        <Button
          buttonStyle={String(backButtonStyle)}
          buttonText={String(backButtonText)}
          callbackFunction={(
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          ) => backButtonCallbackFunction && backButtonCallbackFunction(e)}
          iconStyle={backButtonIconStyle}
          iconYPosition={backButtonIconPosition}
        />
      )}
      {options}
      {shouldShowSearchBar && (
        <SearchBarComponent
          onChangeHandler={search => onChangeHandler && onChangeHandler(search)}
        />
      )}
      {shouldShowSecondToLastBttn && (
        <Button
          buttonStyle={String(secondToLastBttnStyle)}
          buttonText={String(secondToLastBttnText)}
          callbackFunction={() =>
            secondToLastBttnCallback && secondToLastBttnCallback()
          }
        />
      )}
      {shouldShowButton && (
        <Button
          buttonStyle={String(buttonStyle)}
          buttonText={String(buttonText)}
          callbackFunction={(
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          ) => buttonCallbackFunction && buttonCallbackFunction(e)}
          iconStyle={buttonIconStyle}
          iconYPosition={buttonIconPosition}
        />
      )}
    </div>
  );
};

export default ViewNavigator;
