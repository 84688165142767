import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';

import {
  ListOption,
  SelectDropdown,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { getFieldTypesByHelpdeskAndStage } from '../helpers';
import { OutputFieldInputType, QuickstartFieldType } from '../types';
import TriageChoicePredictedFieldComponent from './TriageChoicePredictedFieldComponent';
import TriageConfigDetailInputContainer from './TriageConfigDetailInputContainer';
import { AvatarIcon } from './TriageConfigDetailSetting';
import TriageNewPredictedFieldInputComponent from './TriageNewPredictedFieldInputComponent';
import { Helpdesk } from 'src/components/app/types';
import { selectIsHelpdeskSupported } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import {
  HelpdeskDetail,
  TriagePermissions,
} from 'src/reducers/triageSettingsReducer/types';

interface TriageConfigDetailSettingEditProps {
  helpdeskDataSourceDropdownOptions: ListOption[];
  helpdeskDataSourceValue: Helpdesk | '';
  isTextPredictedFieldUnique: boolean;
  objectFieldValue: string;
  originFieldValue: string[];
  originsDropdownOptions: ListOption[];
  outputFieldInputType: OutputFieldInputType;
  outputFieldTextInputValue: string;
  permissions?: TriagePermissions;
  predictedFieldDropdownOptions: ListOption[];
  predictedFieldValue: string;
  selectedHelpdesk?: HelpdeskDetail;
  setHelpdeskDataSourceValue: (value: Helpdesk) => void;
  setObjectFieldValue: (value: string) => void;
  setOriginFieldValue: (value: string[]) => void;
  setOutputFieldInputType: (value: OutputFieldInputType) => void;
  setOutputFieldTextInputValue: (value: string) => void;
  setPredictedFieldValue: (value: string) => void;
}

const TriageConfigDetailSettingEdit = (
  props: TriageConfigDetailSettingEditProps,
) => {
  const {
    helpdeskDataSourceDropdownOptions,
    helpdeskDataSourceValue,
    isTextPredictedFieldUnique,
    objectFieldValue,
    originFieldValue,
    originsDropdownOptions,
    outputFieldInputType,
    outputFieldTextInputValue,
    permissions,
    predictedFieldDropdownOptions,
    predictedFieldValue,
    selectedHelpdesk,
    setHelpdeskDataSourceValue,
    setObjectFieldValue,
    setOriginFieldValue,
    setOutputFieldInputType,
    setOutputFieldTextInputValue,
    setPredictedFieldValue,
  } = props;
  const isIntegrationSupported = useSelector(selectIsHelpdeskSupported);
  // Currently have all permissions by default for Zendesk customers
  const hasNewFieldCreationPermission =
    permissions?.can_create_custom_field ||
    helpdeskDataSourceValue === 'zendesk';

  const renderOutputFieldComponent = () => {
    switch (outputFieldInputType) {
      case 'picking-field-choice':
        return (
          <TriageChoicePredictedFieldComponent
            helpdeskDataSourceValue={helpdeskDataSourceValue}
            permissions={permissions}
            setOutputFieldInputType={setOutputFieldInputType}
          />
        );
      case 'new-field':
        return (
          <TriageNewPredictedFieldInputComponent
            isTextPredictedFieldUnique={isTextPredictedFieldUnique}
            outputFieldTextInputValue={outputFieldTextInputValue}
            setOutputFieldInputType={setOutputFieldInputType}
            setOutputFieldTextInputValue={setOutputFieldTextInputValue}
          />
        );
      case 'existing-field':
        if (helpdeskDataSourceValue && isIntegrationSupported) {
          return (
            <SelectDropdown
              aria-label='Output Dropdown'
              id='output-field-dropdown'
              isMenuSearchable
              menuMaxHeight={200}
              onChange={({ target }) => setPredictedFieldValue(target.value)}
              options={predictedFieldDropdownOptions}
              placeholder='Select an existing field'
              value={predictedFieldValue}
            />
          );
        }
      default:
        return (
          <TriageChoicePredictedFieldComponent
            helpdeskDataSourceValue={helpdeskDataSourceValue}
            permissions={permissions}
            setOutputFieldInputType={setOutputFieldInputType}
          />
        );
    }
  };

  const fieldTypeFieldMap: Record<QuickstartFieldType, React.ReactNode> = {
    Object: (
      <TriageConfigDetailInputContainer
        key='Object'
        label='Object'
        mb={3}
        view='edit'
      >
        <TooltipContainer>
          <Tooltip tooltipContent='You must output to a field on “Case” object'>
            <SelectDropdown
              aria-label='Object dropdown'
              disabled
              id='object-dropdown'
              onChange={({ target }) => {
                setObjectFieldValue(target.value);
              }}
              options={[{ label: 'Case', value: 'Case' }]}
              placeholder='Select object'
              value={objectFieldValue}
            />
          </Tooltip>
        </TooltipContainer>
      </TriageConfigDetailInputContainer>
    ),
    Origins: (
      <TriageConfigDetailInputContainer
        key='Origins'
        label='Origins'
        mb={3}
        view='edit'
      >
        <SelectDropdown
          aria-label='Origin multiple dropdown'
          chipsToShow={4}
          error={!originFieldValue.length && 'Select at least one origin'}
          id='origin-dropdown'
          isMenuSearchable
          multiple
          onChange={({ target }) => {
            const { value } = target;
            if (typeof value === 'string') {
              return;
            }

            setOriginFieldValue(value);
          }}
          onClear={() => setOriginFieldValue([])}
          onClickOnlyButton={value => setOriginFieldValue(value)}
          onDeleteChip={(_, value) =>
            setOriginFieldValue(
              originFieldValue.filter(fieldValue => fieldValue !== value),
            )
          }
          options={originsDropdownOptions}
          placeholder='Select origins'
          value={originFieldValue}
        />
      </TriageConfigDetailInputContainer>
    ),
    'Output field': (
      <TriageConfigDetailInputContainer
        key='Output field'
        label='Output field'
        onClickCreateNewFieldLink={() => setOutputFieldInputType('new-field')}
        shouldShowCreateNewFieldLink={
          outputFieldInputType === 'existing-field' &&
          hasNewFieldCreationPermission
        }
        view='edit'
      >
        {renderOutputFieldComponent()}
      </TriageConfigDetailInputContainer>
    ),
  };

  return (
    <>
      <TriageConfigDetailInputContainer
        label='Integration'
        mb={3}
        mt={2}
        view='edit'
      >
        <SelectDropdown
          aria-label='Helpdesk Dropdown'
          id='integration-dropdown'
          onChange={({ target }) =>
            setHelpdeskDataSourceValue(target.value as Helpdesk)
          }
          options={helpdeskDataSourceDropdownOptions}
          startAdornment={
            selectedHelpdesk?.avatar && (
              <AvatarIcon src={selectedHelpdesk.avatar} />
            )
          }
          value={helpdeskDataSourceValue}
        />
      </TriageConfigDetailInputContainer>
      {getFieldTypesByHelpdeskAndStage(helpdeskDataSourceValue).map(
        fieldType => fieldTypeFieldMap[fieldType],
      )}
    </>
  );
};

const TooltipContainer = styled('div')`
  & > div {
    display: block;
    width: 100%;
  }
`;

export default TriageConfigDetailSettingEdit;
