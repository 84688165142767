import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { EmailWorkflowStep } from 'src/pages/workflow-builder-edit/types';
import { selectEmailWorkflowState } from 'src/slices/email-workflow/emailWorkflowSlice';

export const useGetIsParentRuleStep = (stepId: string) => {
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);

  return useMemo(() => {
    const stepMap = emailWorkflow?.step_map || {};
    const stepMapValues = Object.values(stepMap);
    let parentStep: EmailWorkflowStep | null = null;

    for (const step of stepMapValues) {
      if (parentStep) {
        break;
      }

      const transitions = step.transitions;

      for (const transition of transitions) {
        if (transition.step_id === stepId) {
          parentStep = step;
          break;
        }
      }
    }
    const parentStepId = parentStep?.step_id || '';
    return stepMap[parentStepId]?.step_type === 'rule';
  }, [emailWorkflow, stepId]);
};
