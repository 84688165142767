import React from 'react';
import { ReactSVG } from 'react-svg';
import { Box, Menu, useTheme } from '@mui/material';

import {
  IconButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { StyledMenuItem } from './VersionCard';
import moreIcon from 'src/pages/workflow-builder-edit/assets/icons/more-icon.svg';

interface VersionCardProps {
  handleClickMenu: (event: React.MouseEvent<HTMLElement>) => void;
  handleCloseMenu: () => void;
  menuAnchorEl: HTMLElement | null;
  setIsEditDialogOpen: (value: boolean) => void;
}

const VersionActionMenu = ({
  handleClickMenu,
  handleCloseMenu,
  menuAnchorEl,
  setIsEditDialogOpen,
}: VersionCardProps) => {
  const { palette } = useTheme();

  const open = Boolean(menuAnchorEl);

  return (
    <Box>
      <IconButton
        aria-label='Version history dropdown menu'
        onClick={handleClickMenu}
        variant='ghost'
      >
        <ReactSVG src={moreIcon} />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={handleCloseMenu}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            border: `1px solid ${palette.colors.grey[200]}`,
            borderRadius: '4px',
            boxShadow:
              '0px 0px 1px rgba(0, 12, 32, 0.04), 2px 5px 8px rgba(3, 17, 38, 0.107135)',
            padding: '4px 0',
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <StyledMenuItem
          aria-label='Name option'
          disableRipple
          onClick={() => setIsEditDialogOpen(true)}
        >
          <Typography variant='font14'>Name this version</Typography>
        </StyledMenuItem>
      </Menu>
    </Box>
  );
};

export default VersionActionMenu;
