import { type ContextVariablesRef, type MentionComponentProps } from '../types';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';

export function replaceContextVariableIdsWithMarkup(
  html = '',
  contextVariables: MentionComponentProps['contextVariables'],
): string {
  if (true) {
    const undefinedContextVariableIds = contextVariables
      .filter(contextVariable => !contextVariable.isDefined)
      .map(contextVariable => contextVariable.id);
    return contextVariableAliasHtml({
      contextVariables: contextVariables.map(contextVariable => ({
        context_variable_id: contextVariable.id,
        context_variable_name: contextVariable.label,
      })),
      html,
      replaceValueFn({ id, isDefined, name }) {
        return (
          '<span' +
          ' class="remirror-mention-atom remirror-mention-atom-context-variable"' +
          ` data-mention-atom-id="${id}"` +
          ' data-mention-atom-name="context-variable"' +
          ` data-is-defined="${isDefined}"` +
          `>$${name}</span>`
        );
      },
      undefinedContextVariableIds,
    });
  }
}

export function replaceContextVariableIdsWithMarkupRef(
  html = '',
  contextVariablesRef: ContextVariablesRef,
): string {
  return replaceContextVariableIdsWithMarkup(
    html,
    contextVariablesRef.current || [],
  );
}

export function replaceNewlinesWithBR(html = ''): string {
  return html.replaceAll('\n', '<br />');
}
