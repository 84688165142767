import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import { useGetTriageModelQuery } from 'src/services/triage/triageApi';

interface TriageSampleValuesDropdownProps {
  componentId: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  model: string;
  onChange: (value: string) => void;
  optional?: boolean;
  value: string;
}

const TriageSampleValuesDropdown = ({
  componentId,
  disabled,
  errorMessage,
  label,
  model,
  onChange,
  optional,
  value,
}: TriageSampleValuesDropdownProps) => {
  const { data: triageModelData } = useGetTriageModelQuery(model, {
    skip: !model,
  });

  const { sample_values = [] } = triageModelData || {};

  const formattedSampleValues = sample_values.map(sampleValue => ({
    label: sampleValue.toString(),
    value: sampleValue.toString(),
  }));

  return (
    <SelectDropdown
      disabled={disabled}
      error={errorMessage}
      id={componentId}
      label={label}
      onChange={e => {
        const value = e?.target.value;
        onChange(value as string);
      }}
      optional={optional}
      options={formattedSampleValues}
      placeholder={disabled ? '' : 'Select a value'}
      value={value.toString()}
    />
  );
};

export default TriageSampleValuesDropdown;
