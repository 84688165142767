import { ConversationQueryArgsType } from './types';

export const handleSerializeConversationQueryArgs = (
  endpointName: string,
  queryArgs: ConversationQueryArgsType,
) => {
  const {
    autonomous_agent_used,
    dropoff_ratings,
    end,
    intent_ids,
    intentId,
    relevance_ratings,
    sort_column,
    sort_direction,
    start,
  } = queryArgs;
  const qParams = new URLSearchParams({
    end,
    sort_column,
    sort_direction,
    start,
  });
  if (dropoff_ratings) {
    qParams.set('intentId', dropoff_ratings);
  }
  if (intent_ids) {
    qParams.set('intentId', intent_ids);
  }
  if (intentId) {
    qParams.set('intentId', intentId);
  }
  if (relevance_ratings) {
    qParams.set('intentId', relevance_ratings);
  }
  if (autonomous_agent_used === true) {
    qParams.set('autonomous_agent_used', autonomous_agent_used.toString());
  }
  return endpointName + '&' + qParams;
};
