import { NodeProps } from 'reactflow';

import { Typography } from '@forethought-technologies/forethought-elements';
import IntentEmailNode from '../IntentEmailNode';

interface IntentNodeData {
  intentTitle: string;
}

export default function IntentNode(props: NodeProps<IntentNodeData>) {
  return (
    <IntentEmailNode {...props} nodeType='intent' title='Customer intent'>
      <Typography variant='font14Bold'>{props.data.intentTitle}</Typography>
    </IntentEmailNode>
  );
}
