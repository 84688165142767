import { sortMacrosTableData } from 'src/reducers/dataDeprecatedReducer/helper';
import { MacroDataDict } from 'src/services/apiInterfaces';

export const sortMacros = (
  isTrainedMacrosView: boolean,
  macros: Array<MacroDataDict>,
) => {
  const fieldToSort = isTrainedMacrosView ? 'resolutions' : 'usage';
  const sortedMacros = macros.filter((macro: MacroDataDict) => {
    if (isTrainedMacrosView) {
      return macro.is_trained;
    } else {
      return !macro.is_trained;
    }
  });

  return sortMacrosTableData(
    sortedMacros,
    fieldToSort,
    false,
    isTrainedMacrosView,
  );
};
