import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import InvitesTab from './tabs/InvitesTab';
import TeamMembersTab from './tabs/TeamMembersTab';
import { a11yTabPanelProps, a11yTabProps } from './utils';
import SettingsPageLayout from 'src/components/settings-page-layout';
import { getUsers } from 'src/slices/team/thunks';
import { useAppDispatch } from 'src/store/hooks';

const tabs = [
  { Component: TeamMembersTab, name: 'Team members' },
  { Component: InvitesTab, name: 'Invites' },
] as const;

export default function TeamSettingsPage() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const tabIndexParam = new URLSearchParams(search).get('tab');
  const tabIndex = tabIndexParam ? parseInt(tabIndexParam, 10) : 0;

  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <>
      <SettingsPageLayout withPadding={false}>
        <Tabs
          a11yProps={a11yTabProps}
          aria-label='team settings tabs'
          ContainerProps={{
            borderBottom: '1px solid' + palette.colors.slate[200],
            px: '40px',
          }}
          onChange={(_, index) => navigate({ search: `tab=${index}` })}
          tabs={tabs.map(({ name }) => name)}
          typographyVariant='font16Bold'
          value={tabIndex}
        />
        {tabs.map(({ Component, name }, index) => (
          <div
            hidden={tabIndex !== index}
            key={name}
            role='tabpanel'
            {...a11yTabPanelProps(index)}
          >
            {tabIndex === index && <Component />}
          </div>
        ))}
      </SettingsPageLayout>
    </>
  );
}
