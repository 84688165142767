import { useEffect, useState } from 'react';

export const useGetHighlightedText = () => {
  const [highlightedText, setHighlightedText] = useState('');

  useEffect(() => {
    const handler = () => {
      const highlightedText = document.getSelection()?.toString();
      setHighlightedText(highlightedText || '');
    };

    document.addEventListener('selectionchange', handler);

    return () => {
      document.removeEventListener('selectionchange', handler);
    };
  }, []);
  return { highlightedText };
};
