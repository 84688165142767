import { useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/system';

import {
  NonIdealState,
  SearchBar,
} from '@forethought-technologies/forethought-elements';
import IntentEmailDynamicArticleComponent from '../IntentEmailDynamicArticleCard';
import DocumentList from './DocumentList';
import { useDocumentSearch } from './hooks';
import iconBrokenSearch from 'src/assets/images/icon-broken-search.svg';
import Spinner from 'src/components/spinner';
import * as API from 'src/services/workflow-builder/workflowBuilderApi';
import {
  selectAvailableArticleSources,
  selectIntentEmailConfiguration,
} from 'src/slices/email-builder/emailBuilderSlice';

export default function IntentEmailArticlesTab() {
  const articleSources = useSelector(selectAvailableArticleSources);
  const emailConfiguration = useSelector(selectIntentEmailConfiguration);

  const [searchTerm, setSearchTerm] = useState('');
  const {
    data: articleData,
    error,
    isLoading,
    isLoadingNextPage,
    loadNextPage,
    refetch,
  } = useDocumentSearch(API.getArticlesAPI, (prevData, newData) => {
    return {
      ...newData,
      articles: [...prevData.articles, ...newData.articles],
    };
  });

  const dynamicArticleExistsInConfiguration =
    emailConfiguration?.components.some(
      component => component.component_type === 'dynamic_article',
    );

  const shouldDisplayDynamicArticleCard =
    !dynamicArticleExistsInConfiguration &&
    !searchTerm &&
    articleSources.length > 0;

  return (
    <Container>
      <Box marginBottom='8px'>
        <SearchBar
          onChange={e => {
            const { value } = e.target;
            setSearchTerm(value);
            refetch(value);
          }}
          onClear={() => {
            setSearchTerm('');
            refetch();
          }}
          placeholder='Search articles'
          value={searchTerm}
        />
      </Box>
      <Container>
        {shouldDisplayDynamicArticleCard && (
          <Box data-testid='dynamic-article-card' marginTop='8px'>
            <IntentEmailDynamicArticleComponent />
          </Box>
        )}
        {!isLoading && !!articleData?.articles.length && (
          <DocumentList
            componentType='static_article'
            disabled={!!error}
            documents={articleData.articles}
            hasNextPage={!articleData.is_last_page}
            loading={isLoadingNextPage}
            loadMoreItems={() => {
              loadNextPage(searchTerm, articleData.page + 1);
            }}
          />
        )}
        <Container justifyContent='center'>
          {isLoading && <Spinner />}
          {!isLoading && articleData?.articles.length === 0 && (
            <NonIdealState
              icon={<img alt='' src={iconBrokenSearch} />}
              title='No results found'
            />
          )}
        </Container>
      </Container>
    </Container>
  );
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  margin-top: 8px;
  width: 100%;
`;
