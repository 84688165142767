import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import InsightTooltip from '../common/InsightTooltip';
import { TABLE_COLUMN_TO_TOOLTIP_MAP } from './constants';
import { InsightTopicDataColumnKeys } from './types';
import { TrackingEventType } from 'src/components/app/types';
import { TrackingEventAdditionalParams } from 'src/hooks/hooks';

export type ColumnHeaderItemProps = {
  accessorKey: InsightTopicDataColumnKeys;
  costPerTicket: string;
  emitTrackingEventCallback: (
    eventType: TrackingEventType,
    additionalParams?: TrackingEventAdditionalParams,
  ) => void;
  header: string;
};

const ColumnHeaderItem = ({
  accessorKey,
  costPerTicket,
  emitTrackingEventCallback,
  header,
}: ColumnHeaderItemProps) => {
  const tooltipMessage = TABLE_COLUMN_TO_TOOLTIP_MAP.topic[accessorKey];
  const isRealizedSaving = accessorKey === 'realized_saving';
  const tooltipForSavings = isRealizedSaving
    ? `Dollarized savings assuming a $${costPerTicket} cost per deflection.`
    : null;

  return (
    <Box alignItems='center' columnGap={1} display='flex'>
      <Typography variant='font14Bold'>{header}</Typography>
      {tooltipMessage && (
        <Box
          display='flex'
          onMouseEnter={() => {
            emitTrackingEventCallback('insight-tooltip-hover', {
              scope: 'main',
              tab: 'topic',
              table: 'topic',
              value: accessorKey,
            });
          }}
        >
          <InsightTooltip tooltip={tooltipForSavings || tooltipMessage} />
        </Box>
      )}
    </Box>
  );
};

export default ColumnHeaderItem;
