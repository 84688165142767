import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import { IconBolt, IconTag } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useSolveMetricsQueryParams } from '../hooks/useSolveMetricsQueryParams';
import DiscoverRealizedImpactTopicMenu from 'src/components/dashboard-pages/discover-automation-page/discover-realized-impact-tab/DiscoverRealizedImpactTopicMenu';
import { generateDiscoverTopicDetailUrl } from 'src/components/dashboard-pages/discover-automation-page/helpers';
import Menu from 'src/components/menu';
import PillButton from 'src/components/pill-button/PillButton';
import {
  getWorkflowBuilderUrl,
  openTab,
} from 'src/pages/intent-conversation-analytics/helpers';
import IntentBadge from 'src/pages/intent-conversation-analytics/IntentBadge';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { useGetIntentsQuery } from 'src/services/workflow-builder-metrics';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import { isIntentActiveForChannel } from 'src/utils/solve/intentUtils';

interface IntentTitleTagProps {
  Menu: typeof DiscoverRealizedImpactTopicMenu;
  MenuProps: {
    goToIntents?: string[];
    hoverBackgroundColor?: string;
    menuItems?: React.ReactNode[];
    title: string;
    topics?: DiscoverTopic[];
    workflowTags?: string[];
  };
  propIcon?: React.ReactNode;
  variant: 'topic' | 'workflowTag' | 'goToIntent' | 'usage' | 'action';
}

export const IntentTitleTag = ({
  children,
  Menu,
  MenuProps,
  propIcon,
  variant,
}: React.PropsWithChildren<IntentTitleTagProps>) => {
  const { channel } = useSolveMetricsQueryParams();

  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const icon =
    variant === 'topic' ? (
      <IconBolt
        color={palette.colors.green[500]}
        fill={palette.colors.green[500]}
        size={16}
        strokeWidth={1}
      />
    ) : (
      <IconTag color={palette.colors.blue[500]} size={16} />
    );

  const arrayLengthByVariant = MenuProps.menuItems?.length ?? 0;
  const shouldShowMenu = arrayLengthByVariant > 1;
  const shouldBeTopicLink = variant === 'topic' && !shouldShowMenu;

  const onClick: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    if (!shouldShowMenu) {
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  return variant === 'goToIntent' ? (
    <>
      <IntentBadge
        channel={channel}
        intentNameSuffix={shouldShowMenu ? ` +${arrayLengthByVariant - 1}` : ''}
        intentWorkflowId={MenuProps.goToIntents?.[0]}
        onClick={shouldShowMenu ? onClick : undefined}
      />
      {isOpen && (
        <Menu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          {...MenuProps}
        />
      )}
    </>
  ) : (
    <>
      <PillButton
        isHighlighted={isOpen}
        maxWidth='250px'
        onClick={onClick}
        variant={variant}
      >
        <Box alignItems='center' display='flex' justifyContent='center'>
          <Box display='flex' mr={0.5}>
            {propIcon ?? icon}
          </Box>
          <Box
            component={shouldBeTopicLink ? Link : 'div'}
            mr={0.5}
            sx={{ color: 'inherit', textDecoration: 'none' }}
            to={
              (MenuProps.topics ?? [])[0] &&
              generateDiscoverTopicDetailUrl(
                (MenuProps.topics ?? [])[0] as DiscoverTopic,
              )
            }
          >
            {children}
          </Box>
          {shouldShowMenu && (
            <Box>
              <Typography noWrap variant='font12Medium'>
                +{arrayLengthByVariant - 1}
              </Typography>
            </Box>
          )}
        </Box>
      </PillButton>
      {isOpen && (
        <Menu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          {...MenuProps}
        />
      )}
    </>
  );
};

interface DropdownMenuProps {
  anchorEl: null | HTMLElement;
  hoverBackgroundColor?: string;
  menuItems?: React.ReactNode[];
  onClickMenuItem?: (value: string) => void;
  onClose: () => void;
  title: string;
}

export const DropdownMenu = ({
  anchorEl,
  hoverBackgroundColor = 'transparent',
  menuItems = [],
  onClickMenuItem,
  onClose,
  title,
}: DropdownMenuProps) => {
  return (
    <Menu
      anchorEl={anchorEl}
      MenuListProps={{
        role: 'listbox',
        sx: {
          py: 1.5,
        },
      }}
      onClick={e => e.stopPropagation()}
      onClose={e => {
        //Casting is needed as MUI has {} as a type for events
        const event = e as MouseEvent;
        event.stopPropagation();
        onClose();
      }}
      open={Boolean(anchorEl)}
      title={title}
      width='350px'
    >
      {menuItems.map((node, index) => (
        <MenuItem
          key={index}
          onClick={() => onClickMenuItem?.('x')}
          sx={{
            '&:hover': {
              backgroundColor: hoverBackgroundColor,
            },
            alignItems: 'center',
            backgroundColor: 'transparent',
            cursor: 'default',
            display: 'flex',
            padding: '4px 16px',
          }}
        >
          <Box alignItems='center' display='flex' flex={1} overflow='hidden'>
            {node}
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
};

interface IntentWorkflowMenuItemProps {
  channel: ConversationChannel;
  intentWorkflowId: string;
}

export const IntentWorkflowMenuItem = ({
  channel,
  intentWorkflowId,
}: IntentWorkflowMenuItemProps) => {
  const { palette } = useTheme();

  const { data: intentsResponse } = useGetIntentsQuery();
  const foundIntentWorkflow = useMemo(() => {
    return intentsResponse?.intents.find(
      intent =>
        intent.intent_workflow_id === intentWorkflowId ||
        intent.api_workflow_id === intentWorkflowId,
    );
  }, [intentWorkflowId, intentsResponse?.intents]);

  if (!foundIntentWorkflow) {
    return null;
  }

  const {
    api_workflow_id,
    intent_definition_id,
    intent_name: name,
    intent_workflow_id,
  } = foundIntentWorkflow;

  const isActive = isIntentActiveForChannel(
    foundIntentWorkflow.active_workflow_types,
    channel,
  );

  return (
    <Box
      onClick={() => {
        const url = getWorkflowBuilderUrl({
          channel,
          intentDefinitionId: intent_definition_id,
          intentWorkflowId:
            channel === 'api'
              ? api_workflow_id || intent_workflow_id
              : intent_workflow_id,
        });
        openTab(url);
      }}
      sx={{
        '& > span:last-child': { display: 'none' },
        '&:hover > span:last-child': { display: 'inline' },
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
      }}
    >
      <Box alignItems='center' display='flex' flex={1} overflow='hidden'>
        <Box alignItems='center' display='flex' mr={1}>
          <StatusIcon
            color={
              isActive ? palette.colors.green[500] : palette.colors.grey[400]
            }
          />
        </Box>
        <Typography color={palette.colors.black} noWrap variant='font14'>
          {name}
        </Typography>
      </Box>
      <Typography color={palette.primary.main} variant='font11'>
        View workflow
      </Typography>
    </Box>
  );
};

export const StatusIcon = styled('div')<{ color: string }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;
