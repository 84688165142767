import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SortingButtons from '../../dashboard-pages/common/sortingButtons';
import DropDownMenu from '../dropdown-menu/dropdownMenu';
import Tooltip from '../tooltip/tooltip';
import { setFilterType } from 'src/actions/solveData';
import {
  FilterDict,
  filterDict,
  workflowsFilterDict,
} from 'src/pages/solve-analytics/filterDict';
import {
  selectFilterType,
  selectIsSolveWorkflows,
} from 'src/reducers/solveReducer';
import { removeUnderscores } from 'src/utils/cleanStr';
import { getData } from 'src/utils/getDataUtil';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';

interface TableHeaderProps {
  divClass: string;
  eventTracker?: Function;
  heading: string;
  headingClass: string;
  onClick: Function;
  productSorted: string;
  propertyName: string;
  /** Should table header hide sorting buttons */
  shouldHideSortButtons?: boolean;
  showFilterDropdown?: boolean;
  tooltipClass?: string;
  tooltipText?: string;
  tooltipTitle?: string;
}

const TableHeader: React.FC<React.PropsWithChildren<TableHeaderProps>> = (
  props: TableHeaderProps,
) => {
  const dispatch = useDispatch();
  const {
    divClass,
    eventTracker,
    heading,
    headingClass,
    onClick,
    productSorted,
    propertyName,
    shouldHideSortButtons,
    showFilterDropdown,
    tooltipText,
    tooltipTitle,
  } = props;

  let isComponentMounted = true;
  const isSolveWorkflows = useSelector(selectIsSolveWorkflows);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [dropdownOptions, setDropdownOptions] = useState<Array<string>>([]);
  const [filterOptions, setFilterOptions] = useState<Array<FilterDict>>([]);
  const tableHeaderRef: React.RefObject<HTMLTableHeaderCellElement> =
    useRef(null);
  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const filterType: { [key: string]: string } = useSelector(selectFilterType);

  // Sets filter options based on current Solve view.
  useEffect(() => {
    if (isSolveWorkflows) {
      setFilterOptions(workflowsFilterDict);
    } else {
      setFilterOptions(filterDict);
    }
  }, []);

  //Sets values to be displayed on dropdown.
  useEffect(() => {
    const dropdown: string[] = [];
    filterOptions && filterOptions.map(({ name }) => dropdown.push(name));
    setDropdownOptions(dropdown);
  }, [filterOptions]);

  const setFilter = (_e: Event, option: string) => {
    let key = '';

    filterOptions.map(val => {
      if (val.name === option) {
        return (key = val.key);
      }
    });

    setIsDropdownVisible(false);
    dispatch(
      setFilterType({
        key: key,
        name: option,
      }),
    );
    // We call the backend to return filtered results for workflows
    if (isSolveWorkflows) {
      getData();
    }

    eventTracker && eventTracker(option);
  };

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        tableHeaderRef,
        ContainerRef,
        setIsDropdownVisible,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);

  return (
    <th className={headingClass} ref={tableHeaderRef}>
      <div className={divClass}>
        <Tooltip tooltipText={tooltipText} tooltipTitle={tooltipTitle} />
        <div
          className='TableHeader-name'
          onClick={(): void => {
            showFilterDropdown && setIsDropdownVisible(!isDropdownVisible);
          }}
        >
          {removeUnderscores(heading)}

          {showFilterDropdown && (
            <div
              className={
                isDropdownVisible ? 'desc-selected -arrow' : 'down-arrow'
              }
            />
          )}

          {!showFilterDropdown && !shouldHideSortButtons && (
            <SortingButtons
              onClick={onClick}
              productSorted={productSorted}
              propertyName={propertyName}
            />
          )}
        </div>
        {showFilterDropdown && isDropdownVisible && (
          <DropDownMenu
            buttonClass={'dropdown-option'}
            data={dropdownOptions}
            dropdownClass={'dropdown-menu FilterDropdownContainer-menu'}
            dropdownContClass={'FilterDropdownContainer'}
            onClick={setFilter}
            reference={ContainerRef}
            selectedOption={filterType.name}
          />
        )}
      </div>
    </th>
  );
};

export default TableHeader;
