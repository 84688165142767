import { styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

interface DividerMenuProps {
  onChange: (value: string) => void;
  options: Array<{ label: string; value: string }>;
  selectedValue: string;
}

const DividerMenu = ({
  onChange,
  options,
  selectedValue,
}: DividerMenuProps) => {
  return (
    <Container>
      {options.map(option =>
        option.value === selectedValue ? (
          <SelectedItem key={option.value}>
            <Typography variant='font14Bold'>{option.label}</Typography>
          </SelectedItem>
        ) : (
          <Item key={option.value} onClick={() => onChange(option.value)}>
            <Typography variant='font14Bold'>{option.label}</Typography>
          </Item>
        ),
      )}
    </Container>
  );
};

export default DividerMenu;

const Container = styled('div')`
  background: ${props => props.theme.palette.colors.slate[100]};
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  padding: 2px;
  height: 32px;
`;

const Item = styled('div')`
  background: transparent;
  color: ${props => props.theme.palette.colors.grey[600]};
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
`;

const SelectedItem = styled('div')`
  background: ${props => props.theme.palette.colors.white};
  color: ${props => props.theme.palette.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: ${props => props.theme.shadows[1]};
`;
