import { useCallback, useMemo, useState } from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import { useNavigate } from 'react-router';
import { ValidationError } from 'yup';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';

import {
  DateRangeFilterButton,
  InfiniteTable,
  MultiSelectFilter,
  Order,
  SearchWithDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateCell } from '../audit/DateCell';
import { useSetDefaultTimeRangeQueryParams } from '../audit/hooks';
import ErrorDrawer from './ErrorDrawer';
import {
  useGetErrorEventsHook,
  useGetErrorFilterOptions,
  useGetErrorPageQueryParams,
  useGetErrorTableSearchQuery,
} from './hooks';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';
import WithHelperText from 'src/components/with-helper-text/WithHelperText';
import { useStateParams } from 'src/hooks/hooks';
import { getTimeRangeFromQueryParams } from 'src/pages/workflow-builder/intent-workflows-table/utils';
import {
  ErrorEventSortColumn,
  ErrorEventSortDirection,
  ErrorQueryEvent,
} from 'src/types/ErrorEventTypes';
import { DateRange } from 'src/types/types';
import { genericSerializeAndDeserialize } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';
import {
  constructDefaultTimeRangeQueryParams,
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';
import { uuidOrEmptySchema } from 'src/utils/validators';

const ErrorPage = () => {
  //state
  const [page, setPage] = useState(1);
  const [selectedErrorId, setSelectedErrorId] = useState('');
  const [sortDirection, setSortDirection] =
    useStateParams<ErrorEventSortDirection>({
      deserialize: (param: string) => param as ErrorEventSortDirection,
      initialState: 'desc',
      paramsName: 'sort_direction',
      serialize: genericSerializeAndDeserialize,
    });
  const [sortColumn, setSortColumn] = useStateParams<ErrorEventSortColumn>({
    deserialize: (param: string) => param as ErrorEventSortColumn,
    initialState: 'timestamp',
    paramsName: 'sort_column',
    serialize: genericSerializeAndDeserialize,
  });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: genericSerializeAndDeserialize,
    initialState: '',
    paramsName: 'chats_search',
    serialize: genericSerializeAndDeserialize,
  });
  type SearchQueryMode = 'conversation_id' | 'error_payload';
  const [searchQueryMode, setSearchQueryMode] = useStateParams<SearchQueryMode>(
    {
      deserialize: genericSerializeAndDeserialize as (
        str: string,
      ) => SearchQueryMode,
      initialState: 'conversation_id',
      paramsName: 'chats_search_mode',
      serialize: genericSerializeAndDeserialize,
    },
  );
  const [multiSelected, setMultiSelected] = useState<string[]>([]);

  const { setUiQuery, uiQuery } = useGetErrorTableSearchQuery({
    searchQuery,
    setSearchQuery,
  });

  const uiQueryError = (() => {
    if (searchQueryMode !== 'conversation_id') {
      return '';
    }

    try {
      uuidOrEmptySchema.validateSync(uiQuery);

      return '';
    } catch (err) {
      return err instanceof ValidationError
        ? err.message
        : 'An unexpected error occurred';
    }
  })();

  //hooks
  const navigate = useNavigate();
  const theme = useTheme();
  useSetDefaultTimeRangeQueryParams();
  const { endDate, startDate } = useGetErrorPageQueryParams();

  const selectedDateRange = useMemo(() => {
    return getTimeRangeFromQueryParams({
      from: startDate,
      to: endDate,
    });
  }, [startDate, endDate]);

  const searchOptions = useMemo(
    () =>
      [
        { text: 'Conversation ID', value: 'conversation_id' },
        { text: 'Error payload', value: 'error_payload' },
      ] as const,
    [],
  );

  const filterOptions = useGetErrorFilterOptions({
    endDate,
    startDate,
  });

  const filtersForBe = useMemo(() => {
    const channels: string[] = [];
    const errorMessages: string[] = [];
    multiSelected.forEach(item => {
      if (filterOptions[0].options.some(option => option.value === item)) {
        channels.push(item);
      } else if (
        filterOptions[1].options.some(option => option.value === item)
      ) {
        errorMessages.push(item);
      }
    });
    return {
      channel: channels.join(','),
      errorMessage: errorMessages.join(','),
    };
  }, [multiSelected, filterOptions]);

  const {
    data: errorEventsResponse,
    isError: isErrorEventsError,
    isFetching: isErrorEventsFetching,
    isLoading: isErrorEventsLoading,
  } = useGetErrorEventsHook({
    conversationId:
      !uiQueryError && searchQueryMode === 'conversation_id' ? searchQuery : '',
    end: endDate,
    errorPayload: searchQueryMode === 'error_payload' ? searchQuery : '',
    page,
    pageSize: '100',
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    start: startDate,
    ...filtersForBe,
  });

  const selectedError = useMemo(() => {
    if (!selectedErrorId) {
      return;
    }
    return (errorEventsResponse?.error_events || []).find(
      event => event.error_id === selectedErrorId,
    );
  }, [errorEventsResponse?.error_events, selectedErrorId]);

  const showLoadingSkeleton =
    isErrorEventsLoading || (page === 1 && isErrorEventsFetching);

  const columnHelper = createMRTColumnHelper<ErrorQueryEvent>();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('timestamp', {
        Cell: p => {
          const formattedDate = p.cell.getValue().replace(' UTC', '');
          return <DateCell dateString={formattedDate + 'Z'} />;
        },
        grow: false,
        header: 'Timestamp',
        size: 230,
      }),
      columnHelper.accessor('channel', {
        enableSorting: false,
        grow: false,
        header: 'Channel',
        size: 160,
      }),
      columnHelper.accessor('conversation_id', {
        enableSorting: false,
        grow: false,
        header: 'Conversation Id',
        size: 380,
      }),
      columnHelper.accessor('error_message', {
        enableSorting: false,
        grow: true,
        header: 'Error Message',
        minSize: 360,
      }),
    ];
  }, [columnHelper]);

  //handlers
  const handleLoadMore = useCallback(
    () => setPage(prevPage => prevPage + 1),
    [],
  );

  const handleSortCallback = useCallback(
    (property: keyof ErrorQueryEvent, order: Order) => {
      setPage(1);
      setSortColumn(property as ErrorEventSortColumn, () =>
        setSortDirection(order),
      );
    },
    [setSortColumn, setSortDirection],
  );

  const handleDateRangeOnChange = (dateRange: DateRange) => {
    // Reset page number
    setPage(1);
    // Update date range
    const existingQueryParams = new URLSearchParams(location.search);
    const newSearchParams = constructDefaultTimeRangeQueryParams(dateRange);

    const mergedSearchParams = new URLSearchParams({
      ...Object.fromEntries(existingQueryParams),
      ...Object.fromEntries(newSearchParams),
    });

    navigate(
      {
        pathname: Routes.LOG,
        search: mergedSearchParams.toString(),
      },
      { replace: true },
    );
  };

  return (
    <>
      <Box
        sx={{
          background: 'white',
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          gap: '26px',
          padding: '16px 40px',
        }}
      >
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Typography color={theme.palette.colors.grey[600]} variant='font16'>
            Errors encountered in the widget
          </Typography>
          <Typography color={theme.palette.colors.grey[600]} variant='font12'>
            Updated hourly
          </Typography>
        </Box>
        <Box flex='1'>
          <InfiniteTable
            hasNextPage={!!errorEventsResponse?.metadata.more_records_to_fetch}
            initialSorting={[
              {
                desc: sortDirection === 'desc',
                id: sortColumn,
              },
            ]}
            isError={isErrorEventsError}
            isLoadingFirstPage={showLoadingSkeleton}
            isLoadingNextPage={isErrorEventsFetching}
            onLoadMore={handleLoadMore}
            onSortCallback={handleSortCallback}
            stickyHeaderHeight={229}
            tableOptions={{
              columns: columns,
              data: errorEventsResponse?.error_events || [],
              enableColumnActions: false,
              enableColumnFilters: false,
              enableExpanding: false,
              enableFullScreenToggle: false,
              enableGlobalFilter: false,
              enableRowActions: false,
              muiTableBodyRowProps: ({ row }) => ({
                onClick: () => {
                  setSelectedErrorId(row.original.error_id);
                },
                sx: {
                  cursor: 'pointer',
                },
              }),
              renderTopToolbarCustomActions: () => (
                <Box display='flex' gap='8px' paddingTop='4px'>
                  <Box width='360px'>
                    <WithHelperText helperText={uiQueryError}>
                      <SearchWithDropdown
                        aria-label='Chat ID filter'
                        fullWidth
                        onChange={e => setUiQuery(e.target.value)}
                        onClear={() => setUiQuery('')}
                        options={[...searchOptions]}
                        placeholder='Enter keyword'
                        selectChange={value => {
                          setSearchQueryMode(value as SearchQueryMode);
                          setUiQuery('');
                        }}
                        selectedOption={searchQueryMode}
                        value={uiQuery}
                      />
                    </WithHelperText>
                  </Box>
                  <DateRangeFilterButton
                    initialValue={last30DaysTimeRange}
                    onChange={dateRange => handleDateRangeOnChange(dateRange)}
                    options={datePickerRangeOptionsRevamp}
                    value={selectedDateRange}
                  />
                  <MultiSelectFilter
                    icon={<img src={filterIcon} />}
                    onChange={value => {
                      setMultiSelected(value);
                    }}
                    options={filterOptions}
                    placeholder='Filter by'
                    value={multiSelected}
                    variant='secondary'
                  />
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
      <ErrorDrawer
        error={selectedError}
        isLoading={isErrorEventsLoading}
        onClose={() => setSelectedErrorId('')}
      />
    </>
  );
};

export default ErrorPage;
