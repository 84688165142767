import {
  MultiStringInput,
  SelectDropdown,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { checkboxTypeValueOptions } from 'src/pages/workflow-builder-edit/conditions/constants';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

interface BaseProps {
  contextVariable: ContextVariable;
  contextVariableType: ContextVariable['context_variable_type'];
  onChange: (value: unknown) => void;
  required: boolean;
  value: unknown;
}

type ContextVariableInputProps = BaseProps &
  (
    | {
        contextVariableType: 'CHECKBOX';
        onChange: (value: boolean) => void;
        value: boolean;
      }
    | {
        contextVariableType: 'LIST';
        onChange: (value: string) => void;
        value: string;
      }
    | {
        contextVariableType: 'MULTI_SELECT_LIST';
        onChange: (value: string | string[]) => void;
        value: string | string[];
      }
    | {
        contextVariableType: 'MULTIOPTIONS';
        onChange: (value: string[]) => void;
        value: string[];
      }
    | {
        contextVariableType: 'NUMBER';
        onChange: (value: number) => void;
        value: number;
      }
    | {
        contextVariableType:
          | 'SHORT_TEXT'
          | 'LONG_TEXT'
          | 'EMAIL'
          | 'DATE'
          | 'PHONE';
        onChange: (value: string) => void;
        value: string;
      }
  );

/**
 * Given a context variable, render the appropriate input for the context variable type.
 * Set value and handle onChange for the input.
 */
export default function ContextVariableInput({
  contextVariable,
  contextVariableType,
  onChange,
  required = false,
  value,
}: BaseProps & ContextVariableInputProps) {
  const sharedProps = {
    label: contextVariable.context_variable_name,
    placeholder: contextVariable.context_variable_name,
    required,
  };

  switch (contextVariableType) {
    case 'CHECKBOX':
      return (
        <SelectDropdown
          {...sharedProps}
          id={contextVariable.context_variable_id}
          onChange={e => onChange(e.target.value === 'true')}
          options={checkboxTypeValueOptions}
          value={value.toString()}
        />
      );
    case 'LIST':
      return (
        <SelectDropdown
          {...sharedProps}
          id={contextVariable.context_variable_id}
          onChange={e => onChange(e.target.value)}
          options={contextVariable.list_type_options ?? []}
          value={value}
        />
      );
    case 'MULTIOPTIONS':
      return (
        <MultiStringInput
          {...sharedProps}
          onChange={(_, value) => onChange(value)}
          options={[]}
          value={Array.isArray(value) ? value : []}
        />
      );
    case 'MULTI_SELECT_LIST':
      return (
        <SelectDropdown
          {...sharedProps}
          id={contextVariable.context_variable_id}
          multiple
          onChange={e => onChange(e.target.value)}
          options={contextVariable.list_type_options ?? []}
          value={Array.isArray(value) ? value : []}
        />
      );
    case 'NUMBER':
      return (
        <TextField
          {...sharedProps}
          id={contextVariable.context_variable_id}
          onChange={e => {
            onChange(
              isNaN(e.target.valueAsNumber) ? '' : e.target.valueAsNumber,
            );
          }}
          type='number'
          value={isNaN(value) ? '' : value.toString()}
        />
      );
    case 'SHORT_TEXT':
    case 'LONG_TEXT':
    case 'EMAIL':
    case 'DATE':
    case 'PHONE':
      return (
        <TextField
          {...sharedProps}
          onChange={e => onChange(e.target.value)}
          value={value}
        />
      );
    default:
      console.error(
        `Context variable type ${contextVariableType} not supported`,
      );
      return null;
  }
}
