import './styles.scss';

import React from 'react';
import classNames from 'classnames';

interface ToggleButtonProps extends React.HTMLAttributes<HTMLInputElement> {
  /** theme for toggle button if not existent it uses default */
  isActionBuilderTheme?: boolean;
  /** Value that indicates if the button is toggled*/
  isChecked: boolean;
  isDisabled?: boolean;
  /** Prop to determine if the toggle should toggle - needed for the table view where it should be read-only */
  isNotToggleable?: boolean;
  /** Label for toggle */
  label?: string;
  /** Function that is executed when component is clicked  */
  onClickHandler?: (field: boolean) => void;
  /** value to determine the order in which elements should be displayed */
  order?: 'toggle-first' | 'label-first';
  /** Addittianal styling */
  styleClass?: string;
}

const ToggleButton: React.FC<React.PropsWithChildren<ToggleButtonProps>> = ({
  isActionBuilderTheme,
  isChecked,
  isDisabled = false,
  label,
  onClickHandler,
  order = 'label-first',
  styleClass,
  ...rest
}: ToggleButtonProps) => {
  const isToggleFirst = order === 'toggle-first';
  const isLabelFirst = order === 'label-first';

  const SliderStyle = classNames('ToggleButton-slider', {
    'ToggleButton-slider--disabled': isDisabled,
    'ToggleButton-slider--forest': !isActionBuilderTheme,
    'ToggleButton-slider--lime': isActionBuilderTheme,
  });

  const LabelStyles = classNames('ToggleButton-label', {
    'ToggleButton-label--toggleFirst': isToggleFirst,
  });

  return (
    <div className={`ToggleButton ${styleClass}`} {...rest}>
      {isLabelFirst && <span className={LabelStyles}>{label}</span>}
      <label className='ToggleButton-container' data-testid='toggle-btn'>
        <input
          checked={isChecked}
          className='ToggleButton-input'
          data-testid='toggle-btn-input'
          disabled={isDisabled}
          onChange={() => {
            onClickHandler && onClickHandler(!isChecked);
          }}
          type='checkbox'
        />
        <span className={SliderStyle}></span>
      </label>
      {isToggleFirst && <span className={LabelStyles}>{label}</span>}
    </div>
  );
};

export default ToggleButton;
