import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addConnectedConnector,
  selectConnectors,
} from '../../../../../../slices/connectors/connectorsSlice';
import { ConnectorDefinition, Credentials } from 'src/services/apiInterfaces';
import { generateOauthUrl, getCreatedConnectors } from 'src/services/apiV1';

export default function useHandleConnectOAuthSetupType({
  connectorDefinition,
}: {
  connectorDefinition: ConnectorDefinition;
}) {
  const dispatch = useDispatch();
  const { createdConnectors } = useSelector(selectConnectors);
  const createdConnectorIds = useMemo(() => {
    return createdConnectors.map(({ connector_id }) => connector_id);
  }, [createdConnectors]);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [timeoutRef]);

  /**
   * Fetch created connectors every 1000ms to check for new connector
   * If connector list length changes, try to find the new connector and add it to the list of created connectors
   * Because we do not have the id of the connector until it is connected, we must fetch the list
   */
  const pollForConnectorUpdate = async () => {
    const updatedConnectorList = await getCreatedConnectors();
    if (updatedConnectorList.length === createdConnectors.length) {
      timeoutRef.current = setTimeout(pollForConnectorUpdate, 1000);
    } else if (updatedConnectorList.length === createdConnectors.length + 1) {
      const newlyCreatedConnector = updatedConnectorList.find(
        ({ connector_id }) => !createdConnectorIds.includes(connector_id),
      );
      if (newlyCreatedConnector) {
        dispatch(addConnectedConnector(newlyCreatedConnector));
      }
    }
  };

  const handleConnectOAuthSetupType = async (formData: Credentials) => {
    const { oauth_url } = await generateOauthUrl(
      connectorDefinition.slug,
      formData,
    );
    // For sunco integration only, go directly to sunco page
    if (connectorDefinition.slug === 'legacy_sunshine') {
      location.href = oauth_url;
      return;
    }
    // For all other oauth integrations, open in a new tab and poll the server for updated connector details
    window.open(oauth_url);
    pollForConnectorUpdate();
  };

  return handleConnectOAuthSetupType;
}
