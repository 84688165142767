import { useMemo } from 'react';
import { Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import BaseModal from 'src/components/base-modal';
import {
  reportTicketReasonOptions,
  SUPPORT_EMAIL,
} from 'src/constants/discover';
import {
  DiscoverReportedTicketReason,
  DiscoverTicket,
} from 'src/reducers/discoverReducer/types';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface ReportTicketModalProps {
  onClose: () => void;
  reportedTicketId: string;
  tickets: DiscoverTicket[];
  topicId: string;
}

const initialValues: {
  notes: string;
  reasons: DiscoverReportedTicketReason[];
} = {
  notes: '',
  reasons: [],
};

const ReportTicketModal = ({
  onClose,
  reportedTicketId,
  tickets,
  topicId,
}: ReportTicketModalProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const ticketToReport = useMemo(
    () => tickets.find(ticket => ticket.id === reportedTicketId),
    [tickets, reportedTicketId],
  );

  const notesErrorMessage =
    'If "Other" is a reason, please specify additional information';

  return (
    <BaseModal
      headerTitle={<Typography variant='font16Bold'>Report ticket?</Typography>}
      isOpen={Boolean(reportedTicketId)}
      maxWidth='sm'
      onClose={onClose}
    >
      <DialogContent sx={{ py: 0, ...theme.typography.font14 }}>
        <Box mb={3}>
          The ticket &lsquo;{ticketToReport?.title}&rsquo; will be{' '}
          <Typography variant='font14Bold'>completely removed</Typography> from
          the topic and Discover. The ticket will no longer be included in any
          metrics calculations.
        </Box>
        <Box mb={4}>
          Contact{' '}
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            style={{ textDecoration: 'none' }}
          >
            {SUPPORT_EMAIL}
          </a>{' '}
          to add the ticket back.
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={({ notes, reasons }, { resetForm }) => {
            dispatch(
              setGlobalDiscoverOptions({
                reportedTicket: {
                  id: reportedTicketId,
                  notes,
                  reasons,
                  title: ticketToReport?.title ?? '',
                  topicId,
                },
              }),
            );
            resetForm();
            onClose();
          }}
          validationSchema={Yup.object().shape({
            notes: Yup.string().when('reasons', (reasons: string[]) => {
              if (reasons.includes('other')) {
                return Yup.string()
                  .trim()
                  .min(1, notesErrorMessage)
                  .max(
                    500,
                    'Additional information should not exceed 500 characters',
                  )
                  .required(notesErrorMessage);
              }

              return Yup.string().notRequired();
            }),
            reasons: Yup.array().min(1),
          })}
        >
          {({ isValid, resetForm, touched, values }) => {
            return (
              <Form translate={undefined}>
                <Box mb={1}>
                  <ReasonsField />
                </Box>
                <Box mb={3}>
                  <NotesField required={values.reasons.includes('other')} />
                </Box>
                <Box display='flex' justifyContent='flex-end' mb={3}>
                  <Box mr={1}>
                    <Button
                      onClick={() => {
                        onClose();
                        resetForm();
                      }}
                      variant='ghost'
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Button
                    disabled={Object.values(touched).length === 0 || !isValid}
                    type='submit'
                    variant='danger'
                  >
                    Report and remove ticket
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </BaseModal>
  );
};

const NotesField = ({ required }: { required: boolean }) => {
  const [field, meta, { setTouched, setValue }] = useField({ name: 'notes' });

  return (
    <TextField
      error={meta.touched && meta.error}
      label='Additional information'
      multiline
      onChange={e => setValue(e.target.value)}
      onFocus={() => setTouched(true)}
      placeholder='Please provide any feedback'
      required={required}
      rows={2}
      value={field.value}
    />
  );
};

const ReasonsField = () => {
  const [field, , { setTouched, setValue }] = useField({ name: 'reasons' });

  return (
    <SelectDropdown
      id='report-ticket-reasons'
      label='Reasons for reporting this ticket'
      multiple
      onChange={({ target }) => {
        setTouched(true);
        const { value } = target;
        if (typeof value === 'string') {
          return;
        }

        setValue(value);
      }}
      onClear={() => setValue([])}
      onDeleteChip={(_, value) =>
        setValue(field.value.filter((reason: string) => reason !== value))
      }
      options={reportTicketReasonOptions}
      value={field.value}
    />
  );
};

export default ReportTicketModal;
