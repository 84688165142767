import React from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';

import DraggableArticleCard from '../workflow-builder/business-logic/DraggableArticleCard';
import articleIcon from 'src/assets/images/article-icon.svg';
import dynamicArticleSuggestionIconRevamped from 'src/assets/images/dynamic-article-suggestion-revamped.svg';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  setIsSqueezingStep,
  setMode,
  setShouldSqueezeIntoEntry,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  startAddingActionThunk,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { Action, ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { ActionBuilderActionTypes, RevampedDragNDrops } from 'src/utils/enums';
import { isDropValid } from 'src/utils/solve/stepUtils';

type ArticleSuggestionActionCardProps = {
  action: Action;
  hasWorkflowConflict: boolean;
  isDisabled: boolean;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  squeezeStepParentId: string;
  tooltip?: string;
};

const ArticleSuggestionActionCard: React.FC<
  React.PropsWithChildren<ArticleSuggestionActionCardProps>
> = ({
  action,
  hasWorkflowConflict,
  isDisabled,
  setActionPanelVisibilityParameters,
  squeezeStepParentId,
}) => {
  const dispatch = useDispatch();

  const { contextVariables } = useGetContextVariables();

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isGranularizedArticleSuggestionV2Enabled = featureFlags.includes(
    'granularized_article_suggestion_v2',
  );
  const isDynamicArticleSuggestionMode = featureFlags.includes(
    'knowledge_article_workflow',
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      canDrag: !isDisabled,
      collect: monitor => ({
        handlerId: monitor.getHandlerId(),
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (!isDropValid(monitor.getDropResult())) {
          return;
        }

        if (monitor.didDrop() && !hasWorkflowConflict) {
          if (
            item.action_type ===
            ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2
          ) {
            const payload = {
              action: item,
              data: {
                action_id: item.action_id,
              },
            };
            dispatch(startAddingActionThunk(payload));
            dispatch(
              setMode({ contextVariables, mode: CanvasModes.ACTION_EDIT }),
            );
            setActionPanelVisibilityParameters(
              item.action_type as ActionPanelMode,
            );
          } else if (
            item.action_type ===
            ActionBuilderActionTypes.DYNAMIC_ARTICLE_SUGGESTION
          ) {
            dispatch(
              addAction({
                // default fields for dynamic article suggestion
                data: {
                  action_id: item.action_id,
                  configuration_fields: {
                    hand_off_when_not_found: true,
                    show_buttons: false,
                    show_not_helpful_text: false,
                  },
                },
              }),
            );
            dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
            dispatch(setIsSqueezingStep(false));
            dispatch(setShouldSqueezeIntoEntry(false));
          } else {
            dispatch(
              addAction({
                data: {
                  action_id: item.action_id,
                },
              }),
            );
            dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
            dispatch(setIsSqueezingStep(false));
            dispatch(setShouldSqueezeIntoEntry(false));
          }
        }
      },
      item: action,
      type: RevampedDragNDrops.REVAMPED_FT_ACTION,
    }),
    [squeezeStepParentId, isDisabled],
  );

  if (
    action.action_type ===
      ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2 &&
    !isGranularizedArticleSuggestionV2Enabled
  ) {
    return null;
  }

  if (
    isDynamicArticleSuggestionMode &&
    action.action_type === ActionBuilderActionTypes.DYNAMIC_ARTICLE_SUGGESTION
  ) {
    return (
      <DraggableArticleCard
        badgeText='recommended'
        description='Designed to dynamically recommend the most relevant articles to customers'
        drag={drag}
        hasGradientBorder
        icon={<img alt='' src={dynamicArticleSuggestionIconRevamped} />}
        isDragging={isDragging}
        label='Dynamic Article Suggestion'
      />
    );
  }

  return (
    <DraggableArticleCard
      description={
        action.action_type ===
        ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2
          ? 'This action suggests articles. Note to separately add forms to collect user questions and options to confirm issue resolution.'
          : 'This action contains all interactions (forms, confirmations) required in an article suggestion experience.'
      }
      drag={drag}
      icon={<img alt='' src={articleIcon} />}
      isDragging={isDragging}
      label={action.action_name}
    />
  );
};

export default ArticleSuggestionActionCard;
