import store from 'src/store/store';
import {
  BreakdownData,
  WorkflowBuilderLandingPageData,
} from 'src/types/workflowBuilderAPITypes';

export const deactivateWorkflowIntent = (
  intentWorkflowId: string,
): WorkflowBuilderLandingPageData => {
  const { workflowBuilder } = store.getState();
  const { landingData } = workflowBuilder;
  const breakdown: Array<BreakdownData> = landingData?.breakdown || [];

  const updatedBreakdownData = breakdown.map(breakdown => {
    if (intentWorkflowId === breakdown.id) {
      return { ...breakdown, is_active: false };
    } else {
      return breakdown;
    }
  });
  // @ts-expect-error legacy code with untyped state
  return { ...landingData, breakdown: updatedBreakdownData };
};

export const activateWorkflowIntent = (
  intentWorkflowId: string,
): WorkflowBuilderLandingPageData => {
  const { workflowBuilder } = store.getState();
  const { landingData } = workflowBuilder;
  const breakdown: Array<BreakdownData> = landingData?.breakdown || [];

  const updatedBreakdownData = breakdown.map(breakdown => {
    if (intentWorkflowId === breakdown.id) {
      return { ...breakdown, is_active: true };
    } else {
      return breakdown;
    }
  });
  // @ts-expect-error legacy code with untyped state
  return { ...landingData, breakdown: updatedBreakdownData };
};

export const removeIntentWorkflowsById = (
  intentWorkflowIds: Array<string>,
): WorkflowBuilderLandingPageData => {
  const { workflowBuilder } = store.getState();
  const { landingData } = workflowBuilder;
  const breakdown: Array<BreakdownData> = landingData?.breakdown || [];

  const filteredData = breakdown.filter(
    breakdown => !intentWorkflowIds.includes(breakdown.id as string),
  );
  // @ts-expect-error legacy code with untyped state
  return { ...landingData, breakdown: filteredData };
};

export const channelsList = ['widget', 'email'];
