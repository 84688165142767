import { createElement } from 'react';
import { TablerIconsProps } from '@tabler/icons-react';

type IconVariant =
  | 'IconBolt'
  | 'IconArticle'
  | 'IconTelegram'
  | 'IconRoute'
  | 'IconSupportGPT';

const getIconSvg = (variant: IconVariant, color: string): string => {
  switch (variant) {
    case 'IconBolt':
      return `
          <path
            d='M13 2V10H20L11 22V14H3L13 2Z'
            stroke="${color}"
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        `;

    case 'IconArticle':
      return `<rect x="2" y="2" width="20" height="20" rx="3" stroke="${color}" stroke-width="2" stroke-linecap="round"/>
<path d="M6 12.0001H18M6 16H13M6 8H18" stroke="${color}" stroke-width="2"/>
</svg>`;
    case 'IconTelegram':
      return `
  <path d="M15.6667 10.7031L10.7778 15.1719L18.1111 21.875L23 4L1 11.8203L5.88889 14.0547L8.33333 20.7578L12 16.2891" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
`;
    case 'IconRoute':
      return `<path d="M7 5L13.5 5C15.433 5 17 6.567 17 8.5V8.5C17 10.433 15.433 12 13.5 12H12" stroke="${color}" stroke-width="2"/>
<path d="M12.2 12L10.5 12C8.56701 12 7 13.567 7 15.5V15.5C7 17.433 8.567 19 10.5 19H20" stroke="${color}" stroke-width="2"/>
<path d="M21 19L18 22" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 19L18 16" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="5" cy="5" r="2" stroke="${color}" stroke-width="2" stroke-linecap="round"/>`;
    case 'IconSupportGPT':
      return `
      <g clip-path="url(#clip0_78_57986)">
        <path d="M5.07043 21.7727C2.26646 19.7664 0.514966 16.7923 0.137961 13.3934C-0.246897 9.93146 0.852699 6.45384 3.154 3.85742L6.6727 6.99673C5.25894 8.59392 4.57562 10.734 4.8191 12.8662C5.04687 14.9119 6.1072 16.7136 7.81157 17.9253L5.07043 21.7727Z" fill="url(#paint0_linear_78_57986)"/>
        <path d="M11.8967 23.9988C9.06917 23.9988 6.39087 23.0074 4.25451 21.1506C3.27273 20.293 3.16277 18.806 4.01889 17.8225C4.875 16.839 6.35945 16.7288 7.34123 17.5864C8.755 18.8138 10.585 19.4118 12.4858 19.2544C14.4808 19.0971 16.2951 18.1529 17.591 16.603C18.4236 15.6037 19.9159 15.47 20.9134 16.3118C21.9109 17.1458 22.0444 18.6407 21.204 19.64C19.0912 22.1656 16.1301 23.6998 12.8628 23.9595C12.5408 23.9909 12.2187 23.9988 11.8967 23.9988Z" fill="url(#paint1_linear_78_57986)"/>
        <path d="M19.2951 20.6073C18.7453 20.6073 18.1955 20.4185 17.7557 20.033C16.7739 19.1754 16.664 17.6883 17.5201 16.7048C18.7846 15.2414 19.4051 13.3767 19.2716 11.4491C19.1381 9.52142 18.2584 7.759 16.7975 6.49227C15.8157 5.63466 15.7057 4.14762 16.5619 3.16413C17.418 2.18064 18.9024 2.07049 19.8842 2.92809C22.2955 5.02096 23.7406 7.9321 23.9684 11.1186C24.1962 14.3051 23.1673 17.3893 21.0781 19.8048C20.6068 20.332 19.9549 20.6073 19.2951 20.6073Z" fill="url(#paint2_linear_78_57986)"/>
        <path d="M4.90691 7.81568C4.33354 7.81568 3.76018 7.61111 3.30464 7.18624C2.35427 6.29717 2.29144 4.81013 3.17897 3.85024C5.34674 1.5056 8.31565 0.144445 11.5359 0.0106907C14.764 -0.123064 17.835 1.00205 20.1756 3.1736C21.1259 4.06268 21.1888 5.54971 20.3012 6.5096C19.4137 7.46162 17.9293 7.52457 16.971 6.63549C15.5573 5.32154 13.6958 4.6449 11.7323 4.72358C9.76084 4.80226 7.95436 5.62839 6.63484 7.05249C6.17144 7.55604 5.5431 7.81568 4.90691 7.81568Z" fill="url(#paint3_linear_78_57986)"/>
        <path d="M2.7153 16.7469C1.70211 16.7469 0.767449 16.0938 0.461133 15.071C-0.72486 11.1685 0.421862 6.6838 3.38292 3.64678C4.29401 2.7105 5.78632 2.69476 6.71312 3.60744C7.64778 4.52012 7.66348 6.01502 6.75239 6.94344C5.00089 8.73733 4.28615 11.4518 4.96947 13.6941C5.34648 14.9451 4.64745 16.2591 3.39862 16.6367C3.17085 16.7154 2.94308 16.7469 2.7153 16.7469Z" fill="url(#paint4_linear_78_57986)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_78_57986" x1="7.99781" y1="8.40845" x2="1.65356" y2="15.5858" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF70C6"/>
          <stop offset="1" stop-color="#EC4A77"/>
        </linearGradient>
        <linearGradient id="paint1_linear_78_57986" x1="19.5695" y1="20.1138" x2="6.44843" y2="19.3648" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3866F5"/>
          <stop offset="1" stop-color="#BD00FF"/>
        </linearGradient>
        <linearGradient id="paint2_linear_78_57986" x1="15.7969" y1="14.9839" x2="21.913" y2="7.90742" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F6C043"/>
          <stop offset="1" stop-color="#FF9634"/>
        </linearGradient>
        <linearGradient id="paint3_linear_78_57986" x1="15.1654" y1="8.34063" x2="8.23738" y2="1.96254" gradientUnits="userSpaceOnUse">
          <stop stop-color="#6BDAB6"/>
          <stop offset="1" stop-color="#66E0F6"/>
        </linearGradient>
        <linearGradient id="paint4_linear_78_57986" x1="3.99618" y1="5.71975" x2="2.83323" y2="16.5544" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF70C6"/>
          <stop offset="1" stop-color="#EC4A77"/>
        </linearGradient>
        <clipPath id="clip0_78_57986">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
`;
    default:
      return '';
  }
};

export const createIcon = (
  variant: IconVariant,
): React.FC<React.PropsWithChildren<TablerIconsProps>> => {
  const Component = ({
    color = 'currentColor',
    fill = 'none',
    height = 24,
    width = 24,
    xmlns = 'http://www.w3.org/2000/svg',
  }: TablerIconsProps) =>
    createElement('svg', {
      dangerouslySetInnerHTML: { __html: getIconSvg(variant, color) },
      fill,
      height,
      width,
      xmlns,
    });

  Component.displayName = `${variant}`;

  return Component;
};
