import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  MISSING_CONTEXT_VARIABLE,
  NO_VALID_CONDITION,
  ZENDESK_REQUESTER_EMAIL_INVALID,
  ZENDESK_REQUESTER_EMAIL_IS_SUPPORT_ADDRESS,
  ZENDESK_REQUESTER_EMAIL_SUSPENDED,
  ZENDESK_VALUE_INVALID_FORMAT,
} from '../utils';
import { MissingContextVariableFix } from './MissingContextVariableFix';
import { NoValidConditionFix } from './NoValidConditionFix';
import { PlainTextFix } from './PlainTextFix';
import {
  ErrorQueryEvent,
  MissingContextVariablePayload,
} from 'src/types/ErrorEventTypes';

export const RecommendedFix = ({ error }: { error: ErrorQueryEvent }) => {
  const { palette } = useTheme();

  const getRecommendedFix = () => {
    switch (error.error_message) {
      case MISSING_CONTEXT_VARIABLE:
        return (
          <MissingContextVariableFix
            channel={error.channel}
            payload={error.error_payload as MissingContextVariablePayload}
          />
        );
      case NO_VALID_CONDITION:
        return (
          <NoValidConditionFix
            channel={error.channel}
            payload={error.error_payload as MissingContextVariablePayload}
          />
        );
      case ZENDESK_REQUESTER_EMAIL_INVALID:
        return (
          <PlainTextFix text='1. Use a context variable of type EMAIL to ensure the collected email adheres to the correct format.' />
        );
      case ZENDESK_REQUESTER_EMAIL_IS_SUPPORT_ADDRESS:
        return (
          <PlainTextFix text="1. Add a condition step to check if the requester's email is already used as a support address." />
        );
      case ZENDESK_REQUESTER_EMAIL_SUSPENDED:
        return (
          <PlainTextFix text='1. Add a condition step to verify the validity of the requester email before sending requests.' />
        );
      case ZENDESK_VALUE_INVALID_FORMAT:
        return (
          <PlainTextFix text='1. Use a context variable of the correct type in the form step to ensure the collected CV is in the right format.' />
        );
      default:
        return null;
    }
  };

  const CurrentErrorFix = getRecommendedFix();

  return (
    CurrentErrorFix && (
      <Box display='flex' flexDirection='column' gap='10px'>
        <Typography variant='font11'>How to fix</Typography>
        <Box
          border={`1px solid ${palette.colors.slate[200]}`}
          borderRadius='8px'
          display='flex'
          flexDirection='column'
          gap='16px'
          padding='16px'
        >
          {CurrentErrorFix}
        </Box>
      </Box>
    )
  );
};
