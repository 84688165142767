import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { patchTopic } from 'src/actions/discover/discover';
import { selectPatchTopic } from 'src/reducers/discoverReducer/discoverReducer';
import { PatchTopicRequest } from 'src/reducers/discoverReducer/types';
import { useAppDispatch } from 'src/store/hooks';

export const usePatchTopicMutation = () => {
  const data = useSelector(selectPatchTopic);
  const dispatch = useAppDispatch();

  const patchTopicMutation = useCallback(
    (topicId: string, body: PatchTopicRequest) => {
      dispatch(patchTopic({ body, topicId }));
    },
    [dispatch],
  );

  return { ...data, patchTopicMutation };
};
