const fieldTypeHeader = {
  heading: 'ALL MACROS & ARTICLES',
  headingClass: 'agent-heading heading-type',
  productSorted: 'solve_table',
  propertyName: 'field_value',
  tooltipText: null,
  tooltipTitle: null,
};

export const emailTableHeaderDict = [
  fieldTypeHeader,
  {
    heading: 'RESPONSES',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'responses',
    tooltipText: 'Number of tickets where Solve made an initial reply',
    tooltipTitle: 'Solve Responses',
  },
  {
    heading: 'RESOLUTIONS',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'resolutions',
    tooltipText:
      'Number of tickets that did not need any agent interaction after Solve’s response',
    tooltipTitle: 'Instant Resolutions',
  },
  {
    heading: 'REOPENS',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'reopens',
    tooltipText:
      'Number of tickets that the customer replied to after Solve’s Response and needed an agent’s interaction',
    tooltipTitle: 'Reopens',
  },
  {
    heading: 'REOPEN RATE',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'reopen_rate',
    tooltipText: 'Reopens / Solve Responses',
    tooltipTitle: 'Reopen Rate',
  },
  {
    heading: 'CSAT',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'csat',
    tooltipText: 'Positive CSAT survey responses for Solve / Overall responses',
    tooltipTitle: 'CSAT',
  },
];

export const widgetTableHeaderDict = [
  fieldTypeHeader,
  {
    heading: 'INTERACTIONS',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'interactions',
    tooltipText: 'Number of interactions where Solve made an initial reply',
    tooltipTitle: 'Solve Interactions',
  },
  {
    heading: 'RESOLUTIONS',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'instant_resolutions',
    tooltipText:
      'Number of interactions that did not lead to need an agent interaction after Solve’s response',
    tooltipTitle: 'Instant Resolutions',
  },
  {
    heading: 'TICKET CREATIONS',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'ticket_creations',
    tooltipText:
      'Number of tickets created because the customer needed more help from agent after Solve’s Response',
    tooltipTitle: 'Ticket Creations',
  },
  {
    heading: 'RESOLVE RATE',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'resolve_rate',
    tooltipText: 'Instant Resolutions / Solve Interactions',
    tooltipTitle: 'Instant Resolution Rate',
  },
];

export const apiTableHeaderDict = [
  fieldTypeHeader,
  {
    heading: 'INTERACTIONS',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'interactions',
    tooltipText:
      'Number of interactions where Solve returned an initial response',
    tooltipTitle: 'Solve Interactions',
  },
  {
    heading: 'RESOLUTIONS',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'instant_resolutions',
    tooltipText:
      'Number of interactions that did not lead to need a ticket creation after Solve’s response',
    tooltipTitle: 'Instant Resolutions',
  },
  {
    heading: 'TICKET CREATIONS',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'ticket_creations',
    tooltipText:
      'Number of tickets created because the customer needed additional help after Solve’s Response',
    tooltipTitle: 'Ticket Creations',
  },
  {
    heading: 'RESOLVE RATE',
    headingClass: 'heading-type',
    productSorted: 'solve_table',
    propertyName: 'resolve_rate',
    tooltipText: 'Instant Resolutions / Solve Interactions',
    tooltipTitle: 'Instant Resolution Rate',
  },
];

const getWorkflowAnalyticsTableHeaderDict = ({
  conversationTooltip,
  efficiencyTooltip,
  fieldRowHeading,
  handoffsTooltip,
  resolutionsTooltip,
  ssrTooltip,
}: {
  [index: string]: string;
}) => {
  const productSorted = {
    productSorted: 'solve_workflows',
  };

  const headingClass = {
    headingClass: 'heading-type',
  };

  return [
    {
      heading: fieldRowHeading,
      headingClass: 'agent-heading heading-type',
      propertyName: 'field_value',
      ...productSorted,
      tooltipText: null,
      tooltipTitle: null,
    },
    {
      heading: 'CONVERSATIONS',
      ...headingClass,
      propertyName: 'conversations',
      ...productSorted,
      tooltipText: conversationTooltip,
      tooltipTitle: 'Conversations',
    },
    {
      heading: 'RESOLUTIONS',
      ...headingClass,
      propertyName: 'resolutions',
      ...productSorted,
      tooltipText: resolutionsTooltip,
      tooltipTitle: 'Resolutions',
    },
    {
      heading: 'HANDOFFS',
      ...headingClass,
      propertyName: 'handoffs',
      ...productSorted,
      tooltipText: handoffsTooltip,
      tooltipTitle: 'handoffs',
    },
    {
      heading: 'EFFICIENCY',
      ...headingClass,
      propertyName: 'efficiency',
      ...productSorted,
      tooltipText: efficiencyTooltip,
      tooltipTitle: 'Efficiency',
    },
    {
      heading: 'SSR',
      ...headingClass,
      propertyName: 'self_serve_rate',
      ...productSorted,
      tooltipText: ssrTooltip,
      tooltipTitle: 'Self-Serve Rate (SSR)',
    },
  ];
};

export const workflowWidgetTableHeaderDict = (filterType: string) => {
  // Tooltips to display if filter type is 'table-knowledge'
  const articlesFilterTooltips = {
    conversationTooltip:
      'Conversations that are attributed to the given article. If many articles were recommended in a conversation, we will only attribute it to the last.',

    efficiencyTooltip:
      'Percentage of conversations that are deemed resolved by the given article. Efficiency = Resolutions / Conversations.',

    fieldRowHeading: 'ARTICLES',

    handoffsTooltip:
      "Conversations for which the given article was recommended and that ended-up requiring agent assistance. We count all conversations that go through a 'handoff' workflow in the workflow builder.",

    resolutionsTooltip:
      'Conversations that are deemed resolved by the given article. If multiple articles are recommended in a conversation, we will attribute the resolution only to the last.',

    ssrTooltip:
      'Conversations resolved by the given article over the entire traffic of conversations. SSR = Resolutions / all non-empty conversations',
  };

  // Tooltips to display if filter type is 'table-workflow'
  const workflowFilterTooltips = {
    conversationTooltip:
      'Conversations that are attributed to the given workflow. If a conversation goes through many workflows, we will only attribute it to the last.',

    efficiencyTooltip:
      'Percentage of conversations that are deemed resolved by the given workflow. Efficiency = Resolutions / Conversations.',

    fieldRowHeading: 'WORKFLOWS',

    handoffsTooltip:
      "Conversations attributed to the given workflow that ended-up requiring agent assistance. We count all conversations that go through a 'handoff' workflow in the workflow builder",

    resolutionsTooltip:
      'Conversations that are deemed resolved by the given workflow. If a conversation goes through many workflows, we will attribute the resolution only to the last.',

    ssrTooltip:
      'Percentage of conversations that are deemed resolved by the given article.',
  };

  if (filterType === 'table-knowledge') {
    return getWorkflowAnalyticsTableHeaderDict(articlesFilterTooltips);
  } else {
    return getWorkflowAnalyticsTableHeaderDict(workflowFilterTooltips);
  }
};
