import React from 'react';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import closeIcon from 'src/assets/images/close-toast-icon.svg';

interface BannerProps {
  handleClose?: () => void;
  message: string;
}

const Banner: React.FC<React.PropsWithChildren<BannerProps>> = ({
  handleClose,
  message,
}) => {
  return (
    <Wrapper role='alert'>
      <span>{message}</span>
      {handleClose && (
        <CloseIcon
          aria-label='Close message'
          onClick={handleClose}
          role='button'
        >
          <img src={closeIcon} />
        </CloseIcon>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  background-color: ${theme.palette.colors.red[500]};
  border-radius: 6px;
  color: ${theme.palette.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-base);
  padding: 18px;
  width: 100%;
  max-width: 600px;
`;

const CloseIcon = styled('span')`
  cursor: pointer;
`;

export default Banner;
