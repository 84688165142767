import { useMemo } from 'react';
import { useLocation } from 'react-router';

import {
  ConversationChannel,
  WorkflowBuilderHeaderChannel,
} from 'src/types/workflowBuilderAPITypes';

export function useSolveMetricsQueryParams() {
  const { search } = useLocation();

  return useMemo(() => {
    const qParams = new URLSearchParams(search);
    const to = qParams.get('end');
    const from = qParams.get('start');
    const headerChannel = (qParams.get('header') ||
      'workflows') as WorkflowBuilderHeaderChannel;
    const channel = (qParams.get('channel') || 'widget') as ConversationChannel;

    return { channel, from, headerChannel, to };
  }, [search]);
}
