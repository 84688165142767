import { useState } from 'react';
import { styled } from '@mui/material';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Button,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { productNameToLabel, showProductUsageMeter } from './helpers';
import UpgradePlanModal from './UpgradePlanModal';
import UpgradeIcon from 'src/assets/images/upgrade-icon.svg';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { ProductQuota } from 'src/reducers/userReducer/types';
import { upgradeButtonClickedApi } from 'src/services/api';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';

export const UsageMeterWidget = ({
  isFullWidth,
  pathname,
}: {
  isFullWidth: boolean;
  pathname: string;
}) => {
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const [shouldShowUpgradePlanModal, setShouldShowUpgradePlanModal] =
    useState(false);

  if (!paidPlanData) {
    return null;
  }

  const { product_quotas: productQuotas, show_quota_widget: showQuotaWidget } =
    paidPlanData;

  const prunedProductQuotas = productQuotas.filter(quota =>
    showProductUsageMeter(quota, paidPlanData, pathname),
  );

  if (!showQuotaWidget) {
    return null;
  }

  if (!isFullWidth) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        pb={3}
        sx={{ background: theme.palette.colors.slate[800] }}
      >
        <Box
          borderRadius={0.5}
          onClick={() => {
            emitTrackingEventCallback('triage-clicked-upgrade-plan-button');

            upgradeButtonClickedApi();
            setShouldShowUpgradePlanModal(true);
          }}
          p={1.25}
          sx={{
            background: theme.palette.colors.purple[500],
            cursor: 'pointer',
            height: 20,
            width: 20,
          }}
        >
          <img alt='Upgrade plan' sizes='10px' src={UpgradeIcon} />
          {shouldShowUpgradePlanModal && (
            <UpgradePlanModal
              isOpen={shouldShowUpgradePlanModal}
              onClose={() => setShouldShowUpgradePlanModal(false)}
            />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <>
      <UsageMeterWidgetContainer>
        <UsageMeterWidgetHeader>Free trial</UsageMeterWidgetHeader>
        {prunedProductQuotas.map(details => (
          <LinearProgressItem key={details.product} {...details} />
        ))}

        <Button
          aria-label='upgrade-full-button'
          fullWidth
          onClick={() => {
            emitTrackingEventCallback('triage-clicked-upgrade-plan-button');

            upgradeButtonClickedApi();
            setShouldShowUpgradePlanModal(true);
          }}
          size='large'
          variant='main'
        >
          <img src={UpgradeIcon} />
          <Typography color={theme.palette.colors.white} variant='font14Bold'>
            Upgrade plan
          </Typography>
        </Button>
      </UsageMeterWidgetContainer>
      {shouldShowUpgradePlanModal && (
        <UpgradePlanModal
          isOpen={shouldShowUpgradePlanModal}
          onClose={() => setShouldShowUpgradePlanModal(false)}
        />
      )}
    </>
  );
};

const LinearProgressItem = ({
  current_quota: currentQuota,
  max_quota: maxQuota,
  product,
}: ProductQuota) => {
  const maxUsage = Math.max(Math.min(currentQuota, maxQuota), 0);

  return (
    <>
      <LinearProgressLabels>
        <span>{productNameToLabel(product)}</span>
        <span>
          {maxUsage}{' '}
          <DarkerProgressLabelText>/ {maxQuota}</DarkerProgressLabelText>
        </span>
      </LinearProgressLabels>
      <LinearProgress
        color='success'
        sx={{
          backgroundColor: theme.palette.colors.slate[700],
          borderRadius: '50px',
          height: '5px',
          mb: 2,
        }}
        value={(maxUsage / maxQuota) * 100}
        variant='determinate'
      />
    </>
  );
};

// TODO remove mui styles once ft-elements has been updated
const UsageMeterWidgetContainer = styled('div')`
  background-color: ${theme.palette.colors.slate[800]};
  border-top: 1px solid ${theme.palette.colors.slate[700]};
  padding: 16px 16px 24px 16px;
  color: ${theme.palette.colors.white};
`;

const UsageMeterWidgetHeader = styled('p')`
  font-weight: 400;
  margin-top: 0;
`;

const LinearProgressLabels = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
`;

const DarkerProgressLabelText = styled('span')`
  color: ${theme.palette.colors.grey[300]};
`;
