import { setTag, withScope } from '@sentry/react';

export const setSentryTraceIdTag = () => {
  let traceId = undefined;
  withScope(function (scope) {
    traceId = scope?.getSpan()?.toTraceparent();
  });

  if (traceId !== undefined) {
    setTag('trace_id', traceId);
  }
};

export const getSentryTraceId = (): string | undefined => {
  let traceId = undefined;
  withScope(function (scope) {
    traceId = scope?.getSpan()?.toTraceparent();
  });

  return traceId;
};
