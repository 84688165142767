import { useEffect, useState } from 'react';
import { JSONSchema7 } from 'json-schema';

import { ConnectorDefinition, Credentials } from 'src/services/apiInterfaces';
import { getConnector } from 'src/services/apiV1';

/**
 * Manages state of credential form depending on form setup type and whether that form is already connected
 * If connected, set the initial form data with existing connector credentials
 * Otherwise, determine which form schema to render depending on the connector definition's 'setup_type' property
 */
export function useConnectorFormData({
  connectorDefinition,
  connectorId,
}: {
  connectorDefinition?: ConnectorDefinition;
  connectorId?: string;
}) {
  const [credentialSchema, setCredentialSchema] = useState<JSONSchema7 | null>(
    null,
  );
  const [formData, setFormData] = useState<Credentials>({});

  useEffect(() => {
    if (!connectorDefinition) {
      return;
    }

    // If we have an existing connectorId, fetch connector by id in order to retrieve connector credential schema and existing credentials
    const { oauth_credential_schema, setup_type } = connectorDefinition;
    if (connectorId) {
      getConnector(connectorId).then(connector => {
        const { connector_definition, connector_fields, credentials } =
          connector;
        if (setup_type === 'OAUTH') {
          setCredentialSchema(connector_definition.oauth_credential_schema);
          setFormData(credentials || {});
        } else {
          setCredentialSchema(connector_definition.connector_fields_schema);
          setFormData(connector_fields);
        }
      });
    } else if (setup_type === 'OAUTH' && oauth_credential_schema) {
      setCredentialSchema(connectorDefinition.oauth_credential_schema);
    } else if (setup_type === 'FORM') {
      setCredentialSchema(connectorDefinition.connector_fields_schema);
    }

    return () => {
      setCredentialSchema(null);
      setFormData({});
    };
  }, [connectorId, connectorDefinition]);

  return {
    credentialSchema,
    formData,
    setFormData,
  };
}
