import LogRocket from 'logrocket';
import { Middleware } from '@reduxjs/toolkit';

import { dashboardApi } from '../services/dashboard-api';
import type { RootState } from './rootReducer';
import merge from 'lodash/fp/merge';

const LogRocketMiddleware = LogRocket.reduxMiddleware({
  stateSanitizer: state =>
    merge(state, {
      ...(state.solveWorkflows.workflowConfigData?.preview_api_token
        ? {
            solveWorkflows: {
              workflowConfigData: {
                preview_api_token: null,
              },
            },
          }
        : {}),

      actionBuilder: {
        settings: {
          authorization_configs: null,
        },
      },

      canvasWorkflowBuilder: {
        previewToken: null,
      },
    }),
});

const persistMiddleware: Middleware<void, RootState> = () => next => action => {
  const result = next(action);
  if (action.type === 'ui/setIsSidebarExpanded') {
    localStorage.setItem('sidebarToggle', JSON.stringify(action.payload));
  }
  return result;
};

// NOTE if we add Middleware in the future, LogRocket should always be the last
// middleware added.
// https://docs.logrocket.com/reference/redux-logging
export const middleware = [
  persistMiddleware,
  dashboardApi.middleware,
  LogRocketMiddleware,
];
