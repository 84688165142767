import cloneDeep from 'lodash/fp/cloneDeep';
import { ArticleSuggestionActionSettings } from 'src/types/workflowBuilderAPITypes';

export const removeFilterQuery = ({
  articleSourceIndex,
  articleSources,
  filterQueryIndex,
}: {
  articleSourceIndex: number;
  articleSources: ArticleSuggestionActionSettings['article_sources'];
  filterQueryIndex: number;
}) => {
  const articleSourcesCopy: ArticleSuggestionActionSettings['article_sources'] =
    cloneDeep(articleSources);

  const articleSourceToUpdate = articleSourcesCopy.find(
    (_, index) => index === articleSourceIndex,
  );

  const updatedFilterQueries =
    articleSourceToUpdate?.filter_queries.filter(
      (_, index) => index !== filterQueryIndex,
    ) || [];

  if (updatedFilterQueries.length === 1) {
    updatedFilterQueries[0] = {
      ...updatedFilterQueries[0],
      boolean_operator: null,
    };
  }

  articleSourcesCopy[articleSourceIndex]['filter_queries'] =
    updatedFilterQueries;

  return articleSourcesCopy;
};
