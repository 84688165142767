import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import { useReactFlow } from 'reactflow';
import { v4 as uuid } from 'uuid';
import { Box } from '@mui/material';

import { DndContext } from '../DndContext';
import { ForethoughtFlowProps } from '../ForethoughtFlow';
import { useEdgeDrop } from '../hooks/useEdgeDrop';
import { DragItem } from '../types';

interface FlowDropzoneProps {
  children: React.ReactNode;
  onDropCallback: ForethoughtFlowProps['onDropCallback'];
}

export default function FlowDropzone({
  children,
  onDropCallback,
}: FlowDropzoneProps) {
  const { getNode } = useReactFlow();
  const { nearestEdge } = useContext(DndContext);
  const handleEdgeDrop = useEdgeDrop();

  const [, dropRef] = useDrop({
    accept: ['action', 'response', 'rule'],
    drop: (item: DragItem, monitor) => {
      const didDrop = monitor.didDrop();
      // check to see if drop was handling by placeholder node
      if (didDrop) {
        return;
      }
      const insertNodeType = monitor.getItemType() as string;
      if (!nearestEdge || !insertNodeType) {
        return;
      }

      // create the node here so that we can add this id in the ui and send it to the backend
      // after the node is created, we can update the optimistic frontend node with the correct data
      const insertNodeId = uuid();

      // this adds the node to the ui
      handleEdgeDrop(nearestEdge.id, insertNodeId, insertNodeType, item);

      const parentNode = getNode(nearestEdge.source);
      if (!parentNode) {
        return;
      }

      // this callback can do other stuff like make a request to add the node on the backend
      onDropCallback?.(
        item,
        insertNodeId,
        parentNode,
        nearestEdge.data.transitionId,
      );
    },
  });

  return (
    <Box ref={dropRef} sx={{ height: '100%', width: '100%' }}>
      {children}
    </Box>
  );
}
