import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Skeleton } from '@forethought-technologies/forethought-elements';
import { BUTTON_SIZE, COLUMN_SIZE } from '../constants';

interface DiscoverAutomationCardLoadingSkeletonProps {
  variant: 'topic' | 'intent';
}

const DiscoverAutomationCardLoadingSkeleton = ({
  variant,
}: DiscoverAutomationCardLoadingSkeletonProps) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        border: `1px solid ${palette.colors.slate[200]}`,
        borderRadius: '8px',
        display: 'flex',
        mb: 2,
        px: 3,
        py: 4,
      }}
    >
      <Box alignItems='center' display='flex' flex={1}>
        {variant === 'topic' ? (
          <Skeleton variant='text' width={424} />
        ) : (
          <Box display='flex' flexDirection='column'>
            <Skeleton variant='text' width={424} />
            <Skeleton variant='text' width={304} />
          </Box>
        )}
      </Box>
      <Box alignItems='center' display='flex' width={COLUMN_SIZE}>
        <Skeleton variant='text' width={COLUMN_SIZE * 0.66} />
      </Box>
      {variant === 'intent' && (
        <Box alignItems='center' display='flex' width={COLUMN_SIZE}>
          <Skeleton variant='text' width={COLUMN_SIZE * 0.66} />
        </Box>
      )}
      <Box alignItems='center' display='flex'>
        <Box alignItems='center' display='flex' width={COLUMN_SIZE}>
          <Skeleton variant='text' width={COLUMN_SIZE * 0.66} />
        </Box>
        <Box width={BUTTON_SIZE}>
          <Skeleton variant='text' width={BUTTON_SIZE * 0.66} />
        </Box>
      </Box>
    </Box>
  );
};

export default DiscoverAutomationCardLoadingSkeleton;
