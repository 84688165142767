import { MutableRefObject, useEffect } from 'react';

import { TEXT_EDITOR_FLOATING_TOOLBAR_ID } from '../rich-text-editor/constants';
import { ACTIVE_COMPONENT_ID } from './constants';

const MUI_POPOVER_ROOT_CLASS = 'MuiPopover-root';
const MUI_POPPER_ROOT_CLASS = 'MuiPopper-root';

/**
 * Adds an event listener to call callback whenever click event happens outside
 * 1. the ref given for the sidebar
 * 2. the distant active node with id: ACTIVE_COMPONENT_ID
 * 3. MUI popover root, because MUI uses React portal for select menu.
 */
export function useOnClickOutsideActiveComponent(
  ref: MutableRefObject<Element | null>,
  handler: (event: MouseEvent) => void,
  id = ACTIVE_COMPONENT_ID,
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const activeComponentElement = document.getElementById(id);
      const textEditorloatingToolbar = document.getElementById(
        TEXT_EDITOR_FLOATING_TOOLBAR_ID,
      );

      if (!activeComponentElement) {
        return;
      }
      const elements = [ref.current, activeComponentElement];

      // this prevents us from handling clicks on MUI select dropdowns
      // because MUI uses a react portal, they otherwise will be ignored by 'element.contains(event)'
      const muiPopoverRoots = document.getElementsByClassName(
        MUI_POPOVER_ROOT_CLASS,
      );
      // this targets the rich text editor's link menu
      const muiPopperRoots = document.getElementsByClassName(
        MUI_POPPER_ROOT_CLASS,
      );
      elements.push(
        ...Array.from(muiPopoverRoots),
        ...Array.from(muiPopperRoots),
      );

      const isTextEditorFloatingToolbarEvent =
        textEditorloatingToolbar &&
        textEditorloatingToolbar.contains(event.target as Node);

      const isEventContained = elements.some(element => {
        return !element || element.contains(event.target as Node);
      });

      if (isEventContained || isTextEditorFloatingToolbarEvent) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler, id]);
}
