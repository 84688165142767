export const formatNToPercentage = (n: number) => {
  const formattedNumber = Math.round(100 * n).toString();
  return formattedNumber + '%';
};

export const roundNumToPercentage = (n: number) => {
  const formattedNumber = Math.round(n).toString();
  return formattedNumber + '%';
};

/** Function that returns percentage with 1 decimal ej. 0.03% */
export const formatNToPercentageDecimal = (n: number | string) => {
  let number;
  if (typeof n === 'string') {
    number = n;
  } else {
    const numberToFormat = 100 * (n as number);
    number = numberToFormat.toFixed(1).toString() + '%';
  }
  return number;
};
