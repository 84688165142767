import { ReactNode } from 'react';
import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { Divider } from '../intent-conversation-analytics/ConversationDrawer';
import { ErrorQueryEvent } from 'src/types/ErrorEventTypes';

export default function Section({
  data,
  label,
}: {
  data: Array<{
    includeDivider?: boolean;
    label: string;
    value:
      | string
      | ReactNode
      | Array<string>
      | ErrorQueryEvent['error_payload'];
  }>;
  label: string;
}) {
  const { palette } = useTheme();

  const renderValue = (
    value:
      | string
      | ReactNode
      | Array<string>
      | ErrorQueryEvent['error_payload'],
  ) => {
    const isValueArray = Array.isArray(value);
    const isValueEmpty = isValueArray ? value.length === 0 : !value;

    if (isValueEmpty) {
      return (
        <Typography color={palette.colors.grey[600]} variant='font16'>
          n/a
        </Typography>
      );
    }

    if (isValueArray) {
      return (
        <Box display='flex' flexWrap='wrap' gap='3px'>
          {value.map((item, index) => {
            const shouldAddcomma =
              index !== value.length - 1 && value.length > 1;

            return (
              <Box key={index}>
                <Typography color={palette.colors.black} variant='font16'>
                  {`${item}${shouldAddcomma ? ', ' : ''}`}
                </Typography>
              </Box>
            );
          })}
        </Box>
      );
    }

    if (typeof value === 'string' || React.isValidElement(value)) {
      return (
        <Typography color={palette.colors.black} variant='font16'>
          {value}
        </Typography>
      );
    }

    return (
      <Box
        component='pre'
        margin={0}
        sx={{
          overflowX: 'scroll',
        }}
      >
        <code>{JSON.stringify(value, null, 2)}</code>
      </Box>
    );
  };

  return (
    <Box display='flex' flexDirection='column' gap='10px'>
      <Typography color={palette.colors.black} variant='font11'>
        {label}
      </Typography>
      <Box
        border={`1px solid ${palette.colors.slate[200]}`}
        borderRadius='8px'
        display='flex'
        flexDirection='column'
        gap='16px'
        padding='16px'
      >
        {data.map(({ includeDivider, label, value }) => {
          return (
            <Box display='flex' flexDirection='column' gap='5px' key={label}>
              <Typography
                color={palette.colors.grey[600]}
                variant='font12Medium'
              >
                {label}
              </Typography>

              {renderValue(value)}

              {!!includeDivider && <Divider />}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
