import { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { HeadingExtension } from '@remirror/extension-heading';
import { useActive, useCommands } from '@remirror/react-core';
import { IconTextSize } from '@tabler/icons-react';

import {
  ListOption,
  MoreMenu,
} from '@forethought-technologies/forethought-elements';
import CustomThemeProvider from '../theme';
import { buttonStyles } from './helpers/buttonStyles';

const HUGE_TEXT_LEVEL = '1';
const LARGE_TEXT_LEVEL = '2';
const MEDIUM_TEXT_LEVEL = '3';
const NORMAL_TEXT_LEVEL = '-1';
const SMALL_TEXT_LEVEL = '6';

/**
 * label to display in dropdown menu
 * value is stringified equivalent to 'level' that remirror heading extension accepts
 * -1 is used for normal text
 */
const options: ListOption[] = [
  {
    label: 'Huge Text',
    value: HUGE_TEXT_LEVEL,
  },
  {
    label: 'Large Text',
    value: LARGE_TEXT_LEVEL,
  },
  {
    label: 'Medium Text',
    value: MEDIUM_TEXT_LEVEL,
  },
  {
    label: 'Normal Text',
    value: NORMAL_TEXT_LEVEL,
  },
  {
    label: 'Small Text',
    value: SMALL_TEXT_LEVEL,
  },
];

export default function HeadingLevelTool() {
  // provides callback to determine active state
  // 'true' option is to update this component when the value changes
  const active = useActive<HeadingExtension>(true);
  const getActiveOption = useCallback(() => {
    // check each option with 'active.heading' callback to determine active heading level for block
    for (const { value } of options) {
      const level = parseInt(value, 10);
      const isActive = active.heading({ level });
      if (isActive) {
        return value;
      }
    }
    return NORMAL_TEXT_LEVEL;
  }, [active]);

  const { toggleHeading } = useCommands<HeadingExtension>();
  const disabled = !toggleHeading.enabled();

  return (
    <CustomThemeProvider>
      <MoreMenu
        onChange={value => {
          if (value === NORMAL_TEXT_LEVEL) {
            return toggleHeading();
          }

          const level = parseInt(value, 10);
          toggleHeading({ level });
        }}
        options={options}
        renderAnchorEl={() => (
          <IconButton disabled={disabled} sx={buttonStyles}>
            <IconTextSize size='18px' />
          </IconButton>
        )}
        value={getActiveOption()}
      />
    </CustomThemeProvider>
  );
}
