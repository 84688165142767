import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import BranchStepValidIndicator from '../../BranchStepValidIndicator';
import UndefinedContextVariablesTooltip from '../../UndefinedContextVariablesTooltip';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import TextBox from './TextBox';
import {
  selectCanvasWorkflowBuilder,
  selectEditingStepId,
  selectUndefinedContextVariablesInStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetContextVariablesQuery } from 'src/services/dashboard-api';
import {
  CanvasWorkflowBuilderState,
  selectEditingStepFields,
  setOutputVariableValue,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  ButtonOption,
  ButtonsStepFields,
  Transition,
} from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';
import { isOptionsStepFields } from 'src/utils/solve/stepUtils';

interface OptionsStepFields {
  fields?: ButtonsStepFields;
  stepId?: string;
  transitions?: Transition[];
}

const OptionsStep = ({
  fields,
  stepId,
  transitions = [],
}: OptionsStepFields) => {
  const dispatch = useAppDispatch();
  const editingFields = useSelector(selectEditingStepFields);
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const { data } = useGetContextVariablesQuery();
  const { context_variables: contextVariables = [] } = data ?? {};

  const editingStepId = useSelector(selectEditingStepId);
  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );

  const isBeingEdited = editingStepId === stepId || !stepId;

  const toShow = isBeingEdited ? editingFields : fields;

  if (!toShow || !isOptionsStepFields(toShow)) {
    return null;
  }

  const { options, prompt } = toShow;

  const getBranchId = (option: ButtonOption) => {
    const transitionBranch = transitions.find(transition => {
      const conditionExpr = transition.condition_expression;
      return (
        conditionExpr?.field === toShow.output_variable &&
        conditionExpr?.values?.includes(option.value)
      );
    });
    return transitionBranch?.step_id;
  };

  const onClick = (option: ButtonOption) => {
    if (!stepId) {
      return;
    }
    dispatch(
      setOutputVariableValue({
        outputVariable: toShow.output_variable,
        stepId,
        value: option.value,
      }),
    );
  };

  const undefinedContextVariableIds =
    undefinedContextVariablesInStep[stepId ?? ''];

  return (
    <StepContainer isBeingEdited={isBeingEdited}>
      <StepHeader stepType={StepTypes.BUTTONS}>
        <Box ml='auto' mr={2}>
          <UndefinedContextVariablesTooltip
            contextVariables={contextVariables}
            stepId={stepId ?? ''}
          />
        </Box>
      </StepHeader>
      <Box display='flex' flexDirection='column' gap={1}>
        <TextBox
          isRichText
          placeholder='Empty message'
          undefinedContextVariableIds={undefinedContextVariableIds}
          value={prompt}
          variant='dark'
        />
        {options.map((option, index) => (
          <TextBox
            index={index}
            isRichText
            isSelected={
              canvasData.outputVariableValuesMap[stepId ?? '']?.[
                toShow.output_variable
              ] === option.value
            }
            key={index}
            onClick={() => onClick(option)}
            placeholder='Empty option'
            undefinedContextVariableIds={undefinedContextVariableIds}
            validIndicator={
              <BranchStepValidIndicator
                transitionBranchStepId={getBranchId(option)}
                undefinedTransitionCvs={[]}
              />
            }
            value={option.display_name}
          />
        ))}
      </Box>
    </StepContainer>
  );
};

export default OptionsStep;
