import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';

import {
  Colors,
  Typography,
} from '@forethought-technologies/forethought-elements';
import NameEmailQuestion from '../name-email-question/NameEmailQuestion';
import TicketCreationOnly from './ticket-creation/ticket-creation-only';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import {
  Label,
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { textMediumStyle } from 'src/styles/styledMixin';
import {
  ZendeskCommonCustomization,
  ZendeskOrSalesforceContextVariables,
  ZendeskTicketCreationCustomization,
} from 'src/types/workflowBuilderAPITypes';

interface ZendeskTicketCreationConfigurationProps {
  customizationData: ZendeskTicketCreationCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  isFromCanvas: boolean;
  setCustomizationData: Dispatch<
    SetStateAction<ZendeskTicketCreationCustomization>
  >;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const ZendeskTicketCreationConfiguration: FC<
  React.PropsWithChildren<ZendeskTicketCreationConfigurationProps>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  isFromCanvas,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  return (
    <ContentWrapper useFullHeight={isFromCanvas}>
      <Content>
        {!isFromCanvas ? (
          <>
            <DefaultTitle>
              Connected with
              <Helpdesk color={Colors.ui.text.secondary} variant='font16'>
                Zendesk
              </Helpdesk>
            </DefaultTitle>
          </>
        ) : (
          <Title>Zendesk Ticket Creation</Title>
        )}
        <Spacer height='20px' />
        <Section>
          <NameEmailQuestion
            customizationData={customizationData}
            errorMessages={errorMessages}
            isFieldTouchedArr={isFieldTouchedArr}
            setCustomizationData={
              setCustomizationData as Dispatch<
                SetStateAction<ZendeskOrSalesforceContextVariables>
              >
            }
            setIsFieldTouchedArr={setIsFieldTouchedArr}
          />
          <Spacer height='28px' />
          <Label>Ticket ID Output Context Variable</Label>
          <ContextVariableSelectDropdown
            aria-label='Ticket ID Output Context Variable'
            isClearable
            onChange={value => {
              setCustomizationData({
                ...customizationData,
                ticket_id_output_variable: value,
              });
            }}
            value={customizationData.ticket_id_output_variable || ''}
          />
          <Spacer height='28px' />
          <TicketCreationOnly
            customizationData={customizationData}
            errorMessages={errorMessages}
            isFieldTouchedArr={isFieldTouchedArr}
            isFromCanvas={isFromCanvas}
            setCustomizationData={
              setCustomizationData as Dispatch<
                SetStateAction<ZendeskCommonCustomization>
              >
            }
            setIsFieldTouchedArr={setIsFieldTouchedArr}
          />
          <Spacer height='100px' />
        </Section>
      </Content>
    </ContentWrapper>
  );
};

export default ZendeskTicketCreationConfiguration;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const DefaultTitle = styled('h2')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const Helpdesk = styled(Typography)`
  margin-left: 5px;
`;

const ContentWrapper = styled('div')<{ useFullHeight: boolean }>`
  display: flex;
  flex-direction: column;

  max-height: ${props => (props.useFullHeight ? '' : '700px')};
`;

const Content = styled('div')`
  margin-top: 0;
  margin-bottom: 50px;
  min-height: 500px;
`;
