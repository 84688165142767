import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconGripVertical, IconInfoCircle } from '@tabler/icons-react';

import {
  Badge,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ActiveIndicator from './ActiveIndicator';
import CircularStepIcon from './CircularStepIcon';
import { StepTypes } from 'src/utils/enums';

interface BaseDraggableCardProps {
  badgeText?: string;
  description?: string;
  drag?: ConnectDragSource;
  iconUrl?: string;
  isActive?: boolean;
  label?: React.ReactNode;
  opacity?: number;
  stepType: StepTypes;
  tooltipContent?: React.ReactNode;
  width?: string;
}

const BaseDraggableCard = ({
  badgeText = '',
  children,
  description,
  drag,
  iconUrl,
  isActive,
  label = '',
  opacity = 1,
  stepType,
  tooltipContent,
  width,
}: React.PropsWithChildren<BaseDraggableCardProps>) => {
  const { palette } = useTheme();

  const content = (
    <Box flex={1}>
      <Box alignItems='center' display='flex' flex={1}>
        <Box
          flexGrow={1}
          sx={{
            '& a': {
              color: 'inherit',
            },
          }}
        >
          <Typography variant='font14Bold'>{label}</Typography>
        </Box>
        {isActive !== undefined && (
          <ActiveIndicator isActive={isActive} margin='0 0 0 8px' />
        )}
        {tooltipContent && (
          <Tooltip tooltipContent={tooltipContent}>
            <IconInfoCircle size={20} />
          </Tooltip>
        )}
      </Box>
      {description && (
        <Box display='flex' mt='2px'>
          <Typography color={palette.colors.grey[600]} variant='font12'>
            {description}
          </Typography>
        </Box>
      )}
      {children}
    </Box>
  );

  const icon = iconUrl ? (
    <img alt={stepType} height='32px' src={iconUrl} width='32px' />
  ) : (
    <CircularStepIcon stepType={stepType} />
  );

  return (
    <Box
      alignItems='center'
      bgcolor={palette.common.white}
      border={`1px solid ${palette.colors.slate[200]}`}
      borderRadius='8px'
      display='flex'
      p='16px 16px 16px 8px'
      ref={drag}
      sx={{
        '&:hover': {
          borderColor: palette.colors.grey[300],
        },
        cursor: drag ? 'grab' : 'not-allowed',
        opacity: drag ? opacity : 0.3,
        transform: 'translate(0, 0)',
      }}
      width={width}
    >
      <Box alignItems='center' display='flex' flex={1}>
        <Box alignItems='center' display='flex' mr={0.5}>
          <IconGripVertical color={palette.colors.grey[300]} size={20} />
        </Box>
        {badgeText ? (
          <Box width='100%'>
            <Box
              display='flex'
              justifyContent='space-between'
              marginBottom='4px'
            >
              {icon}
              <Box>
                {badgeText && <Badge label={badgeText} variant='macro' />}
              </Box>
            </Box>
            {content}
          </Box>
        ) : (
          <>
            {icon}
            {content}
          </>
        )}
      </Box>
    </Box>
  );
};

export default BaseDraggableCard;
