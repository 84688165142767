import { NavigateFunction } from 'react-router';

interface ParamObject {
  property: string;
  value: string;
}

export const handleUpdateOnQueryParam = (
  navigate: NavigateFunction,
  param: ParamObject,
) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(param.property, param.value);
  navigate(
    {
      pathname: location.pathname,
      search: searchParams.toString(),
    },
    { replace: true },
  );
};

export const handleRemoveOnQueryParam = (
  navigate: NavigateFunction,
  removeProperties: string[],
) => {
  const searchParams = new URLSearchParams(location.search);
  removeProperties.forEach(property => {
    if (searchParams.has(property)) {
      searchParams.delete(property);
    }
  });
  navigate(
    {
      pathname: location.pathname,
      search: searchParams.toString(),
    },
    { replace: true },
  );
};
