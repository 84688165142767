import React from 'react';
import Box from '@mui/material/Box';

export const WorkflowBuilderBanner = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width?: string | number;
}) => {
  return (
    <Box
      border={theme => '1px solid ' + theme.palette.colors.purple[500]}
      borderRadius='4px'
      mb={3}
      px={2}
      py={1.5}
      width={width}
    >
      {children}
    </Box>
  );
};
