import { Link } from 'react-router-dom';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function CreatePasswordError() {
  return (
    <div>
      <div>
        <Typography variant='font16Bold'>
          Oops, it looks like your magic link is no longer valid.
        </Typography>
      </div>
      <div>
        <Typography variant='font14'>
          Please reach out to your account administrator to request a new
          invitation.
        </Typography>
      </div>
      <div>
        <Link to='/'>Return to sign in</Link>
      </div>
    </div>
  );
}
