import { useGetTicketCustomFieldsQuery } from 'src/services/email-automation/emailAutomationApi';

export default function useHelpdeskTicketFields() {
  const { data: fieldsResponse, ...rest } = useGetTicketCustomFieldsQuery();

  const {
    custom_fields: customFields = [],
    mapped_fields: mappedFields = [],
    static_fields: staticFields = [],
  } = fieldsResponse || {};

  const textTypeCustomFields = customFields.filter(
    customField => customField.type === 'text',
  );

  return {
    mappedFields,
    staticFields,
    textTypeCustomFields,
    ...rest,
  };
}
