import './login.scss';

import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik } from 'formik';
import queryString, { ParsedQuery } from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  PasswordField,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import omit from 'lodash/fp/omit';
import LandingPageLayout from 'src/components/reusable-components/landing-page-layout';
import { Routes } from 'src/utils/enums';
import { hasErrorMessage } from 'src/utils/typeGuards';

const title = 'Sign in to Forethought';
const buttonText = 'Sign in';
const ssoButtonText = 'Sign in with your identity provider';
const genericErrorMessage = 'Something went wrong. Please try again.';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('This field is required')
    .min(12, 'Password must have a minimum length of 12 characters'),
});

interface LoginFormState {
  email: string;
  password: string;
}

interface LoginProps {
  isSSOEnabled: boolean;
  isSSOEnforced: boolean;
}

export default function Login({ isSSOEnabled, isSSOEnforced }: LoginProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { palette } = useTheme();
  const [errorMessage, setErrorMessage] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [initialHashParams, setInitialHashParams] =
    useState<ParsedQuery<string> | null>(null);

  useEffect(() => {
    const hashParams = queryString.parse(location.hash);

    if (hashParams.username) {
      setInitialHashParams(hashParams);
      // remove username and newUserPrompt params
      const redirectHashParams = omit(
        ['username', 'newUserPrompt'],
        hashParams,
      );
      navigate(
        {
          ...location,
          hash: queryString.stringify(redirectHashParams, { encode: false }),
        },
        { replace: true },
      );
    }
    setInitialLoading(false);
  }, [navigate, location]);

  const handleSubmit = async ({ email, password }: LoginFormState) => {
    setIsLoading(true);
    try {
      await Auth.signIn(email, password);
    } catch (error) {
      let message = genericErrorMessage;
      if (hasErrorMessage(error)) {
        message = error.message;
      }
      setErrorMessage(message);
      setIsLoading(false);
    }
  };

  const { newUserPrompt, username } = initialHashParams || {};

  const initialValues: LoginFormState = {
    email: typeof username === 'string' ? username : '',
    password: '',
  };

  return (
    <LandingPageLayout testId='login-container'>
      <Container>
        {!initialLoading && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ errors, handleChange, handleSubmit, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Box mb='16px'>
                    <Typography variant='font32'>{title}</Typography>
                    {newUserPrompt && username && (
                      <Box mt='16px' textAlign='center'>
                        <Typography variant='font16'>
                          We’ve sent your temporary password to{' '}
                          <b>{username}</b>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {!isSSOEnforced && (
                    <>
                      <TextField
                        aria-label='Email'
                        name='email'
                        onChange={(...args) => {
                          setErrorMessage('');
                          handleChange(...args);
                        }}
                        placeholder='Enter your email'
                        type='text'
                        value={values.email}
                      />
                      <PasswordField
                        aria-label='Password'
                        error={errors.password}
                        name='password'
                        onChange={(...args) => {
                          setErrorMessage('');
                          handleChange(...args);
                        }}
                        placeholder='Password'
                        type='password'
                        value={values.password}
                      />

                      <ButtonContainer>
                        {errorMessage && (
                          <Box>
                            <Typography
                              color={palette.colors.red[500]}
                              variant='font16'
                            >
                              {errorMessage}
                            </Typography>
                          </Box>
                        )}
                        <Button
                          fullWidth
                          isLoading={isLoading}
                          size='large'
                          type='submit'
                          variant='main'
                        >
                          {buttonText}
                        </Button>
                        <Button
                          onClick={() => navigate(Routes.FORGOT_PASSWORD)}
                          size='large'
                          variant='ghost'
                        >
                          Forgot password?
                        </Button>
                      </ButtonContainer>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
        {isSSOEnabled && (
          <>
            <div className='option-container'>
              {!isSSOEnforced && (
                <>
                  <div className='line' />
                  or <div className='line' />
                </>
              )}
            </div>
            <Button
              onClick={() => navigate(Routes.SINGLE_SIGN_ON)}
              size='large'
              variant='main'
            >
              {ssoButtonText}
            </Button>
          </>
        )}
      </Container>
    </LandingPageLayout>
  );
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Form = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
