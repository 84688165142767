import { Box } from '@mui/material';

import GeneratedResponsesLoadingLabel from '../generated-responses-loading-label/GeneratedResponsesLabel';
import ResponseGenerationCardLoadingSkeleton from '../response-generation-card-loading-skeleton/ResponseGenerationCardLoadingSkeleton';

const GeneratedResponsesLoadingState = () => {
  return (
    <Box display='flex' flexDirection='column' gap='22px'>
      <GeneratedResponsesLoadingLabel
        addLoadingIndicator
        label='Generating responses for you'
      />
      <Box
        data-testid='response-generation-loading-skeleton-container'
        display='flex'
        flexDirection='column'
        gap='15px'
      >
        <ResponseGenerationCardLoadingSkeleton />
        <ResponseGenerationCardLoadingSkeleton />
        <ResponseGenerationCardLoadingSkeleton />
      </Box>
    </Box>
  );
};

export default GeneratedResponsesLoadingState;
