import { formatDate } from 'src/utils/analyticsUtils';
import { formatNum } from 'src/utils/analyticsUtils';
import { cleanStr } from 'src/utils/cleanStr';

interface HoverData {
  [key: string]: number;
}

const excludedVal = ['y', 'x', 'timestamp', 'name'];

export const oneValueTooltip = (
  tooltipTitle: string,
  timeframeFilter: string,
  timestamp: number,
  hoverData: HoverData,
  valueTypes: {},
  color?: string,
): string => {
  return `
    <div class="one-val-tt-cont">
      <div class=${
        color ? 'tt-heading' : 'tooltip-heading'
      } style="background-color:${color};">
        <p>${cleanStr(tooltipTitle)}</p>
        <p class="tooltip-date">${formatDate(timeframeFilter, timestamp)}</p>
      </div>
      <div class=tooltip-inner>
        <p class=one-value tooltip-value>${formatNum(
          tooltipTitle,
          hoverData.y,
          valueTypes,
        )}</p>
      </div>
    </div>`;
};

export const multipleValueTooltip = (
  tooltipTitle: string,
  timeframeFilter: string,
  timestamp: number,
  hoverData: HoverData,
  valueTypes: {},
  color?: string,
  breakdownValue?: string,
): string => {
  const title =
    breakdownValue === 'aggregate' || breakdownValue === undefined
      ? cleanStr(tooltipTitle)
      : breakdownValue;
  return `<div class="three-val-tt-cont">
      <div class=${color ? 'tt-heading' : 'aqua-tooltip-heading'} 
      style="background-color:${color};">
  <p>${title}</p>
  <p class="tooltip-date">${formatDate(timeframeFilter, timestamp)}</p>
      </div >
  <div class="tooltip-inner">
    ${Object.keys(hoverData)
      .map((value: string) => {
        if (!excludedVal.some(word => value === word)) {
          return `
          <div class="tooltip-data-column">
            <p class="chart-tooltip-title">${cleanStr(value)}</p>
            <p class="tooltip-value">${formatNum(
              value,
              hoverData[value],
              valueTypes,
              true,
            )}</p>
          </div>`;
        }
      })
      .join('')}`;
};
