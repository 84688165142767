import React from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ActionOutline } from './outline-chats/ActionOutline';
import ArticleOutline from './outline-chats/ArticleOutline';
import { ArticleSuggestionStepOutline } from './outline-chats/ArticleSuggestionStepOutline';
import { BaseOutline } from './outline-chats/BaseOutline';
import { ActionStep } from './workflow-builder/revamped';
import { type RichTextEditorProps } from 'src/components/rich-text-editor/types';
import SqueezeStepHoverState from 'src/components/squeeze-step-hover-state';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import GoToIntentOutline from 'src/pages/workflow-builder-edit/outline-chats/GoToIntentOutline';
//Selectors
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { selectIsEditingCondition } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectAddingAction,
  selectIsSqueezingStep,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { actionTypesNotTreatedAsActions } from 'src/utils/solve/stepUtils';

export interface ChatBoundaryProps {
  editorRef: RichTextEditorProps['editorRef'];
  hasWorkflowConflict: boolean;
  isDragAboveMiddleOfStepElement?: boolean;
  isOver: boolean;
  isPositionedFirst?: boolean;
  isStaticComponent?: boolean;
  isStepOver?: boolean;
  label?: string;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
  type:
    | 'message'
    | 'action'
    | 'adding-action'
    | 'condition'
    | 'squeeze-button'
    | 'go-to-intent'
    | 'custom-handoff'
    | 'article-picker'
    | 'empty'
    | 'paste'
    | 'empty-and-paste'
    | 'hidden';
}

const ChatBoundary: React.FC<React.PropsWithChildren<ChatBoundaryProps>> = ({
  editorRef,
  hasWorkflowConflict,
  isDragAboveMiddleOfStepElement = false,
  isOver,
  isPositionedFirst = false,
  isStepOver,
  label,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
  type,
}) => {
  const isMessage = type === 'message';
  const isAction = type === 'action';
  const isAddingAction = type === 'adding-action';
  const isCondition = type === 'condition';
  const isGoToStep = type === 'go-to-intent';
  const isCustomHandoff = type === 'custom-handoff';
  const isSqueezeButton = type === 'squeeze-button';
  const isArticlePicker = type === 'article-picker';
  const isEmpty = type === 'empty' || type === 'empty-and-paste';
  const isPasteButton = type === 'paste' || type === 'empty-and-paste';

  const isSqueezingStep = useSelector(selectIsSqueezingStep);

  const isSqueezingAction = isAction && isSqueezingStep;
  const isSqueezingArticle = isArticlePicker && isSqueezingStep;

  const isEditingCondition = useSelector(selectIsEditingCondition);
  const addingAction = useSelector(selectAddingAction);
  const userCanSqueezeStep = useSelector(selectUserCan('squeeze_step'));

  const { contextVariables } = useGetContextVariables();

  const getActionComponent = () => {
    if (isAddingAction && !hasWorkflowConflict) {
      if (addingAction) {
        if (
          actionTypesNotTreatedAsActions.includes(
            addingAction.action.action_type,
          )
        ) {
          return <ArticleSuggestionStepOutline />;
        }
        return (
          <ActionStep
            action={addingAction?.action}
            contextVariables={contextVariables}
            isBeingEdited
          />
        );
      }
    }

    return null;
  };

  return (
    <div aria-label={label ?? 'chat boundary'} role='group'>
      {/*  Show empty outline when user is squeezing a step */}
      {(isMessage || (isCondition && !isEditingCondition)) && (
        <BaseOutline
          isEntryStep={isPositionedFirst}
          shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
          squeezeStepParentId={squeezeStepParentId}
        />
      )}
      {isArticlePicker && (
        <ArticleOutline
          isOver={isOver}
          isPositionedFirst={isPositionedFirst}
          isSqueezingMode={isSqueezingArticle}
        />
      )}
      {/* Show actions squeeze step state */}
      {isAction && !hasWorkflowConflict && (
        <ActionOutline
          isOver={isOver}
          isPositionedFirst={isPositionedFirst}
          isSqueezingMode={isSqueezingAction}
        >
          <Typography variant='font14'>Drop action here</Typography>
        </ActionOutline>
      )}
      {/* Action outline when adding action */}
      {getActionComponent()}
      {/*  Show squeeze step hover state */}
      {(isSqueezeButton || isPasteButton || isStepOver) &&
        userCanSqueezeStep && (
          <SqueezeStepHoverState
            editorRef={editorRef}
            isDragAboveMiddleOfStepElement={isDragAboveMiddleOfStepElement}
            isEntryStep={isPositionedFirst}
            isPasteButton={isPasteButton}
            isSqueezeButton={isSqueezeButton}
            isStepOver={isStepOver}
          />
        )}
      {/* Show outline for go to step */}
      {isGoToStep && <GoToIntentOutline isOver={isOver} />}
      {isCustomHandoff && (
        <GoToIntentOutline isCustomHandoffIntent isOver={isOver} />
      )}
      {/* Show empty outline */}
      {isEmpty && (
        <BaseOutline
          isPositionedLast
          shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
          squeezeStepParentId={squeezeStepParentId}
        />
      )}
    </div>
  );
};

export default ChatBoundary;
