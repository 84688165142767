import React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import emptyImageIcon from 'src/assets/images/empty-image-icon.svg';

interface SafeImageProps {
  alt: string;
  fallbackAlt?: string;
  fallbackSrc?: string;
  src: string;
  sx?: SxProps<Theme>;
}

export const SafeImage: React.FC<React.PropsWithChildren<SafeImageProps>> = ({
  alt,
  fallbackAlt = 'empty image icon',
  fallbackSrc = emptyImageIcon,
  src,
  sx = {},
}) => {
  const imageRef = React.useRef<HTMLImageElement>(null);
  const [tooltip, setTooltip] = React.useState(alt);

  React.useEffect(() => {
    if (tooltip !== fallbackAlt) {
      setTooltip(alt);
    }
  }, [alt, fallbackAlt, tooltip]);

  return (
    <Tooltip tooltipContent={tooltip}>
      <Box
        component='img'
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = fallbackSrc;
        }}
        onLoad={({ currentTarget }) => {
          if (currentTarget.src.endsWith(fallbackSrc)) {
            currentTarget.alt = fallbackAlt;
            setTooltip(fallbackAlt);
          } else {
            currentTarget.alt = alt;
            setTooltip(alt);
          }
        }}
        ref={imageRef}
        src={src}
        sx={{ ...sx }}
      />
    </Tooltip>
  );
};
