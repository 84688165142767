import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { ForethoughtEditStepMenu } from './edit-menu/ForethoughtEditStepMenu';
import StepContainer from './workflow-builder/revamped/StepContainer';
import StepHeader from './workflow-builder/revamped/StepHeader';
import UndefinedContextVariablesTooltip from './UndefinedContextVariablesTooltip';
import SafeImage from 'src/components/reusable-components/safe-image';
import { selectEditingStepId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { ImageStepFields, Step } from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';

interface Props {
  contextVariables: ContextVariable[];
  isCanvasDisabled: boolean;
  setShouldShowEditMenu: (shouldShow: boolean) => void;
  shouldShowEditMenu: boolean;
  step: Step;
  stepId: string;
}

export const ImageStep: React.FC<React.PropsWithChildren<Props>> = ({
  contextVariables,
  isCanvasDisabled,
  setShouldShowEditMenu,
  shouldShowEditMenu,
  step,
  stepId,
}) => {
  const stepFields = step.step_fields as ImageStepFields;
  const hasCondition = !!step.condition_name;

  const editingStepId = useSelector(selectEditingStepId);

  return (
    <StepContainer isBeingEdited={editingStepId === stepId}>
      <Box sx={{ display: 'flex' }}>
        <StepHeader stepType={StepTypes.IMAGE} />
        <UndefinedContextVariablesTooltip
          contextVariables={contextVariables}
          stepId={stepId}
        />
      </Box>
      {stepFields.images.map(image => {
        return (
          <Box
            key={image.url}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <SafeImage
              alt={image.description}
              fallbackAlt={
                'Image cannot be found, most likely due to context variables being part of the image url'
              }
              src={image.url}
              sx={{
                borderRadius: '8px',
                display: 'block',
                flexBasis: '100%',
                height: 'initial',
                maxHeight: '291px',
                maxWidth: '100%',
                objectFit: 'scale-down',
              }}
            />
          </Box>
        );
      })}
      {!isCanvasDisabled && (
        <ForethoughtEditStepMenu
          hasCondition={hasCondition}
          isDeletionDisabled={hasCondition}
          isVisible={shouldShowEditMenu}
          setIsVisible={setShouldShowEditMenu}
          stepId={stepId}
          stepType={step.step_type}
        />
      )}
    </StepContainer>
  );
};
