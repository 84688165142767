import { SOLVE_EMAIL_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { StaticWorkflowData } from 'src/pages/workflow-builder-edit/types';

const emailWorkflowApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getEmailWorkflowStaticData: builder.query<StaticWorkflowData, void>({
      keepUnusedDataFor: 0,
      query: () => `${SOLVE_EMAIL_BASE_URL}/static-workflow-data`,
    }),
  }),
});

export const { useGetEmailWorkflowStaticDataQuery } = emailWorkflowApi;
