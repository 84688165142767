import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UndefinedContextVariablesTooltip from '../../UndefinedContextVariablesTooltip';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import TextBox from './TextBox';
import { selectEditingStepId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetContextVariablesQuery } from 'src/services/dashboard-api';
import { AttachmentAnalysisUploadStepFields } from 'src/slices/canvas-workflow-builder/types/dataTypes';
import { selectEditingStepFields } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { StepTypes } from 'src/utils/enums';
import { isAttachmentUploadAnalysisStepFields } from 'src/utils/solve/stepUtils';

interface AttachmentAnalysisUploadStepProps {
  fields?: AttachmentAnalysisUploadStepFields;
  stepId?: string;
  undefinedContextVariableIds?: string[];
}

const AttachmentAnalysisUploadStep = ({
  fields,
  stepId,
  undefinedContextVariableIds = [],
}: AttachmentAnalysisUploadStepProps) => {
  const editingFields = useSelector(selectEditingStepFields);
  const editingStepId = useSelector(selectEditingStepId);
  const { data } = useGetContextVariablesQuery();
  const { context_variables: contextVariables = [] } = data ?? {};
  const isBeingEdited = editingStepId === stepId || !stepId;
  const toShow = isBeingEdited ? editingFields : fields;

  if (!toShow || !isAttachmentUploadAnalysisStepFields(toShow)) {
    return null;
  }

  return (
    <StepContainer isBeingEdited={isBeingEdited}>
      <StepHeader stepType={StepTypes.ATTACHMENT_ANALYSIS_UPLOAD}>
        <Box ml='auto' mr={2}>
          <UndefinedContextVariablesTooltip
            contextVariables={contextVariables}
            stepId={stepId ?? ''}
          />
        </Box>
      </StepHeader>
      <Box display='flex' flexDirection='column' gap={1}>
        <TextBox
          isRichText
          placeholder='Empty message'
          undefinedContextVariableIds={undefinedContextVariableIds}
          value={toShow.prompt}
          variant='dark'
        />
        <Typography
          color={theme => theme.palette.colors.grey[500]}
          variant='font14'
        >
          Maximum number of files to upload:{' '}
          <Typography
            color={theme => theme.palette.colors.grey[700]}
            variant='font14'
          >
            {toShow.num_files}
          </Typography>
        </Typography>
        {toShow.output_variable && (
          <Typography
            color={theme => theme.palette.colors.grey[500]}
            variant='font14'
          >
            Attachment output:{' '}
            <Typography
              color={theme => theme.palette.primary.main}
              variant='font14'
            >
              $
              {
                contextVariables.find(
                  cv => cv.context_variable_id === toShow?.output_variable,
                )?.context_variable_name
              }
            </Typography>
          </Typography>
        )}
      </Box>
    </StepContainer>
  );
};

export default AttachmentAnalysisUploadStep;
