import React, { FC } from 'react';
import { useField } from 'formik';
import { MentionsInputProps } from 'react-mentions';
import { StandardTextFieldProps } from '@mui/material/TextField';

import { TextField } from '@forethought-technologies/forethought-elements';
import * as Styled from './styles';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { ContextVariableListItems } from 'src/services/apiInterfaces';

interface FormikInputProps extends StandardTextFieldProps {
  label: string;
  name: string;
  onChangeDispatch?: (value: string) => void;
  shouldHideLabel?: boolean;
}

interface MentionsFormikInputProps
  extends Omit<MentionsInputProps, 'children'> {
  contextVariables: ContextVariableListItems[];
  label: string;
  name: string;
}

export const ActionTemplateFormikInput: FC<
  React.PropsWithChildren<FormikInputProps>
> = ({ label, name, onChangeDispatch, shouldHideLabel, ...props }) => {
  const [field, meta, { setTouched, setValue }] = useField({ name });

  return (
    <Styled.InputContainer>
      <TextField
        aria-label={label}
        error={meta.touched && !!meta.error}
        label={shouldHideLabel ? undefined : label}
        name={name}
        onChange={e => {
          setValue(e.target.value);

          if (onChangeDispatch) {
            onChangeDispatch(e.target.value);
          }
        }}
        onFocus={() => setTouched(true)}
        placeholder={label}
        value={field.value}
        {...props}
      />
    </Styled.InputContainer>
  );
};

export const ActionTemplateMentionsFormikInput: FC<
  React.PropsWithChildren<MentionsFormikInputProps>
> = ({ label, name }) => {
  const [field, meta, { setTouched, setValue }] = useField({ name });

  return (
    <>
      <ContextVariableAutocomplete
        aria-label={label}
        error={meta.touched && !!meta.error}
        label={label}
        onChange={value => {
          setValue(value);
        }}
        onFocus={() => setTouched(true)}
        placeholder={label}
        value={field.value ?? ''}
      />
    </>
  );
};
