import React from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import { CanvasModes } from './constants';
import ForethoughtAction from './ForethoughtAction';
import {
  CancelButton,
  CancelButtonWrapper,
  Separator,
} from './ForethoughtCanvasRight';
import actionIcon from 'src/assets/images/action-2.svg';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { type ForethoughtActionsList } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectIsSqueezingStep,
  setIsSqueezingStep,
  setMode,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  actionTooltips,
  actionTypesNotTreatedAsActions,
  nonSqueezingActions,
} from 'src/utils/solve/stepUtils';

const ForethoughtActionsList: React.FC<
  React.PropsWithChildren<ForethoughtActionsList>
> = ({
  actions,
  hasWorkflowConflict,
  setActionPanelVisibilityParameters,
  squeezeStepParentId,
}) => {
  const isSqueezingStep = useSelector(selectIsSqueezingStep);

  const { contextVariables } = useGetContextVariables();

  const dispatch = useAppDispatch();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isGranularizedZendeskActionsEnabled = featureFlags.includes(
    'granularized_zendesk_actions',
  );
  const isGranularizedSalesforceActionsEnabled = featureFlags.includes(
    'granularized_salesforce_actions',
  );

  const validActions = actions.filter(myAction => {
    if (actionTypesNotTreatedAsActions.includes(myAction.action_type)) {
      return false;
    }
    return true;
  });

  const actionsList = isSqueezingStep
    ? validActions.filter(
        action => !nonSqueezingActions.includes(action.action_type),
      )
    : validActions;

  return (
    <ActionsList>
      <Header>
        <ActionSvg height={20} marginRight={14} width={14}>
          <ReactSVG src={actionIcon} />
        </ActionSvg>
        Actions
      </Header>
      <ActionContainer>
        {actionsList.map((action, index) => (
          <ForethoughtAction
            action={action}
            disabled={false}
            hasWorkflowConflict={hasWorkflowConflict}
            key={index}
            setActionPanelVisibilityParameters={
              setActionPanelVisibilityParameters
            }
            squeezeStepParentId={squeezeStepParentId}
            tooltip={
              isGranularizedZendeskActionsEnabled ||
              isGranularizedSalesforceActionsEnabled
                ? actionTooltips[action.action_type]
                : ''
            }
          />
        ))}
      </ActionContainer>
      <Separator />
      <CancelButtonWrapper>
        <CancelButton
          onClick={() => {
            dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
            dispatch(setIsSqueezingStep(false));
          }}
          type='button'
        >
          Cancel
        </CancelButton>
      </CancelButtonWrapper>
    </ActionsList>
  );
};

export default ForethoughtActionsList;

const ActionsList = styled('div')`
  font-weight: var(--font-weight-regular);
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ActionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
`;

export const ActionSvg = styled('div')<{
  height: number;
  marginRight: number;
  width: number;
}>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  display: inline-block;
  vertical-align: middle;
  margin-right: ${props => props.marginRight}px;
`;

const Header = styled('h2')`
  font-weight: var(--font-weight-medium);
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px;

  & svg {
    transform: translateY(-4px);
  }
`;
