import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

import {
  Badge,
  elevations,
  theme,
} from '@forethought-technologies/forethought-elements';
import { getIsModelPremium } from '../triage-config-detail-page/helpers';
import { getTriageModelsPageUrl } from './helpers';
import { TriageModelDetail } from 'src/reducers/triageSettingsReducer/types';

interface TriageModelCardProps {
  triageModel: TriageModelDetail;
}

const TriageModelCard: React.FC<
  React.PropsWithChildren<TriageModelCardProps>
> = props => {
  const {
    description,
    display_name,
    icon,
    is_self_serve,
    model_id,
    model_paid_plan,
    name,
    status,
  } = props.triageModel;
  const isSelfServe = Boolean(is_self_serve);
  const isActive = status === 'enabled';
  const showPremiumBadge = getIsModelPremium(model_paid_plan);
  const destination =
    isSelfServe && model_id
      ? getTriageModelsPageUrl(model_id)
      : `/triage-settings/${name}`;
  return (
    <CardContainer
      data-testid='triage-container'
      id={`configure-${name}`}
      key={name}
      to={destination}
    >
      <CardContentContainer>
        <CardContentSubContainer>
          <CardIconContainer>
            <Icon size={36} src={icon} />
            {isSelfServe && <Badge label='Custom' variant='macro' />}
            {!isSelfServe && showPremiumBadge && (
              <Badge label='Premium' variant='macro' />
            )}
          </CardIconContainer>
          <CardTitleText>{display_name}</CardTitleText>
          <CardContentText>{description}</CardContentText>
        </CardContentSubContainer>
      </CardContentContainer>
      <CardSubContainer>
        <CardStatusContainer>
          <StatusIcon isEnabled={status === 'enabled'} />
          <CardStatusText isActive={isActive}>
            {isActive ? 'Active' : 'Inactive'}
          </CardStatusText>
        </CardStatusContainer>
      </CardSubContainer>
    </CardContainer>
  );
};

const CardContainer = styled(Link)`
  border-style: solid;
  border-width: 1px;
  border-color: ${theme.palette.colors.slate[200]};
  border-radius: 6.66px;
  cursor: pointer;
  &:hover {
    box-shadow: ${elevations.z2};
  }
  text-decoration: none;
`;

const CardContentContainer = styled('div')`
  height: 184px;
`;

const CardContentSubContainer = styled('div')`
  padding: 28px 24px 42px 24px;
`;

const CardIconContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const CardSubContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  max-height: 38px;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: ${theme.palette.colors.slate[200]};
  text-align: right;
`;

const CardStatusContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
`;

const CardStatusText = styled('p')<{ isActive: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${props =>
    props.isActive
      ? theme.palette.colors.black
      : theme.palette.colors.grey[400]};
  padding-left: 8px;
`;

const CardTitleText = styled('p')`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${theme.palette.colors.black};
  margin: 0px;
`;

const CardContentText = styled('p')`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0px;
  padding-top: 5px;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.palette.colors.grey[700]};
`;

const StatusIcon = styled('div')<{ isEnabled: boolean }>`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${props =>
    props.isEnabled
      ? theme.palette.colors.green[500]
      : theme.palette.colors.grey[400]};
`;

const Icon = styled('img')<{
  size: number;
}>`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`;

export default TriageModelCard;
