import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import { FieldNameToIdx } from '../constants';
import {
  formatContextVariableForDisplay,
  formatContextVariableForPersistence,
} from '../helpers';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  Label,
  SectionContainer,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import AllowAttachmentUpload from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/AllowAttachmentUpload';
import AutoCreateTicketRadioButton from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/AutoCreateTicketRadioButton';
import ConfirmationMessage from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/ConfirmationMessage';
import CustomMessage from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/CustomMessage';
import CustomMessageRadioButton from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/CustomMessageRadioButton';
import HandoffFormQuestionMessage from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/HandoffFormQuestionMessage';
import TicketBrand from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/TicketBrand';
import TicketCustomFields from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/TicketCustomFields';
import TicketTitle from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/TicketTitle';
import TagInputWithContextVariables from 'src/pages/workflow-builder-edit/tag-input-with-context-variables/TagInputWithContextVariables';
import {
  ZendeskCommonCustomization,
  ZendeskCustomFieldAndValue,
  ZendeskHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';

interface TicketCreationProps {
  customizationData: ZendeskHandoffCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  isFromCanvas: boolean;
  setCustomizationData: Dispatch<SetStateAction<ZendeskHandoffCustomization>>;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const TicketCreation: FC<React.PropsWithChildren<TicketCreationProps>> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  isFromCanvas,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const handleBlurOnTicketTags = () => {
    const arrCopy = [...isFieldTouchedArr];
    arrCopy[FieldNameToIdx.TICKET_TAGS] = true;
    setIsFieldTouchedArr(arrCopy);
  };

  const handleTagAddition = (tag: string) => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        ticket_creation_settings: {
          ...handoffData.ticket_creation_settings,
          ticket_creation_config: {
            ...customizationData.ticket_creation_settings
              ?.ticket_creation_config,
            ticket_tags: [
              ...handoffData.ticket_creation_settings?.ticket_creation_config
                ?.ticket_tags,
              formatContextVariableForPersistence(contextVariables, tag),
            ],
          },
        },
      };
    });
  };

  const handleTagDeletion = (idx: number) => {
    setCustomizationData(handoffData => {
      const ticketTags =
        handoffData.ticket_creation_settings?.ticket_creation_config
          ?.ticket_tags || [];
      return {
        ...handoffData,
        ticket_creation_settings: {
          ...handoffData.ticket_creation_settings,
          ticket_creation_config: {
            ...customizationData.ticket_creation_settings
              ?.ticket_creation_config,
            ticket_tags: ticketTags
              .slice(0, idx)
              .concat(ticketTags.slice(idx + 1, ticketTags.length)),
          },
        },
      };
    });
  };

  const handleUpdateCustomFieldsAndValues = (
    updatedVal: ZendeskCustomFieldAndValue[],
  ) => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        ticket_creation_settings: {
          ...handoffData.ticket_creation_settings,
          ticket_creation_config: {
            ...customizationData.ticket_creation_settings
              ?.ticket_creation_config,
            custom_fields_and_values: updatedVal,
          },
        },
      };
    });
  };

  const tags = (
    customizationData?.ticket_creation_settings?.ticket_creation_config
      ?.ticket_tags || []
  ).map(tag => formatContextVariableForDisplay(contextVariables, tag));

  return (
    <Wrapper>
      <AutoCreateTicketRadioButton
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
      <Spacer height='32px' />
      {!!customizationData.ticket_creation_settings?.create_ticket && (
        <SectionContainer>
          <HandoffFormQuestionMessage
            customizationData={customizationData}
            errorMessages={errorMessages}
            isFieldTouchedArr={isFieldTouchedArr}
            setCustomizationData={setCustomizationData}
            setIsFieldTouchedArr={setIsFieldTouchedArr}
          />
          <Spacer height='28px' />
          <ConfirmationMessage
            customizationData={customizationData}
            errorMessages={errorMessages}
            isFieldTouchedArr={isFieldTouchedArr}
            setCustomizationData={setCustomizationData}
            setIsFieldTouchedArr={setIsFieldTouchedArr}
          />
          <Spacer height='28px' />
          <AllowAttachmentUpload
            customizationData={customizationData}
            setCustomizationData={
              setCustomizationData as Dispatch<
                SetStateAction<ZendeskCommonCustomization>
              >
            }
          />
          <Spacer height='28px' />
          <TicketTitle
            contextVariables={isFromCanvas ? contextVariables : []}
            customizationData={customizationData}
            errorMessages={errorMessages}
            isFieldTouchedArr={isFieldTouchedArr}
            setCustomizationData={
              setCustomizationData as Dispatch<
                SetStateAction<ZendeskCommonCustomization>
              >
            }
            setIsFieldTouchedArr={setIsFieldTouchedArr}
          />
          <Spacer height='28px' />
          <TicketBrand
            customizationData={customizationData}
            setCustomizationData={
              setCustomizationData as Dispatch<
                SetStateAction<ZendeskCommonCustomization>
              >
            }
          />
          <Spacer height='28px' />
          <TagInputWithContextVariables
            contextVariables={isFromCanvas ? contextVariables : []}
            errorMessages={errorMessages}
            errorTag={FieldNameToIdx.TICKET_TAGS}
            handleBlur={handleBlurOnTicketTags}
            handleTagAddition={handleTagAddition}
            handleTagDeletion={handleTagDeletion}
            label='Ticket Tag'
            tags={tags}
          />
          <Spacer height='28px' />
          <Label>Ticket Status</Label>
          <Box sx={{ backgroundColor: theme.palette.colors.white }}>
            <SelectDropdown
              id='ticket-status-select'
              inputProps={{ 'aria-label': 'Ticket Status' }}
              onChange={e => {
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    ticket_creation_settings: {
                      ...handoffData.ticket_creation_settings,
                      ticket_creation_config: {
                        ...customizationData.ticket_creation_settings
                          ?.ticket_creation_config,
                        status: e.target.value,
                      },
                    },
                  };
                });
              }}
              onClear={() => {
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    ticket_creation_settings: {
                      ...handoffData.ticket_creation_settings,
                      ticket_creation_config: {
                        ...customizationData.ticket_creation_settings
                          ?.ticket_creation_config,
                        status: '',
                      },
                    },
                  };
                });
              }}
              options={
                customizationData.ticket_creation_settings
                  .ticket_creation_config.available_statuses
              }
              placeholder='Select a status'
              value={
                customizationData.ticket_creation_settings
                  .ticket_creation_config.status || ''
              }
            />
          </Box>
          <Spacer height='28px' />
          <TicketCustomFields
            customFieldsAndValues={
              customizationData.ticket_creation_settings.ticket_creation_config
                .custom_fields_and_values
            }
            setCustomFieldsAndValues={handleUpdateCustomFieldsAndValues}
            ticketCustomFields={
              customizationData.ticket_creation_settings.ticket_creation_config
                .ticket_custom_fields
            }
          />
          <Spacer height='60px' />
        </SectionContainer>
      )}
      <CustomMessageRadioButton
        customizationData={customizationData}
        setCustomizationData={setCustomizationData}
      />
      <Spacer height='20px' />
      {!customizationData.ticket_creation_settings?.create_ticket && (
        <SectionContainer>
          <CustomMessage
            errorMessages={errorMessages}
            initialValue={
              customizationData?.ticket_creation_settings?.custom_message || ''
            }
            isFieldTouchedArr={isFieldTouchedArr}
            setCustomizationData={setCustomizationData}
            setIsFieldTouchedArr={setIsFieldTouchedArr}
          />
        </SectionContainer>
      )}
      <Spacer height='100px' />
    </Wrapper>
  );
};

export default TicketCreation;

const Wrapper = styled('div')`
  padding-left: 30px;
`;
