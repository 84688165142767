import React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useNavigate } from 'react-router';
import { ReactSVG } from 'react-svg';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IconArrowAutofitUp, IconEye, IconFiles } from '@tabler/icons-react';

import {
  IconButton,
  MoreMenu,
  Skeleton,
} from '@forethought-technologies/forethought-elements';
import autonomousAgentBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import moreIcon from 'src/pages/workflow-builder-edit/assets/icons/more-icon.svg';
import {
  TEMPLATE_CLONE_TOAST_OPTIONS,
  TEMPLATE_INTENT_ID,
  TEMPLATE_INTENT_WORKFLOW_ID_PREFIX,
} from 'src/pages/workflow-builder-edit/constants';
import {
  useCustomizeAutoflowTemplateMutation,
  useGetAutoflowTemplatesQuery,
} from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { TemplateAutoflowIntentWorkflow } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';

export const TemplateTable = () => {
  const { data = [], isLoading } = useGetAutoflowTemplatesQuery();

  return (
    <>
      {isLoading
        ? Array(5)
            .fill(null)
            .map((_, index) => <LoadingRow key={index} />)
        : data.map(autoflowTemplate => (
            <Row
              {...autoflowTemplate}
              key={autoflowTemplate.intent_workflow_id}
            />
          ))}
    </>
  );
};

const menuOptions = [
  {
    label: 'Preview template',
    optionStartAdornment: <IconEye />,
    value: 'preview_template',
  },
  {
    label: 'Clone template',
    optionStartAdornment: <IconFiles />,
    value: 'clone_template',
  },
  {
    label: 'Open in a new tab',
    optionStartAdornment: <IconArrowAutofitUp />,
    value: 'new_tab',
  },
];

const Row = ({
  intent_workflow_id: intentWorkflowId,
  name,
  template_intent_phrases: templateIntentPhrases,
}: TemplateAutoflowIntentWorkflow) => {
  const [customizeAutoflowTemplate] = useCustomizeAutoflowTemplateMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseParams = new URLSearchParams({
    intentId: TEMPLATE_INTENT_ID,
    mode: 'autoflow',
    workflowId: intentWorkflowId,
  });

  const templateDetailUrl = `${Routes.WORKFLOW_BUILDER_EDIT}?${baseParams}`;

  return (
    <Container
      onClick={() => {
        navigate(templateDetailUrl);
      }}
    >
      <Box alignItems='center' display='flex' flex={1} gap='14px'>
        <img height={20} src={autonomousAgentBadgeSrc} width={20} />
        <Typography variant='font14'>{name}</Typography>
      </Box>
      <Box alignItems='center' display='flex' width='505px'>
        <Typography
          color={theme => theme.palette.colors.grey[600]}
          fontStyle='italic'
          noWrap
        >
          &rsquo;
          {templateIntentPhrases.join(', ')}
          &lsquo;
        </Typography>
      </Box>
      <Box
        alignItems='center'
        display='flex'
        flex={1}
        justifyContent='flex-end'
        onClick={e => e.stopPropagation()}
      >
        <MoreMenu
          onChange={async value => {
            switch (value) {
              case 'clone_template':
                const response = await customizeAutoflowTemplate(
                  intentWorkflowId,
                );

                if (!('data' in response)) {
                  return;
                }

                const { data } = response;
                const baseParams = new URLSearchParams({
                  mode: 'autoflow',
                  workflowId: data.intent_workflow_id,
                });

                navigate(`${Routes.WORKFLOW_BUILDER_EDIT}?${baseParams}`);

                dispatch(setGlobalToastOptions(TEMPLATE_CLONE_TOAST_OPTIONS));
                return;
              case 'preview_template':
                const previewPath = generatePath(
                  Routes.WORKFLOW_BUILDER_PREVIEW,
                  {
                    channel: 'widget',
                  },
                );

                navigate({
                  pathname: previewPath,
                  search: `?intentId=${
                    TEMPLATE_INTENT_WORKFLOW_ID_PREFIX + intentWorkflowId
                  }&mode=autoflow`,
                });
                return;
              case 'new_tab':
                window.open(templateDetailUrl);
                return;
            }
          }}
          options={menuOptions}
          renderAnchorEl={() => (
            <IconButton aria-label={`Menu ${name}`} variant='ghost'>
              <ReactSVG src={moreIcon} />
            </IconButton>
          )}
        />
      </Box>
    </Container>
  );
};

const LoadingRow = () => {
  return (
    <Container gap={10}>
      <Box flex={1}>
        <Skeleton />
      </Box>
      <Box flex={1}>
        <Skeleton />
      </Box>
      <Box flex={1}></Box>
    </Container>
  );
};

const Container = ({
  children,
  gap,
  onClick,
}: {
  children: React.ReactNode;
  gap?: string | number;
  onClick?: () => void;
}) => {
  return (
    <Box
      onClick={onClick}
      sx={theme => ({
        '&:hover': {
          backgroundColor: theme.palette.colors.slate[100],
        },
        '&:last-child': {
          borderBottom: '1px solid ' + theme.palette.colors.slate[200],
        },
        borderTop: '1px solid ' + theme.palette.colors.slate[200],
        cursor: onClick && 'pointer',
        display: 'flex',
        gap,
        p: 2,
      })}
    >
      {children}
    </Box>
  );
};
