import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import { Box } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Button,
  Dialog,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import EmailTranslationsTable from './EmailTranslationsTable';
import { translationChannelToConversationChannel } from './helpers';
import NoLanguageSelected from './NoLanguageSelected';
import WidgetTranslationsTable from './WidgetTranslationsTable';
import {
  getConfigurationTranslationsForOrg,
  getStepTranslationsForOrg,
  getStepTranslationsForStep,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import EditTranslationPhrasesIcon from 'src/assets/images/edit-translation-icon.svg';
import {
  selectDraftTranslations,
  selectIntentWorkflowId,
  selectIsConfigTranslationsLoading,
  selectIsStepTranslationsLoading,
  selectStepLevelTranslationsId,
  selectTranslationsTableMode,
  selectWorkflowBuilderLanguages,
  selectWorkflowBuilderLanguagesEnabled,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useLazyGetEmailTranslationsQuery } from 'src/services/solve-email-multilingual/solveEmailMultilingualApi';
import { selectEmailTranslations } from 'src/slices/email-translations/emailTranslationsSlice';
import { TranslationChannel } from 'src/types/workflowBuilderAPITypes';
import { TranslationsTableMode } from 'src/utils/enums';

interface EditTranslationPhrasesTabProps {
  isTranslationPhrasesUnchanged: boolean;
  selectedChannelLabel: TranslationChannel;
  selectedLanguage: string;
  setSelectedLanguage: (selectedLanguage: string) => void;
}

const EditTranslationPhrasesTab: React.FC<
  React.PropsWithChildren<EditTranslationPhrasesTabProps>
> = ({
  isTranslationPhrasesUnchanged,
  selectedChannelLabel,
  selectedLanguage,
  setSelectedLanguage,
}) => {
  const dispatch = useDispatch();
  const [getEmailTranslationsTrigger] = useLazyGetEmailTranslationsQuery();

  // Selectors
  const languages = useSelector(selectWorkflowBuilderLanguages);
  const languagesEnabledConfig = useSelector(
    selectWorkflowBuilderLanguagesEnabled,
  );
  const draftTranslations = useSelector(selectDraftTranslations); // translations that is being modified on FE
  const isConfigTranslationsLoading = useSelector(
    selectIsConfigTranslationsLoading,
  );
  const isStepTranslationsLoading = useSelector(
    selectIsStepTranslationsLoading,
  );
  const tableMode = useSelector(selectTranslationsTableMode);

  const stepLevelTranslationsId = useSelector(selectStepLevelTranslationsId);
  const currentWorkflowId = useSelector(selectIntentWorkflowId);
  const emailTranslations = useSelector(selectEmailTranslations);

  const isStepLevelMode = stepLevelTranslationsId !== '';
  const conversationChannel =
    translationChannelToConversationChannel(selectedChannelLabel);

  const isEmailChannel = conversationChannel === 'email';
  const isWidgetChannel = conversationChannel === 'widget';
  const shouldShowEmptyMessage =
    (isWidgetChannel && draftTranslations.length === 0) ||
    (isEmailChannel && emailTranslations.length === 0);

  const shouldShowTranslatingNewDraftMessage =
    (isWidgetChannel && draftTranslations.length !== 0) ||
    (isEmailChannel && emailTranslations.length !== 0);

  const emptyDraftStateMessage = `If you have just added or modified ${
    isEmailChannel ? 'email components' : 'steps'
  }`;

  const emptyLiveStateMessage = `If you have just published ${
    isEmailChannel ? 'an email' : 'a widget'
  } workflow`;

  const isDraftMode = tableMode === TranslationsTableMode.DRAFT;

  // States
  const [expanded, setExpanded] = useState(true);
  const [isTranslationsLoading, setIsTranslationsLoading] = useState(false);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [switchingToLanguage, setSwitchingToLanguage] = useState('');

  // options for the language dropdown in thrid column
  const languageOptions = useMemo(
    () =>
      languagesEnabledConfig.language_codes.map(code => {
        const displayName =
          languages.find(lan => lan.code === code)?.display_name || '';
        return {
          label: displayName,
          value: code,
        };
      }),
    [languagesEnabledConfig, languages],
  );

  useEffect(() => {
    setIsTranslationsLoading(
      isStepTranslationsLoading || isConfigTranslationsLoading,
    );
  }, [isStepTranslationsLoading, isConfigTranslationsLoading]);

  // handlers
  const handleWidgetChangeLanguage = (language_code: string) => {
    if (isStepLevelMode) {
      dispatch(
        getStepTranslationsForStep({
          intent_workflow_id: currentWorkflowId || '',
          language_code: language_code,
          step_id: stepLevelTranslationsId,
        }),
      );
    } else {
      if (tableMode === TranslationsTableMode.LIVE) {
        dispatch(
          getConfigurationTranslationsForOrg({
            language_code: language_code,
          }),
        );
      }
      dispatch(
        getStepTranslationsForOrg({
          language_code: language_code,
          mode: tableMode,
        }),
      );
    }
  };
  const handleEmailChangeLanguage = (languageCode: string) => {
    getEmailTranslationsTrigger({
      mode: tableMode,
      targetLanguage: languageCode,
    });
  };

  const handleChangeLanguage = (languageCode: string) => {
    setSelectedLanguage(languageCode);
    if (isWidgetChannel) {
      handleWidgetChangeLanguage(languageCode);
    } else if (isEmailChannel) {
      handleEmailChangeLanguage(languageCode);
    }
  };

  const getTable = () => {
    switch (selectedChannelLabel) {
      case 'email':
        return (
          <EmailTranslationsTable
            languageOptions={languageOptions}
            onSelectLanguageChange={language => {
              setSwitchingToLanguage(language);
              handleChangeLanguage(language);
            }}
            selectedLanguage={selectedLanguage}
          />
        );
      case 'widget':
        return (
          <WidgetTranslationsTable
            isTranslationsLoading={isTranslationsLoading}
            languageOptions={languageOptions}
            onSelectLanguageChange={language => {
              setSwitchingToLanguage(language);
              if (isTranslationPhrasesUnchanged) {
                handleChangeLanguage(language);
              } else {
                setIsWarningModalVisible(true);
              }
            }}
            selectedLanguage={selectedLanguage}
          />
        );
      case 'interactive_email':
        return (
          <WidgetTranslationsTable
            isTranslationsLoading={isTranslationsLoading}
            languageOptions={languageOptions}
            onSelectLanguageChange={language => {
              setSwitchingToLanguage(language);
              if (isTranslationPhrasesUnchanged) {
                handleChangeLanguage(language);
              } else {
                setIsWarningModalVisible(true);
              }
            }}
            product='interactive_email'
            selectedLanguage={selectedLanguage}
          />
        );
    }
  };

  // components
  const warningDialog = (
    <Dialog
      footer={
        <>
          <Button
            onClick={() => {
              setIsWarningModalVisible(false);
            }}
            variant='ghost'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsWarningModalVisible(false);
              handleChangeLanguage(switchingToLanguage);
            }}
            variant='secondary'
          >
            OK
          </Button>
        </>
      }
      hideCloseButton
      onClose={() => setIsWarningModalVisible(false)}
      open={isWarningModalVisible}
      title='Are you sure?'
    >
      <Typography variant='font14'>
        This will erase all edited translation phrases in the current language
      </Typography>
    </Dialog>
  );

  return (
    <CollapseWrapper expanded={expanded}>
      <CollapseHeader
        className='CollapseHeader'
        onClick={() => setExpanded(!expanded)}
      >
        <TranslateIconDisplay src={EditTranslationPhrasesIcon} />
        <SupportedLanguagesHeader>
          Edit auto-translated phrases
        </SupportedLanguagesHeader>
        <Tooltip tooltipContent='You can manually edit or revert to auto-translated phrases'>
          <IconInfoCircle height={16} width={16} />
        </Tooltip>
        {!!languagesEnabledConfig.language_codes.length &&
          shouldShowTranslatingNewDraftMessage &&
          isDraftMode && (
            <Typography
              color={theme.palette.colors.grey[500]}
              variant='font14Bold'
            >
              Translating new draft phrases may take a few seconds.
            </Typography>
          )}

        {expanded ? <ExpandedIcon /> : <ContractedIcon />}
      </CollapseHeader>
      {expanded && (
        <PhrasesContainer>
          {languagesEnabledConfig.language_codes.length === 0 ? (
            <NoLanguageSelected />
          ) : (
            getTable()
          )}
        </PhrasesContainer>
      )}
      {warningDialog}
      {!!languagesEnabledConfig.language_codes.length &&
        shouldShowEmptyMessage && (
          <Box
            display='flex'
            justifyContent='center'
            padding='40px'
            textAlign='center'
          >
            <Typography
              color={theme.palette.colors.grey[500]}
              variant='font14Bold'
            >
              {`${
                isDraftMode ? emptyDraftStateMessage : emptyLiveStateMessage
              }, we might be translating all the ${
                isDraftMode ? 'draft' : 'live'
              } records. `}
              <br />
              Please wait. This process may take a few seconds.
            </Typography>
          </Box>
        )}
    </CollapseWrapper>
  );
};

export default EditTranslationPhrasesTab;

const ExpandedIcon = styled(ExpandMoreIcon)`
  position: absolute;
  right: 20px;
`;

const ContractedIcon = styled(ExpandLessIcon)`
  position: absolute;
  right: 20px;
`;

const CollapseWrapper = styled('div')<{ expanded: boolean }>`
  border: 1px solid ${({ expanded }) => (expanded ? '#7b33fb' : '#e4e7f0')};
  border-radius: 8px;
`;

const CollapseHeader = styled('div')`
  cursor: pointer;
  font-weight: 600;
  line-height: 22px;
  padding-left: 18px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  min-height: 22px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TranslateIconDisplay = styled(ReactSVG)`
  display: inline;
  float: left;
  margin-right: 6px;
`;

const SupportedLanguagesHeader = styled('div')`
  float: left;
`;

const PhrasesContainer = styled('div')`
  padding: 24px;
  display: flex;
  justify-content: center;
`;

export const Table = styled('table')`
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
`;

export const Row = styled('tr')``;

export const RowCell = styled('td')<{ isFirstColumn?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
  width: ${props => (props.isFirstColumn ? '200px' : 'auto')};
  position: relative;
  vertical-align: top;
`;

export const HeaderCell = styled('th')<{ isFirstColumn?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
  width: ${props => (props.isFirstColumn ? '200px' : 'auto')};
`;

export const HeaderCellContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
