import { EmailConversationMetricsResponse } from '../apiInterfaces';
import { SOLVE_EMAIL_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import _omit from 'lodash/fp/omit';

const PAGE_SIZE = 100;

interface GetEmailConversationsParams {
  conversationId: string | null;
  end: string;
  intentId: string | null;
  page: number;
  sortColumn: string;
  sortDirection: string;
  start: string;
}

const emailConversationsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getEmailConversations: builder.query<
      EmailConversationMetricsResponse,
      GetEmailConversationsParams
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        if (newItems.metadata.page === 1) {
          currentCache.conversations = [...newItems.conversations];
        } else {
          currentCache.conversations = [
            ...currentCache.conversations,
            ...newItems.conversations,
          ];
        }
      },
      query: ({
        conversationId,
        end,
        intentId,
        page,
        sortColumn,
        sortDirection,
        start,
      }) => {
        const qParams = new URLSearchParams({
          end_timestamp: end,
          page: page.toString(),
          page_size: PAGE_SIZE.toString(),
          sort_column: sortColumn,
          sort_direction: sortDirection,
          start_timestamp: start,
        });

        if (conversationId) {
          qParams.append('conversation_id', conversationId);
        }
        if (intentId) {
          qParams.append('intent_definition_id', intentId);
        }

        return (
          SOLVE_EMAIL_BASE_URL +
          '/conversation/metrics/conversations' +
          '?' +
          qParams
        );
      },
      serializeQueryArgs: ({ queryArgs }) => {
        // Remove page from cache key so we maintain the same cache when fetching next page
        return _omit(['page'], queryArgs);
      },
    }),
    getFormattedIntentEmail: builder.query<
      { formatted_email: string },
      { conversationId: string }
    >({
      query: ({ conversationId }) => ({
        url:
          SOLVE_EMAIL_BASE_URL +
          `/conversation/${conversationId}/formatted-intent-email`,
      }),
    }),
  }),
});

export const {
  useGetEmailConversationsQuery,
  useGetFormattedIntentEmailQuery,
} = emailConversationsApi;
