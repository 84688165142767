import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export const PlainTextFix = ({ text }: { text: string }) => {
  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Typography variant='font16'>{text}</Typography>
    </Box>
  );
};
