import { Palette } from '@mui/material';

export const handleColorForComparisonChange = (
  palette: Palette,
  type: string,
  value?: number,
) => {
  if (value) {
    if (type === 'potential') {
      if (value > 0) {
        return palette.colors.red[500];
      } else if (value < 0) {
        return palette.colors.green[500];
      }
    }
    if (type === 'realized') {
      if (value > 0) {
        return palette.colors.green[500];
      } else if (value < 0) {
        return palette.colors.red[500];
      }
    }
  }
  return palette.colors.black;
};
