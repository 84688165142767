import './styles.scss';

import React, { useEffect, useState } from 'react';
import 'moment-timezone';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { theme } from '@forethought-technologies/forethought-elements';
import LoadingSpinner from '../loading-spinner/loadingSpinner';
import HighchartOptions from '../pie-chart/highchartOptions';
import {
  selectIsLoading,
  selectIsSolveWorkflows,
} from 'src/reducers/solveReducer';
import { SolveOverviewDataDict } from 'src/services/apiInterfaces';
import { barEmptyData } from 'src/utils/analyticsUtils';
import { solveChartColorUtil } from 'src/utils/chartColorUtil';
import { cleanStr } from 'src/utils/cleanStr';

type Props = {
  /** Name to be displayed on y axis */
  name: string;
  /** Data for chart */
  overviewData: SolveOverviewDataDict | undefined;
  /** Channel selected */
  selectedChannel: string;
  /** Timeframe selected */
  timeFrameFilter: string;
};

interface BarChartData {
  data: Array<{
    x: number;
    y: number;
  }>;
  id: string;
}

const StackedBarChart: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  overviewData,
  selectedChannel,
  timeFrameFilter,
}: Props) => {
  /** Initializes highchart options for language */
  HighchartOptions(Highcharts);
  /** Highcharts rendering options */
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  /** Boolean value that inidicates if there is data to be displayed */
  const [isNoData, setNoData] = useState<boolean>(false);
  /** Indicates loading state */
  const isLoading: boolean = useSelector(selectIsLoading);
  /** Local time zone */
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  /** Indicates if current view is workflows */
  const isSolveWorkflows: boolean = useSelector(selectIsSolveWorkflows);

  const setData = (data: Array<BarChartData>) => {
    if (data && data.length) {
      setNoData(false);
      const seriesData = [];
      const barData = data.map(data => {
        return {
          color: solveChartColorUtil(
            data.id,
            selectedChannel,
            isSolveWorkflows,
          ),
          data: data.data,
          id: data.id,
          name: cleanStr(data.id),
        };
      });
      seriesData.push(barData);
      return seriesData.flat();
    } else {
      setNoData(true);
      return barEmptyData();
    }
  };

  // formats dates for tooltip and x axis
  const formatXDate = (): string => {
    let format = '';
    if (timeFrameFilter === 'Daily' || timeFrameFilter === 'Weekly') {
      format = '{value:%m/%e}';
    } else if (timeFrameFilter === 'Monthly') {
      format = '{value:%b, %Y}';
    }
    return format;
  };

  // Highcharts will be able to find moment
  window.moment = moment;

  useEffect(() => {
    setOptions({
      chart: {
        height: 450,
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          borderWidth: 1,
          grouping: false,
          maxPointWidth: 20,
          stacking: 'normal',
        },
        series: {
          borderColor: theme.palette.colors.white,
          stacking: 'normal',
          states: {
            hover: {
              enabled: !isNoData, //disables hover on data point
            },
          },
        },
      },
      responsive: {
        rules: [
          {
            chartOptions: {
              plotOptions: {
                column: {
                  maxPointWidth: 20,
                },
              },
            },
            condition: {
              maxWidth: 1200,
            },
          },
        ],
      },
      series: setData(
        overviewData?.bar_chart as BarChartData[],
      ) as SeriesOptionsType[],
      time: {
        timezone,
      },
      title: {
        text: '',
      },
      tooltip: {
        borderWidth: 0,
        enabled: !isNoData,
        headerFormat: '',
        outside: true,
        padding: 0,
        pointFormat: `<div class='StackedBarChart-tooltip-cont'>
          <div class='StackedBarChart-tooltip-color'style='background-color:{series.color};'></div>
          <div class='StackedBarChart-tooltip-data'>
          <p class='StackedBarChart-tooltip-data-x'>{point.x:%b %e, %Y}</p>
            <p class='StackedBarChart-tooltip-data-name'>{series.name}</p>
            <span class='StackedBarChart-tooltip-data-y'>{point.y:,.0f}</span>
          </div>
        </div>`,
        shadow: false,
        useHTML: true,
        valueDecimals: 0,
      },
      xAxis: {
        labels: {
          format: formatXDate(),
        },
        type: 'datetime',
      },

      yAxis: {
        title: {
          text: name,
        },
      },
    });
  }, [overviewData, timeFrameFilter, isNoData, isSolveWorkflows]);

  return (
    <section className='StackedBarChart'>
      <div className='StackedBarChart-legend'>
        <div className='StackedBarChart-legend-name'>{name}</div>
        <div className='StackedBarChart-legend-val'>
          {options &&
            (options?.series as Highcharts.SeriesOptionsType[]).map(
              (data: SeriesOptionsType, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className='StackedBarChart-legend-val-y'
                      style={{
                        backgroundColor: (data as unknown as { color: string })
                          .color,
                      }}
                    />
                    <span className='StackedBarChart-legend-val-text'>
                      {data.name}
                    </span>
                  </React.Fragment>
                );
              },
            )}
        </div>
      </div>
      <div>
        {overviewData && !isLoading && options ? (
          <HighchartsReact highcharts={Highcharts} options={options} />
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </section>
  );
};

export default StackedBarChart;
