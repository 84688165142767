import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

import {
  Button,
  TextField,
} from '@forethought-technologies/forethought-elements';
import * as Styled from './styles';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { useCreateActionMutation } from 'src/services/action-builder/actionBuilderApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { AddOrUpdateActionInterface } from 'src/types/actionBuilderApiTypes';
import { replaceActionIdInRoute } from 'src/utils/actionBuilder/helpers';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  closeCallback: () => void;
}

export const HubspotContactCreationForm: FC<React.PropsWithChildren<Props>> = ({
  closeCallback,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [actionName, setActionName] = useState('');
  const [actionDescription, setActionDescription] = useState('');
  const [createAction] = useCreateActionMutation();

  const save = async () => {
    // https://developers.hubspot.com/docs/api/crm/contacts#create-contacts
    const action: AddOrUpdateActionInterface = {
      action_description: actionDescription,
      action_fields: {
        authorization_integration: 'hubspot',
        body_parameters: JSON.stringify({
          properties: {
            email: `\{\{${email}\}\}`,
            firstname: `\{\{${firstName}\}\}`,
            lastname: `\{\{${lastName}\}\}`,
          },
        }),
        headers: { ['Content-Type']: 'application/json' },
        method: 'post', // must be lowercase
        url: 'https://api.hubapi.com/crm/v3/objects/contacts',
      },
      action_name: actionName,
      action_type: ActionBuilderActionTypes.API,
    };

    const res = await createAction(action);

    if ('error' in res) {
      return;
    }

    const createdAction = res.data.actions.find(
      action => action.action_name === actionName,
    );

    if (createdAction) {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          title: `${actionName} Action successfully created`,
          variant: 'main',
        }),
      );

      closeCallback();

      setTimeout(() => {
        navigate(replaceActionIdInRoute(createdAction.action_id), {
          replace: true,
        });
      }, 1000);
    }
  };

  return (
    <Styled.ActionTemplateForm
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <Styled.InputsContainer>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextField
            label='Action Name'
            onChange={e => {
              setActionName(e.target.value);
            }}
            required
            value={actionName}
          />
          <TextField
            label='Action Description'
            onChange={e => {
              setActionDescription(e.target.value);
            }}
            required
            value={actionDescription}
          />
          <ContextVariableSelectDropdown
            label='First Name'
            onChange={setFirstName}
            value={firstName}
          />
          <ContextVariableSelectDropdown
            label='Last Name'
            onChange={setLastName}
            value={lastName}
          />
          <ContextVariableSelectDropdown
            label='Email'
            onChange={setEmail}
            value={email}
          />
        </Box>
      </Styled.InputsContainer>
      <Styled.SaveContainer>
        <Button onClick={save} variant='main'>
          Save
        </Button>
      </Styled.SaveContainer>
    </Styled.ActionTemplateForm>
  );
};
