import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';

import NotFound from '../../triage-version-control-detail/NotFound';
import { LAST_14_DAYS_INDEX } from '../constants';
import { TriageModelLabel } from '../types';
import EmptyLabelsTable from './EmptyLabelsTable';
import LabelsOverview from './LabelsOverview';
import LabelsTable from './LabelsTable';
import Spinner from 'src/components/spinner';
import {
  DISCOVER_SHARED_PARAM_NAMES,
  timeFilterOptions,
} from 'src/constants/discover';
import { useStateParams } from 'src/hooks/hooks';
import {
  useGetModelByIdQuery,
  useGetModelLabelsQuery,
  useGetPredictionTestResultsQuery,
} from 'src/services/triage/triageApi';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptions } from 'src/utils/timeRangeHelpers';

const LabelsTab = ({ modelId }: { modelId: string }) => {
  const {
    data: currentVersionedModel,
    isError,
    isLoading: isModelLoading,
  } = useGetModelByIdQuery(modelId || '');
  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: datePickerRangeOptions[LAST_14_DAYS_INDEX].value,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TIME_FILTER,
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator(timeFilterOptions),
  });
  const [selectedTagId, selectTagId] = useState<string | null>(null);
  const location = useLocation();
  const isSimulation = location.pathname.includes('simulations');
  const predictionTestId = window.location.pathname.split('/')[2];
  const {
    data: modelTableData,
    isFetching: isModelTableFetching,
    isLoading: isModelTableLoading,
  } = useGetModelLabelsQuery(
    {
      endDate: Number(dateRange.to),
      modelId,
      startDate: Number(dateRange.from),
    },
    { skip: isSimulation },
  );
  const {
    data: testTableData,
    isFetching: isTestTableFetching,
    isLoading: isTestTableLoading,
  } = useGetPredictionTestResultsQuery(predictionTestId as string, {
    skip: !isSimulation,
  });
  const tableData = modelTableData || testTableData;
  const isTableFetching = isModelTableFetching || isTestTableFetching;
  const isTableLoading = isModelTableLoading || isTestTableLoading;
  const { labels } = tableData || {};
  const labelSet = useMemo<TriageModelLabel[]>(() => {
    if (currentVersionedModel && !labels) {
      return currentVersionedModel.labels.map(label => ({
        ...label,
        accuracy: 0,
        actual_count: 0,
        correct_count: 0,
        coverage: 0,
        finalized_count: 0,
        last_update_at: '',
        last_updated_by: '',
        name: label.title,
        pending_count: 0,
        predicted_count: 0,
        prediction_count: 0,
        qualified_count: 0,
        recall: null,
      }));
    }
    if (!currentVersionedModel || !labels) {
      return [];
    }
    return labels;
  }, [currentVersionedModel, labels]);

  const isLoading = isModelLoading || isTableLoading;
  if (isLoading) {
    return <Spinner />;
  }

  if (!currentVersionedModel || isError) {
    return <NotFound />;
  }

  const isEmpty = !labelSet.length;
  const grid = isEmpty ? 'auto 110px auto' : 'auto minmax(350px, auto)';

  return (
    <Box display='grid' gridTemplateRows={grid} rowGap={5}>
      <LabelsOverview dateRange={dateRange} overview={tableData?.overview} />
      <LabelsTable
        areRowsFetching={isTableFetching || isTableLoading}
        dateRange={dateRange}
        isTableDataLoading={isTableLoading}
        labelControlOptions={{
          selectTagId,
          tagId: selectedTagId,
        }}
        labels={labelSet}
        model={currentVersionedModel}
        onDateRangeChange={setDateRange}
        viewOnly={isSimulation}
      />
      {isEmpty && <EmptyLabelsTable />}
    </Box>
  );
};

export default LabelsTab;
