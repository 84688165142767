import './OrgNotFound.scss';

import LandingPageLayout from 'src/components/reusable-components/landing-page-layout';

export default function OrgNotFound() {
  return (
    <LandingPageLayout>
      <>
        <div className='OrgNotFound-mainTitle'>Organization not found</div>
        <p className='OrgNotFound-subTitle'>
          We do not support this organization. Learn how you can start using
          Assist Anywhere.
        </p>
        <a
          className='OrgNotFound--link'
          href='https://support.forethought.ai/hc/en-us/requests/new'
        >
          Get Help Now
        </a>
      </>
    </LandingPageLayout>
  );
}
