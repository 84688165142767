import { Colors, theme } from '@forethought-technologies/forethought-elements';

export const selectStyleProps = {
  backgroundColor: Colors.ui.backgrounds.default,
  border: `1px solid ${theme.palette.colors.grey[300]}`,
  color: Colors.ui.text.primary,
  hoverColor: theme.palette.colors.purple[100],
  justifyContent: 'space-between',
  padding: '0 16px',
  width: '100%',
};

// Tracks which fields have errors or have been touched
export enum FieldNameToIdx {
  HANDOFF_FORM_QUESTION_MESSAGE = 0,
  CONFIRMATION_MESSAGE = 1,
  CUSTOM_BRAND_VALUE = 2,
  TICKET_TAGS = 3,
  CUSTOM_MESSAGE = 4,
  CHAT_TAGS = 5,
  NAME = 6,
  EMAIL = 7,
  QUESTION = 8,
}
