import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import { FetchAction } from '../../types/types';
import { FilterActions } from '../actionTypes';
import { getAllReports } from 'src/actions/reports/reports';
import { FieldsInterface } from 'src/reducers/filtersReducer/filtersReducer';
import {
  Fields,
  Filters,
  FilterValueResponse,
} from 'src/services/apiInterfaces';
import { getFilterFieldsAPI, getFilterValuesAPI } from 'src/services/apiV1';
import type { RootState } from 'src/store/rootReducer';

export const getFilterFailure = (error: string) => ({
  error,
  type: FilterActions.GET_FILTER_FAILURE,
});

export const setFiltersLoading = createAction<boolean>(
  FilterActions.SET_FILTER_LOADING,
);

export const toggleFilterValue = createAction<string>(
  FilterActions.TOGGLE_FILTER_VALUE,
);

export const setFilterFieldNull = createAction(
  FilterActions.SET_FILTER_FIELD_NULL,
);

export const clearSelectedFilterValues = createAction(
  FilterActions.CLEAR_SELECTED_FILTER_VALUES,
);

export const toggleSelectedFilterField = createAction<FieldsInterface>(
  FilterActions.TOGGLE_SELECTED_FILTER_FIELD,
);

export const getFilterFieldsSuccess = createAction<Fields>(
  FilterActions.GET_FILTER_FIELDS_SUCCESS,
);

export const getFilterValuesSuccess = createAction<string[]>(
  FilterActions.GET_FILTER_VALUES_SUCCESS,
);

export const setFilter = createAction<Filters>(FilterActions.SET_FILTER);

export const clearFilters = createAction(FilterActions.CLEAR_FILTERS);

export const deleteFilter = createAction<number>(FilterActions.DELETE_FILTER);

export const cleanFilterValues = createAction(
  FilterActions.CLEAN_FILTER_VALUES,
);

export const getFilterFields: Function =
  () =>
  (dispatch: Dispatch): Promise<void | FetchAction> => {
    return getFilterFieldsAPI()
      .then((data: Fields) => {
        dispatch(getFilterFieldsSuccess(data));
        dispatch(getAllReports(true));
      })
      .catch((err: Error) => {
        dispatch(getFilterFailure(err.message));
      });
  };

export const getFilterValues: any =
  (search?: string) =>
  (
    dispatch: Dispatch,
    getState: Function,
  ): Promise<void | FetchAction | null> => {
    const state: RootState = getState();
    const selectedFilterField = state.filters.selectedFilterField;
    if (!selectedFilterField) {
      return Promise.resolve(null);
    }
    dispatch(setFiltersLoading(true));
    return getFilterValuesAPI(state, search)
      .then((data: FilterValueResponse) => {
        dispatch(getFilterValuesSuccess(data.values));
        dispatch(setFiltersLoading(false));
      })
      .catch((err: Error) => {
        dispatch(setFiltersLoading(false));
        dispatch(getFilterFailure(err.message));
      });
  };
