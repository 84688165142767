import { useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  ActiveCanvasComponentProps,
  ButtonComponentFields,
  IdleCanvasComponentProps,
} from 'src/components/email-builder/types';
import { selectThemeColor } from 'src/slices/email-builder/emailBuilderSlice';

const IntentEmailButton = ({
  component,
}: IdleCanvasComponentProps | ActiveCanvasComponentProps) => {
  const themeColor = useSelector(selectThemeColor);
  const { button_style, button_text } =
    component.component_fields as ButtonComponentFields;

  if (button_style === 'link') {
    return (
      <Box padding='16px'>
        <a href='#'>{button_text || 'Add a new label...'}</a>
      </Box>
    );
  }

  return (
    <Box padding='16px'>
      <Button backgroundColor={themeColor}>
        <Typography color={theme.palette.colors.white} variant='font16Bold'>
          {button_text || 'Add a new label...'}
        </Typography>
      </Button>
    </Box>
  );
};

export default IntentEmailButton;

const Button = styled('button')<{ backgroundColor: string }>`
  width: 100%;
  padding: 14px 16px;
  border-radius: 4px;
  background: ${props => props.backgroundColor};
  cursor: pointer;
`;
