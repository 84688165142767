import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useEmailMode } from '../hooks';

export function PublishEmailWorkflowPrompt() {
  const { palette } = useTheme();

  const { isLoading, setEmailMode } = useEmailMode();

  if (isLoading) {
    return null;
  }

  return (
    <Box alignItems='center' display='flex'>
      <Typography color={palette.text.secondary} variant='font14'>
        Changes auto-saved, visit{' '}
        <Box
          component={'span'}
          onClick={() => setEmailMode('Journey')}
          sx={{
            color: palette.colors.purple[500],
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          journey map
        </Box>{' '}
        to publish changes
      </Typography>
    </Box>
  );
}
