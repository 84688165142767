import { Box, useTheme } from '@mui/material';

import {
  formatToDateAndTime,
  Typography,
} from '@forethought-technologies/forethought-elements';

export function DateCell({ dateString }: { dateString: string }) {
  const { palette } = useTheme();

  return (
    <Box display='flex'>
      <Typography color={palette.colors.black} variant='font14'>
        {formatToDateAndTime(dateString)}
      </Typography>
    </Box>
  );
}
