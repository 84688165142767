import React, { useRef, useState } from 'react';

interface AutosizeIframeProps extends React.HTMLProps<HTMLIFrameElement> {
  /**
   * Optionally provide some extra height to the iframe to account for padding/margin and box-sizing auto.
   */
  padding?: number;
}

/**
 * An iframe component that automatically resizes its height to fit its content on load.
 */
export default function AutosizeIframe({
  padding = 0,
  ...rest
}: AutosizeIframeProps) {
  const ref = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState('0px');

  function onLoad() {
    const scrollHeight = ref.current?.contentWindow?.document.body.scrollHeight;
    if (scrollHeight) {
      setHeight(scrollHeight + padding + 'px');
    }
  }

  return (
    <iframe
      height={height}
      onLoad={onLoad}
      ref={ref}
      style={{
        border: 'none',
        width: '100%',
      }}
      {...rest}
    />
  );
}
