import { getAnswersData } from 'src/actions/data/data';
import { getMacroControlsData } from 'src/actions/macroControls';
import { getSolveData } from 'src/actions/solveData';
import store from 'src/store/store';

// As of now only Solve and Assist analytics are using the the SettingsContainer and reusable components and the API calls rely on the redux state
// If any of the other analytics e.g(Assist Search or Triage) start using this logic and these components add call for data to callback

/** Util function shared by reusable components in SettingsContainer that makes call to get data when a page settings is applied based on the users active analytics tab */
export const getData = (): void => {
  const state = store.getState();
  const { pageSettings, user } = state;
  const { currentTab } = user;
  const { solveViewMode } = pageSettings;

  switch (currentTab) {
    case 'Assist':
    case 'Assist Analytics':
      store.dispatch(getAnswersData());
      break;
    case 'Solve':
    case 'Solve Dashboard':
      if (solveViewMode !== 'Macro Controls') {
        store.dispatch(getSolveData());
      } else {
        store.dispatch(getMacroControlsData());
      }
      break;
  }
};
