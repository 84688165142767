import { useCallback } from 'react';
import { EdgeProps, MarkerType, useReactFlow } from 'reactflow';

import { FLOW_EDGES } from '../components/flow-layout/EdgeTypes';
import { DragItem } from '../types';

// this hook implements the logic for dropping a new node on a workflow edge
export function useEdgeDrop() {
  // combine event listeners from useEdgeClick and useNodeDrop to create a new node on a workflow edge
  const { getEdge, getNode, setEdges, setNodes } = useReactFlow();

  const handleEdgeDrop = useCallback(
    (
      id: EdgeProps['id'],
      insertNodeId: string,
      insertNodeType: string,
      item: DragItem,
    ) => {
      // retrieve the edge object to get the source and target id
      const edge = getEdge(id);
      if (!edge) {
        return;
      }

      // retrieve the target node to get its position
      const targetNode = getNode(edge.target);
      if (!targetNode) {
        return;
      }

      // optional parentTranisitionId for children of rule nodes
      const { parentTransitionId } = targetNode.data;

      // this is the node object that will be added in between source and target node
      const insertNode = {
        data: {
          parentTransitionId,
          ...item.componentMetadata,
        },
        id: insertNodeId,
        // we place the node at the current position of the target (prevents jumping)
        position: { x: targetNode.position.x, y: targetNode.position.y },
        selected: true,
        type: insertNodeType,
      };

      // new connection from source to new node
      const sourceEdge = {
        ...edge,
        id: `${edge.source}->${insertNodeId}`,
        markerEnd: { strokeWidth: 2, type: MarkerType.Arrow },
        source: edge.source,
        target: insertNodeId,
        type: FLOW_EDGES.WORKFLOW,
      };

      // new connection from new node to target
      const targetEdge = {
        data: {},
        id: `${insertNodeId}->${edge.target}`,
        markerEnd: { strokeWidth: 2, type: MarkerType.Arrow },
        source: insertNodeId,
        target: edge.target,
        type: FLOW_EDGES.WORKFLOW,
      };

      // remove the edge that was clicked as we have a new connection with a node inbetween
      setEdges(edges =>
        edges.filter(edge => edge.id !== id).concat([sourceEdge, targetEdge]),
      );

      // insert the node between the source and target node in the react flow state
      setNodes(nodes => {
        // unselect all nodes so new node is the only selected node
        const prevNodes = nodes.map(node => ({ ...node, selected: false }));
        const targetNodeIndex = prevNodes.findIndex(
          node => node.id === edge.target,
        );
        return [
          ...prevNodes.slice(0, targetNodeIndex),
          insertNode,
          ...prevNodes.slice(targetNodeIndex, prevNodes.length),
        ];
      });
    },
    [getEdge, getNode, setEdges, setNodes],
  );

  return handleEdgeDrop;
}
