import {
  getSelectedOrganizationForSuperAdmin,
  UserRoles,
} from 'src/utils/enums';

export const displayOrg = (role: string, org: string) => {
  const selectedOrg = getSelectedOrganizationForSuperAdmin();
  if (role === UserRoles.ROLE_SUPER_ADMIN && selectedOrg) {
    return selectedOrg.org_name;
  } else if (role === UserRoles.ROLE_SUPER_ADMIN && !selectedOrg) {
    return 'No Org Selected';
  }
  return org;
};
