import Box from '@mui/material/Box';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

const DevianceTooltipContent = () => (
  <Box padding={2}>
    <Typography variant='font12'>
      <Box mb={2}>
        <strong>Deviance</strong> is the comparison between one topic and the
        average of all topics. <strong>Positive (+)</strong> deviance indicates
        the topic is more than the average, while <strong>negative (-)</strong>{' '}
        deviance indicates the topic is less than the average.
      </Box>
      <Box mb={0.5}>
        <Typography color={theme.palette.colors.grey[800]} variant='font11'>
          Examples
        </Typography>
      </Box>
      <Box mb={2}>
        If a topic&apos;s volume deviance is <strong>+2.3x</strong>, that topic
        has 2.3 times <strong>more</strong> tickets than the average.{' '}
      </Box>
      <Box>
        If a topic&apos;s volume deviance is <strong>-1.2x</strong>, that topic
        has 1.2 times <strong>fewer</strong> tickets than the average.
      </Box>
    </Typography>
  </Box>
);

export default DevianceTooltipContent;
