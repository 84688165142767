import { createAsyncThunk } from '@reduxjs/toolkit';

import { AnalyticsSettingsActions } from '../actionTypes';
import { isTypedError } from './helpers';
import {
  HelpdeskDetail,
  TriageFieldsToPredictConfigData,
  TriageModelDetail,
  TypedError,
  UpdateStatusData,
} from 'src/reducers/triageSettingsReducer/types';
import {
  getHelpdeskApi,
  getPredictedFieldsApi,
  getTriageModel,
  getTriageModels,
  updateTriageModel,
  updateTriageModelFieldsToPredict,
} from 'src/services/api';

export const getTriageModelList = createAsyncThunk(
  AnalyticsSettingsActions.GET_TRIAGE_MODELS,
  async () => {
    return await getTriageModels();
  },
);

export const getTriageModelByModelName = createAsyncThunk(
  AnalyticsSettingsActions.GET_TRIAGE_MODEL,
  async (modelName: string) => {
    return await getTriageModel(modelName);
  },
);

export const updateStatusOfTriageModelByModelName = createAsyncThunk(
  AnalyticsSettingsActions.UPDATE_TRIAGE_MODEL_STATUS,
  async (data: UpdateStatusData) => {
    return await updateTriageModel(data.modelName, data.status);
  },
);

export const updatePredictedFieldOfTriageModelByModelName = createAsyncThunk<
  TriageModelDetail,
  TriageFieldsToPredictConfigData,
  {
    rejectValue: TypedError;
  }
>(
  AnalyticsSettingsActions.POST_TRIAGE_MODEL_FIELDS_TO_PREDICT,
  async (data, { rejectWithValue }) => {
    try {
      return await updateTriageModelFieldsToPredict(data.modelName, data.body);
    } catch (error) {
      if (isTypedError(error)) {
        return rejectWithValue(error);
      }

      throw error;
    }
  },
);

export const getHelpdesk = createAsyncThunk(
  AnalyticsSettingsActions.GET_HELPDESK,
  async () => {
    return await getHelpdeskApi();
  },
);

export const getPredictedFields = createAsyncThunk(
  AnalyticsSettingsActions.GET_PREDICTED_FIELDS,
  async ({
    helpdesk,
    isHelpdeskNotConfigured = false,
  }: {
    helpdesk: HelpdeskDetail | null;
    isHelpdeskNotConfigured: boolean;
  }) => {
    if (!helpdesk || !helpdesk.is_quickstart_supported) {
      throw new Error('Helpdesk not supported');
    }

    if (isHelpdeskNotConfigured) {
      throw new Error('No data source configured');
    }

    return await getPredictedFieldsApi(helpdesk.name);
  },
);
