import DiscoverBookmarkIcon from '../../../discover-bookmark-icon/DiscoverBookmarkIcon';
import { usePatchTopicMutation } from 'src/hooks/discover/usePatchTopicMutation';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';

export const BookmarkedRendererComponent = ({
  isBookmarked,
  topicId,
  trackingEventMetadata,
  version = 'V1',
}: {
  isBookmarked?: boolean;
  topicId: string;
  trackingEventMetadata: Record<string, string | string[]>;
  version?: 'V1' | 'V2';
}) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { patchTopicMutation } = usePatchTopicMutation();

  return (
    <DiscoverBookmarkIcon
      isBookmarked={isBookmarked}
      onClick={() => {
        emitTrackingEventCallback(
          'discover-bookmarked-topic',
          trackingEventMetadata,
        );
        patchTopicMutation(topicId, { is_bookmarked: !isBookmarked });
      }}
      version={version}
    />
  );
};
