import { useMemo } from 'react';
import Box from '@mui/material/Box';

import {
  Badge,
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetSortedConnectableIntents } from '../hooks/useGetSortedConnectableIntents';

export const ReroutingIntentSelector = ({
  defaultLabel,
  negativeRatingRoutingIntent,
  setNegativeRatingRoutingIntent,
}: {
  defaultLabel: string;
  negativeRatingRoutingIntent?: string;
  setNegativeRatingRoutingIntent: (value?: string) => void;
}) => {
  const { intents: routableIntents } = useGetSortedConnectableIntents();

  const routableOptions = useMemo(() => {
    const options = routableIntents.map(intent => ({
      label: intent.intent_name,
      value: intent.intent_workflow_id,
    }));
    options.unshift({
      label: defaultLabel,
      value: '',
    });

    return options;
  }, [routableIntents, defaultLabel]);

  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Box alignItems='center' display='flex' gap='4px'>
        <Typography variant='font14'>
          Reroute to Workflow for low rating
        </Typography>
        <Badge label='Negative' variant='error' />
        <Badge label='Neutral' variant='macro' />
      </Box>
      <SelectDropdown
        id='Negative rating routing intent selector'
        isMenuSearchable
        onChange={e => {
          setNegativeRatingRoutingIntent(
            e.target.value === '' ? undefined : e.target.value,
          );
        }}
        options={routableOptions}
        value={negativeRatingRoutingIntent || ''}
      />
    </Box>
  );
};
