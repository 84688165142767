import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Drawer } from '@forethought-technologies/forethought-elements';
import { setIsOverlayVisible } from 'src/actions/workflow-builder/workflowBuilderActions';
import UnsavedChangesModal from 'src/components/unsaved-changes-modal';
import ContextVariablesPage from 'src/pages/workflow-builder-config/context-variables/ContextVariablesPage';
import {
  selectGlobalWorkflowBuilderOptions,
  setGlobalWorkflowBuilderOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const ContextVariableDrawer = () => {
  const dispatch = useAppDispatch();
  const { hasUnsavedChanges, isCvDrawerOpen } = useSelector(
    selectGlobalWorkflowBuilderOptions,
  );

  const setHasUnsavedChanges = useCallback(
    (value: boolean) => {
      dispatch(
        setGlobalWorkflowBuilderOptions({
          hasUnsavedChanges: value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      {/**
       * There is logic that ties hasUnsavedChanges to preview 
      this conditional rendering blocks that scenario
       */}
      {hasUnsavedChanges && isCvDrawerOpen && (
        <UnsavedChangesModal
          onCancel={() => {
            dispatch(setIsOverlayVisible(false));
          }}
          onDiscard={() => {
            dispatch(setIsOverlayVisible(false));
            dispatch(
              setGlobalWorkflowBuilderOptions({
                hasUnsavedChanges: false,
                isCvDrawerOpen: false,
              }),
            );
          }}
        />
      )}
      <Drawer
        isOpen={isCvDrawerOpen}
        onClose={() => {
          if (hasUnsavedChanges) {
            dispatch(setIsOverlayVisible(true));
          } else {
            dispatch(setIsOverlayVisible(false));
            dispatch(
              setGlobalWorkflowBuilderOptions({
                isCvDrawerOpen: false,
              }),
            );
          }
        }}
        width='72%'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: '40px',
            position: 'relative',
          }}
        >
          <ContextVariablesPage
            isOnNavbar
            setHasUnsavedChanges={setHasUnsavedChanges}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default ContextVariableDrawer;
