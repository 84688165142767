import React, { useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, styled, useTheme } from '@mui/material';
import { IconGraphOff } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { DateRange } from '../discover-dashboard-page/types';
import { dateRangeToTimestamp } from '../solve-insights/helpers';
import { TOPIC_COLOR_ARRAY } from '../solve-insights/topics/constants';
import { InsightPeriod } from '../solve-insights/types';
import LineGraphFilter from './LineGraphFilter';
import { renderLineGraphUtils } from 'src/components/solve-insights-charts/line-graph/lineGraphUtils';
import { useGetTopicDetailGraphQuery } from 'src/services/insights';
import {
  SubtopicInformation,
  TopicDetailResponse,
} from 'src/services/insights/types';

interface TopicDetailChartProps {
  dateRange: DateRange;
  isParentTopic: boolean;
  periodicalFilter: InsightPeriod;
  subTopics: SubtopicInformation[];
  topicId: string;
}

const TopicDetailChart = ({
  dateRange,
  isParentTopic,
  periodicalFilter,
  subTopics,
  topicId,
}: TopicDetailChartProps) => {
  const { palette } = useTheme();
  const backendReadyTimestamps = dateRangeToTimestamp(dateRange);

  // const initialFilterList = useMemo(() => {
  //   return topicDetailData?.line.values.map((value, idx) => {
  //     return { label: value.label, value: idx <= 2 };
  //   });
  // }, [topicDetailData?.line.values]);

  const initialFilterList = useMemo(() => {
    return subTopics.map((value, idx) => {
      return {
        label: value.cluster_name,
        topicId: value.cluster_id,
        value: idx <= 2,
      };
    });
  }, [subTopics]);

  const [filterList, setFilterList] = React.useState<
    { label: string; topicId: string; value: boolean }[]
  >(initialFilterList ?? []);

  const colorMap = useMemo(() => {
    const colorMap = new Map<string, string>();
    filterList.forEach((value, idx) => {
      if (!colorMap.has(value.topicId)) {
        colorMap.set(value.topicId, TOPIC_COLOR_ARRAY[idx]);
      }
    });
    return colorMap;
  }, [filterList]);

  const getActiveTopicsById = () => {
    return filterList
      .map(topic => {
        if (topic.value) {
          return topic.topicId;
        }
      })
      .filter(Boolean) as string[];
  };

  const {
    data: topicDetailData,
    isFetching: isGraphFetching,
    isLoading: isGraphLoading,
  } = useGetTopicDetailGraphQuery({
    end: backendReadyTimestamps.end_timestamp,
    is_parent_topic: isParentTopic,
    period: periodicalFilter,
    start: backendReadyTimestamps.start_timestamp,
    subTopics: getActiveTopicsById(),
    topic_id: topicId,
  });

  const isLoading = isGraphFetching || isGraphLoading;

  const handleParentTopicGraphData = (
    topicDetailData: TopicDetailResponse,
  ): TopicDetailResponse => {
    /**
     * Parent topic logic
     * 1. Don't filter if its a sub topic
     * 2. Maintain color sync with filter
     */
    const filterValues = getActiveTopicsById();
    const updatedValuesByFilter = topicDetailData.line.values.filter(value =>
      filterValues.includes(value.topicId ?? ''),
    );
    const updatedValues = updatedValuesByFilter.map(value => {
      const updatedValue = { ...value };
      updatedValue.color = colorMap.get(value.topicId ?? '');
      return updatedValue;
    });

    return {
      ...topicDetailData,
      line: {
        ...topicDetailData.line,
        values: updatedValues,
      },
    };
  };

  const handleLineGraphRender = () => {
    if (!topicDetailData || isLoading) {
      return <Skeleton height='275px' width='100%' />;
    }

    const checkIfEmpty = () => {
      let isEmpty = true;
      topicDetailData?.line.values.forEach(value => {
        if (value.value.length > 0) {
          isEmpty = false;
        }
      });
      return isEmpty;
    };

    if (checkIfEmpty()) {
      return (
        <Box
          alignItems='center'
          display='flex'
          height='100%'
          justifyContent='center'
          width='100%'
        >
          <EmptyGraphStateWrapper>
            <IconWrapper>{<IconGraphOff />}</IconWrapper>
            <Typography color={palette.colors.grey[700]} variant='font16Bold'>
              No data found
            </Typography>
          </EmptyGraphStateWrapper>
        </Box>
      );
    }

    if (isParentTopic) {
      return renderLineGraphUtils(
        palette,
        handleParentTopicGraphData(topicDetailData),
        isParentTopic ? 'parent_detail' : 'detail',
      );
    }
    return renderLineGraphUtils(
      palette,
      topicDetailData,
      isParentTopic ? 'parent_detail' : 'detail',
    );
  };

  useEffect(() => {
    if (!isLoading) {
      setFilterList(initialFilterList ?? []);
    }
  }, [initialFilterList, isLoading]);

  return (
    <Box display='flex'>
      {isParentTopic && (
        <Box flex={1}>
          <LineGraphFilter
            filterList={filterList}
            setFilterList={setFilterList}
          />
        </Box>
      )}
      <Box flex={5}>{handleLineGraphRender()}</Box>
    </Box>
  );
};

const EmptyGraphStateWrapper = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 326px;
  text-align: center;
`;

const IconWrapper = styled('div')`
  align-items: center;
  background-color: ${p => p.theme.palette.colors.slate[100]};
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  height: 90px;
  justify-content: center;
  margin-bottom: 16px;
  padding: 18px;
  width: 90px;

  > svg {
    height: 100%;
    width: 100%;
  }
`;

export default TopicDetailChart;
