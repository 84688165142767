import { useMemo } from 'react';

import { useSolveMetricsQueryParams } from './useSolveMetricsQueryParams';
import keyBy from 'lodash/fp/keyBy';
import { useGetEmailBreakdownMetricsQuery } from 'src/services/workflow-builder-metrics';

export function useEmailMetricBreakdownById() {
  const { from, to } = useSolveMetricsQueryParams();
  const { data, isFetching } = useGetEmailBreakdownMetricsQuery({
    from,
    to,
  });

  return useMemo(() => {
    const breakdownById = keyBy('intent_definition_id', data?.breakdown);
    return { breakdownById, isFetching };
  }, [data, isFetching]);
}
