import { EmailTranslation } from 'src/pages/workflow-builder-edit/types';

export const getFormattedTranslations = (
  emailTranslations: Array<EmailTranslation>,
) => {
  return emailTranslations.map(data => {
    return {
      ...data,
      current_translation: data.manual_translation || data.auto_translation,
    };
  });
};
