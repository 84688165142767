import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Button } from '@forethought-technologies/forethought-elements';
import { Container } from '../solve-config-header/SolveConfigHeader';

const SolveConfigPreviewHeader = () => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Box display='flex' gap='12px' justifyContent='flex-end' width='100%'>
        <Button onClick={handleClose} variant='secondary'>
          Close
        </Button>
      </Box>
    </Container>
  );
};

export default SolveConfigPreviewHeader;
