import React, { RefObject, useEffect, useRef, useState } from 'react';
import MiniSearch, { SearchResult } from 'minisearch';
import { usePopper } from 'react-popper';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

export type SuggestionsProps = {
  displayField: 'context_variable_name';
  fields: string[];
  idField: string;
  indexData: ContextVariable[];
  onMouseDown: (result: any) => void;
  search: string;
  storeFields: string[];
  tetheredRef: RefObject<HTMLElement>;
  width: string;
};

const Suggestions: React.FC<SuggestionsProps> = ({
  displayField,
  fields,
  idField,
  indexData,
  onMouseDown,
  search,
  storeFields,
  tetheredRef,
  width,
}) => {
  const [searchResults, setSearchResults] = useState<
    SearchResult[] | ContextVariable[]
  >([]);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const { attributes, styles } = usePopper(
    tetheredRef.current,
    suggestionsRef.current,
    {
      placement: 'top-start',
    },
  );
  useEffect(() => {
    const miniSearch = new MiniSearch({
      fields,
      idField,
      storeFields,
    });
    miniSearch.addAll(indexData);
    let results;
    if (search.length === 0) {
      results = indexData;
    } else {
      results = miniSearch.search(search, {
        fuzzy: 0.2,
        prefix: true,
      });
    }
    setSearchResults(results.slice(0, 5));
  }, [fields, idField, indexData, search, storeFields]);

  return (
    <SuggestionsList
      ref={suggestionsRef}
      style={styles.popper}
      {...attributes.popper}
      width={width}
    >
      {!searchResults.length && <NoOptionsMessage>No options</NoOptionsMessage>}
      {searchResults.map((result, searchIndex) => {
        const myResult = result;
        return (
          <Suggestion
            key={searchIndex}
            onMouseDown={e => {
              setTimeout(() => onMouseDown(myResult), 200);
              e.preventDefault();
            }}
          >
            ${myResult[displayField]}
          </Suggestion>
        );
      })}
    </SuggestionsList>
  );
};

export default Suggestions;

const SuggestionsList = styled('div')<{ width: string }>`
  width: ${props => props.width};
  background: ${Colors.ui.backgrounds.default};
  border: 1px solid ${theme.palette.colors.grey[100]};
  box-shadow: 0 4px 12px ${theme.palette.colors.grey[100]};
  padding: 8px 0;
  border-radius: 4px;
  z-index: 11;
`;

const Suggestion = styled('div')`
  cursor: pointer;
  padding: 7px 8px;
  color: ${theme.palette.colors.purple[500]};
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--font-weight-medium);
  width: 100%;

  &:hover {
    background: ${theme.palette.colors.grey[100]};
  }
`;

const NoOptionsMessage = styled('div')`
  padding: 5px 5px 5px 15px;
  margin-top: 3px;
  width: 100%;
  color: ${theme.palette.colors.grey[600]};
`;
