import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import AutosizeIframe from 'src/components/autosize-iframe/AutosizeIframe';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { getWidgetConversationForInteractiveEmailPreview } from 'src/services/email-builder/emailBuilderApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import {
  selectEmailPreviewResponseEmail,
  setEmailPreviewStage,
  setEmailPreviewToken,
  setPreviewConversationId,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import {
  ForethoughtPreviewInteractiveEmailClickedEvent,
  isForethoughtPreviewInteractiveEmailClickedEvent,
} from 'src/types/solveWidgetEventTypes';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';

export const PreviewEmailTemplate = () => {
  const dispatch = useDispatch();
  const emailString = useSelector(selectEmailPreviewResponseEmail);
  const { contextVariables } = useGetContextVariables({
    shouldIncludeWorkflowTagContextVariable: false,
  });

  const emailResponse = useMemo(() => {
    if (!contextVariables.length) {
      return null;
    }
    return contextVariableAliasHtml({
      contextVariables,
      html: emailString,
      undefinedContextVariableIds: [],
    });
  }, [contextVariables, emailString]);

  useEffect(() => {
    const handleMessage = async (
      e: ForethoughtPreviewInteractiveEmailClickedEvent,
    ) => {
      if (isForethoughtPreviewInteractiveEmailClickedEvent(e)) {
        try {
          const token = e.data.token;
          const response =
            await getWidgetConversationForInteractiveEmailPreview(token);

          dispatch(setPreviewConversationId(response.conversation_id || ''));
          dispatch(setEmailPreviewToken(token));
          dispatch(setEmailPreviewStage('interactive-widget'));
        } catch {
          dispatch(
            setGlobalToastOptions({
              autoHideDuration: 4000,
              title:
                'Error redirecting to the interactive email widget, please try again.',
              variant: 'danger',
            }),
          );
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [dispatch, emailResponse]);

  if (!emailResponse) {
    return <Typography variant='font14Medium'>Not found</Typography>;
  }

  return (
    <Box
      alignSelf='center'
      bgcolor='white'
      border='1px solid rgba(0, 0, 0, 0.10)'
      boxShadow='0px 12px 48px 0px rgba(0, 0, 0, 0.10)'
      display='flex'
      flexDirection='column'
      margin='auto'
      padding='30px 80px'
      width='700px'
    >
      <AutosizeIframe padding={20} srcDoc={emailResponse} />
    </Box>
  );
};
