import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserRequestBody } from 'src/services/apiInterfaces';
import * as API from 'src/services/apiV1';

export const getUsers = createAsyncThunk('team/getUsers', () => API.getUsers());

export const updateUser = createAsyncThunk(
  'team/updateUser',
  ({
    userId,
    userRequestBody,
  }: {
    userId: string;
    userRequestBody: UserRequestBody;
  }) => API.updateUser(userId, userRequestBody),
);

export const createUser = createAsyncThunk(
  'team/createUser',
  ({ userRequestBody }: { userRequestBody: UserRequestBody }) =>
    API.createUser(userRequestBody),
);

export const deleteUser = createAsyncThunk(
  'team/deleteUser',
  (userId: string) => API.deleteUser(userId),
);
