import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import { useLocation } from 'react-router';

import TextWithInfo from '../common//TextWithInfo';
import FilterSelect from '../common/FilterSelect';
import TriageModelTable from '../common/TriageModelTable';
import ValueCell from '../common/ValueCell';
import { labelInfoMap } from '../constants';
import { ModelLabelKey, TriageModelLabel } from '../types';
import LabelsTableControl, { LabelControlOptions } from './LabelsTableControl';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { DateRange } from 'src/types/types';

interface LabelsTableProps {
  areRowsFetching: boolean;
  dateRange: DateRange;
  isTableDataLoading: boolean;
  labelControlOptions: LabelControlOptions;
  labels: TriageModelLabel[];
  model: VersionedTriageModel;
  onDateRangeChange: (value: DateRange) => void;
  viewOnly?: boolean;
}

const tableColumns: {
  key: ModelLabelKey;
  size?: number;
  title: string;
  tooltip: string | null;
}[] = [
  {
    key: 'name',
    size: 300,
    title: 'Labels',
    tooltip: null,
  },
  {
    key: 'description',
    size: 300,
    title: 'Description',
    tooltip: null,
  },
  {
    key: 'predicted_count',
    size: 240,
    title: labelInfoMap.predicted_count.key,
    tooltip: labelInfoMap.predicted_count.tooltip,
  },
  {
    key: 'pending_count',
    size: 240,
    title: labelInfoMap.pending_count.key,
    tooltip: labelInfoMap.pending_count.tooltip,
  },
  {
    key: 'finalized_count',
    size: 240,
    title: labelInfoMap.finalized_count.key,
    tooltip: labelInfoMap.finalized_count.tooltip,
  },
  {
    key: 'correct_count',
    size: 300,
    title: labelInfoMap.correct_count.key,
    tooltip: labelInfoMap.correct_count.tooltip,
  },
  {
    key: 'accuracy',
    size: 240,
    title: labelInfoMap.avg_accuracy.key,
    tooltip: labelInfoMap.avg_accuracy.tooltip,
  },
  {
    key: 'last_update_at',
    size: 200,
    title: 'Last update',
    tooltip: null,
  },
];

const filterOptions = [
  {
    label: 'All coverage',
    options: [
      {
        label: 'Above 80% coverage',
        value: 'above_80',
      },
      {
        label: 'Below 80% coverage',
        value: 'below_80',
      },
    ],
    value: 'all_coverage',
  },
  {
    label: 'All accuracy',
    options: [
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Above 80% accuracy',
        value: 'above_80',
      },
      {
        label: 'Below 80% accuracy',
        value: 'below_80',
      },
    ],
    value: 'all_accuracy',
  },
];

const percentages: Array<Partial<ModelLabelKey>> = ['accuracy', 'coverage'];

const LabelsTable = ({
  areRowsFetching,
  dateRange,
  isTableDataLoading,
  labelControlOptions,
  labels,
  model,
  onDateRangeChange,
  viewOnly,
}: LabelsTableProps) => {
  const location = useLocation();
  const isSimulation = location.pathname.includes('simulations');
  const [filters, setFilters] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const updatedTableColumns = useMemo(() => {
    let columns = [...tableColumns];
    if (isSimulation) {
      columns = columns.filter(
        item =>
          item.key !== 'last_update_at' &&
          item.key !== 'pending_count' &&
          item.key !== 'correct_count' &&
          item.key !== 'accuracy',
      );
      columns.push({
        key: 'recall',
        size: 200,
        title: 'Recall',
        tooltip: null,
      });
      columns.push({
        key: 'accuracy',
        size: 240,
        title: labelInfoMap.avg_accuracy.key,
        tooltip: labelInfoMap.avg_accuracy.tooltip,
      });
    }
    return columns;
  }, [isSimulation]);

  const columnHelper = createMRTColumnHelper<TriageModelLabel>();
  const dateRangeFilter = {
    onChange: onDateRangeChange,
    value: dateRange,
  };
  const columns = useMemo(() => {
    return updatedTableColumns.map(item => {
      return columnHelper.accessor(item.key, {
        Cell: ({ row }: { row: MRT_Row<TriageModelLabel> }) => {
          return (
            <ValueCell
              isLoading={isTableDataLoading || areRowsFetching}
              isPercentage={percentages.includes(item.key)}
              itemKey={item.key}
              value={row.original[item.key]}
            />
          );
        },
        header: item.title,
        Header: (
          <TextWithInfo
            textColor={['grey', 800]}
            title={item.title}
            tooltip={item.tooltip || ''}
            variant='font14Bold'
          />
        ),
        size: item.size,
      });
    });
  }, [columnHelper, isTableDataLoading, areRowsFetching, updatedTableColumns]);

  const rows = useMemo(() => {
    if (!searchText) {
      return labels;
    }
    return labels.filter(item => {
      const text = searchText.toLowerCase();
      const hasName = item.name.toLowerCase().includes(text);
      const hasDescription = item.description.toLowerCase().includes(text);

      return hasName || hasDescription;
    });
  }, [searchText, labels]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const loading = isTableDataLoading || areRowsFetching;

  return (
    <TriageModelTable<TriageModelLabel, number | string | null>
      columns={columns}
      Control={
        !viewOnly ? (
          <LabelsTableControl
            labelControlOptions={labelControlOptions}
            model={model}
          />
        ) : null
      }
      data={rows}
      dateRangeFilter={isSimulation ? undefined : dateRangeFilter}
      FilterComponent={
        !viewOnly ? (
          <FilterSelect
            menuTitle='Filter by'
            onChange={setFilters}
            options={filterOptions}
            value={filters}
          />
        ) : null
      }
      initialSorting={[{ desc: true, id: 'name' }]}
      initialState={{
        columnPinning: {
          left: ['name'],
        },
      }}
      isLoading={loading}
      onSearch={handleSearch}
      options={{
        muiTableBodyRowProps: ({
          row,
        }: {
          row: { original: { tag_id: string } };
        }) => ({
          onClick: () => {
            if (loading || viewOnly) {
              return;
            }
            // eslint-disable-next-line react/prop-types
            const tagId: string = row.original.tag_id;
            labelControlOptions.selectTagId(tagId);
          },
          sx: {
            cursor: loading || viewOnly ? undefined : 'pointer',
          },
        }),
      }}
      renderEmptyRowsFallback={() => <div></div>}
    />
  );
};

export default LabelsTable;
