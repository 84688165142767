import { useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { actionToDisplayName, actionToIconURL } from './constants';
import { GenerateResponseActionType } from 'src/types/workflowBuilderAPITypes';

interface ResponseGenerationModalBodyProps {
  /** Action executed by the user */
  action: GenerateResponseActionType;
  /** Generated response */
  generatedResponse: string;
}

const ResponseGenerationModalBody = ({
  action,
  generatedResponse,
}: ResponseGenerationModalBodyProps) => {
  const { palette } = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (generatedResponse && containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
      });
    }
  }, [generatedResponse]);

  return (
    <Box
      bgcolor={palette.colors.white}
      height='fit-content'
      maxHeight='300px'
      overflow='auto'
      p='16px'
      ref={containerRef}
      textAlign='left'
      whiteSpace='pre-wrap'
    >
      <Box alignItems='center' display='flex' gap='5px' marginBottom='5px'>
        {action !== 'rephrase' && (
          <>
            <Box position='relative' top='3px'>
              <ImgFill src={actionToIconURL[action]} />
            </Box>
            <Typography color={palette.colors.grey[500]} variant='font11'>
              {actionToDisplayName[action]}
            </Typography>
          </>
        )}
      </Box>
      <Typography color={palette.colors.black} variant='font14'>
        {generatedResponse.trimStart()}
      </Typography>
    </Box>
  );
};

export default ResponseGenerationModalBody;

const ImgFill = styled(ReactSVG)`
  stroke-width: 0;
  path {
    fill: ${({ theme }) => theme.palette.colors.grey[500]};
  }
`;
