import { useState } from 'react';
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';

import {
  Button,
  DateRangeFilterButton,
  Dialog,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { dateRangeToTimestamp } from '../solve-insights/helpers';
import {
  GetModelsAndVersionsResponse,
  TriageSimulateRequest,
} from 'src/reducers/triageSettingsReducer/types';
import {
  useGetModelsAndVersionsQuery,
  useSimulateTriageTicketsMutation,
} from 'src/services/triage/triageApi';
import { DateRange } from 'src/types/types';
import {
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

const TriageCreateRun = () => {
  const { palette } = useTheme();
  const {
    data: modelAndVersionData = [],
    isFetching,
    isLoading,
  } = useGetModelsAndVersionsQuery();
  const [testSelfServeModel] = useSimulateTriageTicketsMutation();
  const [isOpen, setIsOpen] = useState(false);

  const [testName, setTestName] = useState<string>('');
  const [selectedModelId, setSelectedModelId] = useState<string>('');
  const [selectedVersionId, setSelectedVersionId] = useState<string>('');
  const [testType, setTestType] = useState<string>('dateRange');
  const [dateRange, setDateRange] = useState<DateRange>(last30DaysTimeRange);
  const [ticketIds, setTicketIds] = useState<string>('');
  const modelList = modelAndVersionData.map(item => {
    return { label: item.model_name, value: item.model_id };
  });
  const filteredModels = modelAndVersionData
    .map(item => {
      if (item.model_id === selectedModelId) {
        return item;
      }
    })
    .filter(Boolean) as GetModelsAndVersionsResponse[];
  const versionsFromFilteredModels = filteredModels.map(item => {
    return { label: item.version_name, value: item.version_id };
  });

  const handleDisable = () => {
    const isFieldsFilledBeforeType = !!selectedModelId && !!selectedVersionId;
    const isTestTypeFilled =
      testType === 'dateRange' || (testType === 'ids' && !!ticketIds);
    return !(isFieldsFilledBeforeType && isTestTypeFilled);
  };

  const handleOnClose = () => {
    setTestName('');
    setSelectedModelId('');
    setSelectedVersionId('');
    setTestType('dateRange');
    setDateRange(last30DaysTimeRange);
    setTicketIds('');

    setIsOpen(false);
  };

  const handleClick = () => {
    let ticketIdsToArray: string[] = [];
    if (ticketIds) {
      // remove white space
      const cleanTicketIdString = ticketIds.replaceAll(/\s/g, '');
      ticketIdsToArray = cleanTicketIdString.split(',');
    }
    const timestamp = dateRangeToTimestamp(dateRange);
    const payload: TriageSimulateRequest = {
      end_datetime: timestamp.end_timestamp || null,
      model_id: selectedModelId,
      name: testName,
      start_datetime: timestamp.start_timestamp || null,
      ticket_ids: ticketIdsToArray,
      version_id: selectedVersionId,
    };
    testSelfServeModel(payload);
    // Close and refresh table
    handleOnClose();
  };

  if (isFetching || isLoading) {
    return <></>;
  }

  return (
    <Box display='flex' justifyContent='flex-end'>
      <Button onClick={() => setIsOpen(true)} variant='secondary'>
        Create a new simulation
      </Button>
      <Dialog onClose={handleOnClose} open={isOpen} title='Create simulation'>
        <Box display='flex' flexDirection='column' rowGap={2} width={600}>
          <TextField
            label='Test name'
            onChange={e => {
              const { value } = e.target;
              setTestName(value);
            }}
            placeholder='Give it a name'
            value={testName}
          />
          <SelectDropdown
            id='model_selector'
            label='Model'
            menuMaxHeight={300}
            onChange={e => {
              setSelectedModelId(e.target.value);
              setSelectedVersionId('');
            }}
            options={modelList}
            placeholder='Select model'
            showRequiredLabel
            value={selectedModelId}
          />
          <SelectDropdown
            id='version_selector'
            label='Version'
            menuMaxHeight={300}
            onChange={e => {
              setSelectedVersionId(e.target.value);
            }}
            options={versionsFromFilteredModels}
            placeholder='Select version'
            showRequiredLabel
            value={selectedVersionId}
          />
          <Box component='label' htmlFor='test-by'>
            <Typography variant='font16Bold'>Test by</Typography>
          </Box>
          <RadioGroup
            aria-labelledby='test-by'
            onChange={e => setTestType(e.target.value)}
            value={testType}
          >
            <FormControlLabel
              control={<Radio size='small' />}
              label='Date range'
              name='testBy'
              sx={{ fontSize: 14 }}
              value='dateRange'
            />
            {testType === 'dateRange' && (
              <Box pl='28px'>
                <DateRangeFilterButton
                  onChange={dateRange => setDateRange(dateRange)}
                  options={datePickerRangeOptionsRevamp}
                  size='medium'
                  value={dateRange}
                />
              </Box>
            )}
            <Divider
              sx={{
                background: palette.colors.slate[200],
                margin: '12px 0 12px 28px',
              }}
            />
            <FormControlLabel
              control={<Radio size='small' />}
              label='Ticket IDs'
              name='testBy'
              sx={{ fontSize: 14, mb: '12px' }}
              value='ids'
            />
            {testType === 'ids' && (
              <Box pl='28px' width='100%'>
                <TextField
                  aria-label='ticket ids'
                  name='ticketIds'
                  onChange={e => setTicketIds(e.target.value)}
                  placeholder='Enter a comma-separated list of helpdesk ticket IDs'
                  value={ticketIds}
                />
              </Box>
            )}
          </RadioGroup>
          <Box display='flex' justifyContent='flex-end'>
            <Button
              disabled={handleDisable()}
              onClick={handleClick}
              variant='main'
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default TriageCreateRun;
