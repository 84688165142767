/* eslint-disable react/prop-types -- false warnings for column definitions */

import { useCallback, useMemo } from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import moment from 'moment';
import { Box, styled, useTheme } from '@mui/material';
import { IconInfoCircle, IconTag } from '@tabler/icons-react';

import {
  EllipsisWithTooltip,
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  getWorkflowIdByChannel,
  getWorkflowType,
  IntentTableDataFilter,
} from '../helper';
import { useSolveMetricsQueryParams } from '../hooks/useSolveMetricsQueryParams';
import { useWidgetMetricBreakdownById } from '../hooks/useWidgetMetricBreakdownById';
import {
  DropdownMenu,
  IntentTitleTag,
  IntentWorkflowMenuItem,
  StatusIcon,
} from '../intent-title-tags/IntentTitleTags';
import IntentWorkflowsTable from '../IntentWorkflowsTable';
import { PaletteTooltip } from '../PaletteTooltip';
import {
  IntentDataWithTopics,
  IntentWorkflowsTableContainerProps,
} from '../types';
import { formatNumberWithCommas } from '../utils';
import dynamicArticleSuggestionIcon from 'src/assets/images/dynamic-article-suggestion-revamped.svg';
import effectivenessIcon from 'src/assets/images/effectiveness-icon.svg';
import iconFallbackColorful from 'src/assets/images/fallback-colorful.svg';
import needsAdjustmentIcon from 'src/assets/images/needs-adjustment-icon.svg';
import hasSurgeIcon from 'src/assets/images/surge-icon.svg';
import DiscoverRealizedImpactTopicMenu from 'src/components/dashboard-pages/discover-automation-page/discover-realized-impact-tab/DiscoverRealizedImpactTopicMenu';
import IconWithTooltip from 'src/components/reusable-components/tooltip/tooltip';
import { SHARED_METRIC_DEFINITIONS, TEMP_BLUE } from 'src/constants/solve';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetActionUsagesByEntityId } from 'src/pages/action-builder/hooks';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import {
  useGetActionBuilderActionsQuery,
  useGetContextVariableUsagesQuery,
} from 'src/services/action-builder/actionBuilderApi';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  useGetGoToIntentUsagesQuery,
  useGetIntentWorkflowMetadataQuery,
} from 'src/services/workflow-builder-metrics';
import {
  BreakdownData,
  IntentData,
  IntentWorkflowMetadata,
} from 'src/types/workflowBuilderAPITypes';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';
import { CommonIntentWorkflowType } from 'src/utils/enums';
import { formatNToPercentageDecimal } from 'src/utils/formatToPercentage';
import { isIntentActiveForChannel } from 'src/utils/solve/intentUtils';

const buildLastUpdatedAt = (metadata: Partial<IntentWorkflowMetadata>) => {
  if (!metadata.modified_by || !metadata.modified_date) {
    return '';
  }
  const modifiedAt = new Date(metadata.modified_date);
  const modifiedAtDate = moment(modifiedAt).format('MMM D, YYYY h:mm A');
  const modifiedBy = metadata.modified_by?.includes('forethought')
    ? 'admin@forethought.ai'
    : metadata.modified_by;

  return `${modifiedAtDate} by ${modifiedBy}`;
};

export default function WidgetIntentWorkflowsTableContainer({
  workflowTagFilterValue,
  ...props
}: IntentWorkflowsTableContainerProps) {
  const { channel } = useSolveMetricsQueryParams();
  const { breakdownById, isFetching } = useWidgetMetricBreakdownById(
    workflowTagFilterValue,
  );
  const { data: featureFlagsResponse } = useGetFeatureFlagsQuery();
  const isCSATEnabled =
    featureFlagsResponse?.feature_flags.includes('landing_page_csat');
  const isFallbackWorkflowEnabled =
    featureFlagsResponse?.feature_flags.includes('fallback_workflow');
  const getCSATColor = useGetCSATColor();
  const { palette } = useTheme();
  const { data: goToIntentUsagesData, isFetching: isGoToIntentUsagesFetching } =
    useGetGoToIntentUsagesQuery();
  const { usages: goToIntentUsages = [] } = goToIntentUsagesData ?? {};
  const { data: actionsData } = useGetActionBuilderActionsQuery({
    shouldReturnAll: true,
  });
  const { isLoading: isLoadingActionUsages } = useGetActionUsagesByEntityId();
  const { isLoading: isLoadingCvUsages } = useGetContextVariableUsagesQuery();
  const { contextVariables } = useGetContextVariables();
  const { isLoading: isLoadingMetadata } = useGetIntentWorkflowMetadataQuery();

  const widgetFilter = useCallback(
    (filter: IntentTableDataFilter<IntentDataWithTopics>) => {
      const data = filter.getTableData().map(data => ({
        ...data,
        workflowTags: breakdownById[data.intent_workflow_id]?.workflow_tags,
      }));

      if (!workflowTagFilterValue?.length) {
        return data;
      }

      return data.filter(({ intent_workflow_id }) =>
        Boolean(
          breakdownById[intent_workflow_id]?.workflow_tags?.find(tag =>
            workflowTagFilterValue.includes(tag),
          ),
        ),
      );
    },
    [workflowTagFilterValue, breakdownById],
  );

  const getRowByWorkflowId = useCallback(
    (
      intentData: IntentData,
      breakdownById: Record<string, BreakdownData> | null,
    ): BreakdownData | Record<string, never> => {
      return (
        breakdownById?.[getWorkflowIdByChannel(channel, intentData) || ''] || {}
      );
    },
    [channel],
  );

  const columnHelper = createMRTColumnHelper<IntentDataWithTopics>();

  const materialReactTableColumns = useMemo(() => {
    return [
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const type = getWorkflowType(
            row.original,
            channel,
            isFallbackWorkflowEnabled,
          );

          const getIcon = () => {
            if (type === 'Fallback') {
              return (
                <img alt='' height='24' src={iconFallbackColorful} width='24' />
              );
            }

            if (type === 'Knowledge Retrieval') {
              return (
                <img
                  alt=''
                  height='24'
                  src={dynamicArticleSuggestionIcon}
                  width='24'
                />
              );
            }

            return <></>;
          };

          return (
            <Box alignItems='center' display='flex' gap='8px'>
              {getIcon()}
              {type}
            </Box>
          );
        },
        enableSorting: false,
        header: 'Type',
        id: 'type',
        size: 215,
      }),
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intent = row.original;

          const { has_surge: hasSurge, inquiries = 0 } = getRowByWorkflowId(
            intent,
            breakdownById,
          );

          return (
            <DataCell>
              {isFetching ? (
                <Skeleton width='60px' />
              ) : (
                <Box display='flex' width='100px'>
                  {hasSurge && (
                    <IconWithTooltip
                      icon={hasSurgeIcon}
                      margin='0'
                      size='20px'
                      tooltipText='There has been a significant surge in inquiries with this intent in this period'
                    />
                  )}
                  {formatNumberWithCommas(inquiries)}
                </Box>
              )}
            </DataCell>
          );
        },
        header: 'Chats',
        id: 'inquiries',
        sortingFn: (rowA, rowB) => {
          const { inquiries: inquiriesA = 0 } = getRowByWorkflowId(
            rowA.original,
            breakdownById,
          );
          const { inquiries: inquiriesB = 0 } = getRowByWorkflowId(
            rowB.original,
            breakdownById,
          );
          return inquiriesA - inquiriesB;
        },
      }),
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intent = row.original;

          const { deflected = 0 } = getRowByWorkflowId(intent, breakdownById);

          return (
            <Box display='flex' width='100px'>
              <span>
                {isFetching ? (
                  <Skeleton width='60px' />
                ) : (
                  formatNumberWithCommas(deflected)
                )}
              </span>
            </Box>
          );
        },
        header: 'Deflected',
        id: 'deflected',
        sortingFn: (rowA, rowB) => {
          const { deflected: deflectedA = 0 } = getRowByWorkflowId(
            rowA.original,
            breakdownById,
          );
          const { deflected: deflectedB = 0 } = getRowByWorkflowId(
            rowB.original,
            breakdownById,
          );
          return deflectedA - deflectedB;
        },
      }),
      columnHelper.accessor(row => row, {
        Cell: ({ row }) => {
          const intent = row.original;

          const {
            deflection_rate: deflectionRate = 0,
            is_effective: isEffective,
            needs_adjustment: needsAdjustment,
          } = getRowByWorkflowId(intent, breakdownById);

          return (
            <DataCell>
              {isFetching ? (
                <Skeleton width='60px' />
              ) : (
                <Box display='flex' width='180px'>
                  {isEffective && (
                    <IconWithTooltip
                      icon={effectivenessIcon}
                      margin='0'
                      size='20px'
                      tooltipText='This workflow has been effective in deflecting conversations'
                    />
                  )}
                  {needsAdjustment && (
                    <IconWithTooltip
                      icon={needsAdjustmentIcon}
                      margin='0'
                      size='20px'
                      tooltipText='Consider adjusting this workflow to make it more effective'
                    />
                  )}
                  {formatNToPercentageDecimal(deflectionRate)}
                </Box>
              )}
            </DataCell>
          );
        },
        header: 'Deflection rate',
        id: 'deflection_rate',
        sortingFn: (rowA, rowB) => {
          const { deflection_rate: deflectionRateA = 0 } = getRowByWorkflowId(
            rowA.original,
            breakdownById,
          );
          const { deflection_rate: deflectionRateB = 0 } = getRowByWorkflowId(
            rowB.original,
            breakdownById,
          );
          return deflectionRateA - deflectionRateB;
        },
      }),
      ...(isCSATEnabled
        ? [
            columnHelper.accessor(row => row, {
              Cell: ({ row }) => {
                const { avg_csat: avgCSAT } = getRowByWorkflowId(
                  row.original,
                  breakdownById,
                );

                return (
                  <Box display='flex'>
                    <Typography
                      color={getCSATColor(avgCSAT)}
                      variant='font14Medium'
                    >
                      {formatCSAT(avgCSAT)}
                    </Typography>
                  </Box>
                );
              },
              grow: false,
              header: 'CSAT',
              size: 180,
              sortingFn: (rowA, rowB) => {
                const { avg_csat: csatA } = getRowByWorkflowId(
                  rowA.original,
                  breakdownById,
                );
                const { avg_csat: csatB } = getRowByWorkflowId(
                  rowB.original,
                  breakdownById,
                );
                return (csatA ?? 0) - (csatB ?? 0);
              },
            }),
          ]
        : []),
      ...[
        columnHelper.accessor(row => row, {
          Cell: ({ row }) => {
            const {
              intent_definition_id: intentDefinitionId,
              workflowTags = [],
            } = row.original;
            const isGeneralHandoff =
              intentDefinitionId === CommonIntentWorkflowType.GENERAL_HANDOFF;

            return !isGeneralHandoff && workflowTags.length > 0 ? (
              <IntentTitleTag
                Menu={DropdownMenu}
                MenuProps={{
                  menuItems: workflowTags.map(tag => (
                    <>
                      <Box alignItems='center' display='flex' mr={1}>
                        <IconTag color={palette.colors.blue[500]} size={16} />
                      </Box>
                      <Typography
                        color={palette.colors.black}
                        noWrap
                        variant='font14'
                      >
                        {tag}
                      </Typography>
                    </>
                  )),
                  title: 'Tags',
                }}
                variant='workflowTag'
              >
                <Typography noWrap variant='font12Medium'>
                  {workflowTags[0]}
                </Typography>
              </IntentTitleTag>
            ) : (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          },
          enableSorting: false,
          header: 'Tags',
          id: 'tags',
        }),
        columnHelper.accessor(row => row, {
          Cell: ({ row }) => {
            const cvUsages = row.original.contextVariables[channel].map(cv =>
              contextVariables.find(
                contextVariable => contextVariable.context_variable_id === cv,
              ),
            );

            if (isLoadingCvUsages) {
              return <Skeleton width='60px' />;
            }

            const text = cvUsages
              .map(cvUsage =>
                cvUsage?.context_variable_name
                  ? `$${cvUsage.context_variable_name}`
                  : '',
              )
              .join(' ');

            return cvUsages.length > 0 ? (
              <Box maxWidth='100%'>
                <Typography color={palette.colors.black} variant='font14'>
                  <EllipsisWithTooltip tooltipContent={text}>
                    {text}
                  </EllipsisWithTooltip>
                </Typography>
              </Box>
            ) : (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          },
          enableSorting: false,
          header: 'Context variables',
          id: 'context_variables_in_use',
        }),
        columnHelper.accessor(row => row, {
          Cell: ({ row }) => {
            const actionUsages = row.original.actions[channel].map(id =>
              actionsData?.actions.find(action => action.action_id === id),
            );

            if (isLoadingActionUsages) {
              return <Skeleton width='60px' />;
            }

            return actionUsages.length > 0 ? (
              <IntentTitleTag
                Menu={DropdownMenu}
                MenuProps={{
                  menuItems: actionUsages.map(action => (
                    <>
                      <Box alignItems='center' display='flex' mr={1}>
                        <StatusIcon color={palette.colors.blue[500]} />
                      </Box>
                      <Typography
                        color={palette.colors.black}
                        noWrap
                        variant='font14'
                      >
                        {action?.action_name}
                      </Typography>
                    </>
                  )),
                  title: 'Actions',
                }}
                propIcon={<StatusIcon color={palette.colors.blue[500]} />}
                variant='workflowTag'
              >
                <Typography noWrap variant='font12Medium'>
                  {actionUsages[0]?.action_name}
                </Typography>
              </IntentTitleTag>
            ) : (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          },
          enableSorting: false,
          header: 'Actions',
          id: 'actions_in_use',
        }),
        columnHelper.accessor(row => row, {
          Cell: ({ row }) => {
            const {
              api_workflow_id: apiWorkflowId,
              intent_workflow_id: intentWorkflowId,
              is_handoff: isHandoff,
            } = row.original;

            if (isGoToIntentUsagesFetching) {
              return <Skeleton width='60px' />;
            }

            const intentWorkflowsUsingIntentWorkflow =
              goToIntentUsages.find(usage =>
                channel === 'api'
                  ? usage.intent_workflow_id === apiWorkflowId
                  : usage.intent_workflow_id === intentWorkflowId,
              )?.intent_workflow_ids_using_intent_workflow ?? [];

            return intentWorkflowsUsingIntentWorkflow.length > 0 ? (
              <IntentTitleTag
                Menu={DropdownMenu}
                MenuProps={{
                  goToIntents: intentWorkflowsUsingIntentWorkflow,
                  hoverBackgroundColor: palette.colors.purple[100],
                  menuItems: intentWorkflowsUsingIntentWorkflow.map(
                    intentWorkflowId => (
                      <IntentWorkflowMenuItem
                        channel={channel}
                        intentWorkflowId={intentWorkflowId}
                        key={intentWorkflowId}
                      />
                    ),
                  ),
                  title: `Workflows using this ${
                    isHandoff ? 'handoff' : 'intent'
                  }`,
                }}
                variant='goToIntent'
              />
            ) : (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          },
          enableSorting: false,
          header: 'Workflows in use',
          id: 'workflows_in_use',
        }),

        columnHelper.accessor(row => row, {
          Cell: ({ row }) => {
            const { intent_definition_id: intentDefinitionId, topics } =
              row.original;
            const isGeneralHandoff =
              intentDefinitionId === CommonIntentWorkflowType.GENERAL_HANDOFF;

            return !isGeneralHandoff && topics.length > 0 ? (
              <IntentTitleTag
                Menu={DiscoverRealizedImpactTopicMenu}
                MenuProps={{
                  title: 'Discover topics associated with this workflow',
                  topics,
                }}
                variant='topic'
              >
                <Box
                  sx={{
                    maxWidth: '200px',
                    minWidth: 0,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography noWrap variant='font12Medium'>
                    {deriveTopicNameFromTopic(topics[0])}
                  </Typography>
                </Box>
              </IntentTitleTag>
            ) : (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          },
          enableSorting: false,
          header: 'Topics',
          id: 'topics',
        }),
        columnHelper.accessor(row => row, {
          Cell: ({ row }) => {
            const hasUnsavedChanges =
              row.original.metadata[channel]?.unsaved_changes;
            const isActive = isIntentActiveForChannel(
              row.original.active_workflow_types,
              channel,
            );

            if (isLoadingMetadata) {
              return <Skeleton width='60px' />;
            }

            if (!isActive && !hasUnsavedChanges) {
              return <StatusBadge variant='draft' />;
            }

            return (
              <Box display='flex' gap={1}>
                {isActive && <StatusBadge variant='published' />}
                {hasUnsavedChanges && <StatusBadge variant='draft' />}
              </Box>
            );
          },
          enableSorting: false,
          header: 'Status',
          Header: (
            <Box alignItems='center' display='flex'>
              Status{' '}
              <Tooltip tooltipContent={<StatusTooltipMessage />}>
                <IconInfoCircle color='currentColor' height={16} width={16} />
              </Tooltip>
            </Box>
          ),
          id: 'status',
        }),
        columnHelper.accessor(row => row, {
          Cell: ({ row }) => {
            const metadata = row.original.metadata[channel];
            const lastUpdatedString = buildLastUpdatedAt(metadata);

            if (isLoadingMetadata) {
              return <Skeleton width='60px' />;
            }

            return lastUpdatedString ? (
              <Box maxWidth='100%'>
                <Typography color={palette.colors.black} variant='font14'>
                  <EllipsisWithTooltip tooltipContent={lastUpdatedString}>
                    {lastUpdatedString}
                  </EllipsisWithTooltip>
                </Typography>
              </Box>
            ) : (
              <Typography color={palette.colors.grey[500]} variant='font14'>
                {NA}
              </Typography>
            );
          },
          enableSorting: false,
          header: 'Last updated',
          id: 'last_updated',
        }),
      ],
    ];
  }, [
    columnHelper,
    isCSATEnabled,
    channel,
    isFallbackWorkflowEnabled,
    getRowByWorkflowId,
    breakdownById,
    isFetching,
    getCSATColor,
    palette.colors.grey,
    palette.colors.blue,
    palette.colors.black,
    palette.colors.purple,
    isLoadingCvUsages,
    contextVariables,
    isLoadingActionUsages,
    actionsData?.actions,
    isGoToIntentUsagesFetching,
    goToIntentUsages,
    isLoadingMetadata,
  ]);

  return (
    <IntentWorkflowsTable
      {...props}
      channelColumns={[
        {
          cellRenderer: intent => {
            const { has_surge: hasSurge, inquiries = 0 } = getRowByWorkflowId(
              intent,
              breakdownById,
            );

            return (
              <DataCell>
                {isFetching ? (
                  <Skeleton width='60px' />
                ) : (
                  <Box display='flex' width='100px'>
                    {hasSurge && (
                      <IconWithTooltip
                        icon={hasSurgeIcon}
                        margin='0'
                        size='20px'
                        tooltipText='There has been a significant surge in inquiries with this intent in this period'
                      />
                    )}
                    {formatNumberWithCommas(inquiries)}
                  </Box>
                )}
              </DataCell>
            );
          },
          comparatorFunction: (a, b) => {
            const { inquiries: inquiriesA } = getRowByWorkflowId(
              a,
              breakdownById,
            );
            const { inquiries: inquiriesB } = getRowByWorkflowId(
              b,
              breakdownById,
            );
            return inquiriesB - inquiriesA;
          },
          id: 'inquiries',
          label: 'Chats',
          width: '100px',
        },
        {
          cellRenderer: intent => {
            const { deflected = 0 } = getRowByWorkflowId(intent, breakdownById);

            return (
              <Box display='flex' width='100px'>
                <span>
                  {isFetching ? (
                    <Skeleton width='60px' />
                  ) : (
                    formatNumberWithCommas(deflected)
                  )}
                </span>
              </Box>
            );
          },
          comparatorFunction: (a, b) => {
            const { deflected: deflectedA } = getRowByWorkflowId(
              a,
              breakdownById,
            );
            const { deflected: deflectedB } = getRowByWorkflowId(
              b,
              breakdownById,
            );
            return deflectedB - deflectedA;
          },
          id: 'deflected',
          label: 'Deflected',
          width: '100px',
        },
        {
          cellRenderer: intent => {
            const {
              deflection_rate: deflectionRate = 0,
              is_effective: isEffective,
              needs_adjustment: needsAdjustment,
            } = getRowByWorkflowId(intent, breakdownById);

            return (
              <DataCell>
                {isFetching ? (
                  <Skeleton width='60px' />
                ) : (
                  <Box display='flex' width='180px'>
                    {isEffective && (
                      <IconWithTooltip
                        icon={effectivenessIcon}
                        margin='0'
                        size='20px'
                        tooltipText='This workflow has been effective in deflecting conversations'
                      />
                    )}
                    {needsAdjustment && (
                      <IconWithTooltip
                        icon={needsAdjustmentIcon}
                        margin='0'
                        size='20px'
                        tooltipText='Consider adjusting this workflow to make it more effective'
                      />
                    )}
                    {formatNToPercentageDecimal(deflectionRate)}
                  </Box>
                )}
              </DataCell>
            );
          },
          comparatorFunction: (a, b) => {
            const { deflection_rate: deflectionRateA } = getRowByWorkflowId(
              a,
              breakdownById,
            );
            const { deflection_rate: deflectionRateB } = getRowByWorkflowId(
              b,
              breakdownById,
            );
            return deflectionRateB - deflectionRateA;
          },
          id: 'deflection_rate',
          label: 'Deflection rate',
          width: '180px',
        },
      ]}
      channelMaterialReactTableColumns={materialReactTableColumns}
      tableFilterCallback={widgetFilter}
      workflowTagFilterValue={workflowTagFilterValue}
    />
  );
}

export const DataCell = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const HeaderCell = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export function RelevanceTooltip({ text }: { text?: string }) {
  const { palette } = useTheme();

  return (
    <PaletteTooltip
      text={text ? text : SHARED_METRIC_DEFINITIONS.relevance.tooltip}
      values={[
        {
          color: TEMP_BLUE,
          label: 'Relevant',
        },
        {
          color: palette.colors.blue[400],
          label: 'Somewhat relevant',
        },
        { color: palette.colors.red[500], label: 'Irrelevant' },
      ]}
    />
  );
}

export function UserEngagementTooltip({ text }: { text?: string }) {
  const { palette } = useTheme();

  return (
    <PaletteTooltip
      text={text ? text : SHARED_METRIC_DEFINITIONS.userEngagement.tooltip}
      values={[
        { color: TEMP_BLUE, label: 'Yes' },
        { color: palette.colors.red[500], label: 'No' },
      ]}
    />
  );
}

const StatusBadge = ({ variant }: { variant: 'published' | 'draft' }) => {
  const theme = useTheme();
  return (
    <Box
      alignItems='center'
      bgcolor={
        variant === 'draft'
          ? theme.palette.colors.grey[500]
          : theme.palette.colors.green[500]
      }
      borderRadius='4px'
      display='flex'
      gap='4px'
      padding='4px 8px'
      textTransform='capitalize'
    >
      <Typography color={theme.palette.colors.white} noWrap variant='font11'>
        {variant}
      </Typography>
    </Box>
  );
};

const StatusTooltipMessage = () => {
  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <Box display='flex'>
        <StatusBadge variant='published' />
      </Box>
      <Typography color='white' variant='font14'>
        The workflow content represents what is visible to customers in the
        widget when the workflow is activated.
      </Typography>
      <Box display='flex'>
        <StatusBadge variant='draft' />
      </Box>
      <Typography color='white' variant='font14'>
        The workflow content is not visible to customers.
      </Typography>
      <Box display='flex' gap={1}>
        <StatusBadge variant='published' />
        <StatusBadge variant='draft' />
      </Box>
      <Typography color='white' variant='font14'>
        The workflow is currently published, and it includes unpublished
        changes. The content in the workflow builder is the draft version.
      </Typography>
    </Box>
  );
};
