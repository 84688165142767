import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DrawerSection from '../../DrawerSection';
import ConnectedIntegrationOverview from './ConnectedIntegrationOverview';
import { useLastConnectorJob } from './hooks';
import { Connector, ConnectorDefinition } from 'src/services/apiInterfaces';

interface IntegrationOverviewProps {
  connectorDefinition: ConnectorDefinition;
  selectedConnector?: Connector;
}

export default function IntegrationOverview({
  connectorDefinition,
  selectedConnector,
}: IntegrationOverviewProps) {
  const { isPending, lastConnectorJob, setConnectorJobPendingState } =
    useLastConnectorJob(selectedConnector?.connector_id);

  const hasConnectedOverview =
    selectedConnector &&
    (!!lastConnectorJob || !!selectedConnector.last_modified_at);
  const hasUnconnectedOverview =
    !selectedConnector && connectorDefinition.overview;

  if (!hasConnectedOverview && !hasUnconnectedOverview) {
    return null;
  }

  return (
    <DrawerSection title='Integration Overview'>
      {selectedConnector ? (
        <ConnectedIntegrationOverview
          isPending={isPending}
          lastConnectorJob={lastConnectorJob}
          selectedConnector={selectedConnector}
          setConnectorJobPendingState={setConnectorJobPendingState}
        />
      ) : (
        <Typography color={theme.palette.grey[700]} variant='font14'>
          {connectorDefinition.overview}
        </Typography>
      )}
    </DrawerSection>
  );
}
