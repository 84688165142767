import brightSpaceIcon from '../../../assets/images/brightspace-article-logo.svg';
import confluenceIcon from '../../../assets/images/confluence-article-logo.svg';
import contentfulIcon from '../../../assets/images/contentful-article-logo.svg';
import egainIcon from '../../../assets/images/egain-article-logo.svg';
import elevioIcon from '../../../assets/images/elevio-article-logo.svg';
import freshdeskIcon from '../../../assets/images/freshdesk-article-logo.svg';
import helpJuiceIcon from '../../../assets/images/helpjuice-article-logo.svg';
import intercomIcon from '../../../assets/images/intercom-article-logo.svg';
import kustomerIcon from '../../../assets/images/kustomer-article-logo.svg';
import mindtouchIcon from '../../../assets/images/mindtouch-article-logo.svg';
import nanorepIcon from '../../../assets/images/nanorep-article-logo.svg';
import odoIcon from '../../../assets/images/odo-article-logo.svg';
import readmeIoIcon from '../../../assets/images/readmeio-article-logo.svg';
import salesforceIcon from '../../../assets/images/salesforce-article-icon.svg';
import vanillaForumsIcon from '../../../assets/images/vanilla-article-logo.svg';
import webPageIcon from '../../../assets/images/webpage-article-logo.svg';
import wordpressIcon from '../../../assets/images/wordpress-article-logo.svg';
import zendeskIcon from '../../../assets/images/zendesk-article-logo.svg';

export const articleLogos: { [key: string]: string } = {
  article: zendeskIcon,
  brightspace_article: brightSpaceIcon,
  confluence_page: confluenceIcon,
  contentful_entry: contentfulIcon,
  egain_article: egainIcon,
  elevio_article: elevioIcon,
  freshdesk_article: freshdeskIcon,
  help_juice_article: helpJuiceIcon,
  intercom_article: intercomIcon,
  kustomer_article: kustomerIcon,
  macro: zendeskIcon,
  mind_touch_page: mindtouchIcon,
  nanorep_article: nanorepIcon,
  odo_article: odoIcon,
  readmeio_article: readmeIoIcon,
  sf_knowledge: salesforceIcon,
  vanilla_forums_discussion: vanillaForumsIcon,
  webpage: webPageIcon,
  wordpress_post: wordpressIcon,
};
