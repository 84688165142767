import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';

import Tooltip from 'src/components/reusable-components/tooltip/tooltip';
import {
  ErrorMessage,
  Label,
  Row,
  TextArea,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { FieldNameToIdx } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import { ZendeskHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface HandoffFormQuestionMessageProps {
  customizationData: ZendeskHandoffCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<SetStateAction<ZendeskHandoffCustomization>>;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const HandoffFormQuestionMessage: FC<
  React.PropsWithChildren<HandoffFormQuestionMessageProps>
> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  const errorMessage =
    errorMessages[FieldNameToIdx.HANDOFF_FORM_QUESTION_MESSAGE];
  const isTouched =
    isFieldTouchedArr[FieldNameToIdx.HANDOFF_FORM_QUESTION_MESSAGE];
  const showErrorMessage = !!errorMessage && !!isTouched;

  return (
    <>
      <Row>
        <Label>Handoff Form Question Message</Label>
        <TooltipWrapper>
          <Tooltip
            tooltipText={`Form fields include name, email, and question by default.
                          Additional variables specified in the below fields are also automatically added.`}
          />
        </TooltipWrapper>
      </Row>
      <TextArea
        hasError={showErrorMessage}
        onBlur={() => {
          const arrCopy = [...isFieldTouchedArr];
          arrCopy[FieldNameToIdx.HANDOFF_FORM_QUESTION_MESSAGE] = true;
          setIsFieldTouchedArr(arrCopy);
        }}
        onChange={e => {
          const { value } = e.target;

          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              ticket_creation_settings: {
                ...handoffData.ticket_creation_settings,
                ticket_creation_config: {
                  ...customizationData.ticket_creation_settings
                    ?.ticket_creation_config,
                  handoff_form_question_message: value,
                },
              },
            };
          });
        }}
        placeholder='Add question'
        value={
          customizationData.ticket_creation_settings?.ticket_creation_config
            ?.handoff_form_question_message
        }
      />
      {showErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

const TooltipWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export default HandoffFormQuestionMessage;
