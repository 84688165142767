import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconAdjustmentsHorizontal } from '@tabler/icons-react';
import { IconTag } from '@tabler/icons-react';

import {
  Button,
  MultiSelectFilter,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  NEW_NOTE_ID,
  USER_PERMISSION_FILTER_OPTIONS,
  UserPermissionValue,
} from '../constants';
import filterIcon from 'src/assets/images/filter-analytic-icon.svg';

interface NotesTableToolbarProps {
  allAvailableTags: string[];
  filterOptions: { label: string; value: string }[];
  filterValue: string[];
  searchText: string;
  setFilterValue: (value: string[]) => void;
  setSearchText: (text: string) => void;
  setSelectedNoteId: React.Dispatch<React.SetStateAction<string>>;
  setTagFilterValue: (value: string[]) => void;
  setUserPermissionFilterValue: (value: UserPermissionValue) => void;
  tagFilterValue: string[];
  userPermissionFilterValue: UserPermissionValue;
}

const NotesTableToolbar: React.FC<
  React.PropsWithChildren<NotesTableToolbarProps>
> = ({
  allAvailableTags,
  filterOptions,
  filterValue,
  searchText,
  setFilterValue,
  setSearchText,
  setSelectedNoteId,
  setTagFilterValue,
  setUserPermissionFilterValue,
  tagFilterValue,
  userPermissionFilterValue,
}) => {
  const { palette } = useTheme();

  const areFiltersUsed = Boolean(
    searchText.length ||
      userPermissionFilterValue.length ||
      filterValue.length ||
      tagFilterValue.length,
  );

  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.white}
      display='flex'
      id='toolbar'
      justifyContent='space-between'
      mb={2}
      pt={3}
    >
      <Box display='flex' gap={1} id='left-side-toolbar'>
        <SearchBar
          onChange={({ target }) => setSearchText(target.value)}
          onClear={() => setSearchText('')}
          placeholder='Search'
          size='small'
          value={searchText}
        />
        <MultiSelectFilter
          icon={
            <IconAdjustmentsHorizontal color={palette.grey[800]} size={20} />
          }
          isMenuSearchable={false}
          onChange={setUserPermissionFilterValue as (value: string[]) => void}
          options={
            USER_PERMISSION_FILTER_OPTIONS as unknown as {
              label: string;
              value: string;
            }[] // typescript wont accept this type because it's a readonly array need to cast
          }
          placeholder='Show only'
          value={userPermissionFilterValue}
          variant='secondary'
        />
        <MultiSelectFilter
          icon={<img src={filterIcon} />}
          isMenuSearchable
          menuTitle='Filter by'
          onChange={setFilterValue}
          options={filterOptions}
          placeholder='Filter by'
          value={filterValue}
          variant='secondary'
        />
        <MultiSelectFilter
          icon={<IconTag size={20} />}
          isMenuSearchable
          menuTitle='Filter by Tags'
          onChange={setTagFilterValue}
          options={allAvailableTags.map(tag => {
            return { label: tag, value: tag };
          })}
          placeholder='Filter by Tags'
          value={tagFilterValue}
          variant='secondary'
        />
        {areFiltersUsed && (
          <Button
            onClick={() => {
              setFilterValue([]);
              setTagFilterValue([]);
              setSearchText('');
              setUserPermissionFilterValue([]);
            }}
            size='medium'
            variant='ghost'
          >
            <Typography noWrap variant='font14Medium'>
              Reset filters
            </Typography>
          </Button>
        )}
      </Box>
      <Box display='flex' id='right-side-toolbar'>
        <Button
          onClick={() => setSelectedNoteId(NEW_NOTE_ID)}
          startIcon={<AddIcon fontSize='small' />}
          variant='secondary'
        >
          Create new note
        </Button>
      </Box>
    </Box>
  );
};
export default NotesTableToolbar;
