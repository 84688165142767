import './tooltip.scss';
import '../../dashboard-pages/common/tablePage.scss';

import { styled } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/system/Box';

import { Tooltip as FTElementsTooltip } from '@forethought-technologies/forethought-elements';
import tooltipInfoIcon from '../../../assets/images/tooltip-info.svg';

type MyProps = {
  icon?: string;
  margin?: string;
  size?: string;
  tooltipText?: string;
  tooltipTitle?: string;
};

const Tooltip = ({
  icon,
  margin,
  size,
  tooltipText,
  tooltipTitle,
}: MyProps) => {
  const displayTooltip =
    (tooltipTitle && tooltipTitle.length > 0) ||
    (tooltipText && tooltipText.length > 0);
  return (
    <>
      {displayTooltip && (
        <FTElementsTooltip
          tooltipContent={
            <>
              {tooltipTitle && (
                <>
                  <Box mb={0.5}>
                    <Typography component='h4' variant='font16Bold'>
                      {tooltipTitle}
                    </Typography>
                  </Box>
                </>
              )}
              <Body>{tooltipText}</Body>
            </>
          }
        >
          <IconContainer data-testid='tooltip' margin={margin}>
            <Icon
              isBigIcon={(icon && icon?.length > 0) || false}
              size={size}
              src={icon || tooltipInfoIcon}
            />
          </IconContainer>
        </FTElementsTooltip>
      )}
    </>
  );
};

export default Tooltip;

const IconContainer = styled('div')<{ margin: string | undefined }>`
  display: flex;
  flex-direction: column-reverse;
  margin: ${props => (props.margin ? props.margin : '0px 4px')};
  position: relative;
`;

const Body = styled('span')`
  font-size: 14px;
`;

const Icon = styled('img')<{
  isBigIcon: boolean | undefined;
  size: string | undefined;
}>`
  height: ${props =>
    props.size ? props.size : props.isBigIcon ? '25px' : '16px'};
  width: ${props =>
    props.size ? props.size : props.isBigIcon ? '25px' : '16px'};
`;
