import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/system';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ActiveSidebarProps } from './types';
import ConfirmModal from 'src/components/email-builder/components/ConfirmModal';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { ConditionEditor } from 'src/pages/workflow-builder-edit/conditions/ConditionEditor';
import {
  getCompositeConditionsConfigFromStep,
  getTransitionsFromConfig,
} from 'src/pages/workflow-builder-edit/conditions/conditionEditorHelpers';
import { CompositeConditionsConfig } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { ConditionExpressions } from 'src/services/apiInterfaces';
import { emptyCondition } from 'src/slices/canvas-workflow-builder/utils';
import {
  selectEmailWorkflowState,
  updateEmailWorkflowStep,
  updateEmailWorkflowStepTransitions,
} from 'src/slices/email-workflow/emailWorkflowSlice';
import { selectTicketFieldMappingsState } from 'src/slices/ticket-field-mappings/ticketFieldMappingsSlice';
import { useAppDispatch } from 'src/store/hooks';

const API_HTTP_STATUS_CV_ID = 'api_http_status';

export default function RuleSidebarWrapper(props: ActiveSidebarProps) {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: true,
  });

  return <RuleSidebar {...props} key={contextVariables.length} />;
}

function RuleSidebar({ handleCancel, node }: ActiveSidebarProps) {
  const { step } = node.data;
  const dispatch = useAppDispatch();
  const { emailWorkflow } = useSelector(selectEmailWorkflowState);
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: true,
    systemContextVariableIdsToInclude: [API_HTTP_STATUS_CV_ID],
  });

  const ticketFieldMappingsState = useSelector(selectTicketFieldMappingsState);

  const { ticketFieldMappings = [] } = ticketFieldMappingsState;

  const compositeConditionsConfig = useMemo(() => {
    if (!step || !contextVariables.length) {
      return;
    }

    const config = getCompositeConditionsConfigFromStep(
      step,
      contextVariables,
      true,
      true,
    );
    if (!config.compositeConditions.length) {
      config.compositeConditions = [{ conditions: [emptyCondition] }];
    }

    return config;
  }, [step, contextVariables]);

  const [
    isDeleteConfirmationDialogVisible,
    setIsDeleteConfirmationDialogVisible,
  ] = useState(false);

  const [conditions, setConditions] = useState(compositeConditionsConfig);

  if (!emailWorkflow) {
    return null;
  }

  const {
    email_workflow_id: emailWorkflowId,
    last_modified_date: lastModifiedDate,
    version,
  } = emailWorkflow;

  const closeDialog = () => {
    setIsDeleteConfirmationDialogVisible(false);
  };

  const getIsTransitionUsingTriageModels = (
    conditionExpression: ConditionExpressions,
  ): boolean => {
    const { expression_type: type, expressions, field } = conditionExpression;
    const isFilterExpressionType = type === 'filter';
    const isBooleanAggregatorType = type === 'boolean_aggregator';
    if (isFilterExpressionType) {
      return ticketFieldMappings.some(mapping => {
        return mapping.context_variable_id === field && !!mapping.mapping_type;
      });
    } else if (isBooleanAggregatorType) {
      return (
        !!expressions &&
        expressions?.some(expression =>
          getIsTransitionUsingTriageModels(expression),
        )
      );
    }
    return false;
  };

  const updateCondition = async (
    compositeConditionsConfig: CompositeConditionsConfig | undefined,
  ) => {
    if (!step || !compositeConditionsConfig) {
      return;
    }

    const transitions = getTransitionsFromConfig(compositeConditionsConfig).map(
      transition => {
        const conditionExpression = transition.condition_expression;
        return {
          ...transition,
          transition_id: transition.transition_id || uuidv4(),
          uses_mapped_fields: conditionExpression
            ? getIsTransitionUsingTriageModels(conditionExpression)
            : false,
        };
      },
    );
    await dispatch(
      updateEmailWorkflowStep({
        emailWorkflowId,
        lastModifiedDate: step.last_modified_date,
        stepFields: {
          condition_name: compositeConditionsConfig.conditionName,
        },
        stepId: step.step_id,
        version,
      }),
    );
    dispatch(
      updateEmailWorkflowStepTransitions({
        emailWorkflowId,
        lastModifiedDate,
        stepId: step.step_id,
        transitions,
        version,
      }),
    );
  };

  const getTransitionIds = (
    conditions: CompositeConditionsConfig['compositeConditions'],
  ) => conditions.map(condition => condition.transitionId || '');

  const getRemovedTransitionIds = (
    oldTransitionIds: Array<string>,
    newTransitionIds: Array<string>,
  ) =>
    oldTransitionIds.filter(
      transition => !newTransitionIds.includes(transition),
    );

  return (
    <Box key={JSON.stringify(step)}>
      <Box padding='24px'>
        <Typography variant='font24'>If/Otherwise</Typography>
      </Box>
      <ConditionEditor
        allowConditionDeletion
        compositeConditionsConfig={compositeConditionsConfig}
        contextVariables={contextVariables}
        hasWorkflowConflict={false}
        hideOtherwise
        minConditionCount={1}
        onCancel={handleCancel}
        onSave={newCompositeConditionsConfig => {
          const oldTransitionIds = getTransitionIds(
            compositeConditionsConfig?.compositeConditions || [],
          );
          const newTransitionIds = getTransitionIds(
            newCompositeConditionsConfig.compositeConditions,
          );

          const removedTransitionIds = getRemovedTransitionIds(
            oldTransitionIds,
            newTransitionIds,
          );

          const shouldAddDialog = !!removedTransitionIds.length;

          if (shouldAddDialog) {
            setIsDeleteConfirmationDialogVisible(true);
            setConditions(newCompositeConditionsConfig);
          } else {
            updateCondition(newCompositeConditionsConfig);
          }
        }}
        ticketFieldMappings={ticketFieldMappings}
        undefinedCvs={[]}
      />
      <ConfirmModal
        actions={[
          {
            onClick: () => closeDialog(),
            title: 'Cancel',
          },
          {
            onClick: () => updateCondition(conditions),
            title: 'Save and delete paths',
          },
        ]}
        description={
          <>
            You have deleted one or more paths that contain subsequent nodes. By
            deleting these paths, you will also delete all subsequent nodes that
            belonged to those paths.
            <p>
              Are you sure you want to continue? This action cannot be undone.
            </p>
          </>
        }
        isOpen={isDeleteConfirmationDialogVisible}
        onClose={() => closeDialog()}
        title='Delete?'
      />
    </Box>
  );
}
