import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DropoffIcon from '../assets/icons/metrics-dropoff.svg';
import { useGetBuilderQueryParams } from '../hooks';
import EmptyState from './EmptyState';
import {
  featureEnabledDateString,
  getFormattedDate,
  isCurrentDate,
  mapButtonValuesToNames,
  mapTimePeriodsToAPIParam,
  stepModifiedAfterFeatureEnabled,
  timePeriods,
} from './helpers';
import SelectButton from './SelectButton';
import StepMetrics, { Icon, Label, Row, Value } from './StepMetrics';
import { LoadingContainer } from './StepMetricsWindow';
import chevronDownIcon from 'src/assets/images/chevron-down.svg';
import chevronUpIcon from 'src/assets/images/chevron-up.svg';
import Spinner from 'src/components/spinner';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import {
  Features,
  Pages,
} from 'src/pages/workflow-builder-edit/workflow-builder/appCuesIdentifiers';
import {
  selectCanvasWorkflowBuilder,
  selectIntentTitle,
  selectIntentWorkflowId,
  selectIsBranchMetricsViewable,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { getBranchingStepMetricsAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import { selectCurrentWorkflowVersion } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { BranchingStepMetricsResponse } from 'src/types/workflowBuilderAPITypes';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
  StepTypes,
} from 'src/utils/enums';

interface BranchStepMetricsWindowProps {
  actionType?: string;
  isLoadingMetricsWindow: boolean;
  left: number;
  setIsEditMenuVisible: (isEditMenuVisible: boolean) => void;
  setIsLoadingMetricsWindow: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMetricsWindowVisible: (isVisible: boolean) => void;
  stepId: string;
  top: number;
}

type BranchingSteps = StepTypes.ARTICLE_SUGGESTION | StepTypes.BUTTONS;

type EventTypeMapper = {
  [key in BranchingSteps]: FlamethrowerTrackingEventTypes;
};

const BranchStepMetricsWindow: React.FC<
  React.PropsWithChildren<BranchStepMetricsWindowProps>
> = ({
  actionType,
  isLoadingMetricsWindow,
  left,
  setIsEditMenuVisible,
  setIsLoadingMetricsWindow,
  setIsMetricsWindowVisible,
  stepId,
  top,
}) => {
  // Selector
  const workflowId = useSelector(selectIntentWorkflowId);
  const activeVersion = useSelector(selectCurrentWorkflowVersion);
  const { workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const intentTitle = useSelector(selectIntentTitle);
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const isBranchMetricsViewable = useSelector(
    selectIsBranchMetricsViewable(stepId),
  );
  const stepType = canvasData.steps[stepId].step_type as BranchingSteps;

  const containerRef = useRef<HTMLDivElement>(null);

  // states
  const [currentPosition, setCurrentPosition] = useState<{
    bottom?: number;
    left?: number;
    top?: number;
  }>({
    left,
    top,
  });
  const [selectedRangeIndex, setSelectedRangeIndex] = useState(1);
  const [hiddenOptions, setHiddenOptions] = useState<number[]>([]);
  const [branchingStepMetrics, setBranchingStepMetrics] =
    useState<BranchingStepMetricsResponse>({
      buttons: [
        {
          button_display_name: 'loading...',
          button_index: 0,
          button_value: 'loading...',
          csat: {
            average_score: 0.0,
          },
          deflection_rate: {
            count: 0,
            total_count: 0,
          },
          traffic: {
            count: 0,
            total_count: 0,
          },
        },
      ],
      dropoff: {
        count: 0,
        total_count: 0,
      },
      step_is_modified: false,
      step_last_modified_at: '',
    });
  const [isLoading, setIsLoading] = useState(true);

  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );

  const eventTypeMapper: EventTypeMapper = {
    article_suggestion:
      FlamethrowerTrackingEventTypes.STEP_LEVEL_ARTICLE_SUGGESTION_STEP_OPTION_SELECTED,
    buttons:
      FlamethrowerTrackingEventTypes.STEP_LEVEL_BUTTON_STEP_OPTION_SELECTED,
  };

  // data to display
  const dropoff = branchingStepMetrics.dropoff;
  const percentDropoff =
    dropoff.total_count === 0
      ? 0
      : Math.round((dropoff.count / dropoff.total_count) * 1000) / 10;

  const buttonValuesToNames = mapButtonValuesToNames(
    branchingStepMetrics,
    actionType,
  );

  const stepLastModifiedAt = branchingStepMetrics.step_last_modified_at;
  const wasModifiedToday = isCurrentDate(stepLastModifiedAt);
  const formattedLastModifiedDate = getFormattedDate(stepLastModifiedAt);
  const formattedFeatureEnabledDate = getFormattedDate(
    featureEnabledDateString,
  );

  const modifiedTodayLabel = 'Data available since step last updated today';
  const lastModifiedLabel = `Data available since step last updated ${formattedLastModifiedDate} days ago`;
  const featureEnabledLabel = `Data available since ${formattedFeatureEnabledDate} days ago`;

  let timeFrameLabel = null;
  if (!stepLastModifiedAt) {
    timeFrameLabel = 'Last updated at ...';
  } else if (wasModifiedToday) {
    timeFrameLabel = modifiedTodayLabel;
  } else if (stepModifiedAfterFeatureEnabled(stepLastModifiedAt)) {
    timeFrameLabel = lastModifiedLabel;
  } else {
    timeFrameLabel = featureEnabledLabel;
  }

  // useCallbacks
  const setContainerCurrentTopPosition = useCallback(() => {
    const screenHeight = window.innerHeight;
    const containerHeight = containerRef.current?.offsetHeight ?? 0;
    const containerTopPosition = currentPosition.top ?? 0;
    const sum = containerHeight + containerTopPosition;

    if (sum > screenHeight) {
      setCurrentPosition({ ...currentPosition, bottom: 10, top: undefined });
    }
  }, [currentPosition]);

  const handleGetMetrics = useCallback(
    (timePeriod: string) => {
      setIsLoading(true);
      const metricsPromise = getBranchingStepMetricsAPI(
        workflowId || '',
        activeVersion,
        stepId,
        mapTimePeriodsToAPIParam(timePeriod),
      );
      metricsPromise
        .then(data => setBranchingStepMetrics(data))
        .catch(() =>
          console.error(
            `failed to fetch branching step metrics for time period ${timePeriod}`,
          ),
        )
        .finally(() => {
          setIsLoading(false);
          setIsLoadingMetricsWindow(false);
        });
    },
    [activeVersion, stepId, workflowId, setIsLoadingMetricsWindow],
  );

  // useEffects
  useEffect(() => {
    handleGetMetrics('30D');
  }, [handleGetMetrics]);

  useEffect(() => {
    if (!isLoadingMetricsWindow) {
      setContainerCurrentTopPosition();
    }
  }, [isLoadingMetricsWindow, setContainerCurrentTopPosition]);

  return (
    <Backdrop
      data-testid='backdrop'
      onClick={() => {
        setIsMetricsWindowVisible(false);
        setIsEditMenuVisible(false);
      }}
    >
      {!isLoadingMetricsWindow && (
        <MetricsWrapper
          bottom={currentPosition.bottom}
          data-appcues-target={getAppCuesId({
            componentType: 'Metrics',
            featureName: Features.CANVAS,
            pageName: Pages.SOLVE,
            subType: 'Window',
          })}
          left={currentPosition.left}
          onClick={e => {
            e.stopPropagation();
          }}
          ref={containerRef}
          top={currentPosition.top}
        >
          <Container>
            <TimePeriodSelector>
              {timePeriods.map((timePeriod, index) => (
                <SelectButton
                  index={index}
                  key={index}
                  onClick={() => {
                    if (selectedRangeIndex === index) {
                      return;
                    }
                    setSelectedRangeIndex(index);
                    handleGetMetrics(timePeriod);
                  }}
                  selectedIndex={selectedRangeIndex}
                  text={timePeriod}
                />
              ))}
            </TimePeriodSelector>
            {isLoading ? (
              <LoadingContainer>
                <Spinner height='190px' />
              </LoadingContainer>
            ) : (
              <>
                {branchingStepMetrics.step_is_modified ? (
                  <EmptyState />
                ) : (
                  <>
                    {isBranchMetricsViewable && (
                      <BranchesContainer>
                        {branchingStepMetrics.buttons.map((button, index) => {
                          const isSelected = !hiddenOptions.includes(index);

                          return (
                            <Section key={button.button_value}>
                              <BranchNameTab
                                aria-label={`Select branch ${buttonValuesToNames.get(
                                  button.button_value,
                                )}`}
                                isSelected={isSelected}
                                onClick={() => {
                                  if (!isSelected) {
                                    setHiddenOptions(currentHiddenOptions =>
                                      currentHiddenOptions.filter(
                                        hiddenOption => hiddenOption !== index,
                                      ),
                                    );
                                  } else {
                                    setHiddenOptions([...hiddenOptions, index]);
                                  }

                                  dispatchTrackingAction(
                                    eventTypeMapper[stepType],
                                    {
                                      intent_id: selectedWorkflowId || '',
                                      intent_name: intentTitle,
                                      org_id: canvasData.org_id,
                                    },
                                  );
                                }}
                                role='group'
                              >
                                <Typography
                                  color={
                                    isSelected
                                      ? theme.palette.colors.purple[500]
                                      : theme.palette.colors.black
                                  }
                                  variant='font14Medium'
                                >
                                  {buttonValuesToNames.get(button.button_value)}
                                </Typography>
                                {isSelected ? (
                                  <ReactSVGIcon src={chevronUpIcon} />
                                ) : (
                                  <ReactSVGIcon src={chevronDownIcon} />
                                )}
                              </BranchNameTab>
                              {isSelected && (
                                <BranchMetricsContainer>
                                  <StepMetrics
                                    csat={button.csat}
                                    deflection_rate={button.deflection_rate}
                                    traffic={button.traffic}
                                  />
                                </BranchMetricsContainer>
                              )}
                            </Section>
                          );
                        })}
                      </BranchesContainer>
                    )}

                    <DropoffRow>
                      <Row>
                        <Label>
                          <Icon src={DropoffIcon} />
                          <Typography variant='font14Medium'>
                            Dropoff
                          </Typography>
                        </Label>
                        <Value>
                          <>
                            <Typography variant='font14'>
                              {percentDropoff}%
                            </Typography>
                            <Typography
                              color={theme.palette.colors.grey[600]}
                              variant='font14'
                            >
                              {dropoff.count}
                            </Typography>
                          </>
                        </Value>
                      </Row>
                    </DropoffRow>
                    <Footer>
                      <Typography
                        color={theme.palette.colors.grey[600]}
                        variant='font12'
                      >
                        {timeFrameLabel}
                      </Typography>
                    </Footer>
                  </>
                )}
              </>
            )}
          </Container>
        </MetricsWrapper>
      )}
    </Backdrop>
  );
};

const Backdrop = styled('div')`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 199;
  top: 0px;
  left: 0px;
`;

const MetricsWrapper = styled('div')<{
  bottom?: number;
  left?: number;
  top?: number;
}>`
  position: absolute;
  z-index: 200;
  ${props => props.left !== undefined && `left:${props.left}px;`}
  ${props => props.top !== undefined && `top:${props.top}px;`}
  ${props => props.bottom !== undefined && `bottom:${props.bottom}px;`}
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.colors.white};
  width: fit-content;
  border: 1px solid ${theme.palette.colors.slate[300]};
  box-shadow: 0px 0px 1px rgba(0, 12, 32, 0.02),
    2px 8px 16px rgba(3, 17, 38, 0.107135);
  border-radius: 8px;
`;

const TimePeriodSelector = styled('div')`
  display: flex;
  padding: 16px;
`;

const BranchesContainer = styled('div')`
  max-height: 311px;
  overflow-y: scroll;
`;

const BranchNameTab = styled('div')<{ isSelected: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
  :hover {
    ${props =>
      !props.isSelected &&
      `background-color: ${theme.palette.colors.slate[100]};`}
  }
`;

const BranchMetricsContainer = styled('div')`
  padding: 0px 16px 16px 16px;
`;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  width: 340px;
  border-top: 1px solid ${theme.palette.colors.slate[200]};
`;

const DropoffRow = styled('div')`
  border-top: 1px solid ${theme.palette.colors.slate[200]};
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
  padding: 16px;
`;

const Footer = styled('div')`
  display: flex;
  padding: 16px;
`;

const ReactSVGIcon = styled(ReactSVG)`
  height: 20px;
`;

export default BranchStepMetricsWindow;
