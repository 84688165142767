import { Box, styled } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Typography } from '@forethought-technologies/forethought-elements';
import { Tooltip } from '@forethought-technologies/forethought-elements';

interface ToggleButtonGroupProps<T> {
  alignment: T | null;
  fullWidth?: boolean;
  isDisabled?: boolean;
  items: readonly {
    icon?: JSX.Element;
    isDisabled?: boolean;
    label: string;
    value: T;
  }[];
  onChange: (newAlignment: T | null) => void;
  toggleWidth?: string;
  tooltips?: string[];
  width?: string | number;
}

const ToggleButtonGroup = <T extends string>({
  alignment,
  fullWidth = false,
  isDisabled,
  items,
  onChange,
  toggleWidth = '112px',
  tooltips,
  width,
}: ToggleButtonGroupProps<T>) => {
  return (
    <MuiToggleButtonGroup
      aria-label='Mode'
      disabled={isDisabled}
      exclusive
      fullWidth={fullWidth}
      onChange={(_, newAlignment) => {
        if (!newAlignment) {
          return;
        }
        onChange(newAlignment);
      }}
      sx={theme => ({
        '& .Mui-selected': {
          background: theme.palette.background.default + ' !important',
          boxShadow: theme.shadows[1],
          color: theme.palette.text.primary,
        },

        backgroundColor: theme.palette.colors.slate[100],
        border: '1px solid' + theme.palette.colors.slate[200],
        borderRadius: '6px',
        cursor: isDisabled ? 'not-allowed' : undefined,
        gap: '4px',
        height: '32px',
        padding: '2px',
        width,
      })}
      value={alignment}
    >
      {items.map(({ icon, isDisabled, label, value }, index) =>
        tooltips && tooltips[index] ? (
          <StyledToggleButton
            disabled={isDisabled}
            fullWidth={fullWidth}
            key={value}
            toggleWidth={toggleWidth}
            value={value}
          >
            <Tooltip tooltipContent={tooltips[index]}>
              <span>{label}</span>
            </Tooltip>
          </StyledToggleButton>
        ) : (
          <StyledToggleButton
            disabled={isDisabled}
            fullWidth={fullWidth}
            key={value}
            toggleWidth={toggleWidth}
            value={value}
          >
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography variant='font14Bold'>{label}</Typography>
              {icon}
            </Box>
          </StyledToggleButton>
        ),
      )}
    </MuiToggleButtonGroup>
  );
};

const StyledToggleButton = styled(ToggleButton, {
  shouldForwardProp: prop => prop !== 'fullWidth' && prop !== 'toggleWidth',
})<{ fullWidth: boolean; toggleWidth: string }>`
  width: ${props => (props.fullWidth ? '100%' : props.toggleWidth)};
  padding: 0;
  background-color: ${props => props.theme.palette.colors.slate[100]};
  border-radius: 4px !important;
  border: None;
  font-weight: 600;
  transition: ${props =>
    props.theme.transitions.create(['background-color'], { duration: 100 })};
`;

export default ToggleButtonGroup;
