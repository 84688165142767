import { useMemo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material';

import {
  Avatar,
  TextField,
} from '@forethought-technologies/forethought-elements';
import SettingsSection from 'src/components/reusable-components/settings-section';

export interface AccountSettingsFormProps {
  avatarText: string;
  disabled?: boolean;
  editButtonLabel: string;
  formConfig: {
    id: string;
    label: string;
    readOnly?: boolean;
    tooltipText?: string;
    value?: string;
  }[];
  isEditing: boolean;
  isLoading?: boolean;
  onSubmit: (formData: Record<string, string>) => void;
  setIsEditing: (isEditing: boolean) => void;
  title: string;
  // eslint-disable-next-line
  validationSchema?: Yup.ObjectSchema<any>;
}

export default function AccountSettingsForm({
  avatarText,
  disabled,
  editButtonLabel,
  formConfig,
  isEditing,
  isLoading,
  onSubmit,
  setIsEditing,
  title,
  validationSchema,
}: AccountSettingsFormProps) {
  const initialValues = useMemo(() => {
    return formConfig.reduce<Record<string, string>>((acc, curr) => {
      acc[curr.id] = curr.value || '';
      return acc;
    }, {});
  }, [formConfig]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, handleSubmit, setFieldValue, setValues, values }) => {
        return (
          <SettingsSection
            actions={
              isEditing
                ? [
                    {
                      label: 'Cancel',
                      onClick: () => {
                        setIsEditing(false);
                        setValues(initialValues);
                      },
                    },
                    {
                      isLoading,
                      label: 'Save',
                      onClick: () => handleSubmit(),
                    },
                  ]
                : [
                    {
                      label: editButtonLabel,
                      onClick: () => setIsEditing(true),
                    },
                  ]
            }
            disabled={disabled}
            title={title}
          >
            <FormContent>
              <Avatar variant='lg'>{avatarText}</Avatar>
              <InputGroup>
                {formConfig.map(({ id, label, readOnly, tooltipText }) => {
                  return (
                    <InputWrapper key={id}>
                      <TextField
                        disabled={isEditing && readOnly}
                        error={errors[id]}
                        label={label}
                        onChange={e => {
                          const { value } = e.target;
                          setFieldValue(id, value);
                        }}
                        placeholder={label}
                        readOnly={!isEditing}
                        tooltipContent={tooltipText}
                        value={values[id] || ''}
                      />
                    </InputWrapper>
                  );
                })}
              </InputGroup>
            </FormContent>
          </SettingsSection>
        );
      }}
    </Formik>
  );
}

const FormContent = styled('div')`
  display: flex;
  gap: 42px;
  padding: 32px 0;
`;

const InputWrapper = styled('div')`
  flex-basis: calc(
    50% - 12px
  ); // take up half of parent width accounting for gap
`;

const InputGroup = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 640px;
  width: 100%;
`;
