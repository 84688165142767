import { useField } from 'formik';

import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { useGetActionBuilderDetailFormIsActive } from 'src/pages/action-builder/hooks';

interface ActionBuilderFormTextFieldProps {
  'aria-label'?: string;
  label?: string;
  name: string;
  placeholder?: string;
}

const ActionBuilderFormContextVariableAutocomplete = ({
  'aria-label': ariaLabel,
  label,
  name,
  placeholder,
}: ActionBuilderFormTextFieldProps) => {
  const [{ value }, meta, { setValue }] = useField<string>({
    name,
  });
  const isActive = useGetActionBuilderDetailFormIsActive();

  return (
    <ContextVariableAutocomplete
      aria-label={ariaLabel}
      disabled={isActive}
      error={meta.error}
      label={label}
      onChange={setValue}
      placeholder={placeholder}
      shouldIncludeTemplateContextVariables={isActive}
      value={value}
    />
  );
};

export default ActionBuilderFormContextVariableAutocomplete;
