import { styled } from '@mui/material/styles';

import { Skeleton } from '@forethought-technologies/forethought-elements';
import { FormButtonGroup, FormHeader, FormWrapper } from './styles';

export default function FormSkeleton() {
  return (
    <FormWrapper>
      <FormHeader>
        <Skeleton height={48} width='40%' />
      </FormHeader>
      <RJSFPlaceholder>
        {[85, 95, 100, 85, 90].map((width, idx) => (
          <Skeleton height={36} key={idx} width={`${width}%`} />
        ))}
      </RJSFPlaceholder>
      <FormButtonGroup>
        <Skeleton height={48} width='100%' />
        <Skeleton height={48} width='100%' />
      </FormButtonGroup>
    </FormWrapper>
  );
}

const RJSFPlaceholder = styled('div')`
  padding-top: 24px;
`;
