import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Theme } from '@mui/material';
import { styled } from '@mui/system';

import {
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import { TRIAGE_OVERALL_METRIC_COST_TOOLTIP } from 'src/constants/triage';
import {
  getPredictionMetricsOverall,
  selectPredictionMetricsOverall,
} from 'src/slices/triage-analytics/triageAnalyticsSlice';
import { useAppDispatch } from 'src/store/hooks';

interface OverallMetricsProps {
  endTimestamp: number;
  modelName: string;
  startTimestamp: number;
}

const OverallMetrics = ({
  endTimestamp,
  modelName,
  startTimestamp,
}: OverallMetricsProps) => {
  const dispatch = useAppDispatch();
  const { data: predictionMetricsOverall, loading: isLoading } = useSelector(
    selectPredictionMetricsOverall,
  );

  useEffect(() => {
    dispatch(
      getPredictionMetricsOverall({ endTimestamp, modelName, startTimestamp }),
    );
  }, [startTimestamp, endTimestamp, dispatch, modelName]);
  const { accuracy, cost_saved, time_saved_hours } =
    predictionMetricsOverall ?? {};

  const handleRounding = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  };

  const loadingState = (
    <Skeleton height={20} variant='rectangular' width={50} />
  );

  return (
    <Box display='flex'>
      <OverallMetricContainer>
        <Typography variant='font12'>Accuracy</Typography>
        {isLoading ? (
          loadingState
        ) : (
          <Typography variant='font14Bold'>
            {accuracy ? `${Math.round(accuracy * 100)}%` : 'N/A'}
          </Typography>
        )}
      </OverallMetricContainer>
      <OverallMetricContainer>
        <Box alignItems='center' display='flex' gap={0.5}>
          <Typography variant='font12'>Cost savings</Typography>
          <Tooltip tooltipContent={TRIAGE_OVERALL_METRIC_COST_TOOLTIP}>
            <Icon src={tooltipInfoIcon} />
          </Tooltip>
        </Box>
        {isLoading ? (
          loadingState
        ) : (
          <Typography variant='font14Bold'>
            {cost_saved ? `$${Math.round(cost_saved)}` : 'N/A'}
          </Typography>
        )}
      </OverallMetricContainer>
      <OverallMetricContainer>
        <Typography variant='font12'>Time gained</Typography>
        {isLoading ? (
          loadingState
        ) : (
          <Typography variant='font14Bold'>
            {time_saved_hours
              ? `${handleRounding(time_saved_hours)} hr`
              : 'N/A'}
          </Typography>
        )}
      </OverallMetricContainer>
    </Box>
  );
};

const OverallMetricContainer = styled('div')(
  ({ theme }: { theme?: Theme }) => ({
    '&:first-of-type': {
      borderLeftStyle: 'solid',
      borderLeftWidth: '5px',
      marginLeft: '16px',
    },
    alignItems: 'center',
    borderBottomWidth: '0px',
    borderColor: theme?.palette.colors.slate[100],
    borderRightWidth: '5px',
    borderStyle: 'solid',
    borderTopWidth: '0px',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  }),
);

const Icon = styled('img')`
  height: 16px;
  width: 16px;
`;

export default OverallMetrics;
