import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { OutputFieldInputType } from '../types';
import TriageConfigDetailLinkText from './TriageConfigDetailLinkText';
import { Helpdesk } from 'src/components/app/types';
import { TriagePermissions } from 'src/reducers/triageSettingsReducer/types';

interface TriageChoicePredictedFieldComponentProps {
  helpdeskDataSourceValue: Helpdesk | '';
  permissions?: TriagePermissions;
  setOutputFieldInputType: (value: OutputFieldInputType) => void;
}

const TriageChoicePredictedFieldComponent = ({
  helpdeskDataSourceValue,
  permissions,
  setOutputFieldInputType,
}: TriageChoicePredictedFieldComponentProps) => {
  const theme = useTheme();
  // Currently have all permissions by default for Zendesk customers
  const hasNewFieldCreationPermission =
    permissions?.can_create_custom_field ||
    helpdeskDataSourceValue === 'zendesk';

  const renderButtonComponent = () => {
    const button = (
      <Button
        disabled={!hasNewFieldCreationPermission}
        id='create-new-field-btn'
        onClick={() => setOutputFieldInputType('new-field')}
        size='large'
        startIcon={<AddIcon />}
        variant='secondary'
      >
        <Typography
          color={
            hasNewFieldCreationPermission
              ? theme.palette.colors.purple[500]
              : theme.palette.colors.grey[400]
          }
          variant='font14Bold'
        >
          Create new field
        </Typography>
      </Button>
    );

    if (hasNewFieldCreationPermission) {
      return button;
    }

    return (
      <Tooltip tooltipContent='You don’t have permission to create new fields in the selected integration. Ask your helpdesk admin to create a field, or select an existing field.'>
        {button}
      </Tooltip>
    );
  };

  return (
    <>
      <Box>{renderButtonComponent()}</Box>
      <TextContainer>
        <Typography color={theme.palette.colors.grey[800]} variant='font12'>
          Pro-tip: Create a field to avoid overwriting existing values
        </Typography>
        <TriageConfigDetailLinkText
          onClick={() => setOutputFieldInputType('existing-field')}
        >
          Select an existing field instead
        </TriageConfigDetailLinkText>
      </TextContainer>
    </>
  );
};

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

export default TriageChoicePredictedFieldComponent;
