import {
  solveLiteUsageMeterRoutes,
  triageUsageMeterRoutes,
} from '../app/sidebar/helpers';
import { PaidPlanResponse, ProductQuota } from 'src/reducers/userReducer/types';

export const showProductUsageMeter = (
  productQuota: ProductQuota,
  paidPlan: PaidPlanResponse,
  pathname: string,
) => {
  if (productQuota.product === 'triage') {
    return isTriageUsageMeterRoute(pathname);
  } else if (productQuota.product === 'solve-widget') {
    return shouldShowSolveMeter({ paidPlan, pathname });
  }
};

const isTriageUsageMeterRoute = (path: string) =>
  triageUsageMeterRoutes.some(route => path.startsWith(route));

const isSolveLiteUsageMeterRoute = (path: string) =>
  solveLiteUsageMeterRoutes.some(route => path.startsWith(route));

export const productNameToLabel = (productName: ProductQuota['product']) => {
  if (productName === 'solve-widget') {
    return 'Solve Lite';
  }
  return productName;
};

const shouldShowSolveMeter = ({
  paidPlan,
  pathname,
}: {
  paidPlan: PaidPlanResponse;
  pathname: string;
}) => {
  const isSolveLiteRoute = isSolveLiteUsageMeterRoute(pathname);
  const isTrialOrg = paidPlan?.is_trial_org;
  const isSolveLiteRouteEnabled = paidPlan?.enablement_info?.solve_lite;
  return isTrialOrg && isSolveLiteRouteEnabled && isSolveLiteRoute;
};
