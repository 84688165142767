import React, { FC, useState } from 'react';
import { Box } from '@mui/material';

import {
  Button,
  Dialog,
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ReadArticleActionOptions } from 'src/types/workflowBuilderAPITypes';

interface Props {
  onClose: () => void;
  onSubmit: (readArticleAction: string) => void;
  open: boolean;
  title: string;
}

const ArticleOptionsDialog: FC<React.PropsWithChildren<Props>> = ({
  onClose,
  onSubmit,
  open,
  title,
}) => {
  const [readArticleAction, setReadArticleAction] = useState('new_tab');
  const readArticleActionOptions: {
    label: string;
    value: ReadArticleActionOptions;
  }[] = [
    {
      label: 'New Tab',
      value: 'new_tab',
    },
    {
      label: 'Expand In Widget',
      value: 'expand_in_widget',
    },
    {
      label: 'None',
      value: 'none',
    },
  ];

  return (
    <Dialog onClose={onClose} open={open} title={title}>
      <Box display='flex' flexDirection='column' gap={3} minWidth='30vw'>
        <Box display='flex' flexDirection='column' gap={1}>
          <Box display='flex' gap={0.5}>
            <Typography variant='font14Medium'>Read Article Action</Typography>
          </Box>
          <SelectDropdown
            id='readArticleAction'
            onChange={e => {
              setReadArticleAction(e.target.value);
            }}
            options={readArticleActionOptions}
            value={readArticleAction}
          />
        </Box>
        <Box display='flex' gap={2} justifyContent={'flex-end'}>
          <Button
            onClick={() => {
              onSubmit(readArticleAction);
            }}
            variant='main'
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ArticleOptionsDialog;
