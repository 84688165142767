import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { theme } from '@forethought-technologies/forethought-elements';
import ContextMention from '../context-mention-input';
import CustomizationInput from '../customization-input';
import { Section, Spacer } from '../handoff-configuration/styles';
import CustomFieldSelector from './CustomFieldSelector';
import TagSelection from './TagSelection';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import IntercomOptionComponent from 'src/pages/workflow-builder-edit/handoff-configuration/intercom/IntercomOptionComponent';
import {
  selectActionSettingsConfigurationFields,
  selectCanvasActionSettings,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { updateActionSettings } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { FreshdeskTicketCreationCustomizationSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

type FreshdeskTicketCreationCustomizationPanelProps = {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
};

type CustomFieldValue = string | number | boolean;

type CustomField = {
  key: string;
  value: CustomFieldValue;
};

const FreshdeskTicketCreationCustomizationPanel: FC<
  React.PropsWithChildren<FreshdeskTicketCreationCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  // redux
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const actionSettings = useSelector(selectCanvasActionSettings);
  const freshdeskTicketCreationCustomizationSettings = useSelector(
    selectActionSettingsConfigurationFields,
  ) as FreshdeskTicketCreationCustomizationSettings;

  // state
  const [customizationData, setCustomizationData] =
    useState<FreshdeskTicketCreationCustomizationSettings>(
      freshdeskTicketCreationCustomizationSettings,
    );
  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  // functions
  const getGroupIDValue = (): string => {
    const groupID = customizationData?.ticket_creation_settings.group_id;

    if (groupID === null || groupID === undefined) {
      return '';
    } else {
      return groupID.toString();
    }
  };
  const getPriorityValue = (): string => {
    const priority = customizationData?.ticket_creation_settings.priority;

    if (priority === null || priority === undefined) {
      return '';
    } else {
      return priority.toString();
    }
  };

  const deleteTag = (indexToDelete: number) => {
    setCustomizationData(data => {
      return {
        ...data,
        ticket_creation_settings: {
          ...data.ticket_creation_settings,
          tags: data.ticket_creation_settings.tags.filter(
            (tag, index) => index !== indexToDelete,
          ),
        },
      };
    });
  };

  const editTag = (indexToUpdate: number, value: string) => {
    const currentTags = customizationData?.ticket_creation_settings.tags || [];
    const newTags = [...currentTags];
    newTags[indexToUpdate] = value;

    setCustomizationData(data => ({
      ...data,
      ticket_creation_settings: {
        ...data.ticket_creation_settings,
        tags: [...newTags],
      },
    }));
  };

  const deleteCustomField = (indexToDelete: number) => {
    setCustomFields(data => {
      return data.filter((cf, index) => index !== indexToDelete);
    });
  };

  const getCustomFieldsObject = (): Record<string, CustomFieldValue> | null => {
    const customFieldsObject: Record<string, CustomFieldValue> = {};

    customFields.forEach(cf => {
      if (cf.key) {
        customFieldsObject[cf.key] = cf.value;
      }
    });

    if (!Object.keys(customFieldsObject).length) {
      return null;
    }

    return customFieldsObject;
  };

  const getCustomizationData =
    (): FreshdeskTicketCreationCustomizationSettings => {
      return {
        ...customizationData,
        ticket_creation_settings: {
          ...customizationData.ticket_creation_settings,
          custom_fields: getCustomFieldsObject(),
        },
      };
    };

  const isPristine =
    isEqual(customizationData, freshdeskTicketCreationCustomizationSettings) &&
    isEqual(
      customizationData?.ticket_creation_settings.custom_fields,
      getCustomFieldsObject(),
    );

  // effects
  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  // effect is needed because freshdeskTicketCreationCustomizationSettings can be undefined on initial renders
  useEffect(() => {
    if (freshdeskTicketCreationCustomizationSettings) {
      setCustomizationData({ ...freshdeskTicketCreationCustomizationSettings });

      // custom fields array
      const customFieldsObject =
        freshdeskTicketCreationCustomizationSettings?.ticket_creation_settings
          .custom_fields || {};

      const arrayOfCustomFields: CustomField[] = Object.keys(
        customFieldsObject,
      ).map(key => ({
        key: key,
        value: customFieldsObject[key],
      }));

      setCustomFields(arrayOfCustomFields);
    }
  }, [freshdeskTicketCreationCustomizationSettings]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>Freshdesk Ticket Creation</Title>
        <Spacer height='20px' />
        {/* include attachments? */}
        <IntercomOptionComponent
          checked={
            !!customizationData?.ticket_creation_settings.upload_files_attached
          }
          label=' Prompt user for file attachments'
          onChange={() =>
            setCustomizationData(data => {
              return {
                ...data,
                ticket_creation_settings: {
                  ...data.ticket_creation_settings,
                  upload_files_attached:
                    !data.ticket_creation_settings.upload_files_attached,
                },
              };
            })
          }
        />
        {/* form prompt */}
        <StyledSection>
          <ContextMention
            label='Form Prompt'
            onChange={value => {
              setCustomizationData(data => {
                return {
                  ...data,
                  ticket_creation_settings: {
                    ...data.ticket_creation_settings,
                    form_prompt: value,
                  },
                };
              });
            }}
            placeholder='Enter form prompt'
            value={
              customizationData?.ticket_creation_settings.form_prompt || ''
            }
          />
        </StyledSection>
        {/* success text message */}
        <StyledSection>
          <ContextMention
            label='Success Message'
            onChange={value => {
              setCustomizationData(data => {
                return {
                  ...data,
                  ticket_creation_settings: {
                    ...data.ticket_creation_settings,
                    ticket_creation_success_message: value,
                  },
                };
              });
            }}
            placeholder='Enter success message'
            tooltip='Text message after ticket has been created'
            value={
              customizationData?.ticket_creation_settings
                .ticket_creation_success_message || ''
            }
          />
        </StyledSection>
        {/* transcript field */}
        <StyledSection>
          <ContextMention
            label='Transcript field'
            onChange={value => {
              setCustomizationData(data => {
                return {
                  ...data,
                  ticket_creation_settings: {
                    ...data.ticket_creation_settings,
                    transcript_field: value,
                  },
                };
              });
            }}
            placeholder='Enter transcript field'
            tooltip='Ticket custom field that you want us to add the conversation transcript to'
            value={
              customizationData.ticket_creation_settings.transcript_field || ''
            }
          />
        </StyledSection>
        {/* group id */}
        <StyledSection>
          <CustomizationInput
            label='Group ID'
            onChange={({ target }) => {
              setCustomizationData(data => ({
                ...data,
                ticket_creation_settings: {
                  ...data.ticket_creation_settings,
                  group_id: parseInt(target.value),
                },
              }));
            }}
            placeholder='Enter Group ID'
            tooltip='Group ID'
            type='number'
            value={getGroupIDValue()}
          />
        </StyledSection>
        {/* priority */}
        <StyledSection>
          <CustomizationInput
            label='Priority'
            onChange={({ target }) => {
              setCustomizationData(data => ({
                ...data,
                ticket_creation_settings: {
                  ...data.ticket_creation_settings,
                  priority: parseInt(target.value),
                },
              }));
            }}
            placeholder='Enter Priority Code'
            tooltip='Priority'
            type='number'
            value={getPriorityValue()}
          />
        </StyledSection>
        {/* tags */}
        <StyledSection>
          <TagSelection
            addTag={() =>
              setCustomizationData(data => ({
                ...data,
                ticket_creation_settings: {
                  ...data.ticket_creation_settings,
                  tags: [...data.ticket_creation_settings.tags, ''],
                },
              }))
            }
            deleteTag={indexToDelete => deleteTag(indexToDelete)}
            editTag={(index, value) => editTag(index, value)}
            includeTooltip
            label='Tags'
            tags={customizationData.ticket_creation_settings.tags}
          />
        </StyledSection>
        {/* custom fields */}
        <StyledSection>
          <CustomFieldSelector
            addCustomField={() =>
              setCustomFields([...customFields, { key: '', value: '' }])
            }
            customFields={customFields}
            deleteCustomField={indexToDelete =>
              deleteCustomField(indexToDelete)
            }
            updateCustomField={(index, value) =>
              setCustomFields(data => {
                const newCustomFields = [...data];
                newCustomFields[index].key = value;
                return newCustomFields;
              })
            }
            updateCustomFieldValue={(index, value) =>
              setCustomFields(data => {
                const newCustomFields = [...data];
                newCustomFields[index].value = value;
                return newCustomFields;
              })
            }
          />
        </StyledSection>
      </Box>
      {/* Save and Cancel Buttons */}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isPristine || hasWorkflowConflict}
          onClick={() => {
            dispatch(
              updateActionSettings({
                action_type: actionSettings.action_type as string,
                configuration_fields: getCustomizationData(),
              }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.FRESHDESK_TICKET_CREATION,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

export default FreshdeskTicketCreationCustomizationPanel;

export const Title = styled('h1')`
  font-size: var(--font-size-m);
  text-align: left;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  cursor: default;
  margin: 0 10px;
`;

const StyledSection = styled(Section)`
  margin: 0 10px;
`;

export const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;
