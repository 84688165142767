import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import { HubspotContactCreationForm } from './HubspotContactCreationForm';
import { ShopifyForm } from './ShopifyForm';
import { SnowflakeForm } from './SnowflakeForm';
import * as Styled from './styles';
import { setSelectedActionTemplate } from 'src/actions/action-builder-actions/actionBuilderActions';
import shopifyIcon from 'src/assets/images/shopify.svg';
import snowflakeIcon from 'src/assets/images/snowflake.svg';
import BaseModal from 'src/components/base-modal';
import { selectSelectedActionTemplate } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { dashboardApi } from 'src/services/dashboard-api';
import { useAppDispatch } from 'src/store/hooks';
import { ActionBuilderActionTypes, APITemplateTypes } from 'src/utils/enums';

interface ActionTemplateModalProps {
  onFinished?: () => void;
}

export const ActionTemplateModal = ({
  onFinished,
}: ActionTemplateModalProps) => {
  const dispatch = useAppDispatch();
  const { refetch } = useGetActionBuilderActionsQuery({});

  const selectedAction = useSelector(selectSelectedActionTemplate);

  const onClose = () => {
    dispatch(setSelectedActionTemplate(null));
  };

  const onSaveCallback = () => {
    dispatch(dashboardApi.util.invalidateTags(['Actions']));
    refetch();
    if (onFinished) {
      onFinished();
    }
  };

  const { action_description: actionDescription, action_name: actionName } =
    selectedAction ?? {};

  const deriveComponents = () => {
    const ErrorComponent = <div>Unsupported Template Modal</div>;

    if (!selectedAction) {
      return [undefined, ErrorComponent] as const;
    }

    switch (selectedAction.action_type) {
      case ActionBuilderActionTypes.SHOPIFY_ORDER_STATUS_TEMPLATE:
        const DerivedShopifyForm = (
          <ShopifyForm
            closeCallback={onClose}
            onSaveCallback={onSaveCallback}
            selectedAction={selectedAction}
          />
        );
        return [shopifyIcon, DerivedShopifyForm] as const;
      case ActionBuilderActionTypes.SNOWFLAKE_DATA_QUERY_TEMPLATE:
        const DerivedSnowflakeForm = (
          <SnowflakeForm
            closeCallback={onClose}
            onSaveCallback={onSaveCallback}
            selectedAction={selectedAction}
          />
        );
        return [snowflakeIcon, DerivedSnowflakeForm] as const;
      case APITemplateTypes.HUBSPOT_CREATE_CONTACT_TEMPLATE:
        const DerivedHubspotContactCreationForm = (
          <HubspotContactCreationForm closeCallback={onClose} />
        );

        return [
          selectedAction.icon,
          DerivedHubspotContactCreationForm,
        ] as const;
      default:
        return [undefined, ErrorComponent] as const;
    }
  };

  const [icon, Form] = deriveComponents();

  return (
    <BaseModal
      headerTitle={
        <Box display='flex'>
          <Styled.Icon src={icon} />
          <div>
            <Styled.ActionName>{actionName}</Styled.ActionName>
            <Styled.ActionDescription>
              {actionDescription}
            </Styled.ActionDescription>
          </div>
        </Box>
      }
      isOpen={Boolean(selectedAction)}
      maxWidth='sm'
      onClose={onClose}
    >
      {Form}
    </BaseModal>
  );
};
