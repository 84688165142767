import { forwardRef } from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

interface NodeCvDisplayerRowProps {
  message: string;
  shouldAddDefaultTitleColor?: boolean;
  shouldAddLineClamp?: boolean;
  title: string;
}

const NodeCvDisplayerRow = forwardRef<
  HTMLDivElement | null,
  NodeCvDisplayerRowProps
>(function NodeCvDisplayerRow(
  {
    message,
    shouldAddDefaultTitleColor = false,
    shouldAddLineClamp = true,
    title,
  },
  ref,
) {
  const { palette } = useTheme();
  const lineClampStyles = shouldAddLineClamp
    ? {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '2',
      }
    : null;

  return (
    <Box display='flex' gap='4px'>
      <Typography
        color={
          shouldAddDefaultTitleColor ? undefined : palette.colors.grey[600]
        }
        variant='font12'
      >
        {title}:{' '}
      </Typography>
      <Box ref={ref} sx={lineClampStyles}>
        <Typography variant='font12'>{message}</Typography>
      </Box>
    </Box>
  );
});

export default NodeCvDisplayerRow;
