import './AssistLogin.scss';

import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import CreatePasswordForm from '../create-password-form';
import ValidateEmailForm from '../validate-email-form';
import LandingPageLayout from 'src/components/reusable-components/landing-page-layout';
import LoadingSpinner from 'src/components/reusable-components/loading-spinner/loadingSpinner';

/**
 *
 * This route handles:
 *  1. initial email validation for assist
 *  2. with 'username' and 'code' query params, the magic link email response allowing users to create a password for a new account
 *
 */
type AssistLoginState = 'loading' | 'validate-email' | 'create-password';
const AssistLogin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [step, setStep] = useState<AssistLoginState>('loading');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const { hash } = useLocation();
  const { code, company_name, username } = queryString.parse(hash);

  useEffect(() => {
    if (username && code) {
      setStep('create-password');
    } else {
      setStep('validate-email');
    }
  }, [username, code]);

  const handleMagicLinkError = useCallback(() => {
    navigate({ hash: '' });
    setStep('validate-email');
    setErrorMessage(
      'Your magic link has expired. Please enter your email again for a new link.',
    );
  }, [navigate]);

  return (
    <LandingPageLayout
      errorMessage={errorMessage}
      handleBannerClose={() => setErrorMessage('')}
    >
      {step === 'loading' && <LoadingSpinner />}
      {step === 'validate-email' && <ValidateEmailForm />}
      {step === 'create-password' && code && username && (
        <CreatePasswordForm
          handleMagicLinkError={handleMagicLinkError}
          handleSuccess={() => {
            // redirect to the agent landing page
            navigate('/#extensionAuth=1');
          }}
          oldPassword={code.toString()}
          title={`Join ${company_name || 'your team'} on Assist Anywhere`}
          username={username.toString()}
        />
      )}
    </LandingPageLayout>
  );
};

export default AssistLogin;
