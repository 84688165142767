import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SessionSliceState } from './types';
import { Organization, UserRequestBody } from 'src/services/apiInterfaces';
import * as API from 'src/services/apiV1';
import type { RootState } from 'src/store/rootReducer';

const initialState: SessionSliceState = {
  currentUser: {
    data: null,
    error: false,
    loading: false,
  },
  organization: {
    data: null,
    error: false,
    loading: false,
  },
};

export const getCurrentUser = createAsyncThunk('session/getCurrentUser', () =>
  API.getCurrentUser(),
);
export const updateUser = createAsyncThunk(
  'session/updateUser',
  ({
    userId,
    userRequestBody,
  }: {
    userId: string;
    userRequestBody: UserRequestBody;
  }) => API.updateUser(userId, userRequestBody),
);
export const updateOrganization = createAsyncThunk(
  'session/updateOrganization',
  ({ organization }: { organization: Partial<Organization> }) =>
    API.updateOrganization(organization),
);

const sessionSlice = createSlice({
  extraReducers: builder => {
    /**
     * getCurrentUser
     */
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      const { current_user, organization } = action.payload;
      state.currentUser.data = current_user;
      state.organization.data = organization;
      state.currentUser.loading = false;
      state.organization.loading = false;
    });
    builder.addCase(getCurrentUser.pending, state => {
      state.currentUser.error = false;
      state.organization.error = false;
      state.currentUser.loading = true;
      state.organization.loading = true;
    });
    builder.addCase(getCurrentUser.rejected, state => {
      state.currentUser.error = true;
      state.organization.error = true;
      state.currentUser.loading = false;
      state.organization.loading = false;
    });
    /**
     * updateUser
     */
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.currentUser.data = action.payload;
      state.currentUser.loading = false;
    });
    builder.addCase(updateUser.pending, state => {
      state.currentUser.error = false;
      state.currentUser.loading = true;
    });
    builder.addCase(updateUser.rejected, state => {
      state.currentUser.error = true;
      state.currentUser.loading = false;
    });
    /**
     * udpateOrganization
     */
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.organization.data = action.payload.organization;
      state.organization.loading = false;
    });
    builder.addCase(updateOrganization.pending, state => {
      state.organization.error = false;
      state.organization.loading = true;
    });
    builder.addCase(updateOrganization.rejected, state => {
      state.organization.error = true;
      state.organization.loading = false;
    });
  },
  initialState,
  name: 'session',
  reducers: {},
});

export const selectSession = (state: RootState): SessionSliceState =>
  state.session;

export default sessionSlice.reducer;
