import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import debounce from 'lodash/fp/debounce';
import { TopicTimeFilter } from 'src/components/app/types';
import { DEBOUNCED_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import {
  selectUser,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import { emitTrackingEventApi } from 'src/services/api';

const debouncedTrackingEventApi = debounce(
  DEBOUNCED_TRACKING_EVENT_TIMEOUT,
  emitTrackingEventApi,
);

export const useDebouncedTrackingEventOnSearchQueryChange = ({
  metricFilters,
  searchQuery,
  timeFilter,
}: {
  metricFilters: string[];
  searchQuery: string;
  timeFilter: TopicTimeFilter;
}) => {
  const user = useSelector(selectUser);
  const userRole = useSelector(selectUserRole);
  const { auth_time: sessionId } = user?.user ?? {};

  useEffect(() => {
    if (!searchQuery) {
      // if user clears input, don't send an event
      return debouncedTrackingEventApi.cancel();
    }

    debouncedTrackingEventApi({
      eventType: 'discover-searched-for-term',
      'metric-filters': metricFilters,
      page: 'All Topics',
      sessionId,
      'time-period-selected': timeFilter,
      userRole,
      value: searchQuery,
    });

    return () => debouncedTrackingEventApi.cancel();
  }, [searchQuery, sessionId, userRole, timeFilter, metricFilters]);
};
