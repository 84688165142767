import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';

interface GenerateButtonProps {
  isDisabled?: boolean;
}

const GenerateButton: React.FC<
  React.PropsWithChildren<GenerateButtonProps>
> = ({ isDisabled = false }: GenerateButtonProps) => {
  const { palette } = useTheme();
  return (
    <Box
      alignItems='center'
      border={`1px solid ${
        isDisabled ? palette.colors.slate[200] : palette.colors.purple[400]
      }`}
      borderRadius={'40px'}
      component='button'
      disabled={isDisabled}
      display='flex'
      gap='5px'
      padding='5px'
      sx={{ cursor: 'pointer' }}
      width='105px'
    >
      <Box
        component='img'
        src={SupportGPTIcon}
        sx={{ opacity: isDisabled ? 0.5 : 1 }}
      />
      <Typography
        color={`${isDisabled ? palette.text.disabled : palette.text.primary}`}
        variant='font14Medium'
      >
        Generate
      </Typography>
    </Box>
  );
};

export default GenerateButton;
