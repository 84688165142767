import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, MenuItem, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { Scope } from '../../types';
import Menu from 'src/components/menu';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  InsightRelatedArticle,
  InsightRelatedIntent,
} from 'src/services/insights/types';
import { Routes } from 'src/utils/enums';

interface OverflowDropdownProps {
  data: InsightRelatedArticle[] | InsightRelatedIntent[] | string[];
  footer?: React.ReactNode;
  menuTitle: string;
  NavigateComponent?: React.ReactNode;
  scope: Scope;
  tab: 'article' | 'workflow' | 'topic' | 'chat';
  titleSuffix?: string;
  variant?: 'article' | 'workflow' | 'user_queries';
  width?: string;
}

const OverflowDropdown = ({
  data,
  footer,
  menuTitle,
  NavigateComponent,
  scope,
  tab,
  titleSuffix,
  variant = 'article',
  width,
}: OverflowDropdownProps) => {
  const handleDataConversion = () => {
    if (variant === 'workflow') {
      return (data as InsightRelatedIntent[]).map(item => {
        return {
          id: item.intent_id,
          name: item.intent_name,
          optionalId: item.workflow_id,
        };
      });
    }
    if (variant === 'article') {
      return (data as InsightRelatedArticle[]).map(item => {
        return {
          id: item.article_id,
          name: item.article_title,
          optionalId: undefined,
        };
      });
    }
    if (variant === 'user_queries') {
      return (data as string[]).map(item => {
        return {
          id: item,
          name: item,
          optionalId: undefined,
        };
      });
    }
    return [];
  };

  const normalizedData = handleDataConversion();

  return (
    <Box
      alignItems='center'
      display='flex'
      gap={1}
      width={width ? width : '200px}'}
    >
      {normalizedData.length > 0 && (
        <TitleWithMenu
          firstItemId={normalizedData[0].id ?? ''}
          Menu={DropdownMenu}
          MenuProps={{
            firstItemTitle: normalizedData[0].name ?? '',
            menuItems: [
              ...normalizedData.map(item => (
                <ComponentMenuItem
                  id={item.id}
                  key={item.id}
                  optionalId={item.optionalId}
                  scope={scope}
                  tab={tab}
                  title={item.name}
                  variant={variant}
                />
              )),
              footer,
            ].filter(Boolean),
            NavigateComponent,
            scope: scope,
            tab: tab,
            title: menuTitle,
            variant: variant,
          }}
          optionalId={normalizedData[0]?.optionalId}
          tab={tab}
          titleSuffix={titleSuffix}
          variant={variant}
        />
      )}
    </Box>
  );
};

interface TitleWithMenuProps {
  firstItemId: string;
  Menu: typeof DropdownMenu;
  MenuProps: {
    firstItemTitle: string;
    menuItems?: React.ReactNode[];
    NavigateComponent?: React.ReactNode;
    scope: Scope;
    tab: 'article' | 'workflow' | 'topic' | 'chat';
    title: string;
    variant?: 'article' | 'workflow' | 'user_queries';
  };
  optionalId?: string;
  tab: 'article' | 'workflow' | 'topic' | 'chat';
  titleSuffix?: string;
  variant?: 'article' | 'workflow' | 'user_queries';
}

const TitleWithMenu = ({
  firstItemId,
  Menu,
  MenuProps,
  optionalId = '',
  tab,
  titleSuffix,
  variant,
}: React.PropsWithChildren<TitleWithMenuProps>) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchorEl);

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const arrayLengthByVariant = MenuProps.menuItems?.length ?? 0;
  const shouldShowMenu = arrayLengthByVariant > 1;

  const handleClickMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    emitTrackingEventCallback('insight-overflow-menu-open', {
      scope: MenuProps.scope,
      tab,
      type: variant,
    });
  };

  const handleClickLink = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    let pathName = '';
    if (variant === 'workflow') {
      pathName = Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
        ':workflowId',
        optionalId,
      );
    } else {
      pathName = Routes.SOLVE_INSIGHTS_ARTICLE_DETAIL.replace(
        ':articleId',
        firstItemId,
      );
    }
    emitTrackingEventCallback('insight-overflow-surfaced-data-clicked', {
      id: firstItemId,
      scope: MenuProps.scope,
      tab: tab,
      type: variant,
      value: pathName,
    });

    navigate({
      pathname: pathName,
    });
  };

  const handleSuffixLabel = () => {
    if (titleSuffix) {
      return ` ${titleSuffix}`;
    }

    if (shouldShowMenu) {
      return ` +${arrayLengthByVariant - 1}`;
    }

    return '';
  };

  const suffix = handleSuffixLabel();

  return (
    <>
      <Box
        alignItems='center'
        bgcolor={
          variant === 'workflow'
            ? palette.colors.slate[100]
            : palette.colors.white
        }
        borderRadius='4px'
        component='div'
        display='flex'
        lineHeight='14px'
        onClick={shouldShowMenu ? handleClickMenu : handleClickLink}
        padding='4px 8px'
        sx={{
          '.MuiTypography-root': {
            fontStyle: variant === 'user_queries' ? 'italic' : undefined,
            textDecoration:
              variant === 'user_queries' ? undefined : 'underline',
          },
          ':hover': {
            bgcolor: palette.colors.slate[200],
            cursor: 'pointer',
          },
        }}
        width='fit-content'
      >
        <Box
          maxWidth={variant === 'user_queries' ? '150px' : '190px'}
          overflow='hidden'
          textOverflow='ellipsis'
        >
          <Typography
            color={
              variant === 'workflow'
                ? palette.colors.grey[700]
                : palette.colors.blue[600]
            }
            noWrap
            variant={variant === 'user_queries' ? 'font14' : 'font12Medium'}
          >
            {variant === 'user_queries' && '"'}
            {MenuProps.firstItemTitle}
            {variant === 'user_queries' && '"'}
          </Typography>
        </Box>
        <Typography
          color={
            variant === 'workflow'
              ? palette.colors.grey[700]
              : palette.colors.blue[600]
          }
          noWrap
          variant={variant === 'user_queries' ? 'font14' : 'font12Medium'}
        >
          {suffix}
        </Typography>
      </Box>
      {isOpen && (
        <Menu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          {...MenuProps}
        />
      )}
    </>
  );
};

interface DropdownMenuProps {
  anchorEl: null | HTMLElement;
  menuItems?: React.ReactNode[];
  NavigateComponent?: React.ReactNode;
  onClickMenuItem?: (value: string) => void;
  onClose: () => void;
  scope: Scope;
  tab: 'article' | 'workflow' | 'topic' | 'chat';
  title: string;
  variant?: 'article' | 'workflow' | 'user_queries';
}

const DropdownMenu = ({
  anchorEl,
  menuItems = [],
  NavigateComponent,
  onClickMenuItem,
  onClose,
  scope,
  tab,
  title,
  variant,
}: DropdownMenuProps) => {
  const { palette } = useTheme();
  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  return (
    <Menu
      anchorEl={anchorEl}
      MenuListProps={{
        role: 'listbox',
        sx: {
          py: 1.5,
        },
      }}
      onClick={e => e.stopPropagation()}
      onClose={e => {
        //Casting is needed as MUI has {} as a type for events
        const event = e as MouseEvent;
        event.stopPropagation();
        onClose();
        emitTrackingEventCallback('insight-overflow-menu-close', {
          scope,
          tab,
          type: variant,
        });
      }}
      open={Boolean(anchorEl)}
      title={title}
      width='350px'
    >
      {menuItems.map((node, index) => (
        <MenuItem
          key={index}
          onClick={() => onClickMenuItem?.('x')}
          sx={{
            '&:hover': {
              backgroundColor:
                variant === 'user_queries'
                  ? 'transparent'
                  : palette.colors.purple[100],
            },
            alignItems: 'center',
            backgroundColor: 'transparent',
            cursor: variant === 'user_queries' ? 'text' : 'default',
            display: 'flex',
            padding: '4px 16px',
            userSelect: variant === 'user_queries' ? 'initial' : undefined,
          }}
        >
          <Box
            alignItems='center'
            display='flex'
            flex={1}
            overflow='hidden'
            sx={{ textWrap: 'wrap' }}
          >
            {node}
          </Box>
        </MenuItem>
      ))}
      {NavigateComponent && NavigateComponent}
    </Menu>
  );
};

interface ComponentMenuItemProps {
  id: string;
  optionalId?: string;
  scope: Scope;
  tab: 'article' | 'workflow' | 'topic' | 'chat';
  title: string;
  variant?: 'article' | 'workflow' | 'user_queries';
}

const ComponentMenuItem = ({
  id,
  optionalId = '',
  scope,
  tab,
  title,
  variant,
}: ComponentMenuItemProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const handleClickLink = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    let pathName = '';
    if (variant === 'workflow') {
      pathName = Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
        ':workflowId',
        optionalId,
      );
    } else {
      pathName = Routes.SOLVE_INSIGHTS_ARTICLE_DETAIL.replace(':articleId', id);
    }

    emitTrackingEventCallback('insight-overflow-surfaced-data-clicked', {
      id,
      scope,
      tab: tab,
      type: variant,
      value: pathName,
    });

    navigate({
      pathname: pathName,
    });
  };

  return variant === 'user_queries' ? (
    <Box fontStyle='italic' marginBottom='12px'>
      <Typography color={palette.colors.blue[600]} variant='font14'>
        &quot;{title}&quot;
      </Typography>
    </Box>
  ) : (
    <Box
      onClick={handleClickLink}
      sx={{
        '& > span:last-child': { display: 'none' },
        '&:hover > span:last-child': { display: 'inline' },
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Box alignItems='center' display='flex' flex={1} overflow='hidden'>
        <Typography color={palette.colors.black} noWrap variant='font14'>
          {title}
        </Typography>
      </Box>
      <Typography color={palette.primary.main} variant='font11'>
        {`View ${variant === 'workflow' ? 'workflow' : 'article'}`}
      </Typography>
    </Box>
  );
};

export default OverflowDropdown;
