export const removeEmail = (arr: string[], rmvdEmail: string) => {
  return arr.filter(email => email !== rmvdEmail);
};

export const isEmailValid = (email: string): boolean => {
  if (!email.length) {
    return false;
  }
  const emailValidationRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailValidationRegex.test(String(email).toLowerCase());
};
