import { useEffect, useState } from 'react';

import debounce from 'lodash/fp/debounce';

export const useGetObservableContainerHeight = (elementId: string) => {
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const debouncedSetContainerHeight = debounce(500, (height: number) =>
      setContainerHeight(height),
    );

    const observer = new ResizeObserver(resizeEntries => {
      const [entry] = resizeEntries;
      debouncedSetContainerHeight(entry.contentRect.height);
    });

    const element = document.getElementById(elementId);

    if (element) {
      observer.observe(element);
    }
  }, [elementId]);

  return containerHeight;
};
