import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';

import {
  formatToDateAndTime,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverCard from '../../discover-card/DiscoverCard';
import DiscoverMenuButton from '../../discover-menu-button/DiscoverMenuButton';
import {
  getFirstSentenceAndRest,
  getMetricFromMetrics,
  getTicketCardMetric,
  getTopicMetricDevianceMessage,
} from './helpers';
import HighlightedText from 'src/components/highlighted-text';
import {
  DiscoverTicket,
  DiscoverTopicAggregatedMetricType,
} from 'src/reducers/discoverReducer/types';
import { selectGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';

interface TicketCardProps {
  metricFilter: DiscoverTopicAggregatedMetricType;
  metricFilterLabel: string;
  onClick: () => void;
  searchKeyword?: string;
  setReportedTicketId: (id: string) => void;
  ticketData: DiscoverTicket;
}

const TicketCard: React.FC<React.PropsWithChildren<TicketCardProps>> = ({
  metricFilter,
  metricFilterLabel,
  onClick,
  searchKeyword,
  setReportedTicketId,
  ticketData,
}) => {
  const { reportedTicket } = useSelector(selectGlobalDiscoverOptions);

  if (reportedTicket?.id === ticketData.id) {
    return null;
  }

  const { body, date_created: dateCreated, id: ticketId, title } = ticketData;

  const foundMetrics = getMetricFromMetrics(
    [
      'first_resolution_time',
      'full_resolution_time',
      'number_of_agent_replies',
      'reply_time',
      'resolved_on_first_contact',
    ],
    ticketData.metrics,
  );
  const metric = foundMetrics.get(
    metricFilter === 'first_contact_resolution'
      ? 'resolved_on_first_contact'
      : metricFilter,
  );
  const hasDevianceMetric = typeof metric?.value_deviance === 'number';
  const hasMetric =
    typeof metric?.value === 'number' || typeof metric?.value === 'boolean';

  const shouldShowMetricData =
    metricFilter !== 'volume' && metricFilter !== 'sentiment';

  const ticketCardMetricValue = getTicketCardMetric(
    metric?.value ?? null,
    metricFilter,
  );

  const renderTicketCardBody = () => {
    if (title) {
      return (
        <>
          <Typography variant='font14Bold'>{title}</Typography>
          <DescriptionText shouldHaveMarginTop>
            <HighlightedText
              keyword={searchKeyword}
              text={body?.slice(0, 500)}
            />
          </DescriptionText>
        </>
      );
    }
    const [firstSentence, rest] = getFirstSentenceAndRest(body ?? '');

    return (
      <DescriptionText>
        <Typography variant='font14Bold'>{firstSentence}</Typography>
        {rest?.slice(0, 500)}
      </DescriptionText>
    );
  };

  return (
    <Box mb={2.5}>
      <DiscoverCard
        featureName='ticket'
        headerComponent={
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <Typography color={theme.palette.colors.grey[800]} variant='font12'>
              {dateCreated && formatToDateAndTime(dateCreated)} &#183; Ticket #
              {ticketId}
            </Typography>
            <DiscoverMenuButton
              setReportedTicketId={() => setReportedTicketId(ticketData.id)}
              variant='ticket_card'
            />
          </Box>
        }
        linkText='View details'
        onClick={onClick}
      >
        <Box
          display={shouldShowMetricData ? 'flex' : undefined}
          justifyContent='space-between'
          mt={2}
        >
          <Box width={shouldShowMetricData ? '75%' : '100%'}>
            {renderTicketCardBody()}
          </Box>

          {shouldShowMetricData && (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              maxWidth='25%'
              px='10px'
              width='200px'
            >
              <Box>
                <Typography
                  color={theme.palette.colors.grey[800]}
                  variant='font11'
                >
                  {metricFilter === 'first_contact_resolution'
                    ? 'Resolved on first contact?'
                    : metricFilterLabel}
                </Typography>
              </Box>
              {hasDevianceMetric && <Box mb={1.5} />}
              <Typography variant='font14'>
                {hasMetric ? (
                  ticketCardMetricValue
                ) : (
                  <i>{ticketCardMetricValue}</i>
                )}
              </Typography>
              {hasDevianceMetric && (
                <Typography
                  color={theme.palette.colors.grey[800]}
                  noWrap
                  variant='font12'
                >
                  {getTopicMetricDevianceMessage(metric.value_deviance)}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </DiscoverCard>
    </Box>
  );
};

export default TicketCard;

const DescriptionText = styled('p')<{ shouldHaveMarginTop?: boolean }>`
  font-size: ${theme.typography.font14.fontSize}px;
  font-weight: ${theme.typography.font14.fontWeight};
  line-height: ${theme.typography.font14.lineHeight};
  color: ${theme.palette.colors.black};
  margin-bottom: 0;
  margin-top: ${props => (props.shouldHaveMarginTop ? 8 : 0)}px;
  // css properties for ellipsis after second line
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
