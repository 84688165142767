import React, { FC } from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { Select } from '../../../../../components/reusable-components/select/Select';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import keyBy from 'lodash/fp/keyBy';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import {
  Label,
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { selectStyleProps } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from 'src/pages/workflow-builder-edit/hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import {
  ZendeskCustomFieldAndValue,
  ZendeskTicketCustomField,
} from 'src/types/workflowBuilderAPITypes';

interface TicketCustomFieldsProps {
  customFieldsAndValues?: ZendeskCustomFieldAndValue[];
  setCustomFieldsAndValues: (newVal: ZendeskCustomFieldAndValue[]) => void;
  ticketCustomFields?: ZendeskTicketCustomField[];
  title?: string;
}

const TicketCustomFields: FC<
  React.PropsWithChildren<TicketCustomFieldsProps>
> = ({
  customFieldsAndValues,
  setCustomFieldsAndValues,
  ticketCustomFields,
  title = 'Ticket Custom Field',
}) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();

  const customFieldArr = ticketCustomFields || [];

  const idCustomFieldMap = keyBy('id', customFieldArr);
  const idValueArr = customFieldsAndValues || [{ id: -1, value: '' }];
  const idsWithValues = new Set(idValueArr.map(idVal => idVal.id));

  return (
    <>
      <Label>{title}</Label>
      {idValueArr.map(
        ({ id, value }: ZendeskCustomFieldAndValue, idx: number) => {
          return (
            <div key={`custom-field-row-${idx}`}>
              <Row>
                <SelectWrapper>
                  <Select
                    aria-label='Select a Field...'
                    maxMenuHeight={180}
                    onChange={e => {
                      if (e) {
                        const updatedFieldValues = idValueArr
                          .slice(0, idx)
                          .concat({ id: parseInt(e.value), value: '' })
                          .concat(idValueArr.slice(idx + 1));

                        setCustomFieldsAndValues(updatedFieldValues);
                      }
                    }}
                    options={customFieldArr
                      .filter(({ id }) => !idsWithValues.has(id))
                      .map((customField: { id: number; title: string }) => ({
                        label: customField.title,
                        value: customField.id.toString(),
                      }))}
                    value={{
                      label: getOr(
                        'Select a Field...',
                        [id, 'title'],
                        idCustomFieldMap,
                      ),
                      value: String(get([id, 'id'], idCustomFieldMap)),
                    }}
                    {...selectStyleProps}
                  />
                </SelectWrapper>
                <Spacer width='8px' />
                {/* Initially, if user hasn't selected a field type, we wouldn't know what field value
                  input type to render. So we default it to text field. */}
                {get([id, 'type'], idCustomFieldMap) !== 'tagger' && (
                  <InputWrapper>
                    <ContextVariableAutocomplete
                      aria-label='Field Value'
                      onChange={fieldValue => {
                        if (get([id, 'type'], idCustomFieldMap) === 'integer') {
                          const reNumbers = /^[0-9\b]*$/;
                          const reCVs = /^\{\{.*\}\}$/;
                          if (reNumbers.test(fieldValue)) {
                            const updatedFieldValues = idValueArr
                              .slice(0, idx)
                              .concat({
                                id: idValueArr[idx].id,
                                value: Number(fieldValue),
                              })
                              .concat(idValueArr.slice(idx + 1));
                            setCustomFieldsAndValues(updatedFieldValues);
                          } else if (reCVs.test(fieldValue)) {
                            const updatedFieldValues = idValueArr
                              .slice(0, idx)
                              .concat({
                                id: idValueArr[idx].id,
                                value: fieldValue,
                              })
                              .concat(idValueArr.slice(idx + 1));
                            setCustomFieldsAndValues(updatedFieldValues);
                          }
                        } else {
                          const updatedFieldValues = idValueArr
                            .slice(0, idx)
                            .concat({
                              id: idValueArr[idx].id,
                              value: fieldValue,
                            })
                            .concat(idValueArr.slice(idx + 1));
                          setCustomFieldsAndValues(updatedFieldValues);
                        }
                      }}
                      placeholder='Field Value'
                      shouldIncludeSystemContextVariables
                      undefinedContextVariables={undefinedContextVariableIds}
                      value={value.toString()}
                    />
                  </InputWrapper>
                )}
                {get([id, 'type'], idCustomFieldMap) === 'tagger' && (
                  <Box
                    sx={{
                      flex: '1 1 50%',
                    }}
                  >
                    <ContextVariableSelectDropdown
                      additionalOptions={get(
                        [id, 'custom_field_options'],
                        idCustomFieldMap,
                      ).map(
                        ({ name, value }: { name: string; value: string }) => ({
                          category: 'Field Options',
                          label: name,
                          value,
                        }),
                      )}
                      aria-label='Field Value'
                      id={idValueArr[idx].id.toString()}
                      onChange={value => {
                        const updatedFieldValues = idValueArr
                          .slice(0, idx)
                          .concat({
                            id: idValueArr[idx].id,
                            value,
                          })
                          .concat(idValueArr.slice(idx + 1));

                        setCustomFieldsAndValues(updatedFieldValues);
                      }}
                      shouldIncludeSystemContextVariables
                      shouldProvideCVIdFormatting
                      value={value.toString()}
                    />
                  </Box>
                )}
                <ButtonWrapper>
                  <CloseButton
                    onClick={() => {
                      setCustomFieldsAndValues(
                        idValueArr
                          .slice(0, idx)
                          .concat(idValueArr.slice(idx + 1, idValueArr.length)),
                      );
                    }}
                    role='button'
                    src={xIcon}
                  />
                </ButtonWrapper>
              </Row>
              <Spacer height='8px' />
            </div>
          );
        },
      )}
      <LinkOut
        isDisabled={idValueArr.length === customFieldArr.length}
        onClick={() => {
          if (idValueArr.length !== customFieldArr.length) {
            setCustomFieldsAndValues(
              idValueArr.concat({
                id: -1,
                value: '',
              }),
            );
          }
        }}
      >
        + Add field
      </LinkOut>
    </>
  );
};

export default TicketCustomFields;

const SelectWrapper = styled('div')`
  flex: 1 1 50%;
`;

const InputWrapper = styled('div')`
  flex: 1 1 50%;
`;

const CloseButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 42px;
  width: 30px;
`;

const LinkOut = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;
