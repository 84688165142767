import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';

interface AutomationTextFieldProps {
  contextVariables: string[];
  onChange: (value: string) => void;
  text?: string;
}

const AutomationTextField: React.FC<
  React.PropsWithChildren<AutomationTextFieldProps>
> = ({ contextVariables, onChange, text }) => {
  const editorRef = useRef<EditorRef>(null);

  return (
    <Wrapper>
      <RichTextEditor
        contextVariables={contextVariables.map(contextVariable => ({
          context_variable_type: 'Short text',
          disabled: false,
          id: contextVariable,
          indent: 0,
          is_universal_context_variable: true,
          label:
            contextVariable[0] === '$'
              ? contextVariable.slice(1)
              : contextVariable,
        }))}
        editorRef={editorRef}
        initialContent={text}
        onChange={onChange}
      />
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  border: 1px solid ${theme.palette.colors.slate[300]};
  border-radius: 8px;
  font-family: inherit;
`;

export default AutomationTextField;
