import { useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { columns } from './constants';
import { formatSignedValue } from './helpers';
import ValueCell from './ValueCell';
import round from 'lodash/round';
import { NA } from 'src/constants/solve';
import {
  DiscoverTopicValue,
  TopicMetricTypeDetails,
} from 'src/reducers/discoverReducer/types';
import { getDisplayableDataByMetricType } from 'src/utils/discover/helpers';

type PercentChangeValueProps = {
  accessorKey: string;
  change: number | null;
  isNormalFont: boolean;
  metricType?: TopicMetricTypeDetails;
  value: DiscoverTopicValue;
};
const PercentChangeValue = ({
  accessorKey,
  change,
  isNormalFont,
  metricType,
  value,
}: PercentChangeValueProps) => {
  const { palette } = useTheme();

  if (value === null || change === null || !metricType) {
    return (
      <Typography color={palette.colors.grey[600]} variant='font14'>
        {NA}
      </Typography>
    );
  }

  const previousValue = round(Number(value) / (1 + change / 100), 2);
  const displayValue = getDisplayableDataByMetricType({
    dataType: metricType.data_type,
    forTable: true,
    metric: metricType.type,
    value: previousValue,
  });
  const isInvertedColor = Boolean(
    columns.find(item => item.accessorKey === accessorKey)?.isColorInverted,
  );

  return (
    <ValueCell
      isInvertedColor={isInvertedColor}
      isNormalFont={isNormalFont}
      subValue={displayValue}
      value={`${formatSignedValue(change)}%`}
    />
  );
};

export default PercentChangeValue;
