import '../../common/datePicker.scss';
import '../../common/tablePage.scss';
import 'react-day-picker/lib/style.css';
import 'react-day-picker/lib/style.css';

import React from 'react';

import AxisDropdown from './AxisDropdown/AxisDropdown';
import CustomDatepicker from './CustomDatepicker/CustomDatepicker';
import DeleteReportModal from './DeleteReportModal/index';
import OpenReportModal from './OpenReportModal/index';
import SaveReportModal from './SaveReportModal/index';
import ShareDropdown from './ShareDropdown/ShareDropdown';
import TimeframeDropdown from './TimeframeDropdown/TimeframeDropdown';
import ViewModeDropdown from './ViewModeDropdown';
import ChannelPicker from 'src/components/channel-picker';
import {
  ChartDataDict,
  SolveTableDict,
  TableDataDict,
} from 'src/services/apiInterfaces';
import { ViewModes } from 'src/utils/enums';

export interface SettingContainerProps {
  axisName?: string;
  channelEventTracker?: Function;
  channels?: string[] | undefined;
  chartData?: ChartDataDict;
  dateRangeEventTracker?: Function;
  hideYAxisDropdown?: boolean;
  /** Flag that indicates if channel dropdown should be displayed */
  isChannelDropdownDisabled?: boolean;
  shareBtnEventTracker?: Function;
  showChannelDropdown?: boolean;
  showlegend?: boolean;
  showReportSettings: boolean;
  tableData?: SolveTableDict | TableDataDict;
  timeframeEventTracker?: Function;
  viewMode: ViewModes;
}

class SettingsContainer extends React.Component<SettingContainerProps> {
  render(): React.ReactNode {
    const {
      axisName,
      channelEventTracker,
      channels,
      chartData,
      dateRangeEventTracker,
      hideYAxisDropdown,
      isChannelDropdownDisabled,
      shareBtnEventTracker,
      showChannelDropdown,
      showlegend,
      showReportSettings,
      tableData,
      timeframeEventTracker,
      viewMode,
    } = this.props;
    return (
      <>
        <div className='settings-container'>
          <div className='heading-cont'>
            <AxisDropdown
              axisName={axisName}
              dropdownOptions={chartData?.y || []}
              hidden={viewMode === 'Table'}
              hideYAxisDropdown={hideYAxisDropdown}
            />
            {showlegend && (
              <div className='SettingsContainer-bannerCont'>
                <div className={'SettingsContainer-legend'} />
                Numbers subject to change
              </div>
            )}

            {showReportSettings && (
              <>
                <SaveReportModal />
                <OpenReportModal />
                <DeleteReportModal />
              </>
            )}
          </div>
          <div className='settings-bar' data-testid='settings-bar'>
            <CustomDatepicker eventTracker={dateRangeEventTracker} />
            {!showChannelDropdown && <ViewModeDropdown viewMode={viewMode} />}
            <TimeframeDropdown
              disabled={viewMode === 'Table'}
              eventTracker={timeframeEventTracker}
            />
            {showChannelDropdown && (
              <ChannelPicker
                channels={channels as string[]}
                eventTracker={channelEventTracker}
                isDropdownDisabled={isChannelDropdownDisabled}
              />
            )}
            <ShareDropdown
              eventTracker={shareBtnEventTracker}
              tableData={tableData}
            />
          </div>
        </div>
      </>
    );
  }
}

export default SettingsContainer;
