import Box from '@mui/material/Box';

import TriageSimulationsTable from './TriageSimulationsTable';
import NavbarV2 from 'src/components/app/navbar/navbarV2';
import {
  useGetModelsAndVersionsQuery,
  useGetPredictionRunsQuery,
} from 'src/services/triage/triageApi';

const TriageSimulationsPage = () => {
  const { data, isLoading } = useGetPredictionRunsQuery();
  // Call it on page load, rtk query will get from cache on modal
  useGetModelsAndVersionsQuery();

  return (
    <Box
      bgcolor='white'
      display='flex'
      flexDirection='column'
      sx={{ minHeight: '100vh' }}
    >
      <NavbarV2 />
      <Box
        bgcolor='white'
        display='flex'
        flexDirection='column'
        height='100%'
        overflow='scroll'
        px={4}
        rowGap={2}
      >
        <TriageSimulationsTable
          data={data?.prediction_tests}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default TriageSimulationsPage;
