import { useCallback, useState } from 'react';
import { AnySchema, ValidationError } from 'yup';

interface UseValidatedStateProps<T> {
  initialValue: T;
  validationSchema: AnySchema<T>;
}

/**
 * Create a state that is validated synchronously against a Yup schema
 * returns a tuple of [value, setValue, error], where error is a string (if there is a validation error) or null
 * NOTE: This could be slow (depending on the complexity of the schema) as it blocks setState while validating. Depending on your use-case, you might want to create a `validateAsync` option.
 */
export const useValidatedState = <T>({
  initialValue,
  validationSchema,
}: UseValidatedStateProps<T>): [T, (value: T) => void, string | null] => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);

  const handleChange = useCallback(
    (value: T) => {
      try {
        validationSchema.validateSync(value);
        setError(null);
      } catch (err) {
        if (err instanceof ValidationError) {
          setError(err.message);
        } else {
          setError('An unexpected error occurred');
        }
      }
      setValue(value);
    },
    [validationSchema],
  );

  return [value, handleChange, error];
};
