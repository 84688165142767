import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/system';

import CSVMappings from './CSVMappings';
import { setToastType } from 'src/actions/toastNotification/toastNotification';
import shareIcon from 'src/assets/images/icon-cloud.svg';
import {
  selectSolveViewMode,
  selectViewMode,
} from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { selectSolveWorkflowsView } from 'src/reducers/solveWorkflowsReducer';
import { selectCurrentTab } from 'src/reducers/userReducer/userReducer';
import { SolveTableDict, TableDataDict } from 'src/services/apiInterfaces';
import { ViewModes } from 'src/utils/enums';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';

interface Props {
  eventTracker?: Function;
  tableData?: SolveTableDict | TableDataDict;
}

const ShareDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  eventTracker,
  tableData,
}: Props) => {
  const dispatch = useDispatch();
  // View mode for solve workflows
  const solveWorkflowsView = useSelector(selectSolveWorkflowsView);
  // View used by V0 tables
  const viewMode = useSelector(selectViewMode);
  const solveViewMode = useSelector(selectSolveViewMode);
  const product = useSelector(selectCurrentTab);
  const csvData = product && tableData ? CSVMappings[product]?.(tableData) : [];
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const reference: React.RefObject<HTMLDivElement> = useRef(null);
  const isTableView =
    viewMode === ViewModes.TABLE ||
    solveViewMode ||
    solveWorkflowsView === 'breakdowns'
      ? true
      : false;

  let isComponentMounted = true;

  const shareDropdown = (option: string): void => {
    setIsDropdownVisible(false);
    eventTracker && eventTracker(option);
  };

  //Function that creates a temporary DOM element to hold the URL that is being copied
  const copyUrltoClipboard = () => {
    const dummyInput = document.createElement('input'),
      text = window.location.href;

    document.body.appendChild(dummyInput);
    dummyInput.value = text;
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
  };

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        reference,
        ContainerRef,
        setIsDropdownVisible,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);

  return (
    <div className='dropdown  view-dropdown share-dropdown' ref={reference}>
      <button
        aria-expanded='false'
        className='share-button'
        onBlur={() => !isMouseOver && setIsDropdownVisible(false)}
        onClick={(): void => {
          setIsDropdownVisible(!isDropdownVisible);
        }}
        onMouseEnter={() => setIsMouseOver(true)}
        type='button'
      >
        <SVG src={shareIcon} />
        Share
      </button>

      {isDropdownVisible && (
        <div
          className='share-menu-cont'
          onMouseLeave={() => setIsMouseOver(false)}
          ref={ContainerRef}
        >
          <div
            aria-labelledby='dropdownMenu'
            className='dropdown-menu view-menu'
          >
            <div
              aria-labelledby='dropdownMenu'
              className='dropdown-option share-opt'
              onClick={() => {
                copyUrltoClipboard();
                dispatch(setToastType('link copied'));
                shareDropdown('Copy URL');
              }}
            >
              <span className='ShareDropdown-copyLinkIcon' />
              Copy URL
            </div>
            {isTableView && (
              <CSVLink
                className='dropdown-option share-opt'
                data={csvData}
                filename={`Forethought ${product} Report.csv`}
                onClick={(): void => shareDropdown('Download CSV')}
                onMouseDown={(e: MouseEvent) => e.preventDefault()}
                target='_blank'
              >
                <span className='ShareDropdown-CSVDownloadIcon' />
                Download CSV
              </CSVLink>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const SVG = styled(ReactSVG)`
  height: 16px;
  margin-right: 5px;
`;

export default ShareDropdown;
