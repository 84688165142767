import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import { FetchAction, FilterObject } from '../../types/types';
import {
  FETCH_SOLVE_CHANNELS_ERROR,
  FETCH_SOLVE_CHANNELS_SUCCESS,
  GET_OVERVIEW_DATA_SUCCESS,
  GET_SOLVE_TABLE_DATA_SUCCESS,
  SET_FILTER_TYPE,
  SET_SELECTED_CHANNEL,
} from '../actionTypes';
import { SolveDataActions } from './actionTypes';
import { getDataFailure, setLoading } from 'src/actions/data/data';
import { PageSettingsState } from 'src/reducers/pageSettingsReducer/types';
import { getSolveChannels } from 'src/services/api';
import {
  ChannelsInterface,
  SolveOverviewDataDict,
  SolveTableDict,
} from 'src/services/apiInterfaces';
import { getSolveDataAPI } from 'src/services/apiV1';
import type { RootState } from 'src/store/rootReducer';

export const getSolveTableDataSuccess = createAction<SolveTableDict>(
  GET_SOLVE_TABLE_DATA_SUCCESS,
);

const fetchChannelsSuccess = createAction<ChannelsInterface>(
  FETCH_SOLVE_CHANNELS_SUCCESS,
);

const fetchChannelsError = createAction<string>(FETCH_SOLVE_CHANNELS_ERROR);

export const getOverviewDataSuccess = createAction<SolveOverviewDataDict>(
  GET_OVERVIEW_DATA_SUCCESS,
);

export const setChannel = createAction<string>(SET_SELECTED_CHANNEL);

export const setIsSolveWorkflows = createAction<boolean>(
  SolveDataActions.SET_IS_SOLVE_WORKFLOWS,
);

export const setFilterType = createAction<FilterObject>(SET_FILTER_TYPE);

export const getSolveData: Function =
  () =>
  (dispatch: Dispatch, getState: Function): Promise<void | FetchAction> => {
    const state: RootState = getState();

    const { solveViewMode } = state.pageSettings as PageSettingsState;
    dispatch(setLoading(true));
    return getSolveDataAPI(state)
      .then((data: SolveOverviewDataDict & SolveTableDict) => {
        if (solveViewMode === 'overview') {
          dispatch(getOverviewDataSuccess(data));
        } else if (solveViewMode === 'breakdowns') {
          dispatch(getSolveTableDataSuccess(data));
        }
        dispatch(setLoading(false));
      })
      .catch((err: Error) => {
        dispatch(getDataFailure(err.message));
        dispatch(setLoading(false));
      });
  };

//Get channels available for solve
export const getSolveChannelsAPI: Function =
  () =>
  (dispatch: Dispatch): Promise<void | FetchAction | string[]> => {
    dispatch(setLoading(true));
    return getSolveChannels()
      .then((channels: ChannelsInterface) => {
        dispatch(fetchChannelsSuccess(channels));
        dispatch(getSolveData());
      })
      .catch((err: string) => {
        dispatch(fetchChannelsError(err));
        dispatch(setLoading(false));
      });
  };
