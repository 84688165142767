import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import defaultHeaders from 'src/services/defaultHeaders';
import { selectPusherSessionId } from 'src/slices/settings/settingsSlice';

export const useSetupHeaders = () => {
  const pusherSessionId = useSelector(selectPusherSessionId);
  useEffect(() => {
    defaultHeaders.set('edit-session-uuid', pusherSessionId);
  }, [pusherSessionId]);
};
