import { StepTypes } from './enums';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  articleWasHelpfulCase,
  articleWasNotHelpfulCase,
} from 'src/utils/constants';

export const areTransitionBranchesFilled = (
  canvasData: CanvasWorkflowBuilderState,
  transitionBranches: Array<{ display_name: string; parent_step_id: string }>,
) => {
  return transitionBranches.every(transitionBranch => {
    const parentStep = canvasData.steps[transitionBranch.parent_step_id];
    if (!parentStep) {
      return true;
    }
    /** 
       If display name is articleWasHelpfulCase parent step is a button step.
       If the button step has more than one transition the branch is filled
       because this button step will have a transition by default
       */

    if (transitionBranch.display_name === articleWasHelpfulCase) {
      if (parentStep.transitions.length === 1) {
        return false;
      } else if (parentStep.transitions[0]?.step_id) {
        return true;
      }

      /** 
       If display name is articleWasNotHelpfulCase parent step is a message step.
       If the message step has a transition the branch is filled
       because this step will have not have a transition by default
       */
    } else if (transitionBranch.display_name === articleWasNotHelpfulCase) {
      return parentStep.transitions.length > 0;
    }
  });
};

export const getTransitionStepIdsForArticleSuggestion = (
  canvasData: CanvasWorkflowBuilderState,
  transitionBranches: Array<{ display_name: string; parent_step_id: string }>,
): string[] | null => {
  const res = [];
  for (const transition of transitionBranches) {
    const transitionStepId =
      canvasData.steps[transition.parent_step_id]?.transitions[0]?.step_id;
    if (!transitionStepId) {
      return null;
    } else {
      res.push(transitionStepId);
    }
  }
  return res;
};

export const areTransitionBranchesFilledForDynamicArticleSuggestion = (
  canvasData: CanvasWorkflowBuilderState,
  transitionBranches: Array<{ display_name: string; parent_step_id: string }>,
) => {
  const transitionParentStep =
    canvasData.steps[transitionBranches[0]?.parent_step_id];

  if (!transitionParentStep) {
    return true;
  }

  // transition step is of type condition, this means the feedback button is disabled
  // dynamic article suggestion action can be treated as a non-branching step in this case, so we return true here
  if (transitionParentStep.step_type === StepTypes.CONDITION) {
    return true;
  }
  // transition step is of type button, check whether the button steps are filled or not
  else {
    return (
      transitionParentStep.transitions.length > 1 &&
      transitionParentStep.transitions.every(transition => transition.step_id)
    );
  }
};

export const isDynamicArticleSuggestionTheLeaf = (
  canvasData: CanvasWorkflowBuilderState,
  transitionBranches: Array<{ display_name: string; parent_step_id: string }>,
) => {
  const transitionParentStep =
    canvasData.steps[transitionBranches[0]?.parent_step_id];

  return transitionParentStep.transitions.every(
    transition => !transition.step_id,
  );
};

export const isArticleSuggestionTheLeaf = (
  canvasData: CanvasWorkflowBuilderState,
  transitionBranches: Array<{ display_name: string; parent_step_id: string }>,
) => {
  return transitionBranches.every(transitionBranch => {
    const transitionParentStep =
      canvasData.steps[transitionBranch.parent_step_id];

    if (transitionBranch.display_name === articleWasHelpfulCase) {
      return (
        transitionParentStep.transitions.length === 1 ||
        transitionParentStep.transitions.some(transition => !transition.step_id)
      );
    } else if (transitionBranch.display_name === articleWasNotHelpfulCase) {
      return transitionParentStep.transitions.length === 0;
    }
  });
};

export const getTransitionStepIdsForDynamicArticleSuggestion = (
  canvasData: CanvasWorkflowBuilderState,
  transitionBranches: Array<{ display_name: string; parent_step_id: string }>,
): string[] | null => {
  /**
   * For each of the transition parent step
   * 1. if the transition parent step is of type button, then we add the transitions of the button step to res
   * 2. if the transition parent step is of type condition, then find the branch that had condition expression === null
   *    and add the transition step id to res if it's not null
   *  */
  const res = [];
  for (const transition of transitionBranches) {
    const transitionParentStep = canvasData.steps[transition.parent_step_id];
    const transitionParentStepType = transitionParentStep.step_type;

    if (transitionParentStepType === StepTypes.BUTTONS) {
      transitionParentStep.transitions.forEach(transition =>
        res.push(transition.step_id),
      );
    } else if (transitionParentStepType === StepTypes.CONDITION) {
      const transition = transitionParentStep.transitions.filter(
        transition => transition.condition_expression === null,
      );
      if (transition && transition.length > 0 && transition[0].step_id) {
        res.push(transition[0].step_id);
      }
    } else {
      const transition = transitionParentStep.transitions;
      if (transition.length > 0 && transition[0].step_id) {
        res.push(transition[0].step_id);
      }
    }
  }
  return res;
};

// dynamic article suggestion is deletable at all times except when
// both feedback button is enabled and both branches are filled
export const canDynamicArticleSuggestionBeDeleted = (
  canvasData: CanvasWorkflowBuilderState,
  transitionBranches: Array<{ display_name: string; parent_step_id: string }>,
) => {
  const transitionParentStep =
    canvasData.steps[transitionBranches[0]?.parent_step_id];
  if (transitionParentStep.step_type === StepTypes.CONDITION) {
    return true;
  }

  return (
    transitionParentStep.transitions.length < 2 ||
    transitionParentStep.transitions.some(
      transition => transition.step_id === null,
    )
  );
};

export const canFeedbackButtonBeRemoved = (
  canvasData: CanvasWorkflowBuilderState,
  actionId: string,
) => {
  const transitionBranches =
    canvasData.canvas_action_id_to_action_component[actionId]
      ?.transition_branches;

  if (!transitionBranches) {
    return false;
  }

  return canDynamicArticleSuggestionBeDeleted(canvasData, transitionBranches);
};
