import './styles/TooltipPopperStyles.scss';

import React from 'react';

import { TooltipPopperProps } from 'src/components/tooltip-popper/types/TooltipPopperTypes';

export const TooltipPopper: React.FC<
  React.PropsWithChildren<TooltipPopperProps>
> = ({ tooltipDescription, tooltipText, tooltipTitle }) => {
  return (
    <div className='TooltipPopper'>
      <p className='TooltipPopper-title'>{tooltipTitle}</p>
      <p className='TooltipPopper-desc'>{tooltipDescription}</p>
      <p className='TooltipPopper-text'>{tooltipText}</p>
      <div className='TooltipPopper-carat' />
    </div>
  );
};
