import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, DialogContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DISCOVER_TEXT } from './constants';
import DiscoverAutomationCheck from 'src/assets/images/discover-automation-check.svg';
import BaseModal from 'src/components/base-modal';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectGlobalDiscoverOptions,
  setGlobalDiscoverOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';
import { Routes } from 'src/utils/enums';

const PostAutomationActionModal = () => {
  const navigate = useNavigate();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const location = useLocation();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const dispatch = useAppDispatch();
  const { postAutomationActionModalOptions, topicToAutomate } = useSelector(
    selectGlobalDiscoverOptions,
  );

  const { palette } = useTheme();
  const { automatedObjectName, isOpen, redirectUrl } =
    postAutomationActionModalOptions;

  const isAutonomousAgentEnabled = featureFlags.includes(
    'autonomous_agent_enabled',
  );

  const renderTitleText = () => {
    if (redirectUrl) {
      if (automatedObjectName) {
        return DISCOVER_TEXT.postAutomationModalAddedToWorkflowSingularTitle;
      }

      return DISCOVER_TEXT.postAutomationModalWorkflowTitle;
    }

    return DISCOVER_TEXT.postAutomationModalInterestedTitle;
  };

  const renderSubtitleText = () => {
    if (redirectUrl) {
      if (automatedObjectName) {
        return (
          <>
            A topic has been successfully added to the{' '}
            <b>{automatedObjectName}</b>!
            <br />
            {DISCOVER_TEXT.postAutomationModalCompletedSubtitleEnding}
          </>
        );
      }
      return (
        <>
          A workflow from the topic{' '}
          <b>{deriveTopicNameFromTopic(topicToAutomate)}</b> is successfully
          created!
          <br />
          {DISCOVER_TEXT.postAutomationModalCompletedSubtitleEnding}
        </>
      );
    }

    return DISCOVER_TEXT.postAutomationModalInterestedSubtitle;
  };

  const onClose = () =>
    dispatch(
      setGlobalDiscoverOptions({
        postAutomationActionModalOptions: {
          automatedObjectName: '',
          isOpen: false,
          redirectUrl: '',
        },
        topicToAutomate: null,
      }),
    );

  return (
    <BaseModal
      featureName='postautomationmodal'
      HeaderProps={{
        mb: 3,
      }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogContent
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          mb: redirectUrl ? 0 : 7,
          padding: 3,
        }}
      >
        <Box mb={4}>
          <img src={DiscoverAutomationCheck} />
        </Box>
        <Box mb={1.5}>
          <Typography variant='font28'>{renderTitleText()}</Typography>
        </Box>
        <Box textAlign='center'>
          <Typography color={palette.colors.grey[700]} variant='font14'>
            {renderSubtitleText()}
          </Typography>
        </Box>
        {redirectUrl && (
          <Box display='flex' gap='8px' mt='32px' width='100%'>
            <StyledLink to={Routes.DISCOVER_AUTOMATION}>
              <Button
                fullWidth
                onClick={() => {
                  emitTrackingEventCallback(
                    'discover-stay-in-discover-button-clicked',
                    { pathname: location.pathname },
                  );
                  onClose();
                }}
                size='large'
                variant='secondary'
              >
                Automate more topics
              </Button>
            </StyledLink>
            <StyledLink
              to={
                redirectUrl + (isAutonomousAgentEnabled ? '&mode=classic' : '')
              }
            >
              <Button
                fullWidth
                onClick={() => {
                  emitTrackingEventCallback(
                    'discover-continue-to-solve-button-clicked',
                    { pathname: location.pathname },
                  );

                  onClose();
                }}
                size='large'
                variant='secondary'
              >
                Build with Solve
              </Button>
            </StyledLink>
            {isAutonomousAgentEnabled && (
              <Box flex={1}>
                <Button
                  fullWidth
                  onClick={async () => {
                    emitTrackingEventCallback(
                      'discover-continue-to-solve-button-clicked',
                      { pathname: location.pathname },
                    );

                    navigate(
                      decodeURIComponent(redirectUrl) + '&mode=autoflow',
                    );

                    onClose();
                  }}
                  size='large'
                  variant='secondary'
                >
                  <Box alignItems='center' display='flex' gap='4px'>
                    <Typography variant='font14Bold'>
                      Build with AutoFlows
                    </Typography>
                  </Box>
                </Button>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
    </BaseModal>
  );
};

const StyledLink = styled(Link)`
  flex: 1;
  text-decoration: none;
`;

export default PostAutomationActionModal;
