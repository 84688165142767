import { ListOption } from '@forethought-technologies/forethought-elements';
import {
  FieldPredictorConfig,
  TriageHelpdeskTicketFields,
  TriageSalesforceTicketFields,
} from './types';
import { Helpdesk } from 'src/components/app/types';

export const isSalesforceTicketFields = (
  ticketFields: TriageHelpdeskTicketFields | undefined,
): ticketFields is TriageSalesforceTicketFields => {
  return (
    (ticketFields as TriageSalesforceTicketFields)?.case_origins !== undefined
  );
};

export const getFieldPredictorOutputField = (
  helpdesk: Helpdesk | undefined | null,
  fieldsToPredict: FieldPredictorConfig,
): Record<string, string> | undefined => {
  switch (helpdesk) {
    case 'zendesk':
      return fieldsToPredict?.outputs[0]?.written_object
        .custom_fields as Record<string, string>;
    case 'salesforce':
      return fieldsToPredict?.outputs[0]?.written_object as Record<
        string,
        string
      >;
    default:
      return;
  }
};

export const getDataSourcePredictedField = (
  fieldToPredictOutputFieldId: string,
  ticketFields: TriageHelpdeskTicketFields | undefined,
): ListOption | undefined => {
  if (isSalesforceTicketFields(ticketFields)) {
    const foundSalesforceField = ticketFields.output_fields?.find(
      field => field.name === fieldToPredictOutputFieldId,
    );

    if (foundSalesforceField) {
      return {
        label: foundSalesforceField.label,
        value: foundSalesforceField.name,
      };
    }
    return;
  }

  const foundZendeskField = ticketFields?.output_fields?.find(
    (field: { id: number }) => String(field.id) === fieldToPredictOutputFieldId,
  );

  if (foundZendeskField) {
    return {
      label: foundZendeskField.name,
      value: foundZendeskField.id.toString(),
    };
  }
};
