import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import useActionBuilderActions from '../../../intent-email-journey-map/hooks/useActionBuilderActions';

const id = 'email-button-action-selector';
const label = 'Button action';

export default function ButtonActionSelector({
  selectedActionId,
  setSelectedAction,
}: {
  selectedActionId?: string;
  setSelectedAction: (action: string) => void;
}) {
  const { actions, actionsLoading } = useActionBuilderActions();

  const areActionsAvailable = actions.length > 0;

  if (actionsLoading) {
    return null; // TODO
  }

  return (
    <SelectDropdown
      disabled={!areActionsAvailable}
      id={id}
      label={label}
      onChange={e => setSelectedAction(e.target.value)}
      options={actions.map(action => ({
        description: action.action_description,
        label: action.action_name,
        value: action.action_id,
      }))}
      placeholder={areActionsAvailable ? '' : 'There are no existent actions'}
      value={selectedActionId ?? ''}
    />
  );
}
