export const isContextVariable = (tag: string | number) =>
  typeof tag === 'string' && tag.slice(0, 2) === '{{' && tag.slice(-2) === '}}';

export const hasAtLeastOneError = (
  errorMessages: Array<string | Array<string>>,
) => {
  return errorMessages.some(errMsgOrArr => {
    return typeof errMsgOrArr === 'string'
      ? !!errMsgOrArr
      : errMsgOrArr.some(Boolean);
  });
};
