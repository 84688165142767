import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsModelTraining } from 'src/actions/workflow-builder/workflowBuilderActions';
import { selectPusherSessionId } from 'src/slices/settings/settingsSlice';
import { getUserOrgCode } from 'src/utils/getUserOrgCode';

export enum PusherEvents {
  CONFIGURATION_CHANGE = 'configuration-change',
  DELETE_EMAIL_CONFIGURATION = 'delete-email-configuration',
  DELETE_INTENT = 'delete-intent',
  INTENT_STATUS = 'intent-status-change',
  INTENT_TEST_UPDATED = 'intent-test-updated',
  MODEL_TRAINING_STATUS_CHANGE = 'model-training-status-change',
  RELOAD_WORKFLOW = 'reload-workflow',
  SOLVE_EMAIL_REPLY_GENERATION = 'solve-email-reply-generation',
  UPDATE_INTENT = 'update-intent',
  WORKFLOW_ACTIVE_STATUS_CHANGE = 'workflow-active-status-change',
}

const useListenSolveEmailReplyGenerationJobStatus = ({
  onEvent,
}: {
  onEvent: () => void;
}) => {
  const orgCode = getUserOrgCode();
  const sessionId = useSelector(selectPusherSessionId);

  useEffect(() => {
    if (orgCode) {
      const channelName = `solve;canvas;${orgCode}`;
      const channel = window.pusher.subscribe(channelName);
      channel.bind(PusherEvents.SOLVE_EMAIL_REPLY_GENERATION, () => {
        onEvent();
      });
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [orgCode, onEvent, sessionId]);
};

export const useDeleteEmailConfigurationEventSubscription = ({
  onEvent,
  orgCode,
}: {
  onEvent: (configurationId: string, intentId: string) => void;
  orgCode?: string;
}) => {
  const sessionId = useSelector(selectPusherSessionId);

  useEffect(() => {
    if (orgCode) {
      const channelName = `solve;canvas;${orgCode}`;
      const channel = window.pusher.subscribe(channelName);
      channel.bind(
        PusherEvents.DELETE_EMAIL_CONFIGURATION,
        (data: {
          configuration_id: string;
          edit_session_uuid: string;
          intent_id: string;
        }) => {
          const { configuration_id, edit_session_uuid, intent_id } = data;
          if (edit_session_uuid !== sessionId) {
            onEvent(configuration_id, intent_id);
          }
        },
      );
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [orgCode, onEvent, sessionId]);
};

const useIntentStatusEventSubscription = ({
  onEvent,
  orgCode,
}: {
  onEvent: () => void;
  orgCode?: string;
}) => {
  const sessionId = useSelector(selectPusherSessionId);

  useEffect(() => {
    if (orgCode) {
      const channelName = `solve;canvas;${orgCode}`;
      const channel = window.pusher.subscribe(channelName);
      channel.bind(
        PusherEvents.INTENT_STATUS,
        (data: { edit_session_uuid: string }) => {
          if (data.edit_session_uuid !== sessionId) {
            onEvent();
          }
        },
      );
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [orgCode, onEvent, sessionId]);
};

const useDeleteIntentEventSubscription = ({
  onEvent,
  orgCode,
}: {
  onEvent: () => void;
  orgCode?: string;
}) => {
  const sessionId = useSelector(selectPusherSessionId);

  useEffect(() => {
    if (orgCode) {
      const channelName = `solve;canvas;${orgCode}`;
      const channel = window.pusher.subscribe(channelName);
      channel.bind(
        PusherEvents.DELETE_INTENT,
        (data: { edit_session_uuid: string }) => {
          if (data.edit_session_uuid !== sessionId) {
            onEvent();
          }
        },
      );
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [orgCode, onEvent, sessionId]);
};

const useUpdateIntentEventSubscription = ({
  onEvent,
  orgCode,
}: {
  onEvent: () => void;
  orgCode?: string;
}) => {
  const sessionId = useSelector(selectPusherSessionId);

  useEffect(() => {
    if (orgCode) {
      const channelName = `solve;canvas;${orgCode}`;
      const channel = window.pusher.subscribe(channelName);
      channel.bind(
        PusherEvents.UPDATE_INTENT,
        (data: { edit_session_uuid: string }) => {
          if (data.edit_session_uuid !== sessionId) {
            onEvent();
          }
        },
      );
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [orgCode, onEvent, sessionId]);
};

const useReloadWorkflowEventSubscription = ({
  intentWorkflowId,
  onEvent,
  orgCode,
  version,
}: {
  intentWorkflowId: string | null;
  onEvent: () => void;
  orgCode?: string;
  version: number;
}) => {
  const sessionId = useSelector(selectPusherSessionId);

  useEffect(() => {
    if (orgCode && intentWorkflowId) {
      const channelName = `solve;canvas;${orgCode};${intentWorkflowId}`;
      const channel = window.pusher.subscribe(channelName);

      channel.bind(
        PusherEvents.RELOAD_WORKFLOW,
        (data: { edit_session_uuid: string }) => {
          if (data.edit_session_uuid !== sessionId) {
            onEvent();
          }
        },
      );
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [orgCode, intentWorkflowId, version, onEvent, sessionId]);
};

const useConfigurationChangeEventSubscription = ({
  onEvent,
  orgCode,
}: {
  onEvent: () => void;
  orgCode?: string;
}) => {
  const sessionId = useSelector(selectPusherSessionId);

  useEffect(() => {
    if (orgCode) {
      const channelName = `solve;configuration;${orgCode}`;
      const channel = window.pusher.subscribe(channelName);

      channel.bind(
        PusherEvents.CONFIGURATION_CHANGE,
        (data: { edit_session_uuid: string }) => {
          if (data.edit_session_uuid !== sessionId) {
            onEvent();
          }
        },
      );
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [orgCode, onEvent, sessionId]);
};

const useModelTrainingStatus = (orgCode?: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (orgCode) {
      const channelName = `solve;preview;${orgCode};model-training`;
      const channel = window.pusher.subscribe(channelName);

      channel.bind(
        PusherEvents.MODEL_TRAINING_STATUS_CHANGE,
        (data: { model_training_status: string }) => {
          const iframe = document.getElementById(
            'forethought-chat',
          ) as HTMLIFrameElement;
          const isTraining = data.model_training_status === 'training';
          dispatch(setIsModelTraining(isTraining));
          iframe?.contentWindow?.postMessage(
            {
              rawParams: {
                'data-is-model-training': isTraining ? 'true' : 'false',
              },
            },
            '*',
          );
        },
      );
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [dispatch, orgCode]);
};

const useCanvasActiveWorkflowStatusEventSubscription = ({
  intentWorkflowId,
  onEvent,
  orgCode,
  version,
}: {
  intentWorkflowId: string | null;
  onEvent: () => void;
  orgCode?: string;
  version: number;
}) => {
  const sessionId = useSelector(selectPusherSessionId);

  useEffect(() => {
    if (orgCode && intentWorkflowId) {
      const channelName = `solve;canvas;${orgCode};${intentWorkflowId}`;
      const channel = window.pusher.subscribe(channelName);

      channel.bind(
        PusherEvents.WORKFLOW_ACTIVE_STATUS_CHANGE,
        (data: { edit_session_uuid: string }) => {
          if (data.edit_session_uuid !== sessionId) {
            onEvent();
          }
        },
      );
      return () => window.pusher.unsubscribe(channelName);
    }
  }, [orgCode, version, onEvent, intentWorkflowId, sessionId]);
};

export {
  useCanvasActiveWorkflowStatusEventSubscription,
  useConfigurationChangeEventSubscription,
  useDeleteIntentEventSubscription,
  useIntentStatusEventSubscription,
  useListenSolveEmailReplyGenerationJobStatus,
  useModelTrainingStatus,
  useReloadWorkflowEventSubscription,
  useUpdateIntentEventSubscription,
};
