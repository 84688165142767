import {
  apiTableHeaderDict,
  emailTableHeaderDict,
  widgetTableHeaderDict,
  workflowWidgetTableHeaderDict,
} from './tableHeadersUtil';
import {
  apiTableVal,
  emailTableVal,
  widgetTableVal,
  workflowWidgetTableVal,
} from './tableValuesUtil';
import store from 'src/store/store';
import {
  apiEmptyBreakdown,
  emailEmptyBreakdown,
  widgetEmptyBreakdown,
} from 'src/utils/analyticsUtils';
import { SolveMacroControlAdminViews, solveViews } from 'src/utils/constants';
import { SolveChannels, Tabs, UserRoles } from 'src/utils/enums';

// Empty breakdown for workflows widget data
const workflowsWidgetEmptyBreakdown = () => {
  const { filterType } = store.getState().solveData;
  const { name } = filterType;
  return {
    conversations: 0,
    efficiency: 0,
    field_value: name,
    handoffs: 0,
    resolutions: 0,
    self_serve_rate: 0,
  };
};

/**
 * Breakdown for no data view
 * @param {isSolveWorkflows: boolean} - Represents if the view for the abalytics is workflows.
 * @param {selectedChannel: string} - The current selected channel for analytics. eg. Email/Widget
 * @returns Object with keys and values to render when there's no data.
 */
export const showNoDataViewBreakdown = (
  isSolveWorkflows: boolean,
  selectedChannel: string,
): { [key: string]: number | string } => {
  if (isSolveWorkflows) {
    return workflowsWidgetEmptyBreakdown();
  } else {
    switch (selectedChannel) {
      case SolveChannels.EMAIL:
        return emailEmptyBreakdown;
      case SolveChannels.API:
        return apiEmptyBreakdown;
      case SolveChannels.WIDGET:
      default:
        return widgetEmptyBreakdown;
    }
  }
};

/**
 * Returns table values used for table.
 * @param {isSolveWorkflows: boolean} - Represents if the view for the abalytics is workflows.
 * @param {selectedChannel: string} - The current selected channel for analytics. eg. Email/Widget
 * @returns array of string with values for table.
 */
export const getTableValues = (
  isSolveWorkflows: boolean,
  selectedChannel: string,
): Array<string> => {
  if (isSolveWorkflows) {
    return workflowWidgetTableVal;
  } else {
    switch (selectedChannel) {
      case SolveChannels.EMAIL:
        return emailTableVal;
      case SolveChannels.API:
        return apiTableVal;
      case SolveChannels.WIDGET:
      default:
        return widgetTableVal;
    }
  }
};

/**
 * Returns the dictionary to use for columns and tooltips of the table
 * @param {isSolveWorkflows: boolean} - Represents if the view for the abalytics is workflows.
 * @param {selectedChannel: string} - The current selected channel for analytics. eg. Email/Widget
 * @returns Array of object containing heading, headingClass, propertyName, productSorted, tooltipTitle, and tooltipText
 */
export const getTableHeaderDict = (
  isSolveWorkflows: boolean,
  selectedChannel: string,
  filterType?: string,
): Array<{ [key: string]: string | null }> => {
  if (isSolveWorkflows && filterType) {
    return workflowWidgetTableHeaderDict(filterType);
  } else {
    switch (selectedChannel) {
      case SolveChannels.EMAIL:
        return emailTableHeaderDict;
      case SolveChannels.API:
        return apiTableHeaderDict;
      case SolveChannels.WIDGET:
      default:
        return widgetTableHeaderDict;
    }
  }
};

/**
 * Returns name of pie chart based on current view and channel.
 * @param {selectedChannel: string} - The current selected channel for analytics. eg. Email/Widget
 * @returns String that will be displayed as the name of the chart.
 */
export const getPieChartName = (
  isSolveWorkflows: boolean,
  selectedChannel: string,
): string => {
  if (isSolveWorkflows) {
    return 'Agatha Conversations';
  } else {
    switch (selectedChannel) {
      case SolveChannels.EMAIL:
        return 'Total email tickets processed by Forethought';
      case SolveChannels.API:
      case SolveChannels.WIDGET:
      default:
        return 'Agatha Interactions';
    }
  }
};
export const getPieChartTooltipMessage = (selectedChannel: SolveChannels) => {
  switch (selectedChannel) {
    case SolveChannels.EMAIL:
      return 'This represents the number of tickets sent to Forethought to attempt deflection';
  }
};

/**
 * Returns description of pie chart based on current view and channel.
 * @param {selectedChannel: string} - The current selected channel for analytics. eg. Email/Widget
 * @returns String that will be displayed as the description of the chart.
 */
export const getPieChartDescription = (selectedChannel: string): string => {
  switch (selectedChannel) {
    case SolveChannels.EMAIL:
      return 'Total Email Volume';
    case SolveChannels.API:
    case SolveChannels.WIDGET:
    default:
      return 'Total Interactions';
  }
};

/**
 * Returns name of bar chart based on current view and channel.
 * @param {selectedChannel: string} - The current selected channel for analytics. eg. Email/Widget
 * @returns String that will be displayed as the name of the chart.
 */
export const getBarChartName = (selectedChannel: string): string => {
  switch (selectedChannel) {
    case SolveChannels.EMAIL:
      return 'Email Ticket Breakdown';
    case SolveChannels.API:
    case SolveChannels.WIDGET:
    default:
      return 'Agatha Interactions Breakdown';
  }
};

/**
 * Gets axis name to be displayed based on: if the current analytic, the current filter and the selected channel.
 * @param { boolean} isSolveWorkflows - Represents if the selected analytics tab is Solve Workflows.
 * @param {string} selectedChannel - The current selected channel for analytics. eg. Email/Widget
 * @param { name: string, key: string } selectedFilter - Current selected filter.
 * @returns axis name for table view.
 */
export const getSettingsContainerAxisName = (
  isSolveWorkflows: boolean,
  selectedChannel: string,
  selectedFilter: { key: string; name: string },
): string => {
  let axis = '';
  const filterName = selectedFilter.name.toLowerCase();
  if (isSolveWorkflows) {
    if (filterName === 'all workflows') {
      axis = 'Workflows';
    } else if (filterName === 'all articles') {
      axis = 'Articles';
    }
  } else {
    axis = selectedChannel;
  }
  return `Breakdown - ${axis}`;
};

/**
 * Sets the view options to be displayed on the dropdown for Solve analytics page.
 * @param { boolean} isSolveWorkflows - Represents if the selected analytics tab is Solve Workflo
 * @param { string[] | undefined} availableTabs - Tabs user can access.
 * @param {string} userRole - Type of user. e.g superadmin, admin, user.
 * @returns Array containing the view options to show on the view navigator.
 */
export const getSolveViewOptions = (
  isSolveWorkflows: boolean,
  availableTabs: string[] | undefined,
  userRole: string,
): string[] => {
  const includesMacro = availableTabs?.includes(Tabs.MACRO_CONTROLS);
  const isSuperAdmin = userRole === UserRoles.ROLE_SUPER_ADMIN;
  const isAdmin = userRole === UserRoles.ROLE_ADMIN;
  const isAllowed = isSuperAdmin || isAdmin;
  if (!isSolveWorkflows && includesMacro && isAllowed) {
    return SolveMacroControlAdminViews;
  } else {
    return solveViews;
  }
};
