import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material';

import { dataVisColors } from 'src/constants/colors';

interface IntentTestPieChartProps {
  data: { name: string; y: number }[];
  total: string;
}

function IntentTestPieChart({ data, total }: IntentTestPieChartProps) {
  const { typography } = useTheme();

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        chart: {
          height: 280,
          plotShadow: false,
          style: { fontFamily: typography.fontFamily },
          type: 'pie',
        },
        colors: dataVisColors,
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            borderWidth: 0,
            cursor: 'pointer',
            innerSize: '70%',
            shadow: false,
            size: '85%',
            states: {
              brightness: null,
              hover: {
                halo: null,
              },
            },
          },
        },
        series: [
          {
            data,
          },
        ],
        subtitle: {
          align: 'center',
          text: 'Conversations',
          verticalAlign: 'middle',
          y: 30,
        },
        title: {
          align: 'center',
          style: {
            fontSize: '24px',
          },
          text: total,
          verticalAlign: 'middle',
          y: 20,
        },
        tooltip: {
          headerFormat: '',
          padding: 0,
          pointFormat:
            "<div class='PieChart-tooltip' style='{border: 2px solid {series.color}'}><span>{point.name}</span>: " +
            '<span>{point.y:,.0f}%</div>',
          useHTML: true,
          valueDecimals: 0,
        },
      }}
    />
  );
}

const MemoizedIntentTestPieChart = React.memo(IntentTestPieChart);

export default MemoizedIntentTestPieChart;
