import { TriageTicket } from 'src/services/triage/types';

export const PARAM_SEARCH_NAME = 'ticket_search_keyword';
export const PARAM_FILTER_NAME = 'ticket_filter';

export const FILTER_STATUS_KEY = 'ticket_status_';
export const FILTER_HELPDESK_KEY = 'written_to_helpdesk_';
export const FILTER_REASON_KEY = 'reason_for_no_prediction_';
export const FILTER_CORRECT_PREDICTION_KEY = 'correct_prediction_filter_';

export const FILTER_STATUS = {
  CLOSED: `${FILTER_STATUS_KEY}closed`,
  IN_PROGRESS: `${FILTER_STATUS_KEY}in_progress`,
  OPEN: `${FILTER_STATUS_KEY}open`,
  RESOLVED: `${FILTER_STATUS_KEY}resolved`,
};

export const FILTER_HELP_DESK = {
  NO: `${FILTER_HELPDESK_KEY}no`,
  YES: `${FILTER_HELPDESK_KEY}yes`,
};

export const FILTER_CORRECT_PREDICTION = {
  NO: `${FILTER_CORRECT_PREDICTION_KEY}no`,
  YES: `${FILTER_CORRECT_PREDICTION_KEY}yes`,
};

export const TABLE_COLUMNS: {
  asIs?: boolean;
  key: keyof TriageTicket;
  size?: number;
  title: string;
  tooltip: string;
}[] = [
  {
    key: 'timestamp',
    size: 220,
    title: 'Prediction date',
    tooltip: '',
  },
  {
    asIs: true,
    key: 'id',
    size: 210,
    title: 'Ticket ID',
    tooltip: '',
  },
  {
    key: 'title',
    size: 300,
    title: 'Ticket title',
    tooltip: '',
  },
  {
    key: 'body',
    size: 300,
    title: 'Ticket body',
    tooltip: '',
  },
  {
    key: 'status',
    size: 160,
    title: 'Ticket status',
    tooltip: '',
  },
  {
    key: 'predicted_label',
    size: 300,
    title: 'Predicted label',
    tooltip: '',
  },
  {
    key: 'current_label',
    size: 300,
    title: 'Finalized label',
    tooltip: 'This is the finalized label assigned to a resolved/closed ticket',
  },
  {
    key: 'is_prediction_accurate',
    size: 200,
    title: 'Correct prediction',
    tooltip: '',
  },
  {
    key: 'reason_no_prediction',
    size: 360,
    title: 'Reason for no prediction',
    tooltip: '',
  },
  {
    key: 'written_to_helpdesk',
    size: 260,
    title: 'Written to the helpdesk',
    tooltip:
      'This field indicates whether a Triage model successfully wrote the prediction value to the helpdesk',
  },
];

export const FILTER_OPTIONS_STATUS_ITEM = {
  label: 'Ticket Status',
  options: [
    {
      label: 'Open',
      value: FILTER_STATUS.OPEN,
    },
    {
      label: 'In progress',
      value: FILTER_STATUS.IN_PROGRESS,
    },
    {
      label: 'Resolved',
      value: FILTER_STATUS.RESOLVED,
    },
    {
      label: 'Closed',
      value: FILTER_STATUS.CLOSED,
    },
  ],
  value: FILTER_STATUS_KEY,
};

export const reasonForNoPredictionOptions = [
  {
    label: 'Below confidence threshhold',
    value: `${FILTER_REASON_KEY}below_confidence_threshold`,
  },
  {
    label: 'Empty input fields',
    value: `${FILTER_REASON_KEY}empty_input_fields`,
  },
  {
    label: 'Model dependencies not met',
    value: `${FILTER_REASON_KEY}model_dependencies_not_met`,
  },
];

export const FILTER_OPTIONS = [
  {
    label: 'Correct Prediction',
    options: [
      {
        label: 'Yes',
        value: FILTER_CORRECT_PREDICTION.YES,
      },
      {
        label: 'No',
        value: FILTER_CORRECT_PREDICTION.NO,
      },
    ],
    value: FILTER_CORRECT_PREDICTION_KEY,
  },
  {
    label: 'Reason for no prediction',
    options: reasonForNoPredictionOptions,
    value: FILTER_REASON_KEY,
  },
  {
    label: 'Written to Helpdesk',
    options: [
      {
        label: 'Yes',
        value: FILTER_HELP_DESK.YES,
      },
      {
        label: 'No',
        value: FILTER_HELP_DESK.NO,
      },
    ],
    value: FILTER_REASON_KEY,
  },
];

export const FILTER_KEY_FINALIZED_LABEL = 'finalized_label_filter_';
export const FILTER_LABEL_FINALIZED_LABEL = 'Finalized label';

export const FILTER_KEY_PREDICTED_LABEL = 'predicted_label_filter_';
export const FILTER_LABEL_PREDICTED_LABEL = 'Predicted label';

export const FILTER_KEY_REASON_NO_PREDICTION = 'reason_no_prediction_filter_';
export const FILTER_LABEL_REASON_NO_PREDICTION = 'Reason for no prediction';

export const INITIAL_SEARCH_TYPE = 'keyword';

export const SEARCH_TYPE_OPTIONS = [
  {
    text: 'Ticket',
    value: 'keyword',
  },
  {
    text: 'Ticket ID',
    value: 'ticket_id',
  },
];

export const PARAM_SEARCH_TYPE = 'ticket_search_type';
