import { FieldArray, useField } from 'formik';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconEqual, IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ActionBuilderFormContextVariableAutocomplete from './ActionBuilderFormContextVariableAutocomplete';
import ActionBuilderFormTextField from './ActionBuilderFormTextField';
import { useGetActionBuilderDetailFormIsActive } from 'src/pages/action-builder/hooks';
import { KVPairs } from 'src/types/actionBuilderApiTypes';

interface KeyValuePairsProps {
  name: string;
}

const KeyValuePairs = ({ name }: KeyValuePairsProps) => {
  const [paramsField] = useField<KVPairs[]>({
    name,
  });
  const isActive = useGetActionBuilderDetailFormIsActive();

  const { palette } = useTheme();

  return (
    <Box mt={2}>
      <Box color={palette.colors.grey[700]} display='flex' mb={0.5}>
        <Box flex={1}>
          <Typography variant='font14Bold'>Key</Typography>
        </Box>
        <Box flex={1} ml={1}>
          <Typography variant='font14Bold'>Value</Typography>
        </Box>
      </Box>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <>
            {paramsField.value.map((_, index) => (
              <KeyValuePair
                index={index}
                key={index}
                name={name}
                onRemove={() => {
                  if (index === 0) {
                    arrayHelpers.replace(index, { key: '', value: undefined });
                    return;
                  }
                  arrayHelpers.remove(index);
                }}
              />
            ))}
            <Button
              disabled={isActive}
              onClick={() => arrayHelpers.push({ key: '', value: undefined })}
              startIcon={<IconPlus />}
              variant='ghost'
            >
              Add
            </Button>
          </>
        )}
      />
    </Box>
  );
};

interface KeyValuePairProps {
  index: number;
  name: string;
  onRemove: () => void;
}

const KeyValuePair = ({ index, name, onRemove }: KeyValuePairProps) => {
  const { palette } = useTheme();
  const isActive = useGetActionBuilderDetailFormIsActive();

  return (
    <Box alignItems='center' display='flex' gap={2} mb={2}>
      <Box flex={1}>
        <ActionBuilderFormTextField
          name={`${name}.${index}.key`}
          placeholder='Key'
        />
      </Box>
      <Box alignItems='center' display='flex'>
        <IconEqual color={palette.colors.slate[600]} />
      </Box>
      <Box flex={1}>
        <ActionBuilderFormContextVariableAutocomplete
          aria-label={`${name}.${index}.value`}
          name={`${name}.${index}.value`}
          placeholder='Enter value or select CV from the list'
        />
      </Box>
      <IconButton
        aria-label='Remove Params'
        disabled={isActive}
        onClick={onRemove}
        variant='ghost'
      >
        <IconTrash size={20} />
      </IconButton>
    </Box>
  );
};

export default KeyValuePairs;
