import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmailTranslanstionSliceState } from './types';
import { EmailTranslation } from 'src/pages/workflow-builder-edit/types';
import type { RootState } from 'src/store/rootReducer';

const initialState: EmailTranslanstionSliceState = {
  emailTranslations: [],
};

const emailTranslationSlice = createSlice({
  initialState,
  name: 'emailTranslations',
  reducers: {
    setEmailTranslations(
      state,
      action: PayloadAction<Array<EmailTranslation>>,
    ) {
      state.emailTranslations = action.payload;
    },
  },
});

export const selectEmailTranslations = (
  state: RootState,
): EmailTranslanstionSliceState['emailTranslations'] => {
  const stepLevelTranslationId = state.workflowBuilder.stepLevelTranslationsId;
  const emailTranslations = state.emailTranslations.emailTranslations;

  const validTranslations = emailTranslations.filter(data => data.intent_name);

  if (stepLevelTranslationId) {
    return validTranslations.filter(
      data => data.component_id === stepLevelTranslationId,
    );
  }

  return validTranslations;
};

export const { setEmailTranslations } = emailTranslationSlice.actions;

export default emailTranslationSlice.reducer;
