import { ListOption } from '@forethought-technologies/forethought-elements';
import {
  TagDefinitionResponse,
  TriageTicketFieldsResponse,
} from 'src/reducers/triageSettingsReducer/types';

export const determineOutputFieldValueOptions = (
  selectedOutputField: string,
  ticketFields: TriageTicketFieldsResponse | undefined,
): ListOption[] | undefined => {
  if (!selectedOutputField || !ticketFields) {
    return [];
  }
  if (ticketFields.data_source === 'zendesk') {
    return ticketFields.ticket_fields.output_fields
      .find(field => field.id.toString() === selectedOutputField)
      ?.field_options?.map(option => ({
        label: option.name,
        value: option.value,
      }));
  } else if (ticketFields.data_source === 'salesforce') {
    return ticketFields.ticket_fields.output_fields
      .find(field => field.name === selectedOutputField)
      ?.field_options?.map(option => ({
        label: option.name,
        value: option.value,
      }));
  }
  return [];
};

export const determineOutputFieldOptions = (
  ticketFields: TriageTicketFieldsResponse | undefined,
): ListOption[] => {
  if (!ticketFields) {
    return [];
  }
  if (ticketFields.data_source === 'zendesk') {
    return (
      ticketFields.ticket_fields.output_fields.map(field => ({
        label: field.name,
        value: field.id.toString(),
      })) || []
    );
  } else if (ticketFields.data_source === 'salesforce') {
    return (
      ticketFields.ticket_fields.output_fields.map(field => ({
        label: field.label,
        value: field.name,
      })) || []
    );
  }
  return [];
};

export const labelToObj = (labels?: Array<TagDefinitionResponse>) => {
  if (!labels) {
    return {};
  }
  return labels.reduce(
    (prev, current) => ({
      ...prev,
      [current.title]: current.output_field_value,
    }),
    {},
  );
};
