import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ft from 'src/assets/images/logo-v2-forethought.svg';

export const TemplateBadge = () => {
  return (
    <Box
      alignItems='center'
      bgcolor={theme => theme.palette.colors.slate[100]}
      border={theme => '1px solid ' + theme.palette.colors.slate[300]}
      borderRadius='4px'
      component='span'
      display='inline-flex'
      height='fit-content'
      p='4px 8px'
    >
      <img height='13px' src={ft} width='11px' />
      <Typography
        color={theme => theme.palette.colors.grey[700]}
        ml='3px'
        variant='font12Medium'
      >
        Template
      </Typography>
    </Box>
  );
};
