import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverAutomatedBadge from 'src/components/discover-automated-badge/DiscoverAutomatedBadge';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectDiscoverTopicsByIntentId } from 'src/slices/data/dataSlice';

interface SolveDiscoverTopicsButtonProps {
  onClick?: () => void;
}

const SolveDiscoverTopicsButton: React.FC<
  React.PropsWithChildren<SolveDiscoverTopicsButtonProps>
> = ({ onClick }) => {
  const workflowBuilderData = useSelector(selectCanvasWorkflowBuilder);
  const discoverTopicsByIntentId = useSelector(selectDiscoverTopicsByIntentId);

  return (
    <Tooltip tooltipContent={'Discover topics associated with this workflow'}>
      <DiscoverTopicsButton
        aria-label='discover topics'
        disabled={workflowBuilderData.loading}
        onClick={() => onClick && onClick()}
      >
        <Box marginRight='4px' position='relative' top='2px'>
          <DiscoverAutomatedBadge />
        </Box>
        <Typography color={theme.palette.text.primary} variant='font14Bold'>
          {discoverTopicsByIntentId.length}
        </Typography>
      </DiscoverTopicsButton>
    </Tooltip>
  );
};

export default SolveDiscoverTopicsButton;

const DiscoverTopicsButton = styled('button')`
  display: flex;
  padding: 6px 16px 6px 12px;
  border-radius: 3px;
  align-items: center;
  &:hover {
    background-color: ${theme.palette.colors.purple[100]};
    cursor: pointer;
  }
`;
