import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTriageModelByModelName } from 'src/actions/triageSettings/triageSettings';
import { selectFocusedTriageModel } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';

export const useGetFocusedTriageModel = (modelName: string) => {
  const dispatch = useDispatch();
  const triageModel = useSelector(selectFocusedTriageModel);

  useEffect(() => {
    dispatch(getTriageModelByModelName(modelName));
  }, [dispatch, modelName]);

  return triageModel;
};
