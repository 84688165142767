import { setMidnightHour } from './setMidnightHour';
import { CustomRangeModifier } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';

//interface fordays  timeframe options
interface DaysTimeframeMap {
  [key: string]: number;
  'Last 7 days': number;
  'Last 14 days': number;
  'Last 30 days': number;
}

//interface for timestamp object we return
interface TimestampDict {
  end_timestamp: number;
  start_timestamp: number;
}

//converts date to unix timestamp
const getUnixDate = (newDate: Date): number => {
  return Date.parse(newDate.toString()) / 1000;
};

//calculate timestamps here if timeframe received is last 7-30 days
const calculateDays = (timeframe: string): TimestampDict => {
  const endTime = new Date();

  const daysTimeframeMap: DaysTimeframeMap = {
    'Last 14 days': 14,
    'Last 30 days': 30,
    'Last 7 days': 7,
  };

  const days: number = daysTimeframeMap[timeframe];

  const startTime: number = endTime.setDate(
    endTime.getDate() - (days as number),
  );

  return {
    end_timestamp: getUnixDate(new Date()),
    start_timestamp: getUnixDate(new Date(startTime)),
  };
};

//calculate timestamps here if timeframe received is this month or previous month
const calculateMonths = (timeframe: string): TimestampDict => {
  const endTime: Date = new Date();

  const year: number = endTime.getFullYear();

  const month: number = endTime.getMonth();

  const date: number = endTime.getDate();

  const start_add: number = timeframe === 'This month' ? 0 : -1;

  const end_add: number = timeframe === 'This month' ? 1 : 0;

  return {
    end_timestamp:
      timeframe === 'This month'
        ? getUnixDate(new Date(year, month, date))
        : getUnixDate(new Date(year, month + end_add, 0)),
    start_timestamp: getUnixDate(new Date(year, month + start_add, 1)),
  };
};

//util to calculate start timestamp and endtimestamp based on timeframe received
const calculateTimeframe = (timeframe: string): TimestampDict => {
  if (
    timeframe === 'Last 7 days' ||
    timeframe === 'Last 14 days' ||
    timeframe === 'Last 30 days'
  ) {
    return calculateDays(timeframe);
  } else if (timeframe === 'This month' || timeframe === 'Previous month') {
    return calculateMonths(timeframe);
  }
  return { end_timestamp: 0, start_timestamp: 0 };
};

export const calculateRange = (timeframe: string): CustomRangeModifier => {
  const timestamp = calculateTimeframe(timeframe);
  return calculateRangeWithTimestamp(timestamp);
};

export const calculateRangeWithTimestamp = (
  timestamp: TimestampDict,
): CustomRangeModifier => {
  if (!timestamp.start_timestamp && !timestamp.end_timestamp) {
    return { from: undefined, to: undefined };
  }

  const timeRange = {
    from: new Date(timestamp.start_timestamp * 1000),
    to: new Date(timestamp.end_timestamp * 1000),
  };

  return setMidnightHour(timeRange);
};
