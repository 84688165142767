import React from 'react';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import noStepMetricsDataIcon from 'src/assets/images/no-step-metrics-data.svg';

const EmptyState: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Container>
      <ReactSVG src={noStepMetricsDataIcon} />
      <Typography color={theme.palette.colors.grey[500]} variant='font14'>
        Data is unavailable because this step has been modified since the last
        updated workflow.
      </Typography>
    </Container>
  );
};

export default EmptyState;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 308px;
  padding: 32px 16px 48px 16px;
`;
