import React, { Dispatch, FC, SetStateAction } from 'react';

import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from 'src/pages/workflow-builder-edit/hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import { KustomerConversationCreationCustomization } from 'src/types/workflowBuilderAPITypes';

interface ConversationSubjectProps {
  customizationData: KustomerConversationCreationCustomization;
  setCustomizationData: Dispatch<
    SetStateAction<KustomerConversationCreationCustomization>
  >;
}

const ConversationSubject: FC<
  React.PropsWithChildren<ConversationSubjectProps>
> = ({ customizationData, setCustomizationData }) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();

  return (
    <>
      <ContextVariableAutocomplete
        label='Conversation subject'
        onChange={value =>
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              conversation_creation_settings: {
                ...handoffData.conversation_creation_settings,
                subject: value,
              },
            };
          })
        }
        placeholder='Enter conversation subject'
        shouldIncludeSystemContextVariables
        undefinedContextVariables={undefinedContextVariableIds}
        value={customizationData.conversation_creation_settings?.subject || ''}
      />
    </>
  );
};

export default ConversationSubject;
