import { Box } from '@mui/material';

import {
  Button,
  TextField,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import ContextMention from '../context-mention-input';
import { Icon, Label, Row } from '../handoff-configuration/styles';
import { DeleteButton } from './FreshdeskTicketCreationCustomizationPanel';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';

export type CustomFieldValue = string | number | boolean;

export type CustomField = {
  key: string;
  value: CustomFieldValue;
};

interface CustomFieldSelectorProps {
  addCustomField: () => void;
  customFields: CustomField[];
  deleteCustomField: (index: number) => void;
  updateCustomField: (index: number, value: string) => void;
  updateCustomFieldValue: (index: number, value: CustomFieldValue) => void;
}

const CustomFieldSelector = ({
  addCustomField,
  customFields,
  deleteCustomField,
  updateCustomField,
  updateCustomFieldValue,
}: CustomFieldSelectorProps) => {
  return (
    <Box>
      <Row>
        <Label>Custom Fields</Label>
        <Tooltip tooltipContent='Custom fields to be added to the ticket'>
          <Icon src={tooltipInfoIcon} />
        </Tooltip>
      </Row>
      {customFields.map((cf, index) => {
        return (
          <Box
            key={index}
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              pb: 1,
            }}
          >
            <TextField
              aria-label='Add a custom field'
              onChange={({ target }) => {
                updateCustomField(index, target.value);
              }}
              placeholder='Enter Custom Field key'
              value={cf.key}
            />
            <Box sx={{ width: '100%' }}>
              <ContextMention
                onChange={value => {
                  updateCustomFieldValue(index, value);
                }}
                placeholder='Enter Custom Field value'
                value={String(cf.value)}
              />
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                justifyContent: 'flex-end',
                width: '30px',
              }}
            >
              <DeleteButton
                alt='delete custom field icon'
                onClick={() => {
                  deleteCustomField(index);
                }}
                role='button'
                src={xIcon}
              />
            </Box>
          </Box>
        );
      })}
      <Button onClick={() => addCustomField()} variant='ghost'>
        + Add Custom Field
      </Button>
    </Box>
  );
};

export default CustomFieldSelector;
