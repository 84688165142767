import { GroupInsertionData } from 'src/slices/canvas-workflow-builder/types/dataTypes';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { Action } from 'src/types/actionBuilderApiTypes';
import { BaseTransition, Nullable } from 'src/types/workflowBuilderAPITypes';
import {
  articleWasHelpfulCase,
  articleWasNotHelpfulCase,
} from 'src/utils/constants';
import { ActionBuilderActionTypes, StepTypes } from 'src/utils/enums';

/** Returns the action id from canvas_action_id_to_action_component
     given the entry step id of an action */

export const getArticleSuggestionActionKeyId = (
  canvasActionIds: CanvasWorkflowBuilderState['canvas_action_id_to_action_component'],
  actions: Array<Action>,
  entryStepId: string,
) => {
  const articleSuggestionActionId =
    actions?.find(
      (action: Action) =>
        action.action_type === ActionBuilderActionTypes.ARTICLE_SUGGESTION,
    )?.action_id || '';

  const actionIdKeys = Object.keys(canvasActionIds);

  return (
    actionIdKeys.find(
      key =>
        canvasActionIds[key]?.['entry_step_id'] === entryStepId &&
        canvasActionIds[key]?.['action_id'] === articleSuggestionActionId,
    ) || ''
  );
};

export const doesActionContainStepWithType = (
  canvasWorkflowBuilder: CanvasWorkflowBuilderState | GroupInsertionData,
  actionId: string,
  stepType: StepTypes,
) => {
  const canvasActionIdToComponent =
    canvasWorkflowBuilder.canvas_action_id_to_action_component;
  const stepMap =
    'steps' in canvasWorkflowBuilder
      ? canvasWorkflowBuilder.steps
      : canvasWorkflowBuilder.step_map;
  const stepsInAction = canvasActionIdToComponent[actionId]?.steps;

  if (!stepsInAction) {
    return false;
  }

  return stepsInAction.some(stepId => stepMap[stepId].step_type === stepType);
};

export const getStepWithTypeFromAction = (
  canvasWorkflowBuilder: CanvasWorkflowBuilderState,
  actionId: string,
  stepType: StepTypes,
) => {
  const canvasActionIdToComponent =
    canvasWorkflowBuilder.canvas_action_id_to_action_component;
  const stepMap = canvasWorkflowBuilder.steps;
  const stepsInAction = canvasActionIdToComponent[actionId].steps;

  const stepId = stepsInAction.find(
    stepId => stepMap[stepId].step_type === stepType,
  );

  return stepId ? stepMap[stepId] : undefined;
};

export const isDynamicArticleSuggestion = (
  canvasWorkflowBuilder: CanvasWorkflowBuilderState | GroupInsertionData,
  actionId: string,
): boolean => {
  // if the steps contain a go to intent step, then it's a dynamic article suggestion action
  return doesActionContainStepWithType(
    canvasWorkflowBuilder,
    actionId,
    StepTypes.GO_TO_INTENT,
  );
};

export const showSqueezeForGranularArticleStep = (
  canvasActionIds: CanvasWorkflowBuilderState['canvas_action_id_to_action_component'],
  actions: Array<Action>,
  squeezeStepParentId: string,
  lastStepId: string,
) => {
  const articleSuggestionActionId = actions?.find(
    action =>
      action.action_type ===
      ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2,
  )?.action_id;

  const actionIdKeys = Object.keys(canvasActionIds);

  const actionKeyId = actionIdKeys.find(
    key =>
      canvasActionIds[key]?.['entry_step_id'] === squeezeStepParentId &&
      canvasActionIds[key]?.['action_id'] === articleSuggestionActionId,
  );

  if (actionKeyId) {
    const transitionBranches =
      canvasActionIds[actionKeyId]?.['transition_branches'] || [];
    if (
      transitionBranches.length === 1 &&
      transitionBranches[0]?.parent_step_id === lastStepId
    ) {
      return false;
    }
  }
  return true;
};

/** Returns the step id we need to transition to inside the Article Case Selector */

export const getStepIdToTransitionFromBranch = (
  canvasWorkflowBuilder: CanvasWorkflowBuilderState,
  actionId: string,
  isSuccessCase: boolean,
): string | null | undefined => {
  const canvasActionIdToComponent =
    canvasWorkflowBuilder.canvas_action_id_to_action_component;
  const condition = isSuccessCase
    ? articleWasHelpfulCase
    : articleWasNotHelpfulCase;

  const transitionBranches =
    canvasActionIdToComponent[actionId]?.['transition_branches'] || [];

  const buttonParentStepid = transitionBranches.find(
    (branch: { display_name: string; parent_step_id: string }) =>
      branch.display_name === condition,
  )?.parent_step_id;

  if (!buttonParentStepid) {
    return null;
  } else {
    if (isSuccessCase) {
      const stepTransitions =
        canvasWorkflowBuilder.steps[buttonParentStepid]?.transitions;
      if (stepTransitions?.length > 1) {
        const validStepTransition = getValidStepTransition(stepTransitions);
        if (!!validStepTransition && !!validStepTransition.step_id) {
          return validStepTransition.step_id;
        }
      }
      return null;
    }
    return buttonParentStepid;
  }
};

export const getStepIdToTransitionForDynamicArticleSuggestion = (
  canvasWorkflowBuilder: CanvasWorkflowBuilderState,
  actionId: string,
  isBranchChosen: boolean,
  isYesBranchChosen: boolean,
): string | null | undefined => {
  const canvasActionIdToComponent =
    canvasWorkflowBuilder.canvas_action_id_to_action_component;
  const transitionBranches =
    canvasActionIdToComponent[actionId]?.['transition_branches'] || [];

  const transitionParentStep =
    canvasWorkflowBuilder.steps[
      transitionBranches?.find(transition => transition.display_name === 'No')
        ?.parent_step_id || ''
    ];

  // feedback button is enabled
  if (transitionParentStep.step_type === StepTypes.BUTTONS) {
    if (isYesBranchChosen) {
      return transitionParentStep.transitions.find(
        transition =>
          transition.condition_expression?.values &&
          transition.condition_expression?.values[0] === 'yes_resolve',
      )?.step_id;
    } else if (isBranchChosen) {
      return transitionParentStep.transitions.find(
        transition =>
          transition.condition_expression?.values &&
          transition.condition_expression?.values[0] === 'no_resolve',
      )?.step_id;
    } else {
      return null;
    }
  } else if (transitionParentStep.step_type === StepTypes.CONDITION) {
    {
      const nextStep = transitionParentStep?.transitions.filter(
        transition => transition.condition_expression === null,
      );

      return nextStep ? nextStep[0]?.step_id : null;
    }
  }
};

export const getIsTerminalAction = (
  stepId: string | null,
  canvasActionIdToComponent: CanvasWorkflowBuilderState['canvas_action_id_to_action_component'],
) => {
  return Object.values(canvasActionIdToComponent).some(
    action => action.entry_step_id === stepId && action.is_last_element,
  );
};

const getValidStepTransition = (
  stepTransitions: Nullable<BaseTransition>[],
) => {
  return stepTransitions.find((t: Nullable<BaseTransition>) => {
    if (!!t.condition_expression?.values?.length) {
      return t.condition_expression?.values?.[0] === 'yes_resolve';
    } else {
      return true;
    }
  });
};
