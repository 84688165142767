import { DateRange } from 'src/components/dashboard-pages/discover-dashboard-page/types';

// YYYY-MM-DD
export const convertToYYYYMMDD = (date: Date): string =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

// MM DD, YYYY
export const convertToMMDDYYYY = (date: Date): string =>
  date
    .toLocaleDateString('en-us', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .toString();

export const getDaysBetweenTwoDates = (startDate: Date, endDate: Date) => {
  const difference = endDate.getTime() - startDate.getTime();
  return Math.ceil(difference / (1000 * 3600 * 24));
};

export const isValidTimeStamp = (timestamp: string) => {
  const date = new Date(Number(timestamp));

  return (
    !isNaN(date.valueOf()) &&
    date.valueOf() <= new Date().setHours(23, 59, 59, 999).valueOf()
  );
};

// Format date as string in format: Jul 31, 2023
export const formatReadableDate = (date: Date) => {
  return date.toLocaleString('en-us', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

// Format time as hour:minute
const formatReadableTime = (date: Date) => {
  return date.toLocaleString('en-us', {
    hour: 'numeric',
    minute: 'numeric',
  });
};

// Format date as string in format: (short month) (day), (year) (time)
// Optional delimiter to join date and time by '|', ',', etc.
export const formatReadableDatetime = (date: Date, delimiter = ' ') => {
  return formatReadableDate(date) + delimiter + formatReadableTime(date);
};

export const getPreviousDateRangeFromCurrentDateRange = (
  current: DateRange,
): DateRange => {
  const { from, to } = current;
  if (from && to) {
    const timeDelta = Math.abs(to.getTime() - from.getTime());
    const previousEndDate = new Date(from);
    const previousStartDate = new Date(from.getTime() - timeDelta);
    previousStartDate.setDate(previousStartDate.getDate() - 1);
    previousEndDate.setDate(previousEndDate.getDate() - 1);
    return { from: previousStartDate, to: previousEndDate };
  }
  return { from: undefined, to: undefined };
};
