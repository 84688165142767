import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, SelectChangeEvent } from '@mui/material';
import { IconMapPinCheck } from '@tabler/icons-react';

import {
  Button,
  Dialog,
  Drawer,
  IconButton,
  ListOption,
  SelectDropdown,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetHelpdesk } from '../dashboard-pages/triage-config-detail-page/hooks/useGetHelpdesk';
import { isModelReadOnly } from '../dashboard-pages/triage-model-detail-page/helpers';
import Header from './Header';
import {
  determineOutputFieldOptions,
  determineOutputFieldValueOptions,
  labelToObj,
} from './helpers';
import LabelList from './LabelList';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { getFieldPredictorOutputField } from 'src/reducers/triageSettingsReducer/helpers';
import {
  PatchTriageModelRequest,
  TagDefinitionResponse,
} from 'src/reducers/triageSettingsReducer/types';
import {
  useGetHelpdeskOptionsQuery,
  useGetModelByIdQuery,
  useGetModelVersionQuery,
  useGetSelfServeTriageModelsQuery,
  useGetTicketFieldsQuery,
  usePatchSelfServeTriageModelMutation,
} from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

interface TriageLabelMappingDrawerProps {
  modelId?: string;
  onCloseDrawer?: () => void;
  showDrawer?: boolean;
  versionId?: string;
}

const TriageLabelMappingDrawer: React.FC<TriageLabelMappingDrawerProps> = ({
  modelId,
  onCloseDrawer,
  showDrawer,
  versionId,
}) => {
  // state
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState<string>(modelId || '');
  const [currentPatchModel, setCurrentPatchModel] = useState<
    PatchTriageModelRequest['body']
  >({});
  const [selectedOutputField, setSelectedOutputField] = useState('');

  // external data
  const helpdesk = useGetHelpdesk();
  const { data: helpdeskOptions } = useGetHelpdeskOptionsQuery();
  const {
    data: selfServeTriageModels,
    isFetching,
    isLoading,
  } = useGetSelfServeTriageModelsQuery();
  const {
    data: selectedModel,
    isFetching: isFetchingModel,
    isLoading: isLoadingModel,
  } = useGetModelByIdQuery(selectedModelId, {
    skip: !selectedModelId && !versionId,
  });
  const {
    data: selectedVersionModel,
    isFetching: isFetchingVersionedModel,
    isLoading: isLoadingVersionedModel,
  } = useGetModelVersionQuery(
    { modelId: modelId || '', versionId: versionId || '' },
    { skip: !versionId },
  );
  const [patchModel] = usePatchSelfServeTriageModelMutation();
  const [targetedModel, setTargetedModel] = useState(
    selectedVersionModel || selectedModel,
  );
  const emitTrackingEventCallback = useSelfServeEvents({
    model: targetedModel,
  });
  // derived state
  const isLoadingLabels =
    isFetchingModel ||
    isLoadingModel ||
    isFetchingVersionedModel ||
    isLoadingVersionedModel;
  const modelLabels = targetedModel?.labels;
  const { data: ticketFields } = useGetTicketFieldsQuery(helpdesk?.name ?? '', {
    skip: !helpdesk,
  });
  const isLoadingDrawer = isFetching || isLoading || !helpdesk;

  useEffect(() => {
    setTargetedModel(selectedVersionModel || selectedModel);
  }, [selectedVersionModel, selectedModel]);

  useEffect(() => {
    if (isOpen === false && onCloseDrawer) {
      onCloseDrawer();
    }
  }, [isOpen, onCloseDrawer]);

  useEffect(() => {
    if (showDrawer === true) {
      emitTrackingEventCallback(
        TRIAGE_LLM_TRACKING_EVENTS.VIEW_VALUE_MAPPING_DRAWER,
      );
      setIsOpen(true);
    }
  }, [showDrawer, emitTrackingEventCallback]);

  // When the model is loaded, setup the patch state based on
  // what the model has in value mapping and the outputs
  useEffect(() => {
    if (targetedModel) {
      const valueMapping =
        targetedModel.model.model_output_formatter.value_mapping;
      if (valueMapping) {
        const mappedLabels = targetedModel.labels.map(label => {
          const mappedValue =
            (valueMapping[label.title.trim()] as string) || null;
          return {
            ...label,
            output_field_value: mappedValue,
          };
        });
        setCurrentPatchModel(c => ({
          ...c,
          labels: mappedLabels,
          model: targetedModel.model,
        }));
      }
      if (targetedModel.model.outputs.length > 0) {
        const selectedOutputFieldValue =
          getFieldPredictorOutputField(helpdesk?.name, targetedModel.model) ||
          {};
        setSelectedOutputField(Object.keys(selectedOutputFieldValue)[0]);
      } else {
        setSelectedOutputField('');
      }
    }
  }, [targetedModel, helpdesk]);

  const ticketFieldOptions: ListOption[] = useMemo(() => {
    return determineOutputFieldOptions(ticketFields);
  }, [ticketFields]);

  const modelOptions = useMemo(
    () =>
      selfServeTriageModels
        ?.filter(model => model.is_self_serve)
        .map(model => ({
          label: model.display_name,
          value: model.model_id,
        })) || [],
    [selfServeTriageModels],
  );

  // When the model options are loaded, select the first model
  // or if a model is determined because of the page we're on,
  // select that
  useEffect(() => {
    if (
      modelOptions &&
      modelOptions.length > 0 &&
      !selectedModelId &&
      !modelId
    ) {
      setSelectedModelId(modelOptions[0].value);
    } else if (modelId) {
      setSelectedModelId(modelId);
    }
  }, [modelOptions, selectedModelId, modelId]);

  const handleOpen = () => {
    emitTrackingEventCallback(
      TRIAGE_LLM_TRACKING_EVENTS.VIEW_VALUE_MAPPING_DRAWER,
    );
    setIsOpen(true);
  };

  const handleClose = () => {
    if (!selectedModel) {
      return;
    }
    const labelObj = labelToObj(currentPatchModel.labels);

    const noChangeInLabels = isEqual(
      labelObj,
      selectedModel.model.model_output_formatter.value_mapping,
    );
    const noChangeInOutputs = isEqual(
      currentPatchModel.model?.outputs,
      targetedModel?.model?.outputs,
    );

    if (!noChangeInLabels || !noChangeInOutputs) {
      setDiscardAlertOpen(true);
      return;
    }

    setIsOpen(false);
  };

  const handleChangeModel = (e: SelectChangeEvent<string>) => {
    setSelectedModelId(e.target.value);
  };

  const handleLabelMappingChange = (
    e: SelectChangeEvent<string> | React.ChangeEvent<HTMLInputElement>,
    updatedLabel: TagDefinitionResponse,
  ) => {
    const modelsToUse = currentPatchModel.labels || modelLabels;
    const newLabelSet = modelsToUse?.map(label => {
      if (label.tag_id === updatedLabel.tag_id) {
        return {
          ...label,
          output_field_value: e.target.value,
        };
      }
      return label;
    });

    setCurrentPatchModel(c => ({
      ...c,
      labels: newLabelSet,
    }));
  };

  const handleOutputFieldChange = (e: SelectChangeEvent<string>) => {
    if (!targetedModel || !helpdeskOptions) {
      return;
    }
    const targetValue = e.target.value;
    const currentOutput = targetedModel.model.outputs.length
      ? targetedModel.model.outputs[0]
      : [];
    const helpdeskOptionType = helpdeskOptions.helpdesk_options[0].option_value;
    const outputValue = { [targetValue]: '@value' };
    const writtenObject =
      helpdesk?.name === 'zendesk'
        ? { custom_fields: outputValue }
        : outputValue;
    const outputs = [
      {
        object_name: helpdeskOptionType,
        ...currentOutput,
        create_new_object: false,
        written_object: writtenObject,
      },
    ];
    const updatedLabels = modelLabels?.map(label => ({
      ...label,
      output_field_value: null,
    }));

    setCurrentPatchModel(c => ({
      ...c,
      labels: updatedLabels,
      model: {
        ...targetedModel.model,
        outputs,
      },
    }));
    setSelectedOutputField(targetValue);
  };

  const handleSave = () => {
    if (!targetedModel) {
      return;
    }
    patchModel({
      body: currentPatchModel,
      modelId: modelId || targetedModel.model_id,
      versionId: versionId || targetedModel.version_id,
    });
  };

  const outputFieldLabelOptions = useMemo(() => {
    return determineOutputFieldValueOptions(selectedOutputField, ticketFields);
  }, [selectedOutputField, ticketFields]);

  const handleDiscard = useCallback(() => {
    if (targetedModel) {
      setTargetedModel(clone(selectedVersionModel || selectedModel));
      setDiscardAlertOpen(false);
      setIsOpen(false);
    }
  }, [
    targetedModel,
    selectedVersionModel,
    selectedModel,
    setTargetedModel,
    setDiscardAlertOpen,
    setIsOpen,
  ]);

  return (
    <>
      <Dialog
        onClose={() => setDiscardAlertOpen(false)}
        open={discardAlertOpen}
        title='You have unsaved changes'
      >
        <Box display='flex' flexDirection='column' rowGap={4} width='400px'>
          <Typography variant='font14'>
            Do you want to discard your current changes?
          </Typography>
          <Box columnGap={1} display='flex' justifyContent='flex-end'>
            <Button onClick={() => setDiscardAlertOpen(false)} variant='ghost'>
              Continue editing
            </Button>
            <Button onClick={handleDiscard} variant='main'>
              Discard
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Tooltip tooltipContent='Mapping label values'>
        <IconButton
          aria-label='open label mapping drawer'
          onClick={handleOpen}
          variant='ghost'
        >
          <IconMapPinCheck />
        </IconButton>
      </Tooltip>
      <Drawer isOpen={isOpen} onClose={handleClose} width='1024px'>
        <Box display='flex' flexDirection='column' p='46px 0 46px'>
          {isLoadingDrawer && <Skeleton height='224px' />}
          {!isLoadingDrawer && (
            <>
              <Header handleSave={handleSave} model={targetedModel} />
              <Box
                display='flex'
                gap='70px'
                id='model-output-selection-zone'
                justifyContent='space-between'
                pb='36px'
                pl='40px'
                pr='170px'
              >
                <SelectDropdown
                  disabled={Boolean(modelId) || Boolean(versionId)}
                  id='triage-model-selection'
                  label='Triage model'
                  onChange={handleChangeModel}
                  options={modelOptions}
                  value={selectedModelId}
                />
                <SelectDropdown
                  disabled={!modelId || isModelReadOnly(targetedModel)}
                  id='output-field-selection'
                  label='Helpdesk Output field'
                  onChange={handleOutputFieldChange}
                  options={ticketFieldOptions}
                  placeholder='Select helpdesk output field'
                  value={selectedOutputField}
                />
              </Box>
              <LabelList
                currentPatchModel={currentPatchModel}
                handleLabelMappingChange={handleLabelMappingChange}
                helpdesk={helpdesk}
                isLoadingLabels={isLoadingLabels}
                modelLabels={modelLabels}
                outputFieldLabelOptions={outputFieldLabelOptions}
                targetedModel={targetedModel}
              />
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default TriageLabelMappingDrawer;
