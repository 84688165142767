import { useEffect, useMemo, useState } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import OutputCard from './run-action-card/output-card';
import AddSectionButton from './add-section-button';
import AutomationCard from './automation-card';
import AutomationTextField from './automation-text-field';
import InputFieldCard from './input-field-card';
import InternalTextFieldWrapper from './internal-text-field-wrapper';
import OverviewCard from './overview-card';
import RunActionCard from './run-action-card';
import flattenDepth from 'lodash/flattenDepth';
import omit from 'lodash/fp/omit';
import addActionIcon from 'src/assets/images/add-action-icon.svg';
import addTextIcon from 'src/assets/images/add-text-icon.svg';
import { ErrorMessage } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { AutomationOutputAction } from 'src/services/apiInterfaces';
import { selectAutomations } from 'src/slices/assist-automations/assistAutomationsSlice';
import { AutomationForm } from 'src/slices/assist-automations/types';
import {
  ASSIST_AUTOMATIONS_APPCUES_TARGET,
  ASSIST_AUTOMATIONS_TEXT,
} from 'src/utils/constants';

interface Props {
  isReadOnly: boolean;
}

const AssistAutomationsForm = ({ isReadOnly }: Props) => {
  const { errors, setFieldTouched, setFieldValue, validateForm, values } =
    useFormikContext<AutomationForm>();

  const {
    actions_output_formatter,
    external_text_field,
    input_fields,
    internal_text_field,
    org_id,
  } = values;
  const { action, connector } = useSelector(selectAutomations);

  const [isInputCardVisible, setIsInputCardVisible] = useState(!!org_id);
  const [isOutputCardVisible, setIsOutputCardVisible] = useState(!!org_id);
  const [isBuilderBlockVisible, setIsBuilderBlockVisible] = useState(!!org_id);
  const isInternalTextFieldVisible = internal_text_field !== undefined;
  const isTextFieldCardVisible = external_text_field !== undefined;

  const contextVariables = useMemo(() => {
    // Process output variables
    const outputKeys = Object.keys(actions_output_formatter);
    const excludeUseAsInput = (obj: AutomationOutputAction) =>
      Object.values(omit(['use_as_input'], obj));
    const mapActionsOutputFormatter = (actionOutputKey: string) =>
      actions_output_formatter[actionOutputKey].map(excludeUseAsInput);

    const excludedUseAsInput = outputKeys.map(mapActionsOutputFormatter);
    // cast this type because `flattenDepth` doesn't work out the type properly.
    const processedVariables = flattenDepth(
      excludedUseAsInput,
      3,
    ) as unknown as string[];
    // Stop processing outputvariables

    // Process input fields
    const inputFieldContextVarialbes = input_fields.map(
      input => input.context_variable,
    );
    // Stop processing inputfields
    return [...processedVariables, ...inputFieldContextVarialbes];
  }, [actions_output_formatter, input_fields]);

  const addActionCard = async () => {
    setFieldTouched('title');
    setFieldTouched('description');

    const _errors = await validateForm();

    if (!_errors.title && !_errors.description) {
      setIsBuilderBlockVisible(true);
    }
  };

  const addInputCard = async () => {
    const _errors = await validateForm();

    if (!_errors.actions_input_formatter) {
      setIsInputCardVisible(true);
    }
  };

  const addOutputCard = async () => {
    const _errors = await validateForm();
    if (!_errors.actions_input_formatter) {
      setIsOutputCardVisible(true);
    }
  };

  const addTextFields = async () => {
    setFieldTouched('input_description');
    setFieldTouched('input_fields');

    const _errors = await validateForm();

    if (!Object.keys(_errors).length) {
      setFieldValue('external_text_field', '');
    }
  };

  useEffect(() => {
    setIsInputCardVisible(!!org_id);
    setIsBuilderBlockVisible(!!org_id);
    setIsOutputCardVisible(!!org_id);
  }, [org_id]);

  return (
    <>
      <OverviewCard isReadOnly={isReadOnly} />
      {!isBuilderBlockVisible && values.title && values.description && (
        <SetupActionButtonWrapper>
          <GrayLineSmall />
          <AddSectionButton
            iconSrc={addActionIcon}
            onClick={addActionCard}
            text='Setup Action'
          />
        </SetupActionButtonWrapper>
      )}
      {isBuilderBlockVisible && (
        <BuilderBlock>
          <RunActionCard isReadOnly={isReadOnly} />
          {!isInputCardVisible && connector && action && (
            <AddSectionButton
              iconSrc={addActionIcon}
              onClick={addInputCard}
              text='Set inputs'
            />
          )}
          {isInputCardVisible && <InputFieldCard isReadOnly={isReadOnly} />}
          {!isOutputCardVisible &&
            isInputCardVisible &&
            action &&
            connector && (
              <AddSectionButton
                iconSrc={addActionIcon}
                onClick={addOutputCard}
                text='Set outputs'
              />
            )}
          {isOutputCardVisible && action && (
            <AutomationCard
              dataAppcuesTarget={
                ASSIST_AUTOMATIONS_APPCUES_TARGET.saveOutputSection
              }
              description={ASSIST_AUTOMATIONS_TEXT.outputCardDescription}
              isIconVisible={false}
              title={
                <Typography variant='font16Bold'>
                  {ASSIST_AUTOMATIONS_TEXT.outputCardTitle}
                </Typography>
              }
            >
              <OutputCard action={action} isReadOnly={isReadOnly} />
            </AutomationCard>
          )}
          {isTextFieldCardVisible && (
            <AutomationCard
              dataAppcuesTarget={
                ASSIST_AUTOMATIONS_APPCUES_TARGET.formatOutoutSection
              }
              description='This text field editor allows you to provide agents a formatted message that can be added to the reply OR an internal-only view only visible by the agent.'
              isIconVisible={false}
              title={
                <Typography variant='font16Bold'>
                  {ASSIST_AUTOMATIONS_TEXT.textFieldsTitle}
                </Typography>
              }
            >
              <Field name='external_text_field'>
                {({ field }: FieldProps) => (
                  <ExternalTextFieldWrapper isReadOnly={isReadOnly}>
                    <AutomationTextField
                      {...field}
                      contextVariables={contextVariables}
                      onChange={value => {
                        setFieldValue('external_text_field', value);
                      }}
                      text={field.value}
                    />

                    {errors.external_text_field && (
                      <StyledErrorMessage>
                        {errors.external_text_field}
                      </StyledErrorMessage>
                    )}
                  </ExternalTextFieldWrapper>
                )}
              </Field>

              <InternalTextFieldWrapper
                isChecked={isInternalTextFieldVisible}
                isReadOnly={isReadOnly}
                onToggleClick={value => {
                  setFieldValue('internal_text_field', value ? '' : undefined);
                }}
              >
                {isInternalTextFieldVisible && (
                  <AutomationTextField
                    contextVariables={contextVariables}
                    onChange={value =>
                      setFieldValue('internal_text_field', value)
                    }
                    text={internal_text_field}
                  />
                )}
              </InternalTextFieldWrapper>
            </AutomationCard>
          )}
          {isOutputCardVisible && !isTextFieldCardVisible && (
            <AddSectionButton
              iconSrc={addTextIcon}
              onClick={addTextFields}
              text='Add Text'
            />
          )}
          <GrayLineHolder>
            <GrayLine />
          </GrayLineHolder>
        </BuilderBlock>
      )}
    </>
  );
};

const BuilderBlock = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 32px 0 0;
`;

const GrayLineHolder = styled('div')`
  align-items: center;
  display: flex;
  top: -30px;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const GrayLine = styled('div')`
  border: 1px dashed ${theme.palette.colors.slate[300]};
  height: 100%;
  width: 0;
`;

const GrayLineSmall = styled('div')`
  border: 1px dashed ${theme.palette.colors.slate[300]};
  height: 31px;
  width: 0;
  margin: 0 auto -32px;
`;

const ExternalTextFieldWrapper = styled('div')<{ isReadOnly: boolean }>`
  margin: 16px 0 24px;

  ${({ isReadOnly }) => isReadOnly && 'pointer-events: none;'}
`;

const StyledErrorMessage = styled(ErrorMessage)`
  font-size: 12px;
  margin-top: 3px;
`;

const SetupActionButtonWrapper = styled('div')`
  position: relative;
`;

export default AssistAutomationsForm;
