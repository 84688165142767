import React, { Dispatch, FC, SetStateAction } from 'react';

import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { useGetUndefinedContextVariableIdsOnCurrentAction } from 'src/pages/workflow-builder-edit/hooks/useGetUndefinedContextVariableIdsOnCurrentAction';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { ZendeskCommonCustomization } from 'src/types/workflowBuilderAPITypes';

interface TicketTitleProps {
  contextVariables: ContextVariable[];
  customizationData: ZendeskCommonCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<SetStateAction<ZendeskCommonCustomization>>;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const TicketTitle: FC<React.PropsWithChildren<TicketTitleProps>> = ({
  customizationData,
  setCustomizationData,
}) => {
  const undefinedContextVariableIds =
    useGetUndefinedContextVariableIdsOnCurrentAction();

  return (
    <>
      <ContextVariableAutocomplete
        label='Ticket title'
        onChange={value =>
          setCustomizationData(handoffData => {
            return {
              ...handoffData,
              ticket_creation_settings: {
                ...handoffData.ticket_creation_settings,
                ticket_creation_config: {
                  ...customizationData.ticket_creation_settings
                    ?.ticket_creation_config,
                  ticket_title: value,
                },
              },
            };
          })
        }
        placeholder='Enter ticket title'
        shouldIncludeSystemContextVariables
        undefinedContextVariables={undefinedContextVariableIds}
        value={
          customizationData.ticket_creation_settings?.ticket_creation_config
            ?.ticket_title || ''
        }
      />
    </>
  );
};

export default TicketTitle;
