import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { Checkbox } from '@forethought-technologies/forethought-elements';
import TranslateIcon from 'src/assets/images/translate.svg';
import {
  selectWorkflowBuilderLanguages,
  selectWorkflowBuilderLanguagesEnabled,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { Language } from 'src/types/workflowBuilderAPITypes';

interface LanguageCodes {
  language_codes: Array<string>;
}

interface SelectLanguagesTabProps {
  languagesEnabled: Array<string>;
  setIsLanguageSelectedUnchanged: (arg0: boolean) => void;
  setLanguagesEnabled: (arg0: LanguageCodes) => void;
}

const SelectLanguagesTab: React.FC<
  React.PropsWithChildren<SelectLanguagesTabProps>
> = ({
  languagesEnabled,
  setIsLanguageSelectedUnchanged,
  setLanguagesEnabled,
}) => {
  // Selectors
  const languages = useSelector(selectWorkflowBuilderLanguages);
  const languagesEnabledConfig = useSelector(
    selectWorkflowBuilderLanguagesEnabled,
  );

  // States
  const [expanded, setExpanded] = useState(false);

  // Helpers
  const getEnabledLanguageCodes = (
    languagesEnabled: Array<string>,
  ): Array<string> => {
    return languagesEnabled.slice().sort();
  };

  const handleLanguageCodeChange = (language: Language) => {
    const pos = languagesEnabled.indexOf(language.code);
    if (pos == -1) {
      setLanguagesEnabled({
        language_codes: [...languagesEnabled, language.code],
      });
    } else {
      const newLanguageCodes = languagesEnabled.slice();
      newLanguageCodes.splice(pos, 1);
      setLanguagesEnabled({
        language_codes: newLanguageCodes,
      });
    }
  };

  const languageCodes = getEnabledLanguageCodes(languagesEnabled);
  const configLanguageCodes = getEnabledLanguageCodes(
    languagesEnabledConfig.language_codes,
  );
  const isInitialState =
    languageCodes.length == configLanguageCodes.length &&
    languageCodes.every((val, index) => val === configLanguageCodes[index]);

  useEffect(() => {
    setIsLanguageSelectedUnchanged(isInitialState);
  }, [setIsLanguageSelectedUnchanged, isInitialState]);

  return (
    <CollapseWrapper expanded={expanded}>
      <CollapseHeader
        className='CollapseHeader'
        onClick={() => setExpanded(!expanded)}
      >
        <TranslateIconDisplay src={TranslateIcon} />
        <SupportedLanguagesHeader>
          Select supported languages
        </SupportedLanguagesHeader>
        {expanded ? <ExpandedIcon /> : <ContractedIcon />}
      </CollapseHeader>
      <CheckboxesContainer hidden={!expanded}>
        {languages.map(language => (
          <Box
            data-appcues-target={`${language}-CheckBox`}
            key={language.code}
            width='100%'
          >
            <Checkbox
              checked={languagesEnabled.includes(language.code)}
              label={language.display_name}
              onChange={() => {
                handleLanguageCodeChange(language);
              }}
            />
          </Box>
        ))}
      </CheckboxesContainer>
    </CollapseWrapper>
  );
};

export default SelectLanguagesTab;

const ExpandedIcon = styled(ExpandMoreIcon)`
  position: absolute;
  right: 20px;
`;

const ContractedIcon = styled(ExpandLessIcon)`
  position: absolute;
  right: 20px;
`;

const CollapseWrapper = styled('div')<{ expanded: boolean }>`
  border: 1px solid ${({ expanded }) => (expanded ? '#7b33fb' : '#e4e7f0')};
  border-radius: 8px;
`;

const CollapseHeader = styled('div')`
  cursor: pointer;
  font-weight: 600;
  line-height: 22px;
  padding-left: 18px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
  display: flex;
`;

const TranslateIconDisplay = styled(ReactSVG)`
  display: inline;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
`;

const SupportedLanguagesHeader = styled('div')`
  float: left;
`;

const CheckboxesContainer = styled('div')`
  margin: 24px;
  align-items: flex-start;
  column-count: 3;
  width: 100%;
`;
