import './styles.scss';
import '../../../chart-common/common-chartPage.scss';

import * as React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { theme } from '@forethought-technologies/forethought-elements';
import NoDataView from '../../../reusable-components/no-data-view/NoDataView';
import { averageLabel } from './formatAverageLabel';
import { multipleValueTooltip, oneValueTooltip } from './formatTooltips';
import { chartEmptyData } from 'src/utils/analyticsUtils';
import { cleanStr } from 'src/utils/cleanStr';
import { noDataViewProps } from 'src/utils/enums';
import { findValueByPrefix } from 'src/utils/findValueByPrefix';

type MyProps = {
  chartData: Array<ChartData>;
  dataTypes: {};
  mean: object;
  timeFrameFilter: string;
  /** If prop value is present and true use single value tooltip */
  useSingleTooltip?: boolean;
  y: string;
};

type MyState = {
  //TODO this will get broken-up once we start using hooks @gigi
  chartOptions: any;
  hoverData: {
    [key: string]: number;
  };
  noResults: boolean;
};

interface HighchartPoint {
  plotX: number;
  plotY: number;
}

interface ChartData {
  [key: string]: number;
  timestamp: number;
}

interface EventDict {
  [key: string]: object | number | string;
  target: {
    options: {};
  };
}

class Chart extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      chartOptions: {
        chart: {
          height: 580,
          type: 'line',
        },
        credits: {
          enabled: false,
        },
        global: {
          useUTC: false,
        },
        legend: {
          enabled: false, //shows legend witht the name of the series
        },
        plotOptions: {
          series: {
            // general options for all series
            animation: false,

            color: theme.palette.colors.green[400],
            // color of lines
            cursor: 'default',

            lineWidth: 2.8,

            marker: {
              enabled: false,
              states: {
                hover: {
                  fillColor: theme.palette.colors.green[400],
                  lineColor: theme.palette.colors.white,
                  lineWidth: 2,
                },
              },
            },

            point: {
              events: {
                mouseOver: this.setHoverData,
              },
            },
            states: {
              hover: {
                enabled: true,
                lineWidth: 3.6,
              },
            },
          },
        },
        series: [
          {
            //name on tooltip and legend.
            data: [],
            name: '', //data to be rendered
          },
        ],
        title: {
          text: null, //need to provide empty otherwise highcharts adds one as default
        },
        tooltip: {
          backgroundColor: null,
          borderWidth: 0,
          formatter: () => this.formatTooltip(),
          outside: true,
          positioner: (
            labelWidth: number,
            labelHeight: number,
            point: HighchartPoint,
          ) => {
            // repositioning tooltip so it doesn't go out of range in chart
            //TODO linting error object is possibly null for type Document
            //Someone wants to give it a try? @sebas @loren
            const chartPosition = (document as any)
              .querySelector('.highcharts-container')
              .getBoundingClientRect();
            const chartPositionLeftEnd = chartPosition.left;
            const tooltipX = point.plotX;
            const tooltipY = point.plotY;
            let offset = 90;
            if (tooltipX > 1235) {
              offset = chartPositionLeftEnd - 280;
            }
            return {
              x: tooltipX + offset,
              y: tooltipY + 20,
            };
          },
          shadow: false,
          style: {
            padding: 0,
          },
          useHTML: true,
          valueDecimals: 0,
        },
        xAxis: {
          crosshair: true,
          labels: {
            align: 'right',
            format: this.formatXDate(),
            rotation: -30,
          },
          title: {
            text: 'Time',
          },
          type: 'datetime',
        },
        yAxis: {
          gridLineDashStyle: 'ShortDash',
          title: {
            text: '',
          },
        },
      },
      hoverData: {},
      noResults: false,
    };
  }
  componentDidMount() {
    const { chartData, mean, y } = this.props;
    const meanValue = findValueByPrefix(mean, y);
    this.setChartData(chartData, y, meanValue);
  }

  //Check if data empty, if empty update data to mockdata for no results screen otherwise set data for
  setChartData = (
    chartData: Array<ChartData>,
    y: string,
    mean: string | number,
  ): void => {
    const yStr = cleanStr(y);
    if (chartData && chartData.length > 0) {
      const data = chartData.map((chartMetrics: ChartData) => {
        return {
          x: chartMetrics.timestamp * 1000,
          y: chartMetrics[y],
          ...chartMetrics,
        };
      }); //this creates an array with timestamp and value  to draw chart
      this.setState({
        chartOptions: {
          series: [
            {
              data: data,
              name: yStr,
            },
          ],
          yAxis: {
            plotLines: [
              {
                color: theme.palette.colors.slate[700],
                dashStyle: 'Dash',
                label: {
                  align: 'right',
                  formatter: (): string => averageLabel(mean),
                  useHTML: true,
                  x: 0,
                  y: -8,
                },
                value: mean,
                width: '1',
                zIndex: 3,
              },
            ],
            title: {
              text: yStr,
            },
          },
        },
        noResults: false,
      });
    } else {
      const data = chartEmptyData(y).map((chartMetrics: ChartData) => {
        return {
          x: chartMetrics.timestamp * 1000,
          y: chartMetrics[y],
          ...chartMetrics,
        };
      });
      this.setState({
        chartOptions: {
          series: [
            {
              color: theme.palette.colors.slate[100],
              //disabled data line points hover
              data: data,

              name: yStr,
              states: {
                hover: {
                  enabled: false, //disabled data points hover
                },
              },
            },
          ],
          tooltip: {
            enabled: false, //disable tooltip in no data view
          },
          xAxis: {
            crosshair: false, //disabled crosshair hover
          },
          yAxis: {
            title: {
              text: yStr,
            },
          },
        },
        noResults: true,
      });
    }
  };

  //TODO this chart will be refactored to use hooks and we won't need these types @gigi
  async componentDidUpdate(prevProps: any, _prevState: any) {
    const { chartData, mean, y } = this.props;
    if (prevProps.y !== y || prevProps.chartData !== chartData) {
      const meanValue = findValueByPrefix(mean, y);
      this.setChartData(chartData, y, meanValue);
    }
  }

  setHoverData = (e: EventDict): void => {
    this.setState({ hoverData: e.target.options });
  };

  //placeholder format dates here for tooltip and x axis?
  formatXDate = (): string => {
    const { timeFrameFilter } = this.props;
    let format = '';
    if (timeFrameFilter === 'Daily' || timeFrameFilter === 'Weekly') {
      format = '{value:%b %e, %Y}';
    } else if (timeFrameFilter === 'Monthly') {
      format = '{value:%b, %Y}';
    }
    return format;
  };

  formatTooltip = (): string => {
    const { dataTypes, timeFrameFilter, useSingleTooltip } = this.props;
    const { chartOptions, hoverData } = this.state;

    const tooltipFormat = useSingleTooltip
      ? oneValueTooltip
      : multipleValueTooltip;

    return tooltipFormat(
      chartOptions.yAxis.title.text,
      timeFrameFilter,
      hoverData.x,
      hoverData,
      dataTypes,
    );
  };

  render() {
    const { chartOptions, noResults } = this.state;
    return (
      <div className='analytics-chart-cont'>
        {noResults && (
          <NoDataView
            insideChart
            message={noDataViewProps.ANALYTICS_TIME_RANGE}
            noDataViewClass={noDataViewProps.DATE_ICON_CLASS}
          />
        )}
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    );
  }
}

export default Chart;
