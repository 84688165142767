import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { FormWithErrors, SaveArg, UpdateArg } from '../types';
import {
  selectCanvasWorkflowBuilder,
  selectEditingConditionStepId,
  selectEditingStepId,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  CanvasWorkflowBuilderState,
  clearEditingStep,
  selectEditingStepFields,
  selectIsSqueezingStep,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { AppDispatch, useAppDispatch } from 'src/store/hooks';
import { Step } from 'src/types/workflowBuilderAPITypes';

interface FormContainerProps extends FormWithErrors {
  cancelSqueeze: () => void;
  Form: React.FC<React.PropsWithChildren<FormWithErrors>>;
  label: string;
  onSaveStep?: (fields: SaveArg) => (dispatch: AppDispatch) => void;
  onUpdateStep?: (fields: {
    fields: UpdateArg;
    oldStep: Step;
    stepId: string;
  }) => (dispatch: AppDispatch) => void;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
}

const FormContainer = ({
  cancelSqueeze,
  errorObject,
  Form,
  label,
  onSaveStep,
  onUpdateStep,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}: FormContainerProps) => {
  const dispatch = useAppDispatch();
  const fields = useSelector(selectEditingStepFields);
  const editingStepId = useSelector(selectEditingStepId);
  const editingConditionStepId = useSelector(selectEditingConditionStepId);
  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const isSqueezingStep = useSelector(selectIsSqueezingStep);

  if (!fields) {
    return null;
  }

  const onCancel = () => {
    cancelSqueeze();
    dispatch(clearEditingStep());
  };

  const onSave = () => {
    const isEditingStep = Boolean(editingStepId || editingConditionStepId);

    if (isEditingStep) {
      const step = canvasData.steps[editingStepId || editingConditionStepId];

      if (step && onUpdateStep) {
        dispatch(
          onUpdateStep({
            fields,
            oldStep: step,
            stepId: editingStepId || editingConditionStepId,
          }),
        );
      }
    } else {
      const parentStepId =
        !shouldSqueezeIntoEntry && isSqueezingStep
          ? squeezeStepParentId
          : undefined;
      const isEntryStep = shouldSqueezeIntoEntry && isSqueezingStep;

      if (onSaveStep) {
        dispatch(
          onSaveStep({
            ...fields,
            isEntryStep,
            parentStepId,
          }),
        );
      }
    }

    onCancel();
  };

  return (
    <Box display='flex' flex={1} flexDirection='column' overflow='hidden'>
      <Box p={3}>
        <Typography variant='font24'>{label}</Typography>
      </Box>
      <Box flex={1} px={3} sx={{ overflowY: 'auto' }}>
        <Form errorObject={errorObject} />
      </Box>
      <Box display='flex' gap={2} p={3}>
        <Button onClick={onCancel} size='large' variant='secondary'>
          Cancel
        </Button>
        <Button
          disabled={Boolean(errorObject)}
          fullWidth
          onClick={onSave}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default FormContainer;
