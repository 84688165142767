import React from 'react';

interface TableHeaderProps {
  /** Heading string*/
  heading: string;
}

const OpenReportTableHeader: React.FC<
  React.PropsWithChildren<TableHeaderProps>
> = ({ heading }: TableHeaderProps) => {
  return <th className='Modal-tableHeader'>{heading}</th>;
};

export default OpenReportTableHeader;
