import { CSSProperties, useMemo } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import Box from '@mui/material/Box';

import {
  DiscoverAutomationCardLoadingSkeleton,
  DiscoverAutomationCardsHeader,
} from '../components';
import EmptyTabState from '../components/EmptyTabState';
import { MAX_RECOMMENDATIONS } from '../constants';
import { AutomationTabComponentProps } from '../types';
import DiscoverRealizedImpactCard from './DiscoverRealizedImpactCard';
import debounce from 'lodash/fp/debounce';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectDiscoverAutomations } from 'src/slices/data/dataSlice';

const DiscoverRealizedImpactTab = ({
  hasScrolled,
  setHasScrolled,
}: AutomationTabComponentProps) => {
  const { data, loading } = useSelector(selectDiscoverAutomations);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const onMouseEnter = useMemo(() => {
    return debounce(
      DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT,
      (automationName: string) =>
        emitTrackingEventCallback('discover-realized-impact-hovered', {
          'automation-name': automationName,
          page: 'Automation',
          'page-area': 'Realized impact',
        }),
    );
  }, [emitTrackingEventCallback]);

  const solveWorkflowAutomationData = data.find(
    ({ type }) => type === 'solve_workflow',
  );

  const shouldShowHeader =
    loading || Boolean(solveWorkflowAutomationData?.automations.length);

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const automation = solveWorkflowAutomationData?.automations[index];
    if (!automation) {
      return <Box sx={{ ...style }} />;
    }

    return (
      <Box pb='16px'>
        <DiscoverRealizedImpactCard
          automation={automation}
          key={automation.automated_object.redirect_url}
          onMouseEnter={onMouseEnter}
          style={{ ...style, height: Number(style.height) - 16 }}
        />
      </Box>
    );
  };

  const renderAutomations = () => {
    if (loading) {
      return Array(MAX_RECOMMENDATIONS)
        .fill(undefined)
        .map((_, index) => (
          <DiscoverAutomationCardLoadingSkeleton key={index} variant='intent' />
        ));
    }

    if (!solveWorkflowAutomationData?.automations.length) {
      return <EmptyTabState type='automation' />;
    }

    return (
      <Box height='71vh'>
        <AutoSizer>
          {({ height = 0, width = 0 }) => (
            <List
              height={height}
              itemCount={solveWorkflowAutomationData.automations.length + 1} // adding extra item for better scroll positioning
              itemSize={112 + 16} // 112px height +16px margin bottom
              onScroll={scrollProps => {
                if (!scrollProps.scrollOffset) {
                  setHasScrolled(false);
                  return;
                }

                setHasScrolled(true);
              }}
              overscanCount={10}
              width={width}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </Box>
    );
  };

  return (
    <>
      {shouldShowHeader && (
        <DiscoverAutomationCardsHeader
          hasScrolled={hasScrolled}
          title='Workflow'
        />
      )}
      {<Box pt='1px'>{renderAutomations()}</Box>}
    </>
  );
};

export default DiscoverRealizedImpactTab;
