import { styled } from '@mui/material/styles';

import image from 'src/assets/images/login-svg.svg';

const LandingPageImage = () => <StyledImage />;

const StyledImage = styled('div')`
  flex: 1;
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`;

export default LandingPageImage;
