import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import ChatOnlyHandoff from '../handoff-configuration/zendesk/chat-only/ChatOnlyHandoff';
import TicketCustomFields from '../handoff-configuration/zendesk/ticket-creation/TicketCustomFields';
import isEqual from 'lodash/fp/isEqual';
import set from 'lodash/fp/set';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  initializeFieldTouchedArr,
  validateChatOnlyCustomizationData,
} from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import {
  selectCanvasActionSettings,
  selectCanvasIsLoadingActionCustomization,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  SuncoLiveChatCustomization,
  ZendeskCustomFieldAndValue,
  ZendeskHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';
interface SuncoLiveChatCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const SuncoLiveChatCustomizationPanel: FC<
  React.PropsWithChildren<SuncoLiveChatCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const actionSettings = useSelector(selectCanvasActionSettings);
  const isLoadingActionCustomization = useSelector(
    selectCanvasIsLoadingActionCustomization,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultZendeskHandoffCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<ZendeskHandoffCustomization>;

  const [areErrorsVisible, setAreErrorsVisible] = useState(false);

  const defaultSuncoLiveChatCustomizationSettings = useMemo(
    () => ({
      available_zd_chat_departments:
        defaultZendeskHandoffCustomizationSettings.agent_chat_handoff_settings
          ?.available_zd_chat_departments || [],
      chat_tags: [],
      email: '',
      name: '',
      placeholder_message: 'Connecting you with an agent',
      question: 'free_form_query',
      sunco_ticket_customization_config: {
        custom_fields_and_values: [],
        ticket_custom_fields: [],
        ...defaultZendeskHandoffCustomizationSettings.sunco_ticket_customization_config,
      },
      zd_chat_department: null,
    }),
    [
      defaultZendeskHandoffCustomizationSettings.agent_chat_handoff_settings
        ?.available_zd_chat_departments,
      defaultZendeskHandoffCustomizationSettings.sunco_ticket_customization_config,
    ],
  );

  const handoffCustomizationSettings = addingAction
    ? defaultSuncoLiveChatCustomizationSettings
    : (actionSettings.configuration_fields as SuncoLiveChatCustomization);
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  // Creating a new state so we can keep track of what has changed
  const [customizationData, setCustomizationData] =
    useState<SuncoLiveChatCustomization>(handoffCustomizationSettings);
  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(customizationData),
  );
  const isPristine = isEqual(customizationData, handoffCustomizationSettings);

  const errorMessages = useMemo(
    () =>
      validateChatOnlyCustomizationData({
        allowableContextVariables: contextVariables,
        customizationData: customizationData,
      }),
    [contextVariables, customizationData],
  );

  const handleUpdateCustomFieldsAndValues = (
    updatedVal: ZendeskCustomFieldAndValue[],
  ) => {
    setCustomizationData(data => {
      const updatedCustomizationData = set(
        ['sunco_ticket_customization_config', 'custom_fields_and_values'],
        updatedVal,
        data,
      );
      return updatedCustomizationData;
    });
  };

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!isLoadingActionCustomization) {
      setCustomizationData(
        addingAction
          ? defaultSuncoLiveChatCustomizationSettings
          : handoffCustomizationSettings,
      );
    }
  }, [
    isLoadingActionCustomization,
    handoffCustomizationSettings,
    addingAction,
    defaultSuncoLiveChatCustomizationSettings,
  ]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(ActionBuilderActionTypes.SUNCO_LIVE_CHAT),
      );
    }
  }, [dispatch, addingAction]);

  const getShouldShowErrors = (): boolean => {
    return (
      (customizationData.placeholder_message !== undefined &&
        customizationData.placeholder_message.trim() === '') ||
      customizationData.name.trim() === '' ||
      customizationData.email.trim() === ''
    );
  };

  if (
    isLoadingActionCustomization ||
    !handoffCustomizationSettings ||
    !customizationData
  ) {
    return <LoadingSkeleton />;
  }

  return (
    <Box display='flex' flexDirection='column' height='100%' overflow='auto'>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Typography variant='font24'>Zendesk Sunshine Handoff</Typography>
        <Typography color={theme.palette.colors.grey[700]} variant='font14'>
          Forethought’s Live Chat transition feature enables a unified agent
          handoff experience, allowing your customers to remain in a single chat
          for a seamless transition.
        </Typography>
        <Separator />
        <TextField
          error={
            areErrorsVisible &&
            customizationData.placeholder_message !== undefined &&
            customizationData.placeholder_message.trim() === '' &&
            'Field cannot be empty'
          }
          label='Message to customers when connecting with a live agent'
          onChange={e => {
            setCustomizationData({
              ...customizationData,
              placeholder_message: e.target.value,
            });
          }}
          placeholder='Please wait while connecting'
          value={customizationData.placeholder_message || ''}
        />
        <ContextVariableSelectDropdown
          error={
            areErrorsVisible &&
            customizationData.name.trim() === '' &&
            'Field cannot be empty'
          }
          label='Name'
          onChange={value => {
            setCustomizationData({
              ...customizationData,
              name: value,
            });
          }}
          shouldIncludeSystemContextVariables
          value={customizationData.name || ''}
        />
        <ContextVariableSelectDropdown
          error={
            areErrorsVisible &&
            customizationData.email.trim() === '' &&
            'Field cannot be empty'
          }
          label='Email'
          onChange={value => {
            setCustomizationData({
              ...customizationData,
              email: value,
            });
          }}
          shouldIncludeSystemContextVariables
          value={customizationData.email || ''}
        />
        <ChatOnlyHandoff
          customizationData={customizationData}
          errorMessages={errorMessages}
          isFieldTouchedArr={isFieldTouchedArr}
          setCustomizationData={setCustomizationData}
          setIsFieldTouchedArr={setIsFieldTouchedArr}
          shouldHideNameEmailQuestion
        />
        {customizationData.sunco_ticket_customization_config && (
          <Box display='flex' flexDirection='column' gap='8px'>
            <TicketCustomFields
              customFieldsAndValues={
                customizationData.sunco_ticket_customization_config
                  .custom_fields_and_values
              }
              setCustomFieldsAndValues={handleUpdateCustomFieldsAndValues}
              ticketCustomFields={
                customizationData.sunco_ticket_customization_config
                  .ticket_custom_fields
              }
            />
          </Box>
        )}

        <Box display='flex' gap={2} mt='auto'>
          <Button
            onClick={() => {
              onDiscard();
            }}
            size='large'
            variant='secondary'
          >
            Cancel
          </Button>
          <Button
            disabled={hasWorkflowConflict || (!addingAction && isPristine)}
            fullWidth
            onClick={() => {
              if (getShouldShowErrors()) {
                setAreErrorsVisible(true);
                return;
              }

              setAreErrorsVisible(false);

              dispatch(
                addingAction
                  ? addAction({
                      ...addingAction,
                      data: {
                        ...addingAction.data,
                        configuration_fields: customizationData,
                      },
                    })
                  : updateActionSettings({
                      action_type: actionSettings.action_type as string,
                      configuration_fields: customizationData,
                    }),
              );
              dispatchActionEditedTrackingEvent({
                actionType: ActionBuilderActionTypes.SUNCO_LIVE_CHAT,
              });
              setActionPanelVisibilityParameters('hidden');
              setAreActionSettingsUnsaved(false);
              dispatch(setCustomizableActionId(''));
              onDismiss();
            }}
            size='large'
            variant='main'
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const Separator = styled('div')`
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.palette.colors.slate[200]};
`;

export default SuncoLiveChatCustomizationPanel;
