import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';

import {
  ErrorMessage,
  Label,
  Required,
  Row,
  Spacer,
  TextArea,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';

const ConfirmationMessage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <Row>
        <Label>Confirmation Message</Label>
        <Spacer width='4px' />
        <Required>Required</Required>
      </Row>
      <Field name='ticket_creation_settings.ticket_creation_config.confirmation_message'>
        {({ field, meta }: FieldProps) => (
          <>
            <TextArea
              hasError={meta.touched && !!meta.error}
              placeholder='Add message'
              {...field}
            />
            {meta.touched && meta.error && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </Field>
    </>
  );
};

export default ConfirmationMessage;
