import moment from 'moment';

import { NEW_NOTE_OBJECT } from './constants';
import { AssistNote } from 'src/slices/assist-notes/types';

export const unixTimestampToDateCreated = (timestamp: number): string => {
  const dateCreated = moment.unix(timestamp);

  return dateCreated.format('MMM Do, YYYY h:mm a');
};

export const buildFilterOptions = (notes: AssistNote[] = []) => {
  const getDedupedNoteCreators = (notes: AssistNote[]): string[] => {
    const creatorSet = new Set(notes.map(note => note.agent_email));

    return Array.from(creatorSet);
  };

  const valueToOption = (value: string) => ({
    label: value,
    value,
  });

  const admins = getDedupedNoteCreators(
    notes.filter(
      note =>
        note.created_by_role === 'ADMIN' ||
        note.created_by_role === 'SUPER_ADMIN',
    ),
  ).map(valueToOption);
  const agents = getDedupedNoteCreators(
    notes.filter(
      note => note.created_by_role === 'AGENT' || !note.created_by_role,
    ),
  ).map(valueToOption);

  return [
    ...(admins.length
      ? [{ label: 'Admins', options: admins, value: 'admins' }]
      : []),
    ...(agents.length
      ? [{ label: 'Agents', options: agents, value: 'agents' }]
      : []),
  ];
};
export const createNewNote = ({
  agent_email,
  created_by_role,
}: Pick<AssistNote, 'agent_email' | 'created_by_role'>): AssistNote => ({
  ...NEW_NOTE_OBJECT,
  agent_email,
  created_by_role,
});
