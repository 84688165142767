import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconTags } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';

const EmptyLabelsTable = () => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      mt={5}
    >
      <IconTags color={palette.colors.slate[300]} size={140} strokeWidth={1} />
      <Box columnGap={1} display='flex'>
        <Typography color={palette.colors.grey[500]} variant='font18Bold'>
          You haven&apos;t created any labels yet. To begin, click
        </Typography>
        <Typography color={palette.colors.purple[500]} variant='font18Bold'>
          &apos;Create label&apos;
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyLabelsTable;
