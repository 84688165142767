import { useCallback, useEffect, useMemo, useState } from 'react';

import { SEARCH_QUERY_FILTER_DEBOUNCE_DELAY } from '../constants';
import { Scope } from '../types';
import debounce from 'lodash/fp/debounce';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';

export const useGetSearchQuery = ({
  scope,
  searchQuery,
  setSearchQuery,
  tab,
  type,
}: {
  scope: Scope;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  tab: 'chat' | 'article' | 'topic' | 'workflow';
  type: 'chat' | 'article' | 'workflow';
}) => {
  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [uiQuery, setUiQuery] = useState(searchQuery);

  const handleOnChange = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery],
  );

  // Optimizations on query search
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce(SEARCH_QUERY_FILTER_DEBOUNCE_DELAY, (value: string) => {
        // To not call tracking event on rest filter event
        const isReset = uiQuery.length > 1 && value === '';
        !isReset &&
          // TODO - when tab navigation is added this should be updated
          emitTrackingEventCallback('insight-search-query-change', {
            query: value,
            scope,
            tab,
            table: type,
          });
        handleOnChange(value);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emitTrackingEventCallback, handleOnChange, type],
  );

  useEffect(() => {
    if (uiQuery !== searchQuery) {
      debouncedOnChangeHandler(uiQuery);
    }
  }, [debouncedOnChangeHandler, searchQuery, uiQuery]);

  return { setUiQuery, uiQuery };
};
