import React from 'react';
import styled from '@emotion/styled';
import { Box, Palette } from '@mui/material';
import Slider, { SliderThumb } from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';

import { TRACK_HEIGHT } from 'src/constants/discover';

interface RangeSliderProps {
  maxRange: number;
  minRange: number;
  onChange: (data: number[]) => void;
  values: number[];
}

const RangeSlider = ({
  maxRange,
  minRange,
  onChange,
  values,
}: RangeSliderProps) => {
  const { palette } = useTheme();
  return (
    <Box display='flex' width='100%'>
      <AirbnbSlider
        components={{ Thumb: ThumbComponent }}
        defaultValue={values}
        disableSwap
        max={maxRange}
        min={minRange}
        onChange={(_: Event, newValue: number | number[]) => {
          if (typeof newValue === 'object') {
            onChange(newValue ?? []);
          }
        }}
        palette={palette}
        size='small'
        step={1}
        value={values}
      />
    </Box>
  );
};

type ThumbComponentProps = React.HTMLAttributes<unknown>;

function ThumbComponent(props: ThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className='thumb-bar' />
      <span className='thumb-bar' />
      <span className='thumb-bar' />
    </SliderThumb>
  );
}

const AirbnbSlider = styled(Slider)<{
  palette: Palette;
}>`
  & .MuiSlider-rail {
    color: ${props => props.palette.colors.grey[200]};
    height: ${TRACK_HEIGHT}px;
  }
  & .MuiSlider-thumb {
    & .thumb-bar {
      background-color: ${props => props.palette.colors.purple[500]};
      height: 12px;
      margin-left: 1px;
      margin-right: 1px;
      width: 1.5px;
    }
    background-color: ${props => props.palette.colors.purple[100]};
    border: 1px solid currentColor;
    height: 32px;
    width: 32px;
  }
  & .MuiSlider-track {
    height: ${TRACK_HEIGHT}px;
    color: ${props => props.palette.colors.grey[200]};
  }
  height: ${TRACK_HEIGHT}px;
  .MuiSlider-thumb:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .Mui-focusVisible {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .Mui-active {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
`;

export default RangeSlider;
