import cloneDeep from 'lodash/fp/cloneDeep';
import { ArticleSuggestionActionSettings } from 'src/types/workflowBuilderAPITypes';

export const addArticleField = (
  articleSources: ArticleSuggestionActionSettings['article_sources'],
  articleSourceIndex: number,
) => {
  const articleSourcesCopy: ArticleSuggestionActionSettings['article_sources'] =
    cloneDeep(articleSources);

  const filterQueries =
    articleSourcesCopy[articleSourceIndex]['filter_queries'];

  const currentBooleanOperatorValue =
    articleSourcesCopy[articleSourceIndex]['filter_queries'][1]?.[
      'boolean_operator'
    ] || '';

  articleSourcesCopy[articleSourceIndex]['filter_queries'] = [
    ...filterQueries,
    {
      boolean_operator:
        filterQueries.length >= 2 ? currentBooleanOperatorValue : 'and',
      field: null,
      operator: 'is',
      value: null,
    },
  ];

  return articleSourcesCopy;
};
