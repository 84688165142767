import { useCallback, useRef } from 'react';
import type { XYCoord } from 'dnd-core';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material';
import { IconEqual } from '@tabler/icons-react';

import { Checkbox } from '@forethought-technologies/forethought-elements';
import {
  selectWidgetConfiguration,
  swapManuallySelectedTopIntents,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { IntentData } from 'src/types/workflowBuilderAPITypes';

interface DragItem {
  id: string;
  index: number;
  type: string;
}

export default function ManuallySelectedIntent({
  handleManualIntentChange,
  index,
  intent,
}: {
  handleManualIntentChange: (intents: string[]) => void;
  index: number;
  intent: IntentData;
}): JSX.Element {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const { manually_selected_top_intents: manuallySelectedTopIntents } =
    widgetConfiguration;

  const ref = useRef<HTMLDivElement>(null);

  const move = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(swapManuallySelectedTopIntents({ dragIndex, hoverIndex }));
    },
    [dispatch],
  );

  const save = useCallback(() => {
    handleManualIntentChange(manuallySelectedTopIntents);
  }, [handleManualIntentChange, manuallySelectedTopIntents]);

  const [{ handlerId }, drop] = useDrop({
    accept: 'intent',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: save,
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => {
      return { id: intent.intent_definition_id, index };
    },
    type: 'intent',
  });

  drag(drop(ref));

  const isSelected = manuallySelectedTopIntents.includes(
    intent.intent_definition_id,
  );

  const IntentCheckbox = () => (
    <Checkbox
      checked={isSelected}
      label={intent.intent_name}
      onChange={e => {
        handleManualIntentChange(
          e.target.checked
            ? [...manuallySelectedTopIntents, intent.intent_definition_id]
            : manuallySelectedTopIntents.filter(
                id => id !== intent.intent_definition_id,
              ),
        );
      }}
    />
  );

  return manuallySelectedTopIntents.length > 1 && isSelected ? (
    // Selected, draggable intent:
    <IntentContainer
      data-handler-id={handlerId}
      isDragging={isDragging}
      ref={ref}
    >
      <IntentCheckbox />
      <DragIcon color={palette.colors.grey[500]} />
    </IntentContainer>
  ) : (
    // Unselected, non-draggable intent:
    <IntentContainer isDragging={false}>
      <IntentCheckbox />
    </IntentContainer>
  );
}

const DragIcon = styled(IconEqual)`
  cursor: move;
`;

const IntentContainer = styled('div')<{ isDragging: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  opacity: ${props => (props.isDragging ? 0 : 1)};
  padding-left: 20px;
  padding-right: 20px;

  &:not(:last-of-type) {
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  }
`;
