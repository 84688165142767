import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { DateRangeFilterButton } from '@forethought-technologies/forethought-elements';
import { setTimeRange } from 'src/actions/pageSettings/pageSettings';
import SettingsLabel from 'src/components/reusable-components/settings-label';
import {
  CustomRangeModifier,
  selectTimeRange,
} from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { getData } from 'src/utils/getDataUtil';
import {
  datePickerRangeOptionsRevamp,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

interface Props {
  eventTracker?: Function;
  isUsedByLandingPage?: boolean;
}

const CustomDatePicker: React.FC<React.PropsWithChildren<Props>> = ({
  eventTracker,
  isUsedByLandingPage,
}) => {
  const dispatch = useDispatch();
  const timeRange: CustomRangeModifier = useSelector(selectTimeRange);
  const [datePickerRange, setDatePickerRange] =
    useState<CustomRangeModifier>(timeRange);

  return (
    <Container>
      {!isUsedByLandingPage && <SettingsLabel name={'date range'} />}
      <DateRangeFilterButton
        initialValue={last30DaysTimeRange}
        onChange={timeRange => {
          setDatePickerRange(timeRange);
          dispatch(setTimeRange(timeRange));
          getData();
          eventTracker && eventTracker(datePickerRange);
        }}
        options={datePickerRangeOptionsRevamp}
        value={datePickerRange}
      />
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 8px;
  > button {
    height: 35px;
  }
`;
export default CustomDatePicker;
