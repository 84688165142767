import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';

const DescriptionText = styled('p')<{ shouldHaveMarginTop?: boolean }>`
  font-size: ${theme.typography.font14.fontSize}px;
  font-weight: ${theme.typography.font14.fontWeight};
  line-height: ${theme.typography.font14.lineHeight};
  color: ${theme.palette.colors.black};
  margin-bottom: 0;
  margin-top: ${props => (props.shouldHaveMarginTop ? 8 : 0)}px;
  // css properties for ellipsis after second line
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default DescriptionText;
