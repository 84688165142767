import flatten from 'lodash/flatten';
import { FilterOption } from 'src/components/analytic-filter/types';
import { UNFORMATTED_LABEL_SEPARATOR } from 'src/constants/discover';
import { Field } from 'src/services/apiInterfaces';
import {
  DiscoverGetFilterFieldsResponse,
  DiscoverGetFiltersResponse,
  FilterCategory,
} from 'src/services/discover/types';

export const createFilterRequest = (
  fields?: DiscoverGetFilterFieldsResponse,
): (Omit<Field, 'field_category'> & { field_category: string })[] => {
  if (!fields) {
    return [];
  }

  return flatten(
    Object.entries(fields).map(item => {
      const [key, value] = item;
      return value.map(fieldItem => ({
        field_category: key,
        field_id: fieldItem.field_id,
        field_name: fieldItem.field_name,
        field_type: fieldItem.field_type,
      }));
    }),
  );
};

export const createOptions = (
  filterFields: Field[],
  fields?: DiscoverGetFiltersResponse,
): FilterOption[] | null => {
  if (!fields) {
    return null;
  }

  const fieldObject = {
    ...fields.agatha,
    ...fields.agent,
    ...fields.ticket,
  };

  return filterFields.map(filterFieldItem => {
    const category = filterFieldItem.field_category;
    const fieldLabel = filterFieldItem.field_name;
    const fieldId = filterFieldItem.field_id;
    const items: string[] = fieldObject[fieldId as FilterCategory] ?? [];

    return {
      category,
      field: filterFieldItem,
      label: fieldLabel,
      negate: false,
      operator: '=',
      options: items.map(item => {
        const label = String(item);
        const value = `${category}${UNFORMATTED_LABEL_SEPARATOR}${fieldId}${UNFORMATTED_LABEL_SEPARATOR}${label}`;

        return {
          category,
          label,
          value,
        };
      }),
      value: filterFieldItem.field_id,
      values: items.map(String),
    };
  });
};
