import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { CsatWidgetContent } from './CsatWidgetContent';
import { QuickFeedbackWidgetContent } from './QuickFeedbackWidgetContent';
import Spinner from 'src/components/spinner';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

const CsatQuickFeedbackParentWidgetContent = () => {
  const { data: featureFlagsData, isLoading: isFeatureFlagsLoading } =
    useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('sub-tab') ?? '';

  const page = useMemo(() => {
    switch (query) {
      case 'quick feedback':
        return <QuickFeedbackWidgetContent />;
      case 'csat':
      default:
        return <CsatWidgetContent />;
    }
  }, [query]);

  if (isFeatureFlagsLoading) {
    return <Spinner />;
  }

  if (!isQuickFeedbackEnabled) {
    return <CsatWidgetContent />;
  }

  return page;
};

export default CsatQuickFeedbackParentWidgetContent;
