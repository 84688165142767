import React from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import {
  TextField,
  Toggle,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  selectArticleSuggestionFeedbackSettings,
  setArticleSuggestionFeedbackSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';

interface FeedbackTabProps {
  isFeedbackRemovable: boolean;
  shouldShowErrors: boolean;
}

export const FeedbackTab: React.FC<
  React.PropsWithChildren<FeedbackTabProps>
> = ({ isFeedbackRemovable, shouldShowErrors }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const articleSuggestionFeedbackSettings = useSelector(
    selectArticleSuggestionFeedbackSettings,
  );

  if (!articleSuggestionFeedbackSettings) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Tooltip
        tooltipContent={
          isFeedbackRemovable
            ? ''
            : 'Cannot remove feedback because both branches are filled'
        }
      >
        <Toggle
          checked={articleSuggestionFeedbackSettings.show_buttons}
          disabled={!isFeedbackRemovable}
          label={
            <Typography variant='font14'>
              Gather customers&apos; feedback on the helpfulness of an article
              suggestion
            </Typography>
          }
          onChange={() => {
            dispatch(
              setArticleSuggestionFeedbackSettings({
                ...articleSuggestionFeedbackSettings,
                show_buttons: !articleSuggestionFeedbackSettings.show_buttons,
              }),
            );
          }}
        />
      </Tooltip>
      {articleSuggestionFeedbackSettings.show_buttons && (
        <>
          <Box>
            <TextField
              error={
                shouldShowErrors &&
                articleSuggestionFeedbackSettings.button_prompt.length > 48
              }
              label='Feedback question text'
              onChange={e => {
                dispatch(
                  setArticleSuggestionFeedbackSettings({
                    ...articleSuggestionFeedbackSettings,
                    button_prompt: e.target.value,
                  }),
                );
              }}
              placeholder='Was this helpful?'
              value={articleSuggestionFeedbackSettings.button_prompt}
            />
            <Typography
              color={
                shouldShowErrors &&
                articleSuggestionFeedbackSettings.button_prompt.length > 48
                  ? theme.palette.colors.red[500]
                  : theme.palette.colors.grey[400]
              }
              variant='font12'
            >
              48 char limit
            </Typography>
          </Box>
          <Box>
            <TextField
              error={
                shouldShowErrors &&
                articleSuggestionFeedbackSettings.helpful_button_text.length >
                  24
              }
              label='Helpful button text'
              onChange={e => {
                dispatch(
                  setArticleSuggestionFeedbackSettings({
                    ...articleSuggestionFeedbackSettings,
                    helpful_button_text: e.target.value,
                  }),
                );
              }}
              placeholder='Yes'
              value={articleSuggestionFeedbackSettings.helpful_button_text}
            />
            <Typography
              color={
                shouldShowErrors &&
                articleSuggestionFeedbackSettings.helpful_button_text.length >
                  24
                  ? theme.palette.colors.red[500]
                  : theme.palette.colors.grey[400]
              }
              variant='font12'
            >
              {"24 char limit: 'Helpful'"}
            </Typography>
          </Box>
          <Box>
            <TextField
              error={
                shouldShowErrors &&
                articleSuggestionFeedbackSettings.not_helpful_button_text
                  .length > 24
              }
              label='Not helpful button text'
              onChange={e => {
                dispatch(
                  setArticleSuggestionFeedbackSettings({
                    ...articleSuggestionFeedbackSettings,
                    not_helpful_button_text: e.target.value,
                  }),
                );
              }}
              placeholder='No'
              value={articleSuggestionFeedbackSettings.not_helpful_button_text}
            />
            <Typography
              color={
                shouldShowErrors &&
                articleSuggestionFeedbackSettings.not_helpful_button_text
                  .length > 24
                  ? theme.palette.colors.red[500]
                  : theme.palette.colors.grey[400]
              }
              variant='font12'
            >
              {"24 char limit: 'Not helpful'"}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
