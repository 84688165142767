import { EMPTY_SUMMARY_ROW_VALUE } from '../constants';
import { DiscoverTopicTableData } from '../types';
import { DevianceRendererComponent } from './DevianceRenderer';
import { METRIC_FILTERS_SEPARATOR } from 'src/constants/discover';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';

export const devianceCellRenderer =
  (metricFilter: DiscoverTopicAggregatedMetricType) =>
  // eslint-disable-next-line react/display-name
  (row: DiscoverTopicTableData, isSummaryRow?: boolean) => {
    const value =
      row[`${metricFilter}${METRIC_FILTERS_SEPARATOR}value_deviance`];
    if (isSummaryRow && value === null) {
      return EMPTY_SUMMARY_ROW_VALUE;
    }
    return (
      <DevianceRendererComponent
        isHidden={Boolean(row.topic?.is_hidden)}
        isSummaryRow={isSummaryRow}
        metricFilter={metricFilter}
        value={value}
        volumeValue={row['volume.value']}
      />
    );
  };
