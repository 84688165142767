import { useLocation } from 'react-router-dom';

import { handleCreateLinkLabel, handleCreateLinkPath } from '../helpers';
import { DISCOVER_SHARED_PARAM_NAMES } from 'src/constants/discover';
import { useGenerateLinkWithSearch } from 'src/hooks/useGenerateLinkWithSearch';
import { Routes } from 'src/utils/enums';

export const useGenerateBackLinkFromSource = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const source = params.get(DISCOVER_SHARED_PARAM_NAMES.SOURCE);
  const path = handleCreateLinkPath(source);
  const pathWithSearch = useGenerateLinkWithSearch(path);

  return {
    label: handleCreateLinkLabel(source),
    link:
      path === Routes.DISCOVER_AUTOMATION
        ? path + (source === 'knowledge gap' ? '?tab=Knowledge+gap' : '')
        : pathWithSearch,
  };
};
