import 'jsoneditor/dist/jsoneditor.css';
import './styles/ResponseView.scss';

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useField } from 'formik';
import { Box } from '@mui/material';

import {
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { JsonViewer } from './JsonViewer';
import debounce from 'lodash/fp/debounce';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import { useGetActionBuilderData } from 'src/hooks/useGetActionBuilderData';
import TestValuesTable from 'src/pages/action-builder-detail/action-builder-form/TestValuesTable';
import { validateJmesPath } from 'src/services/action-builder/actionBuilderApi';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { OutputValues } from 'src/types/actionBuilderApiTypes';
import { replaceCVWithTestValuesForJmespathQuery } from 'src/utils/actionBuilder/helpers';
import { extractCvIdsFromFieldValue } from 'src/utils/cleanStr';

interface ResponseViewProps {
  clearOutputParameters: () => void;
  isActive?: boolean;
  jmespathQueryTestCvs: Partial<Record<string, string>>;
  /** Json object to be displayed. Using type unknown as this could be any type */
  jsonData: unknown;
  /** Callback that fires as new param is added */
  onAddNewParam: (outputValues: OutputValues) => void;
  setJmespathQueryTestCvs: (
    jmespathQueryTestCvs: Partial<Record<string, string>>,
  ) => void;
}

export const ResponseView: FC<React.PropsWithChildren<ResponseViewProps>> = ({
  clearOutputParameters,
  isActive,
  jmespathQueryTestCvs,
  jsonData,
  onAddNewParam,
  setJmespathQueryTestCvs,
}) => {
  const [{ value: jmespathQuery }, , { setValue: setJmespathQuery }] =
    useField<string>({
      name: 'fields.jmespathQuery',
    });

  const [isAdvancedFilterMode, setIsAdvancedFilterMode] = useState(
    Boolean(jmespathQuery),
  );
  const [transformedJsonData, setTransformedJsonData] = useState<
    unknown | undefined
  >();
  const [errorMessage, setErrorMessage] = useState('');

  const {
    hasEmptyTestValues,
    jmespathQueryWithTestValues,
    usedContextVariables,
  } = useTestCvs(jmespathQuery, jmespathQueryTestCvs);

  const debouncedValidateJmesPath = useMemo(
    () =>
      debounce(
        500,
        ({
          expression,
          testData,
        }: {
          expression: string;
          testData: unknown;
        }) => {
          validateJmesPath({ expression, test_data: testData })
            .then(res => {
              setTransformedJsonData(res.result);
              setErrorMessage(res.error || '');
            })
            .catch(error => {
              setTransformedJsonData(undefined);
              setErrorMessage(error.message);
            });
        },
      ),
    [],
  );

  useEffect(() => {
    if (!jmespathQueryWithTestValues) {
      setTransformedJsonData(undefined);
      setErrorMessage('');
    } else {
      debouncedValidateJmesPath({
        expression: jmespathQueryWithTestValues,
        testData: jsonData,
      });
    }
  }, [debouncedValidateJmesPath, jmespathQueryWithTestValues, jsonData]);

  const hasOutputValues = useHasOutputValues();

  return (
    <>
      <Box>
        <Box
          display='flex'
          marginBottom={isAdvancedFilterMode ? '18px' : '14px'}
        >
          <Toggle
            checked={isAdvancedFilterMode}
            disabled={isActive}
            label='Advanced Filter'
            onChange={() => {
              clearOutputParameters();
              if (isAdvancedFilterMode) {
                setJmespathQuery('');
              }
              setIsAdvancedFilterMode(!isAdvancedFilterMode);
            }}
          />
        </Box>
        {isAdvancedFilterMode && (
          <>
            <ContextVariableAutocomplete
              disabled={isActive}
              error={
                hasEmptyTestValues
                  ? 'Missing test value for a context variable'
                  : errorMessage
              }
              onChange={value => {
                if (hasOutputValues) {
                  clearOutputParameters();
                }
                setJmespathQuery(value);
              }}
              placeholder="e. g. { openTickets: tickets[?status == 'open'] } or { adults: people[?age >= `18`] }"
              value={jmespathQuery}
            />
            <Box marginBottom='12px' />
            <Typography variant='font12'>
              Specify a{' '}
              <a
                href='https://support.forethought.ai/hc/en-us/articles/25038989182995-Action-Builder-Advanced-Filter'
                rel='noreferrer'
                target='_blank'
              >
                JMESPath
              </a>{' '}
              expression
              {usedContextVariables.length > 0 &&
                ' and test values for Context Variables'}{' '}
              to transform the response
            </Typography>
            {usedContextVariables.length > 0 && (
              <TestValuesTable
                contextVariables={usedContextVariables}
                setTestValues={setJmespathQueryTestCvs}
                testValues={jmespathQueryTestCvs}
              />
            )}
          </>
        )}
      </Box>
      {isAdvancedFilterMode && (
        <>
          {transformedJsonData !== undefined && (
            <>
              <Box marginBottom='8px' marginTop='10px'>
                <Typography variant='font12Medium'>Result:</Typography>
              </Box>
              <Box bgcolor='#283348' padding='28px 16px 8px'>
                <JsonViewer
                  isActive={isActive}
                  isSearchBarVisible={!isAdvancedFilterMode}
                  jsonData={transformedJsonData}
                  onAddNewParam={onAddNewParam}
                  theme='dark'
                />
              </Box>
            </>
          )}
        </>
      )}
      {isAdvancedFilterMode && (
        <Box marginTop='10px'>
          <Typography variant='font12Medium'>Original response:</Typography>
        </Box>
      )}
      <JsonViewer
        disabled={isAdvancedFilterMode}
        isActive={isActive}
        isSearchBarVisible={!isAdvancedFilterMode}
        jsonData={jsonData}
        onAddNewParam={onAddNewParam}
      />
    </>
  );
};

function useHasOutputValues() {
  const [{ value: outputValues }] = useField<string>({
    name: 'outputValues',
  });

  return Object.keys(outputValues).length > 0;
}

function useTestCvs(
  jmespathQuery: string,
  jmespathQueryTestCvs: ResponseViewProps['jmespathQueryTestCvs'],
) {
  const { contextVariables } = useGetActionBuilderData();

  const usedContextVariables = useMemo(() => {
    const nonUnique = extractCvIdsFromFieldValue(jmespathQuery)
      .map(variableId =>
        contextVariables.find(
          ({ context_variable_id: id }) => id === variableId,
        ),
      )
      .filter((param): param is ContextVariable => param !== undefined);

    return Array.from(new Set(nonUnique));
  }, [jmespathQuery, contextVariables]);

  const hasEmptyTestValues = usedContextVariables.some(
    cv => !jmespathQueryTestCvs[cv.context_variable_id],
  );

  const jmespathQueryWithTestValues = replaceCVWithTestValuesForJmespathQuery(
    jmespathQuery,
    jmespathQueryTestCvs,
  );

  return {
    hasEmptyTestValues,
    jmespathQueryWithTestValues,
    usedContextVariables,
  } as const;
}
