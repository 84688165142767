import { useMemo } from 'react';

import {
  DropoffBreakdown,
  RelevanceBreakdown,
} from 'src/services/apiInterfaces';
import { useGetWidgetBreakdownMetricsQuery } from 'src/services/workflow-builder-metrics';

interface MapItem {
  dropoff_breakdown?: DropoffBreakdown;
  relevance_breakdown?: RelevanceBreakdown;
}

export const useIntentToDeflectionInsightMap = (from: string, to: string) => {
  // fetch breakdown by intent for total inquiries and deflection rate
  const { data: breakdownResponse, isLoading } =
    useGetWidgetBreakdownMetricsQuery({
      from: from,
      to: to,
    });
  const breakdown = breakdownResponse?.breakdown;

  const result = useMemo(() => {
    const map: Record<string, MapItem> = {};
    breakdown?.forEach(item => {
      if (!item) {
        return;
      }

      map[item.intent_id] = {
        dropoff_breakdown: item.dropoff_breakdown,
        relevance_breakdown: item.relevance_breakdown,
      };
    });

    return map;
  }, [breakdown]);

  return { isLoading, result };
};
