import { useEffect } from 'react';

import { getTicketFieldMappings } from 'src/slices/ticket-field-mappings/ticketFieldMappingsSlice';
import { useAppDispatch } from 'src/store/hooks';

export const useGetTicketFieldMappings = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTicketFieldMappings());
  }, [dispatch]);
};
