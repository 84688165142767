import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// In some cases we don't want to send error messages to Sentry
const IGNORE_ERRORS = [
  // Filters out Cognito error when a new user is invited but already exists
  'already exists in pool',
];

export function initSentry() {
  Sentry.init({
    attachStacktrace: true,
    beforeSend(event: Sentry.Event) {
      if (!event.extra) {
        event.extra = {};
      }
      event.extra['LogRocket'] =
        LogRocket.sessionURL ?? 'No session URL available';

      return event;
    },
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    ignoreErrors: IGNORE_ERRORS,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [new RegExp(/forethought\.((app)|(tech)|ai)/)],
      }),
    ],
    tracesSampleRate: 0.2,
  });
}
