import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';

export const CheckboxContainer = styled('div')`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled('svg')`
  fill: none;
  stroke: ${theme.palette.colors.white};
  stroke-width: 3px;
  position: relative;
  bottom: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const HiddenCheckbox = styled('input')`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled('div')<{
  checked: boolean;
  isDisabled?: boolean;
}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props =>
    props.checked ? 'var(--color-purple-500)' : theme.palette.colors.white};
  border-radius: var(--border-radius-button);
  transition: ease-in-out 150ms;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};

  &:hover {
    ${props =>
      !props.isDisabled &&
      `border: 1px solid ${theme.palette.colors.grey[600]}`}
  }

  .hidden-checkbox:focus + & {
    box-shadow: 0 0 0 3px ${theme.palette.colors.red[100]};
  }

  border: ${props =>
    props.checked
      ? `1px solid ${theme.palette.colors.purple[500]}`
      : `1px solid ${theme.palette.colors.grey[300]} `};

  .ft-icon {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
