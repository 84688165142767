import React from 'react';
import Box from '@mui/material/Box';
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';

interface MenuProps extends MuiMenuProps {
  title: string;
  width?: string | number;
}

const Menu = ({
  children,
  title,
  width = '350px',
  ...props
}: React.PropsWithChildren<MenuProps>) => {
  const { palette } = useTheme();

  return (
    <MuiMenu
      PaperProps={{
        elevation: 1,
        sx: {
          border: `1px solid ${palette.colors.slate[200]}`,
          borderRadius: '4px',
          marginTop: 1,
          width,
        },
      }}
      {...props}
    >
      <Box mb={1.5} px={2}>
        <Typography color={palette.colors.grey[500]} variant='font14'>
          {title}
        </Typography>
      </Box>
      {children}
    </MuiMenu>
  );
};

export default Menu;
