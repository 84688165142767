import { theme } from '@forethought-technologies/forethought-elements';

const textMediumStyle = ({
  color,
  fontSize,
}: {
  color?: string;
  fontSize?: string;
} = {}) => `
  font-size: ${fontSize || 'var(--font-size-m)'};
  text-align: left;
  color: ${color || 'var(--color-text-primary)'};
  font-weight: var(--font-weight-medium);
  cursor: default;
`;

const textRegularStyle = ({
  color,
  fontSize,
}: {
  color?: string;
  fontSize?: string;
} = {}) => `
  font-size: ${fontSize || 'var(--font-size-xs)'};
  color: ${color || theme.palette.colors.grey[500]};
  font-weight: var(--font-weight-regular);
  &::first-letter {
    text-transform: uppercase;
  }
  cursor: default;
`;

const textBoldStyle = ({
  color,
  fontSize,
}: {
  color: string;
  fontSize: string;
}) => `
  font-size: ${fontSize};
  text-align: left;
  color: ${color};
  font-weight: var(--font-weight-bold);
  cursor: default;
`;

export { textBoldStyle, textMediumStyle, textRegularStyle };
