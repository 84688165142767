import { useMemo, useState } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TextWithInfo from '../triage-model-detail-page/common/TextWithInfo';
import TriageModelTable from '../triage-model-detail-page/common/TriageModelTable';
import EmptyLabelsTable from '../triage-model-detail-page/label-tab/EmptyLabelsTable';
import LabelsTableControl, {
  LabelControlOptions,
} from '../triage-model-detail-page/label-tab/LabelsTableControl';
import { TagPhrase } from '../triage-model-detail-page/types';
import { VersionLabel } from './types';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';

interface TableColumnItem {
  key: keyof VersionLabel;
  size?: number;
  title: string;
  tooltip: string;
}

const tableColumns: TableColumnItem[] = [
  {
    key: 'title',
    title: 'Label',
    tooltip: '',
  },
  {
    key: 'description',
    title: 'Description',
    tooltip:
      "Brief description of your customer's action or query to guide the model in predicting the accurate label.",
  },
  {
    key: 'phrases',
    title: 'Training Phrases',
    tooltip:
      'Training phrases help AI to better classify tickets within this label.',
  },
];

interface LabelsTableProps {
  labelControlOptions: LabelControlOptions;
  loading: boolean;
  model: VersionedTriageModel;
}

const getValue = (currentValue: string | TagPhrase[]): string => {
  if (typeof currentValue === 'string') {
    return currentValue;
  }
  return currentValue.map(value => value.phrase_text).join(', ');
};

const LabelsTable = ({
  labelControlOptions,
  loading,
  model,
}: LabelsTableProps) => {
  const [searchText, setSearchText] = useState('');
  const columnHelper = createMRTColumnHelper<VersionLabel>();

  const columns = useMemo(
    () =>
      tableColumns.map(item =>
        columnHelper.accessor(item.key, {
          Cell: ({ row }: { row: MRT_Row<VersionLabel> }) => {
            const currentValue = row.original[item.key];
            const targetValue = getValue(currentValue);
            return <Typography variant='font14'>{targetValue}</Typography>;
          },
          header: item.title,
          Header: (
            <TextWithInfo
              textColor={['grey', 800]}
              title={item.title}
              tooltip={item.tooltip}
              variant='font14Bold'
            />
          ),
          size: item.size,
        }),
      ),
    [columnHelper],
  );

  const allLabels = useMemo(() => {
    return model.labels.map(item => {
      return {
        description: item.description,
        phrases: model.phrases.filter(phrase => phrase.tag_id === item.tag_id),
        tag_id: item.tag_id,
        title: item.title,
      };
    });
  }, [model]);

  const rows = useMemo(() => {
    if (!searchText) {
      return allLabels;
    }
    return allLabels.filter(item => {
      const text = searchText.toLowerCase();
      const hasTitle = item.title.toLowerCase().includes(text);
      const hasDescription = item.description.toLowerCase().includes(text);

      return hasTitle || hasDescription;
    });
  }, [allLabels, searchText]);

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  return (
    <Box display='grid' height='100%' minHeight='300px'>
      <TriageModelTable<VersionLabel, string | TagPhrase[]>
        columns={columns}
        Control={
          <LabelsTableControl
            labelControlOptions={labelControlOptions}
            model={model}
          />
        }
        data={rows}
        initialSorting={[{ desc: true, id: 'title' }]}
        isLoading={loading}
        onSearch={handleSearch}
        options={{
          enableHiding: false,
          muiTableBodyRowProps: ({
            row,
          }: {
            row: { original: { tag_id: string } };
          }) => ({
            onClick: () => labelControlOptions.selectTagId(row.original.tag_id),
            sx: {
              cursor: 'pointer',
            },
          }),
          muiTableContainerProps: {
            sx: {
              height: '100%',
            },
          },
        }}
        renderEmptyRowsFallback={() => <EmptyLabelsTable />}
      />
    </Box>
  );
};

export default LabelsTable;
