import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import {
  Alert,
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import LoadingSpinner from 'src/components/reusable-components/loading-spinner/loadingSpinner';
import {
  getEmbeddableDashboardsSettings,
  getEmbeddableDashboardURL,
} from 'src/services/api';

interface ListOption {
  label: string;
  value: string;
}

const EmbeddableDashboardsPage = () => {
  const [dashboardID, setDashboardID] = useState<string>('');
  const [options, setOptions] = useState<ListOption[]>([]);
  const [embedURL, setEmbedURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [noOptionsAvailable, setNoOptionsAvailable] = useState(false);

  const resetPage = () => {
    setLoading(false);
    setEmbedURL('');
    setErrorMessage('');
  };

  useEffect(() => {
    const getOptions = async () => {
      const settings = await getEmbeddableDashboardsSettings();

      const newOptions: ListOption[] = settings.embeddable_dashboards.map(
        dashboard => {
          return {
            label: dashboard.title,
            value: dashboard.metabase_dashboard_id.toString(),
          };
        },
      );

      if (!newOptions.length) {
        setNoOptionsAvailable(true);
      }

      setOptions(newOptions);
    };

    getOptions().catch(() => {
      setErrorMessage('Error retrieving list of dashboards');
    });
  }, []);

  useEffect(() => {
    if (dashboardID) {
      resetPage();
      setLoading(true);
      getEmbeddableDashboardURL(parseInt(dashboardID))
        .then(res => setEmbedURL(res.embed_url))
        .catch(() => setErrorMessage('Error retrieving dashboard'))
        .finally(() => setLoading(false));
    }
  }, [dashboardID]);

  if (noOptionsAvailable) {
    return (
      <Container>
        <Alert
          isFullWidth
          title='No custom analytics available for your organization'
          variant='warning'
        />
      </Container>
    );
  }

  return (
    <Container>
      {errorMessage && (
        <Alert
          isFullWidth
          onClose={resetPage}
          title={errorMessage}
          variant='danger'
        />
      )}
      <DropdownContainer>
        <SelectDropdown
          id='select-dropdown'
          label='Select a dashboard to view'
          onChange={e => {
            setDashboardID(e.target.value);
          }}
          options={options}
          value={dashboardID}
        />
      </DropdownContainer>
      {loading && <LoadingSpinner />}
      {embedURL && <EmbedFrame src={embedURL} />}
    </Container>
  );
};

export default EmbeddableDashboardsPage;

const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: calc(100vh - 57px);
  background-color: ${theme.palette.colors.white};
`;

const EmbedFrame = styled('iframe')`
  display: flex;
  flex: 1;
  border: none;
`;

const DropdownContainer = styled('div')`
  display: flex;
  align-self: center;
  width: 100%;
  max-width: 600px;
  padding: 10px 0;
`;
