import React, { useMemo } from 'react';
import { FloatingToolbar, useCurrentSelection } from '@remirror/react';

import CustomThemeProvider from '../theme';
import { TEXT_EDITOR_FLOATING_TOOLBAR_ID } from './constants';
import { useFloatingLinkState } from './hooks';
import LinkTools, { LinkToolForm } from './LinkTools';
import { LinkPreviewConfig } from './types';
import { createMarkPositioner } from 'remirror/extensions';

const buttonStyles = {
  '&:hover': {
    backgroundColor: '#e8e9eb',
  },
  color: '#000',
  margin: '0px !important',
  padding: '6px',
} as const;

interface Props {
  linkPreviewConfig?: LinkPreviewConfig;
  shouldValidateHrefLength?: boolean;
}

export const FloatingLinkToolbar: React.FC<React.PropsWithChildren<Props>> = ({
  linkPreviewConfig,
  shouldValidateHrefLength = false,
}) => {
  const state = useFloatingLinkState({ shouldValidateHrefLength });

  // A positioner which only shows for links.
  const linkPositioner = useMemo(
    () => createMarkPositioner({ type: 'link' }),
    [],
  );
  const { empty } = useCurrentSelection();

  return (
    <CustomThemeProvider>
      {!state.isEditing && (
        <FloatingToolbar id={TEXT_EDITOR_FLOATING_TOOLBAR_ID}>
          <LinkTools buttonStyles={buttonStyles} {...state} />
        </FloatingToolbar>
      )}
      {!state.isEditing && empty && (
        <FloatingToolbar
          id={TEXT_EDITOR_FLOATING_TOOLBAR_ID}
          positioner={linkPositioner}
        >
          <LinkTools buttonStyles={buttonStyles} {...state} />
        </FloatingToolbar>
      )}
      <LinkToolForm
        enabled={state.isEditing}
        linkPreviewConfig={linkPreviewConfig}
        {...state}
      />
    </CustomThemeProvider>
  );
};
