import classNames from 'classnames';

// TODO Test this file

export const getContentIcon = (
  fieldType: string,
  isDark: boolean,
  selected: boolean,
): string => {
  let typeIcon = '';

  if (fieldType === 'STRING' || fieldType === 'URL') {
    if (isDark) {
      if (selected) {
        typeIcon = 'ModalItem-SelectedStringIcon';
      } else {
        typeIcon = 'ModalItem-stringTypeIcon';
      }
    } else {
      typeIcon = 'ModalItem-stringTypeIcon';
    }
  }

  if (fieldType === 'INTEGER' || fieldType === 'DOUBLE') {
    if (isDark) {
      if (selected) {
        typeIcon = 'ModalItem-SelectedIntIcon';
      } else {
        typeIcon = 'ModalItem-intTypeIcon';
      }
    } else {
      typeIcon = 'ModalItem-intTypeIcon';
    }
  }

  if (fieldType === 'DATE') {
    if (isDark) {
      if (selected) {
        typeIcon = 'ModalItem-SelectedDateIcon';
      } else {
        typeIcon = 'ModalItem-dateTypeIcon';
      }
    } else {
      typeIcon = 'ModalItem-dateTypeIcon';
    }
  }

  if (fieldType === 'BOOLEAN') {
    if (isDark) {
      if (selected) {
        typeIcon = 'ModalItem-SelectedBooleanIcon';
      } else {
        typeIcon = 'ModalItem-booleanTypeIcon';
      }
    } else {
      typeIcon = 'ModalItem-booleanTypeIcon';
    }
  }

  if (fieldType === 'ORG') {
    if (selected) {
      typeIcon = 'ModalItem-SelectedOrgTypeIcon';
    } else {
      typeIcon = 'ModalItem-orgTypeIcon';
    }
  }

  return classNames('ModalItem-TypeIcon', typeIcon);
};
