import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getKnowledgeGapRecommendations } from 'src/actions/discover/discover';
import { selectKnowledgeGapRecommendations } from 'src/reducers/discoverReducer/discoverReducer';
import { useAppDispatch } from 'src/store/hooks';

export const useGetKnowledgeGapRecommendations = () => {
  const dispatch = useAppDispatch();
  const knowledgeGapRecommendations = useSelector(
    selectKnowledgeGapRecommendations,
  );

  useEffect(() => {
    dispatch(getKnowledgeGapRecommendations());
  }, [dispatch]);

  return knowledgeGapRecommendations;
};
