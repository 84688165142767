import { useState } from 'react';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import TestValuesTable from './TestValuesTable';
import BaseModal from 'src/components/base-modal';
import {
  ContextVariable,
  SelectedActionDetail,
} from 'src/types/actionBuilderApiTypes';

interface TestValuesModalProps {
  handleTestConnection: (
    values: SelectedActionDetail,
    idTestValueDict?: Record<string, string>,
  ) => Promise<unknown>;
  inputParams: ContextVariable[];
  isOpen: boolean;
  onClose: () => void;
}

const TestValuesModal = ({
  handleTestConnection,
  inputParams,
  isOpen,
  onClose,
}: TestValuesModalProps) => {
  const { values } = useFormikContext<SelectedActionDetail>();

  const [testValues, setTestValues] = useState<Record<string, string>>({});

  const hasEmptyTestValues = inputParams.some(
    cv => !testValues[cv.context_variable_id],
  );

  return (
    <BaseModal
      headerTitle={
        <Typography variant='font16Bold'>
          Add test values to receive a response
        </Typography>
      }
      isOpen={isOpen}
      maxWidth='sm'
      onClose={onClose}
    >
      <Box height='450px' overflow='auto' paddingInline='24px'>
        <TestValuesTable
          contextVariables={inputParams}
          setTestValues={setTestValues}
          tableWidth='100%'
          testValues={testValues}
        />
      </Box>
      <Box display='flex' justifyContent='flex-end' p={3}>
        <Button
          disabled={hasEmptyTestValues}
          onClick={() => {
            handleTestConnection(values, testValues);
            onClose();
          }}
          variant='main'
        >
          Continue
        </Button>
      </Box>
    </BaseModal>
  );
};

export default TestValuesModal;
