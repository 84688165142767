const setTooltipVisible = (
  visible: boolean,
  setVisibilityState: Function,
  setOverflowState: Function,
  ref: React.RefObject<HTMLElement>,
  size: number,
) => {
  setVisibilityState(visible);
  const offset = ref.current ? ref.current.scrollWidth : 0;
  if (offset - 18 >= size) {
    setOverflowState(true);
  } else {
    setOverflowState(false);
  }
};

export default setTooltipVisible;
