import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getDiscoverRecommendationDetails } from 'src/actions/discover/discover';
import { selectRecommendationDetails } from 'src/reducers/discoverReducer/discoverReducer';
import { useAppDispatch } from 'src/store/hooks';

export const useGetDiscoverRecommendations = (topicId?: string) => {
  const recommendationDetails = useSelector(selectRecommendationDetails);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!topicId) {
      return;
    }
    dispatch(getDiscoverRecommendationDetails({ topicId }));
  }, [dispatch, topicId]);

  return recommendationDetails;
};
