import { useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { Button } from '@forethought-technologies/forethought-elements';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { Divider } from 'src/pages/intent-conversation-analytics/ConversationDrawer';
import {
  formatContextVariableForDisplay,
  formatContextVariableForPersistence,
} from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import TicketCustomFields from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/TicketCustomFields';
import TicketFields from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/ticket-creation/TicketFields';
import TagInputWithContextVariables from 'src/pages/workflow-builder-edit/tag-input-with-context-variables/TagInputWithContextVariables';
import { TicketUpdateStepFields } from 'src/pages/workflow-builder-edit/types';
import { useGetTicketCustomFieldsQuery } from 'src/services/email-automation/emailAutomationApi';
import {
  TicketStaticFieldAndValue,
  ZendeskCustomFieldAndValue,
} from 'src/types/workflowBuilderAPITypes';

interface ZendeskTicketUpdateFormProps {
  handleCancel: () => void;
  handleSave: (fields: TicketUpdateStepFields) => void;
  initialStepFields?: TicketUpdateStepFields;
}

export function ZendeskTicketUpdateForm({
  handleCancel,
  handleSave,
  initialStepFields,
}: ZendeskTicketUpdateFormProps) {
  const { data: customFieldsResponse } = useGetTicketCustomFieldsQuery();
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const [tags, setTags] = useState<string[]>(
    initialStepFields?.ticket_tags || [],
  );
  const [staticFieldsAndValues, setStaticFieldsAndValues] = useState<
    TicketStaticFieldAndValue[]
  >(initialStepFields?.static_fields_and_values || []);
  const [customFieldsAndValues, setCustomFieldsAndValues] = useState<
    ZendeskCustomFieldAndValue[]
  >(initialStepFields?.custom_fields_and_values || []);

  const handleTagAddition = (tag: string) =>
    setTags(prevTags => [
      ...prevTags,
      formatContextVariableForPersistence(contextVariables, tag),
    ]);
  const handleTagDeletion = (idx: number) =>
    setTags(prevTags => prevTags.filter((_, i) => i !== idx));

  const ticketFields = useMemo(() => {
    if (!customFieldsResponse) {
      return [];
    }

    return customFieldsResponse.static_fields
      .filter(field =>
        [
          'assignee_id',
          'form',
          'subject',
          'brand_id',
          'group',
          'status',
          'priority',
        ].includes(field.id),
      )
      .map(field => {
        const type = field.field_options ? 'tagger' : 'text';
        return {
          field_options:
            field.field_options?.map(option => ({
              name: option.label,
              value: option.value,
            })) || [],
          id: field.id,
          title: field.title,
          type,
        };
      });
  }, [customFieldsResponse]);

  if (!customFieldsResponse) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' gap={2} px={3}>
      <div>
        <TagInputWithContextVariables
          contextVariables={contextVariables}
          errorMessages={[]}
          errorTag={0}
          handleTagAddition={handleTagAddition}
          handleTagDeletion={handleTagDeletion}
          label='Ticket Tag'
          tags={tags.map(tag =>
            formatContextVariableForDisplay(contextVariables, tag),
          )}
        />
      </div>
      <Divider />
      <div>
        <TicketFields
          setStaticFieldsAndValues={setStaticFieldsAndValues}
          staticFieldsAndValues={staticFieldsAndValues}
          ticketFields={ticketFields}
        />
      </div>
      <Divider />
      <div>
        <TicketCustomFields
          customFieldsAndValues={customFieldsAndValues}
          setCustomFieldsAndValues={values => setCustomFieldsAndValues(values)}
          ticketCustomFields={customFieldsResponse.custom_fields.map(field => {
            const type =
              field.type === 'sing_select' || field.type === 'reference'
                ? 'tagger'
                : 'text';
            return {
              custom_field_options:
                field.field_options?.map(option => ({
                  name: option.label,
                  value: option.value,
                })) || [],
              id: parseInt(field.id, 10),
              title: field.title,
              type,
            };
          })}
          title='Update Custom Ticket Fields'
        />
      </div>
      <Divider />
      <Box display='flex' gap={1} justifyContent='flex-end'>
        <Button onClick={handleCancel} variant='secondary'>
          Cancel
        </Button>
        <Button
          aria-label='save ticket update step'
          onClick={() =>
            handleSave({
              custom_fields_and_values: customFieldsAndValues,
              static_fields_and_values: staticFieldsAndValues,
              ticket_tags: tags,
            })
          }
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
