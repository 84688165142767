import React from 'react';
import { FormProps } from '@rjsf/core';

import { Typography } from '@forethought-technologies/forethought-elements';
import { FormButtonGroup, FormHeader, FormWrapper } from './styles';
import JSONSchemaForm from 'src/components/json-schema-form';
import { Credentials } from 'src/services/apiInterfaces';

interface IntegrationFormProps extends FormProps<Credentials> {
  formButtons: React.ReactNode;
}

export default function IntegrationForm({
  formButtons,
  schema,
  ...rest
}: IntegrationFormProps) {
  const hasFormFields = !!Object.keys(schema?.properties || {}).length;

  return (
    <FormWrapper>
      {hasFormFields && (
        <FormHeader>
          <Typography variant='font16Bold'>General Preferences</Typography>
        </FormHeader>
      )}
      <JSONSchemaForm schema={schema} {...rest}>
        <FormButtonGroup>{formButtons}</FormButtonGroup>
      </JSONSchemaForm>
    </FormWrapper>
  );
}
