import { ConfigurationPageTabs } from './types';

export const configurationTabs: ConfigurationPageTabs = [
  'Customization',
  'Proactive prompt',
  'Entry flow',
  'Context variables',
  'Code snippet',
  'Email',
];

export const SELECT_DEFUALT_OPTION = 'select_default_option';
