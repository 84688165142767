import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material/styles';

import {
  NUMBER_OF_BUCKETS,
  RANGE_SIZE_MULTIPLIER,
} from 'src/constants/discover';

interface HistogramGraphProps {
  data: number[];
  leftEdgeSelection: number;
  maxRangeValue: number;
  rightEdgeSelection: number;
}

const HistogramGraph = ({
  data,
  leftEdgeSelection,
  maxRangeValue,
  rightEdgeSelection,
}: HistogramGraphProps) => {
  const theme = useTheme();
  const highChartRef = React.createRef<HighchartsReact.RefObject>();
  const convertToNormalizedValue = (rangeValue: number) => {
    /**
     * convert large range (for slider smoothness back to normalized value)
     * to be used as index for highlighting graph
     * Example:
     * # of buckets: 10
     * Range of slider: 0 - 500, normalized is 0 - 10 (range in actual values)
     * When the slider range chosen is from 100 - 300
     * Normalized value will be 1 - 3 so values within this range [1 - 3) which will be highlighted
     */
    let result = rangeValue / RANGE_SIZE_MULTIPLIER;
    result /= maxRangeValue / (RANGE_SIZE_MULTIPLIER * NUMBER_OF_BUCKETS);
    return result;
  };

  const normalizedLeftEdgeSelection =
    convertToNormalizedValue(leftEdgeSelection);
  const normalizedRightEdgeSelection =
    convertToNormalizedValue(rightEdgeSelection);

  const generateGraphData = data.map((yValue, i) => {
    const color =
      i + 1 > normalizedLeftEdgeSelection && i < normalizedRightEdgeSelection
        ? theme.palette.colors.grey[300]
        : theme.palette.colors.grey[100];
    return {
      color: color,
      // Setting y value to null for 0 values, for minPointLength to be ignored
      y: yValue === 0 ? null : yValue,
    };
  });

  const options = {
    chart: {
      /**
       * allowMutatingData
       * chart mutates data points by default for optimizations
       * this is an issue because mutating data will not be detected by react to rerender
       * The only issue turning this off is related to speed which has no issues with smaller datasets
       */
      allowMutatingData: false,
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    legend: { enabled: false },
    marginLeft: 0,
    marginRight: 0,
    plotOptions: {
      column: {
        minPointLength: 5,
      },
      series: {
        groupPadding: 0,
        pointPadding: 0.05,
      },
    },
    series: [
      {
        data: generateGraphData,
        states: {
          hover: {
            enabled: false,
          },
        },
        type: 'column',
      },
    ],
    title: {
      text: '',
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      categories: [],
      crosshair: {
        color: 'transparent',
        width: 0,
      },
      labels: {
        enabled: false,
      },
      lineWidth: 0,
      title: { text: '' },
    },
    yAxis: {
      gridLineColor: 'transparent',
      labels: {
        enabled: false,
      },
      title: { text: '' },
    },
  };

  useEffect(() => {
    if (data) {
      const chart = highChartRef?.current?.chart;
      // There seems to be an issue where it will not update correctly unless setting the data to empty
      // Redraw does not work when user is interacting without this change
      chart?.series[0].setData([]);
      chart?.series[0].setData(generateGraphData);
    }
  }, [highChartRef, generateGraphData, data]);

  return (
    <HighchartsReact
      containerProps={{ style: { height: '90px', marginBottom: '-29px' } }}
      highcharts={Highcharts}
      options={options}
      ref={highChartRef}
    />
  );
};

export default HistogramGraph;
