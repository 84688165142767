import { styled } from '@mui/material/styles';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import assistAutomationInternalOnlyIcon from 'src/assets/images/assist-automation-internal-only-icon.svg';
import HtmlComponent from 'src/components/html-component/HtmlComponent';

const InternalHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: ${theme.palette.colors.slate[100]};
  border-radius: 4px 4px 0px 0px;
`;

const TextWrapper = styled('div')`
  border: 1px solid ${theme.palette.colors.slate[200]};
  border-radius: 0px 0px 4px 4px;
  border-top: 0;
  padding: 16px;
  margin-bottom: 25px;
`;

interface InternalMessageProps {
  isInternal?: boolean;
  text?: string;
}

export default function InternalMessage({ text }: InternalMessageProps) {
  if (!text) {
    return null;
  }

  return (
    <>
      <InternalHeader>
        <Typography variant='font11'>Internal Only</Typography>

        <img src={assistAutomationInternalOnlyIcon} />
      </InternalHeader>
      <TextWrapper>
        <Typography variant='font12'>
          <HtmlComponent content={text} />
        </Typography>
      </TextWrapper>
    </>
  );
}
