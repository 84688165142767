import { Navigate, useLocation, useParams } from 'react-router-dom';

import { BASE_LOOKER_PATH } from './sidebar/constants';

export function AnalyticsRedirect() {
  const { dashboardId } = useParams();

  return <Navigate replace to={BASE_LOOKER_PATH + dashboardId} />;
}

interface NavigateAndMergeQueryParamsProps {
  newParams: Record<string, string>;
  to: string;
}

export const NavigateAndMergeQueryParams = ({
  newParams,
  to,
}: NavigateAndMergeQueryParamsProps) => {
  const location = useLocation();
  const currentParams = new URLSearchParams(location.search);
  const newURLParams = new URLSearchParams(newParams);

  // Merge the existing params with the new params
  for (const [key, value] of newURLParams.entries()) {
    currentParams.set(key, value);
  }

  return <Navigate replace to={`${to}?${currentParams.toString()}`} />;
};
