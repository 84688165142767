import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import store from 'src/store/store';
import { DiscoverAutomationsByTypeResponse } from 'src/types/DiscoverTypes';

const getTopicsByIntentWorkflow = (intentWorkflowId: string) => {
  const state = store.getState();

  const dicoverAutomations: DiscoverAutomationsByTypeResponse[] =
    state.data.automations.data;

  let discoverTopics: Array<DiscoverTopic> = [];

  const solveWorkflowAutomations = dicoverAutomations.find(
    automation => automation.type === 'solve_workflow',
  );

  solveWorkflowAutomations?.automations.forEach(automation => {
    if (automation.automated_object.automated_object_id === intentWorkflowId) {
      discoverTopics = discoverTopics.concat(automation.topics);
    }
  });

  return discoverTopics;
};

export const getTopicIds = (topics: Array<DiscoverTopic>) => {
  return topics.map(topic => topic.topic_id);
};

export const getTopicIdsFromWorkflowId = (intentWorkflowId: string) => {
  const topics = getTopicsByIntentWorkflow(intentWorkflowId);
  return getTopicIds(topics);
};

export const hasElement = (
  htmlString: string,
  elementSelector: string,
): boolean => {
  try {
    return (
      new DOMParser()
        .parseFromString(htmlString, 'text/html')
        .querySelector(elementSelector) !== null
    );
  } catch {
    return false;
  }
};
