import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import profileBot from '../assets/icons/profile-bot.svg';
import { CanvasModes } from '../constants';
import TextStepEditor from '../Input/TextStepEditor';
import {
  ConditionStep,
  CsatTriggerPointStep,
  FormStep,
  OptionsStep,
} from '../workflow-builder/revamped';
import AttachmentAnalysisUploadStep from '../workflow-builder/revamped/AttachmentAnalysisUploadStep';
import EmbedStep from '../workflow-builder/revamped/EmbedStep';
import { selectMode } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectIsSqueezingStep,
  selectreadArticleActionDialogId,
  setIsSqueezingStep as setIsSqueezingStepActionCreator,
  setShouldSqueezeIntoEntry as setShouldSqueezeIntoEntryActionCreator,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { useAppDispatch } from 'src/store/hooks';

interface BaseOutlineProps {
  isEntryStep?: boolean;
  isPositionedLast?: boolean;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
}

export const BaseOutline: FC<React.PropsWithChildren<BaseOutlineProps>> = ({
  isEntryStep = false,
  isPositionedLast = false,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const mode = useSelector(selectMode);
  const readArticleActionDialogId = useSelector(
    selectreadArticleActionDialogId,
  );
  const isSqueezingStep = useSelector(selectIsSqueezingStep);
  const setIsSqueezingStep = useCallback(
    (newValue: boolean) => {
      dispatch(setIsSqueezingStepActionCreator(newValue));
    },
    [dispatch],
  );
  const setShouldSqueezeIntoEntry = useCallback(
    (newValue: boolean) => {
      dispatch(setShouldSqueezeIntoEntryActionCreator(newValue));
    },
    [dispatch],
  );

  useEffect(() => {
    // TODO: Workraround to solve timing issue that breaks scrolling on page load.
    setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth' }), 500);
  }, []);

  const renderComponentByStepType = () => {
    if (readArticleActionDialogId) {
      return null;
    }

    switch (mode) {
      case CanvasModes.BUTTON:
        return <OptionsStep />;
      case CanvasModes.FORM:
        return <FormStep />;
      case CanvasModes.EMBED:
        return <EmbedStep />;
      case CanvasModes.CONDITION:
        return <ConditionStep />;
      case CanvasModes.CSAT_TRIGGER_POINT:
        return <CsatTriggerPointStep />;
      case CanvasModes.ATTACHMENT_ANALYSIS_UPLOAD:
        return <AttachmentAnalysisUploadStep />;
      default:
        return (
          <>
            <Image src={profileBot} />
            <TextStepEditor
              autoFocus={!isPositionedLast}
              isSqueezingStep={isSqueezingStep}
              setIsSqueezingStep={setIsSqueezingStep}
              setShouldSqueezeIntoEntry={setShouldSqueezeIntoEntry}
              shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
              squeezeStepParentId={squeezeStepParentId}
            />
          </>
        );
    }
  };

  return (
    <>
      <OutlineChat
        data-testid='chat-outline'
        id='chat-outline'
        isEntryStep={isEntryStep}
        isPositionedLast={isPositionedLast}
        isRevampedStep={mode !== CanvasModes.MESSAGE}
        ref={ref}
      >
        {renderComponentByStepType()}
      </OutlineChat>
    </>
  );
};
const OutlineChat = styled('div')<{
  isEntryStep?: boolean;
  isPositionedLast?: boolean;
  isRevampedStep?: boolean;
}>`
  display: flex;
  margin: ${props => {
    if (props.isPositionedLast) {
      return '20px 0 10px 0';
    }
    if (props.isEntryStep) {
      return '10px 0 0 0';
    }
    return '20px 0 0 0';
  }};
  padding: 0 20px 0 20px;

  ${props => props.isRevampedStep && 'padding: 0; margin: 0;'}
`;

const Image = styled('img')`
  width: 32px;
  height: 32px;
  margin-right: 8px;
`;
