import { Method } from 'src/types/actionBuilderApiTypes';

export const methodOptions: { label: Method; value: Method }[] = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
  { label: 'PUT', value: 'PUT' },
  { label: 'DELETE', value: 'DELETE' },
  { label: 'PATCH', value: 'PATCH' },
];

export const a11yProps = (index: number) => {
  return {
    'aria-controls': `action-builder-method-tabpanel-${index}`,
    id: `action-builder-method-tab-${index}`,
  };
};
