import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectDiscoverAutomations } from 'src/slices/data/dataSlice';
import { getDiscoverAutomations } from 'src/slices/data/thunks';
import { useAppDispatch } from 'src/store/hooks';

export const useGetAutomations = () => {
  const dispatch = useAppDispatch();
  const automations = useSelector(selectDiscoverAutomations);

  useEffect(() => {
    dispatch(getDiscoverAutomations());
  }, [dispatch]);

  return automations;
};
