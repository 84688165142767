import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Tabs } from '@forethought-technologies/forethought-elements';
import ContentHeader from '../ContentHeader';
import EditTranslationPhrasesTab from './EditTranslationPhrasesTab';
import {
  translationChannelToConversationChannel,
  translationChannelToLabelMap,
} from './helpers';
import {
  getisInitialStateEmailValidation,
  useGetTranslationsChangedData,
  useUpdateEnabledLanguages,
} from './hooks';
import SelectLanguagesTab from './SelectLanguagesTab';
import {
  overrideConfigurationTranslations,
  overrideTranslations,
  overrideTranslationsForStep,
  setNetworkError,
  setTranslationsTableMode,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import ToggleButtonGroup from 'src/components/toggle-button-group';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { selectIsLoading } from 'src/reducers/dataDeprecatedReducer/dataDeprecatedReducer';
import {
  selectDraftTranslations,
  selectIntentWorkflowId,
  selectStepLevelTranslationsId,
  selectStepTranslations,
  selectTranslationsTableMode,
  selectWorkflowBuilderLanguages,
  selectWorkflowBuilderLanguagesEnabled,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useUpdateManualTranslationMutation } from 'src/services/solve-email-multilingual/solveEmailMultilingualApi';
import { selectEmailTranslations } from 'src/slices/email-translations/emailTranslationsSlice';
import {
  isStepTranslation,
  OverrideConfigurationTranslationRequest,
  OverrideTranslationRequest,
  TranslationChannel,
} from 'src/types/workflowBuilderAPITypes';
import { TranslationsTableMode } from 'src/utils/enums';

interface TranslationsPageProps {
  channels: TranslationChannel[];
  channelTabIndex: number;
  isOnNavbar?: boolean;
  setChannelTabIndex: (channelTabIndex: number) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

const TranslationsPage: React.FC<
  React.PropsWithChildren<TranslationsPageProps>
> = ({
  channels,
  channelTabIndex,
  isOnNavbar = false,
  setChannelTabIndex,
  setHasUnsavedChanges,
}) => {
  const dispatch = useDispatch();

  // Selectors
  const languagesConfig = useSelector(selectWorkflowBuilderLanguages);
  const languagesEnabledConfig = useSelector(
    selectWorkflowBuilderLanguagesEnabled,
  );
  const translationsConfig = useSelector(selectStepTranslations);
  const draftTranslations = useSelector(selectDraftTranslations);
  const isLoading = useSelector(selectIsLoading);
  const currentWorkflowId = useSelector(selectIntentWorkflowId);
  const stepLevelTranslationsId = useSelector(selectStepLevelTranslationsId);
  const tableMode = useSelector(selectTranslationsTableMode);
  const emailTranslations = useSelector(selectEmailTranslations);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const [updateManualEmailTranslation] = useUpdateManualTranslationMutation();
  const { feature_flags: enabledFeatureFlags = [] } = featureFlagsData ?? {};
  const isSolveEmailTranslationEnabled = enabledFeatureFlags?.includes(
    'solve_email_translation_enabled',
  );
  const isStepLevelMode = stepLevelTranslationsId !== '';

  // States for select language tab
  const [languagesEnabled, setLanguagesEnabled] = useState({
    language_codes: languagesEnabledConfig
      ? languagesEnabledConfig.language_codes
      : [],
  });
  // States for edit translation phrases tab
  const [selectedLanguage, setSelectedLanguage] = useState(
    languagesEnabledConfig.language_codes[0] || '',
  );
  // States for determine if anything has been modified by admin
  const [isLanguageSelectedUnchanged, setIsLanguageSelectedUnchanged] =
    useState(true);
  // States for confirmation modal
  const [deselectedLanguages, setDeselectedLanguages] = useState<string[]>([]);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);

  const conversationChannel = translationChannelToConversationChannel(
    channels[channelTabIndex],
  );

  const channelTabLabels = channels.map(
    channel => translationChannelToLabelMap[channel],
  );

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: true,
  });

  const { hasEmptyOverride, isTranslationPhrasesUnchanged } =
    useGetTranslationsChangedData(conversationChannel);

  const isInitialState =
    isLanguageSelectedUnchanged && isTranslationPhrasesUnchanged;

  const shouldDisplayModeNavbar =
    conversationChannel === 'email' ||
    (conversationChannel === 'widget' && !isStepLevelMode);

  const updateEnabledLanguages = useUpdateEnabledLanguages();

  useEffect(() => {
    setHasUnsavedChanges(!isInitialState);
  }, [setHasUnsavedChanges, isInitialState]);

  useEffect(() => {
    setLanguagesEnabled(languagesEnabledConfig);
    setSelectedLanguage(languagesEnabledConfig.language_codes[0] || '');
  }, [channelTabIndex, languagesEnabledConfig]);

  const handleEmailSave = () => {
    const newTranslations = emailTranslations.filter(translation => {
      return !getisInitialStateEmailValidation(translation);
    });

    const requestFormatTranslations = newTranslations.map(translation => ({
      email_translation_id: translation.email_translation_id,
      manual_translation: translation.current_translation,
      modified_date: translation.modified_date,
    }));

    if (requestFormatTranslations.length) {
      updateManualEmailTranslation({
        manualTranslationList: requestFormatTranslations,
        mode: tableMode,
        targetLanguage: selectedLanguage,
      })
        .unwrap()
        .catch(e => {
          const errorType = e?.data?.error_type ?? '';
          dispatch(setNetworkError(errorType));
        });
    }
  };

  const handleWidgetSave = () => {
    const translationsChanged = draftTranslations.filter(
      (translation, index) =>
        translation.target_text !== translationsConfig[index].target_text,
    );
    const overrideRequests: OverrideTranslationRequest[] = [];
    const overrideConfigurationRequests: OverrideConfigurationTranslationRequest[] =
      [];

    translationsChanged.forEach(translation => {
      if (isStepTranslation(translation)) {
        overrideRequests.push({
          element_path: translation.element_path,
          intent_workflow_id: translation.intent_workflow_id,
          modified_date: translation.modified_date,
          step_id: translation.step_id,
          target_text: translation.target_text,
        });
      } else if (translation.bucket_id === 'Workflow tag') {
        overrideConfigurationRequests.push({
          configuration_id: translation.configuration_id,
          modified_date: translation.modified_date,
          source_text: translation.source_text,
          target_text: translation.target_text,
        });
      } else {
        overrideConfigurationRequests.push({
          configuration_id: translation.configuration_id,
          configuration_path: translation.configuration_path,
          intent_id: translation.intent_id,
          modified_date: translation.modified_date,
          target_text: translation.target_text,
        });
      }
    });
    if (overrideRequests.length > 0) {
      if (isStepLevelMode) {
        dispatch(
          overrideTranslationsForStep(
            overrideRequests,
            selectedLanguage,
            currentWorkflowId || '',
            stepLevelTranslationsId,
          ),
        );
      } else {
        dispatch(
          overrideTranslations(overrideRequests, selectedLanguage, tableMode),
        );
      }
    }
    if (overrideConfigurationRequests.length > 0) {
      dispatch(
        overrideConfigurationTranslations(
          overrideConfigurationRequests,
          selectedLanguage,
        ),
      );
    }
  };

  // Handlers

  const handleUpdateEnabledLanguages = async () => {
    const currentLanguageCodes = languagesEnabled.language_codes;

    const isSelectedLanguageRemoved =
      !!currentLanguageCodes.length &&
      !currentLanguageCodes.includes(selectedLanguage);

    const requestData = {
      ...languagesEnabled,
      channel: conversationChannel,
      modified_date: languagesEnabledConfig.modified_date,
    };

    updateEnabledLanguages(
      requestData,
      conversationChannel,
      isSelectedLanguageRemoved,
    );
  };

  const handleSave = async () => {
    const sourceLanguageCodes = languagesEnabledConfig.language_codes;
    const currentLanguageCodes = languagesEnabled.language_codes;
    const isLanguageCodesInitialState =
      sourceLanguageCodes.length === currentLanguageCodes.length &&
      sourceLanguageCodes.every(languageCode =>
        currentLanguageCodes.includes(languageCode),
      );

    if (!isLanguageCodesInitialState) {
      handleUpdateEnabledLanguages();
    }

    if (conversationChannel === 'email' && isSolveEmailTranslationEnabled) {
      handleEmailSave();
    } else {
      handleWidgetSave();
    }
  };

  // handlers
  const handleChangeMode = (newMode: TranslationsTableMode) => {
    dispatch(setTranslationsTableMode(newMode));
  };

  return (
    <Container>
      <Box
        sx={{
          paddingRight: isOnNavbar ? '68px' : '0px',
          paddingTop: isOnNavbar ? '5px' : '0px',
        }}
      >
        <ContentHeader
          buttonDataAttribute={{
            'data-appcues-target': 'TranslationsPageSaveButton',
          }}
          componentsInTheMiddle={
            shouldDisplayModeNavbar && (
              <>
                <ToggleButtonGroup
                  alignment={tableMode}
                  items={[
                    { label: 'Live', value: TranslationsTableMode.LIVE },
                    { label: 'Draft', value: TranslationsTableMode.DRAFT },
                  ]}
                  onChange={newAlignment => {
                    if (newAlignment) {
                      handleChangeMode(newAlignment);
                    }
                  }}
                  tooltips={[
                    'The live section will contain the translations that are currently being used in your workflow. Changes made here will be reflected in your workflow immediately.',
                    'The draft section will contain the translations corresponding to any existing draft workflows. Changes made here will not be reflected until the draft workflow is published',
                  ]}
                />
              </>
            )
          }
          isButtonDisabled={isInitialState || hasEmptyOverride}
          isButtonLoading={isLoading}
          onClickHandler={() => {
            const newDeselectedLanguages: string[] = [];
            languagesEnabledConfig.language_codes.forEach(lanCode => {
              if (!languagesEnabled.language_codes.includes(lanCode)) {
                newDeselectedLanguages.push(lanCode);
              }
            });
            if (newDeselectedLanguages.length > 0) {
              setDeselectedLanguages(newDeselectedLanguages);
              setIsConfirmationModalVisible(true);
            } else {
              handleSave();
            }
          }}
          title='Translations'
        />
      </Box>
      {isSolveEmailTranslationEnabled && (
        <Box>
          <Tabs
            a11yProps={index => {
              return {
                'aria-controls': `translations-tabpanel-${index}`,
                id: `translations-tab-${index}`,
              };
            }}
            aria-label='Translations channel tabs'
            ContainerProps={{
              mt: 3,
            }}
            onChange={(_, value) => setChannelTabIndex(value)}
            tabs={channelTabLabels}
            typographyVariant='font16Bold'
            value={channelTabIndex}
          />
        </Box>
      )}
      <MainContentContainer>
        <ConfigsContainer>
          {!isStepLevelMode && (
            <SelectLanguagesTab
              languagesEnabled={languagesEnabled.language_codes}
              setIsLanguageSelectedUnchanged={setIsLanguageSelectedUnchanged}
              setLanguagesEnabled={setLanguagesEnabled}
            />
          )}
          {contextVariables.length > 0 && (
            <EditTranslationPhrasesTab
              isTranslationPhrasesUnchanged={isTranslationPhrasesUnchanged}
              // key is necessary here, as if a context variable is added or deleted,
              // an endless loop could occur with the `useTable` hook inside this component
              // the key ensures the component will rerender safely if a cv is added or deleted
              key={
                contextVariables[contextVariables.length - 1]
                  ?.context_variable_id + String(contextVariables.length)
              }
              selectedChannelLabel={channels[channelTabIndex]}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          )}
        </ConfigsContainer>
      </MainContentContainer>
      <Dialog
        footer={
          <>
            <Button
              onClick={() => {
                setIsConfirmationModalVisible(false);
                setLanguagesEnabled({
                  language_codes: [
                    ...languagesEnabled.language_codes,
                    ...deselectedLanguages,
                  ],
                });
              }}
              variant='ghost'
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsConfirmationModalVisible(false);
                handleSave();
              }}
              variant='secondary'
            >
              OK
            </Button>
          </>
        }
        hideCloseButton
        onClose={() => setIsConfirmationModalVisible(false)}
        open={isConfirmationModalVisible}
        title='Are you sure?'
      >
        <Typography variant='font14'>
          The following languages will be disabled (edited translation phrases
          will be saved):
          <ul>
            {deselectedLanguages
              .map(
                lanCode =>
                  languagesConfig.find(language => lanCode === language.code)
                    ?.display_name || '',
              )
              .map(lan => (
                <li key={lan}>{lan}</li>
              ))}
          </ul>
        </Typography>
      </Dialog>
    </Container>
  );
};

export default TranslationsPage;

const Container = styled('div')`
  width: 100%;
  height: 100%;
`;

const MainContentContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-top: 30px;
`;

const ConfigsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-right: 48px;
`;
