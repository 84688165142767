import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import StepContainer from './workflow-builder/revamped/StepContainer';
import StepHeader from './workflow-builder/revamped/StepHeader';
import TextBox from './workflow-builder/revamped/TextBox';
import BranchStepValidIndicator from './BranchStepValidIndicator';
import UndefinedContextVariablesTooltip from './UndefinedContextVariablesTooltip';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  selectCanvasWorkflowBuilder,
  selectEditingActionId,
  selectSelectedConditions,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { setSelectedCondition } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { StepTypes } from 'src/utils/enums';

interface GranularArticleSuggestionV2StepProps {
  actionId: string;
  allStepsWithinAction: string[];
  isCanvasDisabled: boolean;
  stepId?: string;
  steps?: string[];
}

export const GranularArticleSuggestionV2Step: React.FC<
  React.PropsWithChildren<GranularArticleSuggestionV2StepProps>
> = ({ actionId, allStepsWithinAction, stepId, steps }) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const dispatch = useDispatch();
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const selectedConditions = useSelector(selectSelectedConditions);
  const editingActionId = useSelector(selectEditingActionId);

  const conditionStepId =
    allStepsWithinAction.find(
      stepId => canvasData.steps[stepId].step_type === StepTypes.CONDITION,
    ) || '';

  const conditionStep = canvasData.steps[conditionStepId];

  return (
    <StepContainer isBeingEdited={actionId === editingActionId}>
      <StepHeader stepType={StepTypes.ARTICLE_SUGGESTION}>
        <Box ml='auto' mr={2}>
          <UndefinedContextVariablesTooltip
            contextVariables={contextVariables}
            stepId={stepId ?? ''}
            steps={steps}
          />
        </Box>
      </StepHeader>
      <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='font16Bold'>Article Suggestion (step)</Typography>
        {['If article is suggested', 'If article is not suggested'].map(
          (option, index) => (
            <TextBox
              index={index}
              isSelected={selectedConditions[conditionStepId] === index}
              key={option}
              onClick={() => {
                dispatch(
                  setSelectedCondition({
                    conditionIndex: index,
                    stepId: conditionStepId,
                  }),
                );
              }}
              validIndicator={
                <BranchStepValidIndicator
                  transitionBranchStepId={
                    conditionStep.transitions[index].step_id
                  }
                  undefinedTransitionCvs={[]}
                />
              }
              value={option}
            />
          ),
        )}
      </Box>
    </StepContainer>
  );
};
