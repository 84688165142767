import React, { Dispatch, FC, SetStateAction } from 'react';

import { Checkbox } from '@forethought-technologies/forethought-elements';
import set from 'lodash/fp/set';
import { SalesforceCommonCustomization } from 'src/types/workflowBuilderAPITypes';

interface MarkAsNotDeflectedProps {
  customizationData: SalesforceCommonCustomization;
  setCustomizationData: Dispatch<SetStateAction<SalesforceCommonCustomization>>;
}
const MarkAsNotDeflected: FC<
  React.PropsWithChildren<MarkAsNotDeflectedProps>
> = ({ customizationData, setCustomizationData }) => {
  return (
    <Checkbox
      checked={
        !!customizationData.ticket_creation_settings?.ticket_creation_config
          ?.update_flamethrower_conversation_deflection_status
      }
      label='Mark Conversation as Not Deflected'
      /* Update the deflection status value in handoff data and return the updated object */
      onChange={() =>
        setCustomizationData(handoffData => {
          return set(
            [
              'ticket_creation_settings',
              'ticket_creation_config',
              'update_flamethrower_conversation_deflection_status',
            ],
            !handoffData.ticket_creation_settings?.ticket_creation_config
              ?.update_flamethrower_conversation_deflection_status,
            handoffData,
          );
        })
      }
    />
  );
};

export default MarkAsNotDeflected;
