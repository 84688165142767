import { JSONSchema7 } from 'json-schema';
import { WidgetProps } from '@rjsf/utils';

import {
  PasswordField,
  TextField,
} from '@forethought-technologies/forethought-elements';

interface TextWidgetProps extends WidgetProps {
  schema: JSONSchema7 & {
    is_masked?: boolean;
    is_required_public_dashboard?: boolean;
  };
}

export default function TextWidget({
  label,
  name,
  onChange,
  required,
  schema,
  value,
  ...rest
}: TextWidgetProps) {
  const Component = schema.is_masked ? PasswordField : TextField;

  return (
    // @ts-expect-error rjsf's `label` prop is incompatible with our type
    <Component
      // Don't show label if it's autogenerated:
      label={label === name ? '' : label}
      onChange={e => onChange(e.target.value)}
      required={!!(required || schema.is_required_public_dashboard)}
      value={value || ''}
      {...rest}
    />
  );
}
