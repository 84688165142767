import Box from '@mui/material/Box';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';

export const SelectableOptions = ({
  message,
  minOptions = 3,
  onAddOption,
  onEditOption,
  onRemoveOption,
  options,
}: {
  message: string;
  minOptions?: number;
  onAddOption: () => void;
  onEditOption: (index: number, newVal: string) => void;
  onRemoveOption: (index: number) => void;
  options: string[];
}) => {
  return (
    <Box display='flex' flexDirection='column' gap='8px'>
      <Typography variant='font14'>{message}</Typography>
      {options.map((option, index) => {
        return (
          <Box display='flex' gap='8px' key={index}>
            <TextField
              aria-label={`Option number ${index + 1} for ${message}`}
              onChange={e => {
                onEditOption(index, e.target.value);
              }}
              placeholder='Please enter a value'
              value={option}
            />
            <IconButton
              aria-label={`Remove option number ${index + 1} for ${message}`}
              disabled={options.length <= minOptions}
              onClick={() => onRemoveOption(index)}
              variant='ghost'
            >
              <IconTrash size={20} />
            </IconButton>
          </Box>
        );
      })}
      <Box alignSelf='flex-start'>
        <Button
          onClick={onAddOption}
          startIcon={<IconPlus size={20} />}
          variant='ghost'
        >
          Add Option
        </Button>
      </Box>
    </Box>
  );
};
