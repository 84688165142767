import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  SelectDropdown,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  HTTPS_INVALID_URL_MESSAGE,
  VALID_KNOWLEDGE_URL_WITH_HTTPS_REGEX,
} from '../../../constants/solve';
import {
  ADD_CUSTOM_URL_BUTTON_TEXT,
  CUSTOM_PUBLIC_CONNECTOR_TYPE,
  REQUEST_FORM_URL,
} from '../constants';
import ExcludePrivateArticlesCheckbox from './ExcludePrivateArticlesCheckbox';
import NotificationStatus from './NotificationStatus';
import { getActionBuilderActiveIntegrationsSettings } from 'src/actions/action-builder-actions/actionBuilderActions';
import { useSolveConfigTrackingEventAction } from 'src/hooks/hooks';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import { KNOWLEDGE_IN_PROGRESS_STATUS } from 'src/slices/solve-config/constants';
import {
  selectKnowledgeConfiguration,
  updateKnowledgeConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';

const KnowledgeBase = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const dispatchTrackingAction = useSolveConfigTrackingEventAction();
  const knowledgeConfiguration = useSelector(selectKnowledgeConfiguration);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const [isIntegrationDropdownOpen, setIsIntegrationDropdownOpen] =
    useState(false);
  const [integrationOptions, setIntegrationOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [selectedKnowledge, setSelectedKnowledge] = useState('');
  const [customUrl, setCustomUrl] = useState('');

  const {
    available_knowledge_sources: availableKnowledgeSources,
    selected_knowledge_source: knowledgeSource,
  } = knowledgeConfiguration;

  const customKnowledgeSourceExists = availableKnowledgeSources.some(
    source => source.integration_name === CUSTOM_PUBLIC_CONNECTOR_TYPE,
  );
  const disableAddNewCustomButton =
    customKnowledgeSourceExists ||
    integrationOptions.some(
      option => option.value === ADD_CUSTOM_URL_BUTTON_TEXT,
    );

  const hasExistingConnection = Boolean(
    knowledgeSource?.custom_url ||
      (Boolean(knowledgeSource?.integration_name) &&
        knowledgeSource?.integration_name !== CUSTOM_PUBLIC_CONNECTOR_TYPE),
  );

  const isConnectionBuilding =
    knowledgeSource?.status === KNOWLEDGE_IN_PROGRESS_STATUS;

  const cleanCurrentDomain = customUrl.trim();

  const errorMessage =
    Boolean(cleanCurrentDomain) &&
    !VALID_KNOWLEDGE_URL_WITH_HTTPS_REGEX.test(cleanCurrentDomain) &&
    HTTPS_INVALID_URL_MESSAGE;

  const isSelectedKnowledgeValid =
    Boolean(selectedKnowledge) &&
    selectedKnowledge !== knowledgeSource?.integration_name &&
    selectedKnowledge !== knowledgeSource?.custom_url &&
    selectedKnowledge !== ADD_CUSTOM_URL_BUTTON_TEXT &&
    selectedKnowledge !== CUSTOM_PUBLIC_CONNECTOR_TYPE;

  const isCustomUrlValid =
    Boolean(cleanCurrentDomain) &&
    cleanCurrentDomain !== knowledgeSource?.custom_url &&
    !Boolean(errorMessage);

  const showConnectButton =
    (isSelectedKnowledgeValid || isCustomUrlValid) && !isConnectionBuilding;

  useEffect(() => {
    dispatch(getActionBuilderActiveIntegrationsSettings());
  }, [dispatch]);

  const initialIntegrations = useMemo(() => {
    const optionsFromAvailableKnowledgeArticle = availableKnowledgeSources.map(
      source => {
        if (source.custom_url) {
          return {
            label: `Public URL - ${source.custom_url}`,
            value: source.custom_url,
          };
        }
        return {
          label: source.integration_display_name || source.integration_name,
          value: source.integration_name,
        };
      },
    );
    return optionsFromAvailableKnowledgeArticle;
  }, [availableKnowledgeSources]);

  useEffect(() => {
    setIntegrationOptions(initialIntegrations);
  }, [initialIntegrations]);

  useEffect(() => {
    if (knowledgeSource?.custom_url) {
      setSelectedKnowledge(knowledgeSource?.custom_url);
    } else if (knowledgeSource?.integration_name) {
      setSelectedKnowledge(knowledgeSource?.integration_name);
    }
  }, [knowledgeSource]);

  const handleOnConnectClicked = () => {
    const isCustom =
      VALID_KNOWLEDGE_URL_WITH_HTTPS_REGEX.test(selectedKnowledge) ||
      Boolean(cleanCurrentDomain);
    if (isCustom) {
      const updateKnowledgeSource = {
        custom_url: cleanCurrentDomain || selectedKnowledge,
        integration_name: CUSTOM_PUBLIC_CONNECTOR_TYPE,
      };
      dispatch(updateKnowledgeConfiguration(updateKnowledgeSource));
      dispatchTrackingAction(
        SolveConfigTrackingEventTypes.CONNECT_KNOWLEDGE_BASE,
        { ...updateKnowledgeSource },
      );
    } else {
      const updateKnowledgeSource = {
        integration_name: selectedKnowledge,
      };
      dispatch(updateKnowledgeConfiguration(updateKnowledgeSource));
      dispatchTrackingAction(
        SolveConfigTrackingEventTypes.CONNECT_KNOWLEDGE_BASE,
        { ...updateKnowledgeSource },
      );
    }
    // Reset custom url form
    setCustomUrl('');
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='space-between'
    >
      <Box>
        <Typography variant='font16Bold'>
          Connect with knowledge base
        </Typography>
        <Box pb={3} pt={3}>
          <Button
            fullWidth
            onClick={() => {
              navigate({
                pathname: Routes.INTEGRATIONS,
              });
              dispatchTrackingAction(
                SolveConfigTrackingEventTypes.NAVIGATE_TO_INTEGRATIONS_PAGE,
              );
            }}
            startIcon={<AddIcon fontSize='small' />}
            variant='secondary'
          >
            <Typography variant='font14Bold'>
              Connect new integration
            </Typography>
          </Button>
        </Box>
        <SelectDropdown
          addNewButtonText={
            disableAddNewCustomButton
              ? undefined
              : 'Add a custom URL to connect'
          }
          disabled={
            isConnectionBuilding ||
            knowledgeSource?.status === KNOWLEDGE_IN_PROGRESS_STATUS
          }
          id='knowledge'
          onAddNewClick={
            disableAddNewCustomButton
              ? undefined
              : () => {
                  setIntegrationOptions([
                    ...initialIntegrations,
                    {
                      label: ADD_CUSTOM_URL_BUTTON_TEXT,
                      value: ADD_CUSTOM_URL_BUTTON_TEXT,
                    },
                  ]);
                  setIsIntegrationDropdownOpen(false);
                  dispatchTrackingAction(
                    SolveConfigTrackingEventTypes.ADD_CUSTOM_URL_FOR_KNOWLEDGE_BASE,
                  );
                }
          }
          onChange={({ target }) => {
            // When value is undefined it is onAddNewClick trigger
            setSelectedKnowledge(target.value || ADD_CUSTOM_URL_BUTTON_TEXT);
            setIsIntegrationDropdownOpen(false);
          }}
          onClose={() => setIsIntegrationDropdownOpen(false)}
          onOpen={() => setIsIntegrationDropdownOpen(true)}
          open={isIntegrationDropdownOpen}
          options={integrationOptions}
          placeholder='or select existing from the list'
          value={selectedKnowledge}
        />
        {selectedKnowledge === ADD_CUSTOM_URL_BUTTON_TEXT && (
          <Box pt='18px'>
            <TextField
              disabled={isConnectionBuilding}
              label='Enter your knowledge base URL'
              onChange={({ target }) => setCustomUrl(target.value)}
              placeholder='e.g. https://mytestknowledgebase.ai'
              value={customUrl}
            />
            {Boolean(errorMessage) ? (
              <Typography color={palette.colors.red[500]} variant='font12'>
                {errorMessage}
              </Typography>
            ) : (
              <Typography color={palette.colors.grey[400]} variant='font12'>
                Check the correct URL before connecting to the knowledge base,
                as the connection cannot be undone.
              </Typography>
            )}
          </Box>
        )}
        {showConnectButton && !isConnectionBuilding && (
          <Box pt='18px'>
            <Button
              fullWidth
              onClick={handleOnConnectClicked}
              size='large'
              variant='main'
            >
              <Typography variant='font14Bold'>
                {hasExistingConnection ? 'Replace and connect' : 'Connect'}
              </Typography>
            </Button>
          </Box>
        )}
        {isSolveLiteEnabled &&
          !showConnectButton &&
          knowledgeSource &&
          knowledgeSource.integration_name !== CUSTOM_PUBLIC_CONNECTOR_TYPE && (
            <ExcludePrivateArticlesCheckbox />
          )}
        <NotificationStatus
          isReplaceScenario={showConnectButton && hasExistingConnection}
          knowledgeSource={knowledgeSource}
        />
      </Box>
      {hasExistingConnection && (
        <Box pt={1}>
          <Typography color={palette.colors.grey[700]} variant='font14Medium'>
            To
          </Typography>
          <Typography variant='font14Bold'>
            {' change the knowledge base '}
          </Typography>
          <Typography color={palette.colors.grey[700]} variant='font14Medium'>
            select from the list or create a new one in the Integration. If you
            need help, please{' '}
          </Typography>
          <Typography variant='font14Bold'>
            <a href={REQUEST_FORM_URL} rel='noreferrer' target='_blank'>
              contact us!
            </a>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default KnowledgeBase;
