import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Backdrop, Box, styled, Typography, useTheme } from '@mui/material';

import { IconButton } from '@forethought-technologies/forethought-elements';
import GenerationCheckIcon from 'src/assets/images/generation-check-icon.svg';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import {
  selectPolicyDescription,
  selectWorkflowRecommendation,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { selectDiscoverTopicsByIntentId } from 'src/slices/data/dataSlice';
import { selectIsSidebarExpanded } from 'src/slices/ui/uiSlice';
import { Routes } from 'src/utils/enums';
import { UserRoles } from 'src/utils/enums';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

export const WorkflowRecommendationContent = ({
  showSubtitle = true,
}: {
  showSubtitle?: boolean;
}) => {
  const theme = useTheme();
  const workflowRecommendation = useSelector(selectWorkflowRecommendation);

  if (!workflowRecommendation) {
    return null;
  }

  const conversationIdsQueryParam =
    workflowRecommendation.conversation_ids.join(',');
  const startTime = moment(last30DaysTimeRange.from).unix();
  const endTime = moment(last30DaysTimeRange.to).unix();

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='16px'
      sx={{
        '.MuiTypography-root': {
          whiteSpace: 'pre-wrap',
        },
        // TODO: Make these styles the default for all links on all pages:
        a: {
          textDecoration: 'none',
        },
        'a:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {showSubtitle && (
        <Typography variant='font14'>
          Improve this workflow based on insights
        </Typography>
      )}
      <SummaryBox>
        <Box alignItems='center' display='flex' gap='4px'>
          <img src={GenerationCheckIcon} />
          <Typography variant='font12'>Issue Summary</Typography>
        </Box>
        <Typography color={theme.palette.colors.slate[700]} variant='font14'>
          <HtmlComponent
            content={workflowRecommendation.summary}
            linkifyHtmlOptions={solveLinkifyHtmlOptions}
          />
        </Typography>
      </SummaryBox>
      <Typography variant='font14'>
        <a
          href={`${Routes.WORKFLOW_BUILDER}?header=chats&conversationId=${conversationIdsQueryParam}&channel=widget&end=${endTime}&start=${startTime}`}
          rel='noreferrer'
          target='_blank'
        >
          Chats prompting the issue summary
        </a>
      </Typography>
      <Typography variant='font14'>
        {workflowRecommendation.recommendation}
      </Typography>
    </Box>
  );
};

export const WorkflowDescription = () => {
  // selectors
  const discoverTopics = useSelector(selectDiscoverTopicsByIntentId);
  const policyDescription = useSelector(selectPolicyDescription);
  const isSidebarExpanded = useSelector(selectIsSidebarExpanded);
  const workflowRecommendation = useSelector(selectWorkflowRecommendation);
  const userRole = useSelector(selectUserRole);

  // consts
  const isSuperAdmin = userRole === UserRoles.ROLE_SUPER_ADMIN;

  // states
  const [isOpen, setIsOpen] = useState(false);
  const [offsetTop, setOffsetTop] = useState(134);
  const [offsetLeft, setOffsetLeft] = useState(630);

  // ref
  const containerRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setOffsetTop(node.offsetTop);
      setOffsetLeft(node.offsetLeft);
      setIsOpen(true);
    }
  }, []);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (buttonRef.current !== null) {
      setOffsetTop(buttonRef.current.offsetTop);
      setOffsetLeft(buttonRef.current.offsetLeft);
    }
  }, [isSidebarExpanded, isOpen]);

  const shouldShowPolicyDescription =
    discoverTopics.length > 0 && policyDescription;
  const shouldShowWorkflowRecommendation =
    isSuperAdmin && workflowRecommendation;
  if (!shouldShowPolicyDescription && !shouldShowWorkflowRecommendation) {
    return null;
  }

  const descriptionContent = shouldShowWorkflowRecommendation ? (
    <WorkflowRecommendationContent showSubtitle={false} />
  ) : (
    <Typography
      component='div'
      dangerouslySetInnerHTML={{ __html: policyDescription || '' }}
      sx={{ whiteSpace: 'pre-wrap' }}
      variant='font14'
    />
  );

  return (
    <div ref={containerRef}>
      <div ref={buttonRef}>
        <IconButton
          aria-label='workflow description button'
          onClick={() => {
            setIsOpen(prev => !prev);
          }}
          variant='ghost'
        >
          <img src={SupportGPTIcon} />
        </IconButton>
      </div>
      {isOpen && (
        <Backdrop
          invisible={true}
          onClick={() => {
            setIsOpen(false);
          }}
          open={isOpen}
          sx={{ zIndex: 199 }}
        >
          <WindowWrapper
            left={offsetLeft}
            onClick={e => {
              e.stopPropagation();
            }}
            top={offsetTop}
          >
            <Container>
              <Header>
                <img src={SupportGPTIcon} />
                <Typography variant='font14Bold'>
                  {workflowRecommendation
                    ? 'Improve this workflow based on insights'
                    : 'Workflow Scenario based on your data insights'}
                </Typography>
              </Header>
              <Content>{descriptionContent}</Content>
            </Container>
          </WindowWrapper>
        </Backdrop>
      )}
    </div>
  );
};

const WindowWrapper = styled('div')<{
  left?: number;
  top?: number;
}>`
  position: absolute;
  z-index: 200;
  top: ${props => (props.top ? `${props.top - 10}px` : '144px')};
  left: ${props => (props.left ? `${props.left + 60}px` : '690px')};
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.colors.white};
  width: 432px;
  box-shadow: 0px 0px 1px rgba(0, 12, 32, 0.02),
    2px 8px 16px rgba(3, 17, 38, 0.107135);
  border: 1px solid ${props => props.theme.palette.colors.purple[500]};
  border-radius: 8px;
  overflow: hidden;
`;

const Header = styled('div')`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fafafa;
  border-radius: 8px 8px 0 0;
  height: 52px;
`;

const Content = styled(Box)`
  padding: 24px;
  max-height: 550px;
  overflow-y: scroll;
`;

const SummaryBox = styled('div')`
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.colors.purple[500]};
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
