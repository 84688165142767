import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import { styled } from '@mui/material';

import Tooltip from 'src/components/reusable-components/tooltip/tooltip';
import {
  ErrorMessage,
  Label,
  Required,
  Row,
  Spacer,
  TextArea,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';

const HandoffFormQuestionMessage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <Row>
        <Label>Handoff Form Question Message</Label>
        <TooltipWrapper>
          <Tooltip
            tooltipText={`Form fields include name, email, and question by default. 
                          Additional variables specified in the below fields are also automatically added.`}
          />
        </TooltipWrapper>
        <Spacer width='4px' />
        <Required>Required</Required>
      </Row>
      <Field name='ticket_creation_settings.ticket_creation_config.handoff_form_question_message'>
        {({ field, meta }: FieldProps) => (
          <>
            <TextArea
              hasError={!!meta.error}
              placeholder='Add question'
              {...field}
            />
            {meta.touched && meta.error && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </Field>
    </>
  );
};

const TooltipWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export default HandoffFormQuestionMessage;
