import { Dispatch } from '@reduxjs/toolkit';

import { FetchAction } from '../types/types';
import { setDashboardApplicationsByRole } from './user';
import { getOrgIdAndOrgCode, getPermission } from 'src/services/api';

export const getPermissionInfo =
  () =>
  async (dispatch: Dispatch): Promise<void | FetchAction> => {
    const permissions = await getPermission();
    dispatch(setDashboardApplicationsByRole(permissions));
    return permissions;
  };

export const getOrgIdInfo: Function = () => (): Promise<any> => {
  return getOrgIdAndOrgCode();
};
