import { styled } from '@mui/material';

import {
  Button,
  Dialog,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useWorkflowInvalidMessages } from './hooks';
import warningIcon from 'src/assets/images/circular-warning-icon.svg';

interface WorkflowInvalidModalProps {
  onClose: () => void;
}

function WorkflowInvalidModal({ onClose }: WorkflowInvalidModalProps) {
  const {
    invalidIntentNames,
    isAllBranchesFilled,
    undefinedContextVariableNames,
    unsupportedStepNames,
  } = useWorkflowInvalidMessages();

  return (
    <Dialog
      onClose={onClose}
      open={true}
      title={'Edits required for your workflow'}
    >
      <Wrapper>
        <Typography color={theme.palette.colors.grey[700]} variant={'font14'}>
          Please review the following before updating your workflow.
        </Typography>
        <WarningSection>
          {isAllBranchesFilled === false && (
            <Row>
              <img alt='' src={warningIcon} />
              <Typography variant={'font14'}>
                Ensure that all branches have been filled
              </Typography>
            </Row>
          )}
          {undefinedContextVariableNames.length > 0 && (
            <Row>
              <img alt='' src={warningIcon} />
              <Typography variant={'font14'}>
                Ensure collection of{' '}
                <RedText>{undefinedContextVariableNames}</RedText>
              </Typography>
            </Row>
          )}
          {unsupportedStepNames.length > 0 && (
            <Row>
              <img alt='' src={warningIcon} />
              <Typography variant={'font14'}>
                Ensure deletion of <RedText>{unsupportedStepNames}</RedText>
              </Typography>
            </Row>
          )}
          {invalidIntentNames.length > 0 && (
            <Row>
              <img alt='' src={warningIcon} />
              <Typography variant={'font14'}>
                Ensure activation of <RedText>{invalidIntentNames}</RedText>
              </Typography>
            </Row>
          )}
        </WarningSection>

        <Button onClick={onClose} variant='secondary'>
          Got it
        </Button>
      </Wrapper>
    </Dialog>
  );
}

export default WorkflowInvalidModal;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 400px;
`;
const Row = styled('div')`
  display: flex;
  gap: 8px;
`;

const WarningSection = styled('div')`
  display: flex;
  flex-direction: column;
`;

const RedText = styled('span')`
  color: ${theme.palette.colors.red[500]};
`;
