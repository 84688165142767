import { Button } from '@forethought-technologies/forethought-elements';
import NoteDialog from './NoteDialog';

interface UnsavedNoteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDontSave: () => void;
  onSave: () => void;
}

const UnsavedNoteDialog = ({
  isOpen,
  onClose,
  onDontSave,
  onSave,
}: UnsavedNoteDialogProps) => {
  return (
    <NoteDialog
      actionButton={
        <>
          <Button
            onClick={() => {
              onDontSave();
            }}
            variant='ghost'
          >
            Don&apos;t Save
          </Button>
          <Button
            onClick={() => {
              onSave();
            }}
            variant='secondary'
          >
            Save
          </Button>
        </>
      }
      fullWidth={true}
      onClose={onClose}
      open={isOpen}
      showCancel={false}
      title='Save your changes?'
    />
  );
};

export default UnsavedNoteDialog;
