import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Button } from '@forethought-technologies/forethought-elements';
interface NoteDialogProps {
  actionButton: React.ReactNode;
  body?: string;
  fullWidth?: DialogProps['fullWidth'];
  onClose: () => void;
  open: boolean;
  showCancel?: boolean;
  title: string;
}

const NoteDialog = ({
  actionButton,
  body,
  fullWidth = false,
  onClose,
  open,
  showCancel = true,
  title,
}: NoteDialogProps) => {
  return (
    <Dialog fullWidth={fullWidth} maxWidth={'xs'} onClose={onClose} open={open}>
      <DialogTitle sx={{ pt: 3, typography: 'font20' }}>{title}</DialogTitle>
      {body ? (
        <DialogContent sx={{ pb: 2 }}>
          <DialogContentText
            sx={theme => ({
              color: theme.palette.colors.grey[700],
              typography: 'font14',
            })}
          >
            {body}
          </DialogContentText>
        </DialogContent>
      ) : null}
      <DialogActions sx={{ p: 3, pt: 0 }}>
        {showCancel ? (
          <Button onClick={onClose} variant='ghost'>
            Cancel
          </Button>
        ) : null}
        {actionButton}
      </DialogActions>
    </Dialog>
  );
};

export default NoteDialog;
