import React, { Dispatch, FC, SetStateAction } from 'react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { Checkbox } from '../../../../../components/checkbox/Checkbox';
import set from 'lodash/fp/set';
import {
  Row,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { ZendeskCommonCustomization } from 'src/types/workflowBuilderAPITypes';

interface MarkAsNotDeflectedProps {
  customizationData: ZendeskCommonCustomization;
  setCustomizationData: Dispatch<SetStateAction<ZendeskCommonCustomization>>;
}
const MarkAsNotDeflected: FC<
  React.PropsWithChildren<MarkAsNotDeflectedProps>
> = ({ customizationData, setCustomizationData }) => {
  return (
    <Row>
      <Checkbox
        checked={
          !!customizationData.ticket_creation_settings?.ticket_creation_config
            ?.update_flamethrower_conversation_deflection_status
        }
        onChange={() =>
          setCustomizationData(handoffData => {
            return set(
              [
                'ticket_creation_settings',
                'ticket_creation_config',
                'update_flamethrower_conversation_deflection_status',
              ],
              !handoffData.ticket_creation_settings?.ticket_creation_config
                ?.update_flamethrower_conversation_deflection_status,
              handoffData,
            );
          })
        }
      />
      <Spacer width='4px' />
      <Typography variant='font14'>
        Mark Conversation as Not Deflected
      </Typography>
    </Row>
  );
};

export default MarkAsNotDeflected;
