import {
  useGetFeatureFlagsQuery,
  useGetWidgetConfigQuery,
} from 'src/services/dashboard-api';

export default function useIsCSATEnabled() {
  const { data: widgetConfig } = useGetWidgetConfigQuery();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isCsatConfigRevampEnabled = featureFlags.includes('csat_config_revamp');

  return (
    !!widgetConfig?.csat_survey_config?.is_enabled || isCsatConfigRevampEnabled
  );
}
