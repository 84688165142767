import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

export function useIsDynamicListFeatureFlagEnabled() {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isDynamicListFeatureFlagEnabled = featureFlags.includes(
    'dynamic_list_context_variable',
  );

  return isDynamicListFeatureFlagEnabled;
}
