import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';

import { Typography } from '@forethought-technologies/forethought-elements';
import { EMAIL_RESPONSE } from '../../../intent-email-builder/constants';
import { MISSING_STATIC_ARTICLE_ERROR } from '../../constants';
import AlertTooltip from '../AlertTooltip';
import DraggableListItem from './DraggableListItem';
import StatusIndicator from 'src/components/status-indicator';
import { selectEmailBuilderState } from 'src/slices/email-builder/emailBuilderSlice';

export default function EmailResponseList() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { intentEmailConfigurations } = useSelector(selectEmailBuilderState);

  const onTitleClick = (configurationId: string) => {
    const queryParams = new URLSearchParams(search);
    queryParams.set('emailMode', EMAIL_RESPONSE);
    queryParams.set('configurationId', configurationId);

    navigate({
      search: queryParams.toString(),
    });
  };

  return (
    <Box>
      <Box mb='20px'>
        <Typography variant='font16Bold'>Responses</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        {intentEmailConfigurations.map((emailConfiguration, index) => {
          const contentRight = emailConfiguration.missing_static_article ? (
            <AlertTooltip
              tooltipProps={{
                placement: 'left',
                tooltipContent: MISSING_STATIC_ARTICLE_ERROR,
              }}
            />
          ) : (
            <StatusIndicator
              status={
                emailConfiguration.needs_to_be_published ? 'idle' : 'active'
              }
            />
          );

          return (
            <div
              aria-label={emailConfiguration.name || `Response ${index + 1}`}
              key={emailConfiguration.configuration_id}
            >
              <DraggableListItem
                componentMetadata={{ response: emailConfiguration }}
                componentType='response'
                contentRight={contentRight}
                onTitleClick={() =>
                  onTitleClick(emailConfiguration.configuration_id)
                }
                title={emailConfiguration.name || `Response ${index + 1}`}
              />
            </div>
          );
        })}
      </Box>
    </Box>
  );
}
