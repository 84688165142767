import { Box } from '@mui/system';

import ActionList from './ActionList';
import EmailResponseList from './EmailResponseList';
import RuleList from './RuleList';

export default function IdleSidebarContent() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        padding: '24px',
      }}
    >
      <ActionList />
      <RuleList />
      <EmailResponseList />
    </Box>
  );
}
