import React, { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { BUTTON_SIZE, COLUMN_SIZE } from '../constants';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface DiscoverAutomationCardProps {
  children: React.ReactNodeArray;
  linkTo?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  style?: CSSProperties;
}

const DiscoverAutomationCard = ({
  children,
  linkTo,
  onClick,
  onMouseEnter,
  style,
}: DiscoverAutomationCardProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [title, volume, cost] = children;
  const isStatusIncluded = children.length === 5;
  const status = isStatusIncluded ? children[3] : null;
  const callToAction = isStatusIncluded ? children[4] : children[3];

  const handleOnClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    if (linkTo) {
      navigate(linkTo);
    }
  };

  return (
    <Box
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      sx={[
        {
          border: `1px solid ${palette.colors.slate[200]}`,
          borderRadius: '8px',
          color: palette.colors.black,
          display: 'flex',
          mb: 2,
          p: 3,
          textDecoration: 'none',
        },
        linkTo || onClick
          ? {
              '&:hover': {
                border: `1px solid ${palette.colors.grey[800]}`,
                boxShadow:
                  '0px 0px 1px rgba(0, 12, 32, 0.04), 0px 2px 6px rgba(3, 17, 38, 0.107135)',
              },
              cursor: 'pointer',
            }
          : null,
        { ...style },
      ]}
    >
      <Box
        alignItems='center'
        display='flex'
        flex={1}
        minWidth={COLUMN_SIZE}
        overflow='hidden'
      >
        {title}
      </Box>
      <Box alignItems='center' display='flex' width={COLUMN_SIZE}>
        {volume}
      </Box>
      {status && (
        <Box alignItems='center' display='flex' width={COLUMN_SIZE}>
          {cost}
        </Box>
      )}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        width={COLUMN_SIZE + BUTTON_SIZE}
      >
        <Box alignItems='center' display='flex'>
          {status || cost}
        </Box>
        <Box
          data-appcues-target={getAppCuesId({
            componentType: 'button',
            featureName: 'automation',
            pageName: 'automation',
            subType: 'calltoaction',
          })}
          display='flex'
          justifyContent='flex-end'
          whiteSpace='nowrap'
          width={115}
        >
          {callToAction}
        </Box>
      </Box>
    </Box>
  );
};

export default DiscoverAutomationCard;
