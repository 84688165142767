import './styles.scss';

import React from 'react';
import classNames from 'classnames';

interface NoDataViewProps {
  /** Boolean to determine if opacity level should be high */
  highOpacityLevel?: boolean;
  /** Boolean that determines if component will be inside a chart */
  insideChart?: boolean;
  /** Message to display */
  message: string;
  /** Class to determine wich icon to use */
  noDataViewClass: string;
}

const NoDataView: React.FC<React.PropsWithChildren<NoDataViewProps>> = ({
  highOpacityLevel,
  insideChart,
  message,
  noDataViewClass,
}: NoDataViewProps) => {
  const cotainerClasses = classNames('NoDataView-container', {
    'NoDataView-container--insideChart': insideChart,
  });

  const backgroundClass = classNames('NoDataView-background', {
    'NoDataView-background--highOpacityLevel': highOpacityLevel,
  });
  return (
    <>
      <div className={backgroundClass} role='region' />
      <div className={cotainerClasses}>
        <div className='NoDataView-iconMessageContainer'>
          <span
            className={`NoDataView-icon NoDataView--${noDataViewClass}`}
            data-testid='no-data-view-icon'
          />
          <span className='NoDataView-message'>{message}</span>
        </div>
      </div>
    </>
  );
};

export default NoDataView;
