import React, { FC } from 'react';
import { Box } from '@mui/material';

import FormikTextArea from '../FormikTextArea';
import IgnoreNameEmailCheckbox from './IgnoreNameEmailCheckbox';

const MessageCustomization: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      {/* form_prompt */}
      <FormikTextArea
        fieldName='agent_chat_handoff_settings.form_prompt'
        label='Handoff Form Question Message'
        placeholder='Add agent chat handoff form prompt'
        required
        tooltip='Message before user fills out the agent chat handoff form.'
      />
      <Box mb={2} />
      {/* ignore_name_email */}
      <IgnoreNameEmailCheckbox />
      <Box mb={2} />
      {/* talk_to_agent_button_prompt */}
      <FormikTextArea
        fieldName='agent_chat_handoff_settings.talk_to_agent_button_prompt'
        label='Chat Handoff Question'
        placeholder='Add agent chat handoff button prompt'
        required
        tooltip='Message before user is asked if they want to speak with an agent.'
      />
      <Box mb={3} />
      {/* handoff_success_message */}
      <FormikTextArea
        fieldName='agent_chat_handoff_settings.handoff_success_message'
        label='Confirmation Message'
        placeholder='Add agent chat handoff success message'
        required
        tooltip='Message after we have verified that agents are available and are about to handoff user to the Salesforce widget.'
      />
      <Box mb={3} />
      {/* no_agents_available_message */}
      <FormikTextArea
        fieldName='agent_chat_handoff_settings.no_agents_available_message'
        label='Chat Handoff Unavailable Message'
        placeholder='Add agent chat handoff no agents available message'
        required
        tooltip='Message sent to user if we are unable to verify agents are online or if the handoff to the Salesforce widget fails.'
      />
    </>
  );
};

export default MessageCustomization;
