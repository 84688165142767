import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Box, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  EllipsisWithTooltip,
  IconButton,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getWorkflowValueFromSearch } from '../autonomous-agent/helpers';
import CanvasTagger from '../canvas-tagger/CanvasTagger';
import {
  API,
  EMPTY_WORKFLOW,
  NO_LIVE_VERSION_IS_PUBLISHED,
  viewToWorkflowTypes,
  WIDGET,
} from '../constants';
import { entryStepMissingError } from '../email-builder-page/intent-email-journey-map/constants';
import {
  useEmailMode,
  useGetBuilderQueryParams,
  useGetHandoffConfigurationForWorkflow,
} from '../hooks';
import { getTopicIds } from '../Input/utils';
import { BuilderView } from '../types';
import { Features, Pages } from '../workflow-builder/appCuesIdentifiers';
import SolveDiscoverTopicsButton from '../workflow-builder/solve-discover-topics-button/SolveDiscoverTopicsButton';
import WorkflowPublishButton from '../workflow-builder/workflow-publish-button/WorkflowPublishButton';
import { HistoryButtonWrapper } from '../workflow-builder/WorkflowBuilder';
import { PublishButtonContainer } from './BuilderTopbar';
import historyIcon from 'src/assets/images/history-icon.svg';
import ActivateWorkflowModal from 'src/components/activate-workflow-modal/ActivateWorkflowModal';
import RequiredPhrasesModal from 'src/components/required-phrases-modal/RequiredPhrasesModal';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { useGetHasOtherQuestions } from 'src/hooks/solve';
import { AlertModalContainer } from 'src/pages/workflow-builder/landing-page/LandingPage';
import {
  selectCanvasWorkflowBuilder,
  selectIsFallback,
  selectVersion,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  useGetFeatureFlagsQuery,
  useGetPaidPlanDetailsQuery,
} from 'src/services/dashboard-api';
import { dashboardApi } from 'src/services/dashboard-api';
import { getHandoffConfigurationsAPI } from 'src/services/workflow-builder/workflowBuilderApi';
import { useGetWorkflowsForIntentQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import {
  useActivateIntentWorkflowMutation,
  useDeactivateIntentWorkflowMutation,
} from 'src/services/workflow-builder-metrics';
import {
  CanvasWorkflowBuilderState,
  selectIntent,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  getIntent,
  loadDraftWorkflow,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { selectDiscoverTopicsByIntentId } from 'src/slices/data/dataSlice';
import { getDiscoverAutomations } from 'src/slices/data/thunks';
import { selectValidationErrors } from 'src/slices/email-workflow/emailWorkflowSlice';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import {
  openGlobalIntentDrawer,
  setGlobalToastOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { isApiError } from 'src/types/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { minimumPhrasesRequired } from 'src/utils/constants';
import {
  CommonIntentWorkflowType,
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';
import {
  getWorkflowActivationCopy,
  isIntentActiveForChannel,
} from 'src/utils/solve/intentUtils';

interface WorkflowPublishButtonsProps {
  areChangesUnsaved: boolean;
  isAutochainWorkflow?: boolean;
  isCanvasTaggerVisible?: boolean;
  isDiscardButtonDisabled: boolean;
  isDraftVersion: boolean;
  isJourneyMapTab?: boolean;
  isPreviewButtonVisible?: boolean;
  isSolveDiscoverTopicsButtonVisible?: boolean;
  isToggleButtonOn: boolean;
  isUpdateButtonDisabled: boolean;
  isVersionHistoryButtonVisible?: boolean;
  isVersionHistoryPanelOpen?: boolean;
  isWorkflowBuilderDisabled?: boolean;
  onActivateToggleButtonChange?: () => void;
  onCanvasTaggerClick?: () => void;
  onDiscardButtonClick: () => void;
  onPreviewButtonClick?: () => void;
  onSolveDiscoverTopicButonClick?: () => void;
  onUpdateButtonClick?: () => void;
  onVersionHistoryButtonClick?: () => void;
  previewButtonText?: string;
  saveChangesButtonLabel?: string;
  shouldUseWorkflowPublishButton?: boolean;
  toggleButtonTooltipText?: string;
  view?: BuilderView;
}

export default function WorkflowPublishButtons({
  areChangesUnsaved,
  isAutochainWorkflow = false,
  isCanvasTaggerVisible = false,
  isDiscardButtonDisabled,
  isDraftVersion,
  isJourneyMapTab,
  isPreviewButtonVisible = false,
  isSolveDiscoverTopicsButtonVisible = false,
  isToggleButtonOn,
  isUpdateButtonDisabled,
  isVersionHistoryButtonVisible,
  isVersionHistoryPanelOpen,
  isWorkflowBuilderDisabled = false,
  onActivateToggleButtonChange,
  onCanvasTaggerClick,
  onDiscardButtonClick,
  onPreviewButtonClick,
  onSolveDiscoverTopicButonClick,
  onUpdateButtonClick,
  onVersionHistoryButtonClick,
  previewButtonText,
  saveChangesButtonLabel,
  shouldUseWorkflowPublishButton,
  toggleButtonTooltipText,
  view,
}: WorkflowPublishButtonsProps) {
  const { palette } = useTheme();
  const { search } = useLocation();
  const { emailMode } = useEmailMode();

  const isWorkflowBuilderView =
    view === WIDGET ||
    emailMode === 'Interactive' ||
    view === API ||
    view === 'slack';

  const intentId = getWorkflowValueFromSearch(search, 'intentId');
  const isKnowledgeRetrieval =
    intentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const isGeneralHandoffWidgetWorkflow =
    intentId === 'general-handoff' && view === 'widget';

  const { workflowErrors } = useSelector(selectValidationErrors);
  const isHandoff = useGetHandoffConfigurationForWorkflow() !== undefined;
  const version = useSelector(selectVersion);
  const isFallback = useSelector(selectIsFallback);

  const shouldShowWidgetToggle =
    (!isAutochainWorkflow && version > 2) || isKnowledgeRetrieval;

  const shouldShowToggle =
    (shouldShowWidgetToggle || !isWorkflowBuilderView) &&
    !isHandoff &&
    !isGeneralHandoffWidgetWorkflow &&
    !isFallback;

  const toggle = isWorkflowBuilderView ? (
    <WidgetToggle />
  ) : (
    <WorkflowToggle
      aria-label={toggleButtonTooltipText}
      checked={isToggleButtonOn}
      disabled={isWorkflowBuilderDisabled}
      onChange={() => {
        if (onActivateToggleButtonChange) {
          onActivateToggleButtonChange();
        }
      }}
      tooltipText={toggleButtonTooltipText}
    />
  );

  return (
    <PublishButtonContainer
      minWidth={areChangesUnsaved || !!workflowErrors.length ? '558px' : 'auto'}
    >
      {isJourneyMapTab && !!workflowErrors.length && (
        <Box marginLeft='8px'>
          <Typography color={palette.text.secondary} variant='font14'>
            {entryStepMissingError}
          </Typography>
        </Box>
      )}
      {isWorkflowBuilderView && isSolveDiscoverTopicsButtonVisible && (
        <SolveDiscoverTopicsButton
          onClick={() =>
            onSolveDiscoverTopicButonClick && onSolveDiscoverTopicButonClick()
          }
        />
      )}
      {isWorkflowBuilderView && isCanvasTaggerVisible && (
        <CanvasTagger
          isDisabled={isWorkflowBuilderDisabled}
          onClick={() => onCanvasTaggerClick && onCanvasTaggerClick()}
        />
      )}

      {isWorkflowBuilderView && isVersionHistoryButtonVisible && (
        <HistoryButtonWrapper
          shouldShowVersionHistory={Boolean(isVersionHistoryPanelOpen)}
        >
          <IconButton
            aria-label='History'
            data-appcues-target={getAppCuesId({
              componentType: 'VersionHistory',
              featureName: Features.CANVAS,
              pageName: Pages.SOLVE,
              subType: 'ToggleButton',
            })}
            disabled={isWorkflowBuilderDisabled}
            onClick={() => {
              onVersionHistoryButtonClick && onVersionHistoryButtonClick();
            }}
            variant='ghost'
          >
            <ReactSVG src={historyIcon} />
          </IconButton>
        </HistoryButtonWrapper>
      )}
      {areChangesUnsaved ? (
        <>
          {(isWorkflowBuilderView ||
            (isJourneyMapTab && !workflowErrors.length)) && (
            <EllipsisWithTooltip tooltipContent='You have unsaved changes'>
              <Typography color={palette.text.secondary} variant='font14'>
                You have unsaved changes
              </Typography>
            </EllipsisWithTooltip>
          )}
          <Button
            disabled={isDiscardButtonDisabled}
            onClick={() => onDiscardButtonClick()}
            variant='secondary'
          >
            Discard draft
          </Button>
        </>
      ) : (
        shouldShowToggle && toggle
      )}
      {isPreviewButtonVisible && (
        <Button
          disabled={isWorkflowBuilderDisabled}
          onClick={() => onPreviewButtonClick && onPreviewButtonClick()}
          variant='secondary'
        >
          {previewButtonText ? previewButtonText : 'Preview Intent'}
        </Button>
      )}
      {shouldUseWorkflowPublishButton ? (
        <WorkflowPublishButton hasUnsavedChanges={areChangesUnsaved} />
      ) : (
        isDraftVersion && (
          <Button
            disabled={isUpdateButtonDisabled}
            onClick={() => {
              if (onUpdateButtonClick) {
                onUpdateButtonClick();
              }
            }}
            variant='main'
          >
            {saveChangesButtonLabel ?? ''}
          </Button>
        )
      )}
    </PublishButtonContainer>
  );
}

export const WidgetToggle = () => {
  const dispatch = useAppDispatch();

  const canvasData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const [activateIntentWorkflow] = useActivateIntentWorkflowMutation();
  const [deactivateIntentWorkflow] = useDeactivateIntentWorkflowMutation();

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const { view, workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const intent = useSelector(selectIntent);
  const isWorkflowActive = isIntentActiveForChannel(
    intent?.workflow_types_enabled ?? [],
    view === 'email' ? 'interactive_email' : view,
  );

  const { data: workflowsForIntent, isLoading } = useGetWorkflowsForIntentQuery(
    {
      intentDefinitionId: canvasData.intent_id ?? '',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: view !== 'email' || !canvasData.intent_id,
    },
  );

  const discoverTopicsByIntentId = useSelector(selectDiscoverTopicsByIntentId);
  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const [isRequiredPhrasesModalVisible, setIsRequiredPhrasesModalVisible] =
    useState(false);

  const [isActivateWorkflowDialogVisible, setIsActivateWorkflowDialogVisible] =
    useState(false);

  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );

  const isHandoffRevampEnabled = featureFlags.includes('handoff_revamp');
  const hasOtherQuestions = useGetHasOtherQuestions();

  const isDiscoverEnabled = paidPlanData?.enablement_info.discover;

  const apiErrors = [EMPTY_WORKFLOW, NO_LIVE_VERSION_IS_PUBLISHED];
  const workflowTypes = viewToWorkflowTypes[view];

  const activateWorkflow = async () => {
    try {
      await activateIntentWorkflow({
        intentWorkflowId: selectedWorkflowId,
        workflowTypes,
      }).unwrap();
      await dispatch(loadDraftWorkflow(selectedWorkflowId));
      dispatch(dashboardApi.util.invalidateTags(['Intents']));
    } catch (error) {
      if (isApiError(error) && apiErrors.includes(error.data?.error_type)) {
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: 10000,
            title: error.data.error_message,
            variant: 'warning',
          }),
        );
      }
    }

    dispatch(getIntent(canvasData.intent_id as string));
  };

  const deactivateWorkflow = async () => {
    try {
      await deactivateIntentWorkflow({
        intentWorkflowId: selectedWorkflowId,
        workflowTypes,
      }).unwrap();
      await dispatch(loadDraftWorkflow(selectedWorkflowId));
      dispatch(dashboardApi.util.invalidateTags(['Intents']));
    } catch {
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 4000,
          title: 'Something went wrong deactivating workflow.',
          variant: 'danger',
        }),
      );
    }
    dispatch(getIntent(canvasData.intent_id as string));
  };

  const onClickToggle = async () => {
    const phrases = canvasData.intent?.phrases || [];
    const isDefaultHandoff = Object.values(CommonIntentWorkflowType).includes(
      canvasData.intent?.intent_id as CommonIntentWorkflowType,
    );

    // TODO: use RTK query instead of send a new request
    let isHandoff = false;
    if (isHandoffRevampEnabled) {
      const { configurations } = await getHandoffConfigurationsAPI();
      isHandoff = configurations.some(
        configuration =>
          configuration.intent_definition_id === canvasData.intent?.intent_id,
      );
    }

    const shouldDisplayRequiredPhraseModal =
      !isWorkflowActive &&
      !isDefaultHandoff &&
      !isHandoff &&
      phrases.length < minimumPhrasesRequired;

    if (shouldDisplayRequiredPhraseModal) {
      setIsRequiredPhrasesModalVisible(true);
    } else {
      setIsActivateWorkflowDialogVisible(true);
    }
  };

  const { confirmationMessage, label } = getWorkflowActivationCopy({
    channel: view === 'email' ? 'Interactive email' : view,
    hasOtherQuestions,
    isActive: isWorkflowActive,
    isKnowledgeArticle:
      canvasData.intent_id === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE,
  });

  const hasLiveEmailWorkflowAndWorkflowHasLiveInteractiveEmailComponent =
    workflowsForIntent?.has_live_interactive_email_component &&
    intent?.workflow_types_enabled?.includes('email');
  const isEssentialDataLoading = canvasData.loading || isLoading;
  const isUsedByQuickFeedbackNegativeRouting =
    canvasData.intent_workflow_id ===
    widgetConfiguration.quick_feedback_config
      .negative_routing_intent_workflow_id;

  const isToggleDisabled =
    hasLiveEmailWorkflowAndWorkflowHasLiveInteractiveEmailComponent ||
    isEssentialDataLoading ||
    isUsedByQuickFeedbackNegativeRouting;

  const getTooltipText = () => {
    if (hasLiveEmailWorkflowAndWorkflowHasLiveInteractiveEmailComponent) {
      return "Can't deactivate this workflow because it is used in a live Email Workflow";
    }

    return isUsedByQuickFeedbackNegativeRouting
      ? "Can't deactivate this workflow because it is used in Quick Feedback configuration"
      : label;
  };

  return (
    <>
      <WorkflowToggle
        ariaLabel={label}
        checked={isWorkflowActive}
        disabled={isToggleDisabled}
        onChange={onClickToggle}
        tooltipText={getTooltipText()}
      />
      {isRequiredPhrasesModalVisible && (
        <AlertModalContainer>
          <RequiredPhrasesModal
            onActivateOptionClick={async () => {
              await dispatch(getIntent(canvasData.intent_id as string));
              if (isDiscoverEnabled) {
                await dispatch(getDiscoverAutomations());
              }
              dispatch(
                openGlobalIntentDrawer({
                  type: 'widget',
                }),
              );
              setIsRequiredPhrasesModalVisible(false);
            }}
            onLaterOptionClick={() => setIsRequiredPhrasesModalVisible(false)}
          />
        </AlertModalContainer>
      )}

      <ActivateWorkflowModal
        isActivating={isWorkflowActive}
        isDialogVisible={isActivateWorkflowDialogVisible}
        message={confirmationMessage}
        onClose={() => setIsActivateWorkflowDialogVisible(false)}
        onNegativeOptionSelected={() =>
          setIsActivateWorkflowDialogVisible(false)
        }
        onPositiveOptionSelected={() => {
          const topicIds = discoverTopicsByIntentId
            ? getTopicIds(discoverTopicsByIntentId)
            : [];

          setIsActivateWorkflowDialogVisible(false);
          if (isWorkflowActive) {
            dispatchTrackingAction(
              FlamethrowerTrackingEventTypes.WORKFLOW_DEACTIVATED,
              {
                channel: 'widget',
                topic_ids: topicIds,
                workflow_id: selectedWorkflowId || '',
              },
            );
            deactivateWorkflow();
          } else {
            dispatchTrackingAction(
              FlamethrowerTrackingEventTypes.WORKFLOW_ACTIVATED,
              {
                channel: 'widget',
                topic_ids: topicIds,
                workflow_id: selectedWorkflowId || '',
              },
            );
            activateWorkflow();
          }
        }}
      />
    </>
  );
};

const WorkflowToggle = styled(Toggle)`
  input {
    height: 20px !important;
  }
`;
