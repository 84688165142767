import React from 'react';
import Box from '@mui/material/Box';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface ActivateWorkflowModalProps {
  isActivating: boolean;
  isDialogVisible: boolean;
  message: string;
  onClose: () => void;
  onNegativeOptionSelected: () => void;
  onPositiveOptionSelected: () => void;
}

const ActivateWorkflowModal: React.FC<
  React.PropsWithChildren<ActivateWorkflowModalProps>
> = ({
  isActivating,
  isDialogVisible,
  message,
  onClose,
  onNegativeOptionSelected,
  onPositiveOptionSelected,
}) => {
  return (
    <Dialog
      footer={
        <>
          <Button
            onClick={e => {
              e.stopPropagation();
              onNegativeOptionSelected();
            }}
            variant='ghost'
          >
            No
          </Button>
          <Button
            onClick={e => {
              e.stopPropagation();
              onPositiveOptionSelected();
            }}
            variant='secondary'
          >
            Yes
          </Button>
        </>
      }
      hideCloseButton
      onClose={e => {
        e.stopPropagation();
        onClose();
      }}
      open={isDialogVisible}
      title={isActivating ? 'Deactivate?' : 'Activate?'}
    >
      <Box maxWidth='400px'>
        <Typography variant='font14'>{message}</Typography>
      </Box>
    </Dialog>
  );
};

export default ActivateWorkflowModal;
