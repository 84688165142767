import './styles.scss';

import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { EmailFilterTextProps } from '../utils/enum';
import { isEmailValid, removeEmail } from '../utils/handleEmailUtil';
import { setToastType } from 'src/actions/toastNotification/toastNotification';
import FilterTag from 'src/components/reusable-components/filter-tag/FilterTag';
import ToggleButton from 'src/components/reusable-components/toggle-button/ToggleButton';
import Tooltip from 'src/components/reusable-components/tooltip/tooltip';
import { TypesOfToast } from 'src/utils/enums';

interface EmailFilterProps {
  /** List of filtered emails */
  emails: Array<string>;
  /** Boolean to determine if email filtering is enabled */
  isEnabled: boolean;
  /** Callback to be executed on submit */
  onSubmit: Function;
  /** Boolean to determine if emails container takes small size class */
  smallContainer: boolean;
  /** Description for tooltip */
  tooltipDescription: string;
  /** Title for tooltip */
  tooltipTitle: string;
}

const EmailFilter: React.FC<React.PropsWithChildren<EmailFilterProps>> = ({
  emails,
  isEnabled,
  onSubmit,
  smallContainer,
  tooltipDescription,
  tooltipTitle,
}: EmailFilterProps) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');

  const onKeyDown = () => {
    if (isEmailValid(email) && !emails.includes(email)) {
      onSubmit({ whitelisted_emails: [...emails, email] });
      setEmail('');
    }
    if (emails.includes(email)) {
      dispatch(setToastType(TypesOfToast.DUPLICATE_EMAIL));
    }
    if (!isEmailValid(email)) {
      dispatch(setToastType(TypesOfToast.INVALID_EMAIL));
    }
  };

  const emailsContainerClass = classNames('EmailFilter-emailsContainer', {
    'EmailFilter-emailsContainer--small': smallContainer,
  });

  return (
    <div className='EmailFilter'>
      <div className='EmailFilter-headerContainer'>
        <div className='EmailFilter-titleContainer'>
          <span className='EmailFilter-titleContainer-title'>
            {EmailFilterTextProps.LABEL}
          </span>
          <Tooltip
            tooltipText={tooltipDescription}
            tooltipTitle={tooltipTitle}
          />
        </div>
        <div className='EmailFilter-toggleBtnContainer'>
          <span className='EmailFilter-label'>
            {EmailFilterTextProps.TOGGLE_BUTTON_LABEL}
          </span>
          <ToggleButton
            isChecked={isEnabled}
            onClickHandler={(isEnabled: boolean) =>
              onSubmit({ is_whitelist_emails_enabled: isEnabled })
            }
          />
        </div>
      </div>

      {isEnabled && (
        <div className='EmailFilter-content' data-testid='email-filter-content'>
          <div className={emailsContainerClass}>
            {emails?.map((email: string, index: number) => (
              <FilterTag
                data={email}
                isSimpleTag
                key={index}
                onClickDeleteBtn={() =>
                  onSubmit({ whitelisted_emails: removeEmail(emails, email) })
                }
              />
            ))}
          </div>
          <div className='EmailFilter-inputContainer'>
            <input
              className='EmailFilter-inputContainer-input'
              data-testid='email-filter-input'
              onChange={e => setEmail(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter' && email) {
                  onKeyDown();
                }
              }}
              placeholder='Add email'
              type='text'
              value={email}
            />
            {email.length !== 0 && (
              <span
                className='EmailFilter-cancelIcon'
                onClick={() => setEmail('')}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailFilter;
