import { useMemo, useRef } from 'react';

import { Filters } from 'src/services/apiInterfaces';

export const useIsNewFilterEqualToOld = (dataFilterQuery: Filters[]) => {
  const prevStringifiedFilter = useRef<string | null>(null);

  const isNewFilterEqualToOld = useMemo(() => {
    const stringifiedFilter = JSON.stringify(dataFilterQuery);
    const isEqual =
      // allows loading on first hook run
      prevStringifiedFilter.current === null ||
      prevStringifiedFilter.current === stringifiedFilter;

    prevStringifiedFilter.current = stringifiedFilter;

    return isEqual;
  }, [dataFilterQuery]);

  return isNewFilterEqualToOld;
};
