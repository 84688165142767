import { FetchAction, SuperAdminState } from './types';
import { SuperAdminActions } from 'src/actions/actionTypes';
import type { RootState } from 'src/store/rootReducer';

const getSuperAdminInitialState = () => {
  return [
    {
      org_id: '',
      org_name: '',
    },
  ];
};

export const initialState: SuperAdminState = {
  error: '',
  organizations: getSuperAdminInitialState(),
};

export default function superAdminReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === SuperAdminActions.GET_SEARCH_ORG_SUCCESS) {
    return { ...state, organizations: action.payload.organizations };
  }

  if (action.type === SuperAdminActions.GET_SEARCH_ORG_FAILURE) {
    return { ...state, error: action.payload?.toString() || 'Error' };
  }

  return state;
}

// SELECTORS
export const getSearchOrganizationsData = (
  state: RootState,
): SuperAdminState['organizations'] => state.superAdmin.organizations;
