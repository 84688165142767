import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { Colors } from '@forethought-technologies/forethought-elements';
import { UnstyledButton, UnstyledUnorderedList } from '../common/resetUI';
import Logout from '../user-profile/logout/logout';
import { getOrgIdInfo } from 'src/actions/auth';
import { updateQueryString } from 'src/actions/search';
import { setCurrentApplication } from 'src/actions/user';
import { displayOrg } from 'src/components/app/displayOrgUtil';
import { showSettings } from 'src/components/app/displaySettings';
import FuzzySearchModal from 'src/components/reusable-components/fuzzy-search/FuzzySearchModal';
import { SESSION_COPY_PASTE_STORAGE_KEY } from 'src/pages/workflow-builder-edit/constants';
import { Apps, Routes, Tabs, UserRoles } from 'src/utils/enums';

type Props = {
  handleModalVisibility: () => void;
  role: UserRoles;
};

const PAGE_SETTING = 'settings';
const PAGE_ORG = 'org_settings';

const USER_ROLE_MAPPING: Record<UserRoles, string> = {
  [UserRoles.ROLE_ADMIN]: 'Admin',
  [UserRoles.ROLE_SUPER_ADMIN]: 'Super Admin',
  [UserRoles.ROLE_USER]: 'User',
  [UserRoles.ROLE_AGENT]: 'User',
};

const NavbarMenu = ({ handleModalVisibility, role }: Props) => {
  const [page, setPage] = useState(PAGE_SETTING);
  const [org, setOrg] = useState('');

  const dispatch = useDispatch();

  const fetchOrgId = useCallback(async () => {
    const data = await dispatch(getOrgIdInfo());
    const { organization_id } = data || {};
    if (organization_id) {
      setOrg(organization_id);
    }
  }, [dispatch]);

  useEffect(() => {
    if (role !== UserRoles.ROLE_SUPER_ADMIN) {
      fetchOrgId();
    }
  }, [role, fetchOrgId]);

  const orgInfo = useMemo(
    () => (
      <StyledOrg datat-testid='org-info'>
        <div>{displayOrg(role, org)}</div>
        <StyledRole>
          {USER_ROLE_MAPPING[role] ?? USER_ROLE_MAPPING[UserRoles.ROLE_USER]}
        </StyledRole>
      </StyledOrg>
    ),
    [role, org],
  );

  const changeOrgOption = useMemo(
    () =>
      role === UserRoles.ROLE_SUPER_ADMIN && (
        <StyledSettings>
          <StyledButton
            data-testid='change-org'
            onClick={() => {
              setPage(PAGE_ORG);
              sessionStorage.removeItem(SESSION_COPY_PASTE_STORAGE_KEY);
            }}
          >
            Change Org
          </StyledButton>
        </StyledSettings>
      ),
    [role],
  );

  const settings = useMemo(
    () =>
      showSettings(role) && (
        <StyledSettings>
          <StyledLink
            data-testid='settings'
            onClick={() => {
              dispatch(setCurrentApplication(Apps.INSIGHTS, Tabs.SETTINGS));
              dispatch(updateQueryString(''));
            }}
            to={Routes.ACCOUNT_SETTINGS}
          >
            Settings
          </StyledLink>
        </StyledSettings>
      ),
    [dispatch, role],
  );

  const logout = useMemo(
    () => (
      <StyledSettings>
        <StyledLogout data-testid='logout' />
      </StyledSettings>
    ),
    [],
  );

  const orgSearchModal = useMemo(
    () => <StyledOrgSearch handleModalVisibility={handleModalVisibility} />,
    [handleModalVisibility],
  );

  const content = useMemo(() => {
    if (page === PAGE_SETTING) {
      return (
        <UnstyledUnorderedList>
          {orgInfo}
          {changeOrgOption}
          {settings}
          {logout}
        </UnstyledUnorderedList>
      );
    }

    if (page === PAGE_ORG) {
      return orgSearchModal;
    }
  }, [page, orgInfo, changeOrgOption, settings, logout, orgSearchModal]);

  return <StyledMenu data-testid='navbar-menu'>{content}</StyledMenu>;
};

const MemoizedNavbarMenu = memo(NavbarMenu);

export default MemoizedNavbarMenu;

const StyledMenu = styled('div')`
  --overlay-min-width: 240px;
  --item-padding: 12px 16px;

  border-radius: var(--border-radius-button);
  background: ${Colors.ui.backgrounds.default};
  box-shadow: 0 2px 4px var(--color-grey-100);
  color: var(--color-solid-white);
  min-width: var(--overlay-min-width);
  margin: 16px 24px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1;
  user-select: none;
`;

const StyledRole = styled('sub')`
  font-size: var(--font-size-xs);
  opacity: 0.8;
`;

const StyledOrg = styled('li')`
  font-size: var(--font-size-xs);
  background-color: var(--color-blue-600);
  padding: var(--item-padding);
  font-weight: var(--font-weight-medium);
`;

const StyledSettings = styled('li')`
  border-bottom: 1px solid var(--color-grey-100);
  color: var(--color-text-primary);
  font-size: var(--font-size-xs);
  transition-duration: var(--duration-fast);
  &:last-child {
    border-bottom-width: 0;
  }
  &:hover {
    background-color: var(--background-color-hover);
  }
`;

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;
  padding: var(--item-padding);
  &:visited {
    color: var(--color-text-primary);
  }
`;

const StyledButton = styled(UnstyledButton)`
  padding: var(--item-padding);
  width: 100%;
  text-align: start;
`;

const StyledLogout = styled(Logout)`
  padding: var(--item-padding);
  width: 100%;
  text-align: start;
`;

const StyledOrgSearch = styled(FuzzySearchModal)`
  position: static;
`;
