import React, { Dispatch, FC, SetStateAction } from 'react';

import ContextMention from 'src/pages/workflow-builder-edit/context-mention-input';
import { Spacer } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { KustomerHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

type Props = {
  customizationData: KustomerHandoffCustomization;
  setCustomizationData: Dispatch<SetStateAction<KustomerHandoffCustomization>>;
};

const KustomerConversationMessages: FC<React.PropsWithChildren<Props>> = ({
  customizationData,
  setCustomizationData,
}) => {
  const formPrompt =
    customizationData.conversation_creation_settings
      .handoff_form_question_message;
  const confirmationMessage =
    customizationData.conversation_creation_settings.confirmation_message;

  return (
    <>
      <ContextMention
        label='Conversation Creation Form Prompt'
        onChange={value =>
          setCustomizationData({
            ...customizationData,
            conversation_creation_settings: {
              ...customizationData.conversation_creation_settings,
              handoff_form_question_message: value,
            },
          })
        }
        placeholder='Enter form prompt'
        value={formPrompt}
      />
      <Spacer height='24px' />
      <ContextMention
        label='Post Conversation Creation Message'
        onChange={value =>
          setCustomizationData({
            ...customizationData,
            conversation_creation_settings: {
              ...customizationData.conversation_creation_settings,
              confirmation_message: value,
            },
          })
        }
        placeholder='Enter form prompt'
        tooltip='Text message to be sent after a Kustomer conversation is created'
        value={confirmationMessage}
      />
    </>
  );
};

export default KustomerConversationMessages;
