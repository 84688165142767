import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';

import { Chip } from '@forethought-technologies/forethought-elements';
import {
  selectLegibleButtonOutlineColor,
  selectLegibleElementColor,
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

interface IntentButtonProps {
  intentName: string;
  isProactiveBubble?: boolean;
}

const IntentButton = ({
  intentName,
  isProactiveBubble = true,
}: IntentButtonProps) => {
  const { palette } = useTheme();
  const { theme_color } = useSelector(selectWidgetConfiguration);
  const legibleTextColor = useSelector(selectLegibleTextColor);
  const legibleElementColor = useSelector(selectLegibleElementColor);
  const legibleButtonOutlineColor = useSelector(
    selectLegibleButtonOutlineColor,
  );

  return (
    <Chip
      backgroundColor={palette.colors.white}
      color={legibleElementColor}
      fontSize='14px'
      key={intentName}
      label={intentName ?? ''}
      size='medium'
      sx={[
        {
          '&&.Mui-focusVisible': {
            backgroundColor: theme_color,
            color: legibleTextColor,
            outline: `${legibleButtonOutlineColor} solid 2px`,
          },
          '&&:hover': {
            backgroundColor: palette.colors.white,
            color: legibleElementColor,
            outline: `${legibleButtonOutlineColor} solid 2px`,
          },
          '.MuiChip-label': {
            whiteSpace: 'normal',
          },
          fontWeight: 500,
          height: 'auto',
          marginRight: isProactiveBubble ? '0px' : '7px',
          mb: '8px',
          minHeight: '36px',
        },
      ]}
      variant='intent'
    />
  );
};

export default IntentButton;
