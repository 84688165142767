import { sendAuthRequest } from '../api';
import {
  TicketCustomFieldsResponse,
  TicketFieldMappingsResponse,
  UpdateTicketFieldMappingsRequest,
} from 'src/pages/workflow-builder-edit/types';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';
const BASE_URL = `${API_URL}solve/tickets`;

export const getCustomTicketFields =
  (): Promise<TicketCustomFieldsResponse> => {
    return sendAuthRequest(`${BASE_URL}/ticket-custom-field`, null, 'GET')
      .then(normalizeResponseErrors)
      .then(res => res.json());
  };

export const getTicketFieldMappings =
  (): Promise<TicketFieldMappingsResponse> => {
    return sendAuthRequest(`${BASE_URL}/ticket-field-mapping`, null, 'GET')
      .then(normalizeResponseErrors)
      .then(res => res.json());
  };

export const updateTicketFieldMappings = (
  mappings: UpdateTicketFieldMappingsRequest,
): Promise<TicketFieldMappingsResponse> => {
  return sendAuthRequest(
    `${BASE_URL}/ticket-field-mapping`,
    { mappings },
    'PUT',
  )
    .then(normalizeResponseErrors)
    .then(res => res.json());
};
