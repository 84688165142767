import React, { useCallback, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import Box from '@mui/material/Box';

import {
  Button,
  Drawer,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import AddLabelForm from '../drawers-and-dialogs/AddLabelForm';
import {
  convertFormPhrasesToTrainingPhrases,
  convertFormTagToTagDefinition,
  getTagFormValuesFromModel,
  isModelReadOnly,
  mergeLabelIntoExistingLabels,
} from '../helpers';
import { TagFormValue } from '../types';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import {
  PatchTriageModelRequest,
  VersionedTriageModel,
} from 'src/reducers/triageSettingsReducer/types';
import { usePatchSelfServeTriageModelMutation } from 'src/services/triage/triageApi';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

export interface LabelControlOptions {
  selectTagId: (tagId: string | null) => void;
  tagId: string | null;
}

interface LabelsTableControlProps {
  labelControlOptions: LabelControlOptions;
  model: VersionedTriageModel;
}

const LabelsTableControl: React.FC<LabelsTableControlProps> = ({
  labelControlOptions,
  model,
}) => {
  const emitTrackingEventCallback = useSelfServeEvents({
    model,
  });
  const { selectTagId, tagId } = labelControlOptions;
  const {
    formal_model_name,
    labels,
    model_id,
    model_name,
    phrases,
    version_id,
    version_name,
  } = model;
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      return;
    }
    if (tagId) {
      selectTagId(null);
    }
  }, [selectTagId, tagId, isOpen, setIsOpen]);

  useEffect(() => {
    if (tagId) {
      emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.VIEW_LABEL_DRAWER, {
        mode: 'edit',
        tag_id: tagId,
      });
    }
  }, [
    tagId,
    model_id,
    version_id,
    model_name,
    version_name,
    emitTrackingEventCallback,
  ]);

  const [patchModel] = usePatchSelfServeTriageModelMutation();

  const handleDeleteLabel = useCallback(() => {
    const newLabels = labels.filter(label => label.tag_id !== tagId);
    const newPhrases = phrases.filter(phrase => phrase.tag_id !== tagId);
    const requestBody: PatchTriageModelRequest = {
      body: {
        labels: newLabels,
        phrases: newPhrases,
      },
      modelId: model_id,
      versionId: version_id,
    };
    patchModel(requestBody)
      .unwrap()
      .then(() => {
        handleClose();
      });
  }, [patchModel, model_id, labels, phrases, version_id, tagId, handleClose]);

  const handleAddLabelSuccess = useCallback(
    (values: TagFormValue) => {
      const newLabel = convertFormTagToTagDefinition(
        values.name,
        values.description,
        labels,
        formal_model_name,
        tagId,
        model,
      );
      const insertedLabel = mergeLabelIntoExistingLabels(newLabel, labels);
      const newPhrases = convertFormPhrasesToTrainingPhrases(
        [values.addTrainingPhrase, ...values.trainingPhrases],
        newLabel.tag_id,
        phrases,
      );
      const requestBody: PatchTriageModelRequest = {
        body: {
          labels: insertedLabel,
          phrases: newPhrases,
        },
        modelId: model_id,
        versionId: version_id,
      };
      patchModel(requestBody)
        .unwrap()
        .then(() => {
          handleClose();
        });
    },
    [
      handleClose,
      model_id,
      patchModel,
      formal_model_name,
      labels,
      model,
      phrases,
      version_id,
      tagId,
    ],
  );
  if (!model) {
    return null;
  }

  const initialFormState = getTagFormValuesFromModel(model, tagId);
  const isReadOnly = isModelReadOnly(model);
  const drawerIsOpen = isOpen || Boolean(tagId);

  return (
    <Box columnGap={1} display='flex'>
      <Tooltip
        tooltipContent={
          isReadOnly
            ? 'Published versions cannot be edited. Please duplicate a version to make changes'
            : ''
        }
      >
        <Button
          color='primary'
          disabled={isReadOnly}
          onClick={() => {
            setIsOpen(true);
            emitTrackingEventCallback(
              TRIAGE_LLM_TRACKING_EVENTS.VIEW_LABEL_DRAWER,
              {
                mode: 'create',
                tag_id: tagId,
              },
            );
          }}
          startIcon={<Add />}
          variant='main'
        >
          Create label
        </Button>
      </Tooltip>
      <Drawer isOpen={drawerIsOpen} onClose={handleClose} width='434px'>
        <AddLabelForm
          initialValues={initialFormState}
          model={model}
          onDelete={handleDeleteLabel}
          onSuccess={handleAddLabelSuccess}
          tagId={tagId}
        />
      </Drawer>
    </Box>
  );
};

export default LabelsTableControl;
