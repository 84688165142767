import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  Dialog,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { CanvasModes } from '../constants';
import ContextMention from '../context-mention-input';
import { HiddenImageInput } from './HiddenImageInput';
import { SafeImage } from 'src/components/reusable-components/safe-image/SafeImage';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { uploadImagetoS3 } from 'src/pages/solve-config/utils';
import {
  selectCanvasWorkflowBuilder,
  selectChatOrder,
  selectChatOrderLastStepId,
  selectEditingStepId,
  selectEditingStepType,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  clearSelectedImageFile,
  selectIsSqueezingStep,
  selectSelectedImageFile,
  setEditingStepId,
  setEditingStepType,
  setMode,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addImageStep,
  updateWorkflowStep,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { ImageStepFields } from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
}

export const ImagePicker: React.FC<React.PropsWithChildren<Props>> = ({
  hasWorkflowConflict,
  onDiscard,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const { contextVariables } = useGetContextVariables();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const chatOrder = useSelector(selectChatOrder);
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const selectedImage = useSelector(selectSelectedImageFile);
  const editingStepId = useSelector(selectEditingStepId);
  const editingStepType = useSelector(selectEditingStepType);
  const isSqueezingStep = useSelector(selectIsSqueezingStep);
  const isEditing = editingStepId && editingStepType === StepTypes.IMAGE;
  const stepFields = isEditing
    ? (canvasData.steps[editingStepId].step_fields as ImageStepFields)
    : null;
  const isEntryStep =
    Object.keys(canvasData.steps).length === 0 || !chatOrder.length;
  const [altText, setAltText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const updateOrSaveImageAndStep = async () => {
    setIsSaving(true);

    const saveUrl = selectedImage
      ? (await uploadImagetoS3({ description: altText, file: selectedImage }))
          .public_url
      : imageUrl;

    if (!saveUrl) {
      dispatch(
        setGlobalToastOptions({
          title: 'Error saving image.',
          variant: 'danger',
        }),
      );
      setIsSaving(false);
      return;
    }

    if (isEditing) {
      dispatch(
        updateWorkflowStep({
          condition_name: canvasData.steps[editingStepId].condition_name,
          step_fields: {
            images: [{ description: altText, url: saveUrl }],
          },
          stepId: editingStepId,
          transitions: canvasData.steps[editingStepId].transitions,
        }),
      );
    } else {
      dispatch(
        addImageStep({
          is_entry_step: shouldSqueezeIntoEntry || isEntryStep,
          parent_step_id: isSqueezingStep ? squeezeStepParentId : lastStepId,
          step_fields: {
            images: [{ description: altText, url: saveUrl }],
          },
          step_type: StepTypes.IMAGE,
        }),
      );
    }

    setIsSaving(false);
    resetCanvas();
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    if (isEditing && stepFields) {
      setImageUrl(stepFields.images[0].url);
      setAltText(stepFields.images[0].description);
    }
  }, [isEditing, stepFields]);

  const resetCanvas = useCallback(() => {
    // onDiscard MUST be called first
    onDiscard();
    dispatch(setEditingStepId(''));
    dispatch(setEditingStepType(''));
    dispatch(clearSelectedImageFile());
    dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
  }, [dispatch, onDiscard, contextVariables]);

  return (
    <>
      <Dialog
        footer={
          <>
            <Button onClick={resetCanvas} variant='ghost'>
              Cancel
            </Button>
            <Button
              disabled={!altText || hasWorkflowConflict || !imageUrl}
              isLoading={isSaving}
              onClick={updateOrSaveImageAndStep}
              variant='main'
            >
              Save
            </Button>
          </>
        }
        onClose={resetCanvas}
        open
        title='Add Image'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            width: '600px',
          }}
        >
          {/* Image Preview */}
          <Box
            sx={{
              alignItems: 'center',
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
              maxHeight: '500px',
              minHeight: '200px',
            }}
          >
            <SafeImage
              alt={altText}
              fallbackAlt={
                'Image cannot be found, most likely due to context variables being part of the image url'
              }
              src={imageUrl}
              sx={{
                height: 'initial',
                maxHeight: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
                width: 'initial',
              }}
            />
          </Box>
          {/* Inputs */}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {/* Buttons */}
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <Button
                fullWidth
                onClick={() => {
                  inputRef.current?.click();
                }}
                variant='secondary'
              >
                Upload Image
              </Button>
              {selectedImage && (
                <Button
                  color='blue'
                  onClick={() => {
                    dispatch(clearSelectedImageFile());
                    setImageUrl('');
                  }}
                  variant='danger'
                >
                  Clear Image
                </Button>
              )}
            </Box>
            {/* Image URL */}
            <Box>
              <Typography variant='font14Bold'>Image URL</Typography>
              <ContextMention
                disabled={!!selectedImage}
                onChange={value => setImageUrl(value)}
                placeholder='Enter Image URL or Upload Image above'
                value={imageUrl}
              />
            </Box>
            {/* Alt text */}
            <Box>
              <Typography variant='font14Bold'>Alt Text</Typography>
              <TextField
                aria-label='Alt Text'
                multiline
                onChange={e => setAltText(e.target.value)}
                placeholder='Write a description of this photo for increased accessibility'
                required
                rows={3}
                value={altText}
              />
              <Typography color={theme.palette.grey[500]} variant='font12'>
                Alt text is read aloud to visually impaired users or when the
                image fails to load.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {/* Hidden image input */}
      <HiddenImageInput ref={inputRef} />
    </>
  );
};
