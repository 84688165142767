import { ValidateWorkflowResponse } from 'src/pages/workflow-builder-edit/types';

/**
 * Creates a new object with the step id as a key and the list of errors per step
 * as the value. This is needed to avoid redundant calculations on each step of the graph.
 */
export const formatErrorByStep = (
  stepErrors: ValidateWorkflowResponse['errors'],
) => {
  const errors: { [key: string]: ValidateWorkflowResponse['errors'] } = {};

  stepErrors.forEach(error => {
    const stepId = error.step_id;
    if (!stepId) {
      return;
    }
    if (!errors[stepId]) {
      errors[stepId] = [];
    }
    errors[stepId].push(error);
  });

  return errors;
};
