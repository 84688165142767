import React, { FC } from 'react';
import { styled } from '@mui/system';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import { Label, Row, StyledInput } from '../handoff-configuration/styles';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';

type Props = {
  label?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  tooltip?: string;
  type?: 'text' | 'number';
  value: string | number;
};

const CustomizationInput: FC<React.PropsWithChildren<Props>> = ({
  label,
  onChange,
  placeholder = 'Enter value here',
  tooltip,
  type = 'text',
  value,
}) => {
  return (
    <>
      <Row>
        <Label>{label}</Label>
        {tooltip && (
          <Tooltip tooltipContent={tooltip}>
            <Icon src={tooltipInfoIcon} />
          </Tooltip>
        )}
      </Row>
      <StyledInput
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
        width='inherit'
      />
    </>
  );
};

export default CustomizationInput;

const Icon = styled('img')`
  margin-bottom: 8px;
  margin-left: 4px;
`;
