import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export const ImageUploadError = () => {
  const theme = useTheme();
  return (
    <ImageErrorContainer>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          color={theme.palette.colors.red[500]}
          variant='font14Medium'
        >
          Keep the aspect ratio of an image at 1:1 <br />
          For example, 300 x 300 pixel
        </Typography>
      </Box>
    </ImageErrorContainer>
  );
};

const ImageErrorContainer = styled('div')`
  height: 96px;
  width: 100%;
  border: 1px solid ${props => props.theme.palette.colors.red[500]};
  background-color: rgba(216, 44, 13, 0.05);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
