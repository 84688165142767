import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface RelatedTopicsProps {
  onTopicClick: (event: React.MouseEvent) => void;
  topicNames: string;
}
const RelatedTopics = ({ onTopicClick, topicNames }: RelatedTopicsProps) => {
  const { palette } = useTheme();
  return (
    <Box
      borderRadius={1}
      onClick={onTopicClick}
      overflow='hidden'
      px={1}
      py={0.5}
      sx={{
        '&>div': { width: '100%' },
        backgroundColor: palette.colors.slate[100],
        maxWidth: '100%',
      }}
    >
      <Tooltip tooltipContent={topicNames}>
        <Typography color={palette.colors.grey[700]} noWrap variant='font12'>
          {topicNames}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default RelatedTopics;
