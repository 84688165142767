import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Box, styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconEye, IconLink } from '@tabler/icons-react';

import {
  IconButton,
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import useIsDeflectionInsightsEnabled from '../intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import moreIcon from '../workflow-builder-edit/assets/icons/more-icon.svg';
import moreIconWhite from '../workflow-builder-edit/assets/icons/more-icon-white.svg';
import { useBuildInsightsChatNavigationCallback } from '../workflow-builder-edit/hooks';
import { useSolveMetricsQueryParams } from './intent-workflows-table/hooks/useSolveMetricsQueryParams';
import conversationsIcon from 'src/assets/images/conversations.svg';
import copyIcon from 'src/assets/images/copy-icon.svg';
import deleteIcon from 'src/assets/images/delete-icon.svg';
import editIcon from 'src/assets/images/edit-icon.svg';
import { dateRangeToTimestamp } from 'src/components/dashboard-pages/solve-insights/helpers';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { WorkflowBuilderHeaderChannel } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

interface WorkflowDropdownMenuProps {
  intentId: string;
  intentName: string;
  isDarkMode?: boolean;
  isDisabled?: boolean;
  isHandoff?: boolean;
  onDeleteClicked: () => void;
  onDuplicateClicked?: () => void;
  onEditClicked: () => void;
  onPreviewClicked?: () => void;
  shouldEnableDelete: boolean;
  shouldShowChats: boolean;
  shouldShowDuplicate: boolean;
  shouldShowEdit: boolean;
  shouldShowPreview: boolean;
}

export const WorkflowDropdownMenu: React.FC<
  React.PropsWithChildren<WorkflowDropdownMenuProps>
> = ({
  intentId,
  intentName,
  isDarkMode = false,
  isDisabled = false,
  isHandoff = false,
  onDeleteClicked,
  onDuplicateClicked,
  onEditClicked,
  onPreviewClicked,
  shouldEnableDelete,
  shouldShowChats,
  shouldShowDuplicate,
  shouldShowEdit,
  shouldShowPreview,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { channel } = useSolveMetricsQueryParams();

  const navigateToInsightsChat =
    useBuildInsightsChatNavigationCallback(intentId);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChatNavigation = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (channel === 'widget') {
      navigateToInsightsChat();
      return;
    }

    const header: WorkflowBuilderHeaderChannel = 'chats';
    const currentQuery = new URLSearchParams(search);
    const defaultTimestamp = dateRangeToTimestamp(last30DaysTimeRange);
    const startTime =
      currentQuery.get('start') || defaultTimestamp.start_timestamp.toString();
    const endTime =
      currentQuery.get('end') || defaultTimestamp.end_timestamp.toString();

    const queryParams = new URLSearchParams({
      channel,
      end: endTime,
      header,
      start: startTime,
    });

    if (isDeflectionInsightsEnabled) {
      queryParams.set('filters', `intent_ids.${intentId}`);
    } else {
      queryParams.set('intentId', intentId);
    }

    navigate({
      pathname: Routes.WORKFLOW_BUILDER,
      search: '?' + queryParams.toString(),
    });
  };

  return (
    <>
      <IconButton
        aria-label={`workflow dropdown menu for ${intentName}`}
        disabled={isDisabled}
        onClick={handleClick}
        variant='ghost'
      >
        <ReactSVG src={isDarkMode ? moreIconWhite : moreIcon} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={(e: React.MouseEvent) => {
          e.stopPropagation();
          handleClose();
        }}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            border: `1px solid ${theme.palette.colors.grey[200]}`,
            borderRadius: '4px',
            boxShadow:
              '0px 0px 1px rgba(0, 12, 32, 0.04), 2px 5px 8px rgba(3, 17, 38, 0.107135)',
            padding: '4px 0',
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {shouldShowPreview && (
          <StyledMenuItem
            aria-label='Preview intent'
            disableRipple
            onClick={e => {
              e.stopPropagation();
              onPreviewClicked && onPreviewClicked();
              handleClose();
            }}
          >
            <IconEye size={20} />
            <Typography variant='font14'>Preview intent</Typography>
          </StyledMenuItem>
        )}
        {shouldShowEdit && (
          <StyledMenuItem
            aria-label='Edit intent'
            disableRipple
            onClick={e => {
              e.stopPropagation();
              onEditClicked();
              handleClose();
            }}
          >
            <StyledReactSVG src={editIcon} />
            <Typography variant='font14'>
              Edit {!isHandoff && 'intent'}
            </Typography>
          </StyledMenuItem>
        )}
        {shouldShowDuplicate && (
          <StyledMenuItem
            aria-label='Duplicate intent'
            disableRipple
            onClick={e => {
              e.stopPropagation();
              onDuplicateClicked && onDuplicateClicked();
              handleClose();
            }}
          >
            <StyledReactSVG src={copyIcon} />
            <Typography variant='font14'>Duplicate intent</Typography>
          </StyledMenuItem>
        )}
        {!!intentId && (
          <StyledMenuItem
            aria-label='Copy intent ID'
            disableRipple
            onClick={e => {
              e.stopPropagation();
              navigator.clipboard.writeText(intentId);
              dispatch(
                setGlobalToastOptions({
                  title: 'Intent ID copied',
                  variant: 'main',
                }),
              );
              handleClose();
            }}
          >
            <Tooltip tooltipContent='Copy intent ID'>
              <Box display='flex' gap='4px'>
                <IconLink size='20px' />
                <Typography variant='font14'>Intent ID</Typography>
              </Box>
            </Tooltip>
          </StyledMenuItem>
        )}
        {shouldShowChats && (
          <StyledMenuItem
            aria-label={`View chats for ${intentName}`}
            disableRipple
            onClick={handleChatNavigation}
          >
            <StyledReactSVG src={conversationsIcon} />
            <Typography variant='font14'>Chats</Typography>
          </StyledMenuItem>
        )}
        <StyledMenuItem
          aria-label={`Delete ${intentName}`}
          disabled={!shouldEnableDelete}
          disableRipple
          onClick={e => {
            e.stopPropagation();
            onDeleteClicked();
            handleClose();
          }}
        >
          <StyledReactSVG src={deleteIcon} />
          <Typography color={theme.palette.colors.red[500]} variant='font14'>
            Delete
          </Typography>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    background-color: ${theme.palette.colors.purple[100]};
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledReactSVG = styled(ReactSVG)`
  height: 20px;
`;
