import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Checkbox,
  Table,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ICON_SIZE } from '../dashboard-pages/discover-automation-page/constants';
import { useGetDiscoverRecommendations } from './hooks/useGetDiscoverRecommendationDetails';
import AutomationModalActionRow from './AutomationModalActionRow';
import {
  CREATE_INTENT_WITH_LESS_THAN_FIVE_PHRASES_MIN_REQUIRED_PHRASES,
  DISCOVER_MODAL_TEXT,
} from './constants';
import BaseModal from 'src/components/base-modal';
import { useGetMetadata } from 'src/hooks/discover/useGetMetadata';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';
import {
  selectGlobalDiscoverOptions,
  setGlobalDiscoverOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import {
  deriveTopicNameFromTopic,
  getCostReductionCopy,
  getDisplayableTimeFromSecondsAsString,
  handleDiscoverBannerValues,
} from 'src/utils/discover/helpers';

const AutomationModal = () => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { postAutomationActionModalOptions, topicToAutomate } = useSelector(
    selectGlobalDiscoverOptions,
  );

  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const { palette } = useTheme();

  const { data, loading } = useGetDiscoverRecommendations(
    topicToAutomate?.topic_id,
  );
  const neighboringTopics = data?.neighbor_topics ?? [];

  const [shouldShowScrollBorders, setShouldShouldScrollBorders] =
    useState(false);
  const [selectedNeighboringTopicIds, setSelectedNeighboringTopicIds] =
    useState<string[]>([]);
  const selectedNeighboringTopics = selectedNeighboringTopicIds.map(topic => {
    return neighboringTopics.find(neighbor => neighbor.topic_id === topic);
  });

  const discoverBannerValues = handleDiscoverBannerValues(
    selectedNeighboringTopics,
    topicToAutomate?.actionable_value?.solve_workflow,
  );
  const { costValue, fullResolutionTimeValue, volumeValue } =
    discoverBannerValues;
  const isOpen = Boolean(
    topicToAutomate && !postAutomationActionModalOptions.isOpen && !loading,
  );
  const { summaries } = topicToAutomate ?? { summaries: [] };

  const minRequiredPhrases =
    CREATE_INTENT_WITH_LESS_THAN_FIVE_PHRASES_MIN_REQUIRED_PHRASES;

  const canCreateAutomation = Number(summaries?.length) >= minRequiredPhrases;
  const isSolveEnabled = Boolean(paidPlanData?.enablement_info.flamethrower);
  const { data: metadata } = useGetMetadata();
  const {
    description: costReductionDescription,
    tooltip: costReductionTooltip,
  } = getCostReductionCopy(metadata?.ticket_cost_info);

  const handleSummaries = () => {
    const allSummaries = selectedNeighboringTopics.reduce(
      (acc, topic) => acc.concat(topic?.summaries ?? []),
      summaries ?? [],
    );
    // dedupe
    return Array.from(new Set(allSummaries));
  };

  const totalSummaries = handleSummaries();

  return (
    <BaseModal
      featureName='automationmodal'
      hasScrolled={shouldShowScrollBorders}
      HeaderProps={{
        pb: 4,
      }}
      headerTitle={
        <Typography variant='font20'>
          Automate topic &lsquo;
          {deriveTopicNameFromTopic(topicToAutomate)}
          &rsquo;
        </Typography>
      }
      isOpen={isOpen}
      maxWidth='lg'
      onClose={() => {
        emitTrackingEventCallback('discover-automation-modal-closed', {
          pathname: location.pathname,
          'topic-name': topicToAutomate?.topic_name,
        });
        dispatch(setGlobalDiscoverOptions({ topicToAutomate: null }));
        setShouldShouldScrollBorders(false);
        setSelectedNeighboringTopicIds([]);
      }}
    >
      <DialogContent
        onScroll={() => setShouldShouldScrollBorders(true)}
        sx={{ pb: 0, pt: 1, px: 3 }}
      >
        <Box mb={2}>
          <Typography variant='font16Bold'>
            Estimated annual savings:
          </Typography>
        </Box>
        <Box
          data-appcues-target={getAppCuesId({
            componentType: 'information',
            featureName: 'automationmodal',
            pageName: 'discover',
            subType: 'savings',
          })}
          display='flex'
          mb={4}
        >
          <AutomationMetric
            description={DISCOVER_MODAL_TEXT.volumeDescription}
            title={DISCOVER_MODAL_TEXT.volumeTitle}
            value={volumeValue}
          />
          <AutomationMetric
            description={DISCOVER_MODAL_TEXT.resolutionTimeDescription}
            title={DISCOVER_MODAL_TEXT.resolutionTimeTitle}
            value={fullResolutionTimeValue}
          />
          <AutomationMetric
            description={costReductionDescription}
            title={DISCOVER_MODAL_TEXT.costTitle}
            tooltip={costReductionTooltip}
            value={costValue}
          />
        </Box>

        {isSolveEnabled && (
          <SimilarTopicSection
            neighboringTopics={neighboringTopics}
            selectedNeighboringTopicIds={selectedNeighboringTopicIds}
            setSelectedNeighboringTopicIds={setSelectedNeighboringTopicIds}
          />
        )}
        {totalSummaries.length && (
          <Box
            bgcolor={palette.colors.slate[100]}
            border='1px solid'
            borderColor={palette.colors.slate[200]}
            borderRadius='8px'
            data-appcues-target={getAppCuesId({
              componentType: 'information',
              featureName: 'automationmodal',
              pageName: 'discover',
              subType: 'summaries',
            })}
            display='flex'
            flexDirection='column'
            mb='16px'
            mt='32px'
            p='16px'
          >
            <Box mb='12px'>
              <Typography variant='font14Bold'>
                The automation will generate the following key phrases:
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              maxHeight='300px'
              overflow='auto'
            >
              {totalSummaries.map(summary => (
                <Typography key={summary} variant='font14'>
                  <i>&quot;{summary}&quot;</i>
                </Typography>
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        data-appcues-target={getAppCuesId({
          componentType: 'container',
          featureName: 'automationmodal',
          pageName: 'discover',
          subType: 'calltoactions',
        })}
        sx={[
          {
            padding: 3,
          },
          shouldShowScrollBorders && {
            borderTop: `1px solid ${palette.colors.slate[300]}`,
          },
        ]}
      >
        {topicToAutomate && (
          <AutomationModalActionRow
            canCreateAutomation={canCreateAutomation}
            isSolveEnabled={isSolveEnabled}
            selectedNeighboringTopicIds={selectedNeighboringTopicIds}
            topic={topicToAutomate}
          />
        )}
      </DialogActions>
    </BaseModal>
  );
};

interface AutomationMetricProps {
  description: string;
  title: string;
  tooltip?: string;
  value: string;
}

const AutomationMetric = ({
  description,
  title,
  tooltip,
  value,
}: AutomationMetricProps) => {
  const { palette } = useTheme();
  return (
    <Box display='flex' flex={1} flexDirection='column'>
      <Box alignItems='flex-start' display='flex' mb={0.5}>
        <Typography variant='font32'>
          {value === 'N/A' ? '' : '≈'}
          {value}
        </Typography>
      </Box>
      <Box mb={0.25}>
        <Typography variant='font14Bold'>{title}</Typography>
      </Box>
      <Typography color={palette.colors.grey[700]} variant='font12'>
        {description}
        {tooltip && (
          <Tooltip tooltipContent={tooltip}>
            <TransformedIconInfoCircle
              size={ICON_SIZE}
              stroke={palette.colors.grey[600]}
              strokeWidth={2}
            />
          </Tooltip>
        )}
      </Typography>
    </Box>
  );
};

interface SimilarTopicSectionProps {
  neighboringTopics: DiscoverTopic[];
  selectedNeighboringTopicIds: string[];
  setSelectedNeighboringTopicIds: (values: string[]) => void;
}

const SimilarTopicSection = ({
  neighboringTopics,
  selectedNeighboringTopicIds,
  setSelectedNeighboringTopicIds,
}: SimilarTopicSectionProps) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { palette } = useTheme();

  if (neighboringTopics.length === 0) {
    return null;
  }

  const handleOnChange = (topicId: string, topicName: string) => {
    const isChecked = selectedNeighboringTopicIds.includes(topicId);
    if (isChecked) {
      setSelectedNeighboringTopicIds(
        selectedNeighboringTopicIds.filter(item => item !== topicId),
      );
    } else {
      setSelectedNeighboringTopicIds([...selectedNeighboringTopicIds, topicId]);
    }
    emitTrackingEventCallback(
      !isChecked
        ? 'discover-check-neighboring-topic-upon-automation'
        : 'discover-uncheck-neighboring-topic-upon-automation',
      {
        pathname: location.pathname,
        'topic-name': topicName,
      },
    );
  };

  const columns = [
    {
      cellRenderer: ({ topic_id, topic_name }: DiscoverTopic) => (
        <Checkbox
          aria-label='Select similar topic'
          checked={selectedNeighboringTopicIds.includes(topic_id)}
          onChange={() => handleOnChange(topic_id, topic_name)}
        />
      ),
      hideSortIcon: true,
      id: 'checkbox',
      label: '',
    },
    {
      cellRenderer: ({ topic_name }: DiscoverTopic) => (
        <Typography color={palette.colors.black} variant='font14'>
          {topic_name}
        </Typography>
      ),
      hideSortIcon: true,
      id: 'name',
      label: 'Topic',
      minWidth: '300px',
    },
    {
      cellRenderer: ({ actionable_value }: DiscoverTopic) => (
        <Typography color={palette.colors.black} variant='font14'>
          {actionable_value?.solve_workflow?.volume ?? 0}
        </Typography>
      ),
      hideSortIcon: true,
      id: 'volume',
      label: 'Reduction in ticket volume',
    },
    {
      cellRenderer: ({ actionable_value }: DiscoverTopic) => (
        <Typography color={palette.colors.black} variant='font14'>
          {getDisplayableTimeFromSecondsAsString(
            actionable_value?.solve_workflow?.full_resolution_time ?? 0,
            1,
          )}
        </Typography>
      ),
      hideSortIcon: true,
      id: 'full resolution time',
      label: 'Reduction in average full resolution time',
    },
    {
      cellRenderer: ({ actionable_value }: DiscoverTopic) => (
        <Typography color={palette.colors.black} variant='font14'>
          ${actionable_value?.solve_workflow?.cost ?? 0}
        </Typography>
      ),
      hideSortIcon: true,
      id: 'cost',
      label: 'Reduction in cost',
    },
  ];

  return (
    <Box display='flex' flexDirection='column'>
      <Typography color={palette.colors.black} variant='font16Bold'>
        We recommend including these similar topics:
      </Typography>
      <Box>
        <Table
          columns={columns}
          rows={neighboringTopics}
          tableRowStyles={topic => ({
            '& .MuiTableCell-root': {
              '&:first-of-type': {
                borderRight: 0,
              },
              '&:last-child': {
                borderRight: 0,
              },
              bgcolor: selectedNeighboringTopicIds.includes(topic.topic_id)
                ? palette.colors.purple[100]
                : palette.colors.white,
              borderBottomColor: palette.colors.slate[200],
              borderRight: 1,
              borderRightColor: palette.colors.slate[200],
              height: '36px',
              padding: '0px 0px 0px 8px',
            },
          })}
        />
      </Box>
    </Box>
  );
};

const TransformedIconInfoCircle = styled(IconInfoCircle)`
  transform: translateX(3px) translateY(4px);
`;

export default AutomationModal;
