import React from 'react';
import { styled } from '@mui/material/styles';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

export default function Field({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) {
  return (
    <div>
      <Label>
        <Typography color={theme.palette.colors.grey[500]} variant='font11'>
          {label}
        </Typography>
      </Label>
      {children}
    </div>
  );
}

const Label = styled('div')`
  margin-bottom: 6px;
`;
