import Box from '@mui/material/Box';

import { Button, Tabs } from '@forethought-technologies/forethought-elements';
import TestModelPanel from '../triage-model-detail-page/drawers-and-dialogs/TestModelPanel';
import { useTestDrawer } from '../triage-model-detail-page/hooks/useTestDrawer';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';

const tabs = ['Labels', 'Configurations'];

const VersionControlTabs = ({
  model,
  onChange,
  tabIndex,
}: {
  model: VersionedTriageModel;
  onChange: (index: number) => void;
  tabIndex: number;
}) => {
  const emitTrackingEventCallback = useSelfServeEvents({
    model,
  });
  const { isTestDrawerOpen, setIsTestDrawerOpen, testDisabled } =
    useTestDrawer(model);

  return (
    <Box
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      px={5}
    >
      <Tabs
        a11yProps={index => {
          return {
            'aria-controls': `versions-tabpanel-${index}`,
            id: `versions-tab-${index}`,
          };
        }}
        aria-label='triage versions tabs'
        onChange={(_, value) => {
          onChange(value);
          const tabTrackingEvent =
            tabs[value] === 'Labels'
              ? TRIAGE_LLM_TRACKING_EVENTS.LABELS_VERSIONS_TAB_CLICKED
              : TRIAGE_LLM_TRACKING_EVENTS.CONFIGURATION_VERSIONS_TAB_CLICKED;
          emitTrackingEventCallback(tabTrackingEvent);
        }}
        tabs={tabs}
        typographyVariant='font16Bold'
        value={tabIndex}
      />
      <Button
        disabled={testDisabled}
        onClick={() => {
          setIsTestDrawerOpen(true);
          emitTrackingEventCallback(
            TRIAGE_LLM_TRACKING_EVENTS.TEST_VERSION_OPENED,
          );
        }}
        variant='secondary'
      >
        Test
      </Button>
      <TestModelPanel
        isOpen={isTestDrawerOpen}
        model={model}
        setIsOpen={setIsTestDrawerOpen}
      />
    </Box>
  );
};

export default VersionControlTabs;
