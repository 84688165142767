import React, { Dispatch, FC, SetStateAction, useRef } from 'react';
import { styled } from '@mui/material';

import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { ErrorMessage } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { FieldNameToIdx } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import { ZendeskHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface CustomMessageProps {
  errorMessages: Array<string | Array<string>>;
  initialValue: string;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  setCustomizationData: Dispatch<SetStateAction<ZendeskHandoffCustomization>>;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const CustomMessage: FC<React.PropsWithChildren<CustomMessageProps>> = ({
  errorMessages,
  initialValue,
  isFieldTouchedArr,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  const errorMessage = errorMessages[FieldNameToIdx.CUSTOM_MESSAGE];
  const isTouched = !!isFieldTouchedArr[FieldNameToIdx.CUSTOM_MESSAGE];
  const showErrorMessage = !!errorMessage && isTouched;
  const editorRef = useRef<EditorRef>(null);

  return (
    <>
      <RichTextEditorContainer isError={showErrorMessage}>
        <RichTextEditor
          editorRef={editorRef}
          initialContent={initialValue}
          onBlur={() => {
            if (!isTouched) {
              const arrCopy = [...isFieldTouchedArr];
              arrCopy[FieldNameToIdx.CUSTOM_MESSAGE] = true;
              setIsFieldTouchedArr(arrCopy);
            }
          }}
          onChange={html => {
            setCustomizationData(handoffData => {
              return {
                ...handoffData,
                ticket_creation_settings: {
                  ...handoffData.ticket_creation_settings,
                  custom_message: html,
                },
              };
            });
          }}
          placeholder='Add custom message'
        />
      </RichTextEditorContainer>
      {showErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

const RichTextEditorContainer = styled('div')<{ isError: boolean }>`
  background: ${props => props.theme.palette.background.default};
  border: 1px solid
    ${props =>
      props.isError
        ? props.theme.palette.error.main
        : props.theme.palette.colors.grey[200]};
  border-radius: 6px;
`;

export default CustomMessage;
