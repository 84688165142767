import React from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from '@sentry/react';

import ErrorMessage from '../error-message/ErrorMessage';

interface SentryErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: ErrorBoundaryProps['fallback'];
}

/**
 * Wrapped ErrorBoundary from Sentry with default fallback error message.
 * This will catch any error that bubbles up from a child component, send an error to sentry, and display the fallback.
 */
export default function SentryErrorBoundary({
  children,
  fallback = <ErrorMessage />,
}: SentryErrorBoundaryProps) {
  return <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>;
}
