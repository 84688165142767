import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { noWorkflowFoundErrorMessage } from 'src/constants/apiErrors';
import { selectTimeRange } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import {
  selectCanvasErrors,
  setErrors,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { Routes } from 'src/utils/enums';
import { constructTimeRangeQueryParams } from 'src/utils/timeRangeHelpers';

const removeNoWorkflowFoundErrorMessage = (errors: Array<string>) => {
  const index = errors.indexOf(noWorkflowFoundErrorMessage);
  const errorsCopy = [...errors];
  if (index > -1) {
    errorsCopy.splice(index, 1);
  }

  return errorsCopy;
};

export const useRedirectToLandingPageOnError = () => {
  const disaptch = useDispatch();
  const navigate = useNavigate();
  const errors = useSelector(selectCanvasErrors);
  const timeRange = useSelector(selectTimeRange);

  useEffect(() => {
    const shouldRedirectToLandingPage = errors.includes(
      noWorkflowFoundErrorMessage,
    );
    if (shouldRedirectToLandingPage) {
      disaptch(setErrors(removeNoWorkflowFoundErrorMessage(errors)));
      navigate({
        pathname: Routes.WORKFLOW_BUILDER,
        search: `?${constructTimeRangeQueryParams(timeRange, 'widget')}`,
      });
    }
  }, [errors, navigate, timeRange, disaptch]);
};
