import '../chart.scss';

import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material';

import { ExtendedBarTooltipFormatterContextObject } from '../types';
import { renderChartTooltip } from './renderChartTooltip';
import patternFill from 'highcharts/modules/pattern-fill';
import {
  BarGraphDataType,
  BarGraphValueType,
} from 'src/services/insights/types';

patternFill(Highcharts);

// Numbers will have commas
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

export interface BarGraphData {
  categories?: string[];
  values: BarGraphDataType[];
}
export interface ManualBarGraphData {
  color: string;
  data: number[];
  name: string;
  type: 'column';
}

interface BarGraphProps {
  colors?: string[];
  containerProps?: { [key: string]: { [key: string]: string } };
  data?: BarGraphDataType;
  disableTooltip?: boolean;
  disableXAxisLabel?: boolean;
  disableYAxisLabel?: boolean;
  disableYGridLines?: boolean;
  horizontal?: boolean;
  isComparing?: boolean;
  isStacked?: boolean;
  manualData?: ManualBarGraphData[];
  showDataLabel?: boolean;
  showLegend?: boolean;
  transparentBackground?: boolean;
}

const BarGraph = ({
  colors,
  containerProps,
  data,
  disableTooltip = false,
  disableXAxisLabel = false,
  disableYAxisLabel = false,
  disableYGridLines = false,
  horizontal = false,
  isComparing = false,
  isStacked = false,
  manualData,
  showDataLabel = false,
  showLegend = false,
  transparentBackground = false,
}: BarGraphProps) => {
  const theme = useTheme();
  const { palette } = theme;

  const constructBarGraphOptions = (
    transformData:
      | Highcharts.SeriesColumnOptions[]
      | Highcharts.SeriesBarOptions[],
  ) => {
    // Label is used as the X-Axis
    const xAxisCategories = data?.values.map(value => value.label);

    return {
      chart: {
        backgroundColor: transparentBackground ? 'rgba(0,0,0,0)' : undefined,
        // Stops data label from cutting off
        marginRight: 25,
        spacing: isComparing ? [0, 0, 0, 0] : [10, 10, 15, 10],
        type: horizontal ? 'bar' : 'line',
      },
      colors: colors,
      credits: {
        enabled: false,
      },
      lang: {
        thousandsSep: ',',
      },
      legend: {
        enabled: showLegend,
        itemDistance: 8,
        itemStyle: {
          color: palette.colors.black,
          fontFamily: theme.typography.fontFamily,
          fontSize: '12px',
          fontWeight: '400',
        },
        reversed: !isStacked,
      },
      plotOptions: {
        column: {
          colorByPoint: isStacked ? false : Boolean(colors),
          pointPadding: 0,
        },
        series: {
          dataLabels: {
            crop: false,
            enabled: showDataLabel,
            overflow: 'allow',
            style: {
              color:
                isStacked && horizontal && manualData
                  ? palette.colors.grey[600]
                  : palette.colors.black,
              fontFamily: theme.typography.fontFamily,
              fontSize: '12px',
              fontWeight: 400,
              textOutline: undefined,
            },
            y: isStacked && horizontal && manualData ? 20 : undefined,
          },
          groupPadding: 0.1,
          pointWidth: 16,
          stacking: isStacked ? 'normal' : undefined,
          states: {
            hover: {
              enabled: !disableTooltip,
            },
            inactive: {
              opacity: !disableTooltip ? undefined : 1,
            },
          },
        },
      },
      series: transformData,
      title: {
        align: 'left',
        style: {
          fontFamily: theme.typography.fontFamily,
          fontSize: '16px',
          fontWeight: '600',
        },
        text: undefined,
        y: 0,
      },
      tooltip: {
        backgroundColor: theme.palette.colors.white,
        borderColor: theme.palette.colors.slate[200],
        borderWidth: 0,
        enabled: !disableTooltip,
        formatter: function (this: ExtendedBarTooltipFormatterContextObject) {
          return renderChartTooltip(theme, this, isStacked, isComparing);
        },
        hideDelay: 0,
        outside: true,
        padding: 0,
        shadow: false,
        shared: true,
        style: {
          fontFamily: theme.typography.fontFamily,
          fontSize: '14px',
        },
        useHTML: true,
      },
      xAxis: {
        categories: xAxisCategories ?? [],
        gridLineWidth: 0,
        labels: {
          distance: 8,
          enabled: !disableXAxisLabel,
          style: {
            color: palette.colors.grey[700],
            fontFamily: theme.typography.fontFamily,
            fontSize: '11px',
            fontWeight: '400',
          },
        },
        lineWidth: 0,
        maxPadding: 0,
        minPadding: 0,
      },
      yAxis: {
        // Fixes weird issue on single stacking bar when width is not fitting to container
        endOnTick: false,
        gridLineWidth: disableYGridLines ? 0 : 2,
        labels: {
          enabled: !disableYAxisLabel,
          style: {
            color: palette.colors.grey[700],
            fontFamily: theme.typography.fontFamily,
            fontSize: '11px',
            fontWeight: '400',
            whiteSpace: 'nowrap',
          },
        },
        reversedStacks: false,
        title: undefined,
      },
    };
  };

  const handleDataTransformation = ():
    | Highcharts.SeriesColumnOptions[]
    | Highcharts.SeriesBarOptions[]
    | null => {
    if (manualData) {
      return manualData;
    }
    if (data) {
      if (isStacked) {
        return (
          data?.categories?.map?.((category, categoryIndex) => {
            const dataValues = data.values.map(
              dataValue =>
                dataValue?.values?.find?.(value => value.label === category) ||
                null,
            );
            const currentValues = dataValues.map((dataValue, valueIndex) => ({
              categories: data?.categories || [],
              tooltip_header_label: data?.values?.[valueIndex]?.extended_label,
              y: dataValue ? dataValue.value : 0,
            }));
            const shouldBeHidden = dataValues.some(
              dataValue => dataValue?.hidden,
            );

            return {
              color: colors?.[categoryIndex] ?? undefined,
              data: currentValues,
              name: category,
              type: 'column',
              visible: !shouldBeHidden,
            };
          }) || null
        );
      } else if (isComparing) {
        // Gather all values in correct categories
        const categorizedValues = new Map<string, BarGraphValueType[]>();
        data.values.forEach(value => {
          const currentArray: BarGraphValueType[] =
            categorizedValues.get(value?.category ?? '') ?? [];
          currentArray.push(value);
          categorizedValues.set(value?.category ?? '', currentArray);
        });
        const baseData = Array.from(categorizedValues, ([name, data]) => ({
          data,
          name,
        }));
        // Add color/tooltip logic for comparisons
        const updatedBaseData = baseData.map((data, ctgIdx) => {
          const updateDataWithColor = data.data.map((value, idx) => {
            const color = colors?.[idx];
            return {
              actualColor: color,
              category: value.category,
              color:
                ctgIdx === 0
                  ? color
                  : {
                      pattern: {
                        backgroundColor: palette.colors.purple[100],
                        height: 10,
                        opacity: 1,
                        path: {
                          d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                          stroke: color,
                        },
                        patternTransform: 'scale(0.75,-0.75)',
                        width: 10,
                      },
                    },
              tooltip_values: value.tooltip_values,
              y: value.value,
            };
          });
          return { data: updateDataWithColor, name: data.name, type: 'bar' };
        });

        return updatedBaseData as Highcharts.SeriesBarOptions[];
      } else {
        const values = data.values.map(value => {
          return {
            categories: data.categories,
            tooltip_header_label: value.extended_label,
            type: value.type,
            y: value.value,
          };
        });
        return [{ data: values, type: 'column' }];
      }
    }
    return null;
  };

  const transformData = handleDataTransformation();
  if (!transformData) {
    return <></>;
  }
  const options = constructBarGraphOptions(transformData);

  return (
    <HighchartsReact
      containerProps={containerProps}
      highcharts={Highcharts}
      options={options}
    />
  );
};

export default BarGraph;
