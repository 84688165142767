import { useState } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useSendPreviewApiRequest } from '../hooks';
import { ResponseMessage } from './StyledComponents';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import useStrippedHTML from 'src/hooks/useStrippedHTML';
import {
  FormApiWidgetComponent,
  FormField,
} from 'src/slices/workflow-preview/types';

export const ApiFormStep = ({
  isTheLatestStep,
  step,
}: {
  isTheLatestStep: boolean;
  step: FormApiWidgetComponent;
}) => {
  const theme = useTheme();
  const sendPreviewApiRequest = useSendPreviewApiRequest();
  const [formValues, setFormValues] = useState<Record<string, string>>({});
  const isPlainText = useStrippedHTML(step.message) === step.message;

  const setValueForOutputVariable = (outputVariable: string, value: string) => {
    const updatedFormValues = {
      ...formValues,
    };
    updatedFormValues[outputVariable] = value;
    setFormValues(updatedFormValues);
  };

  const handleSendForm = () => {
    sendPreviewApiRequest({
      contextVariables: formValues,
    });
  };

  return (
    <Box display='flex' flexDirection='column' gap={1}>
      <ResponseMessage isPlainText={isPlainText}>
        <Typography variant='font14Medium'>
          <HtmlComponent
            content={step.message}
            linkifyHtmlOptions={solveLinkifyHtmlOptions}
          />
        </Typography>
      </ResponseMessage>
      {isTheLatestStep && (
        <Box
          border={`1px solid ${theme.palette.colors.slate[200]}`}
          borderRadius='10px'
          display='flex'
          flexDirection='column'
          gap={2}
          padding='12px 20px'
        >
          {step.form_fields.map((formField, index) => (
            <FormFieldInput
              formField={formField}
              key={index}
              setValueForOutputVariable={setValueForOutputVariable}
              value={formValues[formField.output_variable] || ''}
            />
          ))}
          <Button onClick={handleSendForm} variant='main'>
            Send
          </Button>
        </Box>
      )}
    </Box>
  );
};

const FormFieldInput = ({
  formField,
  setValueForOutputVariable,
  value,
}: {
  formField: FormField;
  setValueForOutputVariable: (outputVariable: string, value: string) => void;
  value: string;
}) => {
  return (
    <Box display='flex' gap='4px'>
      <TextField
        label={formField.placeholder}
        onChange={e =>
          setValueForOutputVariable(formField.output_variable, e.target.value)
        }
        placeholder={formField.placeholder}
        value={value}
      />
    </Box>
  );
};
