import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { Card, InlineData, TextDivider } from '../ConversationDrawer';
import ConversationTranscript from '../ConversationTranscript';
import { formatTimestamp } from '../helpers';
import { ConversationDetailResponse } from 'src/services/insights/types';

export const InteractiveEmailCard = ({
  conversationData,
}: {
  conversationData: ConversationDetailResponse;
}) => {
  return (
    <>
      <Box sx={{ pb: 2, pt: 3 }}>
        <TextDivider size='large' text='Engaged with interactive email' />
      </Box>
      <Box display='flex' flexDirection='column' gap='10px'>
        <Box alignItems='center' display='flex' gap='4px'>
          <Typography variant='font11'>Interactive email</Typography>
        </Box>
        <Card>
          <InlineData
            label='Timestamp'
            value={formatTimestamp(conversationData.start_datetime + 'Z')}
          />
          {conversationData.csat_score && (
            <InlineData label='CSAT' value={conversationData.csat_score} />
          )}
          <TextDivider size='large' text='Interactive email chat' />
          <ConversationTranscript
            transcript={conversationData.transcript}
            variant='drawer'
          />
        </Card>
      </Box>
    </>
  );
};
