import { useEffect } from 'react';

import { useLazyGetFreshchatGroupsQuery } from 'src/services/dashboard-api/dashboardApi';

export const useGetFreshChatGroups = (apiUrl: string) => {
  const [getGroups, { data: freshchatGroups = [] }] =
    useLazyGetFreshchatGroupsQuery();

  useEffect(() => {
    if (apiUrl) {
      getGroups(apiUrl);
    }
  }, [apiUrl, getGroups]);

  return freshchatGroups;
};
