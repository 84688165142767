import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getNotes } from 'src/actions/assist';
import { selectNotes } from 'src/slices/assist-notes';
import { useAppDispatch } from 'src/store/hooks';

export const useGetNotes = () => {
  const data = useSelector(selectNotes);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotes());
  }, [dispatch]);

  return data;
};
