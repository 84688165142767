export const formatCvsKey = (str: string) => {
  const name = str && str.replace(/[_-]/g, ' ');
  const nameArr = name.split(' ');
  const firstLetter = nameArr[0].charAt(0).toUpperCase();

  if (str === 'field_value') {
    return 'All Macros & articles';
  }

  if (nameArr.length > 1) {
    const secondLetter = nameArr[1].charAt(0).toUpperCase();

    return `${
      firstLetter +
      nameArr[0].slice(1) +
      ' ' +
      secondLetter +
      nameArr[1].slice(1)
    }`;
  }

  return firstLetter + nameArr[0].slice(1);
};
