import React from 'react';
import { Box } from '@mui/material';

import { DEFAULT_BANNER_IMAGE } from '../constants';
import { BannerImageConfig } from 'src/slices/solve-config/types';

type BannerImageProps = {
  config: BannerImageConfig;
};

const BannerImage: React.FC<React.PropsWithChildren<BannerImageProps>> = ({
  config,
}) => {
  if (!config.is_enabled) {
    return null;
  }

  const imageSrc = config.image || DEFAULT_BANNER_IMAGE;
  const altText = config.alt_text || 'banner image';

  if (config.link) {
    return (
      <a href={config.link} rel='noopener noreferrer' target='_blank'>
        <Box
          alt={altText}
          component='img'
          src={imageSrc}
          sx={{ width: '100%' }}
        />
      </a>
    );
  } else {
    return (
      <Box
        alt={altText}
        component='img'
        src={imageSrc}
        sx={{ width: '100%' }}
      />
    );
  }
};

export default BannerImage;
