import { Button, Dialog } from '@forethought-technologies/forethought-elements';
import { useDeleteActionMutation } from 'src/services/action-builder/actionBuilderApi';

interface ActionDeleteDialogProps {
  actionIdToDelete: string;
  onClose: () => void;
}

const ActionDeleteDialog = ({
  actionIdToDelete,
  onClose,
}: ActionDeleteDialogProps) => {
  const [deleteAction, { isLoading }] = useDeleteActionMutation();

  const isOpen = Boolean(actionIdToDelete);
  return (
    <Dialog
      footer={
        <>
          <Button onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            onClick={() => {
              deleteAction({ action_ids: [actionIdToDelete] });
              onClose();
            }}
            variant='danger'
          >
            Delete
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title='Delete this action?'
      variant='sm'
    >
      This action will be deleted permanently and will not be retrievable
    </Dialog>
  );
};

export default ActionDeleteDialog;
