import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import DropDownMenu from '../../../../reusable-components/dropdown-menu/dropdownMenu';
import { setChartYAxis } from 'src/actions/pageSettings/pageSettings';
import { selectChartYAxis } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { cleanStr } from 'src/utils/cleanStr';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';

interface Props {
  axisName?: string;
  dropdownOptions: Array<string>;
  hidden: boolean;
  hideYAxisDropdown?: boolean;
}

const AxisDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  axisName,
  dropdownOptions,
  hidden,
  hideYAxisDropdown,
}: Props) => {
  const dispatch = useDispatch();
  let isComponentMounted = true;
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const axisDropdownContainerRef: React.RefObject<HTMLDivElement> =
    useRef(null);
  const axisDropdownRef: React.RefObject<HTMLDivElement> = useRef(null);

  const chartDataFilterMenu = (e: Event, option: string): void => {
    setIsDropdownVisible(false);
    dispatch(setChartYAxis(option));
    // ToDo Track -> sendTrackingEvent(...)
  };

  const arrowClasses = classNames({
    'desc-selected -arrow': !hidden && isDropdownVisible,
    'disabled disabled-arrow': hidden,
    'down-arrow': !hidden,
    none: hideYAxisDropdown,
  });

  const chartYAxisSelected: string = useSelector(selectChartYAxis);

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        axisDropdownRef,
        axisDropdownContainerRef,
        setIsDropdownVisible,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);

  return (
    <div className='dropdown view-dropdown YAxis-cont' ref={axisDropdownRef}>
      <button
        aria-expanded='false'
        className='select'
        data-testid='dropdown-button'
        onClick={(): void => setIsDropdownVisible(!isDropdownVisible)}
        type='button'
      >
        <span
          className={
            !hidden
              ? 'yAxis-title selected-option chart-filter-selected'
              : 'yAxis-title chart-filter-selected'
          }
        >
          {axisName ||
            (!axisName && hidden && 'Totals') ||
            cleanStr(chartYAxisSelected)}
        </span>
        <div className={arrowClasses} />
      </button>
      {!hidden && isDropdownVisible && (
        <DropDownMenu
          buttonClass={'dropdown-option answers-chart-data-view '}
          data={dropdownOptions}
          dropdownClass={'dropdown-menu view-menu'}
          dropdownContClass={'answers-chart-data-menu '}
          onClick={chartDataFilterMenu}
          reference={axisDropdownContainerRef}
          selectedOption={chartYAxisSelected}
        />
      )}
    </div>
  );
};

export default AxisDropdown;
