import { useMemo } from 'react';
import linkifyHtml from 'linkify-html';
import sanitizeHtml from 'sanitize-html';
import { styled } from '@mui/material';

import { EmailStyleOverrides } from 'src/components/email-builder/components/EmailBuilderCanvas';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';

export function HTMLWithContext({ html }: { html: string }) {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: true,
  });

  const htmlWithContextVariables = useMemo(
    () =>
      contextVariableAliasHtml({
        contextVariables,
        html,
        undefinedContextVariableIds: [],
      }),
    [contextVariables, html],
  );

  return (
    <Container
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(
          linkifyHtml(htmlWithContextVariables, {
            rel: 'noreferrer',
            target: '_blank',
            truncate: 42,
          }),
          {
            allowedAttributes: {
              '*': ['style'],
            },
            allowedStyles: {
              '*': {
                color: [/^#?([a-f0-9]{6}|[a-f0-9]{3})$/], // allow color hex code style attribute for context variables
                'text-align': [/^left$/, /^right$/, /^center$/],
              },
            },
          },
        ),
      }}
    />
  );
}

const Container = styled(EmailStyleOverrides)`
  a {
    text-decoration: underline;
  }
  padding: 16px;
`;
