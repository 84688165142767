import { tagsInvalidCharactersRegex } from '../constants';
import { isContextVariable } from '../helpers';
import {
  formatMessageFromBackendForReactMentions,
  formatReactMentionsMessageToSendToBackend,
} from 'src/pages/solve-workflows/utils/stepHelper';
import { FieldNameToIdx } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import {
  ZendeskChatHandoffCustomization,
  ZendeskHandoffCustomization,
  ZendeskMessagingHandoffActionSettings,
  ZendeskOrSalesforceContextVariables,
  ZendeskTicketCreationCustomization,
} from 'src/types/workflowBuilderAPITypes';

export const initializeFieldTouchedArr = (
  customizationData:
    | ZendeskHandoffCustomization
    | ZendeskTicketCreationCustomization
    | ZendeskChatHandoffCustomization
    | ZendeskOrSalesforceContextVariables
    | ZendeskMessagingHandoffActionSettings,
) => {
  if (!customizationData) {
    return [];
  }
  const numFieldsToValidate = Object.keys(FieldNameToIdx).length;
  return new Array(numFieldsToValidate).fill(false);
};

const validateTags = (
  tags: string[],
  allowableContextVariables: ContextVariable[],
  cvTemplatesByName: string[],
) => {
  const ticketTagErrorMessages = [];
  const tagsSeenSoFar = new Set();

  for (const ticketTag of tags) {
    const displayTag = formatContextVariableForDisplay(
      allowableContextVariables,
      ticketTag,
    );

    if (tagsSeenSoFar.has(displayTag)) {
      ticketTagErrorMessages.push('Duplicate tags are not allowed');
    } else if (
      isContextVariable(displayTag) &&
      !cvTemplatesByName.includes(displayTag)
    ) {
      ticketTagErrorMessages.push(
        'The context variable in this tag was not found',
      );
    } else if (
      !isContextVariable(displayTag) &&
      tagsInvalidCharactersRegex.test(displayTag)
    ) {
      ticketTagErrorMessages.push(
        'Tags can only consist of alphanumerics, dashes (-), underscores (_), or forward slashes (/)',
      );
    } else {
      ticketTagErrorMessages.push('');
    }
    tagsSeenSoFar.add(displayTag);
  }
  return ticketTagErrorMessages;
};

export const validateChatOnlyCustomizationData = ({
  allowableContextVariables,
  customizationData,
}: {
  allowableContextVariables: Array<ContextVariable>;
  customizationData: ZendeskChatHandoffCustomization;
}) => {
  const numFieldsToValidate = Object.keys(FieldNameToIdx).length;
  const errorMessages = new Array(numFieldsToValidate).fill('');
  const cvTemplatesByName = allowableContextVariables.map(
    cv => `{{${cv.context_variable_name}}}`,
  );

  // Validate chat tags
  errorMessages[FieldNameToIdx.CHAT_TAGS] = validateTags(
    customizationData?.chat_tags || [],
    allowableContextVariables,
    cvTemplatesByName,
  );

  if (!customizationData) {
    return errorMessages;
  }

  if (!customizationData.name) {
    errorMessages[FieldNameToIdx.NAME] = 'Field cannot be empty';
  }
  if (!customizationData.email) {
    errorMessages[FieldNameToIdx.EMAIL] = 'Field cannot be empty';
  }
  if (!customizationData.question) {
    errorMessages[FieldNameToIdx.QUESTION] = 'Field cannot be empty';
  }

  return errorMessages;
};

export const validateCustomizationData = ({
  allowableContextVariables,
  customizationData,
}: {
  allowableContextVariables: Array<ContextVariable>;
  customizationData: ZendeskHandoffCustomization;
}) => {
  const numFieldsToValidate = Object.keys(FieldNameToIdx).length;
  const errorMessages = new Array(numFieldsToValidate).fill('');
  const cvTemplatesByName = allowableContextVariables.map(
    cv => `{{${cv.context_variable_name}}}`,
  );

  if (customizationData?.agent_chat_handoff_settings) {
    // Validate chat tags
    errorMessages[FieldNameToIdx.CHAT_TAGS] = validateTags(
      customizationData.agent_chat_handoff_settings?.chat_tags || [],
      allowableContextVariables,
      cvTemplatesByName,
    );
  }

  if (customizationData?.include_ticket_creation_handoff) {
    const config =
      customizationData.ticket_creation_settings?.ticket_creation_config || {};

    const cvTemplatesById = allowableContextVariables.map(
      cv => `{{${cv.context_variable_id}}}`,
    );

    if (!config.handoff_form_question_message.trim()) {
      errorMessages[FieldNameToIdx.HANDOFF_FORM_QUESTION_MESSAGE] =
        'Handoff message cannot be empty';
    }
    if (!config.confirmation_message.trim()) {
      errorMessages[FieldNameToIdx.CONFIRMATION_MESSAGE] =
        'Confirmation message cannot be empty';
    }

    const ticketBrand = config.ticket_brand || '';

    const isEmptyBrand = !ticketBrand.trim();
    const isSelectedAvailable =
      config.available_ticket_brands.includes(ticketBrand);
    const isValidCVBrand = cvTemplatesById.includes(ticketBrand);

    if (!isEmptyBrand && !isSelectedAvailable && !isValidCVBrand) {
      errorMessages[FieldNameToIdx.CUSTOM_BRAND_VALUE] =
        'The context variable in this brand was not found';
    }

    // Validate ticket tags
    errorMessages[FieldNameToIdx.TICKET_TAGS] = validateTags(
      customizationData.ticket_creation_settings?.ticket_creation_config
        ?.ticket_tags || [],
      allowableContextVariables,
      cvTemplatesByName,
    );

    if (!customizationData.ticket_creation_settings?.create_ticket) {
      if (customizationData.ticket_creation_settings?.custom_message === '') {
        errorMessages[FieldNameToIdx.CUSTOM_MESSAGE] =
          'Custom message cannot be empty';
      }
    }

    return errorMessages;
  }

  return errorMessages;
};

export const validateTicketCreationCustomizationData = ({
  allowableContextVariables,
  customizationData,
}: {
  allowableContextVariables: Array<ContextVariable>;
  customizationData: ZendeskTicketCreationCustomization | undefined;
}) => {
  const numFieldsToValidate = Object.keys(FieldNameToIdx).length;
  const errorMessages = new Array(numFieldsToValidate).fill('');
  const cvTemplatesByName = allowableContextVariables.map(
    cv => `{{${cv.context_variable_name}}}`,
  );

  if (!customizationData) {
    return errorMessages;
  }

  if (!customizationData.name) {
    errorMessages[FieldNameToIdx.NAME] = 'Field cannot be empty';
  }
  if (!customizationData.email) {
    errorMessages[FieldNameToIdx.EMAIL] = 'Field cannot be empty';
  }
  if (!customizationData.question) {
    errorMessages[FieldNameToIdx.QUESTION] = 'Field cannot be empty';
  }

  const config =
    customizationData.ticket_creation_settings?.ticket_creation_config || {};

  const cvTemplatesById = allowableContextVariables.map(
    cv => `{{${cv.context_variable_id}}}`,
  );

  const ticketBrand = config.ticket_brand || '';

  const isEmptyBrand = !ticketBrand.trim();
  const isSelectedAvailable =
    config.available_ticket_brands.includes(ticketBrand);
  const isValidCVBrand = cvTemplatesById.includes(ticketBrand);

  if (!isEmptyBrand && !isSelectedAvailable && !isValidCVBrand) {
    errorMessages[FieldNameToIdx.CUSTOM_BRAND_VALUE] =
      'The context variable in this brand was not found';
  }

  // Validate ticket tags
  errorMessages[FieldNameToIdx.TICKET_TAGS] = validateTags(
    customizationData.ticket_creation_settings?.ticket_creation_config
      ?.ticket_tags || [],
    allowableContextVariables,
    cvTemplatesByName,
  );

  return errorMessages;
};

export const validateZendeskOrSalesforceContextVariables = ({
  customizationData,
  shouldAllowEmptyName = false,
}: {
  customizationData: ZendeskOrSalesforceContextVariables | undefined;
  shouldAllowEmptyName?: boolean;
}) => {
  const numFieldsToValidate = Object.keys(FieldNameToIdx).length;
  const errorMessages: string[] = new Array(numFieldsToValidate).fill('');

  if (!customizationData) {
    return errorMessages;
  }

  if (!customizationData.name && !shouldAllowEmptyName) {
    errorMessages[FieldNameToIdx.NAME] = 'Field cannot be empty';
  }
  if (!customizationData.email) {
    errorMessages[FieldNameToIdx.EMAIL] = 'Field cannot be empty';
  }
  if (!customizationData.question) {
    errorMessages[FieldNameToIdx.QUESTION] = 'Field cannot be empty';
  }

  return errorMessages;
};

export const formatContextVariableForDisplay = (
  contextVariables: ContextVariable[],
  value: string | number,
): string => {
  return formatMessageFromBackendForReactMentions(
    contextVariables.map(cv => ({
      displayName: cv.context_variable_name,
      id: cv.context_variable_id,
      type: cv.context_variable_type,
    })),
    value.toString(),
  );
};

export const formatContextVariableForPersistence = (
  contextVariables: ContextVariable[],
  value: string,
): string => {
  return formatReactMentionsMessageToSendToBackend(
    contextVariables.map(cv => ({
      displayName: cv.context_variable_name,
      id: cv.context_variable_id,
      type: cv.context_variable_type,
    })),
    value,
  );
};

export const cleanCustomizationData = <
  CustomizationData extends
    | ZendeskHandoffCustomization
    | ZendeskTicketCreationCustomization,
>(
  customizationData: CustomizationData,
): CustomizationData => {
  if (
    !customizationData?.ticket_creation_settings?.ticket_creation_config
      ?.custom_fields_and_values
  ) {
    return customizationData;
  }
  const cleansedFieldsAndValues =
    customizationData.ticket_creation_settings.ticket_creation_config.custom_fields_and_values.filter(
      fieldAndValue => fieldAndValue.id > 0 && fieldAndValue.value,
    );

  return {
    ...customizationData,
    ticket_creation_settings: {
      ...customizationData.ticket_creation_settings,
      ticket_creation_config: {
        ...customizationData.ticket_creation_settings.ticket_creation_config,
        custom_fields_and_values: cleansedFieldsAndValues,
      },
    },
  };
};

export const validateZdMessagingCustomizationData = ({
  allowableContextVariables,
  customizationData,
}: {
  allowableContextVariables: Array<ContextVariable>;
  customizationData: ZendeskMessagingHandoffActionSettings;
}) => {
  const numFieldsToValidate = Object.keys(FieldNameToIdx).length;
  const errorMessages = new Array(numFieldsToValidate).fill('');
  const cvTemplatesByName = allowableContextVariables.map(
    cv => `{{${cv.context_variable_name}}}`,
  );

  // Validate ticket tags
  errorMessages[FieldNameToIdx.TICKET_TAGS] = validateTags(
    customizationData?.ticket_tags || [],
    allowableContextVariables,
    cvTemplatesByName,
  );

  if (!customizationData) {
    return errorMessages;
  }

  return errorMessages;
};
