import { useEffect, useState } from 'react';

import * as API from 'src/services/api';
import { EmbeddableLookerDashboard } from 'src/services/apiInterfaces';

export default function useEmbeddedDashboardSettings() {
  const [settings, setSettings] = useState<EmbeddableLookerDashboard[] | null>(
    null,
  );

  useEffect(() => {
    API.getLookerDashboardSettings()
      .then(setSettings)
      .catch(() => console.error('Error loading embedded dashboard settings'));
  }, []);

  return settings;
}
