import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogActions, DialogTitle } from '@mui/material';

import {
  Button,
  IconButton,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { usePatchVersionHistoryMutation } from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import { AutoflowVersionHistory } from 'src/types/workflowBuilderAPITypes';

interface VersionEditDialogProps {
  handleCloseMenu: () => void;
  isEditDialogOpen: boolean;
  setIsEditDialogOpen: (value: boolean) => void;
  versionHistory: AutoflowVersionHistory;
}

const VersionEditDialog = ({
  handleCloseMenu,
  isEditDialogOpen,
  setIsEditDialogOpen,
  versionHistory,
}: VersionEditDialogProps) => {
  const [patchVersionHistory] = usePatchVersionHistoryMutation();

  const [versionName, setVersionName] = React.useState(
    versionHistory.version_name,
  );
  const [versionDesc, setVersionDesc] = React.useState(
    versionHistory.description,
  );
  const isChanged =
    versionHistory.description !== versionDesc ||
    versionHistory.version_name !== versionName;

  const onClickSave = () => {
    const versionHistoryData = {
      body: {
        description: versionDesc,
        is_draft: versionHistory.is_draft,
        version_name: versionName,
      },
      intentWorkflowId: versionHistory.intent_workflow_id,
      version: versionHistory.version,
    };

    patchVersionHistory(versionHistoryData);
    handleCloseMenu();
    setIsEditDialogOpen(false);
  };

  const handleOnCancel = () => {
    handleCloseMenu();
    setIsEditDialogOpen(false);
    setVersionName(versionHistory.version_name);
    setVersionDesc(versionHistory.description);
  };

  return (
    <>
      <Dialog onClose={handleOnCancel} open={isEditDialogOpen}>
        <Box minWidth='600px'>
          <DialogTitle
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              px: 3,
              py: 2.5,
              typography: 'font20',
            }}
          >
            Edit version information
            <IconButton
              aria-label='close dialog'
              onClick={() => {
                handleCloseMenu();
                setIsEditDialogOpen(false);
              }}
              variant='ghost'
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Box
            display='flex'
            flexDirection='column'
            gap='4px'
            marginBottom='20px'
            px={3}
          >
            <Typography variant='font14Medium'>Version name</Typography>
            <TextField
              aria-label='Version name form'
              onChange={e => setVersionName(e.target.value)}
              placeholder='Name this version'
              value={versionName || ''}
            />
          </Box>
          <Box display='flex' flexDirection='column' gap='4px' px={3}>
            <Typography variant='font14Medium'>What changed</Typography>
            <TextField
              aria-label='Version description form'
              minRows={3}
              multiline
              onChange={e => setVersionDesc(e.target.value)}
              placeholder='Describe what changed'
              value={versionDesc || ''}
            />
          </Box>
        </Box>
        <DialogActions sx={{ px: 3, py: 2.5 }}>
          <>
            <Button onClick={handleOnCancel} variant='ghost'>
              Cancel
            </Button>
            <Button
              disabled={!isChanged}
              onClick={onClickSave}
              variant='secondary'
            >
              Save
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VersionEditDialog;
