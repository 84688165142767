import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { articleLogos } from '../../article-picker/constants';
import { ForethoughtEditStepMenu } from '../../edit-menu/ForethoughtEditStepMenu';
import StepContainer from './StepContainer';
import { ArticleStepFields, Step } from 'src/types/workflowBuilderAPITypes';

interface ArticleStepProps {
  isCanvasDisabled: boolean;
  setShouldShowEditMenu: (shouldShow: boolean) => void;
  shouldShowEditMenu: boolean;
  step: Step;
  stepId: string;
}

const ArticleStep = ({
  isCanvasDisabled,
  setShouldShowEditMenu,
  shouldShowEditMenu,
  step,
  stepId,
}: ArticleStepProps) => {
  const { palette } = useTheme();

  const stepFields = step.step_fields as ArticleStepFields;
  const hasCondition = !!step.condition_name;

  function getReadArticleActionTitle() {
    switch (stepFields.read_article_action) {
      case 'expand_in_widget':
        return 'Open in Widget';
      case 'none':
        return 'Read Article Disabled';
      case 'new_tab':
      default:
        return 'Open in New Tab';
    }
  }

  return (
    <StepContainer isBeingEdited={false}>
      <Box alignItems='center' display='flex' mb={1}>
        <Box
          alignItems='center'
          bgcolor={'white'}
          border={'1px solid var(--color-slate-200)'}
          borderRadius='16px'
          display='flex'
          mr={1}
          p='6px'
        >
          <img
            alt={stepFields.doc_type}
            src={articleLogos[stepFields.doc_type] || articleLogos.webpage}
          />
        </Box>
        <Typography color={palette.colors.grey[600]} variant='font11'>
          Articles ({getReadArticleActionTitle()})
        </Typography>
      </Box>
      <Box>
        <Typography variant='font16Bold'>
          {stepFields.document_title}
        </Typography>
      </Box>
      {!isCanvasDisabled && (
        <ForethoughtEditStepMenu
          hasCondition={hasCondition}
          isDeletionDisabled={hasCondition}
          isVisible={shouldShowEditMenu}
          setIsVisible={setShouldShowEditMenu}
          stepId={stepId}
          stepType={step.step_type}
        />
      )}
    </StepContainer>
  );
};

export default ArticleStep;
