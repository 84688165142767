import { useMemo } from 'react';
import { createMRTColumnHelper, MRT_Row } from 'material-react-table';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import TableDownloadButton from '../../common/table-download-button';
import FilterSelect from '../common/FilterSelect';
import TextWithInfo from '../common/TextWithInfo';
import TriageModelTable from '../common/TriageModelTable';
import ValueCell from '../common/ValueCell';
import {
  FILTER_KEY_FINALIZED_LABEL,
  FILTER_KEY_PREDICTED_LABEL,
  FILTER_OPTIONS,
  FILTER_OPTIONS_STATUS_ITEM,
  TABLE_COLUMNS,
} from './constants';
import { createLabelFiltersOptions, formatRowValue } from './helpers';
import TicketStatusTableValue from './TicketStatusTableValue';
import omit from 'lodash/omit';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { createFilterBody } from 'src/services/triage/helpers';
import { TriageTicket } from 'src/services/triage/types';
import { DateRange } from 'src/types/types';

interface TicketsTableProps {
  dateRange: DateRange;
  filters: string[];
  hasNextPage: boolean;
  isFetching: boolean;
  isLoading: boolean;
  model: VersionedTriageModel;
  onDateRangeChange: (value: DateRange) => void;
  onFilterChange: (filters: string[]) => void;
  onLoadMore: () => void;
  onSearch: (text: string) => void;
  onSearchTypeChange?: (value: string) => void;
  searchOptions?: { text: string; value: string }[];
  searchText: string;
  searchType?: string;
  tickets?: TriageTicket[];
}

const TicketsTable = ({
  dateRange,
  filters,
  hasNextPage,
  isFetching,
  isLoading,
  model,
  onDateRangeChange,
  onFilterChange,
  onLoadMore,
  onSearch,
  onSearchTypeChange,
  searchOptions,
  searchText,
  searchType,
  tickets,
}: TicketsTableProps) => {
  const location = useLocation();
  const isSimulation = location.pathname.includes('simulations');
  const columnHelper = createMRTColumnHelper<TriageTicket>();
  const dateRangeFilter = {
    onChange: onDateRangeChange,
    value: dateRange,
  };

  const updatedTableColumns = useMemo(() => {
    let column = [...TABLE_COLUMNS];
    if (isSimulation) {
      column = column.filter(
        item => item.key !== 'timestamp' && item.key !== 'written_to_helpdesk',
      );
    }
    return column;
  }, [isSimulation]);

  const columns = useMemo(() => {
    return updatedTableColumns.map(item => {
      return columnHelper.accessor(item.key, {
        Cell: ({ row }: { row: MRT_Row<TriageTicket> }) => {
          const rowValue = formatRowValue(item.key, row.original[item.key]);
          if (item.key === 'status') {
            return (
              <TicketStatusTableValue
                status={rowValue as TriageTicket['status']}
              />
            );
          }
          if (item.key === 'title' || item.key === 'body') {
            return (
              <Tooltip tooltipContent={String(rowValue)}>
                <ValueCell
                  isLoading={isLoading}
                  itemKey={item.key}
                  value={rowValue}
                />
              </Tooltip>
            );
          }
          if (item.key === 'current_label' || item.key === 'predicted_label') {
            return (
              <Tooltip tooltipContent={String(rowValue)}>
                <ValueCell
                  isLoading={isLoading}
                  itemKey={item.key}
                  value={rowValue}
                  wrap
                />
              </Tooltip>
            );
          }
          if (item.key === 'reason_no_prediction') {
            return (
              <Tooltip tooltipContent={String(rowValue)}>
                <ValueCell
                  isLoading={isLoading}
                  itemKey={item.key}
                  value={rowValue}
                  wrap
                />
              </Tooltip>
            );
          }
          return (
            <ValueCell
              isLoading={isLoading}
              itemKey={item.key}
              returnDefaultValue={
                item.key !== 'written_to_helpdesk' &&
                item.key !== 'is_prediction_accurate'
              }
              value={rowValue}
            />
          );
        },
        header: item.title,
        Header: (
          <TextWithInfo
            textColor={['grey', 800]}
            title={item.title}
            tooltip={item.tooltip}
            variant='font14Bold'
          />
        ),
        size: item.size,
      });
    });
  }, [columnHelper, isLoading, updatedTableColumns]);

  const filterOptions = useMemo(() => {
    const finalizedLabel = {
      label: 'Finalized label',
      options: createLabelFiltersOptions(model, FILTER_KEY_FINALIZED_LABEL),
      value: 'finalized_label_option',
    };

    const predictedLabel = {
      label: 'Predicted label',
      options: createLabelFiltersOptions(model, FILTER_KEY_PREDICTED_LABEL),
      value: 'predicted_label_option',
    };

    return [FILTER_OPTIONS_STATUS_ITEM, finalizedLabel, predictedLabel].concat(
      FILTER_OPTIONS.filter(item => {
        if (!isSimulation) {
          return true;
        }
        return item.label === 'Written to Helpdesk' ? false : true;
      }),
    );
  }, [model, isSimulation]);

  const keywordSearch = searchType === 'keyword' ? searchText : undefined;
  const ticketId = searchType === 'ticket_id' ? searchText : undefined;

  return (
    <TriageModelTable<TriageTicket, string | number | boolean | null>
      columns={columns}
      Control={
        isSimulation ? undefined : (
          <TableDownloadButton
            data_export_type='triage_llm_tickets_table'
            requestData={omit(
              createFilterBody({
                endDate: Number(dateRangeFilter.value.to),
                filters,
                keywordSearch,
                modelId: model.model_id,
                page: 0,
                startDate: Number(dateRangeFilter.value.from),
                ticketId,
              }),
              ['page'],
            )}
          />
        )
      }
      data={tickets || []}
      dateRangeFilter={isSimulation ? undefined : dateRangeFilter}
      FilterComponent={
        <FilterSelect
          maxHeight={400}
          menuTitle='Filter by'
          onChange={onFilterChange}
          options={filterOptions}
          value={filters}
        />
      }
      hasNextPage={hasNextPage}
      initialSorting={[{ desc: true, id: 'timestamp' }]}
      initialState={{
        columnPinning: {
          left: ['timestamp'],
        },
      }}
      isFetching={isFetching}
      isLoading={isLoading}
      onLoadMore={onLoadMore}
      onSearch={onSearch}
      onSearchTypeChange={onSearchTypeChange}
      renderEmptyRowsFallback={() => {
        if (!tickets?.length && !isFetching && !isLoading) {
          return (
            <Box alignItems='center' display='flex'>
              No search results found.
            </Box>
          );
        }
        return <div></div>;
      }}
      searchOptions={searchOptions}
      searchPlaceholderText='Search ticket'
      searchText={searchText}
      searchType={searchType}
      stickyHeaderHeight={160}
    />
  );
};

export default TicketsTable;
