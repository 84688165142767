import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import LandingPageSubTabs from '../landing-page/components/LandingPageSubTabs';
import { TemplateTable } from './TemplateTable';

export const TemplateTab = () => {
  return (
    <Box px={5}>
      <LandingPageSubTabs />
      <Header />
      <TemplateTable />
    </Box>
  );
};

const Header = () => {
  return (
    <Box p={2}>
      <Typography
        color={theme => theme.palette.colors.grey[800]}
        mr={1}
        variant='font14Bold'
      >
        Templates
      </Typography>
      <Typography
        color={theme => theme.palette.colors.grey[600]}
        variant='font14'
      >
        Here are examples to learn and preview workflows. Duplicate and
        customize them as needed.
      </Typography>
    </Box>
  );
};
