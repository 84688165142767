import React from 'react';
import { Box } from '@mui/material';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface RangeInputProps {
  handleOnClickApply: () => void;
  handleReset: () => void;
  label: string;
  leftEdgeNormalizedSelection: number;
  onChange: (value: number[]) => void;
  removeDecimal: boolean;
  rightEdgeNormalizedSelection: number;
}

const RangeInput = ({
  handleOnClickApply,
  handleReset,
  label,
  leftEdgeNormalizedSelection,
  onChange,
  removeDecimal,
  rightEdgeNormalizedSelection,
}: RangeInputProps) => {
  const handleChangeMin = (e: React.FocusEvent<HTMLInputElement>) =>
    onChange([Number(e.target.value), rightEdgeNormalizedSelection]);
  const handleChangeMax = (e: React.FocusEvent<HTMLInputElement>) =>
    onChange([leftEdgeNormalizedSelection, Number(e.target.value)]);

  return (
    <Box display='flex' gap={1}>
      <TextField
        aria-label='min-field'
        description={`Min ${label.toLowerCase()}`}
        onChange={handleChangeMin}
        type='number'
        value={String(
          removeDecimal
            ? Math.trunc(leftEdgeNormalizedSelection)
            : leftEdgeNormalizedSelection,
        )}
      />
      <Box mt={1.25}>
        <Typography variant='font16'>-</Typography>
      </Box>
      <TextField
        aria-label='max-field'
        description={`Max ${label.toLowerCase()}`}
        onChange={handleChangeMax}
        type='number'
        value={String(
          removeDecimal
            ? Math.trunc(rightEdgeNormalizedSelection)
            : rightEdgeNormalizedSelection,
        )}
      />
      <Button
        onClick={handleOnClickApply}
        size='large'
        type='submit'
        variant='secondary'
      >
        Apply
      </Button>
      <Button onClick={handleReset} size='large' type='submit' variant='ghost'>
        Reset
      </Button>
    </Box>
  );
};

export default RangeInput;
