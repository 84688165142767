import { UnionToIntersection } from 'type-fest';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SetOutputVariableValue } from './types/actionTypes';
import { AddActionData } from './types/dataTypes';
import {
  findBranchSelectionsToStepId,
  transformModeToEmptyFields,
} from './utils';
import {
  clearIntent,
  loadNewState,
  reload,
  storeActionSettings,
  storeHandoffCustomization,
  storeIntent,
} from './workflowBuilderSlice.helpers';
import {
  addAction,
  addArticleStep,
  addAttachmentAnalysisUploadStep,
  addButtonsStep,
  addCondition,
  addCsatTriggerPointStep,
  addEmbedStep,
  addFormStep,
  addGoToIntentStep,
  addImageStep,
  addTextMessage,
  deleteWorkflowAction,
  deleteWorkflowStep,
  deleteWorkflowStepAndBelow,
  discardDraftWorkflow,
  getActionSettings,
  getHandoffCustomization,
  getIntent,
  getLatestWorkflowVersions,
  getOnboardingFlags,
  getWorkflowVersion,
  insertGroupOfSteps,
  loadDraftWorkflow,
  saveWorkflow,
  updateActionSettings,
  updateHandoffCustomization,
  updateIntent,
  updateOnboardingFlags,
  updateWorkflowStep,
} from './workflowBuilderSlice.thunks';
import isEmpty from 'lodash/fp/isEmpty';
import { getCompositeConditionsConfigFromStep } from 'src/pages/workflow-builder-edit/conditions/conditionEditorHelpers';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import { CompositeConditionsConfig } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import type { RootState } from 'src/store/rootReducer';
import { Action, ContextVariable } from 'src/types/actionBuilderApiTypes';
import {
  ActionSettingsCustomization,
  ActionStepList,
  ArticleSuggestionFeedbackSettings,
  CanvasResponse,
  DynamicCardCustomizationSettings,
  Intent,
  OnboardingFlagsResponse,
  SalesforceHandoffCustomization,
  SetContextVariableCustomizationSettings,
  Step,
  WorkflowRecommendation,
  WorkflowVersion,
  WorkflowVersionsResponse,
  ZendeskHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';
import { isOptionsStepFields } from 'src/utils/solve/stepUtils';

type StepIntersection = UnionToIntersection<
  Step['step_fields'] | CompositeConditionsConfig
>;

const stepStepFieldValueName = 'canvasWorkflowBuilder/setStepFieldValue';
export function setStepFieldValue<T extends keyof StepIntersection>({
  field,
  value,
}: {
  field: T;
  value: StepIntersection[T];
}): PayloadAction<{
  field: T;
  value: StepIntersection[T];
}> {
  return {
    payload: { field, value },
    type: stepStepFieldValueName,
  };
}
setStepFieldValue.type = stepStepFieldValueName;

export type CanvasWorkflowBuilderState = {
  actionCaseMap: {
    [key: string]: boolean;
  };
  actionCustomization:
    | SetContextVariableCustomizationSettings
    | DynamicCardCustomizationSettings
    | null;
  actionFilter: 'All' | 'API' | 'Handoffs' | 'Others';
  actionSettings: Partial<ActionSettingsCustomization>;
  addingAction: {
    action: Action;
    data: AddActionData;
  } | null;
  api_workflow_id: string | null;
  articleSuggestionFeedbackSettings: ArticleSuggestionFeedbackSettings | null;
  canvas_action_id_to_action_component: Record<
    Action['action_id'],
    ActionStepList
  >;
  editing_condition_step_id: string;
  editing_step_id: string;
  editing_step_type: StepTypes | '';
  entryStepId: string | null;
  errors: string[];
  handoffCustomization: Partial<
    ZendeskHandoffCustomization | SalesforceHandoffCustomization
  >;
  intent: Partial<Intent> | null;
  intent_id: string | null;
  intent_title: string | null;
  intent_workflow_id: string | null;
  invalid_go_to_steps: CanvasResponse['invalid_go_to_steps'];
  is_active: boolean;
  is_draft: boolean;
  is_duplicated_pending_customization: boolean;
  isInputErrorMessageVisible: boolean;
  isLoadingCustomization: boolean;
  isSqueezingStep: boolean;
  latestStepId: string | null;
  loading: boolean;
  mode: CanvasModes;
  onboardingFlags: OnboardingFlagsResponse | null;
  org_id: number;
  outputVariableValuesMap: Record<string, Record<string, string>>;
  policy_description?: string | null;
  readArticleActionDialogId: string;
  richTextEditorValue: string;
  routeFilter:
    | 'All'
    | 'Handoff Workflow'
    | 'Workflow - Classic'
    | 'Workflow - AutoFlow';
  selectedConditions: Record<string, number>;
  selectedImageFile: File | null;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
  stepFields: Step['step_fields'] | CompositeConditionsConfig | null;
  steps: Record<string, Step>;
  targetActionId: string | null;
  targetStepId: string | null;
  undefined_context_variables_in_step: CanvasResponse['undefined_context_variables_in_step'];
  unsaved_changes: boolean;
  unsupported_steps: CanvasResponse['unsupported_steps'];
  version: number;
  versions: WorkflowVersion[];
  workflow_recommendation: WorkflowRecommendation | null;
  workflowTags: Array<string>;
};

// Initial state
export const initialState: CanvasWorkflowBuilderState = {
  // steps basically keeps a copy of the entire tree
  actionCaseMap: {},
  actionCustomization: null,
  actionFilter: 'All',
  actionSettings: {},
  addingAction: null,
  api_workflow_id: null,
  articleSuggestionFeedbackSettings: null,
  canvas_action_id_to_action_component: {},
  editing_condition_step_id: '',
  editing_step_id: '',
  editing_step_type: '',
  entryStepId: null,
  errors: [],
  handoffCustomization: {},
  intent: {},
  intent_id: null,
  intent_title: '',
  intent_workflow_id: null,
  invalid_go_to_steps: [],
  is_active: false,
  is_draft: true,
  is_duplicated_pending_customization: false,
  isInputErrorMessageVisible: false,
  isLoadingCustomization: false,
  isSqueezingStep: false,
  latestStepId: null,
  loading: false,
  mode: CanvasModes.MESSAGE,
  onboardingFlags: null,
  org_id: 0,
  outputVariableValuesMap: {},
  policy_description: null,
  readArticleActionDialogId: '',
  richTextEditorValue: '',
  routeFilter: 'All',
  selectedConditions: {},
  selectedImageFile: null,
  shouldSqueezeIntoEntry: false,
  squeezeStepParentId: '',
  stepFields: null,
  steps: {},
  targetActionId: null,
  targetStepId: null,
  undefined_context_variables_in_step: {},
  unsaved_changes: false,
  unsupported_steps: [],
  version: 0,
  versions: [],
  workflow_recommendation: null,
  workflowTags: [],
};

// Main
const workflowBuilderSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(setStepFieldValue, (state, action) => {
      if (!state.stepFields) {
        return;
      }
      state.stepFields = {
        ...state.stepFields,
        [action.payload.field]: action.payload.value,
      };
    });
    // loadDraftWorkflow
    builder.addCase(
      loadDraftWorkflow.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) => {
        const newState = loadNewState(state, action.payload);

        // Go to a target step if needed
        if (newState.targetStepId) {
          // set the action id too
          const actions = newState.canvas_action_id_to_action_component;
          const actionKeys = Object.keys(actions);
          actionKeys.forEach(key => {
            if (actions[key].steps.includes(newState.targetStepId || '')) {
              newState.targetActionId = key;
            }
          });

          // find the branch selections to get to the target step
          const res = findBranchSelectionsToStepId({
            canvasData: newState,
            targetActionId: newState.targetActionId,
            targetStepId: newState.targetStepId,
          });
          if (res) {
            newState.outputVariableValuesMap = res.outputVariableValuesMap;
            newState.actionCaseMap = res.actionCaseMap;
            newState.selectedConditions = res.selectedConditions;
          }
        }

        return newState;
      },
    );

    builder.addCase(loadDraftWorkflow.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // loadLatestWorkflowVersions
    builder.addCase(
      getLatestWorkflowVersions.fulfilled,
      (state, action: PayloadAction<WorkflowVersionsResponse>) => {
        return {
          ...state,
          versions: action.payload.versions,
        };
      },
    );

    builder.addCase(getLatestWorkflowVersions.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // loadWorkflowVersion
    builder.addCase(
      getWorkflowVersion.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        loadNewState(state, action.payload),
    );

    builder.addCase(getWorkflowVersion.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // addTextMessage
    builder.addCase(
      addTextMessage.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addTextMessage.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(addTextMessage.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // addButtonsStep
    builder.addCase(
      addButtonsStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addButtonsStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(addButtonsStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // addButtonsStep
    builder.addCase(
      addAttachmentAnalysisUploadStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addAttachmentAnalysisUploadStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(
      addAttachmentAnalysisUploadStep.rejected,
      (state, action) => {
        state.errors = [...state.errors, action.error.message || 'error'];
        state.loading = false;
      },
    );

    // addFormStep
    builder.addCase(
      addFormStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addFormStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(addFormStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // addCsatTriggerPointStep
    builder.addCase(
      addCsatTriggerPointStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addCsatTriggerPointStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(addCsatTriggerPointStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // addGoToIntentStep
    builder.addCase(
      addGoToIntentStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addGoToIntentStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(addGoToIntentStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // saveWorkflow
    builder.addCase(
      saveWorkflow.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) => ({
        ...reload(state, action.payload),
      }),
    );

    builder.addCase(saveWorkflow.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(saveWorkflow.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // discardDraftWorkflow
    builder.addCase(
      discardDraftWorkflow.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        loadNewState(state, action.payload),
    );

    builder.addCase(discardDraftWorkflow.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(discardDraftWorkflow.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // addAction
    builder.addCase(
      addAction.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addAction.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(addAction.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // insertGroupOfSteps
    builder.addCase(
      insertGroupOfSteps.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(insertGroupOfSteps.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(insertGroupOfSteps.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // updateWorkflowStep
    builder.addCase(
      updateWorkflowStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(updateWorkflowStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(updateWorkflowStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // deleteWorkflowStep
    builder.addCase(
      deleteWorkflowStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(deleteWorkflowStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(deleteWorkflowStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // deleteWorkflowAction
    builder.addCase(deleteWorkflowAction.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(
      deleteWorkflowAction.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(deleteWorkflowAction.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // deleteWorkflowStepAndBelow
    builder.addCase(deleteWorkflowStepAndBelow.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(
      deleteWorkflowStepAndBelow.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(deleteWorkflowStepAndBelow.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // addCondition
    builder.addCase(
      addCondition.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addCondition.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(addCondition.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // getIntent
    builder.addCase(
      getIntent.fulfilled,
      (state, action: PayloadAction<Intent>) =>
        storeIntent(state, action.payload),
    );

    builder.addCase(getIntent.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(getIntent.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // getHandoffCustomization
    builder.addCase(
      getHandoffCustomization.fulfilled,
      (state, action: PayloadAction<ZendeskHandoffCustomization>) =>
        storeHandoffCustomization(state, action.payload),
    );

    builder.addCase(getHandoffCustomization.pending, state => {
      state.errors = [];
      state.isLoadingCustomization = true;
    });

    builder.addCase(getHandoffCustomization.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.isLoadingCustomization = false;
    });

    // getActionSettings
    builder.addCase(
      getActionSettings.fulfilled,
      (state, action: PayloadAction<ActionSettingsCustomization>) =>
        storeActionSettings(state, action.payload),
    );

    builder.addCase(getActionSettings.pending, state => {
      state.errors = [];
      state.actionSettings = {};
      state.isLoadingCustomization = true;
    });

    builder.addCase(getActionSettings.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.isLoadingCustomization = false;
    });

    // updateActionSettings
    builder.addCase(
      updateActionSettings.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(updateActionSettings.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(updateActionSettings.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // updateHandoffCustomization
    builder.addCase(
      updateHandoffCustomization.fulfilled,
      (state, action: PayloadAction<ZendeskHandoffCustomization>) =>
        storeHandoffCustomization(state, action.payload),
    );
    builder.addCase(updateHandoffCustomization.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    builder.addCase(updateHandoffCustomization.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    // add article step
    builder.addCase(addArticleStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(
      addArticleStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addArticleStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // add image step
    builder.addCase(addImageStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(
      addImageStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addImageStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // add embed step
    builder.addCase(addEmbedStep.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(
      addEmbedStep.fulfilled,
      (state, action: PayloadAction<CanvasResponse>) =>
        reload(state, action.payload),
    );

    builder.addCase(addEmbedStep.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // update intent
    builder.addCase(
      updateIntent.fulfilled,
      (state, action: PayloadAction<Intent>) => {
        return {
          ...state,
          intent: action.payload,
        };
      },
    );

    // get onboarding flags
    builder.addCase(
      getOnboardingFlags.fulfilled,
      (state, action: PayloadAction<OnboardingFlagsResponse>) => {
        return {
          ...state,
          onboardingFlags: action.payload,
        };
      },
    );

    builder.addCase(getOnboardingFlags.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(getOnboardingFlags.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });

    // update onboarding flags
    builder.addCase(
      updateOnboardingFlags.fulfilled,
      (state, action: PayloadAction<OnboardingFlagsResponse>) => {
        return {
          ...state,
          loading: false,
          onboardingFlags: action.payload,
        };
      },
    );

    builder.addCase(updateOnboardingFlags.pending, state => {
      state.errors = [];
      state.loading = true;
    });

    builder.addCase(updateOnboardingFlags.rejected, (state, action) => {
      state.errors = [...state.errors, action.error.message || 'error'];
      state.loading = false;
    });
  },
  initialState,
  name: 'canvasWorkflowBuilder',
  reducers: {
    addWorkflowType: (state, action: PayloadAction<WorkflowTypes>) => {
      state.intent = {
        ...state.intent,
        workflow_types_enabled: [
          ...(state.intent?.workflow_types_enabled || []),
          action.payload,
        ],
      };
    },
    cancelAddingAction: state => {
      return {
        ...state,
        addingAction: null,
      };
    },
    cleanWorkflowTypes: state => {
      state.intent = {
        ...state.intent,
        workflow_types_enabled: state.intent?.workflow_types_enabled?.filter(
          item => item !== 'email',
        ),
      };
    },
    clearActionSettings: state => {
      state.actionSettings = {};
    },
    clearEditingStep(state) {
      state.stepFields = null;
      state.editing_step_id = '';
      state.editing_step_type = '';
      state.mode = CanvasModes.MESSAGE;
      state.editing_condition_step_id = '';
    },
    clearIntentData: state => {
      return clearIntent(state);
    },
    clearSelectedImageFile: state => {
      state.selectedImageFile = null;
    },
    setActionCustomization: (
      state,
      action: PayloadAction<CanvasWorkflowBuilderState['actionCustomization']>,
    ) => {
      state.actionCustomization = action.payload;
      state.targetStepId = null;
    },
    setActionFilter: (
      state,
      action: PayloadAction<CanvasWorkflowBuilderState['actionFilter']>,
    ) => {
      state.actionFilter = action.payload;
    },
    setArticleSuggestionActionCase: (
      state,
      action: PayloadAction<{
        stepId: string;
        value: boolean;
      }>,
    ) => {
      state.actionCaseMap = {
        ...state.actionCaseMap,
        [action.payload.stepId]: action.payload.value,
      };
    },
    setArticleSuggestionFeedbackSettings: (
      state,
      action: PayloadAction<ArticleSuggestionFeedbackSettings | null>,
    ) => {
      state.articleSuggestionFeedbackSettings = action.payload;
    },
    setEditingConditionStepId: (state, action: PayloadAction<string>) => {
      state.editing_condition_step_id = action.payload;
      state.targetStepId = null;
    },
    setEditingStepId: (state, action: PayloadAction<string>) => {
      state.editing_step_id = action.payload;
      state.targetStepId = null;
    },
    setEditingStepType: (state, action: PayloadAction<StepTypes | ''>) => {
      state.editing_step_type = action.payload;
    },
    setErrors: (state, action: PayloadAction<Array<string>>) => {
      state.errors = action.payload;
    },
    setIntentWorkflowId: (
      state,
      action: PayloadAction<CanvasWorkflowBuilderState['intent_workflow_id']>,
    ) => {
      state.intent_workflow_id = action.payload;
    },
    setIsSqueezingStep: (state, action: PayloadAction<boolean>) => {
      state.isSqueezingStep = action.payload;
      state.targetStepId = null;
    },
    setMode: (
      state,
      action: PayloadAction<{
        contextVariables: ContextVariable[];
        mode: CanvasModes;
      }>,
    ) => {
      state.mode = action.payload.mode;

      if (state.editing_step_id && state.steps[state.editing_step_id]) {
        const stepFields = state.steps[state.editing_step_id].step_fields;
        const transitions = state.steps[state.editing_step_id].transitions;

        if (isOptionsStepFields(stepFields) && transitions) {
          const options = stepFields.options.map((option, index) => ({
            ...option,
            transition_id: transitions[index].transition_id,
          }));
          state.stepFields = { ...stepFields, options };
          return;
        }

        state.stepFields = state.steps[state.editing_step_id].step_fields;
        return;
      }

      if (
        state.editing_condition_step_id &&
        state.steps[state.editing_condition_step_id]
      ) {
        state.stepFields = getCompositeConditionsConfigFromStep(
          state.steps[state.editing_condition_step_id],
          action.payload.contextVariables,
          true,
          true,
        );
        return;
      }

      state.stepFields = transformModeToEmptyFields(action.payload.mode);
    },
    setOutputVariableValue: (
      state,
      action: PayloadAction<SetOutputVariableValue>,
    ) => {
      state.outputVariableValuesMap[action.payload.stepId] = {
        ...state.outputVariableValuesMap[action.payload.stepId],
        [action.payload.outputVariable]: action.payload.value,
      };
    },
    setReadArticleActionDialogId: (state, action: PayloadAction<string>) => {
      state.readArticleActionDialogId = action.payload;
    },
    setRichTextEditorValue: (state, action: PayloadAction<string>) => {
      state.richTextEditorValue = action.payload;
    },
    setRouteFilter: (
      state,
      action: PayloadAction<CanvasWorkflowBuilderState['routeFilter']>,
    ) => {
      state.routeFilter = action.payload;
    },
    setSelectedCondition: (state, action) => {
      state.selectedConditions[action.payload.stepId] =
        action.payload.conditionIndex;
    },
    setSelectedImageFile: (state, action: PayloadAction<File>) => {
      state.selectedImageFile = action.payload;
    },
    setShouldSqueezeIntoEntry: (state, action: PayloadAction<boolean>) => {
      state.shouldSqueezeIntoEntry = action.payload;
    },
    setSqueezeStepParentId: (state, action: PayloadAction<string>) => {
      state.squeezeStepParentId = action.payload;
    },
    setStateCanvas: (state, action: PayloadAction<CanvasResponse>) => {
      return reload(state, action.payload);
    },
    setTargetStepId: (state, action: PayloadAction<string | null>) => {
      state.targetStepId = action.payload;
    },
    startAddingAction: (
      state,
      action: PayloadAction<CanvasWorkflowBuilderState['addingAction']>,
    ) => {
      state.addingAction = action.payload;
    },
    unsetSelectedCondition: (state, action) => {
      delete state.selectedConditions[action.payload];
    },
    updateWorkflowTags: (state, action: PayloadAction<Array<string>>) => {
      state.workflowTags = action.payload;
    },
  },
});

export const selectActionSettings = (
  state: RootState,
): CanvasWorkflowBuilderState['actionSettings'] =>
  state.canvasWorkflowBuilder.actionSettings;

export const selectActionCaseMap = (
  state: RootState,
): CanvasWorkflowBuilderState['actionCaseMap'] =>
  state.canvasWorkflowBuilder.actionCaseMap;

export const selectCurrentWorkflowVersion = (
  state: RootState,
): CanvasWorkflowBuilderState['version'] => state.canvasWorkflowBuilder.version;

export const selectCurrentWorkflowVersions = (
  state: RootState,
): CanvasWorkflowBuilderState['versions'] =>
  state.canvasWorkflowBuilder.versions;

export const selectIsDraft = (
  state: RootState,
): CanvasWorkflowBuilderState['is_draft'] =>
  state.canvasWorkflowBuilder.is_draft;

export const selectCanvasErrors = (
  state: RootState,
): CanvasWorkflowBuilderState['errors'] => state.canvasWorkflowBuilder.errors;

export const selectIsWorkflowActive = (
  state: RootState,
): CanvasWorkflowBuilderState['is_active'] =>
  state.canvasWorkflowBuilder.is_active;

export const selectAddingAction = (
  state: RootState,
): CanvasWorkflowBuilderState['addingAction'] =>
  state.canvasWorkflowBuilder.addingAction;

export const selectHandoffCustomization = (
  state: RootState,
): CanvasWorkflowBuilderState['handoffCustomization'] =>
  state.canvasWorkflowBuilder.handoffCustomization;

export const selectPolicyDescription = (
  state: RootState,
): CanvasWorkflowBuilderState['policy_description'] =>
  state.canvasWorkflowBuilder.policy_description;

export const selectWorkflowRecommendation = (
  state: RootState,
): CanvasWorkflowBuilderState['workflow_recommendation'] =>
  state.canvasWorkflowBuilder.workflow_recommendation;

export const selectOnboardingFlags = (
  state: RootState,
): CanvasWorkflowBuilderState['onboardingFlags'] =>
  state.canvasWorkflowBuilder.onboardingFlags;

export const selectSelectedImageFile = (
  state: RootState,
): CanvasWorkflowBuilderState['selectedImageFile'] =>
  state.canvasWorkflowBuilder.selectedImageFile;

export const selectEditingStepFields = (
  state: RootState,
): CanvasWorkflowBuilderState['stepFields'] =>
  state.canvasWorkflowBuilder.stepFields;

export const selectreadArticleActionDialogId = (
  state: RootState,
): CanvasWorkflowBuilderState['readArticleActionDialogId'] =>
  state.canvasWorkflowBuilder.readArticleActionDialogId;

export const selectArticleSuggestionFeedbackSettings = (
  state: RootState,
): CanvasWorkflowBuilderState['articleSuggestionFeedbackSettings'] =>
  state.canvasWorkflowBuilder.articleSuggestionFeedbackSettings;

export const selectActionCustomization = (
  state: RootState,
): CanvasWorkflowBuilderState['actionCustomization'] =>
  state.canvasWorkflowBuilder.actionCustomization;

export const selectActionFilter = (
  state: RootState,
): CanvasWorkflowBuilderState['actionFilter'] =>
  state.canvasWorkflowBuilder.actionFilter;

export const selectRouteFilter = (
  state: RootState,
): CanvasWorkflowBuilderState['routeFilter'] =>
  state.canvasWorkflowBuilder.routeFilter;

export const selectRichTextEditorValue = (
  state: RootState,
): CanvasWorkflowBuilderState['richTextEditorValue'] =>
  state.canvasWorkflowBuilder.richTextEditorValue;

export const selectIntent = (
  state: RootState,
): CanvasWorkflowBuilderState['intent'] => state.canvasWorkflowBuilder.intent;

export const selectIsRichTextEditorValueEmpty = createSelector(
  [selectRichTextEditorValue],
  richTextEditorValue => richTextEditorValue === '',
);

export const selectIsSqueezingStep = (
  state: RootState,
): CanvasWorkflowBuilderState['isSqueezingStep'] =>
  state.canvasWorkflowBuilder.isSqueezingStep;

export const selectSqueezeStepParentId = (
  state: RootState,
): CanvasWorkflowBuilderState['squeezeStepParentId'] =>
  state.canvasWorkflowBuilder.squeezeStepParentId;

export const selectShouldSqueezeIntoEntry = (
  state: RootState,
): CanvasWorkflowBuilderState['shouldSqueezeIntoEntry'] =>
  state.canvasWorkflowBuilder.shouldSqueezeIntoEntry;

export const selectIsDuplicatedPendingCustomization = (
  state: RootState,
): boolean =>
  !isEmpty(state.canvasWorkflowBuilder.steps) &&
  state.canvasWorkflowBuilder.is_duplicated_pending_customization;

export const {
  addWorkflowType,
  cancelAddingAction,
  cleanWorkflowTypes,
  clearActionSettings,
  clearEditingStep,
  clearIntentData,
  clearSelectedImageFile,
  setActionCustomization,
  setActionFilter,
  setArticleSuggestionActionCase,
  setArticleSuggestionFeedbackSettings,
  setEditingConditionStepId,
  setEditingStepId,
  setEditingStepType,
  setErrors,
  setIntentWorkflowId,
  setIsSqueezingStep,
  setMode,
  setOutputVariableValue,
  setReadArticleActionDialogId,
  setRichTextEditorValue,
  setRouteFilter,
  setSelectedCondition,
  setSelectedImageFile,
  setShouldSqueezeIntoEntry,
  setSqueezeStepParentId,
  setStateCanvas,
  setTargetStepId,
  startAddingAction,
  unsetSelectedCondition,
  updateWorkflowTags,
} = workflowBuilderSlice.actions;

export default workflowBuilderSlice.reducer;
