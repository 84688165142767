import ErrorIcon from '@mui/icons-material/Error';
import { Box, Dialog, styled, useTheme } from '@mui/material';

import { Button, theme } from '@forethought-technologies/forethought-elements';

interface WorkflowConflictModalProps {
  buttonMessage: string;
  message: string;
  onButtonClick: () => void;
  open: boolean;
  title: string;
}

function WorkflowConflictMessage({
  buttonMessage,
  message,
  onButtonClick,
  open,
  title,
}: WorkflowConflictModalProps) {
  const { palette } = useTheme();

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            borderLeft: `6px solid ${theme.palette.colors.yellow[500]}`,
            display: 'flex',
            flexDirection: 'row',
            marginTop: '74px',
            padding: '16px 18px',
            width: '416px',
          },
        }}
        sx={{
          '& .MuiDialog-scrollPaper': {
            alignItems: 'flex-start',
          },
        }}
      >
        <Icon>
          <ErrorIcon sx={{ color: palette.colors.yellow[500] }} />
        </Icon>
        <Box>
          <Text>
            <h3>{title}</h3>
            <p>{message}</p>
          </Text>
          <Box alignItems='center' display='flex' justifyContent='flex-end'>
            <Button onClick={onButtonClick} variant='ghost'>
              {buttonMessage}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

const Icon = styled('div')`
  width: 50px;
`;

const Text = styled('div')`
  width: 347px;

  & h3 {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 20px;
  }

  & p {
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
  }
`;

export default WorkflowConflictMessage;
