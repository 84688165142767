import React, { Dispatch, FC, SetStateAction } from 'react';
import { styled } from '@mui/material';

import {
  Checkbox,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import TicketCustomFields from './TicketCustomFields';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import {
  Label,
  Row,
  StyledInput,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { textMediumStyle } from 'src/styles/styledMixin';
import { JiraServiceManagementCreateRequestSettings } from 'src/types/workflowBuilderAPITypes';

interface JiraServiceManagementCreateRequestConfigurationProps {
  customizationData: JiraServiceManagementCreateRequestSettings;
  errorMessages: Array<string | Array<string>>;
  setCustomizationData: Dispatch<
    SetStateAction<JiraServiceManagementCreateRequestSettings>
  >;
}

const JiraServiceManagementCreateRequestConfiguration: FC<
  React.PropsWithChildren<JiraServiceManagementCreateRequestConfigurationProps>
> = ({ customizationData, errorMessages, setCustomizationData }) => {
  return (
    <ContentWrapper useFullHeight={true}>
      <Content>
        <Title>Jira Service Management - Create Request</Title>
        <Spacer height='20px' />
        <Section>
          <Row>
            <Label>Service Desk ID</Label>
            <Tooltip
              tooltipContent={
                'Service Desk ID, can be located at the end of the url for a given project, e.g. 1 in the case of <domain>/jira/servicedesk/projects/TEST/queues/custom/1'
              }
            >
              <Icon src={tooltipInfoIcon} />
            </Tooltip>
            <Spacer width='4px' />
          </Row>
          <StyledInput
            onChange={({ target }) => {
              setCustomizationData(handoffData => {
                return {
                  ...handoffData,
                  service_desk_id: target.value,
                };
              });
            }}
            placeholder='Service Desk ID'
            type='text'
            value={customizationData?.service_desk_id}
            width='inherit'
          />
          <Spacer height='20px' />
          <Row>
            <Label>Request Type ID</Label>
            <Tooltip
              tooltipContent={
                'Request Type ID, can be found at the end of the url under JSM -> Project Settings -> Request Types -> click on request type you want. e.g. 2 in the case of <domain>/jira/servicedesk/projects/TEST/settings/request-types/request-type/2'
              }
            >
              <Icon src={tooltipInfoIcon} />
            </Tooltip>
            <Spacer width='4px' />
          </Row>
          <StyledInput
            onChange={({ target }) => {
              setCustomizationData(handoffData => {
                return {
                  ...handoffData,
                  request_type_id: target.value,
                };
              });
            }}
            placeholder='Request Type ID'
            type='text'
            value={customizationData?.request_type_id}
            width='inherit'
          />
          <Spacer height='20px' />
          <Checkbox
            checked={customizationData.include_transcript}
            label='Include transcript'
            onChange={() => {
              setCustomizationData({
                ...customizationData,
                include_transcript: !customizationData.include_transcript,
              });
            }}
          />
          <Spacer height='20px' />
          <Row>
            <Label>Ticket Custom Field</Label>
            <Spacer width='4px' />
          </Row>
          <TicketCustomFields
            customizationData={customizationData}
            errorMessages={errorMessages}
            setCustomizationData={setCustomizationData}
          />
        </Section>
      </Content>
    </ContentWrapper>
  );
};

export default JiraServiceManagementCreateRequestConfiguration;

const ContentWrapper = styled('div')<{ useFullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const Content = styled('div')`
  margin-top: 0;
  margin-bottom: 50px;
  overflow-y: auto;
`;

const Icon = styled('img')`
  margin-bottom: 8px;
  margin-left: 4px;
`;
