import { SearchBar } from '@forethought-technologies/forethought-elements';
import Bubble from './Bubble';
import {
  BubbleGroup,
  Content,
  NarrowContentWrapper,
  Section,
  Subtitle,
  Title,
} from './styles';
import { IntegrationsPageHeaderProps } from './types';
import { avatarTranslations, bubbleAvatars } from './utils';
import useMediaQuery from 'src/hooks/useMediaQuery';

export default function IntegrationsPageHeader({
  clearSearch,
  onSearch,
  searchQuery,
}: IntegrationsPageHeaderProps) {
  // Haven't defined the breakpoint in global styles because this header is kind of a one-off design
  const isXLDesktop = useMediaQuery('(min-width: 1440px)');

  return (
    <Section>
      {isXLDesktop && (
        <BubbleGroup>
          {bubbleAvatars.left.map(
            (src, idx) =>
              src && (
                <Bubble
                  idx={idx}
                  key={src}
                  src={src}
                  translate={avatarTranslations[idx]}
                />
              ),
          )}
        </BubbleGroup>
      )}
      <Content>
        <NarrowContentWrapper>
          <Title>Connect your tools to make the best out of Forethought</Title>
        </NarrowContentWrapper>
        <Subtitle>
          Find the products your company uses, and integrate them to make your
          Forethought platform smarter and better for your entire company!
        </Subtitle>
        <NarrowContentWrapper>
          <SearchBar
            onChange={e => onSearch(e.target.value)}
            onClear={searchQuery ? clearSearch : undefined}
            placeholder='Search for products'
            value={searchQuery}
          />
        </NarrowContentWrapper>
      </Content>
      {isXLDesktop && (
        <BubbleGroup>
          {bubbleAvatars.right.map(
            (src, idx) =>
              src && (
                <Bubble
                  idx={idx}
                  key={src}
                  src={src}
                  translate={avatarTranslations[idx]}
                />
              ),
          )}
        </BubbleGroup>
      )}
    </Section>
  );
}
