import { styled } from '@mui/material';

import { Colors } from '@forethought-technologies/forethought-elements';

export const UnsecuredPage = () => {
  return (
    <Container>
      <Title>
        If you are seeing this page, you are under a Clickjacking security
        attack
      </Title>
      <Body>
        Not to worry, we are aware of this and have taken action on your behalf
        to prevent any potential malicious activity.
      </Body>
      <Body>
        If you would like to learn more about clickjacking attacks, check out
        this{' '}
        <a
          href='https://en.wikipedia.org/wiki/Clickjacking'
          rel='noopener noreferrer'
          target='_blank'
        >
          wikipedia article
        </a>{' '}
        for more information.
      </Body>
    </Container>
  );
};

const Container = styled('section')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled('h1')`
  color: ${Colors.ui.semantic.destructive.default};
  font-size: 30px;
`;

const Body = styled('p')`
  font-size: 22px;
`;
