import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import { FetchAction } from '../../types/types';
import { SuperAdminActions } from '../actionTypes';
import { OrgDict } from 'src/services/apiInterfaces';
import { getSearchOrganizationsAPI } from 'src/services/apiV1';

export const getDataSuccess = createAction<OrgDict>(
  SuperAdminActions.GET_SEARCH_ORG_SUCCESS,
);

export const getDataFailure = createAction<string>(
  SuperAdminActions.GET_SEARCH_ORG_FAILURE,
);

export const getSearchOrganizations: Function =
  () =>
  (dispatch: Dispatch): Promise<void | FetchAction> => {
    return getSearchOrganizationsAPI()
      .then((data: OrgDict) => {
        dispatch(getDataSuccess(data));
      })
      .catch((err: Error) => {
        dispatch(getDataFailure(err.message));
      });
  };
