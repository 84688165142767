import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { normalizeTableFloat } from '../helpers';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';
import { getNumberIndicator } from 'src/utils/discover/helpers';
import { formatNToPercentageDecimal } from 'src/utils/formatToPercentage';

export const DevianceRendererComponent = ({
  isHidden,
  isSummaryRow,
  metricFilter,
  value,
  volumeValue,
}: {
  isHidden: boolean;
  isSummaryRow?: boolean;
  metricFilter: DiscoverTopicAggregatedMetricType | '';
  value: number | null;
  volumeValue: number | null;
}) => {
  const { palette } = useTheme();

  if (value === null) {
    return (
      <Typography
        color={isHidden ? palette.colors.grey[600] : palette.grey[800]}
        variant='font14'
      >
        <i>N/A</i>
      </Typography>
    );
  }

  const valueFixed = normalizeTableFloat(value);

  const renderValue = () => {
    if (metricFilter === 'sentiment') {
      if (!isSummaryRow && volumeValue && typeof value === 'number') {
        return (
          <>
            {value}
            &nbsp;
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {`(${formatNToPercentageDecimal(value / volumeValue)})`}
            </Typography>
          </>
        );
      }
      return value;
    }

    return `${getNumberIndicator(value)}${valueFixed}x`;
  };

  return (
    <Typography
      color={isHidden ? palette.colors.grey[600] : palette.colors.black}
      variant='font14'
    >
      {renderValue()}
    </Typography>
  );
};
