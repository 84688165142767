import React from 'react';
import { Location } from 'history';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

export interface WithRouterProps {
  location: Location;
  navigate: NavigateFunction;
}

/**
 * @deprecated
 * Not for use in new components
 * This replaces the 'withRouter' HOC that was removed from react-router in v6
 * After refactoring our class components to functions, this can be removed
 */
export function withRouter<T extends WithRouterProps = WithRouterProps>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
) {
  const ComponentWithRouter = (props: Omit<T, keyof WithRouterProps>) => {
    const navigate = useNavigate();
    const location = useLocation();

    return <Component {...{ location, navigate }} {...(props as T)} />;
  };

  return ComponentWithRouter;
}
