import './ModalItem.scss';

import React from 'react';
import classNames from 'classnames';

import { getContentIcon } from './iconHelper';

interface ModalItemProps {
  fieldType: string;
  isDark?: boolean;
  isSelected: boolean;
  itemValue: string;
  onClickFunction: Function;
}

// TODO: Could we move these to consts and set the className to the value?
const ModalItem: React.FC<React.PropsWithChildren<ModalItemProps>> = (
  props: ModalItemProps,
) => {
  const { fieldType, isDark, isSelected, itemValue, onClickFunction } = props;
  const isSafari =
    navigator.userAgent.search('Safari') >= 0 &&
    navigator.userAgent.search('Chrome') < 0;
  return (
    <button
      className={classNames('ModalItem-container ModalItem--color', {
        ' ModalItem--selectColor': isDark && isSelected,
        'ModalItem--SelectedDarkPurple': isDark && isSelected,
        'ModalItem--SelectedLightPurple': !isDark && isSelected,
        'ModalItem-container-safari': isSafari,
      })}
      onClick={() => onClickFunction()}
      type='button'
    >
      <span className={getContentIcon(fieldType, !!isDark, isSelected)}></span>
      <span className='ModalItem-filterType '>{itemValue}</span>
      <span
        className={classNames({
          'ModalItem-darkIndicator': isDark,
          'ModalItem-darkIndicatorIcon': isDark,
          'ModalItem-lightIndicator': !isDark,
          'ModalItem-lightIndicatorIcon': !isDark,
          'ModalItem-SelectedDarkIndicator': isDark && isSelected,
        })}
      />
    </button>
  );
};

export default ModalItem;
