import React from 'react';
import { styled, useTheme } from '@mui/material';
import { IconArrowsMaximize, IconTrash, IconWorld } from '@tabler/icons-react';

import {
  elevations,
  Tooltip,
} from '@forethought-technologies/forethought-elements';

interface VerticalMenuProps {
  deleteDisabledMessage?: string;
  isDragIconVisible?: boolean;
  isTranslationIconVisible?: boolean;
  onClickDelete: () => void;
  onTranslationsClick?: () => void;
}

const VerticalMenu = React.forwardRef<HTMLButtonElement, VerticalMenuProps>(
  function VerticalMenu(
    {
      deleteDisabledMessage,
      isDragIconVisible = true,
      isTranslationIconVisible = false,
      onClickDelete,
      onTranslationsClick,
    },
    ref,
  ) {
    const { palette } = useTheme();
    const isDeleteDisabled = Boolean(deleteDisabledMessage);

    const deleteButton = (
      <ActionButton
        aria-label='Delete component'
        disabled={isDeleteDisabled}
        onClick={e => {
          e.stopPropagation();
          onClickDelete();
        }}
      >
        <IconTrash
          color={
            isDeleteDisabled
              ? palette.colors.grey[500]
              : palette.colors.red[500]
          }
          display='block'
          size={20}
        />
      </ActionButton>
    );

    return (
      <Menu>
        {isDragIconVisible && (
          <li>
            <ActionButton aria-label='Drag component' ref={ref}>
              <IconArrowsMaximize
                color={palette.colors.grey[700]}
                display='block'
                size={20}
                style={{ transform: 'rotate(45deg)' }}
              />
            </ActionButton>
          </li>
        )}
        {isTranslationIconVisible && (
          <li>
            <ActionButton
              onClick={() => onTranslationsClick && onTranslationsClick()}
            >
              <IconWorld color={palette.colors.grey[700]} size={20} />
            </ActionButton>
          </li>
        )}
        <li>
          {deleteDisabledMessage ? (
            <Tooltip tooltipContent={deleteDisabledMessage}>
              {deleteButton}
            </Tooltip>
          ) : (
            deleteButton
          )}
        </li>
      </Menu>
    );
  },
);

const Menu = styled('ul')`
  background-color: ${props => props.theme.palette.colors.white};
  border: 1px solid ${props => props.theme.palette.colors.slate[200]};
  box-shadow: ${elevations.z1};
  border-radius: 6px;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const ActionButton = styled('button')`
  cursor: pointer;
  padding: 8px;

  &:hover:not(:disabled) {
    background: ${props => props.theme.palette.colors.purple[100]};
  }
`;

export default VerticalMenu;
