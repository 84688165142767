import Skeleton from 'react-loading-skeleton';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Button,
  DateRangeFilterButton,
  FilterButton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DateRange } from '../discover-dashboard-page/types';
import {
  initialInsightParams,
  INSIGHT_TOOLTIP_COPY,
} from '../solve-insights/constants';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
} from '../solve-insights/helpers';
import { initialInsightTopicParams } from '../solve-insights/topics/constants';
import { InsightPeriod } from '../solve-insights/types';
import TopicDetailChart from './TopicDetailChart';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import { periodicalFilterOptions } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { NA } from 'src/pages/intent-conversation-analytics/constants';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import { TopicInformationResponse } from 'src/services/insights/types';
import {
  dateRangeToTimeFilter,
  isTimePeriodValid,
} from 'src/utils/discover/helpers';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface TopicContentProps {
  areFiltersUsed: boolean;
  dateRange: DateRange;
  handleFilterReset: () => void;
  isParentTopic: boolean;
  periodicalFilter: InsightPeriod;
  setDateRange: (dateRange: DateRange) => void;
  setPeriodicalFilter: (period: InsightPeriod) => void;
  topicId: string;
  topicInformation?: TopicInformationResponse;
}

const TopicContent = ({
  areFiltersUsed,
  dateRange,
  handleFilterReset,
  isParentTopic,
  periodicalFilter,
  setDateRange,
  setPeriodicalFilter,
  topicId,
  topicInformation,
}: TopicContentProps) => {
  const { palette } = useTheme();
  const getCSATColor = useGetCSATColor();

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const timeFilter = dateRangeToTimeFilter(dateRange);

  const isLoading = false;

  const handleRenderCsatComponent = () => {
    const csat = topicInformation?.csat;
    if (!csat) {
      return (
        <Typography color={palette.colors.grey[600]} variant='font24'>
          {NA}
        </Typography>
      );
    }
    return (
      <Typography color={getCSATColor(csat)} variant='font24'>
        {formatCSAT(csat)}
      </Typography>
    );
  };

  return (
    <Box
      border={`1px solid ${palette.colors.slate[200]}`}
      borderRadius='8px'
      display='flex'
      padding='24px'
      width='100%'
    >
      <Box display='flex' flexDirection='column' gap={3} width='100%'>
        <Box alignItems='center' display='flex' gap={1} justifyContent='start'>
          {isParentTopic && (
            <Box display='flex' paddingRight='8px'>
              <Typography variant='font20'>{`Subtopics (${
                topicInformation?.subtopics.length ?? 0
              })`}</Typography>
            </Box>
          )}
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightTopicParams.date}
            onChange={value => {
              setDateRange(value);
              const timeStamp = dateRangeToTimestamp(value);
              emitTrackingEventCallback('insight-date-change', {
                from: timeStamp.start_timestamp,
                scope: 'detail',
                tab: 'topic',
                to: timeStamp.end_timestamp,
              });
            }}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <FilterButton
            aria-label='time period filter'
            disabledTooltipMessage='Time period not valid for selected time range'
            initialValue={initialInsightParams.period}
            onChange={value => {
              setPeriodicalFilter(value as InsightPeriod);
              emitTrackingEventCallback('insight-period-change', {
                scope: 'detail',
                tab: 'topic',
                value,
              });
            }}
            options={periodicalFilterOptions.map(option => {
              const isDisabled = !isTimePeriodValid(option.value, timeFilter);
              return { ...option, disabled: isDisabled };
            })}
            startAdornment={<FilterButtonIcon dropdownType='time-period' />}
            value={periodicalFilter}
          />
          {areFiltersUsed && (
            <Button
              onClick={() => {
                handleFilterReset();
                emitTrackingEventCallback('insight-reset-filter', {
                  scope: 'detail',
                  tab: 'topic',
                });
              }}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Box display='flex' flexDirection='column' gap='4px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Chats
              </Typography>
            </Box>
            {isLoading ? (
              <Skeleton height={30} />
            ) : (
              <Typography variant='font24'>
                {numbersToStringWithCommas({
                  number: topicInformation?.chats_count,
                })}
              </Typography>
            )}
          </Box>
          <Box display='flex' flexDirection='column' gap='4px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Non-deflections
              </Typography>
              {/* <Box
                display='flex'
                onMouseEnter={() => {
                  emitTrackingEventCallback('insight-tooltip-hover', {
                    scope: 'detail',
                    tab: 'topic',
                    value: 'non_deflected_chats_count',
                  });
                }}
              >
                <Tooltip
                  tooltipContent={`The maximum potential savings you could have realized with workflow  automation, assuming a $${
                    topicInformation?.cost_per_ticket ?? NA
                  } cost per non-deflected chat.`}
                >
                  <IconInfoCircle color={palette.colors.grey[700]} size={20} />
                </Tooltip>
              </Box> */}
            </Box>
            {isLoading ? (
              <Skeleton height={30} />
            ) : (
              <Typography variant='font24'>
                {numbersToStringWithCommas({
                  number: topicInformation?.non_deflected_chats_count,
                })}
              </Typography>
            )}
          </Box>
          <Box display='flex' flexDirection='column' gap='4px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Deflections
              </Typography>
              {/* <Box
                display='flex'
                onMouseEnter={() => {
                  emitTrackingEventCallback('insight-tooltip-hover', {
                    scope: 'detail',
                    tab: 'topic',
                    value: 'deflected_chats_count',
                  });
                }}
              >
                <Tooltip
                  tooltipContent={`Dollarized saving assuming a $${
                    topicInformation?.cost_per_ticket ?? NA
                  } cost per deflection.`}
                >
                  <IconInfoCircle color={palette.colors.grey[700]} size={20} />
                </Tooltip>
              </Box> */}
            </Box>
            {isLoading ? (
              <Skeleton height={30} />
            ) : (
              <Typography variant='font24'>
                {numbersToStringWithCommas({
                  number: topicInformation?.deflected_chats_count,
                })}
              </Typography>
            )}
          </Box>
          <Box display='flex' flexDirection='column' gap='4px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                # of times knowledge article surfaced
              </Typography>
              <Box
                display='flex'
                onMouseEnter={() => {
                  emitTrackingEventCallback('insight-tooltip-hover', {
                    scope: 'detail',
                    tab: 'topic',
                    value: 'ka_surfaced_count',
                  });
                }}
              >
                <Tooltip
                  tooltipContent={INSIGHT_TOOLTIP_COPY.topics_article_surfaced}
                >
                  <IconInfoCircle color={palette.colors.grey[700]} size={20} />
                </Tooltip>
              </Box>
            </Box>
            {isLoading ? (
              <Skeleton height={30} />
            ) : (
              <Typography variant='font24'>
                {numbersToStringWithCommas({
                  number: topicInformation?.ka_surfaced_count,
                })}
              </Typography>
            )}
          </Box>
          <Box display='flex' flexDirection='column' gap='4px'>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Avg. CSAT
              </Typography>
              <Box
                display='flex'
                onMouseEnter={() => {
                  emitTrackingEventCallback('insight-tooltip-hover', {
                    scope: 'detail',
                    tab: 'topic',
                    value: 'csat',
                  });
                }}
              >
                <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.topics_csat}>
                  <IconInfoCircle color={palette.colors.grey[700]} size={20} />
                </Tooltip>
              </Box>
            </Box>
            {isLoading ? <Skeleton height={30} /> : handleRenderCsatComponent()}
          </Box>
        </Box>
        <TopicDetailChart
          dateRange={dateRange}
          isParentTopic={isParentTopic}
          periodicalFilter={periodicalFilter}
          subTopics={topicInformation?.subtopics ?? []}
          topicId={topicId}
        />
      </Box>
    </Box>
  );
};

export default TopicContent;
