import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getArticleIntegrations } from 'src/actions/discover/discover';
import { selectArticleIntegrations } from 'src/reducers/discoverReducer/discoverReducer';
import { useGetPaidPlanDetailsQuery } from 'src/services/dashboard-api';
import { useAppDispatch } from 'src/store/hooks';

export const useGetArticleIntegrations = () => {
  const dispatch = useAppDispatch();
  const data = useSelector(selectArticleIntegrations);
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const isDiscoverEnabled = paidPlanData?.enablement_info.discover || false;

  useEffect(() => {
    if (isDiscoverEnabled) {
      dispatch(getArticleIntegrations());
    }
  }, [dispatch, isDiscoverEnabled]);

  return data;
};
