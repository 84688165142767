import React, { useState } from 'react';
import { Box } from '@mui/material';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import FileUploadButton from './FileUploadButton';
import { InlineForm } from './styles';
import { createIdP } from 'src/slices/sso/thunks';
import { useAppDispatch } from 'src/store/hooks';

interface FormState {
  metadataFile?: string;
  metadataUrl?: string;
}

interface FormProps {
  disabled?: boolean;
  initialValues: FormState;
}

export default function IdPForm({
  disabled = false,
  initialValues,
}: FormProps) {
  const dispatch = useAppDispatch();
  const [metadataUrl, setMetadataUrl] = useState(
    initialValues.metadataUrl || '',
  );

  function loadXML(e: ProgressEvent<FileReader>) {
    const xmlString = e.target?.result;
    if (typeof xmlString === 'string') {
      dispatch(createIdP({ metadata_file: xmlString }));
    }
  }

  function handleXMLFileUpload({
    target,
  }: React.ChangeEvent<HTMLInputElement>) {
    const file = target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener('load', loadXML);
      reader.readAsText(file);
    }
  }

  return (
    <>
      <InlineForm
        aria-disabled={disabled}
        onSubmit={e => {
          e.preventDefault();
          dispatch(createIdP({ metadata_url: metadataUrl }));
        }}
      >
        <Box flex='1'>
          <TextField
            disabled={disabled}
            label='Metadata URL'
            onChange={e => setMetadataUrl(e.target.value)}
            value={metadataUrl}
          />
        </Box>
        {!disabled && (
          <Button size='large' type='submit' variant='secondary'>
            Submit
          </Button>
        )}
      </InlineForm>
      <Box textAlign='center'>
        <Typography variant='font14Bold'>OR</Typography>
      </Box>
      <FileUploadButton
        accept='.xml'
        disabled={disabled}
        onChange={handleXMLFileUpload}
        title='Upload XML metadata file'
      />
    </>
  );
}
