import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { ResponseMessage } from './StyledComponents';
import { ArticleApiWidgetComponent } from 'src/slices/workflow-preview/types';

interface ApiArticleStepProps {
  step: ArticleApiWidgetComponent;
}

export const ApiArticleStep = ({ step }: ApiArticleStepProps) => {
  const { articles } = step;

  return (
    <>
      {articles.map((article, index) => (
        <ResponseMessage fullWidth isPlainText key={index}>
          <Article article={article} />
        </ResponseMessage>
      ))}
    </>
  );
};

const Article = ({
  article,
}: {
  article: ArticleApiWidgetComponent['articles'][number];
}) => {
  return (
    <>
      <Typography
        component='a'
        dangerouslySetInnerHTML={{ __html: article.title }}
        href={article.link}
        rel='noopener noreferrer'
        style={{ cursor: 'pointer' }}
        target='_blank'
        variant='font16Bold'
      />
      <AnswerContentBody
        dangerouslySetInnerHTML={{ __html: article.full_html }}
      />
    </>
  );
};

const AnswerContentBody = styled('div')`
  margin-bottom: 10px;
  max-height: 250px;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  overflow-y: scroll;
  &::after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 20px;
  }

  & h2 {
    font-size: 16px;
  }

  & h3,
  h4,
  h5 {
    font-size: 15px;
  }

  & pre {
    overflow-x: auto;
    white-space: pre-wrap;
    word-break: break-word;
  }

  & .article_header {
    display: none;
  }

  & a {
    color: var(--message-link-color);
    word-break: break-word;
  }

  & a:visited {
    color: ${props => props.theme.palette.colors.purple[600]};
  }

  & a:hover {
    color: ${props => props.theme.palette.colors.purple[600]};
    text-decoration: none;
  }

  & a:active {
    color: ${props => props.theme.palette.colors.purple[700]};
  }

  & img {
    max-width: 100% !important;
    height: auto !important;
    width: initial !important;
  }

  & dl,
  dd,
  dt {
    margin: 0;
  }

  & iframe,
  video,
  dl,
  dd,
  dt {
    width: 100%;
    height: auto;
  }

  & table {
    width: 100% !important;
    table-layout: auto;
  }
  & td {
    padding: 3px;
    word-break: break-word;
    height: auto;
  }

  & ft-highlight {
    background: #fbf2de;
    color: #404b5a;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #edbd59;
    text-decoration-style: solid;
    text-underline-position: under;
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    border: 1px solid var(--message-link-color);
  }
`;
