import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import {
  Button,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
// Redux
import {
  selectAutomations,
  setDeleteAssistAutomationModalVisible,
} from '../../../slices/assist-automations/assistAutomationsSlice';
import { toggleAssistAutomationPublished } from '../../../slices/assist-automations/thunks';
import { AutomationForm } from '../../../slices/assist-automations/types';
import isEqual from 'lodash/fp/isEqual';
// Icons
import BackIcon from 'src/assets/images/actions-back-icon.svg';
import DeleteAssistAutomationIcon from 'src/assets/images/delete-assist-automation-icon.svg';
import { serializeAutomationTextField } from 'src/components/assist-automations-form/helpers';
import { selectTimeRange } from 'src/reducers/pageSettingsReducer/pageSettingsReducer';
import { selectUser } from 'src/reducers/userReducer/userReducer';
// Styles
import { breakpoints } from 'src/styles/breakpoints';
// Enums & Types
import { Permission, Routes } from 'src/utils/enums';
import { sendTrackingEvents } from 'src/utils/sendTrackingEvent';
interface ActionRowProps {
  automationId?: string;
  isCreateLoading?: boolean;
  isReadOnly: boolean;
}

const ActionRow = ({
  automationId,
  isCreateLoading,
  isReadOnly,
}: ActionRowProps) => {
  const dispatch = useDispatch();
  const { errors, submitForm, values } = useFormikContext<AutomationForm>();
  const {
    availableAutomations,
    connector,
    isPublishedStateLoading,
    isUpdateLoading,
  } = useSelector(selectAutomations);

  const timeRange = useSelector(selectTimeRange);
  const user = useSelector(selectUser);
  const application = Permission.ASSIST_AUTOMATIONS;
  const userEmail = user?.user?.email || '';

  const currentAutomation = availableAutomations.find(
    automation => automation.automation_id === values.automation_id,
  );

  const isAutomationUpdated = !isEqual(
    {
      ...values,
      external_text_field: serializeAutomationTextField(
        values.external_text_field || '',
      ),
      internal_text_field: serializeAutomationTextField(
        values.internal_text_field || '',
      ),
    },
    {
      ...currentAutomation,
      external_text_field: serializeAutomationTextField(
        currentAutomation?.external_text_field || '',
      ),
      internal_text_field: serializeAutomationTextField(
        currentAutomation?.internal_text_field || '',
      ),
    },
  );

  const hasError = Object.values(errors).some(Boolean) || !connector;
  const isPublishButtonDisabled = hasError || isAutomationUpdated;
  const isSaveChangesButtonDisabled = hasError || !isAutomationUpdated;

  const handlePublishAutomationClick = () => {
    sendTrackingEvents(
      dispatch,
      'automation-builder-publish',
      timeRange,
      application,
      {
        automation_description: currentAutomation?.description,
        automation_title: currentAutomation?.title,
        connector_used: connector?.connector_id,
        edited_by: userEmail,
        internal_text_field_used:
          currentAutomation?.internal_text_field !== undefined ? 'Yes' : 'No',
        name_of_api: 'Update Automation',
        org_id: currentAutomation?.org_id,
      },
    );

    if (automationId) {
      dispatch(
        toggleAssistAutomationPublished({
          automationId,
          published: true,
        }),
      );
    }
  };

  const handleUnpublishAutomationClick = () => {
    sendTrackingEvents(
      dispatch,
      'automation-builder-unpublish',
      timeRange,
      application,
      {
        automation_description: currentAutomation?.description,
        automation_title: currentAutomation?.title,
        connector_used: connector?.connector_id,
        edited_by: userEmail,
        internal_text_field_used:
          currentAutomation?.internal_text_field !== undefined ? 'Yes' : 'No',
        name_of_api: 'Update Automation',
        org_id: currentAutomation?.org_id,
      },
    );

    if (automationId) {
      dispatch(
        toggleAssistAutomationPublished({
          automationId,
          published: false,
        }),
      );
    }
  };

  const handleOpenDeleteConfirmationModalClick = () => {
    if (!isReadOnly) {
      dispatch(setDeleteAssistAutomationModalVisible(true));
    }
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <UnstyledLink aria-label='Back' to={Routes.ASSIST_AUTOMATIONS}>
          <BackImg src={BackIcon} />
        </UnstyledLink>
        <Typography variant='font20'>Assist Automation Builder</Typography>
      </TitleWrapper>
      <ActionsWrapper>
        <Tooltip tooltipContent='Delete'>
          <Button
            onClick={handleOpenDeleteConfirmationModalClick}
            size='large'
            startIcon={<img src={DeleteAssistAutomationIcon} />}
            variant='ghost'
          />
        </Tooltip>
        <SaveChangesButtonWrapper>
          <Button
            disabled={isSaveChangesButtonDisabled}
            isLoading={isUpdateLoading || isCreateLoading}
            onClick={() => !isReadOnly && submitForm()}
            size='large'
            variant='secondary'
          >
            Save Changes
          </Button>
        </SaveChangesButtonWrapper>

        {values.published ? (
          <Button
            disabled={isPublishButtonDisabled}
            isLoading={isPublishedStateLoading}
            onClick={() => !isReadOnly && handleUnpublishAutomationClick()}
            size='large'
            variant='secondary'
          >
            Unpublish Automation
          </Button>
        ) : (
          <Button
            disabled={isPublishButtonDisabled}
            isLoading={isPublishedStateLoading}
            onClick={() => !isReadOnly && handlePublishAutomationClick()}
            size='large'
            variant='secondary'
          >
            Publish Automation
          </Button>
        )}
      </ActionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

const SaveChangesButtonWrapper = styled('div')`
  margin: 0 8px;
`;

const ActionsWrapper = styled('div')`
  display: flex;
`;

const TitleWrapper = styled('div')`
  display: flex;

  @media ${breakpoints.mobile} {
    margin-bottom: 10px;
  }
`;

const BackImg = styled('img')`
  margin-right: 5px;
`;

export default ActionRow;
