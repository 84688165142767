import { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconDiscountCheck } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Card as BannerLayoutCard, CardMetric } from '../common/banner-layout';
import debounce from 'lodash/fp/debounce';
import { DISCOVER_CARD_TEXT } from 'src/components/global-discover/constants';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { getAppCuesId } from 'src/utils/appCuesUtil';
import { getDiscoverBannerValues } from 'src/utils/discover/helpers';

interface RecommendationCardProps {
  topic: DiscoverTopic;
  trackingEventMetadata: Record<string, string>;
}

const RecommendationCard = ({
  topic,
  trackingEventMetadata,
}: RecommendationCardProps) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const relevantTrackingData = useMemo(
    () => ({
      ...trackingEventMetadata,
      bookmarked: topic.is_bookmarked,
      page: 'Topic view',
      'page-area': 'Recommendation',
      recommended: topic.is_recommended,
      'topic-name': topic.topic_name,
    }),
    [
      topic.is_bookmarked,
      topic.is_recommended,
      topic.topic_name,
      trackingEventMetadata,
    ],
  );
  const onMouseEnter = useMemo(() => {
    return debounce(DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT, () =>
      emitTrackingEventCallback(
        'discover-automation-banner-hovered',
        relevantTrackingData,
      ),
    );
  }, [emitTrackingEventCallback, relevantTrackingData]);

  const solveWorkflowActionableValue = topic.actionable_value?.solve_workflow;

  const direction = 'column';

  if (!solveWorkflowActionableValue) {
    return null;
  }

  const { costValue, fullResolutionTimeValue, volumeValue } =
    getDiscoverBannerValues(solveWorkflowActionableValue);

  return (
    <Box
      data-appcues-target={getAppCuesId({
        componentType: 'container',
        featureName: 'recommendation',
        pageName: 'topicdetail',
        subType: 'banner',
      })}
      onMouseEnter={onMouseEnter}
    >
      <BannerLayoutCard direction={direction} viewType='primary'>
        <Box mb={1}>
          <Box display='flex' mb={1}>
            <Box display='flex' mr={0.5}>
              <IconDiscountCheck />
            </Box>
            <Typography variant='font16Bold'>Recommendation</Typography>
          </Box>
          <Box>
            <Typography variant='font24'>
              Save up to {costValue} per year with automation
            </Typography>
          </Box>
        </Box>
        <Box display='flex' flexDirection={'column'} mb='50px'>
          <CardMetric
            direction={direction}
            labelTitle={DISCOVER_CARD_TEXT.volumeTitle}
            value={volumeValue}
            viewType='primary'
          />
          <CardMetric
            direction={direction}
            labelTitle={DISCOVER_CARD_TEXT.resolutionTimeTitle}
            shouldShowBorderTop
            value={fullResolutionTimeValue}
            viewType='primary'
          />
          <CardMetric
            direction={direction}
            labelTitle={DISCOVER_CARD_TEXT.costTitle}
            shouldShowBorderTop
            value={costValue}
            viewType='primary'
          />
        </Box>
        <Box
          sx={{
            '& > div': {
              width: '100%',
            },
          }}
        >
          <Tooltip tooltipContent={DISCOVER_CARD_TEXT.automateButtonTooltip}>
            <Button
              data-appcues-target={getAppCuesId({
                componentType: 'button',
                featureName: 'recommendation',
                pageName: 'topicdetail',
                subType: 'calltoaction',
              })}
              fullWidth
              onClick={() => {
                dispatch(
                  setGlobalDiscoverOptions({
                    topicToAutomate: topic,
                  }),
                );

                emitTrackingEventCallback(
                  'discover-automate-button-clicked',
                  relevantTrackingData,
                );
              }}
              sx={{
                '&:hover': {
                  color: palette.colors.white,
                },
                backgroundColor: palette.colors.white,
                border: `2px solid ${palette.colors.white}`,
                color: palette.primary.main,
                height: '40px',
              }}
            >
              <Typography variant='font14Bold'>Automate</Typography>
            </Button>
          </Tooltip>
        </Box>
      </BannerLayoutCard>
    </Box>
  );
};

export default RecommendationCard;
