import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import { FetchAction } from '../../types/types';
import {
  GET_ALL_REPORTS,
  GET_ALL_REPORTS_FAILURE,
  GET_REPORT,
  GET_REPORT_FAILURE,
  ReportsActions,
} from '../actionTypes';
import { getAnswersData } from '../data/data';
import { setToastType } from '../toastNotification/toastNotification';
import { ReportsState } from 'src/reducers/reportsReducer/types';
import { GetAllReportsDict, Report } from 'src/services/apiInterfaces';
import {
  bookmarkReportAPI,
  createReportAPI,
  deleteReportAPI,
  getAllReportsAPI,
  getReportAPI,
  modifyExistingReportAPI,
} from 'src/services/apiV1';
import type { RootState } from 'src/store/rootReducer';
import {
  getReportIdFromUrl,
  getUnsavedReportFromURL,
} from 'src/utils/urlBehavior';

export const getAllReportsSuccess =
  createAction<GetAllReportsDict>(GET_ALL_REPORTS);

export const getAllReportsFailure = (error: string) => ({
  error,
  type: GET_ALL_REPORTS_FAILURE,
});

export const setCurrentReport = createAction<Report>(
  ReportsActions.SET_CURRENT_REPORT,
);

const setUnsavedReport = createAction<Report>(
  ReportsActions.SET_UNSAVED_REPORT,
);

export const getAllReports: Function =
  (fetchData?: boolean, newReportCreated?: boolean) =>
  (dispatch: Dispatch, getState: Function): Promise<void | FetchAction> => {
    const state: RootState = getState();
    const { report } = state.reports as ReportsState;

    return getAllReportsAPI()
      .then((data: GetAllReportsDict) => {
        dispatch(getAllReportsSuccess(data));
        const unsavedReport = getUnsavedReportFromURL();
        const reportIdFromUrl = getReportIdFromUrl();
        const foundReport = data.reports.find(
          (report: Report) => report.report_id === reportIdFromUrl,
        );

        const rep = foundReport || data.reports[data.reports.length - 1];

        if (!report) {
          if (unsavedReport) {
            dispatch(setUnsavedReport(unsavedReport));
          } else {
            dispatch(setCurrentReport(rep));
          }
        }
        if (newReportCreated) {
          dispatch(setCurrentReport(data.reports[0]));
        }
        if (fetchData) {
          dispatch(getAnswersData());
        }
      })
      .catch((err: Error) => {
        dispatch(getAllReportsFailure(err.message));
      });
  };

export const getReportSuccess = createAction<Report>(GET_REPORT);

const setIsReportModified = createAction<boolean>(
  ReportsActions.SET_IS_REPORT_MODIFIED,
);

export const getReportFailure = (error: string) => ({
  error,
  type: GET_REPORT_FAILURE,
});

export const getReport: Function =
  (reportId: number) =>
  (dispatch: Dispatch): Promise<void | FetchAction> => {
    return getReportAPI(reportId)
      .then((data: Report) => {
        dispatch(getReportSuccess(data));
      })
      .catch((err: Error) => {
        dispatch(getReportFailure(err.message));
      });
  };

export const createReport: Function =
  (name: string, description: string, baseReport: string) =>
  (dispatch: Dispatch): Promise<void | FetchAction> => {
    return createReportAPI(name, description, baseReport)
      .then(() => {
        dispatch(getAllReports(true, true));
      })
      .catch((err: Error) => {
        console.error(err.message);
      });
  };

export const bookmarkReport: Function =
  (reportId: number, bookmark: boolean) => (dispatch: Dispatch) => {
    return bookmarkReportAPI(reportId, bookmark)
      .then(() => {
        dispatch(getAllReports());
      })
      .catch(() => {
        dispatch(setToastType('bookmark_toast'));
      });
  };

export const deleteReport: Function =
  (reportId: number) => (dispatch: Dispatch) => {
    return deleteReportAPI(reportId)
      .then(() => {
        dispatch(getAllReports());
      })
      .catch((err: Error) => {
        console.error(err.message);
      });
  };

export const modifyExistingReport: Function =
  (
    reportId: number,
    name: string,
    description: string,
    shouldSaveAsNew: boolean,
  ) =>
  (dispatch: Dispatch) => {
    return modifyExistingReportAPI(reportId, name, description, shouldSaveAsNew)
      .then(() => {
        dispatch(setIsReportModified(false));
        dispatch(getAllReports());
        if (shouldSaveAsNew) {
          dispatch(getAllReports(true, true));
        }
      })
      .catch((err: Error) => {
        console.error(err.message);
      });
  };
