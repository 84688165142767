import { type ComponentProps, ReactNode } from 'react';
import { useTheme } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import { useStateParams } from 'src/hooks/hooks';

/**
 * Tabs that are controlled by URL params.
 */
export function URLParamTabs({
  ariaLabel,
  paramName,
  tabCallback,
  tabComponents,
  tabs,
  typographyVariant = 'font20',
}: {
  ariaLabel: string;
  paramName: string;
  tabCallback?: (tabIndex: number) => void;
  tabComponents?: (string | ReactNode)[];
  tabs: string[];
  typographyVariant?: ComponentProps<typeof Tabs>['typographyVariant'];
}) {
  const tabsToDisplay = tabComponents ?? tabs;
  const { palette } = useTheme();

  const [tabIndex, setTabIndex] = useStateParams({
    deserialize: tabName =>
      tabs.findIndex(tab => tab.toLowerCase() === tabName.toLowerCase()),
    initialState: 0,
    paramsName: paramName,
    serialize: tabIndex => tabs[tabIndex].toLowerCase(),
    validator: parameter => {
      return Boolean(tabs.find(tab => tab.toLowerCase() === parameter));
    },
  });

  const a11yTabProps = (index: number) => {
    return {
      'aria-controls': `${paramName}-tabpanel-${index}`,
      id: `${paramName}-tab-${index}`,
    };
  };

  return (
    <Tabs
      a11yProps={a11yTabProps}
      aria-label={ariaLabel}
      ContainerProps={{
        borderBottom: '1px solid' + palette.colors.slate[200],
      }}
      onChange={(_, value) => {
        setTabIndex(value);
        tabCallback?.(value);
      }}
      tabs={tabsToDisplay}
      typographyVariant={typographyVariant}
      value={tabIndex}
    />
  );
}
