import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog } from '@forethought-technologies/forethought-elements';
//Redux
import {
  selectAutomations,
  setDeleteAssistAutomationModalVisible,
} from 'src/slices/assist-automations/assistAutomationsSlice';

interface DeleteAssistAutomationModalProps {
  onDelete: () => void;
}

export const DeleteAssistAutomationModal: FC<
  React.PropsWithChildren<DeleteAssistAutomationModalProps>
> = ({ onDelete }) => {
  const dispatch = useDispatch();

  const { isDeleteAssistAutomationModalVisible, isDeleteLoading } =
    useSelector(selectAutomations);

  const onCloseClick = () =>
    dispatch(setDeleteAssistAutomationModalVisible(false));

  return (
    <Dialog
      footer={
        <>
          <Button onClick={onCloseClick} variant='ghost'>
            Cancel
          </Button>
          <Button
            isLoading={isDeleteLoading}
            onClick={onDelete}
            variant='secondary'
          >
            Delete
          </Button>
        </>
      }
      hideCloseButton
      onClose={onCloseClick}
      open={isDeleteAssistAutomationModalVisible}
      title='Are you sure you want to delete this workflow?'
    />
  );
};
