import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import debounce from 'lodash/fp/debounce';
import {
  selectAuditEventLogs,
  selectFilterValues,
} from 'src/slices/audit/auditSlice';
import {
  getAuditEventLogFilters,
  getAuditEventLogs,
} from 'src/slices/audit/thunks';
import { useAppDispatch } from 'src/store/hooks';
import {
  AuditEventSortColumn,
  AuditEventSortDirection,
  DefaultAuditEventSortColumn,
} from 'src/types/auditTypes';
import {
  constructDefaultTimeRangeQueryParams,
  last30DaysTimeRange,
} from 'src/utils/timeRangeHelpers';

export const useDebouncedCall = () => {
  const debounced = useMemo(
    () =>
      debounce(350, (callback: () => void) => {
        callback();
      }),
    [],
  );

  return debounced;
};

export const useGetAuditEventLogs = ({
  action,
  end_timestamp,
  offset,
  product,
  sort_column,
  sort_direction,
  start_timestamp,
  user_email,
}: {
  action?: string[];
  end_timestamp: number;
  offset: number;
  product?: string[];
  sort_column: AuditEventSortColumn;
  sort_direction: AuditEventSortDirection;
  start_timestamp: number;
  user_email?: string;
}) => {
  const dispatch = useAppDispatch();
  const auditEventlogs = useSelector(selectAuditEventLogs);
  const defaultFilterValues = useSelector(selectFilterValues);
  //maps current keys to the db column name
  const defaultSortColumnValue = useMemo(() => {
    const columnToDefaultColumnMap: {
      [key in AuditEventSortColumn]: DefaultAuditEventSortColumn;
    } = {
      action: 'action',
      product: 'product',
      timestamp: 'event_timestamp',
      user_email: 'actor_user_email',
    };
    return columnToDefaultColumnMap[sort_column];
  }, [sort_column]);

  useEffect(() => {
    if (start_timestamp && end_timestamp && defaultFilterValues) {
      dispatch(
        getAuditEventLogs({
          action,
          end_timestamp,
          offset,
          product,
          sort_column: defaultSortColumnValue,
          sort_direction,
          start_timestamp,
          user_email,
        }),
      );
    }
  }, [
    dispatch,
    sort_column,
    defaultSortColumnValue,
    sort_direction,
    action,
    end_timestamp,
    offset,
    product,
    start_timestamp,
    defaultFilterValues,
    user_email,
  ]);

  return auditEventlogs;
};

export const useGetAuditPageQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const qParams = new URLSearchParams(search);
    const to = qParams.get('end');
    const from = qParams.get('start');

    return { endDate: to, startDate: from };
  }, [search]);
};

export const useSetDefaultTimeRangeQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationHasQueryParams =
    location.search !== '?' && location.search.length > 0;

  useEffect(() => {
    if (!locationHasQueryParams) {
      const queryParams =
        constructDefaultTimeRangeQueryParams(last30DaysTimeRange);

      navigate(`?${queryParams}`);
    }
  }, [navigate, locationHasQueryParams, location]);
};
export const useGetAuditEventLogFilters = () => {
  const dispatch = useAppDispatch();
  const filterValues = useSelector(selectFilterValues);

  useEffect(() => {
    if (!filterValues) {
      dispatch(getAuditEventLogFilters());
    }
  }, [dispatch, filterValues]);

  return [
    {
      label: 'All Products',
      options: filterValues?.products.map(product => ({
        label: product,
        value: product,
      })),
      value: 'all_products',
    },
    {
      label: 'All Actions',
      options: filterValues?.actions.map(action => ({
        label: action,
        value: action,
      })),
      value: 'all_actions',
    },
  ];
};
