import './PromptComponentStyles.scss';

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { PromptComponentProps } from './PromptComponentTypes';
import { setWorkflowError } from 'src/actions/solveWorkflows';
import { selectShouldShowError } from 'src/reducers/solveWorkflowsReducer';

export const PromptComponent: React.FC<
  React.PropsWithChildren<PromptComponentProps>
> = ({
  description,
  iconClass,
  label,
  leftBttnCallback,
  leftBttnText,
  prompt,
  rightBttnCallback,
  rightBttnText,
  shouldShowBttns,
  shouldShowDescription,
}) => {
  const dispatch = useDispatch();
  const errorState = useSelector(selectShouldShowError);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (errorState) {
        dispatch(setWorkflowError(false));
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [errorState]);

  const promptClass = classNames('PromptComponent', {
    'PromptComponent--errorPrompt': errorState,
  });

  return (
    <>
      {((errorState && !shouldShowDescription) || shouldShowDescription) && (
        <div className={promptClass}>
          <div className='PromptComponent-headingCont'>
            <div className={`PromptComponent-icon ${iconClass}`} />
            <div className='PromptComponent-promptCont'>
              <span className='PromptComponent-label'>{label}</span>
              <p className='PromptComponent-prompt'>{prompt}</p>
            </div>
          </div>
          {shouldShowDescription && (
            <p className='PromptComponent-description'>{description}</p>
          )}
          {shouldShowBttns && (
            <div className='PromptComponent-buttonCont'>
              <button
                className='PromptComponent-leftBttn'
                data-testid='prompt-component-left-btn'
                onClick={() => leftBttnCallback && leftBttnCallback()}
              >
                {leftBttnText}
              </button>
              <button
                className='PromptComponent-rightBttn'
                data-testid='prompt-component-right-btn'
                onClick={e => rightBttnCallback && rightBttnCallback(e)}
              >
                {rightBttnText}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
