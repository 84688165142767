import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

interface HighlightedTextProps {
  keyword?: string;
  text?: string;
}

const HighlightedText = ({ keyword, text }: HighlightedTextProps) => {
  const { palette } = useTheme();

  if (!text || !keyword) {
    return text;
  }

  const parts = text.split(new RegExp(`(${keyword})`, 'gi'));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === keyword.toLowerCase() ? (
          <Box
            bgcolor={palette.colors.purple[200]}
            component='span'
            key={index}
          >
            {part}
          </Box>
        ) : (
          part
        ),
      )}
    </>
  );
};

export default HighlightedText;
