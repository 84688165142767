import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTheme } from '@mui/material';

import { generateTooltip } from './utils';

interface Data {
  color: string;
  y: number;
}
interface PriceColumnGraphProps {
  categories: string[];
  contractQuota: number;
  data: Data[];
  tooltipTitle: string;
  yAxisLabel: string;
}

const PriceColumnGraph: React.FC<PriceColumnGraphProps> = ({
  categories,
  contractQuota,
  data,
  tooltipTitle,
  yAxisLabel,
}: PriceColumnGraphProps) => {
  const theme = useTheme();

  const options = {
    chart: {
      height: 304,
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointWidth: 18,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        data,
      },
    ],
    title: {
      text: undefined,
    },

    tooltip: {
      borderColor: theme.palette.colors.slate[200],
      borderWidth: 0,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return generateTooltip({
          contractQuota,
          object: this,
          theme,
          title: tooltipTitle,
        });
      },
      hideDelay: 0,
      outside: true,
      shadow: false,
      shared: false,
      useHTML: true,
    },

    xAxis: {
      categories,

      labels: {
        style: {
          color: theme.palette.colors.grey[600],
          fontSize: '11px',
        },
      },
      lineColor: theme.palette.colors.grey[200],
      title: {
        text: null,
      },
    },
    yAxis: {
      gridLineColor: theme.palette.colors.grey[200],
      labels: {
        style: {
          color: theme.palette.colors.grey[700],
          fontSize: '11px',
        },
      },
      title: {
        style: {
          color: theme.palette.colors.grey[700],
          fontSize: '14px',
        },
        text: yAxisLabel,
      },
    },
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PriceColumnGraph;
