import './FilterBar.scss';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import FilterTag from '../../../reusable-components/filter-tag/FilterTag';
import BooleanFilterModal from '../boolean-filter-modal/BooleanFilterModal';
import DaypickerFilter from '../daypicker-filter/DaypickerFilter';
import FilterFieldsModal from '../filter-fields-modal/FilterFieldsModal';
import FiltersTooltip from '../filters-tooltip/FiltersTooltip';
import IntFilterModal from '../int-filter-modal/IntFilterModal';
import StringFilterModal from '../string-filter-modal/StringFilterModal';
import { getAnswersData } from 'src/actions/data/data';
import {
  clearFilters,
  clearSelectedFilterValues,
  deleteFilter,
  setFilterFieldNull,
} from 'src/actions/filters/filters';
import {
  selectFilters,
  selectSelectedFilterField,
} from 'src/reducers/filtersReducer/filtersReducer';
import { Filters } from 'src/services/apiInterfaces';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';

const FilterBar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);
  let isComponentMounted = true;
  const dispatch = useDispatch();
  const selectedFilterField = useSelector(selectSelectedFilterField);
  const filters = useSelector(selectFilters);
  const [areFiltersOverflowed, setAreFiltersOverflowed] =
    useState<boolean>(false);
  const [isTooltipVisible, setTooltipVisibility] = useState<boolean>(false);
  const [tooltipTitle, setTooltipTitle] = useState<string>('');
  const [tooltipCategory, setTooltipCategory] = useState<string>('');
  const [tooltipValues, setTooltipValues] = useState<string[]>([]);

  const fieldsModalRef: React.RefObject<HTMLDivElement> = useRef(null);
  const buttonRef: React.RefObject<HTMLButtonElement> = useRef(null);
  const filtersContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const valuesModalRef: React.RefObject<HTMLDivElement> = useRef(null);
  let heigthOffset = 0;
  const handleModalVisibility = () => {
    setModalVisibility(!isModalVisible);
    if (!isModalVisible) {
      dispatch(setFilterFieldNull());
      dispatch(clearSelectedFilterValues());
    }
  };

  const clearFiltersClass = classNames('FilterBar-clearFilters', {
    'FilterBar--clearFiltersGreen': filters.length,
  });

  const renderModal = () => {
    const type = selectedFilterField?.field_type;
    let Modal = null;
    if (type === 'STRING' || type === 'URL') {
      Modal = StringFilterModal;
    } else if (type === 'DATE') {
      Modal = DaypickerFilter;
    } else if (type === 'INTEGER' || type === 'DOUBLE') {
      Modal = IntFilterModal;
    } else if (type === 'BOOLEAN') {
      Modal = BooleanFilterModal;
    }

    return (
      <>
        <FilterFieldsModal fieldsReference={fieldsModalRef} />

        {Modal && (
          <Modal
            handleModalVisibility={() => handleModalVisibility()}
            reference={valuesModalRef}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    heigthOffset = filtersContainerRef.current
      ? filtersContainerRef.current.scrollHeight
      : 0;
    if (heigthOffset > 111) {
      setAreFiltersOverflowed(true);
    } else {
      setAreFiltersOverflowed(false);
    }
  }, [filters.length]);

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        buttonRef,
        fieldsModalRef,
        setModalVisibility,
        valuesModalRef,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);

  return (
    <div className='FilterBar-container'>
      <div className='FilterBar-titleBtnContainer'>
        <div className='FilterBar-titleContainer'>
          <span className='FilterBar-title'>Filters</span>
          <button
            className={clearFiltersClass}
            disabled={!filters.length}
            onClick={() => {
              dispatch(clearFilters());
              dispatch(getAnswersData());
              setAreFiltersOverflowed(false);
            }}
          >
            Clear Filters
          </button>
        </div>
        <div className='FilterBar-addFilterButtonContainer'>
          {isModalVisible && renderModal()}

          <button
            className='FilterBar-addFilterButton'
            onClick={handleModalVisibility}
            ref={buttonRef}
          >
            Add Filter <span className='FilterBar-addFilterButtonIcon'></span>
          </button>
          {areFiltersOverflowed && (
            <button className='FilterBar-scrollForMore'>Scroll for more</button>
          )}
        </div>
      </div>
      <div
        className='FilterBar-addedFiltersContainer'
        ref={filtersContainerRef}
      >
        {filters.map((filter: Filters, index: number) => (
          <FilterTag
            data={filter}
            isLastItem={filters.length - 1 === index}
            key={index}
            onClickDeleteBtn={() => {
              dispatch(deleteFilter(index));
              dispatch(getAnswersData());
            }}
            onHover={(title?: string, category?: string, values?: string[]) => {
              setTooltipTitle(title as string);
              setTooltipCategory(category as string);
              setTooltipValues(values as string[]);
              setTooltipVisibility(true);
            }}
            setTooltipVisibility={setTooltipVisibility}
          />
        ))}
      </div>
      {isTooltipVisible && (
        <FiltersTooltip
          category={tooltipCategory}
          isFilter
          title={tooltipTitle}
          values={tooltipValues.map(value => value.toString())}
        />
      )}
    </div>
  );
};

export default FilterBar;
