import { styled } from '@mui/material';

import Icon from 'src/assets/images/support-gpt.svg';

const GenAiIcon = () => {
  return <Img src={Icon} />;
};

export default GenAiIcon;

const Img = styled('img')`
  max-width: 100%;
`;
