import React from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { GREETING_LINE_PLACEHOLDER } from '../constants';
import { useGetIntents } from '../hooks/useGetIntents';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import IntentButton from '../intent-button/IntentButton';
import {
  AgentMessage,
  AgentMessageContainer,
  CustomImage,
  WidgetContentContainer,
} from '../styledComponents';
import { handleIntentNames } from '../utils';
import BannerImage from './BannerImage';
import { getIntentOptionType } from './helper';
import agentChatImageDefaultIcon from 'src/assets/images/agent-chat-image-default.svg';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import { replaceContextVariableIdsWithMarkup } from 'src/components/rich-text-editor/helpers/replaceContextVariableIdsWithMarkup';
import useStrippedHTML from 'src/hooks/useStrippedHTML';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectIsSaving,
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

type ThemeWidgetContentProps = {
  primaryInstruction?: string;
  secondaryInstruction?: string;
};

const ThemeWidgetContent: React.FC<
  React.PropsWithChildren<ThemeWidgetContentProps>
> = ({
  primaryInstruction = 'Personalize your chat by using the menu on the right.',
  secondaryInstruction,
}) => {
  const theme = useTheme();
  const {
    agent_chat_image,
    banner_image_config,
    greeting_line: greetingLine,
    manually_selected_top_intents: manuallySelectedTopIntents,
    num_top_intents: numTopIntents,
    theme_color,
    top_n_intents: topNIntents,
  } = useSelector(selectWidgetConfiguration);
  const intents = useGetIntents({ isActiveUserCreatedWorkflows: true });
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const fontColor = useSelector(selectLegibleTextColor);
  const isSaving = useSelector(selectIsSaving);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const intentType = getIntentOptionType(
    manuallySelectedTopIntents,
    numTopIntents,
  );

  const remirrorContextVariables = useGetRemirrorContextVariables();
  const intentNames = handleIntentNames({
    intents,
    intentType,
    isSaving,
    manuallySelectedTopIntents,
    numTopIntents,
    topNIntents,
  });

  const isGreetingLinePlainText =
    useStrippedHTML(greetingLine) === greetingLine;

  return (
    <>
      {featureFlags.includes('banner_image') && (
        <BannerImage config={banner_image_config} />
      )}
      <WidgetContentContainer>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '32px 32px 0px 32px',
          }}
        >
          <Box
            sx={{
              background:
                'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
              fontStyle: 'italic',
              textAlign: 'center',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            <Typography variant='font16'>{primaryInstruction}</Typography>
          </Box>
          {secondaryInstruction && (
            <Typography color={theme.palette.colors.grey[400]} variant='font16'>
              {secondaryInstruction}
            </Typography>
          )}
        </Box>
        <ChatContainer>
          <AgentMessageContainer>
            <CustomImage
              alt=''
              src={
                agent_chat_image ? agent_chat_image : agentChatImageDefaultIcon
              }
            />
            <AgentMessage>
              <Typography variant='font14Medium'>
                <Box
                  sx={{
                    marginBlockEnd: isGreetingLinePlainText ? '1em' : 'initial',
                    marginBlockStart: isGreetingLinePlainText
                      ? '1em'
                      : 'initial',
                  }}
                >
                  <HtmlComponent
                    content={replaceContextVariableIdsWithMarkup(
                      greetingLine || `<p>${GREETING_LINE_PLACEHOLDER}</p>`,
                      remirrorContextVariables,
                    )}
                    linkifyHtmlOptions={solveLinkifyHtmlOptions}
                  />
                </Box>
              </Typography>
            </AgentMessage>
          </AgentMessageContainer>
          {!isSolveLiteEnabled && (
            <Box>
              {intentNames.map(intentName => (
                <IntentButton
                  intentName={intentName}
                  isProactiveBubble={false}
                  key={intentName}
                />
              ))}
            </Box>
          )}
          <ResponseMessage backgroundColor={theme_color}>
            <Typography color={fontColor} variant='font14Medium'>
              I have some issues with my account
            </Typography>
          </ResponseMessage>
        </ChatContainer>
      </WidgetContentContainer>
    </>
  );
};

const ChatContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ResponseMessage = styled('div')<{ backgroundColor: string }>`
  align-self: flex-end;
  background-color: ${props => props.backgroundColor};
  border-radius: 8px;
  padding: 10px 16px;
`;

export default ThemeWidgetContent;
