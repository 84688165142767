import {
  Container,
  Footer,
  FooterLink,
  Icon,
  StatusLabel,
  Subtitle,
  Title,
  TitleContainer,
} from './styles';
import { CardProps } from './types';
import StatusIndicator from 'src/components/status-indicator';

const Card = ({
  disabled,
  disabledLink,
  iconSrc,
  linkText,
  onClick,
  status,
  statusLabel,
  subtitle,
  title,
}: CardProps) => {
  return (
    <Container disabled={disabled} onClick={disabled ? undefined : onClick}>
      <TitleContainer>
        <Icon src={iconSrc} />
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      <Footer>
        <StatusIndicator status={status} />
        {statusLabel && <StatusLabel>{statusLabel}</StatusLabel>}
        {disabled ? disabledLink : <FooterLink>{linkText}</FooterLink>}
      </Footer>
    </Container>
  );
};

export default Card;
