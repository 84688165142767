import Skeleton from 'react-loading-skeleton';
import { Box } from '@mui/material';

import { Wrapper } from '../DynamicArticleCard';

const ResponseGenerationCardLoadingSkeleton = () => {
  return (
    <Wrapper>
      <Box
        sx={theme => ({
          backgroundColor: theme.palette.colors.white,
          borderRadius: '7px',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          padding: '30px 15px',
        })}
      >
        <Skeleton height='42px' />
        <Skeleton width='43%' />
        <Skeleton width='85%' />
        <Skeleton width='81%' />
        <Skeleton width='62%' />
        <Skeleton width='85%' />
      </Box>
    </Wrapper>
  );
};

export default ResponseGenerationCardLoadingSkeleton;
