import React from 'react';

import ArticleStepRevamped from 'src/pages/workflow-builder-edit/workflow-builder/revamped/ArticleStep';
import { Step } from 'src/types/workflowBuilderAPITypes';

interface ArticleStepProps {
  isCanvasDisabled: boolean;
  setShouldShowEditMenu: (shouldShow: boolean) => void;
  shouldShowEditMenu: boolean;
  step: Step;
  stepId: string;
}

const ArticleStep: React.FC<React.PropsWithChildren<ArticleStepProps>> = ({
  isCanvasDisabled,
  setShouldShowEditMenu,
  shouldShowEditMenu,
  step,
  stepId,
}) => {
  return (
    <ArticleStepRevamped
      isCanvasDisabled={isCanvasDisabled}
      setShouldShowEditMenu={setShouldShowEditMenu}
      shouldShowEditMenu={shouldShowEditMenu}
      step={step}
      stepId={stepId}
    />
  );
};

export default ArticleStep;
