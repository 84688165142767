import { ConnectDragSource } from 'react-dnd';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconGripVertical } from '@tabler/icons-react';

import {
  Badge,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface DraggableArticleCardProps {
  badgeText?: string;
  description: string;
  drag?: ConnectDragSource;
  hasGradientBorder?: boolean;
  icon: JSX.Element;
  isDragging: boolean;
  label: string;
  link?: string;
}

const DraggableArticleCard = ({
  badgeText = '',
  description,
  drag,
  hasGradientBorder = false,
  icon,
  isDragging,
  label,
  link = '',
}: DraggableArticleCardProps) => {
  const { palette } = useTheme();
  const opacity = isDragging ? 0.3 : 1;

  return (
    <Box
      alignItems='center'
      bgcolor={palette.common.white}
      border={
        hasGradientBorder ? undefined : `1px solid ${palette.colors.slate[200]}`
      }
      borderRadius='8px'
      display='flex'
      p='16px 16px 16px 8px'
      ref={drag}
      sx={{
        '&:hover': {
          borderColor: palette.colors.grey[300],
        },
        cursor: drag ? 'grab' : 'not-allowed',
        opacity: drag ? opacity : 0.3,
        transform: 'translate(0, 0)',
        ...(hasGradientBorder && {
          background:
            'linear-gradient(white, white) padding-box, var(--gradient-blue-purple) border-box',
          border: '1px solid transparent',
        }),
      }}
    >
      <Box alignItems='center' display='flex' mr={0.5}>
        <IconGripVertical color={palette.colors.grey[300]} size={20} />
      </Box>
      <Box overflow='hidden'>
        <Box display='flex' justifyContent='space-between' marginBottom='4px'>
          {icon}
          <Box>{badgeText && <Badge label={badgeText} variant='macro' />}</Box>
        </Box>
        <Box>
          {badgeText === 'Public' ? (
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <LinkToArticle href={link} target='_blank'>
                <Typography variant='font16Bold'>{label}</Typography>
              </LinkToArticle>
            </Box>
          ) : (
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography variant='font16Bold'>{label}</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
          }}
        >
          <Typography color={palette.colors.grey[600]} variant='font12'>
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const LinkToArticle = styled('a')`
  display: flex;
  color: ${props => props.theme.palette.colors.black};
`;

export default DraggableArticleCard;
