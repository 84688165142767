import React from 'react';
import { FormControl, FormHelperText } from '@mui/material';

interface WithHelperTextProps {
  children: React.ReactNode;
  helperText: string | null;
}

export default function WithHelperText({
  children,
  helperText,
}: WithHelperTextProps) {
  return (
    <FormControl error fullWidth>
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
