import { createSelector } from '@reduxjs/toolkit';
import { captureMessage } from '@sentry/react';

import { selectCustomizableActionId } from '../actionBuilderReducer/actionBuilderReducer';
import { selectUserCan } from '../userReducer/userReducer';
import { getChatOrder } from './chatOrder';
import {
  doesActionContainStepWithType,
  getArticleSuggestionActionKeyId,
  getIsTerminalAction,
  isDynamicArticleSuggestion,
} from './workflowBuilderReducerHelpers';
import flow from 'lodash/fp/flow';
import keyBy from 'lodash/fp/keyBy';
import mapValues from 'lodash/fp/mapValues';
import values from 'lodash/fp/values';
import { WorkflowBuilderActions } from 'src/actions/workflow-builder/workflowBuilderActionTypes';
import { IntentType } from 'src/pages/workflow-builder/suggested-improvements/constants';
import {
  FetchAction,
  WorkflowBuilderState,
} from 'src/reducers/workflowBuilderReducer/types/WorkflowBuilderReducerTypes';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import type { RootState } from 'src/store/rootReducer';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import {
  ActionStepList,
  ButtonsStepFields,
  ConfigurationTranslation,
  ContextVariablesStep,
  DecommissionWorkflowResponse,
  GetConfigurationTranslationsForOrgResponse,
  GetRestoreTranslationResponse,
  GetStepTranslationsForOrgResponse,
  GetStepTranslationsForStepResponse,
  Intent,
  isStepTranslation,
  LanguagesEnabledResponse,
  LanguagesResponse,
  StepTranslation,
  SuggestedIntentsResponse,
  Transition,
  WidgetConfigResponse,
  WorkflowBuilderLandingPageData,
} from 'src/types/workflowBuilderAPITypes';
import {
  areTransitionBranchesFilled,
  areTransitionBranchesFilledForDynamicArticleSuggestion,
  getTransitionStepIdsForArticleSuggestion,
  getTransitionStepIdsForDynamicArticleSuggestion,
  isArticleSuggestionTheLeaf,
  isDynamicArticleSuggestionTheLeaf,
} from 'src/utils/areTransitionBranchesFilled';
import {
  CommonIntentWorkflowType,
  DecommissionWorkflowType,
  StepTypes,
  TranslationsTableMode,
} from 'src/utils/enums';
import { getFileNameFromUrl } from 'src/utils/getFileNameFromUrl';
import {
  getGranularArticleSuggestionV2ParentStepId,
  isEntryStepOfGranularArticleSuggestionV2,
} from 'src/utils/granularArticleSuggestionV2Utils';

export const workflowBuilderInitialState: WorkflowBuilderState = {
  currentView: '',
  decommissionWorkflowError: null,
  draftTranslations: [],
  error: '',
  isConfigTranslationsLoading: false,
  isErrorBannerVisible: false,
  isInfoBannerVisible: false,
  isLoading: false,
  isModelTraining: false,
  isOverlayVisible: false,
  isPreviewLoaded: false,
  isStepTranslationsLoading: false,
  landingData: null,
  languages: [],
  languagesEnabled: {
    language_codes: [],
    modified_date: null,
  },
  selectedChannel: '',
  stepLevelTranslationsId: '',
  suggestedIntents: [],
  translations: [],
  translationsTableMode: TranslationsTableMode.LIVE,
  usableContextVariablesForTranslation: {
    defined_context_variables: [],
    step_output_variables: [],
  },
  widgetApiKey: '',
  widgetConfig: null,
};

export default function workflowBuilderReducer(
  state = workflowBuilderInitialState,
  action: FetchAction,
): WorkflowBuilderState {
  if (action.type === WorkflowBuilderActions.SET_CHANNEL) {
    const payload = action.payload as string;
    return { ...state, selectedChannel: payload };
  }

  if (action.type === WorkflowBuilderActions.GET_LANDING_DATA_SUCCESS) {
    const landingData = action.payload as WorkflowBuilderLandingPageData;

    if (!landingData.channels) {
      return state;
    }

    const selectedChannel = landingData.channels.includes('widget')
      ? 'widget'
      : 'email';

    if (!state.selectedChannel) {
      return { ...state, landingData, selectedChannel };
    } else {
      return { ...state, landingData };
    }
  }

  if (action.type === WorkflowBuilderActions.GET_WIDGET_CONFIG_SUCCESS) {
    const payload = action.payload as WidgetConfigResponse;
    const widgetConfig = {
      ...payload,
      agent_chat_image: getFileNameFromUrl(payload.agent_chat_image || ''),
      header_image: getFileNameFromUrl(payload.header_image || ''),
      help_button_image: getFileNameFromUrl(payload.help_button_image || ''),
    };

    return { ...state, widgetConfig };
  }

  if (action.type === WorkflowBuilderActions.GET_LANGUAGES_SUCCESS) {
    const payload = action.payload as LanguagesResponse;
    return { ...state, languages: payload.languages };
  }

  if (action.type === WorkflowBuilderActions.GET_LANGUAGES_ENABLED_SUCCESS) {
    const payload = action.payload as LanguagesEnabledResponse;
    return { ...state, languagesEnabled: payload };
  }

  if (
    action.type === WorkflowBuilderActions.GET_STEP_TRANSLATION_FOR_ORG_SUCCESS
  ) {
    const payload = action.payload as GetStepTranslationsForOrgResponse;

    // mismatch mode, that means the requested translations are outdated in FE
    if (payload.mode !== state.translationsTableMode) {
      return { ...state };
    }

    // we don't show config translations for draft translations table
    const configurationTranslations =
      payload.mode !== TranslationsTableMode.DRAFT
        ? state.translations.filter(
            translation => !isStepTranslation(translation),
          )
        : [];
    const newTranslations = configurationTranslations.concat(
      payload.translations,
    );
    return {
      ...state,
      draftTranslations: newTranslations,
      translations: newTranslations,
    };
  }

  if (
    action.type ===
    WorkflowBuilderActions.GET_STEP_TRANSLATIONS_FOR_STEP_SUCCESS
  ) {
    const payload = action.payload as GetStepTranslationsForStepResponse;
    return {
      ...state,
      draftTranslations: payload.translations,
      translations: payload.translations,
    };
  }

  if (
    action.type ===
    WorkflowBuilderActions.GET_CONFIGURATION_TRANSLATION_FOR_ORG_SUCCESS
  ) {
    const payload =
      action.payload as GetConfigurationTranslationsForOrgResponse;
    const stepTranslations = state.translations.filter(translation =>
      isStepTranslation(translation),
    );
    const configurationTranslations: (
      | StepTranslation
      | ConfigurationTranslation
    )[] = payload.translations;
    const newTranslations = configurationTranslations.concat(stepTranslations);
    return {
      ...state,
      draftTranslations: newTranslations,
      translations: newTranslations,
    };
  }

  if (action.type === WorkflowBuilderActions.GET_RESTORE_TRANSLATION_SUCCESS) {
    const payload = action.payload as GetRestoreTranslationResponse;
    if (
      payload.translation === null ||
      payload.index >= state.draftTranslations.length ||
      payload.index >= state.translations.length
    ) {
      return state;
    }

    const newDraftTranslations = state.draftTranslations.slice();
    newDraftTranslations[payload.index] = payload.translation;

    const newTranslations = state.translations.slice();
    newTranslations[payload.index] = payload.translation;

    return {
      ...state,
      draftTranslations: newDraftTranslations,
      translations: newTranslations,
    };
  }

  if (
    action.type ===
    WorkflowBuilderActions.GET_USABLE_CONTEXT_VARIABLES_FOR_TRANSLATION_SUCCESS
  ) {
    const payload = action.payload as ContextVariablesStep;
    return { ...state, usableContextVariablesForTranslation: payload };
  }

  if (action.type === WorkflowBuilderActions.SET_CURRENT_VIEW) {
    const payload = action.payload as string;
    return { ...state, currentView: payload };
  }

  if (action.type === WorkflowBuilderActions.GET_SUGGESTED_INTENTS_SUCCESS) {
    const { suggested_intents: suggestedIntents } =
      action.payload as SuggestedIntentsResponse;
    return { ...state, suggestedIntents };
  }

  if (action.type === WorkflowBuilderActions.CLEAR_SUGGESTED_INTENTS) {
    return { ...state, suggestedIntents: [] };
  }

  if (action.type === WorkflowBuilderActions.SET_IS_ERROR_BANNER_VISIBLE) {
    const payload = action.payload as boolean;
    return { ...state, isErrorBannerVisible: payload };
  }

  if (action.type === WorkflowBuilderActions.SET_IS_INFO_BANNER_VISIBLE) {
    const payload = action.payload as boolean;
    return { ...state, isInfoBannerVisible: payload };
  }

  if (action.type === WorkflowBuilderActions.SET_DEACTIVATE_WORKFLOW_ERROR) {
    const payload = action.payload as DecommissionWorkflowResponse;
    return {
      ...state,
      decommissionWorkflowError: {
        referenced_data: payload?.detail.referenced_data,
        type: DecommissionWorkflowType.DEACTIVATE,
      },
    };
  }

  if (action.type === WorkflowBuilderActions.SET_DELETE_WORKFLOW_ERROR) {
    const payload = action.payload as DecommissionWorkflowResponse;
    return {
      ...state,
      decommissionWorkflowError: {
        referenced_data: payload?.detail.referenced_data,
        type: DecommissionWorkflowType.DELETE,
      },
    };
  }

  if (action.type === WorkflowBuilderActions.SET_DRAFT_TRANSLATIONS) {
    const payload = action.payload as (
      | StepTranslation
      | ConfigurationTranslation
    )[];
    return {
      ...state,
      draftTranslations: payload,
    };
  }

  if (action.type === WorkflowBuilderActions.ClEAR_WORKFLOW_ERROR) {
    return { ...state, decommissionWorkflowError: null };
  }

  if (action.type === WorkflowBuilderActions.SET_IS_OVERLAY_VISIBLE) {
    return { ...state, isOverlayVisible: action.payload as boolean };
  }

  if (action.type === WorkflowBuilderActions.SET_IS_PREVIEW_LOADED) {
    return { ...state, isPreviewLoaded: action.payload as boolean };
  }

  if (action.type === WorkflowBuilderActions.SET_IS_MODEL_TRAINING) {
    return { ...state, isModelTraining: action.payload as boolean };
  }

  if (action.type === WorkflowBuilderActions.GET_WIDGET_API_KEY_SUCCESS) {
    return { ...state, widgetApiKey: action.payload as string };
  }

  if (action.type === WorkflowBuilderActions.GET_IS_MODEL_TRAINING) {
    return {
      ...state,
      isModelTraining: action.payload as boolean,
    };
  }

  if (
    action.type === WorkflowBuilderActions.SET_IS_CONFIG_TRANSLATIONS_LOADING
  ) {
    return {
      ...state,
      isConfigTranslationsLoading: action.payload as boolean,
    };
  }

  if (action.type === WorkflowBuilderActions.SET_IS_STEP_TRANSLATIONS_LOADING) {
    return {
      ...state,
      isStepTranslationsLoading: action.payload as boolean,
    };
  }

  if (action.type === WorkflowBuilderActions.SET_STEP_LEVEL_TRANSLATIONS_ID) {
    return {
      ...state,
      stepLevelTranslationsId: action.payload as string,
    };
  }

  if (action.type === WorkflowBuilderActions.SET_IS_LOADING) {
    return {
      ...state,
      isLoading: action.payload as boolean,
    };
  }

  if (action.type === WorkflowBuilderActions.SET_TRANSLATIONS_TABLE_MODE) {
    return {
      ...state,
      translationsTableMode: action.payload as TranslationsTableMode,
    };
  }
  if (action.type === WorkflowBuilderActions.SET_NETWORK_ERROR) {
    const payload = action.payload as string;
    return { ...state, error: payload };
  }
  return state;
}

export const selectWorkflowBuilderWidgetConfig = (
  state: RootState,
): WorkflowBuilderState['widgetConfig'] => state.workflowBuilder.widgetConfig;

export const selectWorkflowBuilderLanguages = (
  state: RootState,
): WorkflowBuilderState['languages'] => state.workflowBuilder.languages;

export const selectWorkflowBuilderLanguagesEnabled = (
  state: RootState,
): WorkflowBuilderState['languagesEnabled'] =>
  state.workflowBuilder.languagesEnabled;

export const selectStepTranslations = (
  state: RootState,
): WorkflowBuilderState['translations'] => state.workflowBuilder.translations;

export const selectStepLevelTranslationsId = (
  state: RootState,
): WorkflowBuilderState['stepLevelTranslationsId'] =>
  state.workflowBuilder.stepLevelTranslationsId;

export const selectTranslationsTableMode = (
  state: RootState,
): WorkflowBuilderState['translationsTableMode'] =>
  state.workflowBuilder.translationsTableMode;

export const selectDraftTranslations = (
  state: RootState,
): WorkflowBuilderState['translations'] =>
  state.workflowBuilder.draftTranslations;

export const selectUsableContextVariablesForTranslation =
  (contextVariables: ContextVariable[]) =>
  (state: RootState): ContextVariable[] => {
    const workflowBuilder = state.workflowBuilder as WorkflowBuilderState;

    const usableContextVariablesIds = [
      ...workflowBuilder.usableContextVariablesForTranslation
        .defined_context_variables,
      ...contextVariables
        .filter(cv => cv.is_workflow_context)
        .map(cv => cv.context_variable_id),
    ];

    return contextVariables.filter(({ context_variable_id }) =>
      usableContextVariablesIds.includes(context_variable_id),
    );
  };

export const selectEditingStepId = (
  state: RootState,
): CanvasWorkflowBuilderState['editing_step_id'] =>
  state.canvasWorkflowBuilder.editing_step_id;

export const selectMode = (
  state: RootState,
): CanvasWorkflowBuilderState['mode'] => state.canvasWorkflowBuilder.mode;

export const selectEditingStepType = (
  state: RootState,
): CanvasWorkflowBuilderState['editing_step_type'] =>
  state.canvasWorkflowBuilder.editing_step_type;

export const selectWorkflowBuilderSuggestedIntents = (
  state: RootState,
): WorkflowBuilderState['suggestedIntents'] =>
  state.workflowBuilder.suggestedIntents;

export const selectWorkflowBuilderSuggestedGapIntents = createSelector(
  selectWorkflowBuilderSuggestedIntents,
  (suggestedIntents: WorkflowBuilderState['suggestedIntents']) => {
    return suggestedIntents
      .filter(
        suggestedIntent => suggestedIntent.intent_type === IntentType.CUSTOM,
      )
      .sort(
        (intent1, intent2) =>
          (intent2.expected_coverage || 0) - (intent1.expected_coverage || 0),
      );
  },
);

export const selectSelectedConditions = (
  state: RootState,
): CanvasWorkflowBuilderState['selectedConditions'] =>
  state.canvasWorkflowBuilder.selectedConditions;

export const selectDecommissionWorkflowError = (
  state: RootState,
): WorkflowBuilderState['decommissionWorkflowError'] =>
  state.workflowBuilder.decommissionWorkflowError;

export const selectCanvasWorkflowBuilder = (
  state: RootState,
): CanvasWorkflowBuilderState => state.canvasWorkflowBuilder;

// chatOrder determines what is being shown to the user, since it wouldn't make
// sense to show more than one branch at a time
export const selectChatOrder = createSelector(
  [
    selectCanvasWorkflowBuilder,
    (state: RootState): RootState['actionBuilder']['actions'] =>
      state.actionBuilder.actions,
  ],
  getChatOrder,
);

export const selectChatOrderLastStepId = createSelector(
  [selectChatOrder],
  (chatOrder): string | null => chatOrder[chatOrder.length - 1] ?? null,
);

export const selectIsPasteAvailable = createSelector(
  [selectCanvasWorkflowBuilder, selectChatOrder],
  (canvasWorkflowBuilder, chatOrder): boolean => {
    const {
      outputVariableValuesMap,
      selectedConditions,
      steps: stepGraph,
    } = canvasWorkflowBuilder;
    const hasSteps = !!Object.keys(stepGraph).length;

    /**
     * If it has steps there are few conditions that allow possibility of pasting
     * 1. The last step has no transition
     * 2. If the last step has transitions
     *    a. Option
     *      1.a. The transition it is selected on has no step_id defined
     *    b. Condition
     *      1.a. The selectedConditions it is selected on has value (index of the option)
     */

    if (hasSteps) {
      const stepId = chatOrder[chatOrder.length - 1];
      const transitions: Transition[] = stepGraph[stepId]?.transitions;
      if (stepId && transitions.length) {
        const stepFields = stepGraph?.[stepId]?.step_fields;
        const stepType = stepGraph?.[stepId]?.step_type;
        if (stepType === 'condition') {
          return selectedConditions?.[stepId] !== undefined;
        }
        if (stepType === 'buttons') {
          const outputVariableName =
            (stepFields as ButtonsStepFields)?.output_variable ?? null;
          const outputValue =
            outputVariableValuesMap?.[stepId]?.[outputVariableName];
          if (Boolean(outputValue)) {
            const transition = stepGraph?.[stepId]?.transitions.find(
              transition =>
                transition.condition_expression?.values?.includes(outputValue),
            );
            return !transition?.step_id;
          } else {
            return false;
          }
        }
      }
    }
    // If no steps, meaning clean workflow, allow ability to paste
    return true;
  },
);

/*
  A branch is said to be "valid" from a given stepId if every downstream step's
  transitions have a defined stepId, AND all the CVs used in the downstream steps are
  either workflow CVs or defined by an upstream step.

  This function returns
  1. whether every downstream step's transitions have a defined stepId
  2. the list of undefined CVs that are used by the downstream steps

  For 1, There is a notable exception for article suggestion. Article suggestion does a special attachment
  onto a text step attached to a button. From the POV of the internal button, it would satisfy the branch's
  validity, but we actually want to know if the text step has an attachment.
 */
export const selectIsBranchValid = (
  branchStepId: string | null | undefined,
  isEntryStep = true,
) =>
  createSelector(
    (state: RootState) => state.canvasWorkflowBuilder,
    (state: RootState) => state.actionBuilder.actions,
    (canvasWorkflowBuilder, actions): [boolean, string[]] => {
      if (!branchStepId) {
        return [isEntryStep, []];
      }

      const undefined_downstream_cvs_set = new Set<string>();
      let isBranchFilled = true;
      const canvasActions =
        canvasWorkflowBuilder.canvas_action_id_to_action_component;

      const queue = [branchStepId];
      const undefined_context_variables_in_step =
        canvasWorkflowBuilder.undefined_context_variables_in_step || {};

      while (queue.length) {
        const stepId = queue.shift() as string;
        const step = canvasWorkflowBuilder.steps[stepId];

        // check if this step uses undefined cvs
        if (stepId in undefined_context_variables_in_step) {
          undefined_context_variables_in_step[stepId]?.forEach(cv =>
            undefined_downstream_cvs_set.add(cv),
          );
        }
        const articleSuggestionActionId = getArticleSuggestionActionKeyId(
          canvasActions,
          actions,
          stepId,
        );

        if (articleSuggestionActionId) {
          const isDynamicArticleSuggestionVar = isDynamicArticleSuggestion(
            canvasWorkflowBuilder,
            articleSuggestionActionId,
          );
          const transitionBranches =
            canvasActions[articleSuggestionActionId]?.transition_branches || [];

          const areTransitionsValid = isDynamicArticleSuggestionVar
            ? areTransitionBranchesFilledForDynamicArticleSuggestion(
                canvasWorkflowBuilder,
                transitionBranches,
              )
            : areTransitionBranchesFilled(
                canvasWorkflowBuilder,
                transitionBranches,
              );

          if (!areTransitionsValid) {
            isBranchFilled = false;
          }
          const transitionStepIds = isDynamicArticleSuggestionVar
            ? getTransitionStepIdsForDynamicArticleSuggestion(
                canvasWorkflowBuilder,
                transitionBranches,
              )
            : getTransitionStepIdsForArticleSuggestion(
                canvasWorkflowBuilder,
                transitionBranches,
              );
          if (!transitionStepIds) {
            isBranchFilled = false;
          } else {
            transitionStepIds?.forEach(stepId => queue.push(stepId));
          }
        } else if (isEntryStepOfGranularArticleSuggestionV2(stepId)) {
          // if the step is the start of a Granular article suggestion V2, push the condition step within the
          // action to the queue, and it will be handled later in the loop
          const conditionStepId =
            getGranularArticleSuggestionV2ParentStepId(stepId);
          queue.push(conditionStepId);
        } else {
          for (const transition of step?.transitions ?? []) {
            const stepId = transition.step_id;
            if (!stepId) {
              isBranchFilled = false;
            } else {
              queue.push(stepId);
            }
          }
        }
      }

      return [isBranchFilled, Array.from(undefined_downstream_cvs_set)];
    },
  );

export const selectIsBranching = createSelector(
  [
    selectCanvasWorkflowBuilder,
    (state: RootState): RootState['actionBuilder']['actions'] =>
      state.actionBuilder.actions,
    selectChatOrderLastStepId,
  ],
  (canvasWorkflowBuilder, actions, lastStepId): boolean => {
    if (!lastStepId) {
      return false;
    }

    const lastStep = canvasWorkflowBuilder.steps[lastStepId];

    if (!lastStep) {
      return false;
    }

    if (lastStep.step_type === StepTypes.BUTTONS) {
      const stepFields = lastStep.step_fields as ButtonsStepFields;

      const outputVariableValue =
        canvasWorkflowBuilder.outputVariableValuesMap[lastStepId]?.[
          stepFields.output_variable
        ];

      // If there is no output variable value or output variable value is empty string,
      // that means that user has not selected any option:
      return outputVariableValue === undefined || outputVariableValue === '';
    }
    const actionId = getArticleSuggestionActionKeyId(
      canvasWorkflowBuilder.canvas_action_id_to_action_component,
      actions,
      lastStepId,
    );

    if (actionId) {
      // dynamic article suggestion action with no button step, this means it's not a branching action
      if (
        isDynamicArticleSuggestion(canvasWorkflowBuilder, actionId) &&
        !doesActionContainStepWithType(
          canvasWorkflowBuilder,
          actionId,
          StepTypes.BUTTONS,
        )
      ) {
        return false;
      }
      return canvasWorkflowBuilder.actionCaseMap[lastStepId] === undefined;
    }
    // The last step is a condition step, check if the user has selected a condition or not:
    else if (lastStep.step_type === StepTypes.CONDITION) {
      return canvasWorkflowBuilder.selectedConditions[lastStepId] === undefined;
    }

    return false;
  },
);

const shouldSkipInputPathValidation =
  (intentId: string, featureFlags: string[]) =>
  (state: RootState): boolean => {
    const isRelaxInputPathValidationEnabled = featureFlags.includes(
      'relaxed_goto_intent_validation_for_single_entry_point_intent',
    );
    const widgetConfig = selectWorkflowBuilderWidgetConfig(state);

    if (!isRelaxInputPathValidationEnabled) {
      return false;
    }

    if (
      widgetConfig === null ||
      widgetConfig.manually_selected_top_intents === null
    ) {
      return false;
    }

    if (widgetConfig && widgetConfig.free_form_intent_detection_enabled) {
      return false;
    }

    if (
      widgetConfig &&
      widgetConfig.manually_selected_top_intents.length !== 1
    ) {
      return false;
    }

    return intentId == widgetConfig.manually_selected_top_intents[0];
  };

// "Path" here encompasses the chatOrder and any internal steps
// inside of actions.
export const selectDoesPathHaveInputStep =
  (featureFlags: string[]) =>
  (state: RootState): boolean => {
    const intentId = selectIntentId(state);
    if (shouldSkipInputPathValidation(intentId, featureFlags)(state)) {
      return true;
    }

    const chatOrder = selectChatOrder(state);
    const canvasWorkflowBuilder = state.canvasWorkflowBuilder;

    const stepIdToActionStepsMap = flow(
      values,
      keyBy('entry_step_id'),
      mapValues((actionStepList: ActionStepList) => actionStepList.steps),
    )(canvasWorkflowBuilder.canvas_action_id_to_action_component);

    for (const stepId of chatOrder) {
      const step = canvasWorkflowBuilder.steps[stepId];

      if (!step) {
        captureMessage('Step not found in selectDoesPathHaveInputStep', {
          extra: {
            stepId,
          },
          level: 'error',
        });
        continue;
      }

      const stepType = step.step_type;
      if ([StepTypes.FORM, StepTypes.BUTTONS].includes(stepType)) {
        return true;
      } else if (stepIdToActionStepsMap[stepId]) {
        // Note: this solution opts for a "close-enough" approach.
        // It will miss the edge case wherein the action may contain
        // an input step in one path, but some other path exists through
        // the action without an input step. In this case, we rely on the
        // BE's validation to throw the error.
        for (const actionStepId of stepIdToActionStepsMap[stepId]) {
          const actionStep = canvasWorkflowBuilder.steps[actionStepId];
          if (!actionStep) {
            captureMessage(
              'Action step not found in selectDoesPathHaveInputStep',
              {
                extra: {
                  actionStepId,
                },
                level: 'error',
              },
            );
            continue;
          }

          const actionStepType = actionStep.step_type;

          if ([StepTypes.FORM, StepTypes.BUTTONS].includes(actionStepType)) {
            return true;
          }
        }
      }
    }
    return false;
  };

export const selectIntentWorkflowId = (
  state: RootState,
): CanvasWorkflowBuilderState['intent_workflow_id'] => {
  const canvasWorkflowBuilder = state.canvasWorkflowBuilder;

  return canvasWorkflowBuilder.intent_workflow_id;
};

export const selectIsBranchMetricsViewable =
  (stepId: string) =>
  (state: RootState): boolean => {
    const canvasData = state.canvasWorkflowBuilder;
    const actions = state.actionBuilder.actions;

    const actionId = getArticleSuggestionActionKeyId(
      canvasData.canvas_action_id_to_action_component,
      actions,
      stepId,
    );

    if (actionId && isDynamicArticleSuggestion(canvasData, actionId)) {
      return doesActionContainStepWithType(
        canvasData,
        actionId,
        StepTypes.BUTTONS,
      );
    }

    return true;
  };

export const selectIntentTitle = (state: RootState): string => {
  const canvasWorkflowBuilder = state.canvasWorkflowBuilder;
  return canvasWorkflowBuilder.intent?.title || '';
};

export const selectIntentId = (state: RootState): string => {
  const canvasWorkflowBuilder = state.canvasWorkflowBuilder;
  return canvasWorkflowBuilder.intent?.intent_id || '';
};

export const selectIsFallback = createSelector(
  [selectIntentId],
  intentId => intentId === CommonIntentWorkflowType.FALLBACK,
);

export const selectIntentData = createSelector(
  [selectCanvasWorkflowBuilder],
  (canvasWorkflowBuilder): Partial<Intent> | null => {
    return (canvasWorkflowBuilder && canvasWorkflowBuilder.intent) || null;
  },
);

export const selectVersion = (
  state: RootState,
): CanvasWorkflowBuilderState['version'] => {
  const canvasWorkflowBuilder = state.canvasWorkflowBuilder;

  return canvasWorkflowBuilder.version;
};

export const selectCanStepBeDeleted =
  (stepId: string) =>
  (state: RootState): boolean => {
    // if a step has 0 or 1 branch that have child steps, it can be deleted, otherwise it cannot be deleted
    return (
      state.canvasWorkflowBuilder.steps[stepId].transitions.filter(
        (transition: Transition) => Boolean(transition.step_id),
      ).length < 2
    );
  };

export const selectIsBeingEdited =
  (stepId: string) =>
  (state: RootState): boolean => {
    return state.canvasWorkflowBuilder.editing_step_id === stepId;
  };

export const selectIsEditMode = (state: RootState): boolean => {
  return state.canvasWorkflowBuilder.editing_step_id !== '';
};

export const selectEditingConditionStepId = (
  state: RootState,
): CanvasWorkflowBuilderState['editing_condition_step_id'] => {
  return state.canvasWorkflowBuilder.editing_condition_step_id;
};

export const selectIsEditingCondition = (state: RootState): boolean => {
  return selectEditingConditionStepId(state) !== '';
};

export const selectIsErrorBannerVisible = (state: RootState): boolean => {
  return state.workflowBuilder.isErrorBannerVisible;
};

export const selectIsOverlayVisible = (state: RootState): boolean => {
  return state.workflowBuilder.isOverlayVisible;
};

export const selectUndefinedContextVariablesInStep = createSelector(
  (state: RootState) =>
    state.canvasWorkflowBuilder.undefined_context_variables_in_step,
  undefinedContextVariablesInStep => undefinedContextVariablesInStep || {},
);

export const selectUndefinedContextVariables = createSelector(
  (state: RootState) =>
    state.canvasWorkflowBuilder.undefined_context_variables_in_step,
  undefinedContextVariablesInStep =>
    Object.values(undefinedContextVariablesInStep ?? {}).flat(),
);

export const selectUnsupportedSteps = (state: RootState) =>
  state.canvasWorkflowBuilder.unsupported_steps;

export const selectInvalidGoToSteps = (state: RootState) =>
  state.canvasWorkflowBuilder.invalid_go_to_steps;

export const selectCanvasIsLoadingActionCustomization = (
  state: RootState,
): CanvasWorkflowBuilderState['isLoadingCustomization'] =>
  state.canvasWorkflowBuilder.isLoadingCustomization;

export const selectCanvasActionSettings = (
  state: RootState,
): CanvasWorkflowBuilderState['actionSettings'] =>
  state.canvasWorkflowBuilder.actionSettings;

export const selectIsPreviewLoaded = (state: RootState): boolean => {
  return state.workflowBuilder.isPreviewLoaded;
};

export const selectWidgetApiKey = (state: RootState): string =>
  state.workflowBuilder.widgetApiKey;

export const selectIsModelTraining = (state: RootState): boolean =>
  state.workflowBuilder.isModelTraining;

export const selectIsConfigTranslationsLoading = (
  state: RootState,
): boolean => {
  return state.workflowBuilder.isConfigTranslationsLoading;
};

export const selectIsStepTranslationsLoading = (state: RootState): boolean => {
  return state.workflowBuilder.isStepTranslationsLoading;
};

export const selectActionSettingsConfigurationFields = (
  state: RootState,
): CanvasWorkflowBuilderState['actionSettings']['configuration_fields'] =>
  state.canvasWorkflowBuilder.actionSettings.configuration_fields;

export const selectCanvasActionIdToActionComponent = (
  state: RootState,
): CanvasWorkflowBuilderState['canvas_action_id_to_action_component'] =>
  state.canvasWorkflowBuilder.canvas_action_id_to_action_component;

export const selectIsIntentEnabledForEmail = (state: RootState): boolean => {
  const canvasWorkflowBuilder = state.canvasWorkflowBuilder;
  return !!canvasWorkflowBuilder.intent?.workflow_types_enabled?.includes(
    'email',
  );
};

export const selectIsStepOrActionALeaf =
  (stepId: string) =>
  (state: RootState): boolean => {
    const canvasWorkflowBuilder = state.canvasWorkflowBuilder;
    const canvasActions =
      canvasWorkflowBuilder.canvas_action_id_to_action_component;
    const actions = state.actionBuilder.actions;

    const [actionId] =
      Object.entries(canvasActions).find(([, value]) =>
        value.steps.includes(stepId),
      ) || [];

    const articleSuggestionActionId = getArticleSuggestionActionKeyId(
      canvasActions,
      actions,
      stepId,
    );

    if (articleSuggestionActionId) {
      const transitionBranches =
        canvasActions[articleSuggestionActionId]?.transition_branches || [];
      if (isDynamicArticleSuggestion(canvasWorkflowBuilder, actionId || '')) {
        return isDynamicArticleSuggestionTheLeaf(
          canvasWorkflowBuilder,
          transitionBranches,
        );
      } else {
        return isArticleSuggestionTheLeaf(
          canvasWorkflowBuilder,
          transitionBranches,
        );
      }
    } else if (isEntryStepOfGranularArticleSuggestionV2(stepId)) {
      const conditionStepId =
        getGranularArticleSuggestionV2ParentStepId(stepId);
      const conditionStep = canvasWorkflowBuilder.steps[conditionStepId];
      return conditionStep?.transitions.every(
        transition => !transition.step_id,
      );
    } else {
      const step = canvasWorkflowBuilder.steps[stepId];
      return step?.transitions.every(transition => !transition.step_id);
    }
  };

export const selectIsIntentEnabled = (state: RootState): boolean => {
  const canvasWorkflowBuilder = state.canvasWorkflowBuilder;
  return !!canvasWorkflowBuilder.intent?.workflow_types_enabled?.length;
};

export const selectError = (state: RootState): string =>
  state.workflowBuilder.error;

export const selectIsActionDynamicArticleSuggestion =
  (stepId?: string, actionId?: string) =>
  (state: RootState): boolean => {
    const canvasWorkflowBuilder = state.canvasWorkflowBuilder;
    const actions = state.actionBuilder.actions;

    if (!canvasWorkflowBuilder || actions.length === 0) {
      return false;
    }

    if (actionId) {
      return isDynamicArticleSuggestion(canvasWorkflowBuilder, actionId);
    }

    if (stepId) {
      const canvasActions =
        canvasWorkflowBuilder.canvas_action_id_to_action_component;
      const foundActionKey = getArticleSuggestionActionKeyId(
        canvasActions,
        actions,
        stepId,
      );

      // not a article suggestion action
      if (!foundActionKey) {
        return false;
      }

      return isDynamicArticleSuggestion(canvasWorkflowBuilder, foundActionKey);
    }

    return false;
  };

export const selectIsLastStepGoToIntentStep = createSelector(
  [selectCanvasWorkflowBuilder, selectChatOrderLastStepId],
  (canvasWorkflowBuilder, chatOrderLastStepId): boolean => {
    if (chatOrderLastStepId == null) {
      return false;
    }

    return (
      canvasWorkflowBuilder.steps[chatOrderLastStepId].step_type ===
      StepTypes.GO_TO_INTENT
    );
  },
);

export const selectIsLastRestartConversationStep = createSelector(
  [selectCanvasWorkflowBuilder, selectChatOrderLastStepId],
  (canvasWorkflowBuilder, chatOrderLastStepId): boolean => {
    if (chatOrderLastStepId == null) {
      return false;
    }

    return (
      canvasWorkflowBuilder.steps[chatOrderLastStepId].step_type ===
      StepTypes.RESTART_CONVERSATION
    );
  },
);

export const selectIsRestartConversationDisabled = createSelector(
  [
    selectCanvasWorkflowBuilder,
    selectChatOrderLastStepId,
    selectIsBranching,
    selectIsLastStepGoToIntentStep,
    selectIsLastRestartConversationStep,
    selectIsEditMode,
    selectUserCan('route_intent'),
  ],
  (
    canvasWorkflowBuilder,
    chatOrderLastStepId,
    isBranching,
    isLastStepGoToIntentStep,
    isLastRestartConversationStep,
    isEditMode,
    canUserRouteIntent,
  ): boolean => {
    return (
      isBranching ||
      isLastStepGoToIntentStep ||
      isLastRestartConversationStep ||
      getIsTerminalAction(
        chatOrderLastStepId,
        canvasWorkflowBuilder.canvas_action_id_to_action_component,
      ) ||
      isEditMode ||
      !canUserRouteIntent
    );
  },
);

export const selectIsRouteIntentDisabled = (state: RootState): boolean => {
  return selectIsRestartConversationDisabled(state);
};

export const selectRestartConversationTooltip = (state: RootState): string => {
  if (selectIsLastStepGoToIntentStep(state)) {
    return 'No additional step could be added as the workflow has routed to another intent';
  }

  if (selectIsLastRestartConversationStep(state)) {
    return 'No additional step could be added as the workflow has restarted';
  }

  if (selectIsTerminalStepSet(state)) {
    return 'Terminal step set for this workflow';
  }

  if (selectIsBranching(state)) {
    return 'Select a condition or button option to continue';
  }

  return '';
};

export const selectRouteIntentTooltip = (state: RootState): string => {
  const restartConversationTooltip = selectRestartConversationTooltip(state);

  if (restartConversationTooltip) {
    return restartConversationTooltip;
  }

  return '';
};

export const selectHandoffTooltip = (state: RootState): string => {
  if (selectIsLastStepGoToIntentStep(state)) {
    return 'No additional step could be added as the workflow has routed to another intent';
  }

  if (selectIsLastRestartConversationStep(state)) {
    return 'No additional step could be added as the workflow has restarted';
  }

  if (selectIsTerminalStepSet(state)) {
    return 'Terminal step set for this workflow';
  }

  if (selectIsBranching(state)) {
    return 'Select a condition or button option to continue';
  }

  return '';
};

export const selectEditingActionId = (state: RootState): string | undefined => {
  const idBeingEdited = selectCustomizableActionId(state);
  const actionMap =
    state.canvasWorkflowBuilder.canvas_action_id_to_action_component;

  return actionMap[idBeingEdited]?.action_id;
};

export const selectIsTerminalStepSet = createSelector(
  [selectCanvasWorkflowBuilder, selectChatOrderLastStepId],
  (canvasWorkflowBuilder, chatOrderLastStepId): boolean => {
    const actionsStepMap =
      canvasWorkflowBuilder.canvas_action_id_to_action_component ?? {};
    const actionStepMapKeys = Object.keys(actionsStepMap);
    const stepType =
      !!chatOrderLastStepId &&
      canvasWorkflowBuilder.steps[chatOrderLastStepId]?.step_type;
    const isGoToStep =
      stepType === StepTypes.GO_TO_INTENT ||
      stepType === StepTypes.RESTART_CONVERSATION;

    if (isGoToStep) {
      return true;
    }

    if (actionStepMapKeys.length) {
      return getIsTerminalAction(chatOrderLastStepId, actionsStepMap);
    }

    return false;
  },
);

export const selectIsloading = (state: RootState): boolean => {
  return state.workflowBuilder.isLoading;
};
