import './index.scss';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Overlay from '../Overlay/index';
import ReportTableHeader from './ReportTableHeader/ReportTableHeader';
import ReportTableRow from './ReportTableRow/ReportTableRow';
import { getAnswersData } from 'src/actions/data/data';
import { getAllReports } from 'src/actions/reports/reports';
import { setCurrentReport } from 'src/actions/reports/reports';
import {
  selectReport,
  selectReportsData,
} from 'src/reducers/reportsReducer/reportsReducer';
import { Report } from 'src/services/apiInterfaces';
import handleModalsVisibility from 'src/utils/handleModalsVisibility';
import selectNextReport from 'src/utils/selectNextReport';

const OpenReportModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const [reportListSelected, setReportList] = useState<string>('all_reports');

  const reportsData = useSelector(selectReportsData);

  let isComponentMounted = true;
  const selectedReport: Report | undefined = useSelector(selectReport);

  const ContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const reference: React.RefObject<HTMLDivElement> = useRef(null);

  const reportList = reportsData?.reports.map(
    (report: Report, index: number) => {
      const queryType =
        report.query.query_type === 'TICKET' ? 'Tickets' : 'Agents';
      return (
        <ReportTableRow
          cells={[report.user_id, report.description, queryType]}
          id={report.report_id as number}
          isOwner={reportsData.user_owned_report_ids.includes(
            report.report_id as number,
          )}
          key={index}
          name={report.name}
          onClick={() => setModalVisible(false)}
          report={report}
          selectNextReport={() => {
            const report = selectNextReport(reportsData, selectedReport);
            if (report) {
              dispatch(setCurrentReport(report));
              dispatch(getAnswersData());
            }
          }}
          starred={reportsData.user_bookmarked_report_ids.includes(
            report.report_id as number,
          )}
          type={report.query.visualization_type}
        />
      );
    },
  );

  const yourReportsList = reportsData?.reports
    .filter((report: Report) => {
      return reportsData.user_owned_report_ids.includes(
        report.report_id as number,
      );
    })
    .map((report: Report, index: number) => {
      const queryType =
        report.query.query_type === 'TICKET' ? 'Tickets' : 'Agents';
      return (
        <ReportTableRow
          cells={[report.user_id, report.description, queryType]}
          id={report.report_id as number}
          isOwner={reportsData.user_owned_report_ids.includes(
            report.report_id as number,
          )}
          key={index}
          name={report.name}
          onClick={() => setModalVisible(false)}
          report={report}
          selectNextReport={() => {
            if (selectNextReport(reportsData, selectedReport)) {
              dispatch(setCurrentReport(report));
              dispatch(getAnswersData());
            }
          }}
          starred={reportsData.user_bookmarked_report_ids.includes(
            report.report_id as number,
          )}
          type={report.query.visualization_type}
        />
      );
    });

  useEffect(() => {
    document.addEventListener('mousedown', e =>
      handleModalsVisibility(
        isComponentMounted,
        e,
        reference,
        ContainerRef,
        setModalVisible,
      ),
    );

    return () => {
      isComponentMounted = false;
      document.removeEventListener('mousedown', () =>
        handleModalsVisibility(isComponentMounted),
      );
    };
  }, []);
  return (
    <>
      <Overlay displayOverlay={isModalVisible} />
      <div className='dropdown view-dropdown search-view' ref={reference}>
        <button
          aria-expanded='false'
          className='select'
          data-testid='dropdown-button'
          onClick={(): void => {
            setModalVisible(!isModalVisible);
            dispatch(getAllReports());
          }}
          type='button'
        >
          <span className='selected-option'>
            <span
              className={
                isModalVisible ? 'OpenReport-icon-focused' : 'OpenReport-icon'
              }
            />
          </span>
        </button>
        {isModalVisible && (
          <div className='Modal--container' ref={ContainerRef}>
            <div className='Modal--header'>
              <p className='Modal-title'>Open Report</p>
              <div className='Modal--rightAligned'>
                <button
                  className={
                    reportListSelected === 'all_reports'
                      ? 'Modal-option-btn-selected'
                      : 'Modal-option-btn'
                  }
                  onClick={(): void => setReportList('all_reports')}
                >
                  ALL REPORTS
                </button>
                <button
                  className={
                    reportListSelected === 'your_reports'
                      ? 'Modal-option-btn-selected'
                      : 'Modal-option-btn'
                  }
                  onClick={(): void => setReportList('your_reports')}
                >
                  YOUR REPORTS
                </button>
              </div>
            </div>
            <div className='Modal-tableContainer'>
              <table>
                <tbody>
                  <tr className='Modal-tableHeader'>
                    <ReportTableHeader heading={'TYPE'} />
                    <ReportTableHeader heading={'NAME'} />
                    <ReportTableHeader heading={'CREATED BY'} />
                    <ReportTableHeader heading={'DESCRIPTION'} />
                    <ReportTableHeader heading={'BASE REPORT'} />
                    <ReportTableHeader heading={'STARRED'} />
                    <ReportTableHeader heading={'DELETE'} />
                  </tr>
                  {reportListSelected === 'all_reports' && reportList}
                  {reportListSelected === 'your_reports' && yourReportsList}
                </tbody>
              </table>
              {reportListSelected === 'your_reports' &&
                !reportsData?.user_owned_report_ids.length && (
                  <p className='Modal-table-emptyMessage'>
                    You currently have no reports owned by you.
                  </p>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OpenReportModal;
