import { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

const DEBOUNCE_TIME = 300;

export function useDocumentSearch<T>(
  request: (query: string | null, page: number) => Promise<T>,
  mergeResponse: (prevData: T, newData: T) => T,
) {
  const [data, setData] = useState<Awaited<ReturnType<typeof request>> | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const callSearchAPI = useCallback(
    async (query: string | null = null, page = 0) => {
      try {
        const res = await request(query, page);
        setData(res);
      } catch (error) {
        if (error instanceof Error) {
          setError(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [request],
  );

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(callSearchAPI, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    callSearchAPI();
  }, [callSearchAPI]);

  function refetch(query: string | null = null, page = 0) {
    setIsLoading(true);
    debouncedSearch(query?.trim(), page);
  }

  async function loadNextPage(query: string | null = null, page = 0) {
    if (isLoadingNextPage) {
      return;
    }

    setIsLoadingNextPage(true);
    try {
      const response = await request(query, page);
      // @ts-expect-error https://github.com/microsoft/TypeScript/issues/47144
      setData(prevData => {
        if (!prevData) {
          return response;
        }

        return mergeResponse(prevData, response);
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error);
      }
    } finally {
      setIsLoadingNextPage(false);
    }
  }

  return {
    data,
    error,
    isLoading,
    isLoadingNextPage,
    loadNextPage,
    refetch,
  };
}
