import './DaypickerFilter.scss';

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import DayPicker, { DateUtils } from 'react-day-picker';
import { useDispatch, useSelector } from 'react-redux';

import FiltersTooltip from '../filters-tooltip/FiltersTooltip';
import { getAnswersData } from 'src/actions/data/data';
import { setFilter } from 'src/actions/filters/filters';
import { selectSelectedFilterField } from 'src/reducers/filtersReducer/filtersReducer';
import setTooltipVisible from 'src/utils/setTooltipVisibility';

interface DaypickerFilterProps {
  handleModalVisibility: Function;
  reference: React.RefObject<HTMLDivElement>;
}

const DaypickerFilter: React.FC<
  React.PropsWithChildren<DaypickerFilterProps>
> = (props: DaypickerFilterProps) => {
  const { handleModalVisibility, reference } = props;
  const selectedFilterField = useSelector(selectSelectedFilterField);
  const dispatch = useDispatch();
  const [rangeState, setRangeState] = useState<any>({
    from: new Date(),
    to: new Date(),
  }); // TODO  @Seb this type wasn't defined and the tests are breaking
  const [isTitleOverFlowed, setIsTitleOverFlowed] = useState<boolean>(false);
  const [titleTooltipVisibility, setTitlteTooltipVisibility] =
    useState<boolean>(false);
  const titleRef: React.RefObject<HTMLSpanElement> = useRef(null);

  const handleDayClick = (day: Date) => {
    const range = DateUtils.addDayToRange(day, rangeState);
    setRangeState(range);
  };
  const applyFilterClass = classNames('DaypickerFilter-applyFilter', {
    'DaypickerFilter-EnabledApplyBreakdownButton':
      rangeState.from &&
      rangeState.to &&
      rangeState.from.getTime() !== rangeState.to.getTime(),
  });
  const modifiers = { end: rangeState.to, start: rangeState.from };
  const { from, to } = rangeState;
  return (
    <div
      className='DaypickerFilter-contanier'
      data-testid='date_modal'
      ref={reference}
    >
      <div className='DaypickerFilter-titleContainer'>
        <span className='DaypickerFilter-titleIcon DaypickerFilter-dateTypeIcon' />
        <span
          className='DaypickerFilter-title'
          onMouseEnter={() =>
            setTooltipVisible(
              true,
              setTitlteTooltipVisibility,
              setIsTitleOverFlowed,
              titleRef,
              196,
            )
          }
          onMouseLeave={() =>
            setTooltipVisible(
              false,
              setTitlteTooltipVisibility,
              setIsTitleOverFlowed,
              titleRef,
              196,
            )
          }
          ref={titleRef}
        >
          {isTitleOverFlowed && titleTooltipVisibility && (
            <FiltersTooltip
              isShort
              isTitle
              title={selectedFilterField?.field_name}
            />
          )}
          {selectedFilterField?.field_name}
        </span>
      </div>
      <div className='DaypickerFilter-content'>
        <div className='DaypickerFilter-container'>
          <DayPicker
            className='Selectable'
            modifiers={modifiers}
            numberOfMonths={1}
            onDayClick={handleDayClick}
            selectedDays={[from, { from, to }]}
            showOutsideDays
          />
        </div>
        <div className='DaypickerFilter-dateInformation'>
          <span className='DaypickerFilter-modifiers'>From </span>
          <span className='DaypickerFilter-fromToDate'>
            {from && from.toLocaleDateString()}
          </span>
          <span className='DaypickerFilter-modifiers'>To</span>
          <span className='DaypickerFilter-fromToDate'>
            {to && to.toLocaleDateString()}
          </span>
        </div>
      </div>
      <button
        className={applyFilterClass}
        disabled={
          rangeState.from?.getTime() === rangeState.to?.getTime() ||
          !rangeState.from ||
          !rangeState.to
            ? true
            : false
        }
        onClick={() => {
          dispatch(
            setFilter({
              field: {
                field_category: selectedFilterField?.field_category,
                field_id: selectedFilterField?.field_id,
                field_name: selectedFilterField?.field_name,
                field_type: selectedFilterField?.field_type,
              },
              negate: false,
              operator: 'BETWEEN',
              values: [rangeState.from, rangeState.to],
            }),
          );
          handleModalVisibility();
          dispatch(getAnswersData());
        }}
      >
        Apply Filter
      </button>
    </div>
  );
};

export default DaypickerFilter;
