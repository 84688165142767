import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface ArticleListProps {
  articles: KnowledgeGapArticleRecommendation['relevant_articles'];
  hideTooltip?: boolean;
  width?: number | string;
}

const ArticleList = ({
  articles,
  hideTooltip,
  width = '200px',
}: ArticleListProps) => {
  const { palette } = useTheme();
  if (!articles || !articles.length) {
    return (
      <Typography color={palette.colors.grey[700]} variant='font12'>
        {NA}
      </Typography>
    );
  }
  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{ '&>a': { display: 'flex', textDecoration: 'none' } }}
    >
      {articles.map(item => {
        if (!item.title || !item.link) {
          return null;
        }
        return (
          <a
            href={item.link}
            key={item.article_id}
            rel='noreferrer'
            target='_blank'
          >
            <Tooltip tooltipContent={hideTooltip ? '' : item.title}>
              <Box
                borderRadius={1}
                px={0.5}
                sx={{
                  '&:hover': {
                    bgcolor: palette.colors.slate[200],
                    cursor: 'pointer',
                  },
                  '&>span': {
                    textDecoration: 'underline',
                  },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width,
                }}
              >
                <Typography
                  color={palette.colors.blue[600]}
                  noWrap
                  variant='font12Medium'
                >
                  {item.title}
                </Typography>
              </Box>
            </Tooltip>
          </a>
        );
      })}
    </Box>
  );
};

export default ArticleList;
