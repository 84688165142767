import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTicketDetails } from 'src/actions/discover/discover';
import { selectTicketDetails } from 'src/reducers/discoverReducer/discoverReducer';

export const useGetTicketDetails = ({ ticketId }: { ticketId?: string }) => {
  const dispatch = useDispatch();
  const { data, error, loading } = useSelector(selectTicketDetails);

  useEffect(() => {
    if (loading || !ticketId || ticketId === data?.ticket_id || error) {
      return;
    }
    dispatch(
      getTicketDetails({
        ticketId,
      }),
    );
  }, [dispatch, ticketId, data, loading, error]);

  return { data, error, loading: loading };
};
