import { Dispatch } from '@reduxjs/toolkit';
import { createAction } from '@reduxjs/toolkit';

import { SolveWorkflowsActions } from './actionTypes';
import { setLoading } from 'src/actions/data/data';
import { WorkflowConfigDict } from 'src/services/apiInterfaces';
import { deleteWorkflowAPI, getWorkflowConfigAPI } from 'src/services/apiV1';
import { AppDispatch } from 'src/store/hooks';

export const getWorkflowConfigSuccess = createAction<WorkflowConfigDict>(
  SolveWorkflowsActions.GET_WORKFLOW_CONFIG_SUCCESS,
);

export const getWorkflowConfigFailure = createAction<string>(
  SolveWorkflowsActions.GET_WORKFLOW_CONFIG_FAILURE,
);

export const setSolveWorkflowsView = createAction<string>(
  SolveWorkflowsActions.SET_SOLVE_WORKFLOWS_VIEW,
);

export const setIsNewWorkflow = createAction<boolean>(
  SolveWorkflowsActions.SET_IS_NEW_WORKFLOW,
);

export const setWorkflowError = createAction<boolean>(
  SolveWorkflowsActions.SET_WORKFLOW_ERROR,
);

export const setNewWorkflowId = createAction<string>(
  SolveWorkflowsActions.SET_NEW_WORKFLOW_ID,
);

export const setIsEditPanelVisible = createAction<boolean>(
  SolveWorkflowsActions.SET_IS_EDIT_PANEL_VISIBLE,
);

export const getWorkflowConfig: Function =
  () =>
  (dispatch: Dispatch): Promise<void> => {
    dispatch(setLoading(true));
    return getWorkflowConfigAPI()
      .then((data: WorkflowConfigDict) => {
        dispatch(getWorkflowConfigSuccess(data));
      })
      .catch((err: Error) => {
        dispatch(getWorkflowConfigFailure(err.message));
      })
      .then(() => {
        dispatch(setLoading(false));
      });
  };

export const deleteWorkflow =
  (worklowId: string) =>
  (dispatch: AppDispatch): Promise<void> => {
    dispatch(setLoading(true));
    return deleteWorkflowAPI(worklowId)
      .then((data: WorkflowConfigDict) => {
        dispatch(getWorkflowConfigSuccess(data));
      })
      .catch(() => {
        dispatch(setWorkflowError(true));
      })
      .then(() => {
        dispatch(setLoading(false));
      });
  };
