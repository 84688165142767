import { formatToDateAndTime } from '@forethought-technologies/forethought-elements';
import { NA } from 'src/constants/solve';
import { Routes } from 'src/utils/enums';

export const lastSync = (syncDateTime: string | null) => {
  if (!syncDateTime) {
    return NA;
  }
  return formatToDateAndTime(syncDateTime);
};

export const getTriageModelsPageUrl = (modelName: string) => {
  return Routes.TRIAGE_MODELS.replace(':modelId', modelName);
};
