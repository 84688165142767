import { dashboardApi } from '../dashboard-api';
import {
  DiscoverAllTopicsRequest,
  DiscoverGetFilterFieldsResponse,
  DiscoverGetFiltersRequest,
  DiscoverGetFiltersResponse,
  DiscoverTopicAllTicketsRequest,
  DiscoverTopicAllTicketsResponse,
} from './types';
import { DiscoverAllTopicsResponse } from 'src/reducers/discoverReducer/types';
import { constructQueryParamWithTimeFilter } from 'src/utils/discover/helpers';

const discoverRoute = '/ssel/discover/v1';

const discoverApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getAllFilterFields: builder.query<DiscoverGetFilterFieldsResponse, void>({
      query: () => ({
        url: '/analytics/filter-fields?curate_fields=True',
      }),
    }),
    getAllFilters: builder.query<
      DiscoverGetFiltersResponse,
      DiscoverGetFiltersRequest
    >({
      query: ({ filters }) => {
        return {
          body: filters,
          method: 'POST',
          url: '/analytics/v2/filter-values',
        };
      },
    }),
    getAllTopics: builder.query<
      DiscoverAllTopicsResponse,
      DiscoverAllTopicsRequest
    >({
      query: ({ dataFilterQuery, keyword_search, timeFilter }) => {
        const params = constructQueryParamWithTimeFilter(
          timeFilter,
          keyword_search,
        );
        const url = `${discoverRoute}/topics?${params}`;
        return {
          body: dataFilterQuery.length
            ? { filters: dataFilterQuery }
            : undefined,
          method: 'POST',
          url,
        };
      },
    }),
    getAllTopicTickets: builder.query<
      DiscoverTopicAllTicketsResponse,
      DiscoverTopicAllTicketsRequest
    >({
      query: ({ dataFilterQuery, searchText, timeFilter, topicId }) => {
        const params = constructQueryParamWithTimeFilter(
          timeFilter,
          searchText,
        );
        const url = `${discoverRoute}/topics/${topicId}/tickets?${params}`;
        return {
          body: dataFilterQuery.length
            ? { filters: dataFilterQuery }
            : undefined,
          method: 'POST',
          url,
        };
      },
    }),
  }),
});

export const {
  useGetAllFilterFieldsQuery,
  useGetAllFiltersQuery,
  useGetAllTopicsQuery,
  useGetAllTopicTicketsQuery,
} = discoverApi;

export { discoverApi };
