import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getKnowledgeBaseSections } from 'src/actions/discover/discover';
import { selectKnowledgeBaseSections } from 'src/reducers/discoverReducer/discoverReducer';
import { useAppDispatch } from 'src/store/hooks';

export const useGetKnowledgeBaseSections = () => {
  const dispatch = useAppDispatch();
  const data = useSelector(selectKnowledgeBaseSections);

  useEffect(() => {
    dispatch(getKnowledgeBaseSections());
  }, [dispatch]);

  return data;
};
