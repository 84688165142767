import React from 'react';
import { Breakpoint, DialogActions, styled, Typography } from '@mui/material';

import { Button } from '@forethought-technologies/forethought-elements';
import BaseModal from 'src/components/base-modal';

interface ConfirmModalProps {
  actions: { onClick: () => void; title: string }[];
  description?: React.ReactNode;
  isOpen: boolean;
  maxWidth?: Breakpoint;
  onClose: () => void;
  title: string;
}

export default function ConfirmModal({
  actions,
  description = '',
  isOpen,
  maxWidth = 'xs',
  onClose,
  title,
}: ConfirmModalProps) {
  return (
    <BaseModal
      headerTitle={<Typography variant='font16Bold'>{title}</Typography>}
      hideCloseButton
      isOpen={isOpen}
      maxWidth={maxWidth}
      onClose={onClose}
    >
      {description && (
        <DescriptionContainer>
          <Typography variant='font14'>{description}</Typography>
        </DescriptionContainer>
      )}
      <DialogActions sx={{ p: 3 }}>
        {actions.map(({ onClick, title }, index) => {
          return (
            <Button
              key={index}
              onClick={onClick}
              variant={index < actions.length - 1 ? 'ghost' : 'secondary'}
            >
              {title}
            </Button>
          );
        })}
      </DialogActions>
    </BaseModal>
  );
}

const DescriptionContainer = styled('div')`
  margin: 0 24px;
`;
