import wordCount from 'word-count';

import { stripHTML } from 'src/hooks/useStrippedHTML';

export const replaceIdInRoute = (path: string, intentId: string) =>
  path.replace(':intentId?', intentId);

export const getWorkflowValueFromSearch = (
  search: string,
  value: 'intentId' | 'workflowId',
) => {
  const params = new URLSearchParams(search);

  return params.get(value) ?? '';
};

const MAX_AUTOFLOW_POLICY_WORD_LENGTH = 3000;
const AUTOFLOW_POLICY_VALIDATION_MESSAGE = `Autoflow policy descriptions must stay under ${MAX_AUTOFLOW_POLICY_WORD_LENGTH} words`;

export const validateAutoflowPolicy = (policy = '') => {
  const numWords = wordCount(stripHTML(policy));

  if (numWords >= MAX_AUTOFLOW_POLICY_WORD_LENGTH) {
    return AUTOFLOW_POLICY_VALIDATION_MESSAGE;
  }

  return false;
};
