import { useEffect, useState } from 'react';

import { checkHasNoteContentChanged } from './helpers';
import { AssistNote } from 'src/slices/assist-notes/types';

export const useSyncSelectedNoteWithInputs = ({
  selectedNote,
  setBody,
  setTitle,
}: {
  selectedNote: AssistNote | undefined;
  setBody: (body: string) => void;
  setTitle: (title: string) => void;
}) => {
  useEffect(() => {
    if (selectedNote) {
      setTitle(selectedNote?.title ?? '');
      setBody(selectedNote?.body ?? '');
    }
  }, [selectedNote, setTitle, setBody]);
};

export const useIsNoteDirty = (
  body: string | null,
  title: string,
  note?: AssistNote,
) => {
  const [originalEditorBody, setOriginalEditorBody] = useState('');
  useEffect(() => {
    if (originalEditorBody === '' && body && note) {
      setOriginalEditorBody(body);
    }
  }, [body, originalEditorBody, note]);

  if (!note && originalEditorBody !== '') {
    setOriginalEditorBody('');
    return false;
  }

  if (body === null) {
    return false;
  }
  const hasContentChanged = checkHasNoteContentChanged(body, title, note);
  const hasBodyChangedFormat = originalEditorBody !== body;

  return hasContentChanged || hasBodyChangedFormat;
};
