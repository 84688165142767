import { useSelector } from 'react-redux';

import {
  Toast,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  selectGlobalToastOptions,
  setGlobalToastOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

export default function GlobalToast() {
  const dispatch = useAppDispatch();
  const toastOptions = useSelector(selectGlobalToastOptions);

  if (!toastOptions) {
    return null;
  }

  const { subtitle, ...rest } = toastOptions;

  return (
    <Toast
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={() => dispatch(setGlobalToastOptions(null))}
      open={Boolean(toastOptions)}
      {...rest}
    >
      {subtitle && <Typography variant='font12'>{subtitle}</Typography>}
    </Toast>
  );
}
