import { theme } from '@forethought-technologies/forethought-elements';
import { SolveChannels } from './enums';

export const solveChartColorUtil = (
  name: string,
  channel: string,
  isSolveWorkflows: boolean,
): string => {
  if (isSolveWorkflows) {
    switch (name) {
      case 'resolutions':
        return theme.palette.colors.green[300];
      case 'handoffs':
        return theme.palette.colors.grey[500];
    }
  } else {
    switch (channel) {
      case SolveChannels.EMAIL:
        switch (name) {
          case 'reopens':
            return theme.palette.colors.blue[300];
          case 'instant_resolutions':
            return theme.palette.colors.green[300];
          case 'not_attempted':
            return theme.palette.colors.yellow[500];
        }
      case SolveChannels.API:
        switch (name) {
          case 'ticket_creations':
            return theme.palette.colors.yellow[500];
          case 'instant_resolutions':
            return theme.palette.colors.green[300];
          case 'not_attempted':
            return theme.palette.colors.blue[300];
        }
      case SolveChannels.WIDGET:
        switch (name) {
          case 'ticket_creations':
            return theme.palette.colors.yellow[500];
          case 'instant_resolutions':
            return theme.palette.colors.green[300];
          case 'not_attempted':
            return theme.palette.colors.blue[300];
        }
    }
  }
  return '';
};
