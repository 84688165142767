import { Size, Variant } from './types';
import LargeNegativeEmoji from 'src/assets/images/discover-large-negative-emoji.svg';
import LargeNeutralEmoji from 'src/assets/images/discover-large-neutral-emoji.svg';
import LargePositiveEmoji from 'src/assets/images/discover-large-positive-emoji.svg';
import MediumNegativeEmoji from 'src/assets/images/discover-medium-negative-emoji.svg';
import MediumNeutralEmoji from 'src/assets/images/discover-medium-neutral-emoji.svg';
import MediumPositiveEmoji from 'src/assets/images/discover-medium-positive-emoji.svg';
import SmallNegativeEmoji from 'src/assets/images/discover-small-negative-emoji.svg';

export const getDiscoverSentimentEmoji = (variant: Variant, size: Size) => {
  const combo = `${variant}_${size}` as const;

  switch (combo) {
    case 'negative_small':
      return SmallNegativeEmoji;
    case 'negative_medium':
      return MediumNegativeEmoji;
    case 'neutral_medium':
      return MediumNeutralEmoji;
    case 'positive_medium':
      return MediumPositiveEmoji;
    case 'negative_large':
      return LargeNegativeEmoji;
    case 'neutral_large':
      return LargeNeutralEmoji;
    case 'positive_large':
      return LargePositiveEmoji;

    default:
      throw new Error(
        `${combo} variant and size not available for Discover Sentiment Emoji`,
      );
  }
};
