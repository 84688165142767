import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import {
  Checkbox,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  CALL_TO_ACTION_LABEL_PLACEHOLDER,
  PRIVACY_POLICY_PLACEHOLDER,
  PROMPT_HEADER_PLACEHOLDER,
} from '../constants';
import { useDebouncedSubmitConfigForRTE } from '../hooks/useDebouncedSubmitConfig';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import { RTEContainer } from '../styledComponents';
import set from 'lodash/fp/set';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectWidgetConfiguration,
  setPrivacyConsentConfigurationByKey,
} from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

const PrivacyConsentTabContent = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const remirrorContextVariables = useGetRemirrorContextVariables();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const editorRef = useRef<EditorRef>(null);
  const errorMessageRef = useRef<HTMLDivElement>(null);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const isPrivacyConsentEnabled = featureFlags.includes('privacy_consent');

  const debouncedSubmitConfig = useDebouncedSubmitConfigForRTE();

  if (!isPrivacyConsentEnabled) {
    return null;
  }

  const {
    call_to_action_label: callToActionLabel,
    privacy_policy: privacyPolicy,
    prompt_header: promptHeader,
    show_when_conversation_starts: showWhenConversationStarts,
  } = widgetConfiguration.privacy_consent_config;
  const isPromptHeaderEmpty = promptHeader.trim() === '';
  const isPrivacyPolicyEmpty = privacyPolicy.trim() === '';
  const isCallToActionLabelEmpty = callToActionLabel.trim() === '';

  const handleShowWhenConversationStartsChange = (val: boolean) => {
    const updatedConfig: WidgetConfiguration = set(
      ['privacy_consent_config', 'show_when_conversation_starts'],
      val,
      widgetConfiguration,
    );
    dispatch(
      setPrivacyConsentConfigurationByKey({
        isGenericField: true,
        key: 'show_when_conversation_starts',
        value: val,
      }),
    );
    debouncedSubmitConfig(
      updatedConfig,
      'privacy consent - show_when_conversation_starts',
      val.toString(),
    );
  };

  const handlePromptHeaderChange = (val: string) => {
    if (val.length > 30) {
      return;
    }
    const updatedConfig: WidgetConfiguration = set(
      ['privacy_consent_config', 'prompt_header'],
      val,
      widgetConfiguration,
    );
    dispatch(
      setPrivacyConsentConfigurationByKey({
        key: 'prompt_header',
        value: val,
      }),
    );
    debouncedSubmitConfig(
      updatedConfig,
      'privacy consent - prompt_header',
      val,
    );
  };

  const handlePrivacyPolicyChange = (val: string) => {
    const updatedConfig: WidgetConfiguration = set(
      ['privacy_consent_config', 'privacy_policy'],
      val,
      widgetConfiguration,
    );
    dispatch(
      setPrivacyConsentConfigurationByKey({
        key: 'privacy_policy',
        value: val,
      }),
    );
    debouncedSubmitConfig(
      updatedConfig,
      'privacy consent - privacy_policy',
      val,
    );
  };

  const handleCallToActionLabelChange = (val: string) => {
    const updatedConfig: WidgetConfiguration = set(
      ['privacy_consent_config', 'call_to_action_label'],
      val,
      widgetConfiguration,
    );
    dispatch(
      setPrivacyConsentConfigurationByKey({
        key: 'call_to_action_label',
        value: val,
      }),
    );
    debouncedSubmitConfig(
      updatedConfig,
      'privacy consent - call_to_action_label',
      val,
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Section>
        <Typography variant='font16Bold'>
          Present privacy and consent
        </Typography>
        <Checkbox
          checked={showWhenConversationStarts}
          label='Before conversation starts'
          onChange={e => {
            handleShowWhenConversationStartsChange(e.target.checked);
          }}
        />
      </Section>
      {showWhenConversationStarts && (
        <>
          <Section>
            <Typography variant='font16Bold'>Prompt header</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <TextField
                aria-label='Prompt header'
                error={isPromptHeaderEmpty && 'Prompt header cannot be empty'}
                onChange={e => {
                  handlePromptHeaderChange(e.target.value);
                }}
                placeholder={PROMPT_HEADER_PLACEHOLDER}
                value={promptHeader}
              />
              <Typography
                color={theme.palette.colors.grey[400]}
                variant='font12'
              >
                Keep the header under 30 characters
              </Typography>
            </Box>
          </Section>
          <Section>
            <Typography variant='font16Bold'>Privacy policy</Typography>
            <div>
              <RTEContainer hasError={isPrivacyPolicyEmpty}>
                <RichTextEditor
                  contextVariables={remirrorContextVariables}
                  editorRef={editorRef}
                  initialContent={privacyPolicy}
                  label='Privacy policy'
                  onChange={val => {
                    handlePrivacyPolicyChange(val);
                  }}
                  placeholder={PRIVACY_POLICY_PLACEHOLDER}
                />
              </RTEContainer>
              <div ref={errorMessageRef}>
                {isPrivacyPolicyEmpty && (
                  <Typography
                    color={theme.palette.colors.red[500]}
                    variant='font14Medium'
                  >
                    Privacy policy cannot be empty.
                  </Typography>
                )}
              </div>
            </div>
          </Section>
          <Section>
            <Typography variant='font16Bold'>Call to action label</Typography>
            <TextField
              aria-label='Call to action label'
              error={
                isCallToActionLabelEmpty &&
                'Call to action label cannot be empty'
              }
              onChange={e => {
                handleCallToActionLabelChange(e.target.value);
              }}
              placeholder={CALL_TO_ACTION_LABEL_PLACEHOLDER}
              value={callToActionLabel}
            />
          </Section>
        </>
      )}
    </Box>
  );
};

export default PrivacyConsentTabContent;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
