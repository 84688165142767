import { useMemo } from 'react';

import { useSolveMetricsQueryParams } from './useSolveMetricsQueryParams';
import keyBy from 'lodash/fp/keyBy';
import { useGetWidgetBreakdownMetricsQuery } from 'src/services/workflow-builder-metrics';
import {
  BreakdownData,
  ConversationChannel,
} from 'src/types/workflowBuilderAPITypes';

export function useWidgetMetricBreakdownById(tags?: string[]) {
  const { from, to } = useSolveMetricsQueryParams();
  const params = new URLSearchParams(location.search);
  const channel = params.get('channel') as ConversationChannel;
  const { data, isFetching } = useGetWidgetBreakdownMetricsQuery({
    channel,
    from,
    tags,
    to,
  });

  return useMemo(() => {
    const breakdownById = keyBy<BreakdownData>('id')(data?.breakdown);

    return { breakdownById, isFetching };
  }, [data, isFetching]);
}
