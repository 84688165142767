import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHelpdesk } from 'src/actions/triageSettings/triageSettings';
import { selectHelpdesk } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';

export const useGetHelpdesk = () => {
  const dispatch = useDispatch();
  const helpdesk = useSelector(selectHelpdesk);
  useEffect(() => {
    dispatch(getHelpdesk());
  }, [dispatch]);

  return helpdesk;
};
