import React from 'react';
import Box from '@mui/material/Box/Box';
import { useTheme } from '@mui/material/styles';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { numbersToStringWithCommas } from '../../solve-insights/helpers';
import { getTooltipKey } from '../helpers';
import DiscoverMetricTooltip from 'src/components/discover-tooltip/DiscoverMetricTooltip';
import { useGetMetadata } from 'src/hooks/discover/useGetMetadata';
import HeaderSection from 'src/pages/intent-conversation-analytics/HeaderSection';
import {
  DiscoverTopicAggregatedMetricType,
  DiscoverTopicMetric,
} from 'src/reducers/discoverReducer/types';
import {
  getDisplayableDataByMetricType,
  getMetricLabel,
} from 'src/utils/discover/helpers';

const parseDisplayValue = (value: string, metricName?: string) => {
  if (metricName === 'Volume' && !Number.isNaN(Number(value))) {
    return numbersToStringWithCommas({ number: Number(value) });
  }
  return value;
};

const getMetricsOrdered = (metrics: (DiscoverTopicMetric | null)[]) => {
  const order: DiscoverTopicAggregatedMetricType[] = [
    'volume',
    'full_resolution_time',
    'number_of_agent_replies',
    'reply_time',
    'first_resolution_time',
    'first_contact_resolution',
    'sentiment',
  ];

  return [...metrics].sort((a, b) => {
    const indexOfNameA = order.indexOf(a?.name ?? 'volume');
    const indexOfNameB = order.indexOf(b?.name ?? 'volume');

    return indexOfNameA - indexOfNameB;
  });
};

interface AllTopicsMetricRowProps {
  dateLabel: string | null;
  isLoading: boolean;
  metrics?: (DiscoverTopicMetric | null)[];
}

const AllTopicsMetricRow = ({
  dateLabel,
  isLoading,
  metrics = [],
}: AllTopicsMetricRowProps) => {
  const { palette } = useTheme();
  const { data: metadata } = useGetMetadata();
  const { topic_metric_data_types: availableMetrics } = metadata ?? {
    topic_metric_data_types: [],
  };

  return (
    <>
      <HeaderSection dateLabel={dateLabel} overviewText='Topics overview' />
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        px={2}
        width='100%'
      >
        {isLoading
          ? Array(5)
              .fill(null)
              .map((_, index) => (
                <MetricItem key={index} label={<Skeleton width='60px' />}>
                  <Skeleton height='50px' width='120px' />
                </MetricItem>
              ))
          : getMetricsOrdered(metrics).map(metric => {
              if (!metric) {
                return null;
              }
              const { name, value } = metric;
              const dataType =
                availableMetrics.find(item => item.type === name)?.data_type ??
                'unknown';
              if (dataType === 'unknown' || name === 'sentiment') {
                return null;
              }
              const displayableValue =
                typeof value === 'number'
                  ? getDisplayableDataByMetricType({
                      dataType: dataType,
                      metric: name,
                      value: value,
                    })
                  : 'N/A';

              const metricName = getMetricLabel(name);
              return (
                <MetricItem
                  key={name}
                  label={
                    <Typography
                      color={palette.colors.grey[800]}
                      variant='font14'
                    >
                      {metricName}
                    </Typography>
                  }
                  tooltip={
                    <DiscoverMetricTooltip
                      isLight={false}
                      metricType={getTooltipKey(name, 'value')}
                    />
                  }
                >
                  <Typography color={palette.colors.black} variant='font24'>
                    {parseDisplayValue(displayableValue, metricName)}
                  </Typography>
                </MetricItem>
              );
            })}
      </Box>
    </>
  );
};

interface MetricItemProps {
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
}

const MetricItem = ({
  children,
  label,
  tooltip,
}: React.PropsWithChildren<MetricItemProps>) => {
  return (
    <Box
      alignItems='flex-start'
      display='flex'
      flexDirection='column'
      height='80px'
      justifyContent='space-between'
    >
      <Box alignItems='center' display='flex' gap='4px' justifyContent='center'>
        {label}
        {tooltip}
      </Box>
      {children}
    </Box>
  );
};

export default AllTopicsMetricRow;
