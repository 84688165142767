import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TOAST_TIMEOUT } from '../triage-config-detail-page/helpers';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { usePublishVersionMutation } from 'src/services/triage/triageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';
import { Routes } from 'src/utils/enums';

interface PublishVersionDialogProps {
  isOpen: boolean;
  model: VersionedTriageModel;
  onClose: () => void;
}

const PublishVersionDialog = ({
  isOpen,
  model,
  onClose,
}: PublishVersionDialogProps) => {
  const emitTrackingEventCallback = useSelfServeEvents({
    model,
  });
  const [mutate, { isLoading }] = usePublishVersionMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handlePublish = () => {
    mutate({
      modelId: model.model_id,
      publish: true,
      versionId: model.version_id,
    })
      .unwrap()
      .then(publishedModel => {
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: TOAST_TIMEOUT,
            title: `Version ${publishedModel.version_name} was published successfully`,
            variant: 'main',
          }),
        );
        emitTrackingEventCallback(
          TRIAGE_LLM_TRACKING_EVENTS.PUBLISH_VERSION_SUCCESS,
        );
        const newRoute = Routes.TRIAGE_MODELS.replace(
          ':modelId',
          publishedModel.model_id,
        );
        navigate(newRoute);
      })
      .catch(() => {
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: TOAST_TIMEOUT,
            title: 'Publish failed',
            variant: 'danger',
          }),
        );
      });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      footer={
        <>
          <Button disabled={isLoading} onClick={onClose} variant='ghost'>
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handlePublish}
            variant='main'
          >
            Publish
          </Button>
        </>
      }
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title='Publish version'
      variant='sm'
    >
      <Box width='400px'>
        <Typography variant='font14'>
          You are about to publish this version{' '}
          {model.version_name || 'this version'}. This will replace the current
          live version. Are you sure you want to publish it?
        </Typography>
      </Box>
    </Dialog>
  );
};

export default PublishVersionDialog;
