import { ActiveText } from './ActiveText';
import { ActiveCanvasComponentProps } from 'src/components/email-builder/types';

export default function IntentEmailTextActive({
  component,
  setActiveComponentFields,
}: ActiveCanvasComponentProps) {
  return (
    <ActiveText
      message={component.component_fields.message}
      setActiveComponentFields={setActiveComponentFields}
    />
  );
}
