import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import UndefinedContextVariablesTooltip from '../../UndefinedContextVariablesTooltip';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import TextBox from './TextBox';
import { selectEditingStepId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetContextVariablesQuery } from 'src/services/dashboard-api';
import { selectEditingStepFields } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { FormStepFields } from 'src/types/workflowBuilderAPITypes';
import { StepTypes } from 'src/utils/enums';
import {
  dynamicCvValueMessageBuilder,
  isFormStepFields,
} from 'src/utils/solve/stepUtils';

interface FormStepProps {
  fields?: FormStepFields;
  stepId?: string;
  undefinedContextVariableIds?: string[];
}

const FormStep = ({
  fields,
  stepId,
  undefinedContextVariableIds = [],
}: FormStepProps) => {
  const { palette } = useTheme();

  const editingFields = useSelector(selectEditingStepFields);

  const editingStepId = useSelector(selectEditingStepId);
  const { data } = useGetContextVariablesQuery();
  const { context_variables: contextVariables = [] } = data ?? {};
  const isBeingEdited = editingStepId === stepId || !stepId;

  const toShow = isBeingEdited ? editingFields : fields;

  if (!toShow || !isFormStepFields(toShow)) {
    return null;
  }

  const { form_fields: formFields, prompt } = toShow;

  const cvs = formFields
    .map(field => {
      const foundCv = contextVariables.find(
        cv => cv.context_variable_id === field.output_variable,
      );

      if (!foundCv) {
        return {
          label: field.placeholder,
          name: '',
        };
      }

      return {
        label:
          field.placeholder === foundCv.context_variable_name
            ? ''
            : field.placeholder,
        name: `${foundCv.context_variable_name}${
          field.dynamic_list_option_mapping
            ? `.${field.dynamic_list_option_mapping}`
            : ''
        }`,
      };
    })
    .filter(Boolean);

  return (
    <StepContainer isBeingEdited={isBeingEdited}>
      <StepHeader stepType={StepTypes.FORM}>
        <Box ml='auto' mr={2}>
          <UndefinedContextVariablesTooltip
            buildMessage={dynamicCvValueMessageBuilder}
            contextVariables={contextVariables}
            stepId={stepId ?? ''}
          />
        </Box>
      </StepHeader>
      <Box display='flex' flexDirection='column' gap={1}>
        <TextBox
          isRichText
          placeholder='Empty message'
          undefinedContextVariableIds={undefinedContextVariableIds}
          value={prompt}
          variant='dark'
        />
      </Box>
      <Typography color={palette.colors.grey[600]} variant='font11'>
        Form fields:
      </Typography>
      <Box
        fontFamily='Fira Code'
        fontSize='12px'
        fontWeight='400'
        lineHeight='18px'
      >
        <Typography variant='font12'>
          {cvs.map(cv => (
            <>
              {cv.label ? `${cv.label}: ` : ''}
              <Typography color={palette.colors.purple[500]} variant='font12'>
                {cv.name ? `$${cv.name}` : ''}
              </Typography>
              <br />
            </>
          ))}
        </Typography>
      </Box>
    </StepContainer>
  );
};

export default FormStep;
