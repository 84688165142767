import { Skeleton } from '@forethought-technologies/forethought-elements';
import InnerDataOverviewCard from './InnerDataOverviewCard';

export default function DataOverviewCardSkeleton() {
  return (
    <InnerDataOverviewCard
      bottomRow={<Skeleton width='60px' />}
      cardLabel={<Skeleton width='120px' />}
      cardValue={<Skeleton width='80px' />}
    />
  );
}
