import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { extractCvIdsFromFieldValue } from '../cleanStr';
import {
  ActionBuilderTemplate,
  hubspotContactCreationTemplateInitialValue,
  shopifyTemplateInitialValue,
  snowflakeTemplateInitialValue,
} from '../constants';
import { Routes } from '../enums';
import capitalize from 'lodash/fp/capitalize';
import flow from 'lodash/fp/flow';
import isEmpty from 'lodash/fp/isEmpty';
import mapKeys from 'lodash/fp/mapKeys';
import mapValues from 'lodash/fp/mapValues';
import pickBy from 'lodash/fp/pickBy';
import {
  setSelectedActionTemplate,
  setSelectedInitialActionName,
} from 'src/actions/action-builder-actions/actionBuilderActions';
import {
  excludeEmbedScriptParamNames,
  includeEmbedScriptParamNames,
} from 'src/reducers/actionBuilderReducer/constants';
import { AppDispatch } from 'src/store/hooks';
import {
  Action,
  AddOrUpdateActionInterface,
  areActionBuilderFields,
  areShopifyFields,
  areSnowflakeFields,
  AuthorizationConfig,
  AuthorizationField,
  ContentType,
  ContextVariable,
  DynamicListKVPairs,
  DynamicListOption,
  KeyValuePairsDict,
  KVPairs,
  Method,
  OutputValues,
  ProxyTestRequest,
  SelectedActionDetail,
  SelectedActionTemplate,
} from 'src/types/actionBuilderApiTypes';

export const CLOUD_FLARE_PROXY_URL = `https://cors-proxy-v2.forethought-ai.workers.dev/proxy?auth_token=${CORS_PROXY_AUTH_TOKEN}`;

export const EMPTY_KEY_VALUE = [{ key: '', value: undefined }];

export const generateNewActionString = () => `create?newActionId=${uuidv4()}`;

export const replaceActionIdInRoute = (newActionId: string) =>
  Routes.ACTION_BUILDER_EDIT_V2.replace(':actionId?', newActionId);

const emptySelectedActionDetail: SelectedActionDetail = {
  description: '',
  fields: {
    authorization: null,
    body: null,
    contentType: 'none',
    headers: EMPTY_KEY_VALUE,
    jmespathQuery: '',
    method: 'GET',
    params: EMPTY_KEY_VALUE,
    url: '',
  },
  name: '',
  outputValues: [],
  tabId: '',
};

export const actionToSelectedActionDetail = ({
  action,
  contextVariables,
  tabId = '',
}: {
  action?: Action;
  contextVariables?: ContextVariable[];
  tabId?: string;
} = {}): SelectedActionDetail => {
  if (action && areActionBuilderFields(action.action_fields)) {
    return {
      actionId: action.action_id,
      description: action.action_description,
      fields: {
        authorization: getAuthTypeFromFields(action),
        body: action.action_fields.body_parameters ?? null,
        contentType: determineContentType(action.action_fields.headers ?? {}),
        headers: isEmpty(action.action_fields.headers)
          ? EMPTY_KEY_VALUE
          : Object.entries(action.action_fields.headers)
              .map(([key, value]) => ({ key, value }))
              // remove content type from headers form. Its selected by radio buttons
              .filter(header => header?.key.toLowerCase() !== 'content-type'),
        jmespathQuery: action.action_fields.jmespath_query || '',
        method: action.action_fields.method.toUpperCase() as Method,
        params: isEmpty(action.action_fields.query_parameters)
          ? EMPTY_KEY_VALUE
          : Object.entries(action.action_fields.query_parameters ?? {}).map(
              ([key, value]) => ({ key, value }),
            ),
        url: action.action_fields.url,
      },
      name: action.action_name,
      outputValues: Object.entries(
        action.action_fields.output_context_variables ?? {},
      ).map(([key, value]) => {
        const relevantCV = contextVariables?.find(
          cv => cv.context_variable_id === value,
        );

        if (relevantCV?.context_variable_type === 'DYNAMIC_LIST') {
          return {
            jsonSchema:
              relevantCV.configuration_fields?.dynamic_list_config?.json_schema,
            key,
            newContextVariableName: relevantCV.context_variable_name,
            value,
          };
        }

        return { key, value };
      }),
      tabId,
    };
  }

  return { ...emptySelectedActionDetail, tabId };
};

const getAuthTypeFromFields = ({
  action_fields: actionFields,
}: Action): AuthorizationField => {
  if (!areActionBuilderFields(actionFields)) {
    return null;
  }
  if (actionFields.authorization_config_id) {
    return { id: actionFields.authorization_config_id, type: 'bearer' };
  }
  if (actionFields.authorization_integration) {
    return { id: actionFields.authorization_integration, type: 'integration' };
  }

  return null;
};

export const nameKVDictValues = (pairs: KVPairs[]) =>
  pairs
    .filter(pair => pair.key && pair.value)
    .map(pair => ({ name: pair.key, value: pair.value ?? '' }));

export const selectedActionDetailToAction = (
  selectedAction: SelectedActionDetail,
): AddOrUpdateActionInterface => {
  const authorizationConfigId =
    selectedAction.fields.authorization?.type === 'bearer'
      ? selectedAction.fields.authorization?.id
      : null;

  const authorizationIntegration =
    selectedAction.fields.authorization?.type === 'integration'
      ? selectedAction.fields.authorization?.id
      : null;

  const headers = Object.fromEntries(
    [
      ...(selectedAction.fields.headers ?? []),
      ...[contentTypeToHeader(selectedAction.fields.contentType)],
    ]
      .filter(item => item.key && item.value)
      .map(({ key, value }) => [key, value ?? '']),
  );

  const params = Object.fromEntries(
    (selectedAction.fields.params ?? [])
      .filter(item => item.key && item.value)
      .map(({ key, value }) => [key, value ?? '']),
  );

  return {
    action_description: selectedAction.description,
    action_fields: {
      authorization_config_id: authorizationConfigId,
      authorization_integration: authorizationIntegration,
      body_parameters: selectedAction.fields.body,
      headers: isEmpty(headers) ? null : headers,
      jmespath_query: selectedAction.fields.jmespathQuery,
      method: selectedAction.fields.method.toLowerCase(),
      natural_language_query: selectedAction.fields.jmespathQuery,
      output_context_variables: selectedAction.outputValues?.length
        ? Object.fromEntries(
            selectedAction.outputValues.map(({ key, value }) => [
              key,
              value ?? '',
            ]),
          )
        : undefined,
      query_parameters: isEmpty(params) ? null : params,
      url: selectedAction.fields.url,
    },
    action_name: selectedAction.name,
    action_type: 'api',
  };
};

const validateKV = Yup.array().test(arr => {
  if (!arr) {
    return true;
  }

  if (arr.length === 1 && !arr[0].key && !arr[0].value) {
    return true;
  }

  return arr.every(({ key, value }) => key?.trim() && value?.trim());
});

const validateUniqueKey = (arr: Partial<KVPairs>[] | undefined) => {
  return new Set(arr?.map(item => item?.key)).size === arr?.length;
};

const validateUniqueActionName = (actions: Action[], actionName?: string) => {
  return !new Set(actions.map(item => item.action_name)).has(actionName ?? '');
};

export const buildActionValidationSchema = (
  responseData: unknown,
  actions: Action[] = [],
  contextVariables: Readonly<ContextVariable[]> = [],
  jmespathQueryTestCvs: Partial<Record<string, string>>,
  currentSelectedAction?: Action,
) => {
  const cvNameSet = new Set(
    contextVariables.map(cv => cv.context_variable_name),
  );

  return Yup.object().shape({
    description: Yup.string()
      .trim()
      .min(1)
      .required('Action description is required'),
    fields: Yup.object().shape({
      authorization: Yup.object()
        .nullable()
        .shape({
          id: Yup.string().when('type', value => {
            if (value) {
              return Yup.string().required();
            }
            return Yup.string().nullable();
          }),
          type: Yup.string().nullable(),
        }),
      body: Yup.string()
        .when('method', {
          is: 'GET',
          otherwise: Yup.string()
            .nullable()
            .when('contentType', {
              is: 'json',
              then: Yup.string()
                .nullable()
                .required('Invalid JSON')
                .test('json', 'Invalid JSON', value => {
                  if (!value) {
                    return false;
                  }

                  type ExampleValues = true | 1;

                  const replacements: {
                    [key in ContextVariable['context_variable_type']]?: ExampleValues;
                  } = {
                    CHECKBOX: true,
                    NUMBER: 1,
                  } as const;

                  /*
                   * Replaces number and boolean CVs with example values.
                   * { "foo": {{cv_id_0}}, "bar": {{cv_id_1}} } -> { "foo": 1, "bar": true }
                   */
                  const jsonWithExampleValues = contextVariables.reduce(
                    (accumulator, cv) =>
                      Object.keys(replacements).includes(
                        cv.context_variable_type,
                      )
                        ? accumulator.replaceAll(
                            `{{${cv.context_variable_id}}}`,
                            String(replacements[cv.context_variable_type]),
                          )
                        : accumulator,
                    value,
                  );

                  try {
                    JSON.parse(jsonWithExampleValues);
                  } catch {
                    return false;
                  }
                  return true;
                }),
            })
            .when('contentType', {
              is: 'form-url',
              then: Yup.string()
                .nullable()
                .required('Invalid format')
                .test('formUrlSpaces', 'Invalid format: no spaces', value => {
                  return !value?.includes(' ');
                })
                .test(
                  'formUrlEqualsSigns',
                  'Invalid format: missing equal sign(s)',
                  value => {
                    const keyValueArr = value?.split('&') ?? [];
                    for (const kv of keyValueArr) {
                      if (!kv.includes('=')) {
                        return false;
                      }
                    }
                    return true;
                  },
                ),
            }),
          then: Yup.string().nullable().notRequired(),
        })
        .nullable(),
      headers: validateKV.test(validateUniqueKey),
      jmespathQuery: Yup.string().trim().min(1),
      params: validateKV.test(validateUniqueKey),
      url: Yup.string()
        .trim()
        .min(1)
        .required('Url is invalid')
        .test('url', 'Url is invalid', url => {
          try {
            new URL(url ?? '');
          } catch {
            return false;
          }
          return true;
        }),
    }),
    name: Yup.string()
      .trim()
      .min(1)
      .required('Action name is required')
      .matches(
        /^[a-zA-Z0-9_-\s]{1,64}$/,
        'Action name cannot contain special characters',
      )
      .test('uniqueActionName', 'Action names must be unique', name =>
        name === currentSelectedAction?.action_name
          ? true
          : validateUniqueActionName(actions, name),
      ),
    outputValues: Yup.array()
      .of(
        Yup.object().shape({
          jsonSchema: Yup.object(),
          key: Yup.string().when('jsonSchema', jsonSchema => {
            if (!jsonSchema) {
              return Yup.string().trim().min(1).required();
            }
            return Yup.string().trim().min(0).notRequired();
          }),
          newContextVariableName: Yup.string().when(
            'jsonSchema',
            jsonSchema => {
              if (!jsonSchema) {
                return Yup.string().nullable().notRequired();
              }
              return Yup.string()
                .trim()
                .min(1)
                .required('Give a name for Dynamic CV list')
                .test(
                  'uniqueDynamicCVName',
                  'Context variable names must be unique',
                  function (name) {
                    const outputValues:
                      | { newContextVariableName: string }[]
                      | undefined = this.options.context?.outputValues;

                    const hasDuplicateInCurrentList =
                      outputValues &&
                      outputValues.filter(
                        outputValue =>
                          outputValue.newContextVariableName === name,
                      ).length > 1;

                    return (
                      !hasDuplicateInCurrentList && !cvNameSet.has(name ?? '')
                    );
                  },
                );
            },
          ),
          value: Yup.string().when('jsonSchema', jsonSchema => {
            if (!jsonSchema) {
              return Yup.string().trim().min(1).required();
            }
            return Yup.string().trim().min(0).notRequired();
          }),
        }),
      )
      .test(validateUniqueKey),
  });
};

const determineContentType = (headers: Record<string, string>): ContentType => {
  for (const [key, value] of Object.entries(headers)) {
    const isContentTypeHeader = key.toLowerCase() === 'content-type';

    if (!isContentTypeHeader) {
      continue;
    }

    if (value.toLowerCase().includes('application/json')) {
      return 'json';
    }

    if (value.toLowerCase().includes('application/x-www-form-urlencoded')) {
      return 'form-url';
    }
  }

  return 'none';
};

export const contentTypeToHeader = (contentType: ContentType): KVPairs => {
  if (contentType === 'json') {
    return { key: 'Content-Type', value: 'application/json' };
  }
  if (contentType === 'form-url') {
    return {
      key: 'Content-Type',
      value: 'application/x-www-form-urlencoded',
    };
  }

  return { key: '', value: '' };
};

export const getContentTypePlaceholder = (contentType: ContentType) => {
  if (contentType === 'json') {
    return 'e.g. { "key": "value" }';
  }

  if (contentType === 'form-url') {
    return 'e.g. key1=val1&key2=val2';
  }

  return '';
};

export const onClickTemplateHandlers: Record<
  ActionBuilderTemplate['type'],
  (action?: Action) => (dispatch: AppDispatch) => void
> = {
  hubspot_create_contact_template: () => dispatch => {
    dispatch(
      setSelectedActionTemplate(hubspotContactCreationTemplateInitialValue),
    );
    dispatch(setSelectedInitialActionName(''));
  },
  shopify_order_status_template: action => dispatch => {
    if (action) {
      dispatch(setSelectedActionTemplate(action as SelectedActionTemplate));
      dispatch(setSelectedInitialActionName(action.action_name));
      return;
    }
    dispatch(setSelectedActionTemplate({ ...shopifyTemplateInitialValue }));
    dispatch(setSelectedInitialActionName(''));
  },
  snowflake_data_query_template: action => dispatch => {
    if (action) {
      dispatch(setSelectedActionTemplate(action as SelectedActionTemplate));
      dispatch(setSelectedInitialActionName(action.action_name));
      return;
    }
    dispatch(setSelectedActionTemplate({ ...snowflakeTemplateInitialValue }));
  },
};

export const urlToParamsKVPairs = (value: string) => {
  const values = value.split('&');
  const kvPairs: KVPairs[] = [];

  values.forEach(value => {
    const [key, ...v] = value.split('=');

    kvPairs.push({ key, value: v?.join('=') });
  });

  return kvPairs;
};

export const settingsSchema = Yup.object().shape({
  authorizationConfigs: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().trim().min(1).required(),
        value: Yup.string().trim().min(1).required(),
      }),
    )
    .test(validateUniqueKey),
});

export type FlatCv = ContextVariable | DynamicListOption;

const flattenCvsWithFormattedName = (
  cvs: FlatCv[],
  option: FlatCv,
): FlatCv[] => {
  return flattenCvs(cvs).map(cv => ({
    ...cv,
    context_variable_name: `${option.context_variable_name}.${cv.context_variable_name}`,
  }));
};

export const flattenCvs = (cvs: FlatCv[]): FlatCv[] => {
  return cvs.flatMap(option => {
    if (
      'configuration_fields' in option &&
      option.configuration_fields?.dynamic_list_config?.context_variables
    ) {
      return flattenCvsWithFormattedName(
        option.configuration_fields?.dynamic_list_config?.context_variables,
        option,
      );
    }

    if ('options' in option && option.options?.length) {
      return flattenCvsWithFormattedName(option.options, option);
    }

    return option;
  });
};

export const getVariablesFromAction = (
  action: Action,
  contextVariables: ContextVariable[],
): {
  dynamicListOutputVariables?: ContextVariable[];
  inputVariables: ContextVariable[];
  outputVariables: ContextVariable[];
} => {
  const flatCvs = flattenCvs(contextVariables);

  if (areActionBuilderFields(action.action_fields)) {
    const selectedAction = actionToSelectedActionDetail({
      action,
      contextVariables,
    });

    const { body, headers, params, url } = selectedAction.fields;
    const nonUnique = [
      ...params.flatMap(({ value }) => {
        const variableIDs = extractCvIdsFromFieldValue(value);
        return flatCvs.filter(({ context_variable_id: id }) =>
          variableIDs?.includes(id ?? ''),
        );
      }),
      ...headers.flatMap(({ value }) => {
        const variableIDs = extractCvIdsFromFieldValue(value);
        return flatCvs.filter(({ context_variable_id: id }) =>
          variableIDs?.includes(id ?? ''),
        );
      }),
      ...(extractCvIdsFromFieldValue(JSON.stringify(body))?.map(variableId =>
        flatCvs.find(({ context_variable_id: id }) => id === variableId),
      ) ?? []),
      ...(extractCvIdsFromFieldValue(url)?.map(variableId =>
        flatCvs.find(({ context_variable_id: id }) => id === variableId),
      ) ?? []),
    ].filter((param): param is ContextVariable => param !== undefined);
    const inputVariables = Array.from(new Set(nonUnique));
    const outputVariables = selectedAction.outputValues
      .map(outputValue =>
        contextVariables.find(
          ({ context_variable_id: id }) => id === outputValue.value,
        ),
      )
      .filter((param): param is ContextVariable => param !== undefined);

    return {
      dynamicListOutputVariables: outputVariables.filter(
        cv => cv.context_variable_type === 'DYNAMIC_LIST',
      ),
      inputVariables,
      outputVariables: outputVariables.filter(
        cv => cv.context_variable_type !== 'DYNAMIC_LIST',
      ),
    };
  }

  if (areShopifyFields(action.action_fields)) {
    const { customer_email, customer_id, output_variable } =
      action.action_fields;
    const cvFromEmail = extractCvIdsFromFieldValue(customer_email);
    const cvFromId = extractCvIdsFromFieldValue(customer_id);

    const nonUnique = [...cvFromEmail, ...cvFromId]
      .map(cvId => flatCvs.find(({ context_variable_id: id }) => cvId === id))
      .filter((param): param is ContextVariable => param !== undefined);

    const outputVariables = [output_variable]
      .map(outputValue =>
        contextVariables.find(
          ({ context_variable_id: id }) => id === outputValue,
        ),
      )
      .filter((param): param is ContextVariable => param !== undefined);

    const inputVariables = Array.from(new Set(nonUnique));

    return { inputVariables, outputVariables };
  }

  if (areSnowflakeFields(action.action_fields)) {
    const { output_fields, query_condition } = action.action_fields;
    const cvFromQueryCondition = extractCvIdsFromFieldValue(query_condition);

    const nonUnique = cvFromQueryCondition
      .map(cvId => flatCvs.find(({ context_variable_id: id }) => cvId === id))
      .filter((param): param is ContextVariable => param !== undefined);

    const outputVariables =
      output_fields?.kv_list
        .map(outputValue =>
          contextVariables.find(
            ({ context_variable_id: id }) => id === outputValue.value,
          ),
        )
        .filter((param): param is ContextVariable => param !== undefined) ?? [];

    const inputVariables = Array.from(new Set(nonUnique));

    return { inputVariables, outputVariables };
  }

  return { inputVariables: [], outputVariables: [] };
};

const transformIteratee = (
  acc: Record<string, string>,
  nameValObj: KeyValuePairsDict,
) => {
  const { name, value } = nameValObj;
  if (name && value) {
    acc[name] = value;
  }
  return acc;
};

const buildBaseHeaders = ({
  headers = [],
  idTestValueDict,
}: {
  headers?: KeyValuePairsDict[] | null;
  idTestValueDict?: Record<string, string>;
}) => {
  return flow(
    mapValues((v: string) => replaceCVWithTestValues(v, idTestValueDict)),
    // Don't want any stupid Content-Type vs content-type problems.
    mapKeys((k: string) => k.toLocaleLowerCase()),
  )(headers?.reduce(transformIteratee, {}));
};

const replaceCVWithTestValues = (
  value: string,
  idTestValueDict: Record<string, string> = {},
) => {
  if (isEmpty(idTestValueDict)) {
    return value;
  }
  let retVal = value;
  extractCvIdsFromFieldValue(value)?.forEach(id => {
    const testValue = idTestValueDict[id];
    retVal = retVal.replace(`{{${id}}}`, testValue);
  });

  return retVal;
};

export const replaceCVWithTestValuesForJmespathQuery = (
  value: string,
  idTestValueDict: Partial<Record<string, string>>,
) => {
  let retVal = value;
  extractCvIdsFromFieldValue(value)?.forEach(id => {
    const testValue = idTestValueDict[id];
    retVal = retVal.replace(`{{${id}}}`, testValue ?? '');
  });

  return retVal;
};

// NO encodeUriComponent on purpose! We're going to combine this into the request body
// and have Cloudflare finagle it as-is.
const buildQueryParameters = ({
  idTestValueDict,
  queryParameterArr = [],
}: {
  idTestValueDict?: Record<string, string>;
  queryParameterArr?: KeyValuePairsDict[] | null;
}): Record<string, string> => {
  const queryParameters: Record<string, string> = mapValues(
    (v: string) => replaceCVWithTestValues(v, idTestValueDict),
    queryParameterArr?.reduce(transformIteratee, {}),
  );

  return queryParameters;
};

const buildBaseBodyParameters = ({
  bodyParameters,
  idTestValueDict,
}: {
  bodyParameters?: string | null;
  idTestValueDict?: Record<string, string>;
}): string => {
  if (!bodyParameters) {
    return '';
  }

  let _bodyParameters = bodyParameters;
  if (idTestValueDict) {
    extractCvIdsFromFieldValue(_bodyParameters)?.forEach(id => {
      const testValue = idTestValueDict[id];
      _bodyParameters = _bodyParameters.replace(`{{${id}}}`, testValue);
    });
  }

  return _bodyParameters;
};

const buildAuthorizationHeader = ({
  authorizationConfigs,
  authorizationId,
}: {
  authorizationConfigs: Array<AuthorizationConfig>;
  authorizationId?: string | null;
}): Record<string, string> => {
  if (authorizationId) {
    const authorizationConfigObject = authorizationConfigs.find(
      ({ authorization_id }) => authorization_id === authorizationId,
    );

    if (authorizationConfigObject) {
      return {
        authorization: `${capitalize(
          authorizationConfigObject.authorization_type,
        )} ${authorizationConfigObject.authorization_fields.api_token}`,
      };
    }
  }
  return {};
};

export const buildRequestBodyForIntegrationTestCall = ({
  authorizationConfigs,
  authorizationId,
  authorizationIntegration,
  bodyParameters,
  headers,
  idTestValueDict,
  method,
  queryParameters,
  url,
}: {
  authorizationConfigs: Array<AuthorizationConfig>;
  authorizationId?: string | null;
  authorizationIntegration?: string | null;
  bodyParameters?: string | null;
  headers?: KeyValuePairsDict[] | null;
  idTestValueDict?: Record<string, string>;
  method: string;
  queryParameters: KeyValuePairsDict[] | null;
  url: string;
}): ProxyTestRequest => {
  const body = buildBaseBodyParameters({
    bodyParameters,
    idTestValueDict,
  });
  const bodyJson = body ? JSON.parse(body) : null;
  const header = {
    ...buildBaseHeaders({ headers, idTestValueDict }),
    ...buildAuthorizationHeader({
      authorizationConfigs,
      authorizationId,
    }),
  };
  return {
    authorization_integration: authorizationIntegration,
    body: bodyJson,
    headers: header,
    http_method: method.toLocaleUpperCase(),
    query_parameters: buildQueryParameters({
      idTestValueDict,
      queryParameterArr: queryParameters,
    }),
    url: replaceCVWithTestValues(url, idTestValueDict),
  };
};

export const buildUrlOptions = ({
  authorizationConfigs,
  authorizationId,
  bodyParameters,
  headers,
  idTestValueDict,
  method,
  queryParameters,
  url,
}: {
  authorizationConfigs: Array<AuthorizationConfig>;
  authorizationId?: string | null;
  bodyParameters?: string | null;
  headers?: KeyValuePairsDict[] | null;
  idTestValueDict?: Record<string, string>;
  method: string;
  queryParameters: KeyValuePairsDict[] | null;
  url: string;
}): Record<string, unknown> => {
  const body = pickBy((value: unknown) => !isEmpty(value), {
    _apiUrl: replaceCVWithTestValues(url, idTestValueDict),

    _body: buildBaseBodyParameters({
      bodyParameters,
      idTestValueDict,
    }),

    _headers: {
      ...buildBaseHeaders({ headers, idTestValueDict }),
      ...buildAuthorizationHeader({
        authorizationConfigs,
        authorizationId,
      }),
    },

    _method: method.toLocaleUpperCase(),

    // Ugly, but grouping query params into body to bypass Cloudflare decoding bug with query params.
    _queryParameters: buildQueryParameters({
      idTestValueDict,
      queryParameterArr: queryParameters,
    }),
  });

  const options: Record<string, unknown> = {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  };

  return options;
};

const isEmbedScriptParam = (cv: ContextVariable): boolean => {
  if (
    cv.is_universal_context_variable &&
    excludeEmbedScriptParamNames.includes(cv.context_variable_name)
  ) {
    return false;
  }
  if (
    cv.is_universal_context_variable &&
    includeEmbedScriptParamNames.includes(cv.context_variable_name)
  ) {
    return true;
  }
  return cv.is_workflow_context || false;
};

export const getWorkflowContextParams = (
  contextVariables: ContextVariable[],
) => {
  return (
    contextVariables
      .filter(cv => cv.is_workflow_context)
      .map(cv => cv.context_variable_name) || []
  );
};

export const getContextEmbedScriptParams = (
  contextVariables: ContextVariable[],
) => {
  return (
    contextVariables
      .filter(cv => isEmbedScriptParam(cv))
      .map(cv => cv.context_variable_name) || []
  );
};

export const getContextVariableNumbers = (
  contextVariables: ContextVariable[],
) => {
  return (
    contextVariables
      .filter(cv => cv.context_variable_type === 'NUMBER')
      .map(cv => cv.context_variable_name) || []
  );
};

export const getWorkflowContextVariables = (
  contextVariables: ContextVariable[],
) => contextVariables.filter(cv => cv.is_workflow_context);

export const isDynamicListKvPairs = (
  data: OutputValues,
): data is DynamicListKVPairs => 'jsonSchema' in data;
