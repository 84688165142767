import React, { useContext } from 'react';
import { EdgeLabelRenderer, EdgeProps, getSimpleBezierPath } from 'reactflow';
import { alpha, Box, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { IconPlus } from '@tabler/icons-react';

import {
  elevations,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import useEdgeClick from '../../../hooks/useEdgeClick';
import { DndContext } from 'src/components/forethought-flow/DndContext';

const BUTTON_WIDTH = 24;
const FOREIGN_OBJECT_SIZE = BUTTON_WIDTH * 3;
const DROPZONE_TOOLTIP =
  "Add components to your journey map by dragging them from the sidebar and dropping them onto the '+' sign";

export interface WorkflowEdgeData {
  endLabel?: string;
  hideAddButton?: boolean;
  infoLabel?: {
    iconSrc: string;
    text: string;
    tooltipContent?: string;
  };
  /**
   * For edges of rule nodes
   * This is used when creating a new node from the edge
   */
  transitionId?: string | null;
}

export default function WorkflowEdge({
  data,
  id,
  markerEnd,
  sourcePosition,
  sourceX,
  sourceY,
  style,
  targetPosition,
  targetX,
  targetY,
}: EdgeProps<WorkflowEdgeData>) {
  const onClick = useEdgeClick(id);
  const { palette } = useTheme();

  const [edgePath, edgeCenterX, edgeCenterY] = getSimpleBezierPath({
    sourcePosition,
    sourceX,
    sourceY,
    targetPosition,
    targetX,
    targetY,
  });

  const { nearestEdge } = useContext(DndContext);
  const isActive = nearestEdge?.id === id;

  return (
    <>
      <EdgePath d={edgePath} id={id} markerEnd={markerEnd} style={style} />

      {!data?.hideAddButton && (
        <foreignObject
          height={FOREIGN_OBJECT_SIZE}
          requiredExtensions='http://www.w3.org/1999/xhtml'
          width={FOREIGN_OBJECT_SIZE}
          x={edgeCenterX - FOREIGN_OBJECT_SIZE / 2}
          y={edgeCenterY - FOREIGN_OBJECT_SIZE / 2}
        >
          <EdgeButtonContainer>
            <DropzoneIndicator isActive={isActive} />
            <Tooltip placement='right' tooltipContent={DROPZONE_TOOLTIP}>
              <EdgeButton
                aria-label='add step'
                isActive={isActive}
                onClick={onClick}
              >
                <IconPlus
                  color={
                    isActive ? palette.colors.white : palette.colors.grey[700]
                  }
                />
              </EdgeButton>
            </Tooltip>
          </EdgeButtonContainer>
        </foreignObject>
      )}

      <EdgeLabelRenderer>
        {/* shown at start of edge below source node */}
        {data?.infoLabel && (
          <EdgeLabel
            transform={`translate(-50%, 12px) translate(${sourceX}px,${sourceY}px)`}
          >
            <Tooltip tooltipContent={data.infoLabel.tooltipContent ?? ''}>
              <Box
                sx={theme => ({
                  alignItems: 'center',
                  backgroundColor: theme.palette.colors.grey[100],
                  borderColor: theme.palette.colors.grey[300],
                  borderRadius: '6px',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  display: 'flex',
                  fontSize: 11,
                  fontWeight: 600,
                  gap: '6px',
                  padding: '8px 12px',
                })}
              >
                <Logo alt='' src={data.infoLabel.iconSrc} />
                <Typography color={palette.colors.grey[700]} variant='font11'>
                  {data.infoLabel.text}
                </Typography>
              </Box>
            </Tooltip>
          </EdgeLabel>
        )}

        {/* shown at end of edge above target node */}
        {data?.endLabel && (
          <EdgeLabel
            transform={`translate(-50%, -100%) translate(${targetX}px,${targetY}px)`}
          >
            <Box
              sx={theme => ({
                background: theme.palette.colors.blue[200],
                borderRadius: '4px',
                color: theme.palette.colors.blue[600],
                fontSize: 12,
                fontWeight: 700,
                padding: '4px',
              })}
            >
              {data.endLabel}
            </Box>
          </EdgeLabel>
        )}
      </EdgeLabelRenderer>
    </>
  );
}

function EdgeLabel({
  children,
  transform,
}: {
  children: React.ReactNode;
  transform: string;
}) {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        pointerEvents: 'all',
        position: 'absolute',
        transform,
      }}
    >
      {children}
    </Box>
  );
}

const EdgePath = styled('path')`
  fill: none;
  stroke: ${props => props.theme.palette.colors.grey[300]};
  stroke-width: 1;
`;

const EdgeButtonContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const DropzoneIndicator = styled('div')<{ isActive: boolean }>`
  position: absolute;
  background: ${props => alpha(props.theme.palette.colors.purple[500], 0.1)};
  height: 100%;
  width: 100%;
  border-radius: 50%;
  pointer-events: none;

  // isActive transitions
  opacity: ${props => (props.isActive ? 1 : 0)};
  transform: ${props => (!props.isActive ? 'scale(0.3)' : 'scale(1)')};
  transition: ${props =>
    // @ts-expect-error -- TODO: fix Theme type
    props.theme.transitions.create(['opacity', 'transform'], {
      duration: 200,
    })};
`;

const EdgeButton = styled('button')<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${BUTTON_WIDTH}px;
  height: ${BUTTON_WIDTH}px;
  background: ${props =>
    props.isActive
      ? props.theme.palette.colors.purple[500]
      : props.theme.palette.colors.white};
  box-shadow: ${elevations.z1};
  cursor: pointer;
  border-radius: 50%;
  line-height: 1;
  padding: 4px;
  transition: ${props =>
    // @ts-expect-error -- see above
    props.theme.transitions.create('background', { duration: 200 })};
`;

const Logo = styled('img')`
  width: 15px;
`;
