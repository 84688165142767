import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { filterAndSortContextVariables, getAllContextVariables } from './utils';
import { useGetSettingsQuery } from 'src/services/action-builder/actionBuilderApi';
import { selectTicketFieldMappingsState } from 'src/slices/ticket-field-mappings/ticketFieldMappingsSlice';

export const useGetActionBuilderData = ({
  shouldIncludeSystemContextVariables = false,
  shouldIncludeTemplateContextVariables = false,
  shouldIncludeTriageContextVariables = false,
  shouldIncludeWorkflowTagContextVariable = true,
}: {
  shouldIncludeSystemContextVariables?: boolean;
  shouldIncludeTemplateContextVariables?: boolean;
  shouldIncludeTriageContextVariables?: boolean;
  shouldIncludeWorkflowTagContextVariable?: boolean;
} = {}) => {
  const ticketFieldMappingsState = useSelector(selectTicketFieldMappingsState);
  const { data, isFetching, isLoading } = useGetSettingsQuery();
  const {
    authorization_configs: authorizationConfigs = [],
    context_variables: contextVariablesData = [],
    template_context_variables: templateContextVariables = [],
    usable_system_context_variables: usableSystemContextVariables = [],
  } = data ?? {};
  const { ticketFieldMappings = [] } = ticketFieldMappingsState;

  return useMemo(() => {
    const contextVariables = getAllContextVariables({
      contextVariablesData,
      shouldIncludeSystemContextVariables,
      shouldIncludeTemplateContextVariables,
      shouldIncludeTriageContextVariables,
      templateContextVariables,
      ticketFieldMappings,
      usableSystemContextVariables,
    });

    return {
      authorizationConfigs,
      contextVariables: filterAndSortContextVariables(
        contextVariables,
        shouldIncludeWorkflowTagContextVariable,
      ),
      isFetching,
      isLoading,
    };
  }, [
    shouldIncludeSystemContextVariables,
    contextVariablesData,
    usableSystemContextVariables,
    shouldIncludeTemplateContextVariables,
    authorizationConfigs,
    shouldIncludeTriageContextVariables,
    ticketFieldMappings,
    isLoading,
    templateContextVariables,
    shouldIncludeWorkflowTagContextVariable,
    isFetching,
  ]);
};
