import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import isPropValid from '@emotion/is-prop-valid';
import { Box, styled, useTheme } from '@mui/material';

import {
  Button,
  Dialog,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  useConstructUrlOnIntetWorkflowCreation,
  useGetBuilderQueryParams,
  useGetDeleteWorkflowMessage,
  useGetHandoffConfigurationForWorkflow,
  useGetIntentTitle,
  useGetIsTemplateIntentWorkflow,
  useGoBackToLandingPage,
  useInitPusher,
  useSendDeleteIntentTrackingEvents,
} from '../hooks';
import WorkflowConflictMessage from '../WorkflowConflictMessage';
import {
  deleteIntentWorkflows,
  duplicateIntentWorkflow,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import BackIcon from 'src/assets/images/actions-back-icon.svg';
import BackIconWhite from 'src/assets/images/actions-back-icon-white.svg';
import { useDeleteIntentEventSubscription } from 'src/hooks/PusherEventHooks';
import { WorkflowDropdownMenu } from 'src/pages/workflow-builder/DropdownMenu';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  dashboardApi,
  useGetPaidPlanDetailsQuery,
} from 'src/services/dashboard-api';
import {
  useGetHandoffConfigurationsQuery,
  useGetIntentsQuery,
} from 'src/services/workflow-builder-metrics';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { getIntent } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { getDiscoverAutomations } from 'src/slices/data/thunks';
import {
  getWidgetConfiguration,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import {
  openGlobalIntentDrawer,
  setGlobalHandoffDrawerOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';
import { CommonIntentWorkflowType, Routes } from 'src/utils/enums';
import { getUserOrgCode } from 'src/utils/getUserOrgCode';
import {
  isIntentDuplicable,
  isIntentEditable,
} from 'src/utils/solve/intentUtils';

interface WorkflowBuilderEditHeader {
  isDarkMode?: boolean;
  isFromNavbar?: boolean;
}

const WorkflowBuilderEditHeader = ({
  isDarkMode = false,
  isFromNavbar = false,
}: WorkflowBuilderEditHeader) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const deleteMessage = useGetDeleteWorkflowMessage();

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const [isIntentDeleted, setIsIntentDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { intentId, view } = useGetBuilderQueryParams();

  const intentTitle = useGetIntentTitle(intentId);
  const isTemplateIntentWorkflow = useGetIsTemplateIntentWorkflow();
  const workflowBuilderData: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const handoffConfiguration = useGetHandoffConfigurationForWorkflow();
  const isHandoff = handoffConfiguration !== undefined;
  const { refetch: refetchHandoffConfigurations } =
    useGetHandoffConfigurationsQuery();
  const { refetch: refetchIntents } = useGetIntentsQuery();

  const userCanDeleteAction = useSelector(
    selectUserCan('delete_custom_intent'),
  );
  const { data: paidPlanData } = useGetPaidPlanDetailsQuery();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const goBackToLandingPage = useGoBackToLandingPage();

  const sendDeleteWorkflowTrackingEvents =
    useSendDeleteIntentTrackingEvents(workflowBuilderData);

  const workflowDeletionChannel = new BroadcastChannel('workflow-deletion');
  const workflowId = workflowBuilderData?.intent_workflow_id || '';

  const isDiscoverEnabled = paidPlanData?.enablement_info.discover;

  useConstructUrlOnIntetWorkflowCreation();

  useInitPusher();

  useDeleteIntentEventSubscription({
    onEvent: useCallback(() => {
      setIsIntentDeleted(true);
    }, []),
    orgCode: getUserOrgCode(),
  });

  useEffect(() => {
    dispatch(getWidgetConfiguration());
  }, [dispatch]);

  const duplicateWorkflow = async () => {
    try {
      const data = await dispatch(
        duplicateIntentWorkflow({
          workflowId,
        }),
      ).unwrap();
      await refetchHandoffConfigurations();
      const newWorkflowId = data.canvas.intent_workflow_id;
      await dispatch(getIntent(data.canvas.intent_id));
      navigate({
        pathname: Routes.WORKFLOW_BUILDER_EDIT,
        search: `workflowId=${newWorkflowId}&intentId=${data.canvas.intent_id}&view=${view}`,
      });
      if (!isHandoff) {
        dispatch(openGlobalIntentDrawer({ type: 'widget' }));
      }
      // because we don't yet use rtk query for all intent apis,
      // manually invalidate the cache tag to trigger a refetch
      dispatch(dashboardApi.util.invalidateTags(['Intents']));
    } catch (e) {
      console.error(e);
    }
  };

  const onEditHandoffClicked = () => {
    dispatch(
      setGlobalHandoffDrawerOptions({
        handoffToEdit: {
          handoffName: intentTitle,
          intentId: intentId,
          intentWorkflowId: workflowId,
          isDefaultHandoff: handoffConfiguration?.is_default_handoff ?? false,
        },
        isCreateHandoffDrawerOpen: true,
        isEditing: true,
      }),
    );
  };

  const onEditIntentClicked = async () => {
    await dispatch(
      getIntent(
        (workflowBuilderData.intent_id ||
          workflowBuilderData.intent?.intent_id) as string,
      ),
    );
    if (isDiscoverEnabled) {
      await dispatch(getDiscoverAutomations());
    }
    dispatch(
      openGlobalIntentDrawer({
        type: view,
      }),
    );
  };

  const onDeleteIntent = async () => {
    setIsLoading(true);
    await dispatch(
      deleteIntentWorkflows([workflowId], CHANNEL_TO_PRODUCT_MAP[view]),
    );
    await refetchIntents();
    sendDeleteWorkflowTrackingEvents();
    goBackToLandingPage();
    setIsDeleteDialogVisible(false);
    setIsLoading(false);
    workflowDeletionChannel.postMessage(workflowId);
  };

  return (
    <>
      <Header isDarkMode={isDarkMode} isFromNavbar={isFromNavbar}>
        <BackButton
          disabled={workflowBuilderData.loading}
          onClick={() => {
            goBackToLandingPage();
          }}
        >
          <img alt='' src={isDarkMode ? BackIconWhite : BackIcon} />
        </BackButton>
        {intentTitle && (
          <>
            <Typography
              color={isDarkMode ? palette.colors.white : palette.colors.black}
              variant='font24'
            >
              {intentTitle}
            </Typography>
            {!isTemplateIntentWorkflow && (
              <Box height='30px' marginLeft='10px' width='30px'>
                <WorkflowDropdownMenu
                  intentId={intentId}
                  intentName={intentTitle}
                  isDarkMode={isDarkMode}
                  isDisabled={!!workflowBuilderData?.loading}
                  isHandoff={isHandoff}
                  onDeleteClicked={() => setIsDeleteDialogVisible(true)}
                  onDuplicateClicked={duplicateWorkflow}
                  onEditClicked={() => {
                    isHandoff ? onEditHandoffClicked() : onEditIntentClicked();
                  }}
                  shouldEnableDelete={
                    userCanDeleteAction &&
                    !Object.values(CommonIntentWorkflowType).includes(
                      intentId as CommonIntentWorkflowType,
                    ) &&
                    workflowId !==
                      widgetConfiguration.quick_feedback_config
                        ?.negative_routing_intent_workflow_id
                  }
                  shouldShowChats={view === 'widget'}
                  shouldShowDuplicate={
                    view === 'widget' && isIntentDuplicable(intentId)
                  }
                  shouldShowEdit={isIntentEditable(intentId)}
                  shouldShowPreview={false}
                />
              </Box>
            )}
          </>
        )}
      </Header>
      <Dialog
        footer={
          <>
            <Button
              onClick={() => setIsDeleteDialogVisible(false)}
              variant='ghost'
            >
              Cancel
            </Button>
            <Button
              aria-label='Delete confirm'
              disabled={isLoading}
              isLoading={isLoading}
              onClick={onDeleteIntent}
              variant='secondary'
            >
              Delete
            </Button>
          </>
        }
        hideCloseButton
        onClose={() => setIsDeleteDialogVisible(false)}
        open={isDeleteDialogVisible}
        title='Delete?'
      >
        <Typography variant='font14'>
          Are you sure you want to delete this item? <br />
          {deleteMessage}
        </Typography>
      </Dialog>
      <WorkflowConflictMessage
        buttonMessage='Go to the landing page'
        message='Go to the landing page to see the list of existing intents'
        onButtonClick={() => goBackToLandingPage()}
        open={isIntentDeleted}
        title='The current intent has been deleted by another user in your organization'
      />
    </>
  );
};

export default WorkflowBuilderEditHeader;

const Header = styled('div', {
  shouldForwardProp: prop =>
    isPropValid(String(prop)) &&
    prop !== 'isDarkMode' &&
    prop !== 'isFromNavbar',
})<{ isDarkMode: boolean; isFromNavbar: boolean }>`
  display: flex;
  justify-content: flex-start;
  padding: ${props => (props.isFromNavbar ? '0px' : '16px 24px 16px 40px')};
  background: ${props =>
    props.isDarkMode
      ? props.theme.palette.colors.slate[800]
      : props.theme.palette.background.default};
  border-bottom: ${props =>
    props.isFromNavbar
      ? '0px'
      : `1px solid ${props.theme.palette.colors.slate[200]}`};
  align-items: center;
  transition: inherit;
  flex: 1;
`;

const BackButton = styled('button')`
  margin-right: 20px;
  cursor: pointer;
  padding: 1px 2px 2px 2px;
  &:disabled {
    cursor: auto;
  }
  height: 26px;
  &:hover {
    background-color: transparent !important;
  }
`;

BackButton.defaultProps = {
  'aria-label': 'Back',
};
