import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { ICON_SIZE } from '../../discover-automation-page/constants';
import { Direction, ViewMode, ViewType } from './types';

const hideStyle = {
  fontSize: '0px',
  margin: 0,
  maxHeight: '0px',
  maxWidth: 0,
  opacity: 0,
  transitionDelay: '0s, 0s, 0s, 0s, 0s',
  transitionDuration: '0.3s',
  transitionProperty: 'opacity, max-height, margin, font-size, max-width',
};

const showStyle = {
  maxHeight: '100px',
  maxWidth: '100vw',
  transition: '0.3s',
  transitionDelay: '0s',
};

const hideAndShowStyle = {
  '@keyframes font-size-frame': {
    '0%': {
      maxHeight: '100px',
    },
    '100%': {
      fontSize: '16px',
      maxHeight: '100px',
    },
    '50%': {
      fontSize: '0px',
      maxHeight: '0px',
    },
  },
  '@keyframes opacity-frame': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0,
    },
  },
  animationDuration: '0.8s, 0.6s',
  animationName: 'opacity-frame, font-size-frame',
  fontSize: '16px',
  fontWeight: 600,
  transition: '0.6s',
  transitionDelay: '0.3s',
};

interface CardMetricProps {
  direction?: Direction;
  emptyStateIcon?: React.ReactNode;
  icon?: React.ReactNode;
  isBottomLabel?: boolean;
  labelDescription?: string;
  labelTitle: string;
  labelTitleWidth?: string | number;
  loading?: boolean;
  shouldShowBorderRight?: boolean;
  shouldShowBorderTop?: boolean;
  tooltip?: string;
  value: string;
  viewMode?: ViewMode;
  viewType: ViewType;
  wrapperMinWidth?: string | number;
}

const LoadingState = ({ viewType }: { viewType: ViewType }) => {
  const variant = viewType === 'primary' ? 'white' : 'primary';
  return (
    <Skeleton
      height={44}
      skeletonColorVariant={variant}
      variant='rectangular'
      width={100}
    />
  );
};

const CardMetric = ({
  direction,
  emptyStateIcon,
  icon,
  isBottomLabel,
  labelDescription,
  labelTitle,
  labelTitleWidth = 'auto',
  loading,
  shouldShowBorderRight,
  shouldShowBorderTop,
  tooltip,
  value,
  viewMode = 'full',
  viewType = 'primary',
  wrapperMinWidth = 'auto',
}: CardMetricProps) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));
  const isMinimized = viewMode === 'minimized';
  const isColumnDirection = direction === 'column';
  const isDefaultViewType = viewType === 'default';
  const valueFontSize = viewType === 'dashboard' ? '28px' : '42px';

  const valueStyles = [
    { whiteSpace: 'nowrap' },
    isMinimized
      ? hideAndShowStyle
      : {
          ...showStyle,
          fontSize: isColumnDirection ? '24px' : valueFontSize,
          fontWeight: 600,
          lineHeight: isColumnDirection ? '24px' : valueFontSize,
        },
  ];

  const descriptionElement = (
    <Box
      display='flex'
      mb={0.5}
      sx={{
        ...{
          '& span': {
            fontWeight: '500',
          },
        },
        ...(isMinimized ? hideStyle : showStyle),
      }}
    >
      <Typography color={palette.colors.slate[700]} variant='font12'>
        {labelDescription}
        {tooltip && (
          <Tooltip tooltipContent={tooltip}>
            <TransformedIconInfoCircle
              size={ICON_SIZE}
              stroke={
                isDefaultViewType ? palette.colors.grey[600] : 'currentcolor'
              }
              strokeWidth={2}
            />
          </Tooltip>
        )}
      </Typography>
    </Box>
  );

  const renderCardMetricDefaultView = (): React.ReactNode => (
    <Box
      alignItems='flex-start'
      display='flex'
      gap={1.2}
      justifyContent={isMinimized && !isMobile ? 'flex-end' : 'flex-start'}
    >
      <Box sx={isMinimized ? hideAndShowStyle : showStyle}>{value && icon}</Box>
      <Box display='flex' flexDirection='column'>
        {loading ? (
          <LoadingState viewType={viewType} />
        ) : (
          <Box sx={valueStyles}>
            <span>{value || emptyStateIcon || 'N/A'}</span>
          </Box>
        )}

        <Box display='flex' mt={1.5} sx={isMinimized ? hideStyle : showStyle}>
          <Typography color={palette.colors.slate[700]} variant='font16Bold'>
            {labelTitle}
          </Typography>
        </Box>
        <Box sx={isMinimized ? hideStyle : showStyle}>
          {labelDescription && !isColumnDirection && descriptionElement}
        </Box>
      </Box>
    </Box>
  );

  const renderCardMetricPrimaryView = (): React.ReactNode => (
    <>
      {!isBottomLabel && (
        <>
          <Box
            display='flex'
            mb={1}
            sx={{
              '& span': {
                fontWeight: isColumnDirection ? '600' : '500',
              },
            }}
            width={labelTitleWidth}
          >
            <Typography
              color={palette.colors.slate[700]}
              variant={isColumnDirection ? 'font16Bold' : 'font14'}
            >
              {labelTitle}
            </Typography>
          </Box>
          <Box sx={isMinimized ? hideStyle : showStyle}>
            {labelDescription && descriptionElement}
          </Box>
        </>
      )}

      <Box
        display='flex'
        justifyContent={isMinimized && !isMobile ? 'flex-end' : 'flex-start'}
        mt={!isBottomLabel ? 'auto' : 0}
      >
        {loading ? (
          <LoadingState viewType={viewType} />
        ) : (
          <Box sx={valueStyles}>
            <span>{value || emptyStateIcon || 'N/A'}</span>
            {value && icon}
          </Box>
        )}
      </Box>
      {isBottomLabel && (
        <>
          <Box
            display='flex'
            mt={1.5}
            sx={isMinimized ? hideStyle : showStyle}
            width={labelTitleWidth}
          >
            <Typography variant='font16Bold'>{labelTitle}</Typography>
          </Box>

          <Box sx={isMinimized ? hideStyle : showStyle}>
            {labelDescription && !isColumnDirection && descriptionElement}
          </Box>
        </>
      )}
    </>
  );

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      minWidth={wrapperMinWidth}
      py={isColumnDirection ? 1.8 : 0}
      sx={[
        !isColumnDirection && {
          paddingRight: 1.5,
        },
        Boolean(shouldShowBorderRight) && {
          borderRight: `1px solid ${palette.colors.grey[200]}`,
        },
        Boolean(shouldShowBorderTop) && {
          borderTop: `1px solid ${palette.colors.grey[200]}`,
        },
      ]}
    >
      {isDefaultViewType
        ? renderCardMetricDefaultView()
        : renderCardMetricPrimaryView()}
    </Box>
  );
};

const TransformedIconInfoCircle = styled(IconInfoCircle)`
  transform: translateX(3px) translateY(4px);
`;

export default CardMetric;
