import { Box } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { getInstallUrl } from 'src/services/solve-config/solveSlackConfigApi';

export const SlackIntegrationTabContent = () => {
  const onIntegrateClick = async () => {
    const url = await getInstallUrl();

    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='font16Bold'>Integrate Slack</Typography>
      <Box marginBottom='16px' marginTop='16px'>
        <Typography variant='font14'>
          Click the button below to integrate slack to forethought.
        </Typography>
      </Box>
      <Button fullWidth onClick={onIntegrateClick} variant='main'>
        Integrate
      </Button>
    </Box>
  );
};
