import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { updateActionSettings } from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import ContextMention from '../context-mention-input';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import IntercomOptionComponent from 'src/pages/workflow-builder-edit/handoff-configuration/intercom/IntercomOptionComponent';
import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import type { RootState } from 'src/store/rootReducer';
import { textMediumStyle } from 'src/styles/styledMixin';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { IntercomHandoffSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface IntercomHandoffCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const IntercomHandoffCustomizationPanel: FC<
  React.PropsWithChildren<IntercomHandoffCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  // @ts-expect-error legacy code with untyped state
  const handoffCustomization: IntercomHandoffSettings = useSelector(
    (state: RootState) =>
      state.canvasWorkflowBuilder?.actionSettings.configuration_fields,
  );
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  // Creating a new state so we can keep track of what has changed
  const [customizationData, setCustomizationData] =
    useState<IntercomHandoffSettings>(handoffCustomization);
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );

  const isPristine = isEqual(customizationData, handoffCustomization);
  const isFormDisabled = () => {
    if (isPristine) {
      return true;
    }

    if (customizationData) {
      const {
        ask_for_visitor_email: askForEmail,
        ask_for_visitor_name: askForName,
        identity_verification_enabled: identityVerificationEnabled,
        include_transcript: includeTranscript,
      } = customizationData.intercom_handoff_settings;

      if (
        identityVerificationEnabled &&
        !includeTranscript &&
        (askForEmail || askForName)
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setCustomizationData(handoffCustomization);
    }
  }, [canvasState.isLoadingCustomization, handoffCustomization]);

  if (canvasState.isLoadingCustomization || !handoffCustomization) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <ContentWrapper>
        <Content>
          <Title>Intercom Handoff</Title>
          <Spacer height='20px' />
          <IntercomOptionComponent
            checked={
              !!customizationData?.intercom_handoff_settings
                ?.ask_for_visitor_question
            }
            label='Should the user be prompted for their question?'
            onChange={() =>
              setCustomizationData(handoffData => {
                return {
                  ...handoffData,
                  intercom_handoff_settings: {
                    ...handoffData.intercom_handoff_settings,
                    ask_for_visitor_question:
                      !handoffData.intercom_handoff_settings
                        .ask_for_visitor_question,
                  },
                };
              })
            }
          />
          <IntercomOptionComponent
            checked={
              !!customizationData?.intercom_handoff_settings
                ?.ask_for_visitor_email
            }
            label='Should the user be prompted for their email?'
            onChange={() =>
              setCustomizationData(handoffData => {
                return {
                  ...handoffData,
                  intercom_handoff_settings: {
                    ...handoffData.intercom_handoff_settings,
                    ask_for_visitor_email:
                      !handoffData.intercom_handoff_settings
                        .ask_for_visitor_email,
                  },
                };
              })
            }
          />
          <IntercomOptionComponent
            checked={
              !!customizationData?.intercom_handoff_settings
                ?.ask_for_visitor_name
            }
            label=' Should the user be prompted for their name?'
            onChange={() =>
              setCustomizationData(handoffData => {
                return {
                  ...handoffData,
                  intercom_handoff_settings: {
                    ...handoffData.intercom_handoff_settings,
                    ask_for_visitor_name:
                      !handoffData.intercom_handoff_settings
                        .ask_for_visitor_name,
                  },
                };
              })
            }
          />
          <IntercomOptionComponent
            checked={
              !!customizationData?.intercom_handoff_settings?.include_transcript
            }
            label='Should the chat transcript be included in intercom conversation.'
            onChange={() =>
              setCustomizationData(handoffData => {
                return {
                  ...handoffData,
                  intercom_handoff_settings: {
                    ...handoffData.intercom_handoff_settings,
                    include_transcript:
                      !handoffData.intercom_handoff_settings.include_transcript,
                  },
                };
              })
            }
          />
          <IntercomOptionComponent
            checked={
              !!customizationData?.intercom_handoff_settings
                ?.identity_verification_enabled
            }
            label='Is "Identity Verification" enabled on your Intercom app.'
            onChange={() =>
              setCustomizationData(handoffData => {
                return {
                  ...handoffData,
                  intercom_handoff_settings: {
                    ...handoffData.intercom_handoff_settings,
                    identity_verification_enabled:
                      !handoffData.intercom_handoff_settings
                        .identity_verification_enabled,
                  },
                };
              })
            }
            tooltip='If so, we are unable to pass Intercom the user email and name. NOTE: If checked and name or email is checked, you must include the transcript to see that information. Please see Intercom Help Center for more info about Identity Verification.'
          />
          <StyledSection>
            <ContextMention
              label='Form Prompt'
              onChange={value => {
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    intercom_handoff_settings: {
                      ...handoffData.intercom_handoff_settings,
                      form_prompt: value,
                    },
                  };
                });
              }}
              placeholder='Enter form prompt'
              value={
                customizationData?.intercom_handoff_settings?.form_prompt || ''
              }
            />
          </StyledSection>
          <StyledSection>
            <ContextMention
              label='Handoff Failure Message'
              onChange={value => {
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    intercom_handoff_settings: {
                      ...handoffData.intercom_handoff_settings,
                      failure_message: value,
                    },
                  };
                });
              }}
              placeholder='Enter failure message'
              value={
                customizationData?.intercom_handoff_settings?.failure_message ||
                ''
              }
            />
          </StyledSection>
        </Content>
      </ContentWrapper>

      <SaveContainer hasTopBorder>
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isFormDisabled() || hasWorkflowConflict}
          onClick={() => {
            dispatch(
              updateActionSettings({
                action_type: canvasState.actionSettings.action_type as string,
                configuration_fields: customizationData,
              }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.INTERCOM_HANDOFF,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </SaveContainer>
    </>
  );
};

export default IntercomHandoffCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  margin-top: 0;
  overflow-y: auto;
`;

const StyledSection = styled(Section)`
  margin: 0 10px;
`;
