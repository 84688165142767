import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {
  IconButton,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import moreIcon from 'src/pages/workflow-builder-edit/assets/icons/more-icon.svg';
import { InsightWorkflow } from 'src/services/insights/types';
import { Routes } from 'src/utils/enums';

interface WorkflowDropdownMenuProps {
  data: InsightWorkflow;
}

export const WorkflowDropdownMenu: React.FC<
  React.PropsWithChildren<WorkflowDropdownMenuProps>
> = ({ data }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const intentWorkflowId = data.intent_workflow_id;
  const intentDefinitionId = data.intent_definition_id;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewWorkflowNavigation = () => {
    const urlParams = new URLSearchParams({
      intentId: intentDefinitionId,
      workflowId: intentWorkflowId,
    });
    navigate(`${Routes.WORKFLOW_BUILDER_EDIT}?${urlParams}`);
  };

  const handleViewSurfacedArticlesNavigation = () => {
    const search = '?tableTab=1&articles_sort_column=times_surfaced';
    const pathName = Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
      ':workflowId',
      intentWorkflowId,
    );

    navigate({
      pathname: pathName,
      search: search,
    });
  };

  return (
    <>
      <IconButton
        aria-label={`workflow dropdown menu for ${intentWorkflowId}`}
        onClick={handleClick}
        variant='ghost'
      >
        <ReactSVG src={moreIcon} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={(e: React.MouseEvent) => {
          e.stopPropagation();
          handleClose();
        }}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            border: `1px solid ${theme.palette.colors.grey[200]}`,
            borderRadius: '4px',
            boxShadow:
              '0px 0px 1px rgba(0, 12, 32, 0.04), 2px 5px 8px rgba(3, 17, 38, 0.107135)',
            padding: '4px 0',
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <StyledMenuItem
          aria-label='View workflow'
          disableRipple
          onClick={e => {
            e.stopPropagation();
            handleViewWorkflowNavigation();
            handleClose();
          }}
        >
          <Typography variant='font14'>View workflow</Typography>
        </StyledMenuItem>
        <StyledMenuItem
          aria-label='View surfaced articles'
          disableRipple
          onClick={e => {
            e.stopPropagation();
            handleViewSurfacedArticlesNavigation();
            handleClose();
          }}
        >
          <Typography variant='font14'>View surfaced articles</Typography>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    background-color: ${theme.palette.colors.purple[100]};
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
