import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';

import GlobalDiscover from '../global-discover';
import Navbar from './navbar/navbarV2';
import { usePermittedRoutes } from './routes/usePermittedRoutes';
import Layout from './layout';
import { AnalyticsRedirect, NavigateAndMergeQueryParams } from './redirects';
import {
  DISCOVER_ROUTE_PREFIX,
  Routes,
  SOLVE_INSIGHT_ROUTE_PREFIX,
} from 'src/utils/enums';

const defaultNavbar = <Navbar />;

interface AppRoutesProps {
  permissionKeys: string[];
}

const GlobalComponentByPath = ({ path }: { path: `${Routes}` }) => {
  if (
    path.startsWith(DISCOVER_ROUTE_PREFIX) ||
    path.startsWith(SOLVE_INSIGHT_ROUTE_PREFIX)
  ) {
    return <GlobalDiscover />;
  }

  return null;
};

const AppRoutes: React.FC<React.PropsWithChildren<AppRoutesProps>> = ({
  permissionKeys,
}) => {
  const permittedRoutes = usePermittedRoutes({
    permissionKeys,
  });
  const permissionSet = new Set(permissionKeys);
  const routes = permittedRoutes
    .map(route => {
      if (!route) {
        return null;
      }
      const { path, showNavBar = true, showSideBar = true } = route;

      return (
        <Route
          element={
            <Layout
              globalComponent={<GlobalComponentByPath path={path} />}
              main={<route.component />}
              navbar={showNavBar ? defaultNavbar : null}
              permissions={permissionSet}
              showMainApp
              showSideBar={showSideBar}
            />
          }
          key={path}
          path={path}
        />
      );
    })
    .filter(Boolean);

  return (
    <ReactRoutes>
      <Route
        element={<AnalyticsRedirect />}
        path='/analytics-beta/:dashboardId'
      />
      <Route
        element={
          <NavigateAndMergeQueryParams
            newParams={{ channel: 'email' }}
            to={Routes.SOLVE_CONFIGURATION}
          />
        }
        path={Routes.SOLVE_EMAIL_CONFIGURATION}
      />
      <Route
        element={
          <NavigateAndMergeQueryParams
            newParams={{ channel: 'widget' }}
            to={Routes.SOLVE_CONFIGURATION}
          />
        }
        path={Routes.WIDGET_CONFIGURATION}
      />
      {routes}
    </ReactRoutes>
  );
};

export default AppRoutes;
