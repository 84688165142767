import { useSelector } from 'react-redux';

import {
  selectCanvasActionIdToActionComponent,
  selectUndefinedContextVariablesInStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';

export const useGetUndefinedContextVariableIdsOnCurrentAction =
  (): string[] => {
    const actionIdToComponent = useSelector(
      selectCanvasActionIdToActionComponent,
    );
    const undefinedContextVariablesInStep = useSelector(
      selectUndefinedContextVariablesInStep,
    );

    const steps = Object.values(actionIdToComponent)?.[0]?.steps || [];
    const allUndefinedVariableInSet: Set<string> = new Set();
    steps.forEach(stepId => {
      if (stepId in undefinedContextVariablesInStep) {
        undefinedContextVariablesInStep[stepId].forEach(cvId =>
          allUndefinedVariableInSet.add(cvId),
        );
      }
    });

    return Array.from(allUndefinedVariableInSet);
  };
