import { SettingsSlice } from './types';
import type { RootState } from 'src/store/rootReducer';

export const selectWhitelistedDomains = (
  state: RootState,
): SettingsSlice['whitelistedDomains'] => {
  return state.settings.whitelistedDomains;
};

export const selectAllowAllDomains = (
  state: RootState,
): SettingsSlice['allowAllDomains'] => {
  return state.settings.allowAllDomains;
};

export const selectSettings = (state: RootState): SettingsSlice =>
  state.settings;

export const selectPusherSessionId = (state: RootState): string =>
  state.settings.pusherSessionId;
