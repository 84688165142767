import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  setIsEditing,
  setIsUpdateError,
} from '../../../slices/connectors/connectorsSlice';
import {
  useConnectors,
  useIntegrationSlug,
  useTrackingEventOnSearchQueryChange,
} from './hooks';
import IntegrationDrawer from './integration-drawer';
import IntegrationsPageHeader from './integrations-page-header';
import IntegrationsCard from './IntegrationsCard';
import IntegrationsEmptyState from './IntegrationsEmptyState';
import IntegrationsLoadingState from './IntegrationsLoadingState';
import { IntegrationSection } from './styles';
import SettingsSection from 'src/components/reusable-components/settings-section';
import SettingsPageLayout from 'src/components/settings-page-layout';

const AVAILABLE_SECTION_ID = 'available-integrations-section';

export default function IntegrationsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const integrationSlug = useIntegrationSlug();
  const {
    availableConnectors,
    createdConnectorDefinitions,
    createdConnectors,
    filteredAvailableConnectors,
    filteredCreatedConnectors,
    isLoading,
  } = useConnectors(searchQuery);
  useTrackingEventOnSearchQueryChange(searchQuery);

  const selectedConnectorDefinition = useMemo(() => {
    const availableConnectorsForDetailView = availableConnectors;
    return [
      ...createdConnectorDefinitions,
      ...availableConnectorsForDetailView,
    ].find(connector => {
      return connector.slug === integrationSlug;
    });
  }, [availableConnectors, createdConnectorDefinitions, integrationSlug]);

  const selectedConnector = useMemo(() => {
    return createdConnectors.find(
      connector => connector.connector_definition.slug === integrationSlug,
    );
  }, [createdConnectors, integrationSlug]);

  const isEmpty = useMemo(() => {
    return (
      !isLoading &&
      !(filteredCreatedConnectors.length + filteredAvailableConnectors.length)
    );
  }, [filteredCreatedConnectors, filteredAvailableConnectors, isLoading]);

  return (
    <SettingsPageLayout>
      <IntegrationsPageHeader
        clearSearch={() => setSearchQuery('')}
        onSearch={setSearchQuery}
        searchQuery={searchQuery}
      />
      {isLoading && <IntegrationsLoadingState />}
      {isEmpty && <IntegrationsEmptyState />}
      {!isLoading && !!filteredCreatedConnectors.length && (
        <SettingsSection
          actions={[
            {
              label: 'Connect new Integration',
              onClick: () =>
                document
                  .getElementById(AVAILABLE_SECTION_ID)
                  ?.scrollIntoView({ behavior: 'smooth' }),
            },
          ]}
          title='Active'
        >
          <IntegrationSection>
            {filteredCreatedConnectors.map(connector => {
              const { connector_definition, is_active } = connector;
              return (
                <IntegrationsCard
                  connectorDefinition={connector_definition}
                  isInActiveSection
                  key={connector_definition.slug}
                  status={is_active ? 'active' : 'warning'}
                />
              );
            })}
          </IntegrationSection>
        </SettingsSection>
      )}
      {!isLoading && !!filteredAvailableConnectors.length && (
        <SettingsSection id={AVAILABLE_SECTION_ID} title='Available'>
          <IntegrationSection>
            {filteredAvailableConnectors.map(connectorDefinition => {
              return (
                <IntegrationsCard
                  connectorDefinition={connectorDefinition}
                  key={connectorDefinition.slug}
                  status='disconnected'
                />
              );
            })}
          </IntegrationSection>
        </SettingsSection>
      )}
      <IntegrationDrawer
        connectorDefinition={selectedConnectorDefinition}
        onClose={() => {
          dispatch(setIsUpdateError(false));
          dispatch(setIsEditing(false));
          navigate(pathname);
        }}
        selectedConnector={selectedConnector}
      />
    </SettingsPageLayout>
  );
}
