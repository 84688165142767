import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { FormProps } from '@rjsf/core';

import { Button, theme } from '@forethought-technologies/forethought-elements';
import { createConnector } from '../../../../../slices/connectors/thunks';
import DrawerSection from '../../DrawerSection';
import ConnectConnectorButton from '../ConnectConnectorButton';
import IntegrationForm from '../IntegrationForm';
import useHandleConnectOAuthSetupType from './hooks/useHandleConnectOAuthSetupType';
import {
  Connector,
  ConnectorDefinition,
  Credentials,
} from 'src/services/apiInterfaces';
import { selectConnectors } from 'src/slices/connectors/connectorsSlice';

export interface CreateIntegrationFormProps extends FormProps<Credentials> {
  connectorDefinition: ConnectorDefinition;
  /* When using the create form to re-start oauth flow for an existing oauth connector */
  oauthConnector?: Connector;
}

export default function CreateIntegrationForm({
  connectorDefinition,
  oauthConnector,
  ...rest
}: CreateIntegrationFormProps) {
  const dispatch = useDispatch();
  const handleConnectOAuthSetupType = useHandleConnectOAuthSetupType({
    connectorDefinition,
  });
  const { isUpdateLoading } = useSelector(selectConnectors);

  const handleConnectFormSetupType = async (formData: Credentials) => {
    dispatch(
      createConnector({
        connectorDefinitionSlug: connectorDefinition.slug,
        connectorFields: formData,
        connectorTypes: connectorDefinition.connector_types,
      }),
    );
  };

  const handleConnect = ({ formData }: { formData?: Credentials }) => {
    if (formData === undefined) {
      return;
    }

    const { setup_type } = connectorDefinition;
    if (setup_type === 'OAUTH') {
      handleConnectOAuthSetupType(formData);
    } else if (setup_type === 'FORM') {
      handleConnectFormSetupType(formData);
    }
  };

  const formButtons = useMemo(() => {
    return (
      <>
        {oauthConnector && (
          <ConnectConnectorButton connector={oauthConnector} />
        )}
        <Button
          disabled={isUpdateLoading}
          fullWidth
          isLoading={isUpdateLoading}
          size='large'
          type='submit'
          variant='secondary'
        >
          {oauthConnector ? 'Restart auth flow' : 'Connect'}
        </Button>
      </>
    );
  }, [isUpdateLoading, oauthConnector]);

  return (
    <>
      {connectorDefinition.setup_instructions && (
        <DrawerSection title='Setup Instructions'>
          <SetupInstructions
            dangerouslySetInnerHTML={{
              __html: connectorDefinition.setup_instructions,
            }}
          />
        </DrawerSection>
      )}
      <IntegrationForm
        formButtons={formButtons}
        onSubmit={handleConnect}
        {...rest}
      />
    </>
  );
}

const SetupInstructions = styled('div')`
  color: ${theme.palette.grey[700]};
  font-size: 14px;
  line-height: 24px;

  ol,
  ul {
    padding-inline-start: 16px;
  }
`;
