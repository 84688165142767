import React, { FC } from 'react';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';

interface AddFieldLinkProps {
  fieldName: string;
  preChatValueArray: Array<{
    label: string;
    transcript_fields: string[];
    value: string;
  }>;
  setFieldValue: (
    name: string,
    value: Array<{ label: string; transcript_fields: string[]; value: string }>,
  ) => void;
}
const AddPreChatFieldLink: FC<React.PropsWithChildren<AddFieldLinkProps>> = ({
  fieldName,
  preChatValueArray,
  setFieldValue,
}) => {
  return (
    <LinkOut
      isDisabled={false}
      onClick={() => {
        preChatValueArray.concat;
        const updatedFieldValues = preChatValueArray.concat([
          {
            label: '',
            transcript_fields: [''],
            value: '',
          },
        ]);
        setFieldValue(fieldName, updatedFieldValues);
      }}
    >
      + Add field
    </LinkOut>
  );
};

export default AddPreChatFieldLink;

const LinkOut = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;
