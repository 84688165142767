import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

import {
  Button,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import DiscoverMenuButton from 'src/components/discover-menu-button/DiscoverMenuButton';
import { PATCHING_TOPIC_TABLE_ROW_CLASS_NAME } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { setGlobalDiscoverOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface AutomationComponentProps {
  topic: DiscoverTopic | null;
  trackingEventMetadata: Record<string, string | string[]>;
}

export const AutomatedButton = ({
  topic,
  trackingEventMetadata,
}: AutomationComponentProps) => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  if (!topic?.automation_urls?.length) {
    return null;
  }
  return (
    <Tooltip disableHover tooltipContent='View this workflow in Solve'>
      <StyledLink to={decodeURIComponent(topic.automation_urls[0])}>
        <Button
          fullWidth
          hoverBackgroundColor={palette.colors.purple[200]}
          onClick={e => {
            e.stopPropagation();

            emitTrackingEventCallback('discover-view-workflow-button-clicked', {
              ...trackingEventMetadata,
              bookmarked: topic.is_bookmarked,
              recommended: topic.is_recommended,
            });
          }}
          variant='ghost'
        >
          View workflow
        </Button>
      </StyledLink>
    </Tooltip>
  );
};

export const AutomationCallToActionButton = ({
  topic,
  trackingEventMetadata,
}: AutomationComponentProps) => {
  const dispatch = useAppDispatch();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { palette } = useTheme();

  return (
    <Tooltip disableHover tooltipContent='Automate this topic with a workflow'>
      <Button
        fullWidth
        hoverBackgroundColor={palette.colors.purple[200]}
        onClick={e => {
          e.stopPropagation();

          emitTrackingEventCallback('discover-automate-button-clicked', {
            ...trackingEventMetadata,
            bookmarked: topic?.is_bookmarked,
            recommended: topic?.is_recommended,
          });
          dispatch(
            setGlobalDiscoverOptions({
              topicToAutomate: topic,
            }),
          );
        }}
        variant='ghost'
      >
        Automate
      </Button>
    </Tooltip>
  );
};

export const AutomationCallToActionRendererComponent = ({
  topic,
  trackingEventMetadata,
}: AutomationComponentProps) => {
  const showAutomatedButton = topic?.is_automated;
  const showAutomateButton =
    !showAutomatedButton && Boolean(topic?.summaries?.length);

  return (
    <Box
      data-appcues-target={getAppCuesId({
        componentType: 'button',
        featureName: 'table',
        pageName: 'alltopics',
        subType: 'calltoaction',
      })}
      sx={{
        [`.${PATCHING_TOPIC_TABLE_ROW_CLASS_NAME} &`]: {
          opacity: 1,
        },
        '.MuiTableRow-hover:hover &': {
          opacity: 1,
        },
        display: 'flex',
        flexDirection: 'row',
        opacity: 0,
      }}
    >
      {showAutomatedButton && (
        <AutomatedButton
          topic={topic}
          trackingEventMetadata={trackingEventMetadata}
        />
      )}
      {showAutomateButton && (
        <AutomationCallToActionButton
          topic={topic}
          trackingEventMetadata={trackingEventMetadata}
        />
      )}
      <DiscoverMenuButton
        onClickCallback={e =>
          // current target is nested 4 times below table row
          // this is needed to apply hover styles on showing the menu
          e.currentTarget.parentElement?.parentElement?.parentElement?.parentElement?.classList.add(
            PATCHING_TOPIC_TABLE_ROW_CLASS_NAME,
          )
        }
        onCloseCallback={() => {
          const element = document.querySelector(
            `.${PATCHING_TOPIC_TABLE_ROW_CLASS_NAME}`,
          );
          element?.classList.remove(PATCHING_TOPIC_TABLE_ROW_CLASS_NAME);
        }}
        topic={topic}
        variant='all_topics'
      />
    </Box>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  &:visited {
    color: var(--color-text-primary);
  }
`;
