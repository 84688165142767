import './styles.scss';

import React from 'react';

import { InitialsIconColors } from 'src/utils/enums';
import { getInitials } from 'src/utils/getInitials';
import { iconColor } from 'src/utils/iconColorForInitials';

interface InitialsIconProps {
  /** Agent name string */
  agentName: string;
}

const colors = [
  InitialsIconColors.RED,
  InitialsIconColors.YELLOW,
  InitialsIconColors.VIOLET,
  InitialsIconColors.PURPLE,
  InitialsIconColors.SALMON,
  InitialsIconColors.ICE_CREAM,
  InitialsIconColors.BLUE,
  InitialsIconColors.MAGENTA,
  InitialsIconColors.GREEN,
  InitialsIconColors.TEAL,
  InitialsIconColors.DARK_BLUE,
  InitialsIconColors.COAL,
  InitialsIconColors.ORANGE,
];

//renders an icon with agents initials
const InitialsIcon: React.FC<React.PropsWithChildren<InitialsIconProps>> = ({
  agentName,
}: InitialsIconProps) => {
  return (
    <div className={`InitialsIcon ${iconColor(agentName, colors)}`}>
      <p className='InitialsIcon-paragraph'>{getInitials(agentName)}</p>
    </div>
  );
};

export default InitialsIcon;
