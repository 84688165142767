import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import TicketDetails from './TicketDetails';
import TicketId from './TicketId';
import TicketMetrics from './TicketMetrics';
import TicketSummary from './TicketSummary';
import { DiscoverTicket } from 'src/reducers/discoverReducer/types';

const TicketDrawerDetails = ({
  PageControl,
  ticket,
}: {
  PageControl?: React.ReactNode;
  ticket: DiscoverTicket;
}) => {
  const { palette } = useTheme();

  return (
    <Box display='flex' flexDirection='column' rowGap={2}>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='space-between'
        sx={{ button: { position: 'unset' } }}
      >
        <TicketId link={ticket.ticket_link} ticketId={ticket.id} />
        {PageControl}
      </Box>
      <Box display='flex' flexDirection='column' rowGap={1}>
        <TicketSummary summary={ticket.summary} />
        <Box
          borderRadius={1}
          display='flex'
          flexDirection='column'
          p={2}
          sx={{ border: `1px solid ${palette.colors.slate[200]}` }}
        >
          <TicketMetrics ticket={ticket} />
        </Box>
      </Box>

      <TicketDetails ticket={ticket} />
    </Box>
  );
};

export default TicketDrawerDetails;
