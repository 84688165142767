import { ReactNode } from 'react';

import ExpandedWidget from './ExpandedWidget';
import MinimizedWidget from './MinimizedWidget';

type StaticSolveWidgetType = 'expanded' | 'minimized';

interface StaticSolveWidgetProps {
  children?: ReactNode;
  type?: StaticSolveWidgetType;
}

const StaticSolveWidget = ({
  children,
  type = 'expanded',
}: StaticSolveWidgetProps) => {
  if (type === 'minimized') {
    return <MinimizedWidget>{children}</MinimizedWidget>;
  }

  return <ExpandedWidget>{children}</ExpandedWidget>;
};

export default StaticSolveWidget;
