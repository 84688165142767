import { theme } from '@forethought-technologies/forethought-elements';
import { getDiscoverSentimentEmoji } from '../discover-sentiment-emoji/helpers';
import TooltipPrimaryIcon from 'src/assets/images/chart-tooltip-primary.svg';
import TooltipSecondaryIcon from 'src/assets/images/chart-tooltip-secondary.svg';
import TooltipSecondarySolidIcon from 'src/assets/images/chart-tooltip-secondary-solid.svg';
import { sentimentTypes } from 'src/constants/discover';
import {
  DiscoverMetadata,
  DiscoverMetricChart,
  DiscoverMetricChartValue,
  DiscoverMetricDataType,
  DiscoverTopicAggregatedMetricType,
} from 'src/reducers/discoverReducer/types';
import {
  getDisplayableDataByMetricType,
  getDisplayableValueChanged,
  getMetricLabel,
  getMetricValueFromType,
  getNumberIndicator,
  getPercentChangeColor,
  isSentimentValue,
} from 'src/utils/discover/helpers';

type Point = {
  end_date: string;
  index?: number;
  start_date: string;
  value: number | null;
};

export interface TooltipFormatterContextObject {
  points: {
    point: Point;
    y: number;
  }[];
}
type CustomTooltipRenderer = (arg: {
  context: TooltipFormatterContextObject;
  hasCustomTooltipPosition?: boolean;
  isSpline: boolean;
  metadata: DiscoverMetadata;
  metric: DiscoverTopicAggregatedMetricType;
  previousData?: DiscoverMetricChartValue[];
}) => string | undefined;

export const sentimentBarColors = [
  theme.palette.colors.red[400],
  theme.palette.colors.grey[100],
  theme.palette.colors.green[400],
];

const convertData = (
  item: DiscoverMetricChartValue,
  metric: DiscoverTopicAggregatedMetricType,
) => {
  const value = isSentimentValue(item.value) ? 0 : item.value;
  return {
    ...item,
    value,
    y: Number(getMetricValueFromType(metric, value ?? 0)),
  };
};

const normalizeSentimentData = (data: DiscoverMetricChartValue[]) => {
  return sentimentTypes.map(type => {
    const normalizedSentimentData = data.map(line =>
      isSentimentValue(line.value)
        ? {
            end_date: line.end_date,
            start_date: line.start_date,
            value: line.value[`${type}_change`],
            y: line.value[`${type}_change`],
          }
        : convertData(line, 'sentiment'),
    );

    return [type, normalizedSentimentData] as const;
  });
};

const objectToCssString = (style: Record<string, string>) =>
  Object.entries(style)
    .map(([k, v]) => `${k}:${v}`)
    .join(';');

const generateTooltipContainerStyles = (isCustom = false) => ({
  'background-color': theme.palette.colors.white,
  border: `1px solid ${theme.palette.colors.slate[200]}`,
  'border-radius': '6px',
  'box-shadow': theme.shadows[1],
  color: theme.palette.colors.black,
  display: 'flex',
  'flex-direction': 'column',
  padding: '16px',
  ...(isCustom ? { width: '325px' } : {}),
});

const generateHtmlSentimentBars = (
  values: number[],
  hasNullValue?: boolean,
) => {
  if (hasNullValue) {
    return '<i>Not available</i>';
  }

  const maxValue = Math.max(...values);

  const getContainerStyle = (value: number) => ({
    'align-items': 'center',
    display: 'flex',
    'flex-direction': 'column',
    'margin-right': '2px',
    width: `${(value / maxValue) * 100}%`,
  });

  const getBarStyle = (index: number) => ({
    'background-color': sentimentBarColors[index],
    'border-radius': '2px',
    height: '16px',
    width: '100%',
  });

  return values
    .map((value, index) =>
      value === 0
        ? ''
        : `
        <div style='${objectToCssString(getContainerStyle(value))}'>
          <div style='${objectToCssString(getBarStyle(index))}'>&nbsp;</div>
          <strong>${value}</strong>
        </div>
  `,
    )
    .join('');
};

const generateHtmlLine = () => {
  const lineStyle = {
    'background-color': theme.palette.colors.slate[200],
    height: '1px',
  };

  return `<div style='${objectToCssString(lineStyle)}'>&nbsp;</div>`;
};

const generateSecondaryText = (value: string) => {
  const style = {
    color: theme.palette.colors.grey[600],
    'font-size': '12px',
  };
  return `<span style='${objectToCssString(style)}'>${value}</span>`;
};

const generateMetricValueHtml = (value: string, isNull?: boolean) =>
  isNull ? `<i>${value}</i>` : `<strong>${value}</strong>`;

const generatePercentChangeHtml = (
  value: number,
  metric: DiscoverTopicAggregatedMetricType,
  hasAnyNullValue?: boolean,
) => {
  if (!value) {
    return '';
  }

  const style = {
    color: hasAnyNullValue
      ? theme.palette.grey[800]
      : getPercentChangeColor(value, metric),
  };

  return `<strong style='${objectToCssString(style)}'>${
    hasAnyNullValue ? '' : `(${getDisplayableValueChanged(value, true)})`
  }</strong>`;
};

const parsePointValues = ({
  data,
  dataType,
  metric,
}: {
  data: Point;
  dataType: DiscoverMetricDataType | 'unknown';
  metric: DiscoverTopicAggregatedMetricType;
}) => {
  const { end_date: endDate, start_date: startDate, value } = data;

  const displayableValue = getDisplayableDataByMetricType({
    dataType,
    forTooltip: true,
    metric,
    value,
  });

  const dateString = `${startDate} - ${endDate}`;

  return { dateString, displayableValue, value };
};

const parseChangedValue = ({
  currentPointValue,
  dataType,
  metric,
  previousPointValue,
}: {
  currentPointValue: number | null;
  dataType: DiscoverMetricDataType | 'unknown';
  metric: DiscoverTopicAggregatedMetricType;
  previousPointValue: number | null;
}) => {
  // remove null for arithmetic
  const currentPointValueNumber = currentPointValue ?? 0;
  const previousPointValueNumber = previousPointValue ?? 0;

  const changedValue = Number(
    (currentPointValueNumber - previousPointValueNumber).toFixed(2),
  );
  const changedDisplayableValue = getDisplayableDataByMetricType({
    dataType,
    forChart: true,
    metric,
    value: changedValue,
  });
  const changedPercentage =
    previousPointValue === 0
      ? 0
      : ((currentPointValueNumber - previousPointValueNumber) /
          previousPointValueNumber) *
        100;

  return { changedDisplayableValue, changedPercentage, changedValue };
};

const preprocessTooltipValues = ({
  currentData,
  metadata,
  metric,
  previousData,
}: {
  currentData: Point;
  metadata: DiscoverMetadata;
  metric: DiscoverTopicAggregatedMetricType;
  previousData: Point;
}) => {
  const dataType =
    metadata.topic_metric_data_types.find(item => item.type === metric)
      ?.data_type ?? 'unknown';

  const {
    dateString: currentDateString,
    displayableValue: currentDisplayableValue,
    value: currentPointValue,
  } = parsePointValues({
    data: currentData,
    dataType,
    metric,
  });

  const {
    dateString: previousDateString,
    displayableValue: previousDisplayableValue,
    value: previousPointValue,
  } = parsePointValues({
    data: previousData,
    dataType,
    metric,
  });

  const { changedDisplayableValue, changedPercentage, changedValue } =
    parseChangedValue({
      currentPointValue,
      dataType,
      metric,
      previousPointValue,
    });

  return {
    changedDisplayableValue,
    changedPercentage,
    changedValue,
    currentDateString,
    currentDisplayableValue,
    currentHasNullValue: currentPointValue === null,
    hasNullValue: previousPointValue === null || currentPointValue === null,
    previousDateString,
    previousDisplayableValue,
    previousHasNullValue: previousPointValue === null,
  };
};

export const renderChartTooltip: CustomTooltipRenderer = ({
  context,
  hasCustomTooltipPosition,
  isSpline,
  metadata,
  metric,
}) => {
  // All dates
  if (Array.isArray(context.points) && context.points.length >= 2) {
    const titleLabel = getMetricLabel(metric);

    const [previousPoint, currentPoint] = context.points;

    const {
      changedDisplayableValue,
      changedPercentage,
      changedValue,
      currentDateString,
      currentDisplayableValue,
      currentHasNullValue,
      hasNullValue,
      previousDateString,
      previousDisplayableValue,
      previousHasNullValue,
    } = preprocessTooltipValues({
      currentData: currentPoint.point,
      metadata,
      metric,
      previousData: previousPoint.point,
    });
    const secondaryIcon = isSpline
      ? TooltipSecondaryIcon
      : TooltipSecondarySolidIcon;

    return `
      <div style='${objectToCssString(
        generateTooltipContainerStyles(hasCustomTooltipPosition),
      )}'>
        <div style='margin-bottom: 16px;'>
          <strong>${titleLabel}</strong>
        </div>
        <div style='margin-bottom: 8px; display: flex;'>
          <img height='16px' src='${TooltipPrimaryIcon}' width='16px' />
          <div style='display: flex; flex-direction: column; margin-left: 8px;'>
            ${generateSecondaryText(currentDateString)}
            ${generateMetricValueHtml(
              currentDisplayableValue,
              currentHasNullValue,
            )}
          </div>
        </div>
        <div style='margin-bottom: 16px; display: flex;'>
          <img height='18px' src='${secondaryIcon}' width='18px' />
          <div style='display: flex; flex-direction: column; margin-left: 8px;'>
            ${generateSecondaryText(previousDateString)}
            ${generateMetricValueHtml(
              previousDisplayableValue,
              previousHasNullValue,
            )}
          </div>
        </div>
        ${generateHtmlLine()}
        <div style='margin-top: 16px;'>
          <strong>Change: </strong>
          ${generateMetricValueHtml(
            hasNullValue
              ? 'N/A'
              : getNumberIndicator(changedValue) + changedDisplayableValue,
            hasNullValue,
          )}
          ${generatePercentChangeHtml(changedPercentage, metric, hasNullValue)}
        </div>     
      </div>`;
  }
};

export const renderChartSentimentTooltip: CustomTooltipRenderer = ({
  context,
  hasCustomTooltipPosition,
  metadata,
  metric,
  previousData,
}) => {
  if (Array.isArray(context.points) && context.points.length === 3) {
    const [negative, neutral, positive] = context.points;
    const index = negative.point.index ?? 0;
    const [prevNegative, prevNeutral, prevPositive] = normalizeSentimentData(
      previousData ?? [],
    ).map(item => item[1]);

    const {
      changedDisplayableValue,
      changedPercentage,
      changedValue,
      currentDateString,
      currentHasNullValue,
      hasNullValue,
      previousDateString,
      previousHasNullValue,
    } = preprocessTooltipValues({
      currentData: negative.point,
      metadata,
      metric,
      previousData: prevNegative[index],
    });

    return `
    <div style='${objectToCssString(
      generateTooltipContainerStyles(hasCustomTooltipPosition),
    )}'>
      <div style='margin-bottom: 16px;'>
        <strong>Change in sentiment (Ticket volume)</strong>
      </div>
      <div style='margin-bottom: 8px;'>
        ${generateSecondaryText(currentDateString)}
        <div style='display: flex;'>
          ${generateHtmlSentimentBars(
            [
              negative.point.value ?? 0,
              neutral.point.value ?? 0,
              positive.point.value ?? 0,
            ],
            currentHasNullValue,
          )}
        </div>
      </div>
      <div style='margin-bottom: 16px;'>
        ${generateSecondaryText(previousDateString)}
        <div style='display: flex;'>
          ${generateHtmlSentimentBars(
            [
              prevNegative[index].value ?? 0,
              prevNeutral[index].value ?? 0,
              prevPositive[index].value ?? 0,
            ],
            previousHasNullValue,
          )}
        </div>
      </div>
      ${generateHtmlLine()}
      <div style='display: flex; align-items: center; margin-top: 16px;'>
        <strong style='margin-right: 4px;'>Change:</strong>
        ${
          hasNullValue
            ? ''
            : ` <img width='16px' height='16px' src=${getDiscoverSentimentEmoji(
                'negative',
                'small',
              )}></img>`
        }
        <span style='margin-left: 4px;'>
           ${generateMetricValueHtml(
             hasNullValue
               ? 'N/A'
               : getNumberIndicator(changedValue) + changedDisplayableValue,
             hasNullValue,
           )}
          ${generatePercentChangeHtml(changedPercentage, metric, hasNullValue)}
        </span>
      </div>
      ${generateSecondaryText(
        'Change in volume of tickets with drop in sentiment',
      )}
    </div>`;
  }
};

export const convertToUsableHighChartData = (
  data: DiscoverMetricChart,
): Partial<
  Record<(typeof sentimentTypes)[number] | 'current' | 'previous', Point[]>
> => {
  const { current_line: currentLine, name, previous_line: previousLine } = data;

  /**
   * using custom data to populate series high-chart
   * need to specify the value as a Y property
   */

  if (data.name === 'sentiment') {
    return Object.fromEntries(normalizeSentimentData(currentLine));
  }

  return {
    current: currentLine.map(item => convertData(item, name)),
    previous: previousLine.map(item => convertData(item, name)),
  };
};

export const checkIfArrayHasUsableData = (data: DiscoverMetricChartValue[]) =>
  data.some(
    item => isSentimentValue(item.value) || typeof item.value === 'number',
  );
