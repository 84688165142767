import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import TopicCount from './TopicCount';

type TopicNameHeaderProps = {
  header: string;
  topicCount?: number;
};

const TopicNameHeader = (props: TopicNameHeaderProps) => {
  return (
    <Box alignItems='center' columnGap={1} display='flex'>
      <Typography variant='font14'> {props.header}</Typography>
      <TopicCount count={props.topicCount} />
    </Box>
  );
};

export default TopicNameHeader;
