import './StringFilterModal.scss';

import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import InputField from '../../../reusable-components/input-field/InputField';
import ModalItem from '../../../reusable-components/modal-item/ModalItem';
import SearchBar from '../../../reusable-components/search-bar/SearchBar';
import FiltersTooltip from '../filters-tooltip/FiltersTooltip';
import { getAnswersData } from 'src/actions/data/data';
import {
  clearSelectedFilterValues,
  getFilterValues,
  setFilter,
  toggleFilterValue,
} from 'src/actions/filters/filters';
import {
  selectFilterValues,
  selectIsLoading,
  selectSelectedFilterField,
  selectSelectedFilterValues,
} from 'src/reducers/filtersReducer/filtersReducer';
import setTooltipVisible from 'src/utils/setTooltipVisibility';

interface StringFilterModalProps {
  handleModalVisibility: Function;
  reference: React.RefObject<HTMLDivElement>;
}

const StringFilterModal: React.FC<
  React.PropsWithChildren<StringFilterModalProps>
> = (props: StringFilterModalProps) => {
  const { handleModalVisibility, reference } = props;
  const [isDropdownOpen, setDropdownIsOpened] = useState<boolean>(false);
  const [dropdownOption, setDropdownOption] = useState<number>(2);
  const [value, setValue] = useState<string>('');
  const selectedFilterField = useSelector(selectSelectedFilterField);
  const filterValues = useSelector(selectFilterValues);
  const selectedValues = useSelector(selectSelectedFilterValues);
  const isLoading = useSelector(selectIsLoading);
  const [isTitleOverFlowed, setIsTitleOverFlowed] = useState<boolean>(false);
  const [titleTooltipVisibility, setTitlteTooltipVisibility] =
    useState<boolean>(false);
  const titleRef: React.RefObject<HTMLSpanElement> = useRef(null);

  const dispatch = useDispatch();
  const searchFilters = [
    'CONTAINS',
    'DOES NOT CONTAIN',
    'EQUALS',
    'DOES NOT EQUAL',
    'STARTS WITH',
    'ENDS WITH',
    'EXISTS',
    'DOES NOT EXIST',
  ];

  const handleOnChange = (search: string) => {
    dispatch(getFilterValues(search));
  };

  const handleDropdownState = () => {
    setDropdownIsOpened(!isDropdownOpen);
  };

  const handleDropdownOption = (index: number) => {
    setDropdownOption(index);
  };

  const btnClassCondition =
    (searchFilters[dropdownOption].includes('WITH') && value !== '') ||
    (searchFilters[dropdownOption].includes('CONTAIN') && value !== '') ||
    selectedValues.length !== 0 ||
    searchFilters[dropdownOption].includes('EXIST');

  const applyFilterClass = classNames('StringFilterModal-applyFilterButton', {
    'StringFilterModal--applyFilterButtonEnabled': btnClassCondition,
    'StringFilterModal-existsApplyFilter':
      searchFilters[dropdownOption].includes('EXIST'),
  });

  const dropdownSelectedOptionClass = classNames(
    'StringFilterModal-operatorDropdown',
    {
      'StringFilterModal-operatorDropdownOpen': isDropdownOpen,
    },
  );

  const containerClass = classNames('StringFilterModal-container', {
    'StringFilterModal--Size': !searchFilters[dropdownOption].includes('EQUAL'),
  });

  return (
    <div className={containerClass} data-testid='string_modal' ref={reference}>
      <div className='StringFilterModal-titleContainer'>
        <span className='StringFilterModal-titleIcon StringFilterModal-stringTypeIcon' />
        <span
          className='StringFilterModal-title'
          onMouseEnter={() =>
            setTooltipVisible(
              true,
              setTitlteTooltipVisibility,
              setIsTitleOverFlowed,
              titleRef,
              196,
            )
          }
          onMouseLeave={() =>
            setTooltipVisible(
              false,
              setTitlteTooltipVisibility,
              setIsTitleOverFlowed,
              titleRef,
              196,
            )
          }
          ref={titleRef}
        >
          {isTitleOverFlowed && titleTooltipVisibility && (
            <FiltersTooltip
              isShort
              isTitle
              title={selectedFilterField?.field_name}
            />
          )}
          {selectedFilterField?.field_name}
        </span>
      </div>
      <div
        className='StringFilterModal-dropdownOperatorContainer'
        onClick={() => {
          dispatch(clearSelectedFilterValues());
          setValue('');
          handleDropdownState();
        }}
      >
        <div className={dropdownSelectedOptionClass}>
          <span className='StringFilterModal-selectedDropdownOption'>
            {searchFilters[dropdownOption]}
          </span>
          <span className='StringFilterModal-dropdownOperatorIcon' />
          {isDropdownOpen && (
            <div className='StringFilterModal-selectedDropdownOptionBorder'></div>
          )}
        </div>
        {isDropdownOpen && (
          <div className='StringFilterModal-dropdownOptionsContainer'>
            <div className='StringFilterModal-dropdownBorder'></div>
            {searchFilters.map((filter, index) => (
              <span
                className='StringFilterModal-dropdownOption'
                key={filter}
                onClick={() => handleDropdownOption(index)}
              >
                {filter}
              </span>
            ))}
          </div>
        )}
      </div>

      {!searchFilters[dropdownOption].includes('EXIST') ? (
        searchFilters[dropdownOption].includes('WITH') ||
        searchFilters[dropdownOption].includes('CONTAIN') ? (
          <InputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValue(e.target.value)
            }
            value={value}
          />
        ) : (
          <>
            <SearchBar
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleOnChange(e.target.value);
              }}
            />

            <div className='StringFilterModal-content'>
              {!filterValues.length && !isLoading ? (
                <span className='StringFilterModal-noResults'>No results</span>
              ) : (
                filterValues.map((value: string) => (
                  <ModalItem
                    fieldType={'STRING'}
                    isDark={true}
                    isSelected={selectedValues.includes(value)}
                    itemValue={value}
                    key={value}
                    onClickFunction={() => {
                      dispatch(toggleFilterValue(value));
                    }}
                  />
                ))
              )}
            </div>
          </>
        )
      ) : null}
      <button
        className={applyFilterClass}
        disabled={
          value === '' &&
          selectedValues.length === 0 &&
          !searchFilters[dropdownOption].includes('EXIST')
        }
        onClick={() => {
          dispatch(
            setFilter({
              field: {
                field_category: selectedFilterField?.field_category,
                field_id: selectedFilterField?.field_id,
                field_name: selectedFilterField?.field_name,
                field_type: selectedFilterField?.field_type,
              },
              negate: !searchFilters[dropdownOption].includes('NOT'),
              operator: searchFilters[dropdownOption],
              values: value === '' ? selectedValues : [value],
            }),
          );

          handleModalVisibility();
          dispatch(getAnswersData());
        }}
      >
        Apply Filter
      </button>
    </div>
  );
};

export default StringFilterModal;
