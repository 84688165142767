import {
  ConversationAggregateMetricsResponse,
  ConversationsResponse,
} from '../apiInterfaces';
import { SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import { handleSerializeConversationQueryArgs } from './utils';
import { SolveWidgetProduct } from 'src/types/types';

const solveConversationsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    getConversationAggregateMetrics: builder.query<
      ConversationAggregateMetricsResponse,
      {
        autonomousAgentUsed: boolean | null;
        conversation_id?: string;
        dropoff_ratings?: string;
        end: string;
        intent_ids?: string;
        product: SolveWidgetProduct;
        relevance_ratings?: string;
        start: string;
      }
    >({
      query: ({
        autonomousAgentUsed,
        conversation_id,
        dropoff_ratings,
        end,
        intent_ids,
        product,
        relevance_ratings,
        start,
      }) => {
        const qParams = new URLSearchParams({
          end,
          product,
          start,
        });
        if (autonomousAgentUsed === true) {
          qParams.set('autonomous_agent_used', autonomousAgentUsed.toString());
        }
        if (conversation_id) {
          qParams.set('conversation_id', conversation_id);
        }
        if (dropoff_ratings) {
          qParams.set('dropoff_ratings', dropoff_ratings);
        }
        if (intent_ids) {
          qParams.set('intent_ids', intent_ids);
        }
        if (relevance_ratings) {
          qParams.set('relevance_ratings', relevance_ratings);
        }

        return (
          SOLVE_V2_BASE_URL +
          `/workflow-builder/intent-conversations/metrics/aggregate?${qParams}`
        );
      },
    }),
    getConversationAggregateMetricsByIntentId: builder.query<
      ConversationAggregateMetricsResponse,
      {
        autonomousAgentUsed: boolean | null;
        end: string;
        intentId: string;
        product: SolveWidgetProduct;
        start: string;
      }
    >({
      query: ({ autonomousAgentUsed, end, intentId, product, start }) => {
        const qParams = new URLSearchParams({
          end,
          product,
          start,
        });
        if (autonomousAgentUsed === true) {
          qParams.set('autonomous_agent_used', autonomousAgentUsed.toString());
        }

        return (
          SOLVE_V2_BASE_URL +
          `/workflow-builder/intent/${intentId}/metrics/aggregate?${qParams}`
        );
      },
    }),
    getConversations: builder.query<
      ConversationsResponse,
      {
        autonomous_agent_used: boolean | null;
        conversation_id: string | null;
        deflected_filters?: string;
        dropoff_ratings?: string;
        end: string;
        intent_ids?: string;
        page: number;
        product: SolveWidgetProduct;
        relevance_ratings?: string;
        sort_column: string;
        sort_direction: string;
        start: string;
      }
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.nextPage = newItems.metadata.links.next !== null;
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.conversations = [...newItems.conversations];
        } else {
          currentCache.conversations = [
            ...currentCache.conversations,
            ...newItems.conversations,
          ];
        }
      },
      query: ({
        autonomous_agent_used,
        conversation_id,
        deflected_filters,
        dropoff_ratings,
        end,
        intent_ids,
        page,
        product,
        relevance_ratings,
        sort_column,
        sort_direction,
        start,
      }) => {
        const qParams = new URLSearchParams({
          end,
          page: page.toString(),
          page_size: '100',
          product,
          sort_column,
          sort_direction,
          start,
        });

        if (autonomous_agent_used === true) {
          qParams.set(
            'autonomous_agent_used',
            autonomous_agent_used.toString(),
          );
        }
        if (conversation_id) {
          qParams.set('conversation_id', conversation_id);
        }

        if (deflected_filters) {
          qParams.set('deflected_filters', deflected_filters);
        }

        if (dropoff_ratings) {
          qParams.set('dropoff_ratings', dropoff_ratings);
        }
        if (intent_ids) {
          qParams.set('intent_ids', intent_ids);
        }
        if (relevance_ratings) {
          qParams.set('relevance_ratings', relevance_ratings);
        }

        return (
          SOLVE_V2_BASE_URL +
          '/workflow-builder/intent-conversations/conversations' +
          '?' +
          qParams
        );
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return handleSerializeConversationQueryArgs(endpointName, queryArgs);
      },
      transformResponse: (response: ConversationsResponse) => {
        response.nextPage = response.metadata.links.next !== null;
        return response;
      },
    }),
    getConversationsByIntentId: builder.query<
      ConversationsResponse,
      {
        autonomous_agent_used: boolean | null;
        conversation_id: string | null;
        end: string;
        intentId: string;
        page: number;
        product: SolveWidgetProduct;
        sort_column: string;
        sort_direction: string;
        start: string;
      }
    >({
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
      merge: (currentCache, newItems) => {
        currentCache.nextPage = newItems.metadata.links.next !== null;
        currentCache.metadata = newItems.metadata;
        if (newItems.metadata.page === 1) {
          currentCache.conversations = [...newItems.conversations];
        } else {
          currentCache.conversations = [
            ...currentCache.conversations,
            ...newItems.conversations,
          ];
        }
      },
      query: ({
        autonomous_agent_used,
        conversation_id,
        end,
        intentId,
        page,
        product,
        sort_column,
        sort_direction,
        start,
      }) => {
        const qParams = new URLSearchParams({
          end,
          page: page.toString(),
          page_size: '100',
          product,
          sort_column,
          sort_direction,
          start,
        });

        if (autonomous_agent_used === true) {
          qParams.set(
            'autonomous_agent_used',
            autonomous_agent_used.toString(),
          );
        }
        if (conversation_id) {
          qParams.set('conversation_id', conversation_id);
        }

        return (
          SOLVE_V2_BASE_URL +
          `/workflow-builder/intent/${intentId}/conversations` +
          '?' +
          qParams
        );
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return handleSerializeConversationQueryArgs(endpointName, queryArgs);
      },
      transformResponse: (response: ConversationsResponse) => {
        response.nextPage = response.metadata.links.next !== null;
        return response;
      },
    }),
  }),
});

export const {
  useGetConversationAggregateMetricsByIntentIdQuery,
  useGetConversationAggregateMetricsQuery,
  useGetConversationsByIntentIdQuery,
  useGetConversationsQuery,
} = solveConversationsApi;
