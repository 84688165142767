import { Box } from '@mui/material';

import { InfoPreviewLog } from './InfoPreviewLog';
import { GreenText, GreyText } from './TextComponents';
import { ArticleSuggestionStepPreviewLogProps } from 'src/slices/workflow-preview/types';
import { confidenceScoreToWord } from 'src/utils/solve/confidenceUtils';

export const ArticleSuggestionStepPreviewLog = ({
  previewLog,
}: {
  previewLog: ArticleSuggestionStepPreviewLogProps;
}) => {
  const {
    articles_predicted: articlesPredicted,
    confidence_scores: confidenceScores,
  } = previewLog;

  if (articlesPredicted.length === 0) {
    return (
      <InfoPreviewLog
        isWarning
        message='Try with a different query or index new articles.'
        title='No articles found'
      />
    );
  }

  return (
    <Box display='flex' flexDirection='column'>
      <span>Article retrieved:</span>
      <span>
        <GreenText>{articlesPredicted[0]}</GreenText>
        <GreyText>
          [Confidence score: {confidenceScoreToWord(confidenceScores[0])}]
        </GreyText>
      </span>
    </Box>
  );
};
