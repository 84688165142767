import { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Tabs } from '@forethought-technologies/forethought-elements';
import { useGetAutomations } from './hooks/useGetAutomations';
import { useGetRecommendations } from './hooks/useGetRecommendations';
import { BannerSection } from './components';
import DiscoverRealizedImpactTab from './discover-realized-impact-tab';
import DiscoverRecommendationTab from './discover-recommendation-tab';
import { a11yTabPanelProps, a11yTabProps } from './helpers';
import KnowledgeGapTab from './knowledge-gap-tab';
import { DISCOVER_SHARED_PARAM_NAMES } from 'src/constants/discover';
import { useEmitTrackingEvent, useStateParams } from 'src/hooks/hooks';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';

const tabs = [
  { Component: DiscoverRecommendationTab, name: 'Recommendation' },
  { Component: KnowledgeGapTab, name: 'Knowledge gap' },
  { Component: DiscoverRealizedImpactTab, name: 'Realized impact' },
] as const;

const DiscoverAutomationPage = () => {
  const { palette } = useTheme();
  const [hasScrolled, setHasScrolled] = useState(false);
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const shouldShowKnowledgeGapTab =
    orgConfigData?.is_content_generation_enabled;
  const trimmedTabs = shouldShowKnowledgeGapTab ? tabs : [tabs[0], tabs[2]];

  const [tabIndex, setTabIndex] = useStateParams({
    deserialize: tabName => trimmedTabs.findIndex(tab => tab.name === tabName),
    initialState: 0,
    paramsName: DISCOVER_SHARED_PARAM_NAMES.TAB,
    serialize: tabIndex => trimmedTabs[tabIndex].name,
    validator: (parameter: string) =>
      Boolean(trimmedTabs.find(tab => tab.name === parameter)),
  });
  useEmitTrackingEvent('discover-automation-page-viewed');

  const { data: automationData, loading: isAutomationsLoading } =
    useGetAutomations();
  const { data: recommendationData, loading: isRecommendationsLoading } =
    useGetRecommendations();

  const automatedSolveWorkflowData = useMemo(
    () =>
      automationData.find(automation => automation.type === 'solve_workflow'),
    [automationData],
  );

  return (
    <Box
      bgcolor={palette.colors.white}
      id='discover-automation-page'
      minHeight='calc(100vh - 69px)' // 69px navbar height
      overflow='hidden'
    >
      <Tabs
        a11yProps={a11yTabProps}
        aria-label='discover automations tabs'
        ContainerProps={{
          borderBottom: '1px solid' + palette.colors.slate[200],
          mb: 3,
          px: 4,
        }}
        onChange={(_, value) => setTabIndex(value)}
        tabs={trimmedTabs.map(({ name }) => name)}
        typographyVariant='font16Bold'
        value={tabIndex}
      />
      <Box px={4}>
        <BannerSection
          automationValue={automatedSolveWorkflowData?.cumulative_value}
          hasScrolled={hasScrolled}
          isAutomationsLoading={isAutomationsLoading}
          isRecommendationsLoading={isRecommendationsLoading}
          recommendationValue={recommendationData?.banner_recommendation_value}
          tab={trimmedTabs[tabIndex].name}
        />

        {trimmedTabs.map(({ Component, name }, index) => (
          <div
            hidden={tabIndex !== index}
            key={name}
            role='tabpanel'
            {...a11yTabPanelProps(index)}
          >
            {tabIndex === index && (
              <Component
                hasScrolled={hasScrolled}
                setHasScrolled={setHasScrolled}
              />
            )}
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default DiscoverAutomationPage;
