import IntentEmailImageBaseComponent from './IntentEmailImageBaseComponent';
import {
  IdleCanvasComponentProps,
  ImageFields,
} from 'src/components/email-builder/types';

const IntentEmailImageIdle = ({ component }: IdleCanvasComponentProps) => {
  const componentFields = component.component_fields as ImageFields;
  const { alignment, background_color, width } = componentFields;
  const imageUrl = component.display_fields?.image_url || '';

  return (
    <IntentEmailImageBaseComponent
      alignment={alignment}
      backgroundColor={background_color}
      imgSrc={imageUrl}
      width={width}
    />
  );
};

export default IntentEmailImageIdle;
