import React, { useMemo } from 'react';
import { NodeProps } from 'reactflow';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import ValidationErrorModal from './validation-error-modal/ValidationErrorModal';
import AlertTooltip from './AlertTooltip';
import NodeContextVariableDisplayer from './NodeContextVariableDisplayer';
import StepIcon from './StepIcon';
import { NodeWrapper } from 'src/components/forethought-flow';

export default function IntentEmailNode({
  children,
  contentRight,
  isVerticalMenuVisible = false,
  nodeName = '',
  nodeType,
  onDelete,
  title,
  ...rest
}: {
  children?: React.ReactNode;
  contentRight?: React.ReactNode;
  deleteDisabledMessage?: string;
  isVerticalMenuVisible?: boolean;
  nodeName?: string;
  nodeType: string;
  onDelete?: () => void;
  title: string;
} & NodeProps) {
  const { step, stepErrors } = rest.data;
  const { input_context_variable_ids, output_context_variable_ids } =
    step || {};

  const isNodeContextVariableDisplayerVisible =
    !!input_context_variable_ids?.length ||
    !!output_context_variable_ids?.length;

  const extraInfo = useMemo(() => {
    if (stepErrors && stepErrors.length > 0) {
      return (
        <AlertTooltip
          tooltipProps={{
            tooltipContent: (
              <ValidationErrorModal stepValidationErrors={stepErrors} />
            ),
          }}
        />
      );
    }

    return contentRight;
  }, [contentRight, stepErrors]);

  return (
    <NodeWrapper
      onDelete={onDelete}
      {...rest}
      isVerticalMenuVisible={isVerticalMenuVisible}
      title={title}
    >
      <Box padding='16px'>
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Box alignItems='center' display='flex' gap='8px'>
            <StepIcon nodeType={nodeType} />
            <Typography variant='font12'>{title}</Typography>
          </Box>
          {extraInfo && <Box display='flex'>{extraInfo}</Box>}
        </Box>
        {children && <Box mt='12px'>{children}</Box>}
        {isNodeContextVariableDisplayerVisible && (
          <Box marginTop='12px'>
            <NodeContextVariableDisplayer
              inputContextVariableIds={input_context_variable_ids}
              nodeName={nodeName}
              nodeType={nodeType}
              outputContextVariableIds={output_context_variable_ids}
            />
          </Box>
        )}
      </Box>
    </NodeWrapper>
  );
}
