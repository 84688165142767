import { sortBreakdownTableData } from './helper';
import { DataDeprecatedState, FetchAction } from './types';
import {
  GET_ANSWERS_CHART_DATA_SUCCESS,
  GET_ANSWERS_TABLE_DATA_SUCCESS,
  GET_DATA_FAILURE,
} from 'src/actions/actionTypes';
import { PageSettingsActions } from 'src/actions/actionTypes';
import { TableDataDict } from 'src/services/apiInterfaces';
import type { RootState } from 'src/store/rootReducer';

export const initialState: DataDeprecatedState = {
  error: '',
  isLoading: false,
};

export default function dataDeprecatedReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === GET_ANSWERS_CHART_DATA_SUCCESS) {
    const chartData = action.payload;

    return { ...state, chartData };
  }

  if (action.type === GET_ANSWERS_TABLE_DATA_SUCCESS) {
    return {
      ...state,
      tableData: action.payload,
      unsortedTableData: action.payload,
    };
  }

  if (action.type === GET_DATA_FAILURE) {
    return {
      ...state,
      chartData: undefined,
      error: action.error?.toString() || 'Error',
      tableData: undefined,
    };
  }

  if (action.type === PageSettingsActions.SET_LOADING) {
    return { ...state, isLoading: action.payload };
  }

  if (action.type === PageSettingsActions.SORT_TABLE) {
    const { field, sortAsc } = action.payload;

    const tableData = { ...state.tableData } as TableDataDict;
    tableData.breakdown = sortBreakdownTableData(
      state.unsortedTableData?.breakdown || [],
      field,
      sortAsc,
    );

    return { ...state, tableData };
  }

  return state;
}

// SELECTORS

export const selectChartData = (
  state: RootState,
): DataDeprecatedState['chartData'] => state.dataDeprecated.chartData;

export const selectTableData = (
  state: RootState,
): DataDeprecatedState['tableData'] => state.dataDeprecated.tableData;

export const selectIsLoading = (
  state: RootState,
): DataDeprecatedState['isLoading'] => state.dataDeprecated.isLoading;

export const selectDataError = (
  state: RootState,
): DataDeprecatedState['error'] => state.dataDeprecated.error;
