import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface PillButtonProps {
  'aria-controls'?: string;
  children: React.ReactNode;
  id?: string;
  isHighlighted?: boolean;
  maxWidth?: string | number;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  variant: 'topic' | 'workflowTag' | 'usage' | 'action';
}

const PillButton = ({
  children,
  isHighlighted = false,
  maxWidth = '200px',
  onClick,
  variant,
  ...a11yProps
}: PillButtonProps) => {
  const { palette } = useTheme();
  const getPrimaryColor = () => {
    if (variant === 'topic') {
      return palette.colors.green;
    }

    if (variant === 'usage') {
      return palette.colors.purple;
    }

    return palette.colors.blue;
  };
  const primaryColor = getPrimaryColor();

  return (
    <Box
      aria-controls={a11yProps['aria-controls'] ?? 'realized-impact-menu'}
      component='button'
      id={a11yProps['id'] ?? 'realized-impact-button'}
      onClick={onClick}
      sx={{
        '&:hover': {
          bgcolor: primaryColor[100],
          color: primaryColor[600],
          textDecoration: 'underline',
        },
        alignItems: 'center',
        bgcolor: isHighlighted ? primaryColor[100] : palette.colors.slate[100],
        borderRadius: '4px',
        color: isHighlighted ? primaryColor[600] : palette.colors.grey[800],
        cursor: 'pointer',
        display: 'flex',
        maxWidth,
        overflow: 'hidden',
        px: 1,
        py: 0.5,
        textDecoration: 'none',
      }}
    >
      {children}
    </Box>
  );
};

export default PillButton;
