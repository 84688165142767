import { styled } from '@mui/material';

import {
  TableAggregateData,
  TableBreakdownData,
} from 'src/components/dashboard-pages/predictions-page/predictionsPage';
import Tooltip from 'src/components/reusable-components/tooltip/tooltip';
import { formatNToPercentage } from 'src/utils/formatToPercentage';

export const HandleAccuracy = ({
  not_computed_count,
  precision,
  predicted_count,
}: TableAggregateData | TableBreakdownData) => {
  /**
   * if not_computed_count is equal or greater
   * to predicted_count then none are computed in a category
   */
  if (not_computed_count < predicted_count) {
    return formatNToPercentage(precision);
  }

  return (
    <TooltipContainer>
      <Tooltip tooltipText='To calculate accuracy, you must close or manually update the predicted value of at least one ticket.' />
      <p>Pending</p>
    </TooltipContainer>
  );
};

const TooltipContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
