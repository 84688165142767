import React, { Dispatch, FC, SetStateAction } from 'react';

import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown/ContextVariableSelectDropdown';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  SectionContainer,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { FieldNameToIdx } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/constants';
import {
  formatContextVariableForDisplay,
  formatContextVariableForPersistence,
} from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import TagInputWithContextVariables from 'src/pages/workflow-builder-edit/tag-input-with-context-variables/TagInputWithContextVariables';
import { ZendeskHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface ChatHandoffProps {
  customizationData: ZendeskHandoffCustomization;
  errorMessages: Array<string | Array<string>>;
  isFieldTouchedArr: Array<boolean | Array<boolean>>;
  isFromCanvas: boolean;
  setCustomizationData: Dispatch<SetStateAction<ZendeskHandoffCustomization>>;
  setIsFieldTouchedArr: (
    isFieldTouchedArr: Array<boolean | Array<boolean>>,
  ) => void;
}

const ChatHandoff: FC<React.PropsWithChildren<ChatHandoffProps>> = ({
  customizationData,
  errorMessages,
  isFieldTouchedArr,
  isFromCanvas,
  setCustomizationData,
  setIsFieldTouchedArr,
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });
  const optionsList =
    customizationData?.agent_chat_handoff_settings?.available_zd_chat_departments?.map(
      department => {
        return {
          category: 'Departments',
          label: department.name,
          value: department.id,
        };
      },
    );

  const handleBlurOnTicketTags = () => {
    const arrCopy = [...isFieldTouchedArr];
    arrCopy[FieldNameToIdx.CHAT_TAGS] = true;
    setIsFieldTouchedArr(arrCopy);
  };

  const handleTagAddition = (tag: string) => {
    setCustomizationData(handoffData => {
      return {
        ...handoffData,
        agent_chat_handoff_settings: {
          ...handoffData.agent_chat_handoff_settings,
          chat_tags: [
            ...(handoffData.agent_chat_handoff_settings?.chat_tags || []),
            formatContextVariableForPersistence(contextVariables, tag),
          ],
        },
      };
    });
  };

  const handleTagDeletion = (idx: number) => {
    setCustomizationData(handoffData => {
      const chatTags = handoffData.agent_chat_handoff_settings?.chat_tags || [];
      return {
        ...handoffData,
        agent_chat_handoff_settings: {
          ...handoffData.agent_chat_handoff_settings,
          chat_tags: chatTags
            .slice(0, idx)
            .concat(chatTags.slice(idx + 1, chatTags.length)),
        },
      };
    });
  };

  const tags = (
    customizationData.agent_chat_handoff_settings?.chat_tags || []
  ).map(tag => formatContextVariableForDisplay(contextVariables, tag));

  const getDepartmentNameFromID = (id: string): string => {
    for (const department of optionsList) {
      if (department.value === id) {
        return department.label;
      }
    }

    return id;
  };

  return (
    <SectionContainer>
      <ContextVariableSelectDropdown
        additionalOptions={optionsList}
        aria-label='Select a department'
        id='zendesk-select-department'
        isClearable
        label='Chat Department'
        onChange={value =>
          setCustomizationData({
            ...customizationData,
            agent_chat_handoff_settings: {
              ...customizationData.agent_chat_handoff_settings,
              zd_chat_department: {
                id: value,
                name: getDepartmentNameFromID(value),
              },
            },
            include_agent_chat_handoff: true,
          })
        }
        shouldProvideCVIdFormatting
        value={
          customizationData?.agent_chat_handoff_settings?.zd_chat_department
            ?.id || ''
        }
      />
      <Spacer height='28px' />
      <TagInputWithContextVariables
        contextVariables={isFromCanvas ? contextVariables : []}
        errorMessages={errorMessages}
        errorTag={FieldNameToIdx.CHAT_TAGS}
        handleBlur={handleBlurOnTicketTags}
        handleTagAddition={handleTagAddition}
        handleTagDeletion={handleTagDeletion}
        label='Chat Tag'
        tags={tags}
      />
    </SectionContainer>
  );
};

export default ChatHandoff;
