import { createAsyncThunk } from '@reduxjs/toolkit';

import { DiscoverActions } from 'src/actions/actionTypes';
import { getDiscoverAutomationsApi } from 'src/services/discover/api';
import {
  DiscoverAutomationsByTypeResponse,
  DiscoverErrorResponse,
} from 'src/types/DiscoverTypes';
import { isTypedError } from 'src/utils/discover/helpers';

export const getDiscoverAutomations = createAsyncThunk<
  DiscoverAutomationsByTypeResponse[],
  void,
  { rejectValue: DiscoverErrorResponse }
>(DiscoverActions.GET_AUTOMATIONS, async (_, { rejectWithValue }) => {
  try {
    return await getDiscoverAutomationsApi();
  } catch (error) {
    if (isTypedError(error)) {
      return rejectWithValue(error);
    }
    throw error;
  }
});
