import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import Spinner from 'src/components/spinner';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { getPermission } from 'src/services/api';
import {
  getSelectedOrganizationForSuperAdmin,
  Permission,
  Routes,
  UserRoles,
} from 'src/utils/enums';

const LandingPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [showSelectAnOrg, setShowSelectAnOrg] = useState(false);
  const userRole = useSelector(selectUserRole);

  useEffect(() => {
    async function redirect(role: UserRoles) {
      const permissions = await getPermission();

      if (
        !getSelectedOrganizationForSuperAdmin() &&
        role === UserRoles.ROLE_SUPER_ADMIN
      ) {
        setShowSelectAnOrg(true);
      } else if (permissions[Permission.DISCOVER]) {
        location.replace(Routes.DISCOVER_DASHBOARD);
      } else if (permissions[Permission.FLAMETHROWER]) {
        location.replace(Routes.WORKFLOW_BUILDER);
      } else if (permissions[Permission.SOLVE_LITE]) {
        location.replace(Routes.SOLVE_LITE);
      } else {
        location.replace(Routes.PREDICTIONS_SETTINGS);
      }
    }

    if (userRole) {
      redirect(userRole);
    }
  }, [userRole]);

  if (showSelectAnOrg) {
    return (
      <CenteredContainer>
        <h2>Select an organization to get started</h2>
      </CenteredContainer>
    );
  }

  return (
    <CenteredContainer>
      <Spinner />
    </CenteredContainer>
  );
};

const CenteredContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LandingPage;
