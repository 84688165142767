import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectSelectedDataSourcePredictedField } from 'src/reducers/triageSettingsReducer/triageSettingsReducer';

export const useGetCurrentDataSourcePredictedField = () => {
  const selectedDataSourcePredictedField = useSelector(
    selectSelectedDataSourcePredictedField,
  );
  const [predictedFieldValue, setPredictedFieldValue] = useState('');

  useEffect(() => {
    if (selectedDataSourcePredictedField?.value) {
      setPredictedFieldValue(selectedDataSourcePredictedField.value);
    }
  }, [selectedDataSourcePredictedField?.value]);

  return {
    predictedFieldValue,
    selectedDataSourcePredictedField,
    setPredictedFieldValue,
  };
};
