import { useMemo, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconDotsVertical } from '@tabler/icons-react';

import {
  Chip,
  formatToDateOnly,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetIntentIdUsages, useIsActionActive } from '../../hooks';
import noSuggestedIntents from 'src/assets/images/no-suggested-intents.svg';
import HighlightedText from 'src/components/dashboard-pages/assist-notes-page/notes-table/HighlightedText';
import { TemplateBadge } from 'src/components/template-badge';
import IntentBadge from 'src/pages/intent-conversation-analytics/IntentBadge';
import regularActionIcon from 'src/pages/workflow-builder-edit/assets/icons/filled-action.svg';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { useAppDispatch } from 'src/store/hooks';
import { Action } from 'src/types/actionBuilderApiTypes';
import {
  onClickTemplateHandlers,
  replaceActionIdInRoute,
} from 'src/utils/actionBuilder/helpers';
import { ActionBuilderTemplate } from 'src/utils/constants';

const templateTypes: Action['action_type'][] = [
  'shopify_order_status_template',
  'snowflake_data_query_template',
];

interface ActionBuilderTableProps {
  dateSortFilter: 'newest' | 'oldest';
  searchText: string;
  setActionIdToDelete: (id: string) => void;
}

const ActionBuilderTable = ({
  dateSortFilter,
  searchText,
  setActionIdToDelete,
}: ActionBuilderTableProps) => {
  const { data, isFetching, isLoading } = useGetActionBuilderActionsQuery({});
  const { actions = [] } = data ?? {};

  const apiActions = useMemo(() => {
    return actions
      .filter(
        ({ action_type: actionType }) =>
          ['api', 'template_api'].includes(actionType) ||
          templateTypes.includes(actionType),
      )
      .filter(({ action_name: actionName }) => {
        const lowerCaseSearchText = searchText.toLowerCase();
        return actionName.toLowerCase().includes(lowerCaseSearchText);
      })
      .sort((a, b) =>
        dateSortFilter === 'newest'
          ? new Date(b.modified_date ?? '').valueOf() -
            new Date(a.modified_date ?? '').valueOf()
          : new Date(a.modified_date ?? '').valueOf() -
            new Date(b.modified_date ?? '').valueOf(),
      );
  }, [actions, searchText, dateSortFilter]);

  if (!apiActions.length && !searchText && !isLoading) {
    return <EmptyState />;
  }

  return (
    <>
      <ColumnLabels />
      {isLoading || isFetching ? (
        <LoadingState />
      ) : (
        apiActions.map(action => (
          <ActionCard
            action={action}
            key={action.action_id}
            searchText={searchText}
            setActionIdToDelete={setActionIdToDelete}
          />
        ))
      )}
    </>
  );
};

interface ActionCardProps {
  action: Action;
  searchText: string;
  setActionIdToDelete: (id: string) => void;
}

const ActionCard = ({
  action,
  searchText,
  setActionIdToDelete,
}: ActionCardProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { palette } = useTheme();
  const {
    action_description: actionDescription,
    action_id: actionId,
    action_name: actionName,
    action_type: actionType,
    modified_date: modifiedDate = '',
  } = action;
  const { isLoading: isLoadingUsages, usages } = useGetIntentIdUsages(actionId);
  const isActive = useIsActionActive(actionId);

  const isTemplateApiAction = actionType === 'template_api';

  return (
    <Box
      aria-label={actionName}
      border={`1px solid ${palette.colors.slate[200]}`}
      borderRadius='8px'
      component='a'
      display='block'
      mb={1.5}
      onClick={() => {
        if (templateTypes.includes(actionType)) {
          dispatch(
            onClickTemplateHandlers[
              actionType as ActionBuilderTemplate['type']
            ](action),
          );

          return;
        }
        navigate(replaceActionIdInRoute(actionId));
      }}
      px={3}
      py={2.5}
      sx={{
        '&:hover': {
          border: `1px solid ${palette.colors.grey[800]}`,
          boxShadow:
            '0px 0px 1px rgba(0, 12, 32, 0.04), 0px 2px 6px rgba(3, 17, 38, 0.107135)',
        },
        cursor: 'pointer',
      }}
    >
      <Grid container spacing={2}>
        <Grid display='flex' item xs={4}>
          <Box alignItems='center' display='flex' mr={2}>
            <ActionBolt />
          </Box>
          <Box>
            <Box>
              <Typography color={palette.text.primary} variant='font14Bold'>
                <HighlightedText searchText={searchText} text={actionName} />
              </Typography>
            </Box>
            <Box>
              <Typography color={palette.colors.grey[700]} variant='font14'>
                {actionDescription || 'Placeholder for action description'}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid display='flex' item xs={4}>
          {isLoadingUsages ? (
            <Skeleton height={40} variant='text' width={100} />
          ) : (
            <Usages isTemplateApiAction={isTemplateApiAction} usages={usages} />
          )}
        </Grid>
        <Grid alignItems='center' display='flex' item xs={2}>
          {!isTemplateApiAction && (
            <Typography color={palette.colors.grey[700]} variant='font14'>
              {formatToDateOnly(modifiedDate)}
            </Typography>
          )}
        </Grid>
        <Grid
          alignItems='center'
          display='flex'
          item
          justifyContent='space-between'
          xs={2}
        >
          {!isTemplateApiAction && (
            <>
              <Chip
                label={isActive ? 'In use' : 'Not used'}
                startAdornment={
                  <Box
                    bgcolor={
                      isActive
                        ? palette.colors.green[500]
                        : palette.colors.grey[400]
                    }
                    borderRadius={100}
                    height='12px'
                    width='12px'
                  />
                }
                sx={{
                  borderColor: palette.colors.slate[200],
                  color: palette.colors.grey[700],
                  fontSize: 12,
                  fontWeight: 500,
                  height: '26px',
                }}
                variant='outlined'
              />
              {isActive ? (
                <Tooltip tooltipContent='In use actions cannot be deleted'>
                  <MenuButton
                    aria-label={`${actionName} menu`}
                    isDisabled
                    onDeleteClick={() => {
                      setActionIdToDelete(actionId);
                    }}
                  />
                </Tooltip>
              ) : (
                <MenuButton
                  aria-label={`${actionName} menu`}
                  onDeleteClick={() => {
                    setActionIdToDelete(actionId);
                  }}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const ActionBolt = () => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.blue[500]}
      borderRadius='16px'
      display='flex'
      padding='6px'
    >
      <img alt='' src={regularActionIcon} />
    </Box>
  );
};

const ColumnLabels = () => {
  const { palette } = useTheme();

  return (
    <Grid color={palette.grey[700]} container mb={3.5} px='26px' spacing={2}>
      <Grid display='flex' item xs={4}>
        <Typography variant='font14Bold'>Name</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='font14Bold'>Workflows</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='font14Bold'>Updated</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='font14Bold'>Status</Typography>
      </Grid>
    </Grid>
  );
};

const MAX_INTENT_WORKFLOWS_SHOWN = 2;

const Usages = ({
  isTemplateApiAction,
  usages,
}: {
  isTemplateApiAction: boolean;
  usages: string[];
}) => {
  const { palette } = useTheme();
  const shouldShowMore = usages.length > MAX_INTENT_WORKFLOWS_SHOWN;

  const UsagesBadges = () => {
    if (isTemplateApiAction) {
      return <TemplateBadge />;
    }

    if (usages.length) {
      return (
        <>
          {usages.slice(0, MAX_INTENT_WORKFLOWS_SHOWN).map(usage => (
            <IntentBadge intentDefinitionId={usage} key={usage} />
          ))}
        </>
      );
    }

    return (
      <Typography
        color={palette.colors.grey[700]}
        noWrap
        variant='font12Medium'
      >
        n/a
      </Typography>
    );
  };

  return (
    <Box alignItems='center' display='flex' maxWidth='400px'>
      <Box display='flex' flexWrap='wrap' gap={1}>
        <UsagesBadges />
        {shouldShowMore && (
          <Box
            alignItems='center'
            bgcolor={palette.colors.slate[100]}
            borderRadius='4px'
            display='flex'
            p='4px 8px'
          >
            <Typography
              color={palette.colors.grey[700]}
              noWrap
              variant='font12Medium'
            >
              + {usages.length - MAX_INTENT_WORKFLOWS_SHOWN} more
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface MenuButtonProps {
  'aria-label': string;
  isDisabled?: boolean;
  onDeleteClick: () => void;
}

const MenuButton = ({
  'aria-label': ariaLabel,
  isDisabled = false,
  onDeleteClick,
}: MenuButtonProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        aria-label={ariaLabel}
        disabled={isDisabled}
        hoverBackgroundColor={palette.colors.purple[200]}
        id='action-builder-menu-button'
        onClick={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
        size='medium'
        variant='ghost'
      >
        <IconDotsVertical color={palette.colors.black} size={17.5} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        id='action-builder-menu'
        MenuListProps={{
          'aria-labelledby': 'action-builder-menu-button',
          role: 'listbox',
          sx: {
            py: 1.5,
          },
        }}
        onClose={e => {
          //Casting is needed as MUI has {} as a type for this event
          const event = e as MouseEvent;
          event?.stopPropagation && event.stopPropagation();
          setAnchorEl(null);
        }}
        open={Boolean(anchorEl)}
        PaperProps={{
          elevation: 1,
          sx: {
            border: `1px solid ${palette.colors.slate[200]}`,
            borderRadius: '4px',
            width: '110px',
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem
          onClick={e => {
            e.stopPropagation();
            onDeleteClick();

            setAnchorEl(null);
          }}
          sx={{
            alignItems: 'center',
            display: 'flex',
            padding: '6px 16px',
          }}
        >
          <Typography variant='font14'>Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const EmptyState = () => {
  const theme = useTheme();
  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      height='50vh'
      justifyContent='center'
      width='100%'
    >
      <Box
        alignItems='center'
        display='flex'
        flexDirection='column'
        gap={1}
        justifyContent='center'
        mt={1}
      >
        <ReactSVG src={noSuggestedIntents} />
        <Typography color={theme.palette.colors.black} variant='font18Bold'>
          No Actions yet
        </Typography>
        <Typography color={theme.palette.colors.grey[700]} variant='font16'>
          Actions will be shown here once you create one
        </Typography>
      </Box>
    </Box>
  );
};

const LoadingState = () => {
  const { palette } = useTheme();

  const loadingItem = (
    <Grid
      color={palette.grey[700]}
      container
      height='90px'
      mb={1.5}
      px='26px'
      spacing={2}
    >
      <Grid item xs={4}>
        <Skeleton height={40} variant='text' width={150} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton height={40} variant='text' width={100} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton height={40} variant='text' width={100} />
      </Grid>
      <Grid item xs={2}>
        <Skeleton height={40} variant='text' width={100} />
      </Grid>
    </Grid>
  );

  return (
    <>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <React.Fragment key={index}>{loadingItem}</React.Fragment>
        ))}
    </>
  );
};

export default ActionBuilderTable;
