import { NodeProps } from 'reactflow';

import { Typography } from '@forethought-technologies/forethought-elements';
import { Link } from '../idle-sidebar-content/ActionList';
import IntentEmailNode from '../IntentEmailNode';
import { Action } from 'src/types/actionBuilderApiTypes';
import { Routes } from 'src/utils/enums';

interface ActionNodeData {
  action: Action;
}

export default function ActionNode(props: NodeProps<ActionNodeData>) {
  const { action } = props.data;

  return (
    <IntentEmailNode
      {...props}
      isVerticalMenuVisible
      nodeName={action?.action_name || ''}
      nodeType='action'
      title='Action'
    >
      <div aria-label={`${action?.action_name || ''} action node`}>
        {action && (
          <Link
            href={Routes.ACTION_BUILDER_EDIT_V2.replace(
              ':actionId?',
              action.action_id,
            )}
            rel='noreferrer'
            target='_blank'
          >
            <Typography variant='font14Bold'>{action.action_name}</Typography>
          </Link>
        )}
      </div>
    </IntentEmailNode>
  );
}
