import React from 'react';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';

import { TicketMenuButtonVariants, TopicMenuButtonVariants } from '../types';

interface StyledMenuProps {
  anchorEl: null | HTMLElement;
  children: React.ReactNode;
  onClose: () => void;
  variant: TopicMenuButtonVariants | TicketMenuButtonVariants;
}

const StyledMenu = ({
  anchorEl,
  children,
  onClose,
  variant,
}: StyledMenuProps) => {
  const { palette } = useTheme();
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={
        variant === 'topic_detail'
          ? {
              horizontal: 'left',
              vertical: 'bottom',
            }
          : { horizontal: 'right', vertical: 'bottom' }
      }
      id='discover-menu'
      MenuListProps={{
        'aria-labelledby': 'discover-menu-button',
        role: 'listbox',
        sx: {
          py: 1.5,
        },
      }}
      onClose={e => {
        //Casting is needed as MUI has {} as a type for this event
        const event = e as MouseEvent;
        event?.stopPropagation && event.stopPropagation();
        onClose();
      }}
      open={Boolean(anchorEl)}
      PaperProps={{
        elevation: 1,
        sx: {
          border: `1px solid ${palette.colors.slate[200]}`,
          borderRadius: '4px',
          width: '350px',
        },
      }}
      transformOrigin={
        variant === 'topic_detail'
          ? {
              horizontal: 'left',
              vertical: 'top',
            }
          : {
              horizontal: 'right',
              vertical: 'top',
            }
      }
    >
      {children}
    </Menu>
  );
};

export default StyledMenu;
