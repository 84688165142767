import React from 'react';
import { styled } from '@mui/material';

import { MetricUnit, SelectedHistogramFilter } from '../types';
import FilterActiveIcon from 'src/assets/images/filter-active-icon.svg';
import FilterInactiveIcon from 'src/assets/images/filter-inactive-icon.svg';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';
import { getAppCuesId } from 'src/utils/appCuesUtil';

interface DiscoverHistogramFilterButtonProps {
  comparisonType: MetricUnit;
  handleOnClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  histogramRangeValue?: number[];
  isFocused: boolean;
  isHistogramFilterOpen: boolean;
  metricType: DiscoverTopicAggregatedMetricType;
  setSelectedHistogramFilter: (value: SelectedHistogramFilter) => void;
}

const DiscoverHistogramFilterButton = ({
  comparisonType,
  handleOnClick,
  histogramRangeValue,
  isFocused,
  isHistogramFilterOpen,
  metricType,
  setSelectedHistogramFilter,
}: DiscoverHistogramFilterButtonProps) => {
  return (
    <HistogramButton
      className='MuiTableSortLabel-icon'
      data-appcues-target={getAppCuesId({
        componentType: 'button',
        featureName: 'filter',
        pageName: 'discover',
        subType: comparisonType,
      })}
      isFilterApplied={Array.isArray(histogramRangeValue)}
      isHistogramFilterOpen={isHistogramFilterOpen}
      isHistogramFocused={isFocused}
      onClick={e => {
        e.stopPropagation();
        handleOnClick(e);
        setSelectedHistogramFilter({
          metricType,
          type: comparisonType,
          value: histogramRangeValue ?? [],
        });
      }}
    >
      <img
        src={
          Array.isArray(histogramRangeValue)
            ? FilterActiveIcon
            : FilterInactiveIcon
        }
        style={{ display: 'flex', padding: 0, width: '9px' }}
      />
    </HistogramButton>
  );
};

const HistogramButton = styled('div')<{
  isFilterApplied: boolean;
  isHistogramFilterOpen: boolean;
  isHistogramFocused: boolean;
}>(({ isFilterApplied, isHistogramFilterOpen, isHistogramFocused, theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette?.colors.purple[200],
    opacity: 1,
  },
  backgroundColor:
    isHistogramFocused && isHistogramFilterOpen
      ? theme.palette.colors.purple[200]
      : theme.palette.colors.white,
  border:
    isHistogramFocused && isHistogramFilterOpen
      ? `2px solid ${theme?.palette.colors.purple[400]}`
      : `2px solid ${theme?.palette.colors.white}`,
  borderRadius: '4px',
  display: 'inline-flex',
  height: '16px',
  opacity: isFilterApplied ? 1 : 0,
  padding: '2px 2px',
  width: '16px',
}));

export default DiscoverHistogramFilterButton;
