import * as Yup from 'yup';

const ticketCreationNotRequiredSchema = Yup.object().shape({
  confirmation_message: Yup.string(),
  handoff_form_question_message: Yup.string(),
  ticket_fields: Yup.array().of(
    Yup.object().shape({
      field_options: Yup.array().of(
        Yup.object().shape({ name: Yup.string(), value: Yup.string() }),
      ),
      id: Yup.string(),
      title: Yup.string(),
      type: Yup.string(),
    }),
  ),
  ticket_title: Yup.string(),
});

const ticketCreationRequiredSchema = ticketCreationNotRequiredSchema.shape({
  confirmation_message: Yup.string().required(
    'Confirmation message cannot be empty',
  ),
  handoff_form_question_message: Yup.string().required(
    'Handoff message cannot be empty',
  ),
});

export const validationSchemaCatchAll = Yup.object().shape({
  custom_message: Yup.string().required(),
});

export const validationSchema = Yup.object().shape({
  agent_chat_handoff_settings: Yup.object().when('include_agent_chat_handoff', {
    is: true,
    otherwise: Yup.object().shape({
      button_id: Yup.string(),
      deployment_id: Yup.string(),
      form_prompt: Yup.string(),
      handoff_success_message: Yup.string(),
      no_agents_available_message: Yup.string(),
      talk_to_agent_button_prompt: Yup.string(),
    }),
    then: Yup.object().shape({
      button_id: Yup.string().required('Button ID cannot be empty'),
      deployment_id: Yup.string().required('Deployment ID cannot be empty'),
      form_prompt: Yup.string().required('Cannot be empty'),
      handoff_success_message: Yup.string().required('Cannot be empty'),
      no_agents_available_message: Yup.string().required('Cannot be empty'),
      talk_to_agent_button_prompt: Yup.string().required('Cannot be empty'),
    }),
  }),
  include_agent_chat_handoff: Yup.boolean(),
  include_ticket_creation_handoff: Yup.boolean(),
  ticket_creation_settings: Yup.object().when(
    'include_ticket_creation_handoff',
    {
      is: true,
      otherwise: Yup.object().shape({
        create_ticket: Yup.boolean(),
        custom_message: Yup.string(),
        ticket_creation_config: ticketCreationNotRequiredSchema,
      }),
      then: Yup.object().shape({
        create_ticket: Yup.boolean(),
        custom_message: Yup.string(),
        ticket_creation_config: Yup.object().when('create_ticket', {
          is: true,
          otherwise: ticketCreationNotRequiredSchema,
          then: ticketCreationRequiredSchema,
        }),
      }),
    },
  ),
});

export const salesforceChatHandoffValidationSchema = Yup.object().shape({
  agent_chat_handoff_settings: Yup.object().shape({
    button_id: Yup.string().required('Button ID cannot be empty'),
    deployment_id: Yup.string().required('Deployment ID cannot be empty'),
  }),
});
