import { CreateTicketFieldMappingRequest } from '../types';
import {
  BooleanOperators,
  CompositeCondition,
  CompositeConditionsConfig,
  Condition,
  ConditionOperators,
} from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { ConditionExpressions } from 'src/services/apiInterfaces';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import {
  ConditionStepFields,
  Step,
  Transition,
} from 'src/types/workflowBuilderAPITypes';
import { ExpressionTypes } from 'src/utils/enums';

export function isValuelessOperator(operator: ConditionOperators | undefined) {
  return operator === 'is empty' || operator === 'is not empty';
}

export function getContextVariableType(
  contextVariableId: string,
  contextVariables: ContextVariable[],
) {
  return contextVariables.find(
    cv => cv.context_variable_id === contextVariableId,
  )?.context_variable_type;
}

export function getDefaultOperatorByType(
  contextVariable: string,
  contextVariables: ContextVariable[],
) {
  const cvType = getContextVariableType(contextVariable, contextVariables);

  if (cvType === 'MULTIOPTIONS' || cvType === 'MULTI_SELECT_LIST') {
    return 'contains one of';
  }

  return 'is';
}

export function getDefaultVaulesByType(
  contextVariable: string,
  contextVariables: ContextVariable[],
) {
  const cvType =
    getContextVariableType(contextVariable, contextVariables) || '';
  const isTextType = cvType !== 'CHECKBOX' && cvType !== 'NUMBER';
  const defaultValueBytype: { [key: string]: boolean | number | string[] } = {
    CHECKBOX: true,
    MULTI_SELECT_LIST: [],
    MULTIOPTIONS: [],
    NUMBER: 0,
  };

  return isTextType ? '' : defaultValueBytype[cvType];
}

function transformConditionToConditionExpression({
  contextVariable,
  operator,
  value,
}: Condition): ConditionExpressions {
  if (operator === 'is empty') {
    return {
      expression_type: 'boolean_aggregator',
      expressions: [
        {
          expression_type: 'filter',
          field: contextVariable,
          negate: true,
          operator: 'exists',
          values: [],
        },
        {
          expression_type: 'filter',
          field: contextVariable,
          negate: false,
          operator: 'empty',
          values: [],
        },
      ],
      negate: false,
      operator: 'or',
    };
  }

  // if the operator is "is not empty", then we want to send the expression
  // CV exists AND CV is not empty
  else if (operator === 'is not empty') {
    return {
      expression_type: 'boolean_aggregator',
      expressions: [
        {
          expression_type: 'filter',
          field: contextVariable,
          negate: false,
          operator: 'exists',
          values: [],
        },
        {
          expression_type: 'filter',
          field: contextVariable,
          negate: true,
          operator: 'empty',
          values: [],
        },
      ],
      negate: false,
      operator: 'and',
    };
  }

  // For multioption type, the value is already an array
  const valueAsArray = Array.isArray(value) ? value : [value];

  return {
    expression_type: 'filter',
    field: contextVariable,

    negate: [
      'is not',
      'does not contain',
      'does not contain one of',
      'is not empty',
    ].includes(operator),

    operator: {
      contains: 'contains',
      'contains one of': 'contains_one_of',
      'does not contain': 'contains',
      'does not contain one of': 'contains_one_of',
      'greater than': '>',
      is: '=',
      'is empty': 'empty',
      'is not': '=',
      'is not empty': 'empty',
      'less than': '<',
    }[operator],

    values: [
      'is',
      'is not',
      'contains',
      'contains one of',
      'does not contain',
      'does not contain one of',
      'greater than',
      'less than',
    ].includes(operator)
      ? valueAsArray
      : [],
  };
}

function transformCompositeConditionToConditionExpression(
  compositeCondition: CompositeCondition,
): ConditionExpressions {
  const conditions = compositeCondition.conditions;
  if (conditions.length == 1) {
    const condition = conditions[0];
    return transformConditionToConditionExpression(condition);
  } else if (conditions.length == 2) {
    const [firstCond, secondCond] = conditions;
    return {
      expression_type: 'boolean_aggregator',
      expressions: [
        transformConditionToConditionExpression(firstCond),
        transformConditionToConditionExpression(secondCond),
      ],
      negate: false,
      operator: compositeCondition.booleanOperator,
    };
  } else {
    const cond = conditions[0];
    return {
      expression_type: 'boolean_aggregator',
      expressions: [
        transformConditionToConditionExpression(cond),
        transformCompositeConditionToConditionExpression({
          ...compositeCondition,
          conditions: conditions.slice(1),
        }),
      ],
      negate: false,
      operator: compositeCondition.booleanOperator,
    };
  }
}

export function getTransitionsFromConfig(
  compositeConditionsConfig: CompositeConditionsConfig,
): Transition[] {
  const transitions: Transition[] =
    compositeConditionsConfig.compositeConditions.map(compositeCondition => {
      return {
        condition_expression:
          transformCompositeConditionToConditionExpression(compositeCondition),
        step_id: compositeCondition.stepId || null,
        transition_id: compositeCondition.transitionId,
      };
    });

  if (compositeConditionsConfig.otherwiseCondition.isOtherwiseSelected) {
    transitions.push({
      condition_expression: null,
      step_id: compositeConditionsConfig.otherwiseCondition.stepId,
      transition_id: compositeConditionsConfig.otherwiseCondition.transitionId,
    });
  }

  return transitions;
}

function getOperator(
  conditionExpression: Transition['condition_expression'],
): ConditionOperators {
  if (!conditionExpression) {
    return 'is';
  }

  if (conditionExpression.operator === '>') {
    return 'greater than';
  }

  if (conditionExpression.operator === '<') {
    return 'less than';
  }

  if (conditionExpression.operator === '=') {
    return conditionExpression.negate ? 'is not' : 'is';
  }

  if (conditionExpression.operator === 'contains') {
    return conditionExpression.negate ? 'does not contain' : 'contains';
  }

  if (conditionExpression.operator === 'contains_one_of') {
    return conditionExpression.negate
      ? 'does not contain one of'
      : 'contains one of';
  }

  if (conditionExpression.operator === 'empty') {
    return conditionExpression.negate ? 'is not empty' : 'is empty';
  }

  return 'is';
}

function getValueFromConditionExpression(
  conditionExpression: ConditionExpressions,
) {
  if (conditionExpression.operator === 'empty') {
    return '';
  }

  if (conditionExpression.operator === 'contains_one_of') {
    return conditionExpression.values as string[];
  }

  return conditionExpression.values?.[0];
}

function transformConditionExpressionToCondition(
  conditionExpression: ConditionExpressions,
  contextVariables: ContextVariable[],
): Condition {
  const contextVariable = conditionExpression?.field || '';

  const defaultValueByType = getDefaultVaulesByType(
    contextVariable,
    contextVariables,
  );

  /*
    If a value doesn't exist, and the operator is not an empty operator, we want to set
    the default value depending on the type of the cv
  */
  const value =
    getValueFromConditionExpression(conditionExpression) ?? defaultValueByType;

  return {
    contextVariable,
    operator: getOperator(conditionExpression),
    value,
  };
}

function transformConditionExpressionToCompositeConditionHelper(
  conditionExpression: ConditionExpressions | null | undefined,
  contextVariables: ContextVariable[],
  result: CompositeCondition,
): void {
  if (!conditionExpression) {
    return;
  }
  if (conditionExpression.expression_type === ExpressionTypes.FILTER) {
    result.conditions.push(
      transformConditionExpressionToCondition(
        conditionExpression,
        contextVariables,
      ),
    );
  } else if (
    conditionExpression.expression_type ===
      ExpressionTypes.BOOLEAN_AGGREGATOR &&
    !!conditionExpression.expressions
  ) {
    /*
      To avoid accessing non-existent context variable in the context variable dict in BE, when the condition is 'is emtpy' or 'is not empty',
      we use a boolean aggregator to first check for the existence of the context variable in the dict. So the condition expression would be
      'cv does not exist OR cv is empty' (is empty condition) and `cv exists AND cv is not empty` (is not empty condition). Hence in this case
      we only process the second expression in condition_expression.expressions, which is `is empty` or `is not empty`.
    */
    if (conditionExpression.expressions[0].operator === 'exists') {
      result.conditions.push(
        transformConditionExpressionToCondition(
          conditionExpression.expressions[1],
          contextVariables,
        ),
      );
    }
    // This is a recursive Boolean Expression. Process the condition expressions recursively
    else {
      result.booleanOperator = conditionExpression.operator as BooleanOperators;
      transformConditionExpressionToCompositeConditionHelper(
        conditionExpression.expressions[0],
        contextVariables,
        result,
      );
      transformConditionExpressionToCompositeConditionHelper(
        conditionExpression.expressions[1],
        contextVariables,
        result,
      );
    }
  }
}

export function transformConditionExpressionToCompositeCondition(
  conditionExpression: ConditionExpressions | null | undefined,
  contextVariables: ContextVariable[],
  stepId: string,
): CompositeCondition {
  const compositeCondition = {
    booleanOperator: undefined,
    conditions: [],
    stepId: stepId,
  };

  transformConditionExpressionToCompositeConditionHelper(
    conditionExpression,
    contextVariables,
    compositeCondition,
  );

  return compositeCondition;
}

export function transformTransitionsToCompositeConditions(
  transitions: Step['transitions'],
  contextVariables: ContextVariable[],
  shouldAddTransitionId?: boolean,
): CompositeCondition[] {
  return transitions.map(transition => {
    const stepId = transition.step_id || '';
    const transitionId = transition.transition_id || '';
    const conditionExpression = transition.condition_expression;
    const compositeCondition = transformConditionExpressionToCompositeCondition(
      conditionExpression,
      contextVariables,
      stepId,
    );

    if (shouldAddTransitionId) {
      return { ...compositeCondition, transitionId };
    } else {
      return compositeCondition;
    }
  });
}

export function getCompositeConditionsConfigFromStep(
  step: Step,
  contextVariables: ContextVariable[],
  isConditionStep: boolean,
  shouldAddTransitionId?: boolean,
): CompositeConditionsConfig {
  const transitions = step.transitions;
  const isOtherwiseSelected =
    transitions.length > 0 &&
    transitions[transitions.length - 1].condition_expression === null;
  const otherwiseTransition = isOtherwiseSelected
    ? transitions[transitions.length - 1]
    : undefined;
  const compositeConditions = transformTransitionsToCompositeConditions(
    isOtherwiseSelected
      ? transitions.slice(0, transitions.length - 1)
      : transitions,
    contextVariables,
    shouldAddTransitionId,
  );

  const baseCompositeConditionConfig: CompositeConditionsConfig = {
    compositeConditions: compositeConditions,
    conditionName: isConditionStep
      ? (step.step_fields as ConditionStepFields).condition_name
      : step.condition_name || '',
    otherwiseCondition: {
      isOtherwiseSelected: isOtherwiseSelected,
      stepId: isOtherwiseSelected
        ? transitions[transitions.length - 1].step_id || null
        : null,
    },
  };

  if (shouldAddTransitionId) {
    return {
      ...baseCompositeConditionConfig,
      otherwiseCondition: {
        ...baseCompositeConditionConfig.otherwiseCondition,
        transitionId: otherwiseTransition?.transition_id || undefined,
      },
    };
  } else {
    return baseCompositeConditionConfig;
  }
}

export const getTriageModelName = (
  contextVarId: string,
  fieldMappings: Array<CreateTicketFieldMappingRequest>,
): string => {
  return (
    fieldMappings.find(
      fieldMapping => fieldMapping.context_variable_id === contextVarId,
    )?.mapping_data?.model_name || ''
  );
};
