import { useState } from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/system';

import {
  NonIdealState,
  SearchBar,
} from '@forethought-technologies/forethought-elements';
import DocumentList from '../intent-email-articles-tab/DocumentList';
import { useDocumentSearch } from '../intent-email-articles-tab/hooks';
import iconBrokenSearch from 'src/assets/images/icon-broken-search.svg';
import Spinner from 'src/components/spinner';
import * as API from 'src/services/workflow-builder/workflowBuilderApi';

export function IntentEmailMacrosTab() {
  const [searchTerm, setSearchTerm] = useState('');
  const { data, error, isLoading, isLoadingNextPage, loadNextPage, refetch } =
    useDocumentSearch(API.getMacrosAPI, (prevData, newData) => {
      return {
        ...newData,
        macros: [...prevData.macros, ...newData.macros],
      };
    });

  return (
    <Container>
      <Box marginBottom='8px'>
        <SearchBar
          onChange={e => {
            const { value } = e.target;
            setSearchTerm(value);
            refetch(value);
          }}
          onClear={() => {
            setSearchTerm('');
            refetch();
          }}
          placeholder='Search macros'
          value={searchTerm}
        />
      </Box>
      <Container>
        {isLoading && <Spinner />}
        {!isLoading && data?.macros.length === 0 && (
          <NonIdealState
            icon={<img alt='' src={iconBrokenSearch} />}
            title='No results found'
          />
        )}
        {!isLoading && !!data?.macros.length && (
          <DocumentList
            componentType='macro'
            disabled={!!error}
            documents={data.macros}
            hasNextPage={!data.is_last_page}
            loading={isLoadingNextPage}
            loadMoreItems={() => {
              loadNextPage(searchTerm, data.page + 1);
            }}
          />
        )}
      </Container>
    </Container>
  );
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  margin-top: 8px;
  width: 100%;
`;
