import * as Yup from 'yup';

export const defaultInitialValues = {
  email: '',
  enabled: true,
  first_name: '',
  hasAnalyticsAccess: false,
  last_name: '',
  permission_level: 'AGENT',
} as const;

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  permission_level: Yup.string().required('Required'),
});
