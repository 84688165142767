import { calculateRangeWithTimestamp } from 'src/utils/calculateTimeframe';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

export const formatNumberWithCommas = (numberToFormat: number) => {
  return new Intl.NumberFormat(undefined, { style: 'decimal' }).format(
    numberToFormat,
  );
};

export const getTimeRangeFromQueryParams = ({
  from,
  to,
}: {
  from: string | null;
  to: string | null;
}) => {
  if (!from || !to) {
    return last30DaysTimeRange;
  }

  return calculateRangeWithTimestamp({
    end_timestamp: parseInt(to, 10),
    start_timestamp: parseInt(from, 10),
  });
};
