import Box from '@mui/material/Box';

import OverflowDropdown from '../common/overflow-dropdown/OverflowDropdown';
import EmptyValue from './EmptyValue';
import {
  InsightRelatedArticle,
  InsightRelatedIntent,
} from 'src/services/insights/types';

type DropdownValueProps = {
  dataType: 'workflows' | 'related_articles';
  value: InsightRelatedArticle[] | InsightRelatedIntent[];
};

const DropdownValue = ({ dataType, value }: DropdownValueProps) => {
  const isWorkflow = dataType === 'workflows';

  if (!value || value.length === 0) {
    return <EmptyValue />;
  }

  return (
    <Box>
      <OverflowDropdown
        data={value}
        menuTitle={`${
          isWorkflow ? 'Workflows' : 'Articles'
        } surfaced in this chat`}
        scope='main'
        tab='topic'
        variant={isWorkflow ? 'workflow' : 'article'}
        width='220px'
      />
    </Box>
  );
};

export default DropdownValue;
