import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import {
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ErrorIcon from '../ErrorIcon';
import { getFieldTypesByHelpdeskAndStage } from '../helpers';
import { QuickstartFieldType } from '../types';
import TriageConfigDetailInputContainer from './TriageConfigDetailInputContainer';
import { AvatarIcon } from './TriageConfigDetailSetting';
import { Helpdesk } from 'src/components/app/types';
import {
  selectFieldPredictorInputOrigins,
  selectIntegrationSelectedState,
  selectPremiumObjectName,
  selectSelectedDataSourcePredictedField,
  selectSelectedPremiumSalesforceDataSourcePredictedFields,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';
import { HelpdeskDetail } from 'src/reducers/triageSettingsReducer/types';

interface TriageConfigDetailSettingReadProps {
  helpdeskDataSourceValue: Helpdesk | '';
  isPremium: boolean;
  selectedHelpdesk?: HelpdeskDetail;
}

const TriageConfigDetailSettingRead = ({
  helpdeskDataSourceValue,
  isPremium,
  selectedHelpdesk,
}: TriageConfigDetailSettingReadProps) => {
  const selectedDataSourcePredictedField = useSelector(
    selectSelectedDataSourcePredictedField,
  );
  const selectedDataSourcePremiumSalesforcePredictedFields = useSelector(
    selectSelectedPremiumSalesforceDataSourcePredictedFields,
  );
  const fieldPredictorOrigins = useSelector(selectFieldPredictorInputOrigins);
  const integrationSelectedState = useSelector(selectIntegrationSelectedState);
  const objectName = useSelector(selectPremiumObjectName);

  const isPremiumSalesforceModel =
    isPremium && helpdeskDataSourceValue === 'salesforce';
  const isIntegrationFieldError =
    integrationSelectedState === 'error' && !isPremiumSalesforceModel;

  const handleRenderPredictedOutputField = () => {
    if (isPremiumSalesforceModel) {
      return (
        <Box alignItems='center' display='flex'>
          <Typography color={theme.palette.colors.black} variant='font14'>
            {selectedDataSourcePremiumSalesforcePredictedFields.map(
              (output, index) =>
                index ===
                selectedDataSourcePremiumSalesforcePredictedFields.length - 1
                  ? output
                  : `${output}, `,
            )}
          </Typography>
        </Box>
      );
    }

    if (selectedDataSourcePredictedField) {
      return (
        <Box alignItems='center' display='flex'>
          <Typography color={theme.palette.colors.black} variant='font14'>
            {selectedDataSourcePredictedField?.label}
          </Typography>
        </Box>
      );
    }
  };

  const fieldTypeFieldMap: Record<QuickstartFieldType, React.ReactNode> = {
    Object: (
      <TriageConfigDetailInputContainer
        key='Object'
        label='Object'
        mb={5.5}
        mt={2}
        view='read'
      >
        <Box alignItems='center' display='flex'>
          <Typography color={theme.palette.colors.black} variant='font14'>
            {objectName}
          </Typography>
        </Box>
      </TriageConfigDetailInputContainer>
    ),
    Origins: (
      <TriageConfigDetailInputContainer
        key='Origins'
        label='Origins'
        mb={5.5}
        mt={2}
        view='read'
      >
        <Box alignItems='center' display='flex'>
          <Typography color={theme.palette.colors.black} variant='font14'>
            {fieldPredictorOrigins?.map((origin, index) =>
              index === fieldPredictorOrigins?.length - 1
                ? origin
                : `${origin}, `,
            )}
          </Typography>
        </Box>
      </TriageConfigDetailInputContainer>
    ),
    'Output field': (
      <TriageConfigDetailInputContainer
        key='Output field'
        label='Output field'
        view='read'
      >
        {isIntegrationFieldError ? (
          <>
            <Tooltip tooltipContent='This field no longer exists in the selected integration. Create or select another field.'>
              <Box alignItems='center' display='flex'>
                <ErrorIcon />
                <Box ml={0.75}>
                  <Typography
                    color={theme.palette.colors.red[500]}
                    variant='font14'
                  >
                    Error
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          </>
        ) : (
          handleRenderPredictedOutputField()
        )}
      </TriageConfigDetailInputContainer>
    ),
  };

  return (
    <>
      <TriageConfigDetailInputContainer
        label='Integration'
        mb={5.5}
        mt={2}
        view='read'
      >
        <Box alignItems='center' display='flex'>
          <AvatarIcon src={selectedHelpdesk?.avatar} />
          <Typography color={theme.palette.colors.black} variant='font14'>
            {selectedHelpdesk?.display_name}
          </Typography>
        </Box>
      </TriageConfigDetailInputContainer>
      {getFieldTypesByHelpdeskAndStage(selectedHelpdesk?.name ?? '').map(
        type => {
          // When origins is not available do not show the component on read
          if (type === 'Origins' && !fieldPredictorOrigins) {
            return null;
          }
          return fieldTypeFieldMap[type];
        },
      )}
    </>
  );
};

export default TriageConfigDetailSettingRead;
