import configureMockStore from 'redux-mock-store';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';

import { initialState as analyticsInitialState } from './reducers/analyticsReducer/analyticsReducer';
import { initialState as breakdownsInitialState } from './reducers/breakdownsReducer/breakdownsReducer';
import { initialState as dataDeprecatedInitialState } from './reducers/dataDeprecatedReducer/dataDeprecatedReducer';
import { initialState as discoverInitialState } from './reducers/discoverReducer/discoverReducer';
import { initialState as filtersInitialState } from './reducers/filtersReducer/filtersReducer';
import { initialState as MacroControlsDatainitialState } from './reducers/macroControlsReducer/index';
import { initialState as pageSettingsInitialState } from './reducers/pageSettingsReducer/pageSettingsReducer';
import { initialState as reportsInitialState } from './reducers/reportsReducer/reportsReducer';
import { initialState as searchInitialState } from './reducers/searchReducer/searchReducer';
import { initialState as solveDataInitialState } from './reducers/solveReducer/index';
import { initialState as SolveWorkflowInitialState } from './reducers/solveWorkflowsReducer';
import { initialState as superAdminInitialState } from './reducers/superAdminReducer/superAdminReducer';
import { initialState as toastNotificationsInitialState } from './reducers/toastNotificationReducer/toastNotificationReducer';
import { initialState as triageSettingsInitialState } from './reducers/triageSettingsReducer/triageSettingsReducer';
import { initialState as userInitialState } from './reducers/userReducer/userReducer';
import { initialState as assistAutomationsInitialState } from './slices/assist-automations/assistAutomationsSlice';
import { initialState as canvasWorkflowBuilderInitialState } from './slices/canvas-workflow-builder/workflowBuilderSlice';
import { initialState as connectorsInitialState } from './slices/connectors/connectorsSlice';
import { initialState as dataInitialState } from './slices/data/dataSlice';
import { initialState as experimentsInitialState } from './slices/experiments/experimentsSlice';
import { initialState as settingsInitialState } from './slices/settings/settingsSliceInit';
import { initialState as TicketFieldMappingInitialState } from './slices/ticket-field-mappings/ticketFieldMappingsSlice';
import { RootState as ApplicationState } from './store/rootReducer';
import { ActionBuilderInitialState } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import { workflowBuilderInitialState } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';

const storeInitialState = {
  actionBuilder: ActionBuilderInitialState,
  analytics: analyticsInitialState,
  assistAutomations: assistAutomationsInitialState,
  breakdowns: breakdownsInitialState,
  canvasWorkflowBuilder: canvasWorkflowBuilderInitialState,
  connectors: connectorsInitialState,
  data: dataInitialState,
  dataDeprecated: dataDeprecatedInitialState,
  discover: discoverInitialState,
  experiments: experimentsInitialState,
  filters: filtersInitialState,
  macroControlsData: MacroControlsDatainitialState,
  pageSettings: pageSettingsInitialState,
  reports: reportsInitialState,
  search: searchInitialState,
  settings: settingsInitialState,
  solveData: solveDataInitialState,
  solveWorkflows: SolveWorkflowInitialState,
  superAdmin: superAdminInitialState,
  ticketFieldMapping: TicketFieldMappingInitialState,
  toastNotification: toastNotificationsInitialState,
  triageSettings: triageSettingsInitialState,
  user: userInitialState,
  workflowBuilder: workflowBuilderInitialState,
};

export type DispatchExts = ThunkDispatch<ApplicationState, void, AnyAction>;

const mockStore = configureMockStore<ApplicationState, DispatchExts>([thunk]);

// @ts-expect-error legacy code with untyped state
const store = mockStore(storeInitialState);

// TODO FIX THIS TO BE THE TYPE FROM STOREINITIALSTATE
export type RootState = any;

export { mockStore, store, storeInitialState };
export default store;
