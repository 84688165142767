import { CreateTicketFieldMappingRequest } from 'src/pages/workflow-builder-edit/types';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { getNonTriageContextVariables } from 'src/utils/getNonTriageConextVariables';

export const filterAndSortContextVariables = (
  contextVariables: ContextVariable[],
  shouldIncludeWorkflowTagContextVariable: boolean,
  shouldIncludeDynamicListContextVariables = true,
  filterFn?: (cv: ContextVariable) => boolean,
) =>
  contextVariables
    .filter(
      cv =>
        shouldIncludeWorkflowTagContextVariable ||
        cv.context_variable_name !== 'Workflow Tag',
    )
    .filter(
      cv =>
        shouldIncludeDynamicListContextVariables ||
        cv.context_variable_type !== 'DYNAMIC_LIST',
    )
    .filter(filterFn || (() => true))
    .sort((a, b) => {
      const textA = a.context_variable_name.toUpperCase();
      const textB = b.context_variable_name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

export const getAllContextVariables = ({
  contextVariablesData,
  shouldIncludeSystemContextVariables,
  shouldIncludeTemplateContextVariables,
  shouldIncludeTriageContextVariables,
  systemContextVariableIdsToInclude,
  templateContextVariables,
  ticketFieldMappings,
  usableSystemContextVariables,
}: {
  contextVariablesData: ContextVariable[];
  shouldIncludeSystemContextVariables: boolean;
  shouldIncludeTemplateContextVariables: boolean;
  shouldIncludeTriageContextVariables: boolean;
  systemContextVariableIdsToInclude?: string[];
  templateContextVariables: ContextVariable[];
  ticketFieldMappings: CreateTicketFieldMappingRequest[];
  usableSystemContextVariables: ContextVariable[];
}) => {
  let contextVariables = [...contextVariablesData];

  if (shouldIncludeSystemContextVariables) {
    contextVariables = [...contextVariables, ...usableSystemContextVariables];
  } else if (systemContextVariableIdsToInclude?.length) {
    const filteredcontextVariables = usableSystemContextVariables.filter(cv =>
      systemContextVariableIdsToInclude.includes(cv.context_variable_id),
    );

    contextVariables = [...contextVariables, ...filteredcontextVariables];
  }

  if (shouldIncludeTemplateContextVariables) {
    contextVariables = contextVariables.concat(templateContextVariables);
  }
  return shouldIncludeTriageContextVariables
    ? [...contextVariables]
    : getNonTriageContextVariables(ticketFieldMappings, contextVariables);
};
