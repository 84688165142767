import Box from '@mui/material/Box';

import {
  AutomatedButton,
  AutomationCallToActionButton,
} from '../components/AutomatedCallToActionRenderer';
import DiscoverMenuButton from 'src/components/discover-menu-button/DiscoverMenuButton';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';

type CallToActionButtonProps = {
  topic: DiscoverTopic | null;
  trackingEventMetadata: Record<string, string | string[]>;
};

const CallToActionButton = (props: CallToActionButtonProps) => {
  const { topic, trackingEventMetadata } = props;
  if (!topic) {
    return null;
  }
  const showAutomatedButton = topic?.is_automated;
  const showAutomateButton =
    !showAutomatedButton && Boolean(topic?.summaries?.length);

  return (
    <Box alignItems='center' display='flex'>
      {showAutomatedButton && (
        <AutomatedButton
          topic={topic}
          trackingEventMetadata={trackingEventMetadata}
        />
      )}
      {showAutomateButton && (
        <AutomationCallToActionButton
          topic={topic}
          trackingEventMetadata={trackingEventMetadata}
        />
      )}
      <DiscoverMenuButton topic={topic} variant='all_topics' />
    </Box>
  );
};

export default CallToActionButton;
