import './LandingPageLayout.scss';

import React, { useEffect } from 'react';

import LandingPageImage from 'src/components/authentication-wall/landingPageImage/landingPageImage';
import Footer from 'src/components/footer';
import Banner from 'src/components/reusable-components/banner';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { breakpoints } from 'src/styles/breakpoints';

interface LandingPageLayoutProps {
  children: React.ReactNode;
  errorMessage?: string;
  handleBannerClose?: () => void;
  testId?: string;
}

const LandingPageLayout: React.FC<
  React.PropsWithChildren<LandingPageLayoutProps>
> = ({ children, errorMessage, handleBannerClose, testId }) => {
  //Remove NPS Survey
  useEffect(() => {
    const surveyContainer = document.getElementById('npsIframeContainer');
    if (surveyContainer) {
      surveyContainer.style.display = 'none';
    }
  }, []);

  const isDesktop = useMediaQuery(breakpoints.desktop);

  return (
    <div className='LandingPageLayout' data-testid={testId}>
      <div className='LandingPageLayout-pageContainer'>
        <div className='LandingPageLayout-contentContainer'>
          <div className='LandingPageLayout-contentContainer-inner'>
            {children}
          </div>
          <Footer hasUserLoggedIn={false} />
        </div>
        {isDesktop && <LandingPageImage />}
      </div>
      {errorMessage && (
        <div className='LandingPageLayout-bannerContainer'>
          <div className='LandingPageLayout-bannerContainer-inner'>
            <Banner handleClose={handleBannerClose} message={errorMessage} />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPageLayout;
