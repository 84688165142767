import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { IconVersions } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  Toggle,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { TOAST_TIMEOUT } from '../../triage-config-detail-page/helpers';
import {
  CANT_PUBLISH_ERROR,
  CANT_TEST_ERROR,
  DUPLICATE_ACTION_TEXT,
} from '../constants';
import DuplicateVersionDialog from '../drawers-and-dialogs/DuplicateVersionDialog';
import TestModelPanel from '../drawers-and-dialogs/TestModelPanel';
import { useTestDrawer } from '../hooks/useTestDrawer';
import { useModelValidityToast } from 'src/components/triage-label-mapping-drawer/hooks';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import {
  useGetModelVersionsQuery,
  usePublishVersionMutation,
} from 'src/services/triage/triageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { TRIAGE_LLM_TRACKING_EVENTS } from 'src/utils/constants';
import { Routes } from 'src/utils/enums';

interface ModelsHeaderControlProps {
  model: VersionedTriageModel;
  onShowLabelMapping: () => void;
}

const ModelsHeaderControl = ({
  model,
  onShowLabelMapping,
}: ModelsHeaderControlProps) => {
  const emitTrackingEventCallback = useSelfServeEvents({
    model,
  });
  const [duplicateIsOpen, setDuplicateIsOpen] = useState(false);
  const { isTestDrawerOpen, setIsTestDrawerOpen, testDisabled } =
    useTestDrawer(model);
  const [mutate, { isError, isLoading, isSuccess, originalArgs }] =
    usePublishVersionMutation();
  const { data: modelVersions, refetch } = useGetModelVersionsQuery(
    model.model_id,
  );
  const { checkValidity } = useModelValidityToast(model, (isValid, publish) => {
    if (isValid) {
      const eventName = Boolean(publish)
        ? TRIAGE_LLM_TRACKING_EVENTS.PUBLISH_MODEL_SUCCESS
        : TRIAGE_LLM_TRACKING_EVENTS.UNPUBLISH_MODEL_SUCCESS;
      emitTrackingEventCallback(eventName);

      mutate({
        modelId: model.model_id,
        publish: Boolean(publish),
        versionId: model.version_id,
      });
    } else {
      onShowLabelMapping();
    }
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      const publishType = originalArgs?.publish ? 'published' : 'unpublished';
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: TOAST_TIMEOUT,
          title: `Model successfully ${publishType}`,
          variant: 'main',
        }),
      );
    }
  }, [isSuccess, dispatch, originalArgs]);

  useEffect(() => {
    if (isError) {
      const publishType = originalArgs?.publish ? 'published' : 'unpublished';
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: TOAST_TIMEOUT,
          title: `Failed to ${publishType} this model`,
          variant: 'danger',
        }),
      );
    }
  }, [isError, dispatch, originalArgs]);

  const gotoVersions = () => {
    emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.VIEW_VERSIONS_CLICKED);
    navigate(
      `${Routes.PREDICTIONS_SETTINGS}/version-control/${model.model_id}?version=${model.version_id}`,
    );
  };

  const handlePublish = (publish: boolean) => {
    checkValidity(publish);
  };

  const handleDuplicateSuccess = (duplicateResponse: VersionedTriageModel) => {
    refetch();
    emitTrackingEventCallback(
      TRIAGE_LLM_TRACKING_EVENTS.DUPLICATE_MODEL_SUCCESS,
    );
    navigate(
      `${Routes.PREDICTIONS_SETTINGS}/version-control/${duplicateResponse.model_id}?version=${duplicateResponse.version_id}`,
    );
  };
  const handleOpenDuplicate = () => {
    setDuplicateIsOpen(true);
    emitTrackingEventCallback(
      TRIAGE_LLM_TRACKING_EVENTS.DUPLICATE_MODEL_CLICKED,
    );
  };
  const handleOpenTest = () => {
    setIsTestDrawerOpen(true);
    emitTrackingEventCallback(TRIAGE_LLM_TRACKING_EVENTS.TEST_MODEL_OPENED);
  };
  const hasOutputField = model.model.outputs.length > 0;
  const showToggle = model.is_published || model.previously_published;
  const publishDisabled =
    model.labels.length < 2 || isLoading || !hasOutputField;
  const showPublish = !model.is_published;
  const isVersionsDisabled = modelVersions && modelVersions?.length <= 1;
  return (
    <>
      <Box alignItems='center' columnGap={1} display='flex'>
        {showToggle && (
          <Toggle
            checked={model.is_published}
            disabled={isLoading}
            onChange={e => handlePublish(e.target.checked)}
          />
        )}
        <Tooltip
          tooltipContent={
            isVersionsDisabled
              ? 'This model only has one version'
              : 'All Versions'
          }
        >
          <IconButton
            aria-label='versions'
            disabled={isVersionsDisabled}
            onClick={gotoVersions}
            variant='ghost'
          >
            <IconVersions />
          </IconButton>
        </Tooltip>
        <Tooltip tooltipContent={testDisabled && CANT_TEST_ERROR}>
          <Button
            disabled={testDisabled}
            onClick={handleOpenTest}
            variant='secondary'
          >
            Test
          </Button>
        </Tooltip>
        <Button onClick={handleOpenDuplicate} variant='secondary'>
          {DUPLICATE_ACTION_TEXT}
        </Button>
        {showPublish && (
          <Tooltip tooltipContent={publishDisabled && CANT_PUBLISH_ERROR}>
            <Button
              disabled={publishDisabled}
              isLoading={isLoading}
              onClick={() => handlePublish(true)}
              variant='main'
            >
              Publish
            </Button>
          </Tooltip>
        )}
      </Box>
      <TestModelPanel
        isOpen={isTestDrawerOpen}
        model={model}
        setIsOpen={setIsTestDrawerOpen}
      />
      <DuplicateVersionDialog
        currentVersionName={model.version_name}
        isOpen={duplicateIsOpen}
        modelId={model.model_id}
        onClose={() => setDuplicateIsOpen(false)}
        onSuccess={handleDuplicateSuccess}
        versionId={model.version_id}
      />
    </>
  );
};

export default ModelsHeaderControl;
