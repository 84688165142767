import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Badge,
  Checkbox,
  TextField,
  Toggle,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { csatOneToFiveOptions, csatOneToTwoOptions } from '../constants';
import {
  useDebouncedSubmitConfigForNonRTE,
  useDebouncedSubmitConfigForRTE,
} from '../hooks/useDebouncedSubmitConfig';
import { RTEContainer } from '../styledComponents';
import { LabelsForChosenStyle } from './LabelsForChosenStyle';
import { ReroutingIntentSelector } from './ReroutingIntentSelector';
import { SelectableOptions } from './SelectableOptions';
import { TextFieldWithMessage } from './TextFieldWithMessage';
import set from 'lodash/fp/set';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import { EditorRef } from 'src/components/rich-text-editor/types';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectWidgetConfiguration,
  setCsatConfigByKey,
} from 'src/slices/solve-config/solveConfigSlice';
import { CsatConfig, WidgetConfiguration } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

const CsatTabConfiguration = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isCsatConfigEnabled = featureFlags.includes('csat_config_revamp');
  const isCsatTriggerPointEnabled = featureFlags.includes('csat_trigger_point');
  const isNegativeRatingRoutingEnabled = featureFlags.includes(
    'csat_negative_rating_routing',
  );
  const isCsatRatingBasedSubmitMessageEnabled = featureFlags.includes(
    'csat_rating_based_submit_message',
  );
  const isZopimOneChatEnabled = featureFlags.includes('zendesk_zopim_one_chat');
  const isSuncoOneChatEnabled = featureFlags.includes('sunco_live_chat');
  const isFreshchatOneChatEnabled = featureFlags.includes('freshchat_one_chat');

  const debouncedSubmitConfigForRTE = useDebouncedSubmitConfigForRTE();
  const debouncedSubmitConfigForNonRTE = useDebouncedSubmitConfigForNonRTE();
  const editorRefForHighRating = useRef<EditorRef>(null);
  const editorRefForLowRating = useRef<EditorRef>(null);

  if (!isCsatConfigEnabled) {
    return null;
  }

  const {
    negative_rating_routing_intent: negativeRatingRoutingIntent,
    no_resolve_option: noResolveOption,
    question_for_additional_feedback: questionForAdditionalFeedback,
    question_for_high_ratings: questionForHighRatings,
    question_for_low_ratings: questionForLowRatings,
    question_for_resolution_confirmation: questionForResolutionConfirmation,
    rating_question: ratingQuestion,
    reasons_for_high_ratings: reasonsForHightRatings,
    reasons_for_low_ratings: reasonsForLowRatings,
    scale,
    should_request_additional_feedback: shouldRequestAdditionalFeedback,
    should_request_feedback_for_high_ratings:
      shouldRequestFeedbackForHighRatings,
    should_request_feedback_for_low_ratings: shouldRequestFeedbackForLowRatings,
    should_request_resolution_confirmation: shouldRequestResolutionConfirmation,
    should_use_rating_based_submit_message: shouldUseRatingBasedSubmitMessage,
    show_on_header: showOnHeader,
    show_when_agent_chat_ends: showWhenAgentChatEnds,
    show_when_widget_minimizes: showWhenWidgetMinimizes,
    style,
    submit_confirmation_message: submitConfirmationMessage,
    submit_message_for_high_ratings: submitMessageForHighRatings,
    submit_message_for_low_ratings: submitMessageForLowRatings,
    yes_resolve_option: yesResolveOption,
  } = widgetConfiguration.csat_config;

  const csatStyleOptions =
    scale === 'one_to_five' ? csatOneToFiveOptions : csatOneToTwoOptions;
  const chosenStyleOptions = csatStyleOptions.find(
    option => option.value === style,
  );

  // handlers
  const handleUpdateField = ({
    isGenericField,
    key,
    val,
  }: {
    isGenericField?: boolean;
    key: keyof CsatConfig;
    val: string | boolean | string[] | undefined;
  }) => {
    const updatedConfig: WidgetConfiguration = set(
      ['csat_config', key],
      val,
      widgetConfiguration,
    );
    // if we're changing scale, should also update the style to the default style under
    // that scale
    const updatedStyleConfig =
      key === 'scale' && val !== widgetConfiguration.csat_config.scale
        ? set(
            ['csat_config', 'style'],
            val === 'one_to_five' ? 'star' : 'thumbs',
            updatedConfig,
          )
        : updatedConfig;

    dispatch(
      setCsatConfigByKey({
        isGenericField: isGenericField,
        key: key,
        value: val,
      }),
    );

    if (isGenericField !== undefined) {
      debouncedSubmitConfigForNonRTE(
        updatedStyleConfig,
        `csat config - ${key}`,
        (val || '').toString(),
      );
    } else {
      debouncedSubmitConfigForRTE(
        updatedStyleConfig,
        `csat config - ${key}`,
        (val || '').toString(),
      );
    }
  };

  const handleUpdateSelectableOption = (
    key:
      | 'reasons_for_high_ratings'
      | 'reasons_for_low_ratings'
      | 'options_for_resolution_confirmation',
    index: number,
    val: string | boolean,
  ) => {
    const updatedOptions = set(
      [index],
      val,
      widgetConfiguration.csat_config[key],
    );
    const updatedConfig: WidgetConfiguration = set(
      ['csat_config', key],
      updatedOptions,
      widgetConfiguration,
    );
    dispatch(
      setCsatConfigByKey({
        key: key,
        value: updatedOptions,
      }),
    );
    debouncedSubmitConfigForRTE(
      updatedConfig,
      `csat config - ${key}`,
      val.toString(),
    );
  };

  const handleAddSelectableOptions = (
    key:
      | 'reasons_for_high_ratings'
      | 'reasons_for_low_ratings'
      | 'options_for_resolution_confirmation',
  ) => {
    const updatedOptions = [...widgetConfiguration.csat_config[key], ''];
    const updatedConfig: WidgetConfiguration = set(
      ['csat_config', key],
      updatedOptions,
      widgetConfiguration,
    );
    dispatch(
      setCsatConfigByKey({
        key: key,
        value: updatedOptions,
      }),
    );
    debouncedSubmitConfigForRTE(
      updatedConfig,
      `csat config - ${key}`,
      'added option',
    );
  };

  const handleRemoveSelectableOptions = (
    key:
      | 'reasons_for_high_ratings'
      | 'reasons_for_low_ratings'
      | 'options_for_resolution_confirmation',
    index: number,
  ) => {
    const updatedOptions = widgetConfiguration.csat_config[key]
      .slice(0, index)
      .concat(widgetConfiguration.csat_config[key].slice(index + 1));
    const updatedConfig: WidgetConfiguration = set(
      ['csat_config', key],
      updatedOptions,
      widgetConfiguration,
    );
    dispatch(
      setCsatConfigByKey({
        key: key,
        value: updatedOptions,
      }),
    );
    debouncedSubmitConfigForRTE(
      updatedConfig,
      `csat config - ${key}`,
      'removed option',
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Section>
        <Typography variant='font16Bold'>CSAT availability</Typography>
        <Box mt={-1}>
          <Box alignItems='center' display='flex'>
            <Checkbox
              checked={showWhenWidgetMinimizes}
              label='When the user minimizes the widget '
              onChange={e => {
                handleUpdateField({
                  isGenericField: true,
                  key: 'show_when_widget_minimizes',
                  val: e.target.checked,
                });
              }}
            />
            <Box height='20px' ml='-14px'>
              <Tooltip tooltipContent='User must enter a free form query or click a button before minimizing for CSAT to be prompted.'>
                <IconInfoCircle size={20} />
              </Tooltip>
            </Box>
          </Box>
          <Box alignItems='center' display='flex'>
            <Checkbox
              checked={showOnHeader}
              label='Anytime the user accesses it via the widget header'
              onChange={e => {
                handleUpdateField({
                  isGenericField: true,
                  key: 'show_on_header',
                  val: e.target.checked,
                });
              }}
            />
            <Box height='20px' ml='-14px'>
              <Tooltip tooltipContent='CSAT is accessible and updatable anytime via the widget header, with one survey counted per session.'>
                <IconInfoCircle size={20} />
              </Tooltip>
            </Box>
          </Box>
          {(isZopimOneChatEnabled ||
            isSuncoOneChatEnabled ||
            isFreshchatOneChatEnabled) && (
            <Box alignItems='center' display='flex'>
              <Checkbox
                checked={showWhenAgentChatEnds}
                label='When the live chat ends'
                onChange={e => {
                  handleUpdateField({
                    isGenericField: true,
                    key: 'show_when_agent_chat_ends',
                    val: e.target.checked,
                  });
                }}
              />
              <Box height='20px' ml='-14px'>
                <Tooltip tooltipContent='Only applicable when widget and live agent conversations are integrated in one chat window. '>
                  <IconInfoCircle size={20} />
                </Tooltip>
              </Box>
            </Box>
          )}
          {isCsatTriggerPointEnabled && (
            <Box alignItems='center' display='flex'>
              <Checkbox
                checked={true}
                disabled={true}
                label='When CSAT Trigger is applied in the Workflow'
              />
              <Box height='20px' ml='-14px'>
                <Tooltip
                  tooltipContent='The trigger point is always on.
                CSAT prompts are only sent when the CSAT component is dragged onto Workflows.
                Trigger points remain active regardless of checkbox settings.'
                >
                  <IconInfoCircle size={20} />
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>
      </Section>
      {(showWhenWidgetMinimizes ||
        showOnHeader ||
        isCsatTriggerPointEnabled) && (
        <>
          <Separator />
          <Section>
            <Typography variant='font16Bold'> Rating question</Typography>
            <TextFieldWithMessage
              ariaLabel='rating questions'
              footer='Keep the question under 60 characters'
              onChange={e => {
                handleUpdateField({
                  key: 'rating_question',
                  val: e.target.value,
                });
              }}
              placeholder=''
              value={ratingQuestion}
            />
            <Box display='flex' flexDirection='column' gap='8px'>
              <Typography variant='font14Bold'>Style</Typography>
              <Box display='flex' gap='16px'>
                {csatStyleOptions.map(styleOption => {
                  const selected = styleOption.value === style;
                  return (
                    <StyleCard
                      key={styleOption.value}
                      onClick={() => {
                        handleUpdateField({
                          isGenericField: true,
                          key: 'style',
                          val: styleOption.value,
                        });
                      }}
                      selected={selected}
                    >
                      <StyleCardImg src={styleOption.icon} />
                      <Typography
                        color={
                          selected
                            ? theme.palette.colors.black
                            : theme.palette.colors.grey[400]
                        }
                        variant='font11'
                      >
                        {styleOption.label}
                      </Typography>
                    </StyleCard>
                  );
                })}
              </Box>
              <LabelsForChosenStyle
                chosenStyleOptions={chosenStyleOptions}
                handleUpdateField={handleUpdateField}
              />
            </Box>
          </Section>

          <Separator />
          <Section>
            <Typography variant='font16Bold'>Reasons for rating</Typography>
            <Box>
              <Checkbox
                checked={shouldRequestFeedbackForLowRatings}
                label={
                  <Box alignItems='center' display='flex' gap='8px'>
                    <Typography variant='font14'>
                      Request feedback for low ratings{' '}
                      {scale === 'one_to_five' && '(1-3)'}
                    </Typography>
                    <Box display='flex' gap='4px'>
                      <Badge label='Negative' variant='error' />
                      <Badge label='Neutral' variant='macro' />
                    </Box>
                  </Box>
                }
                onChange={e => {
                  handleUpdateField({
                    isGenericField: true,
                    key: 'should_request_feedback_for_low_ratings',
                    val: e.target.checked,
                  });
                }}
              />
              <Checkbox
                checked={shouldRequestFeedbackForHighRatings}
                label={
                  <Box alignItems='center' display='flex' gap='8px'>
                    <Typography variant='font14'>
                      Request feedback for high ratings{' '}
                      {scale === 'one_to_five' && '(4-5)'}
                    </Typography>
                    <Badge label='Positive' variant='success' />
                  </Box>
                }
                onChange={e => {
                  handleUpdateField({
                    isGenericField: true,
                    key: 'should_request_feedback_for_high_ratings',
                    val: e.target.checked,
                  });
                }}
              />
            </Box>
          </Section>
          {shouldRequestFeedbackForLowRatings && (
            <>
              <Section>
                <TextFieldWithMessage
                  ariaLabel='question for low ratings'
                  footer='Keep the question under 60 characters'
                  message={
                    <Box alignItems='center' display='flex' gap='8px' mb='4px'>
                      <Typography variant='font14'>
                        Question for low ratings
                      </Typography>
                      <Badge label='Negative' variant='error' />
                    </Box>
                  }
                  onChange={e => {
                    handleUpdateField({
                      key: 'question_for_low_ratings',
                      val: e.target.value,
                    });
                  }}
                  placeholder=''
                  value={questionForLowRatings}
                />
                <SelectableOptions
                  message='Selectable reasons'
                  onAddOption={() => {
                    handleAddSelectableOptions('reasons_for_low_ratings');
                  }}
                  onEditOption={(index, newValue) => {
                    handleUpdateSelectableOption(
                      'reasons_for_low_ratings',
                      index,
                      newValue,
                    );
                  }}
                  onRemoveOption={index => {
                    handleRemoveSelectableOptions(
                      'reasons_for_low_ratings',
                      index,
                    );
                  }}
                  options={reasonsForLowRatings}
                />
              </Section>
              {shouldRequestFeedbackForHighRatings && <Separator />}
            </>
          )}
          {shouldRequestFeedbackForHighRatings && (
            <Section>
              <TextFieldWithMessage
                ariaLabel='question for high ratings'
                footer='Keep the question under 60 characters'
                message={
                  <Box alignItems='center' display='flex' gap='8px' mb='4px'>
                    <Typography variant='font14'>
                      Question for high ratings
                    </Typography>
                    <Badge label='Positive' variant='success' />
                  </Box>
                }
                onChange={e => {
                  handleUpdateField({
                    key: 'question_for_high_ratings',
                    val: e.target.value,
                  });
                }}
                placeholder=''
                value={questionForHighRatings}
              />
              <SelectableOptions
                message='Selectable reasons'
                onAddOption={() => {
                  handleAddSelectableOptions('reasons_for_high_ratings');
                }}
                onEditOption={(index, newValue) => {
                  handleUpdateSelectableOption(
                    'reasons_for_high_ratings',
                    index,
                    newValue,
                  );
                }}
                onRemoveOption={index => {
                  handleRemoveSelectableOptions(
                    'reasons_for_high_ratings',
                    index,
                  );
                }}
                options={reasonsForHightRatings}
              />
            </Section>
          )}
          <Separator />
          {isNegativeRatingRoutingEnabled && (
            <>
              <Section>
                <Typography variant='font16Bold'>
                  Route based on feedback
                </Typography>
                <ReroutingIntentSelector
                  defaultLabel='None (no rerouting)'
                  negativeRatingRoutingIntent={negativeRatingRoutingIntent}
                  setNegativeRatingRoutingIntent={value =>
                    handleUpdateField({
                      key: 'negative_rating_routing_intent',
                      val: value,
                    })
                  }
                />
              </Section>
              <Separator />
            </>
          )}
          <Section>
            <Typography variant='font16Bold'>
              Resolution confirmation
            </Typography>
            <Checkbox
              checked={shouldRequestResolutionConfirmation}
              label='Request to confirm the resolution of the issue'
              onChange={e => {
                handleUpdateField({
                  isGenericField: true,
                  key: 'should_request_resolution_confirmation',
                  val: e.target.checked,
                });
              }}
            />
            {shouldRequestResolutionConfirmation && (
              <>
                <TextFieldWithMessage
                  ariaLabel='question for resolution confirmation'
                  footer='Keep the question under 60 characters'
                  message='Question to confirm'
                  onChange={e => {
                    handleUpdateField({
                      key: 'question_for_resolution_confirmation',
                      val: e.target.value,
                    });
                  }}
                  placeholder=''
                  value={questionForResolutionConfirmation}
                />
                <Box alignItems='center' display='flex' gap='16px'>
                  <Typography variant='font14Bold'>Yes</Typography>
                  <TextField
                    aria-label='Yes resolved option'
                    onChange={e =>
                      handleUpdateField({
                        key: 'yes_resolve_option',
                        val: e.target.value,
                      })
                    }
                    value={yesResolveOption}
                  />
                </Box>
                <Box alignItems='center' display='flex' gap='16px'>
                  <Typography variant='font14Bold'>No</Typography>
                  <TextField
                    aria-label='No resolved option'
                    onChange={e =>
                      handleUpdateField({
                        key: 'no_resolve_option',
                        val: e.target.value,
                      })
                    }
                    value={noResolveOption}
                  />
                </Box>
              </>
            )}
          </Section>

          <Separator />
          <Section>
            <Typography variant='font16Bold'>Additional feedback</Typography>
            <Checkbox
              checked={shouldRequestAdditionalFeedback}
              label='Request open-ended feedback in free-form text'
              onChange={e => {
                handleUpdateField({
                  isGenericField: true,
                  key: 'should_request_additional_feedback',
                  val: e.target.checked,
                });
              }}
            />
            {shouldRequestAdditionalFeedback && (
              <TextFieldWithMessage
                ariaLabel='question for additional feedback'
                footer='Keep the question under 60 characters'
                message='Open-ended question'
                onChange={e => {
                  handleUpdateField({
                    key: 'question_for_additional_feedback',
                    val: e.target.value,
                  });
                }}
                placeholder=''
                value={questionForAdditionalFeedback}
              />
            )}
          </Section>

          <Separator />
          <Section>
            <Typography variant='font16Bold'>Submit Confirmation</Typography>
            {isCsatRatingBasedSubmitMessageEnabled && (
              <Toggle
                checked={shouldUseRatingBasedSubmitMessage}
                label='Rating-based confirmation message'
                onChange={e => {
                  handleUpdateField({
                    key: 'should_use_rating_based_submit_message',
                    val: e.target.checked,
                  });
                }}
              />
            )}
            {isCsatRatingBasedSubmitMessageEnabled &&
            shouldUseRatingBasedSubmitMessage ? (
              <>
                <Box display='flex' flexDirection='column' gap='8px'>
                  <Box alignItems='center' display='flex' gap='8px'>
                    <Typography variant='font14'>
                      Confirmation for low ratings (1-3)
                    </Typography>
                    <Box display='flex' gap='4px'>
                      <Badge label='Negative' variant='error' />
                      <Badge label='Neutral' variant='macro' />
                    </Box>
                  </Box>
                  <RTEContainer
                    hasError={submitMessageForLowRatings.trim() === ''}
                  >
                    <RichTextEditor
                      editorRef={editorRefForLowRating}
                      initialContent={submitMessageForLowRatings}
                      label='Confirmation for low ratings'
                      onChange={value => {
                        handleUpdateField({
                          key: 'submit_message_for_low_ratings',
                          val: value,
                        });
                      }}
                      placeholder=''
                      withListButtons={false}
                    />
                  </RTEContainer>
                </Box>
                <Box display='flex' flexDirection='column' gap='8px'>
                  <Box alignItems='center' display='flex' gap='8px'>
                    <Typography variant='font14'>
                      Confirmation for high ratings (4-5)
                    </Typography>
                    <Badge label='Positive' variant='success' />
                  </Box>
                  <RTEContainer
                    hasError={submitMessageForHighRatings.trim() === ''}
                  >
                    <RichTextEditor
                      editorRef={editorRefForHighRating}
                      initialContent={submitMessageForHighRatings}
                      label='Confirmation for high ratings'
                      onChange={value => {
                        handleUpdateField({
                          key: 'submit_message_for_high_ratings',
                          val: value,
                        });
                      }}
                      placeholder=''
                      withListButtons={false}
                    />
                  </RTEContainer>
                </Box>
              </>
            ) : (
              <TextFieldWithMessage
                ariaLabel='submit confirmation message'
                footer='Keep the question under 60 characters'
                message='Confirmation message'
                onChange={e => {
                  handleUpdateField({
                    key: 'submit_confirmation_message',
                    val: e.target.value,
                  });
                }}
                placeholder=''
                value={submitConfirmationMessage}
              />
            )}
          </Section>
        </>
      )}
    </Box>
  );
};

export default CsatTabConfiguration;

const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Separator = styled('div')`
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const StyleCard = styled('div')<{ selected: boolean }>`
  cursor: pointer;
  padding: 8px 48px;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  border-radius: 6px;
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.palette.colors.purple[500]
        : props.theme.palette.colors.grey[100]};
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyleCardImg = styled('img')`
  height: 40px;
  width: 40px;
`;
