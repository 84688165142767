import { Box } from '@mui/system';

import { Typography } from '@forethought-technologies/forethought-elements';
import DraggableListItem from './DraggableListItem';

export default function RuleList() {
  return (
    <Box>
      <Box mb='20px'>
        <Typography variant='font16Bold'>Rules</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <div aria-label='If/Otherwise'>
          <DraggableListItem
            componentMetadata={{}}
            componentType='rule'
            title='If/Otherwise'
          />
        </div>
      </Box>
    </Box>
  );
}
