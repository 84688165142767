import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

interface HorizontalStackedBarProps {
  data: {
    color: string;
    value: number;
  }[];
}

export function HorizontalStackedBar({ data }: HorizontalStackedBarProps) {
  const { palette } = useTheme();

  const total = React.useMemo(() => {
    return data.reduce((acc, { value }) => acc + value, 0);
  }, [data]);

  const valuesWithWidths = React.useMemo(() => {
    // Calculate the percentage width of each bar based on the total value
    return data.map(({ value, ...rest }) => {
      const percentageWidth = (value / total) * 100;
      return {
        ...rest,
        value,
        width: percentageWidth ? `${percentageWidth}%` : 0,
      };
    });
  }, [data, total]);

  return (
    <Box flex={1}>
      <Box
        sx={{
          borderRadius: 1,
          boxSizing: 'border-box',
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        {valuesWithWidths.map(({ color, width }, idx) => {
          return (
            <Box
              key={idx}
              sx={{
                backgroundColor: color,
                height: '16px',
                width,
              }}
            />
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          maxWidth: '100%',
        }}
      >
        {valuesWithWidths.map(({ value, width }, idx) => {
          if (!value) {
            // no label for 0
            return null;
          }
          return (
            <Box
              display='flex'
              flex={`1 1 ${width}`}
              justifyContent='center'
              key={idx}
              padding='4px 0 0 4px'
            >
              <Typography
                color={palette.colors.grey[600]}
                variant='font12Medium'
              >
                {value.toLocaleString()}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
