import { Box, useTheme } from '@mui/material';

import {
  LoadingIndicator,
  Typography,
} from '@forethought-technologies/forethought-elements';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';

interface GeneratedResponsesLoadingLabelProps {
  addLoadingIndicator?: boolean;
  label: string;
}

const GeneratedResponsesLabel = ({
  addLoadingIndicator,
  label,
}: GeneratedResponsesLoadingLabelProps) => {
  const { palette } = useTheme();
  return (
    <Box alignItems='center' display='flex' gap='8px' padding='0 4px'>
      <img src={SupportGPTIcon} />
      <Typography color={palette.colors.grey[500]} variant='font14Bold'>
        {label}
      </Typography>
      {addLoadingIndicator && (
        <LoadingIndicator color={palette.colors.grey[600]} />
      )}
    </Box>
  );
};

export default GeneratedResponsesLabel;
