import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Scope } from '../types';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  InsightArticle,
  InsightWorkflowDetail,
} from 'src/services/insights/types';
import { setSelectedArticleId } from 'src/slices/solve-insights/solveInsightsSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

interface TabHeaderProps {
  article?: InsightArticle | null;
  dateRange?: string;
  isLoading?: boolean;
  scope: Scope;
  tabName?: string;
  tabNameIcon?: JSX.Element | null;
  workflowDetail?: InsightWorkflowDetail;
}

const TabHeader = ({
  article = null,
  dateRange,
  isLoading = false,
  scope,
  tabName,
  tabNameIcon = null,
  workflowDetail,
}: TabHeaderProps) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const navigate = useNavigate();

  // Hooks
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const lastUpdated =
    (article?.last_updated_at &&
      moment(article.last_updated_at).format('MMMM Do YYYY, h:mm a')) ??
    null;

  return (
    <Box alignItems='center' display='flex' justifyContent='space-between'>
      <Box
        alignItems='end'
        display='flex'
        gap='16px'
        id='tab-header'
        justifyContent='start'
      >
        {isLoading ? (
          <Skeleton height={30} />
        ) : (
          <>
            {tabName && (
              <Box display='flex' gap='8px'>
                {tabNameIcon}
                <Typography variant='font24'>{tabName}</Typography>
              </Box>
            )}
            {article && (
              <Button
                onClick={() => {
                  dispatch(setSelectedArticleId(article.id));
                  emitTrackingEventCallback(
                    'insight-view-article-btn-clicked',
                    {
                      id: article?.id,
                      scope,
                      tab: 'article',
                      title: article?.title,
                    },
                  );
                }}
                variant='secondary'
              >
                View article
              </Button>
            )}
            {workflowDetail && (
              <Button
                onClick={() => {
                  emitTrackingEventCallback(
                    'insight-view-workflow-btn-clicked',
                    {
                      id: workflowDetail?.intent_workflow_id,
                      scope,
                      tab: 'workflow',
                      title: workflowDetail?.intent_title,
                    },
                  );
                  navigate({
                    pathname: Routes.WORKFLOW_BUILDER_EDIT,
                    search: `?intentId=${workflowDetail?.intent_definition_id}&workflowId=${workflowDetail?.intent_workflow_id}`,
                  });
                }}
                variant='secondary'
              >
                View Workflow
              </Button>
            )}
          </>
        )}
        {dateRange && (
          <Typography color={palette.colors.grey[400]} variant='font24'>
            {dateRange}
          </Typography>
        )}
      </Box>
      <Box display='flex'>
        {isLoading ? (
          <Skeleton height={20} width='200px' />
        ) : (
          <Typography color={palette.colors.grey[600]} variant='font12'>
            {lastUpdated}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TabHeader;
