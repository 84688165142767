import '../../../chart-common/common-chartPage.scss';

import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { analyticsLoading, getChartSearchMetric } from 'src/actions/analytics';
import NoDataView from 'src/components/reusable-components/no-data-view/NoDataView';
import D3Util from 'src/utils/d3Utils';
import { noDataViewProps } from 'src/utils/enums';
import { findValueByPrefix } from 'src/utils/findValueByPrefix';

type MyProps = {
  analyticsLoading: boolean;
  end_timestamp: any;
  getChartSearchMetric: Function;
  isLoading: Function;
  mean: any;
  start_timestamp: any;
  timeFrameFilter: string;
  x: any;
  y: any;
};

type MyState = {
  data: any;
  noResults: boolean;
};

class SearchChart extends React.Component<MyProps, MyState> {
  chart: any;
  redrawChart: any;
  mean: any;
  constructor(props: MyProps) {
    super(props);
    this.state = {
      data: [],
      noResults: false,
    };
    this.mean;
  }

  async componentDidMount() {
    this.props.isLoading(true);
    //constructs the url with the query strings with prop values when it loads
    this.constructQueryParams(
      this.props.start_timestamp,
      this.props.end_timestamp,
      this.props.y,
      this.props.timeFrameFilter,
    );
    const data = await this.props.getChartSearchMetric(
      this.props.start_timestamp,
      this.props.end_timestamp,
      this.props.timeFrameFilter,
    );
    this.props.isLoading(false);
    this.checkIfDataEmpty(data, this.props.y);
    this.mean = findValueByPrefix(this.props.mean, this.props.y);
  }

  //Check if data empty, if empty update data to mockdata for no results screen
  checkIfDataEmpty(data: any, y: string) {
    const emptyData = [
      {
        frequency: 3,
        open_count: 3,
        open_rate: 3,
        timestamp: 1546300800,
      },
      {
        frequency: 4,
        open_count: 4,
        open_rate: 4,
        timestamp: 1548979200,
      },
      {
        frequency: 5,
        open_count: 5,
        open_rate: 5,
        timestamp: 1551398400,
      },
      {
        frequency: 6,
        open_count: 6,
        open_rate: 6,
        timestamp: 1554076800,
      },
      {
        frequency: 8,
        open_count: 8,
        open_rate: 8,
        timestamp: 1556668800,
      },
      {
        frequency: 7,
        open_count: 7,
        open_rate: 7,
        timestamp: 1559347200,
      },
      {
        frequency: 6,
        open_count: 6,
        open_rate: 6,
        timestamp: 1561939200,
      },
      {
        frequency: 9,
        open_count: 9,
        open_rate: 9,
        timestamp: 1564617600,
      },
      {
        frequency: 6,
        open_count: 6,
        open_rate: 6,
        timestamp: 1567296000,
      },
      {
        frequency: 8,
        open_count: 8,
        open_rate: 8,
        timestamp: 1569888000,
      },
      {
        frequency: 7,
        open_count: 7,
        open_rate: 7,
        timestamp: 1572566400,
      },
      {
        frequency: 9,
        open_count: 9,
        open_rate: 9,
        timestamp: 1575158400,
      },
    ];

    const a: any = [];
    if (data && data.length > 0) {
      data.forEach((arrayItem: any) => {
        const x = arrayItem[y];
        a.push(x);
      });
      if (a.every((item: any) => item === 0)) {
        this.setState({ data: emptyData, noResults: true });
      } else {
        this.setState({ data: data, noResults: false });
      }
    }
  }

  //Constructs query parameters for URL
  constructQueryParams(
    start = this.props.start_timestamp,
    end = this.props.end_timestamp,
    yaxis = this.props.y,
    timeFrameFilter = this.props.timeFrameFilter,
  ) {
    const pathName =
      location.pathname === '/'
        ? '/search-analytics'
        : window.location.pathname;
    window.history.replaceState(
      null,
      null as any,
      `${pathName}?view=chart${encodeURIComponent(
        `&start=${start}&end=${end}&y=${yaxis}&timeframe=${timeFrameFilter}`,
      )}`,
    );
  }

  //when props update redraw chart
  async componentDidUpdate(prevProps: any, prevState: any) {
    const { timeFrameFilter, x, y } = this.props;
    this.chart = new D3Util();
    const queryStr = queryString.parse(
      decodeURIComponent(window.location.search),
    );
    if (timeFrameFilter !== queryStr.timeframe || y !== queryStr.y) {
      this.constructQueryParams(
        this.props.start_timestamp,
        this.props.end_timestamp,
        this.props.y,
        this.props.timeFrameFilter,
      );
    }
    if (prevProps.timeFrameFilter !== timeFrameFilter || prevProps.y !== y) {
      this.props.isLoading(true);
      const newData = await this.props.getChartSearchMetric(
        this.props.start_timestamp,
        this.props.end_timestamp,
        this.props.timeFrameFilter,
      );
      this.props.isLoading(false);
      this.checkIfDataEmpty(newData, this.props.y);
      this.mean = findValueByPrefix(this.props.mean, this.props.y);
    }
    if (prevState.data !== this.state.data) {
      this.mean = findValueByPrefix(this.props.mean, this.props.y);
      this.chart.redrawChart(
        this.state.data,
        x,
        y,
        '.search-line-chart',
        this.state.noResults,
        this.mean,
        timeFrameFilter,
      );
    }
  }

  render() {
    return (
      <div className='analytics-chart-cont '>
        {!this.props.analyticsLoading && this.state.noResults && (
          <NoDataView
            insideChart
            message={noDataViewProps.ANALYTICS_TIME_RANGE}
            noDataViewClass={noDataViewProps.DATE_ICON_CLASS}
          />
        )}

        {!this.props.analyticsLoading && <svg className='search-line-chart' />}

        {this.props.analyticsLoading && (
          <div className='chart-loading-container'>
            <div className='loading-gif' />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  analyticsLoading: state.analytics.analyticsLoading,
  mean: state.analytics.chartSearchMetrics.mean,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getChartSearchMetric: async (
    start_timestamp: any,
    end_timestamp: any,
    breakdown_type: string,
  ) =>
    dispatch(
      getChartSearchMetric(start_timestamp, end_timestamp, breakdown_type),
    ),
  isLoading: (isLoading: boolean) => dispatch(analyticsLoading(isLoading)),
});

const ConnectedSearchChart = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchChart);

export default ConnectedSearchChart;
