import Box from '@mui/material/Box';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import discoverNewIcon from 'src/assets/images/discover-new-icon.svg';

interface DiscoverNewTopicBadgeProps {
  isNewTopic: boolean;
  margin?: string | number;
}

const DiscoverNewTopicBadge = ({
  isNewTopic,
  margin = '0 0 0 5px',
}: DiscoverNewTopicBadgeProps) => {
  if (!isNewTopic) {
    return null;
  }

  return (
    <Tooltip tooltipContent='Newly identified topic from last 30 days'>
      <Box alignItems='center' display='flex' margin={margin}>
        <img alt='New topic' src={discoverNewIcon} />
      </Box>
    </Tooltip>
  );
};

export default DiscoverNewTopicBadge;
