import React from 'react';
import { Box, useTheme } from '@mui/material';
import { IconAlertTriangleFilled } from '@tabler/icons-react';

import { Tooltip } from '@forethought-technologies/forethought-elements';

type TooltipProps = React.ComponentProps<typeof Tooltip>;

interface AlertTooltipProps {
  tooltipProps: Pick<TooltipProps, 'tooltipContent' | 'placement'>;
}

/**
 * An alert icon that displays a tooltip on hover.
 */
export default function AlertTooltip({ tooltipProps }: AlertTooltipProps) {
  const { palette } = useTheme();

  return (
    <Tooltip {...tooltipProps}>
      <Box color={palette.colors.red[500]}>
        <IconAlertTriangleFilled data-testid='alert-icon' size={18} />
      </Box>
    </Tooltip>
  );
}
