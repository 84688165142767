import { captureMessage } from '@sentry/react';

import { CanvasWorkflowBuilderState } from './workflowBuilderSlice';
import { CanvasModes } from 'src/pages/workflow-builder-edit/constants';
import {
  doesActionContainStepWithType,
  getStepWithTypeFromAction,
  isDynamicArticleSuggestion,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducerHelpers';
import {
  ActionSettingsCustomization,
  BaseTransition,
  ButtonsStepFields,
  CanvasResponse,
  Intent,
  ZendeskHandoffCustomization,
} from 'src/types/workflowBuilderAPITypes';
import {
  getArticleSuggestionActionId,
  getEntryFromArticleSuggestionActionStep,
} from 'src/utils/articleSuggestionUtils';
import { StepTypes } from 'src/utils/enums';

export const reload = (
  state: CanvasWorkflowBuilderState,
  payload: CanvasResponse,
): CanvasWorkflowBuilderState => {
  return {
    ...state,
    actionSettings: {},
    addingAction: null,
    canvas_action_id_to_action_component:
      payload.canvas.canvas_action_id_to_action_component,
    entryStepId: payload.canvas.entry_step_id,
    errors: [],
    intent_id: payload.canvas.intent_id,
    intent_title: payload.canvas.intent_title,
    intent_workflow_id: payload.canvas.intent_workflow_id,
    invalid_go_to_steps: payload.invalid_go_to_steps,
    is_active: payload.canvas.is_active,
    is_duplicated_pending_customization:
      payload.canvas.is_duplicated_pending_customization,
    latestStepId: payload.step_id,
    loading: false,
    mode: CanvasModes.MESSAGE,
    org_id: payload.canvas.org_id,
    steps: payload.canvas.step_map,
    undefined_context_variables_in_step:
      payload.undefined_context_variables_in_step,
    unsaved_changes: payload.canvas.unsaved_changes,
    unsupported_steps: payload.unsupported_steps,
    version: payload.canvas.version,
    workflowTags: payload.canvas.workflow_tags || [],
  };
};

export const loadNewState = (
  state: CanvasWorkflowBuilderState,
  payload: CanvasResponse,
): CanvasWorkflowBuilderState => {
  return {
    ...state,

    api_workflow_id: payload.canvas.api_workflow_id,

    canvas_action_id_to_action_component:
      payload.canvas.canvas_action_id_to_action_component,

    editing_condition_step_id: '',

    editing_step_id: '',

    editing_step_type: '',

    entryStepId: payload.canvas.entry_step_id,

    errors: [],

    intent_id: payload.canvas.intent_id,

    intent_title: payload.canvas.intent_title,

    intent_workflow_id: payload.canvas.intent_workflow_id,

    invalid_go_to_steps: payload.invalid_go_to_steps,

    is_active: payload.canvas.is_active,

    is_draft: payload.canvas.is_draft,

    is_duplicated_pending_customization:
      payload.canvas.is_duplicated_pending_customization,

    latestStepId: payload.step_id,

    loading: false,
    // reset fields that are used for previous canvas
    mode: CanvasModes.MESSAGE,
    org_id: payload.canvas.org_id,

    outputVariableValuesMap: {},

    policy_description: payload.canvas.policy_description,
    // load data from canvas response
    steps: payload.canvas.step_map,
    undefined_context_variables_in_step:
      payload.undefined_context_variables_in_step,
    unsaved_changes: payload.canvas.unsaved_changes,
    unsupported_steps: payload.unsupported_steps,
    version: payload.canvas.version,
    workflow_recommendation: payload.canvas.workflow_recommendation,
    workflowTags: payload.canvas.workflow_tags || [],
  };
};

export const getSelectedTransitionId = (
  state: CanvasWorkflowBuilderState,
  stepId: string,
): string | undefined => {
  const step = state.steps[stepId];

  if (!step) {
    captureMessage('Step not found in getSelectedTransitionId', {
      extra: {
        stepId,
      },
      level: 'error',
    });
    return;
  }

  if (state.outputVariableValuesMap[stepId]) {
    // Button
    const outputVariable = (step.step_fields as ButtonsStepFields)
      .output_variable;
    const conditionExpressionValue =
      state.outputVariableValuesMap[stepId]?.[outputVariable];
    const transition = step.transitions.find(t => {
      const values = t.condition_expression?.values;
      return values && values.includes(conditionExpressionValue ?? '');
    });
    return transition?.transition_id as string;
  } else if (state.selectedConditions.hasOwnProperty(stepId)) {
    // Condition
    const conditionIndex = state.selectedConditions[stepId] ?? -1;
    return step.transitions[conditionIndex]?.transition_id as string;
  }

  // Article Suggestion
  // If this step doesn't actually belong to article suggestion, then entryStepId will be undefined.
  const actionEntryStepId = getEntryFromArticleSuggestionActionStep(
    stepId,
    state.canvas_action_id_to_action_component,
  );

  if (actionEntryStepId) {
    // if the action is dynamic article suggestion and has no button step, then the parent transition
    // would be the article found branch of the condition step (cond expr is null)
    const actionId = getArticleSuggestionActionId(
      stepId,
      state.canvas_action_id_to_action_component,
    );
    if (isDynamicArticleSuggestion(state, actionId)) {
      return getSelectedTransitionIdForDynamicArticleSuggestion(
        state,
        actionId,
        actionEntryStepId,
      );
    }
    // template article suggestion flow
    if (step.step_type !== StepTypes.BUTTONS) {
      // 3 cases to consider

      // Case 1: Step isn't a button.
      // This means it's the nested text step under the No branch, in which case we don't need to send
      // a parent transition id to the backend.
      return undefined;
    }
    // Case 2: Step is a button, but only has one transition
    // This happens when an article suggestion is first created: we only define the No branch.
    // In this scenario, we rely on the backend to figure out what transitions need be connected.
    if (step.transitions.length === 1) {
      return undefined;
    }
    // Case 3: Step is a button and has two transitions.
    // Now, we need to look at which transition is selected, based on actionCaseMap. If it's the No branch,
    // we again don't want a transition id, because the attachments happens on the nested text step.
    // Otherwise, we return the transition id of the Yes branch.
    if (step.transitions.length === 2) {
      if (!state.actionCaseMap[actionEntryStepId]) {
        return undefined;
      }
      const transitions = step.transitions as BaseTransition[];
      return transitions.find(t =>
        t.condition_expression?.values?.includes('yes_resolve'),
      )?.transition_id;
    }
    throw Error(
      `Article suggestion has illegal # of transitions: ${step.transitions.length}`,
    );
  }
  return undefined;
};

const getSelectedTransitionIdForDynamicArticleSuggestion = (
  state: CanvasWorkflowBuilderState,
  actionId: string,
  actionEntryStepId: string,
): string | undefined => {
  if (!doesActionContainStepWithType(state, actionId, StepTypes.BUTTONS)) {
    const conditionStep = getStepWithTypeFromAction(
      state,
      actionId,
      StepTypes.CONDITION,
    );

    const articleFoundTransition = conditionStep?.transitions.find(
      transition => transition.condition_expression === null,
    );
    if (!articleFoundTransition) {
      return undefined;
    }

    return articleFoundTransition.transition_id || undefined;
  } else {
    const buttonStep = getStepWithTypeFromAction(
      state,
      actionId,
      StepTypes.BUTTONS,
    );

    if (!buttonStep) {
      return undefined;
    }

    const actionCaseMap = state.actionCaseMap;
    const isYesBranchChosen = actionCaseMap[actionEntryStepId];
    if (isYesBranchChosen) {
      return (
        buttonStep.transitions.find(
          transition =>
            transition.condition_expression?.values &&
            transition.condition_expression?.values[0] === 'yes_resolve',
        )?.transition_id ?? undefined
      );
    } else {
      return (
        buttonStep.transitions.find(
          transition =>
            transition.condition_expression?.values &&
            transition.condition_expression?.values[0] === 'no_resolve',
        )?.transition_id ?? undefined
      );
    }
  }
};

export const storeIntent = (
  state: CanvasWorkflowBuilderState,
  payload: Intent,
): CanvasWorkflowBuilderState => {
  return {
    ...state,
    intent: payload,
    intent_title: payload.title,
    loading: false,
  };
};

export const clearIntent = (
  state: CanvasWorkflowBuilderState,
): CanvasWorkflowBuilderState => {
  return {
    ...state,
    intent: null,
    intent_id: null,
    intent_title: null,
    loading: false,
  };
};

export const storeHandoffCustomization = (
  state: CanvasWorkflowBuilderState,
  payload: ZendeskHandoffCustomization,
): CanvasWorkflowBuilderState => {
  return {
    ...state,
    handoffCustomization: payload,
    isLoadingCustomization: false,
  };
};

export const storeActionSettings = (
  state: CanvasWorkflowBuilderState,
  payload: ActionSettingsCustomization,
): CanvasWorkflowBuilderState => {
  return {
    ...state,
    actionSettings: payload,
    isLoadingCustomization: false,
  };
};
