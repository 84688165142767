import { useParams } from 'react-router';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import VersionControlItem from './VersionControlItem';
import Spinner from 'src/components/spinner';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useGetModelByIdQuery } from 'src/services/triage/triageApi';

interface VersionControlListProps {
  onSelect: (id: string) => void;
  selectedVersion: VersionedTriageModel;
  setLabelMappingIsOpen: (value: boolean) => void;
  versions?: VersionedTriageModel[];
}

const VersionControlList = ({
  onSelect,
  selectedVersion,
  setLabelMappingIsOpen,
  versions,
}: VersionControlListProps) => {
  const { modelId = '' } = useParams<'modelId'>();

  const { data: currentVersionedModel, isLoading: fetchingModelDetails } =
    useGetModelByIdQuery(modelId || '');
  const { palette } = useTheme();

  if (!versions) {
    return null;
  }

  const publishedVersions = versions.filter(
    item => item.version_id !== currentVersionedModel?.version_id,
  );

  return (
    <Box
      display='flex'
      flexDirection='column'
      overflow='auto'
      pb={2}
      rowGap={4}
      sx={{
        borderLeft: 'solid 1px #E4E7F0',
      }}
    >
      <Box pt={4} px={3}>
        <Typography variant='font20Medium'>Version</Typography>
      </Box>
      <Box display='flex' flexDirection='column'>
        <Box px={3}>
          <Typography variant='font16Bold'>Current</Typography>
        </Box>
        {fetchingModelDetails && (
          <Box left={0} position='fixed' top={0} width='100vw' zIndex={100}>
            <Spinner />
          </Box>
        )}
        {currentVersionedModel && (
          <VersionControlItem
            isCurrent
            item={currentVersionedModel}
            key={currentVersionedModel.version_id}
            onSelect={onSelect}
            selected={
              currentVersionedModel.version_id === selectedVersion.version_id
            }
            setLabelMappingIsOpen={setLabelMappingIsOpen}
          />
        )}
        {!currentVersionedModel && (
          <Box color={palette.colors.grey[600]} px={3}>
            <Typography variant='font14'>No published version found</Typography>
          </Box>
        )}
      </Box>
      <Box display='flex' flexDirection='column'>
        <Box px={3}>
          <Typography variant='font16Bold'>
            Saved versions ({publishedVersions.length})
          </Typography>
        </Box>
        {!publishedVersions.length && (
          <Box color={palette.colors.grey[600]} px={3}>
            <Typography variant='font14'>No saved versions found</Typography>
          </Box>
        )}
        {publishedVersions.map(item => (
          <VersionControlItem
            item={item}
            key={item.version_id}
            onSelect={onSelect}
            selected={item.version_id === selectedVersion.version_id}
            setLabelMappingIsOpen={setLabelMappingIsOpen}
          />
        ))}
      </Box>
    </Box>
  );
};

export default VersionControlList;
