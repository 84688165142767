import { memo, useCallback, useMemo, useState } from 'react';
import {
  type MRT_TableOptions,
  MRT_VisibilityState,
} from 'material-react-table';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import { IconFilterPlus } from '@tabler/icons-react';

import {
  Button,
  DateRangeFilterButton,
  InfiniteTable,
  MultiSelectFilter,
  Order,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import { initialInsightParams } from '../constants';
import {
  dateRangeToTimestamp,
  sortDirectionToInsightSortDirection,
} from '../helpers';
import { useGetSearchQuery } from '../hooks/useGetSearchQuery';
import { InsightSortDirection } from '../types';
import {
  initialInsightWorkflowParams,
  InsightWorkflowSortColumns,
  TABLE_COLUMN_TO_SORT_MAP,
  WORKFLOW_TABLE_FILTER_OPTIONS,
} from './constants';
import {
  buildWorkflowColumns,
  filterCommonIntentWorkflowsToTop,
  WorkflowTableDataFilter,
} from './helpers';
import { WorkflowDropdownMenu } from './WorkflowDropdownMenu';
import { useStateParams } from 'src/hooks/hooks';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import {
  useGetWorkflowOverallDeflectionsQuery,
  useGetWorkflowTableQuery,
} from 'src/services/insights';
import { InsightWorkflow } from 'src/services/insights/types';
import { DateRange } from 'src/types/types';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
} from 'src/utils/discover/helpers';
import {
  CommonIntentWorkflowType,
  ExportableTableType,
  Routes,
} from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface WorkflowsTableProps {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
}

const WorkflowsTable = ({ dateRange, setDateRange }: WorkflowsTableProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  // State params
  const deserializeSortDirection = useCallback(
    (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    [],
  );

  const deserializeSortColumn = useCallback(
    (param: string) => param as InsightWorkflowSortColumns,
    [],
  );
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: deserializeSortDirection,
      initialState: initialInsightWorkflowParams.workflowColumnSortDirection,
      paramsName: 'workflow_sort_direction',
      serialize: String,
    });
  const [sortColumn, setSortColumn] =
    useStateParams<InsightWorkflowSortColumns>({
      deserialize: deserializeSortColumn,
      initialState: initialInsightWorkflowParams.workflowColumnSort,
      paramsName: 'workflow_sort_column',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: genericSerializeAndDeserialize,
    initialState: '',
    paramsName: 'workflows_search',
    serialize: genericSerializeAndDeserialize,
  });

  const timestamps = dateRangeToTimestamp(dateRange);
  const timeFilter = dateRangeToTimeFilter(dateRange);

  const { setUiQuery, uiQuery } = useGetSearchQuery({
    scope: 'workflow',
    searchQuery,
    setSearchQuery,
    tab: 'workflow',
    type: 'workflow',
  });
  const getCSATColor = useGetCSATColor();

  const [page, setPage] = useState(1);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    Object.fromEntries(
      initialInsightParams.articleColumns.map(column => [column, true]),
    ),
  );
  const [filters, setFilters] = useState<string[]>([]);

  const {
    data: tableData,
    isError: isTableError,
    isFetching: isTableFetching,
    isLoading: isTableDataLoading,
  } = useGetWorkflowTableQuery({
    data_export_type: ExportableTableType.INSIGHT_WORKFLOWS_TABLE,
    end: timestamps.end_timestamp,
    sort_column: sortColumn,
    sort_direction: sortDirection,
    start: timestamps.start_timestamp,
  });
  const {
    data: workflowOverallDeflections,
    isLoading: isWorkflowOverallDeflectionsLoading,
  } = useGetWorkflowOverallDeflectionsQuery({
    end: timestamps.end_timestamp,
    start: timestamps.start_timestamp,
  });
  const costPerTicket = workflowOverallDeflections?.misc.cost_per_ticket;

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const getWorkflowTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const rows: InsightWorkflow[] = useMemo(() => {
    if (!tableData?.data) {
      return [];
    }

    const filter = new WorkflowTableDataFilter(tableData?.data);
    filter.filterByKeyword(uiQuery);
    filter.filterByType(filters);
    const filteredTable = filter.fetchData();
    // always show knowledge retrieval and handoff workflows on top
    return filterCommonIntentWorkflowsToTop(filteredTable);
  }, [uiQuery, filters, tableData?.data]);

  const columns = useMemo(
    () => buildWorkflowColumns(palette, navigate, getCSATColor, costPerTicket),
    [palette, navigate, getCSATColor, costPerTicket],
  );

  const handleFilterReset = useCallback(() => {
    setDateRange(initialInsightParams.date);
    // setVisibleColumns(initialInsightParams);
  }, [setDateRange]);

  const initialTimeFilter = dateRangeToTimeFilter(initialInsightParams.date);

  const showLoadingSkeleton =
    isTableDataLoading || isWorkflowOverallDeflectionsLoading;

  const areFiltersUsed = Boolean(
    uiQuery.length || timeFilter.key !== initialTimeFilter.key,
  );

  const handleSortCallback = useCallback(
    (property: keyof InsightWorkflow, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      const updatedSortColumn =
        TABLE_COLUMN_TO_SORT_MAP.workflow[property] ??
        initialInsightWorkflowParams.workflowColumnSort;
      setSortColumn(updatedSortColumn);
    },
    [setSortColumn, setSortDirection],
  );

  const tableOptions = useMemo<MRT_TableOptions<InsightWorkflow>>(
    () => ({
      columns: columns,
      data: rows,
      displayColumnDefOptions: {
        'mrt-row-actions': {
          // TODO: ask Design Team if we want to use the default `Actions` text.
          // Note that the default `Actions` text is misaligned vertically.
          header: '',
          muiTableBodyCellProps: {
            align: 'center',
          },
          muiTableHeadCellProps: {
            align: 'center',
          },
          size: 50,
        },
      },
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: true,
      initialState: {
        columnPinning: {
          left: ['intent_title', 'is_active'],
          right: ['mrt-row-actions'],
        },
        density: 'spacious',
      },
      muiTableBodyCellProps: ({ column, row }) => {
        const { intent_definition_id: intentDefinitionId } = row.original;
        const isKnowledge =
          intentDefinitionId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
        const isFallback =
          intentDefinitionId === CommonIntentWorkflowType.FALLBACK;

        function getBackgroundColor() {
          if (row.getIsSelected()) {
            return palette.colors.purple[100];
          }

          if (column.getIsPinned()) {
            return palette.colors.blue[50];
          }

          if (isKnowledge || isFallback) {
            return '#DBBCFB12';
          }

          return 'inherit';
        }

        return {
          sx: {
            '::before, ::after': {
              backgroundColor: 'initial !important',
            },
            backgroundColor: getBackgroundColor(),
            fontWeight: row.getCanExpand() ? 'bold' : 'normal',
            opacity: 'initial',
          },
        };
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
          const pathName = Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
            ':workflowId',
            row.original.intent_workflow_id,
          );

          navigate({
            pathname: pathName,
            search: `?tab=${tab}`,
          });
        },
        sx: {
          cursor: 'pointer',
        },
      }),
      onColumnVisibilityChange: setColumnVisibility,
      renderRowActions: ({ row }) => (
        <WorkflowDropdownMenu data={row.original} />
      ),
      renderTopToolbarCustomActions: () => (
        <Box display='flex' gap='8px' paddingTop='4px' width='100%'>
          <Box key={1} width='200px'>
            <SearchBar
              onChange={e => setUiQuery(e.target.value)}
              placeholder='Search workflow'
              size='small'
              value={uiQuery}
            />
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightParams.date}
            onChange={value => setDateRange(value)}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <MultiSelectFilter
            icon={<IconFilterPlus color={palette.grey[700]} size={20} />}
            isMenuSearchable
            maxHeight={400}
            menuTitle='Filter by'
            onChange={setFilters}
            options={WORKFLOW_TABLE_FILTER_OPTIONS}
            placeholder='Filter by'
            value={filters}
          />
          {areFiltersUsed && (
            <Button
              onClick={() => {
                setUiQuery('');
                setDateRange(initialInsightParams.date);
                setSortColumn(initialInsightParams.workflowColumnSort);
                handleFilterReset && handleFilterReset();
              }}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <InsightDownloadCsv
              filename='workflows.csv'
              requestData={{
                data_export_type: ExportableTableType.INSIGHT_WORKFLOWS_TABLE,
                end: timestamps.end_timestamp,
                start: timestamps.start_timestamp,
              }}
              url={getWorkflowTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
      state: {
        columnVisibility,
      },
    }),
    [
      columns,
      columnVisibility,
      uiQuery,
      dateRange,
      setUiQuery,
      setDateRange,
      getWorkflowTableDownloadUrl,
      timestamps.end_timestamp,
      timestamps.start_timestamp,
      navigate,
      areFiltersUsed,
      setSortColumn,
      handleFilterReset,
      filters,
      palette,
      rows,
      tab,
    ],
  );

  return (
    <InfiniteTable
      hasNextPage={typeof tableData?.metadata.next_page === 'number'}
      initialSorting={initialSorting}
      isError={isTableError}
      isLoadingFirstPage={showLoadingSkeleton}
      isLoadingNextPage={isTableFetching}
      onLoadMore={() => {
        const nextPage = page + 1;
        setPage(nextPage);
      }}
      onSortCallback={handleSortCallback}
      stickyHeaderHeight={0}
      tableOptions={tableOptions}
    />
  );
};

const MemoizedWorkflowsTable = memo(WorkflowsTable);

export default MemoizedWorkflowsTable;
