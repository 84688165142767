import { sendAuthRequest } from '../api';
import {
  AcknowledgedResponse,
  HandoffConfiguration,
  KnowledgeConfiguration,
  SolveKnowledgeSource,
  WidgetConfiguration,
  WidgetConfigurationResponse,
} from 'src/slices/solve-config/types';
import { normalizeResponseErrors } from 'src/utils/normalizeResponse';

const BASE_URL = `${API_URL}dashboard-controls/solve/config`;

export const getInitialSolveConfiguration =
  (): Promise<AcknowledgedResponse> => {
    return sendAuthRequest(`${BASE_URL}/initialize`, {}, 'PUT')
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getWidgetConfiguration =
  (): Promise<WidgetConfigurationResponse> => {
    return sendAuthRequest(`${BASE_URL}/widget`, null, 'GET')
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const getKnowledgeConfiguration =
  (): Promise<KnowledgeConfiguration> => {
    return sendAuthRequest(`${BASE_URL}/knowledge`, null, 'GET')
      .then((res: Response | { error: string }) => normalizeResponseErrors(res))
      .then((res: Response) => res.json());
  };

export const updateWidgetConfiguration = (
  updatedConfigurations: WidgetConfiguration,
): Promise<WidgetConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/widget`, updatedConfigurations, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const getHandoffConfiguration = (): Promise<HandoffConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/handoff`, null, 'GET')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateHandoffConfiguration = (
  updatedConfigurations: HandoffConfiguration,
): Promise<HandoffConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/handoff`, updatedConfigurations, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const activateWidget = (): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(`${BASE_URL}/widget/activate`, null, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const deactivateWidget = (): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(`${BASE_URL}/widget/deactivate`, null, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const publishConfiguration = (): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(`${BASE_URL}/publish`, null, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const publishWidgetConfiguration = (): Promise<AcknowledgedResponse> => {
  return sendAuthRequest(`${BASE_URL}/widget/publish`, null, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};

export const updateKnowledgeConfiguration = (
  updatedConfigurations: SolveKnowledgeSource,
): Promise<KnowledgeConfiguration> => {
  return sendAuthRequest(`${BASE_URL}/knowledge`, updatedConfigurations, 'PUT')
    .then((res: Response | { error: string }) => normalizeResponseErrors(res))
    .then((res: Response) => res.json());
};
