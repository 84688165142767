import { UserPermissionValue } from '../constants';
import { AssistNote } from 'src/slices/assist-notes/types';

export const noteStringCompare = (a: string, b: string) => a.localeCompare(b);

export class NotesTableFilter {
  data: AssistNote[];
  constructor(data: AssistNote[]) {
    this.data = data;
  }

  filterByKeyword = (searchText: string) => {
    if (searchText.trim()) {
      this.data = this.data.filter(
        ({ agent_email: agentEmail, tags, title }) => {
          const lowerCaseSearchText = searchText.toLowerCase();
          return (
            title.toLowerCase().includes(lowerCaseSearchText) ||
            agentEmail.toLowerCase().includes(lowerCaseSearchText) ||
            tags.join(',').toLowerCase().includes(lowerCaseSearchText)
          );
        },
      );
    }

    return this;
  };

  filterByTag = (filteredTags: string[]) => {
    if (filteredTags.length) {
      this.data = this.data.filter(({ tags }) =>
        tags.some(tag => filteredTags.includes(tag)),
      );
    }

    return this;
  };

  filterByCreator = (creators: string[]) => {
    if (creators.length) {
      this.data = this.data.filter(({ agent_email }) =>
        creators.includes(agent_email),
      );
    }

    return this;
  };

  filterByPermission = (permissions: UserPermissionValue, me = '') => {
    if (permissions.length) {
      this.data = this.data.filter(
        ({ agent_email, created_by_role: role, is_public: isPublic }) => {
          const isAdmin = role === 'SUPER_ADMIN' || role === 'ADMIN';
          const isMe = agent_email === me;

          return permissions.some(permission => {
            switch (permission) {
              case 'ADMIN':
                return isAdmin && isPublic;
              case 'AGENT':
                return !isAdmin;
              case 'ME':
                return isMe;
            }
          });
        },
      );
    }
    return this;
  };
}
