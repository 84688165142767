import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { IconBolt } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ICON_SIZE } from '../constants';
import { generateDiscoverTopicDetailUrl } from '../helpers';
import Menu from 'src/components/menu';
import { Link } from 'src/components/realized-impact-badge/RealizedImpactBadge';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';

interface DiscoverRealizedImpactTopicMenuProps {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  title: string;
  topics?: DiscoverTopic[];
  trackingEventMetadata?: {
    intentId: string;
    source: string;
  };
}

const DiscoverRealizedImpactTopicMenu = ({
  anchorEl,
  onClose,
  title,
  topics = [],
  trackingEventMetadata,
}: DiscoverRealizedImpactTopicMenuProps) => {
  const { palette } = useTheme();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER,
  );
  return (
    <Menu
      anchorEl={anchorEl}
      id='realized-impact-menu'
      MenuListProps={{
        'aria-labelledby': 'realized-impact-button',
        role: 'listbox',
        sx: {
          py: 1.5,
        },
      }}
      onClick={e => e.stopPropagation()}
      onClose={e => {
        //Casting is needed as MUI has {} as a type for events
        const event = e as MouseEvent;
        event.stopPropagation();
        onClose();
      }}
      open={Boolean(anchorEl)}
      title={title}
    >
      {topics.map(topic => (
        <Link
          aria-label={`${deriveTopicNameFromTopic(topic)} link`}
          href={generateDiscoverTopicDetailUrl(topic)}
          key={topic.topic_id}
          onClick={e => {
            e.stopPropagation();
            if (trackingEventMetadata) {
              dispatchTrackingAction(
                FlamethrowerTrackingEventTypes.INTENT_TOPIC_CLICKED,
                {
                  intent_id: trackingEventMetadata.intentId,
                  source: trackingEventMetadata.source,
                  topic_id: topic.topic_id,
                },
              );
            }
          }}
          rel='noopener noreferrer'
          target='_blank'
        >
          <MenuItem
            key={topic.topic_id}
            sx={{
              '& > span:last-child': {
                display: 'none',
              },
              '&.Mui-focusVisible': {
                backgroundColor: 'rgba(0,0,0,0.05)',
              },
              '&.Mui-selected': {
                backgroundColor: 'transparent',
              },
              '&:hover': {
                '& > div': {
                  maskImage: 'linear-gradient(90deg, #000 80%, transparent)',
                  span: {
                    textOverflow: 'initial',
                  },
                },
                '& > span:last-child': {
                  display: 'flex',
                },
                backgroundColor: `${palette.colors.purple[100]} !important`,
              },
              alignItems: 'center',
              backgroundColor: 'transparent',
              display: 'flex',
              padding: '4px 16px',
            }}
          >
            <Box alignItems='center' display='flex' flex={1} overflow='hidden'>
              <Box alignItems='center' display='flex' mr={1}>
                <IconBolt
                  color={palette.colors.green[500]}
                  fill={palette.colors.green[500]}
                  size={ICON_SIZE}
                  strokeWidth={1}
                />
              </Box>
              <Typography color={palette.colors.black} noWrap variant='font14'>
                {deriveTopicNameFromTopic(topic)}
              </Typography>
            </Box>
            <Typography color={palette.primary.main} variant='font11'>
              View topic
            </Typography>
          </MenuItem>
        </Link>
      ))}
    </Menu>
  );
};

export default DiscoverRealizedImpactTopicMenu;
