import React, { memo } from 'react';
import { Box, styled } from '@mui/material';

import { ChatsSavingsCard, ChatsSavingsCardProps } from './ChatsSavingsCard';
import { CsatCard } from './CsatCard';
import EngagementCard from './EngagmentCard';
import QuickFeedbackCard from './QuickFeedbackCard';
import RelevanceCard from './RelevanceCard';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';

type OverallCardsProps = ChatsSavingsCardProps;

const OverallCards: React.FC<React.PropsWithChildren<OverallCardsProps>> = ({
  dateRange,
  tab,
}) => {
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();

  return (
    <Box
      display='flex'
      gap={3}
      justifyContent='space-between'
      minHeight='280px'
      pb={1}
      sx={{
        overflowX: 'scroll',
      }}
    >
      <ChatsSavingsCard dateRange={dateRange} tab={tab} />
      <CsatCard dateRange={dateRange} tab={tab} />
      <QuickFeedbackCard dateRange={dateRange} tab={tab} />
      {isDeflectionInsightsEnabled && (
        <>
          <RelevanceCard dateRange={dateRange} tab={tab} />
          <EngagementCard dateRange={dateRange} tab={tab} />
        </>
      )}
    </Box>
  );
};

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  width: 50%;
  padding: 24px;
  gap: 24px;
`;

const MemoizedOverallCards = memo(OverallCards);

export default MemoizedOverallCards;
