import { NodeProps } from 'reactflow';

import { useCreateWorkflowNode } from '../../hooks/useCreateNode';
import { DropzoneNode } from 'src/components/forethought-flow';

export default function DropzoneNodeContainer(props: NodeProps) {
  const handleCreate = useCreateWorkflowNode();

  return <DropzoneNode {...props} onDrop={handleCreate} />;
}
