import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Colors,
  SearchBar,
  Tabs,
} from '@forethought-technologies/forethought-elements';
import { useGetBuilderQueryParams } from '../hooks';
//Types
import ArticlesList from './ArticlesList';
import ArticleSuggestionActionCard from './ArticleSuggestionActionCard';
import debounce from 'lodash/fp/debounce';
import { selectActionBuilderActions } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import { selectIntentId } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { getArticlesAPI } from 'src/services/workflow-builder/workflowBuilderApi';
//Styles
import { textBoldStyle } from 'src/styles/styledMixin';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { Article, ArticlesResponse } from 'src/types/workflowBuilderAPITypes';
//Utils
import {
  ActionBuilderActionTypes,
  CommonIntentWorkflowType,
} from 'src/utils/enums';

const a11yName = 'article-picker';

const a11yTabProps = (index: number) => {
  return {
    'aria-controls': `${a11yName}-tabpanel-${index}`,
    id: `${a11yName}-tab-${index}`,
  };
};

const a11yTabPanelProps = (index: number) => {
  return {
    'aria-labelledby': `${a11yName}-tab-${index}`,
    id: `${a11yName}-tabpanel-${index}`,
  };
};

interface ArticlePickerRevampedProps {
  hasWorkflowConflict: boolean;
  isDisabled: boolean;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  shouldSqueezeIntoEntry: boolean;
  squeezeStepParentId: string;
}

const ArticlePickerRevamped: React.FC<
  React.PropsWithChildren<ArticlePickerRevampedProps>
> = ({
  hasWorkflowConflict,
  isDisabled,
  setActionPanelVisibilityParameters,
  shouldSqueezeIntoEntry,
  squeezeStepParentId,
}) => {
  const { palette } = useTheme();

  const currentIntentId = useSelector(selectIntentId);
  const [query, setQuery] = useState<string | null>(null);
  const [articles, setArticles] = useState<Array<Article>>([]);
  const [totalHits, setTotalHits] = useState(0);
  const [latestPage, setLatestPage] = useState(-1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const { view: currentView } = useGetBuilderQueryParams();
  const isDynamicArticleSuggestionEnabled = featureFlags.includes(
    'knowledge_article_workflow',
  );

  const actions = useSelector(state =>
    selectActionBuilderActions(state, featureFlags),
  );

  // filter twice to ensure Article Suggestion appears before Granular Article Suggestion
  const articleActions = actions
    .filter(
      action =>
        action.action_type === ActionBuilderActionTypes.ARTICLE_SUGGESTION,
    )
    .concat(
      actions.filter(
        action =>
          action.action_type ===
          ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2,
      ),
    );

  // show dynamic article suggestion in the list too if we're in the knowledge retrieval workflow
  if (
    isDynamicArticleSuggestionEnabled &&
    articleActions.length > 0 &&
    currentIntentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE
  ) {
    const dynamicArticleSuggestionAction =
      articleActions[0].action_type ===
      ActionBuilderActionTypes.ARTICLE_SUGGESTION
        ? {
            ...articleActions[0],
            action_type: ActionBuilderActionTypes.DYNAMIC_ARTICLE_SUGGESTION,
          }
        : null;
    if (dynamicArticleSuggestionAction) {
      articleActions.unshift(dynamicArticleSuggestionAction);
    }
  }

  const resCallback = (res: ArticlesResponse) => {
    setArticles(prev => prev.concat(res.articles));
    setTotalHits(res.total_hits);
    setIsSearching(false);
    setLatestPage(res.page);
    setHasMoreData(!res.is_last_page);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = React.useCallback(
    debounce(500, (query: string | null, page: number) => {
      if (page === 0) {
        setArticles([]);
      }
      setIsSearching(true);
      getArticlesAPI(query, page).then(resCallback);
    }),
    [],
  );

  // On initial load
  React.useEffect(() => {
    setIsSearching(true);
    getArticlesAPI('', 0).then(resCallback);
  }, []);

  const tabs = useMemo(() => ['Article suggestion', 'Articles'] as const, []);

  const articleSuggestion = (
    <ArticleSuggestionContainer>
      {articleActions.map(action => (
        <ArticleSuggestionActionCard
          action={action}
          hasWorkflowConflict={hasWorkflowConflict}
          isDisabled={isDisabled}
          key={action.action_name}
          setActionPanelVisibilityParameters={
            setActionPanelVisibilityParameters
          }
          squeezeStepParentId={squeezeStepParentId}
        />
      ))}
    </ArticleSuggestionContainer>
  );

  if (currentView === 'slack') {
    return articleSuggestion;
  }

  return (
    <>
      <Tabs
        a11yProps={a11yTabProps}
        aria-label='Article picker'
        ContainerProps={{
          borderBottom: '1px solid' + palette.colors.slate[200],
          mb: 2,
        }}
        onChange={(_, selectedIndex) => {
          setTabIndex(selectedIndex);
        }}
        tabs={[...tabs]}
        typographyVariant='font16Bold'
        value={tabIndex}
        variant='fullWidth'
      />
      {tabs.map((tabName, index) => (
        <div
          hidden={tabIndex !== index}
          key={index}
          role='tabpanel'
          {...a11yTabPanelProps(index)}
        >
          {tabName === 'Article suggestion'
            ? articleSuggestion
            : (articleActions.length > 0 || !!articles.length) && (
                <SearchEngineContainer>
                  <ArticlesNumber>{totalHits} articles</ArticlesNumber>
                  <SearchBar
                    onChange={e => {
                      debouncedSearch(e.target.value, 0);
                      setQuery(e.target.value);
                    }}
                    placeholder='Search articles...'
                  />
                  <Spacer height='12px' />
                  <ArticlesList
                    articles={articles}
                    hasMoreData={hasMoreData}
                    hasWorkflowConflict={hasWorkflowConflict}
                    isDisabled={isDisabled}
                    isLoading={isSearching}
                    loadNextPage={() => debouncedSearch(query, latestPage + 1)}
                    shouldSqueezeIntoEntry={shouldSqueezeIntoEntry}
                    squeezeStepParentId={squeezeStepParentId}
                    totalHits={totalHits}
                  />
                </SearchEngineContainer>
              )}
        </div>
      ))}
    </>
  );
};

export default ArticlePickerRevamped;

const SearchEngineContainer = styled('div')`
  height: 100%;
`;

const ArticleSuggestionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

const ArticlesNumber = styled('p')`
  margin: 0 0 20px 0;
  ${textBoldStyle({
    color: Colors.ui.text.primary,
    fontSize: '16px',
  })};
  line-height: 28px;
  letter-spacing: -0.1px;
`;

const Spacer = styled('div')<{ height?: string; width?: string }>`
  width: ${props => props.width || 0};
  height: ${props => props.height || 0};
`;
