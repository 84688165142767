import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CanvasWorkflowBuilderState,
  selectActionCaseMap,
  setArticleSuggestionActionCase,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice';
import BranchStepValidIndicator from '../BranchStepValidIndicator';
import TextBox from '../workflow-builder/revamped/TextBox';
import { articleWasHelpfulCase } from 'src/utils/constants';

interface ButtonOptionProps {
  canvasData: CanvasWorkflowBuilderState;
  index?: number;
  negativeCaseSelectedCallBack: () => void;
  option: {
    display_name: string;
    parent_step_id: string;
  };
  stepId: string;
  text: string;
}

const ButtonOption: React.FC<React.PropsWithChildren<ButtonOptionProps>> = ({
  canvasData,
  index,
  negativeCaseSelectedCallBack,
  option,
  stepId,
  text,
}) => {
  const dispatch = useDispatch();
  const actionCaseMap = useSelector(selectActionCaseMap);

  const successCase = option.display_name === articleWasHelpfulCase;
  const parentStepId = option.parent_step_id;
  const parentStep = canvasData['steps'][parentStepId];

  if (!parentStep) {
    return null;
  }

  const transitionStepId = parentStep.transitions[0]?.step_id || '';

  // checks whether a child step was appended to the Yes branch of the article suggestion's
  // button step. If not then transition arrary's length is 1 and it transitions to the
  // No branch. Hence we should use null insteadof transitionStepId in that case.
  let childStepExists = false;
  if (successCase) {
    if (
      parentStep.transitions.length > 1 &&
      parentStep.transitions[0]?.step_id
    ) {
      childStepExists = true;
    } else if (parentStep.transitions.length === 1) {
      childStepExists = false;
    }
  } else {
    childStepExists = parentStep.transitions.length > 0;
  }

  const handleClick = () => {
    if (option.display_name === 'No') {
      negativeCaseSelectedCallBack();
    }
    dispatch(
      setArticleSuggestionActionCase({
        stepId,
        value: successCase,
      }),
    );
  };

  const isSelected = successCase
    ? actionCaseMap?.[stepId] === true
    : actionCaseMap?.[stepId] === false;

  return (
    <TextBox
      index={index}
      isSelected={isSelected}
      onClick={handleClick}
      placeholder='Empty option'
      validIndicator={
        <BranchStepValidIndicator
          transitionBranchStepId={childStepExists ? transitionStepId : null}
          undefinedTransitionCvs={[]}
        />
      }
      value={text}
    />
  );
};

export default ButtonOption;
