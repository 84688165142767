import React from 'react';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
// Components
import AssistAutomationsEdit from 'src/components/assist-automations-edit';
import { Routes } from 'src/utils/enums';

const AssistAutomationsPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Page id={Routes.ASSIST_AUTOMATIONS_CREATE}>
      <AssistAutomationsEdit />
    </Page>
  );
};

const Page = styled('div')`
  background-color: ${theme.palette.colors.white};
  width: 100%;
`;

export default AssistAutomationsPage;
