import { useSelector } from 'react-redux';

import {
  selectActionBuilderActionsLoading,
  selectApiActions,
} from 'src/reducers/actionBuilderReducer/actionBuilderReducer';

export default function useActionBuilderActions() {
  const actions = useSelector(selectApiActions);
  const actionsLoading = useSelector(selectActionBuilderActionsLoading);

  return { actions, actionsLoading };
}
