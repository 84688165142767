import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Popover, useTheme } from '@mui/material';
import { IconVariable } from '@tabler/icons-react';

import {
  BaseFilterButton,
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetIsTemplateIntentWorkflow } from '../workflow-builder-edit/hooks';
import set from 'lodash/fp/set';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  selectPreviewWorkflowCvs,
  setPreviewWorkflowCvs,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';
import { getWorkflowContextParams } from 'src/utils/actionBuilder/helpers';

export const InputCvForm = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isTemplate = useGetIsTemplateIntentWorkflow();
  const { contextVariables } = useGetContextVariables({
    shouldIncludeTemplateContextVariables: isTemplate,
  });

  const availableWorkflowCvs = getWorkflowContextParams(
    contextVariables,
  ).filter(cvName => cvName !== 'Conversation Language');
  const savedWorkflowCvs = useSelector(selectPreviewWorkflowCvs);
  const initialFields = availableWorkflowCvs.map(cvName => {
    const cv = savedWorkflowCvs.find(cv => cv.name === cvName);
    return {
      name: cvName,
      value: cv ? cv.value : '',
    };
  });

  const [fields, setFields] = useState(initialFields);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const displayMsg = savedWorkflowCvs
    .map(cv => cv.name + ': ' + cv.value)
    .join(',');

  const onClose = () => {
    setAnchorEl(null);
    setFields([]);
  };

  const onClear = () => {
    setFields([]);
    dispatch(setPreviewWorkflowCvs([]));
  };

  const setField = (
    index: number,
    updatedValue: { name: string; value: string },
  ) => {
    setFields(set([index], updatedValue, fields));
  };

  return (
    <>
      <BaseFilterButton
        aria-label='pre-defined context variable input button'
        isOpen={isOpen}
        onClear={onClear}
        onClick={e => {
          setAnchorEl(e.currentTarget);
          setFields(initialFields);
        }}
        overflowTooltipContent={displayMsg}
        showCaret={!Boolean(displayMsg)}
        startAdornment={
          <IconVariable
            color={
              theme.palette.mode === 'dark'
                ? theme.palette.grey[500]
                : theme.palette.colors.grey[700]
            }
            size={20}
          />
        }
        value={displayMsg === '' ? 'Fill in pre-defined context' : displayMsg}
      />
      <Popover
        anchorEl={anchorEl}
        elevation={2}
        onClose={onClose}
        open={isOpen}
        sx={{ top: '40px' }}
      >
        <Paper
          elevation={2}
          sx={{
            border: '1px solid' + theme.palette.colors.grey[300],
            borderRadius: '4px',
            width: '382px',
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='12px'
            maxHeight='410px'
            overflow='scroll'
            padding='12px 16px'
          >
            <Typography color={theme.palette.colors.grey[500]} variant='font14'>
              Enter pre-defined context to see how the widget responds, matched
              intents and parameters.
            </Typography>
            <Box display='flex' flexDirection='column' gap='16px'>
              {fields.map((field, index) => {
                return (
                  <TextField
                    key={field.name}
                    label={field.name}
                    onChange={e => {
                      setField(index, {
                        name: field.name,
                        value: e.target.value,
                      });
                    }}
                    value={fields[index].value}
                  />
                );
              })}
            </Box>
          </Box>
          <Box
            alignItems='center'
            borderTop={`1px solid ${theme.palette.colors.slate[200]}`}
            display='flex'
            justifyContent='center'
            padding='8px 0'
          >
            <Button
              fullWidth
              onClick={() => {
                dispatch(
                  setPreviewWorkflowCvs(
                    fields.filter(field => field.value.trimEnd() !== ''),
                  ),
                );
                setAnchorEl(null);
              }}
              variant='ghost'
            >
              Done
            </Button>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
