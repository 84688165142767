import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import chatIcon from 'src/assets/images/chat-icon.svg';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';

export const LaunchButton = ({ className }: { className?: string }) => {
  const { help_button_image, theme_color } = useSelector(
    selectWidgetConfiguration,
  );

  return help_button_image ? (
    <CustomImage alt='' className={className} src={help_button_image} />
  ) : (
    <PreviewButton background={theme_color || ''} className={className}>
      <PreviewImg alt='' src={chatIcon} />
    </PreviewButton>
  );
};

const PreviewImg = styled('img')`
  width: 34px;
  height: 43px;
`;

const PreviewButton = styled('div')<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${props => props.background};
`;

const CustomImage = styled('img')`
  max-height: 60px;
`;
