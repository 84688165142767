import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import ActiveAutomationsPage from '../active-automations-page/ActiveAutomationsPage';
import AutomationListItem from '../automation-list-item/AutomationListItem';
import cloneDeep from 'lodash/fp/cloneDeep';
import partition from 'lodash/fp/partition';
import { useGetEmailAutomationsQuery } from 'src/services/email-automation/emailAutomationApi';

interface AutomationsListPageProps {
  /**Determines if the active tab is selected */
  isActiveSection: boolean;
}

const AutomationsListPage = ({ isActiveSection }: AutomationsListPageProps) => {
  const { data } = useGetEmailAutomationsQuery();

  const automationsList = data?.email_automations || [];

  const [activeAutomations, inactiveAutomations] = partition(
    automation => automation.is_active,
    automationsList,
  );

  const [activeAutomationsList, setActiveAutomationsList] =
    useState(activeAutomations);

  useEffect(() => {
    const automationsList = data?.email_automations || [];
    const filteredAutomations = automationsList.filter(
      automation => automation.is_active,
    );
    setActiveAutomationsList(filteredAutomations);
  }, [data?.email_automations]);

  const getMessage = () => {
    if (isActiveSection) {
      return !!activeAutomations.length
        ? 'Order your automations by priority. The first automation rule that satisfies the condition will be executed'
        : 'You have no active automations';
    } else {
      return !inactiveAutomations.length && 'You have no inactive automations';
    }
  };

  const automationsMessage = getMessage();

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display='flex' flexDirection='column' gap='28px' height='100%'>
        {automationsMessage && (
          <Typography variant='font14Medium'>{automationsMessage}</Typography>
        )}
        {isActiveSection ? (
          <ActiveAutomationsPage
            automationsList={activeAutomationsList}
            setActiveAutomationsOrder={(dragIndex, dropIndex) => {
              setActiveAutomationsList(list => {
                const copy = cloneDeep(list);
                const component = copy[dragIndex];
                copy.splice(dragIndex, 1);
                copy.splice(dropIndex, 0, component);
                return copy;
              });
            }}
          />
        ) : (
          <Box display='flex' flex='1' flexDirection='column' gap='8px'>
            {inactiveAutomations.map((automation, index) => (
              <AutomationListItem
                emailAutomation={automation}
                index={index}
                key={automation.email_automation_id}
              />
            ))}
          </Box>
        )}
      </Box>
    </DndProvider>
  );
};

export default AutomationsListPage;
