import Box from '@mui/material/Box';

import { TextField } from '@forethought-technologies/forethought-elements';
import { OutputFieldInputType } from '../types';
import TriageConfigDetailLinkText from './TriageConfigDetailLinkText';

interface TriageNewPredictedFieldInputComponentProps {
  isTextPredictedFieldUnique: boolean;
  outputFieldTextInputValue: string;
  setOutputFieldInputType: (value: OutputFieldInputType) => void;
  setOutputFieldTextInputValue: (value: string) => void;
}

const TriageNewPredictedFieldInputComponent = ({
  isTextPredictedFieldUnique,
  outputFieldTextInputValue,
  setOutputFieldInputType,
  setOutputFieldTextInputValue,
}: TriageNewPredictedFieldInputComponentProps) => {
  return (
    <>
      <Box id='create-new-field-text'>
        <TextField
          aria-label='new field'
          description='This field will be created in the selected integration'
          error={
            isTextPredictedFieldUnique
              ? undefined
              : 'This field already exists in the selected integration'
          }
          onChange={e => setOutputFieldTextInputValue(e.target.value)}
          placeholder='Enter new field name'
          value={outputFieldTextInputValue}
        />
      </Box>
      <TriageConfigDetailLinkText
        onClick={() => setOutputFieldInputType('existing-field')}
      >
        Select an existing field instead
      </TriageConfigDetailLinkText>
    </>
  );
};

export default TriageNewPredictedFieldInputComponent;
