import { createSlice } from '@reduxjs/toolkit';

import { ListOption } from '@forethought-technologies/forethought-elements';
import {
  getDataSourcePredictedField,
  getFieldPredictorOutputField,
} from './helpers';
import {
  CurrentOrgHelpdeskDetail,
  HelpdeskDetail,
  TriageSettingsState,
} from './types';
import {
  getHelpdesk,
  getPredictedFields,
  getTriageModelByModelName,
  getTriageModelList,
  updatePredictedFieldOfTriageModelByModelName,
  updateStatusOfTriageModelByModelName,
} from 'src/actions/triageSettings/triageSettings';
import { Helpdesk } from 'src/components/app/types';
import { getIsModelPremium } from 'src/components/dashboard-pages/triage-config-detail-page/helpers';
import type { RootState } from 'src/store/rootReducer';

export const initialState: TriageSettingsState = {
  error: null,
  focusedTriageModel: null,
  helpdeskIntegrations: null,
  isFocusedTriageModelGetLoading: false,
  isFocusedTriageModelPostLoading: false,
  isHelpdeskIntegrationsLoading: false,
  loading: false,
  ticketFields: { data: null, loading: true },
  // specifically used to refresh quota widget
  triageConfigUpdatedCounter: 0,
  triageModels: [],
};

const triageSettingsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(getTriageModelList.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getTriageModelList.rejected, state => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getTriageModelList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      if (Array.isArray(payload)) {
        state.triageModels = payload;
      }
    });
    builder.addCase(getTriageModelByModelName.pending, state => {
      state.isFocusedTriageModelGetLoading = true;
      state.error = null;
    });
    builder.addCase(getTriageModelByModelName.rejected, state => {
      state.isFocusedTriageModelGetLoading = false;
      state.error = null;
    });
    builder.addCase(
      getTriageModelByModelName.fulfilled,
      (state, { payload }) => {
        state.isFocusedTriageModelGetLoading = false;
        state.error = null;
        state.focusedTriageModel = payload;
      },
    );
    builder.addCase(getHelpdesk.pending, state => {
      state.isHelpdeskIntegrationsLoading = true;
      state.error = null;
    });
    builder.addCase(getHelpdesk.rejected, state => {
      state.isHelpdeskIntegrationsLoading = false;
      state.error = null;
    });
    builder.addCase(getHelpdesk.fulfilled, (state, { payload }) => {
      state.isHelpdeskIntegrationsLoading = false;
      state.error = null;
      state.helpdeskIntegrations = payload;
    });
    builder.addCase(getPredictedFields.pending, state => {
      state.ticketFields.loading = true;
      state.error = null;
    });
    builder.addCase(getPredictedFields.rejected, state => {
      state.ticketFields.loading = false;
      state.error = null;
    });
    builder.addCase(getPredictedFields.fulfilled, (state, { payload }) => {
      state.ticketFields.loading = false;
      state.error = null;
      state.ticketFields.data = payload;
    });
    builder.addCase(
      updatePredictedFieldOfTriageModelByModelName.pending,
      state => {
        state.isFocusedTriageModelPostLoading = true;
        state.error = null;
      },
    );
    builder.addCase(
      updatePredictedFieldOfTriageModelByModelName.rejected,
      (state, action) => {
        if (action.payload) {
          state.error = action.payload.error_type;
        } else {
          state.error = action.error.message ? 'DEFAULT' : null;
        }
        state.isFocusedTriageModelPostLoading = false;
      },
    );
    builder.addCase(
      updatePredictedFieldOfTriageModelByModelName.fulfilled,
      (state, { payload }) => {
        state.isFocusedTriageModelPostLoading = false;
        state.error = null;
        state.focusedTriageModel = payload;
        state.triageConfigUpdatedCounter++;
      },
    );
    builder.addCase(updateStatusOfTriageModelByModelName.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateStatusOfTriageModelByModelName.rejected, state => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(
      updateStatusOfTriageModelByModelName.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.focusedTriageModel = payload;
      },
    );
  },
  initialState,
  name: 'triageSettings',
  reducers: {},
});

export const selectTriageModels = (
  state: RootState,
): TriageSettingsState['triageModels'] => state.triageSettings.triageModels;

export const selectFocusedTriageModel = (
  state: RootState,
): TriageSettingsState['focusedTriageModel'] =>
  state.triageSettings.focusedTriageModel;

export const selectHelpdesk = (
  state: RootState,
): CurrentOrgHelpdeskDetail | null =>
  state.triageSettings.helpdeskIntegrations?.helpdesk_detail ?? null;

export const selectIsHelpdeskSupported = (state: RootState): boolean =>
  !!state.triageSettings.helpdeskIntegrations?.helpdesk_detail
    .is_quickstart_supported;

export const selectTicketFields = (
  state: RootState,
): TriageSettingsState['ticketFields'] => state.triageSettings.ticketFields;

export const selectHasMinimalPermission = (state: RootState): boolean =>
  // @ts-expect-error legacy code with untyped state
  state.triageSettings.ticketFields.data?.ticket_fields?.permissions
    ?.has_minimal_permission !== false;

const selectFieldPredictorOutputField = (
  state: RootState,
): Record<string, string> | undefined => {
  const helpdesk = selectHelpdesk(state);

  if (!helpdesk) {
    return;
  }

  return getFieldPredictorOutputField(
    helpdesk.name,
    // @ts-expect-error legacy code with untyped state
    state.triageSettings.focusedTriageModel?.field_predictor_data,
  );
};

export const selectSelectedDataSourcePredictedField = (
  state: RootState,
): ListOption | undefined => {
  const fieldPredictorOutputField = selectFieldPredictorOutputField(state);
  const { data: ticketFieldsData } = selectTicketFields(state);

  const id = Object.keys(fieldPredictorOutputField || {})[0];

  return getDataSourcePredictedField(id, ticketFieldsData?.ticket_fields);
};

/**
 * Salesforce premium triage model has very different use case for output field
 * it needs to just print out all keys from the triage config (not just one), '@value' may not even be used
 */
export const selectSelectedPremiumSalesforceDataSourcePredictedFields = (
  state: RootState,
): string[] => {
  const helpdesk = selectHelpdesk(state);
  const fieldPredictorOutputField = selectFieldPredictorOutputField(state);
  const isPremium = getIsModelPremium(
    state.triageSettings.focusedTriageModel?.model_paid_plan,
  );

  if (helpdesk?.name === 'salesforce' && isPremium) {
    return Object.keys(fieldPredictorOutputField || {});
  }

  return [];
};

export type IntegrationSelectedStates =
  | 'selected'
  | 'not-selected'
  | 'loading'
  | 'error';

export const selectIntegrationSelectedState = (
  state: RootState,
): IntegrationSelectedStates => {
  const selectedDataSourcePredictedField =
    selectSelectedDataSourcePredictedField(state);
  const fieldPredictorOutputField = selectFieldPredictorOutputField(state);
  if (
    state.triageSettings.ticketFields.loading ||
    state.triageSettings.isFocusedTriageModelPostLoading
  ) {
    return 'loading';
  }

  if (!fieldPredictorOutputField) {
    return 'not-selected';
  }

  return selectedDataSourcePredictedField ? 'selected' : 'error';
};

export const selectTriageConfigUpdatedCounter = (
  state: RootState,
): TriageSettingsState['triageConfigUpdatedCounter'] =>
  state.triageSettings.triageConfigUpdatedCounter;

export const selectIsFocusedTriageModelGetLoading = (
  state: RootState,
): boolean => {
  return state.triageSettings.isFocusedTriageModelGetLoading;
};
export const selectIsFocusedTriageModelPostLoading = (
  state: RootState,
): boolean => {
  return state.triageSettings.isFocusedTriageModelPostLoading;
};
export const selectError = (state: RootState): TriageSettingsState['error'] => {
  return state.triageSettings.error;
};

export const selectIsHelpdeskNotConfigured = (state: RootState): boolean =>
  !state.triageSettings.helpdeskIntegrations?.helpdesk_detail
    .is_current_helpdesk_configured;

export const selectCreatedHelpdeskConnectors = (
  state: RootState,
): HelpdeskDetail[] =>
  state.triageSettings.helpdeskIntegrations?.created_helpdesk_connectors ?? [];

export const selectIsHelpdeskIntegrationsLoading = (
  state: RootState,
): boolean => state.triageSettings.isHelpdeskIntegrationsLoading;

export const selectSupportedHelpdesks = (state: RootState): Helpdesk[] =>
  state.triageSettings.helpdeskIntegrations?.supported_helpdesks ?? [];

export const selectFieldPredictorInputOrigins = (
  state: RootState,
): string[] | null => {
  const [input] =
    state.triageSettings.focusedTriageModel?.field_predictor_data
      ?.model_inputs ?? [];

  // @ts-expect-error legacy code with untyped state
  return input?.schema?.properties?.Origin?.enum ?? null;
};

export const selectPremiumObjectName = (state: RootState): string => {
  const [output] =
    state.triageSettings.focusedTriageModel?.field_predictor_data?.outputs ??
    [];

  return output?.object_name ?? 'Case';
};

export default triageSettingsSlice.reducer;
