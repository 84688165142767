import { Box } from '@mui/material';

import {
  formatUnixTimestampToDateAndTime,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';

interface DiscoverLastUpdatedProps {
  lastUpdateAt: number;
}

const DiscoverLastUpdated = ({ lastUpdateAt }: DiscoverLastUpdatedProps) => (
  <Box alignItems='center' display='flex' ml='auto'>
    <Typography color={theme.palette.colors.grey[600]} variant='font12'>
      Last updated at {formatUnixTimestampToDateAndTime(lastUpdateAt)}
    </Typography>
  </Box>
);

export default DiscoverLastUpdated;
