import linkifyHtml from 'linkify-html';
import { styled } from '@mui/material';

import { IdleCanvasComponentProps } from 'src/components/email-builder/types';

export default function IntentEmailHtmlIdle({
  component,
}: IdleCanvasComponentProps) {
  const { content } = component.component_fields;

  return (
    <Container
      dangerouslySetInnerHTML={{
        __html: linkifyHtml(content, {
          rel: 'noreferrer',
          target: '_blank',
          truncate: 42,
        }),
      }}
    />
  );
}

const Container = styled('div')`
  a {
    text-decoration: underline;
  }
  padding: 16px;
`;
