import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import { theme } from '@forethought-technologies/forethought-elements';
import LoadingSpinner from 'src/components/reusable-components/loading-spinner/loadingSpinner';
import { getSolveEmbeddedDashboardURL } from 'src/services/workflow-builder/workflowBuilderApi';

const SolveAnalyticsEmbedPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [embedURL, setEmbedURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getSolveEmbeddedDashboardURL()
      .then(res => setEmbedURL(res.embed_url))
      .catch(() => setErrorMessage('Error retrieving dashboard'));
  }, []);

  if (errorMessage) {
    return (
      <Container>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Container>
    );
  }

  return (
    <Container>
      {!embedURL ? <LoadingSpinner /> : <EmbedFrame src={embedURL} />}
    </Container>
  );
};

export default SolveAnalyticsEmbedPage;

const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: calc(100vh - 57px); // screen height - nav header height
`;

const EmbedFrame = styled('iframe')`
  display: flex;
  flex: 1;
  border: none;
`;

const ErrorMessage = styled('h1')`
  color: ${theme.palette.colors.red[500]};
  margin: auto;
`;
