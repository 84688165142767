import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { useGetColorMode, useGetTemplatePreviewMessage } from '../hooks';
import {
  convertIntentFiltersToDict,
  getIframeUrl,
  useGetIntentFiltersMode,
} from '../utils';
import { TEMPLATE_INTENT_WORKFLOW_ID_PREFIX } from 'src/pages/workflow-builder-edit/constants';
import { useGetIsTemplateIntentWorkflow } from 'src/pages/workflow-builder-edit/hooks';
import { useGetPreviewTokenQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import {
  selectPreviewIntentFilters,
  selectPreviewLanguage,
  selectPreviewWorkflowCvs,
  selectPreviewWorkflowTag,
} from 'src/slices/workflow-preview/workflowPreviewSlice';

export const WorkflowPreviewWidget = () => {
  const theme = useTheme();
  const location = useLocation();
  const { data: previewTokenData } = useGetPreviewTokenQuery();
  const { preview_api_token: previewToken = '' } = previewTokenData ?? {};
  const selectedLanguage = useSelector(selectPreviewLanguage);
  const selectedTag = useSelector(selectPreviewWorkflowTag);
  const workflowCvs = useSelector(selectPreviewWorkflowCvs);
  const currentIntentFilters = useSelector(selectPreviewIntentFilters);
  const colorMode = useGetColorMode();
  const intentFiltersMode = useGetIntentFiltersMode();

  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');
  const product = params.get('product');
  const isTemplate = useGetIsTemplateIntentWorkflow();

  const previewMessages = useGetTemplatePreviewMessage(
    previewFromIntentId ?? '',
  );

  // iframe src URL with all the configs passed in as attributes
  const iframeURL = getIframeUrl({
    isTemplate,
    language: selectedLanguage,
    previewToken,
    product,
    tag: selectedTag,
    templateIntentId: previewFromIntentId?.startsWith(
      TEMPLATE_INTENT_WORKFLOW_ID_PREFIX,
    )
      ? previewFromIntentId
      : undefined,
    workflowCvs,
  });

  const postIntentFiltersToIFrame = useCallback(() => {
    const previewIframe = document.getElementById(
      'preview-iframe',
    ) as HTMLIFrameElement;

    const intentFilterDict = convertIntentFiltersToDict(currentIntentFilters);
    previewIframe?.contentWindow?.postMessage(
      {
        event: 'updateIntentFiltersForPreview',
        intentFiltersForPreview: intentFilterDict,
        isLiveMode:
          intentFiltersMode === 'live-traffic' ||
          intentFiltersMode === 'current-intent-and-live-traffic',
      },
      '*',
    );
  }, [currentIntentFilters, intentFiltersMode]);

  useEffect(() => {
    postIntentFiltersToIFrame();
  }, [postIntentFiltersToIFrame]);

  return (
    <LeftSection colorMode={colorMode}>
      <Box
        alignItems='center'
        display='flex'
        flex={1}
        flexDirection='column'
        justifyContent='center'
      >
        <Box
          display='flex'
          flex={1}
          height='100%'
          id='iframe-container'
          justifyContent='center'
          maxHeight='100%'
          maxWidth='100%'
          sx={{
            '#preview-iframe': {
              border: 'none',
              height: '100%',
              maxWidth: '570px',
              width: '100%',
            },
          }}
          width='100%'
        >
          <iframe
            id='preview-iframe'
            key={iframeURL}
            onLoad={() => {
              // slight delay to ensure solve-ui is ready to receive the filters
              setTimeout(postIntentFiltersToIFrame, 100);
            }}
            src={iframeURL}
          />
        </Box>

        {previewMessages?.map(
          (message, index) =>
            message && (
              <Box
                key={index}
                maxWidth='600px'
                mb={index === previewMessages.length - 1 ? 2 : 0.5}
                mt={index === 0 ? 2 : 0.5}
                width='100%'
              >
                <Typography
                  color={theme.palette.colors.white}
                  variant='font16Bold'
                >
                  {message}
                </Typography>
              </Box>
            ),
        )}
      </Box>
    </LeftSection>
  );
};

const LeftSection = styled('div')<{ colorMode: string }>`
  display: flex;
  flex-direction: column;
  padding: 16px 40px 24px 40px;
  flex: 1;
  ${props => props.colorMode === 'light' && 'background: #dfe3ed;'};
`;
