import { useEffect } from 'react';
import moment from 'moment';

import { useLocalStorage } from 'src/hooks/useLocalStorage';

let intervalId: number | null = null;

export const usePublishedTimestamp = ({
  checkInterval = moment.duration({ seconds: 10 }).asMilliseconds(),
  wait = moment.duration({ minutes: 5 }).asMilliseconds(),
}: {
  checkInterval?: number;
  wait?: number;
} = {}) => {
  const [publishedTimestamp, setPublishedTimestamp] = useLocalStorage(
    'solveWidgetConfigPublishedTimestamp',
    -1,
  );

  useEffect(() => {
    if (publishedTimestamp === -1) {
      return;
    }

    const updateIfEnoughTimePassed = () => {
      const currentTimestamp = Date.now();

      if (currentTimestamp - publishedTimestamp < wait) {
        return;
      }

      setPublishedTimestamp(-1);

      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };

    intervalId = window.setInterval(updateIfEnoughTimePassed, checkInterval);

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [publishedTimestamp, setPublishedTimestamp, checkInterval, wait]);

  return { publishedTimestamp, setPublishedTimestamp };
};
