import React from 'react';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import DiscoverHistogramFilterButton from '../components/DiscoverHistogramFilterButton';
import { HistogramFilters, SelectedHistogramFilter } from '../types';
import { ColumnItem } from './constants';
import DiscoverMetricTooltip from 'src/components/discover-tooltip/DiscoverMetricTooltip';
import { DiscoverTopicAggregatedMetricType } from 'src/reducers/discoverReducer/types';

export type ColumnHeaderItemProps = {
  histogram: {
    histogramFilters: HistogramFilters;
    isHistogramFilterOpen?: boolean;
    selectedHistogramFilter: SelectedHistogramFilter;
    setHistogramFilters: React.Dispatch<
      React.SetStateAction<
        Partial<
          Record<
            DiscoverTopicAggregatedMetricType,
            Partial<
              Record<'value' | 'value_changed' | 'value_deviance', number[]>
            >
          >
        >
      >
    >;
    setSelectedHistogramFilter: React.Dispatch<
      React.SetStateAction<SelectedHistogramFilter>
    >;
  };
  isSentiment?: boolean;
  item: ColumnItem;
  setAnchorElement: (element: HTMLElement | null) => void;
};

const getMetricType = (props: ColumnHeaderItemProps) => {
  if (props.item.isPercentChange) {
    return 'value_changed';
  }
  if (props.item.accessorKey === 'drop_in_sentiment') {
    return 'negative_change';
  }
  return props.item.accessorKey;
};

const handleSentimentMetricUnit = (accessorKey: string) => {
  if (accessorKey === 'starting_sentiment') {
    return 'value';
  }
  if (accessorKey === 'ending_sentiment') {
    return 'value_changed';
  }
  return 'value_deviance';
};

const ColumnHeaderItem = (props: ColumnHeaderItemProps) => {
  const metric = getMetricType(props) as DiscoverTopicAggregatedMetricType;
  const metricUnit = (() => {
    if (props.item.isSentiment) {
      return handleSentimentMetricUnit(props.item.accessorKey);
    }

    return props.item.isPercentChange ? 'value_changed' : 'value';
  })();
  const targetHistogram = (() => {
    const filters = props.histogram.histogramFilters;
    if (props.item.isSentiment) {
      const targetKey = handleSentimentMetricUnit(props.item.accessorKey);
      return filters.sentiment ? filters.sentiment[targetKey] : undefined;
    }
    if (props.item.isPercentChange && props.item.relatedKey) {
      return filters[props.item.relatedKey as DiscoverTopicAggregatedMetricType]
        ?.value_changed;
    }
    return filters[props.item.accessorKey as DiscoverTopicAggregatedMetricType]
      ?.value;
  })();
  const metricType = (() => {
    if (props.item.isSentiment) {
      return 'sentiment';
    }
    return props.item.relatedKey || props.item.accessorKey;
  })() as DiscoverTopicAggregatedMetricType;

  return (
    <Box alignItems='center' columnGap={0.5} display='flex' whiteSpace='wrap'>
      <Typography variant='font14'>{props.item.header}</Typography>
      <DiscoverMetricTooltip
        isLight={props.item.isPercentChange}
        metricType={metric}
      />
      <DiscoverHistogramFilterButton
        comparisonType={metricUnit}
        handleOnClick={e => props.setAnchorElement(e.currentTarget)}
        histogramRangeValue={targetHistogram}
        isFocused={props.histogram.selectedHistogramFilter.type === metricUnit}
        isHistogramFilterOpen={Boolean(props.histogram.isHistogramFilterOpen)}
        metricType={metricType}
        setSelectedHistogramFilter={props.histogram.setSelectedHistogramFilter}
      />
    </Box>
  );
};

export default ColumnHeaderItem;
