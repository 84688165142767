import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';

import { Colors, theme } from '@forethought-technologies/forethought-elements';
import { ForethoughtEditActionMenu } from './edit-menu/ForethoughtEditActionMenu';
import { ActionStep as ActionStepRevamped } from './workflow-builder/revamped';
import { DynamicArticleSuggestionStep } from './DynamicArticleSuggestionStep';
import { GranularArticleSuggestionV2Step } from './GranularArticleSuggestionV2Step';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import downArrowIcon from 'src/pages/workflow-builder-edit/assets/icons/down-arrow.svg';
import { selectCustomizableActionId } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import { selectUserCan } from 'src/reducers/userReducer/userReducer';
import { selectIsActionDynamicArticleSuggestion } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { CanvasWorkflowBuilderState } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { Action } from 'src/types/actionBuilderApiTypes';
import { ActionStepList } from 'src/types/workflowBuilderAPITypes';
import {
  areTransitionBranchesFilled,
  canDynamicArticleSuggestionBeDeleted,
} from 'src/utils/areTransitionBranchesFilled';
import { ActionBuilderActionTypes } from 'src/utils/enums';
import { isGranularArticleSuggestionV2Deletable } from 'src/utils/granularArticleSuggestionV2Utils';

interface ActionStepProps {
  actionIdToComponent: Record<string, ActionStepList>;
  actionKey: string;
  availableAction: Action;
  canvasData: CanvasWorkflowBuilderState;
  isCanvasDisabled: boolean;
  onCustomizeClick: () => void;
  setShouldShowEditMenu: (shouldShow: boolean) => void;
  shouldShowCustomizeButton: boolean;
  shouldShowEditMenu: boolean;
  stepId: string;
}

const ActionStep: React.FC<React.PropsWithChildren<ActionStepProps>> = ({
  actionIdToComponent,
  actionKey,
  availableAction,
  canvasData,
  isCanvasDisabled,
  onCustomizeClick,
  setShouldShowEditMenu,
  shouldShowCustomizeButton,
  shouldShowEditMenu,
  stepId,
}) => {
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  const userCanDeleteAction = useSelector(
    selectUserCan('delete_canvas_action'),
  );
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isDynamicArticleSuggestion = useSelector(
    selectIsActionDynamicArticleSuggestion(undefined, actionKey),
  );
  const isDynamicArticleSuggestionMode =
    featureFlags.includes('knowledge_article_workflow') &&
    isDynamicArticleSuggestion;

  const customizableActionId = useSelector(selectCustomizableActionId);

  const { steps, transition_branches: transitionBranches } =
    actionIdToComponent[actionKey];

  const isDeletionDisabled = () => {
    if (
      availableAction.action_type ===
      ActionBuilderActionTypes.ARTICLE_SUGGESTION
    ) {
      if (isDynamicArticleSuggestion) {
        return !canDynamicArticleSuggestionBeDeleted(
          canvasData,
          transitionBranches || [],
        );
      } else {
        return areTransitionBranchesFilled(
          canvasData,
          transitionBranches || [],
        );
      }
    }

    if (
      availableAction.action_type ===
      ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2
    ) {
      return !isGranularArticleSuggestionV2Deletable(steps);
    }
    return false;
  };

  const highlightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (actionKey === canvasData.targetActionId) {
      highlightRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [canvasData.targetActionId, actionKey]);

  return (
    <ActionContainer
      className={`revamped-action-container ${
        actionKey === customizableActionId ? 'editing' : ''
      }`}
      isHighlight={actionKey === canvasData.targetActionId}
      ref={highlightRef}
    >
      {availableAction.action_type ===
      ActionBuilderActionTypes.GRANULAR_ARTICLE_SUGGESTION_V2 ? (
        <GranularArticleSuggestionV2Step
          actionId={availableAction.action_id}
          allStepsWithinAction={steps}
          isCanvasDisabled={isCanvasDisabled}
          stepId={stepId}
          steps={steps}
        />
      ) : availableAction.action_type ===
          ActionBuilderActionTypes.ARTICLE_SUGGESTION &&
        isDynamicArticleSuggestionMode ? (
        <DynamicArticleSuggestionStep
          allStepsWithinAction={steps}
          isBeingEdited={actionKey === customizableActionId}
          isCanvasDisabled={isCanvasDisabled}
          stepId={stepId}
          transitionBranches={transitionBranches}
        />
      ) : (
        <ActionStepRevamped
          action={availableAction}
          contextVariables={contextVariables}
          stepId={stepId}
          steps={steps}
        />
      )}
      {(availableAction.action_type ===
        ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2 ||
        availableAction.action_type ===
          ActionBuilderActionTypes.SALESFORCE_CHAT_HANDOFF ||
        availableAction.action_type ===
          ActionBuilderActionTypes.SNAPENGAGE_CHAT_HANDOFF ||
        availableAction.action_type ===
          ActionBuilderActionTypes.GORGIAS_CHAT_HANDOFF ||
        availableAction.action_type ===
          ActionBuilderActionTypes.ZENDESK_MESSAGING_HANDOFF ||
        availableAction.action_type ===
          ActionBuilderActionTypes.FRESHCHAT_CHAT_HANDOFF ||
        availableAction.action_type ===
          ActionBuilderActionTypes.SALESFORCE_MESSAGING_HANDOFF ||
        availableAction.action_type ===
          ActionBuilderActionTypes.SUNCO_LIVE_CHAT ||
        availableAction.action_type ===
          ActionBuilderActionTypes.LIVE_CHAT_CHAT_HANDOFF ||
        availableAction.action_type ===
          ActionBuilderActionTypes.KUSTOMER_CHAT_HANDOFF ||
        availableAction.action_type ===
          ActionBuilderActionTypes.FORETHOUGHT_LIVE_CHAT_HANDOFF) && (
        <DescriptionAndIcon>
          <span>When chat handoff unsuccessful...</span>
          <ReactSVG src={downArrowIcon} />
        </DescriptionAndIcon>
      )}
      {!isCanvasDisabled && userCanDeleteAction && (
        <ForethoughtEditActionMenu
          actionType={availableAction.action_type}
          isDeletionDisabled={isDeletionDisabled()}
          isVisible={shouldShowEditMenu}
          onCustomizeClick={onCustomizeClick}
          setIsVisible={setShouldShowEditMenu}
          shouldShowCustomizeButton={shouldShowCustomizeButton}
          stepId={stepId}
        />
      )}
    </ActionContainer>
  );
};

export const ActionChat = styled('div')`
  border: 1px solid ${theme.palette.colors.slate[200]};
  border-radius: 22px;
  text-align: center;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 20px;
  width: 472px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ActionContainer = styled('div')<{ isHighlight?: boolean }>`
  ${props =>
    props.isHighlight &&
    `background-color: ${props.theme.palette.colors.blue[200]}`}
`;

export const Spacer = styled('div')<{
  height?: string;
  width?: string;
}>`
  height: ${props => props.height || 0};
  width: ${props => props.width || 0};
`;

const DescriptionAndIcon = styled('div')`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${theme.typography.font11.fontSize};
  font-weight: var(--font-weight-bold);
  line-height: ${theme.typography.font11.lineHeight};
  color: ${Colors.ui.text.tertiary};
`;

export default ActionStep;
