import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { IconChevronUp } from '@tabler/icons-react';

import { useGetBuilderQueryParams } from '../hooks';
import { useWorkflowInvalidMessages } from '../hooks';
import { ProductBanner } from './ProductBanner';
import { WorkflowBuilderBanner } from './WorkflowBuilderBanner';
import warningIcon from 'src/assets/images/circular-warning-icon.svg';
import { selectActions } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import { selectIsDuplicatedPendingCustomization } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';

export const WorkflowMessageBanner = () => {
  const isDuplicatedPendingCustomization = useSelector(
    selectIsDuplicatedPendingCustomization,
  );
  const [isOpen, setIsOpen] = useState(false);

  const {
    invalidIntentNames,
    isAllBranchesFilled,
    isLoading,
    undefinedContextVariableNames,
    unsupportedStepNames,
  } = useWorkflowInvalidMessages();
  const loadingActions = !useSelector(selectActions)?.length;
  const { view } = useGetBuilderQueryParams();

  if (loadingActions) {
    // prevents race condition with actions and the loading WF causing a flash
    return null;
  }

  if (
    (isAllBranchesFilled &&
      !undefinedContextVariableNames &&
      !unsupportedStepNames &&
      !invalidIntentNames) ||
    isDuplicatedPendingCustomization
  ) {
    if (view === 'widget') {
      return null;
    }

    return (
      <Box alignItems='center' display='flex' justifyContent='center'>
        <ProductBanner width='600px' />
      </Box>
    );
  }

  return (
    <Box alignItems='center' display='flex' justifyContent='center'>
      <WorkflowBuilderBanner width='600px'>
        <Box display='flex' flexDirection='column'>
          <Box alignItems='center' display='flex' gap={1.5}>
            <img alt='Workflow needs changes' src={warningIcon} />
            <Box flex={1}>
              <Typography
                color={theme => theme.palette.text.primary}
                variant='font14Bold'
              >
                Before you publish, please make the following changes:
              </Typography>
            </Box>
            <IconButton
              onClick={() => setIsOpen(!isOpen)}
              sx={{ padding: '4px' }}
            >
              <IconChevronUp
                style={isOpen ? { transform: 'rotate(180deg)' } : undefined}
              />
            </IconButton>
          </Box>
          <Collapse
            in={isOpen}
            sx={{
              pl: '32px',
            }}
          >
            <Box component='ul' margin={0} padding={0}>
              {!isAllBranchesFilled && (
                <Typography component='li' variant='font14'>
                  Ensure that all branches have been filled
                </Typography>
              )}
              {Boolean(undefinedContextVariableNames) && (
                <Typography component='li' variant='font14'>
                  Ensure collection of{' '}
                  <Typography
                    color={theme => theme.palette.colors.red[500]}
                    variant='font14'
                  >
                    {undefinedContextVariableNames}
                  </Typography>
                </Typography>
              )}
              {Boolean(unsupportedStepNames) && (
                <Typography component='li' variant='font14'>
                  Ensure deletion of{' '}
                  <Typography
                    color={theme => theme.palette.colors.red[500]}
                    variant='font14'
                  >
                    {isLoading ? (
                      <Skeleton
                        sx={{ display: 'inline-block' }}
                        variant='text'
                        width='150px'
                      />
                    ) : (
                      unsupportedStepNames
                    )}
                  </Typography>
                </Typography>
              )}
              {Boolean(invalidIntentNames) && (
                <Typography component='li' variant='font14'>
                  Ensure activation of{' '}
                  <Typography
                    color={theme => theme.palette.colors.red[500]}
                    variant='font14'
                  >
                    {isLoading ? (
                      <Skeleton
                        sx={{ display: 'inline-block' }}
                        variant='text'
                        width='150px'
                      />
                    ) : (
                      invalidIntentNames
                    )}
                  </Typography>
                </Typography>
              )}
            </Box>
          </Collapse>
        </Box>
      </WorkflowBuilderBanner>
    </Box>
  );
};
