import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { numberToCurrency } from '../helpers';
import { NA } from 'src/constants/solve';

type OptionalNumber = number | undefined;

type NumberValueProps = {
  isChild?: boolean;
  value: OptionalNumber | string;
};

const NumberValue = ({ isChild, value }: NumberValueProps) => {
  const { palette } = useTheme();
  const numValue = numberToCurrency({
    number: Number(value),
    shouldRound: true,
  });
  return (
    <Box display='flex' gap='4px'>
      <Typography
        color={
          numValue === NA ? palette.colors.grey[600] : palette.colors.black
        }
        variant={isChild ? 'font14' : 'font14Bold'}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default NumberValue;
