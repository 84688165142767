import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import ChipList from './connector-job-details/ChipList';
import ConnectorJobDetails from './connector-job-details';
import Field from './Field';
import { useConnectorDocumentCount } from './hooks';
import { getShortDate } from './utils';
import { Connector, ConnectorJob } from 'src/services/apiInterfaces';

interface ConnectedIntegrationOverview {
  isPending: boolean;
  lastConnectorJob: ConnectorJob | null;
  selectedConnector: Connector;
  setConnectorJobPendingState: (state: boolean) => void;
}

export default function ConnectedIntegrationOverview({
  isPending,
  lastConnectorJob,
  selectedConnector,
  setConnectorJobPendingState,
}: ConnectedIntegrationOverview) {
  const connectorDocumentCounts = useConnectorDocumentCount(
    isPending,
    selectedConnector?.connector_id,
  );
  const { last_modified_at, last_modified_by } = selectedConnector;
  const nonZeroConnectorDocumentCounts = connectorDocumentCounts.filter(
    documentCount => documentCount.doc_count && documentCount.doc_count > 0,
  );

  return (
    <div>
      {last_modified_at && last_modified_by && (
        <FieldRow>
          <Field label='Last edited by:'>
            <Typography variant='font14'>{last_modified_by}</Typography>
          </Field>
          <Field label='Edited on:'>
            <Typography variant='font14'>
              {getShortDate(last_modified_at)}
            </Typography>
          </Field>
        </FieldRow>
      )}
      {lastConnectorJob && (
        <>
          {nonZeroConnectorDocumentCounts.length ? (
            <Field label='Live Document Count:'>
              <Box display='flex' flexDirection='column' mb='20px'>
                <ChipList
                  chips={nonZeroConnectorDocumentCounts.map(
                    documentCount =>
                      `${documentCount.doc_type.replace('_', ' ')}: ${
                        documentCount.doc_count
                      }`,
                  )}
                />
              </Box>
            </Field>
          ) : null}
          <ConnectorJobDetails
            isPending={isPending}
            job={lastConnectorJob}
            selectedConnector={selectedConnector}
            setConnectorJobPendingState={setConnectorJobPendingState}
          />
        </>
      )}
    </div>
  );
}

const FieldRow = styled('div')`
  display: flex;
  gap: 36px;
  margin-bottom: 20px;
`;
