import { useCallback, useMemo } from 'react';

import { InsightsTab } from '../types';
import {
  useGetFeatureFlagsQuery,
  useGetPermissionsQuery,
} from 'src/services/dashboard-api';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';
import { Permission } from 'src/utils/enums';

export default function useTabs() {
  const { data: permissions = {} } = useGetPermissionsQuery();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { data: orgConfigData } = useGetOrgConfigQuery();

  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isTopicEnabled = featureFlags.includes('solve_insights_topic');
  const isWorkflowEnabled = featureFlags.includes('solve_insights_workflow');
  const isOverviewEnabled = featureFlags.includes('solve_insights_overview');
  const isChatInsightsEnabled = featureFlags.includes('chat_insights');
  const isDiscoverEnabled = orgConfigData?.discover_status === 'enabled';
  const isDiscoverPermissionEnabled = Boolean(permissions[Permission.DISCOVER]);
  // Topic is powered by discover product or WIP or FF (Demo for customer)
  const showTopic =
    isDiscoverEnabled && isDiscoverPermissionEnabled && isTopicEnabled;

  const availableTabs = useMemo(() => {
    // Tab needs to be in the array in certain order
    const tabs = [];
    if (isOverviewEnabled) {
      tabs.push('Overview');
    }
    if (showTopic) {
      tabs.push('Topics');
    }
    if (isChatInsightsEnabled) {
      tabs.push('Chats');
    }
    if (isWorkflowEnabled) {
      tabs.push('Workflows');
    }
    tabs.push('Articles');
    return tabs;
  }, [isOverviewEnabled, showTopic, isChatInsightsEnabled, isWorkflowEnabled]);

  const tabTitleToIndex = useCallback(
    (tabTitle: string) =>
      availableTabs.findIndex(availableTab => availableTab === tabTitle),
    [availableTabs],
  );

  const tabIdToIndexMap = useMemo(
    () =>
      ({
        article: tabTitleToIndex('Articles'),
        chat: tabTitleToIndex('Chats'),
        overview: tabTitleToIndex('Overview'),
        topic: tabTitleToIndex('Topics'),
        workflow: tabTitleToIndex('Workflows'),
      } as const satisfies Record<InsightsTab, number>),
    [tabTitleToIndex],
  );

  return {
    availableTabs,
    tabIdToIndexMap,
  } as const;
}
