import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

import {
  Button,
  Chip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { DiscoverAutomationCard } from '../components';
import DiscoverRealizedImpactTopicRow from './DiscoverRealizedImpactTopicRow';
import { DiscoverAutomationResponse } from 'src/reducers/discoverReducer/types';
import { getDiscoverBannerValues } from 'src/utils/discover/helpers';

interface DiscoverRealizedImpactCardProps {
  automation: DiscoverAutomationResponse;
  onMouseEnter: (automationName: string) => void;
  style: CSSProperties;
}

const DiscoverRealizedImpactCard = ({
  automation,
  onMouseEnter,
  style,
}: DiscoverRealizedImpactCardProps) => {
  const { palette } = useTheme();

  const { automated_object, topics, value } = automation;
  const { name, redirect_url: redirectUrl, status } = automated_object;
  const isAutomatedObjectActive = status === 'active';
  const callToActionText = isAutomatedObjectActive
    ? 'View workflow'
    : 'Activate workflow';

  const { costValue, volumeValue } = getDiscoverBannerValues(value);

  return (
    <DiscoverAutomationCard
      onMouseEnter={() => onMouseEnter(name)}
      style={style}
    >
      <Box
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography noWrap variant='font16Bold'>
          {name}
        </Typography>
        <DiscoverRealizedImpactTopicRow topics={topics} />
      </Box>
      <>
        <Typography noWrap variant='font16Bold'>
          {volumeValue}
        </Typography>
      </>
      <>
        <Typography noWrap variant='font16Bold'>
          {costValue}
        </Typography>
      </>
      <>
        <Chip
          label={isAutomatedObjectActive ? 'Active' : 'Inactive'}
          startAdornment={
            <Box
              bgcolor={
                isAutomatedObjectActive
                  ? palette.colors.green[500]
                  : palette.colors.grey[400]
              }
              borderRadius={100}
              height='12px'
              width='12px'
            />
          }
          sx={{
            borderColor: palette.colors.slate[200],
            color: palette.colors.grey[700],
            fontSize: 12,
            fontWeight: 500,
            height: '26px',
          }}
          variant='outlined'
        />
      </>
      <StyledLink to={redirectUrl}>
        <Button variant='ghost'>
          <Typography noWrap variant='font14Bold'>
            {callToActionText}
          </Typography>
        </Button>
      </StyledLink>
    </DiscoverAutomationCard>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export default DiscoverRealizedImpactCard;
