import React, { useState } from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { theme } from '@forethought-technologies/forethought-elements';
import { ForethoughtEditConditionMenu } from '../edit-menu/ForethoughtEditConditionMenu';
import { ConditionStep } from '../workflow-builder/revamped';
import { getCompositeConditionsConfigFromStep } from './conditionEditorHelpers';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { Step } from 'src/types/workflowBuilderAPITypes';

export const Condition: React.FC<
  React.PropsWithChildren<{
    isCanvasDisabled: boolean;
    step: Step;
    stepId: string;
  }>
> = ({ isCanvasDisabled, step, stepId }) => {
  const [shouldShowEditMenu, setShouldShowEditMenu] = useState(false);
  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  return (
    <>
      {
        <Box
          flex={1}
          onMouseEnter={() => setShouldShowEditMenu(true)}
          onMouseLeave={() => setShouldShowEditMenu(false)}
        >
          <ConditionStep
            fields={getCompositeConditionsConfigFromStep(
              step,
              contextVariables,
              true,
            )}
            stepId={stepId}
          />
          {!isCanvasDisabled && (
            <ForethoughtEditConditionMenu
              isVisible={shouldShowEditMenu}
              setIsVisible={setShouldShowEditMenu}
              stepId={stepId}
            />
          )}
        </Box>
      }
    </>
  );
};

export const ConditionWrapper = styled('div')`
  display: flex;
`;

export const ConditionOutline = styled('div')<{ isEditing: boolean }>`
  border: ${props =>
    props.isEditing
      ? `2px solid ${theme.palette.colors.purple[500]}`
      : `1px solid ${theme.palette.colors.slate[200]}`};
  border-radius: 22px;
  min-height: 80px;
  width: 472px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  flex-direction: column;
`;

export const ConditionName = styled('div')`
  border-bottom: 1px solid ${theme.palette.colors.slate[200]};
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
`;

export const Options = styled('div')`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
`;

export const Label = styled('label')<{
  isDisabled: boolean;
  isEditing: boolean;
  isSelected: boolean;
}>`
  padding: 10px 16px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props =>
    props.isSelected ? theme.palette.colors.slate[100] : 'white'};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};

  :not(:last-child) {
    border: 1px solid transparent;
    ${props =>
      !props.isSelected &&
      `border-bottom: 1px solid ${theme.palette.colors.slate[200]}`};
    ${props =>
      props.isSelected &&
      (props.isEditing
        ? `border-top: 1px solid ${theme.palette.colors.grey[800]}; border-bottom: 1px solid ${theme.palette.colors.grey[800]};`
        : `border: 1px solid ${theme.palette.colors.grey[800]}`)}
  }

  :last-child {
    border: 1px solid transparent;
    border-radius: 0 0 20px 20px;
    ${props =>
      props.isSelected &&
      (props.isEditing
        ? `border-top: 1px solid ${theme.palette.colors.grey[800]};`
        : `border: 1px solid ${theme.palette.colors.grey[800]}`)}
  }

  :hover {
    border: 1px solid ${theme.palette.colors.slate[600]};
  }
`;
