import React from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconBolt } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ICON_SIZE } from '../dashboard-pages/discover-automation-page/constants';
import { generateDiscoverTopicDetailUrl } from '../dashboard-pages/discover-automation-page/helpers';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { deriveTopicNameFromTopic } from 'src/utils/discover/helpers';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';

interface RealizedImpactBadgeProps {
  hasIcon?: boolean;
  topic: DiscoverTopic;
  trackingEventMetadata?: {
    intentId: string;
    source: string;
  };
}

const RealizedImpactBadge: React.FC<
  React.PropsWithChildren<RealizedImpactBadgeProps>
> = ({ hasIcon = true, topic, trackingEventMetadata }) => {
  const { palette } = useTheme();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER,
  );
  return (
    <Link
      aria-label={`${deriveTopicNameFromTopic(topic)} link`}
      href={generateDiscoverTopicDetailUrl(topic)}
      onClick={e => {
        e.stopPropagation();
        if (trackingEventMetadata) {
          dispatchTrackingAction(
            FlamethrowerTrackingEventTypes.INTENT_TOPIC_CLICKED,
            {
              intent_id: trackingEventMetadata.intentId,
              source: trackingEventMetadata.source,
              topic_id: topic.topic_id,
            },
          );
        }
      }}
      rel='noopener noreferrer'
      target='_blank'
    >
      <Box
        aria-controls={hasIcon ? undefined : 'realized-impact-menu'}
        id='realized-impact-button'
        sx={{
          '&:hover': {
            bgcolor: palette.colors.green[100],
            color: palette.colors.green[600],
            textDecoration: 'underline',
          },
          alignItems: 'center',
          bgcolor: palette.colors.slate[100],
          borderRadius: '4px',
          color: palette.colors.grey[800],
          cursor: 'pointer',
          display: 'flex',
          maxWidth: '180px',
          overflow: 'hidden',
          px: 1,
          py: 0.5,
          textDecoration: 'none',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {hasIcon && (
          <IconBolt
            color={palette.colors.green[500]}
            fill={palette.colors.green[500]}
            size={ICON_SIZE}
            strokeWidth={1}
          />
        )}
        <Typography noWrap variant='font12Medium'>
          {deriveTopicNameFromTopic(topic)}
        </Typography>
      </Box>
    </Link>
  );
};

export const Link = styled('a')`
  text-decoration: none;
`;

export default RealizedImpactBadge;
