import WorkflowEdge from './WorkflowEdge';

export const FLOW_EDGES = {
  WORKFLOW: 'WORKFLOW',
} as const;

const edgeTypes = {
  [FLOW_EDGES.WORKFLOW]: WorkflowEdge,
};

export default edgeTypes;
