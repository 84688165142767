import React from 'react';

import { isFinalValueStyle } from 'src/utils/analyticsUtils';

interface TotalsAndAveragesProps {
  cells: (string | number)[];
  isFinalValue?: boolean;
}

export const TotalsAndAverages: React.FC<
  React.PropsWithChildren<TotalsAndAveragesProps>
> = ({ cells, isFinalValue = true }: TotalsAndAveragesProps) => {
  return (
    <tr className='totals data-row'>
      <td className='field-cell'>
        <p className='field-p'>Totals &amp; Averages</p>
      </td>
      {cells.map((cellData: string | number, index: number) => (
        <td className={isFinalValueStyle(isFinalValue, 'cell')} key={index}>
          <p className={isFinalValueStyle(isFinalValue, 'cell-p')}>
            {cellData}
          </p>
        </td>
      ))}
    </tr>
  );
};
