import DiscoverAutomatedBadge from 'src/components/discover-automated-badge/DiscoverAutomatedBadge';
/**
import articleIcon from 'src/assets/images/article-icon.svg';
import discoverNewIconSmall from 'src/assets/images/discover-new-icon-small.svg';
 */

export const showOnlyFilterOptions = [
  {
    label: 'Topics with workflow',
    optionStartAdornment: <DiscoverAutomatedBadge size={20} />,
    value: 'has-workflow',
  },
  {
    label: 'Topics with knowledge retrieval',
    value: 'has-knowledge-retrieval',
  },
  /**
  {
    label: 'New topics',
    optionStartAdornment: <img alt='New topic' src={discoverNewIconSmall} />,
    value: 'new-topics',
  },
  {
    label: 'Article generated',
    optionStartAdornment: (
      <img alt='Article' src={articleIcon} style={{ marginRight: '1px' }} />
    ),
    value: 'article-generated',
  },
   */
];
