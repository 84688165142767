import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

import { SortObject } from '../../../types/types';
import { sortModels } from 'src/actions/analytics';
import { sortTable } from 'src/actions/data/data';

type MyProps = {
  onClick: Function;
  productSorted: string;
  propertyName: string;
  sortAsc: boolean;
  sortedProperty: string;
  sortModels: Function;
  sortTable: Function;
  tableView: string;
};

type MyState = {};

class SortingButtons extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
  }

  render() {
    const propertyName = this.props.propertyName;
    const sortedByAsc = this.props.sortAsc;
    const sortedProperty = this.props.sortedProperty;
    const sortTable = this.props.sortTable;
    const tableView = this.props.tableView;

    return (
      <div className='sorting-btn'>
        <button
          className={
            sortedByAsc === undefined || sortedProperty !== propertyName
              ? 'asc-arrow'
              : sortedByAsc && sortedProperty === propertyName
              ? 'desc-selected -arrow'
              : 'common-none'
          }
          onClick={e => {
            this.props.sortModels(
              propertyName,
              !true,
              this.props.productSorted,
            );
            this.props.onClick(e);
            sortTable({
              field: propertyName,
              sortAsc: false,
              tableView,
            });
          }}
        />
        <button
          className={
            sortedByAsc === undefined || sortedProperty !== propertyName
              ? 'desc-arrow'
              : !sortedByAsc && sortedProperty === propertyName
              ? 'asc-selected -arrow'
              : 'common-none'
          }
          onClick={() => {
            this.props.sortModels(
              propertyName,
              !false,
              this.props.productSorted,
            );

            sortTable({
              field: propertyName,
              sortAsc: true,
              tableView,
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  sortAsc: state.analytics.sortAsc,
  sortedProperty: state.analytics.sortType,
  tableView: state.pageSettings.macroControlsViewMode,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sortModels: (sortType: string, sortAsc: boolean, productSorted: string) =>
    dispatch(sortModels(sortType, sortAsc, productSorted)),
  sortTable: (sortObject: SortObject) => dispatch(sortTable(sortObject)),
});

const ConnectedSortingButtons = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SortingButtons);

export default ConnectedSortingButtons;
