import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';

import { Button } from '@forethought-technologies/forethought-elements';
import ContextMention from '../context-mention-input';
import CustomizationInput from '../customization-input';
import IntercomOptionComponent from '../handoff-configuration/intercom/IntercomOptionComponent';
import CustomFieldSelector, {
  CustomField,
  CustomFieldValue,
} from './CustomFieldSelector';
import { Title } from './FreshdeskTicketCreationCustomizationPanel';
import TagSelection from './TagSelection';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown/ContextVariableSelectDropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import {
  Label,
  Required,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import {
  selectActionSettingsConfigurationFields,
  selectCanvasIsLoadingActionCustomization,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectAddingAction } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { FreshdeskTicketAndContactCreationCustomizationSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

const requiredFields = ['name', 'email', 'subject', 'description'];

interface FreshdeskTicketAndContactCreationPanelCustomizationProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const FreshdeskTicketAndContactCreationCustomizationPanel = ({
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}: FreshdeskTicketAndContactCreationPanelCustomizationProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const freshdeskTicketCreationCustomizationSettings = useSelector(
    selectActionSettingsConfigurationFields,
  ) as FreshdeskTicketAndContactCreationCustomizationSettings;
  const addingAction = useSelector(selectAddingAction);
  const isLoadingActionCustomization = useSelector(
    selectCanvasIsLoadingActionCustomization,
  );

  const defaultZendeskTicketCreationCustomizationSettings: FreshdeskTicketAndContactCreationCustomizationSettings =
    {
      custom_fields: null,
      description: 'free_form_query',
      email: '',
      group_id: undefined,
      name: '',
      priority: undefined,
      subject: '',
      tags: [],
      ticket_creation_success_message:
        'Your ticket has been successfully submitted.',
      transcript_field: '',
      upload_files_attached: false,
    };
  const handoffData = addingAction
    ? defaultZendeskTicketCreationCustomizationSettings
    : freshdeskTicketCreationCustomizationSettings;

  const customFields = useMemo(() => {
    const customFieldsObject =
      freshdeskTicketCreationCustomizationSettings?.custom_fields || {};

    const arrayOfCustomFields: CustomField[] = Object.keys(
      customFieldsObject,
    ).map(key => ({
      key: key,
      value: customFieldsObject[key],
    }));
    return arrayOfCustomFields;
  }, [freshdeskTicketCreationCustomizationSettings]);

  const [customizationData, setCustomizationData] =
    useState<FreshdeskTicketAndContactCreationCustomizationSettings>(
      handoffData,
    );

  const [customFieldList, setCustomFieldList] =
    useState<CustomField[]>(customFields);

  const getAreFormFieldsValid = () => {
    return (
      customizationData &&
      requiredFields.some(
        field =>
          customizationData[field as keyof typeof customizationData] === '',
      )
    );
  };

  const getCustomFieldsObject = (): Record<string, CustomFieldValue> | null => {
    const customFieldsObject: Record<string, CustomFieldValue> = {};

    customFieldList.forEach(cf => {
      if (cf.key) {
        customFieldsObject[cf.key] = cf.value;
      }
    });

    if (!Object.keys(customFieldsObject).length) {
      return null;
    }

    return customFieldsObject;
  };

  const handleSave = () => {
    const formattedData = {
      ...customizationData,
      custom_fields: getCustomFieldsObject(),
    };

    dispatch(
      addingAction
        ? addAction({
            ...addingAction,
            data: { ...addingAction.data, configuration_fields: formattedData },
          })
        : updateActionSettings({
            action_type:
              ActionBuilderActionTypes.FRESHDESK_TICKET_AND_CONTACT_CREATION,
            configuration_fields: formattedData,
          }),
    );
    dispatchActionEditedTrackingEvent({
      actionType:
        ActionBuilderActionTypes.FRESHDESK_TICKET_AND_CONTACT_CREATION,
    });
    setActionPanelVisibilityParameters('hidden');
    setAreActionSettingsUnsaved(false);
    dispatch(setCustomizableActionId(''));
    onDismiss();
  };

  const isPristine =
    isEqual(customizationData, handoffData) &&
    isEqual(customizationData?.custom_fields, getCustomFieldsObject());

  const isSaveButtonDisabled = isPristine || getAreFormFieldsValid();

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (freshdeskTicketCreationCustomizationSettings) {
      setCustomizationData({ ...freshdeskTicketCreationCustomizationSettings });

      const customFieldsObject =
        freshdeskTicketCreationCustomizationSettings.custom_fields || {};

      const arrayOfCustomFields: CustomField[] = Object.keys(
        customFieldsObject,
      ).map(key => ({
        key: key,
        value: customFieldsObject[key],
      }));

      setCustomFieldList(arrayOfCustomFields);
    }
  }, [freshdeskTicketCreationCustomizationSettings]);

  if (isLoadingActionCustomization || !customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <Box display='flex' flexDirection='column' gap='15px' height='100%'>
      <Title>Freshdesk Ticket and Contact Creation</Title>
      <IntercomOptionComponent
        checked={customizationData.upload_files_attached}
        label=' Prompt user for file attachments'
        onChange={() =>
          setCustomizationData(data => {
            return {
              ...data,
              upload_files_attached: !data.upload_files_attached,
            };
          })
        }
      />

      <ContextVariableSelector
        label='Name'
        onChange={value =>
          setCustomizationData(data => ({ ...data, name: value }))
        }
        required
        value={customizationData.name}
      />
      <ContextVariableSelector
        label='Email'
        onChange={value =>
          setCustomizationData(data => ({ ...data, email: value }))
        }
        required
        value={customizationData.email}
      />
      <ContextVariableSelector
        label='Subject'
        onChange={value =>
          setCustomizationData(data => ({ ...data, subject: value }))
        }
        required
        value={customizationData.subject}
      />
      <ContextVariableSelector
        label='Description'
        onChange={value =>
          setCustomizationData(data => ({ ...data, description: value }))
        }
        required
        value={customizationData.description}
      />
      <SectionDivider>
        <ContextMention
          label='Success Message'
          onChange={value => {
            setCustomizationData(data => {
              return {
                ...data,
                ticket_creation_success_message: value,
              };
            });
          }}
          placeholder='Enter success message'
          tooltip='Text message after ticket has been created'
          value={customizationData?.ticket_creation_success_message || ''}
        />
      </SectionDivider>
      <SectionDivider>
        <ContextMention
          label='Transcript field'
          onChange={value => {
            setCustomizationData(data => {
              return {
                ...data,
                transcript_field: value,
              };
            });
          }}
          placeholder='Enter transcript field'
          tooltip='Ticket custom field that you want us to add the conversation transcript to'
          value={customizationData.transcript_field || ''}
        />
      </SectionDivider>
      <SectionDivider>
        <CustomizationInput
          label='Group ID'
          onChange={({ target }) => {
            setCustomizationData(data => ({
              ...data,
              group_id: parseInt(target.value),
            }));
          }}
          placeholder='Enter Group ID'
          tooltip='Group ID'
          type='number'
          value={customizationData.group_id?.toString() ?? ''}
        />
      </SectionDivider>
      <SectionDivider>
        <CustomizationInput
          label='Priority'
          onChange={({ target }) => {
            setCustomizationData(data => ({
              ...data,
              priority: parseInt(target.value),
            }));
          }}
          placeholder='Enter Priority Code'
          tooltip='Priority'
          type='number'
          value={customizationData.priority?.toString() ?? ''}
        />
      </SectionDivider>
      <SectionDivider>
        <TagSelection
          addTag={() =>
            setCustomizationData(data => ({
              ...data,
              tags: [...data.tags, ''],
            }))
          }
          deleteTag={indexToDelete =>
            setCustomizationData(data => ({
              ...data,
              tags: data.tags.filter((_, index) => index !== indexToDelete),
            }))
          }
          editTag={(index, value) =>
            setCustomizationData(data => {
              return {
                ...data,
                tags: data.tags.map((tag, idx) =>
                  idx === index ? value : tag,
                ),
              };
            })
          }
          includeTooltip
          label='Tags'
          tags={customizationData.tags}
        />
      </SectionDivider>
      <SectionDivider>
        <CustomFieldSelector
          addCustomField={() =>
            setCustomFieldList(data => [...data, { key: '', value: '' }])
          }
          customFields={customFieldList}
          deleteCustomField={indexToDelete =>
            setCustomFieldList(data => {
              return data.filter((cf, index) => index !== indexToDelete);
            })
          }
          updateCustomField={(index, value) =>
            setCustomFieldList(data => {
              const newCustomFields = [...data];
              newCustomFields[index].key = value;
              return newCustomFields;
            })
          }
          updateCustomFieldValue={(index, value) =>
            setCustomFieldList(data => {
              const newCustomFields = [...data];
              newCustomFields[index].value = value;
              return newCustomFields;
            })
          }
        />
      </SectionDivider>
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <Button onClick={() => onDiscard()} variant='ghost'>
          Cancel
        </Button>
        <Button
          disabled={isSaveButtonDisabled}
          onClick={handleSave}
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default FreshdeskTicketAndContactCreationCustomizationPanel;

const LabelContainer = styled(Label)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SectionDivider = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.palette.colors.grey[100]};
  border-left: none;
  border-right: none;
  padding-top: 15px;
`;

const ContextVariableSelector = ({
  label,
  onChange,
  required = false,
  value,
}: {
  label: string;
  onChange: (value: string) => void;
  required?: boolean;
  value: string;
}) => {
  const { palette } = useTheme();
  return (
    <SectionDivider>
      <Box display='flex' flexDirection='column' gap='4px'>
        <LabelContainer>
          {label}
          {required && (
            <Required color={palette.colors.grey[400]}>Required</Required>
          )}
        </LabelContainer>
        <ContextVariableSelectDropdown
          aria-label={`Context variable for ${label} field`}
          isClearable
          onChange={onChange}
          shouldIncludeSystemContextVariables
          undefinedContextVariables={[]}
          value={value}
        />
      </Box>
    </SectionDivider>
  );
};
