import React from 'react';
import { styled } from '@mui/material/styles';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ToggleButton from 'src/components/reusable-components/toggle-button/ToggleButton';

interface InternalTextFieldWrapperProps {
  children: React.ReactNode;
  isChecked: boolean;
  isReadOnly: boolean;
  onToggleClick(value: boolean): void;
}

const InternalTextField = ({
  children,
  isChecked,
  isReadOnly,
  onToggleClick,
}: InternalTextFieldWrapperProps) => (
  <Wrapper isReadOnly={isReadOnly}>
    <Header isChecked={isChecked}>
      <TitleWrapper>
        <Typography variant='font14Bold'>
          Add an internal only text field
        </Typography>
        <Typography color={theme.palette.colors.grey[500]} variant='font12'>
          Enable this to communicate internal information to your agents.
        </Typography>
      </TitleWrapper>

      <ToggleButton
        isActionBuilderTheme
        isChecked={isChecked}
        onClickHandler={onToggleClick}
      />
    </Header>
    {children}
  </Wrapper>
);

const Wrapper = styled('div')<{ isReadOnly: boolean }>`
  border-radius: 8px;
  background-color: ${theme.palette.colors.blue[100]};

  ${({ isReadOnly }) => isReadOnly && 'pointer-events: none;'}
`;

const Header = styled('div')<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  ${({ isChecked }) =>
    isChecked &&
    `
      border: 1px solid ${theme.palette.colors.slate[300]};
      border-bottom: none;
      border-radius: 8px 8px 0 0;
  `}
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export default InternalTextField;
