import { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { formatNumberWithPercentage } from 'src/utils/solve/deflectionInsightsUtils';

interface Data {
  color: string;
  label: string;
  value: number;
}

interface PaletteTooltipProps {
  values: Data[];
}

export function BreakdownPaletteTooltip({ values }: PaletteTooltipProps) {
  const { palette } = useTheme();

  const totalSum = useMemo(
    () =>
      values
        .filter(value => value.label !== 'Not answered')
        .reduce((acc, curr) => acc + curr.value, 0),
    [values],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        justifyContent: 'start',
      }}
    >
      {values.map(({ color, label, value }, idx) => (
        <Box
          alignItems='center'
          display='flex'
          justifyContent='start'
          key={idx}
        >
          <Box
            sx={{
              backgroundColor: color,
              border: '1px solid',
              borderColor: palette.colors.grey[100],
              borderRadius: '2px',
              height: '10px',
              marginRight: '4px',
              width: '10px',
            }}
          />
          <Typography color={palette.colors.white} noWrap variant='font12'>
            {label !== 'Not answered'
              ? `${label}: ${formatNumberWithPercentage(value, totalSum)}`
              : `${label}: ${value}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
