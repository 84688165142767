import { useMemo } from 'react';
import linkifyHtml from 'linkify-html';
import sanitizeHtml from 'sanitize-html';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { Wrapper } from '../DynamicArticleCard';
import ResponseGenerationIcon from 'src/assets/images/dynamic_article_logo.svg';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { GeneratedEmailResponse } from 'src/pages/workflow-builder-edit/types';
import { contextVariableAliasHtml } from 'src/utils/cleanStr';

interface ResponseGenerationCardProps {
  /** Index of the component */
  index: number;
  /** Function to be called when the apply button is clicked */
  onApplyButtonClick: () => void;
  /** Function to be called when the show me later button is clicked */
  onShowMeLaterButtonClick: () => void;
  /** Generated Response */
  response: GeneratedEmailResponse;
}

const ResponseGenerationCard = ({
  index,
  onApplyButtonClick,
  onShowMeLaterButtonClick,
  response,
}: ResponseGenerationCardProps) => {
  const { palette } = useTheme();

  const { reply } = response;

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: false,
    shouldIncludeTriageContextVariables: true,
  });

  const messageWithContextVariables = useMemo(
    () =>
      contextVariableAliasHtml({
        contextVariables,
        html: reply,
        undefinedContextVariableIds: [],
      }),
    [contextVariables, reply],
  );

  return (
    <Wrapper>
      <Box borderRadius='8px' display='flex' flexDirection='column'>
        <Box
          alignItems='center'
          bgcolor={'#FAFAFA'}
          borderBottom={`1px solid ${palette.colors.grey[100]}`}
          display='flex'
          justifyContent={'space-between'}
          padding='15px'
          sx={{ borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}
        >
          <Box alignItems='center' display='flex' gap='5px'>
            <img src={ResponseGenerationIcon} />
            <Typography
              color={palette.colors.slate[700]}
              variant='font14Medium'
            >
              {`Response ${index + 1}`}
            </Typography>
          </Box>
          <Button
            aria-label={`Show me response ${index + 1} later`}
            onClick={onShowMeLaterButtonClick}
            variant='ghost'
          >
            Show me later
          </Button>
        </Box>
        <GeneratedResponseBody>
          <Box
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                linkifyHtml(messageWithContextVariables, {
                  rel: 'noreferrer',
                  target: '_blank',
                  truncate: 42,
                }),
                {
                  allowedAttributes: {
                    '*': ['style'],
                  },
                  allowedStyles: {
                    '*': {
                      color: [/^#?([a-f0-9]{6}|[a-f0-9]{3})$/], // allow color hex code style attribute for context variables
                      'text-align': [/^left$/, /^right$/, /^center$/],
                    },
                  },
                },
              ),
            }}
            fontSize={14}
            sx={{ a: { textDecoration: 'underline' } }}
          />
          <Button
            aria-label={`Apply response ${index + 1}`}
            onClick={onApplyButtonClick}
            variant='secondary'
          >
            Apply
          </Button>
        </GeneratedResponseBody>
      </Box>
    </Wrapper>
  );
};

export default ResponseGenerationCard;

const GeneratedResponseBody = styled('div')`
  background: ${({ theme }) => theme.palette.colors.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding: 16px 27px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
`;
