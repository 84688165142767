import './styles.scss';

import React, { useEffect, useState } from 'react';
import Highcharts, { PointOptionsObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { Tooltip } from '@forethought-technologies/forethought-elements';
import LoadingSpinner from '../loading-spinner/loadingSpinner';
import HighchartOptions from './highchartOptions';
import tooltipIcon from 'src/assets/images/tooltip-info.svg';
import {
  selectIsLoading,
  selectIsSolveWorkflows,
} from 'src/reducers/solveReducer';
import { SolveOverviewDataDict } from 'src/services/apiInterfaces';
import { numberWithCommas } from 'src/utils/analyticsUtils';
import { pieEmptyData } from 'src/utils/analyticsUtils';
import { solveChartColorUtil } from 'src/utils/chartColorUtil';
import { cleanStr } from 'src/utils/cleanStr';

type Props = {
  /** Total value description in center subtitle of donut chart */
  description: string;
  /** Name to be displayed in legend */
  name: string;
  /** Data to be displayed in chart */
  overviewData: SolveOverviewDataDict | undefined;
  /** Channel selected */
  selectedChannel: string;
  /** Message to display in the tooltip */
  tooltipMessage?: string;
};

interface PieChartData {
  /** Id for data series */
  id: string;
  /** Y value */
  y: number;
}
[];

const PieChart: React.FC<React.PropsWithChildren<Props>> = ({
  description,
  name,
  overviewData,
  selectedChannel,
  tooltipMessage = '',
}: Props) => {
  /** Initializes highchart options for language */
  HighchartOptions(Highcharts);
  /** Highcharts rendering options */
  const [options, setOptions] = useState<Highcharts.Options | null>(null);
  /** Boolean value that inidicates if there is data to be displayed */
  const [isNoData, setNoData] = useState<boolean>(false);
  /** Indicates loading state */
  const isLoading: boolean = useSelector(selectIsLoading);
  /** Indicates if current view is workflows */
  const isSolveWorkflows: boolean = useSelector(selectIsSolveWorkflows);

  /** Function to calculate sum for y value */
  const calculateSum = (data: Array<PieChartData>): string => {
    let num = 0;
    data && data.reduce((prev, cur) => (num = prev + cur.y), 0);
    return numberWithCommas(num) as string;
  };

  const setData = (
    data: Array<PieChartData>,
  ): Array<{
    color: string;
    name: string;
    y: number;
  }> => {
    if (data && data.length) {
      setNoData(false);
      const seriesData = [];
      const pieData = data.map(data => {
        return {
          color: solveChartColorUtil(
            data.id,
            selectedChannel,
            isSolveWorkflows,
          ),
          name: cleanStr(data.id),
          y: data.y,
        };
      });
      seriesData.push(pieData);
      return seriesData.flat();
    } else {
      setNoData(true);
      return pieEmptyData();
    }
  };

  useEffect(() => {
    setOptions({
      chart: {
        height: 450,
        type: 'pie',
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          borderWidth: 0,
          cursor: 'pointer',
          dataLabels: {
            enabled: !isNoData,
            format:
              '<div class=PieChart-label><span class=PieChart-label-val>{point.y:,.0f}</span><span class=PieChart-label-name>{point.name}</span</div>',
            style: {
              width: 50,
              'word-break': 'break-all',
            },
            useHTML: true,
            y: -10,
          },
          innerSize: '70%',
          shadow: false,
          size: '85%',
        },
        series: {
          states: {
            hover: {
              enabled: !isNoData, //disables hover on data point
            },
          },
        },
      },
      series: [
        {
          data: setData(
            overviewData?.pie_chart as PieChartData[],
          ) as PointOptionsObject[],
          name: name,
          type: 'pie',
        },
      ],
      subtitle: {
        align: 'center',
        text: description,
        verticalAlign: 'middle',
        y: 40,
      },
      title: {
        align: 'center',
        style: {
          fontSize: '36px',
        },
        text: calculateSum(overviewData?.pie_chart as PieChartData[]),
        verticalAlign: 'middle',
      },
      tooltip: {
        enabled: !isNoData,
        headerFormat: '',
        padding: 0,
        pointFormat:
          "<div class='PieChart-tooltip' style='{border: 2px solid {series.color}'}><span>{point.name}</span>: " +
          '<span>{point.y:,.0f}</div>',
        useHTML: true,
        valueDecimals: 0,
      },
    });
  }, [overviewData, isNoData, isSolveWorkflows]);

  /** We send a resize event after the component mounts to replicate the behavior of chart.reflow() from highcharts when is used in a class component*/
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }, [overviewData, isNoData]);

  return (
    <section className='PieChart'>
      <div className='PieChart-legend'>
        <span className='PieChart-name'>{name}</span>
        {tooltipMessage && (
          <Tooltip tooltipContent={tooltipMessage}>
            <TooltipIcon src={tooltipIcon} />
          </Tooltip>
        )}
      </div>
      <div className='row'>
        {overviewData && !isLoading && options ? (
          <HighchartsReact
            className='chart'
            highcharts={Highcharts}
            options={options}
          />
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </section>
  );
};

const TooltipIcon = styled('img')`
  cursor: default;
  margin-left: 5px;
  margin-top: 2px;
`;

export default PieChart;
