import { useMemo } from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Box, css, styled, useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {
  Button,
  TextField,
  Toggle,
  Typography,
} from '@forethought-technologies/forethought-elements';
import PermissionLevelSelect from '../permission-level-select';
import { UserFormProps } from './types';
import { defaultInitialValues, validationSchema } from './utils';
import iconAnalyticsGrey from 'src/assets/images/icon-analytics-grey.svg';
import { selectUserRole } from 'src/reducers/userReducer/userReducer';
import { useGetEmbeddedAnalyticsSettingsQuery } from 'src/services/embedded-analytics/embeddedAnalyticsApi';

const ANALYTICS_ENABLED_MSG = 'Allow access to Advanced Analytics';
const ANALYTICS_DISABLED_MSG = (
  <>
    You have used all your seats. To add more,{' '}
    <a href='mailto:support@forethought.ai'>contact support</a>.
  </>
);

export default function UserForm({
  initialValues = defaultInitialValues,
  mode = 'create',
  onCancel,
  onSubmit,
}: UserFormProps) {
  const { palette } = useTheme();
  const currentUserRole = useSelector(selectUserRole);
  const isUpdateMode = mode === 'update';
  const { data: analyticsSettings } = useGetEmbeddedAnalyticsSettingsQuery();

  const isAnalyticsDisabled = useMemo(() => {
    if (!analyticsSettings) {
      return true;
    }
    const { allowed_emails, user_limit } = analyticsSettings;
    if (allowed_emails.includes(initialValues.email)) {
      return false;
    }

    return allowed_emails.length >= user_limit;
  }, [analyticsSettings, initialValues]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, handleSubmit, isSubmitting, setFieldValue, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <InnerForm $buttonsAtBottom={isUpdateMode}>
              <InputWrapper>
                <TextField
                  label='First name'
                  onChange={e => {
                    const { value } = e.target;
                    setFieldValue('first_name', value);
                  }}
                  placeholder='First name'
                  value={values.first_name}
                />
              </InputWrapper>
              <InputWrapper>
                <TextField
                  label='Last name'
                  onChange={e => {
                    const { value } = e.target;
                    setFieldValue('last_name', value);
                  }}
                  placeholder='Last name'
                  value={values.last_name}
                />
              </InputWrapper>
              <InputWrapper>
                <TextField
                  disabled={isUpdateMode}
                  label='Email'
                  onChange={e => {
                    const { value } = e.target;
                    setFieldValue('email', value);
                  }}
                  placeholder='Email'
                  value={values.email}
                />
              </InputWrapper>
              <InputWrapper>
                {currentUserRole && (
                  <PermissionLevelSelect
                    onChange={e => {
                      const { value } = e.target;
                      setFieldValue('permission_level', value);
                    }}
                    requesterUserRole={currentUserRole.slice(5)}
                    value={values.permission_level}
                  />
                )}
              </InputWrapper>
              {!isUpdateMode && (
                <InputWrapper>
                  <Button
                    fullWidth
                    size='large'
                    type='submit'
                    variant='secondary'
                  >
                    Send invite
                  </Button>
                </InputWrapper>
              )}
              {isUpdateMode && (
                <Box padding='40px 0'>
                  <Box>
                    <Typography variant='font18Bold'>Permissions</Typography>
                    <Box
                      alignItems='center'
                      display='flex'
                      gap='20px'
                      padding='20px 0'
                    >
                      <Toggle
                        ariaLabel='Toggle analytics permission'
                        checked={values.hasAnalyticsAccess}
                        disabled={isAnalyticsDisabled}
                        onChange={e => {
                          setFieldValue('hasAnalyticsAccess', e.target.checked);
                        }}
                      />

                      <Box>
                        <Box alignItems='center' display='flex' gap='8px'>
                          <img src={iconAnalyticsGrey} />
                          <Typography variant='font14Bold'>
                            Advanced Analytics
                          </Typography>
                        </Box>
                        <Typography
                          color={palette.colors.grey[700]}
                          variant='font12'
                        >
                          {isAnalyticsDisabled
                            ? ANALYTICS_DISABLED_MSG
                            : ANALYTICS_ENABLED_MSG}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant='font18Bold'>Account Status</Typography>

                    <RadioGroup
                      aria-label='Set user status'
                      onChange={e => setFieldValue('enabled', e.target.value)}
                      row
                      value={values.enabled}
                    >
                      <FormControlLabel
                        control={<Radio size='small' />}
                        label={
                          <Typography variant='font14'>Enabled</Typography>
                        }
                        value={true}
                      />
                      <FormControlLabel
                        control={<Radio size='small' />}
                        label={
                          <Typography variant='font14'>Disabled</Typography>
                        }
                        value={false}
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              )}
            </InnerForm>
            {isUpdateMode && (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {onCancel && (
                  <Box sx={{ width: '160px' }}>
                    <Button
                      fullWidth
                      onClick={onCancel}
                      size='large'
                      type='button'
                      variant='secondary'
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
                <Button
                  disabled={!dirty || isSubmitting}
                  fullWidth
                  isLoading={isSubmitting}
                  size='large'
                  type='submit'
                  variant='main'
                >
                  Save
                </Button>
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

const Form = styled('form')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const inlineFormStyles = css`
  align-items: flex-end;
  gap: 16px;
`;

const stretchedFormStyles = css`
  gap: 24px;
  justify-content: space-between;
`;

const InnerForm = styled('div')<{ $buttonsAtBottom: boolean }>`
  display: flex;
  flex-grow: 0;
  flex-wrap: wrap;
  ${p => (p.$buttonsAtBottom ? stretchedFormStyles : inlineFormStyles)}
`;

const InputWrapper = styled('div')`
  flex: 1 0 200px;
`;
