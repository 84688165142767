import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { TextField } from '@forethought-technologies/forethought-elements';
import { getTextLengthErrorMessage } from '../../../utils';
import { emptyFieldTextErrorMessage } from '../../constants';
import IntentEmailForm from '../IntentEmailForm';
import {
  FEEDBACK_BUTTONS_TEXT_CHAR_LIMIT,
  HEADER_TEXT_CHAR_LIMIT,
} from './constants';
import {
  FeedbackComponentFields,
  ToolbarFormComponentProps,
} from 'src/components/email-builder/types';

const IntentEmailFeedbackForm = ({
  component,
  onClickCancel,
  onClickSave,
  setActiveComponentFields,
  setIsActiveComponentValid,
}: ToolbarFormComponentProps) => {
  const [
    shouldDisplayEmptyFieldErrorMessage,
    setShouldDisplayEmptyFieldErrorMessage,
  ] = useState(false);

  const componentFields = component.component_fields as FeedbackComponentFields;
  const { heading_text, helpful_label, not_helpful_label } = componentFields;

  const isHeaderTextSurpassingCharLimit =
    heading_text?.length > HEADER_TEXT_CHAR_LIMIT;

  const isHelpFulLabelTextSurpassingCharLimit =
    helpful_label?.length > FEEDBACK_BUTTONS_TEXT_CHAR_LIMIT;

  const isNotHelpFulLabelTextSurpassingCharLimit =
    not_helpful_label?.length > FEEDBACK_BUTTONS_TEXT_CHAR_LIMIT;

  const isFormFieldEmpty =
    !heading_text || !helpful_label || !not_helpful_label;

  const isFormFieldSurpassingCharLimit =
    isHeaderTextSurpassingCharLimit ||
    isHelpFulLabelTextSurpassingCharLimit ||
    isNotHelpFulLabelTextSurpassingCharLimit;

  const headingTextInputErrorMessage =
    !heading_text && shouldDisplayEmptyFieldErrorMessage
      ? emptyFieldTextErrorMessage
      : isHeaderTextSurpassingCharLimit &&
        getTextLengthErrorMessage(HEADER_TEXT_CHAR_LIMIT);

  const helpfulLabelTextInputErrorMessage =
    !helpful_label && shouldDisplayEmptyFieldErrorMessage
      ? emptyFieldTextErrorMessage
      : isHelpFulLabelTextSurpassingCharLimit &&
        getTextLengthErrorMessage(FEEDBACK_BUTTONS_TEXT_CHAR_LIMIT);

  const notHelpfulLabelTextInputErrorMessage =
    !not_helpful_label && shouldDisplayEmptyFieldErrorMessage
      ? emptyFieldTextErrorMessage
      : isNotHelpFulLabelTextSurpassingCharLimit &&
        getTextLengthErrorMessage(FEEDBACK_BUTTONS_TEXT_CHAR_LIMIT);

  const isFormInvalid = isFormFieldEmpty || isFormFieldSurpassingCharLimit;

  useEffect(() => {
    // set flag for parent component to show confirmation dialog when clicking outside
    setIsActiveComponentValid(!isFormInvalid);
  }, [isFormInvalid, setIsActiveComponentValid]);

  return (
    <IntentEmailForm
      isSaveButtonDisabled={isFormInvalid}
      onClickCancel={() => onClickCancel()}
      onClickSave={() => onClickSave()}
    >
      <Box display='flex' flexDirection='column' gap='30px'>
        <Box>
          <TextField
            error={headingTextInputErrorMessage}
            label='Header text'
            onChange={e =>
              setActiveComponentFields({
                ...componentFields,
                heading_text: e.target.value,
              })
            }
            onFocus={() => {
              if (!shouldDisplayEmptyFieldErrorMessage) {
                setShouldDisplayEmptyFieldErrorMessage(true);
              }
            }}
            value={heading_text}
          />
        </Box>
        <Box>
          <TextField
            error={helpfulLabelTextInputErrorMessage}
            label='Helpful label text'
            onChange={e =>
              setActiveComponentFields({
                ...componentFields,
                helpful_label: e.target.value,
              })
            }
            onFocus={() => {
              if (!shouldDisplayEmptyFieldErrorMessage) {
                setShouldDisplayEmptyFieldErrorMessage(true);
              }
            }}
            value={helpful_label}
          />
        </Box>
        <Box>
          <TextField
            error={notHelpfulLabelTextInputErrorMessage}
            label='Not helpful label text'
            onChange={e =>
              setActiveComponentFields({
                ...componentFields,
                not_helpful_label: e.target.value,
              })
            }
            onFocus={() => {
              if (!shouldDisplayEmptyFieldErrorMessage) {
                setShouldDisplayEmptyFieldErrorMessage(true);
              }
            }}
            value={not_helpful_label}
          />
        </Box>
      </Box>
    </IntentEmailForm>
  );
};

export default IntentEmailFeedbackForm;
