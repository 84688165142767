import ReactMarkdown from 'react-markdown';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ResponseMessage } from './StyledComponents';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import useStrippedHTML from 'src/hooks/useStrippedHTML';
import { Format } from 'src/slices/workflow-preview/types';

export const ApiTextStep = ({
  format = Format.html,
  message,
}: {
  format?: Format;
  message: string;
}) => {
  const isPlainText =
    useStrippedHTML(message) === message && format !== 'markdown';

  return (
    <ResponseMessage
      isMarkdown={format === 'markdown'}
      isPlainText={isPlainText}
    >
      <Typography component='div' variant='font14Medium'>
        {format === 'html' ? (
          <HtmlComponent
            content={message}
            linkifyHtmlOptions={solveLinkifyHtmlOptions}
          />
        ) : (
          <ReactMarkdown>{message}</ReactMarkdown>
        )}
      </Typography>
    </ResponseMessage>
  );
};
