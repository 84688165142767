import { useState } from 'react';
import { useParams } from 'react-router';
import { styled } from '@mui/material';

import { ApiTrafficPanel } from './api-preview/ApiTrafficPanel';
import { useGetColorMode } from './hooks';
import { PANELS } from './types';
import { WidgetInfoPanel } from './WidgetInfoPanel';

export const PreviewRightSection = () => {
  const { channel } = useParams<'channel'>();
  const colorMode = useGetColorMode();
  const [expandedPanel, setExpandedPanel] = useState<PANELS>('none');

  return (
    <RightSection colorMode={colorMode}>
      <WidgetInfoPanel
        expandedPanel={expandedPanel}
        setExpandedPanel={setExpandedPanel}
      />
      {channel === 'api' && (
        <ApiTrafficPanel
          expandedPanel={expandedPanel}
          setExpandedPanel={setExpandedPanel}
        />
      )}
    </RightSection>
  );
};

const RightSection = styled('div')<{ colorMode: string }>`
  display: flex;
  flex-direction: column;
  width: 628px;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
  background: ${props =>
    props.colorMode === 'dark' ? 'rgba(6, 17, 27, 0.50)' : '#fff'};
  ${props => props.colorMode === 'dark' && 'background: rgba(6, 17, 27, 0.50);'}

  box-shadow: ${props => props.theme.shadows[3]};
`;
