import { EditEmailAutomation } from 'src/pages/workflow-builder-edit/types';

export const automationActionTypeOptions = [
  { label: "Don't respond", value: 'no-response' },
  { label: 'Delay response', value: 'response-delay' },
  { label: 'Thread parsing', value: 'thread-parsing' },
];

export const labelMap = {
  'response-delay': 'until time has elapsed',
  'thread-parsing': 'for comment position',
};

export const automationActionTypeMap = {
  'response-delay': 'delay_in_minutes',
  'thread-parsing': 'comment_position',
};

export const defaultFilterQueryExpression = {
  expression_type: 'filter',
  field: 'subject',
  negate: false,
  operator: '=',
  values: [],
};

export const defaultEmailAutomation: EditEmailAutomation = {
  automation_actions: [
    {
      automation_action_fields: {},
      automation_action_type: 'no-response',
    },
  ],
  case_insensitive: true,
  query_expression: defaultFilterQueryExpression,
};
