import './FilterFieldsModal.scss';

import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import ModalItem from '../../../reusable-components/modal-item/ModalItem';
import SearchBar from '../../../reusable-components/search-bar/SearchBar';
import isEqual from 'lodash/fp/isEqual';
import {
  cleanFilterValues,
  clearSelectedFilterValues,
  getFilterValues,
  setFilterFieldNull,
  toggleSelectedFilterField,
} from 'src/actions/filters/filters';
import {
  selectFilterFields,
  selectSelectedFilterField,
} from 'src/reducers/filtersReducer/filtersReducer';
import { Field } from 'src/services/apiInterfaces';

interface FilterModalProps {
  fieldsReference: React.RefObject<HTMLDivElement>;
}

const FilterFieldsModal: React.FC<React.PropsWithChildren<FilterModalProps>> = (
  props: FilterModalProps,
) => {
  const { fieldsReference } = props;
  const [search, setSearch] = useState<string>('');
  const types = ['AGENT', 'TICKET'];
  const [selectedIndexType, setSelectedIndexType] = useState<number>(0);
  const filterFields = useSelector(selectFilterFields);
  const dispatch = useDispatch();
  const selectedField = useSelector(selectSelectedFilterField);

  const getFields = (index: number): Field[] => {
    const fieldCategory = index === 0 ? 'agent' : 'ticket';

    return filterFields[fieldCategory].map((field: Field) => {
      return { ...field, field_category: fieldCategory };
    });
  };

  const matchedItems = getFields(selectedIndexType).filter(field => {
    return field.field_name.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <div className='FilterFieldsModal-container' ref={fieldsReference}>
      <SearchBar
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
        }}
      />

      <div className='FilterFieldsModal-typeContainer'>
        {types.map((type, index) => (
          <div
            className={classNames('FilterFieldsModal-type', {
              'FilterFieldsModal-selectedType': index === selectedIndexType,
            })}
            key={type}
            onClick={() => {
              // SWITCHING BETWEEN ACTIONS / TICKETS
              setSelectedIndexType(index);
              dispatch(clearSelectedFilterValues());
              dispatch(setFilterFieldNull());
            }}
          >
            {type}
          </div>
        ))}
      </div>
      <div className='FilterFieldsModal-content'>
        {!matchedItems.length ? (
          <span className={'FilterFieldsModal-noResults'}>No results</span>
        ) : (
          matchedItems.map(field => {
            const fieldType = field?.field_type;
            return (
              <ModalItem
                fieldType={fieldType}
                isSelected={isEqual(selectedField, field)}
                itemValue={field.field_name}
                key={field.field_name}
                onClickFunction={() => {
                  dispatch(toggleSelectedFilterField(field));
                  fieldType !== 'DATE' &&
                    fieldType !== 'BOOLEAN' &&
                    dispatch(cleanFilterValues()) &&
                    dispatch(clearSelectedFilterValues()) &&
                    dispatch(getFilterValues());
                }}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default FilterFieldsModal;
