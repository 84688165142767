import { IntegrationSection } from './styles';
import CardSkeleton from 'src/components/card/CardSkeleton';

export default function IntegrationsEmptyState() {
  return (
    <IntegrationSection>
      {Array(12)
        .fill(null)
        .map((_, idx) => (
          <CardSkeleton key={idx} />
        ))}
    </IntegrationSection>
  );
}
