import {
  ContextVariable,
  DynamicListOption,
} from 'src/types/actionBuilderApiTypes';

export const formatContextVariablesForInput = (
  contextVariables: ContextVariable[] | DynamicListOption[],
  value: string,
  prevCvName?: string,
) => {
  let newMessage = value;
  contextVariables.forEach(cv => {
    const cvName = prevCvName
      ? prevCvName + '.' + cv.context_variable_name
      : `$${cv.context_variable_name}`;

    if (
      'configuration_fields' in cv &&
      cv.configuration_fields?.dynamic_list_config?.context_variables
    ) {
      newMessage = formatContextVariablesForInput(
        cv.configuration_fields?.dynamic_list_config?.context_variables,
        newMessage,
        cvName,
      );
      return;
    }

    if ('options' in cv && cv.options?.length) {
      newMessage = formatContextVariablesForInput(
        cv.options,
        newMessage,
        cvName,
      );
      return;
    }

    newMessage = newMessage.replaceAll(`{{${cv.context_variable_id}}}`, cvName);
  });
  return newMessage;
};
