import React from 'react';

import { theme } from '@forethought-technologies/forethought-elements';

interface Props {
  checked: boolean;
  color: string;
}

const CheckboxItem: React.FC<React.PropsWithChildren<Props>> = ({
  checked,
  color,
}: Props) => {
  if (checked) {
    return (
      <div className='Breakdown-checkboxComponent selected'>
        <svg
          fill='none'
          height='18'
          viewBox='0 0 18 18'
          width='18'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            fill={theme.palette.colors.slate[100]}
            fillOpacity='0.7'
            height='18'
            rx='3'
            transform='matrix(1 0 0 -1 0 18)'
            width='18'
          />
          <rect
            fill={theme.palette.colors.white}
            height='18'
            rx='3'
            transform='matrix(1 0 0 -1 0 18)'
            width='18'
          />
          <rect
            fill={color}
            height='18'
            rx='3'
            transform='matrix(1 0 0 -1 0 18)'
            width='18'
          />
          <line
            stroke={theme.palette.colors.white}
            strokeLinecap='round'
            strokeWidth='1.57597'
            x1='6.11438'
            x2='8.34314'
            y1='8.57214'
            y2='10.8009'
          />
          <line
            stroke={theme.palette.colors.white}
            strokeLinecap='round'
            strokeWidth='1.57597'
            x1='12.8013'
            x2='8.34375'
            y1='6.34338'
            y2='10.8009'
          />
        </svg>
      </div>
    );
  }

  return (
    <div className='Breakdown-checkboxComponent deselected'>
      <svg
        fill='none'
        height='18'
        viewBox='0 0 18 18'
        width='18'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          fill={theme.palette.colors.slate[100]}
          fillOpacity='0.7'
          height='17'
          rx='2.5'
          transform='matrix(1 0 0 -1 0 17)'
          width='17'
          x='0.5'
          y='-0.5'
        />
        <rect
          fill={theme.palette.colors.white}
          height='17'
          rx='2.5'
          transform='matrix(1 0 0 -1 0 17)'
          width='17'
          x='0.5'
          y='-0.5'
        />
        <rect
          height='17'
          rx='2.5'
          stroke={theme.palette.colors.slate[200]}
          transform='matrix(1 0 0 -1 0 17)'
          width='17'
          x='0.5'
          y='-0.5'
        />
        <line
          stroke={theme.palette.colors.white}
          strokeLinecap='round'
          strokeWidth='1.57597'
          x1='6.11438'
          x2='8.34314'
          y1='8.57214'
          y2='10.8009'
        />
        <line
          stroke={theme.palette.colors.white}
          strokeLinecap='round'
          strokeWidth='1.57597'
          x1='12.8013'
          x2='8.34375'
          y1='6.34338'
          y2='10.8009'
        />
      </svg>
    </div>
  );
};

export default CheckboxItem;
