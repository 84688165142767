import { useState } from 'react';
import { Box } from '@mui/material';
import { IconPlus } from '@tabler/icons-react';

import { Button, Tabs } from '@forethought-technologies/forethought-elements';
import useHelpdeskTicketFields from '../../hooks/useHelpdeskTicketFields';
import AutomationsListPage from '../automations-list-page/AutomationsListPage';
import EmailAutomationPage from '../email-automation-page/EmailAutomationPage';
import { defaultEmailAutomation } from './constants';
import Spinner from 'src/components/spinner/Spinner';
import { useGetEmailAutomationsQuery } from 'src/services/email-automation/emailAutomationApi';

const tabOptions = ['Active', 'Inactive'];

export function EmailAutomationPageContainer() {
  const [tabIndex, setTabIndex] = useState(0);
  const [isAutomationPageVisible, setIsAutomationPageVisible] = useState(false);

  const { isLoading: isLoadingCustomFields } = useHelpdeskTicketFields();
  const { isLoading: isLoadingEmailAutomationsList } =
    useGetEmailAutomationsQuery();

  const shouldDisplayLoadingSpinner =
    isLoadingCustomFields || isLoadingEmailAutomationsList;

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      gap='32px'
      height='100%'
      px={4}
      py={1}
    >
      <Box alignItems='center' display='flex' justifyContent='space-between'>
        <Tabs
          a11yProps={index => {
            const isActiveTab = index === 0;
            const tab = isActiveTab ? 'active' : 'inactive';
            return {
              'aria-controls': `${tab}-automation-tab`,
              id: `${tab}-automations-tab`,
            };
          }}
          aria-label='Select a tab'
          onChange={(_, index) => setTabIndex(index)}
          tabs={tabOptions}
          typographyVariant='font20'
          value={tabIndex}
        />
        <Button
          onClick={() => setIsAutomationPageVisible(true)}
          startIcon={<IconPlus fontSize='small' />}
          variant='secondary'
        >
          Add automation
        </Button>
      </Box>
      {shouldDisplayLoadingSpinner ? (
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          height='100%'
          justifyContent='center'
        >
          <Spinner />
        </Box>
      ) : (
        <AutomationsListPage isActiveSection={tabIndex === 0} />
      )}
      <EmailAutomationPage
        emailAutomation={{ ...defaultEmailAutomation }}
        isVisible={isAutomationPageVisible}
        onClose={() => setIsAutomationPageVisible(false)}
      />
    </Box>
  );
}
