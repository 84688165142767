import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { styled } from '@mui/material';

import {
  Colors,
  Typography,
} from '@forethought-technologies/forethought-elements';
import ChatHandoff from './chat-handoff';
import TicketCreation from './ticket-creation';
import Checkbox from 'src/components/checkbox';
import {
  Section,
  Spacer,
} from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { initializeFieldTouchedArr } from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { textMediumStyle, textRegularStyle } from 'src/styles/styledMixin';
import { ZendeskHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface HandoffConfigurationProps {
  customizationData: ZendeskHandoffCustomization;
  errorMessages: Array<string | Array<string>>;
  isFromCanvas: boolean;
  setCustomizationData: Dispatch<SetStateAction<ZendeskHandoffCustomization>>;
}

const ZendeskHandoffConfiguration: FC<
  React.PropsWithChildren<HandoffConfigurationProps>
> = ({
  customizationData,
  errorMessages,
  isFromCanvas,
  setCustomizationData,
}) => {
  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(customizationData),
  );

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isGranularizedZendeskActionsEnabled = featureFlags.includes(
    'granularized_zendesk_actions',
  );

  return (
    <ContentWrapper>
      <Content>
        {!isFromCanvas ? (
          <>
            <DefaultTitle>
              Connected with
              <Helpdesk color={Colors.ui.text.secondary} variant='font16'>
                Zendesk
              </Helpdesk>
            </DefaultTitle>
          </>
        ) : (
          <Title>
            Zendesk Handoff
            {isGranularizedZendeskActionsEnabled && ' (template)'}
          </Title>
        )}
        <Spacer height='20px' />
        <Section>
          <CheckBoxOption>
            <Checkbox
              checked={!!customizationData?.include_agent_chat_handoff}
              onChange={() =>
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    include_agent_chat_handoff:
                      !handoffData.include_agent_chat_handoff,
                  };
                })
              }
            />
            <CheckboxLabel>Include agent chat handoff</CheckboxLabel>
          </CheckBoxOption>
          {customizationData?.include_agent_chat_handoff && (
            <ChatHandoff
              customizationData={customizationData}
              errorMessages={errorMessages}
              isFieldTouchedArr={isFieldTouchedArr}
              isFromCanvas={isFromCanvas}
              setCustomizationData={setCustomizationData}
              setIsFieldTouchedArr={setIsFieldTouchedArr}
            />
          )}
        </Section>
        <Section>
          <CheckBoxOption>
            <Checkbox
              checked={!!customizationData?.include_ticket_creation_handoff}
              onChange={() =>
                setCustomizationData(handoffData => {
                  return {
                    ...handoffData,
                    include_ticket_creation_handoff:
                      !handoffData.include_ticket_creation_handoff,
                  };
                })
              }
            />
            <CheckboxLabel>Include ticket creation handoff</CheckboxLabel>
            <Spacer height='4px' />
          </CheckBoxOption>
          {customizationData?.include_ticket_creation_handoff && (
            <TicketCreation
              customizationData={customizationData}
              errorMessages={errorMessages}
              isFieldTouchedArr={isFieldTouchedArr}
              isFromCanvas={isFromCanvas}
              setCustomizationData={setCustomizationData}
              setIsFieldTouchedArr={setIsFieldTouchedArr}
            />
          )}
        </Section>
      </Content>
    </ContentWrapper>
  );
};

export default ZendeskHandoffConfiguration;

const CheckBoxOption = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const DefaultTitle = styled('h2')`
  ${textMediumStyle()}
  margin: 0 10px;
`;

const Helpdesk = styled(Typography)`
  margin-left: 5px;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Content = styled('div')`
  margin-top: 0;
  margin-bottom: 50px;

  min-height: 500px;
`;

const CheckboxLabel = styled('p')`
  margin-left: 6px;
  ${textRegularStyle({ color: Colors.ui.text.primary, fontSize: '16px' })}
`;
