import React from 'react';
import classNames from 'classnames';

import ActiveStateCircle from './active-state-circle/active-state-circle';
import { removeUnderscores } from 'src/utils/cleanStr';

interface DropDownMenuProps {
  buttonClass: string;
  data: string[];
  dropdownClass: string;
  dropdownContClass: string;
  icon?: boolean;
  islightMode?: boolean;
  onClick: Function;
  reference?: React.RefObject<HTMLDivElement>;
  selectedOption?: string;
}

const DropDownMenu: React.FC<React.PropsWithChildren<DropDownMenuProps>> = (
  props: DropDownMenuProps,
) => {
  const {
    buttonClass,
    data,
    dropdownClass,
    dropdownContClass,
    icon,
    islightMode,
    onClick,
    reference,
    selectedOption,
  } = props;

  const containerClass = classNames(dropdownContClass, {
    'FilterDropdownContainer--lightMode': islightMode,
  });

  const menuContainerClass = classNames(dropdownClass, {
    'dropdown-menu--lightMode': islightMode,
  });

  const dropdownOptionClass = classNames(buttonClass, {
    'dropdown-option--lightMode': islightMode,
  });

  return (
    <div className={containerClass} ref={reference}>
      <div aria-labelledby='dropdownMenu' className={menuContainerClass}>
        {data.map((option: string, index: number) => (
          <button
            aria-expanded='false'
            className={
              selectedOption === option
                ? `${dropdownOptionClass}${!islightMode ? ' focused' : ''} `
                : dropdownOptionClass
            }
            key={index + 'S'}
            onMouseDown={e => onClick(e, option)}
            type='button'
          >
            <span
              className={
                icon && option === 'Chart'
                  ? 'chart-view-icon'
                  : icon && option === 'Table'
                  ? 'table-view-icon'
                  : ''
              }
            />
            {removeUnderscores(option)}
            {!islightMode && selectedOption === option && <ActiveStateCircle />}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DropDownMenu;
