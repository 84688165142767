import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { NewPasswordForm } from '../create-password-form/CreatePasswordForm';
import LandingPageLayout from 'src/components/reusable-components/landing-page-layout';
import { Routes } from 'src/utils/enums';
import { hasErrorMessage } from 'src/utils/typeGuards';

const ForgotUserPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isResetPasswordRequested, setIsResetPasswordRequested] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    setEmail(event.currentTarget.value);
  };

  const handleGoBack = () => {
    navigate(Routes.HOMEPAGE);
  };

  return (
    <LandingPageLayout>
      {isResetPasswordRequested && (
        <NewPasswordForm
          errorMessage={errorMessage}
          handleSubmit={async ({ code = '', password1 }) => {
            setErrorMessage('');

            try {
              await Auth.forgotPasswordSubmit(email, code, password1);
              handleGoBack();
            } catch (error) {
              setErrorMessage(
                hasErrorMessage(error) ? error.message : 'An error happened',
              );
            }
          }}
          showCodeField
          title='Create a new password'
        />
      )}

      {!isResetPasswordRequested && (
        <form
          onSubmit={async event => {
            event.preventDefault();

            setErrorMessage('');

            try {
              await Auth.forgotPassword(email);
              setIsResetPasswordRequested(true);
            } catch (error) {
              setErrorMessage(
                hasErrorMessage(error) ? error.message : 'An error happened',
              );
            }
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <div>
              <Typography variant='font32'>Forgot your password?</Typography>
            </div>
            <div>
              <Typography variant='font16'>
                Provide the email you use to sign in and we&apos;ll send you
                instructions for resetting your password.
              </Typography>
            </div>
            <TextField
              aria-label='email'
              name='username'
              onChange={handleEmailChange}
              placeholder='Email'
              required
              type='email'
              value={email}
            />
            {errorMessage && (
              <Box>
                <Typography
                  color={theme.palette.colors.red[500]}
                  variant='font16'
                >
                  {errorMessage}
                </Typography>
              </Box>
            )}
            <Button size='large' type='submit' variant='main'>
              Reset Password
            </Button>
            <Button onClick={handleGoBack} size='large' variant='secondary'>
              Back to sign in
            </Button>
          </Box>
        </form>
      )}
    </LandingPageLayout>
  );
};

export default ForgotUserPassword;
