import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { TEMPLATE_INTENT_ID } from '../constants';
import {
  useGetAutoflowWorkflowOrAutoflowTemplate,
  useGetBuilderQueryParams,
  useGetIsTemplateIntentWorkflow,
} from '../hooks';
import { getWorkflowValueFromSearch } from './helpers';
import { useGetAutoflowToolsQuery } from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import { CHANNEL_TO_PRODUCT_MAP } from 'src/utils/constants';

export const useGetUsableTools = () => {
  const { search } = useLocation();
  const intentId = getWorkflowValueFromSearch(search, 'intentId');
  const intentWorkflowId = getWorkflowValueFromSearch(search, 'workflowId');
  const isTemplate = useGetIsTemplateIntentWorkflow();
  const { view } = useGetBuilderQueryParams();
  const { data: toolsData, isLoading: isLoadingTools } =
    useGetAutoflowToolsQuery({ product: CHANNEL_TO_PRODUCT_MAP[view] });
  const { autoflowWorkflow, isLoading } =
    useGetAutoflowWorkflowOrAutoflowTemplate(intentWorkflowId);

  const { tools: availableTools = [] } = toolsData ?? {};
  const { tool_definitions: toolDefinitions } = autoflowWorkflow ?? {};

  const tools = useMemo(() => {
    if (isTemplate && autoflowWorkflow && availableTools.length) {
      return autoflowWorkflow.tool_definitions?.map(toolDefinition => {
        const tool = availableTools.find(
          templateTool => templateTool.tool_id === toolDefinition.tool_id,
        );
        if (!tool) {
          throw new Error('Template tool does not exist');
        }

        return { ...tool, isActive: true };
      });
    }
    // filter out current intent from intent routing tools
    const filteredTools = availableTools
      .filter(
        tool =>
          tool.tool_type !== 'intent_routing' || tool.tool_id !== intentId,
      )
      // filter out template handoffs
      .filter(tool => !tool.tool_id.startsWith(TEMPLATE_INTENT_ID))
      // filter out template api call
      .filter(tool => tool.tool_type !== 'template_api_call');

    return filteredTools.map(availableTool => {
      const toolDefinition = toolDefinitions?.find(
        toolDefinition => toolDefinition.tool_id === availableTool.tool_id,
      );

      return {
        ...(availableTool.tool_id === 'search_help_center_articles'
          ? { ...availableTool, tool_name: 'Search Help Center Articles' }
          : availableTool),
        isActive: Boolean(toolDefinition),
        toolDefinition,
      };
    });
  }, [isTemplate, autoflowWorkflow, availableTools, toolDefinitions, intentId]);

  return {
    isLoading: isLoadingTools || isLoading,
    tools,
  } as const;
};
