import { createSelector } from '@reduxjs/toolkit';

import { ActionBuilderActions } from 'src/actions/action-builder-actions/ActionBuilderActionsTypes';
import { RootState } from 'src/mockStore';
import {
  Action,
  ActiveIntegrationsInterface,
  SelectedAction,
} from 'src/types/actionBuilderApiTypes';
import {
  ActionBuilderState,
  FetchAction,
} from 'src/types/actionBuilderReducerTypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

export const ActionBuilderInitialState: ActionBuilderState = {
  accessTokensSettingsErrorState: false,
  actions: [],
  actionsLoading: false,
  activeIntegrations: [],
  contextVariablesSettingsErrorState: false,
  customizableActionId: '',
  endpointBodyErrorState: false,
  endpointHeadersErrorState: false,
  endpointParamsErrorState: false,
  selectedAction: {
    action_description: '',
    action_fields: {
      headers: [
        {
          name: '',
          value: '',
        },
      ],
      jmespath_query: '',
      method: 'GET',
      natural_language_query: '',
      query_parameters: [
        {
          name: '',
          value: '',
        },
      ],
      url: '',
    },
    action_name: '',
    action_type: 'api',
  },
  selectedActionTemplate: null,
  selectedInitialActionName: '',
};

export default function actionBuilderReducer(
  state = ActionBuilderInitialState,
  action: FetchAction,
): ActionBuilderState {
  if (action.type === ActionBuilderActions.SET_CUSTOMIZABLE_ACTION_ID) {
    // @ts-expect-error legacy code with untyped state
    return { ...state, customizableActionId: action.payload };
  }
  if (action.type === ActionBuilderActions.SET_ACITON_BUILDER_ACTIONS_LOADING) {
    return {
      ...state,
      // @ts-expect-error legacy code with untyped state
      actionsLoading: action.payload,
    };
  }
  if (action.type === ActionBuilderActions.GET_ACTION_BUILDER_ACTIONS_SUCCESS) {
    const actions = action.payload as Action[];

    return {
      ...state,
      actions,
    };
  }

  if (
    action.type ===
    ActionBuilderActions.SET_CONTEXT_VARIABLES_SETTINGS_ERROR_STATE
  ) {
    // @ts-expect-error legacy code with untyped state
    return { ...state, contextVariablesSettingsErrorState: action.payload };
  }

  if (
    action.type ===
    ActionBuilderActions.GET_ACTION_BUILDER_ACTIVE_INTEGRATIONS_SUCCESS
  ) {
    const integrationObject = action.payload as ActiveIntegrationsInterface;
    return { ...state, activeIntegrations: integrationObject.integrations };
  }

  if (
    action.type === ActionBuilderActions.SET_OUTPUT_PARAMETER_MODAL_ACTION_DATA
  ) {
    const actionFields = (state.selectedAction as SelectedAction)
      ?.action_fields;

    const selectedAction = {
      ...state.selectedAction,
      action_fields: {
        ...actionFields,
        output_context_variables: {
          ...actionFields?.output_context_variables,
          ...(action.payload as {
            [key: string]: string;
          }),
        },
      },
    };
    return { ...state, selectedAction };
  }

  if (
    action.type ===
    ActionBuilderActions.CLEAR_OUTPUT_PARAMETERS_MODAL_ACTION_DATA
  ) {
    const actionFields = (state.selectedAction as SelectedAction)
      ?.action_fields;

    const selectedAction = {
      ...state.selectedAction,
      action_fields: {
        ...actionFields,
        output_context_variables: {},
      },
    };
    return { ...state, selectedAction };
  }

  if (action.type === ActionBuilderActions.CLEAR_SELECTED_ACTION) {
    return {
      ...state,
      selectedAction: ActionBuilderInitialState.selectedAction,
      selectedInitialActionName:
        ActionBuilderInitialState.selectedInitialActionName,
    };
  }

  if (action.type === ActionBuilderActions.SET_SELECTED_ACTION_TEMPLATE) {
    return {
      ...state,
      // @ts-expect-error legacy code with untyped state
      selectedActionTemplate: action.payload,
    };
  }

  if (action.type === ActionBuilderActions.SET_SELECTED_INITIAL_ACTION_NAME) {
    return {
      ...state,
      // @ts-expect-error legacy code with untyped state
      selectedInitialActionName: action.payload,
    };
  }

  return state;
}

const transformDepreciatedActionsForCanvas = (
  actions: Action[],
  featureFlags: string[] = [],
) => {
  const actionsWithoutTemplateApi = actions.filter(
    action => action.action_type !== 'template_api',
  );
  const actionsWithRenamedZendeskHandoff = featureFlags.includes(
    'granularized_zendesk_actions',
  )
    ? actionsWithoutTemplateApi.map(action =>
        action.action_type === 'zendesk_handoff'
          ? {
              ...action,
              action_name: 'Zendesk Handoff (template)',
            }
          : action,
      )
    : actionsWithoutTemplateApi;

  const actionsWithRenamedSalesforceHandoff = featureFlags.includes(
    'granularized_salesforce_actions',
  )
    ? actionsWithRenamedZendeskHandoff.map(action =>
        action.action_type === 'salesforce_handoff'
          ? {
              ...action,
              action_name: 'Salesforce Handoff (template)',
            }
          : action,
      )
    : actionsWithRenamedZendeskHandoff;

  const isGranularizedArticleSuggestionV2Enabled = featureFlags.includes(
    'granularized_article_suggestion_v2',
  );
  return actionsWithRenamedSalesforceHandoff.map(action =>
    action.action_type === 'article_suggestion'
      ? {
          ...action,
          action_name: isGranularizedArticleSuggestionV2Enabled
            ? 'Article Suggestion (template)'
            : 'Article Suggestion',
        }
      : action,
  );
};

export const transformActionsForCanvas = (
  actions: Action[],
  featureFlags: string[] = [],
) => {
  const actionsWithoutTemplateApi = actions.filter(
    action => action.action_type !== 'template_api',
  );

  const sortedActions = featureFlags.includes('granularized_zendesk_actions')
    ? moveZendeskActionsToTheTop(actionsWithoutTemplateApi)
    : actionsWithoutTemplateApi
        .filter(
          action =>
            action.action_type !==
              ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF_V2 &&
            action.action_type !==
              ActionBuilderActionTypes.ZENDESK_TICKET_CREATION,
        )
        .sort(alphabeticActionComparator);

  return featureFlags.includes('granularized_salesforce_actions')
    ? moveSalesforceActionsToTheTop(sortedActions)
    : actionsWithoutTemplateApi
        .filter(
          action =>
            action.action_type !==
            ActionBuilderActionTypes.SALESFORCE_CASE_CREATION,
        )
        .sort(alphabeticActionComparator);
};

export const selectActionBuilderActions = createSelector(
  (state: RootState): ActionBuilderState['actions'] =>
    state.actionBuilder.actions,
  // Extract the second argument to pass it on
  (state: RootState, featureFlags: string[]) => featureFlags,
  transformDepreciatedActionsForCanvas,
);

const alphabeticActionComparator = (a: Action, b: Action) => {
  const textA = a.action_name.toUpperCase();
  const textB = b.action_name.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
};

const moveZendeskActionsToTheTop = (actions: Action[]) => {
  const nonDeprecatedActions = actions.filter(
    action =>
      action.action_type !== ActionBuilderActionTypes.ZENDESK_CHAT_HANDOFF,
  );
  const zendeskHandoffActions: Action[] = nonDeprecatedActions
    .filter(({ action_type }) => action_type === 'zendesk_handoff')
    .map(action => ({
      ...action,
      action_name: 'Zendesk Handoff (template)',
    }));
  const startZendeskChatActions = nonDeprecatedActions.filter(
    ({ action_type }) =>
      action_type === 'zendesk_chat_handoff' ||
      action_type === 'zendesk_chat_handoff_v2',
  );
  const createZendeskTicketActions = nonDeprecatedActions.filter(
    ({ action_type }) => action_type === 'zendesk_ticket_creation',
  );
  const zendeskAttachmentUploadActions = nonDeprecatedActions.filter(
    ({ action_type }) => action_type === 'zendesk_attachment_upload',
  );
  const nonZendeskActions = nonDeprecatedActions.filter(({ action_type }) => {
    const zendeskActionTypes: Action['action_type'][] = [
      'zendesk_handoff',
      'zendesk_chat_handoff',
      'zendesk_chat_handoff_v2',
      'zendesk_ticket_creation',
      'zendesk_attachment_upload',
    ];
    return !zendeskActionTypes.includes(action_type);
  });

  return [
    ...zendeskHandoffActions,
    ...startZendeskChatActions,
    ...createZendeskTicketActions,
    ...zendeskAttachmentUploadActions,
    ...nonZendeskActions.sort(alphabeticActionComparator),
  ];
};

const moveSalesforceActionsToTheTop = (actions: Action[]) => {
  const salesforceHandoffActions: Action[] = actions
    .filter(({ action_type }) => action_type === 'salesforce_handoff')
    .map(action => ({
      ...action,
      action_name: 'Salesforce Handoff (template)',
    }));
  const startSalesforceChatActions = actions.filter(
    ({ action_type }) => action_type === 'salesforce_chat_handoff',
  );
  const createSalesforceCaseActions = actions.filter(
    ({ action_type }) => action_type === 'salesforce_case_creation',
  );
  const nonSalesforceActions = actions.filter(({ action_type }) => {
    const salesforceActionTypes: Action['action_type'][] = [
      'salesforce_handoff',
      'salesforce_chat_handoff',
      'salesforce_case_creation',
    ];
    return !salesforceActionTypes.includes(action_type);
  });

  return [
    ...salesforceHandoffActions,
    ...startSalesforceChatActions,
    ...createSalesforceCaseActions,
    ...nonSalesforceActions.sort(alphabeticActionComparator),
  ];
};

export const selectActions = (
  state: RootState,
): ActionBuilderState['actions'] => state.actionBuilder.actions;

export const selectNonDeprecatedActionBuilderActions = createSelector(
  (state: RootState): ActionBuilderState['actions'] =>
    state.actionBuilder.actions,
  // Extract the second argument to pass it on
  (state: RootState, featureFlags: string[]) => featureFlags,
  transformActionsForCanvas,
);

export const selectApiActions = createSelector(
  [
    (state: RootState): ActionBuilderState['actions'] =>
      state.actionBuilder.actions,
  ],
  (actions): ActionBuilderState['actions'] => {
    return actions.filter(
      action => action.action_type === ActionBuilderActionTypes.API,
    );
  },
);

export const selectActionBuilderActionsLoading = (
  state: RootState,
): ActionBuilderState['actionsLoading'] => state.actionBuilder.actionsLoading;

export const selectContextVariablesSettingsErrorState = (
  state: RootState,
): ActionBuilderState['contextVariablesSettingsErrorState'] =>
  state.actionBuilder.contextVariablesSettingsErrorState;

export const selectCustomizableActionId = (
  state: RootState,
): ActionBuilderState['customizableActionId'] =>
  state.actionBuilder.customizableActionId;

export const selectSelectedAction = (
  state: RootState,
): ActionBuilderState['selectedAction'] => state.actionBuilder.selectedAction;
export const selectSelectedActionTemplate = (
  state: RootState,
): ActionBuilderState['selectedActionTemplate'] =>
  state.actionBuilder.selectedActionTemplate;

export const selectSelectedInitialActionName = (state: RootState): string =>
  state.actionBuilder.selectedInitialActionName;

export const selectIsActionCustomized = (state: RootState): boolean =>
  state.actionBuilder.customizableActionId !== '';
