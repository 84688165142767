import React, { FC, useRef } from 'react';
import { Field, FieldProps } from 'formik';
import { styled } from '@mui/material';

import { useGetContextVariablesForTextEditor } from '../../email-builder-page/intent-email-builder/hooks';
import { RichTextEditor } from 'src/components/rich-text-editor/RichTextEditor';
import {
  EditorRef,
  LinkPreviewConfig,
} from 'src/components/rich-text-editor/types';
import { ErrorMessage } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { CatchAllHandoffCustomization } from 'src/types/workflowBuilderAPITypes';

interface CustomMessageProps {
  customizationData: CatchAllHandoffCustomization;
  linkPreviewConfig?: LinkPreviewConfig;
}

const CustomMessage: FC<React.PropsWithChildren<CustomMessageProps>> = ({
  customizationData,
  linkPreviewConfig,
}) => {
  const remirrorContextVariables = useGetContextVariablesForTextEditor();
  const editorRef = useRef<EditorRef>(null);

  const initialValue = customizationData.custom_message || '';

  return (
    <Container>
      <Field
        name='custom_message'
        validate={(value: string) => {
          if (value === '') {
            return 'Message cannot be empty';
          }
        }}
      >
        {({
          field,
          form: { setFieldTouched, setFieldValue },
          meta,
        }: FieldProps) => (
          <>
            <RichTextEditorContainer
              isError={Boolean(meta.touched && meta.error)}
            >
              <RichTextEditor
                contextVariables={remirrorContextVariables}
                editorRef={editorRef}
                initialContent={initialValue}
                linkPreviewConfig={linkPreviewConfig}
                onBlur={() => setFieldTouched(field.name, true)}
                onChange={html => {
                  setFieldValue(field.name, html);
                }}
                placeholder='Add custom message'
              />
            </RichTextEditorContainer>
            {meta.touched && meta.error && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </>
        )}
      </Field>
    </Container>
  );
};

export default CustomMessage;

const RichTextEditorContainer = styled('div')<{ isError: boolean }>`
  background: ${props => props.theme.palette.background.default};
  border: 1px solid
    ${props =>
      props.isError
        ? props.theme.palette.error.main
        : props.theme.palette.colors.grey[200]};
  border-radius: 6px;
`;

const Container = styled('div')`
  transform: translateX(-10px);
`;
