import { useTheme } from '@mui/material';
import { Widgets } from '@rjsf/mui';
import { WidgetProps } from '@rjsf/utils';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function CheckboxWidget({
  label,
  schema,
  uiSchema,
  ...rest
}: WidgetProps) {
  const { CheckboxWidget } = Widgets;
  const { palette } = useTheme();
  const description = uiSchema?.['ui:description'] || schema.description;

  return (
    <>
      <CheckboxWidget
        label={label}
        schema={schema}
        uiSchema={uiSchema}
        {...rest}
      />

      <Typography color={palette.colors.grey[600]} variant='font12'>
        {description}
      </Typography>
    </>
  );
}
