import moment from 'moment';
import * as Yup from 'yup';

import { AssistNote } from 'src/slices/assist-notes/types';

export const getEditedTimeStringFromNow = (lastModified: number) => {
  return moment(lastModified * 1000).fromNow();
};

const validationSchema = Yup.object().shape({
  body: Yup.string().required('Body is required'),
  title: Yup.string().required('Title is required'),
});

export const isNoteValid = (note: AssistNote) => {
  try {
    validationSchema.validateSync(note);

    return true;
  } catch {
    return false;
  }
};

/**
 * Determines if the current editor content (currentBody and currentTitle) have been changed
 * from what is present on the selectedNote.
 * @param currentBody
 * @param currentTitle
 * @param selectedNote
 * @returns true if changes have been made, false otherwise.
 */
export const checkHasNoteContentChanged = (
  currentBody: string,
  currentTitle: string,
  selectedNote?: AssistNote,
) => {
  if (!selectedNote) {
    return false;
  }
  const doBodiesMatch =
    extractTextContent(currentBody) === extractTextContent(selectedNote?.body);
  const doTitlesMatch = currentTitle === selectedNote?.title;
  return !doTitlesMatch || !doBodiesMatch;
};

/**
 * Given a string that contains html with inner text nodes, return only the text content of that html string
 * @param htmlString
 * @returns
 */
export const extractTextContent = (htmlString: string | undefined | null) => {
  if (!htmlString) {
    return '';
  }
  const temporaryDiv = document.createElement('div');
  try {
    temporaryDiv.innerHTML = htmlString;
    const content = temporaryDiv.textContent;
    temporaryDiv.remove();
    return content;
  } catch {
    return '';
  }
};
