import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatTicketFields } from '../helpers';
import { getPredictedFields } from 'src/actions/triageSettings/triageSettings';
import {
  selectHelpdesk,
  selectIsHelpdeskNotConfigured,
  selectTicketFields,
} from 'src/reducers/triageSettingsReducer/triageSettingsReducer';

export const useGetDataSourcePredictedFields = () => {
  const dispatch = useDispatch();
  const { data: ticketFieldsData } = useSelector(selectTicketFields);
  const isHelpdeskNotConfigured = useSelector(selectIsHelpdeskNotConfigured);
  const helpdesk = useSelector(selectHelpdesk);

  useEffect(() => {
    dispatch(
      getPredictedFields({
        helpdesk,
        isHelpdeskNotConfigured,
      }),
    );
  }, [dispatch, helpdesk, isHelpdeskNotConfigured]);

  return formatTicketFields(ticketFieldsData?.ticket_fields);
};
