import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { IconPlus, IconSettingsFilled } from '@tabler/icons-react';

import {
  Button,
  FilterButton,
  IconButton,
  SearchBar,
  Tabs,
} from '@forethought-technologies/forethought-elements';
import ActionTemplateModal from '../template-modal';
import ActionBuilderTable from './action-builder-table';
import ActionDeleteDialog from './action-delete-dialog';
import ActionSettingsModal from './action-settings-modal';
import CollectionsTab from './collections-tab';
import { clearSelectedAction } from 'src/actions/action-builder-actions/actionBuilderActions';
import FilterButtonIcon from 'src/components/discover-filter-button-icon/FilterButtonIcon';
import { useAppDispatch } from 'src/store/hooks';
import { replaceActionIdInRoute } from 'src/utils/actionBuilder/helpers';

const sortFilterOptions = [
  { label: 'Date created (newest)', value: 'newest' },
  { label: 'Date created (oldest)', value: 'oldest' },
];

const a11yName = 'action-builder';

const a11yTabProps = (index: number) => {
  return {
    'aria-controls': `${a11yName}-tabpanel-${index}`,
    id: `${a11yName}-tab-${index}`,
  };
};

const a11yTabPanelProps = (index: number) => {
  return {
    'aria-labelledby': `${a11yName}-tab-${index}`,
    id: `${a11yName}-tabpanel-${index}`,
  };
};

const tabs = [
  { Component: ActionBuilderTable, name: 'Available Actions' },
  { Component: CollectionsTab, name: 'Collections' },
] as const;

const ActionBuilderV2 = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const [searchText, setSearchText] = useState('');
  const [dateSortFilter, setDateSortFilter] = useState<'newest' | 'oldest'>(
    'newest',
  );
  const [actionIdToDelete, setActionIdToDelete] = useState('');
  const [shouldShowSettingsModal, setShouldShowSettingsModal] = useState(false);

  const onCloseDialog = () => setActionIdToDelete('');

  return (
    <>
      <Box bgcolor={palette.background.default} flex={1}>
        <Box px={4}>
          <Box
            alignItems='center'
            boxShadow={'0px 1px 0px 0px' + palette.colors.slate[200]}
            display='flex'
            justifyContent='space-between'
            my={3}
          >
            <Tabs
              a11yProps={a11yTabProps}
              aria-label='Action builder'
              onChange={(_, value) => setTabIndex(value)}
              tabs={tabs.map(({ name }) => name)}
              typographyVariant='font20'
              value={tabIndex}
            />
            <Box
              display='flex'
              gap={1}
              sx={{
                'button:first-child': {
                  color: palette.colors.grey[700],
                },
              }}
            >
              <IconButton
                aria-label='Settings'
                onClick={() => setShouldShowSettingsModal(true)}
                variant='ghost'
              >
                <IconSettingsFilled />
              </IconButton>
              <Button
                onClick={() => {
                  dispatch(clearSelectedAction());
                  navigate(replaceActionIdInRoute(''));
                }}
                startIcon={<IconPlus />}
                variant='main'
              >
                Create new action
              </Button>
            </Box>
          </Box>
          <Box display='flex' gap={1} mb={4}>
            <Box width='300px'>
              <SearchBar
                onChange={({ target }) => setSearchText(target.value)}
                onClear={() => setSearchText('')}
                placeholder='Search'
                size='small'
                value={searchText}
              />
            </Box>
            {tabIndex === 0 && (
              <FilterButton
                aria-label='Sort actions'
                initialValue='newest'
                onChange={value => {
                  setDateSortFilter(value as 'newest' | 'oldest');
                }}
                options={sortFilterOptions}
                startAdornment={<FilterButtonIcon dropdownType='ticket' />}
                value={dateSortFilter}
              />
            )}
          </Box>
          {tabs.map(({ Component, name }, index) => (
            <div
              hidden={tabIndex !== index}
              key={name}
              role='tabpanel'
              {...a11yTabPanelProps(index)}
            >
              {tabIndex === index && (
                <Component
                  dateSortFilter={dateSortFilter}
                  searchText={searchText}
                  setActionIdToDelete={setActionIdToDelete}
                />
              )}
            </div>
          ))}
        </Box>
      </Box>
      <ActionDeleteDialog
        actionIdToDelete={actionIdToDelete}
        onClose={onCloseDialog}
      />
      <ActionSettingsModal
        isOpen={shouldShowSettingsModal}
        onClose={() => setShouldShowSettingsModal(false)}
      />
      <ActionTemplateModal onFinished={() => setTabIndex(0)} />
    </>
  );
};

export default ActionBuilderV2;
