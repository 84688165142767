import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { selectAutomations } from '../../slices/assist-automations/assistAutomationsSlice';
import { callPreviewAssistAutomation } from '../../slices/assist-automations/thunks';
import { serializeActionInputFormatter } from '../assist-automations-form/run-action-card/input-card/helpers';
import ActionStatus from './action-status';
import Card from './card';
import Form from './form';
import InternalMessage from './internal-message';
import cloneDeep from 'lodash/fp/cloneDeep';
import assistAutomationActiveActionStatusIcon from 'src/assets/images/assist-automation-active-action-status-icon.svg';
import assistAutomationInactiveActionStatusIcon from 'src/assets/images/assist-automation-inactive-action-status-icon.svg';
import assistAutomationPreviewIcon from 'src/assets/images/assist-automation-preview-icon.svg';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { AutomationInputAction } from 'src/services/apiInterfaces';
import { AutomationForm } from 'src/slices/assist-automations/types';
import {
  ASSIST_AUTOMATIONS_APPCUES_TARGET,
  ASSIST_AUTOMATIONS_TEXT,
} from 'src/utils/constants';

const Wrapper = styled('div')`
  padding: 30px 25px;
  border-radius: 8px;
  border: 1px solid ${theme.palette.colors.blue[200]};
  background-color: ${theme.palette.colors.blue[100]};
  min-height: calc(100vh - 100px);
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const filterPreviewValues = (
  previewValues: Record<string, string | string[] | number>,
  action: AutomationInputAction[] | null,
): Record<string, string | string[] | number> => {
  const result = { ...previewValues };

  Object.keys(result).forEach(key => {
    if (result[key] === '') {
      delete result[key];
    }

    const inputType = String(
      action?.find((actionItem: AutomationInputAction) =>
        Boolean(
          Object.values(actionItem).filter(
            actionItemValue => actionItemValue === key,
          ).length,
        ),
      )?.type,
    );

    switch (inputType) {
      case 'array-string':
        result[key] =
          typeof result[key] === 'string'
            ? String(result[key])
                .split(',')
                .map(item => item.trim())
            : result[key];
        break;
      case 'array-integer':
      case 'array-number':
        result[key] =
          typeof result[key] === 'string'
            ? String(result[key]).replaceAll(/ /g, '').split(',')
            : result[key];
      default:
        break;
    }
  });

  return result;
};

export default function AssistAutomationsPreview({
  isReadOnly,
}: {
  isReadOnly: boolean;
}) {
  const { action, isPreviewSubmitLoading, previewOutput } =
    useSelector(selectAutomations);
  const dispatch = useDispatch();
  const { values } = useFormikContext<AutomationForm>();
  const {
    action_list,
    actions_input_formatter,
    actions_output_formatter,
    description,
    emblem_urls,
    external_text_field,
    input_description,
    input_fields,
    internal_text_field,
    published,
    title,
  } = values;

  const formConfig = useMemo(
    () =>
      input_fields
        .filter(inputField => inputField.input_text)
        .map(inputField => ({
          id: inputField.context_variable,
          placeholder: inputField.input_text,
          value: '',
        })),
    [input_fields],
  );

  const actionStatus = useMemo(
    () =>
      published ? (
        <ActionStatus
          description={`Upon clicking 'Enter', system will trigger ${title} action`}
          iconSrc={assistAutomationActiveActionStatusIcon}
          statusColor={theme.palette.colors.green[100]}
          title='Action Active'
        />
      ) : (
        <ActionStatus
          description='The action you are trying to test has not been connected correctly. Review your assigned inputs.'
          iconSrc={assistAutomationInactiveActionStatusIcon}
          statusColor={theme.palette.colors.yellow[100]}
          title='Action Inactive'
        />
      ),
    [published, title],
  );

  const handleSubmit = useCallback(
    (previewValues: Record<string, string>) => {
      const _actionsInputFormatter = cloneDeep(actions_input_formatter);

      if (action && _actionsInputFormatter) {
        _actionsInputFormatter[action.action_id] =
          serializeActionInputFormatter(_actionsInputFormatter, action);
      }

      dispatch(
        callPreviewAssistAutomation({
          automation: {
            action_list: action_list.map(action => ({
              [action.action_id]: action.connector_id,
            })),
            actions_input_formatter: _actionsInputFormatter,
            actions_output_formatter,
            external_text_field,
            internal_text_field,
          },
          inputs: filterPreviewValues(
            previewValues,
            action && _actionsInputFormatter[action.action_id],
          ),
          isReadOnly,
        }),
      );
    },
    [
      dispatch,
      action_list,
      actions_input_formatter,
      actions_output_formatter,
      external_text_field,
      internal_text_field,
      action,
      isReadOnly,
    ],
  );

  return (
    <Wrapper data-appcues-target={ASSIST_AUTOMATIONS_APPCUES_TARGET.runTest}>
      <TitleWrapper>
        <Typography variant='font16Bold'>
          {ASSIST_AUTOMATIONS_TEXT.previewTitle}
        </Typography>
        <Typography color={theme.palette.colors.grey[500]} variant='font12'>
          {ASSIST_AUTOMATIONS_TEXT.previewDescription}
        </Typography>
      </TitleWrapper>
      <Card
        description={
          description || ASSIST_AUTOMATIONS_TEXT.previewCardDescription
        }
        iconSrc={
          emblem_urls.length ? emblem_urls[0] : assistAutomationPreviewIcon
        }
        title={title || ASSIST_AUTOMATIONS_TEXT.previewCardTitle}
      />
      {input_description && (
        <Typography variant='font14'>{input_description}</Typography>
      )}
      {Boolean(formConfig.length) && (
        <Form
          action={action && values.actions_input_formatter[action?.action_id]}
          formConfig={formConfig}
          isReadOnly={isReadOnly}
          isSubmitting={isPreviewSubmitLoading}
          onSubmit={handleSubmit}
        />
      )}
      {Boolean(action_list.length) && actionStatus}
      {previewOutput.internal_text_field && (
        <InternalMessage text={previewOutput.internal_text_field} />
      )}
      {previewOutput.external_text_field && (
        <Typography variant='font12'>
          <HtmlComponent content={previewOutput.external_text_field} />
        </Typography>
      )}
      {previewOutput.error && (
        <Typography variant='font12'>
          <HtmlComponent content={previewOutput.error} />
        </Typography>
      )}
    </Wrapper>
  );
}
