import React from 'react';
import { ReactSVG } from 'react-svg';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import EmptyStateIcon from 'src/assets/images/empty-state-notes-icon.svg';

const EmptyState: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      height='70%'
      justifyContent='center'
      width='100%'
    >
      <ReactSVG src={EmptyStateIcon} />
      <Box
        alignItems='center'
        display='flex'
        flexDirection='column'
        gap={1}
        justifyContent='center'
        mt={1}
      >
        <Typography color={theme.palette.colors.black} variant='font16Bold'>
          No shared Assist notes found
        </Typography>
        <Typography color={theme.palette.colors.grey[700]} variant='font14'>
          Try reloading the page later
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyState;
