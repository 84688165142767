import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { getDiscoverAutomationsApi } from 'src/services/discover/api';
import { DiscoverAutomationsByTypeResponse } from 'src/types/DiscoverTypes';
import { WorkflowBuilderLandingPageData } from 'src/types/workflowBuilderAPITypes';

const getDiscoverTopics = (
  solveWorkflowsAutomations: DiscoverAutomationsByTypeResponse | undefined,
  workflowData: WorkflowBuilderLandingPageData['breakdown'],
) => {
  return workflowData.map(data => {
    let topicsToInclude: Array<DiscoverTopic> = [];
    solveWorkflowsAutomations?.automations.forEach(automation => {
      if (automation.automated_object.automated_object_id === data.id) {
        topicsToInclude = topicsToInclude.concat(automation.topics);
      }
    });
    return { ...data, topics: topicsToInclude };
  });
};

const getWorkflowsDataWithTopics = (
  automations: Array<DiscoverAutomationsByTypeResponse>,
  workflowData: WorkflowBuilderLandingPageData['breakdown'],
) => {
  const solveWorkflowsAutomations = automations.find(
    automation => automation.type === 'solve_workflow',
  );

  const workflowDataWithTopics = getDiscoverTopics(
    solveWorkflowsAutomations,
    workflowData,
  );

  return workflowDataWithTopics;
};

export const getLandingPageDataWithTopics = async (
  landingPageData: WorkflowBuilderLandingPageData,
) => {
  const automations = await getDiscoverAutomationsApi(false);

  const breakdownDataWithTopics = getWorkflowsDataWithTopics(
    automations,
    landingPageData.breakdown,
  );

  return { ...landingPageData, breakdown: breakdownDataWithTopics };
};

export const isImageASquare = (file: File): Promise<boolean> => {
  return new Promise(resolve => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => resolve(img.height === img.width);
    img.onerror = () => resolve(false);
  });
};
