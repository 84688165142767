import React from 'react';
import { MenuItem } from '@mui/material';

import CustomThemeProvider from '../theme';
import { ContextVariableOptionWithIndent } from 'src/utils/solve/dynamicContextVariableUtils';

export interface ContextVariableOptionWithIndentAndLabelToDisplay
  extends ContextVariableOptionWithIndent {
  labelToDisplay?: string;
}

export function ContextVariableIndentedMenuItems({
  onClick,
  options,
}: {
  onClick: (option: ContextVariableOptionWithIndentAndLabelToDisplay) => void;
  options: ContextVariableOptionWithIndentAndLabelToDisplay[];
}) {
  return (
    <>
      {options.map(option => {
        return (
          <IndentedMenuItem key={option.id} onClick={onClick} option={option} />
        );
      })}
    </>
  );
}

export const IndentedMenuItem = React.forwardRef<
  HTMLLIElement | null,
  {
    focused?: boolean;
    onClick: (option: ContextVariableOptionWithIndentAndLabelToDisplay) => void;
    option: ContextVariableOptionWithIndentAndLabelToDisplay;
  }
>(({ focused = false, onClick, option }, ref) => {
  return (
    <CustomThemeProvider>
      <MenuItem
        disabled={option.disabled}
        key={option.id}
        onClick={() => onClick(option)}
        ref={ref}
        selected={focused}
        sx={theme => ({
          ...theme.typography.font14,
          '& .MuiButton-root': {
            display: 'none',
          },
          '&.Mui-focusVisible': {
            backgroundColor: 'rgba(0,0,0,0.05)',
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            '& .MuiButton-root': {
              display: 'flex',
            },
            backgroundColor: `${theme.palette.colors.purple[100]} !important`,
          },
          '&:not(.Mui-disabled)': {
            color: !option.isDefined
              ? theme.palette.colors.red[500]
              : theme.palette.colors.purple[500],
          },
          alignItems: 'center',
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: 2 + option.indent * 2,
          ...(focused
            ? {
                backgroundColor: `${theme.palette.colors.purple[100]} !important`,
              }
            : {}),
        })}
        tabIndex={option.disabled ? -1 : 0}
      >
        ${option.labelToDisplay || option.label}
      </MenuItem>
    </CustomThemeProvider>
  );
});

IndentedMenuItem.displayName = 'IndentedMenuItem';
