import React, { FC, useState } from 'react';
import { FieldArray, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Box from '@mui/material/Box';

import { Button } from '@forethought-technologies/forethought-elements';
import {
  ActionTemplateFormikInput,
  ActionTemplateMentionsFormikInput,
} from './FormikInputs';
import * as Styled from './styles';
import {
  createActionBuilderAction,
  setSelectedActionTemplate,
  updateActionBuilderAction,
} from 'src/actions/action-builder-actions/actionBuilderActions';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import { useGetActionBuilderData } from 'src/hooks/useGetActionBuilderData';
import {
  useGetActionBuilderActionsQuery,
  useGetActionUsagesQuery,
} from 'src/services/action-builder/actionBuilderApi';
import { ContextVariableListItems } from 'src/services/apiInterfaces';
import { SnowflakeSelectedActionTemplate } from 'src/types/actionBuilderApiTypes';

interface SnowflakeFormProps {
  closeCallback: () => void;
  onSaveCallback: () => void;
  selectedAction: SnowflakeSelectedActionTemplate;
}

export const SnowflakeForm: FC<React.PropsWithChildren<SnowflakeFormProps>> = ({
  closeCallback,
  onSaveCallback,
  selectedAction,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const { data: usagesData } = useGetActionUsagesQuery();
  const { action_fields: actionFields, action_id } = selectedAction;
  const { contextVariables } = useGetActionBuilderData();

  const { data: actionsData } = useGetActionBuilderActionsQuery({});
  const { actions = [] } = actionsData ?? {};
  const isActive =
    (usagesData?.action_id_to_intent_ids[action_id ?? ''] ?? []).length > 0;

  const formattedContextVariables: ContextVariableListItems[] =
    contextVariables.map(
      ({
        context_variable_id: id,
        context_variable_name: displayName,
        context_variable_type: type,
      }) => ({
        displayName,
        id,
        type,
      }),
    );

  return (
    <Formik
      initialValues={{
        ...actionFields,
        actionName: selectedAction.action_name,
        output_fields: actionFields.output_fields?.kv_list,
      }}
      onSubmit={async values => {
        setIsLoading(true);
        const { actionName, output_fields: outputFields } = values;

        const body = {
          ...selectedAction,
          action_fields: {
            ...values,
            output_fields: {
              kv_list:
                outputFields?.map(({ key }) => ({
                  key,
                  value: `snowflake_${key.toLowerCase()}`,
                })) ?? [],
            },
          },
          action_name: actionName,
        };

        if (selectedAction.action_id) {
          await dispatch(
            updateActionBuilderAction(body, selectedAction.action_id),
          );
        } else {
          await dispatch(createActionBuilderAction(body));
        }

        setIsLoading(false);
        onSaveCallback();
        closeCallback();
      }}
      validationSchema={Yup.object().shape({
        account: Yup.string().trim().min(1).required(),
        actionName: Yup.string()
          .trim()
          .min(1)
          .required()
          .test(value =>
            selectedAction.action_id
              ? true
              : actions.every(({ action_name }) => action_name !== value),
          ),
        data_table_name: Yup.string().trim().min(1).required(),
        db_name: Yup.string().trim().min(1).required(),
        output_fields: Yup.array()
          .of(
            Yup.object().shape({ key: Yup.string().trim().min(1).required() }),
          )
          .required()
          .min(1),
        password: Yup.string().trim().min(1).required(),
        query_condition: Yup.string().trim().min(1).required(),
        username: Yup.string().trim().min(1).required(),
      })}
    >
      {({ isValid, touched, values }) => {
        return (
          <Styled.SnowflakeForm>
            <Styled.InputsContainer>
              <ActionTemplateFormikInput
                disabled={isActive}
                label='Action Name'
                name='actionName'
                onChangeDispatch={value =>
                  dispatch(
                    setSelectedActionTemplate({
                      ...selectedAction,
                      action_name: value,
                    }),
                  )
                }
              />
              <ActionTemplateFormikInput
                disabled={isActive}
                label='Username'
                name='username'
              />
              <ActionTemplateFormikInput
                disabled={isActive}
                label='Password'
                name='password'
                type='password'
              />
              <ActionTemplateFormikInput
                disabled={isActive}
                label='Account Name'
                name='account'
              />
              <ActionTemplateFormikInput
                disabled={isActive}
                label='Database Name'
                name='db_name'
              />
              <ActionTemplateFormikInput
                disabled={isActive}
                label='Data Table Name'
                name='data_table_name'
              />
              <ActionTemplateMentionsFormikInput
                contextVariables={formattedContextVariables}
                disabled={isActive}
                label='Query Condition'
                name='query_condition'
              />
              <FieldArray name='output_fields'>
                {({ push, remove }) => {
                  return (
                    <>
                      <Styled.Label>Output Fields</Styled.Label>
                      {values.output_fields?.map((item, idx) => (
                        <Styled.FieldContainer key={idx}>
                          <ActionTemplateFormikInput
                            disabled={isActive}
                            label='Output Field'
                            name={`output_fields.${idx}.key`}
                            shouldHideLabel
                          />
                          <Styled.SnowflakeOutputField>
                            {item.key.trim() !== '' && (
                              <p>$Snowflake {item.key}</p>
                            )}
                          </Styled.SnowflakeOutputField>
                          <Styled.RemoveButton
                            isDisabled={isActive}
                            onClick={() => {
                              if (!isActive) {
                                remove(idx);
                                if (values.output_fields?.length === 1) {
                                  push({ key: '', value: '' });
                                }
                              }
                            }}
                            role='button'
                            src={xIcon}
                          />
                        </Styled.FieldContainer>
                      ))}
                      <Box mt={1}>
                        <Button
                          disabled={isActive}
                          onClick={() => push({ key: '', value: '' })}
                          variant='secondary'
                        >
                          Add
                        </Button>
                      </Box>
                    </>
                  );
                }}
              </FieldArray>
            </Styled.InputsContainer>
            <Styled.SaveContainer>
              <Button
                disabled={
                  isActive ||
                  Object.values(touched).length === 0 ||
                  !isValid ||
                  isLoading
                }
                isLoading={isLoading}
                type='submit'
                variant='main'
              >
                Save
              </Button>
            </Styled.SaveContainer>
          </Styled.SnowflakeForm>
        );
      }}
    </Formik>
  );
};
