// Style
import './card.scss';

import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

import { bookmarkReport } from 'src/actions/reports/reports';
import tooltipInfoIcon from 'src/assets/images/tooltip-info.svg';
import ReusableTooltip from 'src/components/reusable-components/tooltip/RebrandTooltip';
import { numberWithCommas } from 'src/utils/analyticsUtils';

interface CardProps {
  /** class for card */
  class: string;
  /** Totals count displayed on card */
  count: number;
  /** description of report */
  description: string;
  /** value to know if the report is bookmarked */
  isBookmarked?: boolean;
  /** value to know if the report is a default forethought report */
  isDefault?: boolean;
  /** value to know if the report is modified */
  isModified?: boolean;
  isReportSelected: boolean;
  /** Function when card is clicked  */
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => void;
  /** id of report */
  report_id?: number;
  /** todo: @gigi remove once we merge V1 -> this adds support to V0 */
  shouldHideBookmark?: boolean;
  /** Tooltip class */
  tooltipClass: string;
  /** Tooltip text */
  tooltipText: string;

  /** Tooltip title */
  tooltipTitle: string;
}

const Card: React.FC<React.PropsWithChildren<CardProps>> = (
  props: CardProps,
) => {
  const dispatch = useDispatch();
  const { isBookmarked, isDefault, isModified, isReportSelected } = props;

  const bookmarkClass = classNames({
    'Card-newReportStar': !isDefault && !props.isBookmarked,
    'Card-reportStarred': isBookmarked,
    'Card-unsavedReport': isModified || !isBookmarked,
    'disabled-cursor': (isBookmarked && isDefault) || isModified,
    none: props.shouldHideBookmark,
  });

  const defaultReportClass = classNames({
    'Card-defaultReportIcon': !isReportSelected && isDefault,
    'Card-defaultReportIcon-active': isReportSelected && isDefault,
    hidden: !props.isDefault,
  });

  const isReportActive = classNames({
    'card-count show-count': isReportSelected,
    'collapse-count': !isReportSelected,
  });

  return (
    <button className={props.class} onClick={props.onClick}>
      <span className='Card--iconContainer'>
        <span className={defaultReportClass} />
        <span className='Card--rightAligned'>
          <span
            className={bookmarkClass}
            onClick={() => {
              !props.isDefault
                ? dispatch(
                    bookmarkReport(props.report_id, !isBookmarked, false),
                  )
                : null;
            }}
          />
          <ReusableTooltip text={props.tooltipText} title={props.tooltipTitle}>
            <Icon src={tooltipInfoIcon} />
          </ReusableTooltip>
        </span>
      </span>
      <span className={isReportActive}>
        {props.count && Number.isInteger(props.count)
          ? numberWithCommas(props.count)
          : 0}
      </span>
      <span className='card-desc'>
        <span className='card-desc-title'>{props.description}</span>

        <span className={props.isModified ? 'Card-span' : 'none'}>
          {' '}
          (Unsaved)
        </span>
      </span>
    </button>
  );
};

export default Card;

const Icon = styled('img')`
  height: 16px;
  width: 16px;
`;
