import { CanvasWorkflowBuilderState } from './workflowBuilderSlice';
import { selectChatOrder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { isDynamicArticleSuggestion } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducerHelpers';
import store from 'src/store/store';
import { getIsStepEntryOfArticleSuggestionAction } from 'src/utils/articleSuggestionUtils';
import {
  articleWasHelpfulCase,
  articleWasNotHelpfulCase,
} from 'src/utils/constants';
import {
  getGranularArticleSuggestionV2ParentStepId,
  isEntryStepOfGranularArticleSuggestionV2,
} from 'src/utils/granularArticleSuggestionV2Utils';

export const getActionParentStepId = () => {
  const state = store.getState();
  const chatOrder = selectChatOrder(state);

  const canvasActions =
    state.canvasWorkflowBuilder.canvas_action_id_to_action_component;

  const canvasActionIdKeys = Object.keys(canvasActions);

  const entryStepIds: Array<string> = canvasActionIdKeys.map(
    (id: string) => canvasActions[id]?.entry_step_id ?? '',
  );

  const actionEntryStepId = entryStepIds.find((entryStepId: string) =>
    chatOrder.includes(entryStepId),
  );

  return chatOrder[
    chatOrder.findIndex(stepId => stepId === actionEntryStepId) - 1
  ];
};

const getArticleSuggestionCaseParentStepId = (stepId: string) => {
  const state = store.getState() as {
    canvasWorkflowBuilder: CanvasWorkflowBuilderState;
  };

  const actionCaseMap = state.canvasWorkflowBuilder.actionCaseMap;

  const isSuccessCase = actionCaseMap[stepId];

  const canvasActions =
    state.canvasWorkflowBuilder.canvas_action_id_to_action_component;

  const actionIdKeys = Object.keys(canvasActions);

  const articleSuggestionActionId =
    actionIdKeys.find(
      key => canvasActions[key]?.['entry_step_id'] === stepId,
    ) || '';

  const articleSuggestionActionTransitionBranches =
    canvasActions[articleSuggestionActionId]?.['transition_branches'];

  // if the action is dynamic article suggestion and has no button step, then the parent step is the condition step
  if (
    isDynamicArticleSuggestion(
      state.canvasWorkflowBuilder,
      articleSuggestionActionId,
    )
  ) {
    return articleSuggestionActionTransitionBranches
      ? articleSuggestionActionTransitionBranches[0]?.parent_step_id
      : '';
  }

  const parentStepId =
    articleSuggestionActionTransitionBranches?.find(transitionBranch => {
      if (isSuccessCase) {
        return transitionBranch.display_name === articleWasHelpfulCase;
      }
      return transitionBranch.display_name === articleWasNotHelpfulCase;
    })?.parent_step_id || '';

  return parentStepId;
};

// Provided with a parent step id that we're about to attach a step/action to, we need to check
// 1. if the parent step id is actualy an entry step to an (granular) article suggestion action
// 2. if yes, we go into the list of steps of the action to find out the actual parent step id
// 3. if no, return the step id
export const getActualParentStepId = (
  parentStepId: string | null | undefined,
): string | null => {
  if (!parentStepId) {
    return null;
  }

  if (getIsStepEntryOfArticleSuggestionAction(parentStepId)) {
    return getArticleSuggestionCaseParentStepId(parentStepId);
  } else if (isEntryStepOfGranularArticleSuggestionV2(parentStepId)) {
    return getGranularArticleSuggestionV2ParentStepId(parentStepId);
  } else {
    return parentStepId;
  }
};
