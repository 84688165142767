import { DiscoverTopicTableData } from '../types';
import { TopicRendererComponent } from './TopicRenderer';

export const topicCellRenderer =
  (searchText: string, percentageOfTicketVolume: string) =>
  // eslint-disable-next-line react/display-name
  ({ name, topic }: DiscoverTopicTableData, isSummaryRow?: boolean) =>
    (
      <TopicRendererComponent
        doesArticleHaveGap={topic?.relevant_article_count === 0}
        generatedArticleCount={topic?.generated_article_count}
        isAutomated={Boolean(topic?.is_automated)}
        isHidden={Boolean(topic?.is_hidden)}
        isNewTopic={Boolean(topic?.is_new_topic)}
        isRecommended={Boolean(topic?.is_recommended)}
        isSummaryRow={Boolean(isSummaryRow)}
        name={topic?.topic_name || name}
        percentageOfTicketVolume={percentageOfTicketVolume}
        searchText={searchText}
        topicDisplayName={topic?.topic_display_name}
        topicId={topic?.topic_id}
      />
    );
