import { ALL_INTENTS } from '../constants';
import { buildQueryFromMultiSelected } from './useConversations';
import { conversationChannelToProductMap } from 'src/constants/solve';
import { useSolveMetricsQueryParams } from 'src/pages/workflow-builder/intent-workflows-table/hooks/useSolveMetricsQueryParams';
import {
  useGetConversationAggregateMetricsByIntentIdQuery,
  useGetConversationAggregateMetricsQuery,
} from 'src/services/solve-conversations';

interface UseConversationsAggregateMetricsParams {
  autonomousAgentUsed: boolean | null;
  endDate: string;
  intentId: string;
  multiSelected: string[];
  startDate: string;
}

export default function useConversationsAggregateMetrics({
  autonomousAgentUsed,
  endDate,
  intentId,
  multiSelected,
  startDate,
}: UseConversationsAggregateMetricsParams) {
  const { channel } = useSolveMetricsQueryParams();
  const filterByIntent = intentId !== ALL_INTENTS;

  const baseQuery = {
    autonomousAgentUsed,
    end: endDate,
    product: conversationChannelToProductMap[channel],
    start: startDate,
  };

  const filteredResponse = useGetConversationAggregateMetricsByIntentIdQuery(
    {
      ...baseQuery,
      intentId,
    },
    { skip: !filterByIntent },
  );
  const allIntentsResponse = useGetConversationAggregateMetricsQuery(
    { ...baseQuery, ...buildQueryFromMultiSelected(multiSelected) },
    { skip: filterByIntent },
  );

  return filterByIntent ? filteredResponse : allIntentsResponse;
}
