import 'rc-slider/assets/index.css';
import './index.scss';

import { useEffect, useState } from 'react';
import Slider from 'rc-slider';

import Tooltip from '../tooltip/tooltip';

interface SliderProps {
  /** Current value of coverage */
  coverage: number;
  /** Label for range slider */
  label: string;
  /** Label description for max range */
  maxRangeDescription: string;
  /** Label name for max range */
  maxRangeLabel: string;
  /** Label description for min range */
  minRangeDescription: string;
  /** Label name for min range */
  minRangeLabel: string;
  /** Callback to be executed on change */
  onChange: Function;
  /** Description to be shown in tooltip */
  tooltipDescription: string;
  /** title to be shown in tooltip */
  tooltipTitle: string;
}

export const RangeSlider = ({
  coverage,
  label,
  maxRangeDescription,
  maxRangeLabel,
  minRangeDescription,
  minRangeLabel,
  onChange,
  tooltipDescription,
  tooltipTitle,
}: SliderProps) => {
  const [sliderValue, setSliderValue] = useState<number>(coverage);

  useEffect(() => {
    setSliderValue(coverage);
  }, [coverage]);

  return (
    <div className='RangeSlider'>
      <div className='RangeSlider-label'>
        {label}
        <Tooltip tooltipText={tooltipDescription} tooltipTitle={tooltipTitle} />
      </div>
      <Slider
        marks={{
          0: { label: '1' },
          100: { label: '5' },
          25: { label: '2' },
          50: { label: '3' },
          75: { label: '4' },
        }}
        max={100}
        min={0}
        onAfterChange={value => onChange(value)}
        onChange={value => setSliderValue(value)}
        step={25}
        value={sliderValue}
      />
      <div className='RangeSlider-RangeLabel'>
        <div className='RangeSlider-RangeLabel-cont'>
          <p className='RangeSlider-RangeLabel-title'>{minRangeLabel}</p>
          <p className='RangeSlider-RangeLabel-description'>
            {minRangeDescription}
          </p>
        </div>
        <div className='RangeSlider-RangeLabel-cont'>
          <p className='RangeSlider-RangeLabel-title'>{maxRangeLabel}</p>
          <p className='RangeSlider-RangeLabel-description'>
            {maxRangeDescription}
          </p>
        </div>
      </div>
    </div>
  );
};
