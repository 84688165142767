import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  Button,
  SelectDropdown,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetCustomIntents } from './hooks/useGetCustomIntents';
import { useInterestedButtonClickedMutation } from './hooks/useInterestedButtonClickedMutation';
import { DISCOVER_TEXT } from './constants';
import {
  automateTopic,
  getDiscoverRecommendations,
} from 'src/actions/discover/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { selectAutomateTopic } from 'src/reducers/discoverReducer/discoverReducer';
import { DiscoverTopic } from 'src/reducers/discoverReducer/types';
import { dashboardApi } from 'src/services/dashboard-api';
import { getDiscoverAutomations } from 'src/slices/data/thunks';
import {
  setGlobalDiscoverOptions,
  setGlobalToastOptions,
} from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

interface AutomationModalActionRowProps {
  canCreateAutomation: boolean;
  isSolveEnabled: boolean;
  selectedNeighboringTopicIds: string[];
  topic: DiscoverTopic;
}

const AutomationModalActionRow = ({
  canCreateAutomation,
  isSolveEnabled,
  selectedNeighboringTopicIds,
  topic,
}: AutomationModalActionRowProps) => {
  const { loading: isAutomateTopicLoading } = useSelector(selectAutomateTopic);
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const { data } = useGetCustomIntents(isSolveEnabled);
  const { interestedButtonClicked, isLoading: isInterestedClickedLoading } =
    useInterestedButtonClickedMutation();

  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const [intentWorkflowId, setIntentWorkflowId] = useState('');

  const location = useLocation();

  const { intents } = data ?? { intents: [] };

  const handleInterestedClick = async () => {
    await interestedButtonClicked({
      topic_id: topic.topic_id,
      topic_name: topic.topic_name,
    });
    dispatch(
      setGlobalDiscoverOptions({
        postAutomationActionModalOptions: {
          automatedObjectName: '',
          isOpen: true,
          redirectUrl: '',
        },
      }),
    );
  };

  const refreshAutomatedData = () => {
    if (location.pathname === Routes.DISCOVER_AUTOMATION) {
      dispatch(getDiscoverAutomations());
      dispatch(getDiscoverRecommendations());
    }
  };

  const handleAutomateClick = async (automatedObjectId?: string) => {
    emitTrackingEventCallback(
      automatedObjectId
        ? 'discover-add-to-existing-intent-workflow-clicked'
        : 'discover-automate-with-a-new-workflow-clicked',
      {
        neighbor_topic_ids: selectedNeighboringTopicIds,
        pathname: location.pathname,
        'topic-name': topic.topic_name,
      },
    );
    try {
      const updatedTopic = await dispatch(
        automateTopic({
          automated_object_id: automatedObjectId,
          automation_type: 'solve_workflow',
          neighbor_topic_ids: selectedNeighboringTopicIds,
          topic_id: topic.topic_id,
          was_recommended: topic.is_recommended,
        }),
      ).unwrap();

      refreshAutomatedData();
      dispatch(dashboardApi.util.invalidateTags(['Intents']));

      const redirectUrl =
        (updatedTopic.automation_urls && updatedTopic.automation_urls[0]) ?? '';

      dispatch(
        setGlobalDiscoverOptions({
          postAutomationActionModalOptions: {
            automatedObjectName:
              data?.intents.find(
                intent => intent.intent_workflow_id === automatedObjectId,
              )?.intent_name ?? '',
            isOpen: true,
            redirectUrl,
          },
        }),
      );
    } catch (error) {
      console.error(error);
      dispatch(setGlobalDiscoverOptions({ topicToAutomate: null }));
      dispatch(
        setGlobalToastOptions({
          autoHideDuration: 3000,
          title: 'Something unexpected occurred. Please try again.',
          variant: 'danger',
        }),
      );
    }
  };

  const renderAutomateButton = () => {
    const button = (
      <Button
        disabled={isAutomateTopicLoading || !canCreateAutomation}
        fullWidth
        onClick={() => handleAutomateClick()}
        size='large'
        startIcon={<AddIcon />}
        variant={intentWorkflowId ? 'secondary' : 'main'}
      >
        Automate with a new workflow
      </Button>
    );

    if (canCreateAutomation) {
      return button;
    }

    return (
      <Box
        sx={{
          '& > div': {
            width: '100%',
          },
        }}
      >
        <Tooltip tooltipContent={DISCOVER_TEXT.automationTooFewPhrases}>
          {button}
        </Tooltip>
      </Box>
    );
  };

  const renderWorkflowSelectDropdown = () => {
    const dropdown = (
      <SelectDropdown
        disabled={intents.length === 0}
        id='automation_workflow_choice'
        onChange={event => {
          emitTrackingEventCallback('discover-intent-workflow-selected', {
            pathname: location.pathname,
            'topic-name': topic.topic_name,
          });
          setIntentWorkflowId(event.target.value);
        }}
        onOpen={() => {
          emitTrackingEventCallback(
            'discover-intent-workflow-dropdown-opened',
            {
              pathname: location.pathname,
              'topic-name': topic.topic_name,
            },
          );
        }}
        options={intents.map(intent => ({
          label: intent.intent_name,
          value: intent.intent_workflow_id,
        }))}
        placeholder='Existing workflow...'
        value={intentWorkflowId}
      />
    );

    if (intents.length === 0) {
      return (
        <Box
          sx={{
            '& > div': {
              width: '100%',
            },
          }}
        >
          <Tooltip tooltipContent={DISCOVER_TEXT.automationNoExistingWorkflows}>
            {dropdown}
          </Tooltip>
        </Box>
      );
    }

    return dropdown;
  };

  if (!isSolveEnabled) {
    return (
      <Button
        disabled={isInterestedClickedLoading}
        fullWidth
        isLoading={isInterestedClickedLoading}
        onClick={handleInterestedClick}
        size='large'
        variant='main'
      >
        I am interested!
      </Button>
    );
  }

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      {renderAutomateButton()}
      <Box display='flex' mb='24px' mt='24px'>
        <Box
          bgcolor={palette.colors.slate[200]}
          flexGrow={1}
          height='1px'
          margin='auto'
          mr='16px'
        />
        <Typography variant='font14Bold'>
          Or choose an existing workflow from
        </Typography>
        <Box
          bgcolor={palette.colors.slate[200]}
          flexGrow={1}
          height='1px'
          margin='auto'
          ml='16px'
        />
      </Box>
      {renderWorkflowSelectDropdown()}
      <Box mt='16px'>
        <Button
          disabled={!intentWorkflowId || isAutomateTopicLoading}
          fullWidth
          onClick={() => handleAutomateClick(intentWorkflowId)}
          size='large'
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default AutomationModalActionRow;
