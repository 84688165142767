import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { IconCopy } from '@tabler/icons-react';

import {
  Alert,
  Button,
  PasswordField,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  ACS_URL,
  DELETE_CONFIRM_MESSAGE,
  DISABLE_SCIM_MESSAGE,
  ENTITY_ID,
  REGENERATE_SCIM_TOKEN_MESSAGE,
} from './constants';
import IdPForm from './IdPForm';
import { InlineForm, InnerPage, Section } from './styles';
import SettingsPageLayout from 'src/components/settings-page-layout';
import Spinner from 'src/components/spinner';
import { selectSSOState } from 'src/slices/sso/ssoSlice';
import {
  createSCIMKey,
  deleteIdP,
  disableSCIM,
  getSSOSettings,
  updateSSOConfig,
} from 'src/slices/sso/thunks';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

export default function SSOSettingsPage() {
  const dispatch = useAppDispatch();
  const {
    idpSettings,
    isSCIMAllowed,
    isSCIMEnabled,
    isSSOEnabled,
    loading,
    loadingSCIM,
    scimToken,
  } = useSelector(selectSSOState);

  const { metadata_file: metadataFile, metadata_url: metadataUrl } =
    idpSettings || {};

  useEffect(() => {
    dispatch(getSSOSettings());
  }, [dispatch]);

  const handleEnableSSO = async (enable: boolean) => {
    return dispatch(updateSSOConfig({ is_sso_enabled: enable }));
  };

  const handleDelete = () => {
    if (window.confirm(DELETE_CONFIRM_MESSAGE)) {
      dispatch(deleteIdP());
    }
  };

  const handleCreateSCIMToken = () => {
    // If SCIM is enabled, prompt for confirmation before regenerating the token
    if (!isSCIMEnabled || window.confirm(REGENERATE_SCIM_TOKEN_MESSAGE)) {
      dispatch(createSCIMKey());
    }
  };

  const handleDisableSCIM = () => {
    if (window.confirm(DISABLE_SCIM_MESSAGE)) {
      dispatch(disableSCIM());
    }
  };

  const isConfigured = Boolean(metadataFile || metadataUrl);
  const testURL = window.location.origin + Routes.SINGLE_SIGN_ON;

  return (
    <SettingsPageLayout>
      {loading ? (
        <Spinner />
      ) : (
        <InnerPage>
          <Section>
            <Typography component='h2' variant='font18Bold'>
              Configure your Identity Provider (IdP)
            </Typography>
            <Typography variant='font14'>
              In order to configure your Identity Provider, some apps will
              request the values below.
            </Typography>
            <Box display='flex' flexDirection='column' gap={2} py={1}>
              <InlineForm as='div'>
                <Box flex='1'>
                  <TextField label='ACS URL' name='ACS URL' value={ACS_URL} />
                </Box>
                <Button
                  onClick={() => navigator.clipboard.writeText(ACS_URL)}
                  size='large'
                  startIcon={<IconCopy />}
                  variant='secondary'
                >
                  Copy ACS URL
                </Button>
              </InlineForm>
              <InlineForm as='div'>
                <Box flex='1'>
                  <PasswordField
                    label='Entity ID'
                    name='Entity ID'
                    value={ENTITY_ID}
                  />
                </Box>
                <Button
                  onClick={() => navigator.clipboard.writeText(ENTITY_ID)}
                  size='large'
                  startIcon={<IconCopy />}
                  variant='secondary'
                >
                  Copy Entity ID
                </Button>
              </InlineForm>
            </Box>
          </Section>
          <Section>
            <Typography component='h2' variant='font18Bold'>
              Configure SSO
            </Typography>
            <Typography variant='font14'>
              To configure single sign on, provide either a metadata URL or an
              XML metadata file.
            </Typography>

            {isConfigured ? (
              <Box p='16px 0'>
                <Alert
                  footer={
                    <Button onClick={handleDelete} variant='danger'>
                      Delete IdP
                    </Button>
                  }
                  title='An SSO Identity Provider is already configured for your organization'
                  variant='main'
                >
                  If you wish to update your SSO details, you will need to
                  delete the existing IdP configuration.
                </Alert>
              </Box>
            ) : (
              <IdPForm
                initialValues={{
                  metadataFile,
                  metadataUrl,
                }}
              />
            )}
          </Section>
          <Section>
            <Typography component='h2' variant='font18Bold'>
              Enable SSO
            </Typography>
            <Typography variant='font14'>
              You can test your SSO configuration before enabling it for your
              org. We recommend opening the below URL in a private browser
              window in order to test the IdP login.
            </Typography>
            <Box display='flex' flexDirection='column' gap={2} pt={1}>
              <InlineForm as='div'>
                <Box flex='1'>
                  <TextField label='SSO test URL' value={testURL} />
                </Box>
                <Button
                  onClick={() => navigator.clipboard.writeText(testURL)}
                  size='large'
                  startIcon={<IconCopy />}
                  variant='secondary'
                >
                  Copy test URL
                </Button>
              </InlineForm>
              <Button
                disabled={!isConfigured}
                onClick={() => handleEnableSSO(!isSSOEnabled)}
                size='large'
                variant={isSSOEnabled ? 'danger' : 'main'}
              >
                {isSSOEnabled ? 'Disable' : 'Enable'} SSO
              </Button>
            </Box>
          </Section>
          {isSSOEnabled && isSCIMAllowed && (
            <Section>
              <Typography component='h2' variant='font18Bold'>
                Configure SCIM
              </Typography>
              <Typography variant='font14'>
                To learn more, please visit{' '}
                <a
                  href='https://support.forethought.ai/hc/en-us/articles/10187144861715-Getting-Started-with-Single-Sign-On'
                  rel='noreferrer'
                  target='_blank'
                >
                  our support documentation.
                </a>
              </Typography>
              <Box display='flex' flexDirection='column' gap={2} pt={1}>
                {loadingSCIM ? (
                  <Spinner />
                ) : (
                  <>
                    <Box display='flex' gap={2}>
                      <Button
                        fullWidth
                        onClick={handleCreateSCIMToken}
                        size='large'
                        variant='secondary'
                      >
                        {isSCIMEnabled
                          ? 'Regenerate SCIM API key'
                          : 'Enable SCIM'}
                      </Button>
                      {isSCIMEnabled && (
                        <Button
                          fullWidth
                          onClick={handleDisableSCIM}
                          size='large'
                          variant='danger'
                        >
                          Disable SCIM
                        </Button>
                      )}
                    </Box>
                    {scimToken && (
                      <InlineForm as='div'>
                        <Box flex='1'>
                          <PasswordField
                            label='SCIM API key'
                            value={scimToken}
                          />
                        </Box>
                        <Button
                          onClick={() =>
                            navigator.clipboard.writeText(scimToken)
                          }
                          size='large'
                          startIcon={<IconCopy />}
                          variant='secondary'
                        >
                          Copy API key
                        </Button>
                      </InlineForm>
                    )}
                  </>
                )}
              </Box>
            </Section>
          )}
        </InnerPage>
      )}
      <Box height='160px' />
    </SettingsPageLayout>
  );
}
