import { Formik } from 'formik';
import Box from '@mui/material/Box';

import {
  Button,
  Dialog,
  TextField,
} from '@forethought-technologies/forethought-elements';
import { TOAST_TIMEOUT } from '../../triage-config-detail-page/helpers';
import { VersionedTriageModel } from 'src/reducers/triageSettingsReducer/types';
import { useDuplicateVersionMutation } from 'src/services/triage/triageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface DuplicateVersionDialogProps {
  currentVersionName: string;
  isOpen: boolean;
  modelId: string;
  onClose: () => void;
  onSuccess: (data: VersionedTriageModel) => void;
  versionId: string;
}

const DuplicateVersionDialog = ({
  currentVersionName,
  isOpen,
  modelId,
  onClose,
  onSuccess,
  versionId,
}: DuplicateVersionDialogProps) => {
  const [mutate, { isLoading }] = useDuplicateVersionMutation();
  const dispatch = useAppDispatch();
  const initialValues = {
    versionName: `Copy of ${currentVersionName}`,
  };

  const handleSubmit = ({ versionName }: { versionName: string }) => {
    mutate({ modelId, versionId, versionName })
      .unwrap()
      .then(onSuccess)
      .catch(() =>
        dispatch(
          setGlobalToastOptions({
            autoHideDuration: TOAST_TIMEOUT,
            title: 'Duplicate version request failed',
            variant: 'danger',
          }),
        ),
      );
  };
  return (
    <Dialog
      hideCloseButton
      onClose={onClose}
      open={isOpen}
      title='Duplicate as a version to edit'
      variant='sm'
    >
      <Box width='346px'>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, setFieldValue, values }) => {
            return (
              <form
                onSubmit={event => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <Box display='flex' flexDirection='column' rowGap={2}>
                  <TextField
                    disabled={isLoading}
                    label='Version name'
                    onChange={e =>
                      setFieldValue('versionName', e.currentTarget.value)
                    }
                    required
                    value={values.versionName}
                  />
                  <Box columnGap={1} display='flex' justifyContent='flex-end'>
                    <Button
                      disabled={isLoading}
                      onClick={onClose}
                      variant='ghost'
                    >
                      Cancel
                    </Button>
                    <Button isLoading={isLoading} type='submit' variant='main'>
                      Duplicate
                    </Button>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default DuplicateVersionDialog;
