import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box, useTheme } from '@mui/material';
import { IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  TextField,
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { LoadingSkeleton } from '../../../../components/reusable-components/loading-skeleton';
import { getContextVariableType } from '../../conditions/conditionEditorHelpers';
import ContextVariableValue from './ContextVariableValue';
import isEqual from 'lodash/fp/isEqual';
import pullAt from 'lodash/fp/pullAt';
import set from 'lodash/fp/set';
import uniq from 'lodash/fp/uniq';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { Spacer } from 'src/pages/workflow-builder-edit/handoff-configuration/styles';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  CanvasWorkflowBuilderState,
  selectActionCustomization,
  selectAddingAction,
  setActionCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import type { RootState } from 'src/store/rootReducer';
import { textMediumStyle } from 'src/styles/styledMixin';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  ContextVariableValue as ContextVariableValueType,
  SetContextVariableCustomizationSettings,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';
import { isContextVariableValueValid } from 'src/utils/validators';

interface SetContextVariableCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const SetContextVariableCustomizationPanel: FC<
  React.PropsWithChildren<SetContextVariableCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  // @ts-expect-error legacy code with untyped state
  const setContextVariableCustomizationSettings:
    | SetContextVariableCustomizationSettings
    | undefined = useSelector(
    (state: RootState) =>
      state.canvasWorkflowBuilder?.actionSettings.configuration_fields,
  );
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const { contextVariables } = useGetContextVariables();
  const actionSettings = canvasState?.actionSettings;
  const addingAction = useSelector(selectAddingAction);
  const initialCustomizationData: typeof setContextVariableCustomizationSettings =
    addingAction
      ? {
          mappings: [
            {
              context_variable_id: '',
              value: '',
            },
          ],
        }
      : setContextVariableCustomizationSettings && {
          mappings: setContextVariableCustomizationSettings.mappings,
        };

  const customizationData = (useSelector(selectActionCustomization) ||
    initialCustomizationData) as SetContextVariableCustomizationSettings;

  const isPristine = isEqual(customizationData, initialCustomizationData);

  const customizationDataWithoutEmptyRecords: SetContextVariableCustomizationSettings =
    {
      mappings:
        customizationData?.mappings.filter(
          mapping => mapping.context_variable_id,
        ) || [],
    };

  const isFormDisabled = () => {
    if (!addingAction && isPristine) {
      return true;
    }

    if (
      !customizationData ||
      customizationData.mappings.length === 0 ||
      (customizationData.mappings.length === 1 &&
        (customizationData.mappings[0].context_variable_id === '' ||
          customizationData.mappings[0].value === ''))
    ) {
      return true;
    }

    if (
      customizationDataWithoutEmptyRecords.mappings.length !==
      uniq(
        customizationDataWithoutEmptyRecords.mappings.map(
          ({ context_variable_id }) => context_variable_id,
        ),
      ).length
    ) {
      return true;
    }

    return customizationData.mappings.some(mapping => {
      const contextVariable = contextVariables.find(
        cv => cv.context_variable_id === mapping.context_variable_id,
      );

      if (!contextVariable) {
        return false;
      }

      return !isContextVariableValueValid({
        type:
          getContextVariableType(
            mapping.context_variable_id,
            contextVariables,
          ) || 'SHORT_TEXT',
        value: mapping.value,
      });
    });
  };

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (setContextVariableCustomizationSettings) {
      dispatch(
        setActionCustomization({
          mappings: setContextVariableCustomizationSettings.mappings,
        }),
      );
    }
  }, [setContextVariableCustomizationSettings, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setActionCustomization(null));
    };
  }, [dispatch]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <ContentWrapper useFullHeight={true}>
        <Title>Set Context Variable value</Title>
        <Spacer height='20px' />
        <Typography color={theme.palette.colors.grey[700]} variant='font14'>
          Assign specific data or information to a variable within the workflow.
          Set the context variable values to gather or receive information from
          customers in order to provide a tailored response to their inquiries
          or actions.
        </Typography>
        <Spacer height='20px' />
        <Box mb={1}>
          <Typography variant='font14Bold'>Value field</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={1}>
          {customizationData.mappings.map(
            ({ context_variable_id, value }, index) => (
              <Mapping
                contextVariableId={context_variable_id}
                deleteField={indexToDelete => {
                  dispatch(
                    setActionCustomization({
                      mappings: pullAt(
                        indexToDelete,
                        customizationData.mappings,
                      ),
                    }),
                  );
                }}
                error={
                  context_variable_id !== '' &&
                  index !==
                    customizationData.mappings.findIndex(
                      mapping =>
                        mapping.context_variable_id === context_variable_id,
                    )
                    ? 'Duplicate Context Variable'
                    : ''
                }
                index={index}
                key={index}
                setContextVariable={newValue => {
                  dispatch(
                    setActionCustomization({
                      mappings: set(
                        [index],
                        {
                          context_variable_id: newValue,
                          value: '',
                        },
                        customizationData.mappings,
                      ),
                    }),
                  );
                }}
                setContextVariableValue={newValue => {
                  dispatch(
                    setActionCustomization({
                      mappings: set(
                        [index],
                        {
                          ...customizationData.mappings[index],
                          value: newValue,
                        },
                        customizationData.mappings,
                      ),
                    }),
                  );
                }}
                value={value}
              />
            ),
          )}
        </Box>
        <Spacer height='20px' />
        <Box mt={1}>
          <Button
            onClick={() => {
              dispatch(
                setActionCustomization({
                  mappings: [
                    ...customizationData.mappings,
                    {
                      context_variable_id: '',
                      value: '',
                    },
                  ],
                }),
              );
            }}
            startIcon={<IconPlus size={20} />}
            variant='ghost'
          >
            Add form field
          </Button>
        </Box>
      </ContentWrapper>
      <SaveContainer hasTopBorder>
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isFormDisabled() || hasWorkflowConflict}
          onClick={() => {
            dispatch(
              addingAction
                ? addAction({
                    ...addingAction,
                    data: {
                      ...addingAction.data,
                      configuration_fields:
                        customizationDataWithoutEmptyRecords,
                    },
                  })
                : updateActionSettings({
                    action_type: actionSettings.action_type as string,
                    configuration_fields: customizationDataWithoutEmptyRecords,
                  }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.SET_CONTEXT_VARIABLE,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </SaveContainer>
    </>
  );
};

interface MappingProps {
  contextVariableId: string;
  deleteField: (index: number) => void;
  error?: string;
  index: number;
  setContextVariable: (value: string) => void;
  setContextVariableValue: (value: ContextVariableValueType) => void;
  value: ContextVariableValueType;
}

const Mapping = ({
  contextVariableId,
  deleteField,
  error,
  index,
  setContextVariable,
  setContextVariableValue,
  value,
}: MappingProps) => {
  const { palette } = useTheme();

  const { contextVariables } = useGetContextVariables();

  const contextVariableType =
    getContextVariableType(contextVariableId, contextVariables) || 'SHORT_TEXT';

  const contextVariable = contextVariables.find(
    cv => cv.context_variable_id === contextVariableId,
  );

  return (
    <Box
      alignItems='flex-start'
      display='flex'
      gap={1}
      sx={{
        backgroundColor: palette.common.white,
      }}
    >
      <Box alignItems='center' display='flex' height='40px'>
        <Typography variant='font14Bold'>Set</Typography>
      </Box>
      <Box flex={1}>
        <ContextVariableSelectDropdown
          aria-label={`Context Variable ${index + 1}`}
          error={error}
          onChange={setContextVariable}
          shouldIncludeDynamicListContextVariables={false}
          shouldIncludeWorkflowTagContextVariable={false}
          value={contextVariableId}
        />
      </Box>
      <Box alignItems='center' display='flex' height='40px'>
        <Typography variant='font14Bold'>To</Typography>
      </Box>
      <Box flex={1}>
        {contextVariable ? (
          <ContextVariableValue
            contextVariable={contextVariable}
            label={`Value for Context Variable ${index + 1}`}
            setValue={setContextVariableValue}
            type={contextVariableType}
            value={value}
          />
        ) : (
          <TextField
            aria-label={`Value for Context Variable ${index + 1}`}
            disabled
            value=''
          />
        )}
      </Box>
      <Box alignItems='center' display='flex' height='40px'>
        <IconButton
          aria-label={`Delete Context Variable ${index + 1}`}
          onClick={() => deleteField(index)}
          variant='ghost'
        >
          <IconTrash size={20} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SetContextVariableCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ContentWrapper = styled('div')<{ useFullHeight: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 0 50px 0;
`;

const Title = styled('h1')`
  ${textMediumStyle()}
  margin: 0 10px;
`;
