import { sendAuthRequestWithErrorHandling } from '../api';
import {
  AssistNote,
  AssistNotes,
  PostAssistNoteRequest,
  ShareNoteRequest,
} from 'src/slices/assist-notes/types';

export const getNotes = async (): Promise<AssistNotes> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/assist-note`,
    null,
    'GET',
  );
};

export const createNote = async (
  body: PostAssistNoteRequest,
): Promise<AssistNote> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/assist-note`,
    body,
    'POST',
  );
};

export const deleteNote = async (noteId: string): Promise<void> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/assist-note/${noteId}`,
    null,
    'DELETE',
  );
};

export const patchNote = async (body: AssistNote): Promise<AssistNote> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/assist-note/${body.note_id}`,
    body,
    'PATCH',
  );
};

export const patchNoteTag = async (
  noteId: string,
  body: {
    tags: string[];
  },
): Promise<AssistNote> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/assist-note/tag/${noteId}`,
    body,
    'PATCH',
  );
};

export const putShareNote = async (
  body: ShareNoteRequest,
): Promise<AssistNote> => {
  return await sendAuthRequestWithErrorHandling(
    `${API_URL}ssel/assist-note/share`,
    body,
    'PUT',
  );
};
