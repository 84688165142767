import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

interface PaletteTooltipProps {
  text: string;
  values: {
    color: string;
    label: string;
  }[];
}

export function PaletteTooltip({ text, values }: PaletteTooltipProps) {
  const { palette } = useTheme();

  return (
    <Box>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: 2, mb: 1 }}>
        {values.map(({ color, label }, idx) => (
          <Box key={idx} sx={{ alignItems: 'center', display: 'flex' }}>
            <Box
              sx={{
                backgroundColor: color,
                border: '1px solid',
                borderColor: palette.colors.grey[100],
                borderRadius: '2px',
                height: '10px',
                marginRight: '4px',
                width: '10px',
              }}
            />
            <Typography color={palette.colors.white} noWrap variant='font12'>
              {label}
            </Typography>
          </Box>
        ))}
      </Box>
      <Typography color={palette.colors.white} variant='font14'>
        {text}
      </Typography>
    </Box>
  );
}
