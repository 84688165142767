import React from 'react';
import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function TabBody({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <Container>
      <Box marginBottom='24px'>
        <Typography variant='font24'>{title}</Typography>
      </Box>
      {children}
    </Container>
  );
}

const Container = styled('div')`
  background-color: ${props => props.theme.palette.colors.white};
  border-right: 1px solid ${({ theme }) => theme.palette.colors.slate[100]};
  padding: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 400px;
`;
