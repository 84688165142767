import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  DiscoverMetricDataType,
  DiscoverTopic,
  DiscoverTopicAggregatedMetricType,
} from 'src/reducers/discoverReducer/types';
import {
  getDisplayableDataByMetricType,
  getDisplayableValueChanged,
  getNumberIndicator,
  getPercentChangeColor,
  getPercentChangeFontVariant,
} from 'src/utils/discover/helpers';

type MetricData = Partial<
  Record<
    | DiscoverTopicAggregatedMetricType
    | `${DiscoverTopicAggregatedMetricType}valueChanged`,
    number | null
  >
>;

export interface BookmarkTableInput {
  id: string;
  metricData: MetricData;
  name: string;
  topic: DiscoverTopic | null;
}

export const cellRenderer =
  (
    shouldShowPercentChange: boolean,
    id: DiscoverTopicAggregatedMetricType,
    dataType: DiscoverMetricDataType,
  ) =>
  // eslint-disable-next-line react/display-name
  ({ metricData }: BookmarkTableInput) => {
    const value = getDisplayableDataByMetricType({
      dataType,
      forTable: true,
      metric: id,
      value: metricData[id] ?? null,
    });
    const valueChanged = metricData[`${id}valueChanged`] ?? 0;
    const valueToRender =
      value === 'N/A' ? (
        <Typography color={theme.palette.grey[800]} variant='font14'>
          <i>{value}</i>
        </Typography>
      ) : (
        value
      );

    return (
      <>
        {valueToRender}{' '}
        {shouldShowPercentChange && (
          <Typography
            color={getPercentChangeColor(valueChanged, id)}
            variant={getPercentChangeFontVariant(valueChanged)}
          >
            {`(${getNumberIndicator(valueChanged)}${getDisplayableValueChanged(
              valueChanged,
              true,
            )})`}
          </Typography>
        )}
      </>
    );
  };
