import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ForethoughtEditStepMenu } from './edit-menu/ForethoughtEditStepMenu';
import { AttachmentAnalysisUploadStep as UploadStep } from './workflow-builder/revamped';
import { CsatTriggerPointStepProps } from 'src/pages/workflow-builder-edit/types/canvasComponentTypes';
import { selectUndefinedContextVariablesInStep } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { AttachmentAnalysisUploadStepFields } from 'src/slices/canvas-workflow-builder/types/dataTypes';

const AttachmentAnalysisUploadStep: React.FC<
  React.PropsWithChildren<CsatTriggerPointStepProps>
> = ({ setShouldShowEditMenu, shouldShowEditMenu, step, stepId }) => {
  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );
  const undefinedContextVariableIds = useMemo(() => {
    return undefinedContextVariablesInStep[stepId] || [];
  }, [stepId, undefinedContextVariablesInStep]);

  return (
    <>
      <UploadStep
        fields={step.step_fields as AttachmentAnalysisUploadStepFields}
        stepId={stepId}
        undefinedContextVariableIds={undefinedContextVariableIds}
      />
      <ForethoughtEditStepMenu
        isVisible={shouldShowEditMenu}
        setIsVisible={setShouldShowEditMenu}
        stepId={stepId}
        stepType={step.step_type}
      />
    </>
  );
};

export default AttachmentAnalysisUploadStep;
