import { useState } from 'react';

import { TriageTestPredictionResult } from 'src/reducers/triageSettingsReducer/types';
import { getTriageModelDemoPrediction } from 'src/services/api';

export const useGetPredictions = ({ modelName }: { modelName: string }) => {
  const [predictions, setPredictions] = useState<
    TriageTestPredictionResult[] | null
  >(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getPredictions = async (text: string) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const res = await getTriageModelDemoPrediction(modelName, { text });
      setPredictions(res.predictions);
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return { getPredictions, isError, isLoading, predictions };
};
