import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, useTheme } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { INSIGHT_TOOLTIP_COPY } from '../solve-insights/constants';
import { numbersToStringWithCommas } from '../solve-insights/helpers';
import { NA, TEMP_BLUE } from 'src/constants/solve';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { InsightWorkflowDetail } from 'src/services/insights/types';

interface Props {
  data?: InsightWorkflowDetail;
  isLoading: boolean;
}

export const WorkflowDetailHeader: React.FC<React.PropsWithChildren<Props>> = ({
  data,
  isLoading,
}) => {
  const { palette } = useTheme();
  const getCSATColor = useGetCSATColor();
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isQuickFeedbackAnalyticsEnabled = featureFlags.includes(
    'show_quick_feedback_analytics',
  );
  const shouldShowQuickFeedbackAnalytics =
    isQuickFeedbackEnabled && isQuickFeedbackAnalyticsEnabled;

  // Deflection
  const deflectedRate = data ? data.deflected_count / data.chats : 0;

  // CSAT
  const csatScore = data?.avg_csat === 0 ? NA : formatCSAT(data?.avg_csat);

  // Relevance
  const irrelevant_count = data?.relevance_breakdown.irrelevant_count ?? 0;
  const relevant_count = data?.relevance_breakdown.relevant_count ?? 0;
  const somewhat_relevant_count =
    data?.relevance_breakdown.somewhat_relevant_count ?? 0;
  const totalRelevanceCount =
    irrelevant_count + relevant_count + somewhat_relevant_count;
  const relevanceDataIsEmpty = totalRelevanceCount === 0;

  // Engagement
  const dropoffCount = data?.dropoff_breakdown.dropoff_count ?? 0;
  const noDropoffCount = data?.dropoff_breakdown.no_dropoff_count ?? 0;
  const totalEngagementCount = dropoffCount + noDropoffCount;
  const engagementDataIsEmpty = totalEngagementCount === 0;

  return (
    <Box display='flex' flexDirection='column' gap={3} width='100%'>
      <Box
        display='flex'
        flexWrap='wrap'
        gap='16px'
        justifyContent='space-between'
      >
        {/* chats */}
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography color={palette.colors.grey[800]} variant='font14Bold'>
            Total chats
          </Typography>
          <LoadingContainerWrapper isLoading={isLoading}>
            <Typography variant='font24'>
              {numbersToStringWithCommas({
                number: data?.chats,
              })}
            </Typography>
          </LoadingContainerWrapper>
        </Box>
        {/* deflections */}
        <Box display='flex' flexDirection='column' gap={1}>
          <Typography color={palette.colors.grey[800]} variant='font14Bold'>
            Deflections
          </Typography>
          <LoadingContainerWrapper isLoading={isLoading}>
            <Typography variant='font24'>
              {`${numbersToStringWithCommas({
                number: data?.deflected_count,
              })} (${numbersToStringWithCommas({
                number: deflectedRate,
                style: 'percent',
              })})`}
            </Typography>
          </LoadingContainerWrapper>
        </Box>
        {/* articles */}
        <Box display='flex' flexDirection='column' gap={1}>
          <Box alignItems='center' display='flex' gap='4px'>
            <Typography color={palette.colors.grey[800]} variant='font14Bold'>
              Articles surfaced
            </Typography>
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.article_surfaced}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
          <LoadingContainerWrapper isLoading={isLoading}>
            <Typography variant='font24'>
              {`${numbersToStringWithCommas({
                number: data?.surfaced_articles_count,
              })}`}
            </Typography>
          </LoadingContainerWrapper>
        </Box>
        {/* csat */}
        <Box display='flex' flexDirection='column' gap={1}>
          <Box alignItems='center' display='flex' gap='4px'>
            <Typography color={palette.colors.grey[800]} variant='font14Bold'>
              Avg. CSAT
            </Typography>
            <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.articles_avg_csat}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
          <LoadingContainerWrapper isLoading={isLoading}>
            <Typography
              color={
                csatScore === NA
                  ? palette.colors.grey[600]
                  : getCSATColor(Number(csatScore))
              }
              variant='font24'
            >
              {csatScore}
            </Typography>
          </LoadingContainerWrapper>
        </Box>
        {/* relevance */}
        {isDeflectionInsightsEnabled && (
          <Box display='flex' flexDirection='column' gap={1}>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Relevance
              </Typography>
              <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.relevance}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
            <LoadingContainerWrapper isLoading={isLoading}>
              <Box alignItems='center' display='flex' gap={3}>
                <Box display='flex' flexDirection='column'>
                  <Typography
                    color={
                      relevanceDataIsEmpty
                        ? palette.colors.grey[500]
                        : TEMP_BLUE
                    }
                    variant='font24'
                  >
                    {relevanceDataIsEmpty
                      ? NA
                      : numbersToStringWithCommas({
                          number: relevant_count / totalRelevanceCount,
                          style: 'percent',
                        })}
                  </Typography>
                  <Typography variant='font12'>relevant</Typography>
                </Box>
                <Box display='flex' flexDirection='column'>
                  <Typography
                    color={
                      relevanceDataIsEmpty
                        ? palette.colors.grey[500]
                        : palette.colors.blue[400]
                    }
                    variant='font24'
                  >
                    {relevanceDataIsEmpty
                      ? NA
                      : numbersToStringWithCommas({
                          number: somewhat_relevant_count / totalRelevanceCount,
                          style: 'percent',
                        })}
                  </Typography>
                  <Typography variant='font12'>somewhat relevant</Typography>
                </Box>
                <Box display='flex' flexDirection='column'>
                  <Typography
                    color={
                      relevanceDataIsEmpty
                        ? palette.colors.grey[500]
                        : palette.colors.red[500]
                    }
                    variant='font24'
                  >
                    {relevanceDataIsEmpty
                      ? NA
                      : numbersToStringWithCommas({
                          number: irrelevant_count / totalRelevanceCount,
                          style: 'percent',
                        })}
                  </Typography>
                  <Typography variant='font12'>irrelevant</Typography>
                </Box>
              </Box>
            </LoadingContainerWrapper>
          </Box>
        )}
        {/* engagement */}
        {isDeflectionInsightsEnabled && (
          <Box display='flex' flexDirection='column' gap={1}>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Engagement
              </Typography>
              <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.engagement}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
            <LoadingContainerWrapper isLoading={isLoading}>
              <Box display='flex' flexDirection='column'>
                <Typography
                  color={
                    engagementDataIsEmpty ? palette.colors.grey[500] : TEMP_BLUE
                  }
                  variant='font24'
                >
                  {engagementDataIsEmpty
                    ? NA
                    : `${numbersToStringWithCommas({
                        number: noDropoffCount,
                      })} (${numbersToStringWithCommas({
                        number: noDropoffCount / totalEngagementCount,
                        style: 'percent',
                      })})`}
                </Typography>
              </Box>
            </LoadingContainerWrapper>
          </Box>
        )}
        {/* quick feedback */}
        {shouldShowQuickFeedbackAnalytics && (
          <Box display='flex' flexDirection='column' gap={1}>
            <Box alignItems='center' display='flex' gap='4px'>
              <Typography color={palette.colors.grey[800]} variant='font14Bold'>
                Quick feedback
              </Typography>
              <Tooltip tooltipContent={INSIGHT_TOOLTIP_COPY.chats_feedback}>
                <IconInfoCircle color={palette.colors.grey[700]} size={20} />
              </Tooltip>
            </Box>
            <LoadingContainerWrapper isLoading={isLoading}>
              <Box display='flex' gap={3}>
                <Box display='flex' flexDirection='column' gap='4px'>
                  <Typography
                    color={palette.colors.green[500]}
                    variant='font24'
                  >
                    {numbersToStringWithCommas({
                      number: data?.feedback_breakdown.positive,
                    })}
                  </Typography>
                  <Typography variant='font12'>positive</Typography>
                </Box>
                <Box display='flex' flexDirection='column' gap='4px'>
                  <Typography color={palette.colors.red[500]} variant='font24'>
                    {numbersToStringWithCommas({
                      number: data?.feedback_breakdown.negative,
                    })}
                  </Typography>
                  <Typography variant='font12'>negative</Typography>
                </Box>
                <Box display='flex' flexDirection='column' gap='4px'>
                  <Typography color={palette.colors.black} variant='font24'>
                    {numbersToStringWithCommas({
                      number: data?.feedback_breakdown.unanswered,
                    })}
                  </Typography>
                  <Typography variant='font12'>not answered</Typography>
                </Box>
              </Box>
            </LoadingContainerWrapper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const LoadingContainerWrapper = ({
  children,
  isLoading,
}: {
  children: JSX.Element;
  isLoading: boolean;
}): JSX.Element => {
  if (isLoading) {
    return <Skeleton height='30px' width='100%' />;
  }
  return children;
};
