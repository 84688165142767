import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { Label, Spacer, StyledInput } from '../handoff-configuration/styles';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import xIcon from 'src/assets/images/icon-close-bttn.svg';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { selectActionSettingsConfigurationFields } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { selectAddingAction } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import {
  addAction,
  updateActionSettings,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import { ContextVariableMappingActionSettings } from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface Props {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

export const ContextVariableMappingCustomizationPanel: React.FC<
  React.PropsWithChildren<Props>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const dispatch = useDispatch();
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();
  const addingAction = useSelector(selectAddingAction);
  const actionSettingsConfigurationFields = useSelector(
    selectActionSettingsConfigurationFields,
  ) as ContextVariableMappingActionSettings;
  const initialCustomizationData: ContextVariableMappingActionSettings =
    addingAction
      ? {
          input_context_variable: '',
          output_context_variable: '',
          value_map: [{ input_value: '', output_value: '' }],
        }
      : actionSettingsConfigurationFields;

  const [customizationData, setCustomizationData] =
    useState<ContextVariableMappingActionSettings>(initialCustomizationData);
  const isPristine = addingAction
    ? false
    : isEqual(customizationData, initialCustomizationData);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (actionSettingsConfigurationFields) {
      setCustomizationData({ ...actionSettingsConfigurationFields });
    }
  }, [actionSettingsConfigurationFields]);

  if (!customizationData) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 6.25 }}>
        <Title>Context Variable Mapping</Title>
        <Spacer height='20px' />
        <Section>
          <Label>Input Context Variable</Label>
          <ContextVariableSelectDropdown
            aria-label='Input Context Variable'
            onChange={e => {
              setCustomizationData({
                ...customizationData,
                input_context_variable: e,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.input_context_variable}
          />
        </Section>
        <Section>
          <Label>Output Context Variable</Label>
          <ContextVariableSelectDropdown
            aria-label='Output Context Variable'
            onChange={e => {
              setCustomizationData({
                ...customizationData,
                output_context_variable: e,
              });
            }}
            shouldIncludeSystemContextVariables
            value={customizationData.output_context_variable}
          />
        </Section>
        <Section>
          <Label>Value Mapping</Label>
          {customizationData.value_map.map((mapping, idx) => {
            return (
              <Box
                key={`value-map-${idx}`}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  justifyContent: 'space-between',
                  marginTop: '8px',
                }}
              >
                <StyledInput
                  labelText='From'
                  onChange={e => {
                    setCustomizationData(data => {
                      const newValueMap = [...data.value_map];
                      newValueMap[idx] = {
                        ...newValueMap[idx],
                        input_value: e.target.value,
                      };

                      return {
                        ...data,
                        value_map: newValueMap,
                      };
                    });
                  }}
                  placeholder='Enter value'
                  type='text'
                  value={mapping.input_value}
                  width='inherit'
                />
                <StyledInput
                  labelText='To'
                  onChange={e => {
                    setCustomizationData(data => {
                      const newValueMap = [...data.value_map];
                      newValueMap[idx] = {
                        ...newValueMap[idx],
                        output_value: e.target.value,
                      };

                      return {
                        ...data,
                        value_map: newValueMap,
                      };
                    });
                  }}
                  placeholder='Enter value'
                  type='text'
                  value={mapping.output_value}
                  width='inherit'
                />
                <DeleteButton
                  onClick={() => {
                    setCustomizationData(data => {
                      const newValueMap = [...data.value_map];
                      newValueMap.splice(idx, 1);

                      return {
                        ...data,
                        value_map: newValueMap,
                      };
                    });
                  }}
                  role='button'
                  src={xIcon}
                />
              </Box>
            );
          })}
          <Box display='block' sx={{ marginTop: '16px' }}>
            <Add
              onClick={() => {
                setCustomizationData(data => {
                  return {
                    ...data,
                    value_map: [
                      ...data.value_map,
                      { input_value: '', output_value: '' },
                    ],
                  };
                });
              }}
            >
              + Add Mapping
            </Add>
          </Box>
        </Section>
      </Box>
      {/* Save and Cancel Buttons */}
      <Box
        alignItems='center'
        display='flex'
        justifyContent='flex-end'
        py={1.25}
        sx={{
          borderBottom: '8px',
          borderTop: `1px solid ${theme.palette.colors.slate[200]}`,
          height: '40px',
        }}
      >
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={
            isPristine ||
            hasWorkflowConflict ||
            !customizationData.input_context_variable ||
            !customizationData.output_context_variable
          }
          onClick={() => {
            if (addingAction) {
              dispatch(
                addAction({
                  ...addingAction,
                  data: {
                    ...addingAction.data,
                    configuration_fields: { ...customizationData },
                  },
                }),
              );
            } else {
              dispatch(
                updateActionSettings({
                  action_type:
                    ActionBuilderActionTypes.CONTEXT_VARIABLE_MAPPING,
                  configuration_fields: { ...customizationData },
                }),
              );
            }

            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.CONTEXT_VARIABLE_MAPPING,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </Box>
    </>
  );
};

const Title = styled('h1')`
  font-size: var(--font-size-m);
  text-align: left;
  color: var(--color-text-primary);
  font-weight: var(--font-weight-medium);
  cursor: default;
  margin: 0 10px;
`;

const Section = styled('section')`
  border-top: 1px solid ${theme.palette.colors.grey[100]};
  border-left: none;
  border-right: none;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled('img')`
  cursor: pointer;
  height: 20px;
`;

const Add = styled('a')`
  color: ${theme.palette.colors.purple[500]};
  cursor: pointer;
`;
