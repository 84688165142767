import { Tabs } from '@forethought-technologies/forethought-elements';
import useSelfServeEvents from 'src/hooks/triage/useSelfServeEvents';
import { TAB_TO_EVENT_MAP, TRIAGE_MODEL_TABS } from 'src/utils/constants';

const TriageModelTabs = ({
  modelId,
  onChange,
  tabIndex,
}: {
  modelId: string;
  onChange: (index: number) => void;
  tabIndex: number;
}) => {
  const emitTrackingEventCallback = useSelfServeEvents({
    modelId: modelId,
  });

  return (
    <Tabs
      a11yProps={index => {
        return {
          'aria-controls': `triagemodel-tabpanel-${index}`,
          id: `triagemodel-tab-${index}`,
        };
      }}
      aria-label='triage model tabs'
      onChange={(_, value) => {
        onChange(value);
        const tabTrackingEvent = TAB_TO_EVENT_MAP[TRIAGE_MODEL_TABS[value]];
        emitTrackingEventCallback(tabTrackingEvent);
      }}
      tabs={TRIAGE_MODEL_TABS}
      typographyVariant='font16Bold'
      value={tabIndex}
    />
  );
};

export default TriageModelTabs;
