import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { FormWithErrors } from '../types';
import { selectEditingStepFields } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { CsatTriggerPointStepFields } from 'src/types/workflowBuilderAPITypes';
import { Routes } from 'src/utils/enums';
import { isCsatTriggerPointStepFields } from 'src/utils/solve/stepUtils';

const CsatTriggerPointForm =
  ({}: FormWithErrors<CsatTriggerPointStepFields>) => {
    const theme = useTheme();
    const fields = useSelector(selectEditingStepFields);

    if (!isCsatTriggerPointStepFields(fields)) {
      return null;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <Typography color={theme.palette.colors.grey[700]} variant='font14'>
          Customize your CSAT survey within{' '}
          <Link target='_blank' to={Routes.SOLVE_CONFIGURATION}>
            Widget Configuration.
          </Link>
        </Typography>
        <Typography color={theme.palette.colors.grey[700]} variant='font14'>
          The CSAT survey trigger point controls when the survey appears in the
          widget&apos;s workflow. It can trigger CSAT tailored to specific
          workflows alone or in combination with other CSAT methods. Once the
          user responds or dismisses the survey at the initial trigger point, it
          won&apos;t actively prompt again, even with multiple trigger points.
          Users can edit their response during the chat, but only one response
          per conversation is allowed.
        </Typography>
      </Box>
    );
  };

export default CsatTriggerPointForm;
