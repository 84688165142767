import { createAsyncThunk } from '@reduxjs/toolkit';

import { selectUser } from 'src/reducers/userReducer/userReducer';
import * as API from 'src/services/assist/api';
import {
  AssistNote,
  AssistNotes,
  PostAssistNoteRequest,
  ShareNoteRequest,
} from 'src/slices/assist-notes/types';
import type { RootState } from 'src/store/rootReducer';

export const getNotes = createAsyncThunk<AssistNotes, void>(
  'getAssistNotes',
  async () => {
    return await API.getNotes();
  },
);

export const createNote = createAsyncThunk<AssistNote, PostAssistNoteRequest>(
  'createAssistNote',
  async body => {
    return await API.createNote(body);
  },
);

export const deleteNote = createAsyncThunk<string, string>(
  'deleteAssistNote',
  async noteId => {
    await API.deleteNote(noteId);

    return noteId;
  },
);

export const patchNote = createAsyncThunk<AssistNote, AssistNote>(
  'patchAssistNote',
  async body => {
    return await API.patchNote(body);
  },
);

export const patchNoteTag = createAsyncThunk<
  AssistNote,
  { body: { tags: string[] }; noteId: string }
>('patchAssistTagNote', async ({ body, noteId }) => {
  return await API.patchNoteTag(noteId, body);
});

export const putShareNote = createAsyncThunk<
  AssistNote & { isMe: boolean },
  ShareNoteRequest
>('putShareAssistNote', async (body, { getState }) => {
  const userEmail = selectUser(getState() as RootState)?.user?.email || '';
  const note = await API.putShareNote(body);
  const isMe = userEmail === note.agent_email;

  return { ...note, isMe };
});
