import { IntentTestResponse } from 'src/services/intent-tests/types';
import { convertToMMDDYYYY } from 'src/utils/dateUtils';

export const formatTestConfig = (intentTest: IntentTestResponse) => {
  const {
    conversation_ids,
    end_datetime,
    labelled_inputs,
    start_datetime,
    ticket_ids,
  } = intentTest;

  if (conversation_ids?.length) {
    return `Conversation IDs: ${conversation_ids.join(', ')}`;
  }
  if (ticket_ids.length) {
    return `Ticket IDs: ${ticket_ids.join(', ')}`;
  }
  if (labelled_inputs.length) {
    return `Query IDs: ${labelled_inputs
      .map(input => input.query_id)
      .join(', ')}`;
  }

  return (
    convertToMMDDYYYY(new Date(start_datetime)) +
    ' - ' +
    convertToMMDDYYYY(new Date(end_datetime))
  );
};

export function csvStringToArray(ids: string) {
  return ids
    .split(',')
    .filter(Boolean)
    .map(id => id.trim());
}
