import React, { ComponentProps, FC } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { Box, useTheme } from '@mui/material';
import { IconCopy, IconPlus } from '@tabler/icons-react';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { type RichTextEditorProps } from '../rich-text-editor/types';
import { useFlamethrowerTrackingEventAction } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { SESSION_COPY_PASTE_STORAGE_KEY } from 'src/pages/workflow-builder-edit/constants';
import { useGetBuilderQueryParams } from 'src/pages/workflow-builder-edit/hooks';
import {
  findAllUnusableCvsInStepGraph,
  handlePasteGroupOfSteps,
  hasDynamicArticleSuggestion,
} from 'src/pages/workflow-builder-edit/utils';
import {
  selectCanvasWorkflowBuilder,
  selectChatOrder,
  selectChatOrderLastStepId,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  setIsSqueezingStep,
  setShouldSqueezeIntoEntry,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { insertGroupOfSteps } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { getActualParentStepId } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks.helpers';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  FlamethrowerTrackingApplications,
  FlamethrowerTrackingEventTypes,
} from 'src/utils/enums';

interface Props {
  editorRef: RichTextEditorProps['editorRef'];
  isDragAboveMiddleOfStepElement?: boolean;
  isEntryStep?: boolean;
  isPasteButton?: boolean;
  isSqueezeButton?: boolean;
  isStepOver?: boolean;
}

type Variant = 'paste' | 'squeeze' | 'dragStep';

export const SqueezeStepHoverState: FC<React.PropsWithChildren<Props>> = ({
  editorRef,
  isDragAboveMiddleOfStepElement = false,
  isEntryStep = false,
  isPasteButton,
  isSqueezeButton,
  isStepOver,
}) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const dispatchTrackingAction = useFlamethrowerTrackingEventAction(
    FlamethrowerTrackingApplications.WORKFLOW_BUILDER_CANVAS,
  );
  const { contextVariables } = useGetContextVariables();

  const { workflowId: selectedWorkflowId } = useGetBuilderQueryParams();
  const canvasData = useSelector(selectCanvasWorkflowBuilder);
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const actualLastStepId = getActualParentStepId(lastStepId);

  const copyStringValue = sessionStorage.getItem(
    SESSION_COPY_PASTE_STORAGE_KEY,
  );

  const chatOrder = useSelector(selectChatOrder);

  const handleButtonText = (type: Variant) => {
    if (type === 'paste') {
      return 'Paste step';
    }

    if (type === 'squeeze') {
      return 'Create new step';
    }

    if (type === 'dragStep') {
      return 'Drop it here';
    }

    return '';
  };

  const handleOnClick = (type: Variant) => {
    if (type === 'paste') {
      handlePasteOnClick();
    }

    if (type === 'squeeze') {
      handleSqueezeOnClick();
    }
  };

  const handleIconRender = (type: Variant) => {
    if (type === 'paste') {
      return (
        <IconCopy
          color={palette.colors.grey[700]}
          size={20}
          style={{
            stroke: palette.colors.white,
            transform: 'scaleX(-1)',
          }}
        />
      );
    }

    if (type === 'squeeze' || type == 'dragStep') {
      return (
        <IconPlus
          color={palette.colors.grey[700]}
          size={20}
          style={{
            stroke: palette.colors.white,
            transform: 'scaleX(-1)',
          }}
        />
      );
    }
  };

  const handleSqueezeOnClick = () => {
    dispatch(setIsSqueezingStep(true));
    dispatch(setShouldSqueezeIntoEntry(isEntryStep));
    editorRef.current?.focus('end');
  };

  const handlePasteOnClick = async () => {
    const insertGroupPayload = handlePasteGroupOfSteps(
      canvasData,
      lastStepId,
      actualLastStepId,
    );
    if (insertGroupPayload) {
      const intentTitle = insertGroupPayload.intentTitle ?? '';

      if (
        hasDynamicArticleSuggestion() &&
        canvasData.intent_id !== 'Knowledge_Article'
      ) {
        dispatch(
          setGlobalToastOptions({
            title: `Error with pasting "${intentTitle}" steps. "Dynamic Article Suggestion" step can be pasted only to "Knowledge Retrieval" workflow.`,
            variant: 'danger',
          }),
        );

        return;
      }

      // Check if all cvs are valid (valid meaning exist in configurations)
      const stepGraph = insertGroupPayload.step_map;
      const unusableCvs = findAllUnusableCvsInStepGraph(
        stepGraph,
        insertGroupPayload.entry_step_id,
        contextVariables,
      );
      if (unusableCvs.length > 0) {
        dispatch(
          setGlobalToastOptions({
            title: `Error with pasting "${intentTitle}" steps. CV not configured in copied steps.`,
            variant: 'danger',
          }),
        );
        sessionStorage.removeItem(SESSION_COPY_PASTE_STORAGE_KEY);
      } else {
        // Make the call
        await dispatch(insertGroupOfSteps(insertGroupPayload))
          .unwrap()
          .then(() => {
            dispatch(
              setGlobalToastOptions({
                title: `Successfully pasted "${intentTitle}" steps.`,
                variant: 'main',
              }),
            );
            dispatchTrackingAction(
              FlamethrowerTrackingEventTypes.COPY_WORKFLOW_STEPS,
              {
                intent_id: selectedWorkflowId,
                intent_name: intentTitle,
                num_of_steps_pasted: Object.keys(insertGroupPayload.step_map)
                  .length,
                org_id: canvasData.org_id,
              },
            );
          })
          .catch(error => {
            dispatch(
              setGlobalToastOptions({
                title: `Error with pasting "${intentTitle}" steps. ${error.message}`,
                variant: 'danger',
              }),
            );
            sessionStorage.removeItem(SESSION_COPY_PASTE_STORAGE_KEY);
          });
      }
    }

    editorRef.current?.focus('end');
  };

  const renderButton = (type: Variant) => {
    return (
      <Line
        shouldPositionTop={
          (isDragAboveMiddleOfStepElement && type === 'dragStep') ||
          (chatOrder.length === 0 && type !== 'dragStep')
        }
      >
        <Box
          onClick={() => handleOnClick(type)}
          sx={{
            backgroundColor: palette.colors.purple[500],
            borderRadius: '16px',
            cursor: 'pointer',
            height: '32px',
            transform: 'translateY(-46%)',
          }}
        >
          <Box display='flex' padding='6px 12px'>
            {handleIconRender(type)}
            <Typography
              color={palette.colors.white}
              noWrap
              variant='font14Bold'
            >
              {handleButtonText(type)}
            </Typography>
          </Box>
        </Box>
      </Line>
    );
  };

  const renderSqueezeButton = () => {
    return renderButton('squeeze');
  };

  return (
    <>
      {isPasteButton && Boolean(copyStringValue) && renderButton('paste')}
      {isSqueezeButton && renderSqueezeButton()}
      {isStepOver && renderButton('dragStep')}
    </>
  );
};

export const Line = (props: ComponentProps<typeof StyledLine>) => (
  <StyledLine
    {...props}
    className={`styled-line ${props.className}`}
  ></StyledLine>
);

const StyledLine = styled('div')<{ shouldPositionTop?: boolean }>`
  background-color: ${theme.palette.colors.purple[500]};
  height: 1px;
  display: flex;
  justify-content: center;
  z-index: 4;
  position: absolute;
  width: 100%;
  left: 0;
  ${props => (props.shouldPositionTop ? 'top: 0' : 'bottom: 0')};
`;
