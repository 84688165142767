import React, { FC } from 'react';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import { FieldAndValue } from 'src/types/workflowBuilderAPITypes';

interface AddFieldLinkProps {
  fieldArr: Array<{
    field_options: Array<{ name: string; value: string }>;
    id: string;
    title: string;
    type: string;
  }>;
  fieldName: string;
  idValueArr: Array<FieldAndValue>;
  setFieldValue: (name: string, value: Array<FieldAndValue>) => void;
}
const AddFieldLink: FC<React.PropsWithChildren<AddFieldLinkProps>> = ({
  fieldArr,
  fieldName,
  idValueArr,
  setFieldValue,
}) => {
  // idValueArr tracks # of UI fields.
  // fieldArr tracks # of available options.
  const hasReachedTotalCustomFields = idValueArr.length === fieldArr.length;
  return (
    <LinkOut
      isDisabled={hasReachedTotalCustomFields}
      onClick={() => {
        if (!hasReachedTotalCustomFields) {
          const updatedFieldValues = idValueArr.concat({
            id: '',
            value: '',
          });
          setFieldValue(fieldName, updatedFieldValues);
        }
      }}
    >
      + Add field
    </LinkOut>
  );
};

export default AddFieldLink;

const LinkOut = styled('a')<{ isDisabled: boolean }>`
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: ${props =>
    props.isDisabled
      ? theme.palette.colors.grey[300]
      : theme.palette.colors.purple[500]};
  font-style: ${props => props.isDisabled && 'italic'};
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  max-width: 100%;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'cursor')};
`;
