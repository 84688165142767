import { useMemo } from 'react';

import { useGetIntentsQuery } from 'src/services/workflow-builder-metrics';
import { CommonIntentWorkflowType } from 'src/utils/enums';

const useGetHasOtherQuestions = () => {
  const { data } = useGetIntentsQuery();

  const hasOtherQuestions = useMemo(
    () =>
      Boolean(
        data?.intents.find(
          intent =>
            intent.intent_definition_id ===
            CommonIntentWorkflowType.GENERAL_HANDOFF,
        ),
      ),
    [data?.intents],
  );

  return hasOtherQuestions;
};

export default useGetHasOtherQuestions;
