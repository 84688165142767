import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { Skeleton } from '@forethought-technologies/forethought-elements';
import OverviewHeader from '../common/OverviewHeader';
import { numToPercentage } from '../helpers';
import { getAccuracy } from './helpers';
import TicketStatus from './TicketStatus';
import { NA } from 'src/constants/solve';
import {
  useGetPredictionTestResultsQuery,
  useGetTicketsOverviewMetricsQuery,
} from 'src/services/triage/triageApi';
import { TriageTicket } from 'src/services/triage/types';
import { DateRange } from 'src/types/types';

const TicketsOverview = ({
  dateRange,
  filters,
  keywordSearch,
  modelId,
  page,
  ticketId,
  tickets,
}: {
  dateRange: DateRange;
  filters: string[];
  keywordSearch?: string;
  modelId: string;
  page: number;
  ticketId?: string;
  tickets?: TriageTicket[];
}) => {
  const location = useLocation();
  const isSimulation = location.pathname.includes('simulations');
  const predictionTestId = window.location.pathname.split('/')[2];

  const { data, isFetching, isLoading } = useGetTicketsOverviewMetricsQuery(
    {
      endDate: Number(dateRange.to),
      filters,
      keywordSearch,
      modelId,
      page,
      startDate: Number(dateRange.from),
      ticketId,
    },
    {
      skip: isSimulation,
    },
  );

  const { isFetching: isTestTableFetching, isLoading: isTestTableLoading } =
    useGetPredictionTestResultsQuery(predictionTestId, {
      skip: !isSimulation,
    });

  const ticketsOverviewLoading =
    isFetching || isLoading || isTestTableFetching || isTestTableLoading;

  const overviewData = useMemo(() => {
    if (isSimulation) {
      return [
        {
          key: 'Total tickets',
          tooltip: '',
          value: tickets?.length.toLocaleString() || NA,
          Value: ticketsOverviewLoading ? <Skeleton /> : null,
        },
        {
          key: 'Predicted tickets',
          tooltip:
            'Tickets where Triage successfully predicted and assigned a label',
          value:
            tickets
              ?.filter(item => item.predicted_label)
              .length.toLocaleString() || NA,
          Value: ticketsOverviewLoading ? <Skeleton /> : null,
        },
        {
          key: 'Correct predictions',
          tooltip:
            "Tickets where Triage's predicted label was accurate, meaning the agent did not override it, and the ticket is now closed or resolved",
          value:
            tickets
              ?.filter(item => item.is_prediction_accurate)
              .length.toLocaleString() || NA,
          Value: ticketsOverviewLoading ? <Skeleton /> : null,
        },
        {
          key: 'Accuracy',
          tooltip:
            'The percentage of correct predictions out of all finalized tickets. Calculated as: (Correct Predictions / Finalized Tickets) X 100',
          value: numToPercentage(getAccuracy(tickets)) || NA,
          Value: ticketsOverviewLoading ? <Skeleton /> : null,
        },
      ];
    }

    return [
      {
        key: 'Predicted ticket',
        tooltip:
          'Tickets where Triage successfully predicted and assigned a label',
        value: data?.total_predictions_count?.toLocaleString() || NA,
        Value: ticketsOverviewLoading ? <Skeleton /> : null,
      },
      {
        key: 'Ticket status',
        tooltip: '',
        value: '',
        Value: <TicketStatus data={data} isLoading={ticketsOverviewLoading} />,
      },
      {
        key: 'Correct predictions',
        tooltip:
          "Tickets where Triage's predicted label was accurate, meaning the agent did not override it, and the ticket is now closed or resolved",
        value: data?.correct_predictions_count?.toLocaleString() || NA,
        Value: ticketsOverviewLoading ? <Skeleton /> : null,
      },
      {
        key: 'Accuracy',
        tooltip:
          'The percentage of correct predictions out of all finalized tickets. Calculated as: (Correct Predictions / Finalized Tickets) X 100',
        value: numToPercentage(data?.accuracy || 0) || NA,
        Value: ticketsOverviewLoading ? <Skeleton /> : null,
      },
    ];
  }, [data, ticketsOverviewLoading, isSimulation, tickets]);

  return (
    <OverviewHeader
      data={overviewData}
      dateRange={dateRange}
      gridTemplateColumns={isSimulation ? '1fr 1fr 1fr 1fr' : '1fr 2fr 1fr 1fr'}
      isLoading={isLoading}
      title='All tickets overview'
    />
  );
};

export default TicketsOverview;
