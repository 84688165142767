import { useState } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  Drawer,
  formatToDateOnly,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  ADD_ON_DIALOG_DATA,
  ADD_ONS,
  currentPlanToPreviousPlan,
  RENEWS_ON,
  YOUR_PLAN,
} from './constants';
import PlanConfirmationModal from './PlanConfirmationModal';
import SubscriptionPlanDetailsCard from './SubscriptionPlanDetailsCard';
import { DialogData } from './SubscriptionPlanPage';
import UpgradePlanModal from 'src/components/usage-meter-widget/UpgradePlanModal';
import {
  useGetUsageDataQuery,
  useUpgradePlanMutation,
} from 'src/services/subscription-plan/subscriptionPageApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface SubscriptionPlanDetailsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const SubscriptionPlanDetailsDrawer = ({
  isOpen,
  onClose,
}: SubscriptionPlanDetailsDrawerProps) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerBody />
    </Drawer>
  );
};

export default SubscriptionPlanDetailsDrawer;

const DrawerBody = () => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const { data } = useGetUsageDataQuery();
  const [upgradePlan, { isLoading }] = useUpgradePlanMutation();

  const [dialogData, setDialogData] = useState<DialogData | null>(null);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const isDialogVisible = Boolean(dialogData);
  const dialogLabel = dialogData?.label || '';
  const dialogText = dialogData?.text || '';
  const currentPlanData = data?.current_plan;
  const upgradePlansData = data?.upgrade_plan_details || [];
  const availableAddOns = data?.available_add_ons || [];
  const purchasedAddOns = data?.purchased_add_ons || [];

  const getUpgradePlanDescription = (fee: number, planName: string) => {
    return `Everything in ${currentPlanToPreviousPlan[planName]}, plus:`;
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        gap='32px'
        height='100%'
        p='40px'
        sx={{ overflowY: 'scroll' }}
      >
        <Box py='6px'>
          <Typography variant='font24'>{YOUR_PLAN}</Typography>
        </Box>
        <Box display='flex' gap='5px' py='6px'>
          <Typography color={palette.colors.grey[600]} variant='font16'>
            {RENEWS_ON}
          </Typography>
          <Typography variant='font16'>
            {formatToDateOnly(data?.renewal_date || '')}
          </Typography>
        </Box>
        <SubscriptionPlanDetailsCard
          extraFeatures={purchasedAddOns}
          features={currentPlanData?.skus || []}
          isCurrentPlan
          title={currentPlanData?.name || ''}
        />
        {upgradePlansData.length > 0 &&
          upgradePlansData.map(({ fee, name, skus = [] }) => (
            <SubscriptionPlanDetailsCard
              description={getUpgradePlanDescription(fee, name)}
              features={skus}
              key={name}
              onClick={() => {
                setDialogData(ADD_ON_DIALOG_DATA);
              }}
              title={name}
            />
          ))}
        {availableAddOns.length > 0 && (
          <SubscriptionPlanDetailsCard
            features={availableAddOns}
            onClick={() => {
              setDialogData(ADD_ON_DIALOG_DATA);
            }}
            title={ADD_ONS}
          />
        )}
      </Box>
      <PlanConfirmationModal
        isDialogVisible={isDialogVisible}
        isLoading={isLoading}
        label={dialogLabel}
        onChange={text =>
          setDialogData(data => data && { ...data, text: text })
        }
        onClose={() => setDialogData(null)}
        onSend={() => {
          upgradePlan({ upgrade_request_details: dialogText })
            .unwrap()
            .then(() => {
              setDialogData(null);
              setIsSuccessModalVisible(true);
            })
            .catch(() => {
              setDialogData(null);
              dispatch(
                setGlobalToastOptions({
                  title: 'Something went wrong, please try again',
                  variant: 'danger',
                }),
              );
            });
        }}
        value={dialogText}
      />
      <UpgradePlanModal
        isOpen={isSuccessModalVisible}
        onClose={() => setIsSuccessModalVisible(false)}
      />
    </>
  );
};
