import { MacroDataDict, TableDataDict } from 'src/services/apiInterfaces';
export const sortBreakdownTableData = (
  tableDataBreakdown: TableDataDict['breakdown'],
  field: string,
  sortAsc: boolean,
) => {
  return genericSortTableData(
    tableDataBreakdown,
    field,
    sortAsc,
  ) as TableDataDict['breakdown'];
};
export const sortMacrosTableData = (
  tableDataBreakdown: MacroDataDict[],
  field: string,
  sortAsc: boolean,
  isTrainedMacrosView: boolean,
) => {
  return genericSortTableData(
    tableDataBreakdown,
    field,
    sortAsc,
    isTrainedMacrosView,
  ) as Array<MacroDataDict>;
};
const genericSortTableData = (
  tableDataBreakdown: TableDataDict['breakdown'] | Array<MacroDataDict>,
  field: string,
  sortAsc: boolean,
  isTrainedMacrosView?: boolean,
): Array<MacroDataDict> | TableDataDict['breakdown'] => {
  if (!tableDataBreakdown?.length) {
    return [];
  }
  let comparison = 0;
  let tableDataBreakdownCopy;
  if (isTrainedMacrosView !== undefined) {
    tableDataBreakdownCopy = (
      tableDataBreakdown as Array<MacroDataDict>
    ).filter((macro: MacroDataDict) =>
      isTrainedMacrosView ? macro.is_trained : !macro.is_trained,
    );
  } else {
    tableDataBreakdownCopy = [...tableDataBreakdown];
  }

  tableDataBreakdownCopy.sort((a: any, b: any) => {
    let objectA = a[field];
    let objectB = b[field];
    if (typeof objectA === 'string') {
      objectA = objectA.toUpperCase().trim();
    }
    if (typeof objectB === 'string') {
      objectB = objectB.toUpperCase().trim();
    }
    comparison = (sortAsc ? 1 : -1) * (objectA < objectB ? -1 : 1);
    return comparison;
  });
  return tableDataBreakdownCopy as
    | Array<MacroDataDict>
    | TableDataDict['breakdown'];
};
