import { SOLVE_EMAIL_BASE_URL, SOLVE_V2_BASE_URL } from '../constants';
import { dashboardApi } from '../dashboard-api';
import {
  ConversationDetailResponse,
  ConversationRequest,
} from '../insights/types';
import isNil from 'lodash/fp/isNil';
import omitBy from 'lodash/fp/omitBy';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import { SolveWidgetProduct } from 'src/types/types';
import {
  AggregateEmailConversationMetrics,
  CanvasIntentsResponse,
  DecommissionWorkflowResponse,
  EmailConversationMetricBreakdown,
  GoToIntentUsagesResponse,
  HandoffConfigurationsResponse,
  IntentsResponse,
  IntentWorkflowMetadataResponse,
  MetricAggregateResponse,
  MetricBreakdownResponse,
  SolveMetricsRequestQueryParams,
  WorkflowBuilderSettingsResponse,
} from 'src/types/workflowBuilderAPITypes';

const workflowBuilderMetricsApi = dashboardApi.injectEndpoints({
  endpoints: builder => ({
    activateIntentWorkflow: builder.mutation<
      void,
      { intentWorkflowId: string; workflowTypes: Array<WorkflowTypes> }
    >({
      invalidatesTags: ['Intents', 'Tools'],
      async onQueryStarted(
        { intentWorkflowId, workflowTypes },
        { dispatch, queryFulfilled },
      ) {
        // Update the cache before the request succeeds
        const optimisticUpdateAction =
          workflowBuilderMetricsApi.util.updateQueryData(
            'getIntents',
            undefined,
            draft => {
              draft.intents = draft.intents.map(intent => {
                if (intent.intent_workflow_id === intentWorkflowId) {
                  return {
                    ...intent,
                    active_workflow_types: [
                      ...intent.active_workflow_types,
                      ...workflowTypes,
                    ],
                  };
                }
                return intent;
              });
            },
          );
        // updates WFB landing page intents
        const optimisticUpdateAction2 =
          workflowBuilderMetricsApi.util.updateQueryData(
            'getIntents',
            { include_inquiry_counts: true },
            draft => {
              draft.intents = draft.intents.map(intent => {
                if (intent.intent_workflow_id === intentWorkflowId) {
                  return {
                    ...intent,
                    active_workflow_types: [
                      ...intent.active_workflow_types,
                      ...workflowTypes,
                    ],
                  };
                }
                return intent;
              });
            },
          );

        const result = dispatch(optimisticUpdateAction);
        const result2 = dispatch(optimisticUpdateAction2);
        try {
          await queryFulfilled;
        } catch {
          result.undo();
          result2.undo();
        }
      },
      query: ({ intentWorkflowId, workflowTypes }) => ({
        body: { workflow_types: workflowTypes },
        method: 'PUT',
        url:
          SOLVE_V2_BASE_URL +
          `/workflow-builder/workflow/${intentWorkflowId}/activate`,
      }),
    }),
    deactivateIntentWorkflow: builder.mutation<
      { success: true } | DecommissionWorkflowResponse,
      { intentWorkflowId: string; workflowTypes: Array<WorkflowTypes> }
    >({
      invalidatesTags: ['Intents', 'Tools'],
      async onQueryStarted(
        { intentWorkflowId, workflowTypes },
        { dispatch, queryFulfilled },
      ) {
        // Update the cache before the request succeeds
        const optimisticUpdateAction =
          workflowBuilderMetricsApi.util.updateQueryData(
            'getIntents',
            undefined,
            draft => {
              draft.intents = draft.intents.map(intent => {
                if (intent.intent_workflow_id === intentWorkflowId) {
                  return {
                    ...intent,
                    active_workflow_types: intent.active_workflow_types.filter(
                      workflowType => {
                        return !workflowTypes.includes(workflowType);
                      },
                    ),
                  };
                }
                return intent;
              });
            },
          );
        // updates WFB landing page intents
        const optimisticUpdateAction2 =
          workflowBuilderMetricsApi.util.updateQueryData(
            'getIntents',
            { include_inquiry_counts: true },
            draft => {
              draft.intents = draft.intents.map(intent => {
                if (intent.intent_workflow_id === intentWorkflowId) {
                  return {
                    ...intent,
                    active_workflow_types: intent.active_workflow_types.filter(
                      workflowType => {
                        return !workflowTypes.includes(workflowType);
                      },
                    ),
                  };
                }
                return intent;
              });
            },
          );
        const result = dispatch(optimisticUpdateAction);
        const result2 = dispatch(optimisticUpdateAction2);

        try {
          /**
           * Handle unique case where workflows are dependent on each other
           * The backend will return a 200 response with "success": false,
           * and we will pass this error along to setDeactivateWorkflowError
           */
          const { success } = (await queryFulfilled).data;
          if (success === false) {
            result.undo();
            result2.undo();
          }
        } catch {
          result.undo();
          result2.undo();
        }
      },
      query: ({ intentWorkflowId, workflowTypes }) => ({
        body: { workflow_types: workflowTypes },
        method: 'PUT',
        url:
          SOLVE_V2_BASE_URL +
          `/workflow-builder/workflow/${intentWorkflowId}/deactivate`,
      }),
    }),
    getConnectableIntents: builder.query<
      CanvasIntentsResponse,
      { product?: SolveWidgetProduct }
    >({
      providesTags: ['Intents'],
      query: ({ product }) => ({
        params: { product },
        url: SOLVE_V2_BASE_URL + '/workflow-builder/intents',
      }),
    }),
    getEmailAggregateMetrics: builder.query<
      AggregateEmailConversationMetrics,
      SolveMetricsRequestQueryParams
    >({
      query: ({ from, to }) => ({
        params: { end_timestamp: to, start_timestamp: from },
        url: SOLVE_EMAIL_BASE_URL + '/conversation/metrics/aggregate',
      }),
    }),
    getEmailBreakdownMetrics: builder.query<
      EmailConversationMetricBreakdown,
      SolveMetricsRequestQueryParams
    >({
      query: ({ from, to }) => ({
        params: { end_timestamp: to, start_timestamp: from },
        url: SOLVE_EMAIL_BASE_URL + '/conversation/metrics/breakdown',
      }),
    }),
    getEmailConversation: builder.query<
      ConversationDetailResponse,
      ConversationRequest
    >({
      query: ({ conversation_id }) => ({
        url:
          SOLVE_EMAIL_BASE_URL +
          `/conversation/${conversation_id}/interactive-email-conversation`,
      }),
    }),
    getGoToIntentUsages: builder.query<GoToIntentUsagesResponse, void>({
      providesTags: ['IntentUsages'],
      query: () => ({
        url: SOLVE_V2_BASE_URL + '/go-to-intent-usages',
      }),
    }),
    getHandoffConfigurations: builder.query<
      HandoffConfigurationsResponse,
      void
    >({
      providesTags: ['HandoffConfigurations'],
      query: () => SOLVE_V2_BASE_URL + '/intent-handoff-configuration',
    }),
    getIntents: builder.query<
      IntentsResponse,
      {
        end_timestamp?: number | null;
        include_inquiry_counts?: boolean;
        start_timestamp?: number | null;
      } | void
    >({
      providesTags: ['Intents'],
      query: ({
        end_timestamp = null,
        include_inquiry_counts = false,
        start_timestamp = null,
      } = {}) => {
        const params = {
          end_timestamp,
          include_inquiry_counts,
          start_timestamp,
        };
        return {
          params: omitBy(isNil, params),
          url: SOLVE_V2_BASE_URL + '/intents',
        };
      },
      serializeQueryArgs: args => {
        // we dont care about inquiry counts in the cache
        // they are only relevant for the workflow builder landing page table
        // which is refetched on mount or arg change
        return (
          args.endpointName +
          (args.queryArgs?.include_inquiry_counts ?? false).toString()
        );
      },
    }),
    getIntentWorkflowMetadata: builder.query<
      IntentWorkflowMetadataResponse,
      void
    >({
      query: () => SOLVE_V2_BASE_URL + '/intent-workflows/metadata',
    }),
    getWidgetAggregateMetrics: builder.query<
      MetricAggregateResponse,
      SolveMetricsRequestQueryParams
    >({
      query: ({ channel, from, to }) => ({
        params: {
          channel: channel || 'widget',
          end_timestamp: to,
          start_timestamp: from,
        },
        url: SOLVE_V2_BASE_URL + '/workflow-builder/metrics/aggregate',
      }),
    }),
    getWidgetBreakdownMetrics: builder.query<
      MetricBreakdownResponse,
      SolveMetricsRequestQueryParams
    >({
      providesTags: ['WidgetBreakdownMetrics'],
      query: ({ channel, from, tags, to }) => ({
        params: {
          channel: channel || 'widget',
          end_timestamp: to,
          start_timestamp: from,
          tags: tags?.join(','),
        },
        url: SOLVE_V2_BASE_URL + '/workflow-builder/metrics/breakdown',
      }),
    }),
    getWorkflowTags: builder.query<string[], void>({
      providesTags: ['WorkflowTags'],
      query: () =>
        SOLVE_V2_BASE_URL + '/workflow-builder/metrics/workflow-tags',
    }),
    initWorkflowBuilderSettings: builder.query<
      WorkflowBuilderSettingsResponse,
      void
    >({
      query: () => ({
        body: {},
        method: 'PUT',
        url: SOLVE_V2_BASE_URL + '/workflow-builder/settings/workflow-builder',
      }),
    }),
  }),
});

export const {
  useActivateIntentWorkflowMutation,
  useDeactivateIntentWorkflowMutation,
  useGetConnectableIntentsQuery,
  useGetContextVariablesQuery,
  useGetEmailAggregateMetricsQuery,
  useGetEmailBreakdownMetricsQuery,
  useGetEmailConversationQuery,
  useGetGoToIntentUsagesQuery,
  useGetHandoffConfigurationsQuery,
  useGetIntentsQuery,
  useGetIntentWorkflowMetadataQuery,
  useGetWidgetAggregateMetricsQuery,
  useGetWidgetBreakdownMetricsQuery,
  useGetWorkflowTagsQuery,
  useInitWorkflowBuilderSettingsQuery,
} = workflowBuilderMetricsApi;
