import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getFormattedTicketFieldMappings } from './utils';
import {
  CreateTicketFieldMappingRequest,
  UpdateTicketFieldMappingsRequest,
} from 'src/pages/workflow-builder-edit/types';
import * as customFieldsMappingAPI from 'src/services/custom-field-mapping/customFieldMappingApi';
import type { RootState } from 'src/store/rootReducer';

const ERROR = 'Error';

export interface TicketFieldMappingState {
  error: string | null;
  isLoading: boolean;
  ticketFieldMappings: Array<CreateTicketFieldMappingRequest>;
}

export const initialState: TicketFieldMappingState = {
  error: null,
  isLoading: false,
  ticketFieldMappings: [],
};

export const getTicketFieldMappings = createAsyncThunk(
  'emailWorkflow/getTicketFieldMappings',
  () => {
    return customFieldsMappingAPI.getTicketFieldMappings();
  },
);

export const updateTicketFieldMappings = createAsyncThunk(
  'emailWorkflow/updateTicketFieldMappings',
  (mappings: UpdateTicketFieldMappingsRequest) => {
    return customFieldsMappingAPI.updateTicketFieldMappings(mappings);
  },
);

const ticketFieldMappingsSlice = createSlice({
  extraReducers: builder => {
    /**
     * getTicketFieldMappings
     */
    builder.addCase(getTicketFieldMappings.fulfilled, (state, action) => {
      state.ticketFieldMappings = getFormattedTicketFieldMappings(
        action.payload.mappings,
      );
      state.isLoading = false;
    });

    builder.addCase(getTicketFieldMappings.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(getTicketFieldMappings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || ERROR;
    });
    /**
     * updateTicketFieldMappings
     */
    builder.addCase(updateTicketFieldMappings.fulfilled, (state, action) => {
      state.ticketFieldMappings = getFormattedTicketFieldMappings(
        action.payload.mappings,
      );
      state.isLoading = false;
    });
    builder.addCase(updateTicketFieldMappings.pending, state => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(updateTicketFieldMappings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message || ERROR;
    });
  },
  initialState,
  name: 'ticketFieldMappings',
  reducers: {},
});

export default ticketFieldMappingsSlice.reducer;

export const selectTicketFieldMappingsState = (
  state: RootState,
): TicketFieldMappingState => state.ticketFieldMapping;
